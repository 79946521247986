import Schema from "../details/validation";
import { getColumnSize } from "modules/common/services/table/size-storage";
import { getFieldLabel } from "modules/common/services/form/fields";
import { fields } from "../../models/order-type";
import { ColDef } from "@ag-grid-community/core";
import { RowNumberColumn } from "modules/common/services/table/helpers";

const schema = Schema();

export const columns = (tableName: string) => {
    const columnSize = getColumnSize(tableName);

    const result: ColDef[] = [
        RowNumberColumn(false, ""),
        {
            headerName: getFieldLabel(fields.name, schema, null),
            field: fields.name,
            width: columnSize(fields.name, 250),
            sortable: true,
            resizable: true,
        },
        {
            headerName: getFieldLabel(fields.fullName, schema, null),
            field: fields.fullName,
            width: columnSize(fields.fullName, 400),
            sortable: true,
            resizable: true,
        },
        {
            headerName: getFieldLabel(fields.description, schema, null),
            field: fields.description,
            width: columnSize(fields.description, 600),
            sortable: false,
            resizable: true,
        },
    ];

    return result;
};
