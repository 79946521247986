import React from "react";
import { Page } from "modules/common/components/page/Page";
import { Tabs, Tab } from "@blueprintjs/core";
import styles from "./SpendingTabsPage.module.scss";
import { menuItems } from "modules/root/components/menu/MainMenu";
import { routes } from "modules/common/routes";
import { RouteChildrenProps } from "react-router";

const menu = menuItems();

export default class TimesheetPage extends React.Component<SpendingTabsPageProps & RouteChildrenProps> {
  render() {
    const { location, timesheetPanel, schedulePanel, departmentsPanel, workloadPanel, unitPanel, employeePanel } =
      this.props;
    const tabId = getTabId(location.pathname);
    // const selectedTab = items.find((i) => i.id === tabId);
    return (
      <Page>
        <Tabs
          className={`${styles.tabs} planr-tabs-navigation`}
          id="TimesheetPage"
          selectedTabId={tabId}
          renderActiveTabPanelOnly={true}
          onChange={this.handleTabChange}
        >
          {/* <SimpleSelect
            className={`full-width-select ${Classes.FILL}`}
            activeItem={selectedTab}
            items={items}
            itemRenderer={renderSingleOption}
            onItemSelect={(i: SelectItem) => {
              const { history } = this.props;

              history.push(i.id);
            }}
            filterable={false}
            popoverProps={{
              boundary: "viewport",
              //  usePortal: true,
              // popoverClassName: "order-content_mentor-select-dropdown",
            }}
          >
            <DefaultSelectedOption option={selectedTab} />
          </SimpleSelect> */}
          {schedulePanel && (
            <Tab
              className="schedule"
              id={routes.spending.schedule}
              title={menu.spending.nested.schedule.label}
              panel={schedulePanel}
            />
          )}
          {unitPanel && (
            <Tab
              className="schedule"
              id={routes.spending.unit}
              title={menu.spending.nested.unit.label}
              panel={unitPanel}
            />
          )}
          {timesheetPanel && (
            <Tab
              className="timesheet"
              id={routes.spending.timesheet}
              title={menu.spending.nested.timesheet.label}
              panel={timesheetPanel}
            />
          )}
          {workloadPanel && (
            <Tab
              className="timesheet"
              id={routes.spending.workload}
              title={menu.spending.nested.workload.label}
              panel={workloadPanel}
            />
          )}
          {departmentsPanel && (
            <Tab
              className="timesheet"
              id={routes.spending.department.path}
              title={menu.spending.nested.departments.label}
              panel={departmentsPanel}
            />
          )}
          {employeePanel && (
            <Tab
              className="timesheet"
              id={routes.spending.employee.path}
              title={menu.spending.nested.employee.label}
              panel={employeePanel}
            />
          )}
        </Tabs>
      </Page>
    );
  }

  handleTabChange = (newTabId: string) => this.props.history.push(newTabId);
}

const getTabId = (pathname: string) => {
  if (pathname.includes(routes.spending.timesheet)) {
    return routes.spending.timesheet;
  }

  if (pathname.includes(routes.spending.workload)) {
    return routes.spending.workload;
  }

  if (pathname.includes(routes.spending.schedule)) {
    return routes.spending.schedule;
  }

  if (pathname.includes(routes.spending.unit)) {
    return routes.spending.unit;
  }

  if (pathname.includes(routes.spending.department.path)) {
    return routes.spending.department.path;
  }

  if (pathname.includes(routes.spending.employee.path)) {
    return routes.spending.employee.path;
  }

  return "";
};

interface SpendingTabsPageProps {
  timesheetPanel: JSX.Element | null;
  workloadPanel: JSX.Element | null;
  schedulePanel: JSX.Element | null;
  departmentsPanel: JSX.Element | null;
  employeePanel: JSX.Element | null;
  unitPanel: JSX.Element | null;
}
