import React from "react";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Block } from "modules/common/components/page/Block";
import { Route, withRouter, RouteComponentProps, matchPath } from "react-router";
import { routes } from "modules/common/routes";
import { UnregisterCallback } from "history";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { WorkTypesStoreType } from "../models/work-types-store";
import { WorkTypeDetails } from "./details/WorkTypeDetails";
import { WorkTypesList } from "./list/WorkTypesList";
import { menuItems } from "modules/root/components/menu/MainMenu";
import functionalities from "modules/dictionaries/functionalities";
import { can } from "modules/session/auth/access";

import { PlanrButton } from "modules/common/components/planr/button/Button";
import styles from "./WorkTypesPage.module.scss";
import { Search } from "modules/common/components/form/Search";

const menu = menuItems();

class WorkTypesPage extends React.Component<WorkTypesPageProps & RouteComponentProps, WorkTypesPageState> {
  private unregister: UnregisterCallback | null = null;

  constructor(props: WorkTypesPageProps & RouteComponentProps) {
    super(props);

    this.state = { typeId: getSelectedWorkTypeId(props.location.pathname) };
  }

  componentDidMount() {
    document.title = menu.dictionaries.nested.workTypes.label;

    const { history } = this.props;
    const { typeId } = this.state;

    this.unregister = history.listen((location) => {
      const typeId = getSelectedWorkTypeId(location.pathname);

      if (this.state.typeId !== typeId) {
        this.setState({ typeId });
        this.loadDetails(typeId);
      }
    });

    this.loadList();
    this.loadDetails(typeId);
  }

  componentWillUnmount() {
    this.unregister && this.unregister();
  }

  render() {
    const { store, access } = this.props;
    const canChange = can(functionalities.WORKTYPE_EDIT, access);

    return (
      <Block columned={true} className={styles.page}>
        <Route path={routes.dictionaries.workType.details()}>
          <DetailsPopup title="Вид работ" onCancel={this.onWorkTypeSelected}>
            <WorkTypeDetails
              workType={store.details}
              onSaved={this.onWorkTypeSaved}
              onRemoved={this.onWorkTypeRemoved}
              canChange={canChange}
            />
          </DetailsPopup>
        </Route>
        <div className={styles.tools}>
          {canChange && (
            <PlanrButton
              type="secondary"
              size="small"
              icon="general-plus-big"
              onClick={this.onWorkTypeAdd}
              style={{
                width: "250px",
                marginBottom: "32px",
                borderRadius: "120px",
              }}
            >{`${texts.add} вид работ`}</PlanrButton>
            // <DictioantyAddButton onClick={this.onWorkTypeAdd} label={`${texts.add} вид работ`} />
          )}
        </div>
        <div className={styles.mobileTools}>
          {canChange && <PlanrButton type="secondary" round icon="general-plus-big" onClick={this.onWorkTypeAdd} />}
        </div>
        <div className={styles.right}>
          <Search query={store.list.query} onSearch={store.list.setQuery} />
        </div>
        <WorkTypesList store={store.list} onSelected={this.onWorkTypeSelected} onRemoved={this.onWorkTypeRemoved} />
      </Block>
    );
  }

  onWorkTypeAdd = () => {
    this.onWorkTypeSelected(EMPTY_OBJECT_ID);
  };

  onWorkTypeSelected = (id: string = "") => {
    const { history } = this.props;

    const url = id ? routes.dictionaries.workType.details(id) : routes.dictionaries.workType.path;

    history.push(url);
  };

  onWorkTypeSaved = () => {
    this.onWorkTypeSelected("");
    this.loadList();
  };

  onWorkTypeRemoved = () => {
    const { history } = this.props;

    this.loadList();

    const url = routes.dictionaries.workType.path;
    history.push(url);
  };

  loadList = () => this.props.store.list.load();

  loadDetails = (id: string) => this.props.store.details.load(id);
}

export default withRouter(observer(WorkTypesPage));

function getSelectedWorkTypeId(pathname: string) {
  const match = matchPath<WorkTypesPageState>(pathname, routes.dictionaries.workType.details());

  return match && match.params ? match.params.typeId : "";
}

interface WorkTypesPageProps {
  store: WorkTypesStoreType;
  access: string[];
}

interface WorkTypesPageState {
  typeId: string;
}
