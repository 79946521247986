import * as yup from "yup";
import { fields as mailFields } from "modules/orders-manage/models/order-mails";
import { texts } from "modules/common/texts";

const { object, string, date, boolean } = yup;

export const orderMailLabels = () => ({
    [mailFields.orderId]: "Проект",
    [mailFields.date]: "Дата",
    [mailFields.number]: "Номер",
    [mailFields.name]: "Тема",
    [mailFields.comment]: texts.comment,
    [mailFields.type]: "Тип",
    [mailFields.assigneeId]: "Исполнитель",
    [mailFields.mentorId]: "Ответственный",
    [mailFields.deadline]: "Крайний срок",
    [mailFields.accepted]: "Принято",
    [mailFields.completed]: "Исполнено",
});

export const OrderMailsSchema = () => {
    const labels = orderMailLabels();

    return object().shape({
        [mailFields.orderId]: string().label(labels[mailFields.orderId]).notRequired(),

        [mailFields.date]: date().label(labels[mailFields.date]).typeError(texts.messages.incorrectDate()).required(),

        [mailFields.number]: string().label(labels[mailFields.number]).required(),

        [mailFields.name]: string().label(labels[mailFields.name]).required(),

        [mailFields.assigneeId]: string().label(labels[mailFields.assigneeId]).notRequired(),

        [mailFields.mentorId]: string().label(labels[mailFields.mentorId]).required(),

        [mailFields.deadline]: date().label(labels[mailFields.deadline]).typeError(texts.messages.incorrectDate()),
        [mailFields.accepted]: boolean().label(labels[mailFields.accepted]),

        [mailFields.completed]: boolean().label(labels[mailFields.completed]),

        [mailFields.comment]: string().label(labels[mailFields.comment]).notRequired(),

        [mailFields.type]: string().label(labels[mailFields.type]).required(),
    });
};
