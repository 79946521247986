import {
    PlanOrderPaymentDetailsRowType,
    PlanOrderPaymentDetailsType,
} from "modules/orders-manage/models/order-stages-store";

import { formatMoney } from "./../../common/components/money/Money";
import { applySnapshot, Instance, types } from "mobx-state-tree";
import { OrderStagesStore, initialState as emptyStages } from "./order-stages-store";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { BusinessEventDictionaryItem, treat } from "./order-events";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { base64ToBlob, printPdf } from "modules/common/services/files";

export const OrderFinancesStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            stages: OrderStagesStore,
            timeline: types.array(BusinessEventDictionaryItem),
            orderId: types.string,
        })
    )
    .actions((self) => ({
        load: flow(function* (orderId: string) {
            self.orderId = orderId;

            if (orderId === EMPTY_OBJECT_ID) {
                applySnapshot(self.timeline, []);
                applySnapshot(self.stages, emptyStages(orderId));

                return;
            }

            try {
                const events = yield self.transport.get<any>(apiUrls.orders.stages.timeline(orderId));
                applySnapshot(self.timeline, treat(events));
            } catch (er) {
                self.notify.error(er);
                applySnapshot(self.timeline, []);
            }

            yield self.stages.load(orderId);
        }),
        loadTimeline: flow(function* (orderId: string) {
            self.orderId = orderId;

            if (orderId === EMPTY_OBJECT_ID) {
                applySnapshot(self.timeline, []);
                applySnapshot(self.stages, emptyStages(orderId));

                return;
            }

            try {
                const events = yield self.transport.get<any>(apiUrls.orders.stages.timeline(orderId));
                applySnapshot(self.timeline, treat(events));
            } catch (er) {
                self.notify.error(er);
                applySnapshot(self.timeline, []);
            }
        }),

        printStagesModel: flow(function* () {
            try {
                let blocks: any = [];
                let planPaymentsSum = 0;
                let actPaymentsSum = 0;
                const splitText = (str: string) => {
                    const startDateArr = str.split(";");

                    const starDateRows: string[] = [];
                    startDateArr.forEach((d) => {
                        const element = d.split(" ");
                        for (let i = 0; i < element.length; i++) {
                            if (element[i].includes("-")) {
                                const newElements = element[i].split("-");
                                newElements[0] += "-";
                                element[i] = newElements[0];
                                element.splice(i + 1, 0, newElements[1]);
                            }
                        }

                        let row = "";
                        for (let i = 0; i < element.length; i++) {
                            row = row + element[i] + " ";

                            if (row.length > 21) {
                                row = row.slice(0, row.indexOf(element[i]));
                                starDateRows.push(row);
                                row = "";
                                i--;
                            } else {
                                if (i === element.length - 1) {
                                    starDateRows.push(row);
                                    row = "";
                                }
                            }
                        }
                    });
                    return starDateRows;
                };

                const createArrOfPayments = (linkedPayments: any, type: string) => {
                    let planPayments: string[] = [];
                    linkedPayments &&
                        linkedPayments[type] &&
                        linkedPayments[type].forEach((p: PlanOrderPaymentDetailsType) => {
                            let paymentDescr: string[] = [];
                            if (p.dateDescription) {
                                paymentDescr = splitText(p.dateDescription);
                            }

                            if (type === "Авансовый") {
                                planPayments.push(`Аванс-платеж №${p.name}`);
                            } else {
                                planPayments.push(`Платеж №${p.name}`);
                            }
                            const paidSum = p.sum - p.remainsSum;
                            const completellyPaid = p.sum === paidSum;
                            const paidText = !completellyPaid ? ` (${formatMoney(paidSum)})` : "";
                            planPayments.push(`${formatMoney(p.sum)} ${paidText}`);
                            if (paymentDescr.length > 0) {
                                planPayments = [...planPayments, ...paymentDescr];
                            }
                            if (p.actualPaymentGuids.length > 0) {
                                planPayments.push("Оплаты:");
                                p.actualPaymentGuids.forEach((act) => {
                                    const actualPayment = self.stages.actualMap[act];
                                    const paymentLine = `${actualPayment.date} ${formatMoney(actualPayment.sum)}`;
                                    planPayments.push(paymentLine);
                                });
                            }
                            planPayments.push(" ");
                        });
                    if (planPayments.length > 1) {
                        planPayments.pop();
                    }
                    return planPayments;
                };

                self.stages.stages.forEach((stage, index) => {
                    const linkedPayments = self.stages.stagePlanMap[stage.guid];
                    const linkedCompletons = self.stages.stageCompletionMap[stage.guid];
                    const completedSum = linkedCompletons
                        ? linkedCompletons.reduce((sum, item) => sum + item.sum, 0)
                        : 0;
                    const linkedConsignments = self.stages.stageConsignmentMap[stage.guid];
                    const linkedExpertises = self.stages.stageExpertiseMap[stage.guid];
                    const linkedSentExpertises = self.stages.stageSentExpertiseMap[stage.guid];
                    const completions = linkedCompletons
                        ? linkedCompletons.map((completion) => ({
                              name: "Акт №" + completion.name,
                              sum: formatMoney(completion.sum),
                          }))
                        : [];
                    completions.unshift({ name: formatMoney(completedSum), sum: " " });
                    const consignments = linkedConsignments
                        ? linkedConsignments.map((consignment) => `Накладная №${consignment.number}`)
                        : [];

                    const expertises = linkedExpertises
                        ? linkedExpertises.map((expertise) => `${expertise.file?.fileName.slice(0, 15)}...`)
                        : [];

                    const sentExpertises = linkedSentExpertises
                        ? linkedSentExpertises.map((expertise) => `${expertise.file?.fileName.slice(0, 15)}...`)
                        : [];

                    const planPayments = createArrOfPayments(linkedPayments, "Авансовый");
                    const actPayments = createArrOfPayments(linkedPayments, "Расчетный");

                    const startDateRows = splitText(stage.startDateText);
                    const stopDateRows = splitText(stage.stopDateText);

                    linkedPayments &&
                        linkedPayments["Авансовый"].forEach((payment: PlanOrderPaymentDetailsRowType) => {
                            planPaymentsSum += payment.sum;
                        });
                    linkedPayments &&
                        linkedPayments["Расчетный"].forEach((payment: PlanOrderPaymentDetailsRowType) => {
                            actPaymentsSum += payment.sum;
                        });

                    const stageNameRows = splitText(stage.name);
                    stageNameRows.push(" ");
                    if (expertises.length > 0) {
                        stageNameRows.push("Закл. экспертизы:");
                        expertises.forEach((exp: any) => {
                            stageNameRows.push(exp);
                        });
                    }

                    if (sentExpertises.length > 0) {
                        stageNameRows.push(" ");
                        stageNameRows.push("Передача в экспертизу:");
                        sentExpertises.forEach((exp: any) => {
                            stageNameRows.push(exp);
                        });
                    }

                    const amountOfItems =
                        Math.max(
                            completions.length,
                            consignments.length,
                            startDateRows.length,
                            stopDateRows.length,
                            planPayments.length,
                            actPayments.length,
                            stageNameRows.length
                        ) + 1;
                    if (completions.length === amountOfItems - 1) {
                        completions.push({ name: " ", sum: " " });
                    }
                    if (consignments.length === amountOfItems - 1) {
                        consignments.push(" ");
                    }
                    if (startDateRows.length === amountOfItems - 1) {
                        startDateRows.push(" ");
                    }
                    if (stopDateRows.length === amountOfItems - 1) {
                        stopDateRows.push(" ");
                    }
                    if (stopDateRows.length === amountOfItems - 1) {
                        stopDateRows.push(" ");
                    }
                    const stageSumRow: string[] = [formatMoney(stage.sum)];
                    let rows: any = [];
                    for (let i = 0; i < amountOfItems; i++) {
                        let item: any = {};

                        if (completions[i]) {
                            item.completionFile = completions[i].name;
                            item.completionSum = completions[i].sum;
                        } else {
                            item.completionFile = "";
                            item.completionSum = "";
                        }

                        if (consignments[i]) {
                            item.consignmentFile = consignments[i];
                        } else {
                            item.consignmentFile = "";
                        }

                        if (stageNameRows[i]) {
                            item.name = stageNameRows[i];
                        } else {
                            item.name = "";
                        }
                        if (startDateRows[i]) {
                            item.startDate = startDateRows[i];
                        } else {
                            item.startDate = "";
                        }

                        if (stopDateRows[i]) {
                            item.stopDate = stopDateRows[i];
                        } else {
                            item.stopDate = "";
                        }
                        if (planPayments[i]) {
                            item.planPayment = planPayments[i];
                        } else {
                            item.planPayment = "";
                        }

                        if (actPayments[i]) {
                            item.actPayment = actPayments[i];
                        } else {
                            item.actPayment = "";
                        }

                        if (stageSumRow[i]) {
                            item.stageSum = stageSumRow[i];
                        } else {
                            item.stageSum = "";
                        }

                        rows.push(item);
                    }

                    let item = {
                        number: index + 1,
                        name: stage.name,
                        additionsSum: formatMoney(completedSum),
                        stageSum: formatMoney(stage.sum),
                        enter: " ",
                        rows,
                    };

                    blocks.push(item);
                });

                let model = {
                    variables: JSON.stringify({
                        completionsSum: formatMoney(self.stages.completionsSum),
                        stagesSum: formatMoney(self.stages.stagesSum),
                        paymentsSum1: formatMoney(planPaymentsSum),
                        paymentsSum2: formatMoney(actPaymentsSum),
                        blocks: blocks,
                    }),
                    templateId: "ExportFinanceStagesPage",
                };

                const file: FileDescription = yield self.transport.post<any>(apiUrls.application.print2, model);

                const blob: any = yield base64ToBlob(file.content || "", file.mimeType);

                const fileURL = URL.createObjectURL(blob);
                const printer = printPdf(fileURL, true);
                if (printer) {
                    printer.onclose = () => URL.revokeObjectURL(fileURL);
                }

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .named("OrderFinancesStore");

export type OrderFinancesStoreType = Instance<typeof OrderFinancesStore>;
