import { applySnapshot, types } from "mobx-state-tree";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { TableSorter } from "modules/common/models/table-sorter";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { toJsonHard } from "modules/common/services/mobx/serialize";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { texts } from "modules/common/texts";
import { SpendingData } from "modules/expenses/summary/models/spending-list";
import { Constants } from "modules/root/models/constants";
import {
    OtherOrderSpending,
    OtherOrderSpendingType,
    OutsourcedOrderSpending,
    OutsourcedOrderSpendingType,
} from "../../../orders-manage/models/order-spending";
import { fields, ImportedBankRow, ImportedBankRowSnapshotType, ImportedBankRowType } from "./bank-row";

interface UploadedBankInvoiceRowCollection {
    rows: ImportedBankRowSnapshotType[];
    message: string;
}

const limit = 100;

export const ImportSpendingsStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            rows: types.array(ImportedBankRow),
            sorter: TableSorter,
            loader: types.number,
            hasMore: types.boolean,
            // changes
            rowTypeMap: types.map(types.string),
            rowOutsourcedMap: types.array(
                types.model({
                    id: types.string,
                    outsourcedOrderSpendings: types.array(OutsourcedOrderSpending),
                })
            ),
            rowOtherMap: types.array(
                types.model({ id: types.string, otherOrderSpendings: types.array(OtherOrderSpending) })
            ),
            rowSpendingMap: types.array(types.model({ id: types.string, spending: types.string })),
            rowLoadingMap: types.array(types.model({ id: types.string, loading: types.boolean })),
            payloadMap: types.map(types.string),
            tempPayloadMap: types.map(types.string),
            excludeMap: types.map(types.boolean),
        })
    )
    .views((self) => ({
        get isLoading() {
            return self.loader > 0;
        },

        get rowsSnapshot() {
            return self.rows.map((row) => ({
                ...toJsonHard(row),
                dateAsDate: row.dateAsDate,
                money: row.money,
                cleanPayer: row.cleanPayer,
                cleanRecipient: row.cleanRecipient,
            }));
        },

        get asMap(): TStringMap<ImportedBankRowType> {
            return self.rows.reduce((result, row) => {
                result[row.id] = row;
                return result;
            }, {} as TStringMap<ImportedBankRowType>);
        },

        get isEmpty() {
            return self.rows.length === 0;
        },
    }))
    .actions((self) => ({
        isExcluded(id: string) {
            return self.asMap[id].exclude || !!self.excludeMap.get(id);
        },
    }))
    .actions((self) => ({
        getTypeValue(id: string) {
            const row = self.asMap[id];
            const type = self.rowTypeMap.has(row.id) ? self.rowTypeMap.get(row.id) : row.type;
            return type || "";
        },

        getPayloadValue(id: string) {
            const row = self.asMap[id];
            const payload = self.payloadMap.has(row.id) ? self.payloadMap.get(row.id) : row.payload;
            return payload || "";
        },

        getOutsourcedSpendings(id: string) {
            const row = self.rowOutsourcedMap.find((r) => r.id === id);

            return row ? row.outsourcedOrderSpendings : [];
        },
        getOtherSpendings(id: string) {
            const row = self.rowOtherMap.find((r) => r.id === id);
            return row ? row.otherOrderSpendings : [];
        },

        getLoad(id: string) {
            const row = self.rowLoadingMap.find((r) => r.id === id);
            return row ? row.loading : false;
        },

        getSpending(id: string) {
            const row = self.rowSpendingMap.find((r) => r.id === id);
            return row ? row.spending : "";
        },

        getTempPayloadValue(id: string) {
            const row = self.asMap[id];
            const payload = self.tempPayloadMap.has(row.id) ? self.tempPayloadMap.get(row.id) : row.payload;
            return payload || "";
        },

        isRowValid,
    }))
    .actions((self) => ({
        isValid(id: string) {
            const type = self.getTypeValue(id);
            const payload = self.getPayloadValue(id);
            return self.isExcluded(id) || self.isRowValid(type, payload);
        },
    }))
    .actions((self) => ({
        loadTable: flow(function* (reset = false) {
            self.loader++;

            try {
                const fromDate = reset || self.isEmpty ? "" : self.rows[self.rows.length - 1].date;
                const fromRecord = reset || self.isEmpty ? "" : self.rows[self.rows.length - 1].id;

                const data: UploadedBankInvoiceRowCollection = yield self.transport.get<any>(
                    apiUrls.spendings.import.todo,
                    {
                        params: {
                            fromDate,
                            fromRecord,
                            limit,
                        },
                    }
                );

                self.hasMore = data.rows.length === limit;

                if (!fromRecord) {
                    applySnapshot(self.rowTypeMap, {});
                    applySnapshot(self.payloadMap, {});
                    applySnapshot(self.tempPayloadMap, {});
                    applySnapshot(self.rows, data.rows);
                } else {
                    self.rows.push(...data.rows);
                }

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.loader--;
            }
        }),
        loadSpendings: flow(function* (id: string) {
            try {
                let data: SpendingData = yield self.transport.get<any>(apiUrls.spendings.orderSpendings(id));
                if (data) {
                }
                return data;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.loader--;
            }
        }),

        upload: flow(function* (file: File) {
            self.loader++;

            try {
                const model = new FormData();
                model.append("file", file);
                model.append("limit", `${limit}`);

                const data: UploadedBankInvoiceRowCollection = yield self.transport.post<any>(
                    apiUrls.spendings.import.upload,
                    model
                );

                if (data.rows.length > 0) {
                    applySnapshot(self.rows, data.rows);
                }

                self.hasMore = data.rows.length === limit;

                if (data.message) {
                    self.notify.info(data.message);
                }

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.loader--;
            }
        }),

        save: flow(function* (id: string) {
            try {
                if (!self.isValid(id)) {
                    return [];
                }

                const exclude = self.isExcluded(id);
                const payloadId = self.getPayloadValue(id);
                const type = exclude ? Constants.bankInvoiceRowTypeSkip : self.getTypeValue(id);
                const spending = self.getSpending(id);
                const model: CommitRowModel = {
                    type,
                    payloadId,
                    id,
                    spending,
                };

                yield self.transport.post<any>(apiUrls.spendings.import.commit, {
                    rows: [model],
                });

                if (self.asMap[id]) {
                    self.rows.remove(self.asMap[id]);
                }

                self.notify.success(texts.messages.saved);
                return [id];
            } catch (er) {
                self.notify.error(er);
                return [];
            }
        }),
    }))
    .actions((self) => ({
        changeType(id: string, value: string) {
            self.rowTypeMap.set(id, value);
        },

        changeOutsourcedMap(id: string, arr: OutsourcedOrderSpendingType[]) {
            const index = self.rowOutsourcedMap.findIndex((r) => r.id === id);
            const newItem = { id, outsourcedOrderSpendings: arr };
            if (index > -1) {
                self.rowOutsourcedMap.splice(index, 1);
                self.rowOutsourcedMap.push(newItem);
            } else {
                self.rowOutsourcedMap.push(newItem);
            }
        },

        changeOtherMap(id: string, arr: OtherOrderSpendingType[]) {
            const index = self.rowOtherMap.findIndex((r) => r.id === id);
            const newItem = { id, otherOrderSpendings: arr };
            if (index > -1) {
                self.rowOtherMap.splice(index, 1);
                self.rowOtherMap.push(newItem);
            } else {
                self.rowOtherMap.push(newItem);
            }
        },

        changeSpendingMap(id: string, spending: string) {
            const index = self.rowSpendingMap.findIndex((r) => r.id === id);
            const newItem = { id, spending };
            if (index > -1) {
                self.rowSpendingMap.splice(index, 1);
                self.rowSpendingMap.push(newItem);
            } else {
                self.rowSpendingMap.push(newItem);
            }
        },

        changeLoadMap(id: string, val: boolean) {
            const index = self.rowLoadingMap.findIndex((r) => r.id === id);
            const newItem = { id, loading: val };
            if (index > -1) {
                self.rowLoadingMap.splice(index, 1);
                self.rowLoadingMap.push(newItem);
            } else {
                self.rowLoadingMap.push(newItem);
            }
        },

        changePayload(id: string, value: string) {
            self.payloadMap.set(id, value);
        },

        changeTempPayload(id: string, value: string) {
            self.tempPayloadMap.set(id, value);
        },

        changeExclude(id: string, value: boolean) {
            self.excludeMap.set(id, value);
        },
    }))
    .named("ImportSpendingsStore");

const sortStorage = getSortOption(ImportSpendingsStore.name);
export const initialState = (): ImportSpendingsStoreSnapshotType => {
    const options = sortStorage({ column: fields.dateAsDate, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        rows: [],
        rowTypeMap: {},
        payloadMap: {},
        tempPayloadMap: {},
        excludeMap: {},
        rowOutsourcedMap: [],
        rowOtherMap: [],
        rowSpendingMap: [],
        rowLoadingMap: [],
        hasMore: false,
        loader: 0,
        sorter: {
            id: ImportSpendingsStore.name,
            tableName: ImportSpendingsStore.name,
            column: options.column,
            asc: options.asc,
        },
    };
};

export type ImportSpendingsStoreType = typeof ImportSpendingsStore.Type;
export type ImportSpendingsStoreSnapshotType = typeof ImportSpendingsStore.SnapshotType;

interface CommitRowModel {
    type: string;
    id: string;
    payloadId: string;
    spending: string;
}

export const isRowValid = (type: string, payload: string | null) => {
    const pailoadIsValid = !!payload || Constants.nonPayloadBankInvoiceRowTypes.includes(type);
    const val = !!type && pailoadIsValid;

    return val;
};
