import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import {
    Department,
    DepartmentType,
    DepartmentSnapshotType,
    fields,
    initialState as emptyDepartment,
} from "./department";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
import { getSortOption, SortOptions } from "modules/common/services/table/sorting-storage";
import { Queryable } from "modules/common/models/queryable";

export const DepartmentList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            departments: types.array(Department),
            remover: Department,
            sorter: TableSorter,
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<DepartmentType> {
            return self.departments.reduce((result, department) => {
                result[department.id] = department;
                return result;
            }, {} as TStringMap<DepartmentType>);
        },

        get data() {
            const predicate = (o: DepartmentType) => {
                let result = true;
                if (self.pureQuery) {
                    result =
                        result && `${o.name} ${o.description} ${o.order}`.toLowerCase().indexOf(self.pureQuery) >= 0;
                }
                return result;
            };
            return self.departments.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));
            // return getSnapshot(self.departments);
        },

        get isEmpty() {
            return !self.departments.length;
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data = yield self.transport.get<DepartmentSnapshotType[]>(apiUrls.departments.list());
                applySnapshot(self.departments, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        delete: flow(function* (department: DepartmentSnapshotType) {
            applySnapshot(self.remover, department);
            return yield self.remover.delete();
        }),
    }))
    .named("DepartmentList");

export type DepartmentsListType = typeof DepartmentList.Type;
export type DepartmentListSnapshotType = typeof DepartmentList.SnapshotType;
export { Department };

const sortStorage = getSortOption(DepartmentList.name);

export const initialState = (sortOptions: SortOptions | null = null): DepartmentListSnapshotType => {
    const options = sortOptions || sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        departments: [],
        remover: emptyDepartment(),
        pureQuery: "",
        query: "",
        sorter: {
            id: DepartmentList.name,
            tableName: DepartmentList.name,
            column: options.column,
            asc: options.asc,
        },
    };
};
