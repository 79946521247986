import { types, applySnapshot, isAlive } from "mobx-state-tree";
import { BaseEntity, isNewlyCreated, DictionaryItem } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID, DATE_TIME_FORMAT } from "modules/common/constants";
import Schema from "../components/details/validation";
import moment from "moment";
import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";
import { getFieldLabel } from "modules/common/services/form/fields";

export const OrderIndicatorDictionaryItem = types
  .compose(
    DictionaryItem,
    types.model({
      description: types.string,
    })
  )
  .named("OrderIndicatorDictionaryItem");

export const OrderIndicatorBase = types.compose(
  Transport,
  Notificator,
  BaseEntity,
  types.model({
    label: types.string,
    idIndicator: types.string,
    description: types.string,
  })
);

export const OrderIndicatorMarkup = types
  .compose(
    Transport,
    Notificator,
    BaseEntity,
    types.model({
      label: types.string,
      description: types.string,
    })
  )
  .named("OrderIndicatorMarkup");

export type OrderIndicatorMarkupType = typeof OrderIndicatorMarkup.Type;
export type OrderIndicatorMarkupSnapshotType = typeof OrderIndicatorMarkup.SnapshotType;

export const OrderIndicator = OrderIndicatorBase.actions((self) => ({
  load: flow(function* (id: string) {
    try {
      const snapshot = isNewlyCreated(id)
        ? initialState()
        : yield self.transport.get<OrderIndicatorSnapshotType>(apiUrls.orderIndicators.details(id));

      applySnapshot(self, snapshot);
    } catch (er) {
      self.notify.error(er);
    }
  }),

  save: flow(function* (model: OrderIndicatorSnapshotType) {
    try {
      const snapshot = self.isNewlyCreated
        ? yield self.transport.put<OrderIndicatorSnapshotType>(apiUrls.orderIndicators.create(), model)
        : yield self.transport.post<OrderIndicatorSnapshotType>(apiUrls.orderIndicators.update(self.id), model);

      isAlive(self) && applySnapshot(self, snapshot);
      self.notify.success(texts.messages.saved);

      return true;
    } catch (er) {
      self.notify.error(er);

      return false;
    }
  }),

  delete: flow(function* () {
    if (self.isNewlyCreated) {
      return true;
    }

    try {
      yield self.transport.delete<boolean>(apiUrls.orderIndicators.delete(self.id));
      self.notify.success(texts.messages.removed);

      isAlive(self) && applySnapshot(self, initialState());
      return true;
    } catch (er) {
      self.notify.error(er);
      return false;
    }
  }),
})).named("OrderIndicator");

export type OrderIndicatorSnapshotType = typeof OrderIndicatorBase.SnapshotType;
export type OrderIndicatorType = typeof OrderIndicator.Type;
export type OrderIndicatorDictionaryItemType = typeof OrderIndicatorDictionaryItem.Type;

export const initialState = (): OrderIndicatorSnapshotType => ({
  id: EMPTY_OBJECT_ID,
  label: "",
  created: moment().format(DATE_TIME_FORMAT),
  idIndicator: "",
  description: "",
});

export const fields = {
  label: nameof((a: OrderIndicatorType) => a.label) as string,
  idIndicator: nameof((a: OrderIndicatorType) => a.idIndicator) as string,
};

export const fieldsList = {
  label: nameof((a: OrderIndicatorMarkupType) => a.label) as string,
  description: nameof((a: OrderIndicatorMarkupType) => a.description) as string,
};

export function formatOrderIndicator(type: OrderIndicatorSnapshotType | null) {
  let result = "";

  if (type) {
    const schema = Schema();

    result += `${getFieldLabel(fields.label, schema, null)}: ${type.label}\n`;
    result += `${getFieldLabel(fields.idIndicator, schema, null)}: ${type.idIndicator}\n`;
  }

  return result;
}
