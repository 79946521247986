import { nameof } from "modules/common/services/typescript";
import { object, string, number, date, boolean } from "yup";
import { Constants } from "modules/root/models/constants";
import { texts } from "modules/common/texts";

export interface Model {
    id: string;
    /** Дата */
    date: Date | null;
    /** Сумма */
    sum: number;
    /** Доход или расход */
    incoming: string;
    /** Вид расхода */
    type: string;
    /** Связанные данные */
    payloadId: string;
    /** Связанные данные */
    tempPayloadId: string;
    /** Номер платежа */
    name: string;
    /** Наличные */
    isCash: boolean;
    /** Документ (счет и тд) */
    document: { fileId: string; fileName: string } | null;
    /** Примечания */
    comment: string;
    /** Платеж в составе затрат */
    spending: string;
}

export const model = (): Model => ({
    id: "",
    date: new Date(),
    sum: 0,
    incoming: "",
    type: "",
    payloadId: "",
    tempPayloadId: "",
    name: "",
    isCash: false,
    comment: "",
    document: null,
    spending: "",
});

export const fields = {
    sum: nameof((s: Model) => s.sum) as string,
    date: nameof((s: Model) => s.date) as string,
    incoming: nameof((s: Model) => s.incoming) as string,
    type: nameof((s: Model) => s.type) as string,
    payload: nameof((s: Model) => s.payloadId) as string,
    temp: nameof((s: Model) => s.tempPayloadId) as string,
    isCash: nameof((s: Model) => s.isCash) as string,
    name: nameof((s: Model) => s.name) as string,
    comment: nameof((s: Model) => s.comment) as string,
    document: nameof((s: Model) => s.document) as string,
    spending: nameof((s: Model) => s.spending) as string,
};

export const spendingLabels = () => ({
    [fields.incoming]: "Доход или расход",
    [fields.type]: "Тип расхода",
    [fields.payload]: "Данные",
    [fields.temp]: "Данные",
    [fields.sum]: "Сумма",
    [fields.date]: "Дата",
    [fields.name]: "Номер платежа",
    [fields.isCash]: "Наличные",
    [fields.spending]: "Расход",
    [fields.document]: " ",
    [fields.comment]: texts.comment,
});

export const SpeningSchema = () => {
    const labels = spendingLabels();

    return object().shape({
        [fields.sum]: number()
            .label(labels[fields.sum])
            .transform((value) => (isNaN(value) ? undefined : value))
            .required()
            // eslint-disable-next-line no-template-curly-in-string
            .typeError("${path} должен быть числом")
            .min(0.01),

        [fields.incoming]: string().label(labels[fields.incoming]).required(),
        // [fields.spendingId]: string().label(labels[fields.spendingId]).required(),
        [fields.isCash]: boolean().label(labels[fields.isCash]),

        [fields.spending]: string()
            .label(labels[fields.spending])
            .when(fields.type, {
                is: (type) => type === Constants.bankInvoiceRowTypeIncomingCorrection,
                then: string().required(),
                otherwise: string().notRequired(),
            }),
        [fields.name]: string()
            .label(labels[fields.name])
            .when(fields.type, {
                is: (type) => type === Constants.bankInvoiceRowTypeIncoming,
                then: string().required(),
                otherwise: string().notRequired(),
            }),

        [fields.type]: string()
            .label(labels[fields.type])
            .when(fields.incoming, {
                is: (incoming) => incoming === "false",
                then: string().required(),
                otherwise: string().notRequired(),
            }),

        [fields.payload]: string().when(fields.incoming, {
            is: (incoming) => incoming === "false",
            then: string().when(fields.type, {
                is: (type) => payloadNotRequred(type),
                then: string().notRequired(),
                otherwise: string().required().label("значение"),
            }),
            otherwise: string().when(fields.type, {
                is: (type) => payloadNotRequred(type),
                then: string().notRequired(),
                otherwise: string().required().label("Объект"),
            }),
        }),

        [fields.temp]: string().when(fields.type, {
            is: (type) => payloadNotRequred(type),
            then: string().notRequired(),
            otherwise: string().required().label("значение"),
        }),

        [fields.date]: date().label(labels[fields.date]).required().typeError(texts.messages.incorrectDate()),

        [fields.comment]: string().label(labels[fields.comment]).notRequired(),
    });
};

export const overheadVisible = (type: string) => {
    return type === Constants.bankInvoiceRowTypeNonProduction;
};
export const payloadNotRequred = (type: string) => {
    return Constants.nonPayloadBankInvoiceRowTypes.includes(type);
};

export const productionVisible = (type: string) => {
    return type === Constants.bankInvoiceRowTypeProduction;
};
