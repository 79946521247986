import { DateRange } from "@blueprintjs/datetime";
import { saveAs } from "file-saver";
import { applySnapshot, getSnapshot, types } from "mobx-state-tree";
import { DATE_FORMAT, DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
import { AgentsDictionary, initialState as agentsState } from "modules/common/models/agents-dictionary";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Queryable } from "modules/common/models/queryable";
import { TableSorter } from "modules/common/models/table-sorter";
import { Transport } from "modules/common/models/transport";
import { buildCacheStorage } from "modules/common/services/cache";
import { apiUrls } from "modules/common/services/communication/urls";
import { base64ToBlob, printPdf } from "modules/common/services/files";
import { formatDate } from "modules/common/services/formatting/date";
import { toJsonHard } from "modules/common/services/mobx/serialize";
import { toString } from "modules/common/services/strings";
import { getSortOption, SortOptions } from "modules/common/services/table/sorting-storage";
import { texts } from "modules/common/texts";
import {
    initialState as typesState,
    OverheadTypeDictionary,
} from "modules/dictionaries/overhead-types/models/overhead-type-dictionary";
import {
    FinanceRequestDictionary,
    initialState as requestsState,
} from "modules/expenses/finance-requests/models/finance-requests-dictionary";
import { initialState as ordersState, OrderDictionary } from "modules/orders-manage/models/order-dictionary";
import moment from "moment";
import { OtherOrderSpendingType, OutsourcedOrderSpendingType } from "../../../orders-manage/models/order-spending";
import { fields, SpendingsListRow, SpendingsListRowSnapshotType, SpendingsListRowType } from "./spending-list-row";

export const cache = buildCacheStorage("SpendingsList");
const FILTERS_KEY = "filters";

const Filters = types
    .model({
        orderId: types.maybeNull(types.string),
        agentId: types.maybeNull(types.string),
        isIncoming: types.maybeNull(types.boolean),
        isProduction: types.maybeNull(types.boolean),
        start: types.maybeNull(types.Date),
        end: types.maybeNull(types.Date),
        from: types.maybeNull(types.string),
        to: types.maybeNull(types.string),
        queryString: types.maybeNull(types.string),
        spendingId: types.maybeNull(types.string),
    })
    .views((self) => ({
        get fromAsNumber() {
            const number = parseFloat(self.from || "");
            return isNaN(number) ? null : number;
        },

        get toAsNumber() {
            const number = parseFloat(self.to || "");
            return isNaN(number) ? null : number;
        },
    }))
    .views((self) => ({
        get range(): DateRange {
            return [self.start || null, self.end || null];
        },

        get dateRangeIsEmpty() {
            return !self.end || !self.start;
        },

        get sumRangeNotEmpty() {
            return self.fromAsNumber !== null || self.toAsNumber !== null;
        },
    }))
    .actions((self) => ({
        serialize(forRequest: boolean) {
            return {
                ...getSnapshot(self),
                start: forRequest ? formatDate(self.start) : null,
                end: forRequest ? formatDate(self.end) : null,
                to: self.toAsNumber,
                from: self.fromAsNumber,
            };
        },
    }));

export const SpendingsList = types
    .compose(
        Notificator,
        Transport,
        Queryable,
        types.model({
            message: types.string,
            rows: types.array(SpendingsListRow),
            orders: OrderDictionary,
            agents: AgentsDictionary,
            overheadTypes: OverheadTypeDictionary,
            requests: FinanceRequestDictionary,
            sorter: TableSorter,
            filters: Filters,
            loader: types.number,
            incomingSum: types.number,
            spendingSum: types.number,
        })
    )
    .views((self) => ({
        get searched() {
            if (self.pureQuery.length < 1) {
                return self.rows;
            }

            return self.rows.filter((r) => r.found(self.pureQuery));
        },
    }))
    .views((self) => ({
        get asMap(): TStringMap<SpendingsListRowType> {
            return self.rows.reduce((result, row) => {
                result[row.id] = row;
                return result;
            }, {} as TStringMap<SpendingsListRowType>);
        },

        get data() {
            return self.searched.map(toJsonHard);
        },

        get isEmpty() {
            return self.rows.length <= 1;
        },

        get isLoading() {
            return self.loader > 0;
        },
    }))
    .views((self) => ({
        get spendingSumm() {
            let sum = 0;
            self.data.forEach((item) => {
                sum += item.sum;
            });
            return sum;
        },
    }))
    .actions((self) => ({
        init() {
            self.orders.isEmpty && self.orders.load();
            self.agents.isEmpty && self.agents.load();
            self.overheadTypes.isEmpty && self.overheadTypes.load();
            self.requests.isEmpty && self.requests.load();
        },

        loadTable: flow(function* () {
            self.loader++;

            try {
                const request = self.filters.serialize(true);
                if (!self.filters.spendingId) {
                    cache.set(FILTERS_KEY, request);
                }

                const data: SpendingsColletion = yield self.transport.get<any>(apiUrls.spendings.list(), {
                    params: request,
                });
                self.incomingSum = data.incomingSum;

                self.spendingSum = data.spendingSum;
                self.message = data.message;
                applySnapshot(self.rows, data.rows);
                applySnapshot(self.filters, deserializeFilter(data.query));

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.loader--;
            }
        }),

        loadSpendings: flow(function* (id: string) {
            try {
                let data: SpendingData = yield self.transport.get<any>(apiUrls.spendings.orderSpendings(id));
                if (data) {
                }
                return data;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.loader--;
            }
        }),
    }))
    .actions((self) => ({
        removeRow: flow(function* (id: string, revert: boolean) {
            self.loader++;

            try {
                const message: string = yield self.transport.delete<any>(apiUrls.spendings.delete(id), {
                    data: { revert },
                });

                yield self.loadTable();
                self.requests.load();

                self.notify.success(message || texts.messages.removed);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.loader--;
            }
        }),

        saveRow: flow(function* (body: CommitRowModel) {
            self.loader++;

            try {
                body = toJsonHard(body);
                if (!body.sum) {
                    body.sum = 1;
                }

                if (body.id) {
                    yield self.transport.post<any>(apiUrls.spendings.update(body.id), body);
                } else {
                    yield self.transport.put<any>(apiUrls.spendings.create(), body);
                }

                self.notify.success(texts.messages.saved);
                yield self.requests.load();

                self.loadTable();

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.loader--;
            }
        }),

        export: flow(function* () {
            self.loader++;

            try {
                const file: FileDescription = yield self.transport.post<any>(apiUrls.spendings.export, {
                    ...self.filters.serialize(true),
                    asPdf: false,
                });

                const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
                saveAs(blob, file.name);
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.loader--;
            }
        }),

        print: flow(function* () {
            self.loader++;

            try {
                const file: FileDescription = yield self.transport.post<any>(apiUrls.spendings.export, {
                    ...self.filters.serialize(true),
                    asPdf: true,
                });
                const blob: any = yield base64ToBlob(file.content || "", file.mimeType);

                if (blob) {
                    const fileURL = URL.createObjectURL(blob);
                    const printer = printPdf(fileURL, true);
                    if (printer) {
                        printer.onclose = () => URL.revokeObjectURL(fileURL);
                    }
                }
            } finally {
                self.loader--;
            }
        }),
    }))
    .actions((self) => ({
        projectSelected(value: string | null) {
            self.filters.orderId = value;
            self.loadTable();
        },

        agentSelected(value: string | null) {
            self.filters.agentId = value;
            self.loadTable();
        },

        isIncomingSelected(value: string | null) {
            self.filters.isIncoming = value === null ? null : value === "true";
            self.loadTable();
        },

        isProductionSelected(value: string | null) {
            self.filters.isProduction = value === null ? null : value === "true";
            self.loadTable();
        },

        datesChanged(value: DateRange | null) {
            self.filters.start = value ? value[0] || null : null;
            self.filters.end = value ? value[1] || null : null;
            self.loadTable();
        },
        queryChanged(value: string) {
            let query = value === null ? "" : value;
            self.filters.queryString = query;
            self.loadTable();
        },
        fromChanged(value: string) {
            self.filters.from = value;
        },

        toChanged(value: string) {
            self.filters.to = value;
        },

        setupFilter(change: BatchFilterChange) {
            self.filters.orderId = null;
            self.filters.agentId = null;
            self.filters.isIncoming = change.isIncoming === null ? null : change.isIncoming === "true";
            self.filters.isProduction = change.isProduction === null ? null : change.isProduction === "true";
            self.filters.start = change.period ? change.period[0] || null : null;
            self.filters.end = change.period ? change.period[1] || null : null;
            self.filters.queryString = change.query ? change.query : null;
            self.filters.spendingId = change.spending ? change.spending : null;
            change.query && self.setQuery(change.query);
        },
    }))
    .named("SpendingsList");

export type FiltersType = typeof Filters.Type;
export type FiltersSnapshotType = typeof Filters.SnapshotType;
export type SpendingsListType = typeof SpendingsList.Type;
export type SpendingsListSnapshotType = typeof SpendingsList.SnapshotType;

export const deserializeFilter = (object: any) => ({
    ...object,
    start: object.start ? moment(object.start, DATE_FORMAT).toDate() : null,
    end: object.end ? moment(object.end, DATE_FORMAT).toDate() : null,
    from: toString(object.from),
    to: toString(object.to),
});

const sortStorage = getSortOption(SpendingsList.name);
export const cacheFilters = cache.get<typeof Filters.SnapshotType | null>(FILTERS_KEY, null);
export const initialState = (sortOptions: SortOptions | null = null): SpendingsListSnapshotType => {
    const options = sortOptions || sortStorage({ column: fields.sortableDate, asc: DEFAULT_SORTING_ASCENDING_VALUE });
    const filters = cacheFilters;
    const defaultFilters = {
        end: null,
        orderId: null,
        start: null,
        agentId: null,
        isIncoming: null,
        isProduction: null,
    };

    return {
        message: "",
        pureQuery: "",
        query: "",
        loader: 0,
        incomingSum: 0,
        spendingSum: 0,
        orders: ordersState(),
        agents: agentsState(),
        overheadTypes: typesState(),
        requests: requestsState(),
        rows: [],

        sorter: {
            id: SpendingsList.name,
            tableName: SpendingsList.name,
            column: options.column,
            asc: options.asc,
        },
        filters: filters ? { ...defaultFilters, ...deserializeFilter(filters) } : defaultFilters,
    };
};

export interface BatchFilterChange {
    isIncoming: string | null;
    isProduction: string | null;
    period: DateRange | null;
    query: string | null;
    spending: string | null;
}

interface SpendingsColletion {
    rows: SpendingsListRowSnapshotType[];
    query: typeof Filters.SnapshotType;
    message: string;
    incomingSum: number;
    spendingSum: number;
}

interface CommitRowModel {
    id: string;
    sum: number;
    date: string;
    type: string;
    payloadId: string;
    name: string;
    isCash: boolean;
    comment: string;
}

export interface SpendingData {
    outsourcedOrderSpendins: OutsourcedOrderSpendingType[];
    otherOrderSpendings: OtherOrderSpendingType[];
}
