import React from "react";
import { observer } from "mobx-react";
import { columnsBuilder, ContextActions } from "./columns";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { AgGridReact } from "@ag-grid-community/react";
import { FIGMA_HEADER_HEIGHT, defaultModules, DefaultAgGridOptions } from "modules/common/services/table/helpers";
import { agGridLocale } from "modules/root/services/locale";
import { GridReadyEvent, GridApi, RowDoubleClickedEvent } from "@ag-grid-community/core";
import styles from "./Table.module.scss";
import { SpendingsListType, BatchFilterChange } from "../models/spending-list";
import { SpendingsListRowType, SpendingsListRowSnapshotType } from "../models/spending-list-row";
import { Tag } from "@blueprintjs/core";
import { parse } from "query-string";
import { trimStart } from "modules/common/services/strings";
import { parseDate } from "modules/common/services/formatting/date";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
    END_SECTION,
    INCOMINTG_SECTION,
    PRODUCTION_SECTION,
    QUERY_SECTION,
    START_SECTION,
    SPENDING_SECTION,
} from "./filter-params";

export function SpendingsExternalParams(locationHash: string) {
    const hash = trimStart("#", locationHash);

    if (hash) {
        const metadata = parse(hash);
        const isIncoming = (metadata[INCOMINTG_SECTION] as string) || "";
        const isProduction = (metadata[PRODUCTION_SECTION] as string) || "";
        const start = (metadata[START_SECTION] as string) || "";
        const end = (metadata[END_SECTION] as string) || "";
        const query = (metadata[QUERY_SECTION] as string) || "";
        const spending = (metadata[SPENDING_SECTION] as string) || "";
        if (isIncoming && start && end) {
            const result: BatchFilterChange = {
                isIncoming,
                isProduction,
                period: [parseDate(start) || null, parseDate(end) || null],
                query,
                spending,
            };

            return result;
        }
    }

    return null;
}

class Base extends React.Component<TableProps & RouteComponentProps> implements ContextActions {
    private gridApi: GridApi | null = null;
    private columns = columnsBuilder(this.props.store.baseUrl);
    private options = DefaultAgGridOptions(true);

    constructor(props: any) {
        super(props);

        this.state = { wizard: null };
    }

    async componentDidMount() {
        const { store } = this.props;

        const needRedraw = !store.isEmpty;
        // await store.loadTable();

        if (needRedraw) {
            setTimeout(() => {
                this.gridApi && this.gridApi.redrawRows();
            }, 200);
        }
    }

    render() {
        const { store } = this.props;

        return (
            <GridTheme bordered={true} className={`designed ${styles.table}`}>
                {store.message && (
                    <Tag intent="warning" large={true}>
                        {store.message}
                    </Tag>
                )}
                <AgGridReact
                    rowStyle={{
                        borderBottom: "1px solid #EDF0F2",
                        fontFamily: "GothamPro, Arial, sans-serif",
                        color: "#00273D",
                        height: "28px",
                        fontSize: "12px",
                    }}
                    suppressRowClickSelection={true}
                    suppressCellSelection={true}
                    enableCellTextSelection={true}
                    columnDefs={this.columns}
                    rowData={store.data}
                    headerHeight={FIGMA_HEADER_HEIGHT}
                    minColWidth={5}
                    getRowNodeId={this.getRowNodeId}
                    localeText={agGridLocale}
                    context={this}
                    gridOptions={this.options}
                    immutableData={true}
                    modules={defaultModules}
                    onGridReady={this.onGridReady}
                    onSortChanged={store.sorter.resorted}
                    rowClassRules={this.rowClassRules}
                    onRowDoubleClicked={this.onRowDoubleClick}
                    rowBuffer={3000}
                />
            </GridTheme>
        );
    }

    getRowNodeId = (row: SpendingsListRowType) => row.id;

    onGridReady = async ({ api }: GridReadyEvent) => {
        this.gridApi = api;
        const { sorter } = this.props.store;
        setTimeout(() => api.setSortModel(sorter.agGridSortModel));
    };

    onRowDoubleClick = (event: RowDoubleClickedEvent) => {
        this.props.onSelect(event.data);
    };

    private rowClassRules = {
        incoming: (params: any) => {
            const data: SpendingsListRowType = params.data;
            return data.isIncoming;
        },
        nonproduction: (params: any) => {
            const data: SpendingsListRowType = params.data;
            return !data.isIncoming && !data.isProduction;
        },
        production: (params: any) => {
            const data: SpendingsListRowType = params.data;
            return data.isProduction;
        },
    };

    get store() {
        return this.props.store;
    }

    removeRow = async (row: SpendingsListRowSnapshotType, option?: string) => {
        await this.props.store.removeRow(row.id, !!option);
    };
}

export const SummaryTable = withRouter(observer(Base));

interface TableProps {
    store: SpendingsListType;
    onSelect: (row: SpendingsListRowSnapshotType) => void;
}
