import { ColDef, ICellRendererParams, ValueGetterParams } from "@ag-grid-community/core";
import { Popover } from "@blueprintjs/core";
import { FileLink } from "modules/common/components/files/FileLink";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { gridMoneyFormatterWithCurrency } from "modules/common/components/money/Money";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { DictionaryItemType } from "modules/common/models/entity";
import { routes } from "modules/common/routes";
import { eat, isNotNull } from "modules/common/services/typescript";
import { OrderTabs } from "modules/orders-manage/details/OrderDetailsTabsPage";
import { orderTitle, PARAM_RECORD, PARAM_SECTION } from "modules/orders-manage/models/order";
import { Constants } from "modules/root/models/constants";
import React, { useCallback, useState } from "react";
import { TabType } from "../models/finance-requests-store";
import {
    fields,
    FinanceRequestSnapshotType,
    FinanceRequestType,
    removeConfiramtionAction,
    removeConfiramtionName,
} from "../models/request";

function ImmediateInput(params: any) {
    const [immediate, setImmediate] = useState(params.data[fields.immediate]);
    const handler = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            const checked = e.currentTarget.title === "Срочно";
            const actions = params.context as RequestActions;
            const row = (params as ICellRendererParams).data as FinanceRequestType;

            setImmediate(checked);
            actions.onImmediateCange(row, checked);
        },
        [params]
    );

    return (
        <PlanrButton
            type="neutral"
            icon={immediate ? "general-checkbox" : "general-zero-checkbox"}
            title={!immediate ? "Срочно" : "Не срочно"}
            onClick={handler}
            style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
        />
    );
}

export const columnsBuilder = (type: TabType, outsourserLink: boolean): ColDef[] => {
    const result: (ColDef | null)[] = [
        {
            suppressMovable: true,
            headerName: "Объект",
            field: fields.order,
            width: 210,
            sortable: true,
            keyCreator: function (params) {
                return params.value.fullInventoryNumber;
            },
            valueGetter: function (params: ValueGetterParams) {
                const { data } = params;
                const { order } = data as FinanceRequestType;

                return order ? orderTitle(order) : "";
            },
            cellRendererFramework: (params: ICellRendererParams) => {
                const row = params.data as FinanceRequestType;
                const { order } = row;
                if (!order) {
                    return "";
                }

                const section = row.workType ? "outsource" : "other";

                let path = routes.orders.details(order.id, OrderTabs.spending);
                path += `&${PARAM_SECTION}=${section}`;
                path += `&${PARAM_RECORD}=${row.id}`;

                return (
                    <a href={path} target="_blank" rel="noopener noreferrer">
                        {params.value}
                    </a>
                );
            },
        },
        {
            suppressMovable: true,
            headerName: "Задание",
            field: fields.taskStatus,
            width: 85,
            sortable: true,
        },
        {
            suppressMovable: true,
            headerName: "Виды работ",
            field: fields.workTypeName,
            width: 190,
            sortable: true,
        },
        {
            headerName: "Дата",
            field: fields.date,
            width: 150,
            sortable: true,
            sort: "asc",
            comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                const dateA = (nodeA.data as FinanceRequestSnapshotType).sortableDate;
                const dateB = (nodeB.data as FinanceRequestSnapshotType).sortableDate;
                return dateA.localeCompare(dateB);
            },
        },
        {
            suppressMovable: true,
            headerName: "Сумма",
            field: fields.sum,
            width: 150,
            sortable: true,
            valueFormatter: gridMoneyFormatterWithCurrency,
        },
        {
            suppressMovable: true,
            headerName: "Контрагент",
            field: fields.outsourcer,
            flex: 1,
            sortable: true,

            comparator: (valueA, valueB) => {
                const outsourcerA = outsourcerName(valueA);
                const outsourcerB = outsourcerName(valueB);

                return outsourcerA.localeCompare(outsourcerB);
            },
            cellRendererFramework: (params: ICellRendererParams) => {
                const { data } = params;
                const { outsourcer } = data as FinanceRequestType;
                const { supplier } = data;
                if (!outsourcer && !supplier) {
                    return "";
                }
                if (outsourcer && !supplier) {
                    const url = routes.agents.outsourcers.details.outsourcers(outsourcer.id);
                    const name = outsourcerName(outsourcer);

                    return outsourserLink ? (
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            {name}
                        </a>
                    ) : (
                        name
                    );
                }

                if (!outsourcer && supplier) {
                    const url = routes.agents.suppliers.details(supplier.id);
                    if (supplier.label) {
                        return outsourserLink && supplier.id && supplier.id !== EMPTY_OBJECT_ID ? (
                            <a href={url} target="_blank" rel="noopener noreferrer">
                                {supplier.label}
                            </a>
                        ) : (
                            supplier.label
                        );
                    } else {
                        return null;
                    }
                }
            },
        },
        {
            suppressMovable: true,
            headerName: "Счет",
            field: fields.invoice,
            width: 60,
            sortable: true,
            headerClass: "centered",
            cellClass: "centered",
            cellRendererFramework: RenderInvoice,
        },
        {
            suppressMovable: true,
            headerName: "ГИП / Сотрудник",
            field: fields.orderGipName,
            width: 200,
            sortable: true,
        },
        type === "new"
            ? {
                  suppressMovable: true,
                  headerName: "Срч",
                  field: fields.immediate,
                  width: 70,
                  cellClass: "centered",
                  headerClass: "centered",
                  cellRendererFramework: ImmediateInput,
              }
            : null,
        type === "accepted"
            ? {
                  suppressMovable: true,
                  headerName: "Срч",
                  field: fields.immediate,
                  width: 70,
                  cellClass: "centered",
                  headerClass: "centered",
                  cellRendererFramework: (params: any) => {
                      const immediate: boolean = params.value;

                      return immediate ? (
                          <span title="Срочно">
                              <GeneralIcon type={"general-exclamation"} style={{ color: "#EA561E" }} />
                          </span>
                      ) : null;
                  },
              }
            : null,
        type !== "paid"
            ? {
                  suppressMovable: true,
                  headerName: "",
                  field: "*",
                  width: 100,
                  cellClass: "centered flat",
                  headerClass: "no-print no-print",
                  cellRendererFramework: (params: ICellRendererParams) => {
                      const actions = params.context as RequestActions;
                      const row = params.data as FinanceRequestType;

                      return row.status === Constants.orderPaymentStatusNew ? (
                          <PlanrButton
                              type="greenish"
                              onClick={(e: React.MouseEvent) => {
                                  e.stopPropagation();
                                  actions.accept(row);
                              }}
                              style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                          >
                              Одобрить
                          </PlanrButton>
                      ) : null;
                  },
              }
            : null,
        {
            suppressMovable: true,
            headerName: "",
            field: "**",
            width: 100,
            cellClass: "flat no-print",
            headerClass: "no-print",
            cellRendererFramework: (params: any) => {
                const actions = params.context as RequestActions;
                const row = (params as ICellRendererParams).data as FinanceRequestType;

                return (
                    <RemoveConfirmation<FinanceRequestType>
                        what={removeConfiramtionName}
                        actionName={removeConfiramtionAction(row)}
                        onConfirmed={actions.cancel}
                        render={({ confirmRemoving }) => {
                            return (
                                <PlanrButton
                                    type="primary"
                                    onClick={() => confirmRemoving(row)}
                                    style={{
                                        height: "20px",
                                        fontSize: "10px",
                                        backgroundColor: "red",
                                        padding: "0px 7px",
                                    }}
                                >
                                    Отклонить
                                </PlanrButton>
                            );
                        }}
                    />
                );
            },
        },
    ];

    return result.filter(isNotNull);
};

function outsourcerName(outsourcer: DictionaryItemType | null) {
    return outsourcer ? outsourcer.label : "";
}

export interface InvoiceActions {
    baseUrl: () => string;
}

export interface RequestActions extends InvoiceActions {
    accept: (request: FinanceRequestSnapshotType) => void;
    cancel: (request: FinanceRequestSnapshotType) => void;
    onImmediateCange: (request: FinanceRequestSnapshotType, value: boolean) => void;
}

export const RenderInvoice = (params: ICellRendererParams) => {
    const { invoice } = params.data as FinanceRequestType;
    const actions = params.context as InvoiceActions;

    // const srcs = invoice
    //     ? invoice.map(
    //           (file: any) => actions.baseUrl() + apiUrls.application.files.content(file.fileId, file.fileName ?? "Счет")
    //       )
    //     : "";

    return invoice && invoice.length > 0 ? (
        <Popover
            interactionKind="click"
            content={
                <div style={{ padding: "5px", width: "270px" }}>
                    {invoice.map((file) => (
                        <FileLink
                            key={file.fileId}
                            baseUrl={actions.baseUrl()}
                            file={{ fileId: file.fileId, fileName: file.fileName ? file.fileName : "" }}
                            style={{ maxWidth: "none", height: "25px" }}
                            readOnly={true}
                            onRemove={eat}
                        />
                    ))}
                </div>
            }
        >
            <GeneralIcon type={"general-import"} style={{ cursor: "pointer", color: "#279fda" }} />
        </Popover>
    ) : null;
};
