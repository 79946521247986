import React from "react";
import { TimesheetStoreType, DepartmentBlockMode } from "modules/spending/timesheet/models/timesheet-store";
import { observer } from "mobx-react";
import { DepartmentBlock } from "./DepartmentBlock";
import styles from "./TimesheetPage.module.scss";
import { PeriodSlider } from "modules/common/components/period/PeriodSlider";
import { SessionType } from "modules/session/auth/models/session";
import { TimesheetCellMode } from "./TimesheetCell";
import { ItemRenderer } from "@blueprintjs/select";
import { MenuItem, Button } from "@blueprintjs/core";
import { texts } from "modules/common/texts";
import { Zoomer } from "modules/common/components/zoomer/Zoomer";
import { can, canAny } from "modules/session/auth/access";
import func from "modules/spending/functionalities";
import { NoData } from "modules/common/components/table/NoData";
import { ScheduleStoreType } from "modules/spending/schedule/models/schedule-store";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { menuItems } from "modules/root/components/menu/MainMenu";
import { WorkDays } from "modules/spending/components/WorkDays";
import { SelectFactory } from "modules/common/services/form/select";
import { Search } from "modules/common/components/form/Search";
import { CollapseExpandAll } from "modules/common/components/table/CollapseExpandAll";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { MonthHeader } from "./MonthHeader";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { WorkloadDayType } from "modules/spending/workload/models/workload-day";
import { ContextMenu } from "modules/spending/workload/components/ContextMenu";

const menu = menuItems();

interface CellModeOption {
    value: TimesheetCellMode;
    label: string;
}

const cellModeOptions: CellModeOption[] = [
    { value: "hours", label: "Явка" },
    { value: "projects", label: "Проекты" },
];
const CellModeSelect = SelectFactory<CellModeOption>();

class Page extends React.Component<TimesheetPageProps, TimesheetPageState> {
    printable = React.createRef<any>();
    private day: any | null = null;
    constructor(props: any) {
        super(props);

        this.state = {
            mode: "day",
            selectedCell: "",
            selectedCells: [],
            monthModeIsEditing: false,
            copyItem: {},
            showContext: false,
            x: 0,
            y: 0,
        };
    }

    componentDidMount() {
        document.title = menu.spending.nested.timesheet.label;

        const { store } = this.props;
        store.load();
    }
    selectCell = (selectedCell: string) => {
        this.setState({ selectedCell: selectedCell });
    };

    selectCells = (selectedCells: string[]) => {
        this.setState({ selectedCells });
    };
    removeDay = () => {
        this.setState({ showContext: false });

        let item = this.state.copyItem;

        item.day = this.state.selectedCell.slice(0, 10);
        item.employerId = this.state.selectedCell.slice(11, this.state.selectedCell.length);

        item.units = [];

        this.saveDay(item);
    };

    render() {
        const showContext = (x: number, y: number, day: WorkloadDayType) => {
            this.day = day;
            this.setState({ x, y, showContext: true });
        };
        const { session, store, setUserFilter } = this.props;
        const { year, month, workDaysCount, monthsWorkHours, orders, departmentMap, cellMode, isLoading, tasks } =
            store;
        const { selectedCell, mode, monthModeIsEditing, selectedCells } = this.state;

        const cellModeOption = cellModeOptions.find((o) => o.value === cellMode);

        const canManage = canAny(
            [func.TIMESHEET_EDIT, func.TIMESHEET_EDIT_DEPARTMENT, func.TIMESHEET_EDIT_USER],
            session.access
        );
        const canExport = can(func.TIMESHEET_EXPORT, session.access);
        const canComment = can(func.TIMESHEET_COMMENTS, session.access);

        return (
            <div
                className={styles.page}
                ref={this.printable}
                onClick={(e) => {
                    this.setState({ showContext: false });
                }}
            >
                <LocalLoader active={isLoading} />
                {this.state.showContext && (
                    <ContextMenu
                        xpos={`${this.state.x / 2}px`}
                        ypos={`${this.state.y}px`}
                        onCopy={() => {
                            const model = {
                                day: this.day.day,
                                employerId: this.day.user.id,
                                comment: this.day.comment,
                                units: this.day.units.map((unit: any) => {
                                    return {
                                        comment: unit.comment,
                                        hours: unit.hours,
                                        inventoryNumber: unit.order ? unit.order.inventoryNumber : null,
                                        workId: unit.task ? unit.task.workId : null,
                                        type: unit.type,
                                        fromHome: unit.type === "Работа из дома" ? true : false,
                                        isWorkedOut: unit.isWorkedOut,
                                    };
                                }),
                            };

                            this.setState({ copyItem: model, showContext: false });
                        }}
                        onPaste={() => {
                            this.setState({ showContext: false });
                            let item = this.state.copyItem;

                            if (item.units) {
                                item.day = this.day.day;
                                item.employerId = this.day.user.id;
                                this.saveDay(item);
                            }
                        }}
                        //  onClear={this.removeDay}
                    />
                )}
                <div className={styles.filters}>
                    <div className={styles.periodSlider}>
                        <div>
                            <PeriodSlider year={year} month={month} onChange={this.gotoMonth} />
                            <div className={styles.center}>
                                <WorkDays days={workDaysCount} hours={monthsWorkHours} users={store.users.length} />
                            </div>
                        </div>
                        <div className={styles.actions}>
                            <div className={styles.right}>
                                {!monthModeIsEditing && (
                                    <>
                                        {canExport && (
                                            <>
                                                <PlanrButton
                                                    type="graybtn"
                                                    icon="general-export"
                                                    onClick={this.export}
                                                    round={true}
                                                    title={texts.export}
                                                />
                                                <PlanrButton
                                                    type="graybtn"
                                                    icon="general-print"
                                                    onClick={this.print}
                                                    round={true}
                                                    title={texts.print}
                                                />
                                            </>
                                        )}

                                        <Search query={store.query} onSearch={setUserFilter} />
                                    </>
                                )}
                            </div>
                            <Zoomer zoom={store.zoom} min={50} max={100} step={5} onChange={store.setZoom} />
                            <CollapseExpandAll onChange={store.collapser.setAll} />
                        </div>
                    </div>

                    <div className={styles.tools}>
                        <div className={styles.left}>
                            <div className={styles.override}>
                                <PlanrButton
                                    type={mode === "day" ? "secondary" : "graybtn"}
                                    icon="general-calendar-day"
                                    onClick={this.setMode}
                                    round={true}
                                    title="Посмотреть каждый день"
                                />
                                <PlanrButton
                                    type={mode === "month" ? "secondary" : "graybtn"}
                                    icon="general-calendar"
                                    onClick={this.setMode}
                                    round={true}
                                    title="Посмотреть за месяц"
                                />

                                {mode === "day" && !store.isEmpty && (
                                    <div className={styles.cellModeCelector}>
                                        <CellModeSelect
                                            className={"planr-round-select"}
                                            filterable={false}
                                            activeItem={cellModeOption}
                                            items={cellModeOptions}
                                            itemRenderer={renderCellOption}
                                            onItemSelect={this.setCellMode}
                                        >
                                            {renderSelctedCellOption(cellModeOption)}
                                        </CellModeSelect>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.zoomArea} style={{ transform: `scale(${store.transform})` }}>
                    {mode === "month" && <MonthHeader />}
                    {Object.keys(departmentMap).map((department) => {
                        const users = departmentMap[department];
                        return (
                            <DepartmentBlock
                                sessionId={session.userId}
                                mode={mode}
                                cellMode={cellMode}
                                department={department}
                                users={users}
                                tasks={tasks}
                                orders={orders}
                                selectedCell={selectedCell}
                                onSelectCell={this.selectCell}
                                onSelectCells={this.selectCells}
                                key={department}
                                selectedCells={selectedCells}
                                onSave={this.saveDay}
                                canManage={canManage}
                                userLogin={session.userLogin}
                                canEdit={can(func.SCHEDULE_EDIT, session.access)}
                                onEditStateChanged={this.setMonthModeEditingState}
                                collapsed={!store.collapser.opened.get(department)}
                                toggleCollapse={store.collapser.toggle}
                                canComment={canComment}
                                changeComment={(model: any) => {
                                    store.setComment(model);
                                }}
                                showMinutes={store.showMinutes}
                                onContext={showContext}
                            />
                        );
                    })}
                </div>

                {store.isEmpty && !isLoading && <NoData className={styles.nodata} />}
            </div>
        );
    }

    gotoMonth = (year: number, month: number) => {
        this.props.store.load(year, month);
        this.props.schedule.setPeriod(year, month);
    };

    // selectCell = (selectedCell: string) => this.setState({ selectedCell });

    // saveDay = (model: any) => {
    //     return this.props.store.save(model);
    // };
    saveDay = (model: any) => {
        const index = this.state.selectedCells.findIndex((cell) => cell.slice(0, 10) === model.day);
        let success: any = false;

        let arr: any = [];
        if (index !== -1) {
            this.state.selectedCells.forEach((cell) => {
                const item = {
                    day: cell.slice(0, 10),
                    employerId: model.employerId,
                    units: model.units,
                    comment: model.comment,
                };
                arr.push(item);
            });

            success = this.props.store.saveArrayOfCells(arr);
        } else {
            success = this.props.store.save(model);
        }
        return success;
    };

    setMode = (e: React.MouseEvent<HTMLElement>) => {
        let currentmode = "day";
        if (e.currentTarget.title === "Посмотреть каждый день") {
            currentmode = "day";
        } else if (e.currentTarget.title === "Посмотреть за месяц") {
            currentmode = "month";
        }
        const mode: DepartmentBlockMode = currentmode as any;
        this.setState({ mode });
    };

    setCellMode = (mode: CellModeOption) => this.props.store.setCellMode(mode.value);

    print = () => this.props.store.print(this.state.mode);

    export = () => this.props.store.export(this.state.mode);

    setMonthModeEditingState = (state: boolean) => this.setState({ monthModeIsEditing: state });
}

export const TimesheetPage = observer(Page);

interface TimesheetPageProps {
    store: TimesheetStoreType;
    schedule: ScheduleStoreType;
    session: SessionType;
    setUserFilter: (value: string) => void;
}

interface TimesheetPageState {
    selectedCell: string;
    mode: DepartmentBlockMode;
    selectedCells: string[];
    monthModeIsEditing: boolean;
    copyItem: any;
    showContext: boolean;
    x: number;
    y: number;
}

const renderCellOption: ItemRenderer<CellModeOption> = (item, { handleClick, modifiers }) => {
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={item.value}
            onClick={handleClick}
            text={item.label}
        />
    );
};

const renderSelctedCellOption = (option: CellModeOption | null | undefined) => (
    <Button
        fill={true}
        rightIcon={<GeneralIcon type="general-chevron-down" />}
        className="selected-option"
        text={option ? option.label || texts.undefined : texts.undefined}
    />
);
