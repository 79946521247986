import styles from "./UnitUserRow.module.scss";
import React from "react";
import { observer } from "mobx-react";
import { EmployerName } from "modules/common/components/employer/EmployerName";
import { EmployerDictionaryItemSnapshotType } from "modules/spending/employee/models/employee-dictionary";

class Row extends React.Component<UnitUserRowProps, UnitUserRowState> {
    constructor(props: any) {
        super(props);

        this.state = { selected: false };
    }

    div = React.createRef<HTMLDivElement>();
    submit = React.createRef<HTMLButtonElement>();

    render() {
        const { user } = this.props;

        return (
            <div className={`unit-user-row ${styles.row}`}>
                <div className="unit-user-name">
                    {user ? <EmployerName name={user.name} position={user.position} /> : null}
                </div>
            </div>
        );
    }
}

export const UnitUserRow = observer(Row);

interface UnitUserRowProps {
    user: EmployerDictionaryItemSnapshotType;
}

interface UnitUserRowState {
    selected: boolean;
}
