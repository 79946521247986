import React, { FC } from "react";
import { formatMoney, Money } from "modules/common/components/money/Money";
import styles from "./OwnTripSpendings.module.scss";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { formatHours } from "modules/spending/timesheet/models/user-timesheet";
import { OrderTimesheetSpendingRowSnapshotType } from "../../../models/order-spending";
import { OrderTimesheetSpendingType, TripOrderSpendingType } from "modules/orders-manage/models/order-spending";
import { Icon, Classes, Spinner } from "@blueprintjs/core";
import { MoneyInput } from "modules/common/components/money/MoneyInput";
import { NumberFormatValues } from "react-number-format";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { preventSubmitKeyDown } from "modules/common/services/form/values";
import { Constants } from "modules/root/models/constants";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { formatDate } from "modules/common/services/formatting/date";
import { LARGE_ICON_AS_BUTTON_SIZE } from "modules/common/constants";
import { InvoiceFileSnapshotType } from "modules/orders-manage/models/order-file";
import { GeneralIcons } from "modules/common/components/planr/icon/Generalcon";
import { FileUploaderHOC } from "modules/common/components/files/Uploader";
import { texts } from "modules/common/texts";
import {
  outsourcerPaymentFields,
  OutsourcerPaymentSnapshotType,
} from "modules/agents/outsourcers/models/outsourcer-payment";
import { findIndex } from "lodash";
import { UploaderFatory } from "modules/orders-manage/types";
import { PLAN_PAYMENTS_BLOCK_NAME } from "../outsourced/print-view-model";
import { PaymentStatusToggler, OutsourcerPaymentFactory } from "../outsourced/OutsourcedSpendingRow";
import { FieldProps } from "formik";

const OwnTripSpendingEmployer: FC<OwnTripSpendingEmployerProps> = ({
  spending,
  spendings,
  guid,
  tripSpendings,
  formDirty,
  baseUrl,
  toggleStatus,
  addSpending,
  readOnly,
  upload,
  fieldProps,
  onPaymentChange,
  onRemovePayment,
}) => {
  const addSpendingg = (e: React.MouseEvent) => {
    e.stopPropagation();
    addSpending();
  };

  const trip = tripSpendings
    ? tripSpendings.find((tr) => tr.contentGuid === guid && tr.employerId === spending.employer.id)
    : null;

  return (
    <div className={styles.row} key={spending.employer.id}>
      <div className={styles.rowLeft}>
        <div className={styles.mainHeader}>Командировки</div>
        <div className={`${styles.secondHeader} ${styles.leftTable}`}>
          <div className={styles.employ}>Сотрудник:</div>
          <div className={styles.hours}>Часы:</div>
          <div className={styles.money}>Затраты:</div>
        </div>
        <div className={`${styles.leftTable}`}>
          <div className={styles.employ}>{spending.employer.name}</div>
          <div className={styles.hours}>
            {formatHours(spendings.showMinutes ? spending.hoursForTrip : Math.round(spending.hoursForTrip))}
          </div>
          <div className={styles.money}>{formatMoney(spending.spendingTrip)}</div>
        </div>
      </div>
      <div className={styles.spendings}>
        <div className={styles.mainHeader}>Командировочные</div>
        {trip && (
          <div className={styles.plan}>
            <div className={`${styles.secondHeader} ${styles.leftTable}`}>
              <div className={styles.planSum}>Сумма:</div>
              <div className={styles.planDate}>Дата:</div>
            </div>
            <table className={styles.table}>
              <tbody>
                {trip.planPayments.map((payment: any) => (
                  <PlanPaymentRow
                    key={payment.guid}
                    onChange={(field, text) => {
                      onPaymentChange(trip.id, payment, field, text);
                    }}
                    onRemove={() => {
                      onRemovePayment(trip.id, payment);
                    }}
                    payment={payment}
                    readOnly={readOnly}
                    toggleStatus={toggleStatus}
                    formDirty={formDirty}
                    upload={upload}
                    baseUrl={baseUrl}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
        <PlanrButton type="secondary" size="small" onClick={addSpendingg}>
          Добавить оплату
        </PlanrButton>
      </div>
    </div>
  );
};

interface OwnTripSpendingEmployerProps {
  spending: OrderTimesheetSpendingRowSnapshotType;
  spendings: OrderTimesheetSpendingType;
  onPaymentChange: (id: string, payment: OutsourcerPaymentSnapshotType, field: string, text: any) => void;
  onRemovePayment: (id: string, payment: OutsourcerPaymentSnapshotType) => void;
  tripSpendings: TripOrderSpendingType[];
  formDirty: boolean;
  baseUrl: string;
  upload: (file: File) => Promise<FileBase | null>;
  readOnly: boolean;
  toggleStatus?: (guid: string) => void;
  addSpending: () => void;
  guid: string;
  fieldProps: FieldProps;
}

interface PlanPaymentsProps extends PaymentStatusToggler, UploaderFatory {
  value: OutsourcerPaymentSnapshotType[];
  factory: OutsourcerPaymentFactory;
  title?: string;
  onChange: (value: OutsourcerPaymentSnapshotType[]) => void;
  readOnly?: boolean;
  baseUrl: string;
}

export class PlanPayments extends React.Component<PlanPaymentsProps> {
  render() {
    const { value, title, readOnly, formDirty, toggleStatus, upload, baseUrl } = this.props;
    return (
      <div className={styles.plan}>
        <div className={styles.paymentsName}>{title || PLAN_PAYMENTS_BLOCK_NAME}</div>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.headerRow}>
              <td className={styles.sumTop}>Сумма:</td>
              <td className={styles.dateTop}>Дата:</td>
              {/* <td>&nbsp;</td>
                            <td>&nbsp;</td> */}
            </tr>
            {value.map((payment) => (
              <PlanPaymentRow
                key={payment.guid}
                onChange={(field, text) => {
                  this.onPaymentChange(payment, field, text);
                }}
                onRemove={this.removePayment}
                payment={payment}
                readOnly={readOnly}
                toggleStatus={toggleStatus}
                formDirty={formDirty}
                upload={upload}
                baseUrl={baseUrl}
              />
            ))}
          </tbody>
        </table>
        {!readOnly && (
          <PlanrButton icon="general-plus-big" type="dashed" onClick={this.addPayment} style={{ width: "476px" }}>
            Добавить оплату
          </PlanrButton>
        )}
      </div>
    );
  }

  onPaymentChange = (payment: OutsourcerPaymentSnapshotType, field: string, text: any) => {
    const { value, onChange } = this.props;
    const index = value.indexOf(payment);
    const newValue = [...value.slice(0, index), { ...payment, [field]: text }, ...value.slice(index + 1)];
    onChange(newValue);
  };

  addPayment = async () => {
    const { onChange, value } = this.props;
    const payment = await this.props.factory.emptyOutsourcerPayment(value.length + 1);
    const newValue = [...value, payment];

    onChange(newValue);
  };

  removePayment = (guid: string) => {
    const { onChange, value } = this.props;
    const index = findIndex(value, (o) => o.guid === guid);

    if (index >= 0) {
      const newValue = [...value.slice(0, index), ...value.slice(index + 1)];
      onChange(newValue);
    }
  };
}

interface PlanPaymentRowProps {
  payment: TStringMap<any>;
  readOnly?: boolean;
  onChange: (field: string, value: any) => void;
  onRemove: (guid: string) => void;
  baseUrl: string;
  formDirty: boolean;
  toggleStatus?: (guid: string) => void;
  upload: (file: File) => Promise<FileBase | null>;
}

interface PlanPaymentRowState {
  loading: boolean;
}

export class PlanPaymentRow extends React.PureComponent<PlanPaymentRowProps, PlanPaymentRowState> {
  constructor(props: PlanPaymentRowProps) {
    super(props);

    this.state = {
      loading: false,
    };
  }
  render() {
    const { readOnly, payment } = this.props;

    return (
      <>
        <tr>
          <td className={styles.sum}>
            {!readOnly && (
              <MoneyInput
                className="planr-default-input"
                value={payment[outsourcerPaymentFields.sum]}
                onMoneyChange={this.onSumChanged}
                autoComplete="off"
                data-lpignore="true"
                onKeyDown={preventSubmitKeyDown}
                small={true}
              />
            )}
            {readOnly && (
              <div className="centered">
                <Money amount={payment[outsourcerPaymentFields.sum]} noFraction={true} />
              </div>
            )}
          </td>
          <td className={styles.date}>
            {!readOnly && (
              <div className={`${Classes.INPUT_GROUP} `}>
                <DatePicker
                  clasName="planr-default-input"
                  value={payment[outsourcerPaymentFields.date]}
                  onChange={this.onDateChanged}
                  small={true}
                />
              </div>
            )}
            {readOnly && <div className="centered">{formatDate(payment[outsourcerPaymentFields.date])}</div>}
          </td>

          <td className="centered">{this.statusButton()}</td>
          <td className="centered">
            {!readOnly && !payment[outsourcerPaymentFields.invoice] && !this.state.loading && (
              <FileUploaderHOC
                accept={"*"}
                onFileSelected={this.uploadInvoiceFile}
                render={({ onClick }) => (
                  <PlanrButton
                    type="whiteish"
                    icon="general-attach"
                    size="small"
                    onClick={onClick}
                    title="Прикрепить счет"
                  />
                )}
              />
            )}
            {!readOnly && !payment[outsourcerPaymentFields.invoice] && this.state.loading && (
              <div className="uploader-icon">{<Spinner intent="primary" size={16} />}</div>
            )}
            {payment[outsourcerPaymentFields.invoice] && (
              <PlanrButton
                type="whiteish"
                icon="general-un-attach"
                size="small"
                style={{ color: "#1DD278" }}
                title="Открепить счет"
                onClick={this.onInvoiceRemove}
              />
            )}
          </td>
          <td className="centered">
            {!readOnly && (
              <RemoveConfirmation
                onConfirmed={this.onRemove}
                what={() => "оплату"}
                render={({ confirmRemoving }) => {
                  return (
                    <PlanrButton
                      type="neutral"
                      icon="general-trash"
                      size="small"
                      onClick={confirmRemoving}
                      style={{ color: "#E31818" }}
                      title={texts.remove}
                    />
                  );
                }}
              />
            )}
            {payment[outsourcerPaymentFields.automatic] && (
              <Icon icon="lock" htmlTitle="Создано автоматически" iconSize={LARGE_ICON_AS_BUTTON_SIZE} />
            )}
          </td>
        </tr>
      </>
    );
  }

  uploadInvoiceFile = async (file: File) => {
    this.setState({ loading: true });
    const meta = await this.props.upload(file);

    if (meta) {
      const data: InvoiceFileSnapshotType = {
        ...meta,
      };

      this.props.onChange(outsourcerPaymentFields.invoice, data);
    }
    this.setState({ loading: false });
  };

  onInvoiceRemove = () => {
    this.props.onChange(outsourcerPaymentFields.invoice, null);
  };

  onSumChanged = (money: NumberFormatValues) => this.props.onChange(outsourcerPaymentFields.sum, money.value);

  onDateChanged = (date: Date | null) => this.props.onChange(outsourcerPaymentFields.date, date);

  statusIcon = (status: string): GeneralIcons => {
    switch (status) {
      case Constants.orderPaymentStatusNew:
        return "general-question";
      case Constants.orderPaymentStatusAccepted:
        return "general-like";
      case Constants.orderPaymentStatusPaid:
        return "general-check";
      default:
        return "general-credit-card";
    }
  };

  statusButton = () => {
    const { payment, formDirty, readOnly } = this.props;
    const status: string = payment[outsourcerPaymentFields.status];
    const icon = this.statusIcon(status);

    const canBeToggled = ["", Constants.orderPaymentStatusCancelled];

    if (readOnly || formDirty || !canBeToggled.includes(status)) {
      return (
        <PlanrButton
          type="neutral"
          icon={icon}
          title={status}
          size="small"
          style={status === Constants.orderPaymentStatusPaid ? { color: "#1DD278" } : { color: "rgba(0, 39, 61, 0.3)" }}
        />
      );
    }

    if (canBeToggled.includes(status)) {
      return (
        <PlanrButton
          type="neutral"
          size="small"
          icon={icon}
          onClick={this.toggleStatus}
          title={status || "Отправить запрос на оплату"}
        />
      );
    }

    return null;
  };

  toggleStatus = () => {
    const { payment, toggleStatus } = this.props;
    const id = payment[outsourcerPaymentFields.guid];

    toggleStatus && toggleStatus(id);
  };

  onRemove = () => this.props.onRemove(this.props.payment[outsourcerPaymentFields.guid]);
}

export default OwnTripSpendingEmployer;
