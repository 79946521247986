import React from "react";
import { Page } from "modules/common/components/page/Page";
import { Tabs, Tab } from "@blueprintjs/core";
import styles from "./ReportTabsPage.module.scss";
import { menuItems } from "modules/root/components/menu/MainMenu";
import { routes } from "modules/common/routes";
import { RouteChildrenProps } from "react-router";

const menu = menuItems();

export default class ReportPage extends React.Component<ReportTabsPageProps & RouteChildrenProps> {
    render() {
        const { location, reportsPanel, orderReportsPanel } = this.props;
        const tabId = getTabId(location.pathname);
        return (
            <Page>
                <Tabs
                    className={`${styles.tabs} planr-tabs-navigation`}
                    id="ReportPage"
                    selectedTabId={tabId}
                    renderActiveTabPanelOnly={true}
                    onChange={this.handleTabChange}
                >
                    {reportsPanel && (
                        <Tab
                            className="timesheet"
                            id={routes.reports.employerSpending}
                            title={menu.reports.nested.employerSpending.label}
                            panel={reportsPanel}
                        />
                    )}
                    {orderReportsPanel && (
                        <Tab
                            className="timesheet"
                            id={routes.reports.orderYear}
                            title={menu.reports.nested.orderYear.label}
                            panel={orderReportsPanel}
                        />
                    )}
                </Tabs>
            </Page>
        );
    }

    handleTabChange = (newTabId: string) => this.props.history.push(newTabId);
}

const getTabId = (pathname: string) => {
    if (pathname.includes(routes.reports.employerSpending)) {
        return routes.reports.employerSpending;
    }

    if (pathname.includes(routes.reports.orderYear)) {
        return routes.reports.orderYear;
    }

    return "";
};

interface ReportTabsPageProps {
    reportsPanel: JSX.Element | null;
    orderReportsPanel: JSX.Element | null;
}
