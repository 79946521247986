import { types, applySnapshot, isAlive } from "mobx-state-tree";
import { BaseEntity, isNewlyCreated, DictionaryItem } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID, DATE_TIME_FORMAT } from "modules/common/constants";
import Schema from "../components/details/validation";
import moment from "moment";
import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";
import { getFieldLabel } from "modules/common/services/form/fields";
import { DictionaryLink } from "modules/common/models/dictionary-link";
import copyToClipboard from "copy-to-clipboard";
import { MimeTypes } from "modules/common/services/files";
export const ACCEPT = [MimeTypes.jpeg, MimeTypes.png, MimeTypes.pdf].join(",");

const Grade = types.model({
  guid: types.maybeNull(types.string),
  gradeName: types.string,
  percent: types.number,
});

export const EmployeePositionDictionaryItem = types
  .compose(
    DictionaryItem,
    types.model({
      departmentId: types.string,
      departmentName: types.string,
    })
  )
  .named("EmployeePositionDictionaryItem");

export const EmployeePositionDictionaryLink = types.compose(
  DictionaryLink,
  types.model({
    nonProduction: types.boolean,
    order: types.maybeNull(types.number),
  })
);

export const EmployeePositionLink = types.compose(
  DictionaryLink,
  types.model({
    nonProduction: types.boolean,
    order: types.maybeNull(types.number),
    bonus: types.number,
    salary: types.number,
    department: types.maybeNull(DictionaryLink),
    since: types.string,
    grades: types.maybeNull(types.array(Grade)),
    gradeGuid: types.maybeNull(types.string),
  })
);

const EmployeePositionBase = types
  .compose(
    Transport,
    Notificator,
    BaseEntity,
    types.model({
      name: types.string,
      comment: types.string,
      nonProduction: types.boolean,
      order: types.maybeNull(types.number),
      employee: types.optional(types.array(DictionaryLink), []),
      department: types.maybeNull(DictionaryLink),
      grades: types.array(Grade),
      fileId: types.string,
      manualFile: types.maybeNull(
        types.model({
          fileId: types.string,
          fileName: types.string,
        })
      ),
    })
  )
  .views((self) => ({
    get departmentId() {
      return self.department?.id;
    },

    get employeeCount() {
      return self.employee.length;
    },
  }));

export const EmployeePosition = EmployeePositionBase.actions((self) => ({
  load: flow(function* (id: string) {
    try {
      const snapshot = isNewlyCreated(id)
        ? initialState()
        : yield self.transport.get<EmployeePositionSnapshotType>(apiUrls.employeePositions.details(id));

      applySnapshot(self, snapshot);
    } catch (er) {
      self.notify.error(er);
    }
  }),

  save: flow(function* (model: any) {
    try {
      let order: any = model[fields.order];

      if (order === "") {
        order = null;
      } else if (typeof order === "string") {
        order = parseInt(order, 10);
      }
      const snapshot = self.isNewlyCreated
        ? yield self.transport.put<EmployeePositionSnapshotType>(apiUrls.employeePositions.create(), {
            ...model,
            order,
          })
        : yield self.transport.post<EmployeePositionSnapshotType>(apiUrls.employeePositions.update(self.id), {
            ...model,
            order,
          });

      isAlive(self) && applySnapshot(self, snapshot);
      self.notify.success(texts.messages.saved);

      return true;
    } catch (er) {
      self.notify.error(er);

      return false;
    }
  }),

  delete: flow(function* () {
    if (self.isNewlyCreated) {
      return true;
    }

    try {
      yield self.transport.delete<boolean>(apiUrls.employeePositions.delete(self.id));
      self.notify.success(texts.messages.removed);

      isAlive(self) && applySnapshot(self, initialState());
      return true;
    } catch (er) {
      self.notify.error(er);
      return false;
    }
  }),
  uploadFile: flow(function* (file: File) {
    try {
      const model = new FormData();

      model.append("file", file);
      model.append("accept", ACCEPT);

      const result: UploadFileResult = yield self.transport.post<any>(apiUrls.application.files.upload, model);

      const { id } = result;

      const fileBase: any = { fileId: id, fileName: file.name };
      return fileBase;
    } catch (er) {
      self.notify.error(er);
      return null;
    }
  }),

  copyId() {
    const success = copyToClipboard(self.id, {
      format: "text/plain",
    });

    if (success) {
      self.notify.success("Скопировано");
    }
  },
})).named("EmployeePosition");

export type EmployeePositionDictionaryItemType = typeof EmployeePositionDictionaryItem.Type;
export type EmployeePositionSnapshotType = typeof EmployeePositionBase.SnapshotType;
export type EmployeePositionType = typeof EmployeePosition.Type;
export type GradeType = typeof Grade.Type;

export const initialState = (): EmployeePositionSnapshotType => ({
  id: EMPTY_OBJECT_ID,
  created: moment().format(DATE_TIME_FORMAT),
  name: "",
  comment: "",
  nonProduction: false,
  order: null,
  department: null,
  employee: [],
  fileId: "",
  manualFile: null,
  grades: [],
});

export const fields = {
  name: nameof((a: EmployeePositionType) => a.name) as string,
  comment: nameof((a: EmployeePositionType) => a.comment) as string,
  nonProduction: nameof((a: EmployeePositionType) => a.nonProduction) as string,
  order: nameof((a: EmployeePositionType) => a.order) as string,
  departmentId: nameof((a: EmployeePositionType) => a.departmentId) as string,
  department: nameof((a: EmployeePositionType) => a.department) as string,
  fileId: nameof((a: EmployeePositionType) => a.fileId) as string,
  employeeCount: nameof((a: EmployeePositionType) => a.employeeCount) as string,
  manualFile: nameof((a: EmployeePositionType) => a.manualFile) as string,
  grades: nameof((a: EmployeePositionType) => a.grades) as string,
};

export function formatEmployeePosition(stage: EmployeePositionSnapshotType | null) {
  let result = "";

  if (stage) {
    const schema = Schema();

    result += `${getFieldLabel(fields.name, schema, null)}: ${stage.name}\n`;
    result += `${getFieldLabel(fields.comment, schema, null)}: ${stage.comment}\n`;
  }

  return result;
}
