import React from "react";
import { CalendarEventStoreType, CalendarEventDayType } from "modules/main/models/calendar-event-store";
import { observer } from "mobx-react";
import styles from "./CalendarWidget.module.scss";
import { CalendarDay } from "./CalendarDay";

const week = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

export const CalendarWidget = observer(({ store, selection, onSelectDay }: CalendarWidgetProps) => {
    const onRemove = (e: CalendarEventDayType, forAll: boolean) => store.remove(e.event.eventGuid, forAll);

    return (
        <div className={`${styles.widget}`}>
            <div className={styles.names}>
                {week.map((name, i) => (
                    <div key={name} className={`${styles.dayName} ${[5, 6].includes(i) ? styles.weekend : ""}`}>
                        {name}
                    </div>
                ))}
            </div>
            <div className={styles.wrapper}>
                {store.days.map((day, index) => (
                    <CalendarDay
                        key={index}
                        index={index}
                        day={day}
                        daysCount={store.days.length}
                        onClick={onSelectDay}
                        onRemove={onRemove}
                        selected={!!selection && selection === day.day}
                        events={store.eventsAsMap.get(day.day) || []}
                    />
                ))}
            </div>
        </div>
    );
});

interface CalendarWidgetProps {
    store: CalendarEventStoreType;
    selection: string;
    onSelectDay: (day: string) => void;
}
