import { types } from "mobx-state-tree";
import { ProjectPortfolioList, ProjectPortfolio, initialState as emptyList } from "./project-portfolio-list";
import { initialState as emptyProjectPortfolio } from "./project-portfolio";
import { initialState as emptyProjectPortfolioDictionary } from "./project-portfolio-dictionary";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { ProjectPortfolioDictionary } from "./project-portfolio-dictionary";

export const ProjectPortfoliosStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            list: ProjectPortfolioList,
            details: ProjectPortfolio,
            dictionary: ProjectPortfolioDictionary,
        })
    )
    .named("ProjectPortfoliosStore");

export const initialState = (): typeof ProjectPortfoliosStore.SnapshotType => ({
    list: emptyList(),
    details: emptyProjectPortfolio(),
    dictionary: emptyProjectPortfolioDictionary(),
});

export type ProjectPortfoliosStoreType = typeof ProjectPortfoliosStore.Type;
