import React from "react";
import { FieldProps, Field } from "formik";
import { getFieldState, FormFieldState } from "modules/common/services/form/fields";
import { Schema } from "yup";
import { FormGroup, Classes } from "@blueprintjs/core";
import { Asterisk } from "./Asterisk";
import { toString } from "modules/common/services/strings";

export const StandardFormInput = (props: FormInputFieldProps & RenderProp) => {
    const { name, schema, label, small, required, inline, className, style } = props;
    const inl = typeof inline === "undefined" ? true : inline;

    return (
        <Field name={name}>
            {(fieldProps: FieldProps) => {
                const { field, form } = fieldProps;

                const state = getFieldState(field.name, schema, form);
                if (typeof required !== "undefined") {
                    state.isRequired = required;
                }

                const fieldLabel = typeof label === "undefined" ? state.label : label;
                const args = { ...fieldProps, state, label: fieldLabel };

                return (
                    <FormGroup
                        inline={inl}
                        label={fieldLabel}
                        labelFor={field.name}
                        labelInfo={<Asterisk isRequired={state.isRequired} />}
                        helperText={state.error}
                        intent={state.intent}
                        className={`${small ? Classes.SMALL : ""} ${className || ""}`}
                        style={style}
                    >
                        {"render" in props ? props.render(args) : "children" in props ? props.children(args) : null}
                    </FormGroup>
                );
            }}
        </Field>
    );
};

export const ReadOnlyFormElement: React.FC<ReadOnlyFormElementProps> = ({
    value,
    label,
    small,
    inline,
    width,
    children,
    helperText,
    style,
    title,
}) => {
    let modifier = "";
    if (inline) {
        modifier += "bp3-inline ";
    }
    if (small) {
        modifier += `${Classes.SMALL} `;
    }

    return (
        <div className={`bp3-form-group ${modifier}`} style={style} title={title}>
            {label && <label className="bp3-label">{label}</label>}
            <div className="bp3-form-content">
                <div className={`bp3-input-group planr-default-input ${modifier}`}>
                    <input
                        type="text"
                        className="bp3-input bp3-disabled"
                        dir="auto"
                        value={toString(value)}
                        readOnly={true}
                        style={{ color: "rgba(0, 39, 61, 0.8)", width: width }}
                    />
                </div>
                {children}
                {typeof helperText !== "undefined" && <div className="bp3-form-helper-text">{helperText}</div>}
            </div>
        </div>
    );
};

interface ReadOnlyFormElementProps {
    value: any;
    label?: React.ReactNode;
    small?: boolean;
    inline?: boolean;
    width?: number;
    helperText?: string;
    style?: React.CSSProperties;
    title?: string;
}

interface FormInputFieldProps {
    name: string;
    schema: Schema<any>;
    label?: React.ReactNode;
    required?: boolean;
    small?: boolean;
    inline?: boolean;
    className?: string;
    style?: React.CSSProperties;
}

type RenderProp = { render: (props: RenderArgs) => JSX.Element } | { children: (props: RenderArgs) => JSX.Element };

type RenderArgs = FieldProps & {
    state: FormFieldState;
    label: React.ReactNode;
};
