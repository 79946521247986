import { types } from "mobx-state-tree";
import { SimpleChartStore, initialState as simpleChartState } from "./simple-chart";

export const OrdersStateDataset = types
    .compose(
        SimpleChartStore,
        types.model({
            ids: types.array(types.string),
        })
    )
    .named("EngineersLoadingDataset");

export type OrdersStateDatasetType = typeof OrdersStateDataset.Type;
export type OrdersStateDatasetSnapshotType = typeof OrdersStateDataset.SnapshotType;

export const initialState = (url: string): OrdersStateDatasetSnapshotType => {
    return {
        ...simpleChartState(url),
        ids: [],
    };
};
