import React from "react";
import { Formik, Form } from "formik";
import styles from "./IpdTypeDetails.module.scss";
import IpdTypeSchema from "./validation";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { TextArea, Classes } from "@blueprintjs/core";
import { IpdTypeType, fields } from "modules/dictionaries/ipd-types/models/ipd-type";
import { fixTextAreaHeight, MODAL_AWAIT_DELAY } from "modules/common/services/form/textarea";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { Schema } from "yup";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { texts } from "modules/common/texts";
import { Prompt } from "react-router";

class IpdTypeDetailsBase extends React.PureComponent<IpdTypeDetailsProps, IpdTypeDetailsState> {
  private schema: Schema<any>;
  private commentArea: HTMLTextAreaElement | null = null;
  private nameArea: HTMLTextAreaElement | null = null;

  constructor(props: IpdTypeDetailsProps) {
    super(props);

    this.schema = IpdTypeSchema();

    this.state = {
      isMobile: false,
    };
  }

  handleResize = () => {
    if (window.innerWidth < 885) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  private commentAreaRef = (ref: HTMLTextAreaElement | null) => {
    this.commentArea = ref;
  };

  private nameAreaRef = (ref: HTMLTextAreaElement | null) => {
    this.nameArea = ref;
  };

  private resize = () => {
    fixTextAreaHeight(this.commentArea);
    fixTextAreaHeight(this.nameArea);
  };

  public componentDidMount() {
    window.addEventListener("resize", this.handleResize);

    setTimeout(this.resize, MODAL_AWAIT_DELAY);
  }

  public componentDidUpdate() {
    this.resize();
  }

  componentWillMount() {
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const { schema, props } = this;
    const { ipdType, onSaved, onRemoved, canChange } = props;
    const isMobile = this.state.isMobile;

    return (
      <div className={styles.details}>
        <Formik
          initialValues={getFormValues(ipdType)}
          enableReinitialize={true}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            if (!canChange) {
              return;
            }

            const succsessfull = await ipdType.save(values);
            setSubmitting(false);

            if (succsessfull) {
              resetForm();
              onSaved();
            }
          }}
        >
          {(formProps) => {
            const onRemove = async () => {
              if (!canChange) {
                return;
              }

              formProps.setSubmitting(true);
              const success = await ipdType.delete();
              formProps.setSubmitting(false);
              success && onRemoved();
            };

            return (
              <Form autoComplete="off">
                <Prompt when={formProps.dirty && canChange} message={texts.messages.leaveConfiramtion} />
                <div className={Classes.DIALOG_BODY}>
                  <StandardFormInput
                    name={fields.name}
                    schema={schema}
                    small={true}
                    inline={!isMobile}
                    className="planr-form-input"
                  >
                    {({ field }) => (
                      <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                        <TextArea
                          id={field.name}
                          {...field}
                          growVertically={true}
                          className={Classes.FILL}
                          inputRef={this.nameAreaRef}
                          disabled={!canChange}
                        />
                      </div>
                    )}
                  </StandardFormInput>

                  <StandardFormInput
                    name={fields.description}
                    schema={schema}
                    small={true}
                    inline={!isMobile}
                    className="planr-form-input"
                  >
                    {({ field }) => (
                      <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                        <TextArea
                          id={field.name}
                          {...field}
                          growVertically={true}
                          className={Classes.FILL}
                          inputRef={this.commentAreaRef}
                          disabled={!canChange}
                        />
                      </div>
                    )}
                  </StandardFormInput>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                  {canChange && (
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                      <StandardFormButtons
                        {...formProps}
                        isRemoved={false}
                        what={`ИРД ${ipdType.name}`}
                        isNewlyCreated={ipdType.isNewlyCreated}
                        onRemove={onRemove}
                      />
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export const IpdTypeDetails = observer(IpdTypeDetailsBase);

interface IpdTypeDetailsProps {
  ipdType: IpdTypeType;
  onSaved: () => void;
  onRemoved: () => void;
  canChange: boolean;
}

interface IpdTypeDetailsState {
  isMobile: boolean;
}

function getFormValues(ipdType: IpdTypeType) {
  return {
    ...getSnapshot(ipdType),
  };
}
