import React from "react";
import styles from "./Summary.module.scss";
import { fields } from "modules/orders-manage/models/order";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { InputGroup } from "@blueprintjs/core";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { Schema } from "yup";
import { formatDate } from "modules/common/services/formatting/date";
import { OrderFileSnapshotType } from "modules/orders-manage/models/order-file";
import { UploaderFatory, IdFactory } from "modules/orders-manage/types";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";

export class OrderBase extends React.Component<OrderBaseProps> {
    render() {
        const { what, onChange, schema, readOnly, number, date, children, anotherChildren, noUploader } = this.props;

        return (
            <div style={{ width: "100%" }}>
                <div className={`order-base ${styles.orderBase}`}>
                    <div className={styles.half}>
                        <StandardFormInput
                            name={fields.orderNumber}
                            schema={schema}
                            small={true}
                            inline={false}
                            label={`${what} №:`}
                            style={{ marginBottom: "8px" }}
                        >
                            {({ field }) => {
                                return (
                                    <InputGroup
                                        id={field.name}
                                        {...field}
                                        value={number}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            onChange(field.name, e.target.value);
                                        }}
                                        className="planr-default-input"
                                        autoComplete="off"
                                        data-lpignore="true"
                                        disabled={readOnly}
                                    />
                                );
                            }}
                        </StandardFormInput>
                    </div>
                    <div className={styles.half}>
                        <StandardFormInput
                            name={fields.startDate}
                            schema={schema}
                            small={true}
                            inline={false}
                            label="От:"
                            style={{ marginBottom: "8px" }}
                        >
                            {({ field, form }) => {
                                return (
                                    <DatePicker
                                        clasName="planr-default-input"
                                        value={date}
                                        onChange={(d) => {
                                            onChange(field.name, d);
                                        }}
                                        maxDate={form.values[fields.stopDateActual]}
                                        disabled={readOnly}
                                    />
                                );
                            }}
                        </StandardFormInput>
                    </div>
                    {children}
                </div>

                {anotherChildren && (
                    <div className={`order-base ${styles.orderBase}`} style={{ marginTop: "0" }}>
                        {anotherChildren}
                    </div>
                )}

                {!readOnly && !noUploader && this.canUpload() && (
                    <div className="order-filednduploader" style={{ marginTop: "4px" }}>
                        <PlanRDndUploader
                            accept="*"
                            multiple={false}
                            onFileSelected={this.uploadFile}
                            withLoader={true}
                        />
                    </div>
                )}
            </div>
        );
    }

    canUpload = () => {
        return !!this.props.number && !!this.props.date;
    };

    uploadFile = async (file: File) => {
        if (!this.canUpload()) {
            return;
        }

        const meta = await this.props.upload(file);
        if (meta) {
            const data: OrderFileSnapshotType = {
                ...meta,
                orderNumber: this.props.number,
                startDate: formatDate(this.props.date),
                guid: await this.props.newId(),
                sortOrder: 0,
                employerId: EMPTY_OBJECT_ID,
            };

            this.props.addOrderFile(data);
        }
    };
}

interface OrderBaseProps extends UploaderFatory, IdFactory {
    what: string;
    number: string;
    date: Date | null;
    onChange: (field: string, value: any) => void;
    addOrderFile: (row: OrderFileSnapshotType) => void;
    schema: Schema<any>;
    readOnly?: boolean;
    noUploader?: boolean;
    inline: boolean;
    anotherChildren?: React.ReactNode;
}
