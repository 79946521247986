import * as yup from "yup";
import { fields } from "modules/dictionaries/order-indicators/models/order-indicator";
import { texts } from "modules/common/texts";
const { object, string } = yup;

export const orderIndicatorLabels = () => ({
    [fields.label]: texts.name,
    [fields.idIndicator]: "Ед. измерения",
});

const OrderIndicatorSchema = () => {
    const labels = orderIndicatorLabels();

    return object().shape({
        [fields.label]: string().label(labels[fields.label]).required(),

        [fields.idIndicator]: string().label(labels[fields.idIndicator]).notRequired(),
    });
};

export default OrderIndicatorSchema;
