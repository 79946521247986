import React from 'react';
import styles from './Asterisk.module.scss';

export const Asterisk = ({ isRequired }: AsteriskProps) =>
    isRequired ? (
        <span className={`${styles.asterisk} field-asterisk`}>*</span>
    ) : null;

interface AsteriskProps {
    isRequired: boolean;
}
