import { types, applySnapshot } from "mobx-state-tree";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import {
    FinanceRequestStatisticDictionaryDictionaryItem,
    FinanceRequestStatisticDictionaryDictionaryItemType,
} from "modules/expenses/import/models/bank-row";

type Item = FinanceRequestStatisticDictionaryDictionaryItemType;

export const FinanceRequestDictionary = types
    .compose(
        Notificator,
        Transport,
        types.model({
            requests: types.array(FinanceRequestStatisticDictionaryDictionaryItem),
        })
    )
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: Item[] = yield self.transport.get<any>(apiUrls.financeRequests.dictionary);
                applySnapshot(self.requests, data);

                return true;
            } catch (er) {
                applySnapshot(self.requests, []);
                return false;
            }
        }),
    }))
    .views((self) => ({
        get isEmpty() {
            return !self.requests.length;
        },

        get asMap() {
            return self.requests.reduce((result, item) => {
                result[item.id] = item;
                return result;
            }, {} as TStringMap<Item>);
        },
    }))
    .named("FinanceRequestDictionary");

export type FinanceRequestDictionaryType = typeof FinanceRequestDictionary.Type;

export const initialState = (): typeof FinanceRequestDictionary.SnapshotType => ({
    requests: [],
});
