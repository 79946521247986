import React, { useCallback } from "react";
import { Page } from "modules/common/components/page/Page";

import { routes } from "modules/common/routes";
import { RouteChildrenProps } from "react-router";
import { Tabs, Tab } from "@blueprintjs/core";
import styles from "./ItemsTabsPage.module.scss";

import { tabs } from "./tabs";

export const ItemsTabsPage = (props: ItemsTabsPageProps & RouteChildrenProps) => {
    const { itemsFreePanel, itemsAllPanel, itemsFixedPanel, itemsEndedPanel, itemsDeletedPanel, location, history } =
        props;

    const tabId = location.hash.includes(tabs.free)
        ? tabs.free
        : location.hash.includes(tabs.fixed)
        ? tabs.fixed
        : location.hash.includes(tabs.ended)
        ? tabs.ended
        : location.hash.includes(tabs.deleted)
        ? tabs.deleted
        : tabs.free;

    const handleTabChange = useCallback(
        (newTabId: string) => {
            const route = routes.dictionaries.inventory.path + newTabId;
            history.push(route);
        },
        [history]
    );

    // const chartVisible = can(func.FLOW_ORDERS_CHART, access);

    return (
        <Page className={styles.page}>
            <Tabs
                className={`planr-content-tabs-navigation ${styles.tabs} `}
                id="ItemsTabsPage"
                onChange={handleTabChange}
                selectedTabId={tabId}
                renderActiveTabPanelOnly={true}
            >
                {itemsFreePanel && (
                    <Tab id={tabs.free} title={"Свободно"} panel={itemsFreePanel} className="refresh-tabbtn" />
                )}

                {itemsAllPanel && <Tab id={tabs.free} title={"Все"} panel={itemsAllPanel} className="refresh-tabbtn" />}

                {itemsFixedPanel && (
                    <Tab id={tabs.fixed} title={"Закреплено"} panel={itemsFixedPanel} className="refresh-tabbtn" />
                )}
                {itemsEndedPanel && (
                    <Tab id={tabs.ended} title={"Списано"} panel={itemsEndedPanel} className="refresh-tabbtn" />
                )}
                {itemsDeletedPanel && (
                    <Tab id={tabs.deleted} title={"Удалено"} panel={itemsDeletedPanel} className="refresh-tabbtn" />
                )}
            </Tabs>
        </Page>
    );
};

interface ItemsTabsPageProps {
    itemsFreePanel?: JSX.Element | null;
    itemsAllPanel?: JSX.Element | null;
    itemsFixedPanel?: JSX.Element | null;
    itemsEndedPanel?: JSX.Element | null;
    itemsDeletedPanel?: JSX.Element | null;
}
