import { Classes, InputGroup, TextArea } from "@blueprintjs/core";
import { FieldProps, Form, Formik } from "formik";
import { findIndex } from "lodash";
import { FileLink } from "modules/common/components/files/FileLink";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { isNewlyCreated } from "modules/common/models/entity";
import { texts } from "modules/common/texts";
import { UploaderFatory } from "modules/orders-manage/types";
import { Constants } from "modules/root/models/constants";
import React, { useCallback, useEffect, useRef } from "react";
import styles from "./Form.module.scss";
import { OrderMailsSchema } from "./validation";

import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { DATE_FORMAT } from "modules/common/constants";
import { MimeTypes } from "modules/common/services/files";
import { DefaultSelectedOption, SimpleSelect, renderSingleOption } from "modules/common/services/form/select";
import { SaveConfirm, confirmFields } from "modules/orders-manage/models/order-confirm";
import moment from "moment";

export const REQUEST = [MimeTypes.docx, MimeTypes.doc, MimeTypes.xls, MimeTypes.xlsx, MimeTypes.pdf].join(",");

export const SOURCE = [MimeTypes.docx, MimeTypes.doc, MimeTypes.xls, MimeTypes.xlsx].join(",");

export const RESULT = [MimeTypes.pdf].join(",");

const schema = OrderMailsSchema();
export const removeLabel = (node: SaveConfirm) => `запись ${node.number}`;

export const ConfirmForm: React.FC<FormProps> = (props) => {
  const { confirm, onSubmit, onRemove, children, submitLabel, baseUrl } = props;

  const mounted = useRef(true);
  const remove = useCallback(() => onRemove(confirm), [onRemove, confirm]);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  const typeItems = Constants.orderConfirmTypesList;
  return (
    <Formik
      initialValues={confirm}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(values);

        if (mounted.current) {
          setSubmitting(false);
        }
      }}
    >
      {(formProps) => {
        const savedType = formProps.values.type
          ? {
              id: formProps.values.type,
              label: formProps.values.type,
            }
          : null;
        return (
          <Form autoComplete="off" className={styles.form}>
            <div className={styles.dialog}>
              <div className={styles.columnLeft}>
                {children}
                {/* Номер письма */}
                <StandardFormInput
                  name={confirmFields.number}
                  schema={schema}
                  small={true}
                  className="planr-form-input"
                  inline={false}
                >
                  {({ field }) => (
                    <InputGroup
                      id={field.name}
                      {...field}
                      className="planr-default-input"
                      autoComplete="off"
                      data-lpignore="true"
                    />
                  )}
                </StandardFormInput>
                {/* Дата письма */}
                <StandardFormInput
                  name={confirmFields.date}
                  schema={schema}
                  small={true}
                  inline={false}
                  className="planr-form-input"
                >
                  {({ field, form }) => {
                    return (
                      <div className={`${Classes.INPUT_GROUP}`}>
                        <DatePicker
                          value={moment(field.value, DATE_FORMAT).toDate()}
                          clasName="planr-default-input"
                          onChange={(date) => {
                            form.setFieldValue(field.name, moment(date).format(DATE_FORMAT));
                            form.setFieldTouched(field.name, true);
                          }}
                        />
                      </div>
                    );
                  }}
                </StandardFormInput>

                {/* Кому / от кого */}
                <StandardFormInput
                  name={confirmFields.belonging}
                  schema={schema}
                  small={true}
                  className="planr-form-input"
                  inline={false}
                >
                  {({ field }) => (
                    <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                      <TextArea {...field} growVertically={true} className={Classes.FILL} />
                    </div>
                  )}
                </StandardFormInput>

                {/* Тема */}
                <StandardFormInput
                  name={confirmFields.description}
                  schema={schema}
                  small={true}
                  className="planr-form-input"
                  inline={false}
                >
                  {({ field }) => (
                    <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                      <TextArea {...field} growVertically={true} className={Classes.FILL} />
                    </div>
                  )}
                </StandardFormInput>

                {/* Статусы */}
                <StandardFormInput
                  inline={false}
                  name={confirmFields.type}
                  schema={schema}
                  small={true}
                  className="planr-form-input"
                >
                  {() => {
                    return (
                      <SimpleSelect
                        className={`full-width-select ${Classes.FILL}`}
                        activeItem={savedType}
                        items={typeItems}
                        itemRenderer={renderSingleOption}
                        onItemSelect={(i: SelectItem) => {
                          formProps.setFieldValue(confirmFields.type, i.id);
                          formProps.setFieldTouched(confirmFields.type, true);
                        }}
                        filterable={false}
                      >
                        <DefaultSelectedOption option={savedType} />
                      </SimpleSelect>
                    );
                  }}
                </StandardFormInput>

                {(formProps.values as any)[confirmFields.type] ===
                  Constants.orderConfirmTypes[Constants.orderConfirmTypes.length - 1] && (
                  <div className={styles.requestFiles}>
                    <StandardFormInput
                      name={confirmFields.requestFiles}
                      schema={schema}
                      small={true}
                      className="planr-form-input"
                    >
                      {({ field, form }: FieldProps) => {
                        const onRemoveFile = (file: FileBase) => {
                          const value = field.value;
                          const index = findIndex(value, (f: FileBase) => f.fileId === file.fileId);

                          if (index >= 0) {
                            const newValue = [...value.slice(0, index), ...value.slice(index + 1)];
                            form.setFieldValue(field.name, newValue);
                            form.setFieldTouched(field.name, true);
                          }
                        };

                        const onFileSelected = async (file: File) => {
                          const { upload } = props;
                          const success = await upload(file);

                          if (success) {
                            const value = [...field.value, success];
                            form.setFieldValue(field.name, value);
                            form.setFieldTouched(field.name);
                          }
                        };

                        return (
                          <div className={styles.fileList}>
                            <label className="bp3-label">Файл с требованиями:</label>

                            <PlanRDndUploader
                              accept={REQUEST}
                              onFileSelected={onFileSelected}
                              withLoader={true}
                              multiple={true}
                              style={{ maxWidth: "600px" }}
                            />
                            <div className={styles.filesList}>
                              {field.value.map((doc: FileBase, index: number) => (
                                <FileLink
                                  baseUrl={baseUrl}
                                  file={doc}
                                  key={index}
                                  onRemove={onRemoveFile}
                                  style={{ maxWidth: "600px" }}
                                />
                              ))}
                            </div>
                          </div>
                        );
                      }}
                    </StandardFormInput>
                  </div>
                )}
              </div>
              <div className={styles.columnRight}>
                <StandardFormInput
                  name={confirmFields.sourceFiles}
                  schema={schema}
                  small={true}
                  className="planr-form-input"
                >
                  {({ field, form }: FieldProps) => {
                    const onRemoveFile = (file: FileBase) => {
                      const value = field.value;
                      const index = findIndex(value, (f: FileBase) => f.fileId === file.fileId);

                      if (index >= 0) {
                        const newValue = [...value.slice(0, index), ...value.slice(index + 1)];
                        form.setFieldValue(field.name, newValue);
                        form.setFieldTouched(field.name, true);
                      }
                    };

                    const onFileSelected = async (file: File) => {
                      const { upload } = props;
                      const success = await upload(file);

                      if (success) {
                        const value = [...field.value, success];
                        form.setFieldValue(field.name, value);
                        form.setFieldTouched(field.name);
                      }
                    };

                    return (
                      <div className={styles.fileList}>
                        <label className="bp3-label">Исходный файл:</label>

                        <PlanRDndUploader
                          accept={SOURCE}
                          onFileSelected={onFileSelected}
                          withLoader={true}
                          multiple={true}
                        />
                        <div className={styles.filesList}>
                          {field.value.map((doc: FileBase, index: number) => (
                            <FileLink baseUrl={baseUrl} file={doc} key={index} onRemove={onRemoveFile} />
                          ))}
                        </div>
                      </div>
                    );
                  }}
                </StandardFormInput>
                <StandardFormInput
                  name={confirmFields.resultFiles}
                  schema={schema}
                  small={true}
                  className="planr-form-input"
                >
                  {({ field, form }: FieldProps) => {
                    const onRemoveFile = (file: FileBase) => {
                      const value = field.value;
                      const index = findIndex(value, (f: FileBase) => f.fileId === file.fileId);

                      if (index >= 0) {
                        const newValue = [...value.slice(0, index), ...value.slice(index + 1)];
                        form.setFieldValue(field.name, newValue);
                        form.setFieldTouched(field.name, true);
                      }
                    };

                    const onFileSelected = async (file: File) => {
                      const { upload } = props;
                      const success = await upload(file);

                      if (success) {
                        const value = [...field.value, success];
                        form.setFieldValue(field.name, value);
                        form.setFieldTouched(field.name);
                      }
                    };

                    return (
                      <div className={styles.fileList}>
                        <label className="bp3-label">Файл результата:</label>

                        <PlanRDndUploader
                          accept={RESULT}
                          onFileSelected={onFileSelected}
                          withLoader={true}
                          multiple={true}
                        />
                        {field.value && field.value.length > 0 && (
                          <div className={styles.filesList}>
                            {field.value.map((doc: FileBase, index: number) => (
                              <FileLink baseUrl={baseUrl} file={doc} key={index} onRemove={onRemoveFile} />
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  }}
                </StandardFormInput>
              </div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <StandardFormButtons
                {...formProps}
                isRemoved={false}
                what={removeLabel(confirm)}
                isNewlyCreated={isNewlyCreated(confirm.id)}
                onRemove={remove}
                submitLabel={submitLabel || texts.ready}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export interface FormProps extends UploaderFatory {
  baseUrl: string;
  confirm: SaveConfirm;
  onSubmit: (mail: SaveConfirm) => Promise<any>;
  onRemove: (mail: SaveConfirm) => void;
  submitLabel?: string;
  canClientEdit?: boolean;
}
