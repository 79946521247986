import React, { useCallback, useState } from "react";
import { hideButton, hostReady, AppLoadingStatus, DocModifiedStatus, reloadFrame } from "./collabora-api";
import { CollaboraLoader, CollaboraFrame, CollaboraEvent, CollaboraLoaderProps } from "./collabora";
import styles from "./TechTask.module.scss";
import { OrderTechTasksType, ACCEPT, OrderTechTaskVersionType } from "modules/orders-manage/models/order-tech-tasks";
import { observer } from "mobx-react";
import { apiUrls } from "modules/common/services/communication/urls";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { FileLink } from "modules/common/components/files/FileLink";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { ProjectAssigner } from "modules/common/components/orders/ProjectAssigner";
import { OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";
import { OrderSummaryType } from "modules/orders-manage/models/order";
import { CollaboraAnchorsHelp } from "./CollaboraAnchorsHelp";
import { PrintAnchorInfoListType } from "modules/common/models/print-anchor";
import { TechTaskVerisons } from "./TechTaskVerisons";
import { VersionViewer } from "./VersionViewer";

interface TechTaskProps extends Omit<CollaboraLoaderProps, "id" | "apiUrl"> {
    store: OrderTechTasksType;
    fileId: string;
    orderDictionary: OrderDictionaryType;
    order: OrderSummaryType;
    readOnly: boolean;
    baseUrl: string;
    anchors: PrintAnchorInfoListType;
    isEngineer: boolean;
}

export const TechTask = observer(
    ({ store, anchors, orderDictionary, order, readOnly, baseUrl, isEngineer, ...rest }: TechTaskProps) => {
        const editorFrameId = "order-tech-task-frame";
        const [selectedVersion, selectVersion] = useState<OrderTechTaskVersionType>();
        const [uploadedFile, uploadFile] = useState({
            fileId: "",
            fileName: "",
        });
        const [copyVisible, setCopyVisible] = useState(false);
        const [proxyLoading, setProxyLoading] = useState(false);
        const onMessage = useCallback(
            (e: CollaboraEvent) => {
                if (e.MessageId === "App_LoadingStatus") {
                    const event: AppLoadingStatus = e;

                    if (event.Values.Status === "Document_Loaded") {
                        hostReady(e.frame);
                        hideButton(e.frame, "fold");
                    }

                    return true;
                }

                if (e.MessageId === "Doc_ModifiedStatus") {
                    const event: DocModifiedStatus = e;

                    if (!event.Values.Modified) {
                        const id = store.id;
                        setTimeout(() => {
                            if (id === store.id) {
                                store.loadVersions();
                            }
                        }, 1000);
                    }
                }
            },
            [store]
        );

        const fileUpload = async (file: any) => {
            const result = await store.uploadFile(file);
            if (result) {
                uploadFile({ fileId: result.fileId, fileName: result.fileName });
            }
        };

        const onSelectVersion = useCallback((v: OrderTechTaskVersionType) => selectVersion(v), []);

        const onCloseVersion = useCallback(() => selectVersion(undefined), []);

        const apiUrl = apiUrls.orders.summary.tasks.fileInfo(rest.fileId);

        const showDetails = useCallback(() => setCopyVisible(true), []);
        const closeDetails = useCallback(() => setCopyVisible(false), []);

        const copyFile = async (orderId: string) => {
            const fileId = orderDictionary.asMap[orderId].id;

            if (fileId) {
                setProxyLoading(true);
                const version = "";
                try {
                    const result = await store.copyFile(order.id, fileId, version);
                    if (result) {
                        reloadFrame(editorFrameId);
                    } else {
                        return;
                    }
                } finally {
                    setProxyLoading(false);
                }
            }

            closeDetails();
        };

        return (
            <>
                {copyVisible && (
                    <ProjectAssigner
                        orders={orderDictionary}
                        onClose={closeDetails}
                        excludeId={order.id}
                        onAssign={copyFile}
                        loading={proxyLoading}
                    />
                )}

                {!readOnly && (
                    <div className="collapse-add" style={{ position: "absolute", right: "0px", top: "78px" }}>
                        <PlanrButton
                            type="dashed"
                            icon="general-plus-big"
                            className="collapse-add-rows-small"
                            onClick={showDetails}
                        >
                            Добавить из проекта
                        </PlanrButton>
                        <div style={{ display: "flex", width: "500px", marginLeft: "24px" }}>
                            {!uploadedFile.fileId && (
                                <PlanRDndUploader
                                    accept={ACCEPT}
                                    multiple={true}
                                    withLoader={true}
                                    style={{ border: "3px dashed #edf0f2", height: " 52px", width: "500px" }}
                                    onFileSelected={fileUpload}
                                />
                            )}
                            {uploadedFile.fileId && (
                                <RemoveConfirmation
                                    onConfirmed={async () => {
                                        const result = await store.uploadTaskFile(uploadedFile.fileId);
                                        if (result) {
                                            uploadFile({
                                                fileId: "",
                                                fileName: "",
                                            });
                                            reloadFrame(editorFrameId);
                                        }
                                    }}
                                    what={() => "документ"}
                                    actionName={"перезаписать"}
                                    render={({ confirmRemoving }) => {
                                        const onClick = () => confirmRemoving(true);

                                        return (
                                            <PlanrButton type="greenish" onClick={onClick}>
                                                Загрузить
                                            </PlanrButton>
                                        );
                                    }}
                                />
                            )}
                            {uploadedFile.fileId && (
                                <FileLink<any>
                                    baseUrl={baseUrl}
                                    file={uploadedFile}
                                    style={{
                                        height: "52px",
                                        marginBottom: "0px",
                                        width: "301px",
                                        marginLeft: "16px",
                                        border: "3px dashed #edf0f2",
                                    }}
                                    onRemove={() => uploadFile({ fileId: "", fileName: "" })}
                                />
                            )}
                        </div>
                    </div>
                )}

                {selectedVersion && <VersionViewer onClose={onCloseVersion} version={selectedVersion} {...rest} />}

                <CollaboraLoader {...rest} id={editorFrameId} apiUrl={apiUrl} />

                <div style={{ display: "flex", width: "100%", height: "100%" }} className={styles.tech}>
                    {!readOnly && (
                        <CollaboraAnchorsHelp store={anchors} style={{ padding: 0, width: "45px", height: "40px" }} />
                    )}

                    <CollaboraFrame onMessage={onMessage} id={editorFrameId} style={{ minWidth: "1395px" }}>
                        <PlanrButton
                            type="greenish"
                            icon="general-print"
                            onClick={store.print}
                            title="Заполнить якори и распечатать"
                            style={{
                                position: "absolute",
                                top: 0,
                                zIndex: 1,
                                right: 0,
                                padding: 0,
                                width: "45px",
                                height: "40px",
                            }}
                        />
                    </CollaboraFrame>

                    <TechTaskVerisons
                        versions={store.versions}
                        onSave={store.saveVersion}
                        select={onSelectVersion}
                        readonly={!isEngineer}
                        style={{ maxWidth: "217px", minWidth: "217px" }}
                    />
                </div>
            </>
        );
    }
);
