import React from "react";
import ReactDOM from "react-dom";
import { OrderSummaryType, fields } from "modules/orders-manage/models/order";

import { observer } from "mobx-react";
import styles from "./TechIndicator.module.scss";
import { Field, FieldProps, Form, Formik } from "formik";
// import { TechIndicatorRow } from "./TechIndicatorRow";
import { SaveOrderTechIndicator, mapTechIndicator } from "modules/orders-manage/models/order-tech-indicator";
import { Location } from "history";

import { DragDropContext, DropResult, Droppable, Draggable } from "react-beautiful-dnd";
import { move } from "modules/common/services/array";
import portal from "../portal";

import { OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";

import { PlanrButton } from "modules/common/components/planr/button/Button";
import { cloneDeep } from "lodash";

import DenyNavigation from "modules/common/components/routing/DenyNavigation";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { eat } from "modules/common/services/typescript";
import { routes } from "modules/common/routes";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { OrdersStoreType } from "modules/orders-manage/models/orders-store";
import { buildPatch } from "modules/common/services/form/values";
import { TechIndicatorRow } from "./TechIndicatorRow";

import { TechIndicatorHeader } from "./TechIndicatorHeader";

class TechIndicator extends React.Component<TechIndicatorProps, TechIndicatorState> {
    private fieldProps: FieldProps | null = null;
    constructor(props: TechIndicatorProps) {
        super(props);

        this.state = {
            activeRow: null,
        };
    }
    async componentDidMount() {
        const { orderId, store } = this.props;
        if (orderId !== EMPTY_OBJECT_ID) {
            await store.details.loadOrderIndicators(orderId);
        }
    }
    // async componentDidUpdate() {
    //     const { orderId, store } = this.props;
    //     if (orderId !== EMPTY_OBJECT_ID) {
    //         const arr = await store.details.loadStages(orderId);
    //         if (arr !== null) {
    //             this.setState({ stages: arr });
    //         }
    //     }
    // }
    render() {
        const { name, readOnly, store, schema, orderId } = this.props;
        const { activeRow } = this.state;

        const initial = getFormValues(store.details);

        return (
            <Formik
                initialValues={initial}
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    let patch: any;

                    if (store.details.isNewlyCreated) {
                        patch = cloneDeep(values);
                    } else {
                        patch = buildPatch(initial, values);
                    }
                    const orderSnapshot = await store.saveOrderTechIndicators(patch);

                    setSubmitting(false);

                    if (!!orderSnapshot) {
                        store.details.refreshTechIndicators(orderSnapshot);
                        resetForm();
                        // this.onOrderSaved();
                    }

                    if (orderSnapshot === null) {
                        resetForm();
                    }
                }}
                onReset={eat}
            >
                {(formProps) => {
                    if (orderId !== EMPTY_OBJECT_ID) {
                        return (
                            <Form autoComplete="off">
                                <DenyNavigation
                                    when={formProps.dirty}
                                    message="На странице остались несохраненные изменения"
                                    skip={this.navigationPromt}
                                />
                                <div className={styles.ipd}>
                                    <div className="planr-tools"></div>

                                    {
                                        <Field name={name}>
                                            {(fieldProps: FieldProps) => {
                                                this.fieldProps = fieldProps;
                                                const indicators: SaveOrderTechIndicator[] = fieldProps.field.value;

                                                return (
                                                    <div className={styles.contents}>
                                                        {indicators.length > 0 && (
                                                            <TechIndicatorHeader active={activeRow} />
                                                        )}
                                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                                            <Droppable droppableId="ipd">
                                                                {(provided) => (
                                                                    <div
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                    >
                                                                        {indicators.map((indicator, index) => {
                                                                            return (
                                                                                <Draggable
                                                                                    key={indicator.guid}
                                                                                    draggableId={indicator.guid}
                                                                                    index={index}
                                                                                    isDragDisabled={!!activeRow}
                                                                                >
                                                                                    {(draggable, snapshot) => {
                                                                                        const child = (
                                                                                            <TechIndicatorRow
                                                                                                key={indicator.guid}
                                                                                                indicator={indicator}
                                                                                                active={activeRow}
                                                                                                onActivate={
                                                                                                    this.onActivateRow
                                                                                                }
                                                                                                onChange={this.onChange}
                                                                                                onRemove={
                                                                                                    this.onRemoveRow
                                                                                                }
                                                                                                readOnly={readOnly}
                                                                                                onSave={this.onSaveRow}
                                                                                                draggable={draggable}
                                                                                                isDragging={
                                                                                                    snapshot.isDragging
                                                                                                }
                                                                                                indicators={
                                                                                                    store.details
                                                                                                        .indicators
                                                                                                }
                                                                                            />
                                                                                        );

                                                                                        return snapshot.isDragging
                                                                                            ? ReactDOM.createPortal(
                                                                                                  child,
                                                                                                  portal
                                                                                              )
                                                                                            : child;
                                                                                    }}
                                                                                </Draggable>
                                                                            );
                                                                        })}

                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                    </div>
                                                );
                                            }}
                                        </Field>
                                    }

                                    {!readOnly && (
                                        <div className="collapse-add" style={{ margin: "16px", marginBottom: "80px" }}>
                                            <PlanrButton
                                                type="dashed"
                                                icon="general-plus-big"
                                                className="collapse-add-rows-small"
                                                onClick={async () => {
                                                    const unit = await store.details.emptyTechIndicator();
                                                    this.onAddRow(unit);
                                                }}
                                            >
                                                Добавить
                                            </PlanrButton>
                                        </div>
                                    )}
                                </div>
                                {!readOnly && (
                                    <div className={styles.buttons}>
                                        <div className={styles.buttonsLeft}>
                                            <StandardFormButtons
                                                {...formProps}
                                                isRemoved={false}
                                                what={""}
                                                isNewlyCreated={true}
                                                submitOnDirty={true}
                                                onRemove={eat}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Form>
                        );
                    } else {
                        return <h2>Сначала необходимо сохранить основные данные договора</h2>;
                    }
                }}
            </Formik>
        );
    }

    onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination || !this.fieldProps) {
            return;
        }

        // initial index
        const from = result.source.index;
        // new index
        const to = result.destination.index;

        const ipds: SaveOrderTechIndicator[] = this.fieldProps.field.value;
        const newValue = move(ipds, from, to);
        this.fieldProps.form.setFieldValue(this.props.name, newValue);
        this.fieldProps.form.setFieldTouched(this.props.name, true);
    };

    onChange = (changes: Array<{ field: string; value: any }>) => {
        if (this.state.activeRow) {
            const apply: any = {};
            changes.forEach((change) => (apply[change.field] = change.value));
            this.setState({ activeRow: { ...this.state.activeRow, ...apply } });
        }
    };

    onAddRow = (indicator: SaveOrderTechIndicator) => {
        if (this.fieldProps) {
            const indicators: SaveOrderTechIndicator[] = this.fieldProps.field.value;
            const newValue = [...indicators, indicator];
            this.fieldProps.form.setFieldValue(this.props.name, newValue);
            this.fieldProps.form.setFieldTouched(this.props.name, true);
            this.onActivateRow({ ...indicator });
        }
    };

    onRemoveRow = (ipd: SaveOrderTechIndicator) => {
        if (this.fieldProps) {
            const fieldProps = this.fieldProps;

            const ipds: SaveOrderTechIndicator[] = fieldProps.field.value;
            const newValue = ipds.filter((i) => i.guid !== ipd.guid);
            fieldProps.form.setFieldValue(this.props.name, newValue);
            fieldProps.form.setFieldTouched(this.props.name, true);

            setTimeout(() => fieldProps.form.validateField(this.props.name));
        }
    };

    onSaveRow = (ipd: SaveOrderTechIndicator) => {
        if (this.fieldProps) {
            const fieldProps = this.fieldProps;

            const ipds: SaveOrderTechIndicator[] = fieldProps.field.value;
            const newValue = ipds.map((i) => (i.guid === ipd.guid ? { ...ipd } : i));

            fieldProps.form.setFieldValue(this.props.name, newValue);
            fieldProps.form.setFieldTouched(this.props.name, true);
            this.onActivateRow(null);

            setTimeout(() => fieldProps.form.validateField(this.props.name));
        }
    };

    onActivateRow = (row: SaveOrderTechIndicator | null) => this.setState({ activeRow: row });

    getOrderId = () => this.props.store.details.id || EMPTY_OBJECT_ID;

    navigationPromt = (location: Location) => {
        // just tabs switching
        return location.pathname.startsWith(routes.orders.techIndicatorTab(this.getOrderId())) ? true : false;
    };
}

export const OrderTechIndicator = observer(TechIndicator);

interface TechIndicatorProps {
    name: string;
    orderDictionary: OrderDictionaryType;
    readOnly: boolean;
    baseUrl: string;
    onPrint: (content: string) => Promise<boolean | undefined>;

    store: OrdersStoreType;
    schema: any;
    orderId: string;
}
interface Select {
    id: string;
    label: string;
}
interface TechIndicatorState {
    activeRow: SaveOrderTechIndicator | null;
}

function getFormValues(order: OrderSummaryType) {
    const result = {
        [fields.orderTechIndicators]: order.techIndicators.map(mapTechIndicator),
    };

    return result;
}
