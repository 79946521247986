import React from "react";

import styles from "./ImportSpendingsPage.module.scss";
import { observer } from "mobx-react";
import { ImportSpendingsStoreType } from "../models/import-store";
import { FileUploaderHOC } from "modules/common/components/files/Uploader";

import { ImportTable } from "./Table";
import { texts } from "modules/common/texts";

import { Page } from "modules/common/components/page/Page";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { LocalLoader } from "modules/common/components/loader/Loader";

class Base extends React.Component<ImportSpendingsPageProps> {
    render() {
        const { store } = this.props;

        return (
            <Page className={styles.page}>
                <LocalLoader active={store.isLoading} />

                <FileUploaderHOC
                    accept="plain/text"
                    onFileSelected={store.upload}
                    render={({ onClick, loading }) => (
                        <PlanrButton
                            type="graybtn"
                            icon="general-plus"
                            title={texts.upload}
                            onClick={onClick}
                            round={true}
                            disabled={!loading ? false : true}
                            style={{ marginBottom: "24px" }}
                        />
                    )}
                />

                <ImportTable store={store} />
            </Page>
        );
    }
}

export const ImportSpendingsPage = observer(Base);

interface ImportSpendingsPageProps {
    store: ImportSpendingsStoreType;
}
