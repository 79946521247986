import { IToasterProps, Toaster } from "@blueprintjs/core";
import styles from "./App.module.scss";

export const ToasterOptions: IToasterProps = {
  position: "top-right",
  usePortal: false,
  className: styles.mainToaster,
};

/** Static notification service */
export const Notificator = Toaster.create(ToasterOptions);
