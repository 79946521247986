import Schema from "../details/validation";
import { getColumnSize } from "modules/common/services/table/size-storage";
import { getFieldLabel } from "modules/common/services/form/fields";
import { fields } from "modules/dictionaries/production-stages/models/production-stage";
import { ColDef } from "@ag-grid-community/core";
import { RowNumberColumn } from "modules/common/services/table/helpers";

const schema = Schema();

export const columns = (tableName: string) => {
    const columnSize = getColumnSize(tableName);

    const result: ColDef[] = [
        RowNumberColumn(false, ""),
        {
            headerName: getFieldLabel(fields.name, schema, null),
            field: fields.name,
            width: columnSize(fields.name, 300),
            sortable: true,
            resizable: true,
        },
        {
            headerName: getFieldLabel(fields.comment, schema, null),
            field: fields.comment,
            width: columnSize(fields.comment, 700),
            sortable: false,
            resizable: true,
        },
    ];

    return result;
};
