import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { OrderStatus, OrderStatusType, OrderStatusSnapshotType, fields } from "./order-status";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
// import { getIn } from 'formik';
// import { orderBy } from 'lodash';
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { Queryable } from "modules/common/models/queryable";

export const OrderStatusList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            orderStatuses: types.array(OrderStatus),
            sorter: TableSorter,
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<OrderStatusType> {
            return self.orderStatuses.reduce((result, status) => {
                result[status.id] = status;
                return result;
            }, {} as TStringMap<OrderStatusType>);
        },

        get data() {
            const predicate = (o: OrderStatusType) => {
                let result = true;
                if (self.pureQuery) {
                    result = result && `${o.name} ${o.description}`.toLowerCase().indexOf(self.pureQuery) >= 0;
                }
                return result;
            };
            return self.orderStatuses.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));
            // const order = self.sorter.asc ? 'asc' : 'desc';

            // return orderBy(
            //     getSnapshot(self.orderStatuses),
            //     [(o) => getIn(o, self.sorter.column)],
            //     [order]
            // );
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: any = yield self.transport.get<OrderStatusSnapshotType[]>(apiUrls.orderStatuses.list());
                applySnapshot(self.orderStatuses, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        delete: flow(function* (status: OrderStatusSnapshotType) {
            const model = OrderStatus.create(status, {
                http: self.transport,
                notificator: self.notify,
            });
            return yield model.delete();
        }),
    }))
    .named("OrderStatusList");

export type OrderStatusListType = typeof OrderStatusList.Type;
export type OrderStatusListSnapshotType = typeof OrderStatusList.SnapshotType;
export { OrderStatus };

const sortStorage = getSortOption(OrderStatusList.name);

export const initialState = (): OrderStatusListSnapshotType => {
    const options = sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        orderStatuses: [],
        pureQuery: "",
        query: "",
        sorter: {
            id: OrderStatusList.name,
            tableName: OrderStatusList.name,
            column: options.column,
            asc: options.asc,
        },
    };
};
