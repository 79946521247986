import { OrderSummaryType, fields } from "modules/orders-manage/models/order";
import React from "react";
import ReactDOM from "react-dom";

import { Field, FieldProps, Form, Formik } from "formik";
import { observer } from "mobx-react";
import styles from "./OrderAgents.module.scss";
// import { TechIndicatorRow } from "./TechIndicatorRow";
import { Location } from "history";

import { move } from "modules/common/services/array";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import portal from "../portal";

import { OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";

import { cloneDeep } from "lodash";
import { PlanrButton } from "modules/common/components/planr/button/Button";

import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import DenyNavigation from "modules/common/components/routing/DenyNavigation";
import { DATE_FORMAT, EMPTY_OBJECT_ID } from "modules/common/constants";
import { routes } from "modules/common/routes";
import { buildPatch } from "modules/common/services/form/values";
import { eat } from "modules/common/services/typescript";
import { OrdersStoreType } from "modules/orders-manage/models/orders-store";
import { OrderAgentsRow } from "./OrderAgentsRow";

import { Classes, Dialog } from "@blueprintjs/core";
import RequestEmail from "modules/agents/outsourcers/components/request-email/RequestEmail";
import { SupplierType } from "modules/agents/suppliers/models/supplier";
import { SuppliersStoreType } from "modules/agents/suppliers/models/suppliers-store";
import { Buttons } from "modules/common/components/form";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { formatMoney } from "modules/common/components/money/Money";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { formatDateTime } from "modules/common/services/formatting/date";
import { AgentsCategoriesDictionaryType } from "modules/dictionaries/agents-categories/models/agents-categories-dictionary";
import { AgentPaymentType, SaveAgent, mapAgent } from "modules/orders-manage/models/order-agents";
import moment from "moment";
import { v4 } from "uuid";
import { MentorSelectRenderer } from "../orderCommunication/OrderCommunication";
import { OrderAgentsHeader } from "./OrderAgentsHeader";
import { OrderAgentsTotalRow } from "./OrderAgentsTotalRow";

class OrderAgents extends React.Component<OrderAgentsProps, OrderAgentsState> {
  private fieldProps: FieldProps | null = null;
  constructor(props: OrderAgentsProps) {
    super(props);

    this.state = {
      activeRow: null,
      selectedAgent: null,
      responses: [],
      deals: [],
      showMaterials: false,
      showOrderEmail: false,
      showPayments: false,
      showPaymentChosen: false,
      selectedMaterials: [],
      selectedPayments: [],
      startPayments: [],
      agentInfo: null,
    };
  }
  async componentDidMount() {
    const { orderId, store } = this.props;
    if (orderId !== EMPTY_OBJECT_ID) {
      await store.details.loadOrderAgents(orderId);

      if (store.details.orderAgents && store.details.orderAgents.length > 0) {
        const rows: any[] = [...store.details.orderAgents];
        let newResposes: { value: string; guid: string; activeGuid: string }[] = [];
        let newDeals: DealType[] = [];
        rows.forEach((r) => {
          let materials = [...r.materials];
          if (materials && materials.length > 0) {
            materials.forEach((c: string) => {
              newResposes.push({
                guid: v4(),
                activeGuid: r.guid,
                value: c,
              });
            });
          }

          let deals = [...r.deals];
          if (deals && deals.length > 0) {
            deals.forEach(
              (c: {
                sum: string;
                date: string;
                guid: string;
                automatic: boolean;
                comment: string;
                isCash: boolean;
                name: string;
                employerId: string;
              }) => {
                newDeals.push({
                  guid: c.guid,
                  activeGuid: r.guid,
                  automatic: c.automatic,
                  comment: c.comment,
                  sum: c.sum,
                  date: c.date,
                  isCash: c.isCash,
                  name: c.name,
                  employerId: c.employerId,
                });
              }
            );
          }
        });
        this.setState({ responses: newResposes, deals: newDeals });
      }
    }
  }
  // async componentDidUpdate() {
  //     const { orderId, store } = this.props;
  //     if (orderId !== EMPTY_OBJECT_ID) {
  //         const arr = await store.details.loadStages(orderId);
  //         if (arr !== null) {
  //             this.setState({ stages: arr });
  //         }
  //     }
  // }
  render() {
    const { name, readOnly, store, schema, orderId, agents, agentsCategories, orderDictionary, baseUrl } = this.props;
    const {
      activeRow,
      selectedAgent,
      selectedMaterials,
      showMaterials,
      agentInfo,
      showOrderEmail,
      showPayments,
      showPaymentChosen,
      selectedPayments,
    } = this.state;
    const initial = getFormValues(store.details);

    return (
      <Formik
        initialValues={initial}
        enableReinitialize={true}
        validationSchema={schema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          let patch: any;

          if (store.details.isNewlyCreated) {
            patch = cloneDeep(values);
          } else {
            patch = buildPatch(initial, values);
          }
          const orderSnapshot = await store.saveOrderAgents(patch);

          setSubmitting(false);

          if (!!orderSnapshot) {
            store.details.refreshOrderAgents(orderSnapshot);
            resetForm();
            // this.onOrderSaved();
          }

          if (orderSnapshot === null) {
            resetForm();
          }
        }}
        onReset={() => {
          const { orderId, store } = this.props;
          if (orderId !== EMPTY_OBJECT_ID) {
            if (store.details.orderAgents && store.details.orderAgents.length > 0) {
              const rows: any[] = [...store.details.orderAgents];
              let newResposes: { value: string; guid: string; activeGuid: string }[] = [];
              let newDeals: DealType[] = [];

              rows.forEach((r) => {
                let materials = [...r.materials];
                if (materials && materials.length > 0) {
                  materials.forEach((c: string) => {
                    newResposes.push({
                      guid: v4(),
                      activeGuid: r.guid,
                      value: c,
                    });
                  });
                }
                let deals = [...r.deals];
                if (deals && deals.length > 0) {
                  deals.forEach(
                    (c: {
                      sum: string;
                      date: string;
                      guid: string;
                      automatic: boolean;
                      comment: string;
                      isCash: boolean;
                      name: string;
                      employerId: string;
                    }) => {
                      newDeals.push({
                        guid: c.guid,
                        activeGuid: r.guid,
                        sum: c.sum,
                        automatic: c.automatic,
                        comment: c.comment,
                        date: c.date,
                        isCash: c.isCash,
                        name: c.name,
                        employerId: c.employerId,
                      });
                    }
                  );
                }
              });
              this.setState({ responses: newResposes, deals: newDeals });
            }
          }
        }}
      >
        {(formProps) => {
          if (orderId !== EMPTY_OBJECT_ID) {
            return (
              <Form autoComplete="off">
                {showOrderEmail && (
                  <DetailsPopup title={"Запросы"} onCancel={this.toggleShowRequestEmail} width={1454}>
                    <RequestEmail
                      agentsList={[...agents.list.suppliers]}
                      categories={store.agentsCategories}
                      baseUrl={baseUrl}
                      store={store.sendEmailsList}
                      orderDictionary={orderDictionary}
                      toggleClose={this.toggleShowRequestEmail}
                      orderId={orderId}
                    />
                  </DetailsPopup>
                )}
                <Dialog
                  title={`Выберите материалы`}
                  isOpen={showMaterials}
                  onClose={this.cancelShowMaterials}
                  backdropClassName="standard"
                  className={`${styles.smallDialog} ${styles.workDialog} figma-dialog`}
                >
                  <div className={`${Classes.DIALOG_BODY} `}>
                    <div className={styles.workItemContainer}>
                      {agentInfo?.materials.map((unit) => {
                        const item = selectedMaterials.find((u) => u === unit);
                        return (
                          <div className={styles.workItem} key={unit}>
                            <PlanrButton
                              icon={item ? "general-checkbox" : "general-zero-checkbox"}
                              type="neutral"
                              size="small"
                              onClick={() => {
                                this.setSelectedMaterials(unit);
                              }}
                            />
                            <div>{unit}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={Classes.DIALOG_FOOTER}>
                    <Buttons
                      left={
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <PlanrButton
                            size="small"
                            type="greenish"
                            onClick={() => {
                              this.saveSelectedUnits();
                            }}
                          >
                            Сохранить
                          </PlanrButton>
                          <PlanrButton size="small" type="graybtn" onClick={this.cancelShowMaterials}>
                            Отмена
                          </PlanrButton>
                        </div>
                      }
                    />
                  </div>
                </Dialog>
                <DenyNavigation
                  when={formProps.dirty}
                  message="На странице остались несохраненные изменения"
                  skip={this.navigationPromt}
                />

                <div className={styles.ipd}>
                  <div className="planr-tools"></div>

                  <Field name={name}>
                    {(fieldProps: FieldProps) => {
                      this.fieldProps = fieldProps;
                      const values: SaveAgent[] = fieldProps.field.value;

                      const item = selectedAgent ? selectedAgent : { id: "", label: "", additionalParam: "" };
                      const deals = [...this.state.deals];
                      const paymentsInDeals: string[] = [];
                      store.details.orderAgentPayments.forEach((payment) => {
                        deals.forEach((d) => {
                          if (d.guid === payment.guid) {
                            paymentsInDeals.push(d.guid);
                          }
                        });
                      });
                      const agentPayments = store.details.orderAgentPayments.filter(
                        (p) => !paymentsInDeals.includes(p.guid)
                      );

                      //const paymentscount = store.details.orderAgentPayments.length;
                      const paymentscount = agentPayments.length;
                      const paymentSum = agentPayments.reduce((a, b) => a + b.sum, 0);
                      const items = [...agents.list.suppliers].map((a) => ({
                        id: a.id,
                        label: a.name,
                        additionalParam: a.agentReward,
                      }));
                      return (
                        <div className={styles.contents}>
                          <Dialog
                            title={`Нераспределенные платежи`}
                            isOpen={showPayments}
                            onClose={this.closeShowPayments}
                            backdropClassName="standard"
                            className={` ${styles.workDialog} figma-dialog`}
                          >
                            <div className={`${Classes.DIALOG_BODY} `}>
                              <div>
                                <div className={styles.headerItem}>
                                  <div className={styles.icon}></div>
                                  <div className={styles.sum}>Сумма</div>
                                  <div className={styles.date}>Дата</div>
                                  <div className={styles.comment}>Примечания</div>
                                </div>
                                {agentPayments.map((payment) => {
                                  const item = selectedPayments.find((u) => u.paymentGuid === payment.guid);
                                  return (
                                    <div
                                      className={styles.paymentItem}
                                      key={payment.guid}
                                      onClick={
                                        showPaymentChosen
                                          ? () => {
                                              this.setSelectedPayments(payment.guid);
                                            }
                                          : eat
                                      }
                                    >
                                      <div className={styles.icon}>
                                        {showPaymentChosen && (
                                          <GeneralIcon type={item ? "general-checkbox" : "general-zero-checkbox"} />
                                        )}
                                      </div>
                                      <div className={styles.sum}>{formatMoney(payment.sum)}</div>
                                      <div className={styles.date}>{payment.date}</div>
                                      <div className={styles.comment}>{payment.comment}</div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                              <Buttons
                                left={
                                  showPaymentChosen ? (
                                    <div
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <PlanrButton
                                        size="small"
                                        type="greenish"
                                        onClick={() => {
                                          this.addPayments();
                                        }}
                                      >
                                        Сохранить
                                      </PlanrButton>
                                      <PlanrButton size="small" type="graybtn" onClick={this.closeShowPayments}>
                                        Отмена
                                      </PlanrButton>
                                    </div>
                                  ) : (
                                    <PlanrButton size="small" type="greenish" onClick={this.closeShowPayments}>
                                      Закрыть
                                    </PlanrButton>
                                  )
                                }
                              />
                            </div>
                          </Dialog>
                          <div className={styles.attached}>
                            {!readOnly && (
                              <div className={styles.rowAgent}>
                                <div className={styles.agentList}>
                                  <div className={styles.agentListlabel}>Выберите агента</div>
                                  <MentorSelectRenderer
                                    item={item}
                                    items={items}
                                    style={{ width: "480px" }}
                                    className={styles.mentorSelect}
                                    disabled={readOnly}
                                    onSelect={(item: SelectItem) => {
                                      this.setState({ selectedAgent: item });
                                    }}
                                  />
                                </div>
                                <div
                                  className="collapse-add"
                                  style={{
                                    marginLeft: "16px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <PlanrButton
                                    type="lightgreenish"
                                    icon="general-plus-big"
                                    onClick={
                                      selectedAgent
                                        ? async () => {
                                            const unit = await store.details.emptyOrderAgent(
                                              selectedAgent?.id,
                                              selectedAgent.additionalParam ? selectedAgent.additionalParam : ""
                                            );
                                            this.onAddRow(unit);
                                          }
                                        : eat
                                    }
                                  >
                                    Добавить агента
                                  </PlanrButton>
                                  <PlanrButton
                                    disabled={paymentscount === 0}
                                    type="secondary"
                                    onClick={() => {
                                      this.openShowPayments(false);
                                    }}
                                  >
                                    <div>
                                      <div>Нераспределенные платежи</div>
                                      <div>{paymentscount > 0 ? "(" + formatMoney(paymentSum) + ")" : ""}</div>
                                    </div>
                                  </PlanrButton>
                                  <PlanrButton
                                    type="lightblueish"
                                    icon="general-doc-check"
                                    onClick={this.toggleShowRequestEmail}
                                  >
                                    Запросы
                                  </PlanrButton>
                                </div>
                              </div>
                            )}

                            {values.length > 0 && <OrderAgentsTotalRow values={values} />}
                            {values.length > 0 && <OrderAgentsHeader />}
                          </div>
                          <div className={styles.listOrderAgents} style={readOnly ? { paddingTop: "102px" } : {}}>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                              <Droppable droppableId="ipd">
                                {(provided) => (
                                  <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {values.map((indicator, index) => {
                                      return (
                                        <Draggable
                                          key={indicator.guid}
                                          draggableId={indicator.guid}
                                          index={index}
                                          isDragDisabled={!!activeRow}
                                        >
                                          {(draggable, snapshot) => {
                                            const child = (
                                              <OrderAgentsRow
                                                payments={store.details.orderAgentPayments}
                                                key={indicator.guid}
                                                agent={indicator}
                                                active={activeRow}
                                                onActivate={this.onActivateRow}
                                                onChange={this.onChange}
                                                onRemove={this.onRemoveRow}
                                                readOnly={readOnly}
                                                onSave={this.onSaveRow}
                                                draggable={draggable}
                                                isDragging={snapshot.isDragging}
                                                agents={agents.list.suppliers}
                                                agentsCategories={agentsCategories.types}
                                                responses={this.state.responses}
                                                deals={this.state.deals}
                                                setResponse={this.setResponse}
                                                removeResponse={this.removeResponse}
                                                addResponse={this.addResponse}
                                                setDealSum={this.setDealSum}
                                                setDealDate={this.setDealDate}
                                                removeDeal={this.removeDeal}
                                                addDeal={this.addDeal}
                                                addPayment={this.addPayment}
                                                onShowMaterials={this.onShowMaterials}
                                              />
                                            );

                                            return snapshot.isDragging ? ReactDOM.createPortal(child, portal) : child;
                                          }}
                                        </Draggable>
                                      );
                                    })}

                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </div>
                        </div>
                      );
                    }}
                  </Field>
                </div>

                {!readOnly && (
                  <div className={styles.buttons}>
                    <div className={styles.buttonsLeft}>
                      <StandardFormButtons
                        {...formProps}
                        isRemoved={false}
                        what={""}
                        isNewlyCreated={true}
                        submitOnDirty={true}
                        onRemove={eat}
                      />
                    </div>
                  </div>
                )}
              </Form>
            );
          } else {
            return <h2>Сначала необходимо сохранить основные данные договора</h2>;
          }
        }}
      </Formik>
    );
  }

  toggleShowRequestEmail = () => this.setState({ showOrderEmail: !this.state.showOrderEmail });

  onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination || !this.fieldProps) {
      return;
    }

    // initial index
    const from = result.source.index;
    // new index
    const to = result.destination.index;

    const ipds: SaveAgent[] = this.fieldProps.field.value;
    const newValue = move(ipds, from, to);
    this.fieldProps.form.setFieldValue(this.props.name, newValue);
    this.fieldProps.form.setFieldTouched(this.props.name, true);
  };

  onChange = (changes: Array<{ field: string; value: any }>) => {
    if (this.state.activeRow) {
      const apply: any = {};
      changes.forEach((change) => (apply[change.field] = change.value));
      this.setState({ activeRow: { ...this.state.activeRow, ...apply } });
    }
  };

  onAddRow = (indicator: SaveAgent) => {
    if (this.fieldProps) {
      const indicators: SaveAgent[] = this.fieldProps.field.value;
      indicator.created = formatDateTime(new Date());
      const newValue = [...indicators, indicator];
      this.fieldProps.form.setFieldValue(this.props.name, newValue);
      this.fieldProps.form.setFieldTouched(this.props.name, true);
      this.onActivateRow({ ...indicator });
    }
  };

  onRemoveRow = (ipd: SaveAgent) => {
    if (this.fieldProps) {
      const fieldProps = this.fieldProps;

      const ipds: SaveAgent[] = fieldProps.field.value;
      const newValue = ipds.filter((i) => i.guid !== ipd.guid);
      fieldProps.form.setFieldValue(this.props.name, newValue);
      fieldProps.form.setFieldTouched(this.props.name, true);

      setTimeout(() => fieldProps.form.validateField(this.props.name));
    }
  };

  onSaveRow = (row: SaveAgent) => {
    if (this.fieldProps) {
      const fieldProps = this.fieldProps;

      const agents: SaveAgent[] = fieldProps.field.value;

      const newResponses = [...this.state.responses.filter((i) => i.value !== "")];
      const newDeals = [...this.state.deals.filter((i) => i.sum !== "")];
      let noDate = false;
      const newValue = agents.map((i) => {
        const responses = newResponses.filter((r) => r.activeGuid === i.guid);
        const resArr = responses.map((a) => a.value);

        const deals = newDeals.filter((r) => r.activeGuid === i.guid);
        const dealArr = deals.map((a) => {
          if (a.date === "" || a.date === "Invalid date" || a.date === null) {
            noDate = true;
          }
          return {
            sum: a.sum,
            guid: a.guid,
            date: a.date,
            isCash: a.isCash,
            employerId: a.employerId,
            automatic: a.automatic,
            name: a.name,
            comment: a.comment,
          };
        });
        return i.guid === row.guid
          ? { ...row, updated: formatDateTime(new Date()), materials: resArr, deals: dealArr }
          : i;
      });
      if (!noDate) {
        fieldProps.form.setFieldValue(this.props.name, newValue);
        fieldProps.form.setFieldTouched(this.props.name, true);
        this.onActivateRow(null, true);

        setTimeout(() => fieldProps.form.validateField(this.props.name));
      }
    }
  };

  onActivateRow = (row: SaveAgent | null, reset?: boolean) => {
    if (row) {
      let materials = [];

      materials = [...row.materials];
      const newRow = { ...row, materials };
      this.setState({ activeRow: newRow });
    } else {
      const activeRow = this.state.activeRow;

      if (activeRow && !reset) {
        let dealsAct = [...this.state.deals.filter((d) => d.activeGuid === activeRow.guid)];
        let deals = [...this.state.deals];
        let actDeals = activeRow.deals?.map((a) => a.guid);
        dealsAct.forEach((d) => {
          if (!actDeals.includes(d.guid)) {
            const index = deals.indexOf(d);
            deals.splice(index, 1);
          }
        });
        this.setState({ deals });
      }

      this.setState({ activeRow: row });
    }
  };

  getOrderId = () => this.props.store.details.id || EMPTY_OBJECT_ID;

  navigationPromt = (location: Location) => {
    // just tabs switching
    return location.pathname.startsWith(routes.orders.techIndicatorTab(this.getOrderId())) ? true : false;
  };

  setResponse = (value: string, guid: string) => {
    const responses = [...this.state.responses];
    const index = responses.findIndex((e) => e.guid === guid);
    if (index > -1) {
      const item = responses[index];
      responses.splice(index, 1, { ...item, value });
      this.setState({ responses });
    }
  };

  setDealSum = (sum: string, guid: string) => {
    const deals = [...this.state.deals];
    const index = deals.findIndex((e) => e.guid === guid);
    if (index > -1) {
      const item = deals[index];
      deals.splice(index, 1, { ...item, sum });
      this.setState({ deals });
    }
  };

  setDealDate = (date: Date | null, guid: string) => {
    const deals = [...this.state.deals];
    const index = deals.findIndex((e) => e.guid === guid);
    if (index > -1) {
      const item = deals[index];
      deals.splice(index, 1, { ...item, date: moment(date).format(DATE_FORMAT) });
      this.setState({ deals });
    }
  };

  removeDeal = (guid: string) => {
    const deals = [...this.state.deals];

    const index = deals.findIndex((e) => e.guid === guid);
    if (index > -1) {
      deals.splice(index, 1);

      this.setState({ deals });
    }
  };

  addDeal = (sum: string, activeGuid: string) => {
    const deals = [...this.state.deals];
    deals.push({
      guid: v4(),
      sum,
      date: "",
      activeGuid,
      automatic: false,
      comment: "",
      name: "",
      isCash: false,
      employerId: EMPTY_OBJECT_ID,
    });
    this.setState({ deals });
  };

  addPayment = (activeGuid: string) => {
    this.openShowPayments(true);
  };

  addPayments = () => {
    if (this.state.activeRow) {
      const guid = this.state.activeRow?.guid;
      const payments = [...this.state.selectedPayments];
      const deals = [...this.state.deals];
      let agentPayments = [...this.props.store.details.orderAgentPayments];

      payments.forEach((p) => {
        const payment = agentPayments.find((e) => e.guid === p.paymentGuid);
        if (payment) {
          agentPayments = agentPayments.filter((e) => e.guid !== p.paymentGuid);
          const index = deals.findIndex((e) => e.guid === payment.guid);
          if (index === -1) {
            deals.push({
              guid: payment.guid,
              sum: payment.sum.toString(),
              date: payment.date,
              automatic: true,
              comment: payment.comment,
              name: payment.name,
              employerId: payment.employerId,
              isCash: payment.isCash,
              activeGuid: guid,
            });

            this.setState({ deals });
          }
        }
      });

      this.closeShowPayments();
    }
  };

  removeResponse = (guid: string) => {
    const responses = [...this.state.responses];
    const index = responses.findIndex((e) => e.guid === guid);
    if (index > -1) {
      responses.splice(index, 1);
      this.setState({ responses });
    }
  };

  addResponse = (value: string, activeGuid: string) => {
    const responses = [...this.state.responses];
    responses.push({ guid: v4(), value, activeGuid });
    this.setState({ responses });
  };

  cancelShowMaterials = () => {
    this.setState({ showMaterials: false, selectedMaterials: [] });
  };

  closeShowPayments = () => {
    //this.props.store.details.setOrderAgentPayments(this.props.store.details.saveAgentPayments);

    this.setState({ showPayments: false, showPaymentChosen: false });
  };
  openShowPayments = (chosen: boolean) => {
    this.setState({ showPayments: true, showPaymentChosen: chosen, selectedPayments: [] });
  };

  setSelectedMaterials = (s: string) => {
    let newUnits = [...this.state.selectedMaterials];
    const index = newUnits.findIndex((unit) => unit === s);
    let newArr: string[] = newUnits;
    if (index > -1) {
      newArr = [...newUnits.slice(0, index), ...newUnits.slice(index + 1)];
    } else {
      newArr.push(s);
    }

    this.setState({ selectedMaterials: newArr });
  };

  setSelectedPayments = (s: string) => {
    let newUnits = [...this.state.selectedPayments];
    const index = newUnits.findIndex((unit) => unit.paymentGuid === s);
    let newArr: SelectedPayment[] = newUnits;
    if (index > -1) {
      newArr = [...newUnits.slice(0, index), ...newUnits.slice(index + 1)];
    } else {
      if (this.state.activeRow) newArr.push({ paymentGuid: s, rowGuid: this.state.activeRow?.guid });
    }

    this.setState({ selectedPayments: newArr });
  };

  saveSelectedUnits = () => {
    const responses = [...this.state.responses];
    if (this.state.selectedMaterials.length > 0) {
      this.state.selectedMaterials.forEach((element: string) => {
        responses.push({
          guid: v4(),
          value: element,
          activeGuid: this.state.activeRow ? this.state.activeRow.guid : "",
        });
        this.setState({ responses });
      });
    }
    this.setState({ showMaterials: false, selectedMaterials: [] });
  };

  onShowMaterials = (agent: SupplierType | null) => {
    this.setState({ showMaterials: true, agentInfo: agent });
  };
}

export const OrderAgentsPage = observer(OrderAgents);

interface OrderAgentsProps {
  name: string;
  orderDictionary: OrderDictionaryType;
  readOnly: boolean;
  baseUrl: string;
  agents: SuppliersStoreType;
  agentsCategories: AgentsCategoriesDictionaryType;
  onPrint: (content: string) => Promise<boolean | undefined>;

  store: OrdersStoreType;
  schema: any;
  orderId: string;
}
interface Select {
  id: string;
  label: string;
}
interface OrderAgentsState {
  activeRow: SaveAgent | null;
  selectedAgent: SelectItem | null;
  responses: { value: string; guid: string; activeGuid: string }[];
  deals: DealType[];
  showMaterials: boolean;
  selectedMaterials: string[];
  selectedPayments: SelectedPayment[];
  agentInfo: SupplierType | null;
  showOrderEmail: boolean;
  showPayments: boolean;
  showPaymentChosen: boolean;
  startPayments: AgentPaymentType[];
}

export type DealType = {
  sum: string;
  date: string;
  guid: string;
  activeGuid: string;
  isCash: boolean;
  name: string;
  employerId: string;
  automatic: boolean;
  comment: string;
};

type SelectedPayment = {
  paymentGuid: string;
  rowGuid: string;
};
function getFormValues(order: OrderSummaryType) {
  const result = {
    [fields.orderAgents]: order.orderAgents.map(mapAgent),
  };

  return result;
}
