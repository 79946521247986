import { types } from "mobx-state-tree";
import { DictionaryLink } from "modules/common/models/dictionary-link";

export const ExtendedOrderLink = types
    .compose(
        DictionaryLink,
        types.model({
            inventoryNumber: types.number,
            fullInventoryNumber: types.string,
        })
    )
    .named("ExtendedOrderLink");

export const StringifiedOrderLink = types
    .compose(
        DictionaryLink,
        types.model({
            inventoryNumber: types.string,
        })
    )
    .named("StringifiedOrderLink");

export type StringifiedOrderLinkType = typeof StringifiedOrderLink.Type;
export type ExtendedOrderLinkSnapshotType = typeof ExtendedOrderLink.SnapshotType;
