import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { Indicator, IndicatorType, IndicatorSnapshotType, fields } from "./indicator";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
// import { getIn } from "formik";
// import { orderBy } from "lodash";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { Queryable } from "modules/common/models/queryable";

export const IndicatorList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            indicators: types.array(Indicator),
            sorter: TableSorter,
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<IndicatorType> {
            return self.indicators.reduce((result, ipdType) => {
                result[ipdType.id] = ipdType;
                return result;
            }, {} as TStringMap<IndicatorType>);
        },

        get data() {
            const predicate = (o: IndicatorType) => {
                let result = true;
                if (self.pureQuery) {
                    result = result && `${o.label}  `.toLowerCase().indexOf(self.pureQuery) >= 0;
                }
                return result;
            };
            return self.indicators.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));
            // const order = self.sorter.asc ? "asc" : "desc";

            // return orderBy(getSnapshot(self.orderIndicators), [(o) => getIn(o, self.sorter.column)], [order]);
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: any = yield self.transport.get<IndicatorSnapshotType[]>(apiUrls.indicators.list());
                applySnapshot(self.indicators, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        delete: flow(function* (ipdType: IndicatorSnapshotType) {
            const model = Indicator.create(ipdType, {
                http: self.transport,
                notificator: self.notify,
            });
            return yield model.delete();
        }),
    }))
    .named("IndicatorList");

export type IndicatorsListType = typeof IndicatorList.Type;
export type IndicatorListSnapshotType = typeof IndicatorList.SnapshotType;
export { Indicator };

const sortStorage = getSortOption(IndicatorList.name);

export const initialState = (): IndicatorListSnapshotType => {
    const options = sortStorage({ column: fields.label, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        indicators: [],
        pureQuery: "",
        query: "",
        sorter: {
            id: IndicatorList.name,
            tableName: IndicatorList.name,
            column: options.column,
            asc: options.asc,
        },
    };
};
