import React from "react";
import { UploaderProps, FileUploaderHOC } from "./Uploader";
import { intent, icon as notificationIcon, timeout } from "modules/root/components/app/Notifications";
import styles from "./DndUploader.module.scss";
import { LocalLoader } from "../loader/Loader";
import { Notificator } from "modules/root/components/app/toaster";
import { take } from "lodash";
import { MimeTypesEntries } from "modules/common/services/files";

const MAX = 5;

export class DndUploader extends React.Component<DndUploaderProps, DndUploaderState> {
    private dropRef = React.createRef<HTMLDivElement>();
    private dragCounter = 0;

    constructor(props: DndUploaderProps) {
        super(props);

        this.state = { dragging: false, loading: false };
    }

    componentDidMount() {
        const div = this.dropRef.current;
        if (div) {
            div.addEventListener("dragenter", this.handleDragIn);
            div.addEventListener("dragleave", this.handleDragOut);
            div.addEventListener("dragover", this.handleDrag);
            div.addEventListener("drop", this.handleDrop);
        }
    }

    componentWillUnmount() {
        const div = this.dropRef.current;
        if (div) {
            div.removeEventListener("dragenter", this.handleDragIn);
            div.removeEventListener("dragleave", this.handleDragOut);
            div.removeEventListener("dragover", this.handleDrag);
            div.removeEventListener("drop", this.handleDrop);
        }
    }

    render() {
        const { withLoader, accept, children } = this.props;
        const help =
            accept && accept !== "*"
                ? accept
                      .split(",")
                      .map((mime) => MimeTypesEntries[mime])
                      .join(", ")
                : "";

        return (
            <div
                className={`dnd-uploader ${styles.dndUploader} ${this.state.dragging ? styles.dragging : ""}`}
                ref={this.dropRef}
            >
                {withLoader && <LocalLoader active={this.state.loading} />}

                <div className={styles.row}>
                    Перетащите файл сюда или&nbsp;
                    <FileUploaderHOC
                        accept={accept}
                        onFileSelected={this.handleSelect}
                        render={({ onClick }) => (
                            <span className={styles.link} onClick={onClick}>
                                выбрать
                            </span>
                        )}
                    />
                </div>

                {help && <div className={styles.row}>{help}</div>}

                <div className={styles.row}>{children}</div>
            </div>
        );
    }

    handleDrag = (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
    };

    handleDragIn = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        this.dragCounter++;

        if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ dragging: true });
        }
    };

    handleDragOut = (e: Event) => {
        e.preventDefault();
        e.stopPropagation();

        this.dragCounter--;

        if (this.dragCounter === 0) {
            this.setState({ dragging: false });
        }
    };

    handleSelect = (file: File) => {
        return this.filesSelected([file] as any);
    };

    handleDrop = async (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ dragging: false });

        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            await this.filesSelected(e.dataTransfer.files);

            e.dataTransfer.clearData();
            this.dragCounter = 0;
        }
    };

    filesSelected = async (files: FileList) => {
        const { multiple, accept } = this.props;

        const source = take(
            (multiple ? Array.from(files) : [files[0]]).filter((file) => {
                const fileType = file.type || "_unknown_";

                if (accept && accept !== "*" && !accept.includes(fileType)) {
                    return false;
                }

                return true;
            }),
            MAX
        );

        if (!source.length) {
            return;
        }

        this.setState({ loading: true });

        try {
            for (let index = 0; index < source.length; index++) {
                const file = source[index];
                await this.props.onFileSelected(file);
            }
        } catch (er) {
            const message = typeof er === "string" ? er : er.message;

            Notificator.show({
                message,
                icon: notificationIcon("error"),
                intent: intent("error"),
                timeout: timeout("error"),
            });
        } finally {
            this.setState({ loading: false });
        }
    };
}

interface DndUploaderState {
    dragging: boolean;
    loading: boolean;
}

interface DndUploaderProps extends UploaderProps {
    withLoader?: boolean;
    multiple?: boolean;
}
