import { types } from "mobx-state-tree";
import { DATE_TIME_FORMAT } from "modules/common/constants";
import { FileMetadata } from "modules/common/models/file-metadata";
import { formatDate } from "modules/common/services/formatting/date";
import { capitalize } from "modules/common/services/strings";
import moment from "moment";

export const OrderDocument = types
  .model({
    fileId: types.string,
    fileName: types.string,
    mimeType: types.optional(types.string, ""),
    previewMimeType: types.optional(types.string, ""),
  })
  .named("OrderDocument");

export const OrderFile = types
  .compose(
    OrderDocument,
    types.model({
      guid: types.string,
      employerId: types.string,
      startDate: types.maybeNull(types.string),
      orderNumber: types.string,
      sortOrder: types.number,
    })
  )
  .views((self) => ({
    get startDateAsDate() {
      return self.startDate ? moment(self.startDate, DATE_TIME_FORMAT).toDate() : null;
    },
  }))
  .named("OrderFile");

export const SentExpertiseFile = OrderDocument.named("SentExpertiseFile");

export const ExpertiseFile = OrderDocument.named("ExpertiseFile");

export const CompletionFile = OrderDocument.named("CompletionFile");

export const ConsignmentFile = OrderDocument.named("ConsignmentFile");

export const IpdFile = types
  .model({
    fileId: types.string,
    fileName: types.string,
  })
  .named("IpdFile");

export const OrderConfirmFile = FileMetadata;

export const IpdFileGuid = types
  .model({
    fileId: types.string,
    fileName: types.string,
    ipdGuid: types.string,
  })
  .named("IpdFileGuid");

export const TaskFile = types
  .model({
    id: types.string,
    name: types.string,
    type: types.string,
    path: types.maybeNull(types.string),
  })
  .named("TaskFile");

export const VersionFile = types
  .model({
    id: types.string,
    name: types.string,
    type: types.string,
  })
  .named("VersionFile");

export const ClientFeedbackFile = types
  .model({
    fileId: types.string,
    fileName: types.string,
  })
  .named("ClientFeedbackFile");

export const InvoiceFile = types
  .model({
    fileId: types.string,
    fileName: types.maybeNull(types.string),
  })
  .named("InvoiceFile");

export type OrderDocumentSnapshotType = typeof OrderDocument.SnapshotType;
export type OrderFileSnapshotType = typeof OrderFile.SnapshotType;
export type ExpertiseFileSnapshotType = typeof ExpertiseFile.SnapshotType;
export type IpdFileType = typeof IpdFile.Type;
export type IpdFileSnapshotType = typeof IpdFile.SnapshotType;

export type OrderConfirmFileType = typeof OrderConfirmFile.Type;
export type OrderConfirmFileSnapshotType = typeof OrderConfirmFile.SnapshotType;

export type IpdFileGuidSnapshotType = typeof IpdFileGuid.SnapshotType;
export type ClientFeedbackFileType = typeof ClientFeedbackFile.Type;
export type ClientFeedbackFileSnapshotType = typeof ClientFeedbackFile.SnapshotType;
export type InvoiceFileType = typeof InvoiceFile.Type;
export type InvoiceFileSnapshotType = typeof InvoiceFile.SnapshotType;
export type ConsignmentFileType = typeof ConsignmentFile.Type;
export type ConsignmentFileSnapshotType = typeof ConsignmentFile.SnapshotType;
export type CompletionFileType = typeof CompletionFile.Type;
export type CompletionFileSnapshotType = typeof CompletionFile.SnapshotType;

export const OrderFileLabel = (what: string) => (file: OrderFileSnapshotType) =>
  `${capitalize(what)} № ${file.orderNumber} ${file.startDate ? "от " + formatDate(file.startDate as any) : ""}`;

export const OrderFileSorter = (o: OrderFileSnapshotType) => -o.sortOrder;

// export const consignmentFileFields = {
//     guid: nameof((a: ConsignmentFileType) => a.guid) as string,
//     comment: nameof((a: ConsignmentFileType) => a.comment) as string,
//     signDate: nameof((a: ConsignmentFileType) => a.signDate) as string,
//     number: nameof((a: ConsignmentFileType) => a.number) as string,
//     fileId: nameof((a: ConsignmentFileType) => a.fileId) as string,
// };

export interface ConsignmentFileModel extends Omit<ConsignmentFileSnapshotType, "signDate"> {
  signDate: Date | null;
}

// export const mapConsignment = (c: ConsignmentFileType): ConsignmentFileModel => ({
//     comment: c.comment,
//     fileId: c.fileId,
//     fileName: c.fileName,
//     guid: c.guid,
//     number: c.number,
//     signDate: c.signDateAsDate,
// });

// export const emptyConsignment = (guid: string): ConsignmentFileModel => ({
//     comment: "",
//     fileId: "",
//     fileName: "",
//     guid,
//     number: "",
//     signDate: null,
// });
