import React from 'react';
import { Classes } from '@blueprintjs/core';

const SIZE = '16';

export const EmptySquare = () => {
    return (
        <svg className={Classes.ICON} width={SIZE} height={SIZE} xmlns='http://www.w3.org/2000/svg'>
            <rect x="0" y="0" height={SIZE} width={SIZE} style={{
                stroke: 'transparent',
                fill: 'transparent'
            }}/>
        </svg>
    );
}
