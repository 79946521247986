import * as yup from 'yup';
import { fields } from 'modules/dictionaries/overhead-types/models/overhead-type';
import { texts } from 'modules/common/texts';
const { object, string } = yup;

export const overheadTypeLabels = () => ({
    [fields.name]: texts.name,
    [fields.comment]: texts.comment,
});

const OverheadTypeSchema = () => {
    const labels = overheadTypeLabels();

    return object().shape({

        [fields.name]: string()
            .label(labels[fields.name])
            .required(),

        [fields.comment]: string()
            .label(labels[fields.comment])
            .notRequired(),
    });
}

export default OverheadTypeSchema;
