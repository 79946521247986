import React from "react";
import { WorkTypeDictionaryType } from "modules/dictionaries/work-types/models/work-type-dictionary";
import { fields, OrderSummaryType, sortOrderObjects, WorkTypeLinkType } from "modules/orders-manage/models/order";
import { observer } from "mobx-react";
import styles from "./Content.module.scss";
import { Field, FieldProps } from "formik";
import { ContentFactory } from "./ContentDetails";
import { Constants } from "modules/root/models/constants";
import { CategoryBlock, Selection } from "./CategoryBlock";
import { SectionCollapserType } from "modules/orders-manage/models/orders-store";
import { texts } from "modules/common/texts";
import { CollapseExpandAll } from "modules/common/components/table/CollapseExpandAll";
import { OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";
import { ProjectAssigner } from "modules/common/components/orders/ProjectAssigner";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { Classes, Dialog, Spinner } from "@blueprintjs/core";
import { Buttons } from "modules/common/components/form";
import { EmployerDictionaryType } from "modules/spending/employee/models/employee-dictionary";
import { OutsourcerDictionaryType } from "modules/agents/outsourcers/models/outsourcer-dictionary";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

class ContentBase extends React.Component<ContentProps, ContentState> {
    private fieldProps: FieldProps | null = null;
    private willKept: any = null;
    private orderObjects: any[] = [];
    constructor(props: ContentProps) {
        super(props);
        this.state = {
            selected: null,
            copyVisible: false,
            proxyLoading: false,
            acceptVisible: false,
            preparingPrint: false,
            isGenerate: false,
            showPrint: false,
            selectedUnits: [],
        };
    }

    render() {
        const {
            workTypes,
            baseUrl,
            contentFactory,
            name,
            order,
            readOnly,
            collapser,
            orderDictionary,
            employers,
            agents,
            isActiveTab,
        } = this.props;
        const { selected, copyVisible, proxyLoading, selectedUnits, showPrint, preparingPrint, isGenerate } =
            this.state;

        if (!isActiveTab) {
            return <div className={styles.content}></div>;
        }

        const collapsed = { ...collapser.plain };
        const categories = Array.from(Constants.workTypeCategory.values());
        const units: WorkTypeLinkType[] = [];
        order.objects.forEach((object) => {
            object.content.forEach((content) => {
                units.push(content);
            });
        });

        return (
            <div className={styles.content}>
                {copyVisible && (
                    <RemoveConfirmation<string>
                        what={() => "состав проекта"}
                        actionName="перезаписать"
                        onConfirmed={this.copyContent}
                        render={({ confirmRemoving }) => (
                            <ProjectAssigner
                                orders={orderDictionary}
                                onClose={this.closeOrders}
                                excludeId={order.id}
                                onAssign={confirmRemoving}
                                loading={proxyLoading}
                            />
                        )}
                    />
                )}
                <Dialog
                    title={`Очистка`}
                    isOpen={this.state.acceptVisible}
                    backdropClassName="standard"
                    className={`${styles.smallDialog} figma-dialog`}
                >
                    <div className={`${Classes.DIALOG_BODY}`}>
                        <p className={styles.clearInfo}>
                            Следующие разделы будут сохранены, т.к. содержат данные по затратам на аутсорсеров и
                            собственные силы:
                        </p>

                        <p className={styles.clearInfo}>
                            {this.willKept
                                ? this.willKept.map((data: any, key: number) => <span key={key}>{data}, </span>)
                                : null}
                        </p>
                        <p className={styles.clearInfo}>Продолжить?</p>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <Buttons
                            left={
                                <div style={{ display: "flex" }}>
                                    <PlanrButton
                                        type="graybtn"
                                        onClick={this.toggleAcceptVisible}
                                        size="small"
                                        style={{ marginRight: "10px" }}
                                    >
                                        Отмена
                                    </PlanrButton>
                                    <PlanrButton
                                        type="greenish"
                                        size="small"
                                        onClick={() => {
                                            this.copyItems();
                                            this.toggleAcceptVisible();
                                        }}
                                    >
                                        {texts.clear}
                                    </PlanrButton>
                                </div>
                            }
                        />
                    </div>
                </Dialog>
                <Dialog
                    title={
                        isGenerate
                            ? `Выберите разделы для формирования тома Состав проекта`
                            : `Выберите разделы для формирования накладной`
                    }
                    isOpen={showPrint}
                    backdropClassName="standard"
                    onClose={this.toggleShowPrint}
                    canEscapeKeyClose={false}
                    canOutsideClickClose={false}
                    className={`${styles.listDialog} figma-dialog`}
                >
                    <div className={`${Classes.DIALOG_BODY}`}>
                        <div className={styles.dialogBtn}>
                            <PlanrButton
                                type="secondary"
                                size="small"
                                onClick={() => {
                                    this.checkAllUnits(units);
                                }}
                            >
                                Выделить все
                            </PlanrButton>
                        </div>
                        <div className={styles.dialogTable}>
                            {units.map((unit) => (
                                <div className={styles.dialogRow} key={unit.guid}>
                                    <div className={styles.dialogCheck}>
                                        <GeneralIcon
                                            style={{ cursor: "pointer" }}
                                            type={
                                                selectedUnits.includes(unit.guid)
                                                    ? "general-checkbox"
                                                    : "general-zero-checkbox"
                                            }
                                            onClick={() => {
                                                this.pushUnit(unit.guid);
                                            }}
                                        />
                                    </div>
                                    <div className={styles.dialogPart}>{unit.partNumber}</div>
                                    <div className={styles.dialogName}>{unit.name}</div>
                                    <div className={styles.dialogDescription}>{unit.description}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <Buttons
                            left={
                                <div style={{ display: "flex" }}>
                                    <PlanrButton
                                        type="greenish"
                                        size="small"
                                        style={{ width: "152px" }}
                                        onClick={isGenerate ? this.generateReport : this.generateInvoice}
                                    >
                                        {!preparingPrint ? "Сформировать" : <Spinner intent="primary" size={16} />}
                                    </PlanrButton>
                                    <PlanrButton type="graybtn" onClick={this.toggleShowPrint} size="small">
                                        Отмена
                                    </PlanrButton>
                                </div>
                            }
                        />
                    </div>
                </Dialog>
                <div className="planr-tools">
                    <PlanrButton
                        type="graybtn"
                        icon="general-export"
                        onClick={() => {
                            this.toggleShowPrint();
                            this.setState({ isGenerate: false });
                        }}
                        round={true}
                        title="Сформировать накладную"
                    />

                    <PlanrButton
                        type="graybtn"
                        icon="general-document"
                        onClick={() => {
                            this.toggleShowPrint();
                            this.setState({ isGenerate: true });
                        }}
                        round={true}
                        title="Сформировать том Состав проекта"
                    />

                    {!readOnly && (
                        <PlanrButton
                            type="graybtn"
                            icon="general-copy"
                            onClick={this.showOrders}
                            round={true}
                            title="Скопировать из другого проекта"
                        />
                    )}
                    <PlanrButton
                        type="graybtn"
                        icon="general-print"
                        onClick={this.props.onPrint}
                        round={true}
                        title={texts.print}
                    />

                    <CollapseExpandAll onChange={this.collapseAll} />
                </div>

                <Field name={name}>
                    {(fieldProps: FieldProps) => {
                        this.fieldProps = fieldProps;

                        const orderNumber: string = fieldProps.form.values[fields.orderNumber];
                        const objects = sortOrderObjects(fieldProps.field.value);

                        return (
                            <div className={styles.contents}>
                                {categories.map((category) => {
                                    return (
                                        <CategoryBlock
                                            key={category}
                                            category={category}
                                            collapsed={!collapsed[category]}
                                            onToggleCollapse={() => collapser.toggle(category)}
                                            contentFactory={contentFactory}
                                            fieldProps={fieldProps}
                                            objects={objects}
                                            onSelect={this.selectContent}
                                            orderNumber={orderNumber}
                                            selected={selected}
                                            workTypes={workTypes}
                                            readOnly={readOnly}
                                            employers={employers}
                                            agents={agents!}
                                            onUpload={this.onUpload}
                                            onDownload={this.onDownload}
                                            baseUrl={baseUrl}
                                        />
                                    );
                                })}
                            </div>
                        );
                    }}
                </Field>
            </div>
        );
    }

    onUpload = async (file: File) => await this.props.order.uploadFile(file);

    onDownload = async (file: string) => await this.props.order.downloadFile([file]);

    checkAllUnits = (units: WorkTypeLinkType[]) => {
        let arr = this.state.selectedUnits;
        if (arr.length === units.length) {
            arr = [];
        } else {
            arr = [];
            units.forEach((unit) => {
                arr.push(unit.guid);
            });
        }
        this.setState({ selectedUnits: arr });
    };

    pushUnit = (guid: string) => {
        const arr = this.state.selectedUnits;
        const index = arr.findIndex((item) => item === guid);
        if (index > -1) {
            arr.splice(index, 1);
            this.setState({ selectedUnits: arr });
        } else {
            arr.push(guid);
            this.setState({ selectedUnits: arr });
        }
    };

    generateReport = async () => {
        this.setState({ preparingPrint: true });
        const result = await this.props.CreateDocument(this.state.selectedUnits);
        if (result) {
            this.setState({ preparingPrint: false });
            this.toggleShowPrint();
        } else {
            this.setState({ preparingPrint: false });
        }
    };

    generateInvoice = async () => {
        this.setState({ preparingPrint: true });
        const result = await this.props.CreateInvoice(this.state.selectedUnits);
        if (result) {
            this.setState({ preparingPrint: false });
            this.toggleShowPrint();
        } else {
            this.setState({ preparingPrint: false });
        }
    };

    toggleShowPrint = () => {
        this.setState({ showPrint: !this.state.showPrint });
    };

    selectContent = (selected: Selection | null) => this.setState({ selected });

    collapseAll = (state: boolean) => this.props.collapser.setAll(state);

    showOrders = () => this.setState({ copyVisible: true });

    closeOrders = () => this.setState({ copyVisible: false });
    toggleAcceptVisible = () => {
        this.setState({ acceptVisible: !this.state.acceptVisible });
    };
    copyContent = async (orderId: string) => {
        const { order } = this.props;

        const data = await order.getContentReplacement(orderId);

        if (data) {
            if (data.willBeKept.length > 0) {
                const keep = data.willBeKept;
                this.willKept = keep;
                this.orderObjects = data.orderObjects;
                this.closeOrders();
                this.toggleAcceptVisible();
            } else {
                this.orderObjects = data.orderObjects;
                this.copyItems();
            }
        } else {
            this.closeOrders();
            return;
        }
    };

    copyItems = () => {
        const newObjects = this.orderObjects.filter((obj) => obj.isCommon);
        this.fieldProps?.form.setFieldValue(this.fieldProps.field.name, newObjects);
        this.fieldProps?.form.setFieldTouched(this.fieldProps.field.name, true);
        this.closeOrders();
    };
}

export const Content = observer(ContentBase);

interface ContentProps {
    name: string;
    contentFactory: ContentFactory;
    workTypes: WorkTypeDictionaryType;
    readOnly: boolean;
    collapser: SectionCollapserType;
    orderDictionary: OrderDictionaryType;
    order: OrderSummaryType;
    onPrint: () => void;
    employers: EmployerDictionaryType;
    agents: OutsourcerDictionaryType;
    CreateDocument: (contents: string[]) => Promise<boolean | undefined>;
    CreateInvoice: (contents: string[]) => Promise<boolean | undefined>;
    baseUrl: string;
    isActiveTab: boolean;
}

interface ContentState {
    selected: Selection | null;
    copyVisible: boolean;
    proxyLoading: boolean;
    preparingPrint: boolean;
    acceptVisible: boolean;
    isGenerate: boolean;
    showPrint: boolean;
    selectedUnits: string[];
}
