import { types } from "mobx-state-tree";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "modules/common/constants";
import { BaseEntity } from "modules/common/models/entity";
import { asFileBase } from "modules/common/models/file-metadata";
import { Notificator } from "modules/common/models/notificator";
import { formatDate } from "modules/common/services/formatting/date";
import { nameof } from "modules/common/services/typescript";
import moment from "moment";
import { OrderConfirmFile, OrderConfirmFileType } from "./order-file";
import { StringifiedOrderLink } from "./order-link";

export const OrderConfirm = types
  .compose(
    BaseEntity,
    Notificator,
    types.model({
      number: types.string,
      date: types.string,
      day: types.string,
      dateSortable: types.string,
      belonging: types.string,
      orderId: types.string,
      order: types.maybeNull(StringifiedOrderLink),
      description: types.string,
      type: types.string,
      requestFiles: types.array(OrderConfirmFile),
      sourceFiles: types.array(OrderConfirmFile),
      resultFiles: types.array(OrderConfirmFile),
    })
  )
  .views((self) => ({
    get dateAsDate() {
      return moment(self.date, DATE_TIME_FORMAT);
    },
    get documentsAsMap() {
      let docs: OrderConfirmFileType[] = [];

      docs = self.requestFiles.concat(self.sourceFiles).concat(self.resultFiles);

      return docs.reduce((result, doc) => {
        result[doc.id] = doc;
        return result;
      }, {} as TStringMap<OrderConfirmFileType>);
    },
  }))
  .named("OrderConfirm");

export const mapConfirm = (confirm: OrderConfirmSnapshotType): SaveConfirm => ({
  id: confirm.id,
  number: confirm.number,
  orderId: confirm.orderId,
  date: confirm.date,
  belonging: confirm.belonging,
  description: confirm.description,
  type: confirm.type,
  created: confirm.created,
  requestFiles: confirm.requestFiles.map(asFileBase),
  sourceFiles: confirm.sourceFiles.map(asFileBase),
  resultFiles: confirm.resultFiles.map(asFileBase),
});

export interface SaveConfirm {
  id: string;
  number: string;
  date: string;
  orderId: string;
  belonging: string;
  description: string;
  type: string;
  created: string;
  requestFiles: FileBase[];
  sourceFiles: FileBase[];
  resultFiles: FileBase[];
}

export const confirmFields = {
  number: nameof((a: SaveConfirm) => a.number) as string,
  date: nameof((a: SaveConfirm) => a.date) as string,
  orderId: nameof((a: SaveConfirm) => a.orderId) as string,
  description: nameof((a: SaveConfirm) => a.description) as string,
  belonging: nameof((a: SaveConfirm) => a.belonging) as string,
  type: nameof((a: SaveConfirm) => a.type) as string,
  requestFiles: nameof((a: SaveConfirm) => a.requestFiles) as string,
  sourceFiles: nameof((a: SaveConfirm) => a.sourceFiles) as string,
  resultFiles: nameof((a: SaveConfirm) => a.resultFiles) as string,
  order: nameof((a: OrderConfirmType) => a.order) as string,
  day: nameof((a: OrderConfirmType) => a.day) as string,
};

export type OrderConfirmType = typeof OrderConfirm.Type;
export type OrderConfirmSnapshotType = typeof OrderConfirm.SnapshotType;

export const initialState = (): OrderConfirmSnapshotType => ({
  id: "",
  created: formatDate(new Date(), DATE_FORMAT),
  number: "",
  orderId: "",
  date: "",
  order: null,
  belonging: "",
  day: "",
  dateSortable: "",
  description: "",
  type: "",
  requestFiles: [],
  sourceFiles: [],
  resultFiles: [],
});
