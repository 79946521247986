import { Classes, FormGroup, InputGroup, TextArea } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { MoneyInput } from "modules/common/components/money/MoneyInput";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { preventSubmitKeyDown } from "modules/common/services/form/values";
import { texts } from "modules/common/texts";
import {
  EventedDateValue,
  OrderStageRowItem,
  PlanOrderPaymentDetailsRowType,
} from "modules/orders-manage/models/order-stages-store";
import { IdFactory } from "modules/orders-manage/types";
import React, { useCallback } from "react";
import { NumberFormatValues } from "react-number-format";
import { EventedDate } from "./EventedDate";
import { Error, star } from "./events/common";
import styles from "./PaymentRow.module.scss";

export const PaymentRow = observer(({ payment, payments, stages, additions, newId }: PaymentRowProps) => {
  const onSumChangeHandler = useCallback(
    (money: NumberFormatValues) => {
      payment.setSum(money.floatValue ?? 0);
    },
    [payment]
  );

  const onNameChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      payment.setName(e.target.value);
    },
    [payment]
  );

  const onProblemChangeHandler = useCallback(() => {
    payment.setProblem();
  }, [payment]);

  const onDescriptionChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      payment.setDescription(e.target.value);
    },
    [payment]
  );

  const onDateChangeHandler = useCallback(
    (value: EventedDateValue) => {
      payment.setDate(value);
    },
    [payment]
  );

  const errors = payment.validation;

  return (
    <div className={styles.paymentRow}>
      <div>
        <RemoveConfirmation<PlanOrderPaymentDetailsRowType>
          what={() => "Платеж"}
          render={({ confirmRemoving }) => (
            <PlanrButton
              type="secondary"
              icon="general-trash"
              onClick={() => confirmRemoving(payment)}
              size="small"
              title={texts.remove}
              style={{ marginBottom: "5px", width: "100%" }}
            >
              {texts.remove} платеж
            </PlanrButton>
          )}
          onConfirmed={(p) => p.remove()}
        />
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <FormGroup
          label="№"
          labelInfo={star}
          className={Classes.SMALL}
          inline={true}
          intent={errors.name ? Error : "none"}
          helperText={errors.name}
        >
          <InputGroup
            id="name"
            value={payment.name}
            className="planr-default-input"
            autoComplete="off"
            onChange={onNameChangeHandler}
            onKeyDown={preventSubmitKeyDown}
            placeholder="Номер"
          />
        </FormGroup>
        <FormGroup
          label="Сумма"
          labelInfo={star}
          className={Classes.SMALL}
          inline={true}
          intent={errors.sum ? Error : "none"}
          helperText={errors.sum}
        >
          <MoneyInput
            className="planr-default-input"
            id="sum"
            small={false}
            autoComplete="off"
            data-lpignore="true"
            value={payment.sum}
            onMoneyChange={onSumChangeHandler}
            onKeyDown={preventSubmitKeyDown}
          />
        </FormGroup>
        <FormGroup label="Претензия" className={Classes.SMALL} inline={true}>
          <PlanrButton
            type="neutral"
            icon={payment.hasProblem ? "general-checkbox" : "general-zero-checkbox"}
            onClick={onProblemChangeHandler}
            style={{ marginLeft: "20px", marginTop: "-3px" }}
          />
        </FormGroup>
        {payment.hasProblem && (
          <FormGroup label="Примечания к претензии" className={Classes.SMALL} inline={false}>
            <div className={`${Classes.INPUT_GROUP} planr-default-input`} style={{ marginBottom: "10px" }}>
              <TextArea
                value={payment.problemDescription}
                autoComplete="off"
                onChange={onDescriptionChangeHandler}
                growVertically={true}
                className={Classes.FILL}
                style={{ maxHeight: "220px" }}
              />
            </div>
          </FormGroup>
        )}
      </div>

      <div>
        <FormGroup
          labelInfo={star}
          className={Classes.SMALL}
          intent={errors.date ? Error : "none"}
          helperText={errors.date}
        >
          <EventedDate
            onChange={onDateChangeHandler}
            date={payment.concreteDateAsDate}
            days={0}
            workDaysOnly={false}
            events={payment.eventDates}
            newId={newId}
            excludeBelonging={payment.newGuid}
            payments={payments.filter((p) => p.newGuid !== payment.newGuid)}
            stages={stages}
            additions={additions}
          />
        </FormGroup>
      </div>
    </div>
  );
});

interface PaymentRowProps extends IdFactory {
  payment: PlanOrderPaymentDetailsRowType;
  payments: PlanOrderPaymentDetailsRowType[];
  stages: OrderStageRowItem[];
  additions: any;
}
