import React from "react";
import { fields, OrderSummarySnapshotType, OrderSummaryType } from "modules/orders-manage/models/order";
import { OrderSummaryLabels } from "../validation";
import { observer } from "mobx-react";
import { fixTextAreaHeight, MODAL_AWAIT_DELAY } from "modules/common/services/form/textarea";
import styles from "./Summary.module.scss";
import { texts } from "modules/common/texts";
import { StandardFormInput, ReadOnlyFormElement } from "modules/common/components/form/StandardFormInput";
import { InputGroup, Classes, TextArea, Checkbox } from "@blueprintjs/core";
import { ColorPicker } from "modules/common/components/form/ColorPicker";
import {
  renderSingleOption,
  filterItemPredicate,
  buildOptions,
  SimpleSelect,
  DefaultSelectedOption,
} from "modules/common/services/form/select";
import { ProjectPortfolioDictionaryType } from "modules/dictionaries/project-portfolios/models/project-portfolio-dictionary";
import { OrderStatusDictionaryType } from "modules/dictionaries/order-statuses/models/order-status-dictionary";
import { ClientDictionaryType } from "modules/agents/clients/models/client-dictionary";
import { OrderTypeDictionaryType } from "modules/dictionaries/order-types/models/order-type-dictionary";
import { ProductionStageDictionaryType } from "modules/dictionaries/production-stages/models/production-stage-dictionary";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { EmployerDictionaryType } from "modules/spending/employee/models/employee-dictionary";
import { OrderObjects, ObjectFactory } from "./OrderObjects";
import { formatMoney } from "modules/common/components/money/Money";
import { FormikProps } from "formik";
import { OrderBase } from "./OrderBase";
import { Schema } from "yup";
import { OrderFiles } from "./OrderFiles";
import {
  OrderFileSnapshotType,
  ClientFeedbackFileSnapshotType,
  OrderFileLabel,
  OrderFileSorter,
} from "modules/orders-manage/models/order-file";
import { UploaderFatory, IdFactory } from "modules/orders-manage/types";
import { EmployerSelect } from "modules/common/components/form/EmployerSelect";
import { FeedbackFile } from "./FeedbackFile";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { FinanceDashboard } from "./FinanceDashboard";
// import { ConsignmentFactory, Consignments } from "./Consignments";
import { AdditionFactory, Additions } from "./Additions";
import { Expertises } from "./Expertises";
import { OrderBaseWithName, OrderBaseWithNameFactory } from "./OrderBaseWithName";

const SummaryOrderLabel = OrderFileLabel("Договор");
const labels = OrderSummaryLabels();

class SummaryBase extends React.Component<SummaryProps> {
  private fullNameArea: HTMLTextAreaElement | null = null;

  private fullNameAreaRef = (ref: HTMLTextAreaElement | null) => {
    this.fullNameArea = ref;
  };

  private resize = () => {
    fixTextAreaHeight(this.fullNameArea);
  };

  public componentDidMount() {
    setTimeout(this.resize, MODAL_AWAIT_DELAY);
  }

  public componentDidUpdate() {
    this.resize();
  }

  render() {
    const { props } = this;
    if (!props.isActiveTab) {
      return <div className={styles.details}></div>;
    }

    const { formProps, portfolios, statuses, clients, types, stages, engineers, schema, storeDetails } = props;
    const { readOnly, withSensitive } = props;

    const savedEngineer = formProps.values.employer
      ? {
          id: formProps.values.employer.id,
          label: formProps.values.employer.name,
        }
      : null;

    const savedClient = formProps.values.client
      ? {
          id: formProps.values.client.id,
          label: formProps.values.client.name,
        }
      : null;

    const portfolioOptions = buildOptions(null, portfolios.portfolios, true);
    const statusOptions = buildOptions(null, statuses.isAvailable, true);
    const clientOptions = buildOptions(savedClient, clients.clients, true);
    const typesOptions = buildOptions(null, types.orderTypes, true);
    const stageOptions = buildOptions(null, stages.stages, true);
    const price = formProps.values.hidePrice ? 0 : formProps.values.price;

    let agentSum = 0;

    storeDetails.orderAgents.forEach((agent) => {
      agent.deals.forEach((deal) => {
        let hasPayment = false;
        storeDetails.orderAgentPayments.forEach((payment) => {
          if (payment.guid === deal.guid) {
            hasPayment = true;
          }
        });
        if (!hasPayment) {
          agentSum += +deal.sum;
        }
      });
    });
    storeDetails.orderAgentPayments.forEach((payment) => {
      agentSum += payment.sum;
    });

    return (
      <div className={styles.details}>
        <div className={styles.columns}>
          <div className={styles.column}>
            <div className={styles.columnHeader}>
              <span>Основные данные</span>
            </div>
            <div className={styles.columnRow}>
              {/* Инв. номер* */}
              <div style={{ height: "60", marginRight: "16px" }}>
                <StandardFormInput name={fields.fullInventoryNumber} schema={schema} small={true} inline={false}>
                  {({ field }) => (
                    <InputGroup
                      id={field.name}
                      {...field}
                      className="planr-default-input"
                      autoComplete="off"
                      data-lpignore="true"
                      maxLength={4}
                      readOnly={readOnly}
                      style={{ marginRight: "16px" }}
                    />
                  )}
                </StandardFormInput>
              </div>

              {/* Договор на сумму */}
              {withSensitive && (
                <div style={{ padding: "0" }}>
                  <ReadOnlyFormElement label={labels[fields.price]} value={formatMoney(price)} inline={false} />
                </div>
              )}
            </div>

            <div className={styles.agentsSum}>
              {withSensitive && agentSum > 0 && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>Агентские:</div>
                  <div className={styles.agentsSumColor}>{formatMoney(agentSum)}</div>
                </div>
              )}
            </div>

            {/* ГИП */}
            <StandardFormInput
              name={fields.engineerId}
              schema={schema}
              small={true}
              inline={false}
              // className={styles.formInput}
            >
              {(fieldProps) => {
                const clear = () => {
                  fieldProps.form.setFieldValue(fieldProps.field.name, "");
                  fieldProps.form.setFieldTouched(fieldProps.field.name, true);
                };

                return (
                  <div className={`${Classes.INPUT_GROUP}`}>
                    <EmployerSelect
                      employee={engineers.employee}
                      field={fieldProps}
                      saved={savedEngineer}
                      disabled={readOnly}
                      onClear={clear}
                    />
                  </div>
                );
              }}
            </StandardFormInput>

            {/* Внутренний проект */}
            <StandardFormInput name={fields.isInternal} schema={schema} small={true} style={{ marginBottom: "14px" }}>
              {({ field }) => {
                return (
                  <Checkbox
                    large={true}
                    checked={!!field.value}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      formProps.setFieldValue(field.name, e.currentTarget.checked, true);
                      formProps.setFieldTouched(field.name, true);
                    }}
                    disabled={readOnly}
                    label={labels[fields.isInternal]}
                  />
                );
              }}
            </StandardFormInput>

            <div className={styles.columnRow}>
              <div>
                <ReadOnlyFormElement
                  value={
                    formProps.values.stopDatePlanWarning !== null && formProps.values.stopDatePlanWarning !== ""
                      ? "Ошибка при расч."
                      : formProps.values.stopDayPlan !== null && formProps.values.stopDayPlan !== ""
                      ? formProps.values.stopDayPlan
                      : ""
                  }
                  small={false}
                  label={labels[fields.stopDatePlan]}
                  inline={false}
                  title={formProps.values.stopDatePlanWarning ?? ""}
                />
              </div>

              <div style={{ marginLeft: "16px" }}>
                <StandardFormInput
                  name={fields.stopDateActual}
                  schema={schema}
                  small={true}
                  inline={false}
                  // style={{ maxWidth: "150px" }}
                >
                  {({ field, form }) => {
                    return (
                      <DatePicker
                        clasName="planr-default-input"
                        value={field.value}
                        minDate={form.values[fields.startDate]}
                        onChange={(date) => {
                          form.setFieldValue(field.name, date);
                          form.setFieldTouched(field.name, true);
                        }}
                        readOnly={readOnly}
                      />
                    );
                  }}
                </StandardFormInput>
              </div>
            </div>

            {/* Заказчик */}
            <StandardFormInput
              name={fields.clientId}
              schema={schema}
              small={true}
              inline={false}
              style={{ marginBottom: "8px" }}
            >
              {({ field, form }) => {
                const option = clientOptions[field.value];

                const clear = () => {
                  form.setFieldValue(field.name, "");
                  form.setFieldTouched(field.name, true);
                };

                return (
                  <div className={`${Classes.INPUT_GROUP}`}>
                    <SimpleSelect
                      className={`full-width-select ${Classes.FILL}`}
                      filterable={true}
                      activeItem={option}
                      items={Object.values(clientOptions)}
                      itemRenderer={renderSingleOption}
                      inputProps={{
                        ...field,
                        placeholder: texts.search,
                      }}
                      onItemSelect={(item) => {
                        form.setFieldValue(field.name, item.id);
                        form.setFieldTouched(field.name, true);
                      }}
                      disabled={clients.isEmpty || readOnly}
                      itemPredicate={filterItemPredicate}
                    >
                      <DefaultSelectedOption
                        option={option}
                        empty={clients.isEmpty && !option}
                        onClear={readOnly ? undefined : clear}
                      />
                    </SimpleSelect>
                  </div>
                );
              }}
            </StandardFormInput>
            <StandardFormInput
              name={fields.name}
              schema={schema}
              small={true}
              inline={false}
              style={{ marginBottom: "8px" }}
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  {...field}
                  className="planr-default-input"
                  autoComplete="off"
                  data-lpignore="true"
                  readOnly={readOnly}
                  maxLength={70}
                />
              )}
            </StandardFormInput>
            <StandardFormInput name={fields.fullName} schema={schema} small={true} inline={false}>
              {({ field }) => (
                <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                  <TextArea
                    id={field.name}
                    {...field}
                    growVertically={true}
                    className={Classes.FILL}
                    inputRef={this.fullNameAreaRef}
                    readOnly={readOnly}
                  />
                </div>
              )}
            </StandardFormInput>

            {withSensitive && (
              <FinanceDashboard
                formProps={formProps}
                price={price}
                readOnly={readOnly}
                schema={schema}
                statuses={statuses}
              />
            )}
          </div>

          {/*-----------------column separator----------------*/}

          <div className={styles.column}>
            <div className={styles.columnHeader}>
              <span>Договор</span>
            </div>
            <div className={styles.radioGroup}>
              <div>
                <div className={styles.radioLabel}>Муниципальный контракт</div>
                <div style={{ display: "flex" }}>
                  <PlanrButton
                    type={(formProps.values as any)[fields.flags] === "municipal" ? "primary" : "graybtn"}
                    onClick={() => this.onFlagChange("municipal")}
                    size="small"
                    style={{
                      width: "85px",
                      marginRight: "0px",
                      borderBottomRightRadius: "0px",
                      borderTopRightRadius: "0px",
                    }}
                  >
                    Да
                  </PlanrButton>
                  <PlanrButton
                    type={(formProps.values as any)[fields.flags] !== "municipal" ? "primary" : "graybtn"}
                    onClick={() => this.onFlagChange("municipal")}
                    size="small"
                    style={{
                      width: "85px",
                      marginLeft: "0px",
                      borderBottomLeftRadius: "0px",
                      borderTopLeftRadius: "0px",
                    }}
                  >
                    Нет
                  </PlanrButton>
                </div>
              </div>

              <div style={{ marginLeft: "16px" }}>
                <div className={styles.radioLabel}>Рамочный договор</div>
                <div style={{ display: "flex" }}>
                  <PlanrButton
                    type={(formProps.values as any)[fields.flags] === "batch" ? "primary" : "graybtn"}
                    onClick={() => this.onFlagChange("batch")}
                    size="small"
                    style={{
                      width: "85px",
                      marginRight: "0px",
                      borderBottomRightRadius: "0px",
                      borderTopRightRadius: "0px",
                    }}
                  >
                    Да
                  </PlanrButton>
                  <PlanrButton
                    type={(formProps.values as any)[fields.flags] !== "batch" ? "primary" : "graybtn"}
                    onClick={() => this.onFlagChange("batch")}
                    size="small"
                    style={{
                      width: "85px",
                      marginLeft: "0px",
                      borderBottomLeftRadius: "0px",
                      borderTopLeftRadius: "0px",
                    }}
                  >
                    Нет
                  </PlanrButton>
                </div>
              </div>
            </div>
            <div className={styles.radioGroup}>
              <div>
                <div className={styles.radioLabel}>Федеральный договор</div>
                <div style={{ display: "flex" }}>
                  <PlanrButton
                    type={(formProps.values as any)[fields.flags] === "federal" ? "primary" : "graybtn"}
                    onClick={() => this.onFlagChange("federal")}
                    size="small"
                    style={{
                      width: "85px",
                      marginRight: "0px",
                      borderBottomRightRadius: "0px",
                      borderTopRightRadius: "0px",
                    }}
                  >
                    Да
                  </PlanrButton>
                  <PlanrButton
                    type={(formProps.values as any)[fields.flags] !== "federal" ? "primary" : "graybtn"}
                    onClick={() => this.onFlagChange("federal")}
                    size="small"
                    style={{
                      width: "85px",
                      marginLeft: "0px",
                      borderBottomLeftRadius: "0px",
                      borderTopLeftRadius: "0px",
                    }}
                  >
                    Нет
                  </PlanrButton>
                </div>
              </div>

              <div style={{ marginLeft: "16px" }}>
                <div className={styles.radioLabel}>Региональный договор</div>
                <div style={{ display: "flex" }}>
                  <PlanrButton
                    type={(formProps.values as any)[fields.flags] === "region" ? "primary" : "graybtn"}
                    onClick={() => this.onFlagChange("region")}
                    size="small"
                    style={{
                      width: "85px",
                      marginRight: "0px",
                      borderBottomRightRadius: "0px",
                      borderTopRightRadius: "0px",
                    }}
                  >
                    Да
                  </PlanrButton>
                  <PlanrButton
                    type={(formProps.values as any)[fields.flags] !== "region" ? "primary" : "graybtn"}
                    onClick={() => this.onFlagChange("region")}
                    size="small"
                    style={{
                      width: "85px",
                      marginLeft: "0px",
                      borderBottomLeftRadius: "0px",
                      borderTopLeftRadius: "0px",
                    }}
                  >
                    Нет
                  </PlanrButton>
                </div>
              </div>
            </div>

            {/* Номер и дата договора */}
            <div className={styles.columnRow}>
              <OrderBase
                inline={false}
                what="Договор"
                schema={schema}
                number={formProps.values.orderNumber}
                date={formProps.values.startDate as any}
                onChange={(prop, value) => {
                  formProps.setFieldValue(prop, value);
                  formProps.setFieldTouched(prop, value);
                }}
                addOrderFile={this.addOrderFile}
                readOnly={readOnly}
                upload={this.props.upload}
                newId={this.props.newId}
                noUploader={!withSensitive || formProps.values.otherOrders.length >= 1}
              />
            </div>

            {withSensitive && (
              <>
                {/* Список файлов договора */}
                {formProps.values.otherOrders.length >= 1 && (
                  <div className={styles.orderFilesBlock}>
                    <OrderFiles<OrderFileSnapshotType>
                      readOnly={readOnly}
                      removeLabel={SummaryOrderLabel}
                      fileLabel={SummaryOrderLabel}
                      sorter={OrderFileSorter}
                      files={formProps.values.otherOrders}
                      baseUrl={this.props.baseUrl}
                      onRemove={(id) => {
                        const value = formProps.values.otherOrders.filter((f) => f.guid !== id);
                        formProps.setFieldValue(fields.otherOrders, value);
                        formProps.setFieldTouched(fields.otherOrders, true);
                      }}
                    />
                  </div>
                )}

                <OrderBaseWithName
                  baseUrl={this.props.baseUrl}
                  readOnly={readOnly}
                  files={formProps.values.additionalOrders}
                  newId={this.props.newId}
                  upload={this.props.upload}
                  schema={schema}
                  onAdd={this.addAdditionalOrderFile}
                  onRemove={this.removeAdditionalOrderFile}
                  factory={this.props.orderBaseFactory}
                />

                <StandardFormInput
                  name={fields.cypher}
                  schema={schema}
                  small={true}
                  inline={false}
                  style={{ marginBottom: "8px" }}
                >
                  {({ field }) => (
                    <InputGroup
                      id={field.name}
                      {...field}
                      className="planr-default-input"
                      autoComplete="off"
                      data-lpignore="true"
                      readOnly={readOnly}
                      maxLength={70}
                      style={{ width: "100%" }}
                    />
                  )}
                </StandardFormInput>
                {/* Доп соглашения */}
                <Additions
                  baseUrl={this.props.baseUrl}
                  readOnly={readOnly}
                  files={formProps.values.additions}
                  newId={this.props.newId}
                  upload={this.props.upload}
                  schema={schema}
                  onAdd={this.addAddition}
                  onRemove={this.removeAddition}
                  factory={this.props.additionFactory}
                />

                {/* Эксперитизы */}
                <Expertises
                  baseUrl={this.props.baseUrl}
                  readOnly={true}
                  expertises={formProps.values.expertises as any}
                  newId={this.props.newId}
                  upload={this.props.upload}
                  schema={schema}
                />
              </>
            )}
          </div>

          {/*-----------------column separator----------------*/}

          <div className={styles.column}>
            <div className={styles.columnHeader}>
              <span>Доп. данные</span>
            </div>

            {/* Цвет */}
            <div className={styles.columnRow}>
              <div style={{ height: "60", marginRight: "16px" }}>
                <StandardFormInput name={fields.color} schema={schema} small={true} inline={false}>
                  {({ field, form }) => (
                    <div className={`planr-default-input`}>
                      <ColorPicker
                        color={field.value}
                        onSelected={(color) => {
                          form.setFieldValue(field.name, color);
                          form.setFieldTouched(field.name, true);
                        }}
                        // small={true}
                        readOnly={readOnly}
                      />
                    </div>
                  )}
                </StandardFormInput>
              </div>
              {/* Статус проекта */}
              <div>
                <StandardFormInput
                  name={fields.orderStatusId}
                  schema={schema}
                  small={true}
                  inline={false}
                  style={{ width: "200px" }}
                >
                  {({ field, form }) => {
                    const option = statusOptions[field.value];
                    const arr = Object.values(statusOptions);
                    return (
                      <SimpleSelect
                        className={`planr-default-select ${Classes.FILL}`}
                        filterable={false}
                        activeItem={option}
                        inputProps={field}
                        items={arr}
                        itemRenderer={renderSingleOption}
                        onItemSelect={(item) => {
                          form.setFieldValue(field.name, item.id);
                          form.setFieldTouched(field.name, true);
                        }}
                        disabled={statuses.isEmpty || readOnly}
                      >
                        <DefaultSelectedOption option={option} empty={statuses.isEmpty} />
                      </SimpleSelect>
                    );
                  }}
                </StandardFormInput>
              </div>
            </div>
            {/* Портфель проектов */}
            <StandardFormInput
              name={fields.portfolioId}
              schema={schema}
              small={true}
              inline={false}
              style={{ marginBottom: "8px" }}
            >
              {({ field, form }) => {
                const option = portfolioOptions[field.value];

                return (
                  <div className={`${Classes.INPUT_GROUP}`}>
                    <SimpleSelect
                      className={`full-width-select ${Classes.FILL}`}
                      filterable={false}
                      activeItem={option}
                      inputProps={field}
                      items={Object.values(portfolioOptions)}
                      itemRenderer={renderSingleOption}
                      onItemSelect={this.onPortfolioChange}
                      disabled={portfolios.isEmpty || readOnly}
                    >
                      <DefaultSelectedOption option={option} empty={portfolios.isEmpty} />
                    </SimpleSelect>
                  </div>
                );
              }}
            </StandardFormInput>

            {/* Тип проекта */}
            <StandardFormInput
              name={fields.orderTypeId}
              schema={schema}
              small={true}
              inline={false}
              style={{ marginBottom: "8px" }}
            >
              {({ field, form }) => {
                const option = typesOptions[field.value];

                return (
                  <div className={`${Classes.INPUT_GROUP}`}>
                    <SimpleSelect
                      className={`full-width-select ${Classes.FILL}`}
                      filterable={false}
                      activeItem={option}
                      inputProps={field}
                      items={Object.values(typesOptions)}
                      itemRenderer={renderSingleOption}
                      onItemSelect={this.onOrderTypeChange}
                      disabled={types.isEmpty || readOnly}
                    >
                      <DefaultSelectedOption option={option} empty={types.isEmpty} />
                    </SimpleSelect>
                  </div>
                );
              }}
            </StandardFormInput>

            {/* Стадия проиводства */}
            <StandardFormInput
              name={fields.productionStageId}
              schema={schema}
              small={true}
              inline={false}
              style={{ marginBottom: "8px" }}
            >
              {({ field }) => {
                const option = stageOptions[field.value];

                return (
                  <div className={`${Classes.INPUT_GROUP}`}>
                    <SimpleSelect
                      className={`full-width-select ${Classes.FILL}`}
                      filterable={false}
                      activeItem={option}
                      inputProps={field}
                      items={Object.values(stageOptions)}
                      itemRenderer={renderSingleOption}
                      onItemSelect={this.onProductionStageChange}
                      disabled={stages.isEmpty || readOnly}
                    >
                      <DefaultSelectedOption option={option} empty={stages.isEmpty} />
                    </SimpleSelect>
                  </div>
                );
              }}
            </StandardFormInput>
            <div className={styles.columnHeader}>Отзыв заказчика</div>
            <StandardFormInput name={fields.feedbackFileId} schema={schema} small={true} inline={false}>
              {({ field, form }) => {
                const onChange = (file: ClientFeedbackFileSnapshotType | null) => {
                  form.setFieldValue(field.name, file);
                  form.setFieldTouched(field.name, true);
                };

                return (
                  <FeedbackFile
                    baseUrl={this.props.baseUrl}
                    file={field.value}
                    upload={this.props.upload}
                    readOnly={readOnly}
                    onChange={onChange}
                  />
                );
              }}
            </StandardFormInput>
          </div>
          <div className={styles.column}>
            <div className={styles.columnHeader}>
              <span style={formProps.values.isConfirmed ? { color: "#1DD278" } : { color: "#e31818" }}>
                {formProps.values.isConfirmed ? "Загрузка проекта подтверждена" : "Загрузка проекта не подтверждена"}
              </span>
            </div>
            {formProps.values.isGip ? (
              <StandardFormInput
                name={fields.isConfirmed}
                schema={schema}
                small={true}
                inline={false}
                style={{ width: "200px" }}
              >
                {({ field, form }) => {
                  return (
                    <PlanrButton
                      type={formProps.values.isConfirmed ? "secondary" : "blueish"}
                      size="small"
                      onClick={() => {
                        if (formProps.values.isConfirmed) {
                          form.setFieldValue(field.name, false);
                          form.setFieldTouched(field.name, true);
                        } else {
                          form.setFieldValue(field.name, true);
                          form.setFieldTouched(field.name, true);
                        }
                      }}
                    >
                      {formProps.values.isConfirmed ? "Отменить" : "Подтвердить"}
                    </PlanrButton>
                  );
                }}
              </StandardFormInput>
            ) : null}

            <div className={styles.columnHeader} style={formProps.values.isGip ? { marginTop: "35px" } : {}}>
              <span>Объекты по договору</span>
            </div>
            <OrderObjects name={fields.objects} factory={this.props.objectFactory} readOnly={readOnly} />
          </div>
        </div>
      </div>
    );
  }

  onFlagChange = (type: "municipal" | "batch" | "region" | "federal") => {
    const { formProps, readOnly } = this.props;
    if (readOnly) {
      return;
    }

    const current: string = (formProps.values as any)[fields.flags];
    const newValue = current !== type ? type : "";

    formProps.setFieldValue(fields.flags, newValue);
    formProps.setFieldTouched(fields.flags, true);
  };

  onProductionStageChange = (item: SelectItem) => {
    const { formProps } = this.props;
    formProps.setFieldValue(fields.productionStageId, item.id);
    formProps.setFieldTouched(fields.productionStageId, true);
  };

  onOrderTypeChange = (item: SelectItem) => {
    const { formProps } = this.props;
    formProps.setFieldValue(fields.orderTypeId, item.id);
    formProps.setFieldTouched(fields.orderTypeId, true);
  };

  onPortfolioChange = (item: SelectItem) => {
    const { formProps } = this.props;
    formProps.setFieldValue(fields.portfolioId, item.id);
    formProps.setFieldTouched(fields.portfolioId, true);
  };

  addAddition = (file: OrderFileSnapshotType) => {
    const { formProps } = this.props;
    const value = formProps.values.additions;
    file.sortOrder = value.length + 1;
    formProps.setFieldValue(fields.additions, [file, ...value]);
    formProps.setFieldTouched(fields.additions, true);

    this.setState({ addition: null });
  };

  removeAddition = (id: string) => {
    const { formProps } = this.props;
    const value = formProps.values.additions.filter((f) => f.guid !== id);
    formProps.setFieldValue(fields.additions, value);
    formProps.setFieldTouched(fields.additions, true);
  };

  removeOrderFile = (id: string) => {
    const { formProps } = this.props;
    const value = formProps.values.otherOrders.filter((f) => f.guid !== id);
    formProps.setFieldValue(fields.otherOrders, value);
    formProps.setFieldTouched(fields.otherOrders, true);
  };

  addOrderFile = (file: OrderFileSnapshotType) => {
    const { formProps } = this.props;
    const value = formProps.values.otherOrders;
    file.sortOrder = value.length + 1;
    formProps.setFieldValue(fields.otherOrders, [file, ...value]);
    formProps.setFieldTouched(fields.otherOrders, true);
  };

  removeAdditionalOrderFile = (id: string) => {
    const { formProps } = this.props;
    const value = formProps.values.additionalOrders.filter((f) => f.guid !== id);
    formProps.setFieldValue(fields.additionalOrders, value);
    formProps.setFieldTouched(fields.additionalOrders, true);
  };

  addAdditionalOrderFile = (file: OrderFileSnapshotType) => {
    const { formProps } = this.props;
    const value = formProps.values.additionalOrders;
    file.sortOrder = value.length + 1;
    formProps.setFieldValue(fields.additionalOrders, [file, ...value]);
    formProps.setFieldTouched(fields.additionalOrders, true);
  };

  // addConsignmentFile = (file: ConsignmentFileModel) => {
  //     const { formProps } = this.props;
  //     const value = formProps.values.consignments;
  //     formProps.setFieldValue(fields.consignments, [...value, file]);
  //     formProps.setFieldTouched(fields.consignments, true);
  // };

  // removeConsignmentFile = (guid: string) => {
  //     const { formProps } = this.props;
  //     const value = formProps.values.consignments.filter((f) => f.guid !== guid);
  //     formProps.setFieldValue(fields.consignments, value);
  //     formProps.setFieldTouched(fields.consignments, true);
  // };
}

export const Summary = observer(SummaryBase);

interface SummaryProps extends UploaderFatory, IdFactory {
  withSensitive: boolean;
  objectFactory: ObjectFactory;
  // consignmentFactory: ConsignmentFactory;
  additionFactory: AdditionFactory;
  orderBaseFactory: OrderBaseWithNameFactory;
  portfolios: ProjectPortfolioDictionaryType;
  statuses: OrderStatusDictionaryType;
  clients: ClientDictionaryType;
  types: OrderTypeDictionaryType;
  stages: ProductionStageDictionaryType;
  engineers: EmployerDictionaryType;
  formProps: FormikProps<OrderSummarySnapshotType>;
  schema: Schema<any>;
  baseUrl: string;
  readOnly?: boolean;
  isActiveTab: boolean;
  storeDetails: OrderSummaryType;
}
