import { Classes, FormGroup, InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { FileLink } from "modules/common/components/files/FileLink";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { preventSubmitKeyDown } from "modules/common/services/form/values";
import { texts } from "modules/common/texts";
import { SentExpertiseFileType } from "modules/orders-manage/models/order";
import { EXPERTISE_ACCEPT } from "modules/orders-manage/models/order-sent-expertise";
import { OrderSentExpertiseRowType } from "modules/orders-manage/models/order-stages-store";
import React, { useCallback } from "react";
import styles from "./CompletionRow.module.scss";
import { Error, star } from "./events/common";

export const SentExpertiseRow = observer(({ expertise }: SentExpertiseRowProps) => {
    const onDateChangeHandler = useCallback(
        (e: Date | null) => {
            expertise.setDate(e);
        },
        [expertise]
    );
    const onNumberChangeHandler = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            expertise.setNumber(e.target.value);
        },
        [expertise]
    );
    const onFileChangeHandler = useCallback(
        (file: File) => {
            return expertise.uploadFile(file);
        },
        [expertise]
    );

    const errors = expertise.validation;

    const uploader = !expertise.file ? (
        <PlanRDndUploader
            accept={EXPERTISE_ACCEPT}
            multiple={false}
            onFileSelected={onFileChangeHandler}
            withLoader={true}
            oneRow={true}
            style={{ minWidth: "170px" }}
        />
    ) : null;

    return (
        <div className={styles.completionRow}>
            <div>
                <RemoveConfirmation<OrderSentExpertiseRowType>
                    what={() => "Передачу в экспертизу"}
                    render={({ confirmRemoving }) => (
                        <PlanrButton
                            type="secondary"
                            icon="general-trash"
                            onClick={() => confirmRemoving(expertise)}
                            size="small"
                            title={texts.remove}
                            style={{ marginBottom: "5px", width: "100%" }}
                        >
                            {texts.remove} передачу
                        </PlanrButton>
                    )}
                    onConfirmed={(p) => p.remove()}
                />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
                <FormGroup
                    label="Дата"
                    labelInfo={star}
                    className={Classes.SMALL}
                    inline={true}
                    intent={errors.date ? Error : "none"}
                    helperText={errors.date}
                >
                    <DatePicker
                        value={expertise.dateAsDate}
                        onChange={onDateChangeHandler}
                        clasName="planr-default-input"
                        onKeyDown={preventSubmitKeyDown}
                        small={true}
                    />
                </FormGroup>
                <FormGroup
                    label="№"
                    labelInfo={star}
                    className={Classes.SMALL}
                    inline={true}
                    intent={errors.number ? Error : "none"}
                    helperText={errors.number}
                >
                    <InputGroup
                        id="number"
                        value={expertise.number}
                        className="planr-default-input"
                        autoComplete="off"
                        onChange={onNumberChangeHandler}
                        onKeyDown={preventSubmitKeyDown}
                        placeholder="Номер"
                        small={true}
                    />
                </FormGroup>
                <div>
                    {uploader}
                    {expertise.file && (
                        <FileLink<SentExpertiseFileType>
                            baseUrl={expertise.baseUrl}
                            file={expertise.file}
                            onRemove={expertise.clearFile}
                            style={{ minWidth: "170px" }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});

interface SentExpertiseRowProps {
    expertise: OrderSentExpertiseRowType;
}
