export type valueOf<T> = T[keyof T];

export function nameof<T, V extends T[keyof T]>(
    f: (x: T) => V
): valueOf<{ [K in keyof T]: T[K] extends V ? K : never }>;

export function nameof(f: (x: any) => any): keyof any {
    var p = new Proxy(
        {},
        {
            get: (target, key) => key,
        }
    );

    return f(p);
}

export function isNotNull<T>(a: T | null): a is T {
    return a !== null && a !== undefined;
}

export const eat = (data?: any) => {
    // nothing
};
