import * as yup from "yup";
import { fields } from "../../models/depr-group";
import { texts } from "modules/common/texts";
const { object, string, number } = yup;

export const deprGroupLabels = () => ({
    [fields.name]: texts.name,
    [fields.minYears]: "Срок от (лет)",
    [fields.maxYears]: "Срок до (лет)",
    [fields.minLifetime]: "Срок от (месяцев)",
    [fields.maxLifetime]: "Срок до (месяцев)",
});

const DeprGroupSchema = () => {
    const labels = deprGroupLabels();

    return object().shape({
        [fields.name]: string().label(labels[fields.name]).required(),
        [fields.minYears]: number().label(labels[fields.minYears]).required(),
        [fields.maxYears]: number().label(labels[fields.maxYears]).required(),
        [fields.minLifetime]: number().label(labels[fields.minLifetime]),
        [fields.maxLifetime]: number().label(labels[fields.maxLifetime]),
    });
};

export default DeprGroupSchema;
