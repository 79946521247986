import { formatMoney } from "modules/common/components/money/Money";
import { PlanrTag } from "modules/common/components/planr/tag/PlanrTag";
import { PlanOrderPaymentDetailsType } from "modules/orders-manage/models/order-stages-store";
import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import styles from "./PlanPayment.module.scss";

export const PlanPayment: React.FC<PlanPaymentProps> = ({ payment, draggable, children }) => {
    const draggablePrps = draggable
        ? {
              ref: draggable.innerRef,
              ...draggable.draggableProps,
              ...draggable.dragHandleProps,
          }
        : {};

    return (
        <div className={styles.planPayment} {...draggablePrps}>
            <div style={{ maxWidth: "30%", minWidth: "200px" }}>
                <div style={{ display: "flex" }}>
                    <div className={styles.paymentNumber}>№{payment.name}</div>
                    <div className={styles.paymentSum}>
                        <PlanrTag type={payment.looksToBePaid ? "success" : "neutral"}>
                            {formatMoney(payment.sum)}
                        </PlanrTag>
                    </div>
                </div>
                <div className={styles.paymentType}>{payment.type}</div>
            </div>
            {children}
        </div>
    );
};

interface PlanPaymentProps {
    draggable?: DraggableProvided;
    payment: PlanOrderPaymentDetailsType;
}
