import React, { useState, useCallback } from "react";
import {
    CollaboraLoaderProps,
    CollaboraLoader,
    CollaboraFrame,
    CollaboraEvent,
} from "modules/orders-manage/details/tech-task/collabora";
import {
    reloadFrame,
    AppLoadingStatus,
    hostReady,
    hideButton,
} from "modules/orders-manage/details/tech-task/collabora-api";
import { observer } from "mobx-react";
import { apiUrls } from "modules/common/services/communication/urls";
import { CollaboraAnchorsHelp } from "modules/orders-manage/details/tech-task/CollaboraAnchorsHelp";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { FileLink } from "modules/common/components/files/FileLink";
import { UploaderFatory } from "modules/orders-manage/types";
import { PrintAnchorInfoListType } from "modules/common/models/print-anchor";

export interface ITemplateStore extends UploaderFatory {
    replaceTemplate: (id: string, fileId: string) => Promise<boolean>;
    wopiToken: string;
    editorUrl: string;
}

interface SystemTemplateProps extends Omit<CollaboraLoaderProps, "id" | "apiUrl" | "wopiToken" | "editorUrl"> {
    store: ITemplateStore;
    anchors: PrintAnchorInfoListType;
    templateId: string;
    accept: string;
}

export const SystemTemplate = observer(({ store, accept, anchors, templateId, ...rest }: SystemTemplateProps) => {
    const editorFrameId = `settings-${templateId}-frame`;
    const [uploadedFile, uploadFile] = useState({
        fileId: "",
        fileName: "",
    });

    const onMessage = useCallback((e: CollaboraEvent) => {
        if (e.MessageId === "App_LoadingStatus") {
            const event: AppLoadingStatus = e;

            if (event.Values.Status === "Document_Loaded") {
                hostReady(e.frame);
                hideButton(e.frame, "fold");
            }

            return true;
        }
    }, []);

    const fileUpload = async (file: any) => {
        const result = await store.upload(file);
        if (result) {
            uploadFile({ fileId: result.fileId, fileName: result.fileName });
        }
    };

    const apiUrl = apiUrls.templates.technicalTask.fileInfo(templateId);

    return (
        <div style={{ width: "100%", height: "92%" }}>
            <div className="collapse-add" style={{ position: "absolute", right: "0px", top: "78px" }}>
                <div style={{ display: "flex", width: "500px", marginLeft: "24px" }}>
                    {!uploadedFile.fileId && (
                        <PlanRDndUploader
                            accept={accept}
                            multiple={true}
                            withLoader={true}
                            style={{ border: "3px dashed #edf0f2", height: " 52px", width: "500px" }}
                            onFileSelected={fileUpload}
                        />
                    )}
                    {uploadedFile.fileId && (
                        <RemoveConfirmation
                            onConfirmed={async () => {
                                const result = await store.replaceTemplate(templateId, uploadedFile.fileId);
                                if (result) {
                                    uploadFile({
                                        fileId: "",
                                        fileName: "",
                                    });
                                    reloadFrame(editorFrameId);
                                }
                            }}
                            what={() => "документ"}
                            actionName={"перезаписать"}
                            render={({ confirmRemoving }) => {
                                const onClick = () => confirmRemoving(true);

                                return (
                                    <PlanrButton type="greenish" onClick={onClick}>
                                        Загрузить
                                    </PlanrButton>
                                );
                            }}
                        />
                    )}
                    {uploadedFile.fileId && (
                        <FileLink<any>
                            baseUrl={""}
                            file={uploadedFile}
                            style={{
                                height: "52px",
                                marginBottom: "0px",
                                width: "301px",
                                marginLeft: "16px",
                                border: "3px dashed #edf0f2",
                            }}
                            onRemove={() => uploadFile({ fileId: "", fileName: "" })}
                        />
                    )}
                </div>
            </div>
            <CollaboraLoader
                {...rest}
                id={editorFrameId}
                apiUrl={apiUrl}
                wopiToken={store.wopiToken}
                editorUrl={store.editorUrl}
            />

            <div style={{ display: "flex", width: "100%", height: "100%", marginTop: "70px", position: "relative" }}>
                <CollaboraAnchorsHelp store={anchors} style={{ padding: 0, width: "45px", height: "40px" }} />
                <CollaboraFrame onMessage={onMessage} id={editorFrameId} />
            </div>
        </div>
    );
});
