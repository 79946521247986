import { Classes, FormGroup, InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { FileLink } from "modules/common/components/files/FileLink";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { preventSubmitKeyDown } from "modules/common/services/form/values";
import { texts } from "modules/common/texts";
import { CONSIGNMENT_ACCEPT } from "modules/orders-manage/models/order-consignments";
import { ConsignmentFileType } from "modules/orders-manage/models/order-file";
import { OrderConsignmentRowType } from "modules/orders-manage/models/order-stages-store";
import React, { useCallback } from "react";

import styles from "./ConsignmentRow.module.scss";
import { Error, star } from "./events/common";

export const ConsignmentRow = observer(({ consignment }: ConsignmentRowProps) => {
    const onCommentChangeHandler = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            consignment.setComment(e.target.value);
        },
        [consignment]
    );

    const onNumberChangeHandler = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            consignment.setNumber(e.target.value);
        },
        [consignment]
    );

    const onDateChangeHandler = useCallback(
        (e: Date | null) => {
            consignment.setDate(e);
        },
        [consignment]
    );

    const onFileChangeHandler = useCallback(
        (file: File) => {
            return consignment.uploadFile(file);
        },
        [consignment]
    );

    const errors = consignment.validation;

    const uploader = !consignment.file ? (
        <PlanRDndUploader
            accept={CONSIGNMENT_ACCEPT}
            multiple={false}
            onFileSelected={onFileChangeHandler}
            withLoader={true}
            oneRow={true}
        />
    ) : null;

    return (
        <div className={styles.consignmentRow}>
            <div>
                <RemoveConfirmation<OrderConsignmentRowType>
                    what={() => "Накладная"}
                    render={({ confirmRemoving }) => (
                        <PlanrButton
                            type="secondary"
                            icon="general-trash"
                            onClick={() => confirmRemoving(consignment)}
                            size="small"
                            title={texts.remove}
                            style={{ marginBottom: "5px", width: "100%" }}
                        >
                            {texts.remove} накладную
                        </PlanrButton>
                    )}
                    onConfirmed={(p) => p.remove()}
                />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
                <FormGroup
                    label="№"
                    labelInfo={star}
                    className={Classes.SMALL}
                    inline={true}
                    intent={errors.number ? Error : "none"}
                    helperText={errors.number}
                >
                    <InputGroup
                        id="number"
                        value={consignment.number}
                        className="planr-default-input"
                        autoComplete="off"
                        onChange={onNumberChangeHandler}
                        onKeyDown={preventSubmitKeyDown}
                        placeholder="Номер"
                        small={true}
                    />
                </FormGroup>
                <FormGroup
                    label="Дата"
                    labelInfo={star}
                    className={Classes.SMALL}
                    inline={true}
                    intent={errors.date ? Error : "none"}
                    helperText={errors.date}
                >
                    <DatePicker
                        value={consignment.dateAsDate}
                        onChange={onDateChangeHandler}
                        clasName="planr-default-input"
                        onKeyDown={preventSubmitKeyDown}
                        small={true}
                    />
                </FormGroup>
                <FormGroup label="Прим." className={Classes.SMALL} inline={true}>
                    <InputGroup
                        id="comment"
                        value={consignment.comment}
                        className="planr-default-input"
                        autoComplete="off"
                        onChange={onCommentChangeHandler}
                        onKeyDown={preventSubmitKeyDown}
                        placeholder="Примечания"
                        small={true}
                    />
                </FormGroup>
                <div>
                    {uploader}
                    {consignment.file && (
                        <FileLink<ConsignmentFileType>
                            baseUrl={consignment.baseUrl}
                            file={consignment.file}
                            onRemove={consignment.clearFile}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});

interface ConsignmentRowProps {
    consignment: OrderConsignmentRowType;
}
