import { types } from 'mobx-state-tree';
import { OverheadTypeList, OverheadType, initialState as emptyList } from './overhead-type-list';
import { initialState as emptyOverheadType } from './overhead-type';
import { Transport } from 'modules/common/models/transport';
import { Notificator } from 'modules/common/models/notificator';

export const OverheadTypesStore = types.compose(
    Transport, Notificator, types.model({
        list: OverheadTypeList,
        details: OverheadType
    }))
    .named('OverheadTypesStore');

export const initialState = (): typeof OverheadTypesStore.SnapshotType => ({
    list: emptyList(),
    details: emptyOverheadType()
});

export type OverheadTypesStoreType = typeof OverheadTypesStore.Type;
