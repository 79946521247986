import { LocalLoader } from "modules/common/components/loader/Loader";
import React from "react";
import styles from "./LogList.module.scss";

import { observer } from "mobx-react";
import { OrderLogsStoreType } from "../models/order-logs";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import InfiniteScroll from "react-infinite-scroller";
class LogItems extends React.Component<LogListProps> {
  scroller = React.createRef<HTMLDivElement>();

  async componentDidMount() {
    await this.props.store.load(this.props.orderId);
  }
  getScrollParent = () => this.scroller.current;
  render() {
    const { store } = this.props;
    return (
      <div className={`${styles.wrapper} `}>
        {store.loading ? (
          <div>
            <LocalLoader active={store.loading} />
          </div>
        ) : (
          <div className={styles.scroller} ref={this.scroller}>
            {store.data && store.data.length > 0 && (
              <InfiniteScroll
                pageStart={1}
                loadMore={() => {
                  store.loadNext(this.props.orderId);
                }}
                hasMore={store.hasMore}
                useWindow={false}
                initialLoad={false}
                threshold={100}
                getScrollParent={this.getScrollParent}
              >
                {store.data.map((row) => (
                  <div className={styles.mainRow} key={row.id}>
                    <div className={styles.userRow}>
                      <div className={styles.date}>{`${row.dateText} ${row.time}`}</div>

                      <div className={styles.user}>{row.user}</div>
                    </div>
                    <div className={styles.logRows}>
                      {row.rows.map((item, index) => (
                        <div key={index}>
                          <div className={styles.logRow}>
                            <div className={styles.status}>
                              {item.isNew ? (
                                <span className={styles.created}>Создано</span>
                              ) : item.isUpdate ? (
                                <span className={styles.updated}>Обновлено</span>
                              ) : item.isRemove ? (
                                <span className={styles.removed}>Удалено</span>
                              ) : null}
                            </div>
                            {item.isNew || item.isUpdate || item.isRemove ? (
                              <GeneralIcon
                                type="general-chevron-right"
                                style={{ marginLeft: "10px", marginRight: "10px" }}
                              />
                            ) : null}
                            {item.tab ? (
                              <div className={styles.tabName}>
                                {/* <div className={styles.tabLabel}>Вкладка</div> */}
                                <div>{item.tab}</div>
                              </div>
                            ) : null}
                            {item.tab ? (
                              <GeneralIcon
                                type="general-chevron-right"
                                style={{ marginLeft: "10px", marginRight: "10px" }}
                              />
                            ) : null}
                            <div className={styles.fieldNameMain}>
                              <span>{item.fieldName}</span>
                            </div>
                            <GeneralIcon
                              type="general-chevron-right"
                              style={{ marginLeft: "10px", marginRight: "10px" }}
                            />
                            <div className={styles.values}>
                              {item.prevValue ? <div className={styles.prevValue}>{item.prevValue}</div> : null}
                              {item.prevValue ? (
                                <GeneralIcon
                                  type="general-big-arrow-right"
                                  style={{
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                />
                              ) : null}
                              <div className={item.prevValue ? styles.value : styles.defvalue}>{item.value}</div>
                            </div>
                          </div>
                          {item.rows &&
                            item.rows.length > 0 &&
                            item.rows.map((itemrow, ind) => (
                              <div style={{ marginLeft: "217px" }} key={ind}>
                                <div className={styles.logRow}>
                                  <div
                                    className={styles.status}
                                    style={
                                      itemrow.isNew || itemrow.isUpdate || itemrow.isRemove
                                        ? { marginLeft: "-35px" }
                                        : {}
                                    }
                                  >
                                    {itemrow.isNew ? (
                                      <span className={styles.created}>Создано</span>
                                    ) : itemrow.isUpdate ? (
                                      <span className={styles.updated}>Обновлено</span>
                                    ) : itemrow.isRemove ? (
                                      <span className={styles.removed}>Удалено</span>
                                    ) : null}
                                  </div>
                                  {itemrow.isNew || itemrow.isUpdate || itemrow.isRemove ? (
                                    <GeneralIcon
                                      type="general-chevron-right"
                                      style={{
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  ) : null}
                                  {itemrow.tab ? (
                                    <div className={styles.tabName}>
                                      {/* <div className={styles.tabLabel}>Вкладка</div> */}
                                      <div>{itemrow.tab}</div>
                                    </div>
                                  ) : null}
                                  {itemrow.tab ? (
                                    <GeneralIcon
                                      type="general-chevron-right"
                                      style={{
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  ) : null}
                                  <div className={styles.fieldName}>{itemrow.fieldName}</div>
                                  <GeneralIcon
                                    type="general-chevron-right"
                                    style={{
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  <div className={styles.values}>
                                    {itemrow.prevValue ? (
                                      <div className={styles.prevValue}>{itemrow.prevValue}</div>
                                    ) : null}

                                    {itemrow.prevValue ? (
                                      <GeneralIcon
                                        type="general-big-arrow-right"
                                        style={{
                                          marginLeft: "10px",
                                          marginRight: "10px",
                                        }}
                                      />
                                    ) : null}
                                    <div className={itemrow.prevValue ? styles.value : styles.defvalue}>
                                      {itemrow.value}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            )}
          </div>
        )}
      </div>
    );
  }
}

export const LogList = observer(LogItems);

interface LogListProps {
  store: OrderLogsStoreType;
  orderId: string;
}

interface LogListState {
  copied: boolean;
}
