import React from "react";
import { Classes } from "@blueprintjs/core";
import Cleave from "cleave.js/react";
import { capitalize } from "modules/common/services/strings";
import { CleaveOptions } from "cleave.js/options";

const UNKNOWN = "unknown";
const options: CleaveOptions = { creditCard: true };

export class CreditCard extends React.PureComponent<CredirCardInputProps, CredirCardInputState> {
    constructor(props: any) {
        super(props);

        this.state = { cardType: UNKNOWN };
    }

    private ref = React.createRef<any>();

    componentDidMount() {
        const cardType = getCardType(this.ref);
        this.setState({ cardType });
    }

    componentDidUpdate() {
        const cardType = getCardType(this.ref);
        this.setState({ cardType });
    }

    render() {
        const { className, small, ...rest } = this.props;
        const { cardType } = this.state;

        const knownType = cardType !== UNKNOWN;
        const style: React.CSSProperties | undefined = knownType ? { paddingRight: "50px" } : undefined;

        return (
            <div className={`${Classes.INPUT_GROUP} ${className || ""} ${small ? Classes.SMALL : ""}`}>
                <Cleave
                    {...rest}
                    className={`${Classes.INPUT}`}
                    options={options}
                    autoComplete="off"
                    ref={this.ref}
                    style={style}
                />
                {knownType && (
                    <span className={Classes.INPUT_ACTION} style={{ top: "4px" }}>
                        <span className={`${Classes.TAG} ${Classes.MINIMAL}`} style={{ fontStyle: "italic" }}>
                            {capitalize(cardType)}
                        </span>
                    </span>
                )}
            </div>
        );
    }
}

function getCardType(ref: React.RefObject<any>) {
    const element: CleaveProps | null = ref.current;
    const properties = element ? element.properties : null;

    return (properties ? properties.creditCardType : "") || UNKNOWN;
}

interface CredirCardInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    small?: boolean;
}

interface CredirCardInputState {
    cardType: string;
}

interface CleaveProps {
    properties: {
        creditCardType: string;
    };
}
