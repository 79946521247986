import { formatMoney } from "modules/common/components/money/Money";
import { prettyRound } from "modules/common/services/formatting/numbers";
import { isNotNull, nameof } from "modules/common/services/typescript";
import { WorkTypeLinkSnapshotType } from "modules/orders-manage/models/order";
import {
    OrderTimesheetSpendingType,
    OwnOrderSpendingSnapshotType,
    ownSpendingFields,
} from "modules/orders-manage/models/order-spending";
import { EmployerDictionaryItemType } from "modules/spending/employee/models/employee-dictionary";

export const OWN_BLOCK_NAME = "Собственные силы";

type TSpending = OwnOrderSpendingSnapshotType;

interface OwnSpendingsPrintModel {
    name: string;
    sum: string;
    hours: string;
    blocks: {
        name: string;
        hours: string;
        sum: string;
        labels: TStringMap<string>;
        rows: {
            number: string;
            user: string;
            hours: string;
            sum: string;
        }[];
    }[];
}

type OwnSpendingsPrintModelBlock = OwnSpendingsPrintModel["blocks"][number];

export const OwnSpendingBlockSorting = [nameof((a: TSpending) => a.sortOrder) as string];

export function ownSpendingsPlanPrintModel(
    name: string,
    sorted: TSpending[],
    units: WorkTypeLinkSnapshotType[],
    users: TStringMap<EmployerDictionaryItemType>
): OwnSpendingsPrintModel {
    const totalMoney = sorted.reduce((acc, unit) => acc + getMoney(users, unit), 0);
    const totalHours = sorted.reduce((acc, unit) => acc + unit.hours, 0);

    const blocks = units
        .map((unit) => {
            const spendings = sorted.filter((v) => v.contentGuid === unit.guid);
            if (!spendings.length) {
                return null;
            }

            let sum: number = 0;
            let hours: number = 0;

            const block: OwnSpendingsPrintModelBlock = {
                name: `${unit.name}. ${unit.description}`,
                sum: "",
                hours: "",
                rows: [],
                labels: {
                    empty: "",
                },
            };

            spendings.forEach((sp, index) => {
                for (let key in users) {
                    if (users[key].id === (sp as any)[ownSpendingFields.employerId]) {
                        sum += +sp.hours * users[key].companySpendingPerHour;
                    }
                }
                hours += +sp.hours;

                block.rows.push({
                    number: `${index + 1}`,
                    user: getUserName(users, sp),
                    hours: `${sp.hours}ч`,
                    sum: formatMoney(getMoney(users, sp), { noFraction: true }),
                });
            });

            block.hours = `${hours}ч`;
            block.sum = formatMoney(prettyRound(sum));
            return block;
        })
        .filter(isNotNull);

    return {
        name,
        blocks,
        sum: formatMoney(totalMoney),
        hours: `${totalHours}ч`,
    };
}

export function ownSpendingsActualPrintModel(
    name: string,
    spendings: OrderTimesheetSpendingType
): OwnSpendingsPrintModel {
    const { factHours, factMoney } = spendings.spendingsTotal;

    const blocks = spendings.spendingsMap.map(({ label, employee, hours, money, comment }) => {
        const block: OwnSpendingsPrintModelBlock = {
            name: label,
            hours: `${hours}ч`,
            sum: formatMoney(money),
            rows: employee.map((user, index) => ({
                number: `${index + 1}`,
                user: user.employer.name,
                hours: `${user.hours}ч`,
                sum: formatMoney(user.sum, { noFraction: true }),
            })),
            labels: {
                empty: "",
            },
        };
        return block;
    });

    return {
        name,
        blocks,
        sum: formatMoney(factMoney),
        hours: `${factHours}ч`,
    };
}

function getUserName(users: TStringMap<EmployerDictionaryItemType>, spending: TSpending) {
    const employerId = (spending as any)[ownSpendingFields.employerId] || "";
    const savedEmployee = spending.employer
        ? {
              id: spending.employer.id,
              label: spending.employer.name,
          }
        : null;

    const employer = users[employerId] ?? savedEmployee;

    return employer ? employer.label : "";
}

export const getMoney = (users: TStringMap<EmployerDictionaryItemType>, spending: TSpending) => {
    const employerId = (spending as any)[ownSpendingFields.employerId];
    const employer = employerId ? users[employerId] : undefined;

    if (employer) {
        const hoursCount: number = spending.hours;
        return hoursCount * employer.companySpendingPerHour;
    } else {
        return spending.spending;
    }
};
