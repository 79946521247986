import React from "react";
import styles from "./WorkDays.module.scss";

import { Peoples } from "modules/orders-manage/details/Peoples";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

export const WorkDays: React.FC<WorkDaysProps> = ({ days, hours, users }) => {
    // const hours = Constants.workDayHours * days;

    return (
        <div className={`work-days ${styles.workDays}`}>
            <span>
                <GeneralIcon type={"general-clock"} />
                &nbsp;{days}&nbsp;({hours} ч)
            </span>

            <span>
                <Peoples count={users} />
            </span>
        </div>
    );
};

interface WorkDaysProps {
    days: number;
    hours: number;
    users: number;
}
