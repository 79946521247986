import React from "react";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Block } from "modules/common/components/page/Block";
import { Route, withRouter, RouteComponentProps, matchPath } from "react-router";
import { routes } from "modules/common/routes";
import { UnregisterCallback } from "history";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { EmployeePositionsStoreType } from "../models/employee-positions-store";
import { EmployeePositionDetails } from "./details/EmployeePositionDetails";
import { EmployeePositionsList } from "./list/EmployeePositionsList";
import { menuItems } from "modules/root/components/menu/MainMenu";
import func from "../../functionalities";
import { can } from "modules/session/auth/access";
import styles from "./EmployeePositionsPage.module.scss";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { Search } from "modules/common/components/form/Search";

const menu = menuItems();

class EmployeePositionsPage extends React.Component<
    EmployeePositionsPageProps & RouteComponentProps,
    EmployeePositionsPageState
> {
    private unregister: UnregisterCallback | null = null;

    constructor(props: EmployeePositionsPageProps & RouteComponentProps) {
        super(props);

        this.state = { positionId: getSelectedEmployeePositionId(props.location.pathname) };
    }

    componentDidMount() {
        document.title = menu.spending.nested.departments.label;

        const { history, store } = this.props;
        const { positionId } = this.state;
        store.init(positionId);
        this.loadList();
        this.loadDetails(positionId);

        this.unregister = history.listen((location) => {
            const id = getSelectedEmployeePositionId(location.pathname);

            if (this.state.positionId !== id) {
                this.setState({ positionId: id });
                this.loadDetails(id);
            }
        });
    }

    componentWillUnmount() {
        this.unregister && this.unregister();
    }

    render() {
        const { store, access, department, baseUrl } = this.props;
        const canChange = can(func.DEPARTMENT_EDIT, access);

        return (
            <Block columned={true} className={styles.page}>
                <Route path={routes.spending.department.positionDetails()}>
                    {this.state.positionId && (
                        <DetailsPopup title="Должность" onCancel={this.onEmployeePositionSelected}>
                            <EmployeePositionDetails
                                manualFile={store.details.manualFile}
                                canChange={canChange}
                                employeePosition={store.details}
                                onSaved={this.onEmployeePositionSaved}
                                onRemoved={this.onEmployeePositionRemoved}
                                departments={store.departments}
                                department={department}
                                baseUrl={baseUrl}
                            />
                        </DetailsPopup>
                    )}
                </Route>
                <span className={styles.topSpan}>Должности</span>
                {canChange && (
                    <PlanrButton
                        type="secondary"
                        size="small"
                        icon="general-plus-big"
                        onClick={this.onEmployeePositionAdd}
                        style={{
                            width: "230px",
                            marginBottom: "32px",
                            borderRadius: "120px",
                        }}
                    >{`${texts.add} должность`}</PlanrButton>
                )}
                <div className={styles.right}>
                    <Search query={store.list.query} onSearch={store.list.setQuery} />
                </div>
                <EmployeePositionsList
                    store={store.list}
                    onSelected={this.onEmployeePositionSelected}
                    onRemoved={this.onEmployeePositionRemoved}
                    baseUrl={baseUrl}
                />
            </Block>
        );
    }

    onEmployeePositionAdd = () => {
        this.onEmployeePositionSelected(EMPTY_OBJECT_ID);
    };

    onEmployeePositionSelected = (id: string = "") => {
        const { history } = this.props;

        const url = id ? routes.spending.department.positionDetails(id) : routes.spending.department.path;

        history.push(url);
    };

    onEmployeePositionSaved = () => {
        this.onEmployeePositionSelected("");
        this.loadList();
    };

    onEmployeePositionRemoved = () => {
        const { history } = this.props;

        this.loadList();

        const url = routes.spending.department.path;
        history.push(url);
    };

    loadList = () => this.props.store.list.load();

    loadDetails = (id: string) => this.props.store.details.load(id);
}

export default withRouter(observer(EmployeePositionsPage));

function getSelectedEmployeePositionId(pathname: string) {
    const match = matchPath<EmployeePositionsPageState>(pathname, routes.spending.department.positionDetails());

    return match && match.params ? match.params.positionId : "";
}

interface EmployeePositionsPageProps {
    store: EmployeePositionsStoreType;
    access: string[];
    department: string;
    baseUrl: string;
}

interface EmployeePositionsPageState {
    positionId: string;
}
