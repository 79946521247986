import React from "react";
import { objectFields, fullInventoryNumber } from "modules/orders-manage/models/order";
import { InputGroup } from "@blueprintjs/core";
import { onlyDigitsInputKeyPress } from "modules/common/services/form/values";

import { trimStart } from "modules/common/services/strings";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { ReadOnlyFormElement } from "modules/common/components/form/StandardFormInput";

const digits = onlyDigitsInputKeyPress();
type Event = React.ChangeEvent<HTMLInputElement>;

export class ObjectRow extends React.PureComponent<ObjectRowProps, ObjectRowState> {
    input: HTMLInputElement | null = null;

    constructor(props: any) {
        super(props);

        this.state = { focused: false };
    }

    render() {
        const { object, readOnly, onChange } = this.props;
        const number = this.state.focused
            ? object[objectFields.inventoryNumber]
            : fullInventoryNumber(object[objectFields.inventoryNumber]);

        return (
            <>
                <tr>
                    <td>
                        {!readOnly && (
                            <InputGroup
                                value={number}
                                onChange={(e: Event) => {
                                    const value = trimStart("0", e.target.value);
                                    onChange(objectFields.inventoryNumber, value);
                                }}
                                className="planr-default-input"
                                autoComplete="off"
                                data-lpignore="true"
                                type="text"
                                maxLength={2}
                                onKeyDown={this.onKeyDown}
                                onKeyPress={digits}
                                inputRef={this.inputRef}
                                style={{ width: "65px", marginRight: "10px" }}
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                            />
                        )}
                        {readOnly && (
                            <ReadOnlyFormElement value={number} style={{ width: "65px", marginRight: "10px" }} />
                        )}
                    </td>

                    <td>
                        {!readOnly && (
                            <InputGroup
                                value={object[objectFields.name]}
                                onChange={(e: Event) => {
                                    onChange(objectFields.name, e.target.value);
                                }}
                                className="planr-default-input"
                                autoComplete="off"
                                data-lpignore="true"
                                onKeyDown={this.onKeyDown}
                                style={{ width: "250px" }}
                            />
                        )}
                        {readOnly && (
                            <ReadOnlyFormElement value={object[objectFields.name]} style={{ width: "250px" }} />
                        )}
                    </td>
                    {!readOnly && (
                        <td className="centered">
                            <PlanrButton type="neutral" icon="general-trash" onClick={this.onRemove} size="small" />
                        </td>
                    )}
                </tr>
            </>
        );
    }

    onFocus = () => this.setState({ focused: true });

    onBlur = () => this.setState({ focused: false });

    inputRef = (el: HTMLInputElement | null) => (this.input = el);

    focus = () => setTimeout(() => this.input && this.input.focus());

    onRemove = () => this.props.onRemove(this.props.object[objectFields.guid]);

    onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };
}

interface ObjectRowProps {
    object: TStringMap<any>;
    readOnly?: boolean;
    onChange: (field: string, value: any) => void;
    onRemove: (guid: string) => void;
}

interface ObjectRowState {
    focused: boolean;
}
