import { Classes, Divider, Popover } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { ChangelogType } from "modules/root/models/changelog";
import React from "react";
import styles from "./AppVersion.module.scss";

export const AppVersion = observer(({ version, changelog, prefix }: AppVersionProps) => {
    const last = changelog.length - 1;
    return changelog.length ? (
        <Popover
            position="auto"
            enforceFocus={false}
            isOpen={undefined}
            className="app-version"
            content={
                <div className={`${styles.changelog} ${Classes.ELEVATION_2}`}>
                    {changelog.map((log, index) => (
                        <div key={log.name}>
                            <h3 className={styles.title}>
                                {prefix}
                                {log.name}
                            </h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: log.description,
                                }}
                            />
                            {index < last && <Divider />}
                        </div>
                    ))}
                </div>
            }
        >
            {renderVersion(prefix + version, `bp3-tooltip-indicator ${styles.version}`, "Список изменений")}
        </Popover>
    ) : (
        renderVersion(prefix + version, styles.version, "")
    );
});

const renderVersion = (version: string, className: string, title: string) => (
    <span title={title} className={className}>
        v{version}
    </span>
);

interface AppVersionProps {
    version: string;
    changelog: ChangelogType[];
    prefix: string;
}
