import * as yup from "yup";
const { object, string } = yup;

export const workTypeLabels = {
  name: "Сокращенно",
  description: "Описание",
  workTypeCategory: "Категория",
};

const WorkTypeSchema = object().shape({
  name: string().label(workTypeLabels.name).required(),
  description: string().label(workTypeLabels.description).notRequired(),
  workTypeCategory: string().label(workTypeLabels.workTypeCategory).required(),
});

export default WorkTypeSchema;
