import { types, applySnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { flow } from "modules/common/models/flow";
import { sum } from "lodash";

export const SimpleDataset = types
    .compose(
        Transport,
        Notificator,
        types.model({
            url: types.string,
            labels: types.array(types.string),
            data: types.array(types.number),
            activeValue: types.number,
            completedValue: types.number,
        })
    )
    .views((self) => ({
        get amChartData() {
            const periods = self.labels;

            return periods.map(
                (period, index) =>
                    ({
                        period,
                        data: self.data[index],
                    } as SimpleChartDatum)
            );
        },

        get last() {
            return self.data.length > 0 ? self.data[self.data.length - 1] : 0;
        },

        get sum() {
            return sum(self.data);
        },
    }));

export const SimpleChartStore = types
    .compose(
        SimpleDataset,
        types.model({
            title: types.string,
            loaded: types.boolean,
            loading: types.boolean,
        })
    )
    .actions((self) => ({
        setLoaded(value: boolean) {
            self.loaded = value;
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            self.loading = true;

            try {
                const data: any = yield self.transport.get<any>(self.url);

                applySnapshot(self, {
                    ...data,
                    loaded: true,
                    loading: false,
                    url: self.url,
                    title: self.title,
                });

                return true;
            } catch (er) {
                self.loading = false;
                self.setLoaded(true);
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .views(() => ({
        get observable() {
            return null;
        },
    }));

export interface SimpleChartDatum {
    period: string;
    data: number;
}

export type SimpleChartDatasetType = typeof SimpleChartStore.Type;
export type SimpleChartDatasetSnapshotType = typeof SimpleChartStore.SnapshotType;

export const initialState = (url: string, title = ""): SimpleChartDatasetSnapshotType => ({
    title,
    url,
    activeValue: 0,
    completedValue: 0,
    labels: [],
    data: [],
    loaded: false,
    loading: false,
});
