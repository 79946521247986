import React from "react";
import { observer } from "mobx-react";
import { UserTimesheetType, formatHours } from "modules/spending/timesheet/models/user-timesheet";
import { EmployerName } from "modules/common/components/employer/EmployerName";
import styles from "./MonthUserRow.module.scss";
import { Popover, Icon, Menu, Classes, MenuItem, InputGroup } from "@blueprintjs/core";
import { texts } from "modules/common/texts";
import { InvisibleSubmit } from "modules/common/components/form";
import { Hotkey, Hotkeys, HotkeysTarget } from "@blueprintjs/core/lib/esnext";
import { Formik, Form, Field, FieldProps } from "formik";
import { fields } from "../models/user-timesheet";
import TimesheetMonthSchema from "./validation";
import { MODAL_AWAIT_DELAY } from "modules/common/services/form/textarea";

import { PlanrButton } from "modules/common/components/planr/button/Button";

const schema = TimesheetMonthSchema();

@HotkeysTarget
class Row extends React.Component<MonthUserRowProps, MonthUserRowState> {
    block = React.createRef<HTMLDivElement>();
    submit = React.createRef<HTMLButtonElement>();

    constructor(props: MonthUserRowProps) {
        super(props);

        this.state = { onEdit: false };
        props.onEditStateChanged && props.onEditStateChanged(false);
    }

    render() {
        const { timesheet, canEdit, showMinutes } = this.props;
        const { onEdit } = this.state;
        const workedOutHours = showMinutes ? timesheet.workedOutHours : Math.round(timesheet.workedOutHours);
        const overtimeHours = showMinutes
            ? timesheet.overtimeHours.totalHours
            : Math.round(timesheet.overtimeHours.totalHours);
        const overheadHours = showMinutes ? timesheet.overheadHours : Math.round(timesheet.overheadHours);
        const workedOutFromHomeHours = showMinutes
            ? timesheet.workedOutFromHomeHours
            : Math.round(timesheet.workedOutFromHomeHours);
        const dayOffHours = showMinutes
            ? timesheet.overtimeHours.dayOffHours
            : Math.round(timesheet.overtimeHours.dayOffHours);
        const missingHours = showMinutes ? timesheet.missingHours : Math.round(timesheet.missingHours);
        const totalOtherMissingHours = showMinutes
            ? timesheet.totalOtherMissingHours
            : Math.round(timesheet.totalOtherMissingHours);
        const illnessHours = showMinutes ? timesheet.illnessHours : Math.round(timesheet.illnessHours);
        const vacationHours = showMinutes ? timesheet.vacationHours : Math.round(timesheet.vacationHours);
        return (
            <Formik
                initialValues={getFormValues(timesheet)}
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    const succsessfull = await timesheet.save(values);
                    setSubmitting(false);

                    if (succsessfull) {
                        this.stopEdit();
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className={`month-user-row ${!onEdit ? styles.row : styles.editable} `}>
                            <div className="month-user-name">
                                <EmployerName name={timesheet.user.label} position={timesheet.user.position} />
                            </div>
                            <div className={`${styles.workedOut} ${styles.cell}`}>
                                {formatHours(timesheet.requiredHours)}
                            </div>
                            <div className={`${styles.workedOut} ${styles.cell}`}>{formatHours(workedOutHours)}</div>
                            <div className={`${styles.overtime} ${styles.cell}`}>{formatHours(overtimeHours)}</div>
                            <div className={`${styles.overtimeScale} ${styles.cell}`} ref={this.block}>
                                {!onEdit && timesheet.overtimeScale}
                                {onEdit && (
                                    <Field name={fields.overtimeScale}>
                                        {({ field }: FieldProps) => {
                                            return (
                                                <InputGroup
                                                    type="number"
                                                    className="planr-default-input"
                                                    id={field.name}
                                                    small={true}
                                                    autoComplete="off"
                                                    data-lpignore="true"
                                                    {...field}
                                                />
                                            );
                                        }}
                                    </Field>
                                )}

                                {canEdit && !onEdit && (
                                    <PlanrButton
                                        type="neutral"
                                        icon="general-edit"
                                        onClick={this.startEdit}
                                        size="small"
                                        style={{ color: "#EA561E" }}
                                    />
                                )}
                                {canEdit && onEdit && (
                                    <>
                                        <InvisibleSubmit innerRef={this.submit} />
                                        <PlanrButton
                                            type="neutral"
                                            icon="general-redo"
                                            onClick={() => {
                                                !isSubmitting && this.submit.current && this.submit.current.click();
                                            }}
                                            size="small"
                                            style={{ color: "#1DD278" }}
                                        />
                                    </>
                                )}
                            </div>
                            <div className={`${styles.dayOff} ${styles.cell}`}>{formatHours(dayOffHours)}</div>
                            <div className={`${styles.fromHome} ${styles.cell}`}>
                                {formatHours(workedOutFromHomeHours)}
                            </div>
                            <div className={`${styles.workedOut} ${styles.cell}`}>{formatHours(overheadHours)}</div>
                            <div className={`${styles.missing} ${styles.cell}`}>
                                <div>{formatHours(missingHours)}</div>
                            </div>
                            <div className={`${styles.missing} ${styles.cell}`}>
                                <div>{formatHours(vacationHours)}</div>
                            </div>
                            <div className={`${styles.missing} ${styles.cell}`}>
                                <div>{formatHours(illnessHours)}</div>
                            </div>
                            <div className={`${styles.missing} ${styles.cell}`}>
                                {formatHours(totalOtherMissingHours)}
                                {timesheet.totalOtherMissingHours > 0 && (
                                    <Popover
                                        position="bottom"
                                        content={
                                            <Menu className={Classes.ELEVATION_1}>
                                                {Array.from(timesheet.otherMissingHours.keys()).map((type, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        text={`${type} - ${formatHours(
                                                            timesheet.otherMissingHours.get(type) || 0
                                                        )}`}
                                                    />
                                                ))}
                                            </Menu>
                                        }
                                    >
                                        <span title={texts.more}>
                                            <Icon icon="chevron-down" className={styles.expander} />
                                        </span>
                                    </Popover>
                                )}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }

    renderHotkeys() {
        return (
            <Hotkeys>
                <Hotkey global={true} allowInInput={true} label="cancel edit" combo="esc" onKeyUp={this.stopEdit} />
            </Hotkeys>
        );
    }

    startEdit = () => {
        this.setState({ onEdit: true });
        this.props.onEditStateChanged && this.props.onEditStateChanged(true);

        setTimeout(() => {
            const input = this.block.current ? this.block.current.querySelector("input") : null;
            input && input.focus();
        }, MODAL_AWAIT_DELAY);
    };

    stopEdit = () => {
        this.setState({ onEdit: false });
        this.props.onEditStateChanged && this.props.onEditStateChanged(false);
    };
}

export const MonthUserRow = observer(Row);

export interface MonthUserRowEditor {
    canEdit: boolean;
    onEditStateChanged?: (state: boolean) => void;
}

interface MonthUserRowProps extends MonthUserRowEditor {
    timesheet: UserTimesheetType;
    showMinutes: boolean;
}

interface MonthUserRowState {
    onEdit: boolean;
}

function getFormValues(timesheet: UserTimesheetType) {
    return {
        [fields.overtimeScale]: timesheet.overtimeScale || "",
    };
}
