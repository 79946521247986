import { Schema, reach, ValidationError, string } from 'yup';
import { FormikProps, getIn } from 'formik';
import { uniq } from 'lodash';
import { Intent } from '@blueprintjs/core';

export function getFieldState (name: string, validationSchema: Schema<any>, form: FormikProps<any>): FormFieldState {
    const isRequired = isFieldRequred(name, validationSchema, form.values);
    const label = getFieldLabel(name, validationSchema, form.values);
    const error = getFieldError(name, form);

    return {
        error,
        intent: error ? 'danger' : 'none',
        label,
        isRequired
    };
}

export function isFieldRequred (name: string, validationSchema: Schema<any>, values: any) {
    const fieldSchema = getNestedSchema(name, validationSchema, values);

    const described = fieldSchema ? fieldSchema.describe() : null;

    const tests = described ? described.tests : null;
    let isRequired = false;

    if (fieldSchema && (!tests || !tests.length)) {
        try {
            const empty = getEmptyValue(fieldSchema);

            validationSchema.validateSyncAt(name, {
                ...values,
                [name]: empty
            });
        } catch (er) {
            if ((er as ValidationError).type === 'required') {
                isRequired = true;
            }
        }
    } else {
        isRequired = tests ? !!tests.find((test: any) => test.name === 'required') : false;
    }

    return isRequired;
}

export function getFieldLabel (name: string, validationSchema: Schema<any>, values: any): string {
    const fieldSchema = getNestedSchema(name, validationSchema, values);

    return fieldSchema ? fieldSchema.describe().label : '';
}

export function getFieldError (name: string, form: FormikProps<any>) {
    const touched = !!getIn(form.touched, name);

    const result = touched ? getIn(form.errors, name) : '';
    const error = result && Array.isArray(result) ? (uniq(result)).join('. ') : result;

    return error || '';
}

function walk (value: any) {
    let result: string[] = [];

    if (typeof value === 'string') {
        result.push(value);
    } else if (Array.isArray(value)) {
        result = value.reduce((acc, val: any) => [ ...acc, ...walk(val) ], [] as string[]);
    } else if (typeof value === 'object') {
        result = Object.values(value).reduce((acc: string[], val: any) => [ ...acc, ...walk(val) ], [] as string[]);
    }

    return result;
}

/** Collect messages as string from formik ErrorMessage component */
export function collectMessages (message: any) {
    if (!message || typeof message === 'string') {
        return message;
    }

    const iteratee = Array.isArray(message)
        ? message
        : Object.values(message);

    return uniq(walk(iteratee.filter((m) => !!m))).join('. ');
}

function getNestedSchema (name: string, validationSchema: Schema<any>, values: any = undefined): Schema<any> | null {
    try {
        let schema = reach(validationSchema, name, values, values);

        // yup.lazy
        if (schema && schema.hasOwnProperty('_resolve')) {
            schema = (schema as any).resolve({ value: values });
        }

        return schema;
    } catch {
        return null;
    }
}

function getEmptyValue(schema: Schema<any>): any {
    if (schema instanceof string) {
        return '';
    }

    return null;
}

export interface FormFieldState {
    isRequired: boolean;
    label: string;
    error: string;
    intent: Intent;
}
