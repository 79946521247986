import { categoryLabels } from "../details/category-validation";
import { getColumnSize } from "modules/common/services/table/size-storage";
import { fields } from "modules/dictionaries/inventory/models/category";
import { ColDef } from "@ag-grid-community/core";
import { gridMoneyFormatter } from "modules/common/components/money/Money";

const labels = categoryLabels();

export const columns = (tableName: string) => {
    const columnSize = getColumnSize(tableName);

    const result: ColDef[] = [
        {
            headerName: labels[fields.fullInventoryNumber],
            field: fields.fullInventoryNumber,
            width: columnSize(fields.fullInventoryNumber, 60),
            sortable: true,
            resizable: false,
        },
        {
            headerName: labels[fields.name],
            field: fields.name,
            width: 180,
            sortable: true,
            resizable: false,
        },
        {
            headerName: labels[fields.totalItemsCount],
            field: fields.totalItemsCount,
            width: columnSize(fields.totalItemsCount, 60),
            sortable: true,
            resizable: false,
        },
        {
            headerName: labels[fields.freeItemsCount],
            field: fields.freeItemsCount,
            width: columnSize(fields.freeItemsCount, 65),
            sortable: true,
            resizable: false,
        },
        {
            headerName: labels[fields.sum],
            field: fields.sum,
            width: columnSize(fields.sum, 120),
            sortable: true,
            resizable: false,
            valueFormatter: gridMoneyFormatter,
        },
    ];

    return result;
};
