import React from "react";
import { fields as bankFields } from "../../models/bank-details";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { Schema } from "yup";
import { TextArea, Classes } from "@blueprintjs/core";
import { BankDetailsFields } from "modules/agents/components/BankDetailsFields";

type FieldsType = typeof bankFields;

export const BankDetailsForm = ({ schema, fields, commentRef, readonly }: BankDetailsFormProps) => {
    return (
        <>
            <BankDetailsFields schema={schema} fields={fields} inline={true} readonly={readonly} />

            <StandardFormInput name={fields.comment} schema={schema} small={true} className="planr-form-input">
                {({ field }) => (
                    <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                        <TextArea
                            id={field.name}
                            {...field}
                            growVertically={true}
                            className={Classes.FILL}
                            inputRef={commentRef}
                            disabled={readonly}
                        />
                    </div>
                )}
            </StandardFormInput>
        </>
    );
};

interface BankDetailsFormProps {
    fields: FieldsType;
    schema: Schema<any>;
    commentRef?: (ref: HTMLTextAreaElement | null) => any;
    readonly?: boolean;
}
