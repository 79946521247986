import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { routes } from "modules/common/routes";
import { SimpleMessage, SimpleMessageProps } from "./SimpleMessage";
import { OrderTabs } from "modules/orders-manage/details/OrderDetailsTabsPage";

export const OrderRelativeMessage = observer(
    class extends React.Component<OrderRelativeMessageProps> {
        render() {
            const { message, ...rest } = this.props;
            const orderId = message.data.get("OrderId");
            const tab = message.data.get("OrderTab") || OrderTabs.summary;
            const unitGuid = message.data.get("UnitGuid") || null;
            let option = "";
            if (unitGuid) {
                option = "&rowId=" + unitGuid;
            }
            return (
                <SimpleMessage message={message} {...rest}>
                    <Link to={routes.orders.details(orderId, tab) + option}>Перейти к договору</Link>
                </SimpleMessage>
            );
        }
    }
);

interface OrderRelativeMessageProps extends SimpleMessageProps {}
