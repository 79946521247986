import React, { useRef, useState } from "react";
import { UserWorkloadType } from "modules/spending/workload/models/user-workload";
import styles from "./WorkloadUserRow.module.scss";
import { EmployerName } from "modules/common/components/employer/EmployerName";
import { observer } from "mobx-react";
import { WorkloadCell, DaySelector, WorkloadCellMode } from "./WorkloadCell";
import {
  WorkloadDayStoreType,
  WorkloadDayType,
  TasksDictionaryType,
} from "modules/spending/workload/models/workload-day";
import { OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";
import { WorkloadInput, WorkloadSaver } from "./WorkloadInput";
import { getSnapshot } from "mobx-state-tree";
import { makeWorkloadInputStore } from "modules/spending/workload/models/workload-store";
import { ReactMouseSelect } from "react-mouse-select";

export const WorkloadUserRow = observer((props: WorkloadUserRowProps) => {
  const {
    workload,
    onSave,
    selectedCells,
    onSelectCells,
    onSelectCell,
    tasks,
    removeDay,
    selectedCell,
    onContext,
    ...selector
  } = props;

  const [formStore, setformStore] = useState<WorkloadDayStoreType | null>(null);

  const selectCel = (str: string) => {
    const index = selectedCells
      ? selectedCells.findIndex((cell) => {
          return cell === str;
        })
      : 0;
    if (index === -1) {
      onSelectCells && onSelectCells([]);
      onSelectCell(str);
    } else {
      onSelectCell(str);
    }
  };
  const onDayEditStart = (day: WorkloadDayType) => {
    const { orders, tasks } = props;

    setformStore(makeWorkloadInputStore(day, getSnapshot(orders.orders), getSnapshot(tasks.tasks)));
  };
  const closeDialog = () => {
    setformStore(null);
  };
  const onDayEditStop = () => setformStore(null);

  const selectorRef = useRef<HTMLDivElement>(null);
  const itemClassName = "mouse-select__selectable";
  return (
    <div className={`timesheet-user-row ${styles.row}`}>
      <div
        className={`timesheet-user-name ${workload.user.nonProduction ? styles.nonProduction : null}`}
        // style={workload.user.nonProduction ? { background: " #f6f8f9ee" } : {}}
      >
        <EmployerName name={workload.user.label} position={workload.user.position} />
      </div>
      <ReactMouseSelect
        containerRef={selectorRef}
        itemClassName={itemClassName}
        startSelectionCallback={(e: MouseEvent) => {
          onSelectCells && onSelectCells([]);
          onSelectCell("");
        }}
        finishSelectionCallback={(items: Element[], e: MouseEvent) => {
          const selectedItems = items.map((item) => item.getAttribute("data-id") || "");
          let dateItems: string[] = [];
          if (selectedItems.length > 0) {
            selectedItems.forEach((item) => {
              const date = item.slice(0, 10);

              const day = workload.days.find((day) => day.day === date);
              if (day && !day.isDayOff) {
                dateItems.push(item);
              }
            });
            onSelectCells && onSelectCells(dateItems);
            onSelectCell("");
          }
        }}
      />
      <div ref={selectorRef} className="timesheet-user-days" style={{ display: "flex" }}>
        {workload.days.map((day, index) => {
          const next = index + 1 < workload.days.length ? workload.days[index + 1] : undefined;
          const preToday = !!next && next.isToday();

          return (
            <WorkloadCell
              key={day.day}
              day={day}
              preToday={preToday}
              onEdit={onDayEditStart}
              locked={false}
              selectedCells={selectedCells}
              onSelectCel={selectCel}
              selectedCell={selectedCell}
              onContext={onContext}
              nonProduction={workload.user.nonProduction}
              {...selector}
            />
          );
        })}
      </div>

      {formStore && (
        <WorkloadInput
          store={formStore}
          isOpened={true}
          onClose={onDayEditStop}
          onSave={onSave}
          readOnly={false}
          removeDay={removeDay}
          closeDialog={closeDialog}
        />
      )}
    </div>
  );
});

interface WorkloadUserRowProps extends DaySelector, WorkloadSaver {
  workload: UserWorkloadType;
  orders: OrderDictionaryType;
  tasks: TasksDictionaryType;
  canManage: boolean;
  mode: WorkloadCellMode;
  userLogin: string;
  selectedCells: string[];
  onSelectCells: (cell: string[]) => void;
  removeDay: () => void;
}
