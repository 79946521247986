import { types } from "mobx-state-tree";
import { SimpleChartStore, initialState as simpleChartState } from "./simple-chart";
import { OrderDictionaryItem } from "modules/orders-manage/models/order-dictionary";

const EngineerDatasetOrderItem = types
    .compose(
        OrderDictionaryItem,
        types.model({
            status: types.string,
            color: types.string,
            profitability: types.number,
            profitabilityPercents: types.number,
            gipPremiumSum: types.number,
            sum: types.number,
        })
    )
    .named("EngineerDatasetOrderItem");

export const EngineersLoadingDataset = types
    .compose(
        SimpleChartStore,
        types.model({
            orders: types.array(types.array(EngineerDatasetOrderItem)),
        })
    )
    .named("EngineersLoadingDataset");

export type EngineersLoadingDatasetType = typeof EngineersLoadingDataset.Type;
export type EngineersLoadingDatasetSnapshotType = typeof EngineersLoadingDataset.SnapshotType;
export type EngineerDatasetOrderItemType = typeof EngineerDatasetOrderItem.Type;

export const initialState = (url: string): EngineersLoadingDatasetSnapshotType => {
    return {
        ...simpleChartState(url),
        orders: [],
    };
};
