import React from "react";
import { KnowledgeBaseStoreType } from "modules/technical/models/store";
import { FileSystemPage } from "./FileSystemPage";
import styles from "./KnowledgeBasePage.module.scss";
import { Previewer } from "../../../common/components/files/Previewer";
import { ITreeNode } from "@blueprintjs/core/lib/esnext";
import { NodeData } from "./FileActions";
import { observer } from "mobx-react";
import { SessionType } from "modules/session/auth/models/session";
import { can } from "modules/session/auth/access";

export const KnowledgeBasePage = observer(
    class extends React.Component<KnowledgeBasePageProps, KnowledgeBasePageState> {
        constructor(props: any) {
            super(props);

            this.state = { document: null };
        }

        render() {
            const { store, session } = this.props;
            const { document } = this.state;
            const canManage = can(store.functionality, session.access);

            return (
                <div className={styles.page}>
                    <FileSystemPage store={store} canManage={canManage} previewFile={this.onPreview} />
                    <Previewer document={document && document.nodeData ? document.nodeData.meta : null} />
                </div>
            );
        }

        onPreview = (document: ITreeNode<NodeData>) => {
            this.setState({ document });
        };
    }
);

interface KnowledgeBasePageProps {
    store: KnowledgeBaseStoreType;
    session: SessionType;
}

interface KnowledgeBasePageState {
    document: ITreeNode<NodeData> | null;
}
