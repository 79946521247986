import React, { useCallback, useState } from "react";
import { BusinessEventDictionaryItemType, EventTypes } from "modules/orders-manage/models/order-events";
import styles from "./OrderTimeline.module.scss";
import { observer } from "mobx-react";
import { NoData } from "modules/common/components/table/NoData";
import classNames from "classnames";
import { Caret } from "modules/common/components/collapse/Caret";
import { Collapse } from "@blueprintjs/core";
import { colorLuminance } from "modules/common/services/colors";

export const OrderTimeline = observer(({ events, isCollapsed }: OrderTimelineProps) => {
    const [collapsed, setCollapsed] = useState(isCollapsed);
    const onToggleCollapse = useCallback(() => setCollapsed(!collapsed), [collapsed]);

    return (
        <>
            <h1 className="planr-block-header collapser" onClick={onToggleCollapse}>
                Временная шкала проекта
                <Caret collapsed={collapsed} />
            </h1>
            <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                <div className="collapse">
                    <div className={`${styles.timeline} order-timeline`}>
                        {!events.length && <NoData compact={true} />}
                        {events.length > 0 && (
                            <>
                                {events.map((event, index) => {
                                    const eventStyle = new BusinessEventStyle(event);

                                    return (
                                        <div
                                            className={classNames({
                                                [styles.event]: true,
                                                [styles.odd]: index % 2 === 0,
                                                [styles.isPlanEvent]: event.isPlanEvent,
                                                [styles.today]: event.isTodayPoint,
                                            })}
                                            key={event.id}
                                        >
                                            <div className={styles.axis}></div>
                                            <div
                                                className={styles.point}
                                                title={event.isTodayPoint ? event.triggerDate : ""}
                                            ></div>
                                            <div className={styles.line}></div>
                                            <div className={styles.date}>{event.triggerDateText}</div>
                                            <div className={styles.text} style={eventStyle.getLabelStyle()}>
                                                {event.label}
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </div>
                </div>
            </Collapse>
        </>
    );
});

class BusinessEventStyle {
    private type: string;
    private isPlanEvent: boolean;

    constructor(event: BusinessEventDictionaryItemType) {
        this.type = event.type;
        this.isPlanEvent = event.isPlanEvent;
    }

    private _getColor(): string | undefined {
        switch (this.type) {
            case EventTypes.OrderWorkStartedBusinessEvent:
            case "Завершение работы над проектом":
            case EventTypes.OrderStageStartedBusinessEvent:
            case EventTypes.OrderStageCompletedBusinessEvent:
            case EventTypes.OrderSignedBusinessEvent:
                return "#1dd278";
            case EventTypes.ExpertiseBusinessEvent:
                return "#ff0015";
            case EventTypes.SentExpertiseBusinessEvent:
                return "#ff0015";
            case "Подписание акта выполненных работ":
                return "#ffa500";
            case EventTypes.OrderPaymentBusinessEvent:
                return "#8ea1ff";
        }
    }

    public getColor(): string | undefined {
        let color = this._getColor();
        if (color && this.isPlanEvent) {
            color = colorLuminance(color, 0.2);
        }

        return color;
    }

    public getFontStyle(): string | undefined {
        if (
            this.type === EventTypes.OrderSignedBusinessEvent ||
            this.type === EventTypes.OrderWorkStartedBusinessEvent
        ) {
            return "italic";
        }
    }

    public getLabelStyle(): React.CSSProperties {
        return {
            color: this.getColor(),
            fontStyle: this.getFontStyle(),
        };
    }
}

type OrderTimelineProps = {
    events: BusinessEventDictionaryItemType[];
    isCollapsed: boolean;
};
