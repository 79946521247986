import React from "react";
import ReactDOM from "react-dom";
import { WorkTypeLinkSnapshotType } from "modules/orders-manage/models/order";
import { texts } from "modules/common/texts";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import styles from "./Block.module.scss";
import portal from "../portal";
import { EmployerDictionaryType } from "modules/spending/employee/models/employee-dictionary";
import { OutsourcerDictionaryType } from "modules/agents/outsourcers/models/outsourcer-dictionary";
import {
    DefaultSelectedOption,
    filterItemPredicate,
    renderSingleOption,
    SimpleSelect,
} from "modules/common/services/form/select";
import { IpdFileSnapshotType } from "modules/orders-manage/models/order-file";
import RenderRow from "./RenderRow";

type Event = React.ChangeEvent<HTMLInputElement>;
type TUnit = WorkTypeLinkSnapshotType;

export class Block extends React.PureComponent<BlockProps, BlockState> {
    private ref = React.createRef<HTMLDivElement>();

    constructor(props: BlockProps) {
        super(props);
        this.state = {
            active: null,
        };
    }

    componentDidUpdate(prevProps: BlockProps) {
        if (prevProps.touched !== this.props.touched && !this.props.touched) {
            this.deactivate();
        }
    }

    renderHeader = (title: string, readOnly: boolean) => {
        return (
            <div className={`${styles.row} ${styles.unitsHeader}`}>
                <div className={styles.objectTitle}>{title}</div>
                <hr />
                <div className={styles.rowFields}>
                    <div className={styles.iconCellEmpl}>Аутсорс</div>
                    <div className={styles.iconCell}></div>
                    {!readOnly && <div className={styles.orderNumber}>Том</div>}
                    <div className={styles.workAndInventory}>Шифр</div>
                    <div className={styles.description}>Описание</div> <div className={styles.markCell}>Штамп</div>
                    <div className={styles.selectEmployer}>Ответственный</div>
                    <div className={styles.progress}></div>
                    {!readOnly && <div className={styles.iconCell}></div>}
                </div>
            </div>
        );
    };

    renderWithDragDrop = () => {
        const { units, category, title, readOnly } = this.props;
        const { active } = this.state;

        return units.length > 0 ? (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId={category || "common"}>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className={styles.wrapper}>
                            {this.renderHeader(title, !!readOnly)}

                            {units.map((work) => {
                                const rowOnEdit = active !== null && active.guid === work.unit.guid;
                                return (
                                    <Draggable
                                        key={work.unit.guid}
                                        draggableId={work.unit.guid}
                                        index={work.index}
                                        isDragDisabled={rowOnEdit}
                                    >
                                        {(draggable, snapshot) => {
                                            const child = (
                                                <RenderRow
                                                    active={active}
                                                    deactivate={this.deactivate}
                                                    onSave={this.onSave}
                                                    onInput={this.onInput}
                                                    toggleOutsource={this.toggleOutsource}
                                                    activateHandler={this.activateHandler}
                                                    saveTempMentor={this.saveTempMentor}
                                                    clearWorkMentor={this.clearWorkMentor}
                                                    onUpload={this.props.onUpload}
                                                    work={work.unit}
                                                    isDragging={snapshot.isDragging}
                                                    download={this.props.onDownload}
                                                    draggable={draggable}
                                                    readOnly={readOnly}
                                                    onRemove={this.props.onRemove}
                                                    employers={this.props.employers}
                                                    agents={this.props.agents}
                                                    baseUrl={this.props.baseUrl}
                                                />
                                            );

                                            return snapshot.isDragging ? ReactDOM.createPortal(child, portal) : child;
                                        }}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        ) : (
            <div className={styles.wrapper}>{this.renderHeader(title, !!readOnly)}</div>
        );
    };

    renderPlain = () => {
        const { units } = this.props;

        return units.length > 0 ? (
            <div className={styles.wrapper}>
                {units.map((work, index) => (
                    <RenderRow
                        key={index}
                        active={this.state.active}
                        deactivate={this.deactivate}
                        onSave={this.onSave}
                        onInput={this.onInput}
                        toggleOutsource={this.toggleOutsource}
                        activateHandler={this.activateHandler}
                        saveTempMentor={this.saveTempMentor}
                        clearWorkMentor={this.clearWorkMentor}
                        onUpload={this.props.onUpload}
                        work={work.unit}
                        isDragging={false}
                        download={this.props.onDownload}
                        readOnly={true}
                        onRemove={this.props.onRemove}
                        employers={this.props.employers}
                        agents={this.props.agents}
                        baseUrl={this.props.baseUrl}
                    />
                ))}
            </div>
        ) : null;
    };

    render() {
        const { readOnly, activation } = this.props;

        activation && activation({ onActivate: this.activate });

        return (
            <div
                ref={this.ref}
                className={`${styles.block} ${!readOnly ? styles.active : ""}`}
                onDoubleClick={this.open}
            >
                {readOnly ? this.renderPlain() : this.renderWithDragDrop()}
            </div>
        );
    }

    onInput = (active: TUnit) => this.setState({ active });

    onSave = () => {
        const { active } = this.state;

        if (active) {
            const oldUnit = this.props.units.find((unit) => unit.unit.guid === active.guid);

            if (oldUnit) {
                this.props.onChange(oldUnit.unit, active);
                this.deactivate();
            }
        }
    };

    saveTempMentor = (item: SelectItem, guid: string) => {
        const { onChange, readOnly, units } = this.props;
        if (!readOnly) {
            const unit = units.find((u) => u.unit.guid === guid);
            if (unit) {
                onChange(unit.unit, { ...unit.unit, mentorId: item.id });
            }
        }
    };

    uploadstampFile = async (file: File) => {
        //  const { onChange, readOnly, units } = this.props;
        let active = this.state.active;

        const meta = await this.props.onUpload(file);
        if (meta && active) {
            active.stampFile = meta;
            //  this.setState({ ...active });
        }
    };

    clearWorkMentor = (guid: string) => {
        const { onChange, readOnly, units } = this.props;
        if (!readOnly) {
            const unit = units.find((u) => u.unit.guid === guid);
            if (unit) {
                onChange(unit.unit, { ...unit.unit, mentorId: null });
            }
        }
    };
    toggleOutsource = (e: React.MouseEvent<HTMLButtonElement>) => {
        const { onChange, readOnly, units } = this.props;
        if (!readOnly) {
            const guid = e.currentTarget.dataset.workGuid;
            const unit = units.find((u) => u.unit.guid === guid);
            if (unit) {
                onChange(unit.unit, { ...unit.unit, outsourced: !unit.unit.outsourced });
            }
        }
    };

    open = () => {
        const { readOnly, onOpen } = this.props;
        !readOnly && onOpen && onOpen();
    };

    activate = (unit: TUnit) => {
        this.setState({ active: { ...unit } });

        setTimeout(() => {
            if (this.ref.current) {
                const input = this.ref.current.querySelector("input");
                input && input.focus();
            }
        }, 100);
    };

    activateHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        const { units } = this.props;
        const guid = e.currentTarget.dataset.workGuid;
        const unit = units.find((u) => u.unit.guid === guid);

        if (unit) {
            this.activate(unit.unit);
        }
    };

    deactivate = () => this.setState({ active: null });

    onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        // initial index
        const from = result.source.index;
        // new index
        const to = result.destination.index;

        this.props.onSort(from, to);
    };
}

export type ActivationDelegate = (unit: TUnit) => void;

interface BlockProps {
    category: string;
    title: string;
    units: { index: number; unit: TUnit }[];
    onOpen?: () => void;
    onRemove: (unit: TUnit) => void;
    onChange: (from: TUnit, unit: TUnit) => void;
    onSort: (from: number, to: number) => void;
    touched: boolean;
    readOnly?: boolean;
    activation?: (data: { onActivate: ActivationDelegate }) => any;
    employers: EmployerDictionaryType;
    agents: OutsourcerDictionaryType;
    onUpload: (file: File) => Promise<IpdFileSnapshotType | null>;
    onDownload: (file: string) => Promise<boolean | undefined>;
    baseUrl: string;
}
interface Select {
    id: string;
    label: string;
}
interface BlockState {
    active: TUnit | null;
}

interface SelectRendererProps {
    item: Select;
    items: any[];
    onSelect: (item: SelectItem) => void;
    onClear: () => void;
}

export const MentorSelectRenderer = ({ item, items, onSelect, onClear }: SelectRendererProps) => {
    const style: React.CSSProperties = {
        fontWeight: 400,
    };

    if (!item || !item.id) {
        style.color = "#ea561e";
    }

    return (
        <SimpleSelect
            className={styles.mentorSelect}
            activeItem={item}
            filterable={true}
            itemPredicate={filterItemPredicate}
            popoverProps={{
                boundary: "viewport",
                usePortal: true,
                popoverClassName: "order-content_mentor-select-dropdown",
            }}
            inputProps={{
                placeholder: texts.search,
            }}
            items={items}
            itemRenderer={renderSingleOption}
            onItemSelect={onSelect}
            matchTargetWidth={false}
        >
            <DefaultSelectedOption option={item} onClear={onClear} style={style} />
        </SimpleSelect>
    );
};
