export default {
    TECHICAL_MANUALS_READ: 'TECHICAL_MANUALS_READ',
    TECHICAL_MANUALS_EDIT: 'TECHICAL_MANUALS_EDIT',
    TECHICAL_QUALITY_STANDARDS_READ: 'TECHICAL_QUALITY_STANDARDS_READ',
    TECHICAL_QUALITY_STANDARDS_EDIT: 'TECHICAL_QUALITY_STANDARDS_EDIT',
    TECHICAL_TEMPLATES_EDIT: 'TECHICAL_TEMPLATES_EDIT',
    TECHICAL_TEMPLATES_READ: 'TECHICAL_TEMPLATES_READ',
    TECHICAL_ORDERS_READ: 'TECHICAL_ORDERS_READ',
    TECHICAL_ORDERS_EDIT: 'TECHICAL_ORDERS_EDIT',
    TECHICAL_ORGANISATIONS_READ: 'TECHICAL_ORGANISATIONS_READ',
    TECHICAL_ORGANISATIONS_EDIT: 'TECHICAL_ORGANISATIONS_EDIT'
};
