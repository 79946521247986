import * as yup from "yup";
import { fields } from "modules/dictionaries/ipd-types/models/ipd-type";
import { texts } from "modules/common/texts";
const { object, string } = yup;

export const ipdTypeLabels = () => ({
    [fields.name]: texts.name,
    [fields.description]: texts.description,
});

const IpdTypeSchema = () => {
    const labels = ipdTypeLabels();

    return object().shape({
        [fields.name]: string().label(labels[fields.name]).required(),

        [fields.description]: string().label(labels[fields.description]).notRequired(),
    });
};

export default IpdTypeSchema;
