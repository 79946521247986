import React, { useCallback, useEffect, useRef, useState } from "react";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Block } from "modules/common/components/page/Block";
import { Route, matchPath, useHistory } from "react-router";
import { History } from "history";
import { routes } from "modules/common/routes";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { DepartmentDetails } from "./details/DepartmentDetails";
import { DepartmentsList } from "./list/DepartmentsList";
import { menuItems } from "modules/root/components/menu/MainMenu";
import { can } from "modules/session/auth/access";
import functionalities from "../../functionalities";
import EmployeePositionsPage from "./EmployeePositionsPage";
import { useMst } from "modules/root/models/hooks";
import styles from "./DepartmentsPage.module.scss";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { Search } from "modules/common/components/form/Search";

const menu = menuItems();

interface DepartmentsPageProps {
    access: string[];
    baseUrl: string;
}

const openDepartment = (history: History, id: string) => {
    const url = id ? routes.spending.department.details(id) : routes.spending.department.path;
    history.push(url);
};

const getSelectedDepartmentId = (pathname: string) => {
    const match = matchPath<any>(pathname, routes.spending.department.details());

    return match && match.params ? match.params.departmentId : "";
};

export const DepartmentsPage = observer((props: DepartmentsPageProps) => {
    const history = useHistory();
    const { departments, employeePositions } = useMst();
    const departmentId = useRef("");
    const [department, setdepartment] = useState("");

    useEffect(() => {
        document.title = menu.spending.nested.departments.label;

        departments.list.load();
        departments.details.load(getSelectedDepartmentId(history.location.pathname));

        return history.listen((loc) => {
            const currentDepartmentId = getSelectedDepartmentId(loc.pathname);

            if (departmentId.current !== currentDepartmentId) {
                departmentId.current = currentDepartmentId;
                departments.details.load(currentDepartmentId);
            }
        });
    }, [history, departments.list, departments.details]);

    const onDepartmentSelected = useCallback((id = "") => openDepartment(history, id), [history]);

    const onDepartmentClicked = (id = "") => {
        employeePositions.list.setDepartment(id);
        setdepartment(id);
    };

    const onDepartmentRemoved = useCallback(() => {
        departments.list.load();
        history.push(routes.spending.department.path);
    }, [history, departments.list]);

    const onDepartmentSaved = useCallback(() => {
        openDepartment(history, "");
        departments.list.load();
    }, [history, departments.list]);

    const onDepartmentAdd = useCallback(() => openDepartment(history, EMPTY_OBJECT_ID), [history]);

    const canChange = can(functionalities.DEPARTMENT_EDIT, props.access);

    return (
        <div className={styles.container}>
            <Block columned={true} className={styles.page}>
                <Route path={routes.spending.department.details()}>
                    <DetailsPopup title="Отдел" onCancel={onDepartmentSelected}>
                        <DepartmentDetails
                            department={departments.details}
                            onSaved={onDepartmentSaved}
                            onRemoved={onDepartmentRemoved}
                            canChange={canChange}
                        />
                    </DetailsPopup>
                </Route>
                <span className={styles.topSpan}>Отделы</span>
                {canChange && (
                    <PlanrButton
                        type="secondary"
                        size="small"
                        icon="general-plus-big"
                        onClick={onDepartmentAdd}
                        style={{
                            width: "200px",
                            marginBottom: "32px",
                            borderRadius: "120px",
                        }}
                    >{`${texts.add} отдел`}</PlanrButton>
                )}
                <div className={styles.right}>
                    <Search query={departments.list.query} onSearch={departments.list.setQuery} />
                </div>
                <DepartmentsList
                    list={departments.list}
                    onSelected={onDepartmentSelected}
                    onClicked={onDepartmentClicked}
                    onRemoved={onDepartmentRemoved}
                />
            </Block>

            <EmployeePositionsPage
                store={employeePositions}
                access={props.access}
                department={department}
                baseUrl={props.baseUrl}
            />
        </div>
    );
});
