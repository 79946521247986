import React from "react";

interface TelegramLinkProps {
    value: string;
    label?: string;
}

export const TelegramLink = ({ value, label }: TelegramLinkProps) => {
    return <a href={`tg://resolve?domain=${value}`}>{label || `@${value}`}</a>;
};
