export default {
  OUTSOURCER_READ: "AGENTS_OUTSOURCER_READ",
  OUTSOURCER_EDIT: "AGENTS_OUTSOURCER_EDIT",
  OUTSOURCER_CONFIRMATION: "AGENTS_OUTSOURCER_CONFIRMATION",
  AGENTS_SUPPLIER_ORDER: "AGENTS_SUPPLIER_ORDER",

  AGENT_READ: "AGENTS_AGENT_READ",
  AGENT_EDIT: "AGENTS_AGENT_EDIT",
  AGENT_CONFIRMATION: "AGENTS_AGENT_CONFIRMATION",
  AGENT_REQUEST: "AGENTS_AGENT_REQUEST",
  CLIENT_READ: "AGENTS_CLIENT_READ",
  CLIENT_EDIT: "AGENTS_CLIENT_EDIT",

  SUPPLIER_READ: "AGENTS_SUPPLIER_READ",
  SUPPLIER_EDIT: "AGENTS_SUPPLIER_EDIT",
};
