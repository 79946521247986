import { types, applySnapshot, isAlive, getSnapshot } from "mobx-state-tree";
import { BaseEntity, isNewlyCreated } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID, DATE_TIME_FORMAT } from "modules/common/constants";
import Schema from "../components/details/validation";
import moment from "moment";
import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";
import { getFieldLabel } from "modules/common/services/form/fields";
import { formatDate } from "modules/common/services/formatting/date";
import { DictionaryLink, initialState as emptyLink } from "modules/common/models/dictionary-link";
import { groupBy } from "lodash";
import { MimeTypes, base64ToBlob, printPdf, treatBase64 } from "modules/common/services/files";
import { FileMetadata } from "modules/common/models/file-metadata";
import { formatPhoneNumber } from "modules/common/services/formatting/phone";
import {
  EmployeePositionDictionaryLink,
  EmployeePositionLink,
} from "modules/spending/departments/models/employee-position";
import { RoleAccessRecord, RoleData } from "modules/session/access/models/access-store";
import { v4 } from "uuid";

export const ACCEPT = [MimeTypes.jpeg, MimeTypes.png, MimeTypes.pdf].join(",");

export const EmployerDocument = FileMetadata.views((self) => ({
  get asFileBase(): EmployerFileBase {
    return {
      fileId: self.id,
      fileName: self.name,
      type: self.type,
      mimeType: self.mimeType,
    };
  },
})).named("EmployerDocument");

const Child = types
  .model({
    guid: types.string,
    name: types.string,
    birthday: types.maybeNull(types.string),
  })
  .views((self) => ({
    get birthdayAsDate() {
      return self.birthday ? moment(self.birthday, DATE_TIME_FORMAT).toDate() : null;
    },
  }))
  .named("Child");

export const EmployerBase = types
  .compose(
    Transport,
    Notificator,
    BaseEntity,
    types.model({
      name: types.string,
      shortName: types.string,
      phone: types.string,
      avatar: types.maybeNull(types.string),
      email: types.string,
      poohBah: types.boolean,
      birthday: types.maybeNull(types.string),
      fireDate: types.maybeNull(types.string),
      fireDateSortable: types.string,
      hireDate: types.maybeNull(types.string),
      workSince: types.maybeNull(types.string),
      hobby: types.maybeNull(types.string),
      account: types.string,
      contractNumber: types.string,
      address: types.string,
      isBim: types.boolean,
      isRemoved: types.boolean,
      canBeEngineer: types.boolean,
      documents: types.array(EmployerDocument),
      employerPosition: EmployeePositionDictionaryLink,
      employerPositions: types.array(EmployeePositionLink),
      department: DictionaryLink,
      login: types.string,
      newPassword: types.string,
      tgUser: types.string,
      tgRegistered: types.boolean,
      access: types.array(RoleAccessRecord),
      workingPeriod: types.string,
      agePeriod: types.string,
      canBeReturn: types.boolean,
      children: types.array(Child),
    })
  )
  .views((self) => ({
    get childCount() {
      return self.children ? self.children.length.toString() : "0";
    },
    get birthdayAsDate() {
      return self.birthday ? moment(self.birthday, DATE_TIME_FORMAT).toDate() : null;
    },
    get hireDateAsDate() {
      return self.hireDate ? moment(self.hireDate, DATE_TIME_FORMAT).toDate() : null;
    },
    get fireDateAsDate() {
      return self.fireDate ? moment(self.fireDate, DATE_TIME_FORMAT).toDate() : null;
    },
    get workSinceAsDate() {
      return self.workSince ? moment(self.workSince, DATE_TIME_FORMAT).toDate() : null;
    },
    get fired() {
      return fired(self);
    },
    get employePositionId() {
      return self.employerPosition.id;
    },
    get departmentId() {
      return self.department.id;
    },
    get documentGroups() {
      return groupBy(self.documents, (doc) => doc.type);
    },
    get formattedPhone() {
      return formatPhoneNumber(self.phone);
    },

    get role(): RoleData {
      return {
        access: getSnapshot(self.access),
        id: self.id,
        label: self.name,
        sublabel: self.employerPosition.name,
      };
    },
  }));

export const EmployerLink = types.compose(
  DictionaryLink,
  types.model({
    position: types.string,
    login: types.string,
  })
);

export const Employer = EmployerBase.actions((self) => ({
  load: flow(function* (id: string) {
    try {
      const snapshot: any = isNewlyCreated(id)
        ? initialState()
        : yield self.transport.get<EmployerSnapshotType>(apiUrls.employee.details(id));

      applySnapshot(self, treat(snapshot));
      return true;
    } catch (er) {
      self.notify.error(er);
      return false;
    }
  }),

  getCredentials: flow(function* () {
    try {
      const result: EmployerCredentials = yield self.transport.get<any>(apiUrls.employee.credentials(self.id));
      return result;
    } catch (er) {
      self.notify.error(er);
      return null;
    }
  }),

  delete: flow(function* () {
    if (self.isNewlyCreated) {
      return false;
    }

    try {
      yield self.transport.delete<boolean>(apiUrls.employee.delete(self.id));
      self.notify.success(texts.messages.removed);

      isAlive(self) && applySnapshot(self, initialState());
      return true;
    } catch (er) {
      self.notify.error(er);
      return false;
    }
  }),

  restore: flow(function* () {
    if (self.isNewlyCreated) {
      return false;
    }

    try {
      const ok = yield self.transport.post<boolean>(apiUrls.employee.restore(self.id));
      if (isAlive(self)) {
        self.isRemoved = false;
      }

      self.notify.success("Данные успешно восстановлены");
      return ok;
    } catch (er) {
      self.notify.error(er);
      return false;
    }
  }),
}))
  .actions((self) => ({
    getFile: flow(function* (field) {
      try {
        const data: any = yield self.transport.get<EmployerSnapshotType[]>(
          apiUrls.application.files.content(field.fileId, field.fileName),
          {
            responseType: "arraybuffer",
          }
        );

        const buff = Buffer.from(data, "binary").toString("base64");
        const fimageSrc = treatBase64(buff, "image/jpeg");
        return fimageSrc;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),

    save: flow(function* (patch: TStringMap<any>) {
      try {
        // convert input dates
        if (patch[fields.fireDate] instanceof Date) {
          patch[fields.fireDate] = formatDate(patch[fields.fireDate]);
        }

        if (patch[fields.birthday] instanceof Date) {
          patch[fields.birthday] = formatDate(patch[fields.birthday]);
        }

        if (patch[fields.hireDate] instanceof Date) {
          patch[fields.hireDate] = formatDate(patch[fields.hireDate]);
        }

        if (patch[fields.workSince] instanceof Date) {
          patch[fields.workSince] = formatDate(patch[fields.workSince]);
        }

        if (patch.documents) {
          patch.uploads = (patch.documents as EmployerFileBase[]).reduce((map, d) => {
            if (!map[d.type]) {
              map[d.type] = [];
            }

            map[d.type].push(d.fileId);

            return map;
          }, {} as TStringMap<string[]>);

          delete patch.documents;
        }

        if (typeof patch[fields.salary] === "undefined") {
          patch[fields.salary] = 0;
        } else if (typeof patch[fields.salary] === "string") {
          patch[fields.salary] = parseFloat(patch[fields.salary]);
        }
        if (typeof patch[fields.bonus] === "undefined") {
          patch[fields.bonus] = 0;
        } else if (typeof patch[fields.bonus] === "string") {
          patch[fields.bonus] = parseFloat(patch[fields.bonus]);
        }

        if (patch[fields.changePositionDate] instanceof Date) {
          patch[fields.changePositionDate] = formatDate(patch[fields.changePositionDate]);
        }

        if (typeof patch[fields.changeSalary] === "undefined") {
          patch[fields.changeSalary] = 0;
        } else if (typeof patch[fields.changeSalary] === "string") {
          patch[fields.changeSalary] = parseFloat(patch[fields.changeSalary]);
        }
        if (typeof patch[fields.changeBonus] === "undefined") {
          patch[fields.changeBonus] = 0;
        } else if (typeof patch[fields.changeBonus] === "string") {
          patch[fields.changeBonus] = parseFloat(patch[fields.changeBonus]);
        }

        patch[fields.children] = self.children;

        const snapshot: any = self.isNewlyCreated
          ? yield self.transport.put<EmployerSnapshotType>(apiUrls.employee.create(), patch)
          : yield self.transport.post<EmployerSnapshotType>(apiUrls.employee.update(self.id), patch);

        isAlive(self) && applySnapshot(self, treat(snapshot));

        self.notify.success(texts.messages.saved);

        return true;
      } catch (er) {
        self.notify.error(er);

        return false;
      }
    }),
    addChild(guid: string, name: string, birthday: string) {
      const child: ChildSnapshotType = { guid: v4(), name, birthday };
      const itemIndex = self.children.findIndex((ch) => ch.guid === guid);
      if (itemIndex > -1) {
        self.children[itemIndex].birthday = child.birthday;
        self.children[itemIndex].name = child.name;
      } else {
        self.children.push(child);
      }
    },
    removeChild(id: string) {
      const index = self.children.findIndex((c) => c.guid === id);
      if (index > -1) {
        self.children.splice(index, 1);
      }
    },
    uploadFile: flow(function* (file: File, type: string) {
      try {
        const model = new FormData();

        model.append("file", file);
        model.append("accept", ACCEPT);

        const result: UploadFileResult = yield self.transport.post<any>(apiUrls.application.files.upload, model);

        const { id } = result;

        return { fileId: id, fileName: file.name, type } as EmployerFileBase;
      } catch (er) {
        self.notify.error(er);
        return null;
      }
    }),

    printDocuments: flow(function* (documentTypes: string[]) {
      if (self.isNewlyCreated) {
        return false;
      }

      try {
        const result: DownloadFileResult = yield self.transport.post<any>(
          apiUrls.employee.printUserDocuments(self.id),
          { types: documentTypes }
        );

        const blob: any = yield base64ToBlob(result.content || "", result.mimeType);

        if (blob) {
          const fileURL = URL.createObjectURL(blob);
          const printer = printPdf(fileURL, true);
          if (printer) {
            printer.onclose = () => URL.revokeObjectURL(fileURL);
          }
        }
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),

    setFunctionality: flow(function* (functionality: string, state: boolean) {
      try {
        const data: any = yield self.transport.post<any>(apiUrls.employee.singleAccess(self.id), {
          functionality,
          state,
        });

        applySnapshot(self.access, data);

        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
  }))
  .named("Employer");

export interface EmployerFileBase extends Omit<FileBase, "previewMimeType"> {
  type: string;
}

export const treat = (snapshot: EmployerSnapshotType) => {
  snapshot.newPassword = "";
  return snapshot;
};

export type EmployerLinkType = typeof EmployerLink.Type;
export type EmployerSnapshotType = typeof EmployerBase.SnapshotType;
export type EmployerType = typeof Employer.Type;

export type ChildSnapshotType = typeof Child.SnapshotType;
export type ChildType = typeof Child.Type;

export const fired = (e: EmployerSnapshotType) => e.fireDate !== null;

export const initialState = (): EmployerSnapshotType => ({
  id: EMPTY_OBJECT_ID,
  created: moment().format(DATE_TIME_FORMAT),
  name: "",
  shortName: "",
  phone: "",
  email: "",
  tgUser: "",
  tgRegistered: false,
  birthday: null,
  avatar: null,
  hireDate: null,
  fireDate: null,
  hobby: null,
  fireDateSortable: "",
  poohBah: false,
  account: "",
  address: "",
  isBim: false,
  isRemoved: false,
  canBeEngineer: false,
  canBeReturn: false,
  contractNumber: "",
  login: "",
  newPassword: "",
  workSince: null,
  documents: [],
  employerPosition: { ...emptyLink(), nonProduction: false, order: null },
  department: emptyLink(),
  access: [],
  workingPeriod: "",
  agePeriod: "",
  children: [],
  employerPositions: [],
});

export const initialChild = (): ChildSnapshotType => ({
  guid: "",
  name: "",
  birthday: null,
});

export const fields = {
  id: nameof((a: EmployerType) => a.id) as string,
  name: nameof((a: EmployerType) => a.name) as string,
  avatar: nameof((a: EmployerType) => a.avatar) as string,
  phone: nameof((a: EmployerType) => a.phone) as string,
  email: nameof((a: EmployerType) => a.email) as string,
  birthday: nameof((a: EmployerType) => a.birthday) as string,
  poohBah: nameof((a: EmployerType) => a.poohBah) as string,
  position: nameof((a: EmployerType) => a.employerPosition) as string,
  fireDate: nameof((a: EmployerType) => a.fireDate) as string,
  hireDate: nameof((a: EmployerType) => a.hireDate) as string,
  hobby: nameof((a: EmployerType) => a.hobby) as string,
  account: nameof((a: EmployerType) => a.account) as string,
  address: nameof((a: EmployerType) => a.address) as string,
  contractNumber: nameof((a: EmployerType) => a.contractNumber) as string,
  workSince: nameof((a: EmployerType) => a.workSince) as string,
  fired: nameof((a: EmployerType) => a.fired) as string,
  canBeReturn: nameof((a: EmployerType) => a.canBeReturn) as string,
  documents: nameof((a: EmployerType) => a.documents) as string,
  positionId: nameof((a: EmployerType) => a.employePositionId) as string,
  changePositionId: "changePositionId",
  changePositionDate: "changePositionDate",
  changeSalary: "changeSalary",
  changeBonus: "changeBonus",
  changeGrade: "changeGrade",
  changeEmployerId: "changeEmployerId",
  isBim: nameof((a: EmployerType) => a.isBim) as string,
  isRemoved: nameof((a: EmployerType) => a.isRemoved) as string,
  canBeEngineer: nameof((a: EmployerType) => a.canBeEngineer) as string,
  login: nameof((a: EmployerType) => a.login) as string,
  newPassword: nameof((a: EmployerType) => a.newPassword) as string,
  tgUser: nameof((a: EmployerType) => a.tgUser) as string,
  salary: "salary",
  bonus: "bonus",
  children: nameof((a: EmployerType) => a.children) as string,
};

export const childFields = {
  name: nameof((a: ChildType) => a.name) as string,
  birthday: nameof((a: ChildType) => a.birthday) as string,
};

export function formatEmployer(employer: EmployerSnapshotType | null) {
  let result = "";

  if (employer) {
    const schema = Schema();

    result += `${getFieldLabel(fields.name, schema, null)}: ${employer.name}\n`;
    result += `${getFieldLabel(fields.positionId, schema, null)}: ${employer.employerPosition.name}\n`;
    result += `${getFieldLabel(fields.phone, schema, null)}: ${formatPhoneNumber(employer.phone)}\n`;
    result += `${getFieldLabel(fields.address, schema, null)}: ${employer.address}\n`;
    result += `${getFieldLabel(fields.email, schema, null)}: ${employer.email}\n`;
    result += `${getFieldLabel(fields.poohBah, schema, null)}: ${employer.poohBah ? "Да" : "Нет"}\n`;
    result += `${getFieldLabel(fields.isBim, schema, null)}: ${employer.isBim ? "Да" : "Нет"}\n`;
    result += `${getFieldLabel(fields.birthday, schema, null)}: ${employer.birthday || ""}\n`;
    result += `${getFieldLabel(fields.workSince, schema, null)}: ${employer.workSince || ""}\n`;
    result += `${getFieldLabel(fields.fired, schema, null)}: ${employer.fireDate || ""}\n`;
  }

  return result;
}

export interface EmployerCredentials {
  login: string;
  password: string;
}

export const EmployeeFunctionalities = {
  INTERNAL_USER_ACCESS: "INTERNAL_USER_ACCESS",
};
