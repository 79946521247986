import React from "react";
import { TimesheetStoreType } from "modules/spending/timesheet/models/timesheet-store";
import { WeekDays } from "modules/spending/timesheet/components/WeekDays";
import { TimesheetUserRow } from "modules/spending/timesheet/components/TimesheetUserRow";
import { observer } from "mobx-react";
import styles from "./TimeSheet.module.scss";
import { ScrollToElement } from "modules/orders-manage/details/spending/helpers";
import { OrderExternalParams } from "modules/orders-manage/models/order";
import { RouteComponentProps, withRouter } from "react-router";

class Row extends React.Component<TimesheetProps & RouteComponentProps, TimesheetState> {
  private me = React.createRef<HTMLDivElement>();
  recordPointer = "";
  lightPointer = "";

  constructor(props: TimesheetProps & RouteComponentProps) {
    super(props);
    this.state = { selectedCell: "", monthModeIsEditing: false };
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    const external = OrderExternalParams(this.props.location.hash);

    this.recordPointer = yyyy + "." + mm + "." + dd;

    if (external.rowId) {
      this.lightPointer = external.rowId;
    }
  }

  componentDidMount() {
    ScrollToElement(this.me, this.recordPointer || "", 1000);
  }

  render() {
    const { userId, store, userLogin, canComment } = this.props;
    const { users, orders, tasks } = store;
    const { selectedCell } = this.state;

    const timesheet = users.find((r) => r.user.id === userId);
    if (!timesheet) {
      return null;
    }

    return (
      <div className={styles.row} ref={this.me}>
        <WeekDays days={timesheet.days} isMainPage />
        <TimesheetUserRow
          timesheet={timesheet}
          key={timesheet.user.id}
          mode="hours"
          orders={orders}
          canManage={true}
          onSelectCell={this.selectCell}
          selectedCell={selectedCell}
          userLogin={userLogin}
          onSave={this.saveDay}
          isMainPage
          tasks={tasks}
          changeComment={(model: any) => {
            store.setComment(model);
          }}
          canComment={canComment}
          sessionId={userId}
          showMinutes={store.showMinutes}
          multiSelectCells={true}
          onContext={() => {}}
          light={this.lightPointer}
        />
      </div>
    );
  }

  saveDay = (model: any) => this.props.store.save(model);

  selectCell = (selectedCell: string) => this.setState({ selectedCell });
}

export const TimesheetRow = withRouter(observer(Row));

interface TimesheetProps {
  store: TimesheetStoreType;
  userId: string;
  userLogin: string;
  canComment: boolean;
}

interface TimesheetState {
  selectedCell: string;
  monthModeIsEditing: boolean;
}
