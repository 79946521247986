import React from "react";
import { observer } from "mobx-react";
import styles from "./DepartmentBlock.module.scss";
import { ScheduleUserRow, UnitSelector, ScheduleManger } from "./ScheduleUserRow";
import { ScheduleStoreType } from "../models/schedule-store";
import { TotalLine } from "./TotalLine";
import { ScheduleUnitType } from "../models/schedule-unit";
import { Caret } from "modules/common/components/collapse/Caret";
import { Collapse } from "@blueprintjs/core";
import { Peoples } from "modules/orders-manage/details/Peoples";

class Block extends React.Component<DepartmentBlockProps> {
    render() {
        const { department, store, editableUnitId, collapsed, toggleCollapse, onContextMenu, ...rest } = this.props;
        const users = store.departmentMap[department];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, actual] = store.departmentTotals[department];

        return (
            <div className={`department-block ${styles.block}`}>
                <h1 className="planr-block-header-wide_lowscreen collapser " onClick={this.onToggleCollapse}>
                    <TotalLine store={actual} bonusTaxeHidden={store.bonusTaxeHidden}>
                        <div className={styles.departmentName}>
                            <div className={styles.department}>{department}</div>
                            <Peoples count={users.length} />
                        </div>
                    </TotalLine>
                    <Caret collapsed={collapsed} style={{ left: "7px" }} />
                </h1>
                <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                    <div className="collapse-wide_lowscreen">
                        {users.map((schedule) => (
                            <ScheduleUserRow
                                schedule={schedule}
                                key={schedule.plan.id}
                                editMode={editableUnitId === schedule.plan.id}
                                onContextMenu={this.onContextMenu}
                                bonusTaxeHidden={store.bonusTaxeHidden}
                                {...rest}
                            />
                        ))}
                    </div>
                </Collapse>
            </div>
        );
    }

    onContextMenu = (e: React.MouseEvent, unit: ScheduleUnitType) => {
        const { editableUnitId, onContextMenu } = this.props;

        if (!editableUnitId && onContextMenu) {
            onContextMenu(e, unit);
        }
    };

    onToggleCollapse = () => this.props.toggleCollapse(this.props.department);
}

export const DepartmentBlock = observer(Block);

interface DepartmentBlockProps extends UnitSelector, ScheduleManger {
    department: string;
    store: ScheduleStoreType;
    editableUnitId: string;
    onContextMenu?: (e: React.MouseEvent, unit: ScheduleUnitType) => void;
    collapsed: boolean;
    toggleCollapse: (key: string) => void;
    readonly: boolean;
}
