import React from "react";
import {
  CalendarEventStoreType,
  CalendarEventSnapshotType,
  CalendarEventDayType,
} from "modules/main/models/calendar-event-store";
import { observer } from "mobx-react";
import styles from "./Calendar.module.scss";
import { CalendarWidget } from "./CalendarWidget";
import { DayEvents } from "./DayEvents";
import { PeriodSliderGray } from "modules/common/components/period/PeriodSliderGray";
import { EventEditor } from "./EventEditor";
import { SessionType } from "modules/session/auth/models/session";
import { getSnapshot } from "mobx-state-tree";
import { TimesheetRow } from "./TimeSheet";
import { TaskList } from "../task-list/TaskList";
import { OrderContentTaskStoreType } from "modules/main/models/order-content-task-list";
import { NotificationStoreType } from "modules/root/models/alerts";
import { PlanrButton } from "modules/common/components/planr/button/Button";

const localTabName = "MainPageTab";

export const Calendar = observer(
  class extends React.Component<CalendarProps, CalendarState> {
    constructor(props: CalendarProps) {
      super(props);
      this.state = { selection: "", creation: null, tabIndex: 0 };
    }

    componentDidMount() {
      this.props.calendar.employee.load({
        departmentId: "",
        withSalary: null,
        sortByHierarchy: true,
        withFired: false,
      });
      this.props.calendar.load();

      const now = new Date();
      this.props.calendar.timesheet.load(now.getFullYear(), now.getMonth() + 1);
      let local = localStorage.getItem(localTabName);
      try {
        if (local) {
          this.setState({ tabIndex: +JSON.parse(local) });
        }
      } catch (e) {
        /*Ignore*/
      }
    }
    onRemove = (e: CalendarEventDayType, forAll: boolean) => this.props.calendar.remove(e.event.eventGuid, forAll);
    render() {
      const { calendar: store, session, canComment, orderContentTaskList, notification, baseUrl } = this.props;
      const { selection, creation, tabIndex } = this.state;

      const day = store.daysAsMap[selection];
      const events = store.eventsAsMap.get(selection) || [];

      return (
        <>
          <div className={`${styles.calendar} main-widget`}>
            <div className={` flat-mode ${styles.header}`}>
              <PeriodSliderGray year={store.year} month={store.month} onChange={this.gotoMonth} />
            </div>

            <TimesheetRow
              store={store.timesheet}
              userId={session.userId}
              userLogin={session.userLogin}
              canComment={canComment}
            />
          </div>
          <div className={styles.tabButtons}>
            <PlanrButton
              type={tabIndex === 0 ? "blueish" : "lightblueish"}
              size="small"
              onClick={() => {
                localStorage.setItem(localTabName, JSON.stringify(0));
                this.setState({ tabIndex: 0 });
              }}
            >
              Календарь
            </PlanrButton>
            <PlanrButton
              type={tabIndex === 1 ? "blueish" : "lightblueish"}
              size="small"
              onClick={() => {
                localStorage.setItem(localTabName, JSON.stringify(1));

                this.setState({ tabIndex: 1, selection: "" });
              }}
            >
              Задания
            </PlanrButton>
          </div>
          <div className={`${styles.calendar} main-widget`}>
            {tabIndex === 1 && (
              <div className={styles.tasks}>
                <TaskList store={orderContentTaskList} notification={notification} baseUrl={baseUrl} />
              </div>
            )}
            {tabIndex === 0 && <CalendarWidget store={store} selection={selection} onSelectDay={this.onSelectDay} />}

            {day && (
              <DayEvents
                day={day}
                events={events}
                onAdd={this.startCreation}
                onChange={this.startEdit}
                onRemove={this.onRemove}
              />
            )}
            {creation && (
              <EventEditor
                onClose={this.closeEditor}
                employee={store.employee}
                onSave={store.save}
                event={creation}
                currentUser={session.userId}
                width={700}
              />
            )}
          </div>
        </>
      );
    }

    onSelectDay = (day: string) => {
      if (day === this.state.selection) {
        day = "";
      }

      this.setState({ selection: day });
    };

    gotoMonth = (year: number, month: number) => {
      this.props.calendar.setPeriod(year, month);
      this.props.calendar.load();
      this.props.calendar.timesheet.load();
    };

    startCreation = (day: string) => {
      this.setState({ creation: this.props.calendar.factory(day) });
    };

    startEdit = (event: CalendarEventDayType) => {
      this.setState({ creation: getSnapshot(event.event) });
    };

    closeEditor = () => this.setState({ creation: null });
  }
);

interface CalendarProps {
  calendar: CalendarEventStoreType;
  session: SessionType;
  canComment: boolean;
  orderContentTaskList: OrderContentTaskStoreType;
  notification: NotificationStoreType;
  baseUrl: string;
}

interface CalendarState {
  selection: string;
  creation: CalendarEventSnapshotType | null;
  tabIndex: number;
}
