import { Classes, InputGroup } from "@blueprintjs/core";
import { Form, Formik } from "formik";
import { Buttons } from "modules/common/components/form";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import React, { useEffect, useState } from "react";
import { SessionType } from "../models/session";
import AuthSchema from "./validation";

const schema = AuthSchema();

export const AuthForm = ({ store }: AuthFormProps) => {
  const prefilled = !!store.credentials.login;
  const ignoreManager = prefilled
    ? {
        autoComplete: "off",
        "data-lpignore": "true",
      }
    : {};

  const [showCode, setShowCode] = useState(false);
  useEffect(() => {
    if (store.waitConfirm) {
      setShowCode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={getFormValues(store)}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={async (model) => {
        const res = await store.logIn(model);
        if (res === "notConfirm") {
          setShowCode(true);
        } else {
          setShowCode(false);
        }
      }}
    >
      {() => {
        return (
          <Form>
            <div className={Classes.DIALOG_BODY}>
              <StandardFormInput name="login" schema={schema} small={true} className="planr-form-input">
                {({ field }) => <InputGroup id="login" {...field} className="planr-default-input" {...ignoreManager} />}
              </StandardFormInput>

              <StandardFormInput name="password" schema={schema} small={true} className="planr-form-input">
                {({ field }) => (
                  <InputGroup
                    id="password"
                    {...field}
                    className="planr-default-input"
                    type="password"
                    {...ignoreManager}
                  />
                )}
              </StandardFormInput>
              {showCode && (
                <StandardFormInput name="code" schema={schema} small={true} className="planr-form-input">
                  {({ field }) => (
                    <InputGroup id="code" {...field} className="planr-default-input" {...ignoreManager} />
                  )}
                </StandardFormInput>
              )}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <Buttons>
                <PlanrButton
                  type="blueish"
                  nativeType="submit"
                  icon="general-sign-in"
                  size="small"
                  style={{ marginLeft: "80px", width: "170px" }}
                >
                  Вход
                </PlanrButton>
              </Buttons>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export interface AuthFormProps {
  store: SessionType;
}

function getFormValues(store: SessionType) {
  return {
    login: store.credentials.login,
    password: store.credentials.password,
  };
}
