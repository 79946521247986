import { applySnapshot, getSnapshot, types } from "mobx-state-tree";
import { EmployeeList, Employer, initialState as emptyList } from "./employer-list";
import { initialState as emptyEmployer, EmployerType } from "./employer";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { flow } from "modules/common/models/flow";
import { convertTextToImage, printImage, base64ToBlob, printPdf } from "modules/common/services/files";
import { getSiteAddress } from "modules/common/services/communication/http";
import { OwnSpendingsWidgetStore, initialState as emptyProjects } from "modules/main/models/own-spendings-store";
import { isNewlyCreated } from "modules/common/models/entity";
import { EmployerPremiums, initialState as emptyPremiums } from "./employer-premiums";
import { EmployeePositionDictionary } from "modules/spending/departments/models/employee-position-dictionary";
import { apiUrls } from "modules/common/services/communication/urls";
import { Constants } from "modules/root/models/constants";
import { buildAccessTree, makeColumns } from "modules/session/access/models/access-store";
import saveAs from "file-saver";

export const EmployeeStore = types
  .compose(
    Transport,
    Notificator,
    types.model({
      positions: EmployeePositionDictionary,
      list: EmployeeList,
      removed: EmployeeList,
      details: Employer,
      premiums: EmployerPremiums,
      projects: OwnSpendingsWidgetStore,
      authorization: types.map(types.string),
    })
  )
  .actions((self) => ({
    loadSpendings(employerId: string) {
      if (isNewlyCreated(employerId)) {
        self.projects.clear();
      } else {
        self.projects.load(employerId, true);
      }
    },
  }))
  .actions((self) => ({
    init: function (employerId: string) {
      applySnapshot(self.authorization, getSnapshot(Constants.authorization));

      self.details.load(employerId);
      self.premiums.load(employerId);
      self.loadSpendings(employerId);
      self.list.load();
      self.removed.load();
      self.positions.load();
    },

    printCredentials: flow(function* (employer: EmployerType) {
      const credentials = yield employer.getCredentials();

      if (credentials) {
        const dataURL = convertTextToImage([
          `Логин : ${credentials.login}`,
          `Пароль: ${credentials.password}`,
          `Ссылка: ${getSiteAddress()}`,
        ]);

        printImage(dataURL);
      }
    }),
    export: flow(function* () {
      try {
        let file: FileDescription;
        file = yield self.transport.post(apiUrls.employee.exportWorkTime, { asPdf: false });
        const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
        saveAs(blob, file.name);
      } catch (er) {
        self.notify.error(er);
        return false;
      } finally {
      }
    }),
    printDocuments: flow(function* (documentType: string) {
      if (!self.list.active.length || !documentType) {
        return false;
      }

      try {
        const ids = self.list.active.map((e) => e.id);

        const result: DownloadFileResult = yield self.transport.post<any>(apiUrls.employee.printAllDocuments, {
          ids,
          types: [documentType],
        });

        const blob: any = yield base64ToBlob(result.content || "", result.mimeType);

        if (blob) {
          const fileURL = URL.createObjectURL(blob);
          const printer = printPdf(fileURL, true);
          if (printer) {
            printer.onclose = () => URL.revokeObjectURL(fileURL);
          }
        }
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
  }))
  .views((self) => ({
    get accessTree() {
      return buildAccessTree(self.authorization.entries());
    },
  }))
  .views((self) => ({
    get accessColumns() {
      return makeColumns(self.accessTree, 4);
    },
  }))
  .named("EmployeeStore");

export const initialState = (): typeof EmployeeStore.SnapshotType => ({
  positions: { positions: [] },
  list: emptyList(),
  removed: emptyList(true),
  details: emptyEmployer(),
  premiums: emptyPremiums(),
  projects: emptyProjects(),
  authorization: {},
});

export type EmployeeStoreType = typeof EmployeeStore.Type;
