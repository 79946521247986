import { types } from "mobx-state-tree";
import { IpdTypeList, IpdType, initialState as emptyList } from "./ipd-type-list";
import { initialState as emptyIpdType } from "./ipd-type";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";

export const IpdTypesStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            list: IpdTypeList,
            details: IpdType,
        })
    )
    .named("IpdTypesStore");

export const initialState = (): typeof IpdTypesStore.SnapshotType => ({
    list: emptyList(),
    details: emptyIpdType(),
});

export type IpdTypesStoreType = typeof IpdTypesStore.Type;
