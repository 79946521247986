import React from "react";
import { ColDef, ValueGetterParams, ValueFormatterParams, ICellRendererParams } from "@ag-grid-community/core";
import {
    fields,
    OverheadSpendingsByYearType,
    OverheadSpendingsByMonthSnapshotType,
} from "../models/overhead-spendings";
import { Money, gridMoneyFormatter, formatMoney } from "modules/common/components/money/Money";
import { months } from "modules/common/components/form/DatePicker";
import { texts } from "modules/common/texts";
import { fields as detailsFields } from "modules/expenses/summary/models/spending-list-row";
import { AgentRenderer } from "modules/expenses/summary/components/columns";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { prevent } from "modules/common/services/form/select";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import { ROW_HEIGHT, DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import styles from "./OverheadSpendings.module.scss";
import { Popover, Icon, Menu, MenuItem, ContextMenu } from "@blueprintjs/core";
import { EMPTY_OBJECT_ID, ICON_BUTTON_SIZE } from "modules/common/constants";
import { MoneyInput } from "modules/common/components/money/MoneyInput";
import { NumberFormatValues } from "react-number-format";
import { RemoveConfirmation, RemoveConfirmationRenderProps } from "modules/common/components/form/RemoveConfirmation";
import { toString } from "modules/common/services/strings";
import { SortIcon, TSorting, CustomHeaderCell } from "modules/common/services/table/custom-header";
import { Link } from "react-router-dom";
import { routes } from "modules/common/routes";
import {
    END_SECTION,
    INCOMINTG_SECTION,
    PRODUCTION_SECTION,
    QUERY_SECTION,
    SPENDING_SECTION,
    START_SECTION,
} from "modules/expenses/summary/components/filter-params";
import { Constants } from "modules/root/models/constants";

export const columnsBuilder = (): ColDef[] => {
    const result: ColDef[] = [
        {
            suppressMovable: true,
            headerName: texts.name,
            headerClass: "centered",
            cellClass: "overheadType",
            field: fields.overheadTypeName,
            width: 179,
            sortable: true,
        },
        Month(0, "flat total-top"), // Январь
        Month(1, "flat total-top"), // Февраль
        Month(2, "flat total-top"), // Март
        Month(3, "flat total-top"), // Апрель
        Month(4, "flat total-top"), // Май
        Month(5, "flat total-top"), // Июнь
        Month(6, "flat total-top"), // Июль
        Month(7, "flat total-top"), // Август
        Month(8, "flat total-top"), // Сентябрь
        Month(9, "flat total-top"), // Октябрь
        Month(10, "flat total-top"), // Ноябрь
        Month(11, "flat total-top"), // Декабрь,
        Month(12, "flat total-column"), // Итого
        {
            suppressMovable: true,
            headerName: "%",
            field: "months[13].actualSum",
            width: 55,
            sortable: true,
            valueGetter: ({ data }: ValueGetterParams) => {
                const row: OverheadSpendingsByYearType = data;
                return row.months[13];
            },
            comparator: (valueA, valueB) => {
                if (valueA.actualSum === valueB.actualSum) {
                    return 0;
                } else {
                    return valueA.actualSum > valueB.actualSum ? 1 : -1;
                }
            },
            cellRendererFramework: RenderPercents,
            cellClass: "righted flat total-column",
            headerClass: "centered",
        },
    ];

    return result;
};

const monthHeader = [...months, texts.total];

const Month = (index: number, className = "", width = 103, symbol?: string): ColDef => ({
    suppressMovable: true,
    headerName: monthHeader[index],
    field: `months[${index}].actualSum`,
    width,
    sortable: true,
    valueGetter: ({ data }: ValueGetterParams) => {
        const row: OverheadSpendingsByYearType = data;
        return row.months[index];
    },
    comparator: (valueA, valueB) => {
        if (valueA.actualSum === valueB.actualSum) {
            return 0;
        } else {
            return valueA.actualSum > valueB.actualSum ? 1 : -1;
        }
    },
    headerComponentFramework: index < 12 ? headerRenderer(index) : undefined,
    cellRendererFramework: RenderMonth,
    cellClass: `righted ${className}`,
    headerClass: "centered",
});

const headerRenderer = (index: number) =>
    class RenderMonthHeader extends React.PureComponent<any, { sorting: TSorting }> {
        constructor(props: any) {
            super(props);

            props.column.addEventListener("sortChanged", this.onSortChanged);

            this.state = { sorting: this.getSorting() };
        }

        componentWillUnmount() {
            this.props.column.removeEventListener("sortChanged", this.onSortChanged);
        }

        render() {
            const sortIcon = this.props.enableSorting ? SortIcon(this.state.sorting) : null;

            return (
                <RemoveConfirmation<number>
                    what={() => "плановые расходы"}
                    actionName="перезаписать"
                    onConfirmed={this.copy}
                    render={(confirmation) => {
                        const menu = this.renderContextMenu(confirmation);

                        return (
                            <CustomHeaderCell
                                onContextMenu={(e) => this.showMenu(e, menu)}
                                onClick={this.setSort}
                                label={this.props.displayName}
                                children={<>{sortIcon}</>}
                            />
                        );
                    }}
                />
            );
        }

        showMenu = (e: React.MouseEvent<HTMLSpanElement>, menu: any) => {
            e.preventDefault();
            e.stopPropagation();

            ContextMenu.show(menu, { left: e.clientX, top: e.clientY });
        };

        setSort = () => {
            const toggle: TStringMap<string> = {
                asc: "desc",
                desc: "asc",
                none: "asc",
            };

            const direction = toggle[this.state.sorting];
            const sortModel = [{ colId: this.props.column.colId, sort: direction }];
            this.props.api.setSortModel(sortModel);
        };

        getSorting = () => {
            if (this.props.column.isSortAscending()) {
                return "asc";
            }

            if (this.props.column.isSortDescending()) {
                return "desc";
            }

            return "none";
        };

        onSortChanged = () => {
            this.setState({ sorting: this.getSorting() });
        };

        renderContextMenu = ({ confirmRemoving }: RemoveConfirmationRenderProps<number>) => {
            return (
                <Menu>
                    <MenuItem
                        onClick={() => confirmRemoving(1)}
                        text="Копировать план из предыдущего месяца"
                        icon="duplicate"
                    />

                    <MenuItem
                        onClick={() => confirmRemoving(0)}
                        text="Копировать план из предыдущего года"
                        icon="duplicate"
                    />
                </Menu>
            );
        };

        copy = (month: number) => {
            if (month === 1) {
                return this.getContext().copyFromMonth(index, index + 1);
            } else {
                return this.getContext().copyFromYear(index + 1);
            }
        };

        getContext = () => this.props.context as TableContext;
    };

class RenderPercents extends React.PureComponent<ICellRendererParams> {
    render() {
        const month: OverheadSpendingsByMonthSnapshotType = this.props.value;
        if (typeof month.planSum === "undefined" || typeof month.actualSum === "undefined") {
            return null;
        }

        return (
            <div>
                <div className="actual">{month.actualSum.toFixed(2)}%</div>
                <div className="plan">{month.planSum.toFixed(2)}%</div>
            </div>
        );
    }
}

class RenderMonth extends React.PureComponent<ICellRendererParams, { value: any; active: boolean }> {
    constructor(props: ICellRendererParams) {
        super(props);

        const month: OverheadSpendingsByMonthSnapshotType = props.value;

        const active = getCellId(props.value) ? this.getContext().getActivated() === getCellId(props.value) : false;

        this.state = { value: toString(month.planSum), active };
    }

    render() {
        const month: OverheadSpendingsByMonthSnapshotType = this.props.value;
        const { value, active } = this.state;
        const canActivate = !!month.id;
        const money =
            month.actual.length > 0 ? (
                <Popover position="auto" enforceFocus={false} isOpen={undefined}>
                    <Money amount={month.actualSum} className="bp3-tooltip-indicator" />

                    <CellDetails store={month} />
                </Popover>
            ) : (
                <Money amount={month.actualSum} />
            );

        return (
            <>
                <div className="actual">{money}</div>
                <div className="plan">
                    {!active && (
                        <Money
                            amount={month.planSum}
                            onClick={canActivate ? this.activate : undefined}
                            className={canActivate ? "editable" : ""}
                            title={canActivate ? texts.change : ""}
                        />
                    )}
                    {active && (
                        <>
                            <MoneyInput
                                value={value}
                                onMoneyChange={this.onMoneyChange}
                                small={true}
                                hideSuffix={true}
                                autoComplete="off"
                                data-lpignore="true"
                            />
                            {/* <PlanrButton
                                type="neutral"
                                size="tiny"
                                icon="general-undo"
                                onClick={this.cancel}
                                title={texts.cancel}
                            />
                            <PlanrButton
                                type="neutral"
                                size="tiny"
                                icon="general-redo"
                                onClick={this.save}
                                title={texts.save}
                            /> */}
                            <Icon
                                icon="undo"
                                onClick={this.cancel}
                                className={styles.undoButton}
                                iconSize={ICON_BUTTON_SIZE}
                                htmlTitle={texts.cancel}
                            />
                            <Icon
                                icon="floppy-disk"
                                className={styles.saveButton}
                                onClick={this.save}
                                iconSize={ICON_BUTTON_SIZE}
                                htmlTitle={texts.save}
                            />
                        </>
                    )}
                </div>
            </>
        );
    }

    activate = () => {
        const month: OverheadSpendingsByMonthSnapshotType = this.props.value;

        const id = getCellId(this.props.value);
        if (!this.getContext().getActivated()) {
            this.setState({ active: true, value: toString(month.planSum) });
            this.getContext().onActivate(id);
        }
    };

    onMoneyChange = (money: NumberFormatValues) => {
        this.setState({ value: money.value });
    };

    cancel = () => {
        this.setState({ active: false });
        this.getContext().onActivate("");
    };

    save = async () => {
        const { value } = this.state;
        if (!value) {
            return;
        }

        const success = await this.getContext().setSum(getCellId(this.props.value), parseFloat(value));
        if (success) {
            this.cancel();
        }
    };

    getContext = () => this.props.context as TableContext;
}

const getCellId = (node: OverheadSpendingsByMonthSnapshotType) => node.id;

export interface TableContext {
    copyFromYear: (month: number) => Promise<boolean>;
    copyFromMonth: (from: number, to: number) => Promise<boolean>;
    getActivated: () => string;
    onActivate: (id: string) => void;
    setSum: (id: string, sum: number) => Promise<boolean>;
}

export const detailsColumns = (): ColDef[] => {
    return [
        {
            suppressMovable: true,
            headerName: "Дата",
            field: detailsFields.sortableDate,
            width: 110,
            sortable: true,
            valueFormatter: ({ data }: ValueFormatterParams) => data[detailsFields.date],
            // cellClass: "centered",
            headerClass: "centered",
        },
        {
            suppressMovable: true,
            headerName: "Сумма",
            field: detailsFields.sum,
            width: 150,
            sortable: true,
            headerClass: "centered",
            valueFormatter: gridMoneyFormatter,
            cellRendererFramework: (params: ICellRendererParams) => {
                if (params.value) {
                    let url = routes.expenses.path + `#${INCOMINTG_SECTION}=${params.data.isIncoming}`;
                    url += `&${PRODUCTION_SECTION}=${params.data.isProduction}`;
                    url += `&${QUERY_SECTION}=`;
                    url += `&${START_SECTION}=${params.data.date}&${END_SECTION}=${params.data.date}`;
                    url += `&${SPENDING_SECTION}=${params.data.id}`;
                    if (params.data.id !== Constants.nonProductionHoursId && params.data.id !== EMPTY_OBJECT_ID) {
                        return (
                            <Link to={url} className="planr-button-link" target={"_blank"}>
                                <span>{formatMoney(params.value)}</span>
                            </Link>
                        );
                    }
                    return <span>{formatMoney(params.value)}</span>;
                } else {
                    return formatMoney(0);
                }
            },
        },
        {
            suppressMovable: true,
            headerName: "Контрагент",
            field: detailsFields.agentName,
            width: 400,
            sortable: true,
            headerClass: "centered",
            cellRendererFramework: AgentRenderer,
        },
    ];
};

const options = DefaultAgGridOptions();
const columns = detailsColumns();
const CellDetails = ({ store }: CellDetailsProps) => {
    return (
        <GridTheme
            className={`no-last-column-break vacation-details ${styles.cellDetails}`}
            onClick={prevent}
            onDoubleClick={prevent}
        >
            <AgGridReact
                rowStyle={{
                    borderBottom: "1 px solid #EDF0F2",
                    fontFamily: "GothamPro, Arial, sans-serif",
                    color: "#00273D",
                    fontSize: "12px",
                }}
                enableCellTextSelection={true}
                columnDefs={columns}
                rowData={store.actual}
                rowHeight={ROW_HEIGHT}
                headerHeight={ROW_HEIGHT}
                domLayout="autoHeight"
                gridOptions={options}
                modules={defaultModules}
                immutableData={true}
            />
        </GridTheme>
    );
};

interface CellDetailsProps {
    store: OverheadSpendingsByMonthSnapshotType;
}
