import * as yup from "yup";
import { fields } from "modules/agents/clients/models/client";
import { BankDetailsSchema } from "modules/common/models/bank-details";
import { extractDigits } from "modules/common/services/strings";
import { texts } from "modules/common/texts";
const { object, string } = yup;

export const clientLabels = () => ({
    [fields.name]: texts.name,
    [fields.fullName]: "Полное наименование",
    [fields.comment]: texts.comment,
    [fields.email]: "E-mail",
    [fields.phone]: "Телефон",
    [fields.rating]: "Рейтинг",
    [fields.login]: "Логин",
    [fields.newPassword]: "Пароль",
});

const ClientSchema = () => {
    const labels = clientLabels();
    return object().shape({
        [fields.name]: string().label(labels[fields.name]).required(),

        [fields.fullName]: string().label(labels[fields.fullName]).required(),

        [fields.comment]: string().label(labels[fields.comment]).notRequired(),

        [fields.email]: string().label(labels[fields.email]).notRequired().email(),

        [fields.phone]: string()
            .label(labels[fields.phone])
            .transform(function (val, _) {
                return this.isType(val) && val ? extractDigits(val) : val;
            })
            .required()
            .min(7, "Минимум 7 цифр")
            .max(15, "Максимум 15 цифр"),

        [fields.rating]: yup.number().label(labels[fields.rating]).min(1).max(5),

        [fields.bankDetails]: BankDetailsSchema().required(),

        [fields.login]: string()
            .label(labels[fields.login])
            .when(fields.newPassword, {
                is: (pass) => !!pass,
                then: string().required().min(5),
                otherwise: string().notRequired(),
            }),

        [fields.newPassword]: string().label(labels[fields.newPassword]),
    });
};

export default ClientSchema;
