import React from "react";
import { OrderPremiumDetailsType } from "modules/orders-manage/models/premium";
import { observer } from "mobx-react";
import styles from "./Premium.module.scss";
import { PaidMoney } from "./helpers";
import { Popover, Classes } from "@blueprintjs/core";
import { Money } from "modules/common/components/money/Money";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { PlanrTag } from "modules/common/components/planr/tag/PlanrTag";

class Premium extends React.Component<EngineerPremiumProps, EngineerPremiumState> {
    constructor(props: EngineerPremiumProps) {
        super(props);
        this.state = { userId: null };
    }

    render() {
        const { store, canAssing: can, onTouch, readOnly } = this.props;
        const premium = store.engineerPremium;
        const details = store.premiumRowsAsMap[premium.userId];
        const { assigned } = details;

        const canSetPayments = !!premium.userId && !premium.fired;
        const canAssing = can && canSetPayments;

        return (
            <div>
                <div className={`${styles.row} ${styles.summary}`}>
                    <div className={styles.user}>
                        <h3 className={styles.departmentName}>ГИП</h3>
                    </div>
                </div>

                <div className={`${styles.row} ${styles.header}`}>
                    <div className={styles.user}>ФИО</div>
                    <div className={styles.sum}>Сумма</div>
                    <div className={styles.quality}>Отзыв</div>
                    <div className={styles.sum}>Расчетная</div>
                    {(canAssing || assigned) && <div className={styles.sum}>Назначено</div>}
                    <div className={styles.sum}>Оплаты</div>
                    <div className={styles.sum}>Остаток</div>
                    <div className={styles.sum}></div>
                </div>

                <div className={`${styles.row} ${premium.fired ? styles.fired : ""}`}>
                    <div className={styles.user}>{premium.employer?.name}</div>
                    <div className={styles.sum}>
                        <Money amount={premium.bank} />
                    </div>
                    <div className={styles.quality}>{premium.hasQualityMark ? "Да" : "Нет"}</div>

                    <div className={styles.sum}>
                        <Popover
                            position="auto"
                            enforceFocus={false}
                            isOpen={undefined}
                            popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                            usePortal={true}
                        >
                            <Money amount={premium.sum} className="bp3-tooltip-indicator" />
                            <div className={styles.engineerDetails}>
                                <div>
                                    Бюджет: <Money amount={premium.budget} />
                                </div>
                                <div>
                                    Качество: <Money amount={premium.quality} />
                                </div>
                                <div>
                                    Сроки: <Money amount={premium.time} />
                                </div>
                            </div>
                        </Popover>
                    </div>

                    {(canAssing || assigned) && (
                        <div className={styles.assing}>
                            {assigned && (
                                <>
                                    <Money
                                        amount={assigned.sum}
                                        className={!premium.fired ? Classes.TOOLTIP_INDICATOR : ""}
                                        onClick={() => this.selectPremium(premium.userId)}
                                    />
                                    {canAssing && (
                                        <GeneralIcon
                                            type="general-trash"
                                            onClick={() => {
                                                store.removeAssingnation(premium);
                                                onTouch();
                                            }}
                                            style={{ color: "#E31818", cursor: "pointer" }}
                                        />
                                    )}
                                </>
                            )}
                            {!assigned && canAssing && (
                                <PlanrButton
                                    type="secondary"
                                    onClick={() => {
                                        store.assingPremium(premium);
                                        onTouch();
                                    }}
                                    size="small"
                                    className={`${styles.addButton} `}
                                    disabled={readOnly || premium.sum === 0}
                                    style={{
                                        height: "20px",
                                        fontSize: "10px",
                                        padding: "0px 7px",
                                    }}
                                >
                                    Назначить
                                </PlanrButton>
                            )}
                        </div>
                    )}

                    <div className={styles.sum}>
                        {canSetPayments && (
                            <PaidMoney
                                factory={premium.paymentFactory}
                                readOnly={readOnly}
                                row={details}
                                onSelect={this.selectPremium}
                                opened={this.state.userId === details.userId}
                                onSetPayments={(data) => {
                                    store.setPayments(details.userId, data);
                                    onTouch();
                                }}
                            />
                        )}
                        {!canSetPayments && <Money amount={details.paidSum} />}
                    </div>
                    <div className={styles.sum}>
                        <Money amount={details.remainsSum} />
                    </div>
                    <div className={styles.sum}> {premium.fired && <PlanrTag type="danger">Увольнение</PlanrTag>}</div>
                </div>
            </div>
        );
    }

    selectPremium = (userId: string | null) => this.setState({ userId });
}

export const EngineerPremium = observer(Premium);

interface EngineerPremiumState {
    userId: string | null;
}

interface EngineerPremiumProps {
    store: OrderPremiumDetailsType;
    canAssing: boolean;
    onTouch: () => void;
    readOnly: boolean | undefined;
}
