import { phoneValidator } from "modules/common/services/form/values";
import * as yup from "yup";
import { ApplicationSettingsSnapshotType, fields } from "./models/settings";

const { object, number, string, boolean } = yup;

export const settingsLabels = () => ({
  [fields.productionBudget]: "Бюджет для произв сотрудников",
  [fields.productionQuality]: "Качество для произв сотрудников",
  [fields.engineerBudget]: "Бюджет для ГИПа",
  [fields.engineerQuality]: "Качество для ГИПа",
  [fields.engineerTime]: "Сроки для ГИПа",
  [fields.companyName]: "Организация (полное наименование)",
  [fields.shortCompanyName]: "Краткое наименование",
  [fields.address]: "Юр. адрес",
  [fields.phoneNumber]: "Телефон",
  [fields.email]: "E-mail",
  [fields.sendEmail]: "E-mail для отправки писем",
  [fields.emailPassword]: "Пароль для отправки электронных писем",
  [fields.emailServer]: "Адрес stmp сервера для отправки электронных писем",
  [fields.emailPort]: "Порт для отправки электронных писем",
  [fields.emailReadServer]: "Адрес imap сервера для чтения электронных писем",
  [fields.emailReadPort]: "Порт для чтения электронных писем",
  [fields.isSsl]: "Защита соединения SSL",
  [fields.okved]: "ОКВЭД",
  [fields.directorName]: "Гендиректор ФИО",
  [fields.directorNameGenitive]: "Гендиректор ФИО (Родит. падеж)",
  [fields.administratorPositions]: "ID должности сис.админа",
  [fields.showMinutes]: "Отображать минуты в табеле",
  [fields.prevDaysCount]: "Кол-во предыдущих дней для корректировки в табеле",
  [fields.futureDaysCount]: "Кол-во будущих дней для корректировки в табеле",
  [fields.roundStep]: "Шаг округления",
});

const asNumber = (value: any) => (isNaN(value) ? undefined : value);
export const SettingsSchema = (isTestDialog: boolean) => {
  const labels = settingsLabels();
  const shape: TStringMap<any> = {};

  shape[fields.productionBudget] = number()
    .label(labels[fields.productionBudget])
    .transform(asNumber)
    .required()
    // eslint-disable-next-line no-template-curly-in-string
    .typeError("${path} должен быть числом")
    .min(0)
    .test("max", "Сумма показатей должна составлять 100%", function (value: number) {
      const { productionQuality } = this.parent as ApplicationSettingsSnapshotType;
      return value + productionQuality === 100;
    });

  shape[fields.showMinutes] = boolean().label(labels[fields.showMinutes]).notRequired();

  shape[fields.prevDaysCount] = number().transform(asNumber).label(labels[fields.prevDaysCount]).notRequired();
  shape[fields.futureDaysCount] = number().transform(asNumber).label(labels[fields.futureDaysCount]).notRequired();
  shape[fields.roundStep] = number().transform(asNumber).label(labels[fields.roundStep]).notRequired().min(0).max(3);

  shape[fields.isSsl] = boolean().label(labels[fields.isSsl]).notRequired();

  shape[fields.productionBudgetDelta] = number()
    .label("Допуск")
    .transform(asNumber)
    .required()
    // eslint-disable-next-line no-template-curly-in-string
    .typeError("${path} должен быть числом")
    .min(0)
    .max(100);

  shape[fields.productionQuality] = number()
    .label(labels[fields.productionQuality])
    .transform(asNumber)
    .required()
    // eslint-disable-next-line no-template-curly-in-string
    .typeError("${path} должен быть числом")
    .min(0)
    .test("max", "Сумма показатей должна составлять 100%", function (value: number) {
      const { productionBudget } = this.parent as ApplicationSettingsSnapshotType;
      return value + productionBudget === 100;
    });

  shape[fields.engineerBudget] = number()
    .label(labels[fields.engineerBudget])
    .transform(asNumber)
    .required()
    // eslint-disable-next-line no-template-curly-in-string
    .typeError("${path} должен быть числом")
    .min(0)
    .test("max", "Сумма показатей должна составлять 100%", function (value: number) {
      const { engineerQuality, engineerTime } = this.parent as ApplicationSettingsSnapshotType;
      return value + engineerQuality + engineerTime === 100;
    });

  shape[fields.engineerBudgetDelta] = number()
    .label("Допуск")
    .transform(asNumber)
    .required()
    // eslint-disable-next-line no-template-curly-in-string
    .typeError("${path} должен быть числом")
    .min(0)
    .max(100);

  shape[fields.engineerQuality] = number()
    .label(labels[fields.engineerQuality])
    .transform(asNumber)
    .required()
    // eslint-disable-next-line no-template-curly-in-string
    .typeError("${path} должен быть числом")
    .min(0)
    .test("max", "Сумма показатей должна составлять 100%", function (value: number) {
      const { engineerBudget, engineerTime } = this.parent as ApplicationSettingsSnapshotType;
      return value + engineerBudget + engineerTime === 100;
    });

  shape[fields.engineerQualityDelta] = number()
    .label("Допуск")
    .transform(asNumber)
    .required()
    // eslint-disable-next-line no-template-curly-in-string
    .typeError("${path} должен быть числом")
    .min(0)
    .max(100);

  shape[fields.engineerTime] = number()
    .label(labels[fields.engineerTime])
    .transform(asNumber)
    .required()
    // eslint-disable-next-line no-template-curly-in-string
    .typeError("${path} должен быть числом")
    .min(0)
    .test("max", "Сумма показатей должна составлять 100%", function (value: number) {
      const { engineerBudget, engineerQuality } = this.parent as ApplicationSettingsSnapshotType;
      return value + engineerBudget + engineerQuality === 100;
    });

  shape[fields.engineerTimeDelta] = number()
    .label("Допуск")
    .transform(asNumber)
    .required()
    // eslint-disable-next-line no-template-curly-in-string
    .typeError("${path} должен быть числом")
    .min(0);

  shape[fields.companyName] = string().label(labels[fields.companyName]).required();
  shape[fields.shortCompanyName] = string().label(labels[fields.shortCompanyName]).required();
  shape[fields.address] = string().label(labels[fields.address]).required();
  shape[fields.phoneNumber] = phoneValidator(
    labels[fields.phoneNumber],
    string()
      .test("needPhone", "Необходимо указать телефон", function (value) {
        const agent: ApplicationSettingsSnapshotType = this.parent;

        return !!value || !!agent.email;
      })
      .required()
  );

  shape[fields.email] = string()
    .label(labels[fields.email])
    .test("needEmail", "Необходимо указать email", function (value) {
      const agent: ApplicationSettingsSnapshotType = this.parent;

      return !!value || !!agent.phoneNumber;
    })
    .email();

  shape[fields.sendEmail] = string()
    .label(labels[fields.sendEmail])
    .test("needEmail", "Необходимо указать email", function (value) {
      const agent: ApplicationSettingsSnapshotType = this.parent;

      return !!value || !!agent.phoneNumber;
    })
    .email();
  shape[fields.emailPassword] = string().label(labels[fields.emailPassword]).notRequired();
  shape[fields.emailServer] = string().label(labels[fields.emailServer]).notRequired();
  shape[fields.emailPort] = number()
    .label(labels[fields.emailPort])
    .transform(asNumber)
    .notRequired()
    // eslint-disable-next-line no-template-curly-in-string
    .typeError("${path} должен быть числом")
    .min(0);
  shape[fields.emailReadServer] = string().label(labels[fields.emailReadServer]).notRequired();
  shape[fields.emailReadPort] = number()
    .label(labels[fields.emailReadPort])
    .transform(asNumber)
    .notRequired()
    // eslint-disable-next-line no-template-curly-in-string
    .typeError("${path} должен быть числом")
    .min(0);
  shape[fields.okved] = string().label(labels[fields.okved]).required();
  shape[fields.directorName] = string().label(labels[fields.directorName]).required();
  shape[fields.directorNameGenitive] = string().label(labels[fields.directorNameGenitive]).required();

  shape[fields.administratorPositions] = string().label(labels[fields.administratorPositions]).notRequired();

  return object().shape(shape);
};
