import React from "react";
import styles from "./IpdRow.module.scss";
import { SaveOrderIpd, ipdFields } from "modules/orders-manage/models/order-ipd";
import classnames from "classnames";
import { InputGroup, TextArea, Classes, Checkbox, Popover } from "@blueprintjs/core";
import { preventSubmitKeyDown } from "modules/common/services/form/values";
import { texts } from "modules/common/texts";
import { FileLink } from "modules/common/components/files/FileLink";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";

import { DraggableProvided } from "react-beautiful-dnd";
import { getDragItemStyle } from "modules/common/services/drag";
import { IpdFileGuidSnapshotType, IpdFileSnapshotType } from "modules/orders-manage/models/order-file";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { eat } from "modules/common/services/typescript";
import { StageItemType } from "modules/orders-manage/models/order-stages-store";
import {
  DefaultSelectedOption,
  filterItemPredicate,
  renderSingleOption,
  SimpleSelect,
} from "modules/common/services/form/select";

export class IpdRow extends React.Component<IpdRowProps> {
  render() {
    const { ipd, readOnly, active, onChange, baseUrl, onRemove, isDragging, draggable, canClientEdit, stages } =
      this.props;

    const rowOnEdit = active && active.guid === ipd.guid;
    const requestFile = rowOnEdit && active ? active.requestFile : ipd.requestFile;
    const documentFile = rowOnEdit && active ? active.documentFile : ipd.documentFile;

    const canUserEdit = !readOnly && rowOnEdit && active ? true : false;
    const items = stages && stages.length > 0 ? stages.map((s) => ({ id: s.guid, label: s.name })) : [];
    const canSupplierEdit =
      canClientEdit && rowOnEdit && active && (ipd.clientIsMentor || active.clientIsMentor) ? true : false;
    const ipdStageName = items.find((i) => i.id === ipd.stageGuid);
    const selectedStage = rowOnEdit && active ? items.find((i) => i.id === active.stageGuid) : ipdStageName;

    return (
      <div
        ref={draggable.innerRef}
        {...draggable.draggableProps}
        {...draggable.dragHandleProps}
        style={getDragItemStyle(isDragging, draggable.draggableProps.style)}
        className={classnames({
          [styles.row]: true,
          [styles.rowEdit]: rowOnEdit && active,
          // [styles.highlighted]: !!ipd.documentFileId,
          // [styles.inquiried]: !!ipd.requestFileId && !ipd.documentFileId,
        })}
      >
        {!rowOnEdit && (!readOnly || canClientEdit) && (
          <div className={styles.iconCell}>
            <PlanrButton
              type="neutral"
              icon="general-edit"
              onClick={this.activate}
              size="small"
              style={{ color: "#EA561E" }}
            />
          </div>
        )}
        {rowOnEdit && active && (
          <div className={styles.iconCell}>
            <PlanrButton
              type="neutral"
              icon="general-redo"
              onClick={this.onSave}
              size="small"
              style={{ color: "#1DD278" }}
            />
            {/* <SaveIcon className="action-icon" onClick={this.onSave} /> */}
          </div>
        )}
        <div className={styles.name}>
          {rowOnEdit && active && !canClientEdit && (
            <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
              <TextArea
                small={true}
                growVertically={true}
                rows={1}
                autoComplete="off"
                data-lpignore="true"
                value={active.name}
                className={`${Classes.FILL} planr-default-input`}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  onChange([
                    {
                      field: ipdFields.name,
                      value: e.currentTarget.value,
                    },
                  ]);
                }}
              />
            </div>
          )}
          {!rowOnEdit && ipd.name}
          {rowOnEdit && canClientEdit && active && ipd.name}
        </div>
        <div className={styles.number}>
          {rowOnEdit && active && !canClientEdit && (
            <InputGroup
              type="text"
              className="planr-default-input"
              autoComplete="off"
              data-lpignore="true"
              value={active.number}
              // className={Classes.FILL}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange([
                  {
                    field: ipdFields.number,
                    value: e.currentTarget.value,
                  },
                ]);
              }}
              onKeyDown={preventSubmitKeyDown}
            />
          )}
          {!rowOnEdit && ipd.number}
          {rowOnEdit && canClientEdit && active && ipd.number}
        </div>
        <div className={styles.mentor}>
          <Checkbox
            large={true}
            checked={rowOnEdit && active ? active.clientIsMentor : ipd.clientIsMentor}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              onChange([
                {
                  field: ipdFields.clientIsMentor,
                  value: e.currentTarget.checked,
                },
              ]);
            }}
            disabled={!rowOnEdit || canClientEdit}
          />
        </div>
        <div className={styles.stage}>
          {rowOnEdit && active && !canClientEdit && (
            <div style={{ width: "100%", marginTop: "-8px" }}>
              <SimpleSelect
                className={`full-width-select ${Classes.FILL}`}
                activeItem={selectedStage}
                items={items}
                itemRenderer={renderSingleOption}
                onItemSelect={(i: SelectItem) => {
                  onChange([
                    {
                      field: ipdFields.stageGuid,
                      value: i.id,
                    },
                  ]);
                }}
                filterable={true}
                itemPredicate={filterItemPredicate}
              >
                <DefaultSelectedOption option={selectedStage} />
              </SimpleSelect>
            </div>
          )}
          {!rowOnEdit && ipdStageName && <span>{ipdStageName.label}</span>}
          {rowOnEdit && canClientEdit && active && ipdStageName && <span>{ipdStageName.label}</span>}
        </div>
        <div className={styles.description}>
          {rowOnEdit && active && !canClientEdit && (
            <div className={`${Classes.INPUT_GROUP}  planr-default-input`}>
              <TextArea
                small={true}
                growVertically={true}
                rows={1}
                autoComplete="off"
                data-lpignore="true"
                value={active.description}
                className={`${Classes.FILL} planr-default-input`}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  onChange([
                    {
                      field: ipdFields.description,
                      value: e.currentTarget.value,
                    },
                  ]);
                }}
              />
            </div>
          )}
          {!rowOnEdit && ipd.description}
          {rowOnEdit && canClientEdit && active && ipd.description}
        </div>
        <div className={styles.file}>
          {rowOnEdit && active && !canClientEdit && (
            <PlanRDndUploader onFileSelected={this.uploadRequestFile} accept="*" oneRow={true} width={150} />
          )}

          {requestFile && requestFile.length > 0 && (
            <FileLink<IpdFileSnapshotType>
              baseUrl={baseUrl}
              file={requestFile[requestFile.length - 1]}
              onRemove={rowOnEdit ? this.removeRequestFile : eat}
              readOnly={readOnly || !rowOnEdit}
              style={{ width: "150px", maxWidth: "150px", minWidth: "150px", marginTop: "5px" }}
            />
          )}
        </div>
        <div className={styles.version}>
          {requestFile && requestFile.length > 1 && (
            <Popover position="auto" enforceFocus={false} isOpen={undefined}>
              <span className="bp3-tooltip-indicator">{`v_${requestFile.length}`}</span>
              <FilesList
                fileType="request"
                files={requestFile}
                baseUrl={baseUrl}
                rowOnEdit={rowOnEdit}
                readOnly={readOnly}
                onChange={onChange}
                ipd={ipd}
              />
            </Popover>
          )}
        </div>
        <div className={styles.file}>
          {rowOnEdit && active && (
            <PlanRDndUploader
              onFileSelected={async (file) => {
                await this.uploadDocumentFile(file);
              }}
              accept="*"
              oneRow={true}
              width={150}
            />
          )}

          {documentFile && documentFile.length > 0 && (
            <FileLink<IpdFileSnapshotType>
              baseUrl={baseUrl}
              file={documentFile[documentFile.length - 1]}
              onRemove={rowOnEdit ? this.removeDocumentFile : eat}
              readOnly={!canUserEdit && !canSupplierEdit}
              style={{ width: "150px", maxWidth: "150px", minWidth: "150px", marginTop: "5px" }}
            />
          )}
        </div>
        <div className={styles.version}>
          {documentFile && documentFile.length > 1 && (
            <Popover position="auto" enforceFocus={false} isOpen={undefined}>
              <span className="bp3-tooltip-indicator">{`v_${documentFile.length}`}</span>
              <FilesList
                fileType="document"
                files={documentFile}
                baseUrl={baseUrl}
                rowOnEdit={rowOnEdit}
                readOnly={readOnly}
                onChange={onChange}
                ipd={ipd}
              />
            </Popover>
          )}{" "}
        </div>
        {!active && (
          <div className={styles.status}>
            <span
              className={
                ipd.status === "Загружено"
                  ? styles.statusLoad
                  : ipd.status === "Запрошено"
                  ? styles.statusInquired
                  : styles.statusNone
              }
            >
              {ipd.status ? ipd.status : "Нет"}
            </span>
          </div>
        )}
        {!readOnly && !active && (
          <div className={styles.iconCell}>
            <RemoveConfirmation
              onConfirmed={onRemove}
              what={(w) => w.name}
              render={({ confirmRemoving }) => {
                return (
                  <PlanrButton
                    type="neutral"
                    icon="general-trash"
                    onClick={() => confirmRemoving(ipd)}
                    size="small"
                    title={texts.remove}
                  />
                );
              }}
            />
          </div>
        )}
        {rowOnEdit && active && (
          <div className={styles.iconCell}>
            <PlanrButton type="neutral" icon="general-undo" onClick={this.deactivate} size="small" />
          </div>
        )}
      </div>
    );
  }

  activate = () => this.props.onActivate(this.props.ipd);

  onSave = () => {
    const { active } = this.props;

    if (active) {
      this.props.onSave(active);
    }
  };

  removeRequestFile = (file: any) => {
    const { active } = this.props;
    let files = null;

    if (active && active.requestFile) {
      let newFiles = [...active.requestFile];
      const index = newFiles.findIndex((f) => f.fileId === file.fileId);
      if (index > -1) {
        newFiles.splice(index, 1);
      }
      if (newFiles) {
        files = newFiles;
      }
    }
    this.props.onChange([
      {
        field: ipdFields.requestFile,
        value: files,
      },
    ]);
  };

  removeDocumentFile = (file: any) => {
    const { active, ipd } = this.props;
    let files = null;

    if (active && active.documentFile) {
      let newFiles = [...active.documentFile];
      const index = newFiles.findIndex((f) => f.fileId === file.fileId);
      if (index > -1) {
        newFiles.splice(index, 1);
      }
      if (newFiles) {
        files = newFiles;
      }
    }
    this.props.onChange([
      {
        field: ipdFields.documentFile,
        value: files,
      },
      {
        field: ipdFields.clientIsMentor,
        value: ipd.clientIsMentor,
      },
    ]);
  };

  uploadRequestFile = async (file: File) => {
    const meta = await this.props.onUpload(file);
    let files: IpdFileGuidSnapshotType[] = [];
    if (meta) {
      if (this.props.active && this.props.active.requestFile) {
        const ipdGuid = this.props.active.guid;
        const newFile = { ...meta, ipdGuid };
        files = [...this.props.active.requestFile, newFile];
      }
      this.props.onChange([
        {
          field: ipdFields.requestFile,
          value: files,
        },
      ]);
    }
  };

  uploadDocumentFile = async (file: File) => {
    const meta = await this.props.onUpload(file);
    let files: IpdFileGuidSnapshotType[] = [];
    if (meta) {
      if (this.props.active && this.props.active.documentFile) {
        const ipdGuid = this.props.active.guid;
        const newFile = { ...meta, ipdGuid };
        files = [...this.props.active.documentFile, newFile];
      }
      this.props.onChange([
        {
          field: ipdFields.documentFile,
          value: files,
        },
      ]);
    }
  };

  deactivate = () => this.props.onActivate(null);
}

interface IpdRowProps {
  ipd: SaveOrderIpd;
  onChange: (changes: Array<{ field: string; value: any }>) => void;
  onSave: (ipd: SaveOrderIpd) => void;
  onRemove: (ipd: SaveOrderIpd) => void;
  readOnly: boolean;
  active: SaveOrderIpd | null;
  onActivate: (ipd: SaveOrderIpd | null) => void;
  onUpload: (file: File) => Promise<IpdFileSnapshotType | null>;
  baseUrl: string;
  isDragging: boolean;
  draggable: DraggableProvided;
  canClientEdit?: boolean;
  stages: StageItemType[];
}

interface FilesListProps {
  fileType: string;
  files: IpdFileSnapshotType[];
  baseUrl: string;
  rowOnEdit: boolean | null;
  readOnly: boolean;
  ipd: SaveOrderIpd;
  onChange: (changes: Array<{ field: string; value: any }>) => void;
}
const FilesList = ({ files, baseUrl, rowOnEdit, readOnly, onChange, ipd, fileType }: FilesListProps) => {
  let newArrFiles = [...files];
  const removeFile = (file: any) => {
    let newFiles = null;
    if (newArrFiles) {
      const index = newArrFiles.findIndex((f) => f.fileId === file.fileId);
      if (index > -1) {
        newArrFiles.splice(index, 1);
      }
      if (newArrFiles.length > 0) {
        newFiles = newArrFiles;
      }
    }
    if (fileType === "document")
      onChange([
        {
          field: ipdFields.documentFile,
          value: newFiles,
        },

        {
          field: ipdFields.clientIsMentor,
          value: ipd.clientIsMentor,
        },
      ]);
    if (fileType === "request")
      onChange([
        {
          field: ipdFields.requestFile,
          value: newFiles,
        },
      ]);
  };
  return (
    <div className={styles.filesVersion}>
      {newArrFiles &&
        newArrFiles.map((file, i) => (
          <div className={styles.rowVersion} key={i}>
            <div className={styles.file}>
              <FileLink<IpdFileSnapshotType>
                baseUrl={baseUrl}
                file={file}
                onRemove={rowOnEdit ? removeFile : eat}
                readOnly={readOnly || !rowOnEdit}
                style={{ width: "200px", marginTop: "5px" }}
                textWidth={"100px"}
              />
            </div>
            <div className={styles.version}>{`v_${i + 1}`}</div>
          </div>
        ))}
    </div>
  );
};
