import styles from "./ScheduleUserRow.module.scss";
import React from "react";
import { observer } from "mobx-react";
import { ScheduleRowType, ScheduleUnitType, fields, ScheduleUnitSnapshotType } from "../models/schedule-unit";
import { EmployerName } from "modules/common/components/employer/EmployerName";
import { Money } from "modules/common/components/money/Money";
import { Form, Formik, Field, FieldProps, FormikProps } from "formik";
import ScheduleSchema from "./validation";
import { Classes, Tooltip, Popover } from "@blueprintjs/core";
import { Hotkey, Hotkeys, HotkeysTarget } from "@blueprintjs/core/lib/esnext";
import { MODAL_AWAIT_DELAY } from "modules/common/services/form/textarea";
import { EmployerDictionaryItemType } from "modules/spending/employee/models/employee-dictionary";
import { BonusCorrection } from "./BonusCorrection";
import { VacationDetails } from "./VacationDetails";
import { MoneyInput } from "modules/common/components/money/MoneyInput";
import { InvisibleSubmit } from "modules/common/components/form";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { EmployerSelect } from "modules/common/components/form/EmployerSelect";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { texts } from "modules/common/texts";

const schema = ScheduleSchema();

@HotkeysTarget
class Row extends React.Component<ScheduleUserRowProps, ScheduleUserRowState> {
  formProps: FormikProps<any> | null = null;
  div = React.createRef<HTMLDivElement>();
  submit = React.createRef<HTMLButtonElement>();

  constructor(props: ScheduleUserRowProps) {
    super(props);
    this.state = {
      open: false,
    };
  }
  componentDidMount() {
    this.focusInput();
  }

  componentDidUpdate(prevProps: ScheduleUserRowProps) {
    if (!prevProps.editMode && this.props.editMode) {
      this.focusInput();
    }
  }

  render() {
    const { schedule, editMode, onSave, employee, onSaved, bonusTaxeHidden, readonly } = this.props;
    const user = schedule.user;
    const correctionTooltipVisible = schedule.plan.bonusCorrection !== 0 && !editMode;
    const savedUser = user ? { id: user.id, label: user.name } : null;

    return (
      <div
        ref={this.div}
        className={`schedule-user-row ${styles.row} ${this.isHighlighted() ? styles.highlighted : ""} ${
          editMode ? styles.edited : ""
        }`}
        onDoubleClick={this.onDoubleClick}
        onClick={this.onClick}
        onContextMenu={this.onContextMenu}
      >
        <Formik
          initialValues={getFormValues(schedule.plan)}
          enableReinitialize={true}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting }) => {
            const succsessfull = await onSave(schedule.plan.id, values);
            if (succsessfull) {
              onSaved();
            } else {
              setSubmitting(false);
            }
          }}
        >
          {(formProps) => {
            this.formProps = formProps;
            return (
              <Form>
                <div className={`schedule-user-name ${styles.cell}`}>
                  {user && !editMode ? <EmployerName name={user.name} position={user.employerPosition.name} /> : null}

                  {editMode && (
                    <>
                      <div className={`${styles.half} ${styles.actual}`}>&nbsp;</div>
                      <div className={styles.half}>
                        <Field name={fields.employerId}>
                          {(fieldProps: FieldProps) => {
                            return (
                              <EmployerSelect
                                employee={employee}
                                field={fieldProps}
                                saved={savedUser}
                                matchTargetWidth={false}
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </>
                  )}

                  {editMode && (
                    <div className="buttons">
                      <InvisibleSubmit innerRef={this.submit} />

                      <GeneralIcon
                        title={texts.save}
                        type="general-redo"
                        onClick={this.clickSubmit}
                        style={{
                          cursor: "pointer",
                          marginTop: "5px",
                          marginRight: "16px",
                          color: "#1DD278",
                        }}
                      />
                      <GeneralIcon
                        type="general-edit"
                        onClick={() => {
                          this.setState({ open: true });
                        }}
                        title="Корр. премии"
                        style={{
                          cursor: "pointer",
                          marginTop: "5px",
                          marginRight: "16px",
                          color: "#4D9AD3",
                        }}
                      />
                      <RemoveConfirmation onConfirmed={this.onRemove} what={this.removeLabel}>
                        {({ confirmRemoving }) => (
                          <GeneralIcon
                            title={texts.remove}
                            type="general-trash"
                            onClick={confirmRemoving}
                            style={{
                              cursor: "pointer",
                              marginTop: "5px",
                              color: "#E31818",
                            }}
                          />
                        )}
                      </RemoveConfirmation>
                    </div>
                  )}
                </div>
                <div className={`schedule-cell ${styles.cell}`}>
                  <div className={`${styles.half} ${styles.actual}`}>
                    <Money amount={schedule.actual.finalSalary} />
                  </div>
                  <div className={styles.half}>
                    <Money amount={schedule.plan.finalSalary} />
                  </div>
                </div>
                <div className={`schedule-cell ${styles.cell}`}>
                  <div className={`${styles.half} ${styles.actual}`}>
                    <Money amount={schedule.actual.totalSalary} />
                  </div>
                  <div className={styles.half}>
                    {!editMode && <Money amount={schedule.plan.totalSalary} />}
                    {editMode && (
                      <Field name={fields.salary}>
                        {({ field, form }: FieldProps) => {
                          return (
                            <MoneyInput
                              className="in-cell planr-tiny-input"
                              id={field.name}
                              small={true}
                              autoComplete="off"
                              data-lpignore="true"
                              value={field.value}
                              onMoneyChange={(money) => {
                                form.setFieldValue(field.name, money.value);
                                form.setFieldTouched(field.name);
                              }}
                            />
                          );
                        }}
                      </Field>
                    )}
                  </div>
                </div>
                <div className={`schedule-cell ${styles.cell}`}>
                  <div className={`${styles.half} ${styles.actual}`}>
                    {correctionTooltipVisible && (
                      <Tooltip
                        className={Classes.TOOLTIP_INDICATOR}
                        content={
                          <span>
                            {schedule.plan.bonusCorrectionComment || "Корректировка"}:
                            <br />
                            <Money amount={schedule.plan.bonusCorrection} />
                          </span>
                        }
                      >
                        <Money amount={schedule.actual.finalBonus} />
                      </Tooltip>
                    )}
                    {!correctionTooltipVisible && <Money amount={schedule.actual.finalBonus} />}
                  </div>
                  <div className={styles.half}>
                    {!editMode && <Money amount={schedule.plan.bonus} />}
                    {editMode && (
                      <div className={styles.bonus}>
                        <Field name={fields.bonus}>
                          {({ field, form }: FieldProps) => {
                            return (
                              <MoneyInput
                                className="in-cell planr-tiny-input"
                                id={field.name}
                                small={true}
                                autoComplete="off"
                                data-lpignore="true"
                                value={field.value}
                                onMoneyChange={(money) => {
                                  form.setFieldValue(field.name, money.value);
                                  form.setFieldTouched(field.name);
                                }}
                              />
                            );
                          }}
                        </Field>

                        <BonusCorrection
                          open={this.state.open}
                          toggleOpen={() => {
                            this.setState({ open: !this.state.open });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={`schedule-cell ${styles.cell}`}>
                  <div className={`${styles.half} ${styles.actual}`}>
                    <Money amount={schedule.actual.increase} />
                  </div>
                  <div className={styles.half}>
                    {!editMode && <Money amount={schedule.plan.increase} />}
                    {editMode && (
                      <Field name={fields.increase}>
                        {({ field, form }: FieldProps) => {
                          return (
                            <MoneyInput
                              className="in-cell planr-tiny-input"
                              id={field.name}
                              small={true}
                              autoComplete="off"
                              data-lpignore="true"
                              value={field.value}
                              onMoneyChange={(money) => {
                                form.setFieldValue(field.name, money.value);
                                form.setFieldTouched(field.name);
                              }}
                            />
                          );
                        }}
                      </Field>
                    )}
                  </div>
                </div>
                <div className={`schedule-cell ${styles.cell}`}>
                  <div className={`${styles.half} ${styles.actual}`}>
                    <Money amount={schedule.actual.bonusWorkedOutGrade} />
                  </div>

                  <div className={styles.half}>
                    {schedule.plan.bonusWorkedOutGrade === 0 ? (
                      <Money amount={schedule.plan.bonusWorkedOutGrade} />
                    ) : (
                      <Popover
                        usePortal={true}
                        position="left"
                        enforceFocus={false}
                        isOpen={undefined}
                        target={<Money amount={schedule.plan.bonusWorkedOutGrade} className="bp3-tooltip-indicator" />}
                        content={
                          <div className={styles.bonusWorked}>
                            <div className={styles.bonusWorkedRowHeader}>
                              <div className={styles.bonusWorkedCell}>Доплата за стаж</div>
                              <div className={styles.bonusWorkedCell}>Доплата за ранг</div>
                            </div>
                            <div className={styles.bonusWorkedRow}>
                              <div className={styles.bonusWorkedCellPercent}>
                                <div>{`(${schedule.plan.bonusWorkedOutPercent}%) `}</div>
                              </div>
                              <div className={styles.bonusWorkedCellPercent}>
                                <div>{`(${schedule.plan.bonusGradePercent}%) `}</div>
                              </div>
                            </div>
                            <div className={styles.bonusWorkedRow}>
                              <div className={styles.bonusWorkedCell}>
                                <Money amount={schedule.plan.bonusWorkedOut} />
                              </div>
                              <div className={styles.bonusWorkedCell}>
                                <Money amount={schedule.plan.bonusGrade} />
                              </div>
                            </div>
                          </div>
                        }
                      />
                    )}
                  </div>
                </div>
                <div className={`schedule-cell ${styles.cell}`}>
                  <div className={`${styles.half} ${styles.actual}`}>
                    <Money amount={schedule.actual.overtime} />
                  </div>
                  <div className={styles.half}>
                    <Money amount={schedule.plan.overtime} />
                  </div>
                </div>
                <div className={`schedule-cell vacation ${styles.cell}`}>
                  {schedule.actual.vacationSalary > 0 && (
                    <Popover
                      usePortal={true}
                      position="left"
                      enforceFocus={false}
                      isOpen={undefined}
                      target={
                        <div className={`${styles.actual}`}>
                          <Money amount={schedule.actual.vacationSalary} className="bp3-tooltip-indicator" />
                        </div>
                      }
                      content={
                        <div className={styles.vacations}>
                          {schedule.actual.sortedVacations.map((vacation, index) => (
                            <VacationDetails
                              key={index}
                              vacation={vacation}
                              type="salary"
                              addTransfer={schedule.transfer}
                              removeTransfer={schedule.removeTransfer}
                              readonly={editMode || readonly}
                            />
                          ))}
                        </div>
                      }
                    />
                  )}
                  {schedule.actual.vacationSalary === 0 && (
                    <div className={styles.actual}>
                      <Money amount={schedule.actual.vacationSalary} />
                    </div>
                  )}
                </div>
                <div className={`schedule-cell vacation ${styles.cell}`}>
                  {schedule.actual.vacationBonus > 0 && (
                    <Popover
                      usePortal={true}
                      position="left"
                      enforceFocus={false}
                      isOpen={undefined}
                      target={
                        <div className={`${styles.actual}`}>
                          <Money amount={schedule.actual.vacationBonus} className="bp3-tooltip-indicator" />
                        </div>
                      }
                      content={
                        <div className={styles.vacations}>
                          {schedule.actual.sortedVacations.map((vacation, index) => (
                            <VacationDetails
                              key={index}
                              vacation={vacation}
                              type="bonus"
                              addTransfer={schedule.transfer}
                              removeTransfer={schedule.removeTransfer}
                              readonly={editMode || readonly}
                            />
                          ))}
                        </div>
                      }
                    />
                  )}
                  {schedule.actual.vacationBonus === 0 && (
                    <div className={styles.actual}>
                      <Money amount={schedule.actual.vacationBonus} />
                    </div>
                  )}
                </div>
                <div className={`schedule-cell decrease ${styles.cell}`}>
                  {!editMode && <Money amount={schedule.plan.decrease} />}
                  {editMode && (
                    <>
                      <div className={`${styles.half} ${styles.actual}`}>&nbsp;</div>
                      <div className={styles.half}>
                        <Field name={fields.decrease}>
                          {({ field, form }: FieldProps) => {
                            return (
                              <MoneyInput
                                className="in-cell planr-tiny-input"
                                id={field.name}
                                small={true}
                                autoComplete="off"
                                data-lpignore="true"
                                value={field.value}
                                onMoneyChange={(money) => {
                                  form.setFieldValue(field.name, money.value);
                                  form.setFieldTouched(field.name);
                                }}
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </>
                  )}
                </div>
                <div className={`schedule-cell ${styles.cell}`}>
                  <div className={`${styles.half} ${styles.actual}`}>
                    <Money amount={schedule.actual.ndflTaxe} />
                  </div>
                  <div className={styles.half}>
                    <Money amount={schedule.plan.ndflTaxe} />
                  </div>
                </div>
                <div className={`schedule-cell ${styles.cell}`}>
                  <div className={`${styles.half} ${styles.actual}`}>
                    <Money amount={schedule.actual.socialTaxe} />
                  </div>
                  <div className={styles.half}>
                    <Money amount={schedule.plan.socialTaxe} />
                  </div>
                </div>
                {!bonusTaxeHidden && (
                  <div className={`schedule-cell ${styles.cell}`}>
                    <div className={`${styles.half} ${styles.actual}`}>
                      <Money amount={schedule.actual.bonusTaxe} />
                    </div>
                    <div className={styles.half}>
                      <Money amount={schedule.plan.bonusTaxe} />
                    </div>
                  </div>
                )}
                <div className={`schedule-cell ${styles.cell}`}>
                  <div className={`${styles.half} ${styles.actual}`}>
                    <Money amount={schedule.actual.profit} />
                  </div>
                  <div className={styles.half}>
                    <Money amount={schedule.plan.profit} />
                  </div>
                </div>
                <div className={`schedule-cell ${styles.cell}`}>
                  <div className={`${styles.half} ${styles.actual}`}>
                    <Money amount={schedule.actual.companySpending} />
                  </div>
                  <div className={styles.half}>
                    <Money amount={schedule.plan.companySpending} />
                  </div>
                </div>
                <div className={`schedule-cell per-hour ${styles.cell}`}>
                  <div>
                    <Money amount={schedule.plan.companySpendingPerHour} />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }

  clickSubmit = () => this.submit.current && this.submit.current.click();

  renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey global={true} allowInInput={true} label="cancel edit" combo="esc" onKeyUp={this.cancelEdit} />
      </Hotkeys>
    );
  }

  onDoubleClick = () => this.props.onDoubleClick(this.props.schedule.plan);

  onClick = () => setTimeout(() => this.props.onClick(this.props.schedule.plan), 20);

  onRemove = () => this.props.onRemove(this.props.schedule.plan);

  removeLabel = () => "расписание";

  isHighlighted = () => this.props.currentRows.includes(this.props.schedule.plan.id);

  focusInput = () => {
    setTimeout(() => {
      if (this.div.current) {
        const input = this.div.current.querySelector("input");
        input && input.focus();
      }
    }, MODAL_AWAIT_DELAY / 2);
  };

  cancelEdit = () => {
    const { editMode, onDoubleClick } = this.props;
    if (editMode) {
      this.formProps && this.formProps.resetForm();
      onDoubleClick(null);
    }
  };

  onContextMenu = (e: React.MouseEvent) => {
    this.props.onContextMenu(e, this.props.schedule.plan);
  };
}

export const ScheduleUserRow = observer(Row);

export interface UnitSelector {
  onDoubleClick: (unit: ScheduleUnitType | null) => void;
  onClick: (unit: ScheduleUnitType) => void;
  currentRows: string[];
}

export interface ScheduleManger {
  onSaved: () => void;
  onSave: (id: string, values: any) => Promise<boolean>;
  onRemove: (unit: ScheduleUnitType) => Promise<boolean>;
  employee: EmployerDictionaryItemType[];
}

interface ScheduleUserRowProps extends UnitSelector, ScheduleManger {
  bonusTaxeHidden?: boolean;
  schedule: ScheduleRowType;
  editMode?: boolean;
  onContextMenu: (e: React.MouseEvent, unit: ScheduleUnitType) => void;
  readonly: boolean;
}

interface ScheduleUserRowState {
  open: boolean;
}

export function getFormValues(store: ScheduleUnitSnapshotType) {
  return {
    [fields.salary]: store.salary || "",
    [fields.bonus]: store.bonus || "",
    [fields.bonusCorrection]: store.bonusCorrection || "",
    [fields.bonusCorrectionComment]: store.bonusCorrectionComment || "",
    [fields.increase]: store.increase || "",
    [fields.decrease]: store.decrease || "",
    [fields.vacationBonus]: store.vacationBonus || "",
    [fields.vacationSalary]: store.vacationSalary || "",
    [fields.employerId]: store.employer ? store.employer.id : "",
    [fields.bonusGradePercent]: store.bonusGradePercent || "",
    [fields.bonusWorkedOutPercent]: store.bonusWorkedOutPercent || "",
  };
}
