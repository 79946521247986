import { types } from "mobx-state-tree";
import { emptyOutsourcerPayment, OutsourcerPayment } from "modules/agents/outsourcers/models/outsourcer-payment";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { formatDate } from "modules/common/services/formatting/date";
import { CompletionFile } from "./order-file";

export const COMPLETION_ACCEPT = "*";

export const OrderCompletion = types
    .compose(
        Transport,
        Notificator,
        OutsourcerPayment,
        types.model({
            stageGuid: types.string,
            file: types.maybeNull(CompletionFile),
        })
    )
    .actions((self) => ({
        setSum(value: number) {
            self.sum = value;
        },

        setName(value: string) {
            self.name = value;
        },

        setDate(value: Date | null) {
            self.date = formatDate(value);
        },

        uploadFile: flow(function* (file: File) {
            try {
                const model = new FormData();

                model.append("file", file);
                model.append("accept", COMPLETION_ACCEPT);

                const result: UploadFileResult = yield self.transport.post<any>(
                    apiUrls.application.files.upload,
                    model
                );
                const { id, previewMimeType, mimeType } = result;

                self.file = CompletionFile.create({
                    fileId: id,
                    fileName: file.name,
                    previewMimeType,
                    mimeType,
                });

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        clearFile() {
            self.file = null;
        },
    }))
    .named("OrderCompletion");

export type OrderCompletionType = typeof OrderCompletion.Type;
export type OrderCompletionSnapshotType = typeof OrderCompletion.SnapshotType;

export const emptyCompletion = (guid: string, index: number): OrderCompletionSnapshotType => ({
    ...emptyOutsourcerPayment(guid, index),
    stageGuid: "",
    file: null,
});
