import React, { PropsWithChildren, useCallback } from "react";
import { Tab, Tabs } from "@blueprintjs/core";
import { routes } from "modules/common/routes";
import { menuItems } from "modules/root/components/menu/MainMenu";
import { RouteChildrenProps } from "react-router";
import { Page } from "modules/common/components/page/Page";
import styles from "./AgentsTabsPage.module.scss";

const menu = menuItems();

export const AgentsTabsPage = (props: PropsWithChildren<AgentsTabsPageProps & RouteChildrenProps>) => {
    const {
        location,
        history,
        outsourcersPanel,
        // legalEntitiesPanel,
        // physicalEntitiesPanel,
        // clientsPanel,
        // suppliersPanel,
    } = props;

    const tabId = getTabId(location.pathname);
    const handleTabChange = useCallback((newTabId: string) => history.push(newTabId), [history]);

    return (
        <Page>
            <Tabs
                className={`${styles.tabs} planr-tabs-navigation`}
                id="AgentsTabsPage"
                onChange={handleTabChange}
                selectedTabId={tabId}
                renderActiveTabPanelOnly={true}
            >
                {outsourcersPanel && (
                    <Tab
                        id={routes.agents.outsourcers.outsourcers}
                        title={menu.agents.label}
                        panel={outsourcersPanel}
                    />
                )}
                {/* {legalEntitiesPanel && (
                    <Tab
                        id={routes.agents.outsourcers.legal}
                        title={menu.agents.nested.legal.label}
                        panel={legalEntitiesPanel}
                    />
                )}
                */}
                {/* {clientsPanel && <Tab id={routes.agents.clients.path} title={menu.agents.label} panel={clientsPanel} />}
                {suppliersPanel && (
                    <Tab id={routes.agents.suppliers.path} title={menu.agents.label} panel={suppliersPanel} />
                )} */}
            </Tabs>
        </Page>
    );
};

const getTabId = (pathname: string) => {
    if (pathname.includes(routes.agents.outsourcers.outsourcers)) {
        return routes.agents.outsourcers.outsourcers;
    }

    // if (pathname.includes(routes.agents.clients.path)) {
    //     return routes.agents.clients.path;
    // }

    // if (pathname.includes(routes.agents.suppliers.path)) {
    //     return routes.agents.suppliers.path;
    // }

    return "";
};

interface AgentsTabsPageProps {
    outsourcersPanel: JSX.Element | null;
    // legalEntitiesPanel: JSX.Element | null;
    // physicalEntitiesPanel: JSX.Element | null;
    // clientsPanel: JSX.Element | null;
    // suppliersPanel: JSX.Element | null;
}
