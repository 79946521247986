import React from "react";
import styles from "./EmployeeTable.module.scss";
import { RoleAccessSnapshotType } from "../models/access-store";
import { Icon } from "@blueprintjs/core";
import { WHITE } from "modules/common/constants";
import { calculateWidth } from "./EmployeeTableHeader";
import { Constants } from "modules/root/models/constants";

const BLUE = "rgba(54, 172, 215, 0.1)";
const single = calculateWidth(1);

export const EmployeeTable = ({ data, selectedRow, onToggle, selectedColumn, canChange }: EmployeeTableProps) => {
    const authorization = Object.keys(Constants.authorization.toPOJO());
    return (
        <div className={styles.wrapper}>
            <table className={`${styles.table} ${styles.body}`}>
                <tbody>
                    {authorization.map((functionality, index) => {
                        const current = selectedRow === index ? styles.current : "";
                        const afterCurrent = selectedRow + 1 === index ? styles.afterCurrent : "";

                        return (
                            <tr className={`${current} ${afterCurrent}`} key={functionality}>
                                {data.map((employer, key) => {
                                    const { access, id } = employer;
                                    const can = access.find((a) => a.name === functionality);
                                    const backgroundColor = can ? BLUE : WHITE;
                                    const columnOpener = key === selectedColumn && key === 0 ? styles.columnOpener : "";
                                    const columnSelection =
                                        key === selectedColumn || key === selectedColumn - 1
                                            ? styles.columnSelection
                                            : "";

                                    return (
                                        <td
                                            key={`${functionality}_${key}`}
                                            onClick={canChange ? () => onToggle(id, functionality, !can) : () => {}}
                                            style={{ ...single, backgroundColor }}
                                            className={`${columnSelection} ${columnOpener}`}
                                        >
                                            {can && <Icon icon="tick" />}
                                            {!can && <span>&nbsp;</span>}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

interface EmployeeTableProps {
    data: RoleAccessSnapshotType[];
    selectedRow: number;
    onToggle: (employerId: string, functionality: string, state: boolean) => void;
    selectedColumn: number;
    canChange: boolean;
}
