import { applySnapshot, getSnapshot, types } from "mobx-state-tree";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Queryable } from "modules/common/models/queryable";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { nameof } from "modules/common/services/typescript";
import { TaskFile } from "../../orders-manage/models/order-file";
import { EmployerDictionaryLink } from "../../spending/employee/models/employee-dictionary";
import { texts } from "modules/common/texts";

export const TaskArrItem = types
    .model({
        id: types.string,
        endDate: types.string,
        date: types.string,
        time: types.string,
        name: types.string,
        description: types.string,
        author: types.string,
        implementer: types.maybeNull(EmployerDictionaryLink),
        contentGuid: types.string,
        accepted: types.boolean,
        completed: types.boolean,
        confirmed: types.boolean,
        canChangeAcceptance: types.boolean,
        canChangeCompletion: types.boolean,
        canChangeConfirmation: types.boolean,
        taskFile: types.array(TaskFile),
        orderId: types.string,
    })
    .named("TaskArrItem");

export type TaskArrItemType = typeof TaskArrItem.Type;

const OrderContentTaskListItem = types
    .model({
        unitName: types.string,
        unitDescription: types.string,
        inventoryNumber: types.string,
        orderName: types.string,
        unitGuid: types.string,
        orderId: types.string,
        orderStatus: types.string,
        orderStatusColor: types.string,
        mentorName: types.string,
        task: types.maybeNull(TaskArrItem),
        dateStart: types.string,
        type: types.string,
        guid: types.maybeNull(types.string),
    })

    .named("OrderContentTaskListItem");

export const OrderContentTaskStore = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            data: types.array(OrderContentTaskListItem),
            loading: types.boolean,
            // sorter: TableSorter,
        })
    )
    .views((self) => ({
        get mainData() {
            const predicate = (o: OrderContentTaskListItemType) => {
                let result = true;
                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.inventoryNumber} ${o.orderName} ${o.orderStatus} ${o.unitName}  ${o.mentorName} ${
                            o.task?.description
                        } ${o.task?.author} ${o.task?.implementer ? o.task?.implementer.name : null} ${
                            o.task?.accepted && !o.task?.completed ? "принял" : o.task?.completed ? "выполнил" : ""
                        } ${o.task?.date} ${o.task?.endDate}`
                            .toLowerCase()
                            .indexOf(self.pureQuery) >= 0;
                }

                return result;
            };
            return self.data.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                self.loading = true;
                const newData = yield self.transport.get<any>(apiUrls.orders.contentTasks.contentList);

                applySnapshot(self.data, newData);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.loading = false;
            }
        }),
        delete: flow(function* (id: string, guid?: string | null) {
            try {
                self.loading = true;
                var res = yield self.transport.post<boolean>(apiUrls.orders.summary.remove(id));
                if (res != null) {
                    if (res === true) {
                        self.notify.success(texts.messages.removed);
                        const index = self.data.findIndex((d) => d.guid === guid);
                        if (index > -1) {
                            self.data.splice(index, 1);
                        }
                    }
                }

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.loading = false;
            }
        }),

        cancel: flow(function* (id: string, guid: string | null) {
            try {
                self.loading = true;

                var res = yield self.transport.delete<any>(apiUrls.orders.summary.cancel(id));
                if (res) {
                    const index = self.data.findIndex((d) => d.guid === guid);
                    if (index > -1) {
                        self.data.splice(index, 1);
                    }
                }

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.loading = false;
            }
        }),
    }))
    .named("OrderContentTaskStore");

export type OrderContentTaskStoreType = typeof OrderContentTaskStore.Type;
export type OrderContentTaskStoreSnapshotType = typeof OrderContentTaskStore.SnapshotType;

export type OrderContentTaskListItemType = typeof OrderContentTaskListItem.Type;
export type OrderContentTaskListItemSnapshotType = typeof OrderContentTaskListItem.SnapshotType;

// const sortStorage = getSortOption(OrderContentTaskStore.name);

export const initialState = (): OrderContentTaskStoreSnapshotType => ({
    data: [],
    loading: false,
    pureQuery: "",
    query: "",
});

export const fields = {
    inventoryNumber: nameof((a: OrderContentTaskListItemType) => a.inventoryNumber) as string,
    orderName: nameof((a: OrderContentTaskListItemType) => a.orderName) as string,
    orderStatus: nameof((a: OrderContentTaskListItemType) => a.orderStatus) as string,
    unitName: nameof((a: OrderContentTaskListItemType) => a.unitName) as string,
    mentorName: nameof((a: OrderContentTaskListItemType) => a.mentorName) as string,
    task: nameof((a: OrderContentTaskListItemType) => a.task) as string,
    description: nameof((a: OrderContentTaskListItemType) => a.task?.description) as string,
};
