import * as yup from "yup";
import { fields } from "modules/dictionaries/order-statuses/models/order-status";
import { HTML_COLOR_REGEXP } from "modules/common/constants";
import { texts } from "modules/common/texts";
const { object, string } = yup;

export const orderStatusLabels = () => ({
    [fields.name]: "Статус",
    [fields.description]: texts.comment,
    [fields.color]: "Цветовая индикация",
    [fields.type]: "Системный тип",
});

const OrderstatusSchema = () => {
    const labels = orderStatusLabels();

    return object().shape({
        [fields.name]: string().label(labels[fields.name]).required(),

        [fields.type]: string().label(labels[fields.type]).notRequired(),

        [fields.description]: string().label(labels[fields.description]).required(),

        [fields.color]: string().label(labels[fields.color]).matches(HTML_COLOR_REGEXP),
    });
};

export default OrderstatusSchema;
