import * as yup from "yup";
import { texts } from "modules/common/texts";
import { uniq } from "lodash";
const { object, string, array, date } = yup;

export const MAX_VARIANTS = 5;

export const surveyLabels = () => ({
    name: texts.name,
    variants: "Варианты ответов",
    until: "Дата окончания",
});

const SurveySchema = () => {
    const labels = surveyLabels();

    return object().shape({
        name: string().label(labels.name).required(),

        variants: array()
            .of(string().label(labels.variants))
            .label(labels.variants)
            .transform(function (val, _) {
                return this.isType(val) && val ? uniq(val.filter((str) => !!str)) : [];
            })
            .ensure()
            .min(2)
            .max(MAX_VARIANTS),

        until: date().label(labels.until).typeError(texts.messages.incorrectDate()).notRequired().nullable(),
    });
};

export default SurveySchema;
