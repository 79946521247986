import React, { useCallback } from "react";
// import React, { useCallback } from "react";
import { ItemRenderer, ItemPredicate } from "@blueprintjs/select";
import { OrderDictionaryItemType } from "modules/orders-manage/models/order-dictionary";
import { MenuItem, Button } from "@blueprintjs/core";
import { texts } from "modules/common/texts";
import { prevent, SelectFactory } from "modules/common/services/form/select";
import { toString } from "modules/common/services/strings";
import { GeneralIcon } from "../planr/icon/Generalcon";

export const ProjectSelect = SelectFactory<OrderDictionaryItemType>();

export const renderProjectOption: ItemRenderer<OrderDictionaryItemType> = (item, { handleClick, modifiers }) => {
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={item.id}
            onClick={handleClick}
            text={optionLabel(item)}
        />
    );
};

export const filterProjectItemPredicate: ItemPredicate<OrderDictionaryItemType> = (query, item, _index, exactMatch) => {
    const normalizedTitle = (optionLabel(item) + toString(item.orderNumber)).toLowerCase();
    const normalizedQuery = query.toLowerCase().trim();

    if (exactMatch) {
        return normalizedTitle === normalizedQuery;
    } else {
        return normalizedTitle.indexOf(normalizedQuery) >= 0;
    }
};

export const ProjectSelectedOption = ({ option, onClear, empty, noData }: SelectedOptionProps) => {
    const clear = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            onClear && onClear();
        },
        [onClear]
    );

    const icon =
        onClear && option ? (
            // <Icon icon='delete' onClick={clear} />
            <>
                <GeneralIcon type="general-cross-small" onClick={clear} style={{ color: "#E31818" }} />
                <GeneralIcon type="general-chevron-down" />
            </>
        ) : (
            // <Icon icon="caret-down" />
            <GeneralIcon type="general-chevron-down" />
        );

    const button = (
        <Button
            onClick={empty ? prevent : undefined}
            disabled={empty}
            fill={true}
            rightIcon={icon}
            className="selected-option"
            text={empty ? noData || texts.noData : option ? optionLabel(option) : noData || " "}
        />
    );

    return button;
};

interface SelectedOptionProps {
    option: OrderDictionaryItemType | null | undefined;
    onClear?: () => void;
    empty?: boolean;
    noData?: string;
}

export const optionLabel = (option: OrderDictionaryItemType) => `${option.fullInventoryNumber} - ${option.label}`;
