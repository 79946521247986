// https://sdk.collaboraonline.com/docs/postmessage_api.html

const ORIGIN = "*";

export interface CollaboraMessage<TResult = any> {
    MessageId:
        | "App_LoadingStatus"
        | "Get_Views_Resp"
        | "View_Added"
        | "View_Removed"
        | "Views_List"
        | "App_VersionRestore"
        | "Clicked_Button"
        | "Doc_ModifiedStatus";
    Values: TResult;
}

export interface AppLoadingStatus
    extends CollaboraMessage<{
        Status: "Frame_Ready" | "Document_Loaded" | "Failed";
    }> {}

export interface DocModifiedStatus
    extends CollaboraMessage<{
        Modified: boolean;
    }> {}

function sendMessage(frame: HTMLIFrameElement | null, origin: string, id: string, data: any) {
    frame?.contentWindow?.postMessage(
        JSON.stringify({
            MessageId: id,
            Values: data,
        }),
        origin
    );
}

export function hideButton(frame: HTMLIFrameElement | null, id: string) {
    sendMessage(frame, ORIGIN, "Hide_Button", {
        id,
    });
}

export function hostReady(frame: HTMLIFrameElement | null) {
    sendMessage(frame, ORIGIN, "Host_PostmessageReady", {});
}

export function reloadFrame(frameId: string) {
    const form = document.getElementById(frameId) as HTMLFormElement;
    if (form) {
        form.submit();
        return true;
    }

    return false;
}
