import React from "react";
import { Dialog, Classes } from "@blueprintjs/core";
import { texts } from "modules/common/texts";
import { Buttons } from "modules/common/components/form";
import { PlanrButton } from "modules/common/components/planr/button/Button";

const reload = () => document.location.reload(true);

export const Unavailable = () => {
    return (
        <Dialog
            autoFocus={true}
            isOpen={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            isCloseButtonShown={false}
            title={texts.warning}
            icon="error"
            className="figma-dialog"
        >
            <div
                className={Classes.DIALOG_BODY}
                style={{
                    color: "#00273d",
                    fontFamily: "Gotham Pro, Arial, Helvetica, sans-serif",
                }}
            >
                Сервер приложения недоступен. Попробуйте позже.
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <Buttons
                    left={
                        <PlanrButton type="greenish" size="small" onClick={reload}>
                            Обновить
                        </PlanrButton>
                    }
                />
            </div>
        </Dialog>
    );
};
