import { OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";
import { UserTimesheetType } from "modules/spending/timesheet/models/user-timesheet";
import React, { useCallback } from "react";
import styles from "./DepartmentBlock.module.scss";
import { MonthUserRow, MonthUserRowEditor } from "./MonthUserRow";
import { DaySelector, TimesheetCellMode } from "./TimesheetCell";
import { TimesheetSaver } from "./TimesheetInput";
import { TimesheetUserRow } from "./TimesheetUserRow";
import { WeekDays } from "./WeekDays";

import { Collapse } from "@blueprintjs/core";
import { Caret } from "modules/common/components/collapse/Caret";
import { Peoples } from "modules/orders-manage/details/Peoples";
import { TasksDictionaryType, WorkloadDayType } from "modules/spending/workload/models/workload-day";
import { DepartmentBlockMode } from "../models/timesheet-store";

export const DepartmentBlock: React.FC<DepartmentBlockProps> = (props) => {
    const { collapsed, toggleCollapse, onEditStateChanged } = props;
    const { department, users, cellMode, mode, tasks, selectedCells, onSelectCells, onContext, ...selector } = props;

    const onToggleCollapse = useCallback(() => toggleCollapse(department), [toggleCollapse, department]);

    return (
        <div className={`${styles.block} department-block`}>
            <h1 className="planr-block-header-wide_lowscreen collapser" onClick={onToggleCollapse}>
                <div className={styles.departmentName}>
                    <div className={styles.department}>{department}</div>

                    <Peoples count={users.length} />
                </div>
                {!collapsed && (
                    <>
                        {mode === "day" && (
                            <div style={{ marginLeft: "-306px" }}>
                                {users.length && <WeekDays days={users[0].days} />}
                            </div>
                        )}
                    </>
                )}
                <Caret collapsed={collapsed} style={{ left: "7px" }} />
            </h1>
            <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                <div className="collapse-wide_lowscreen" style={{ paddingTop: "0px" }}>
                    {mode === "day" && (
                        <>
                            {/* {users.length && <WeekDays days={users[0].days} />} */}
                            {users.map((timesheet) => (
                                <TimesheetUserRow
                                    timesheet={timesheet}
                                    key={timesheet.user.id}
                                    mode={cellMode}
                                    tasks={tasks}
                                    selectedCells={selectedCells}
                                    onSelectCells={onSelectCells}
                                    onContext={onContext}
                                    {...selector}
                                />
                            ))}
                        </>
                    )}
                    {mode === "month" && (
                        <>
                            {/* {users.length && <MonthHeader />} */}
                            {users.map((timesheet) => (
                                <MonthUserRow
                                    timesheet={timesheet}
                                    key={timesheet.user.id}
                                    onEditStateChanged={onEditStateChanged}
                                    {...selector}
                                />
                            ))}
                        </>
                    )}
                </div>
            </Collapse>
        </div>
    );
};

interface DepartmentBlockProps extends DaySelector, TimesheetSaver, MonthUserRowEditor {
    department: string;
    users: UserTimesheetType[];
    orders: OrderDictionaryType;
    mode: DepartmentBlockMode;
    canManage: boolean;
    userLogin: string;
    cellMode: TimesheetCellMode;
    collapsed: boolean;
    toggleCollapse: (key: string) => void;
    tasks: TasksDictionaryType;
    showMinutes: boolean;
    onContext: (x: number, y: number, day: WorkloadDayType) => void;
    selectedCells: string[];
    onSelectCells: (cell: string[]) => void;
}
