import { types } from "mobx-state-tree";
import { IdEntity, DictionaryItem } from "modules/common/models/entity";
import { nameof } from "modules/common/services/typescript";
import { formatMoney } from "modules/common/components/money/Money";
import { OutsourcerDictionaryItem } from "modules/agents/outsourcers/models/outsourcer-dictionary";
import { ExtendedOrderLink } from "modules/orders-manage/models/order-link";
import { FinanceRequestStatisticDictionaryDictionaryItem } from "modules/expenses/import/models/bank-row";

export const SpendingsListRow = types
    .compose(
        IdEntity,
        types.model({
            bankRowId: types.maybeNull(types.string),
            item: types.maybeNull(FinanceRequestStatisticDictionaryDictionaryItem),
            payload: types.string,
            dictionaryId: types.string,
            date: types.string,
            sortableDate: types.string,
            isIncoming: types.boolean,
            isProduction: types.boolean,
            sum: types.number,
            incomingSum: types.maybeNull(types.number),
            outcomingSum: types.maybeNull(types.number),
            order: types.maybeNull(ExtendedOrderLink),
            orderName: types.string,
            orderInventory: types.string,
            workTypeName: types.string,
            workTypeId: types.string,
            agent: types.maybeNull(OutsourcerDictionaryItem),
            agentName: types.string,
            orderSpending: types.maybeNull(DictionaryItem),
            type: types.string,
            name: types.string,
            automatic: types.boolean,
            isCash: types.boolean,
            comment: types.string,
            document: types.maybeNull(
                types.model({
                    fileId: types.string,
                    fileName: types.string,
                })
            ),
        })
    )
    .actions((self) => ({
        found(query: string) {
            const what =
                `${self.sum} ${self.date} ${self.orderName} ${self.workTypeName} ${self.agentName} ${self.name} ${self.comment}`.toLowerCase();
            return what.indexOf(query) >= 0;
        },
    }))
    .named("SpendingsListRow");

export type SpendingsListRowType = typeof SpendingsListRow.Type;
export type SpendingsListRowSnapshotType = typeof SpendingsListRow.SnapshotType;

export const fields = {
    date: nameof((a: SpendingsListRowType) => a.date) as string,
    sortableDate: nameof((a: SpendingsListRowType) => a.sortableDate) as string,
    isIncoming: nameof((a: SpendingsListRowType) => a.isIncoming) as string,
    isProduction: nameof((a: SpendingsListRowType) => a.isProduction) as string,
    sum: nameof((a: SpendingsListRowType) => a.sum) as string,
    incomingSum: nameof((a: SpendingsListRowType) => a.incomingSum) as string,
    outcomingSum: nameof((a: SpendingsListRowType) => a.outcomingSum) as string,
    orderName: nameof((a: SpendingsListRowType) => a.orderName) as string,
    order: nameof((a: SpendingsListRowType) => a.order) as string,
    workTypeName: nameof((a: SpendingsListRowType) => a.workTypeName) as string,
    agentName: nameof((a: SpendingsListRowType) => a.agentName) as string,
    document: nameof((a: SpendingsListRowType) => a.document) as string,
    comment: nameof((a: SpendingsListRowType) => a.comment) as string,
};

export function removeConfiramtionName(row: SpendingsListRowSnapshotType) {
    const money = formatMoney(row.sum, { noFraction: false });

    return `операцию от ${row.date} на ${money}`;
}
