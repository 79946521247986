import { types, applySnapshot, isAlive } from "mobx-state-tree";
import { BaseEntity, isNewlyCreated, DictionaryItem } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID, DATE_TIME_FORMAT } from "modules/common/constants";
import Schema from "../components/details/category-validation";
import moment from "moment";
import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";
import { getFieldLabel } from "modules/common/services/form/fields";
import { DictionaryLink, DictionaryEmployerHistoryLink } from "modules/common/models/dictionary-link";
import { formatDate } from "modules/common/services/formatting/date";

export const MaterialValueDictionaryItem = DictionaryItem.named("MaterialValueDictionaryItem");

export const MaterialValueDictionaryLink = types.compose(
    DictionaryLink,
    types.model({
        nonProduction: types.boolean,
        order: types.maybeNull(types.number),
    })
);

const MaterialValueBase = types
    .compose(
        Transport,
        Notificator,
        BaseEntity,
        types.model({
            // дата списания с баланса
            stopDate: types.maybeNull(types.string),
            // начальная стоимость
            sum: types.number,
            // инвентарник, введенный вручную
            customInventoryNumber: types.string,
            // отформатированный инв номер, вкючающий префикс категории
            fullInventoryNumber: types.string,
            // дата постановки на баланс
            startDate: types.string,
            // амортизационная группа
            depreciationGroup: types.maybeNull(DictionaryLink),
            // срок полезного использования (мес)
            lifeTime: types.number,
            // ежемесячная сумма амортизации
            rateOfUse: types.number,
            // примечания
            comment: types.string,
            // описание, характеристики
            description: types.string,
            // инвентарный номер (без префикса)
            inventoryNumber: types.number,
            // категория
            materialValueCategory: types.maybeNull(DictionaryLink),
            id: types.string,
            name: types.string,
            // удалено или нет
            isRemoved: types.boolean,
            // дата создания записи
            created: types.string,
            // текущий ответственный сотрудник, Nullable
            currentEmployer: types.maybeNull(DictionaryEmployerHistoryLink),
            // история всех ответсвеных сотрудников (от самого недавнего),
            // включая текущего
            employeeHistory: types.optional(types.array(DictionaryEmployerHistoryLink), []),
            // сумма амортизации на текущий момент
            deprecationSum: types.number,
            // актуальная сумма на текущий момент (с учетом амортизации)
            actualSum: types.number,
        })
    )
    .views((self) => ({
        get materialValueCategoryId() {
            return self.materialValueCategory?.id;
        },

        get employerId() {
            return self.currentEmployer?.id;
        },
        get currentEmployeeName() {
            return self.currentEmployer?.name;
        },
        get depreciationGroupId() {
            return self.depreciationGroup?.id;
        },

        get depreciationGroupName() {
            return self.depreciationGroup?.name;
        },
    }));

export const MaterialValue = MaterialValueBase.actions((self) => ({
    load: flow(function* (id: string) {
        try {
            const snapshot = isNewlyCreated(id)
                ? initialState()
                : yield self.transport.get<MaterialValueSnapshotType>(apiUrls.materialValues.details(id));

            applySnapshot(self, snapshot);
        } catch (er) {
            self.notify.error(er);
        }
    }),

    getCredentials: flow(function* () {
        try {
            const result: MaterialValueCredentials = yield self.transport.get<any>(
                apiUrls.materialValues.details(self.id)
            );
            return result;
        } catch (er) {
            self.notify.error(er);
            return null;
        }
    }),

    employer: flow(function* (id: string) {
        try {
            const snapshot = isNewlyCreated(id)
                ? initialState()
                : yield self.transport.get<MaterialValueSnapshotType>(apiUrls.materialValues.employer(id));

            applySnapshot(self, snapshot);
        } catch (er) {
            self.notify.error(er);
        }
    }),

    save: flow(function* (model: any) {
        try {
            if (model[fields.startDate] instanceof Date) {
                model[fields.startDate] = formatDate(model[fields.startDate]);
            }
            const snapshot = self.isNewlyCreated
                ? yield self.transport.put<MaterialValueSnapshotType>(apiUrls.materialValues.create(), {
                      ...model,
                  })
                : yield self.transport.post<MaterialValueSnapshotType>(apiUrls.materialValues.update(self.id), {
                      ...model,
                  });

            isAlive(self) && applySnapshot(self, snapshot);
            self.notify.success(texts.messages.saved);

            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    stopp: flow(function* (stopDate: string) {
        try {
            yield self.transport.post<MaterialValueSnapshotType>(apiUrls.materialValues.stop(self.id), {
                stopDate,
            });

            self.notify.success(texts.messages.saved);
            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    restore: flow(function* () {
        try {
            yield self.transport.post<MaterialValueSnapshotType>(apiUrls.materialValues.restore(self.id), {});

            self.notify.success(texts.messages.saved);

            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    delete: flow(function* () {
        if (self.isNewlyCreated) {
            return true;
        }

        try {
            yield self.transport.delete<boolean>(apiUrls.materialValues.delete(self.id));
            self.notify.success(texts.messages.removed);

            return true;
        } catch (er) {
            self.notify.error(er);
            return false;
        }
    }),
})).named("MaterialValue");

export type MaterialValueDictionaryItemType = typeof MaterialValueDictionaryItem.Type;
export type MaterialValueSnapshotType = typeof MaterialValueBase.SnapshotType;
export type MaterialValueType = typeof MaterialValue.Type;

export const initialState = (): MaterialValueSnapshotType => ({
    id: EMPTY_OBJECT_ID,
    created: moment().format(DATE_TIME_FORMAT),
    stopDate: null,
    sum: 0,
    customInventoryNumber: "",
    fullInventoryNumber: "",
    startDate: "",
    depreciationGroup: null,
    lifeTime: 0,
    rateOfUse: 0,
    comment: "",
    description: "",
    inventoryNumber: 0,
    materialValueCategory: null,
    name: "",
    isRemoved: false,
    currentEmployer: null,
    employeeHistory: [],
    deprecationSum: 0,
    actualSum: 0,
});

export const fields = {
    name: nameof((a: MaterialValueType) => a.name) as string,
    comment: nameof((a: MaterialValueType) => a.comment) as string,
    actualSum: nameof((a: MaterialValueType) => a.actualSum) as string,
    sum: nameof((a: MaterialValueType) => a.sum) as string,
    fullInventoryNumber: nameof((a: MaterialValueType) => a.fullInventoryNumber) as string,
    customInventoryNumber: nameof((a: MaterialValueType) => a.customInventoryNumber) as string,
    startDate: nameof((a: MaterialValueType) => a.startDate) as string,
    stopDate: nameof((a: MaterialValueType) => a.stopDate) as string,
    lifeTime: nameof((a: MaterialValueType) => a.lifeTime) as string,
    rateOfUse: nameof((a: MaterialValueType) => a.rateOfUse) as string,
    description: nameof((a: MaterialValueType) => a.description) as string,
    inventoryNumber: nameof((a: MaterialValueType) => a.inventoryNumber) as string,
    isRemoved: nameof((a: MaterialValueType) => a.isRemoved) as string,
    deprecationSum: nameof((a: MaterialValueType) => a.deprecationSum) as string,
    materialValueCategoryId: nameof((a: MaterialValueType) => a.materialValueCategoryId) as string,
    employerId: nameof((a: MaterialValueType) => a.employerId) as string,
    currentEmployeeName: nameof((a: MaterialValueType) => a.currentEmployeeName) as string,
    depreciationGroupId: nameof((a: MaterialValueType) => a.depreciationGroupId) as string,
};

export function formatMaterialValue(stage: MaterialValueSnapshotType | null) {
    let result = "";

    if (stage) {
        const schema = Schema();

        result += `${getFieldLabel(fields.name, schema, null)}: ${stage.name}\n`;
        result += `${getFieldLabel(fields.comment, schema, null)}: ${stage.comment}\n`;
    }

    return result;
}

export interface MaterialValueCredentials {
    inventoryNumber: string;
}
