import React from "react";
import styles from "./WeekDays.module.scss";
import { observer } from "mobx-react";
import { WorkloadDayType } from "modules/spending/workload/models/workload-day";

export const WeekDays = observer(({ days, isMainPage }: WeekDaysProps) => {
  return (
    <div className={`week-days ${styles.row}`} style={isMainPage ? { marginBottom: "-15px" } : {}}>
      <div className={`timesheet-user-name ${styles.part}`} />
      {days.map((day, index) => {
        const next = index + 1 < days.length ? days[index + 1] : undefined;

        const preWeekend = next && next.isDayOff ? styles.preWeekend : "";
        const preToday = next && next.isToday() ? styles.preToday : "";
        const weekend = day.isDayOff ? styles.weekend : "";
        const today = day.isToday() ? styles.today : "";

        return (
          <div
            key={day.dayOfMonth}
            className={`timesheet-cell ${styles.cell} ${styles.part} ${weekend} ${preWeekend} ${preToday} ${today}`}
          >
            {day.dayOfMonth}
          </div>
        );
      })}
    </div>
  );
});

interface WeekDaysProps {
  days: WorkloadDayType[];
  isMainPage?: boolean;
}
