import { groupBy, sumBy } from "lodash";
import { DictionaryLink } from "modules/common/models/dictionary-link";
import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { IdEntity, isNewlyCreated } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { prettyRound } from "modules/common/services/formatting/numbers";

const KpiDestributionParameter = types
    .model({
        value: types.number,
        delta: types.number,
    })
    .named("KpiDestributionParameter");

export const KpiDestribution = types
    .model({
        productionBudget: KpiDestributionParameter,
        productionQuality: KpiDestributionParameter,
        engineerBudget: KpiDestributionParameter,
        engineerQuality: KpiDestributionParameter,
        engineerTime: KpiDestributionParameter,
    })
    .named("KpiDestribution");

export const OrderPremiumBankCorrection = types
    .model({
        actualSpendings: types.number,
        planSpendings: types.number,
    })
    .views((self) => ({
        get sum() {
            return self.actualSpendings > self.planSpendings ? self.actualSpendings - self.planSpendings : 0;
        },
    }))
    .named("OrderPremiumBankCorrection");

export const OrderPremiumBank = types
    .model({
        calculatedSumm: types.number,
        correctionDetails: types.maybeNull(OrderPremiumBankCorrection),
    })
    .views((self) => ({
        get correction() {
            return self.correctionDetails ? self.correctionDetails.sum : 0;
        },
    }))
    .views((self) => ({
        get finalSum() {
            return Math.max(0, self.calculatedSumm - self.correction);
        },
    }))
    .named("OrderPremiumBank");

const PremiumBase = types
    .compose(
        Transport,
        types.model({
            bank: types.number,
            employer: DictionaryLink,
            fired: types.boolean,
        })
    )
    .views((self) => ({
        get userId() {
            return self.employer?.id ?? "";
        },
    }))
    .actions((self) => ({
        paymentFactory(sum: number): EmployerPremiumPaymentSnapshotType {
            return {
                ...emptyPayment(self.userId),
                sum,
            };
        },
    }));

export const EngineerPremium = types
    .compose(
        PremiumBase,
        types.model({
            employer: types.maybeNull(DictionaryLink),
            kpi: KpiDestribution,
            hasQualityMark: types.boolean,
            hasBudgetMark: types.boolean,
            hasTimeMark: types.boolean,
        })
    )
    .views((self) => ({
        get quality() {
            return self.hasQualityMark ? (self.bank * self.kpi.engineerQuality.value) / 100 : 0;
        },

        get budget() {
            return self.hasBudgetMark ? (self.bank * self.kpi.engineerBudget.value) / 100 : 0;
        },

        get time() {
            return self.hasTimeMark ? (self.bank * self.kpi.engineerTime.value) / 100 : 0;
        },
    }))
    .views((self) => ({
        get sum() {
            return self.budget + self.quality + self.time;
        },
    }))
    .named("EngineerPremium");

export const ProductionEmployerPremium = types
    .compose(
        PremiumBase,
        types.model({
            kpi: KpiDestribution,
            hasQualityMark: types.boolean,
            hasBudgetMark: types.boolean,
            salary: types.number,
            hours: types.number,
            salaryFactor: types.number,
            hoursFactor: types.number,
            totalFactor: types.maybeNull(
                types.model({
                    value: types.number,
                })
            ),
            planBudget: types.number,
            actualBudget: types.number,
            department: DictionaryLink,
        })
    )
    .views((self) => ({
        get factor() {
            return self.hoursFactor * self.salaryFactor;
        },
    }))
    .views((self) => ({
        get premiumPercent() {
            return self.totalFactor !== null && self.totalFactor.value > 0
                ? (self.factor / self.totalFactor.value) * 100
                : 0;
        },
    }))
    .views((self) => ({
        get calculatedSum() {
            return (self.premiumPercent / 100) * self.bank;
        },
    }))
    .views((self) => ({
        get budget() {
            return self.hasBudgetMark ? (self.calculatedSum * self.kpi.productionBudget.value) / 100 : 0;
        },

        get quality() {
            return self.hasQualityMark ? (self.calculatedSum * self.kpi.productionQuality.value) / 100 : 0;
        },
    }))
    .views((self) => ({
        get sum() {
            return self.budget + self.quality;
        },
    }))
    .named("ProductionEmployerPremium");

export const NonProductionEmployerPremium = types
    .compose(
        PremiumBase,
        types.model({
            salary: types.number,
            hours: types.number,
            salaryFactor: types.number,
            hoursFactor: types.number,
            totalFactor: types.maybeNull(
                types.model({
                    value: types.number,
                })
            ),
        })
    )
    .views((self) => ({
        get factor() {
            return self.hoursFactor * self.salaryFactor;
        },
    }))
    .views((self) => ({
        get premiumPercent() {
            return self.totalFactor !== null && self.totalFactor.value > 0
                ? (self.factor / self.totalFactor.value) * 100
                : 0;
        },
    }))
    .views((self) => ({
        get sum() {
            return (self.premiumPercent / 100) * self.bank;
        },
    }))
    .named("NonProductionEmployerPremium");

const EmployerPremiumBase = types.compose(
    IdEntity,
    types.model({
        dirty: types.optional(types.boolean, false),
        removed: types.optional(types.boolean, false),
        sum: types.number,
        employerId: types.string,
    })
);

export const EmployerPremiumPayment = types
    .compose(
        EmployerPremiumBase,
        types.model({
            period: types.string,
            periodName: types.string,
            month: types.maybeNull(
                types.model({
                    year: types.number,
                    month: types.number,
                })
            ),
        })
    )
    .actions((self) => ({
        setSum(value: number) {
            self.sum = value;
        },
    }))
    .named("EmployerPremiumPayment");

export const EmployerPremiumAssignation = types.compose(Transport, EmployerPremiumBase).named("ActualEmployerPremium");

const OrderPremiumDetailsBase = types.model({
    assignedPremiums: types.array(EmployerPremiumAssignation),
    paidPremiums: types.array(EmployerPremiumPayment),
    engineerPremium: EngineerPremium,
    productionEmployerPremiums: types.array(ProductionEmployerPremium),
    nonProductionEmployerPremiums: types.array(NonProductionEmployerPremium),
    showMinutes: types.boolean,
});

export const OrderPremiumDetails = types
    .compose(
        Transport,
        Notificator,
        OrderPremiumDetailsBase,
        types.model({
            dirty: types.optional(types.boolean, false),
        })
    )
    .views((self) => ({
        get actualAssignedPremiums() {
            return self.assignedPremiums.filter((a) => !a.removed);
        },

        get assignedToRemove() {
            return self.assignedPremiums.filter((a) => a.removed && !!a.id);
        },

        get actualPaiments() {
            return self.paidPremiums.filter((a) => !a.removed);
        },

        get paymentsToRemove() {
            return self.paidPremiums.filter((a) => a.removed && !!a.id);
        },
    }))
    .views((self) => ({
        get premiumProductionAsMap() {
            return groupBy(self.productionEmployerPremiums, (p) => p.department.id);
        },

        get actualAssignedPremiumsAsMap() {
            return groupBy(self.actualAssignedPremiums, (p) => p.employerId);
        },

        get paidPremiumsAsMap() {
            return groupBy(self.paidPremiums, (p) => p.employerId);
        },
    }))
    .views((self) => ({
        get premiumRows() {
            const users = [
                self.engineerPremium,
                ...self.productionEmployerPremiums,
                ...self.nonProductionEmployerPremiums,
            ];

            return users.map((user) => {
                const fromMap = self.actualAssignedPremiumsAsMap[user.userId];
                const assigned = fromMap ? fromMap[0] : null ?? null;
                const payments = self.paidPremiumsAsMap[user.userId] || [];
                const paid = paidSum(payments);
                const assignedSum = assigned && !assigned.removed ? assigned.sum : 0;

                const row: PremiumRow = {
                    userId: user.userId,
                    assigned,
                    payments,
                    paidSum: paid,
                    remainsSum: Math.max(0, assigned ? assigned.sum - paid : 0),
                    assignedSum,
                };

                return row;
            });
        },
    }))
    .views((self) => ({
        get premiumRowsAsMap() {
            const result: TStringMap<PremiumRow> = {};
            self.premiumRows.forEach((row) => (result[row.userId] = row));
            return result;
        },

        get paidSum() {
            return paidSum(self.paidPremiums);
        },
    }))
    .actions((self) => ({
        addPayment(employerId: string) {
            self.paidPremiums.push(emptyPayment(employerId));
            self.dirty = true;
        },

        setPayments(userId: string, data: EmployerPremiumPaymentSnapshotType[]) {
            const snapshot = self.paidPremiums.filter((p) => p.employerId !== userId).map((p) => getSnapshot(p));

            applySnapshot(self.paidPremiums, [...snapshot, ...data]);
            self.dirty = true;
        },

        buildSaveCommand() {
            const command: SaveOrderPremiumsCommand = {
                assignedToRemove: self.assignedToRemove.map((p) => p.id),
                assignedToSave: self.actualAssignedPremiums
                    .filter((p) => p.dirty)
                    .map((p) => ({
                        employerId: p.employerId,
                        id: p.id,
                        sum: p.sum,
                    })),
                paymentsToRemove: self.paymentsToRemove.map((p) => p.id),
                paymentsToSave: self.actualPaiments
                    .filter((p) => p.dirty)
                    .map((p) => ({
                        employerId: p.employerId,
                        id: p.id,
                        sum: p.sum,
                        month: p.month,
                    })),
            };

            if (
                !command.assignedToRemove.length &&
                !command.assignedToSave.length &&
                !command.paymentsToRemove.length &&
                !command.paymentsToSave.length
            ) {
                return null;
            }

            return command;
        },
    }))
    .actions((self) => ({
        save: flow(function* (correctOrderId: string) {
            const command = self.buildSaveCommand();
            if (!command) {
                return getSnapshot(self);
            }

            try {
                const url = apiUrls.orders.summary.premiums(correctOrderId);
                const snapshot: any = yield self.transport.post<any>(url, command);

                applySnapshot(self, snapshot);

                return snapshot;
            } catch (er) {
                self.notify.error(er);
                return null;
            }
        }),

        setProductionEmployerFeedback: (userId: string, checked: boolean) => {
            self.productionEmployerPremiums.forEach((row) => {
                if (row.employer.id === userId) {
                    row.hasQualityMark = checked;
                }
            });
        },

        assingPremium(row: PremiumBaseType & { sum: number }) {
            if (row.sum === 0) {
                return;
            }

            const assignee: EmployerPremiumAssignationSnapshotType = {
                employerId: row.employer.id,
                id: "",
                sum: prettyRound(row.sum),
                dirty: true,
                removed: false,
            };

            self.assignedPremiums.push(assignee);
        },

        removeAssingnation(row: PremiumBaseType) {
            const assignee = self.assignedPremiums.find((a) => a.employerId === row.employer.id);
            if (!assignee) {
                return;
            }

            if (isNewlyCreated(assignee.id)) {
                self.assignedPremiums.remove(assignee);
            } else {
                assignee.removed = true;
            }
        },
    }))
    .named("OrderPremiumDetails");

export interface PremiumRow {
    payments: EmployerPremiumPaymentSnapshotType[];
    assigned: EmployerPremiumAssignationSnapshotType | null;
    userId: string;
    remainsSum: number;
    assignedSum: number;
    paidSum: number;
}

interface SaveOrderPremiumsCommand {
    assignedToRemove: string[];
    paymentsToRemove: string[];
    assignedToSave: Array<{
        sum: number;
        employerId: string;
        id: string;
    }>;
    paymentsToSave: Array<{
        id: string;
        sum: number;
        month: {
            month: number;
            year: number;
        } | null;
    }>;
}

export const paidSum = (payments: EmployerPremiumPaymentSnapshotType[]) => {
    return sumBy(
        payments.filter((p) => !p.removed && p.month),
        (p) => p.sum
    );
};

export const paymentsError = (self: PremiumRow) => {
    if (self.payments.filter((p) => !p.removed).some((p) => p.sum <= 0)) {
        return "Необходимо указать сумму выплаты";
    }

    return "";
};

export interface PlanPremiumBlock {
    department: string;
    rows: PlanPremiumRow[];
    sum: number;
}

interface PlanPremiumRow {
    employerId: string;
    index: number;
    employer: string;
    salary: number;
    salaryRatio: number;
    hours: number;
    overtimeHours: number;
    hoursRatio: number;
    unknownRatio: number;
    premiumPercent: number;
    premiumSum: number;
}

const emptyKpiParameter = (): typeof KpiDestributionParameter.SnapshotType => ({
    delta: 0,
    value: 0,
});

export const emptyKpi = (): typeof KpiDestribution.SnapshotType => ({
    engineerBudget: emptyKpiParameter(),
    engineerQuality: emptyKpiParameter(),
    engineerTime: emptyKpiParameter(),
    productionBudget: emptyKpiParameter(),
    productionQuality: emptyKpiParameter(),
});

export const emptyPremiumDetails = (): OrderPremiumDetailsSnapshotType => ({
    assignedPremiums: [],
    paidPremiums: [],
    engineerPremium: {
        bank: 0,
        hasQualityMark: false,
        hasBudgetMark: false,
        hasTimeMark: false,
        kpi: emptyKpi(),
        employer: { id: "", name: "" },
        fired: false,
    },
    nonProductionEmployerPremiums: [],
    productionEmployerPremiums: [],
    dirty: false,
    showMinutes: false,
});

export const hasRecalculation = (sum: number, assigne: number) => {
    return prettyRound(sum) > prettyRound(assigne);
};

export const emptyPayment = (employerId: string): EmployerPremiumPaymentSnapshotType => {
    return {
        period: "",
        periodName: "",
        id: "",
        dirty: true,
        employerId,
        removed: false,
        sum: 0,
        month: null,
    };
};

export type PremiumBaseType = typeof PremiumBase.Type;
export type ProductionEmployerPremiumType = typeof ProductionEmployerPremium.Type;
export type ProductionEmployerPremiumSnapshotType = typeof ProductionEmployerPremium.SnapshotType;
export type EngineerPremiumType = typeof EngineerPremium.Type;
export type EngineerPremiumSnapshotType = typeof EngineerPremium.SnapshotType;
export type NonProductionEmployerPremiumType = typeof NonProductionEmployerPremium.Type;
export type EmployerPremiumPaymentType = typeof EmployerPremiumPayment.Type;
export type EmployerPremiumPaymentSnapshotType = typeof EmployerPremiumPayment.SnapshotType;
export type EmployerPremiumAssignationType = typeof EmployerPremiumAssignation.Type;
export type EmployerPremiumAssignationSnapshotType = typeof EmployerPremiumAssignation.SnapshotType;
export type OrderPremiumDetailsType = typeof OrderPremiumDetails.Type;
export type OrderPremiumDetailsSnapshotType = typeof OrderPremiumDetails.SnapshotType;
export type OrderPremiumBankType = typeof OrderPremiumBank.Type;
