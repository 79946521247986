/* eslint-disable no-template-curly-in-string */
import { types } from "mobx-state-tree";
import * as yup from "yup";
import { nameof } from "modules/common/services/typescript";
import { getFieldLabel } from "modules/common/services/form/fields";
import { ONLY_DIGITS_REGEXP } from "../constants";

const { object, string } = yup;

export const ShortBankDetails = types
    .model({
        guid: types.string,
        account: types.string,
        bankIdentificator: types.string,
    })
    .named("ShortBankDetails");

export const BankDetails = types
    .compose(
        ShortBankDetails,
        types.model("BankDetails", {
            inn: types.string,
            kpp: types.string,
            ogrn: types.string,
            okpo: types.string,
            bankName: types.string,
            corrAccount: types.string,
            address: types.string,
            directorName: types.string,
            directorNameGenitive: types.string,
            email: types.string,
        })
    )
    .actions((self) => ({
        format() {
            return formatBankDetails(self);
        },
    }));

export type ShortBankDetailsType = typeof ShortBankDetails.Type;
export type ShortBankDetailsSnapshotType = typeof ShortBankDetails.SnapshotType;
export type BankDetailsSnapshotType = typeof BankDetails.SnapshotType;
export type BankDetailsType = typeof BankDetails.Type;

export const initialState = (): BankDetailsSnapshotType => ({
    guid: "",
    account: "",
    bankIdentificator: "",
    address: "",
    email: "",
    bankName: "",
    corrAccount: "",
    directorName: "",
    directorNameGenitive: "",
    inn: "",
    kpp: "",
    ogrn: "",
    okpo: "",
});

export const shortFields = (prefix: string = "") => ({
    guid: prefix + (nameof((a: BankDetailsType) => a.guid) as string),
    account: prefix + (nameof((a: BankDetailsType) => a.account) as string),
    bankIdentificator: prefix + (nameof((a: BankDetailsType) => a.bankIdentificator) as string),
});

export const fields = (prefix: string = "") => ({
    ...shortFields(prefix),
    inn: prefix + (nameof((a: BankDetailsType) => a.inn) as string),
    kpp: prefix + (nameof((a: BankDetailsType) => a.kpp) as string),
    ogrn: prefix + (nameof((a: BankDetailsType) => a.ogrn) as string),
    okpo: prefix + (nameof((a: BankDetailsType) => a.okpo) as string),
    bankName: prefix + (nameof((a: BankDetailsType) => a.bankName) as string),
    corrAccount: prefix + (nameof((a: BankDetailsType) => a.corrAccount) as string),
    address: prefix + (nameof((a: BankDetailsType) => a.address) as string),
    directorName: prefix + (nameof((a: BankDetailsType) => a.directorName) as string),
    directorNameGenitive: prefix + (nameof((a: BankDetailsType) => a.directorNameGenitive) as string),
});

export const shortBankDetailsLabels = () => {
    const ownFields = shortFields();
    return {
        [ownFields.account]: "Расчетный счет",
        [ownFields.bankIdentificator]: "БИК",
    };
};

export const bankDetailsLabels = () => {
    const ownFields = fields();

    return {
        ...shortBankDetailsLabels(),
        [ownFields.inn]: "ИНН",
        [ownFields.kpp]: "КПП",
        [ownFields.ogrn]: "ОГРН",
        [ownFields.okpo]: "ОКПО",
        [ownFields.bankName]: "Банк",
        [ownFields.corrAccount]: "Корр. счет",
        [ownFields.address]: "Юр. адрес",
        [ownFields.directorName]: "Директор",
        [ownFields.directorNameGenitive]: "Директор Родит.п.",
    };
};

export const BankDetailsSchema = (required = false) => {
    const ownFields = fields();
    const labels = bankDetailsLabels();
    const digitsMessage = "${path} должен состоять из цифр";

    const result = {
        [ownFields.inn]: yup.lazy((inn) =>
            !!inn
                ? string().label(labels[ownFields.inn]).matches(ONLY_DIGITS_REGEXP, digitsMessage)
                : string().label(labels[ownFields.inn]).notRequired()
        ),

        [ownFields.kpp]: yup.lazy((kpp) =>
            !!kpp
                ? string().label(labels[ownFields.kpp]).matches(ONLY_DIGITS_REGEXP, digitsMessage)
                : string().label(labels[ownFields.kpp]).notRequired()
        ),

        [ownFields.ogrn]: yup.lazy((ogrn) =>
            !!ogrn
                ? string().label(labels[ownFields.ogrn]).matches(ONLY_DIGITS_REGEXP, digitsMessage)
                : string().label(labels[ownFields.ogrn]).notRequired()
        ),

        [ownFields.okpo]: yup.lazy((okpo) =>
            !!okpo
                ? string().label(labels[ownFields.okpo]).matches(ONLY_DIGITS_REGEXP, digitsMessage)
                : string().label(labels[ownFields.okpo]).notRequired()
        ),

        [ownFields.account]: yup.lazy((account) =>
            !!account
                ? string().label(labels[ownFields.account]).matches(ONLY_DIGITS_REGEXP, digitsMessage)
                : string().label(labels[ownFields.account]).notRequired()
        ),

        [ownFields.bankName]: string().label(labels[ownFields.bankName]),

        [ownFields.bankIdentificator]: yup.lazy((account) =>
            !!account
                ? string().label(labels[ownFields.bankIdentificator]).matches(ONLY_DIGITS_REGEXP, digitsMessage)
                : string().label(labels[ownFields.bankIdentificator]).notRequired()
        ),

        [ownFields.corrAccount]: yup.lazy((corrAccount) =>
            !!corrAccount
                ? string().label(labels[ownFields.corrAccount]).matches(ONLY_DIGITS_REGEXP, digitsMessage)
                : string().label(labels[ownFields.corrAccount]).notRequired()
        ),

        [ownFields.address]: string().label(labels[ownFields.address]),

        [ownFields.directorName]: string().label(labels[ownFields.directorName]),
        [ownFields.directorNameGenitive]: string().label(labels[ownFields.directorNameGenitive]),
    };

    if (required) {
        result[ownFields.account] = string()
            .label(labels[ownFields.account])
            .required()
            .matches(ONLY_DIGITS_REGEXP, digitsMessage);

        result[ownFields.bankIdentificator] = string()
            .label(labels[ownFields.bankIdentificator])
            .required()
            .matches(ONLY_DIGITS_REGEXP, digitsMessage);
    }

    return object().shape(result);
};

export const formatBankDetails = (bankDetails: BankDetailsSnapshotType | null) => {
    let result = "";

    if (bankDetails) {
        const ownFields = fields();
        const schema = BankDetailsSchema();

        result += `${getFieldLabel(ownFields.inn, schema, null)}: ${bankDetails.inn}\n`;
        result += `${getFieldLabel(ownFields.kpp, schema, null)}: ${bankDetails.kpp}\n`;
        result += `${getFieldLabel(ownFields.ogrn, schema, null)}: ${bankDetails.ogrn}\n`;
        result += `${getFieldLabel(ownFields.okpo, schema, null)}: ${bankDetails.okpo}\n`;
        result += `${getFieldLabel(ownFields.account, schema, null)}: ${bankDetails.account}\n`;
        result += `${getFieldLabel(ownFields.bankName, schema, null)}: ${bankDetails.bankName}\n`;
        result += `${getFieldLabel(ownFields.bankIdentificator, schema, null)}: ${bankDetails.bankIdentificator}\n`;
        result += `${getFieldLabel(ownFields.corrAccount, schema, null)}: ${bankDetails.corrAccount}\n`;
        result += `${getFieldLabel(ownFields.address, schema, null)}: ${bankDetails.address}\n`;
        result += `${getFieldLabel(ownFields.directorName, schema, null)}: ${bankDetails.directorName}\n`;
        result += `${getFieldLabel(ownFields.directorNameGenitive, schema, null)}: ${
            bankDetails.directorNameGenitive
        }\n`;
    }

    return result;
};
