import { Button, Classes, FormGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { formatMoney } from "modules/common/components/money/Money";
import { DefaultSelectedOption, SimpleSelect, renderSingleOption } from "modules/common/services/form/select";
import { EventTypes, fields } from "modules/orders-manage/models/order-events";
import React, { useCallback } from "react";
import { CommonBusinessEventProps, Error, star } from "./common";

const Base = ({ event, readonly, payments, isNotStateChange, spendings }: CommonBusinessEventProps) => {
  const { value, validate } = event.model;
  let payment = null;
  if (value) {
    payment = spendings.find((o) => o.guid === value);
  } else {
    payment = spendings.find((o) => o.guid === event.model.paymentGuid);
  }

  const item: SelectItem | null = payment ? { id: payment.guid, label: label(payment) } : null;
  const options = spendings.map((o) => ({ id: o.guid, label: label(o) }));

  const valid = !item ? "Необходимо выбрать платеж" : null;

  const onChangeHandler = useCallback(
    (i: SelectItem) => {
      event.model.setValue(fields.paymentGuid, i.id);
    },
    [event]
  );

  const onChange = (i: SelectItem) => {
    isNotStateChange && isNotStateChange("paymentGuid", i.id);
  };

  if (!isNotStateChange)
    return (
      <FormGroup
        labelInfo={star}
        helperText={validate[fields.paymentGuid]}
        intent={validate[fields.paymentGuid] ? Error : "none"}
        className={Classes.SMALL}
      >
        <div className={`${Classes.INPUT_GROUP}`}>
          <SimpleSelect
            className={`full-width-select ${Classes.FILL}`}
            filterable={false}
            activeItem={item}
            items={options}
            itemRenderer={renderSingleOption}
            onItemSelect={onChangeHandler}
            disabled={readonly}
            popoverProps={{
              usePortal: true,
              boundary: "viewport",
              popoverClassName: "business-event__typeselect-dropdown",
            }}
          >
            {readonly && (
              <Button fill={true} className="selected-option">
                {payment && label(payment)}
              </Button>
            )}
            {!readonly && <DefaultSelectedOption option={item} empty={!options.length} />}
          </SimpleSelect>
        </div>
      </FormGroup>
    );

  return (
    <div className={`${Classes.INPUT_GROUP}`}>
      <div style={{ color: "red", fontSize: "12px" }}>{valid ? valid : null}</div>
      <SimpleSelect
        className={`full-width-select ${Classes.FILL}`}
        filterable={false}
        activeItem={item}
        items={options}
        itemRenderer={renderSingleOption}
        onItemSelect={onChange}
        disabled={readonly}
        popoverProps={{
          usePortal: true,
          boundary: "viewport",
          popoverClassName: "business-event__break-dropdown",
        }}
      >
        {readonly && (
          <Button fill={true} className="selected-option">
            {payment && label(payment)}
          </Button>
        )}
        {!readonly && <DefaultSelectedOption option={item} empty={!options.length} />}
      </SimpleSelect>
    </div>
  );
};

Base.TYPE = EventTypes.OrderSpendingPaymentBusinessEvent;

export const OrderSpendingPaymentBusinessEvent = observer(Base);

const label = (payment: any) => {
  let result = "";

  result = "Платеж №" + payment.name;

  result += " на сумму " + formatMoney(payment.sum);

  return result;
};
