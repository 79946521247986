import { saveAs } from "file-saver";
import { applySnapshot, getSnapshot, types } from "mobx-state-tree";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { MimeTypes, base64ToBlob } from "modules/common/services/files";
import { texts } from "modules/common/texts";
import { Constants } from "modules/root/models/constants";
import { EmployerDictionaryItemType } from "modules/spending/employee/models/employee-dictionary";
import { v4 } from "uuid";
import { EmployerDictionaryLink } from "../../spending/employee/models/employee-dictionary";
import { TaskFile, VersionFile } from "./order-file";

export const ACCEPT = MimeTypes.pdf;
export const tasksVersions = types
    .model({
        date: types.string,
        time: types.string,
        version: types.number,
        files: types.array(VersionFile),
    })
    .named("taskVersions");

export type TasksVersionsType = typeof tasksVersions.Type;

export const OrderAddedTasks = types
    .model({
        taskFileId: types.string,
        contentGuid: types.string,
        description: types.string,
    })
    .named("OrderAddedTasks");
export const DevelopersType = types.model({
    name: types.string,
    type: types.string,
});

export const OrderContentTasks = types
    .model({
        id: types.string,
        date: types.string,
        time: types.string,
        stopDate: types.maybeNull(types.string),
        name: types.string,
        description: types.string,
        author: types.string,
        implementer: types.maybeNull(EmployerDictionaryLink),
        contentGuid: types.string,
        accepted: types.boolean,
        completed: types.boolean,
        confirmed: types.boolean,
        cancelled: types.boolean,
        canChangeAcceptance: types.boolean,
        canChangeCompletion: types.boolean,
        canChangeConfirmation: types.boolean,
        canChangeCancellation: types.boolean,
        canEditTask: types.boolean,
        taskFile: types.array(TaskFile),
        orderId: types.string,
        toRemove: types.boolean,
    })
    .named("OrderContentTasks");

export const OrderTasksToAdd = types
    .model({
        description: types.string,
        contentGuid: types.string,
        taskFilesId: types.array(types.string),
        taskFilesPaths: types.array(types.model({ fileId: types.string, path: types.string })),
        implementerId: types.maybeNull(types.string),
        stopDate: types.string,
        id: types.maybeNull(types.string),
        isNew: types.boolean,
    })

    .named("OrderTasksToAdd");

// export const OrderTasksAcceptedToSave = types
//     .model({
//         contentGuid: types.string,
//         accepted: types.boolean,
//     })

//     .named("OrderTasksToAdd");

const CommentLink = types
    .model({
        comment: types.string,
        version: types.number,
        commentFiles: types.maybeNull(
            types.array(
                types.model({
                    fileId: types.string,
                    fileName: types.string,
                })
            )
        ),
    })
    .named("CommentLink");

export const OrderContentTasksList = types
    .compose(
        Transport,
        Notificator,
        types.model({
            canUploadFiles: types.boolean,
            canAddTasks: types.boolean,
            canDeleteVersion: types.boolean,
            canDownloadStructure: types.boolean,
            canGenerateSheet: types.boolean,
            guid: types.string,
            comments: types.array(CommentLink),
            developers: types.array(DevelopersType),
            versions: types.array(tasksVersions),
            sheetFile: types.maybeNull(VersionFile),
            tasks: types.array(OrderContentTasks),
        })
    )
    .views((self) => ({
        get lastFilesVersion() {
            if (self.versions.length > 0) {
                return self.versions[self.versions.length - 1];
            }
            return null;
        },
    }))
    .actions((self) => ({
        addItem: function (item: any) {
            self.tasks.push(item);
        },
    }))
    .named("OrderContentTasksList");
export const OrderContentTasksDictionary = types
    .compose(
        Transport,
        Notificator,
        types.model({
            contentTasksList: types.array(OrderContentTasksList),
            oldContentTasksList: types.array(OrderContentTasksList),
            tasksToAdd: types.array(OrderTasksToAdd),
            tasksToRemove: types.array(types.model({ id: types.string })),
            removedAddedTasks: types.array(OrderTasksToAdd),
        })
    )
    .actions((self) => ({
        copyFiles: flow(function* (ids: string[]) {
            try {
                const result: TStringMap<string> = yield self.transport.post<TStringMap<string>>(
                    apiUrls.application.files.copy,
                    {
                        ids,
                    }
                );
                return result;
            } catch (er) {
                self.notify.error(er);
                return null;
            }
        }),
    }))
    .actions((self) => ({
        findTask: function (id: string) {
            let indexTask = -1;
            let contentIndex = -1;
            self.oldContentTasksList.forEach((el, indexContent) => {
                el.tasks.forEach((task, taskIndex) => {
                    if (task.id === id) {
                        indexTask = taskIndex;
                        contentIndex = indexContent;
                    }
                });
            });
            return { indexTask, contentIndex };
        },
        getGuidFileTasks: async function (guids: string[], files: any[]) {
            let tasksFiles: any[] = [];
            for (let i = 0; i < guids.length; i++) {
                let taskFile = files && files.length > 0 ? files.map((file: any) => ({ ...file, type: "" })) : [];
                if (i > 0) {
                    let fileIds = files && files.length > 0 ? files.map((file: any) => file.id) : [];

                    if (fileIds.length > 0) {
                        const uploads = await self.copyFiles(fileIds);
                        taskFile =
                            uploads && taskFile.length > 0
                                ? taskFile.map((file: any) => ({ ...file, type: "", id: uploads[file.id] }))
                                : [];
                    }
                }
                tasksFiles.push({ guid: guids[i], taskFile });
            }
            return tasksFiles;
        },
    }))
    .actions((self) => ({
        addTaskTemp: flow(function* (
            idTask: string,
            guids: string[],
            description: string,
            stopDate: string,
            files: any,
            user: string,
            implementer: { id: string; label: string },
            employee: EmployerDictionaryItemType | undefined
        ) {
            if (description !== "" && stopDate !== "") {
                const author = user;
                const date = "";
                const time = "";
                let tasksFiles: any[] = yield self.getGuidFileTasks(guids, files);

                guids.forEach((contentGuid, index) => {
                    const filesTask = tasksFiles.find((item) => item.guid === contentGuid);
                    const id = v4();
                    let indexTask = -1;
                    let taskFile = filesTask ? filesTask.taskFile.map((file: any) => ({ ...file, type: "" })) : [];
                    self.contentTasksList.forEach((el) => {
                        el.tasks.forEach((task, taskIndex) => {
                            if (task.id === idTask) {
                                indexTask = taskIndex;
                            }
                        });
                    });

                    const newFields = {
                        taskFile,
                        description,
                        stopDate,
                        author,
                        date,
                        time,
                        contentGuid,
                        implementer: employee ? { ...employee } : null,
                    };
                    const contentTaskListitem = self.contentTasksList.find((item) => item.guid === contentGuid);
                    const newTask = {
                        ...emptyContentTasks(),
                        ...newFields,
                        id,
                    };
                    if (contentTaskListitem) {
                        if (indexTask < 0) {
                            contentTaskListitem.tasks.push(newTask);
                            self.tasksToAdd.push({
                                description: newTask.description,
                                taskFilesId: newTask.taskFile?.map((file: any) => file.id),
                                taskFilesPaths: newTask.taskFile?.map((file: any) => ({
                                    fileId: file.id,
                                    path: file.path ? file.path : "",
                                })),
                                contentGuid: newTask.contentGuid,
                                stopDate: newTask.stopDate,
                                id: newTask.id,
                                implementerId: implementer ? implementer.id : null,
                                isNew: true,
                            });
                        } else {
                            const editedTask = {
                                ...getSnapshot(contentTaskListitem.tasks[indexTask]),
                                ...newFields,
                            };
                            applySnapshot(contentTaskListitem.tasks[indexTask], editedTask);

                            const oldAddedTask = self.tasksToAdd.find((task) => task.id === editedTask.id);
                            if (!oldAddedTask) {
                                self.tasksToAdd.push({
                                    description: editedTask.description,
                                    taskFilesId: editedTask.taskFile?.map((file: any) => file.id),
                                    implementerId: implementer ? implementer.id : null,
                                    contentGuid: editedTask.contentGuid,
                                    stopDate: editedTask.stopDate,
                                    taskFilesPaths: editedTask.taskFile?.map((file: any) => ({
                                        fileId: file.id,
                                        path: file.path ? file.path : "",
                                    })),
                                    id: editedTask.id,
                                    isNew: false,
                                });
                            } else {
                                const isNew = oldAddedTask.isNew;
                                self.tasksToAdd.splice(self.tasksToAdd.indexOf(oldAddedTask), 1);
                                self.tasksToAdd.push({
                                    description: editedTask.description,
                                    taskFilesId: editedTask.taskFile?.map((file: any) => file.id),
                                    taskFilesPaths: editedTask.taskFile?.map((file: any) => ({
                                        fileId: file.id,
                                        path: file.path ? file.path : "",
                                    })),
                                    implementerId: implementer ? implementer.id : null,
                                    contentGuid: editedTask.contentGuid,
                                    stopDate: editedTask.stopDate,
                                    id: editedTask.id,
                                    isNew,
                                });
                            }
                        }
                    }
                });
                return true;
            } else {
                self.notify.error("Необходимо указать Название и Дату!");
                return false;
            }
        }),

        deleteTaskTemp: function (id: string) {
            self.contentTasksList.forEach((key: any) => {
                const task = key.tasks.find((el: any) => el.id === id);
                if (task) {
                    task.toRemove = true;
                }
            });

            const isAdded = self.tasksToAdd.find((task) => task.id === id);

            if (isAdded && isAdded.isNew) {
                self.removedAddedTasks.push(getSnapshot(isAdded));
                self.tasksToAdd.splice(self.tasksToAdd.indexOf(isAdded), 1);
            } else {
                self.tasksToRemove.push({ id });
            }
        },

        returnTaskTemp: function (id: string) {
            self.contentTasksList.forEach((key: any) => {
                const task = key.tasks.find((el: any) => el.id === id);
                if (task) {
                    task.toRemove = false;
                }
            });
            const indexOfRemoved = self.tasksToRemove.findIndex((task) => task.id === id);
            if (indexOfRemoved > -1) {
                self.tasksToRemove.splice(indexOfRemoved, 1);
            }

            const removedAddedTask = self.removedAddedTasks.find((task) => task.id === id);
            if (removedAddedTask) {
                self.removedAddedTasks.splice(self.removedAddedTasks.indexOf(removedAddedTask), 1);
                self.tasksToAdd.push(getSnapshot(removedAddedTask));
            }
        },
        resetContentTasks: function () {
            applySnapshot(self.oldContentTasksList, getSnapshot(self.contentTasksList));
        },
        resetTasksToDefault: function () {
            self.tasksToAdd.clear();
            self.tasksToRemove.clear();
            applySnapshot(self.contentTasksList, getSnapshot(self.oldContentTasksList));
        },
        prepareToApply: function (data: OrderContentTasksListType[]) {
            const result = data.map((item) => {
                const tasks = item.tasks.map((task: OrderContentTasksType) => {
                    return { ...task, toRemove: false };
                });
                return { ...item, tasks };
            });
            return result;
        },
        prepareTasks: function (data: OrderContentTasksListType) {
            const tasks = data.tasks.map((task: OrderContentTasksType) => {
                return { ...task, toRemove: false };
            });

            return { ...data, tasks };
        },
    }))

    .actions((self) => ({
        applyChangesArray: function (result: OrderContentTasksListType[]) {
            result.forEach((item: any) => {
                const hasIndex = self.contentTasksList.findIndex((task) => task.guid === item.guid);
                if (hasIndex >= 0) {
                    applySnapshot(self.contentTasksList[hasIndex], self.prepareTasks(item));
                } else {
                    self.contentTasksList.push(self.prepareTasks(item));
                }
            });
        },
        applyChanges: function (result: OrderContentTasksListType) {
            const hasIndex = self.contentTasksList.findIndex((task) => task.guid === result.guid);
            if (hasIndex >= 0) {
                applySnapshot(self.contentTasksList[hasIndex], self.prepareTasks(result));
            } else {
                self.contentTasksList.push(self.prepareTasks(result));
            }
        },
    }))
    .actions((self) => ({
        load: flow(function* (id: string) {
            try {
                const data: OrderContentTasksListType[] = yield self.transport.get<any>(
                    apiUrls.orders.contentTasks.list(id)
                );

                applySnapshot(self.oldContentTasksList, self.prepareToApply(data));
                applySnapshot(self.contentTasksList, self.prepareToApply(data));
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        addTaskArray: flow(function* (id: string) {
            try {
                const tasks = self.tasksToAdd.map((task) => {
                    if (task.isNew) {
                        return {
                            taskFilesId: task.taskFilesId,
                            implementerId: task.implementerId,
                            contentGuid: task.contentGuid,
                            stopDate: task.stopDate,
                            taskFilePaths: task.taskFilesPaths,
                            description: task.description,
                            id: null,
                        };
                    } else {
                        return {
                            taskFilesId: task.taskFilesId,
                            contentGuid: task.contentGuid,
                            taskFilePaths: task.taskFilesPaths,
                            implementerId: task.implementerId,
                            stopDate: task.stopDate,
                            description: task.description,
                            id: task.id,
                        };
                    }
                });
                const result = yield self.transport.post<any>(apiUrls.orders.contentTasks.addTasks(id), {
                    tasks,
                });
                if (result) {
                    self.applyChangesArray(result);
                    self.tasksToAdd.clear();
                    self.removedAddedTasks.clear();
                }
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        saveAccepted: flow(function* (id: string, contentGuid: string, accepted: boolean, taskId: string) {
            try {
                const { indexTask, contentIndex } = self.findTask(taskId);

                if (indexTask > -1) {
                    const result = yield self.transport.post<any>(
                        apiUrls.orders.contentTasks.saveFlag(id, contentGuid),
                        {
                            value: accepted,
                            type: Constants.contentTaskAccepted,
                            taskId,
                        }
                    );
                    if (result) {
                        self.notify.success(texts.messages.saved);
                        applySnapshot(self.contentTasksList[contentIndex].tasks[indexTask], {
                            ...result,
                            toRemove: false,
                        });
                    }
                    self.resetContentTasks();
                    return true;
                } else {
                    self.notify.error("Необходимо сначала сохранить данные!");
                }
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
        saveCompleted: flow(function* (id: string, contentGuid: string, completed: boolean, taskId: string) {
            try {
                const { indexTask, contentIndex } = self.findTask(taskId);

                if (indexTask > -1) {
                    const result = yield self.transport.post<any>(
                        apiUrls.orders.contentTasks.saveFlag(id, contentGuid),
                        {
                            value: completed,
                            type: Constants.contentTaskCompleted,
                            taskId,
                        }
                    );
                    if (result) {
                        self.notify.success(texts.messages.saved);
                        applySnapshot(self.contentTasksList[contentIndex].tasks[indexTask], {
                            ...result,
                            toRemove: false,
                        });
                    }
                    self.resetContentTasks();
                    return true;
                } else {
                    self.notify.error("Необходимо сначала сохранить данные!");
                }
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        saveConfirmed: flow(function* (id: string, contentGuid: string, confirmed: boolean, taskId: string) {
            try {
                const { indexTask, contentIndex } = self.findTask(taskId);

                if (indexTask > -1) {
                    const result = yield self.transport.post<any>(
                        apiUrls.orders.contentTasks.saveFlag(id, contentGuid),
                        {
                            value: confirmed,
                            type: Constants.contentTaskConfirmed,
                            taskId,
                        }
                    );
                    if (result) {
                        self.notify.success(texts.messages.saved);
                        applySnapshot(self.contentTasksList[contentIndex].tasks[indexTask], {
                            ...result,
                            toRemove: false,
                        });
                    }
                    self.resetContentTasks();
                    return true;
                } else {
                    self.notify.error("Необходимо сначала сохранить данные!");
                }
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        saveCancelled: flow(function* (id: string, contentGuid: string, cancelled: boolean, taskId: string) {
            try {
                const { indexTask, contentIndex } = self.findTask(taskId);

                if (indexTask > -1) {
                    const result = yield self.transport.post<any>(
                        apiUrls.orders.contentTasks.saveFlag(id, contentGuid),
                        {
                            value: cancelled,
                            type: Constants.contentTaskCancelled,
                            taskId,
                        }
                    );
                    if (result) {
                        self.notify.success(texts.messages.saved);
                        applySnapshot(self.contentTasksList[contentIndex].tasks[indexTask], {
                            ...result,
                            toRemove: false,
                        });
                    }
                    self.resetContentTasks();
                    return true;
                } else {
                    self.notify.error("Необходимо сначала сохранить данные!");
                }
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        downloadFiles: flow(function* (id: string, content: any[]) {
            try {
                const result = yield self.transport.post<any>(apiUrls.orders.contentTasks.downloadStructure(id), {
                    content,
                });
                if (result) {
                    const blob: any = yield base64ToBlob(result.content || "", result.mimeType);
                    saveAs(blob, result.name);
                }
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
        downloadVersion: flow(function* (id: string, guid: string, version: number) {
            try {
                const result = yield self.transport.get<any>(
                    apiUrls.orders.contentTasks.downloadFilesVersion(id, guid, version)
                );
                if (result) {
                    const blob: any = yield base64ToBlob(result.content || "", result.mimeType);
                    saveAs(blob, result.name);
                }
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
        removeVersion: flow(function* (id: string, guid: string, version: number) {
            try {
                const result = yield self.transport.delete<any>(apiUrls.orders.contentTasks.removeVersion(id, guid), {
                    data: {
                        version: version,
                    },
                });
                if (result) {
                    self.applyChangesArray(result);
                    self.resetContentTasks();
                }
                self.notify.success(texts.messages.saved);
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        downloadSheets: flow(function* (id: string) {
            try {
                const result = yield self.transport.get<any>(apiUrls.orders.contentTasks.downloadSheets(id));
                if (result) {
                    const blob: any = yield base64ToBlob(result.content || "", result.mimeType);
                    saveAs(blob, result.name);
                }
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        saveDevelopers: flow(function* (id: string, contentGuid: string, developers: any[]) {
            try {
                const indexWrong = developers.findIndex((item) => item.name === "" || item.type === "");
                if (developers.length > 0 && indexWrong === -1) {
                    const result = yield self.transport.post<any>(
                        apiUrls.orders.contentTasks.saveSheet(id, contentGuid),
                        {
                            developers,
                        }
                    );
                    if (result) {
                        self.notify.success(texts.messages.saved);
                        self.applyChanges(result);
                    }
                    self.resetContentTasks();
                    return true;
                } else {
                    self.notify.error("Укажите исполнителей. Все поля должны быть заполнены!");
                }
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        saveWorkFiles: flow(function* (id: string, contentGuid: string, workFiles: any[], resultFile: any) {
            try {
                if (workFiles.length > 0 && resultFile) {
                    const files = workFiles.map((file) => {
                        return {
                            uploadId: file.id,
                            type: Constants.orderFileTypeContentTaskSource,
                        };
                    });
                    files.push({ uploadId: resultFile.id, type: Constants.orderFileTypeContentTaskResult });
                    const result = yield self.transport.post<any>(
                        apiUrls.orders.contentTasks.saveWorkFiles(id, contentGuid),
                        {
                            files,
                        }
                    );
                    if (result) {
                        self.notify.success(texts.messages.saved);

                        self.applyChangesArray(result);
                    }
                    self.resetContentTasks();
                    return true;
                } else {
                    self.notify.error("Необходимо загрузить файл результата и как минимум 1 исходный файл!");
                }
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
        removeTaskArray: flow(function* (id: string) {
            try {
                const taskIds: string[] = self.tasksToRemove.map((item) => item.id);
                yield self.transport.delete<any>(
                    apiUrls.orders.contentTasks.removeTasks(id),

                    {
                        data: { taskIds },
                    }
                );

                self.tasksToRemove.forEach((item: any) => {
                    self.contentTasksList.forEach((el, index) => {
                        el.tasks.forEach((task, taskIndex) => {
                            if (task.id === item.id) {
                                el.tasks.splice(taskIndex, 1);
                            }
                        });
                    });
                });

                self.tasksToRemove.clear();
                self.removedAddedTasks.clear();
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .actions((self) => ({
        uploadFile: flow(function* (file: File) {
            try {
                const model = new FormData();

                model.append("file", file);
                model.append("accept", "*");

                const result: any = yield self.transport.post<any>(apiUrls.application.files.upload, model);
                const { id, previewMimeType, mimeType } = result;
                const fileBase: FileBase = { fileId: id, fileName: file.name, previewMimeType, mimeType };
                return fileBase;
            } catch (er) {
                self.notify.error(er);
                return null;
            }
        }),

        updateData: flow(function* (id: string) {
            try {
                let result = false;
                if (self.tasksToAdd.length > 0) {
                    result = yield self.addTaskArray(id);
                }

                if (self.tasksToRemove.length > 0) {
                    result = yield self.removeTaskArray(id);
                }

                if (result) {
                    self.resetContentTasks();
                }
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }));

export const emptyContentTasks = (): OrderContentTasksSnapshotType => ({
    date: "",
    id: "",
    time: "",
    name: "",
    description: "",
    stopDate: null,
    author: "",
    contentGuid: "",
    accepted: false,
    completed: false,
    confirmed: false,
    cancelled: false,
    canChangeAcceptance: true, //для  срабатывания предупрежедения, что сначала нужно сохранить созданные задачи
    canChangeCompletion: false,
    canChangeConfirmation: false,
    canChangeCancellation: false,
    canEditTask: false,
    taskFile: [],
    toRemove: false,
    orderId: "",
    implementer: null,
});

export const emptyContentList = (): OrderContentTasksListSnapshotType => ({
    tasks: [],
    comments: [],
    developers: [],
    canUploadFiles: false,
    canAddTasks: false,
    canDeleteVersion: false,
    canDownloadStructure: false,
    canGenerateSheet: false,
    versions: [],
    sheetFile: null,
    guid: "",
});

export type OrderContentTasksType = typeof OrderContentTasks.Type;
export type OrderContentTasksSnapshotType = typeof OrderContentTasks.SnapshotType;
export type OrderContentTasksDictionarySnapshotType = typeof OrderContentTasksDictionary.SnapshotType;
export type OrderContentTasksListType = typeof OrderContentTasksList.Type;
export type OrderContentTasksListSnapshotType = typeof OrderContentTasksList.SnapshotType;
export type OrderContentTasksDictionaryType = typeof OrderContentTasksDictionary.Type;
export const initialState = (): OrderContentTasksDictionarySnapshotType => ({
    contentTasksList: [],
    oldContentTasksList: [],
    tasksToAdd: [],
    tasksToRemove: [],
    removedAddedTasks: [],
});
