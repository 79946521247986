import { PrintAnchorInfoListType } from "modules/common/models/print-anchor";
import { Popover, Classes } from "@blueprintjs/core";
import React from "react";
import { PrintAnchors } from "modules/common/components/print-anchors/PrintAnchors";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import "./CollaboraAnchorsHelp.module.scss";

interface AnchorsHelpProps {
    store: PrintAnchorInfoListType;
    style?: React.CSSProperties;
}

export const CollaboraAnchorsHelp = ({ store, style }: AnchorsHelpProps) => {
    return (
        <Popover
            interactionKind="click"
            position="bottom"
            className="collabora-anchors-help"
            content={
                <div className={`${Classes.ELEVATION_1}`}>
                    <PrintAnchors store={store} className="collabora-anchors-list" />
                </div>
            }
        >
            <PlanrButton type="primary" icon="general-list" title="Якори печатной формы" style={style} />
        </Popover>
    );
};
