import { types, applySnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { OrderStatusDictionaryItem, OrderStatusDictionaryItemType } from "./order-status";
import { Constants } from "modules/root/models/constants";

export const OrderStatusDictionary = types
    .compose(
        Transport,
        Notificator,
        types.model({
            statuses: types.array(OrderStatusDictionaryItem),
        })
    )
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: OrderStatusDictionaryItemType[] = yield self.transport.get<any>(
                    apiUrls.orderStatuses.dictionary()
                );
                applySnapshot(self.statuses, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        canEditOrderFinanceFields(statusId: string) {
            const status = self.statuses.find((s) => s.id === statusId);
            const statusTypes = Constants.updatableOrderFinanceStatusTypes;

            return !!status && statusTypes.includes(status.type);
        },

        canAssingPremium(statusId: string) {
            const status = self.statuses.find((s) => s.id === statusId);
            const statusTypes = Constants.assingnablePremiumStatusTypes;

            return !!status && statusTypes.includes(status.type);
        },
    }))
    .views((self) => ({
        get isEmpty() {
            return !self.statuses.length;
        },

        get asMap(): TStringMap<OrderStatusDictionaryItemType> {
            return self.statuses.reduce((result, status) => {
                result[status.id] = status;
                return result;
            }, {} as TStringMap<OrderStatusDictionaryItemType>);
        },

        get isAvailable() {
            return self.statuses.filter((item) => item.canBeApplied === true);
        },
    }))
    .named("OrderStatusDictionary");

export const initialState = (): OrderStatusDictionarySnapshotType => ({
    statuses: [],
});

export type OrderStatusDictionaryType = typeof OrderStatusDictionary.Type;
export type OrderStatusDictionarySnapshotType = typeof OrderStatusDictionary.SnapshotType;
