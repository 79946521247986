import { observer } from "mobx-react";
import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import styles from "./Linker.module.scss";
import portal from "../portal";
import {
    OrderStagesStoreType,
    OrderStageRowType,
    OrderConsignmentRowType,
} from "modules/orders-manage/models/order-stages-store";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { Consignment } from "./Consignment";

export const LinkConsignments = observer(({ stage, store }: LinkConsignmentsProps) => {
    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (result.destination) {
                const guid = result.destination.droppableId === "unlink" ? "" : stage.newGuid;
                const consignmentGuid = result.draggableId;
                const consignment = store.consignmentMap[consignmentGuid];

                consignment.linkToStage(stage.newGuid, guid);
            }
        },
        [stage, store]
    );

    const unlinkConsignment = (consignment: OrderConsignmentRowType) => {
        consignment.linkToStage(stage.newGuid, "");
    };

    const { used, available } = splitByStage(store, stage.newGuid);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className={styles.paymentLinker}>
                <div>
                    <h2>{stage.name}</h2>
                    <Droppable droppableId={stage.newGuid}>
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={`${styles.dropHere} ${snapshot.isDraggingOver ? styles.isDraggingOver : ""}`}
                            >
                                {used.map((consignment, index) => (
                                    <Draggable
                                        key={consignment.newGuid}
                                        draggableId={consignment.newGuid}
                                        index={index}
                                    >
                                        {(draggable, sn) => {
                                            const child = (
                                                <Consignment
                                                    consignment={consignment}
                                                    key={consignment.newGuid}
                                                    draggable={draggable}
                                                >
                                                    <div className={styles.unlink}>
                                                        <GeneralIcon
                                                            type="general-cross-small"
                                                            onClick={() => unlinkConsignment(consignment)}
                                                            title="Отвязать акт"
                                                        />
                                                    </div>
                                                </Consignment>
                                            );
                                            return sn.isDragging ? ReactDOM.createPortal(child, portal) : child;
                                        }}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                <div className={styles.pointer}>Перетащите нужную накладную сюда</div>
                            </div>
                        )}
                    </Droppable>
                </div>

                <div>
                    <h2>Все накладные</h2>
                    <Droppable droppableId="unlink">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={`${styles.dropHere} ${snapshot.isDraggingOver ? styles.isDraggingOver : ""}`}
                            >
                                {available.map((consignment, index) => (
                                    <Draggable
                                        key={consignment.newGuid}
                                        draggableId={consignment.newGuid}
                                        index={index}
                                    >
                                        {(draggable, sn) => {
                                            const child = (
                                                <Consignment
                                                    consignment={consignment}
                                                    key={consignment.newGuid}
                                                    draggable={draggable}
                                                />
                                            );
                                            return sn.isDragging ? ReactDOM.createPortal(child, portal) : child;
                                        }}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </div>
        </DragDropContext>
    );
});

interface LinkConsignmentsProps {
    stage: OrderStageRowType;
    store: OrderStagesStoreType;
}

function splitByStage(self: OrderStagesStoreType, stage: string) {
    const used: OrderConsignmentRowType[] = [];
    const available: OrderConsignmentRowType[] = [];

    self.consignments.forEach((consignment) => {
        if (consignment.removed) {
            return;
        }

        if (consignment.stageGuid === stage) {
            used.push(consignment);
        } else if (!consignment.stageGuid) {
            available.push(consignment);
        }
    });

    return { used, available };
}
