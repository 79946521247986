import { types, applySnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EmployeePositionDictionaryItem, EmployeePositionDictionaryItemType } from "./employee-position";

export const EmployeePositionDictionary = types
    .compose(
        Transport,
        Notificator,
        types.model({
            positions: types.array(EmployeePositionDictionaryItem),
        })
    )
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: EmployeePositionDictionaryItemType[] = yield self.transport.get<any>(
                    apiUrls.employeePositions.dictionary()
                );
                applySnapshot(self.positions, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .views((self) => ({
        get isEmpty() {
            return !self.positions.length;
        },

        get asMap(): TStringMap<EmployeePositionDictionaryItemType> {
            return self.positions.reduce((result, pos) => {
                result[pos.id] = pos;
                return result;
            }, {} as TStringMap<EmployeePositionDictionaryItemType>);
        },
    }))
    .named("EmployeePositionDictionary");

export type EmployeePositionDictionaryType = typeof EmployeePositionDictionary.Type;
export type EmployeePositionDictionarySnapshotType = typeof EmployeePositionDictionary.SnapshotType;
