import { observer } from "mobx-react";
import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Draggable, DraggableProvided, Droppable, DropResult } from "react-beautiful-dnd";
import styles from "./Linker.module.scss";
import portal from "../portal";
import {
    OrderStagesStoreType,
    OrderStageRowType,
    OrderExpertiseRowType,
} from "modules/orders-manage/models/order-stages-store";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { FileLink } from "modules/common/components/files/FileLink";
import { eat } from "modules/common/services/typescript";
import { ExpertiseFileType } from "modules/orders-manage/models/order";

export const LinkExpertises = observer(({ stage, store }: LinkExpertisesProps) => {
    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (result.destination) {
                const guid = result.destination.droppableId === "unlink" ? "" : stage.newGuid;
                const expertiseGuid = result.draggableId;
                const expertise = store.expertiseMap[expertiseGuid];

                expertise.linkToStage(stage.newGuid, guid);
            }
        },
        [stage, store]
    );

    const unlinkExpertise = (expertise: OrderExpertiseRowType) => {
        expertise.linkToStage(stage.newGuid, "");
    };

    const { used, available } = splitByStage(store, stage.newGuid);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className={styles.paymentLinker}>
                <div>
                    <h2>{stage.name}</h2>
                    <Droppable droppableId={stage.newGuid}>
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={`${styles.dropHere} ${snapshot.isDraggingOver ? styles.isDraggingOver : ""}`}
                            >
                                {used.map((expertise, index) => (
                                    <Draggable key={expertise.newGuid} draggableId={expertise.newGuid} index={index}>
                                        {(draggable, sn) => {
                                            const child = (
                                                <Expertise
                                                    expertise={expertise}
                                                    key={expertise.newGuid}
                                                    draggable={draggable}
                                                >
                                                    <div className={styles.unlink}>
                                                        <GeneralIcon
                                                            type="general-cross-small"
                                                            onClick={() => unlinkExpertise(expertise)}
                                                            title="Отвязать акт"
                                                        />
                                                    </div>
                                                </Expertise>
                                            );
                                            return sn.isDragging ? ReactDOM.createPortal(child, portal) : child;
                                        }}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                <div className={styles.pointer}>Перетащите нужное заключение сюда</div>
                            </div>
                        )}
                    </Droppable>
                </div>

                <div>
                    <h2>Все эаключения экспертиз</h2>
                    <Droppable droppableId="unlink">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={`${styles.dropHere} ${snapshot.isDraggingOver ? styles.isDraggingOver : ""}`}
                            >
                                {available.map((expertise, index) => (
                                    <Draggable key={expertise.newGuid} draggableId={expertise.newGuid} index={index}>
                                        {(draggable, sn) => {
                                            const child = (
                                                <Expertise
                                                    expertise={expertise}
                                                    key={expertise.newGuid}
                                                    draggable={draggable}
                                                />
                                            );
                                            return sn.isDragging ? ReactDOM.createPortal(child, portal) : child;
                                        }}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </div>
        </DragDropContext>
    );
});

interface LinkExpertisesProps {
    stage: OrderStageRowType;
    store: OrderStagesStoreType;
}

const Expertise: React.FC<ExpertiseProps> = ({ expertise, draggable, children }) => {
    const draggablePrps = draggable
        ? {
              ref: draggable.innerRef,
              ...draggable.draggableProps,
              ...draggable.dragHandleProps,
          }
        : {};

    return (
        <div {...draggablePrps}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                {expertise.file && (
                    <FileLink<ExpertiseFileType>
                        baseUrl={expertise.baseUrl}
                        file={expertise.file}
                        onRemove={eat}
                        readOnly={true}
                        style={{ flex: "1" }}
                    />
                )}
                {children}
            </div>
        </div>
    );
};

interface ExpertiseProps {
    draggable?: DraggableProvided;
    expertise: OrderExpertiseRowType;
}

function splitByStage(self: OrderStagesStoreType, stage: string) {
    const used: OrderExpertiseRowType[] = [];
    const available: OrderExpertiseRowType[] = [];

    self.expertises.forEach((expertise) => {
        if (expertise.removed) {
            return;
        }

        if (expertise.stageGuid === stage) {
            used.push(expertise);
        } else if (!expertise.stageGuid) {
            available.push(expertise);
        }
    });

    return { used, available };
}
