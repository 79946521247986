import React from "react";
import { fields } from "modules/orders-manage/models/order";
import { Classes, Collapse, Popover } from "@blueprintjs/core";
import { OrderPremiumDetailsType, OrderPremiumBankType } from "modules/orders-manage/models/premium";
import { observer } from "mobx-react";
import { EngineerPremium } from "./EngineerPremium";
import { PremiumDestribution } from "./PremiumDestribution";
import { FormikProps } from "formik";
import { Schema } from "yup";
import { Money } from "modules/common/components/money/Money";
import { Caret } from "modules/common/components/collapse/Caret";
import { ProductionPremiums } from "./ProductionPremium";
import { NonProductionPremiums } from "./NonProductionPremium";
import { stopPropagation } from "modules/common/services/form/values";
import { PREMIUM_BLOCK_NAME } from "./print-view-model";

class PremiumBase extends React.PureComponent<PremiumProps> {
    render() {
        const { bank, store, collapsed, onToggleCollapse, readOnly, canAssing, cantEdit, schema, hide, premiums } =
            this.props;
        const disabled = readOnly || !cantEdit;

        const engineerPremium = hide ? null : (
            <EngineerPremium store={store} readOnly={readOnly} onTouch={this.touchPremiums} canAssing={canAssing} />
        );

        const productionPremiums = hide ? null : (
            <ProductionPremiums
                store={store}
                readOnly={readOnly}
                onFeedbackToggle={this.toggleFeedback}
                onTouch={this.touchPremiums}
                canAssing={canAssing}
            />
        );

        const nonProductionPremiums = hide ? null : (
            <NonProductionPremiums
                store={store}
                readOnly={readOnly}
                onTouch={this.touchPremiums}
                canAssing={canAssing}
            />
        );

        const premiumDestribution = <PremiumDestribution bank={bank} disabled={disabled} schema={schema} />;

        return (
            <div className="order-premium">
                <h1 className="planr-block-header collapser" onClick={onToggleCollapse}>
                    {PREMIUM_BLOCK_NAME}
                    <Caret collapsed={collapsed} />
                </h1>

                <div className="order-total" onClick={onToggleCollapse} style={{ display: "flex" }}>
                    Фонд:&nbsp;
                    <Money className="spendings-money" amount={bank.calculatedSumm} />
                    {bank.correctionDetails && (
                        <span onClick={stopPropagation}>
                            <Popover
                                position="auto"
                                enforceFocus={false}
                                popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                                usePortal={true}
                            >
                                <div className={Classes.TOOLTIP_INDICATOR} style={{ marginLeft: "10px" }}>
                                    Корр:&nbsp;
                                    <Money className="spendings-money" amount={bank.correction} />
                                </div>

                                <div>
                                    <div>
                                        План (без учета премий): <Money amount={bank.correctionDetails.planSpendings} />
                                    </div>
                                    <div>
                                        Факт (без учета премий):{" "}
                                        <Money amount={bank.correctionDetails.actualSpendings} />
                                    </div>
                                </div>
                            </Popover>
                        </span>
                    )}
                    {premiums > 0 && (
                        <div style={{ marginLeft: "10px" }}>
                            Расчет:&nbsp;
                            <Money className="spendings-money" amount={premiums} />
                        </div>
                    )}
                </div>

                <div className="actual-total" onClick={onToggleCollapse}>
                    Всего выплачено:&nbsp;
                    <Money className="spendings-money" amount={store.paidSum} />
                </div>
                <div className={`spendings-total `} onClick={onToggleCollapse}>
                    Остаток по оплатам:&nbsp;
                    <Money className="spendings-money" amount={premiums - store.paidSum} />
                </div>

                <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                    <div className="collapse">
                        <div className="columns ">
                            <div className="flex-column">
                                {premiumDestribution}

                                {!hide && <h3>Расчет премий на текущую дату (План)</h3>}

                                {engineerPremium}
                                {productionPremiums}
                                {nonProductionPremiums}
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }

    toggleFeedback = (userId: string, checked: boolean) => {
        const { formProps, name } = this.props;

        const oldValue = formProps.values[name];

        const newValue = {
            ...oldValue,
            [userId]: checked,
        };

        formProps.setFieldValue(name, newValue);
        formProps.setFieldTouched(name, true);
    };

    touchPremiums = () => {
        const { formProps } = this.props;

        formProps.setFieldValue(fields.premiums, true);
        formProps.setFieldTouched(fields.premiums, true);
    };
}

export const OrderPremium = observer(PremiumBase);

interface PremiumProps {
    onToggleCollapse: () => void;
    collapsed: boolean;
    bank: OrderPremiumBankType;
    store: OrderPremiumDetailsType;
    readOnly: boolean | undefined;
    name: string;
    canAssing: boolean;
    cantEdit: boolean;
    schema: Schema<any>;
    formProps: FormikProps<any>;
    hide?: boolean;
    premiums: number;
}
