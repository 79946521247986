import React from 'react';
import { isElementOfType } from '@blueprintjs/core/lib/esnext/common/utils';
import classnames from 'classnames';
import styles from './IconedRadioGroup.module.scss';

export const IconedRadio: React.FC<IconedRadioProps> = (props) => {
    const { inline, name, value, checked, label, onChange, children } = props;

    const className = classnames({
        'bp3-control bp3-radio': true,
        'bp3-inline': !!inline
    });

    return (
        <label className={className}>
            <input name={name} type="radio" value={value} checked={checked} onChange={() => onChange && onChange(value || '')} />
            <span className={`${styles.indicator} control-indicator`} title={label}>
                {children}
            </span>
        </label>
    );
};

(IconedRadio as any).displayName = 'IconedRadio';

export const IconedRadioGroup: React.FC<IconedRadioGroupProps> = (props) => {
    const getRadioProps = (optionProps: IconedRadioProps) => {
        const { name, onChange, inline } = props;
        const { className, disabled, value, label } = optionProps;

        return {
            label,
            checked: value === props.selectedValue,
            className,
            disabled: disabled || props.disabled,
            inline,
            name,
            onChange,
            value
        };
    }

    const renderChildren = () => {
        return React.Children.map(props.children, (child) => {
            if (isElementOfType(child, IconedRadio)) {
                return React.cloneElement(child, getRadioProps(child.props));
            }
            else {
                return child;
            }
        });
    }

    return (
        <div className={`${styles.radio} ${props.className || ''}`}>
            {renderChildren()}
        </div>
    );
};

interface IconedRadioGroupProps {
    name: string;
    onChange?: (value: string) => void;
    selectedValue?: string;
    inline?: boolean;
    disabled?: boolean;
    className?: string;
}

interface IconedRadioProps {
    label: string;
    name?: string;
    value?: string;
    checked?: boolean;
    disabled?: boolean;
    className?: string;
    inline?: boolean;
    onChange?: (value: string) => void;
}
