import { observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Page } from "modules/common/components/page/Page";
import styles from "./BotRegistration.module.scss";
import { SessionType } from "modules/session/auth/models/session";

interface Params {
    token: string;
}

interface BotRegistrationProps {
    store: SessionType;
}

export const BotRegistration: React.FC<BotRegistrationProps & RouteComponentProps<Params>> = observer(
    ({ match, store }) => {
        const userData = match?.params.token ?? "";
        const token = userData ? atob(userData) : "";
        const [error, setError] = useState<string>();
        useEffect(() => {
            const register = async () => {
                setError(await store.tgRegister(token));
            };

            register();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <Page className={styles.page}>
                {error === "" && (
                    <div>
                        <h3>Вы успешно зарегистрировались!</h3>
                        <br />
                        <Link to="/">На главную</Link>
                    </div>
                )}

                {error === undefined && <h3>Идет регистрация, пожалуйста, подождите...</h3>}

                {error && <h3>{error}</h3>}
            </Page>
        );
    }
);
