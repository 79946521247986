import React, { useCallback } from "react";
import { Dialog } from "@blueprintjs/core";

export const DetailsPopup = ({
  children,
  onCancel,
  title,
  width,
  onOpened,
  style,
}: React.PropsWithChildren<DetailsPopupProps>) => {
  const onClose = useCallback(() => onCancel(), [onCancel]);

  return (
    <Dialog
      icon="edit"
      title={title}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      isOpen={true}
      onClose={onClose}
      style={style ? style : { width: `${width || 600}px`, zIndex: 1000 }}
      backdropClassName="standard"
      onOpened={onOpened}
    >
      {children}
    </Dialog>
  );
};

interface DetailsPopupProps {
  onCancel: () => void;
  title: React.ReactNode;
  width?: number;
  onOpened?: () => void;
  style?: React.CSSProperties;
}
