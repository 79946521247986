import * as React from 'react';

const styles = (columned?: boolean): React.CSSProperties => ({
    display: 'flex',
    flex: '1 1',
    overflow: 'hidden',
    position: 'relative',
    flexDirection: columned ? 'column' : 'row'
});

export const Block = ({ children, columned, ...rest }: BlockProps) => (
    <div {...rest} style={styles(columned)}>{children}</div>
);

interface BlockProps extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
    columned?: boolean;
};
