import React from "react";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

interface CaretProps {
    collapsed: boolean;
    className?: string;
    style?: React.CSSProperties;
}

export const Caret = ({ collapsed, className, style }: CaretProps) => (
    <GeneralIcon
        type={collapsed ? "general-chevron-down" : "general-chevron-up"}
        className={`main-menu-item_icon collapse-caret ${className}`}
        style={style ? style : { zIndex: 1 }}
    />
);
