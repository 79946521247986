import React from "react";
import { UnregisterCallback, Location } from "history";
import { RouteComponentProps, withRouter } from "react-router";
import { intent, timeout, icon } from "modules/root/components/app/Notifications";
import { Notificator } from "modules/root/components/app/toaster";

class DenyNavigation extends React.Component<DenyNavigationProps & RouteComponentProps> {
    private release: UnregisterCallback | null = null;

    componentDidMount() {
        const { history, when } = this.props;

        this.release = when ? history.block(this.blocker) : null;
        this.registerGlobal();
    }

    componentDidUpdate() {
        const { history, when } = this.props;

        if (!when && this.release) {
            this.release();
            this.release = null;
        }

        if (when) {
            this.release && this.release();
            this.release = history.block(this.blocker);
        }

        this.registerGlobal();
    }

    registerGlobal() {
        if (this.props.when) {
            window.onbeforeunload = stop;
        } else {
            this.unregisterGlobal();
        }
    }

    unregisterGlobal() {
        window.onbeforeunload = null;
    }

    componentWillUnmount() {
        this.release && this.release();
        this.unregisterGlobal();
    }

    render() {
        return null;
    }

    blocker = (loc: Location): false | void => {
        const { message, skip } = this.props;
        const ignore = skip ? skip(loc) : false;

        if (!ignore) {
            const type = "error";

            Notificator.show({
                message,
                icon: icon(type),
                intent: intent(type),
                timeout: timeout(type),
            });

            return false;
        }
    };
}

export default withRouter(DenyNavigation);

interface DenyNavigationProps {
    when: boolean;
    message: string;
    skip?: (tx: Location) => boolean;
}

const stop = () => true;
