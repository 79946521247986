import { types, applySnapshot } from "mobx-state-tree";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { OrderDictionaryItem } from "modules/orders-manage/models/order-dictionary";
import { noFractionFormatter } from "modules/common/components/money/Money";

const OwnSpendingHours = types
    .model({
        order: OrderDictionaryItem,
        hours: types.number,
        money: types.number,
    })
    .views((self) => ({
        get formattedHours() {
            return noFractionFormatter.format(self.hours);
        },
    }))
    .named("OwnSpendingHours");

export const OwnSpendingsWidgetStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            items: types.array(OwnSpendingHours),
        })
    )
    .views((self) => ({
        get isEmpty() {
            return self.items.length === 0;
        },
    }))
    .actions((self) => ({
        load: flow(function* (employerId = "", withSpendings = false) {
            try {
                const data = yield self.transport.get<any>(apiUrls.employee.ownSpendings(employerId), {
                    params: {
                        withSpendings,
                    },
                });
                applySnapshot(self.items, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        clear() {
            applySnapshot(self.items, []);
        },
    }))
    .named("OwnSpendingsWidgetStore");

export type OwnSpendingHoursType = typeof OwnSpendingHours.Type;
export type OwnSpendingsWidgetStoreType = typeof OwnSpendingsWidgetStore.Type;

export const initialState = (): typeof OwnSpendingsWidgetStore.SnapshotType => ({
    items: [],
});
