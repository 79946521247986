import React from "react";
import { Page } from "modules/common/components/page/Page";
import styles from "./ExpensesPage.module.scss";
import { Tabs, Tab } from "@blueprintjs/core";
import func from "../functionalities";
import { can } from "modules/session/auth/access";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { routes } from "modules/common/routes";
import { ImportSpendingsPage } from "../import/components/ImportSpendingsPage";
import { ImportSpendingsStoreType } from "../import/models/import-store";
import { SessionType } from "modules/session/auth/models/session";
import { observer } from "mobx-react";
import { SpendingsListPage } from "../summary/components/SpendingsListPage";
import { SpendingsListType } from "../summary/models/spending-list";
import { OverheadSpendingsType } from "../overhead/models/overhead-spendings";
import { OverheadSpendings } from "../overhead/components/OverheadSpendings";

export const ExpensesPageTabs = {
    import: "import",
    overhead: "overhead",
};

export const ExpensesPage = withRouter(
    observer(
        class Base extends React.PureComponent<ExpensesPageProps & RouteComponentProps> {
            render() {
                const { session, location, importStore, spendingsStore, overheadStore } = this.props;
                const canImport = can(func.SPENDINGS_IMPORT, session.access);
                const selectedTabId = this.getTabId(location.pathname);

                return (
                    <Page>
                        <Tabs
                            className={`${styles.tabs} planr-tabs-navigation`}
                            id="ExpensesPage"
                            onChange={this.changeTabId}
                            selectedTabId={selectedTabId}
                            renderActiveTabPanelOnly={true}
                        >
                            <Tab
                                id={routes.expenses.overhead.path}
                                title={"Накладные расходы"}
                                panel={<OverheadSpendings store={overheadStore} session={session} />}
                            />

                            {canImport && (
                                <Tab
                                    id={routes.expenses.import}
                                    title={"Импорт"}
                                    panel={<ImportSpendingsPage store={importStore} />}
                                />
                            )}

                            <Tab
                                id={routes.expenses.path}
                                title={"Расходы"}
                                panel={<SpendingsListPage store={spendingsStore} session={session} />}
                            />
                        </Tabs>
                    </Page>
                );
            }

            changeTabId = (tabId: string) => {
                const { history } = this.props;
                history.push(tabId);
            };

            getTabId = (pathname: string) => {
                if (pathname.includes(ExpensesPageTabs.import)) {
                    return routes.expenses.import;
                }

                if (pathname.includes(ExpensesPageTabs.overhead)) {
                    return routes.expenses.overhead.path;
                }

                return routes.expenses.path;
            };
        }
    )
);

interface ExpensesPageProps {
    session: SessionType;
    importStore: ImportSpendingsStoreType;
    spendingsStore: SpendingsListType;
    overheadStore: OverheadSpendingsType;
}
