import React from "react";
import { agGridLocale } from "modules/root/services/locale";
import { DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import {
  GridReadyEvent,
  SelectionChangedEvent,
  RowDoubleClickedEvent,
  GetContextMenuItemsParams,
  GridApi,
  ColDef,
  RowClickedEvent,
} from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import styles from "./List.module.scss";
import { Pager } from "modules/common/components/table/Pager";
import { Actions } from "./Actons";
import { MailDetals } from "./Details";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { SaveOrderMailValue, OrderMailSnapshotType, convert } from "modules/orders-manage/models/order-mails";
import { agGridEntityContextMenu } from "modules/common/components/table/EntityContextMenu";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { routes } from "modules/common/routes";
import { ProjectAssigner } from "modules/common/components/orders/ProjectAssigner";
import { Filters } from "./Filters";
import { StoreContract } from "./types";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { FileLink } from "modules/common/components/files/FileLink";
import { Constants } from "modules/root/models/constants";
import { OrderTabs } from "modules/orders-manage/details/OrderDetailsTabsPage";
import { Link } from "react-router-dom";
import { StringifiedOrderLinkType } from "modules/orders-manage/models/order-link";
import { orderTitle } from "modules/orders-manage/models/order";
import { eat } from "modules/common/services/typescript";

const removeLabel = (what: OrderMailSnapshotType[]) => {
  if (!what) {
    return "";
  }

  const prefix = what.length > 1 ? "сообщения" : "сообщение";
  const numbers = what.map((m) => m.number);

  return `${prefix} ${numbers.join(", ")}`;
};

const modules = [MasterDetailModule, MenuModule, ...defaultModules];

export class MailsList extends React.PureComponent<MailsListProps, MailsListState> {
  private options = DefaultAgGridOptions(true);
  private gridApi: GridApi | null = null;

  constructor(props: MailsListProps) {
    super(props);

    const { columns, canManage } = props;

    this.state = { selection: [], editable: null, assigment: [], colDefs: columns(canManage), showTools: false };
  }

  componentDidUpdate(prevProps: MailsListProps) {
    if (this.props.canManage !== prevProps.canManage) {
      this.setState({ colDefs: this.props.columns(this.props.canManage) });
    }

    if (this.props.current !== prevProps.current) {
      if (this.gridApi) {
        const row = this.props.current ? this.gridApi.getRowNode(this.getRowNodeId(this.props.current)) : null;

        if (row) {
          row.setSelected(true, true);
        } else {
          this.gridApi.deselectAll();
        }
      }
    }

    // select single mail
    if (this.props.data !== prevProps.data && this.props.data.length === 1) {
      this.props.onMailSelected(this.props.data[0]);
    }
  }

  render() {
    const store = this.props;
    const { canManage, data, rowBuffer, hideProject } = this.props;
    const { editable, assigment, colDefs, selection, showTools } = this.state;

    return (
      <div className={`mails ${styles.list}`}>
        {!!editable && (
          <MailDetals
            mail={editable}
            onClose={this.closeDialog}
            orders={store.orders}
            employee={store.employee}
            title="Сообщение"
            onRemove={this.removeSingleMail}
            onSubmit={this.onSubmit}
            upload={store.upload}
            baseUrl={store.baseUrl}
            hideProject={hideProject}
          />
        )}

        {canManage && store.orders && assigment.length > 0 && (
          <ProjectAssigner onClose={this.cancelAssignment} orders={store.orders} onAssign={this.assignMails} />
        )}

        <RemoveConfirmation<OrderMailSnapshotType[]>
          observation={data}
          what={removeLabel}
          onConfirmed={store.remove}
          render={({ confirmRemoving }) => {
            const getContextMenuItems = canManage
              ? ({ node }: GetContextMenuItemsParams) =>
                  agGridEntityContextMenu({
                    onChange: () => this.openDialog(node.data),
                    onRemove: () => confirmRemoving([node.data]),
                  })
              : undefined;

            return (
              <>
                <div className={styles.mobileTools}>
                  {canManage && (
                    <Actions
                      mails={this.state.selection}
                      onEdit={this.openDialog}
                      onRemove={confirmRemoving}
                      onAssign={!hideProject ? this.startAssignment : undefined}
                      onAdd={this.openNewDialog}
                    />
                  )}
                  <PlanrButton
                    type={showTools ? "blueish" : "lightblueish"}
                    icon="general-dots-more"
                    round
                    onClick={this.toggleShowTools}
                  />
                </div>

                {showTools && (
                  <div className={styles.dialogTools}>
                    <Filters
                      filtersStatus={store.filtersStatus}
                      filtersType={store.filtersType}
                      query={store.query}
                      setFiltersType={store.setFiltersType}
                      setQuery={store.setQuery}
                      toggleFiltersStatus={store.toggleFiltersStatus}
                      hideProject={hideProject}
                    >
                      <div className={styles.actions}>
                        <PlanrButton
                          type="graybtn"
                          icon={"general-mail"}
                          onClick={this.batchDownload}
                          round={true}
                          title="Скачать файлы"
                          size="small"
                        />
                        <PlanrButton
                          type="graybtn"
                          icon={"general-print"}
                          onClick={this.batchPrint}
                          round={true}
                          title="Печать"
                          size="small"
                        />
                      </div>
                    </Filters>
                  </div>
                )}
                <div className={styles.tools}>
                  {canManage && (
                    <Actions
                      mails={this.state.selection}
                      onEdit={this.openDialog}
                      onRemove={confirmRemoving}
                      onAssign={!hideProject ? this.startAssignment : undefined}
                      onAdd={this.openNewDialog}
                    />
                  )}

                  <Filters
                    filtersStatus={store.filtersStatus}
                    filtersType={store.filtersType}
                    query={store.query}
                    setFiltersType={store.setFiltersType}
                    setQuery={store.setQuery}
                    toggleFiltersStatus={store.toggleFiltersStatus}
                    hideProject={hideProject}
                  >
                    <div className={styles.actions}>
                      <PlanrButton
                        type="graybtn"
                        icon={"general-mail"}
                        onClick={this.batchDownload}
                        round={true}
                        title="Скачать файлы"
                        size="small"
                      />
                      <PlanrButton
                        type="graybtn"
                        icon={"general-print"}
                        onClick={this.batchPrint}
                        round={true}
                        title="Печать"
                        size="small"
                      />
                    </div>
                  </Filters>
                </div>

                <GridTheme bordered={true} className={styles.Mailtable}>
                  <AgGridReact
                    rowStyle={{
                      border: "none",
                      borderBottom: "1px solid #EDF0F2",
                      fontFamily: "GothamPro, Arial, sans-serif",
                      color: "#00273D",
                      fontSize: "12px",
                    }}
                    rowSelection="multiple"
                    enableCellTextSelection={true}
                    columnDefs={colDefs}
                    rowData={data}
                    localeText={agGridLocale}
                    getRowNodeId={this.getRowNodeId}
                    gridOptions={this.options}
                    immutableData={true}
                    detailCellRenderer="detailCellRenderer"
                    modules={modules}
                    masterDetail={true}
                    detailRowHeight={65}
                    groupDefaultExpanded={0}
                    context={this}
                    suppressCellSelection={true}
                    onGridReady={this.onGridReady}
                    onSortChanged={store.resorted}
                    onSelectionChanged={this.onSelectionChanged}
                    onRowDoubleClicked={this.onRowDoubleClicked}
                    getContextMenuItems={getContextMenuItems}
                    onRowClicked={this.onRowClicked}
                    rowBuffer={rowBuffer}
                  />
                </GridTheme>

                <div className={styles.cardList}>
                  {data.map((row) => {
                    let color = "#36acd7";
                    if (row.type === Constants.incomingOrderMailType) {
                      color = "#ff4949";
                    }
                    const selected = selection.find((s) => s.id === row.id);
                    const order = row.order as StringifiedOrderLinkType;

                    return (
                      <div key={row.id}>
                        {/* <div className={selected ? styles.cardSelected : styles.card}> */}
                        <div className={styles.card}>
                          <div className={styles.line} style={{ backgroundColor: color }}></div>
                          <div className={styles.mid}>
                            <div className={styles.row}>
                              <div className={styles.rowItem}>{row.day}</div>
                            </div>
                            <div className={styles.row}>{row.number}</div>
                            <div className={styles.label}>Тема:</div>
                            <div className={styles.row}>{row.name}</div>
                            {row.counterpart && (
                              <>
                                <div className={styles.label}>{`${
                                  row.type === Constants.incomingOrderMailType ? "От кого" : "Кому"
                                }:`}</div>
                                <div className={styles.row}>{row.counterpart}</div>
                              </>
                            )}
                            {order && (
                              <div className={styles.row}>
                                <div className={styles.rowItem}>
                                  <Link to={routes.orders.details(order.id, OrderTabs.mails)}>
                                    {orderTitle({ fullInventoryNumber: order.inventoryNumber, name: order.name })}
                                  </Link>
                                </div>
                              </div>
                            )}
                            {row.assignee && (
                              <>
                                <div className={styles.label}>Исполнитель:</div>
                                <div className={styles.row}>{row.assignee.name}</div>
                              </>
                            )}
                            {row.accepted && !row.completed && (
                              <div className={styles.row}>
                                <div
                                  className={"color-green"}
                                  style={{
                                    display: "flex",
                                    height: "25px",
                                    width: "100px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "12px",
                                  }}
                                >
                                  {Constants.acceptedOrderMailStatus}
                                </div>
                              </div>
                            )}
                            {row.completed && !row.expired && (
                              <div className={styles.row}>
                                <div
                                  className={"color-orange"}
                                  style={{
                                    display: "flex",
                                    height: "25px",
                                    width: "100px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "12px",
                                  }}
                                >
                                  {Constants.completedOrderMailStatus}
                                </div>
                              </div>
                            )}
                            {row.expired && !row.accepted && (
                              <div className={styles.row}>
                                <div
                                  className={"color-red"}
                                  style={{
                                    display: "flex",
                                    height: "25px",
                                    width: "100px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "12px",
                                  }}
                                >
                                  {Constants.expiredOrderMailStatus}
                                </div>
                              </div>
                            )}
                            <div className={styles.fileRow}>
                              {row.documents.map((file, index) => (
                                <FileLink
                                  baseUrl={store.baseUrl}
                                  file={{ fileId: file.id, fileName: `Файл №${index + 1}` }}
                                  key={file.id}
                                  onRemove={eat}
                                  readOnly
                                  style={{ width: "240px" }}
                                />
                              ))}
                            </div>
                            <div className={styles.row}>
                              <div
                                className={styles.rowItem}
                                //style={{ wordBreak: "normal", lineHeight: "unset", whiteSpace: "pre-line" }}
                              >
                                {/* {row.belonging} */}
                              </div>
                            </div>
                          </div>
                          <div className={styles.edit}>
                            <PlanrButton
                              type="lightblueish"
                              icon="general-edit"
                              size="small"
                              onClick={() => {
                                this.props.canManage && this.openDialog(row);
                              }}
                            />
                            <PlanrButton
                              type="neutral"
                              icon={selected ? "general-checkbox" : "general-zero-checkbox"}
                              size="small"
                              style={{ marginLeft: "0px", marginTop: "5px" }}
                              onClick={() => {
                                let newArr = [...selection];
                                const index = newArr.findIndex((s) => s.id === row.id);
                                if (index > -1) {
                                  newArr.splice(index, 1);
                                } else {
                                  newArr.push(row);
                                }
                                this.setState({ selection: [...newArr] });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {store.pager && (
                    <Pager
                      itemsPerPage={store.pager.pageSize}
                      page={store.pager.page}
                      totalItems={store.pager.totalCount}
                      pageSelected={store.setPage}
                      small={true}
                      hideIfEmpty={true}
                      pagesNumber={7}
                    />
                  )}
                </div>
              </>
            );
          }}
        />
        <div className={styles.pages}>
          {store.pager && (
            <Pager
              itemsPerPage={store.pager.pageSize}
              page={store.pager.page}
              totalItems={store.pager.totalCount}
              pageSelected={store.setPage}
              small={true}
              hideIfEmpty={true}
            />
          )}
        </div>
      </div>
    );
  }

  removeSingleMail = async (mail: SaveOrderMailValue) => {
    const success = await this.props.remove([mail]);
    success && this.closeDialog();
  };

  assignMails = async (orderId: string) => {
    const { assign } = this.props;

    if (!assign) {
      return;
    }

    const success = await assign(this.state.assigment, orderId);

    if (success) {
      this.cancelAssignment();
      this.gridApi && this.gridApi.refreshCells();
    }
  };

  cancelAssignment = () => this.startAssignment([]);

  startAssignment = (mails: OrderMailSnapshotType[]) => this.setState({ assigment: mails });

  openNewDialog = () => this.openDialog(this.props.mailFactory());

  onSubmit = async (mail: SaveOrderMailValue) => {
    const success = await this.props.save(mail);

    if (success) {
      this.closeDialog();

      const refresh = this.props.data.find((m) => m.id === mail.id);
      if (refresh) {
        this.props.onMailSelected(refresh);
      }
    }

    return success;
  };

  onRowClicked = ({ data }: RowClickedEvent) => {
    const mail: OrderMailSnapshotType = data;
    this.props.onMailSelected(mail);
  };

  onRowDoubleClicked = ({ data }: RowDoubleClickedEvent) => this.props.canManage && this.openDialog(data);

  openDialog = (mail: OrderMailSnapshotType) => {
    this.setState({ editable: convert(mail) });
    if (this.gridApi) {
      const node = this.gridApi.getRowNode(this.getRowNodeId(mail));
      node && node.setSelected(true, true);
    }
  };

  closeDialog = () => this.setState({ editable: null });

  onSelectionChanged = (e: SelectionChangedEvent) => {
    const selection = e.api.getSelectedNodes().map((n) => n.data);
    this.setState({ selection });
  };

  getRowNodeId = (node: OrderMailSnapshotType) => node.id;

  onGridReady = async ({ api }: GridReadyEvent) => {
    const { sorter } = this.props;
    setTimeout(() => api.setSortModel(sorter.agGridSortModel));
    this.gridApi = api;
  };

  batchPrint = () => {
    const { selection } = this.state;
    if (selection.length) {
      this.props.printMails(selection.map((m) => m.id));
    }
  };

  batchDownload = () => {
    const { selection } = this.state;
    if (selection.length) {
      this.props.downloadMails(selection.map((m) => m.id));
    }
  };
  toggleShowTools = () => this.setState({ showTools: !this.state.showTools });
}

interface MailsListProps extends StoreContract {
  columns: (canManage: boolean) => ColDef[];
  rowBuffer: number;
  canManage: boolean;
  onMailSelected: (mail: OrderMailSnapshotType) => void;
  current: OrderMailSnapshotType | null;
  hideProject: boolean;
}

interface MailsListState {
  selection: OrderMailSnapshotType[];
  editable: SaveOrderMailValue | null;
  assigment: OrderMailSnapshotType[];
  colDefs: ColDef[];
  showTools: boolean;
}
