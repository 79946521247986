import React, { useCallback } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import styles from "./DepartmentDetails.module.scss";
import DepartmentSchema from "./validation";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { InputGroup, TextArea, Classes } from "@blueprintjs/core";
import { useTextAreaAutoHeight } from "modules/common/services/form/textarea";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { texts } from "modules/common/texts";
import { Prompt } from "react-router";
import { toString } from "modules/common/services/strings";
import { onlyDigitsInputKeyPress } from "modules/common/services/form/values";
import { DepartmentType, fields } from "../../models/department";

const digits = onlyDigitsInputKeyPress();
const schema = DepartmentSchema();

export const DepartmentDetails: React.FC<DepartmentDetailsProps> = observer((props) => {
    const { department, canChange, onSaved, onRemoved } = props;
    const textAreaRef = useTextAreaAutoHeight();

    const onSubmit = useCallback(
        async (values: FormValues, form: FormikHelpers<FormValues>) => {
            if (!canChange) {
                return;
            }

            const succsessfull = await department.save(values);
            form.setSubmitting(false);

            if (succsessfull) {
                form.resetForm();
                onSaved();
            }
        },
        [canChange, onSaved, department]
    );

    return (
        <div className={styles.details}>
            <Formik<FormValues>
                initialValues={getFormValues(department)}
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={onSubmit}
            >
                {(formProps) => {
                    const onRemove = async () => {
                        formProps.setSubmitting(true);
                        const success = await department.delete();
                        formProps.setSubmitting(false);
                        success && onRemoved();
                    };

                    return (
                        <Form autoComplete="off">
                            <Prompt when={formProps.dirty && canChange} message={texts.messages.leaveConfiramtion} />

                            <div className={Classes.DIALOG_BODY}>
                                <StandardFormInput
                                    name={fields.order}
                                    schema={schema}
                                    small={true}
                                    className="planr-form-input"
                                >
                                    {({ field }) => (
                                        <InputGroup
                                            id={field.name}
                                            className="planr-default-input"
                                            {...field}
                                            type="text"
                                            autoComplete="off"
                                            data-lpignore="true"
                                            onKeyPress={digits}
                                            disabled={!canChange}
                                        />
                                    )}
                                </StandardFormInput>

                                <StandardFormInput
                                    name={fields.name}
                                    schema={schema}
                                    small={true}
                                    className="planr-form-input"
                                >
                                    {({ field }) => (
                                        <InputGroup
                                            className="planr-default-input"
                                            id={field.name}
                                            {...field}
                                            autoComplete="off"
                                            data-lpignore="true"
                                            disabled={!canChange}
                                        />
                                    )}
                                </StandardFormInput>

                                <StandardFormInput
                                    name={fields.description}
                                    schema={schema}
                                    small={true}
                                    className="planr-form-input"
                                >
                                    {({ field }) => (
                                        <div className={`${Classes.INPUT_GROUP}  planr-default-input`}>
                                            <TextArea
                                                id={field.name}
                                                {...field}
                                                growVertically={true}
                                                className={Classes.FILL}
                                                inputRef={textAreaRef}
                                                disabled={!canChange}
                                            />
                                        </div>
                                    )}
                                </StandardFormInput>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                {canChange && (
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        <StandardFormButtons
                                            {...formProps}
                                            isRemoved={false}
                                            what={`отдел ${department.name}`}
                                            isNewlyCreated={department.isNewlyCreated}
                                            onRemove={onRemove}
                                        />
                                    </div>
                                )}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
});

interface DepartmentDetailsProps {
    department: DepartmentType;
    canChange: boolean;
    onSaved: () => void;
    onRemoved: () => void;
}

function getFormValues(department: DepartmentType) {
    return {
        ...getSnapshot(department),
        order: toString(department.order),
    };
}

type FormValues = ReturnType<typeof getFormValues>;
