import { LoggedInProps } from "modules/common/components/routing/AnonymousRoute";
import { ProtectedRoute } from "modules/common/components/routing/ProtectedRoute";
import { routes } from "modules/common/routes";
import { MaterialValuesStoreType } from "modules/dictionaries/inventory/models/material-value-store";
import { can, canAny } from "modules/session/auth/access";
import { SessionType } from "modules/session/auth/models/session";
import func from "modules/spending/functionalities";
import React, { useCallback } from "react";
import SpendingTabsPage from "./components/SpendingTabsPage";
import { DepartmentsPage } from "./departments/components/DepartmentsPage";
import EmployeePage from "./employee/components/EmployeePage";
import { EmployeeStoreType } from "./employee/models/employee-store";
import { SchedulePage } from "./schedule/components/SchedulePage";
import { ScheduleStoreType } from "./schedule/models/schedule-store";
import { TimesheetPage } from "./timesheet/components/TimesheetPage";
import { TimesheetStoreType } from "./timesheet/models/timesheet-store";
import { UnitPage } from "./unit/components/UnitPage";
import { UnitStoreType } from "./unit/models/unit-store";
import { WorkloadPage } from "./workload/components/WorkloadPage";
import { WorkloadStoreType } from "./workload/models/workload-store";

export const Routes = ({
    loggedIn,
    timesheet,
    session,
    schedule,
    unit,
    employee,
    workload,
    materials,
}: RoutesProps) => {
    const setUserFilter = useCallback(
        (value: string) => {
            timesheet.setQuery(value);
            workload.setQuery(value);
            unit.setQuery(value);
            schedule.setQuery(value);
        },
        [schedule, workload, unit, timesheet]
    );
    return (
        <ProtectedRoute
            path={routes.spending.path}
            loggedIn={loggedIn}
            render={(props) => (
                <SpendingTabsPage
                    {...props}
                    timesheetPanel={
                        canAny(
                            [func.TIMESHEET_EDIT, func.TIMESHEET_EDIT_DEPARTMENT, func.TIMESHEET_EDIT_USER],
                            session.access
                        ) ? (
                            <TimesheetPage
                                store={timesheet}
                                schedule={schedule}
                                session={session}
                                setUserFilter={setUserFilter}
                            />
                        ) : null
                    }
                    workloadPanel={
                        can(func.WORKLOAD_EDIT, session.access) ? (
                            <WorkloadPage
                                store={workload}
                                schedule={schedule}
                                session={session}
                                setUserFilter={setUserFilter}
                            />
                        ) : null
                    }
                    unitPanel={
                        can(func.WORKOUT_UNIT, session.access) ? (
                            <UnitPage store={unit} setUserFilter={setUserFilter} />
                        ) : null
                    }
                    schedulePanel={
                        can(func.SCHEDULE_READ, session.access) ? (
                            <SchedulePage
                                store={schedule}
                                timesheet={timesheet}
                                session={session}
                                setUserFilter={setUserFilter}
                            />
                        ) : null
                    }
                    departmentsPanel={
                        can(func.DEPARTMENT_READ, session.access) ? (
                            <DepartmentsPage access={session.access} baseUrl={session.baseUrl} />
                        ) : null
                    }
                    employeePanel={
                        can(func.EMPLOYER_READ, session.access) ? (
                            <EmployeePage
                                {...props}
                                baseUrl={session.baseUrl}
                                store={employee}
                                access={session.access}
                                materials={materials}
                                session={session}
                            />
                        ) : null
                    }
                />
            )}
        />
    );
};

interface RoutesProps extends LoggedInProps {
    schedule: ScheduleStoreType;
    timesheet: TimesheetStoreType;
    workload: WorkloadStoreType;
    unit: UnitStoreType;
    session: SessionType;
    employee: EmployeeStoreType;
    materials: MaterialValuesStoreType;
}
