import { Classes, InputGroup } from "@blueprintjs/core";
import { DatePicker } from "modules/common/components/form/DatePicker";

import { StandardFormInput } from "modules/common/components/form/StandardFormInput";

import React from "react";
import { childFields, EmployerType } from "../../models/employer";
import { ChildSchema } from "./validation";
import { formatDate } from "modules/common/services/formatting/date";
import { PlanrButton } from "modules/common/components/planr/button/Button";

class ChildDetails extends React.Component<ChildDetailsProps, ChildDetailsState> {
    constructor(props: ChildDetailsProps) {
        super(props);
        this.state = {
            guid: "",
            name: "",
            birthday: null,
        };
    }
    componentDidMount() {
        if (this.props.employer.children) {
            const child = this.props.employer.children.filter((c) => c.guid === this.props.childId)[0];
            if (child) {
                this.setState({ name: child.name, birthday: child.birthdayAsDate, guid: child.guid });
            }
        }
    }
    render() {
        const { hideDialog, employer, canChange } = this.props;
        const { name, birthday, guid } = this.state;
        const schema = ChildSchema();
        return (
            <div>
                <div className={Classes.DIALOG_BODY}>
                    <StandardFormInput
                        name={childFields.birthday}
                        schema={schema}
                        small={true}
                        className="planr-form-input"
                    >
                        {() => {
                            return (
                                <div className={`${Classes.INPUT_GROUP} `}>
                                    <DatePicker
                                        clasName="planr-default-input"
                                        value={this.state.birthday}
                                        onChange={(date) => {
                                            this.setState({ birthday: date });
                                        }}
                                    />
                                </div>
                            );
                        }}
                    </StandardFormInput>
                    <StandardFormInput
                        name={childFields.name}
                        schema={schema}
                        small={true}
                        className="planr-form-input"
                    >
                        {() => (
                            <InputGroup
                                value={this.state.name}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    this.setState({ name: e.currentTarget.value });
                                }}
                                className="planr-default-input"
                                autoComplete="off"
                                data-lpignore="true"
                                disabled={!canChange}
                            />
                        )}
                    </StandardFormInput>
                </div>

                <div className={Classes.DIALOG_FOOTER} style={{ display: "flex" }}>
                    <PlanrButton
                        type="greenish"
                        onClick={() => {
                            employer.addChild(guid, name, formatDate(birthday));
                            hideDialog();
                        }}
                    >
                        Сохранить
                    </PlanrButton>
                    <PlanrButton
                        style={{ marginLeft: "10px" }}
                        type="graybtn"
                        onClick={() => {
                            hideDialog();
                        }}
                    >
                        Отмена
                    </PlanrButton>
                </div>
            </div>
        );
    }
}

interface ChildDetailsProps {
    childId: string;
    canChange: boolean;
    employer: EmployerType;
    hideDialog: () => void;
}

interface ChildDetailsState {
    name: string;
    birthday: Date | null;
    guid: string;
}

export default ChildDetails;
