import React from "react";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Block } from "modules/common/components/page/Block";
import { Route, withRouter, RouteComponentProps, matchPath } from "react-router";
import { routes } from "modules/common/routes";
import { UnregisterCallback } from "history";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { IpdTypesStoreType } from "../models/ipd-types-store";
import { IpdTypeDetails } from "./details/IpdTypeDetails";
import { IpdTypesList } from "./list/IpdTypesList";
import { menuItems } from "modules/root/components/menu/MainMenu";
import func from "modules/dictionaries/functionalities";
import { can } from "modules/session/auth/access";

import styles from "./IpdTypesPage.module.scss";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { Search } from "modules/common/components/form/Search";

const menu = menuItems();

class IpdTypesPage extends React.Component<IpdTypesPageProps & RouteComponentProps, IpdTypesPageState> {
  private unregister: UnregisterCallback | null = null;

  constructor(props: IpdTypesPageProps & RouteComponentProps) {
    super(props);

    this.state = { typeId: getSelectedIpdTypeId(props.location.pathname) };
  }

  componentDidMount() {
    document.title = menu.dictionaries.nested.ipdTypes.label;

    const { history } = this.props;
    const { typeId } = this.state;

    this.loadList();
    this.loadDetails(typeId);

    this.unregister = history.listen((location) => {
      const typeId = getSelectedIpdTypeId(location.pathname);

      if (this.state.typeId !== typeId) {
        this.setState({ typeId });
        this.loadDetails(typeId);
      }
    });
  }

  componentWillUnmount() {
    this.unregister && this.unregister();
  }

  render() {
    const { store, access } = this.props;
    const canChange = can(func.IPDTYPE_EDIT, access);

    return (
      <Block columned={true} className={styles.page}>
        <Route path={routes.dictionaries.ipdType.details()}>
          <DetailsPopup title="ИРД" onCancel={this.onIpdTypeSelected}>
            <IpdTypeDetails
              canChange={canChange}
              ipdType={store.details}
              onSaved={this.onIpdTypeSaved}
              onRemoved={this.onIpdTypeRemoved}
            />
          </DetailsPopup>
        </Route>
        <div className={styles.tools}>
          {canChange && (
            <PlanrButton
              type="secondary"
              size="small"
              icon="general-plus-big"
              onClick={this.onIpdTypeAdd}
              style={{
                width: "200px",
                marginBottom: "32px",
                borderRadius: "120px",
              }}
            >{`${texts.add} ИРД`}</PlanrButton>
          )}
        </div>
        <div className={styles.mobileTools}>
          {canChange && <PlanrButton type="secondary" round icon="general-plus-big" onClick={this.onIpdTypeAdd} />}
        </div>
        <div className={styles.right}>
          <Search query={store.list.query} onSearch={store.list.setQuery} />
        </div>
        <IpdTypesList store={store.list} onSelected={this.onIpdTypeSelected} onRemoved={this.onIpdTypeRemoved} />
      </Block>
    );
  }

  onIpdTypeAdd = () => {
    this.onIpdTypeSelected(EMPTY_OBJECT_ID);
  };

  onIpdTypeSelected = (id: string = "") => {
    const { history } = this.props;

    const url = id ? routes.dictionaries.ipdType.details(id) : routes.dictionaries.ipdType.path;

    history.push(url);
  };

  onIpdTypeSaved = () => {
    this.onIpdTypeSelected("");
    this.loadList();
  };

  onIpdTypeRemoved = () => {
    const { history } = this.props;

    this.loadList();

    const url = routes.dictionaries.ipdType.path;
    history.push(url);
  };

  loadList = () => this.props.store.list.load();

  loadDetails = (id: string) => this.props.store.details.load(id);
}

export default withRouter(observer(IpdTypesPage));

function getSelectedIpdTypeId(pathname: string) {
  const match = matchPath<IpdTypesPageState>(pathname, routes.dictionaries.ipdType.details());

  return match && match.params ? match.params.typeId : "";
}

interface IpdTypesPageProps {
  store: IpdTypesStoreType;
  access: string[];
}

interface IpdTypesPageState {
  typeId: string;
}
