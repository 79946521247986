import React from "react";
import { IconName, MaybeElement } from "@blueprintjs/core";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon, GeneralIcons } from "modules/common/components/planr/icon/Generalcon";

interface InputRightIconProps {
    hasValue: boolean;
    disabled?: boolean;
    onClear?: () => void;
    icon: IconName | MaybeElement | GeneralIcons;
}

export const InputRightIcon = ({ icon, hasValue, disabled, onClear }: InputRightIconProps) => {
    const clear = (e: React.MouseEvent) => {
        e.stopPropagation();
        !disabled && onClear && onClear();
    };

    const element =
        onClear && hasValue ? (
            <PlanrButton
                type="neutral"
                icon="general-cross-small"
                onClick={clear}
                round={true}
                className="input-crossbtn"
                style={{ color: "#E31818" }}
            />
        ) : (
            <GeneralIcon type={`general-${icon}`} className="input-searchicon" />
        );

    return element;
};
