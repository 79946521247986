import { Classes, FormGroup, InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { FileLink } from "modules/common/components/files/FileLink";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { MoneyInput } from "modules/common/components/money/MoneyInput";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { preventSubmitKeyDown } from "modules/common/services/form/values";
import { texts } from "modules/common/texts";
import { COMPLETION_ACCEPT } from "modules/orders-manage/models/order-completions";
import { CompletionFileType } from "modules/orders-manage/models/order-file";
import { OrderCompletionRowType } from "modules/orders-manage/models/order-stages-store";
import React, { useCallback } from "react";
import { NumberFormatValues } from "react-number-format";
import styles from "./CompletionRow.module.scss";
import { Error, star } from "./events/common";

export const CompletionRow = observer(({ completion }: CompletionRowProps) => {
    const onSumChangeHandler = useCallback(
        (money: NumberFormatValues) => {
            completion.setSum(money.floatValue ?? 0);
        },
        [completion]
    );

    const onNameChangeHandler = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            completion.setName(e.target.value);
        },
        [completion]
    );

    const onDateChangeHandler = useCallback(
        (e: Date | null) => {
            completion.setDate(e);
        },
        [completion]
    );

    const onFileChangeHandler = useCallback(
        (file: File) => {
            return completion.uploadFile(file);
        },
        [completion]
    );

    const errors = completion.validation;

    const uploader = !completion.file ? (
        <PlanRDndUploader
            accept={COMPLETION_ACCEPT}
            multiple={false}
            onFileSelected={onFileChangeHandler}
            withLoader={true}
            oneRow={true}
        />
    ) : null;

    return (
        <div className={styles.completionRow}>
            <div>
                <RemoveConfirmation<OrderCompletionRowType>
                    what={() => "Акт выполненных работ"}
                    render={({ confirmRemoving }) => (
                        <PlanrButton
                            type="secondary"
                            icon="general-trash"
                            onClick={() => confirmRemoving(completion)}
                            size="small"
                            title={texts.remove}
                            style={{ marginBottom: "5px", width: "100%" }}
                        >
                            {texts.remove} акт
                        </PlanrButton>
                    )}
                    onConfirmed={(p) => p.remove()}
                />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
                <FormGroup
                    label="№"
                    labelInfo={star}
                    className={Classes.SMALL}
                    inline={true}
                    intent={errors.name ? Error : "none"}
                    helperText={errors.name}
                >
                    <InputGroup
                        id="name"
                        value={completion.name}
                        className="planr-default-input"
                        autoComplete="off"
                        onChange={onNameChangeHandler}
                        onKeyDown={preventSubmitKeyDown}
                        placeholder="Номер"
                        small={true}
                    />
                </FormGroup>
                <FormGroup
                    label="Сумма"
                    labelInfo={star}
                    className={Classes.SMALL}
                    inline={true}
                    intent={errors.sum ? Error : "none"}
                    helperText={errors.sum}
                >
                    <MoneyInput
                        className="planr-default-input"
                        id="sum"
                        small={true}
                        autoComplete="off"
                        data-lpignore="true"
                        value={completion.sum}
                        onMoneyChange={onSumChangeHandler}
                        onKeyDown={preventSubmitKeyDown}
                    />
                </FormGroup>
                <FormGroup
                    label="Дата"
                    labelInfo={star}
                    className={Classes.SMALL}
                    inline={true}
                    intent={errors.date ? Error : "none"}
                    helperText={errors.date}
                >
                    <DatePicker
                        value={completion.dateAsDate}
                        onChange={onDateChangeHandler}
                        clasName="planr-default-input"
                        onKeyDown={preventSubmitKeyDown}
                        small={true}
                    />
                </FormGroup>
                <div>
                    {uploader}
                    {completion.file && (
                        <FileLink<CompletionFileType>
                            baseUrl={completion.baseUrl}
                            file={completion.file}
                            onRemove={completion.clearFile}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});

interface CompletionRowProps {
    completion: OrderCompletionRowType;
}
