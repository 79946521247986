import * as yup from "yup";
import { fields } from "modules/dictionaries/bank-details/models/bank-details";
import { BankDetailsSchema, bankDetailsLabels as baseLabels } from "modules/common/models/bank-details";
import { texts } from "modules/common/texts";
const { object, string, boolean } = yup;

export const bankDetailsLabels = () => ({
    [fields.name]: texts.name,
    ...baseLabels(),
    [fields.comment]: texts.comment,
    [fields.main]: "Основные реквизиты",
});

const CompanyBankDetailsSchema = () => {
    const labels = bankDetailsLabels();

    return object()
        .shape({
            [fields.name]: string().label(labels[fields.name]).required(),

            [fields.comment]: string().label(labels[fields.comment]).notRequired(),

            [fields.main]: boolean().label(labels[fields.main]).notRequired(),
        })
        .concat(BankDetailsSchema(true));
};

export default CompanyBankDetailsSchema;
