import React from "react";
import { Formik, Form } from "formik";
import styles from "./ProjectPortfolioDetails.module.scss";
import ProjectPortfolioSchema from "./validation";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { InputGroup, TextArea, Classes } from "@blueprintjs/core";
import { fixTextAreaHeight, MODAL_AWAIT_DELAY } from "modules/common/services/form/textarea";
import { ProjectPortfolioType, fields } from "../../models/project-portfolio";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { texts } from "modules/common/texts";
import { Prompt } from "react-router";

const schema = ProjectPortfolioSchema();

class ProjectPortfolioDetailsBase extends React.PureComponent<ProjectPortfolioDetailsProps, ProjectPortfolioState> {
  private descriptionArea: HTMLTextAreaElement | null = null;
  constructor(props: ProjectPortfolioDetailsProps) {
    super(props);

    this.state = {
      isMobile: false,
    };
  }

  handleResize = () => {
    if (window.innerWidth < 885) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  private descriptionAreaRef = (ref: HTMLTextAreaElement | null) => {
    this.descriptionArea = ref;
  };

  private resize = () => {
    fixTextAreaHeight(this.descriptionArea);
  };

  public componentDidMount() {
    window.addEventListener("resize", this.handleResize);

    setTimeout(this.resize, MODAL_AWAIT_DELAY);
  }

  public componentDidUpdate() {
    this.resize();
  }

  componentWillMount() {
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const { projectPortfolio, onSaved, onRemoved, canChange } = this.props;
    const isMobile = this.state.isMobile;
    return (
      <div className={styles.details}>
        <Formik
          initialValues={getFormValues(projectPortfolio)}
          enableReinitialize={true}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            if (!canChange) {
              return;
            }

            const succsessfull = await projectPortfolio.save(values);
            setSubmitting(false);

            if (succsessfull) {
              resetForm();
              onSaved();
            }
          }}
        >
          {(formProps) => {
            const onRemove = async () => {
              formProps.setSubmitting(true);
              const success = await projectPortfolio.delete();
              formProps.setSubmitting(false);
              success && onRemoved();
            };

            return (
              <Form autoComplete="off">
                <Prompt when={formProps.dirty && canChange} message={texts.messages.leaveConfiramtion} />
                <div className={Classes.DIALOG_BODY}>
                  <StandardFormInput
                    name={fields.name}
                    schema={schema}
                    small={true}
                    inline={!isMobile}
                    className="planr-form-input"
                  >
                    {({ field }) => (
                      <InputGroup
                        id={field.name}
                        {...field}
                        className="planr-default-input"
                        autoComplete="off"
                        data-lpignore="true"
                        disabled={!canChange}
                      />
                    )}
                  </StandardFormInput>

                  <StandardFormInput
                    name={fields.description}
                    schema={schema}
                    inline={!isMobile}
                    small={true}
                    className="planr-form-input"
                  >
                    {({ field }) => (
                      <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                        <TextArea
                          id={field.name}
                          {...field}
                          growVertically={true}
                          className={Classes.FILL}
                          inputRef={this.descriptionAreaRef}
                          disabled={!canChange}
                        />
                      </div>
                    )}
                  </StandardFormInput>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                  {canChange && (
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                      <StandardFormButtons
                        {...formProps}
                        isRemoved={false}
                        what={`портфель проектов ${projectPortfolio.name}`}
                        isNewlyCreated={projectPortfolio.isNewlyCreated}
                        onRemove={onRemove}
                      />
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export const ProjectPortfolioDetails = observer(ProjectPortfolioDetailsBase);

interface ProjectPortfolioDetailsProps {
  projectPortfolio: ProjectPortfolioType;
  onSaved: () => void;
  onRemoved: () => void;
  canChange: boolean;
}

interface ProjectPortfolioState {
  isMobile: boolean;
}

function getFormValues(projectPortfolio: ProjectPortfolioType) {
  return {
    ...getSnapshot(projectPortfolio),
  };
}
