import moment from "moment";

export const chunkString = (size: number) => (text: string) => {
    const parts: string[] = [];
    while (text) {
        parts.push(text.substring(0, size));
        text = text.substring(size);
    }

    return parts;
};

export const toString = (value: any): string => {
    if (typeof value === "undefined" || value === null) {
        return "";
    }

    if (typeof value.toString === "function") {
        return value.toString();
    }

    return value + "";
};

export const capitalize = (value: string) => {
    return value ? value.substring(0, 1).toUpperCase() + value.substring(1) : value;
};

export const trimStart = (character: string, text: string) => {
    var startIndex = 0;

    while (text[startIndex] === character) {
        startIndex++;
    }

    return text.substr(startIndex);
};

export const trimEnd = (character: string, text: string) => {
    var startIndex = text.length - 1;

    while (text[startIndex] === character) {
        startIndex--;
    }

    return text.substr(0, startIndex + 1);
};

export function urlB64ToUint8Array(base64String: string) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
}

export function mongoObjectId() {
    var timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
    return (
        timestamp +
        "xxxxxxxxxxxxxxxx"
            .replace(/[x]/g, function () {
                return ((Math.random() * 16) | 0).toString(16);
            })
            .toLowerCase()
    );
}

export const extractDigits = (value: any) => {
    const text = toString(value);
    return text.replace(/\D+/g, "");
};

export const reverse = (text: string | null | undefined) => {
    if (!text) {
        return text;
    }

    return text.split("").reverse().join("");
};

export function parsePeriod(quarter: string): [Date, Date] | null {
    const expr = /(\d{4})$/;

    const match = quarter.match(expr);
    if (!match) {
        return null;
    }

    const year = parseInt(match[0], 10);
    let start = null;
    let end = null;

    if (match[0] === quarter) {
        // целиком год
        start = new Date(year, 0, 1);
        end = moment(new Date(year, 11, 1)).endOf("month").toDate();
    } else {
        // квартал
        let number = -1;
        if (quarter.indexOf("4 квартал") >= 0) {
            number = 12;
        } else if (quarter.indexOf("3 квартал") >= 0) {
            number = 9;
        } else if (quarter.indexOf("2 квартал") >= 0) {
            number = 6;
        } else if (quarter.indexOf("1 квартал") >= 0) {
            number = 3;
        }

        if (number < 0) {
            return null;
        }

        start = new Date(year, number - 3, 1);
        end = moment(new Date(year, number - 1, 1))
            .endOf("month")
            .toDate();
    }

    return [start, end];
}
