import React from "react";
import { Dialog, Classes, MenuItem, InputGroup } from "@blueprintjs/core";
import { Formik, Form } from "formik";
import styles from "./EventEditor.module.scss";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { CalendarEventSnapshotType, fields } from "modules/main/models/calendar-event-store";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { SORTABLE_DATE_TIME_FORMAT, WHITE, EMPTY_OBJECT_ID } from "modules/common/constants";
import moment from "moment";
import {
    SimpleSelect,
    DefaultSelectedOption,
    renderSingleOption,
    filterItemPredicate,
} from "modules/common/services/form/select";
import {
    EmployerDictionaryType,
    EmployerDictionaryItemType,
} from "modules/spending/employee/models/employee-dictionary";
import { texts } from "modules/common/texts";
import { MultiSelect, ItemRenderer } from "@blueprintjs/select";
import EventSchema, { eventLabels } from "./validation";
import { isNotNull, eat } from "modules/common/services/typescript";
import { formatDateTime } from "modules/common/services/formatting/date";
import { DictionaryItemSnapshotType } from "modules/common/models/entity";
import { toString } from "modules/common/services/strings";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { PlanrButton } from "modules/common/components/planr/button/Button";

const UserMultiSelect = MultiSelect.ofType<EmployerDictionaryItemType>();

const colors = [
    { id: WHITE, label: "Белый" },
    { id: "#FBDDF0", label: "Розовый" },
    { id: "#E9C7CD", label: "Красный" },
    { id: "#F4ECCD", label: "Желтый" },
    { id: "#DAEAC6", label: "Зеленый" },
    { id: "#D0E7F8", label: "Синий" },
    { id: "#F1D9CC", label: "Бронзовый" },
    { id: "#E5F2D3", label: "Лаймовый" },
    { id: "#C2E7E7", label: "Голубой" },
    { id: "#E2D1CB", label: "Коричневый" },
    // { id: WHITE, label: "Белый" },
    // { id: "#F9C4C4", label: "Красный", leftborder: "#E31818" },
    // { id: "#C4F7DD", label: "Зеленый", leftborder: "#1DD278" },
    // { id: "#DECDF5", label: "Фиолетовый", leftborder: "#7C36D7" },
    // { id: "#CDEAF5", label: "Синий", leftborder: "#36ACD7" },
];

const minutes = [
    { id: "", label: "Без уведомления" },
    { id: "15", label: "15 минут" },
    { id: "30", label: "30 минут" },
    { id: "60", label: "Час" },
];

const labels = eventLabels();
const schema = EventSchema();

export class EventEditor extends React.Component<EventEditorProps, EventEditorState> {
    constructor(props: EventEditorProps) {
        super(props);

        this.state = { usersHidden: props.event.users.length > 5 };
    }

    render() {
        const { onClose, width, employee, event, onSave, currentUser } = this.props;
        const { usersHidden } = this.state;

        return (
            <Dialog
                title="Событие"
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                isOpen={true}
                onClose={onClose}
                style={{ width: `${width || 500}px` }}
                backdropClassName="standard"
                className={`figma-dialog`}
            >
                <Formik
                    initialValues={getFormValues(event)}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        const succsessfull = await onSave({ ...values, id: event.eventGuid });
                        setSubmitting(false);

                        if (succsessfull) {
                            resetForm();
                            onClose();
                        }
                    }}
                >
                    {(formProps) => {
                        const usersValue = (formProps.values[fields.users] as string[])
                            .map((id: string) => employee.asMap[id])
                            .filter(isNotNull);

                        const usersLabel =
                            !usersValue.length || !event.iamAnAuthor ? null : usersHidden ? (
                                <div onClick={this.showUsers}>Показать</div>
                            ) : (
                                <div onClick={this.hideUsers}>Скрыть</div>
                            );

                        const clearUsers = () => {
                            formProps.setFieldValue(fields.users, []);
                            formProps.setFieldTouched(fields.users, true);
                            this.setState({ usersHidden: false });
                        };

                        return (
                            <Form autoComplete="off">
                                <div className={`${Classes.DIALOG_BODY} ${styles.dialog}`}>
                                    <StandardFormInput
                                        name={fields.name}
                                        schema={schema}
                                        small={true}
                                        inline={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <InputGroup
                                                id={field.name}
                                                {...field}
                                                className="planr-default-input"
                                                autoComplete="off"
                                                disabled={!event.iamAnAuthor}
                                            />
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.color}
                                        schema={schema}
                                        small={true}
                                        inline={true}
                                        className="planr-form-input"
                                    >
                                        {({ field, form }) => {
                                            const option = colors.find((i) => i.id === field.value);

                                            return (
                                                <SimpleSelect
                                                    className={`full-width-select ${Classes.FILL}`}
                                                    filterable={false}
                                                    activeItem={option}
                                                    inputProps={field}
                                                    items={colors}
                                                    itemRenderer={this.renderColor}
                                                    onItemSelect={(item) => {
                                                        form.setFieldValue(field.name, item.id);
                                                        form.setFieldTouched(field.name, true);
                                                    }}
                                                    disabled={!event.iamAnAuthor}
                                                >
                                                    <DefaultSelectedOption
                                                        option={option}
                                                        style={{ backgroundColor: option ? option.id : WHITE }}
                                                        disabled={!event.iamAnAuthor}
                                                    />
                                                </SimpleSelect>
                                            );
                                        }}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.start}
                                        schema={schema}
                                        small={true}
                                        inline={true}
                                        className="planr-form-input"
                                    >
                                        {({ field, form }) => {
                                            return (
                                                <div className={`${Classes.INPUT_GROUP} `}>
                                                    <DatePicker
                                                        clasName="planr-default-input"
                                                        value={field.value}
                                                        timePrecision="minute"
                                                        onChange={(date) => {
                                                            form.setFieldValue(field.name, date);
                                                            form.setFieldTouched(field.name, true);
                                                        }}
                                                        formatDate={formatDateTime}
                                                        disabled={!event.iamAnAuthor}
                                                    />
                                                </div>
                                            );
                                        }}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.stop}
                                        schema={schema}
                                        small={true}
                                        inline={true}
                                        className="planr-form-input"
                                    >
                                        {({ field, form }) => {
                                            return (
                                                <div className={`${Classes.INPUT_GROUP} `}>
                                                    <DatePicker
                                                        clasName="planr-default-input"
                                                        value={field.value}
                                                        timePrecision="minute"
                                                        onChange={(date) => {
                                                            form.setFieldValue(field.name, date);
                                                            form.setFieldTouched(field.name, true);
                                                        }}
                                                        formatDate={formatDateTime}
                                                        disabled={!event.iamAnAuthor}
                                                    />
                                                </div>
                                            );
                                        }}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.notificationMinutes}
                                        schema={schema}
                                        small={true}
                                        inline={true}
                                        className="planr-form-input"
                                    >
                                        {({ field, form }) => {
                                            const value = field.value === null ? "" : toString(field.value);
                                            const option = minutes.find((i) => i.id === value);

                                            return (
                                                <SimpleSelect
                                                    className={`full-width-select ${Classes.FILL}`}
                                                    filterable={false}
                                                    activeItem={option}
                                                    inputProps={field}
                                                    items={minutes}
                                                    itemRenderer={renderSingleOption}
                                                    onItemSelect={(item) => {
                                                        form.setFieldValue(field.name, item.id);
                                                        form.setFieldTouched(field.name, true);
                                                    }}
                                                >
                                                    <DefaultSelectedOption option={option} />
                                                </SimpleSelect>
                                            );
                                        }}
                                    </StandardFormInput>

                                    {event.iamAnAuthor && (
                                        <StandardFormInput
                                            name={fields.users}
                                            schema={schema}
                                            small={true}
                                            inline={false}
                                            label={
                                                <div className={styles.usersLabel}>
                                                    {labels[fields.users]}
                                                    {usersLabel}
                                                </div>
                                            }
                                            className="planr-form-input"
                                        >
                                            {({ field, form }) => {
                                                const items: any[] = [{ id: EMPTY_OBJECT_ID, label: texts.all }];
                                                employee.departmentMap.forEach((group) => {
                                                    items.push({
                                                        id: group.department,
                                                        label: group.department,
                                                        type: "department",
                                                    });
                                                    items.push(...group.employee.filter((e) => e.id !== currentUser));
                                                });

                                                const handleSelect = (item: EmployerDictionaryItemType) => {
                                                    let newValue: string[] = [];
                                                    const isDepartment = (item as any).type === "department";

                                                    if (isDepartment) {
                                                        const source = employee.departmentMap.find(
                                                            (gr) => gr.department === item.id
                                                        );
                                                        newValue = source
                                                            ? source.employee
                                                                  .filter((e) => e.id !== currentUser)
                                                                  .map((e) => e.id)
                                                            : [];
                                                    } else if (item.id === EMPTY_OBJECT_ID) {
                                                        newValue = employee.employee
                                                            .filter((e) => e.id !== currentUser)
                                                            .map((e) => e.id);
                                                    } else {
                                                        const index = field.value.indexOf(item.id);
                                                        newValue =
                                                            index < 0
                                                                ? [...field.value, item.id]
                                                                : [
                                                                      ...field.value.slice(0, index),
                                                                      ...field.value.slice(index + 1),
                                                                  ];
                                                    }

                                                    form.setFieldValue(field.name, newValue);
                                                    form.setFieldTouched(field.name, true);
                                                };

                                                const itemRenderer = this.renderUser(usersValue);

                                                return usersHidden ? (
                                                    <div className={styles.usersInput}>
                                                        <InputGroup
                                                            disabled={true}
                                                            value={`${usersValue.length} человек`}
                                                            className={"planr-default-input"}
                                                        />
                                                        <PlanrButton
                                                            type="neutral"
                                                            icon="general-trash"
                                                            size="small"
                                                            onClick={clearUsers}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className={styles.usersInput}>
                                                        <UserMultiSelect
                                                            activeItem={null}
                                                            className={`full-width-select ${Classes.FILL}`}
                                                            itemRenderer={itemRenderer}
                                                            itemsEqual={this.areUsersEquals}
                                                            selectedItems={usersValue}
                                                            items={items}
                                                            onItemSelect={handleSelect}
                                                            placeholder="Сотрудники"
                                                            itemPredicate={filterItemPredicate}
                                                            tagRenderer={this.renderTag}
                                                            noResults={<MenuItem disabled={true} text={texts.noData} />}
                                                            popoverProps={{ minimal: true }}
                                                            resetOnSelect={true}
                                                        />
                                                        <GeneralIcon
                                                            type="general-cross-big"
                                                            onClick={clearUsers}
                                                            style={{
                                                                marginTop: "5px",
                                                                marginLeft: "5px",
                                                                color: "#00273D",
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            }}
                                        </StandardFormInput>
                                    )}
                                </div>

                                <div className={Classes.DIALOG_FOOTER}>
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        <StandardFormButtons
                                            {...formProps}
                                            isRemoved={false}
                                            what="событие"
                                            isNewlyCreated={true}
                                            onRemove={eat}
                                        />
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Dialog>
        );
    }

    hideUsers = () => this.setState({ usersHidden: true });

    showUsers = () => this.setState({ usersHidden: false });

    renderTag = (user: EmployerDictionaryItemType) => user.label;

    areUsersEquals = (user1: EmployerDictionaryItemType, user2: EmployerDictionaryItemType) => {
        return user1.id === user2.id;
    };

    renderUser =
        (value: EmployerDictionaryItemType[]): ItemRenderer<EmployerDictionaryItemType> =>
        (user, { modifiers, handleClick }) => {
            if (!modifiers.matchesPredicate) {
                return null;
            }

            const selected = this.isUserSelected(value, user);
            const isDepartment = (user as any).type === "department";

            return (
                <MenuItem
                    active={modifiers.active}
                    icon={selected ? "tick" : "blank"}
                    key={user.id}
                    text={user.label}
                    onClick={handleClick}
                    shouldDismissPopover={false}
                    className={isDepartment ? styles.department : ""}
                />
            );
        };

    renderColor: ItemRenderer<DictionaryItemSnapshotType> = (item, { handleClick, modifiers }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }

        const text = item.label || texts.undefined;
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                key={item.id}
                onClick={handleClick}
                text={text}
                style={{ backgroundColor: item.id }}
            />
        );
    };

    isUserSelected = (value: EmployerDictionaryItemType[], user: EmployerDictionaryItemType) => {
        const selected = value.some((u) => u.id === user.id);
        return selected;
    };
}

interface EventEditorProps {
    currentUser: string;
    employee: EmployerDictionaryType;
    onClose: () => void;
    onSave: (values: any) => Promise<boolean>;
    width?: number;
    event: CalendarEventSnapshotType;
}

interface EventEditorState {
    usersHidden: boolean;
}

function getFormValues(event: CalendarEventSnapshotType) {
    return {
        [fields.color]: event.color,
        [fields.start]: moment(`${event.startDay} ${event.startTime}`, SORTABLE_DATE_TIME_FORMAT).toDate(),
        [fields.stop]: moment(`${event.endDay} ${event.endTime}`, SORTABLE_DATE_TIME_FORMAT).toDate(),
        [fields.name]: event.name,
        [fields.notificationMinutes]: event.notificationMinutes,
        [fields.users]: event.users,
    };
}
