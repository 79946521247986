import React from "react";
import { observer } from "mobx-react";
import { OverheadSpendingsSummaryType } from "../models/overhead-spendings";
import styles from "./OverheadSpendings.module.scss";
import { Money } from "modules/common/components/money/Money";
import { LocalLoader } from "modules/common/components/loader/Loader";

export const YearCard = observer(({ store, active, onSelect }: YearCardProps) => {
    const onClick = () => !store.loading && onSelect(store.period);

    return (
        <div
            className={`${styles.yearBlock} ${active ? styles.active : ""} ${store.loading ? styles.loading : ""}`}
            onClick={onClick}
        >
            {!store.loading && (
                <>
                    <div className={styles.year}>{store.period}</div>

                    <div className={styles.money}>
                        <div className={styles.actual}>
                            <Money amount={store.actualSum} />
                        </div>
                        <div className={styles.planSum}>
                            <Money amount={store.planSum} />
                        </div>
                    </div>
                </>
            )}
            <LocalLoader active={store.loading} />
        </div>
    );
});

interface YearCardProps {
    store: OverheadSpendingsSummaryType;
    active: boolean;
    onSelect: (year: string) => void;
}
