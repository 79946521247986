import { ConstantsType } from 'modules/root/models/constants';
import { SimpleSelect } from 'modules/common/services/form/select';

export const CategorySelect = SimpleSelect;

export function buildWorkCategoryOptions (constants: ConstantsType) {
    const result: TStringMap<SelectItem> = {};

    for (let node of constants.workTypeCategory.entries()) {
        result[node[1]] = {
            id: node[1],
            label: node[1],
        };
    }

    return result;
}
