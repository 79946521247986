import { types } from "mobx-state-tree";
import { nameof } from "../services/typescript";
import { IdEntity } from "./entity";

export const DictionaryLink = types
    .compose(
        IdEntity,
        types.model({
            name: types.string,
        })
    )
    .named("DictionaryLink");

export const initialState = (): DictionaryLinkSnapshotType => ({
    id: "",
    name: "",
});

export type DictionaryLinkType = typeof DictionaryLink.Type;
export type DictionaryLinkSnapshotType = typeof DictionaryLink.SnapshotType;
export type DictionaryEmployerHistoryLinkSnapshotType = typeof DictionaryEmployerHistoryLink.SnapshotType;
export type DictionaryEmployerHistoryLinkType = typeof DictionaryEmployerHistoryLink.Type;
export const DictionaryEmployerHistoryLink = types
    .compose(
        IdEntity,
        types.model({
            name: types.string,
            since: types.string,
            until: types.maybeNull(types.string),
        })
    )
    .named("DictionaryEmployerHistoryLink");
export const initialStateEmployerHistory = (): DictionaryEmployerHistoryLinkSnapshotType => ({
    id: "",
    name: "",
    since: "",
    until: "",
});

export const fields = {
    since: nameof((a: DictionaryEmployerHistoryLinkType) => a.since) as string,
    until: nameof((a: DictionaryEmployerHistoryLinkType) => a.until) as string,
    name: nameof((a: DictionaryEmployerHistoryLinkType) => a.name) as string,
};
