import cookies from 'js-cookie';

const prefix = (tableName: string) => `sort_${tableName}`;

const valueSerializer = (column: string, asc: boolean) => {
    return JSON.stringify(({ column, asc } as SortOptions));
}

const valueDeserializer = (json: string): SortOptions | null => {
    return json ? JSON.parse(json) : null;
}

export const saveSortOption = (tableName: string) => {
    const key = prefix(tableName);

    return (column: string, asc: boolean) => {
        cookies.set(key, valueSerializer(column, asc));
    }
}

export const getSortOption = (tableName: string) => {
    const key = prefix(tableName);

    return (defaultOptions: SortOptions) => {
        const value = cookies.get(key) || '';
        return valueDeserializer(value) || defaultOptions;
    };
}

export interface SortOptions {
    column: string;
    asc: boolean;
}
