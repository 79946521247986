import { types, applySnapshot, isAlive } from "mobx-state-tree";
import { BaseEntity, isNewlyCreated } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID, DATE_TIME_FORMAT } from "modules/common/constants";
import Schema from "../components/details/validation";
import moment from "moment";
import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";
import { getFieldLabel } from "modules/common/services/form/fields";
import {
    BankDetails,
    initialState as emptyBankDetails,
    fields as bankDetailsFields,
    formatBankDetails,
} from "modules/common/models/bank-details";

const CompanyBankDetailsBase = types.compose(
    Transport,
    Notificator,
    BaseEntity,
    BankDetails,
    types.model({
        name: types.string,
        comment: types.string,
        main: types.boolean,
    })
);

export const CompanyBankDetails = CompanyBankDetailsBase.actions((self) => ({
    load: flow(function* (id: string) {
        try {
            const snapshot = isNewlyCreated(id)
                ? initialState()
                : yield self.transport.get<CompanyBankDetailsSnapshotType>(apiUrls.bankDetails.details(id));

            applySnapshot(self, snapshot);
        } catch (er) {
            self.notify.error(er);
        }
    }),

    save: flow(function* (model: CompanyBankDetailsSnapshotType) {
        try {
            const snapshot = self.isNewlyCreated
                ? yield self.transport.put<CompanyBankDetailsSnapshotType>(apiUrls.bankDetails.create(), model)
                : yield self.transport.post<CompanyBankDetailsSnapshotType>(apiUrls.bankDetails.update(self.id), model);

            isAlive(self) && applySnapshot(self, snapshot);
            self.notify.success(texts.messages.saved);

            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    delete: flow(function* () {
        if (self.isNewlyCreated) {
            return true;
        }

        try {
            yield self.transport.delete<boolean>(apiUrls.bankDetails.delete(self.id));
            self.notify.success(texts.messages.removed);

            isAlive(self) && applySnapshot(self, initialState());
            return true;
        } catch (er) {
            self.notify.error(er);
            return false;
        }
    }),
})).named("CompanyBankDetails");

export type CompanyBankDetailsSnapshotType = typeof CompanyBankDetailsBase.SnapshotType;
export type CompanyBankDetailsType = typeof CompanyBankDetails.Type;

export const initialState = (): CompanyBankDetailsSnapshotType => ({
    ...emptyBankDetails(),
    id: EMPTY_OBJECT_ID,
    created: moment().format(DATE_TIME_FORMAT),
    name: "",
    comment: "",
    main: false,
});

export const fields = {
    ...bankDetailsFields(),
    name: nameof((a: CompanyBankDetailsType) => a.name) as string,
    main: nameof((a: CompanyBankDetailsType) => a.main) as string,
    comment: nameof((a: CompanyBankDetailsType) => a.comment) as string,
};

export function formatCompanyBankDetails(details: CompanyBankDetailsSnapshotType | null) {
    let result = "";

    if (details) {
        const schema = Schema();

        result += `${getFieldLabel(fields.name, schema, null)}: ${details.name}\n`;
        result += formatBankDetails(details);
        result += `${getFieldLabel(fields.comment, schema, null)}: ${details.comment}\n`;
    }

    return result;
}
