import React, { useCallback } from "react";
import { findIndex } from "lodash";
import { ButtonGroup } from "@blueprintjs/core";
import { toString } from "modules/common/services/strings";
import { PlanrButton } from "../planr/button/Button";

const DOTS = "...";

export const Pager = ({ page, totalItems, itemsPerPage, pageSelected, hideIfEmpty, pagesNumber = 13 }: PagerProps) => {
  const buttons = makePagerLabels(totalItems, page, itemsPerPage, pagesNumber);

  const onClick = useCallback(
    (p: Page) => {
      if (typeof p.label === "number") {
        pageSelected(p.label);
      }
    },
    [pageSelected]
  );

  if (hideIfEmpty && buttons.length < 2) {
    return null;
  }

  return (
    <ButtonGroup className="pager">
      {buttons.map((button) => {
        return (
          <PlanrButton
            round={true}
            key={button.key}
            onClick={() => onClick(button)}
            type={button.current ? "primary" : "dashed"}
            disabled={button.disabled}
            size="small"
          >
            {button.label}
          </PlanrButton>
        );
      })}
    </ButtonGroup>
  );
};

interface PagerProps {
  page: number;
  totalItems: number;
  itemsPerPage: number;
  pageSelected: (page: number) => void;
  small?: boolean;
  hideIfEmpty?: boolean;
  pagesNumber?: number;
}

export function makePagerLabels(totalCount: number, current: number, pageSize: number, pagesNumber: number) {
  let result: Page[];
  const pageCount = Math.ceil(totalCount / pageSize);

  if (pageCount < 2) {
    result = [makePage(1, current, "1", false)];
  } else {
    const max = pagesNumber; // должно быть нечетным числом
    const stepTwo = max - 1;
    const stepOne = stepTwo / 2;
    // всего максимум 13 кнопок
    // 1 ... 6  7  [8]  9  10 ... 15
    // [1]  2  3  4   5   6   7  ... 15

    let start = current - stepOne;
    let end = start + stepTwo;
    let win: Label[] = [];
    let copy: Label[] = [];
    let cursor: number, marker: number;

    for (let i = start; i <= end; i++) {
      win.push(i > pageCount ? -1 : i <= 0 ? -1 : i);
    }

    // если в конце пустота - сдвигаем все в начало
    if (win[win.length - 1] < 0 && win[0] > 0) {
      cursor = findIndex(win, (e) => e < 0);

      for (let i = 0; i < cursor; i++) {
        copy.push(win[i]);
      }

      marker = copy[0] as number;

      for (let i = cursor; i < win.length; i++) {
        marker--;
        copy.unshift(marker > 0 ? marker : -1);
      }

      win = copy;
    }
    // если в начале путота - сдвигаем все в конец
    else if (win[0] < 0 && win[win.length - 1] > 0) {
      cursor = findIndex(win, (e) => e > 0);

      for (let i = cursor; i < win.length; i++) {
        copy.push(win[i]);
      }

      marker = copy[copy.length - 1] as number;

      for (let i = cursor - 1; i >= 0; i--) {
        marker++;
        copy.push(marker <= pageCount ? marker : -1);
      }

      win = copy;
    }

    if (win.indexOf(1) < 0) {
      win[0] = 1;
      win[1] = DOTS;
    }

    if (win.indexOf(pageCount) < 0) {
      win[win.length - 1] = pageCount;
      win[win.length - 2] = DOTS;
    }

    let dotsCounter = 0;
    result = win
      .filter((item) => {
        return item !== -1;
      })
      .map((item) => {
        let key = toString(item);
        if (item === DOTS) {
          dotsCounter++;
          key = `${DOTS}-${dotsCounter}`;
        }
        return makePage(item, current, key, item !== DOTS);
      });
  }

  return result;
}

function makePage(label: Label, current: number, key: string, active: boolean): Page {
  return {
    label,
    current: current === label,
    disabled: !active,
    key,
  };
}

type Label = number | string;

interface Page {
  label: Label;
  current: boolean;
  disabled: boolean;
  key: string;
}
