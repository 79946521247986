import React from "react";
import { AgGridSizeSaver, saveAgGridColumnSize } from "modules/common/services/table/size-storage";
import styles from "./TokensList.module.scss";
import { TokensListType } from "../../models/token-list";
import { observer } from "mobx-react";
import { AutomaticTableName, OuterTableName } from "../../models/tokens-store";
import { TokenType, TokenSnapshotType } from "../../models/token";
import { columns, TokenActions } from "./columns";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { AgGridReact } from "@ag-grid-community/react";
import { ColDef, GridReadyEvent } from "@ag-grid-community/core";
import { agGridLocale } from "modules/root/services/locale";
import { HEADER_HEIGHT, DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import { MAX_INT } from "modules/common/constants";

const modules = [...defaultModules];

export const TokensList = observer(
    class Tokens extends React.PureComponent<TokensListProps> implements TokenActions {
        private sizeSaver: AgGridSizeSaver;
        private options = DefaultAgGridOptions();
        private columns: ColDef[];

        constructor(props: TokensListProps) {
            super(props);

            const tableName = props.isAutomatic ? AutomaticTableName : OuterTableName;

            this.columns = columns(tableName);
            this.sizeSaver = saveAgGridColumnSize(tableName);
        }

        render() {
            const { store } = this.props;

            return (
                <GridTheme className={styles.table} bordered={true}>
                    <AgGridReact
                        rowStyle
                        rowSelection="single"
                        suppressCellSelection={true}
                        enableCellTextSelection={true}
                        columnDefs={this.columns}
                        rowData={store.data}
                        localeText={agGridLocale}
                        rowHeight={40}
                        headerHeight={HEADER_HEIGHT}
                        rowBuffer={MAX_INT}
                        getRowNodeId={getRowNodeId}
                        onGridReady={this.onGridReady}
                        onSortChanged={store.sorter.resorted}
                        onColumnResized={this.sizeSaver}
                        gridOptions={this.options}
                        modules={modules}
                        immutableData={true}
                        context={this}
                    />
                </GridTheme>
            );
        }

        onRowClick = (index: number) => {
            this.setState({ selectedRow: index });
        };

        onToggle = (row: TokenSnapshotType, state: boolean) => {
            const { store, onTokenToggle } = this.props;
            const token = store.asMap[row.id];

            token && onTokenToggle(token, state);
        };

        onGridReady = async ({ api }: GridReadyEvent) => {
            const { sorter } = this.props.store;
            setTimeout(() => api.setSortModel(sorter.agGridSortModel));
        };
    }
);

const getRowNodeId = (node: TokenType) => node.id;

interface TokensListProps {
    isAutomatic: boolean;
    store: TokensListType;
    onTokenToggle: (token: TokenType, state: boolean) => void;
}
