import React from "react";
import styles from "./OwnPremiumsWidget.module.scss";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import { routes } from "modules/common/routes";
import { Link } from "react-router-dom";
import { Money } from "modules/common/components/money/Money";
import { EmployerPremiumsType } from "modules/spending/employee/models/employer-premiums";

class Widget extends React.Component<OwnPremiumsWidgetProps> {
    render() {
        const { store, orderAccess } = this.props;

        return (
            <div className={`${styles.widget} own-premiums-widget`}>
                {!store.isEmpty && (
                    <div className={styles.headerTableTop}>
                        <>
                            <div className={styles.name}> Назначенные премии</div>
                            <div className={`${styles.money} centered`}>
                                <Money className={`${styles.greenTotal}`} amount={store.totals.sum} />
                            </div>
                            <div className={`${styles.money} centered`}>
                                <Money className={`${styles.greenTotal}`} amount={store.totals.paidSum} />
                            </div>
                            <div className={`${styles.money} centered`}>
                                <Money
                                    className={`${store.totals.remainsSum > 0 ? styles.redTotal : styles.greenTotal}`}
                                    amount={store.totals.remainsSum}
                                />
                            </div>
                        </>
                    </div>
                )}
                <div className={`${styles.scroller} own-premiums-widget-scroller`}>
                    {!store.isEmpty && (
                        <>
                            <div className={`${styles.row} ${styles.rowProject}`}>
                                <div className={`${styles.inventory} righted`}>&nbsp;</div>
                                <div className={styles.name}>Проект</div>
                                <div className={`${styles.money} centered`}>Назначено</div>
                                <div className={`${styles.money} centered`}>К выплате</div>
                                <div className={`${styles.money} centered`}>Остаток</div>
                            </div>
                        </>
                    )}

                    {store.rows.map((premium) => {
                        const href = orderAccess ? routes.orders.details(premium.orderId) : "";

                        return (
                            <div className={styles.row} key={premium.id}>
                                <div className={`${styles.inventory} righted`}>
                                    {href ? (
                                        <Link to={href}>{premium.fullInventoryNumber}</Link>
                                    ) : (
                                        premium.fullInventoryNumber
                                    )}
                                </div>
                                <div className={styles.name}>
                                    {href ? <Link to={href}>{premium.name}</Link> : premium.name}
                                </div>
                                <div className={`${styles.money} centered`}>
                                    <Money amount={premium.sum} />
                                </div>
                                <div className={`${styles.money} centered`}>
                                    <Money amount={premium.paidSum} />
                                </div>
                                <div className={`${styles.money} centered`}>
                                    <Money amount={premium.remainsSum} />
                                </div>
                            </div>
                        );
                    })}
                    {store.isEmpty && <div className={styles.noData}>{texts.noData}</div>}
                </div>
            </div>
        );
    }
}

export const OwnPremiumsWidget = observer(Widget);

interface OwnPremiumsWidgetProps {
    store: EmployerPremiumsType;
    orderAccess?: boolean;
}
