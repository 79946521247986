import { types } from "mobx-state-tree";
import { BaseEntity } from "modules/common/models/entity";
import { DictionaryLink } from "modules/common/models/dictionary-link";

export const HoursPerDay = types.model({
  unitTypes: types.array(types.string),
  hours: types.number,
});

export const WorkloadUnitTypeDetails = types
  .model({
    name: types.string,
    shortName: types.string,
    legalName: types.string,
    legalCode: types.string,
    fullDay: types.boolean,
    defaultHours: types.maybeNull(types.number),
    stacksWith: types.array(types.string),
    unavailableForDayOff: types.boolean,
  })
  .actions((self) => ({
    availableForDay(isDayOff: boolean) {
      return !isDayOff || !self.unavailableForDayOff;
    },
  }))
  .named("WorkloadUnitType");

export const WorkloadUnitOrderLink = types
  .compose(
    DictionaryLink,
    types.model({
      inventoryNumber: types.number,
      color: types.string,
    })
  )
  .views((self) => ({
    get fullInventoryNumber() {
      return self.inventoryNumber.toString().padStart(4, "0");
    },
  }))
  .named("WorkloadUnitOrderLink");

export const WorkloadUnit = types
  .compose(
    BaseEntity,
    types.model({
      order: types.maybeNull(WorkloadUnitOrderLink),
      projectInventoryNumber: types.maybeNull(types.number),
      fullInventoryNumber: types.string,
      day: types.string,
      date: types.string,
      isDayOff: types.boolean,
      isWorkedOut: types.boolean,
      type: types.string,
      isHome: types.maybeNull(types.boolean),
      typeDetails: WorkloadUnitTypeDetails,
      hours: types.number,
      minutes: types.number,
      comment: types.string,
      task: types.maybeNull(
        types.model({
          workId: types.string,
          workName: types.string,
          workDescription: types.string,
          type: types.string,
          guid: types.string,
        })
      ),
    })
  )
  .named("WorkloadUnit");

export type WorkloadUnitOrderLinkType = typeof WorkloadUnitOrderLink.Type;
export type WorkloadUnitTypeType = typeof WorkloadUnitTypeDetails.Type;
export type WorkloadUnitTypeSnapshotType = typeof WorkloadUnitTypeDetails.SnapshotType;
export type WorkloadUnitType = typeof WorkloadUnit.Type;
export type WorkloadUnitSnapshotType = typeof WorkloadUnit.SnapshotType;

export const emptyWorkloadUnitType = (): WorkloadUnitTypeSnapshotType => ({
  name: "",
  shortName: "",
  legalCode: "",
  legalName: "",
  fullDay: true,
  defaultHours: null,
  stacksWith: [],
  unavailableForDayOff: false,
});
