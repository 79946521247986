import { types } from "mobx-state-tree";
import { AgentsCategoryList, AgentsCategories, initialState as emptyList } from "./agents-categories-list";
import { initialState as emptyAgentsCategories } from "./agents-categories";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";

export const AgentsCategoriesStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            list: AgentsCategoryList,
            details: AgentsCategories,
        })
    )
    .named("AgentsCategoriesStore");

export const initialState = (): typeof AgentsCategoriesStore.SnapshotType => ({
    list: emptyList(),
    details: emptyAgentsCategories(),
});

export type AgentsCategoriesStoreType = typeof AgentsCategoriesStore.Type;
