import { types } from "mobx-state-tree";
import { formatMoney } from "modules/common/components/money/Money";
import { DATE_TIME_FORMAT } from "modules/common/constants";
import { BankDetails } from "modules/common/models/bank-details";
import { BaseEntity, DictionaryItem } from "modules/common/models/entity";
import { nameof } from "modules/common/services/typescript";
import { OrderDictionaryItem } from "modules/orders-manage/models/order-dictionary";
import moment from "moment";

const cleaners: [RegExp, string][] = [
    [new RegExp("ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ", "gi"), "ООО"],
    [new RegExp("ПРОЕКТНАЯ МАСТЕРСКАЯ", "gi"), "ПМ"],
    [new RegExp("КОМПАНИЯ СТРОИТЕЛЬНОГО ПРОЕКТИРОВАНИЯ", "gi"), "КСП"],
];

const StatisticDictionaryItem = types.compose(
    DictionaryItem,
    types.model({
        percents: types.number,
        type: types.string,
        parent: types.string,
    })
);

export const FinanceRequestStatisticDictionaryDictionaryItem = types.compose(
    StatisticDictionaryItem,
    types.model({
        workType: types.string,
        outsourcerName: types.string,
        date: types.string,
        type: types.string,
        invoice: types.maybeNull(
            types.array(
                types.model({
                    fileId: types.string,
                    fileName: types.string,
                })
            )
        ),
    })
);

export const OrderStatisticDictionaryItem = types.compose(StatisticDictionaryItem, OrderDictionaryItem);

export const BankInvoiceRow = types
    .compose(
        BaseEntity,
        types.model({
            sum: types.number,
            date: types.string,
            description: types.string,
            type: types.string,
            number: types.string,
            recipient: types.string,
            recipientBankDetails: BankDetails,
            payer: types.string,
            payerBankDetails: BankDetails,
            operation: types.maybeNull(types.string),
            uniqueHash: types.string,
            processed: types.boolean,
            incoimng: types.boolean,
            payload: types.maybeNull(types.string),
        })
    )
    .views((self) => ({
        get dateAsDate() {
            return moment(self.date, DATE_TIME_FORMAT);
        },

        get money() {
            return formatMoney(self.sum);
        },

        get cleanPayer() {
            let result = self.payer;
            cleaners.forEach(([pattern, replacement]) => (result = result.replace(pattern, replacement)));

            return result;
        },

        get cleanRecipient() {
            let result = self.recipient;
            cleaners.forEach(([pattern, replacement]) => (result = result.replace(pattern, replacement)));

            return result;
        },
    }))
    .named("BankInvoiceRow");

export const ImportedBankRow = types
    .compose(
        BankInvoiceRow,
        types.model({
            isIncoimng: types.maybeNull(types.boolean),
            error: types.string,
            exclude: types.boolean,
            dictionaries: types.map(
                types.model({
                    key: types.string,
                    value: types.maybeNull(
                        types.array(
                            types.union(
                                {
                                    eager: true,
                                },
                                FinanceRequestStatisticDictionaryDictionaryItem,
                                OrderStatisticDictionaryItem,
                                StatisticDictionaryItem
                            )
                        )
                    ),
                })
            ),
        })
    )
    .actions((self) => ({
        setIsIncoimng(value: boolean | null) {
            self.isIncoimng = value;
        },

        setOrderId(value: string | null) {
            self.operation = value;
        },
    }))
    .named("ImportedBankRow");

export const fields = {
    date: nameof((r: ImportedBankRowType) => r.date) as string,
    dateAsDate: nameof((r: ImportedBankRowType) => r.dateAsDate) as string,
    sum: nameof((r: ImportedBankRowType) => r.sum) as string,
    payer: nameof((r: ImportedBankRowType) => r.payer) as string,
    recipient: nameof((r: ImportedBankRowType) => r.recipient) as string,
    description: nameof((r: ImportedBankRowType) => r.description) as string,
    isIncoimng: nameof((r: ImportedBankRowType) => r.isIncoimng) as string,
    payload: nameof((r: ImportedBankRowType) => r.payload) as string,
};

export type BankInvoiceRowType = typeof BankInvoiceRow.Type;
export type BankInvoiceRowSnapshotType = typeof BankInvoiceRow.SnapshotType;
export type ImportedBankRowSnapshotType = typeof ImportedBankRow.SnapshotType;
export type ImportedBankRowType = typeof ImportedBankRow.Type;
export type StatisticDictionaryItemType = typeof StatisticDictionaryItem.Type;
export type OrderStatisticDictionaryItemType = typeof OrderStatisticDictionaryItem.Type;
export type FinanceRequestStatisticDictionaryDictionaryItemType =
    typeof FinanceRequestStatisticDictionaryDictionaryItem.Type;
export type FinanceRequestStatisticDictionaryDictionaryItemSnapshotType =
    typeof FinanceRequestStatisticDictionaryDictionaryItem.SnapshotType;
