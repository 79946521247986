import React from "react";
import { FileMetadataSnapshotType } from "modules/common/models/file-metadata";
import { OrderMailsStoreType } from "../models/store";
import { MailsList } from "./List";
import styles from "./Tab.module.scss";
import { observer } from "mobx-react";
import { OrderMailSnapshotType } from "modules/orders-manage/models/order-mails";
import { SidePanel } from "./SidePanel";
import { getSnapshot } from "mobx-state-tree";
import { columns } from "./columns";

class Mails extends React.Component<TabProps, TabState> {
    mounted = true;
    colDefs: any;

    constructor(props: TabProps) {
        super(props);
        this.state = { selection: null, sideLoading: false };
        this.colDefs = columns(!!props.hideProject);
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const { store, canManage, hideProject } = this.props;
        const { selection, sideLoading } = this.state;

        return (
            <div className={styles.tab}>
                <MailsList
                    sorter={store.sorter}
                    canManage={canManage}
                    onMailSelected={this.mailSelected}
                    current={selection}
                    data={store.data}
                    baseUrl={store.baseUrl}
                    downloadMails={store.downloadMails}
                    employee={store.employee}
                    filtersStatus={store.filtersStatus}
                    filtersType={store.filtersType}
                    printMails={store.printMails}
                    query={store.query}
                    remove={store.remove}
                    resorted={store.resorted}
                    save={store.save}
                    setFiltersType={store.setFiltersType}
                    setPage={store.setPage}
                    setQuery={store.setQuery}
                    toggleFiltersStatus={store.toggleFiltersStatus}
                    upload={store.uploadFile}
                    assign={store.assign}
                    orders={store.orders}
                    pager={{
                        page: store.page,
                        pageSize: store.pageSize,
                        totalCount: store.totalCount,
                    }}
                    rowBuffer={store.pageSize}
                    columns={this.colDefs}
                    hideProject={!!hideProject}
                    mailFactory={store.emptyMail}
                />

                <SidePanel
                    selection={selection}
                    onPreview={this.previewFile}
                    renderer={store.renderer}
                    goBack={this.goBack}
                    goForward={this.goForward}
                    onPrint={this.printFile}
                    onDownload={this.downloadFile}
                    loading={sideLoading}
                />
            </div>
        );
    }

    mailSelected = (selection: OrderMailSnapshotType) => {
        if (!this.mounted) {
            return;
        }

        this.setState({ selection });
        if (selection.documents.length > 0) {
            this.previewFile(selection.documents[0]);
        } else {
            this.previewFile(null);
        }
    };

    previewFile = (document: FileMetadataSnapshotType | null) => {
        const { renderer } = this.props.store;
        this.mounted && renderer.preview(document);
    };

    goForward = async (from: OrderMailSnapshotType) => {
        const seletion = await this.props.store.moveSelection(from, 1);
        if (seletion) {
            this.mailSelected(getSnapshot(seletion));
        }
    };

    goBack = async (from: OrderMailSnapshotType) => {
        const seletion = await this.props.store.moveSelection(from, -1);
        if (seletion) {
            this.mailSelected(getSnapshot(seletion));
        }
    };

    printFile = async (fileId: string) => {
        this.setState({ sideLoading: true });
        await this.props.store.printFile(fileId);
        this.setState({ sideLoading: false });
    };

    downloadFile = async (fileId: string) => {
        this.setState({ sideLoading: true });
        await this.props.store.downloadFile(fileId);
        this.setState({ sideLoading: false });
    };
}

export const MailsTab = observer(Mails);

interface TabProps {
    canManage: boolean;
    store: OrderMailsStoreType;
    hideProject?: boolean;
}

interface TabState {
    selection: OrderMailSnapshotType | null;
    sideLoading: boolean;
}
