import { observer } from "mobx-react";
import { AnonymousRoute } from "modules/common/components/routing/AnonymousRoute";
import { ProtectedRoute } from "modules/common/components/routing/ProtectedRoute";
import { routes } from "modules/common/routes";
import React from "react";
import AccessPage from "./access/components/AccessPage";
import { AccessStoreType } from "./access/models/access-store";
import { AuthPage } from "./auth/components/AuthPage";
import { SessionType } from "./auth/models/session";
import { FormAgentPage } from "./formAgentPage/FormAgentPage";
import SettingsPage from "./settings/components/SettingsPage";
import { SettingsStoreType } from "./settings/models/settings";
import { TokensTabsPage } from "./tokens/components/TokensTabsPage";
import { TokensStoreType } from "./tokens/models/tokens-store";

export const Routes = observer(({ tokens, session, access, settings, baseUrl, store }: RoutesProps) => {
  const loggedIn = session.loggedIn;

  const agent = session.agent;

  return (
    <>
      <AnonymousRoute path={routes.login} loggedIn={loggedIn} render={() => <AuthPage store={session} />} />
      <ProtectedRoute
        path={routes.tokens.path}
        loggedIn={loggedIn}
        render={(props) => <TokensTabsPage {...props} store={tokens} />}
      />
      <ProtectedRoute
        path={routes.access}
        loggedIn={loggedIn}
        render={(props) => <AccessPage {...props} store={access} session={session} />}
      />
      <ProtectedRoute
        path={routes.settings}
        loggedIn={loggedIn}
        render={(props) => <SettingsPage {...props} store={settings} collapser={settings.collapser} />}
      />
      <AnonymousRoute
        path={routes.partnerForm}
        loggedIn={loggedIn}
        render={() => <FormAgentPage baseUrl={baseUrl} session={session} agent={agent} />}
      />
    </>
  );
});

interface RoutesProps {
  tokens: TokensStoreType;
  session: SessionType;
  access: AccessStoreType;
  settings: SettingsStoreType;
  baseUrl: string;
  store: any;
}
