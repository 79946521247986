import { types, applySnapshot } from "mobx-state-tree";
import { Transport } from "./transport";
import { Notificator } from "./notificator";
import { flow } from "./flow";
import copyToClipboard from "copy-to-clipboard";
import { Queryable } from "modules/common/models/queryable";

/**
 * Template printing anchor
 */
export const PrintAnchorInfo = types
    .compose(
        Notificator,
        types.model({
            value: types.string,
            help: types.string,
            description: types.string,
        })
    )
    .views((self) => ({
        get wrap() {
            return "{" + self.value + "}";
        },
    }))
    .actions((self) => ({
        copy() {
            const success = copyToClipboard(self.wrap, {
                format: "text/plain",
            });

            if (success) {
                self.notify.success("Скопировано");
            }
        },
    }))
    .named("PrintAnchorInfo");

export type PrintAnchorInfoType = typeof PrintAnchorInfo.Type;

export const PrintAnchorInfoList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            url: types.string,
            anchors: types.array(PrintAnchorInfo),
        })
    )
    .views((self) => ({
        get filtered() {
            const predicate = (o: PrintAnchorInfoType) => {
                let result = true;
                if (self.pureQuery) {
                    result = result && `${o.help} ${o.description}`.toLowerCase().indexOf(self.pureQuery) >= 0;
                }

                return result;
            };

            return self.anchors.filter(predicate);
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                const content = yield self.transport.get<any>(self.url);

                applySnapshot(self.anchors, content);

                return true;
            } catch (er) {
                self.notify.error(er);
                applySnapshot(self.anchors, []);
                return false;
            }
        }),
    }));

export const initialState = (url: string): typeof PrintAnchorInfoList.SnapshotType => ({
    url,
    anchors: [],
    pureQuery: "",
    query: "",
});

export type PrintAnchorInfoListType = typeof PrintAnchorInfoList.Type;
