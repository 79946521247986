import { useEffect } from "react";

export const fixTextAreaHeight = (...args: Array<HTMLTextAreaElement | null>) => {
    if (args.length) {
        args.forEach((area) => {
            area && (area.style.height = area.scrollHeight + "px");
        });
    }
};

export const MODAL_AWAIT_DELAY = 200;

export function useTextAreaAutoHeight() {
    let textarea: HTMLTextAreaElement | null = null;

    const oldRef = (value: HTMLTextAreaElement | null) => (textarea = value);

    useEffect(() => {
        setTimeout(() => fixTextAreaHeight(textarea), MODAL_AWAIT_DELAY);
    });

    return oldRef;
}
