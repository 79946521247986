import { applySnapshot, getSnapshot, types } from "mobx-state-tree";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";

export const DataRow = types
  .model({
    tab: types.string,
    type: types.string,
    fieldName: types.string,
    value: types.string,
    prevValue: types.string,
    isNew: types.boolean,
    isUpdate: types.boolean,
    isRemove: types.boolean,
  })
  .named("DataRow");

export const RowData = types.compose(
  DataRow,
  types.model({ rows: types.maybeNull(types.array(DataRow)) }).named("RowData")
);

export const OrderLog = types
  .model({
    id: types.string,
    user: types.string,
    dateText: types.string,
    time: types.string,
    rows: types.array(RowData),
  })
  .named("OrderLog");

export const OrderLogsStore = types
  .compose(
    Transport,
    Notificator,
    types.model({
      loading: types.boolean,
      data: types.array(OrderLog),
      hasMore: types.boolean,
    })
  )
  .actions((self) => ({
    setData(data: OrderLogSnapshotType[], append = true) {
      if (data.length) {
        if (append) {
          applySnapshot(self.data, [...getSnapshot(self.data), ...data]);
        } else {
          applySnapshot(self.data, [...data, ...getSnapshot(self.data)]);
        }
      }
    },
  }))
  .actions((self) => ({
    load: flow(function* (orderId: string) {
      try {
        self.loading = true;
        const fromId = "";
        const data: any[] = yield self.transport.get<any>(apiUrls.orders.logs, {
          params: { orderId, fromId, limit: 10 },
        });
        applySnapshot(self.data, data);
        self.hasMore = true;
        self.loading = false;
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
    loadNext: flow(function* (orderId: string) {
      try {
        const fromId = self.data.length ? self.data[self.data.length - 1].id : "";

        const data: any[] = yield self.transport.get<any>(apiUrls.orders.logs, {
          params: { orderId, fromId, limit: 10 },
        });
        self.hasMore = data.length <= LIMIT;
        self.setData(data);
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
  }))
  .named("OrderLogsStore");

const LIMIT = 10;
export type OrderLogType = typeof OrderLog.Type;
export type OrderLogSnapshotType = typeof OrderLog.SnapshotType;
export type OrderLogsStoreType = typeof OrderLogsStore.Type;
