import React from "react";
import { observer } from "mobx-react";
import styles from "./WorkTypesList.module.scss";
import { columns } from "./columns";
import copyToClipboard from "copy-to-clipboard";
import { saveAgGridColumnSize } from "modules/common/services/table/size-storage";
import { DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { agGridEntityContextMenu } from "modules/common/components/table/EntityContextMenu";
import { WorkTypeList, WorkTypesListType } from "../../models/work-type-list";
import { WorkTypeSnapshotType, formatWorkType } from "../../models/work-type";
import {
  GridOptions,
  CellContextMenuEvent,
  RowClickedEvent,
  GetContextMenuItemsParams,
  GridReadyEvent,
} from "@ag-grid-community/core";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import { agGridLocale } from "modules/root/services/locale";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MAX_INT } from "modules/common/constants";
import { PlanrButton } from "modules/common/components/planr/button/Button";

const modules = [...defaultModules, MenuModule];
const TABLE_NAME = WorkTypeList.name;
const sizeSaver = saveAgGridColumnSize(TABLE_NAME);
const config = columns(TABLE_NAME);

class Internal extends React.Component<WorkTypesListProps> {
  private options: GridOptions;

  constructor(props: any) {
    super(props);
    this.options = DefaultAgGridOptions(true);
  }

  render() {
    const { store, onSelected } = this.props;

    return (
      <RemoveConfirmation<WorkTypeSnapshotType>
        observation={store.data}
        what={this.removeLabel}
        onConfirmed={this.removeWorkType}
        render={({ confirmRemoving }) => {
          const getContextMenuItems = ({ node }: GetContextMenuItemsParams) =>
            agGridEntityContextMenu({
              onCopy: () => copyToClipboard(formatWorkType(node.data), { format: "text/plain" }),
              onChange: () => onSelected(getRowNodeId(node.data)),
              onRemove: () => confirmRemoving(node.data),
            });

          return (
            <>
              <GridTheme bordered={true} className={`designed ${styles.grid}`}>
                <AgGridReact
                  rowStyle={{
                    borderBottom: "1 px solid #EDF0F2",
                    fontFamily: "GothamPro, Arial, sans-serif",
                    color: "#00273D",
                    height: "32px",
                    fontSize: "12px",
                  }}
                  rowSelection="single"
                  suppressCellSelection={true}
                  enableCellTextSelection={true}
                  columnDefs={config}
                  rowData={store.data}
                  localeText={agGridLocale}
                  onRowDoubleClicked={this.onRowSelected}
                  getRowNodeId={getRowNodeId}
                  getContextMenuItems={getContextMenuItems}
                  onGridReady={this.onGridReady}
                  onCellContextMenu={this.onCellContextMenu}
                  onSortChanged={store.sorter.resorted}
                  onColumnResized={sizeSaver}
                  gridOptions={this.options}
                  modules={modules}
                  rowBuffer={MAX_INT}
                />
              </GridTheme>
              <div className={styles.cardList}>
                {store.data.map((row) => {
                  return (
                    <div key={row.id}>
                      <div className={styles.card}>
                        <div className={styles.mid}>
                          <div className={styles.label}>Наименование:</div>
                          <div className={styles.row}>{row.name}</div>

                          {row.description && (
                            <>
                              <div className={styles.label}>Описание:</div>
                              <div className={styles.row}>{row.description}</div>
                            </>
                          )}
                          {row.workTypeCategory && (
                            <>
                              <div className={styles.label}>Категория:</div>
                              <div className={styles.row}>{row.workTypeCategory}</div>
                            </>
                          )}
                        </div>
                        <div className={styles.edit}>
                          <PlanrButton
                            type="lightblueish"
                            icon="general-edit"
                            size="small"
                            onClick={() => {
                              this.props.onSelected(row.id);
                            }}
                          />
                          <PlanrButton
                            type="neutral"
                            icon={"general-trash"}
                            size="small"
                            style={{ marginLeft: "0px", marginTop: "5px", color: "#e31818" }}
                            onClick={() => {
                              confirmRemoving(row);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          );
        }}
      />
    );
  }

  removeLabel = (client: WorkTypeSnapshotType) => `тип работ ${client.name}`;

  onCellContextMenu = ({ node }: CellContextMenuEvent) => {
    node && node.setSelected(true, true);
  };

  onRowSelected = ({ data }: RowClickedEvent) => {
    const { onSelected } = this.props;
    onSelected(getRowNodeId(data));
  };

  onGridReady = async ({ api }: GridReadyEvent) => {
    const { sorter } = this.props.store;
    setTimeout(() => api.setSortModel(sorter.agGridSortModel));
  };

  removeWorkType = async (client: WorkTypeSnapshotType) => {
    const { store, onRemoved } = this.props;
    const success = await store.delete(client);
    success && onRemoved();
  };
}

const getRowNodeId = (e: WorkTypeSnapshotType) => e.id;

export const WorkTypesList = observer(Internal);

interface WorkTypesListProps {
  onRemoved: () => void;
  onSelected: (id: string) => void;
  store: WorkTypesListType;
}
