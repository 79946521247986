import React from "react";
import styles from "./Uploader.module.scss";
import { Spinner, IconName } from "@blueprintjs/core";
import { texts } from "modules/common/texts";
import classNames from "classnames";
import { intent, icon as notificationIcon, timeout } from "modules/root/components/app/Notifications";
import { Notificator } from "modules/root/components/app/toaster";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { take } from "lodash";

export class FileUploaderHOC extends React.PureComponent<
    Omit<FileUploaderProps, "icon"> & {
        render: (p: FileUploaderHOCRenderProps) => React.ReactNode;
        maximum?: number;
    },
    UploaderState
> {
    ref = React.createRef<HTMLInputElement>();
    state = { loading: false };
    mounted = true;

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const { accept, className, title, maximum, render } = this.props;
        const { loading } = this.state;
        const limit = maximum ?? 1;
        const css = classNames("file-uploader", className);

        return (
            <div className={css} title={title || texts.upload} onClick={this.onClick}>
                {render({ loading, onClick: this.onClick })}

                <input
                    accept={accept}
                    type="file"
                    style={{ display: "none" }}
                    ref={this.ref}
                    onChange={this.onFileSelected}
                    multiple={limit > 1}
                />
            </div>
        );
    }

    onClick = (e: React.MouseEvent) => {
        e.stopPropagation();

        if (!this.state.loading) {
            this.ref.current && this.ref.current.click();
        }
    };

    onFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { onFileSelected, maximum } = this.props;
        const limit = maximum ?? 1;

        let source = e.target.files && e.target.files.length ? Array.from(e.target.files) : [];
        source = take(source, limit);

        if (source.length) {
            this.setState({ loading: true });

            for (let file of source) {
                await onFileSelected(file);
            }

            if (this.mounted) {
                this.setState({ loading: false });
                this.ref.current && (this.ref.current.value = "");
            }
        }
    };
}

export class FileUploader extends React.PureComponent<FileUploaderProps, UploaderState> {
    ref = React.createRef<HTMLInputElement>();
    state = { loading: false };
    mounted = true;

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const { accept, icon, title } = this.props;
        const { loading } = this.state;

        return (
            <div>
                {!loading && (
                    <PlanrButton
                        type={icon ? "neutral" : "graybtn"}
                        icon={icon ? "general-plus-small" : "general-upload"}
                        onClick={this.onClick}
                        size={icon ? "small" : "medium"}
                        round={true}
                        title={title || texts.upload}
                    />
                )}

                {loading && <Spinner size={15} />}

                <input
                    accept={accept}
                    type="file"
                    style={{ display: "none" }}
                    ref={this.ref}
                    onChange={this.onFileSelected}
                    multiple={false}
                />
            </div>
        );
    }

    onFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { onFileSelected } = this.props;
        const file = e.target.files ? e.target.files[0] : undefined;

        if (file) {
            this.setState({ loading: true });

            try {
                await onFileSelected(file);
            } catch (er) {
                const message = typeof er === "string" ? er : er.message;

                Notificator.show({
                    message,
                    icon: notificationIcon("error"),
                    intent: intent("error"),
                    timeout: timeout("error"),
                });
            } finally {
                if (this.mounted) {
                    this.setState({ loading: false });
                    this.ref.current && (this.ref.current.value = "");
                }
            }
        }
    };

    onClick = () => {
        if (!this.state.loading) {
            this.ref.current && this.ref.current.click();
        }
    };
}

export const Uploader = (props: UploaderProps) => {
    return <FileUploader {...props} className={`${styles.icon} ${styles.uploader}`} />;
};

interface FileUploaderProps extends UploaderProps {
    className?: string;
    icon?: IconName;
    title?: string;
}

export interface UploaderProps {
    accept: string;
    onFileSelected: (file: File) => Promise<any>;
}

interface UploaderState {
    loading: boolean;
}

interface FileUploaderHOCRenderProps {
    onClick: (e: React.MouseEvent) => void;
    loading: boolean;
}
