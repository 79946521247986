// import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import React from "react";
// import { Link } from "react-router-dom";
import styles from "./AppLogo.module.scss";
// import { routes } from "modules/common/routes";

export const AppLogo = () => {
    return (
        <div className={`${styles.logo} app-logo`}>
            {/* <Link to={routes.dictionaries.employeePosition.path} className="add-order-button" title="">
                <GeneralIcon
                    type="general-exclamation"
                    size={34}
                    className={styles.warning}
                    style={{ color: "#E31818" }}
                />
            </Link> */}
        </div>
    );
};
