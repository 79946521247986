import { observer } from "mobx-react";
import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import styles from "./Linker.module.scss";
import portal from "../portal";
import {
    OrderStagesStoreType,
    OrderStageRowType,
    OrderCompletionRowType,
} from "modules/orders-manage/models/order-stages-store";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { Completion } from "./Completion";

export const LinkCompletions = observer(({ stage, store }: LinkCompletionsProps) => {
    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (result.destination) {
                const guid = result.destination.droppableId === "unlink" ? "" : stage.newGuid;
                const completionGuid = result.draggableId;
                const completion = store.completionMap[completionGuid];

                completion.linkToStage(stage.newGuid, guid);
            }
        },
        [stage, store]
    );

    const unlinkCompletion = (completion: OrderCompletionRowType) => {
        completion.linkToStage(stage.newGuid, "");
    };

    const { used, available } = splitByStage(store, stage.newGuid);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className={styles.paymentLinker}>
                <div>
                    <h2>{stage.name}</h2>
                    <Droppable droppableId={stage.newGuid}>
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={`${styles.dropHere} ${snapshot.isDraggingOver ? styles.isDraggingOver : ""}`}
                            >
                                {used.map((completion, index) => (
                                    <Draggable key={completion.newGuid} draggableId={completion.newGuid} index={index}>
                                        {(draggable, sn) => {
                                            const child = (
                                                <Completion
                                                    completion={completion}
                                                    key={completion.newGuid}
                                                    draggable={draggable}
                                                >
                                                    <div className={styles.unlink}>
                                                        <GeneralIcon
                                                            type="general-cross-small"
                                                            onClick={() => unlinkCompletion(completion)}
                                                            title="Отвязать акт"
                                                        />
                                                    </div>
                                                </Completion>
                                            );
                                            return sn.isDragging ? ReactDOM.createPortal(child, portal) : child;
                                        }}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                <div className={styles.pointer}>Перетащите нужный акт сюда</div>
                            </div>
                        )}
                    </Droppable>
                </div>

                <div>
                    <h2>Все акты выполненных работ</h2>
                    <Droppable droppableId="unlink">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={`${styles.dropHere} ${snapshot.isDraggingOver ? styles.isDraggingOver : ""}`}
                            >
                                {available.map((completion, index) => (
                                    <Draggable key={completion.newGuid} draggableId={completion.newGuid} index={index}>
                                        {(draggable, sn) => {
                                            const child = (
                                                <Completion
                                                    completion={completion}
                                                    key={completion.newGuid}
                                                    draggable={draggable}
                                                />
                                            );
                                            return sn.isDragging ? ReactDOM.createPortal(child, portal) : child;
                                        }}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </div>
        </DragDropContext>
    );
});

interface LinkCompletionsProps {
    stage: OrderStageRowType;
    store: OrderStagesStoreType;
}

function splitByStage(self: OrderStagesStoreType, stage: string) {
    const used: OrderCompletionRowType[] = [];
    const available: OrderCompletionRowType[] = [];

    self.completions.forEach((completion) => {
        if (completion.removed) {
            return;
        }

        if (completion.stageGuid === stage) {
            used.push(completion);
        } else if (!completion.stageGuid) {
            available.push(completion);
        }
    });

    return { used, available };
}
