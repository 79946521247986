import { types } from "mobx-state-tree";
import { OrderCommunicationSnapshotType, OrderCommunicationType } from "./order";

const OrderCommunicationRow = types.model({
    guid: types.string,
    employer: types.string,
    employerName: types.string,
    employerPhone: types.string,
    employerEmail: types.string,
    employerCompanyName: types.string,
    timeDescription: types.string,
    responses: types.array(types.string),
});

export type OrderCommunicationRowType = typeof OrderCommunicationRow.Type;
export type OrderCommunicationRowSnapshotType = typeof OrderCommunicationRow.SnapshotType;

export const OrderCommunication = types
    .model({
        projectManager: types.string,
        projectManagerPhone: types.string,
        projectManagerMobilePhone: types.string,
        projectManagerEmail: types.string,
        projectManagerName: types.string,
        orderCommunicationRows: types.maybeNull(types.array(OrderCommunicationRow)),
    })
    .named("OrderCommunication");

export const mapOrderCommunication = (com: OrderCommunicationType): SaveOrderCommunication => ({
    projectManager: com.projectManager,
    orderCommunicationRows: com.orderCommunicationRows,
    projectManagerName: com.projectManagerName,
    projectManagerPhone: com.projectManagerPhone,
    projectManagerMobilePhone: com.projectManagerMobilePhone,
    projectManagerEmail: com.projectManagerEmail,
});

export const initialOrderCommunication = (): OrderCommunicationSnapshotType => ({
    projectManager: "",
    projectManagerName: "",
    projectManagerPhone: "",
    projectManagerMobilePhone: "",
    projectManagerEmail: "",
    orderCommunicationRows: [],
});

export interface SaveOrderCommunication {
    projectManager: string;
    projectManagerName: string;
    projectManagerPhone: string;
    projectManagerMobilePhone: string;
    projectManagerEmail: string;
    orderCommunicationRows: SaveOrderCommunicationRow[] | null;
}

export interface SaveOrderCommunicationRow {
    guid: string;
    employer: string;
    employerName: string;
    employerPhone: string;
    employerEmail: string;
    employerCompanyName: string;
    timeDescription: string;
    responses: string[];
}
