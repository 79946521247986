import React from "react";
import { OrderCompletionRowType } from "modules/orders-manage/models/order-stages-store";
import { DraggableProvided } from "react-beautiful-dnd";
import { observer } from "mobx-react";
import { PlanrTag } from "modules/common/components/planr/tag/PlanrTag";
import styles from "./Completion.module.scss";
import { formatMoney } from "modules/common/components/money/Money";
import { FileLink } from "modules/common/components/files/FileLink";
import { CompletionFileType } from "modules/orders-manage/models/order-file";
import { eat } from "modules/common/services/typescript";

type CompletionProps = {
    draggable?: DraggableProvided;
    completion: OrderCompletionRowType;
};

export const Completion: React.FC<CompletionProps> = observer(({ completion, draggable, children }) => {
    const draggablePrps = draggable
        ? {
              ref: draggable.innerRef,
              ...draggable.draggableProps,
              ...draggable.dragHandleProps,
          }
        : {};

    return (
        <div className={`${styles.planPayment} ${styles.completion}`} {...draggablePrps}>
            <div style={{ display: "flex" }}>
                <div className={styles.paymentNumber}>№{completion.name}</div>
                <div className={styles.paymentSum}>
                    <PlanrTag type={"neutral"}>{formatMoney(completion.sum)}</PlanrTag>
                </div>
                {children}
            </div>
            {completion.file && (
                <FileLink<CompletionFileType>
                    baseUrl={completion.baseUrl}
                    file={completion.file}
                    onRemove={eat}
                    readOnly={true}
                />
            )}
        </div>
    );
});
