import React from "react";
import { observer } from "mobx-react";
import styles from "./EmployerList.module.scss";
import { columnsRemoved, RestoreActions } from "./columns";
import { saveAgGridColumnSize } from "modules/common/services/table/size-storage";
import { DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import { EmployeeListType, EmployeeList } from "../../models/employer-list";
import {
    RowClickedEvent,
    GetContextMenuItemsParams,
    GridReadyEvent,
    CellContextMenuEvent,
} from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { agGridLocale } from "modules/root/services/locale";
import { EmployerSnapshotType, formatEmployer } from "../../models/employer";
import { agGridEntityContextMenu, UndoIcon } from "modules/common/components/table/EntityContextMenu";
import copyToClipboard from "copy-to-clipboard";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { MAX_INT } from "modules/common/constants";
import { texts } from "modules/common/texts";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";

const modules = [...defaultModules, MenuModule];
const TABLE_NAME = EmployeeList.name + "Removed";
const sizeSaver = saveAgGridColumnSize(TABLE_NAME);
const options = DefaultAgGridOptions(true);

class Internal extends React.Component<RemovedListProps> implements RestoreActions {
    confirmRestore?: (e: EmployerSnapshotType) => void;

    render() {
        const { store, hidden, canChange } = this.props;
        const config = columnsRemoved(TABLE_NAME, canChange);

        return (
            <RemoveConfirmation<EmployerSnapshotType>
                observation={store.data}
                what={(e) => `сотрудника ${e.name}`}
                onConfirmed={this.onConfirmed}
                actionName={texts.restore.toLowerCase()}
                render={({ confirmRemoving }) => {
                    this.confirmRestore = confirmRemoving;
                    return (
                        <GridTheme
                            bordered={true}
                            className={`designed  ${styles.grid} ${hidden ? styles.hidden : ""}`}
                        >
                            <AgGridReact
                                rowStyle={{
                                    borderBottom: "1 px solid #EDF0F2",
                                    fontFamily: "GothamPro, Arial, sans-serif",
                                    color: "#00273D",
                                    fontSize: "12px",
                                }}
                                rowSelection="single"
                                suppressCellSelection={true}
                                enableCellTextSelection={true}
                                columnDefs={config}
                                rowData={store.data}
                                localeText={agGridLocale}
                                onRowDoubleClicked={this.onRowSelected}
                                getRowNodeId={getRowNodeId}
                                getContextMenuItems={this.getContextMenuItems}
                                onGridReady={this.onGridReady}
                                onCellContextMenu={this.onCellContextMenu}
                                onSortChanged={store.sorter.resorted}
                                onColumnResized={sizeSaver}
                                gridOptions={options}
                                modules={modules}
                                rowBuffer={MAX_INT}
                                context={this}
                                immutableData={true}
                            />
                        </GridTheme>
                    );
                }}
            />
        );
    }

    onCellContextMenu = ({ node }: CellContextMenuEvent) => {
        node && node.setSelected(true, true);
    };

    getContextMenuItems = ({ node }: GetContextMenuItemsParams) => {
        return [
            ...agGridEntityContextMenu({
                onCopy: () => copyToClipboard(formatEmployer(node.data), { format: "text/plain" }),
            }),
            {
                name: texts.restore,
                icon: UndoIcon,
                action: () => this.confirmRestore && this.confirmRestore(node.data),
            },
        ];
    };

    onRowSelected = ({ data }: RowClickedEvent) => {
        const { onEmployerSelected } = this.props;
        onEmployerSelected(getRowNodeId(data));
    };

    onGridReady = async ({ api }: GridReadyEvent) => {
        const { sorter } = this.props.store;
        setTimeout(() => api.setSortModel(sorter.agGridSortModel));
    };

    onConfirmed = (user: EmployerSnapshotType) => {
        const { onEmployerRestore } = this.props;
        onEmployerRestore(user.id);
    };
}

export const RemovedList = observer(Internal);

const getRowNodeId = (e: EmployerSnapshotType) => e.id;

interface RemovedListProps {
    onEmployerRestore: (id: string) => void;
    onEmployerSelected: (id: string) => void;
    store: EmployeeListType;
    hidden?: boolean;
    canChange: boolean;
}
