import React from "react";
import { ValueFormatterParams } from "@ag-grid-community/core";

export const gridMoneyFormatter = (params: ValueFormatterParams) => {
    if (params.value === null || params.value === undefined) {
        return "";
    }

    const value: number = params.value;
    return defaultFormatter.format(value);
};

export const numberFormatter = (num: number) => {
    if (num === null || num === undefined) {
        return "";
    }

    const value: number = num;
    return defaultFormatter.format(value);
};

export const gridMoneyFormatterWithCurrency = (params: ValueFormatterParams) => {
    if (params.value === null) {
        return "";
    }

    const value: number = params.value;
    return formatMoney(value);
};

export const defaultFormatter = new Intl.NumberFormat("ru-RU", {
    useGrouping: true,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});

export const noFractionFormatter = new Intl.NumberFormat("ru-RU", {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export const Money = ({ amount, noSymbol, noFraction, className, onClick, title, style }: MoneyProps) => (
    <span className={`money ${className || ""}`} onClick={onClick} title={title} style={style}>
        {formatMoney(amount, { noSymbol, noFraction })}
    </span>
);

export const formatMoney = (amount: number, options?: MoneyOptions) => {
    const { noSymbol, noFraction } = options || {};

    const formater = amount === 0 ? noFractionFormatter : noFraction ? noFractionFormatter : defaultFormatter;

    let text = formater.format(amount);

    if (!noSymbol) {
        text += " ₽";
    }

    return text.replace(",", ".");
};

interface MoneyOptions {
    noSymbol?: boolean;
    noFraction?: boolean;
}

interface MoneyProps extends MoneyOptions {
    amount: number;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLSpanElement>;
    title?: string;
    style?: React.CSSProperties;
}
