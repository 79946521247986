import React from "react";
import { Dialog, Classes } from "@blueprintjs/core";
import { texts } from "modules/common/texts";

export const InvalidVersion = () => {
    return (
        <Dialog
            autoFocus={true}
            isOpen={true}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            isCloseButtonShown={false}
            title={texts.error}
            icon="error"
            className="figma-dialog"
        >
            <div className={Classes.DIALOG_BODY}>Неверная версия приложения. Работа не может быть продолжена.</div>
        </Dialog>
    );
};
