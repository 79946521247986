import React from "react";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Block } from "modules/common/components/page/Block";
import { Route, withRouter, RouteComponentProps, matchPath } from "react-router";
import { routes } from "modules/common/routes";
import { UnregisterCallback } from "history";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { CompanyBankDetailsStoreType } from "../models/bank-details-store";
import { BankDetailsDetails } from "./details/BankDetailsDetails";
import { BankDetailsList } from "./list/BankDetailsList";
import { menuItems } from "modules/root/components/menu/MainMenu";
import func from "modules/dictionaries/functionalities";
import { can } from "modules/session/auth/access";

import styles from "./BankDetailsPage.module.scss";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { Search } from "modules/common/components/form/Search";
import { CompanyBankDetailsSnapshotType } from "../models/bank-details";

const menu = menuItems();

class BankDetailsPage extends React.Component<BankDetailsPageProps & RouteComponentProps, BankDetailsPageState> {
  private unregister: UnregisterCallback | null = null;

  constructor(props: BankDetailsPageProps & RouteComponentProps) {
    super(props);

    this.state = { detailsId: getSelectedBankDetailsId(props.location.pathname) };
  }

  componentDidMount() {
    document.title = menu.dictionaries.nested.bankDetais.label;

    const { history } = this.props;
    const { detailsId } = this.state;

    this.props.store.init(detailsId);

    this.unregister = history.listen((location) => {
      const id = getSelectedBankDetailsId(location.pathname);

      if (this.state.detailsId !== id) {
        this.setState({ detailsId: id });
        this.loadDetails(id);
      }
    });
  }

  componentWillUnmount() {
    this.unregister && this.unregister();
  }

  render() {
    const { store, access } = this.props;
    const canChange = can(func.BANKDETAILS_EDIT, access);

    return (
      <Block columned={true} className={styles.page}>
        <Route path={routes.dictionaries.bankDetails.details()}>
          <DetailsPopup title="Реквизиты" onCancel={this.onBankDetailsSelected}>
            <BankDetailsDetails
              canChange={canChange}
              bankDetails={store.details}
              onSave={this.onBankDetailsSave}
              onSaved={this.onBankDetailsSaved}
              onRemove={this.onBankDetailsRemove}
            />
          </DetailsPopup>
        </Route>
        <div className={styles.tools}>
          {canChange && (
            <PlanrButton
              type="secondary"
              size="small"
              icon="general-plus-big"
              onClick={this.onBankDetailsAdd}
              style={{
                width: "230px",
                marginBottom: "32px",
                borderRadius: "120px",
              }}
            >{`${texts.add} реквизиты`}</PlanrButton>
          )}
        </div>
        <div className={styles.mobileTools}>
          {canChange && <PlanrButton type="secondary" round icon="general-plus-big" onClick={this.onBankDetailsAdd} />}
        </div>
        <div className={styles.right}>
          <Search query={store.list.query} onSearch={store.list.setQuery} />
        </div>

        <BankDetailsList
          store={store.list}
          onBankDetailsSelected={this.onBankDetailsSelected}
          onBankDetailsRemoved={this.onBankDetailsRemoved}
        />
      </Block>
    );
  }

  onBankDetailsAdd = () => {
    this.onBankDetailsSelected(EMPTY_OBJECT_ID);
  };

  onBankDetailsSelected = (id: string = "") => {
    const { history } = this.props;

    const url = id ? routes.dictionaries.bankDetails.details(id) : routes.dictionaries.bankDetails.path;

    history.push(url);
  };

  onBankDetailsSave = (values: CompanyBankDetailsSnapshotType) => {
    return this.props.store.details.save(values);
  };

  onBankDetailsSaved = () => {
    this.onBankDetailsSelected("");
    this.loadList();
  };

  onBankDetailsRemove = async () => {
    const ok = await this.props.store.details.delete();

    if (ok) {
      this.onBankDetailsRemoved();
    }

    return ok;
  };

  onBankDetailsRemoved = () => {
    this.loadList();

    const url = routes.dictionaries.bankDetails.path;
    this.props.history.push(url);
  };

  loadList = () => this.props.store.list.load();

  loadDetails = (id: string) => this.props.store.details.load(id);
}

export default withRouter(observer(BankDetailsPage));

function getSelectedBankDetailsId(pathname: string) {
  const match = matchPath<BankDetailsPageState>(pathname, routes.dictionaries.bankDetails.details());

  return match && match.params ? match.params.detailsId : "";
}

interface BankDetailsPageProps {
  store: CompanyBankDetailsStoreType;
  access: string[];
}

interface BankDetailsPageState {
  detailsId: string;
}
