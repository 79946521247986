import { Classes, Collapse, Dialog, InputGroup, Spinner, TextArea } from "@blueprintjs/core";
import { FieldProps } from "formik";
import { findIndex } from "lodash";
import { Caret } from "modules/common/components/collapse/Caret";
import { move } from "modules/common/services/array";
import { formatDate } from "modules/common/services/formatting/date";
import {
    OrderObjectSnapshotType,
    WorkTypeLinkSnapshotType,
    filterObjectUnits,
    getCategoryProgress,
    getObjectsToDisplay,
} from "modules/orders-manage/models/order";
import React from "react";
import { OTHER_WORKS } from "../constants";
import { Block } from "./Block";

import { observer } from "mobx-react";
import { FileLink } from "modules/common/components/files/FileLink";
import { Buttons } from "modules/common/components/form";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { DATE_TIME_FORMAT } from "modules/common/constants";
import {
    DefaultSelectedOption,
    SimpleSelect,
    filterItemPredicate,
    renderSingleOption,
} from "modules/common/services/form/select";
import { eat } from "modules/common/services/typescript";
import {
    ACCEPT,
    OrderContentTasksDictionaryType,
    OrderContentTasksListSnapshotType,
} from "modules/orders-manage/models/order-content-tasks";
import { SectionCollapserType } from "modules/orders-manage/models/orders-store";
import { EmployerDictionaryType } from "modules/spending/employee/models/employee-dictionary";
import moment from "moment";
import styles from "./CategoryBlock.module.scss";

type TObject = OrderObjectSnapshotType;
type TTasks = OrderContentTasksListSnapshotType;
type TUnit = WorkTypeLinkSnapshotType;

export const titleIndicator = "Кол-во неподтвержденных заданий и незаполненных заданий при наличии результата";

class CategoryBlockBase extends React.Component<CategoryBlockProps, CategoryBlockState> {
    constructor(props: CategoryBlockProps) {
        super(props);
        this.state = {
            showDelete: false,
            workGuid: "",
            taskId: "",
            showUploadFiles: false,
            workFiles: [],
            developers: [],
            taskFiles: [],
            selectedUnits: [],
            saveUnits: [],
            taskFile: null,
            showAdd: false,
            showSheet: false,
            showUnits: false,
            description: "",
            isUploading: false,
            stopDate: null,
            isEditeMode: false,
            implementer: { id: "", label: "" },
        };
    }

    render() {
        const {
            category,
            onToggleCollapse,
            collapsed,
            objects,
            readOnly,
            fieldProps,
            store,
            baseUrl,
            orderId,
            user,
            collapserContent,
            employee,
            highlightRow,
        } = this.props;

        const {
            workGuid,
            taskId,
            showUploadFiles,
            workFiles,
            taskFile,
            description,
            isEditeMode,
            showSheet,
            developers,
            isUploading,
            taskFiles,
            selectedUnits,
            showUnits,
            implementer,
            saveUnits,
            stopDate,
        } = this.state;

        const objectsToDisplay = getObjectsToDisplay(category, objects);
        const progress = getCategoryProgress(category, objectsToDisplay);
        const touched = !!fieldProps.form.touched[fieldProps.field.name];
        let newItems: WorkTypeLinkSnapshotType[] = [];

        objects.forEach((object, i) => {
            object.content.forEach((unit) => {
                newItems.push(unit);
            });
        });

        let allTasksCount = 0;
        objectsToDisplay.forEach((object, i) => {
            // checked work types sorted by user order
            const filteredUnits = filterObjectUnits(object, category);
            // original indexes for dnd sorting
            filteredUnits.forEach((unit) => {
                const contentTask = store.contentTasksList.filter((item) => item.guid === unit.guid)[0];
                let tasksCount = 0;
                if (contentTask) {
                    tasksCount = contentTask.tasks.filter((t) => t.confirmed === false).length;

                    if (tasksCount > 0) {
                        allTasksCount += tasksCount;
                    }
                }
            });
        });
        return (
            <div className={styles.categoryBlock}>
                <Dialog
                    title={`Формирование ИУЛ`}
                    isOpen={showSheet}
                    onClose={this.toggleShowDevelopers}
                    backdropClassName="standard"
                    className={`${styles.FilesDialog} figma-dialog`}
                >
                    <div className={`${Classes.DIALOG_BODY} `}>
                        {developers.length > 0 && (
                            <div className={styles.addFilesDialog}>
                                <div className={styles.addFilesDialogColumn}>
                                    <div className={styles.dialogLabel}>Фамилия</div>
                                </div>
                                <div className={styles.addFilesDialogColumn}>
                                    <div className={styles.dialogLabel}>Характер работы</div>
                                </div>
                            </div>
                        )}

                        {developers.map((item, index) => (
                            <div className={styles.addFilesDialog} key={index}>
                                <div className={styles.addFilesDialogColumn}>
                                    <InputGroup
                                        type="text"
                                        className="planr-default-input"
                                        autoComplete="off"
                                        data-lpignore="true"
                                        value={item.name}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.changeDeveloperRow(index, 0, e.target.value);
                                        }}
                                    />
                                </div>
                                <div className={styles.addFilesDialogColumn}>
                                    <InputGroup
                                        type="text"
                                        className="planr-default-input"
                                        autoComplete="off"
                                        data-lpignore="true"
                                        value={item.type}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.changeDeveloperRow(index, 1, e.target.value);
                                        }}
                                    />
                                </div>
                                <GeneralIcon
                                    type="general-trash"
                                    style={{
                                        marginTop: "10px",
                                        marginLeft: "16px",
                                        color: "#E31818",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        this.removeDeveloper(index);
                                    }}
                                />
                            </div>
                        ))}
                        <PlanrButton
                            type="secondary"
                            size="small"
                            onClick={this.addNewDeveloper}
                            style={{ margin: "0 auto", width: "400px" }}
                        >
                            Добавить исполнителя
                        </PlanrButton>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <Buttons
                            left={
                                <div style={{ display: "flex" }}>
                                    <PlanrButton size="small" type="greenish" onClick={this.saveDevelopers}>
                                        Сформировать
                                    </PlanrButton>
                                    <PlanrButton size="small" type="graybtn" onClick={this.toggleShowDevelopers}>
                                        Отмена
                                    </PlanrButton>
                                </div>
                            }
                        />
                    </div>
                </Dialog>
                <Dialog
                    title={`Загрузка файлов результата выполнения работ`}
                    isOpen={showUploadFiles}
                    onClose={this.toggleShowUploadFiles}
                    backdropClassName="standard"
                    className={`${styles.FilesDialog} figma-dialog`}
                >
                    <div className={`${Classes.DIALOG_BODY} `}>
                        <div className={styles.addFilesDialog}>
                            <div className={styles.addFilesDialogColumn}>
                                <div className={styles.dialogLabel}>Исходные файлы</div>
                                {workFiles.map((file, index) => (
                                    <FileLink<any>
                                        key={index}
                                        baseUrl={baseUrl}
                                        file={{ fileId: file.id, fileName: file.name }}
                                        style={{
                                            height: " 50px",
                                            width: "100%",
                                            border: "3px dashed #edf0f2",
                                            marginBottom: "5px",
                                        }}
                                        readOnly={false}
                                        onRemove={() => {
                                            this.removeWorkFilesFromState(file.id);
                                        }}
                                    />
                                ))}
                                <PlanRDndUploader
                                    accept={"*"}
                                    multiple={true}
                                    withLoader={true}
                                    style={{
                                        border: "3px dashed #edf0f2",
                                        height: " 50px",
                                        width: "100%",
                                    }}
                                    onFileSelected={this.workFilesUpload}
                                />
                            </div>
                            <div className={styles.addFilesDialogColumn}>
                                <div className={styles.dialogLabel}>Файл результата</div>
                                {!taskFile && (
                                    <PlanRDndUploader
                                        accept={ACCEPT}
                                        multiple={false}
                                        withLoader={true}
                                        style={{
                                            border: "3px dashed #edf0f2",
                                            height: " 50px",
                                            width: "100%",
                                        }}
                                        onFileSelected={this.taskFileLoad}
                                    />
                                )}
                                {taskFile && (
                                    <FileLink<any>
                                        baseUrl={baseUrl}
                                        file={{ fileId: taskFile.id, fileName: taskFile.name }}
                                        style={{
                                            height: " 50px",
                                            width: "100%",
                                            marginBottom: "0px",
                                            border: "3px dashed #edf0f2",
                                        }}
                                        readOnly={false}
                                        onRemove={this.resetFile}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <Buttons
                            left={
                                <div style={{ display: "flex" }}>
                                    <PlanrButton
                                        size="small"
                                        type="greenish"
                                        onClick={this.saveUploadedWorkFiles}
                                        style={{ minWidth: "120px" }}
                                        disabled={isUploading}
                                    >
                                        {isUploading && <Spinner intent="primary" size={16} />}
                                        {!isUploading && "Сохранить"}
                                    </PlanrButton>
                                    <PlanrButton size="small" type="graybtn" onClick={this.toggleShowUploadFiles}>
                                        Отмена
                                    </PlanrButton>
                                </div>
                            }
                        />
                    </div>
                </Dialog>

                <Dialog
                    title={`Удаление задания`}
                    isOpen={this.state.showDelete}
                    onClose={this.toggleDeleteShow}
                    backdropClassName="standard"
                    className={`${styles.smallDialog} figma-dialog`}
                >
                    <div className={`${Classes.DIALOG_BODY} `}>
                        <div>Вы действительно хотите удалить задание?</div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <Buttons
                            left={
                                <div style={{ display: "flex" }}>
                                    <PlanrButton
                                        size="small"
                                        type="danger"
                                        onClick={() => {
                                            this.deleteTask(taskId);
                                            this.toggleDeleteShow();
                                        }}
                                    >
                                        Удалить
                                    </PlanrButton>
                                    <PlanrButton size="small" type="graybtn" onClick={this.toggleDeleteShow}>
                                        Отмена
                                    </PlanrButton>
                                </div>
                            }
                        />
                    </div>
                </Dialog>

                <Dialog
                    title={`Задание`}
                    isOpen={this.state.showAdd}
                    onClose={this.closeAddDialog}
                    backdropClassName="standard"
                    className={`${styles.smallDialog} figma-dialog`}
                    style={{ width: "700px" }}
                >
                    <div className={`${Classes.DIALOG_BODY} `}>
                        <div>
                            <div className={styles.dialogLabel}>Название</div>
                            <div className={`${Classes.INPUT_GROUP}  planr-default-input`}>
                                <TextArea
                                    growVertically={true}
                                    rows={1}
                                    autoComplete="off"
                                    placeholder=""
                                    data-lpignore="true"
                                    value={description}
                                    className={`${Classes.FILL} planr-default-input`}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        this.setState({ description: e.target.value });
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", width: "100%" }}>
                            <div style={{ width: "50%" }}>
                                <div className={styles.dialogLabel}>Дата окончания</div>
                                <DatePicker
                                    clasName="planr-default-input"
                                    value={stopDate}
                                    // minDate={form.values[fields.startDate]}
                                    onChange={(date) => {
                                        this.setState({ stopDate: date });
                                    }}
                                />
                            </div>
                            <div style={{ width: "50%", marginLeft: "20px" }}>
                                <div className={styles.dialogLabel}>Исполнитель</div>
                                <SimpleSelect
                                    className={`full-width-select ${Classes.FILL}`}
                                    activeItem={implementer}
                                    items={employee.employee.filter((emp) => !emp.fired)}
                                    itemRenderer={renderSingleOption}
                                    onItemSelect={(i) => {
                                        this.setState({ implementer: i });
                                    }}
                                    popoverProps={{
                                        boundary: "viewport",
                                        popoverClassName: "import-spendings-table__select",
                                    }}
                                    inputProps={{
                                        placeholder: "Расход",
                                    }}
                                    filterable={true}
                                    itemPredicate={filterItemPredicate}
                                >
                                    <DefaultSelectedOption option={implementer} />
                                </SimpleSelect>
                            </div>
                        </div>
                        <div className={styles.dialogLabel}>Файлы задания</div>

                        {taskFiles &&
                            taskFiles.length > 0 &&
                            taskFiles.map((file) => (
                                <div style={{ display: "flex", width: "100%" }} key={file.id}>
                                    <div style={{ width: "50%" }}>
                                        <div className={styles.dialogLabelSmall}>Путь к файлу</div>
                                        <div
                                            className={`${Classes.INPUT_GROUP}  planr-default-input`}
                                            style={{ width: "100%" }}
                                        >
                                            <InputGroup
                                                className="planr-default-input"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    let newFiles: TTaskFilePath[] = [];
                                                    taskFiles.forEach((f) => {
                                                        let newItem: TTaskFilePath = {
                                                            id: f.id,
                                                            path: f.path,
                                                            name: f.name,
                                                        };
                                                        if (f.id === file.id) {
                                                            newItem.path = e.target.value;
                                                        }
                                                        newFiles.push(newItem);
                                                    });

                                                    this.setState({ taskFiles: newFiles });
                                                }}
                                                value={file.path}
                                                style={{ fontSize: "12px" }}
                                                title={file.path}
                                            />
                                        </div>
                                    </div>{" "}
                                    <div style={{ width: "50%", marginLeft: "10px" }}>
                                        <div className={styles.dialogLabelSmall}>Файл задания</div>
                                        <FileLink<any>
                                            key={file.id}
                                            baseUrl={baseUrl}
                                            file={{ fileId: file.id, fileName: file.name }}
                                            style={{
                                                height: " 38px",
                                                width: "100%",
                                                border: "3px dashed #edf0f2",
                                            }}
                                            readOnly={false}
                                            onRemove={() => {
                                                this.resetFiles(file.id);
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}

                        <PlanRDndUploader
                            accept={"*"}
                            multiple={true}
                            withLoader={true}
                            style={{
                                border: "3px dashed #edf0f2",
                                height: " 50px",
                                width: "100%",
                                maxWidth: "100%",
                                marginTop: "5px",
                            }}
                            onFileSelected={this.taskFilesLoad}
                        />
                        {!isEditeMode && (
                            <div>
                                <div className={styles.dialogLabel}>Список работ</div>
                                <div className={styles.workItemContainer}>
                                    {newItems.map((unit) => {
                                        const item = saveUnits.find((u) => u === unit.guid);
                                        return item ? (
                                            <div
                                                className={styles.workItem}
                                                style={{ fontSize: "13px" }}
                                                key={unit.guid}
                                            >
                                                <PlanrButton
                                                    icon={item ? "general-checkbox" : "general-zero-checkbox"}
                                                    type="neutral"
                                                    size="small"
                                                    onClick={
                                                        unit.guid === workGuid
                                                            ? eat
                                                            : () => {
                                                                  this.setSaveUnits(unit.guid);
                                                              }
                                                    }
                                                />
                                                <div>{` ${unit.name} ${unit.description}`}</div>
                                            </div>
                                        ) : null;
                                    })}
                                </div>
                                <PlanrButton
                                    size="small"
                                    type="secondary"
                                    onClick={this.showSelectedUnits}
                                    className={styles.changeWorkListBtn}
                                >
                                    Изменить
                                </PlanrButton>
                            </div>
                        )}
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <Buttons
                            left={
                                <div style={{ display: "flex" }}>
                                    <PlanrButton
                                        size="small"
                                        type="greenish"
                                        onClick={() => {
                                            this.saveTask(taskId, workGuid, user);
                                        }}
                                    >
                                        Сохранить
                                    </PlanrButton>
                                    <PlanrButton size="small" type="graybtn" onClick={this.closeAddDialog}>
                                        Отмена
                                    </PlanrButton>
                                </div>
                            }
                        />
                    </div>
                </Dialog>

                <Dialog
                    title={`Виды работ`}
                    isOpen={showUnits}
                    onClose={this.cancelSelectedUnits}
                    backdropClassName="standard"
                    className={`${styles.smallDialog} ${styles.workDialog} figma-dialog`}
                >
                    <div className={`${Classes.DIALOG_BODY} `}>
                        <div className={styles.workItemContainer}>
                            {newItems.map((unit) => {
                                const item = selectedUnits.find((u) => u === unit.guid);
                                return (
                                    <div className={styles.workItem} key={unit.guid}>
                                        <PlanrButton
                                            icon={item ? "general-checkbox" : "general-zero-checkbox"}
                                            type="neutral"
                                            size="small"
                                            onClick={
                                                unit.guid === workGuid
                                                    ? eat
                                                    : () => {
                                                          this.setSelectedUnits(unit.guid);
                                                      }
                                            }
                                        />
                                        <div>{` ${unit.name} ${unit.description}`}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <Buttons
                            left={
                                <div style={{ display: "flex" }}>
                                    <PlanrButton size="small" type="greenish" onClick={this.saveSelectedUnits}>
                                        Сохранить
                                    </PlanrButton>
                                    <PlanrButton size="small" type="graybtn" onClick={this.cancelSelectedUnits}>
                                        Отмена
                                    </PlanrButton>
                                </div>
                            }
                        />
                    </div>
                </Dialog>
                <h1 className="planr-block-header collapser" onClick={onToggleCollapse}>
                    <div className={styles.indicator}>
                        {allTasksCount > 0 && (
                            <span className={styles.countIndicator} title={titleIndicator}>
                                {allTasksCount}
                            </span>
                        )}
                    </div>
                    {category || OTHER_WORKS}
                    <span
                        style={{ marginLeft: "1110px" }}
                        className={
                            progress === 0
                                ? "collapse-percents color-red"
                                : progress < 50
                                ? "collapse-percents color-orange"
                                : progress >= 50 && progress < 75
                                ? "collapse-percents color-blue"
                                : progress >= 75 && progress < 100
                                ? "collapse-percents color-purple"
                                : progress === 100
                                ? "collapse-percents color-green"
                                : "collapse-percents collapse-progress"
                        }
                    >
                        {progress.toFixed(0)}%
                    </span>
                    <Caret collapsed={collapsed} />
                </h1>

                <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                    <div className="collapse">
                        <div className="columns-vertical">
                            {objectsToDisplay.map((object, i) => {
                                // checked work types sorted by user order
                                const filteredUnits = filterObjectUnits(object, category)
                                    // original indexes for dnd sorting
                                    .map((unit) => ({ unit, index: object.content.indexOf(unit) }));

                                return (
                                    <div key={object.guid} className={styles.content}>
                                        <div>
                                            {category && (
                                                <div className={styles.objectTitle}>{object.name || "Общее"}</div>
                                            )}
                                            <Block
                                                // activation={({ onActivate }) => (activation = onActivate)}
                                                category={category}
                                                touched={touched}
                                                units={filteredUnits}
                                                collapserContent={collapserContent}
                                                onSort={(from, to) => this.resortUnits(object, from, to)}
                                                onChange={this.setTasksValue}
                                                readOnly={readOnly}
                                                store={store}
                                                baseUrl={baseUrl}
                                                orderId={orderId}
                                                toggleDeleteShow={this.toggleDeleteShow}
                                                toggleShowUploadFiles={this.showUploadFilesDialog}
                                                showAddTask={this.showAddTask}
                                                user={user}
                                                setTaskId={(id: string) => {
                                                    this.setState({ taskId: id });
                                                }}
                                                setGuid={(guid: string) => {
                                                    this.setState({ workGuid: guid });
                                                }}
                                                showEditTask={this.showEditTask}
                                                showDevelopersDialog={this.showDevelopersDialog}
                                                highlightRow={highlightRow}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }

    toggleDeleteShow = () => {
        this.setState({ showDelete: !this.state.showDelete });
    };

    toggleShowUploadFiles = () => {
        this.setState({ showUploadFiles: !this.state.showUploadFiles, isUploading: false });
    };

    deleteTask = (id: string) => {
        this.props.store.deleteTaskTemp(id);
        this.setTasksValue(this.props.store.contentTasksList);
    };

    setTasksValue = (newTasks: TTasks[]) => {
        this.props.fieldProps.form.setFieldValue(this.props.fieldProps.field.name, newTasks);
        this.props.fieldProps.form.setFieldTouched(this.props.fieldProps.field.name, true);
    };

    taskFileLoad = async (file: any) => {
        const result = await this.props.store.uploadFile(file);
        if (result) {
            this.setState({ taskFile: { id: result?.fileId, name: result?.fileName } });
        }
    };

    taskFilesLoad = async (file: any) => {
        const result = await this.props.store.uploadFile(file);
        if (result) {
            const files = [...this.state.taskFiles];
            files.push({ id: result?.fileId, name: result?.fileName, path: "" });
            this.setState({ taskFiles: files });
        }
    };

    removeWorkFilesFromState = (fileId: string) => {
        this.setState({ workFiles: this.state.workFiles.filter((item) => item.id !== fileId) });
    };

    workFilesUpload = async (file: File) => {
        const result = await this.props.store.uploadFile(file);
        if (result) {
            const newFile = { id: result?.fileId, name: result?.fileName };

            this.setState({ workFiles: [...this.state.workFiles, newFile] });
        }
    };

    saveUploadedWorkFiles = async () => {
        if (this.state.isUploading) {
            return;
        }

        this.setState({ isUploading: true });

        const result = await this.props.store.saveWorkFiles(
            this.props.orderId,
            this.state.workGuid,
            this.state.workFiles,
            this.state.taskFile
        );

        if (result) {
            this.toggleShowUploadFiles();
        } else {
            this.setState({ isUploading: false });
        }
    };

    showUploadFilesDialog = () => {
        this.setState({ workFiles: [], taskFile: null });
        this.toggleShowUploadFiles();
    };

    showSelectedUnits = () => {
        this.setState({ showUnits: true, selectedUnits: [...this.state.saveUnits] });
    };

    setSelectedUnits = (guid: string) => {
        let newUnits = [...this.state.selectedUnits];
        const index = newUnits.findIndex((unit) => unit === guid);
        let newArr: string[] = newUnits;
        if (index > -1) {
            newArr = [...newUnits.slice(0, index), ...newUnits.slice(index + 1)];
        } else {
            newArr.push(guid);
        }
        this.setState({ selectedUnits: newArr });
    };

    setSaveUnits = (guid: string) => {
        let newUnits = [...this.state.saveUnits];
        const index = newUnits.findIndex((unit) => unit === guid);
        let newArr: string[] = newUnits;
        if (index > -1) {
            newArr = [...newUnits.slice(0, index), ...newUnits.slice(index + 1)];
        } else {
            newArr.push(guid);
        }
        this.setState({ saveUnits: newArr });
    };

    saveSelectedUnits = () => {
        this.setState({ saveUnits: this.state.selectedUnits, selectedUnits: [], showUnits: false });
    };

    cancelSelectedUnits = () => {
        this.setState({ selectedUnits: [], showUnits: false });
    };

    closeAddDialog = () => {
        this.setState({ showAdd: false });
    };

    saveTask = async (id: string, guid: string, user: string) => {
        const { taskFiles, description, saveUnits, implementer, stopDate } = this.state;
        const employer = this.props.employee.employee.find((emp) => emp.id === implementer.id);
        const result = await this.props.store.addTaskTemp(
            id,
            saveUnits,
            description,
            formatDate(stopDate),
            taskFiles,
            user,
            implementer,
            employer
        );
        if (result) {
            this.setTasksValue(this.props.store.contentTasksList);
            this.closeAddDialog();
        }
    };

    showAddTask = (guid: string) => {
        this.setState({
            showAdd: true,
            description: "",
            taskFiles: [],
            saveUnits: [guid],
            isEditeMode: false,
            stopDate: null,
            implementer: { id: "", label: "" },
        });
    };

    showEditTask = (
        description: string,
        stopDate: string | null,
        taskFiles: TTaskFilePath[],
        guid: string,
        employerId: string | null
    ) => {
        const implement = this.props.employee.employee.find((emp) => emp.id === employerId);
        this.setState({
            showAdd: true,
            description,
            taskFiles: [...taskFiles],
            isEditeMode: true,
            stopDate: stopDate ? moment(stopDate, DATE_TIME_FORMAT).toDate() : null,
            saveUnits: [guid],
            implementer: implement ? { id: implement.id, label: implement.name } : { id: "", label: "" },
        });
    };

    toggleShowDevelopers = () => {
        this.setState({ showSheet: !this.state.showSheet });
    };

    changeDeveloperRow = (indexItem: number, fieldNumber: number, value: string) => {
        if (fieldNumber === 0) {
            this.setState({
                developers: this.state.developers.map((el, index) =>
                    index === indexItem
                        ? {
                              ...el,
                              name: value,
                          }
                        : el
                ),
            });
        } else {
            this.setState({
                developers: this.state.developers.map((el, index) =>
                    index === indexItem
                        ? {
                              ...el,
                              type: value,
                          }
                        : el
                ),
            });
        }
    };

    removeDeveloper = (indexItem: number) => {
        this.setState({ developers: this.state.developers.filter((item, index) => index !== indexItem) });
    };

    addNewDeveloper = () => {
        const newItem = { name: "", type: "" };
        this.setState({ developers: [...this.state.developers, newItem] });
    };

    saveDevelopers = async () => {
        const result = await this.props.store.saveDevelopers(
            this.props.orderId,
            this.state.workGuid,
            this.state.developers
        );
        if (result) {
            this.toggleShowDevelopers();
        }
    };

    showDevelopersDialog = (developers: TTaskDevelopers[]) => {
        this.setState({ developers });
        this.toggleShowDevelopers();
    };

    resetFile = () =>
        this.setState({
            taskFile: null,
        });

    resetFiles = (id: string) => {
        const files = [...this.state.taskFiles];

        const index = files.findIndex((f) => f.id === id);

        if (index > -1) {
            const newFiles = [...files.slice(0, index), ...files.slice(index, files.length - 1)];
            this.setState({ taskFiles: newFiles });
        }
    };

    //////////////////////////////////////////////////////////////////

    setFieldValue = (newObjects: TObject[]) => {
        this.props.fieldProps.form.setFieldValue(this.props.fieldProps.field.name, newObjects);
        this.props.fieldProps.form.setFieldTouched(this.props.fieldProps.field.name, true);
    };

    resortUnits = (object: TObject, from: number, to: number) => {
        if (from !== to) {
            const { objects } = this.props;
            const position = findIndex(objects, (o) => o.guid === object.guid);

            const content = move(object.content, from, to);
            const newObjects = [
                ...objects.slice(0, position),
                {
                    ...object,
                    content,
                },
                ...objects.slice(position + 1),
            ];

            this.setFieldValue(newObjects);
        }
    };
}

export const CategoryBlock = observer(CategoryBlockBase);

interface CategoryBlockProps {
    fieldProps: FieldProps;
    category: string;
    readOnly: boolean;
    objects: TObject[];
    collapsed: boolean;
    onToggleCollapse: () => void;
    store: OrderContentTasksDictionaryType;
    baseUrl: string;
    orderId: string;
    user: string;
    collapserContent: SectionCollapserType;
    employee: EmployerDictionaryType;
    highlightRow?: string;
}

interface CategoryBlockState {
    showDelete: boolean;
    showUploadFiles: boolean;
    showAdd: boolean;
    showSheet: boolean;
    isEditeMode: boolean;
    showUnits: boolean;
    workGuid: string;
    taskId: string;
    description: string;
    workFiles: TTaskFile[];
    taskFile: TTaskFile | null;
    taskFiles: TTaskFilePath[];
    selectedUnits: string[];
    saveUnits: string[];
    developers: TTaskDevelopers[];
    isUploading: boolean;
    stopDate: Date | null;
    implementer: { id: string; label: string };
}

export interface Selection {
    category: string;
    object: string;
    units: TUnit[];
}
interface TTaskFile {
    id: string;
    name: string;
}
export interface TTaskFilePath {
    id: string;
    name: string;
    path: string;
}
interface TTaskDevelopers {
    name: string;
    type: string;
}
