import React from "react";
import classnames from "classnames";

export const PlanrTag: React.FC<PlanrTagProps> = ({ type, children, onClick, style, className, uppercase }) => {
    const styleName = classnames({
        "planr-tag": true,
        neutral: !type || type === "neutral",
        primary: type === "primary",
        success: type === "success",
        danger: type === "danger",
        uppercase: !!uppercase,
        [className || ""]: true,
    });

    return (
        <span className={styleName} style={style}>
            {children}
        </span>
    );
};

export type PlanrTagType = "primary" | "neutral" | "success" | "danger";

interface PlanrTagProps {
    uppercase?: boolean;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    className?: string;
    type?: PlanrTagType;
    style?: React.CSSProperties;
}
