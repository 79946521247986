import React from "react";

import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
interface PeoplesProps {
    count: number;
    style?: React.CSSProperties;
}

export const Peoples: React.FC<PeoplesProps> = ({ count }) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }} className="peoples">
            <GeneralIcon type={"general-users"} />
            &nbsp;
            {count}
        </div>
    );
};
