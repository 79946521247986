import { Collapse } from "@blueprintjs/core";
import { Caret } from "modules/common/components/collapse/Caret";
import { Money } from "modules/common/components/money/Money";
import { prettyRound } from "modules/common/services/formatting/numbers";
import { WorkTypeLinkSnapshotType } from "modules/orders-manage/models/order";
import {
    OwnOrderSpendingType,
    SpendingsGropiung,
    TripOrderSpendingType,
} from "modules/orders-manage/models/order-spending";
import React from "react";
import styles from "./OwnTripSpendingObject.module.scss";

type TUnit = WorkTypeLinkSnapshotType;
type TSpending = OwnOrderSpendingType;

export class OwnSpendingObject extends React.PureComponent<OwnSpendingObjectProps> {
    renderInternal = () => {
        const { item, children, collapsed, onToggleCollapse, label, tripSpendings, guid } = this.props;
        let summ = 0;
        let hours = 0;
        item.employee.forEach((e) => {
            summ += e.spendingTrip;
            hours += e.hoursForTrip;
        });
        let factSum = 0;
        let planSum = 0;
        tripSpendings
            .filter((t) => t.contentGuid === guid)
            .forEach((trip) => {
                trip.actualPayments.forEach((actP) => {
                    factSum += actP.sum;
                });
                trip.planPayments.forEach((planP) => {
                    if (planP.status !== "Оплачен") {
                        planSum += planP.sum;
                    } else {
                        planSum += 0;
                    }
                });
            });

        return (
            <div className={styles.block}>
                <div className={styles.label} onClick={onToggleCollapse}>
                    <span className={styles.span}>{label}</span>
                    {hours !== 0 && (
                        <div className={styles.sumNumbersObj}>
                            <div
                                className="order-total"
                                style={{
                                    left: "260px",
                                    top: "-18px",
                                    float: "right",
                                }}
                            >
                                Всего часов:&nbsp;
                                {hours}
                            </div>

                            <div className={`actual-total`} style={{ left: "390px", top: "-18px" }}>
                                Всего затрат:&nbsp;
                                <Money className="spendings-money" amount={prettyRound(summ)} />
                            </div>

                            <div
                                className={`actual-total ${planSum > 0 ? "red-total" : "green-total"}`}
                                style={{ left: "600px", top: "-18px" }}
                            >
                                Всего по оплатам:&nbsp;
                                <Money className="spendings-money" amount={factSum} />
                            </div>

                            <div
                                className={`actual-total ${planSum > 0 ? "red-total" : "green-total"}`}
                                style={{ left: "900px", top: "-18px" }}
                            >
                                Остаток по оплатам:&nbsp;
                                <Money className="spendings-money" amount={planSum} />
                            </div>
                        </div>
                    )}
                    <Caret collapsed={collapsed} style={{ top: "3px" }} />
                </div>

                <Collapse isOpen={!collapsed}>{children}</Collapse>
            </div>
        );
    };

    render() {
        const child = this.renderInternal();

        return child;
    }
}

interface OwnSpendingObjectProps {
    index: number;
    item: SpendingsGropiung;
    label: string;
    guid: string;
    tripSpendings: TripOrderSpendingType[];

    onToggleCollapse: () => void;
    collapsed: boolean;
}
