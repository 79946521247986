import * as yup from "yup";
import { fields } from "modules/dictionaries/inventory/models/category";
import { texts } from "modules/common/texts";
const { object, string, number } = yup;

export const categoryLabels = () => ({
    [fields.name]: texts.name,
    [fields.inventoryNumber]: "Инв",
    [fields.fullInventoryNumber]: "Инв",
    [fields.sum]: "Сумма",
    [fields.usedItemsCount]: "Использовано",
    [fields.freeItemsCount]: "Своб",
    [fields.totalItemsCount]: "Кол",
});

const CategorySchema = () => {
    const labels = categoryLabels();

    return object().shape({
        [fields.name]: string().label(labels[fields.name]).required(),

        [fields.inventoryNumber]: number().label(labels[fields.inventoryNumber]).required(),
    });
};

export default CategorySchema;
