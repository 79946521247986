import { types } from "mobx-state-tree";
import { DictionaryLink } from "modules/common/models/dictionary-link";
import {
  HoursPerDay,
  WorkloadUnitTypeDetails,
  emptyWorkloadUnitType,
} from "modules/spending/workload/models/workload-unit";

const constants = types
  .model({
    orderFileTypeContentTaskSource: types.string,
    orderFileTypeContentTaskResult: types.string,
    employerDocumentType: types.map(types.string),
    workTypeCategory: types.map(types.string),
    authorization: types.map(types.string),
    workedOutHoursType: WorkloadUnitTypeDetails,
    allCountryRegions: types.array(types.string),
    workedOutFromHomeHoursType: WorkloadUnitTypeDetails,
    workedOutForExpertiseHoursType: WorkloadUnitTypeDetails,
    workedOutForTripHoursType: WorkloadUnitTypeDetails,
    vacationHoursType: WorkloadUnitTypeDetails,
    workedVacationHoursType: WorkloadUnitTypeDetails,
    illnesHoursType: WorkloadUnitTypeDetails,
    workedIllnesHoursType: WorkloadUnitTypeDetails,
    withoutPayHoursType: WorkloadUnitTypeDetails,
    idleHoursType: WorkloadUnitTypeDetails,
    freeFromWorkHoursType: WorkloadUnitTypeDetails,
    otherHoursTypes: types.array(WorkloadUnitTypeDetails),
    allHoursTypes: types.array(WorkloadUnitTypeDetails),
    maxHoursPerDays: types.array(HoursPerDay),
    workDayHours: types.number,
    timesheetPastDayLimit: types.number,
    pushPublicKey: types.string,
    orderPaymentStatusAccepted: types.string,
    orderPaymentStatusNew: types.string,
    orderPaymentStatusPaid: types.string,
    contentTaskCompleted: types.string,
    contentTaskConfirmed: types.string,
    contentTaskAccepted: types.string,
    contentTaskCancelled: types.string,
    orderPaymentStatusCancelled: types.string,
    /** Статус договора - Переговоры (Инициация) */
    orderStatusNegotiationType: types.string,
    /** Статус договора - Планирование */
    orderStatusPlanType: types.string,
    orderCheckOverdueStatusTypes: types.array(types.string),
    /** Статус договора - Утверждение плана */
    orderStatusPlanAcceptanceType: types.string,
    /** Статусы договора, разрешающие изменение фин. показателей */
    updatableOrderFinanceStatusTypes: types.array(types.string),
    /** Статусы договора, разрешающие назначение премий */
    assingnablePremiumStatusTypes: types.array(types.string),
    /** Идентфикатор отдела главных инженеров */
    gipDepartmentId: types.string,
    /** Входящая корреспондениция */
    incomingOrderMailType: types.string,
    /** Исходящаяя корреспондениция */
    outcomingOrderMailType: types.string,
    /** Письмо принято */
    acceptedOrderMailStatus: types.string,
    /** Письмо выполнено */
    completedOrderMailStatus: types.string,
    /** Письмо просрочено */
    expiredOrderMailStatus: types.string,
    /** Типы расходов банковских выгрузок */
    bankInvoiceRowTypes: types.array(types.string),
    /** Типы банковских выгрузок, не требущих payload */
    nonPayloadBankInvoiceRowTypes: types.array(types.string),
    bankInvoiceRowTypeProduction: types.string,
    bankInvoiceRowTypeNonProduction: types.string,
    /** Доход по договорам */
    bankInvoiceRowTypeIncoming: types.string,
    bankInvoiceRowTypeAgents: types.string,
    bankInvoiceRowTypeIncomingCorrection: types.string,
    bankInvoiceRowTypeSkip: types.string,
    /** Выручка, дивиденды */
    bankInvoiceRowTypeDividends: types.string,
    /** Погашение кредита */
    bankInvoiceRowTypeCreditRefund: types.string,
    /** НДФЛ */
    bankInvoiceRowTypeNdfl: types.string,
    /** Кредитные средства */
    bankInvoiceRowTypeCredit: types.string,
    /** Доп доход */
    bankInvoiceRowTypeAdditionalIncome: types.string,
    // премии
    premiumPercents: types.number,
    premiumGipPercents: types.number,
    premiumProductionPercents: types.number,
    premiumNonProductionPercents: types.number,
    /** eсли премия назначена до этого дня (включительно) - распределять на текущий месяц, после - на след. месяц */
    premiumTransferDay: types.number,
    /** Системные типы статусов договора */
    serviceOrderStatuses: types.array(types.string),
    /** Статусы неактивных опросов */
    surveyInactiveStatuses: types.array(types.string),
    /** ID файла для шаблона ТЗ проекта */
    settingsTechnicalTaskTemplateId: types.string,
    /** ID файла для шаблона ИУЛ проекта */
    settingsInformationAndCertificationSheetTemplateId: types.string,
    /** Фин запрос Одобрен */
    financeRequestAcceptedStatus: types.string,
    /** Фин запрос Оплачен */
    financeRequestPaidStatus: types.string,
    /** Фин запрос Отменен */
    financeRequestCancelledStatus: types.string,
    /** Список доступных типов событий */
    businessEventTypes: types.array(types.string),
    /** Список статусов для события по договору */
    changeStatusBusinessEventValues: types.array(types.string),
    /** Список статусов для события по затратам */
    changeOrderSpendingStatusBusinessEventValues: types.array(types.string),
    nonProductionHoursId: types.string,
    /** Статусы для вкладки Подтв изменений*/
    orderConfirmTypes: types.array(types.string),
    scheduleFieldNames: types.array(DictionaryLink),
  })
  .views((self) => ({
    get changeStatusBusinessEventValuesOptions(): SelectItem[] {
      return self.changeStatusBusinessEventValues.map((value) => ({ id: value, label: value }));
    },

    get changeOrderSpendingStatusBusinessEventValuesOptions(): SelectItem[] {
      return self.changeOrderSpendingStatusBusinessEventValues.map((value) => ({ id: value, label: value }));
    },

    get businessEventTypesOptions(): SelectItem[] {
      return self.businessEventTypes.map((value) => ({ id: value, label: value }));
    },

    get orderConfirmTypesList(): SelectItem[] {
      return self.orderConfirmTypes.map((value) => ({ id: value, label: value }));
    },
    get scheduleFields(): SelectItem[] {
      return self.scheduleFieldNames.map((value) => ({ id: value.id, label: value.name }));
    },
  }));

export const emptyConstants = (): ConstantsSnapshotType => {
  return {
    scheduleFieldNames: [],
    orderConfirmTypes: [],
    allCountryRegions: [],
    orderFileTypeContentTaskSource: "",
    orderFileTypeContentTaskResult: "",
    employerDocumentType: {},
    workTypeCategory: {},
    authorization: {},
    contentTaskCompleted: "",
    contentTaskConfirmed: "",
    contentTaskAccepted: "",
    contentTaskCancelled: "",
    illnesHoursType: emptyWorkloadUnitType(),
    otherHoursTypes: [],
    allHoursTypes: [],
    vacationHoursType: emptyWorkloadUnitType(),
    withoutPayHoursType: emptyWorkloadUnitType(),
    workedOutHoursType: emptyWorkloadUnitType(),
    workedOutFromHomeHoursType: emptyWorkloadUnitType(),
    workedOutForExpertiseHoursType: emptyWorkloadUnitType(),
    workedOutForTripHoursType: emptyWorkloadUnitType(),
    workedIllnesHoursType: emptyWorkloadUnitType(),
    workedVacationHoursType: emptyWorkloadUnitType(),
    idleHoursType: emptyWorkloadUnitType(),
    freeFromWorkHoursType: emptyWorkloadUnitType(),
    maxHoursPerDays: [],
    workDayHours: 0,
    timesheetPastDayLimit: 0,
    pushPublicKey: "",
    gipDepartmentId: "",
    orderPaymentStatusAccepted: "",
    orderPaymentStatusNew: "",
    orderPaymentStatusPaid: "",
    orderPaymentStatusCancelled: "",
    orderStatusNegotiationType: "",
    orderStatusPlanType: "",
    orderStatusPlanAcceptanceType: "",
    updatableOrderFinanceStatusTypes: [],
    orderCheckOverdueStatusTypes: [],
    assingnablePremiumStatusTypes: [],
    incomingOrderMailType: "",
    outcomingOrderMailType: "",
    acceptedOrderMailStatus: "",
    completedOrderMailStatus: "",
    expiredOrderMailStatus: "",
    bankInvoiceRowTypes: [],
    nonPayloadBankInvoiceRowTypes: [],
    bankInvoiceRowTypeNonProduction: "",
    bankInvoiceRowTypeProduction: "",
    bankInvoiceRowTypeIncoming: "",
    bankInvoiceRowTypeAgents: "",
    bankInvoiceRowTypeIncomingCorrection: "",
    bankInvoiceRowTypeSkip: "",
    bankInvoiceRowTypeDividends: "",
    bankInvoiceRowTypeCreditRefund: "",
    bankInvoiceRowTypeCredit: "",
    bankInvoiceRowTypeAdditionalIncome: "",
    bankInvoiceRowTypeNdfl: "",
    premiumGipPercents: 0,
    premiumNonProductionPercents: 0,
    premiumPercents: 0,
    premiumProductionPercents: 0,
    premiumTransferDay: 1,
    serviceOrderStatuses: [],
    surveyInactiveStatuses: [],
    settingsTechnicalTaskTemplateId: "",
    settingsInformationAndCertificationSheetTemplateId: "",
    financeRequestAcceptedStatus: "",
    financeRequestCancelledStatus: "",
    financeRequestPaidStatus: "",
    changeStatusBusinessEventValues: [],
    changeOrderSpendingStatusBusinessEventValues: [],
    businessEventTypes: [],
    nonProductionHoursId: "",
  };
};

export const Constants = constants.create(emptyConstants());

export type ConstantsSnapshotType = typeof constants.SnapshotType;
export type ConstantsType = typeof constants.Type;
