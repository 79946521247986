import React from "react";
import { Schema } from "yup";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { observer } from "mobx-react";
import { BonusSalaryPercentType, fields, SettingsStoreType } from "../models/settings";
import styles from "./SystemSettings.module.scss";
import { onlyDigitsInputKeyPress } from "modules/common/services/form/values";
import { PercentInput } from "modules/common/components/form/PercentInput";
import { FieldProps } from "formik";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { Buttons } from "modules/common/components/form";
import { Classes, Dialog, InputGroup } from "@blueprintjs/core";
import { v4 } from "uuid";
import { texts } from "modules/common/texts";
import {
  DefaultSelectedOption,
  filterItemPredicate,
  renderSingleOption,
  SimpleSelect,
} from "modules/common/services/form/select";
import { DictionaryLinkType } from "modules/common/models/dictionary-link";
import { Constants } from "modules/root/models/constants";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

const digits = onlyDigitsInputKeyPress();
const roundItems = [
  { id: "0", label: "Без округления" },
  { id: "1", label: "10" },
  { id: "2", label: "100" },
  { id: "3", label: "1000" },
];
class OtherBase extends React.PureComponent<OtherProps, OtherState> {
  constructor(props: any) {
    super(props);

    this.state = { showDialog: false, showDanger: false, bonusSalaryPercent: [], employersExclude: [] };
  }

  componentDidMount() {
    const { store } = this.props;
    this.setState({ employersExclude: store.model.excludeEmployersBonus });
  }

  render() {
    const { schema, store } = this.props;
    const { showDialog, showDanger, bonusSalaryPercent, employersExclude } = this.state;
    const items = store.employers.employee;
    const selectedEmployer = null;
    const scheduleFields = Constants.scheduleFields;

    return (
      <div className="columns">
        <div className={styles.columns}>
          <div className={styles.column}>
            <h1 className={styles.info}>
              <GeneralIcon type="general-info" style={{ marginRight: "10px" }} />
              Изменения вступят в силу в текущем месяце
            </h1>

            <StandardFormInput
              name={fields.roundStep}
              schema={schema}
              inline={false}
              small={true}
              className="planr-form-input"
            >
              {({ field, form }) => {
                const selectedRound = roundItems.find((i) => i.id === field.value?.toString());
                return (
                  <SimpleSelect
                    className={`full-width-select ${Classes.FILL}`}
                    activeItem={selectedRound}
                    items={roundItems}
                    itemRenderer={renderSingleOption}
                    onItemSelect={(i: SelectItem) => {
                      form.setFieldValue(field.name, +i.id);
                      form.setFieldTouched(field.name, true);
                    }}
                    popoverProps={{
                      boundary: "viewport",
                      usePortal: true,
                      popoverClassName: "select-dropdown",
                    }}
                    filterable={false}
                    matchTargetWidth={false}
                  >
                    <DefaultSelectedOption option={selectedRound} />
                  </SimpleSelect>
                );
              }}
            </StandardFormInput>

            <StandardFormInput
              name={fields.fieldsToRound}
              schema={schema}
              label={"Выберите поля для округления"}
              inline={false}
              small={true}
              className="planr-form-input"
            >
              {({ field, form }) => {
                const scheduleItems = scheduleFields.filter((r) => field.value.indexOf(r.id) < 0);
                return (
                  <div className={styles.stageDialogColumn}>
                    <SimpleSelect
                      className={`full-width-select ${Classes.FILL}`}
                      activeItem={selectedEmployer}
                      items={scheduleItems}
                      itemRenderer={renderSingleOption}
                      onItemSelect={(i: SelectItem) => {
                        let oldVal = [...field.value];
                        if (!oldVal.includes(i.id)) {
                          oldVal.push(i.id);
                        }

                        form.setFieldValue(field.name, oldVal);
                        form.setFieldTouched(field.name, true);
                      }}
                      popoverProps={{
                        boundary: "viewport",
                        usePortal: true,
                        popoverClassName: "select-dropdown",
                      }}
                      inputProps={{
                        placeholder: "Поиск",
                      }}
                      filterable={false}
                      matchTargetWidth={false}
                      // itemPredicate={filterItemPredicate}
                    >
                      <DefaultSelectedOption option={selectedEmployer} />
                    </SimpleSelect>
                    {field.value.length > 0 && (
                      <div className={styles.employersList}>
                        {field.value.map((val: string) => (
                          <div className={styles.employersListItem} key={val}>
                            <div className={styles.employersListItemName}>
                              {scheduleFields.find((i) => i.id === val)?.label}
                            </div>
                            <PlanrButton
                              type="neutral"
                              icon="general-trash"
                              size="small"
                              onClick={() => {
                                let oldVal = [...field.value];
                                const index = oldVal.indexOf(val);
                                if (index >= 0) {
                                  oldVal.splice(index, 1);
                                }

                                form.setFieldValue(field.name, oldVal);
                                form.setFieldTouched(field.name, true);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              }}
            </StandardFormInput>
          </div>
          <div className={styles.column}>
            <div className={styles.row}>
              <StandardFormInput
                name={fields.bonusSalaryPercents}
                schema={schema}
                small={true}
                inline={false}
                className={styles.full}
              >
                {({ field, form }) => (
                  <>
                    <PlanrButton
                      type="secondary"
                      onClick={() => {
                        this.setState({ bonusSalaryPercent: field.value });
                        this.toggleDialog();
                      }}
                      size="small"
                      style={{ marginBottom: "10px" }}
                    >
                      Доплата за стаж в организации
                    </PlanrButton>
                    <Dialog
                      title={`Доплата за стаж в организации`}
                      isOpen={showDialog}
                      onClose={this.toggleDialog}
                      backdropClassName="standard"
                      style={{ width: "930px" }}
                      className={`${styles.FilesDialog} figma-dialog`}
                    >
                      <div className={`${Classes.DIALOG_BODY} `}>
                        <div className={styles.stageDialog}>
                          <div className={styles.stageDialogColumn}>
                            {bonusSalaryPercent.length > 0 && (
                              <div className={styles.itemRowHeaderWrap}>
                                <div className={styles.itemRowHeader1}>Стаж в годах от</div>
                                <div className={styles.itemRowHeader1}>Стаж в годах до</div>
                                <div className={styles.itemRowHeader2}>Процент от ставки</div>
                              </div>
                            )}
                            {bonusSalaryPercent &&
                              bonusSalaryPercent.map((res, index) => {
                                return (
                                  <div
                                    key={res.guid}
                                    style={{ display: "flex", marginBottom: "10px", gap: "10px", alignItems: "center" }}
                                  >
                                    <div className={styles.itemRow}>
                                      <InputGroup
                                        type="text"
                                        autoComplete="off"
                                        className="planr-default-input"
                                        data-lpignore="true"
                                        value={res.yearsCount1.toString()}
                                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                          this.setBonuses("yearsCount1", e.currentTarget.value, res.guid);
                                        }}
                                      />
                                    </div>
                                    <div className={styles.itemRow}>
                                      <InputGroup
                                        type="text"
                                        autoComplete="off"
                                        className="planr-default-input"
                                        data-lpignore="true"
                                        value={res.yearsCount2.toString()}
                                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                          this.setBonuses("yearsCount2", e.currentTarget.value, res.guid);
                                        }}
                                      />
                                    </div>
                                    <div className={styles.itemRow}>
                                      <PercentInput
                                        value={res.percentValue.toString()}
                                        className="planr-default-input"
                                        autoComplete="off"
                                        data-lpignore="true"
                                        onKeyPress={digits}
                                        onPercentChange={(p) => {
                                          this.setBonuses(
                                            "percentValue",
                                            p.floatValue ? p.floatValue.toString() : "",
                                            res.guid
                                          );
                                        }}
                                      />
                                    </div>
                                    <PlanrButton
                                      type="neutral"
                                      icon="general-trash"
                                      onClick={() => {
                                        this.removeBonuses(res.guid);
                                      }}
                                      size="small"
                                      style={{ marginLeft: "10px" }}
                                      title={texts.remove}
                                    />
                                  </div>
                                );
                              })}
                            <div style={{ marginTop: "6px" }}>
                              <PlanrButton
                                size="small"
                                type="secondary"
                                onClick={this.addBonuses}
                                icon="general-plus-big"
                              >
                                Добавить
                              </PlanrButton>
                            </div>
                            {showDanger && (
                              <div style={{ color: "red", marginTop: "10px", fontSize: "12px" }}>
                                Значения во всех полях должны быть больше 0!
                              </div>
                            )}
                          </div>
                          <div className={styles.stageDialogColumn}>
                            <div className={styles.labelHeader}>
                              Список сотрудников, которым не начислять доплату за стаж
                            </div>
                            <SimpleSelect
                              className={`full-width-select ${Classes.FILL}`}
                              activeItem={selectedEmployer}
                              items={items}
                              itemRenderer={renderSingleOption}
                              onItemSelect={(i: SelectItem) => {
                                if (!employersExclude.find((e) => e.id === i.id)) {
                                  let newEmployers = [...employersExclude];
                                  newEmployers.push({ id: i.id, name: i.label });
                                  this.setState({ employersExclude: newEmployers });
                                }
                              }}
                              popoverProps={{
                                boundary: "viewport",
                              }}
                              inputProps={{
                                placeholder: "Поиск",
                              }}
                              filterable={true}
                              itemPredicate={filterItemPredicate}
                            >
                              <DefaultSelectedOption option={selectedEmployer} />
                            </SimpleSelect>
                            {employersExclude.length > 0 && (
                              <div className={styles.employersList}>
                                {employersExclude.map((employer) => (
                                  <div className={styles.employersListItem} key={employer.id}>
                                    <div key={employer.id} className={styles.employersListItemName}>
                                      {employer.name}
                                    </div>
                                    <PlanrButton
                                      type="neutral"
                                      icon="general-trash"
                                      size="small"
                                      onClick={() => {
                                        let newEmployers = [...employersExclude];
                                        const index = newEmployers.findIndex((e) => e.id === employer.id);
                                        if (index > -1) {
                                          newEmployers.splice(index, 1);
                                          this.setState({ employersExclude: newEmployers });
                                        }
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={Classes.DIALOG_FOOTER}>
                        <Buttons
                          left={
                            <div style={{ display: "flex" }}>
                              <PlanrButton
                                size="small"
                                type="greenish"
                                onClick={() => {
                                  let hasError = false;
                                  if (bonusSalaryPercent.length > 0) {
                                    bonusSalaryPercent.forEach((element: any) => {
                                      if (
                                        element.yearsCount1 <= 0 ||
                                        element.yearsCount2 <= 0 ||
                                        element.percentValue <= 0
                                      ) {
                                        hasError = true;
                                        this.setState({ showDanger: true });
                                      }
                                    });
                                  }
                                  if (!hasError) {
                                    form.setFieldValue(field.name, bonusSalaryPercent);
                                    form.setFieldTouched(field.name, true);
                                    form.setFieldValue(fields.excludeEmployersBonus, employersExclude);
                                    form.setFieldTouched(fields.excludeEmployersBonus, true);
                                    this.toggleDialog();
                                  }
                                }}
                              >
                                Сохранить
                              </PlanrButton>
                              <PlanrButton size="small" type="graybtn" onClick={this.toggleDialog}>
                                Отмена
                              </PlanrButton>
                            </div>
                          }
                        />
                      </div>
                    </Dialog>
                  </>
                )}
              </StandardFormInput>
            </div>
          </div>
        </div>
      </div>
    );
  }

  fieldRenderer = ({ field, form }: FieldProps) => (
    <PercentInput
      onBlur={field.onBlur}
      id={field.name}
      name={field.name}
      value={field.value}
      className="planr-default-input"
      autoComplete="off"
      data-lpignore="true"
      onKeyPress={digits}
      onPercentChange={(p) => {
        form.setFieldValue(field.name, p.floatValue);
        form.setFieldTouched(field.name, true);
      }}
    />
  );

  toggleDialog = () => {
    this.setState({ showDialog: !this.state.showDialog, showDanger: false });
  };

  setBonuses = (field: string, value: string, guid: string) => {
    const bonuses = [...this.state.bonusSalaryPercent];
    const index = bonuses.findIndex((e) => e.guid === guid);

    if (index > -1) {
      const item = bonuses[index];
      bonuses.splice(index, 1, { ...item, [field]: !isNaN(+value) ? +value : "" });
      this.setState({ bonusSalaryPercent: bonuses });
    }
  };

  removeBonuses = (guid: string) => {
    const bonuses = [...this.state.bonusSalaryPercent];
    const index = bonuses.findIndex((e) => e.guid === guid);
    if (index > -1) {
      bonuses.splice(index, 1);
      this.setState({ bonusSalaryPercent: bonuses });
    }
  };

  addBonuses = () => {
    const bonuses = [...this.state.bonusSalaryPercent];
    bonuses.push({ guid: v4(), yearsCount1: 0, yearsCount2: 0, percentValue: 0 });
    this.setState({ bonusSalaryPercent: bonuses });
  };
}

export const OtherSettings = observer(OtherBase);

interface OtherState {
  showDialog: boolean;
  showDanger: boolean;
  bonusSalaryPercent: BonusSalaryPercentType[];
  employersExclude: DictionaryLinkType[];
}

interface OtherProps {
  schema: Schema<any>;
  store: SettingsStoreType;
}
