import * as yup from "yup";
import { confirmFields as fields } from "modules/orders-manage/models/order-confirm";
import { texts } from "modules/common/texts";

const { object, string } = yup;

export const orderMailLabels = () => ({
  [fields.number]: "Номер",
  [fields.date]: "Дата",
  [fields.belonging]: "Принадл к комплектам",
  [fields.description]: texts.comment,
  [fields.type]: "Основание",
  [fields.orderId]: "Проект",
});

export const OrderMailsSchema = () => {
  const labels = orderMailLabels();

  return object().shape({
    [fields.orderId]: string().label(labels[fields.orderId]).notRequired(),

    [fields.date]: string().label(labels[fields.date]).typeError(texts.messages.incorrectDate()).required(),

    [fields.number]: string().label(labels[fields.number]).required(),

    [fields.belonging]: string().label(labels[fields.belonging]).required(),

    [fields.type]: string().label(labels[fields.type]).required(),

    [fields.description]: string().label(labels[fields.description]).notRequired(),
  });
};
