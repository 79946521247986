import React from "react";
import { OrderListType } from "../models/order-list";
import { canReadOrder } from "../functionalities";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

export const MenuTools = (store: OrderListType, isMobile?: boolean, setHideMenu?: () => void) => (access: string[]) =>
  canReadOrder(access) ? (
    <GeneralIcon
      type={store.visible ? "general-active-list" : "general-list"}
      onClick={() => {
        if (isMobile && setHideMenu) {
          setHideMenu();
        }
        store.toggleVisibility();
      }}
      className="toggle-ribbon-button"
    />
  ) : undefined;
