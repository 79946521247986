import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { OverheadType, OverheadTypeType, OverheadTypeSnapshotType, fields } from "./overhead-type";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { Queryable } from "modules/common/models/queryable";

export const OverheadTypeList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            overheadTypes: types.array(OverheadType),
            sorter: TableSorter,
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<OverheadTypeType> {
            return self.overheadTypes.reduce((result, overheadType) => {
                result[overheadType.id] = overheadType;
                return result;
            }, {} as TStringMap<OverheadTypeType>);
        },

        get data() {
            const predicate = (o: OverheadTypeType) => {
                let result = true;
                if (self.pureQuery) {
                    result = result && `${o.name} ${o.comment}`.toLowerCase().indexOf(self.pureQuery) >= 0;
                }
                return result;
            };
            return self.overheadTypes.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: any = yield self.transport.get<OverheadTypeSnapshotType[]>(apiUrls.overheadTypes.list());
                applySnapshot(self.overheadTypes, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        delete(overheadType: OverheadTypeSnapshotType) {
            const model = OverheadType.create(overheadType, {
                http: self.transport,
                notificator: self.notify,
            });

            return model.delete();
        },
    }))
    .named("OverheadTypeList");

export type OverheadTypesListType = typeof OverheadTypeList.Type;
export type OverheadTypeListSnapshotType = typeof OverheadTypeList.SnapshotType;
export { OverheadType };

const sortStorage = getSortOption(OverheadTypeList.name);

export const initialState = (): OverheadTypeListSnapshotType => {
    const options = sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        overheadTypes: [],
        pureQuery: "",
        query: "",
        sorter: {
            id: OverheadTypeList.name,
            tableName: OverheadTypeList.name,
            column: options.column,
            asc: options.asc,
        },
    };
};
