import React from 'react';

const styles: React.CSSProperties = {
    border: 0,
    margin: 0,
    padding: 0,
    minWidth: '1px',
    maxWidth: '1px',
    visibility: 'hidden',
    top: '-1px',
    left: '-1px',
    position: 'absolute'
};

export const InvisibleSubmit = ({ disabled, innerRef }: InvisibleSubmitProps) => {
    return (
        <button type="submit" style={styles} disabled={disabled} ref={innerRef} />
    );
}

interface InvisibleSubmitProps {
    disabled?: boolean;
    innerRef?: any;
}
