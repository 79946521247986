import React from "react";
import { Block } from "modules/common/components/page/Block";
import { withRouter, RouteComponentProps, matchPath } from "react-router";
import { routes } from "modules/common/routes";
import { UnregisterCallback } from "history";
import { observer } from "mobx-react";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { MaterialValuesStoreType } from "../models/material-value-store";
import { MaterialValuesList } from "./list/MaterialValuesList";
import styles from "./MaterialValuesFreePage.module.scss";
import { MaterialValuesListType } from "../models/material-value-list";

class ItemsFreePage extends React.Component<ItemsFreePageProps & RouteComponentProps, ItemsFreePageState> {
    private unregister: UnregisterCallback | null = null;

    constructor(props: ItemsFreePageProps & RouteComponentProps) {
        super(props);

        this.state = { materialId: getSelectedMaterialValueId(props.location.pathname) };
    }

    componentDidMount() {
        const { history, store } = this.props;
        const { materialId } = this.state;
        store.init(materialId);
        this.loadList();
        this.loadDetails(materialId);

        this.unregister = history.listen((location) => {
            const id = getSelectedMaterialValueId(location.pathname);

            if (this.state.materialId !== id) {
                this.setState({ materialId: id });
                this.loadDetails(id);
            }
        });
    }

    componentWillUnmount() {
        this.unregister && this.unregister();
    }

    render() {
        const { store, getDataset, ended, onChange, all } = this.props;

        return (
            <Block columned={true} className={styles.page}>
                <MaterialValuesList
                    store={store.list}
                    onSelected={this.onMaterialSelected}
                    onRemoved={this.onMaterialRemoved}
                    getDataset={getDataset}
                    ended={ended}
                    all={all}
                    onChange={onChange}
                />
            </Block>
        );
    }

    onEmployeePositionAdd = () => {
        this.onMaterialSelected(EMPTY_OBJECT_ID);
    };

    onMaterialSelected = (id: string = "") => {
        const { history } = this.props;

        const url = id ? routes.dictionaries.inventory.itemsDetils(id) : routes.dictionaries.inventory.path;

        history.push(url);
    };

    onMaterialSaved = () => {
        this.onMaterialSelected("");

        this.loadList();
    };

    onMaterialRemoved = () => {
        const { history } = this.props;

        this.loadList();

        const url = routes.dictionaries.inventory.path;
        history.push(url);
    };

    loadList = () => this.props.store.list.load();

    loadDetails = (id: string) => this.props.store.details.load(id);
}

export default withRouter(observer(ItemsFreePage));

function getSelectedMaterialValueId(pathname: string) {
    const match = matchPath<any>(pathname, routes.dictionaries.inventory.itemsDetils());
    return match && match.params ? match.params.id : "";
}

interface ItemsFreePageProps {
    store: MaterialValuesStoreType;
    access: string[];
    category?: string;
    getDataset: (store: MaterialValuesListType) => any[];
    ended?: boolean;
    all?: boolean;
    onChange: () => void;
}

interface ItemsFreePageState {
    materialId: string;
}
