import styles from "./ScheduleUserRow.module.scss";
import React from "react";
import { fields } from "../models/schedule-unit";
import ScheduleSchema from "./validation";
import { FieldProps } from "formik";
import { InputGroup, Dialog, Classes } from "@blueprintjs/core";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { MoneyInput } from "modules/common/components/money/MoneyInput";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { texts } from "modules/common/texts";

const schema = ScheduleSchema();

export class BonusCorrection extends React.Component<BonusCorrectionProps> {
    render() {
        const { open, toggleOpen } = this.props;
        return (
            <div className={styles.correctionWrapper}>
                <Dialog
                    icon="help"
                    canOutsideClickClose={false}
                    title={"Корр. премии"}
                    isOpen={open}
                    onClose={toggleOpen}
                    backdropClassName="standard"
                >
                    <div className={Classes.DIALOG_BODY}>
                        <div>
                            <StandardFormInput
                                name={fields.bonusCorrection}
                                schema={schema}
                                small={true}
                                inline={false}
                            >
                                {({ field, form }: FieldProps) => {
                                    return (
                                        <MoneyInput
                                            className="in-cell planr-tiny-input"
                                            id={field.name}
                                            small={true}
                                            autoComplete="off"
                                            data-lpignore="true"
                                            allowNegative={true}
                                            value={field.value}
                                            onMoneyChange={(money) => {
                                                form.setFieldValue(field.name, money.value);
                                                form.setFieldTouched(field.name);
                                            }}
                                        />
                                    );
                                }}
                            </StandardFormInput>
                            <StandardFormInput
                                name={fields.bonusCorrectionComment}
                                schema={schema}
                                small={true}
                                inline={false}
                            >
                                {({ field }: FieldProps) => {
                                    return (
                                        <InputGroup
                                            className="in-cell planr-tiny-input"
                                            id={field.name}
                                            small={true}
                                            autoComplete="off"
                                            data-lpignore="true"
                                            {...field}
                                        />
                                    );
                                }}
                            </StandardFormInput>
                        </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <PlanrButton type="greenish" size="small" onClick={toggleOpen}>
                                {texts.save}
                            </PlanrButton>
                        </div>
                    </div>
                </Dialog>
                {/* <Popover
                    interactionKind="click"
                    position="bottom"
                    isOpen={true}
                    usePortal={true}
                    content={
                        <Card className={styles.correction} elevation={3}>
                            <StandardFormInput
                                name={fields.bonusCorrection}
                                schema={schema}
                                small={true}
                                inline={false}
                            >
                                {({ field, form }: FieldProps) => {
                                    return (
                                        <MoneyInput
                                            className="in-cell planr-tiny-input"
                                            id={field.name}
                                            small={true}
                                            autoComplete="off"
                                            data-lpignore="true"
                                            allowNegative={true}
                                            value={field.value}
                                            onMoneyChange={(money) => {
                                                form.setFieldValue(field.name, money.value);
                                                form.setFieldTouched(field.name);
                                            }}
                                        />
                                    );
                                }}
                            </StandardFormInput>
                            <StandardFormInput
                                name={fields.bonusCorrectionComment}
                                schema={schema}
                                small={true}
                                inline={false}
                            >
                                {({ field }: FieldProps) => {
                                    return (
                                        <InputGroup
                                            className="in-cell planr-tiny-input"
                                            id={field.name}
                                            small={true}
                                            autoComplete="off"
                                            data-lpignore="true"
                                            {...field}
                                        />
                                    );
                                }}
                            </StandardFormInput>
                        </Card>
                    }
                >
                    <></>
                </Popover> */}
            </div>
        );
    }
}

interface BonusCorrectionProps {
    open: boolean;
    toggleOpen: () => void;
}
