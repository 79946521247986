import { types, getSnapshot } from "mobx-state-tree";
import { Supplier, SupplierType, fields, SupplierSnapshotType } from "./supplier";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { flow } from "modules/common/models/flow";
// import { toJsonHard } from "modules/common/services/mobx/serialize";
import { Queryable } from "modules/common/models/queryable";

export const SupplierList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            suppliers: types.array(Supplier),
            sorter: TableSorter,
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<SupplierType> {
            return self.suppliers.reduce((result, supplier) => {
                result[supplier.id] = supplier;
                return result;
            }, {} as TStringMap<SupplierType>);
        },

        get data() {
            const predicate = (o: SupplierType) => {
                let result = true;
                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.name} ${o.phone} ${o.email} ${o.comment}`.toLowerCase().indexOf(self.pureQuery) >= 0;
                }

                return result;
            };
            return self.suppliers.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));

            // return self.suppliers.map(toJsonHard);
        },

        get isEmpty() {
            return !self.suppliers.length;
        },
    }))
    .actions((self) => ({
        delete: flow(function* (supplier: SupplierSnapshotType) {
            const model = Supplier.create(supplier, {
                http: self.transport,
                notificator: self.notify,
            });
            return yield model.delete();
        }),
    }))
    .named("SupplierList");

export type SupplierListType = typeof SupplierList.Type;
export type SupplierListSnapshotType = typeof SupplierList.SnapshotType;
export { Supplier };

export const tableName = SupplierList.name;
export const initialState = (): SupplierListSnapshotType => {
    const sortStorage = getSortOption(tableName);
    const options = sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        suppliers: [],
        pureQuery: "",
        query: "",
        sorter: {
            id: tableName,
            tableName,
            column: options.column,
            asc: options.asc,
        },
    };
};
