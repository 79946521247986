import { types, applySnapshot } from "mobx-state-tree";
import { flow } from "modules/common/models/flow";
import { SimpleDataset } from "./simple-chart";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";

export const PeriodDataset = types
    .compose(
        Transport,
        Notificator,
        types.model({
            period: types.union(types.literal("Quarter"), types.literal("Year")),
            url: types.string,
            title: types.string,
            loaded: types.boolean,
            loading: types.boolean,
            data: types.map(SimpleDataset),
        })
    )
    .actions((self) => ({
        setLoaded(value: boolean) {
            self.loaded = value;
        },

        setPeriod(value: "Quarter" | "Year") {
            self.period = value;
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            self.loading = true;
            try {
                const data: any = yield self.transport.get<any>(self.url);
                Object.keys(data.data).forEach((period) => (data.data[period].url = self.url));

                applySnapshot(
                    self,
                    {
                        period: self.period,
                        data: data.data,
                        loaded: true,
                        loading: false,
                        url: self.url,
                        title: self.title,
                    } ?? {}
                );

                return true;
            } catch (er) {
                self.loading = false;
                self.setLoaded(true);
                self.notify.error(er);

                return false;
            }
        }),
    }))
    .views((self) => ({
        get amChartData() {
            const chart = self.data.get(self.period);
            return chart ? chart.amChartData : [];
        },

        get summary() {
            return self.data.get("Quarter")?.completedValue ?? 0;
        },

        get current() {
            return self.data.get("Quarter")?.activeValue ?? 0;
        },

        get observable() {
            return self.period;
        },
    }));

export type PeriodDatasetType = typeof PeriodDataset.Type;
export type PeriodDatasetSnapshotType = typeof PeriodDataset.SnapshotType;

export const initialState = (url: string, title: string): PeriodDatasetSnapshotType => ({
    data: {},
    loaded: false,
    loading: false,
    title: title,
    url: url,
    period: "Quarter",
});
