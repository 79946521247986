import React from "react";
import { IInputGroupProps, HTMLInputProps, InputGroup } from "@blueprintjs/core";
import NumberFormat, { NumberFormatValues } from "react-number-format";

export class MoneyInput extends React.Component<MoneyInputProps & HTMLInputProps> {
    render() {
        const { type, value, hideSuffix, onMoneyChange, allowNegative, ...rest } = this.props;

        return (
            <NumberFormat
                {...rest}
                value={value === null || value === undefined ? "" : value}
                thousandSeparator={" "}
                allowNegative={typeof allowNegative === "boolean" ? allowNegative : false}
                suffix={hideSuffix ? undefined : " ₽"}
                customInput={InputGroup}
                allowedDecimalSeparators={[".", ","]}
                onValueChange={onMoneyChange}
            />
        );
    }
}

interface MoneyInputProps extends Omit<IInputGroupProps, "value"> {
    value?: number | string;
    allowNegative?: boolean;
    onMoneyChange: (value: NumberFormatValues) => void;
    hideSuffix?: boolean;
}
