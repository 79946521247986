import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { groupBy, maxBy, orderBy } from "lodash";
import { nameof } from "modules/common/services/typescript";
import {
    EmployerDictionaryItem,
    EmployerDictionaryItemType,
} from "modules/spending/employee/models/employee-dictionary";
import { SectionCollapser } from "modules/common/models/section-collapser";
import { Queryable } from "modules/common/models/queryable";
import { getMedian } from "modules/common/services/array";
import { base64ToBlob, printPdf } from "modules/common/services/files";
import { ColumnState } from "@ag-grid-community/core";

const NAME = "UnitStore";

const WorkingOut = types
    .model({
        salary: types.number,
        paidMoney: types.number,
        salaryFactor: types.number,
        workedOutHours: types.number,
        overtimeHours: types.number,
        hours: types.number,
        hoursFactor: types.number,
        factor: types.number,

        workPercent: types.number,
        sum: types.number,
        employerId: types.string,
        name: types.string,
    })
    .named("WorkingOut");

export type WorkingOutType = typeof WorkingOut.Type;
export type WorkingOutSnapshotType = typeof WorkingOut.SnapshotType;

export const UnitEmployerMonth = types
    .model({
        month: types.number,
        year: types.number,
        quarter: types.number,
        quarterName: types.string,
        monthName: types.string,
        finalSalary: types.number,
        paidMoney: types.number,
        workDaysCount: types.number,
        workHoursCount: types.number,
        workedOutHours: types.number,
        workedOutDays: types.number,
        illnessHours: types.number,
        illnessDays: types.number,
        vacationHours: types.number,
        vacationDays: types.number,
        missingHours: types.number,
        missingDays: types.number,
    })
    .named("UnitEmployerMonth");
const EmployerMonth = types
    .compose(
        EmployerDictionaryItem,
        UnitEmployerMonth,
        types.model({
            costPerHour: types.number,
            percentDifference: types.number,
            actCost: types.number,
            planCost: types.number,
            workedPercentSort: types.number,
        })
    )
    .named("EmployerMonth");
export type EmployerMonthType = typeof EmployerMonth.Type;
export type EmployerMonthSnapshotType = typeof EmployerMonth.SnapshotType;
export type UnitEmployerMonthType = typeof UnitEmployerMonth.Type;
export type UnitEmployerMonthSnapshotType = typeof UnitEmployerMonth.SnapshotType;

export const monthFields = {
    workedOutHours: nameof((a: EmployerMonthType) => a.workedOutHours) as string,
    paidMoney: nameof((a: EmployerMonthType) => a.paidMoney) as string,
    costPerHour: nameof((a: EmployerMonthType) => a.costPerHour) as string,
    percentDifference: nameof((a: EmployerMonthType) => a.percentDifference) as string,
    workedPercentSort: nameof((a: EmployerMonthType) => a.workedPercentSort) as string,
    name: nameof((a: EmployerMonthType) => a.name) as string,
};

export const UnitStore = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            departments: types.array(types.string),
            year: types.number,
            days: types.number,
            workDays: types.number,
            workHours: types.number,
            years: types.array(types.model({ year: types.number })),
            users: types.array(
                types.model({
                    employer: EmployerDictionaryItem,
                    months: types.array(UnitEmployerMonth),
                })
            ),
            workingOut: types.array(
                types.model({
                    income: types.number,
                    period: types.number,
                    periodName: types.string,
                    type: types.string,
                    data: types.array(WorkingOut),
                })
            ),
            loader: types.optional(types.number, 0),
            collapser: SectionCollapser,
            showMinutes: types.boolean,
        })
    )
    .views((self) => ({
        get usersLength() {
            return self.users.length;
        },
    }))
    .actions((self) => ({
        isRowMatch(name: string, department?: string, position?: string) {
            const filterRow = `${name} ${department} ${position}`.toLowerCase();
            return filterRow.includes(self.pureQuery);
        },
    }))
    .views((self) => ({
        get isEmpty() {
            return self.users.length === 0;
        },

        get isLoading() {
            return self.loader > 0;
        },

        get periodGroups() {
            const groups: TStringMap<UnitPeriod> = emptyGrouping();

            const temp: TStringMap<
                Array<{ employer: EmployerDictionaryItemType; data: UnitEmployerMonthSnapshotType }>
            > = {};

            self.users.forEach((user) => {
                const year: UnitEmployerMonthType[] = [];
                const periods: TStringMap<UnitEmployerMonthType[]> = {};

                user.months.forEach((month) => {
                    if (!periods[month.quarterName]) {
                        periods[month.quarterName] = [];
                    }

                    if (!temp[month.quarterName]) {
                        temp[month.quarterName] = [];
                    }

                    periods[month.quarterName].push(month);
                    year.push(month);
                });

                periods["Год"] = year;
                if (!temp["Год"]) {
                    temp["Год"] = [];
                }

                Object.keys(periods).forEach((periodName) => {
                    const mergedRow = merge(periods[periodName]);

                    if (mergedRow) {
                        temp[periodName].push({
                            data: mergedRow,
                            employer: user.employer,
                        });
                    }
                });
            });

            Object.keys(temp).forEach((periodName) => {
                const medianHours = getMedian(temp[periodName], (r) => {
                    if (temp[periodName].length && temp[periodName].length > 0) {
                        return r.data.workedOutHours;
                    }
                    return 0;
                });
                const departmentMap = groupBy(temp[periodName], (r) => r.employer.department);
                const users = temp[periodName].map((item) => {
                    const planCost = userPlanCost(item.data);
                    const actCost = userActCost(item.data);
                    const percentDifference = userPercentDifference(item.data);

                    const workedPercentSort = (100 * item.data.workedOutHours) / userTotalHours(item.data);
                    return {
                        ...item.data,
                        ...item.employer,
                        costPerHour: item.data.paidMoney / item.data.workedOutHours,
                        planCost,
                        actCost,
                        percentDifference,
                        workedPercentSort,
                    };
                });
                const maxHours = maxBy(temp[periodName], (r) => r.data.workedOutHours)?.data.workedOutHours ?? 0;

                groups[periodName] = {
                    departmentMap,
                    medianHours,
                    users,
                    maxHours,
                };
            });

            return groups;
        },
    }))
    .actions((self) => ({
        workedOutPeriod(periodName: string) {
            if (periodName === "Год") {
                periodName = self.year.toString();
            }

            let arr: WorkingOutType[] = [];
            let search: WorkingOutType[] = [];

            self.workingOut.find((period) => {
                if (period.periodName === periodName) {
                    period.data.forEach((data) => {
                        if (self.isRowMatch(data.name)) {
                            search.push(data);
                        }
                    });

                    arr = period.data;
                    return true;
                }

                return false;
            });

            if (search.length > 0) {
                return search;
            }

            return arr;
        },
    }))
    .actions((self) => ({
        buildDepartmentPrintModel(period: string): PrintDepartmentModel[] | null {
            const source = self.periodGroups[period];

            if (!source) {
                return null;
            }

            const result: PrintDepartmentModel[] = [];

            Object.keys(source.departmentMap).forEach((department) => {
                const userList = source.departmentMap[department].filter((row) =>
                    self.isRowMatch(row.employer.name, row.employer.department, row.employer.position)
                );

                if (userList.length) {
                    result.push({
                        name: department,
                        median: source.maxHours > 0 ? (100 * source.medianHours) / source.maxHours : 0,
                        users: userList.map((u) => ({
                            data: u.data,
                            name: u.employer.name,
                            position: u.employer.position,
                        })),
                    });
                }
            });

            return result.length ? result : null;
        },

        buildDirectPrintModel(period: string, column?: ColumnState): PrintDepartmentModel | null {
            const source = self.periodGroups[period];
            if (!source) {
                return null;
            }

            let userList = source.users.filter((row) => self.isRowMatch(row.name, row.department, row.position));
            if (!userList.length) {
                return null;
            }

            if (column && column.colId && userList[0].hasOwnProperty(column.colId)) {
                userList = orderBy(userList, [column.colId], [column.sort as any]);
            }

            return {
                median: source.maxHours > 0 ? (100 * source.medianHours) / source.maxHours : 0,
                name: "",
                users: userList.map((u) => ({
                    data: u,
                    name: u.name,
                    position: u.position,
                })),
            };
        },

        buildWorkingOutPrintModel(period: string, column?: ColumnState) {
            let source = self.workedOutPeriod(period);
            if (!source.length) {
                return null;
            }

            if (column && column.colId && source[0].hasOwnProperty(column.colId)) {
                source = orderBy(source, [column.colId], [column.sort as any]);
            }

            return source;
        },

        showPdf: flow(function* (content: DownloadFileResult) {
            const blob: any = yield base64ToBlob(content.content, content.mimeType);
            const fileURL = URL.createObjectURL(blob);
            const printer = printPdf(fileURL, true);
            if (printer) {
                printer.onclose = () => URL.revokeObjectURL(fileURL);
            }

            return true;
        }),
    }))
    .actions((self) => {
        const updateMe = (data: any) =>
            applySnapshot(self, {
                ...data,
                collapser: getSnapshot(self.collapser),

                query: "",
                pureQuery: "",
            });

        return {
            setPeriod: (year: number) => {
                self.year = year;
            },

            load: flow(function* (year: number | null = null) {
                self.loader++;

                try {
                    const data: any = yield self.transport.get<any>(apiUrls.timesheet.unit.list, {
                        params: { year: year || self.year },
                    });

                    if (data === null) {
                        self.loader--;
                    } else {
                        updateMe({
                            ...data,
                            loader: self.loader - 1,
                        });
                    }

                    groupBy(data.users, (row) => self.collapser.set(row.employer?.department || "", true));

                    return true;
                } catch (er) {
                    self.notify.error(er);

                    applySnapshot(self, {
                        ...initialState(),
                        year: year || self.year,
                        loader: self.loader - 1,
                        query: "",
                        pureQuery: "",
                    });

                    return false;
                }
            }),

            printDepartments: flow(function* (period: string) {
                try {
                    const model = self.buildDepartmentPrintModel(period);
                    if (!model) {
                        return;
                    }

                    const content: DownloadFileResult = yield self.transport.post<any>(
                        apiUrls.timesheet.unit.print.departmetns,
                        {
                            departments: model,
                            period,
                            year: self.year,
                        }
                    );

                    if (content) {
                        return self.showPdf(content);
                    }

                    return false;
                } catch (er) {
                    self.notify.error(er);
                    return false;
                }
            }),

            printDirect: flow(function* (period: string, column?: ColumnState) {
                try {
                    const model = self.buildDirectPrintModel(period, column);
                    if (!model) {
                        return;
                    }

                    const content: DownloadFileResult = yield self.transport.post<any>(
                        apiUrls.timesheet.unit.print.direct,
                        {
                            ...model,
                            period,
                            year: self.year,
                        }
                    );

                    if (content) {
                        return self.showPdf(content);
                    }

                    return false;
                } catch (er) {
                    self.notify.error(er);
                    return false;
                }
            }),

            printWorkingOut: flow(function* (period: string, column?: ColumnState) {
                try {
                    const model = self.buildWorkingOutPrintModel(period, column);
                    if (!model) {
                        return;
                    }

                    const content: DownloadFileResult = yield self.transport.post<any>(
                        apiUrls.timesheet.unit.print.workingout,
                        {
                            users: model,
                            period,
                            year: self.year,
                        }
                    );

                    if (content) {
                        return self.showPdf(content);
                    }

                    return false;
                } catch (er) {
                    self.notify.error(er);
                    return false;
                }
            }),
        };
    })
    .named(NAME);

export type UnitStoreType = typeof UnitStore.Type;
export type UnitStoreSnapshotType = typeof UnitStore.SnapshotType;

export const fields = {
    sum: nameof((a: WorkingOutType) => a.sum) as string,
    name: nameof((a: WorkingOutType) => a.name) as string,
    salary: nameof((a: WorkingOutType) => a.salary) as string,
    salaryFactor: nameof((a: WorkingOutType) => a.salaryFactor) as string,
    workedOutHours: nameof((a: WorkingOutType) => a.workedOutHours) as string,
    overtimeHours: nameof((a: WorkingOutType) => a.overtimeHours) as string,
    hours: nameof((a: WorkingOutType) => a.hours) as string,
    hoursFactor: nameof((a: WorkingOutType) => a.hoursFactor) as string,
    factor: nameof((a: WorkingOutType) => a.factor) as string,
    workPercent: nameof((a: WorkingOutType) => a.workPercent) as string,
    paidMoney: nameof((a: WorkingOutType) => a.paidMoney) as string,
};

export const initialState = (): UnitStoreSnapshotType => {
    const now = new Date();

    return {
        departments: [],
        year: now.getFullYear(),
        days: 0,
        query: "",
        pureQuery: "",
        workDays: 0,
        workHours: 0,
        users: [],
        workingOut: [],
        collapser: { opened: {} },
        loader: 0,
        years: [],
        showMinutes: false,
    };
};

export interface UnitPeriodRow {
    /** данные о сотруднике */
    employer: EmployerDictionaryItemType;
    /** кол-во часов и денежных средств для графика */
    data: UnitEmployerMonthSnapshotType;
}

export interface UnitPeriod {
    /** [одтел; список сотрудников] */
    departmentMap: TStringMap<UnitPeriodRow[]>;
    /** медиана рабочих часов за период */
    medianHours: number;
    /** максимальное значение рабочих часов за период */
    maxHours: number;
    users: EmployerMonthSnapshotType[];
}

function merge(rows: UnitEmployerMonthType[]): UnitEmployerMonthSnapshotType | null {
    if (!rows.length) {
        return null;
    }

    const mwrged: UnitEmployerMonthSnapshotType = {
        finalSalary: 0,
        illnessDays: 0,
        illnessHours: 0,
        missingDays: 0,
        missingHours: 0,
        month: 1,
        monthName: "",
        paidMoney: 0,
        quarter: rows[0].quarter,
        quarterName: rows[0].quarterName,
        vacationDays: 0,
        vacationHours: 0,
        workDaysCount: 0,
        workHoursCount: 0,
        workedOutDays: 0,
        workedOutHours: 0,

        year: rows[0].year,
    };

    let hours = 0;

    rows.forEach((row) => {
        mwrged.finalSalary += row.finalSalary;
        mwrged.illnessDays += row.illnessDays;
        mwrged.illnessHours += row.illnessHours;
        mwrged.missingDays += row.missingDays;
        mwrged.missingHours += row.missingHours;
        mwrged.paidMoney += row.paidMoney;
        mwrged.vacationDays += row.vacationDays;
        mwrged.vacationHours += row.vacationHours;
        mwrged.workDaysCount += row.workDaysCount;
        mwrged.workHoursCount += row.workHoursCount;
        mwrged.workedOutDays += row.workedOutDays;
        mwrged.workedOutHours += row.workedOutHours;

        hours += row.illnessHours + row.vacationHours + row.workedOutHours;
    });

    return hours > 0 ? mwrged : null;
}

export function userTotalHours(row: UnitEmployerMonthSnapshotType) {
    return row.workHoursCount;
}

export function userPlanCost(row: UnitEmployerMonthSnapshotType) {
    return row.finalSalary / row.workHoursCount;
}
export function userPercentDifference(row: UnitEmployerMonthSnapshotType) {
    const actCost = userActCost(row);
    const planCost = userPlanCost(row);

    return actCost === 0 || planCost === 0
        ? 0
        : planCost <= actCost
        ? ((actCost - planCost) / planCost) * 100
        : ((planCost - actCost) / planCost) * 100 * -1;
}

export function userActCost(row: UnitEmployerMonthSnapshotType) {
    return row.workedOutHours === 0 && row.paidMoney > 0
        ? 0
        : row.workedOutHours === 0 && row.paidMoney === 0
        ? 0
        : row.paidMoney / row.workedOutHours;
}

function emptyGrouping() {
    const groups: TStringMap<UnitPeriod> = {
        "I квартал": { departmentMap: {}, users: [], maxHours: 0, medianHours: 0 },
        "II квартал": { departmentMap: {}, users: [], maxHours: 0, medianHours: 0 },
        "III квартал": { departmentMap: {}, users: [], maxHours: 0, medianHours: 0 },
        "IV квартал": { departmentMap: {}, users: [], maxHours: 0, medianHours: 0 },
        Год: { departmentMap: {}, users: [], maxHours: 0, medianHours: 0 },
    };

    return groups;
}

interface PrintDepartmentModel {
    median: number;
    name: string;
    users: PrintDepartmentModelUser[];
}

interface PrintDepartmentModelUser {
    position: string;
    name: string;
    data: UnitEmployerMonthSnapshotType;
}
