import React from 'react';
import { LoggedInProps } from 'modules/common/components/routing/AnonymousRoute';
import { ProtectedRoute } from 'modules/common/components/routing/ProtectedRoute';
import { routes } from 'modules/common/routes';
import { SessionType } from 'modules/session/auth/models/session';
import { OrderMailsStoreType } from './models/store';
import { OrderMailsPage } from './components/OrderMailsPage';

const path = routes.orders.mails;

export const Routes = ({ store, session, loggedIn }: RoutesProps) => {
    return (
        <>
            <ProtectedRoute
                path={path}
                loggedIn={loggedIn}
                render={(props) => (
                    <OrderMailsPage
                        {...props}
                        store={store}
                        session={session}
                    />
                )}
            />
        </>
    )
};

interface RoutesProps extends LoggedInProps {
    store: OrderMailsStoreType;
    session: SessionType;
}
