import React from "react";
import classnames from "classnames";
import { GeneralIcon, GeneralIcons } from "../icon/Generalcon";

export const PlanrButton: React.FC<ButtonProps> = ({
    type,
    size,
    className,
    nativeType,
    icon,
    disabled,
    round,
    onClick,
    children,
    style,
    title,
    datatype,
    loading,
    innerRef,
    ...rest
}) => {
    // TODO: loading

    const styleName = classnames({
        "planr-button": true,
        "is-round": round,
        primary: !type || type === "primary",
        secondary: type === "secondary",
        dashed: type === "dashed",
        neutral: type === "neutral",
        graybtn: type === "graybtn",
        blueish: type === "blueish",
        danger: type === "danger",
        lightdanger: type === "lightdanger",
        lightblueish: type === "lightblueish",
        greenish: type === "greenish",
        lightgreenish: type === "lightgreenish",
        whiteish: type === "whiteish",
        success: type === "success",
        medium: size !== "small" && size !== "big" && size !== "tiny",
        small: size === "small",
        big: size === "big",
        tiny: size === "tiny",

        disabled: disabled,
        [className || ""]: true,
    });

    return (
        <button
            {...rest}
            onClick={onClick}
            type={nativeType || "button"}
            className={styleName}
            disabled={disabled}
            style={style}
            title={title}
            datatype={datatype}
            ref={innerRef}
        >
            {icon && <GeneralIcon type={icon} title={title} />}
            {children && <span>{children}</span>}
        </button>
    );
};

export interface ButtonProps {
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    size?: "medium" | "small" | "big" | "tiny";
    icon?: GeneralIcons;
    loading?: boolean;
    disabled?: boolean;
    type:
        | "primary"
        | "secondary"
        | "dashed"
        | "neutral"
        | "graybtn"
        | "success"
        | "blueish"
        | "danger"
        | "lightdanger"
        | "greenish"
        | "lightgreenish"
        | "lightblueish"
        | "whiteish";
    nativeType?: "submit" | "reset" | "button";
    className?: string;
    round?: boolean;
    style?: React.CSSProperties;
    title?: string;
    datatype?: string;
    innerRef?: any;
}
