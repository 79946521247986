import { IpdTypeDictionaryItemType } from "modules/dictionaries/ipd-types/models/ipd-type";
import { SaveOrderIpd } from "modules/orders-manage/models/order-ipd";
import React from "react";
import { Dialog, Classes } from "@blueprintjs/core";
import { observer } from "mobx-react";
import styles from "./IpdDetails.module.scss";
import { Buttons } from "modules/common/components/form";
import { texts } from "modules/common/texts";
// import classnames from "classnames";
import { IpdTypeDictionaryType } from "modules/dictionaries/ipd-types/models/ipd-type-dictionary";
// import { ICON_SIZE } from "../content/ContentDetails";
import { Search } from "modules/common/components/form/Search";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

export const IpdsDetails = observer(
    class extends React.Component<IpdsDetailsProps, IpdsDetailsState> {
        constructor(props: IpdsDetailsProps) {
            super(props);
            this.state = { units: [...props.units], message: "" };
        }

        render() {
            const { ipdTypes, factory, onClose } = this.props;
            const { units, message } = this.state;

            return (
                <Dialog
                    isCloseButtonShown={true}
                    isOpen={true}
                    className={`${styles.details} figma-dialog`}
                    onClose={onClose}
                    title="ИРД"
                    backdropClassName="standard"
                    canOutsideClickClose={false}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <Search
                            query={ipdTypes.query}
                            onSearch={ipdTypes.setQuery}
                            style={{ marginLeft: 0, marginBottom: "1rem" }}
                        />

                        {ipdTypes.filtered.map((type) => {
                            const checked = !!units.find((i) => i.orderIpdTypeId === type.id);

                            return (
                                <div key={type.id} className={styles.row}>
                                    <div className={styles.iconCell}>
                                        <GeneralIcon
                                            type={checked ? "general-checkbox" : "general-zero-checkbox"}
                                            onClick={async () => {
                                                let newValue: SaveOrderIpd[];

                                                // add new type
                                                if (!checked) {
                                                    this.setState({ message: "" });

                                                    const content = await factory.emptyIpdFromDictionary(type);
                                                    newValue = [...units, content];
                                                } else {
                                                    newValue = units.filter((i) => i.orderIpdTypeId !== type.id);
                                                }

                                                this.setState({ units: newValue });
                                            }}
                                        />
                                    </div>

                                    <div className={styles.name}>{type.label}</div>

                                    <div className={styles.description}>{type.description}</div>
                                </div>
                            );
                        })}
                    </div>

                    <div className={Classes.DIALOG_FOOTER}>
                        <Buttons
                            left={
                                <div style={{ display: "flex" }}>
                                    <PlanrButton type="greenish" size="small" onClick={this.onClose}>
                                        {texts.save}
                                    </PlanrButton>

                                    <PlanrButton
                                        type="graybtn"
                                        onClick={onClose}
                                        size="small"
                                        style={{ marginLeft: "10px" }}
                                    >
                                        {texts.cancel}
                                    </PlanrButton>
                                </div>
                            }
                        >
                            {message && <div className={styles.error}>{message}</div>}
                        </Buttons>
                    </div>
                </Dialog>
            );
        }

        onClose = () => {
            const { units } = this.state;
            const { onSave } = this.props;

            if (!units.length) {
                this.setState({ message: "Необходимо выбрать список ИРД" });
            } else {
                onSave(units);
            }
        };
    }
);

interface IpdsDetailsProps {
    ipdTypes: IpdTypeDictionaryType;
    units: SaveOrderIpd[];
    onSave: (units: SaveOrderIpd[]) => void;
    onClose: () => void;
    factory: IpdFactory;
}

interface IpdsDetailsState {
    units: SaveOrderIpd[];
    message: string;
}

export interface IpdFactory {
    emptyIpd: () => Promise<SaveOrderIpd>;
    emptyIpdFromDictionary: (item: IpdTypeDictionaryItemType) => Promise<SaveOrderIpd>;
}
