import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { routes } from "modules/common/routes";
import { SimpleMessage, SimpleMessageProps } from "./SimpleMessage";
import { KnowledgeBaseTypes } from "modules/technical/models/store";

export const KnowledgeBaseFileUploadedMessage = observer(
    class extends React.Component<KnowledgeBaseFileUploadedMessageProps> {
        render() {
            const { message, ...rest } = this.props;
            const type = message.data.get("Type");
            const path = message.data.get("Path");
            const [key] = Object.entries(KnowledgeBaseTypes).find(([_, value]) => value === type) ?? [
                KnowledgeBaseTypes.manuals,
                "",
            ];

            return (
                <SimpleMessage message={message} {...rest}>
                    <div>
                        {path} &nbsp;
                        <Link to={`${routes.technical.path}/${key}`}>Перейти</Link>
                    </div>
                </SimpleMessage>
            );
        }
    }
);

interface KnowledgeBaseFileUploadedMessageProps extends SimpleMessageProps {}
