import { Button, Checkbox, Classes, InputGroup, Radio, RadioGroup, TextArea } from "@blueprintjs/core";
import { Form, Formik } from "formik";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { ActualPaymentType } from "modules/agents/outsourcers/models/outsourcer-payment";
import { FileLink } from "modules/common/components/files/FileLink";
import { DatePicker } from "modules/common/components/form/DatePicker";
import {
    filterProjectItemPredicate,
    ProjectSelect,
    ProjectSelectedOption,
    renderProjectOption,
} from "modules/common/components/form/ProjectSelect";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { Loader } from "modules/common/components/loader/Loader";
import { formatMoney } from "modules/common/components/money/Money";
import { MoneyInput } from "modules/common/components/money/MoneyInput";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { isNewlyCreated } from "modules/common/models/entity";
import {
    DefaultSelectedOption,
    filterItemPredicate,
    renderSingleOption,
    SimpleSelect,
} from "modules/common/services/form/select";
import { fixTextAreaHeight, MODAL_AWAIT_DELAY } from "modules/common/services/form/textarea";
import { stopPropagation } from "modules/common/services/form/values";
import { eat } from "modules/common/services/typescript";
import { texts } from "modules/common/texts";
import { OverheadTypeDictionaryType } from "modules/dictionaries/overhead-types/models/overhead-type-dictionary";
import { IncomingTypeOptions, SpendingTypeOptions } from "modules/expenses/common";
import { FinanceRequestDictionaryType } from "modules/expenses/finance-requests/models/finance-requests-dictionary";
import {
    filterFinanceRequestItemPredicate,
    FinanceRequestSelect,
    renderFinanceRequestOptionFactory,
    renderOrderSpendingsFactory,
} from "modules/expenses/import/components/columns";
import { FinanceRequestStatisticDictionaryDictionaryItem } from "modules/expenses/import/models/bank-row";
import { OrderDictionaryItemType, OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";
import { OutsourcedOrderSpendingType } from "modules/orders-manage/models/order-spending";
import { Constants } from "modules/root/models/constants";
import React from "react";
import { Prompt } from "react-router-dom";
import { OtherOrderSpendingType } from "../../../orders-manage/models/order-spending";
import { SpendingsListType } from "../models/spending-list";
import { SpendingsListRowSnapshotType } from "../models/spending-list-row";
import { fields, Model, overheadVisible, productionVisible, SpeningSchema } from "./validation";

type TRequest = typeof FinanceRequestStatisticDictionaryDictionaryItem.SnapshotType;

const renderFinanceRequestOption = renderFinanceRequestOptionFactory(true);
const renderOrderSpendingsOption = renderOrderSpendingsFactory();
const schema = SpeningSchema();
const IMPORTED = "Импортировано из банка";

class Base extends React.Component<SpendingFormProps, SpendingFormState> {
    private productionRequests: TRequest[];
    private overheadRequests: TRequest[];
    private spendingTypeOptions: SelectItem[];
    private incomingTypeOptions: SelectItem[];
    private automatic: boolean;
    private commentArea: HTMLTextAreaElement | null = null;
    private outsorcedSpendings: any[] = [];
    private otherSpendings: any[] = [];
    constructor(props: SpendingFormProps) {
        super(props);
        this.state = {
            otherSpendings: [],
            outsourcedSpendings: [],
            loading: false,
        };
        const { requests, model, row } = props;

        this.spendingTypeOptions = SpendingTypeOptions();
        this.incomingTypeOptions = IncomingTypeOptions();

        this.productionRequests = [];
        this.overheadRequests = [];

        let alreadyHasItem = false;
        requests.requests.forEach((r) => {
            if (r.id === row?.item?.id) {
                alreadyHasItem = true;
            }

            if (r.type === "Production") {
                this.productionRequests.push(getSnapshot(r));
            } else {
                this.overheadRequests.push(getSnapshot(r));
            }
        });

        const editMode = !isNewlyCreated(model.id);

        if (editMode && row?.item != null && !alreadyHasItem) {
            if (!!row?.order) {
                this.productionRequests.unshift(row.item);
            } else if (row?.payload !== row?.dictionaryId) {
                const appendix = { ...row.item };
                appendix.label = appendix.outsourcerName;
                appendix.outsourcerName = row.item.label;

                this.overheadRequests.unshift(appendix);
            }
        }

        this.automatic = row !== null && row.automatic;
    }

    private commentAreaRef = (ref: HTMLTextAreaElement | null) => {
        this.commentArea = ref;
    };

    private resize = () => {
        fixTextAreaHeight(this.commentArea);
    };

    public componentDidMount() {
        setTimeout(this.resize, MODAL_AWAIT_DELAY);
    }

    public componentDidUpdate() {
        this.resize();
    }

    getSpendings = async (id: string) => {
        this.setState({ loading: true });
        const result = await this.props.store.loadSpendings(id);
        if (result) {
            this.setState({
                loading: false,
                otherSpendings: result.otherOrderSpendings,
                outsourcedSpendings: result.outsourcedOrderSpendins,
            });
        }
    };

    render() {
        const { orders, model, types, onSaved, onSubmit, baseUrl } = this.props;
        const editMode = !isNewlyCreated(model.id);

        return (
            <Formik
                initialValues={model}
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    const succsessfull = await onSubmit(values);

                    setSubmitting(false);

                    if (succsessfull) {
                        resetForm();
                        onSaved();
                    }
                }}
            >
                {(formProps) => {
                    const incoming = formProps.values.incoming;
                    const type = formProps.values.type;
                    const payloadLabel = incoming === "true" ? "Объект" : "";
                    const document = formProps.values.document;
                    const temp = formProps.values.tempPayloadId;
                    return (
                        <Form autoComplete="off" className="spending-details-form">
                            <Prompt when={formProps.dirty} message={texts.messages.leaveConfiramtion} />
                            <div className={Classes.DIALOG_BODY}>
                                <StandardFormInput name={fields.incoming} schema={schema} small={true}>
                                    {({ field, form }) => (
                                        <RadioGroup
                                            disabled={editMode}
                                            inline={true}
                                            onChange={(e) => {
                                                if (editMode) {
                                                    return;
                                                }

                                                const value = e.currentTarget.value;
                                                form.setFieldValue(field.name, value, true);

                                                form.setFieldValue(fields.type, "");
                                                form.setFieldValue(fields.payload, "");
                                                form.setFieldValue(fields.temp, "");
                                                form.setFieldValue(fields.sum, 0);
                                                form.setFieldValue(fields.date, null);
                                                form.setFieldValue(fields.document, null);
                                            }}
                                            selectedValue={field.value}
                                        >
                                            <Radio label="Доход" value="true" large={true} />
                                            <Radio label="Расход" value="false" large={true} />
                                        </RadioGroup>
                                    )}
                                </StandardFormInput>

                                {incoming === "true" && (
                                    <StandardFormInput
                                        name={fields.type}
                                        schema={schema}
                                        small={true}
                                        label="Тип дохода"
                                    >
                                        {({ field, form }) => {
                                            const typeOption = this.incomingTypeOptions.find(
                                                (o) => o.id === field.value
                                            );

                                            return (
                                                <SimpleSelect
                                                    disabled={editMode}
                                                    className={`full-width-select ${Classes.FILL}`}
                                                    activeItem={typeOption}
                                                    items={this.incomingTypeOptions}
                                                    itemRenderer={renderSingleOption}
                                                    onItemSelect={(i) => {
                                                        if (!editMode) {
                                                            form.setFieldValue(field.name, i.id, true);
                                                        }
                                                    }}
                                                    popoverProps={{
                                                        boundary: "viewport",
                                                        popoverClassName: "import-spendings-table__select",
                                                    }}
                                                    inputProps={{
                                                        placeholder: "Доход",
                                                    }}
                                                    filterable={true}
                                                    itemPredicate={filterItemPredicate}
                                                >
                                                    <DefaultSelectedOption option={typeOption} disabled={editMode} />
                                                </SimpleSelect>
                                            );
                                        }}
                                    </StandardFormInput>
                                )}

                                {type === Constants.bankInvoiceRowTypeIncoming && (
                                    <StandardFormInput name={fields.name} schema={schema} small={true}>
                                        {({ field }) => (
                                            <InputGroup
                                                className="planr-default-input"
                                                id={field.name}
                                                {...field}
                                                autoComplete="off"
                                                disabled={this.automatic}
                                                title={this.automatic ? IMPORTED : ""}
                                            />
                                        )}
                                    </StandardFormInput>
                                )}

                                {type === Constants.bankInvoiceRowTypeIncoming && (
                                    <StandardFormInput
                                        name={fields.payload}
                                        schema={schema}
                                        small={true}
                                        label={payloadLabel}
                                    >
                                        {({ field, form }) => {
                                            const item = orders.asMap[field.value];
                                            const projectSelected = (i: OrderDictionaryItemType | null) => {
                                                const value = i ? i.id : "";

                                                form.setFieldValue(field.name, value, true);
                                            };

                                            return (
                                                <ProjectSelect
                                                    className={"full-width-select"}
                                                    filterable={true}
                                                    itemPredicate={filterProjectItemPredicate}
                                                    activeItem={item}
                                                    items={orders.orders}
                                                    itemRenderer={renderProjectOption}
                                                    onItemSelect={projectSelected}
                                                    resetOnClose={true}
                                                    inputProps={{
                                                        placeholder: "Проект",
                                                    }}
                                                >
                                                    <ProjectSelectedOption option={item} />
                                                </ProjectSelect>
                                            );
                                        }}
                                    </StandardFormInput>
                                )}

                                {type === Constants.bankInvoiceRowTypeIncomingCorrection && (
                                    <StandardFormInput
                                        name={fields.payload}
                                        schema={schema}
                                        small={true}
                                        label={payloadLabel}
                                    >
                                        {({ field, form }) => {
                                            const item = orders.asMap[field.value];
                                            const projectSelected = async (i: OrderDictionaryItemType | null) => {
                                                const value = i ? i.id : "";

                                                form.setFieldValue(field.name, value, true);
                                                this.getSpendings(value);
                                            };

                                            return (
                                                <ProjectSelect
                                                    className={"full-width-select"}
                                                    filterable={true}
                                                    itemPredicate={filterProjectItemPredicate}
                                                    activeItem={item}
                                                    items={orders.orders}
                                                    itemRenderer={renderProjectOption}
                                                    onItemSelect={projectSelected}
                                                    resetOnClose={true}
                                                    inputProps={{
                                                        placeholder: "Проект",
                                                    }}
                                                >
                                                    <ProjectSelectedOption option={item} />
                                                </ProjectSelect>
                                            );
                                        }}
                                    </StandardFormInput>
                                )}

                                {this.state.loading && <Loader active />}
                                {!this.state.loading &&
                                    (this.state.otherSpendings.length > 0 ||
                                        this.state.outsourcedSpendings.length > 0) &&
                                    type === Constants.bankInvoiceRowTypeIncomingCorrection && (
                                        <StandardFormInput
                                            name={fields.spending}
                                            schema={schema}
                                            small={true}
                                            label={"Платеж"}
                                        >
                                            {({ field, form }) => {
                                                const spendingSelected = (i: any) => {
                                                    const value = i ? i.id : "";

                                                    form.setFieldValue(field.name, value, true);
                                                };

                                                const items = [
                                                    ...this.state.otherSpendings,
                                                    ...this.state.outsourcedSpendings,
                                                ];

                                                let payments: SpendingPayments[] = [];
                                                items.forEach((item) => {
                                                    if (item.actualPayments) {
                                                        item.actualPayments.forEach((payment: ActualPaymentType) => {
                                                            const correctionSum = payment.correctionPayments.reduce(
                                                                (accumulator, object) => {
                                                                    return accumulator + object.sum;
                                                                },
                                                                0
                                                            );
                                                            payments.push({
                                                                id: `${item.id} ${payment.guid}`,
                                                                label: `${formatMoney(payment.sum)} ${payment.date} ${
                                                                    item.outsourcer
                                                                        ? item.outsourcer.name.slice(0, 26)
                                                                        : ""
                                                                }`,
                                                                spendingId: item.id,
                                                                paymentGuid: payment.guid,
                                                                outsourcer: item.outsourcer ? item.outsourcer.name : "",
                                                                sum: formatMoney(payment.sum),
                                                                spentSum: formatMoney(payment.sum - correctionSum),
                                                                date: payment.date ? payment.date : "",
                                                            });
                                                        });
                                                    }
                                                });
                                                const item = payments.find((p) => p.id === field.value);

                                                return (
                                                    <SimpleSelect
                                                        className={`full-width-select ${Classes.FILL}`}
                                                        activeItem={item}
                                                        items={payments}
                                                        itemRenderer={renderOrderSpendingsOption}
                                                        onItemSelect={spendingSelected}
                                                        popoverProps={{
                                                            boundary: "viewport",
                                                            popoverClassName: "import-spendings-table__select",
                                                        }}
                                                        inputProps={{
                                                            placeholder: "Расход",
                                                        }}
                                                        filterable={true}
                                                        itemPredicate={filterItemPredicate}
                                                    >
                                                        <DefaultSelectedOption option={item} />
                                                    </SimpleSelect>
                                                );
                                            }}
                                        </StandardFormInput>
                                    )}

                                {incoming === "false" && (
                                    <StandardFormInput name={fields.type} schema={schema} small={true}>
                                        {({ field, form }) => {
                                            const typeOption = this.spendingTypeOptions.find(
                                                (o) => o.id === field.value
                                            );

                                            return (
                                                <SimpleSelect
                                                    disabled={editMode}
                                                    className={`full-width-select ${Classes.FILL}`}
                                                    activeItem={typeOption}
                                                    items={this.spendingTypeOptions}
                                                    itemRenderer={renderSingleOption}
                                                    onItemSelect={(i) => {
                                                        if (!editMode) {
                                                            form.setFieldValue(field.name, i.id, true);
                                                            form.setFieldValue(fields.document, null, true);
                                                        }
                                                    }}
                                                    popoverProps={{
                                                        boundary: "viewport",
                                                        popoverClassName: "import-spendings-table__select",
                                                    }}
                                                    inputProps={{
                                                        placeholder: "Расход",
                                                    }}
                                                    filterable={true}
                                                    itemPredicate={filterItemPredicate}
                                                >
                                                    <DefaultSelectedOption option={typeOption} disabled={editMode} />
                                                </SimpleSelect>
                                            );
                                        }}
                                    </StandardFormInput>
                                )}

                                {incoming === "false" && overheadVisible(type) && (
                                    <>
                                        <StandardFormInput
                                            name={fields.temp}
                                            schema={schema}
                                            label="Категория"
                                            small={true}
                                        >
                                            {({ field, form }) => {
                                                const typeOption = types.asMap[field.value];

                                                return (
                                                    <SimpleSelect
                                                        className={`full-width-select ${Classes.FILL}`}
                                                        activeItem={typeOption}
                                                        items={types.types}
                                                        itemRenderer={renderSingleOption}
                                                        onItemSelect={(i) => {
                                                            form.setFieldValue(field.name, i.id, true);
                                                            form.setFieldValue(fields.payload, i.id, true);
                                                        }}
                                                        popoverProps={{
                                                            boundary: "viewport",
                                                            popoverClassName: "import-spendings-table__select",
                                                        }}
                                                        inputProps={{
                                                            placeholder: "Расход",
                                                        }}
                                                        filterable={true}
                                                        itemPredicate={filterItemPredicate}
                                                    >
                                                        <DefaultSelectedOption option={typeOption} />
                                                    </SimpleSelect>
                                                );
                                            }}
                                        </StandardFormInput>

                                        {temp && (
                                            <StandardFormInput
                                                name={fields.payload}
                                                schema={schema}
                                                label="Запрос"
                                                small={true}
                                                required={false}
                                            >
                                                {({ field, form }) => {
                                                    const tempPayload = form.values[fields.temp];

                                                    let option: TRequest | null = null;
                                                    const filtered = this.overheadRequests.filter((r) => {
                                                        const sumOk = !this.automatic || r.percents === model.sum;

                                                        if (
                                                            (r.parent === tempPayload || r.id === field.value) &&
                                                            sumOk
                                                        ) {
                                                            if (r.id === field.value) {
                                                                option = r;
                                                            }

                                                            return true;
                                                        }

                                                        return false;
                                                    });

                                                    return (
                                                        <FinanceRequestSelect
                                                            className={`full-width-select ${Classes.FILL}`}
                                                            filterable={true}
                                                            itemPredicate={filterFinanceRequestItemPredicate}
                                                            activeItem={option}
                                                            items={filtered}
                                                            itemRenderer={renderFinanceRequestOption}
                                                            onItemSelect={(i) => {
                                                                const finRequest: TRequest = i as any;

                                                                form.setFieldValue(field.name, finRequest.id);
                                                                form.setFieldTouched(field.name, true);

                                                                form.setFieldValue(fields.sum, finRequest.percents);
                                                                form.setFieldValue(fields.document, finRequest.invoice);
                                                            }}
                                                            disabled={filtered.length === 0}
                                                            resetOnClose={true}
                                                            popoverProps={{
                                                                boundary: "viewport",
                                                                popoverClassName:
                                                                    "import-spendings-table__select finance-requests",
                                                            }}
                                                            inputProps={{
                                                                placeholder: "Запрос",
                                                            }}
                                                        >
                                                            <FinanceRequestSelectedOptions
                                                                option={option}
                                                                empty={filtered.length === 0}
                                                                onClear={(e) => {
                                                                    stopPropagation(e);

                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        form.values[fields.temp]
                                                                    );
                                                                    form.setFieldTouched(field.name, true);
                                                                }}
                                                            />
                                                        </FinanceRequestSelect>
                                                    );
                                                }}
                                            </StandardFormInput>
                                        )}
                                    </>
                                )}

                                {incoming === "false" && productionVisible(type) && (
                                    <StandardFormInput
                                        name={fields.payload}
                                        schema={schema}
                                        label="Платеж"
                                        small={true}
                                    >
                                        {({ field, form }) => {
                                            const filtered = editMode
                                                ? this.productionRequests.filter((r) => {
                                                      return r.percents === model.sum;
                                                  })
                                                : this.productionRequests;

                                            const option = filtered.find((o) => o.id === field.value) || null;

                                            return (
                                                <FinanceRequestSelect
                                                    className={`full-width-select ${Classes.FILL}`}
                                                    filterable={true}
                                                    itemPredicate={filterFinanceRequestItemPredicate}
                                                    activeItem={option}
                                                    items={filtered}
                                                    itemRenderer={renderFinanceRequestOption}
                                                    onItemSelect={(i) => {
                                                        const finRequest: TRequest = i as any;

                                                        form.setFieldValue(field.name, finRequest.id, true);
                                                        form.setFieldValue(fields.temp, finRequest.id, true);
                                                        form.setFieldValue(fields.sum, finRequest.percents);
                                                        form.setFieldValue(fields.document, finRequest.invoice);
                                                    }}
                                                    disabled={filtered.length === 0}
                                                    resetOnClose={true}
                                                    popoverProps={{
                                                        boundary: "viewport",
                                                        popoverClassName:
                                                            "import-spendings-table__select finance-requests",
                                                    }}
                                                    inputProps={{
                                                        placeholder: "Платеж",
                                                    }}
                                                >
                                                    <FinanceRequestSelectedOptions
                                                        option={option}
                                                        empty={filtered.length === 0}
                                                    />
                                                </FinanceRequestSelect>
                                            );
                                        }}
                                    </StandardFormInput>
                                )}

                                {dateVisible(formProps.values) && (
                                    <StandardFormInput name={fields.date} schema={schema} small={true} label="Дата">
                                        {({ field, form }) => {
                                            return (
                                                <div
                                                    className={`${Classes.INPUT_GROUP}`}
                                                    title={this.automatic ? IMPORTED : ""}
                                                >
                                                    <DatePicker
                                                        clasName="planr-default-input"
                                                        value={field.value}
                                                        onChange={(date) => {
                                                            form.setFieldValue(field.name, date, true);
                                                        }}
                                                        disabled={this.automatic}
                                                    />
                                                </div>
                                            );
                                        }}
                                    </StandardFormInput>
                                )}

                                {sumVisible(formProps.values) && (
                                    <StandardFormInput name={fields.sum} schema={schema} small={true}>
                                        {({ field }) => (
                                            <MoneyInput
                                                className="planr-default-input"
                                                onBlur={field.onBlur}
                                                name={field.name}
                                                value={field.value}
                                                onMoneyChange={(money) => {
                                                    formProps.setFieldValue(field.name, money.floatValue, true);
                                                }}
                                                small={false}
                                                autoComplete="off"
                                                data-lpignore="true"
                                                disabled={this.automatic}
                                                title={this.automatic ? IMPORTED : ""}
                                            />
                                        )}
                                    </StandardFormInput>
                                )}

                                {(type === Constants.bankInvoiceRowTypeIncoming || overheadVisible(type)) && (
                                    <StandardFormInput name={fields.isCash} schema={schema} small={true}>
                                        {({ field }) => {
                                            return (
                                                <div title={this.automatic ? IMPORTED : ""}>
                                                    <Checkbox
                                                        large={true}
                                                        checked={!!field.value}
                                                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                            formProps.setFieldValue(
                                                                field.name,
                                                                e.currentTarget.checked,
                                                                true
                                                            );
                                                            formProps.setFieldTouched(field.name, true);
                                                        }}
                                                        disabled={this.automatic}
                                                    />
                                                </div>
                                            );
                                        }}
                                    </StandardFormInput>
                                )}

                                {document && (
                                    <StandardFormInput name={fields.document} schema={schema} small={true} label=" ">
                                        {({ field }) => (
                                            <div className={`${Classes.INPUT_GROUP} ${Classes.SMALL}`}>
                                                <FileLink
                                                    baseUrl={baseUrl}
                                                    file={field.value}
                                                    readOnly={true}
                                                    onRemove={eat}
                                                />
                                            </div>
                                        )}
                                    </StandardFormInput>
                                )}

                                <StandardFormInput name={fields.comment} schema={schema} small={true}>
                                    {({ field }) => (
                                        <div className={`${Classes.INPUT_GROUP} ${Classes.SMALL}`}>
                                            <TextArea
                                                {...field}
                                                id={field.name}
                                                className={Classes.FILL}
                                                growVertically={true}
                                                inputRef={this.commentAreaRef}
                                            />
                                        </div>
                                    )}
                                </StandardFormInput>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <StandardFormButtons
                                    {...formProps}
                                    isRemoved={false}
                                    what={""}
                                    isNewlyCreated={true}
                                    onRemove={eat}
                                />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

const FinanceRequestSelectedOptions = (props: {
    option: TRequest | null;
    empty: boolean;
    onClear?: (e: React.MouseEvent) => void;
}) => {
    const summ = props.option ? <span className="right-text">{formatMoney(props.option.percents)}</span> : undefined;

    const icon =
        props.onClear && !props.empty ? (
            <>
                <GeneralIcon type="general-cross-small" onClick={props.onClear} style={{ color: "#E31818" }} />
                <GeneralIcon type="general-chevron-down" />
            </>
        ) : (
            <GeneralIcon type="general-chevron-down" />
        );

    return (
        <Button
            fill={true}
            rightIcon={icon}
            className="selected-option"
            text={props.empty ? texts.noData : props.option ? props.option.label || " " : " "}
        >
            {summ}
        </Button>
    );
};

export const SpendingForm = observer(Base);

interface SpendingFormProps {
    orders: OrderDictionaryType;
    types: OverheadTypeDictionaryType;
    requests: FinanceRequestDictionaryType;
    onSubmit: (values: Model) => Promise<boolean>;
    onSaved: () => void;
    model: Model;
    row: SpendingsListRowSnapshotType | null;
    baseUrl: string;
    store: SpendingsListType;
}

interface SpendingFormState {
    otherSpendings: OtherOrderSpendingType[];
    outsourcedSpendings: OutsourcedOrderSpendingType[];
    loading: boolean;
}

const sumVisible = (model: Model) => {
    return model.type && model.type !== Constants.bankInvoiceRowTypeProduction;
};

const dateVisible = (model: Model) => {
    return !!model.type;
};

export interface SpendingPayments {
    id: string;
    label: string;
    spendingId: string;
    paymentGuid: string;
    outsourcer: string;
    sum: string;
    spentSum: string;
    date: string;
}
