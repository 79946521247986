import React from "react";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Block } from "modules/common/components/page/Block";
import { Route, withRouter, RouteComponentProps, matchPath } from "react-router";
import { routes } from "modules/common/routes";
import { UnregisterCallback } from "history";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { OrderStatusStoreType } from "../models/order-status-store";
import { OrderStatusDetails } from "./details/OrderStatusDetails";
import { OrderStatusesList } from "./list/OrderStatusList";
import { menuItems } from "modules/root/components/menu/MainMenu";
import func from "modules/dictionaries/functionalities";
import { can } from "modules/session/auth/access";

import { PlanrButton } from "modules/common/components/planr/button/Button";
import styles from "./OrderStatusPage.module.scss";
import { Search } from "modules/common/components/form/Search";

const menu = menuItems();

class OrderStatusPage extends React.Component<OrderStatusPagePageProps & RouteComponentProps, OrderStatusPageState> {
  private unregister: UnregisterCallback | null = null;

  constructor(props: OrderStatusPagePageProps & RouteComponentProps) {
    super(props);

    this.state = { statusId: getSelectedStatusId(props.location.pathname) };
  }

  componentDidMount() {
    document.title = menu.dictionaries.nested.orderStatuses.label;

    const { history } = this.props;
    const { statusId } = this.state;

    this.loadList();
    this.loadDetails(statusId);

    this.unregister = history.listen((location) => {
      const statusId = getSelectedStatusId(location.pathname);

      if (this.state.statusId !== statusId) {
        this.setState({ statusId });
        this.loadDetails(statusId);
      }
    });
  }

  componentWillUnmount() {
    this.unregister && this.unregister();
  }

  render() {
    const { store, access } = this.props;
    const canChange = can(func.ORDERSTATUS_EDIT, access);

    return (
      <Block columned={true} className={styles.page}>
        <Route path={routes.dictionaries.orderStatus.details()}>
          <DetailsPopup title="Статус договора" onCancel={this.onStatusSelected}>
            <OrderStatusDetails
              canChange={canChange}
              orderStatus={store.details}
              onSaved={this.onStatusSaved}
              onRemoved={this.onStatusRemoved}
            />
          </DetailsPopup>
        </Route>
        <div className={styles.tools}>
          {canChange && (
            <PlanrButton
              type="secondary"
              size="small"
              icon="general-plus-big"
              onClick={this.onStatusAdd}
              style={{
                width: "260px",
                marginBottom: "32px",
                borderRadius: "120px",
              }}
            >{`${texts.add} cтатус договора`}</PlanrButton>
          )}
        </div>
        <div className={styles.mobileTools}>
          {canChange && <PlanrButton type="secondary" round icon="general-plus-big" onClick={this.onStatusAdd} />}
        </div>
        <div className={styles.right}>
          <Search query={store.list.query} onSearch={store.list.setQuery} />
        </div>
        <OrderStatusesList store={store.list} onSelected={this.onStatusSelected} onRemoved={this.onStatusRemoved} />
      </Block>
    );
  }

  onStatusAdd = () => {
    this.onStatusSelected(EMPTY_OBJECT_ID);
  };

  onStatusSelected = (id: string = "") => {
    const { history } = this.props;

    const url = id ? routes.dictionaries.orderStatus.details(id) : routes.dictionaries.orderStatus.path;

    history.push(url);
  };

  onStatusSaved = () => {
    this.onStatusSelected("");
    this.loadList();
  };

  onStatusRemoved = () => {
    const { history } = this.props;

    this.loadList();

    const url = routes.dictionaries.orderStatus.path;
    history.push(url);
  };

  loadList = () => this.props.store.list.load();

  loadDetails = (id: string) => this.props.store.details.load(id);
}

export default withRouter(observer(OrderStatusPage));

function getSelectedStatusId(pathname: string) {
  const match = matchPath<OrderStatusPageState>(pathname, routes.dictionaries.orderStatus.details());

  return match && match.params ? match.params.statusId : "";
}

interface OrderStatusPagePageProps {
  store: OrderStatusStoreType;
  access: string[];
}

interface OrderStatusPageState {
  statusId: string;
}
