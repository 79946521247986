import React from "react";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Block } from "modules/common/components/page/Block";
import { Route, withRouter, RouteComponentProps, matchPath } from "react-router";
import { routes } from "modules/common/routes";
import { UnregisterCallback } from "history";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { OrderTypesStoreType } from "../models/order-types-store";
import { OrderTypeDetails } from "./details/OrderTypeDetails";
import { OrderTypesList } from "./list/OrderTypesList";
import { menuItems } from "modules/root/components/menu/MainMenu";
import func from "modules/dictionaries/functionalities";
import { can } from "modules/session/auth/access";

import styles from "./OrderTypesPage.module.scss";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { Search } from "modules/common/components/form/Search";

const menu = menuItems();

class OrderTypesPage extends React.Component<OrderTypesPageProps & RouteComponentProps, OrderTypesPageState> {
  private unregister: UnregisterCallback | null = null;

  constructor(props: OrderTypesPageProps & RouteComponentProps) {
    super(props);

    this.state = { typeId: getSelectedOrderTypeId(props.location.pathname) };
  }

  componentDidMount() {
    document.title = menu.dictionaries.nested.orderTypes.label;

    const { history } = this.props;
    const { typeId } = this.state;

    this.unregister = history.listen((location) => {
      const typeId = getSelectedOrderTypeId(location.pathname);

      if (this.state.typeId !== typeId) {
        this.setState({ typeId });
        this.loadDetails(typeId);
      }
    });

    this.loadList();
    this.loadDetails(typeId);
  }

  componentWillUnmount() {
    this.unregister && this.unregister();
  }

  render() {
    const { store, access } = this.props;
    const canChange = can(func.ORDERTYPE_EDIT, access);

    return (
      <Block columned={true} className={styles.page}>
        <Route path={routes.dictionaries.orderType.details()}>
          <DetailsPopup title="Тип договора" onCancel={this.onOrderTypeSelected}>
            <OrderTypeDetails
              canChange={canChange}
              orderType={store.details}
              onSaved={this.onOrderTypeSaved}
              onRemoved={this.onOrderTypeRemoved}
            />
          </DetailsPopup>
        </Route>
        <div className={styles.tools}>
          {" "}
          {
            canChange && (
              <PlanrButton
                type="secondary"
                size="small"
                icon="general-plus-big"
                onClick={this.onOrderTypeAdd}
                style={{
                  width: "250px",
                  marginBottom: "32px",
                  borderRadius: "120px",
                }}
              >{`${texts.add} тип договора`}</PlanrButton>
            )

            // <DictioantyAddButton onClick={this.onOrderTypeAdd} label={`${texts.add} тип договора`} />
          }
        </div>
        <div className={styles.mobileTools}>
          {canChange && <PlanrButton type="secondary" round icon="general-plus-big" onClick={this.onOrderTypeAdd} />}
        </div>
        <div className={styles.right}>
          <Search query={store.list.query} onSearch={store.list.setQuery} />
        </div>
        <OrderTypesList store={store.list} onSelected={this.onOrderTypeSelected} onRemoved={this.onOrderTypeRemoved} />
      </Block>
    );
  }

  onOrderTypeAdd = () => {
    this.onOrderTypeSelected(EMPTY_OBJECT_ID);
  };

  onOrderTypeSelected = (id: string = "") => {
    const { history } = this.props;

    const url = id ? routes.dictionaries.orderType.details(id) : routes.dictionaries.orderType.path;

    history.push(url);
  };

  onOrderTypeSaved = () => {
    this.onOrderTypeSelected("");
    this.loadList();
  };

  onOrderTypeRemoved = () => {
    const { history } = this.props;

    this.loadList();

    const url = routes.dictionaries.orderType.path;
    history.push(url);
  };

  loadList = () => this.props.store.list.load();

  loadDetails = (id: string) => this.props.store.details.load(id);
}

export default withRouter(observer(OrderTypesPage));

function getSelectedOrderTypeId(pathname: string) {
  const match = matchPath<OrderTypesPageState>(pathname, routes.dictionaries.orderType.details());

  return match && match.params ? match.params.typeId : "";
}

interface OrderTypesPageProps {
  store: OrderTypesStoreType;
  access: string[];
}

interface OrderTypesPageState {
  typeId: string;
}
