import { Page } from "modules/common/components/page/Page";
import React, { useEffect, useState } from "react";
import { ExternalUserType, SessionType } from "../auth/models/session";
import styles from "./FormAgentPage.module.scss";
import { routes } from "modules/common/routes";
import { Classes, Dialog } from "@blueprintjs/core";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { eat } from "modules/common/services/typescript";
import { AppLogo } from "modules/root/components/logo/AppLogo";
import { FormAgentDetails } from "./FormAgentDetails";

export const FormAgentPage = ({ baseUrl, session }: FormAgentPageProps) => {
  const [openMain, setOpenMain] = useState(false);
  const [openHello, setOpenHello] = useState(true);
  const [openInfo, setOpenInfo] = useState(false);

  useEffect(() => {
    async function fetchFromDatabase() {
      try {
        session.newAgent();
        const agent = session.agent;
        if (agent) {
          await agent.loadAgentAddInfo();
        }
      } catch (error) {
        // handle any rejected Promises and thrown errors
      }
    }

    fetchFromDatabase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { agent } = session;
  return (
    <Page className={styles.page}>
      <div className={styles.wrapper}>
        {agent && (
          <Dialog
            title={""}
            isOpen={openMain}
            isCloseButtonShown={false}
            style={{ width: "882px" }}
            backdropClassName="standard"
          >
            <FormAgentDetails
              access={[]}
              canChange={true}
              canRemove={false}
              canConfirm={false}
              canSupplierOrder={false}
              outsourcer={agent.self}
              categories={agent.categories}
              onSaved={() => {
                setOpenMain(false);
                setOpenInfo(true);
              }}
              onRemoved={eat}
              regions={agent.allCountryRegions}
              onPrintCredentials={eat}
              showSensitiveData={false}
              baseUrl={baseUrl}
              session={session}
            />
          </Dialog>
        )}
        <Dialog isOpen={openInfo} className={styles.dialogInfo} backdropClassName="standard">
          <AppLogo />
          Заявка успешно отправлена!
        </Dialog>

        <Dialog isOpen={openHello} className={styles.dialogInfo} backdropClassName="standard">
          <AppLogo />
          <div className={`${Classes.DIALOG_BODY} ${styles.dialogBody}`}>{/* <p></p> */}</div>
          <div className={styles.bottomBtns}>
            <PlanrButton
              className={styles.okBtn}
              type="secondary"
              icon="general-edit"
              onClick={() => {
                setOpenMain(true);
                setOpenHello(false);
              }}
            >
              Заполнить заявку
            </PlanrButton>
          </div>
          <div className={styles.bottomBtns}>
            <PlanrButton
              style={{ width: "212px" }}
              type="lightblueish"
              icon="general-sign-in"
              onClick={() => {
                const path = routes.login;
                window.open(path, "_self");
              }}
            >
              Войти в систему
            </PlanrButton>
          </div>
        </Dialog>
      </div>
    </Page>
  );
};

interface FormAgentPageProps {
  baseUrl: string;
  session: SessionType;
  agent: null | ExternalUserType;
}
