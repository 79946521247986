import React, { useCallback } from "react";

import styles from "./Zoomer.module.scss";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { prettyRound } from "modules/common/services/formatting/numbers";

export const Zoomer: React.FC<ZoomerProps> = ({ onChange, zoom, max, min, step }) => {
    const lower = typeof min === "undefined" ? 0 : min;
    const upper = typeof max === "undefined" ? 100 : max;

    const onMinus = useCallback(() => {
        const decrement = step || 1;
        const value = prettyRound(zoom - decrement);

        if (value >= lower) {
            onChange(value);
        }
    }, [onChange, step, lower, zoom]);

    const onPlus = useCallback(() => {
        const increment = step || 1;
        const value = prettyRound(zoom + increment);

        if (value <= upper) {
            onChange(value);
        }
    }, [onChange, step, upper, zoom]);

    return (
        <div className={`${styles.zoomer} zoomer`}>
            <PlanrButton type="graybtn" icon="general-plus-big" onClick={onPlus} round={true} />
            <PlanrButton type="graybtn" icon="general-minus" onClick={onMinus} round={true} />
            {/* <ZoomOutIcon onClick={onMinus} className={`figma-icon ${zoom <= lower ? "disabled" : "action-icon"}`} /> */}
            {/* <ZoomInIcon onClick={onPlus} className={`figma-icon ${zoom >= upper ? "disabled" : "action-icon"}`} /> */}
        </div>
    );
};

interface ZoomerProps {
    zoom: number;
    onChange: (zoom: number) => void;
    max?: number;
    min?: number;
    step?: number;
}
