import { Button, Classes, Icon, IconName, MaybeElement } from "@blueprintjs/core";
import { Hotkey, Hotkeys, HotkeysTarget } from "@blueprintjs/core/lib/esnext";
import React from "react";
import ReactDOM from "react-dom";
import portal from "./portal";
import styles from "./DraggableDialog.module.scss";
import Draggable from "react-draggable";

let DialogCounter = 0;

@HotkeysTarget
class DraggableDialog extends React.PureComponent<DraggableDialogProps> {
    private counter: number = 0;

    componentDidMount() {
        DialogCounter++;
        this.counter += DialogCounter;
    }

    componentWillUnmount() {
        DialogCounter = Math.max(0, DialogCounter - 1);
    }

    render() {
        const { isOpen, onClose, width, height, title, icon, initialX, initialY, children } = this.props;

        if (!isOpen) {
            return null;
        }

        const isIconName = typeof icon === "string";
        const isIconElement = !!icon && !isIconName;

        const child1 = (
            <Draggable
                handle={`.${Classes.DIALOG_HEADER}`}
                defaultClassName={`${styles.root} draggable-dialog-${this.counter}`}
                defaultPosition={{ x: initialX ?? window.innerWidth / 2 - width / 2, y: initialY ?? 100 }}
                axis="both"
                offsetParent={document.body}
            >
                <div className={Classes.DIALOG} style={{ width: `${width}px`, height: `${height}px` }}>
                    <div className={`${Classes.DIALOG_HEADER} ${styles.header}`}>
                        {isIconName && <Icon icon={icon} />}
                        {isIconElement && icon}

                        {title && <div className={Classes.HEADING}>{title}</div>}
                        {onClose && (
                            <Button
                                minimal={true}
                                className={Classes.DIALOG_CLOSE_BUTTON}
                                icon="small-cross"
                                onClick={onClose}
                            />
                        )}
                    </div>
                    {children}
                </div>
            </Draggable>
        );

        return <>{ReactDOM.createPortal(child1, portal)}</>;
        // use Fragment because of HotkeysTarget restriction:
        // Your decorated component must return a single DOM element in its render() method, not a custom React component.
    }

    onClose = () => this.props.onClose && this.props.onClose();

    renderHotkeys() {
        return (
            <Hotkeys>
                <Hotkey global={true} allowInInput={true} label="cancel edit" combo="esc" onKeyUp={this.onClose} />
            </Hotkeys>
        );
    }
}

export { DraggableDialog };

export type DraggableDialogProps = {
    width: number;
    height: number;
    isOpen: boolean;
    initialX?: number;
    initialY?: number;
    title?: React.ReactNode;
    icon?: IconName | MaybeElement;
    onClose?: (event?: React.SyntheticEvent<HTMLElement>) => void;
};
