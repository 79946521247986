import { isEqual, cloneDeep } from "lodash";
import { string } from "yup";
import { extractDigits } from "../strings";

export const flattenObject = (object: any, delimeter = "-"): TStringMap<any> => {
    if (!object || Array.isArray(object)) {
        return {};
    }

    const result: TStringMap<any> = {};
    Object.keys(object).forEach((key) => {
        const value = object[key];

        if (typeof value === "object") {
            const nested = flattenObject(value, delimeter);
            Object.keys(nested).forEach((child) => {
                result[`${key}${delimeter}${child}`] = nested[child];
            });
        } else {
            result[key] = value;
        }
    });

    return result;
};

export const buildPatch = (initial: any, values: any, exclude: string[] = []) => {
    const result: TStringMap<any> = {};

    Object.keys(values).forEach((field) => {
        const previous = initial[field];
        const current = values[field];

        const same = exclude.includes(field) ? true : isEqual(previous, current);
        if (!same) {
            result[field] = cloneDeep(current);
        }
    });

    return result;
};

export const buildPatchEmployer = (initial: any, values: any, exclude: string[] = []) => {
    const result: TStringMap<any> = {};

    Object.keys(values).forEach((field) => {
        const previous = initial[field];
        const current = values[field];

        const same = exclude.includes(field) ? false : isEqual(previous, current);
        if (!same) {
            result[field] = cloneDeep(current);
        }
    });

    return result;
};

export const onlyDigitsInputKeyPress =
    (float = false, negative = false, extension: string[] = []) =>
    (e: React.KeyboardEvent<HTMLInputElement>) => {
        const allowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ...extension];
        if (float) {
            allowed.push(".");
        }
        if (negative) {
            allowed.push("-");
        }

        if (!allowed.includes(e.key)) {
            preventDefault(e);
        }
    };

export const preventSubmitKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
        preventDefault(e);
    }
};

export const stopPropagation = (e: React.BaseSyntheticEvent) => {
    e?.stopPropagation();
};

export const preventDefault = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
};

export const phoneValidator = (label: string, target = string().notRequired()) =>
    target
        .label(label)
        .transform(function (val, _) {
            return this.isType(val) && val ? extractDigits(val) : val;
        })
        .min(7, "Минимум 7 цифр")
        .max(15, "Максимум 15 цифр");
