/* eslint-disable */

import { setLocale } from "yup";
import { texts } from "modules/common/texts";

export const RequiredMessage = (what: string) => "Необходимо указать " + what;

setLocale({
    mixed: {
        default: "${path} имеет неверное значение",
        required: RequiredMessage("${path}"),
    },
    string: {
        min: "Требуется не менее ${min} символов",
        max: "Не должно быть более ${max} символов",
        email: "Некорректный email",
    },
    number: {
        min: "${path} не должен быть меньше ${min}",
        max: "${path} не должен быть больше ${max}",
    },
    array: {
        min: "Требуется не менее ${min} элементов",
    },
});

export const agGridLocale = {
    loadingOoo: texts.loading,
    noRowsToShow: texts.noData,
};
