import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { UserWorkload, UserWorkloadSnapshotType } from "./user-workload";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { groupBy } from "lodash";
import {
  OrderDictionary,
  initialState as emptyOrders,
  OrderDictionaryItemSnapshotType,
} from "modules/orders-manage/models/order-dictionary";
import {
  WorkloadDayType,
  WorkloadDayStore,
  WorkloadDayStoreSnapshotType,
  WorkloadDaySnapshotType,
} from "./workload-day";
import { texts } from "modules/common/texts";
import { WorkloadCellMode } from "../components/WorkloadCell";
import { buildCacheStorage } from "modules/common/services/cache";
import { nameof } from "modules/common/services/typescript";
import { Constants } from "modules/root/models/constants";
import { WorkloadUnitTypeSnapshotType } from "./workload-unit";
import { SectionCollapser } from "modules/common/models/section-collapser";
import { Queryable } from "modules/common/models/queryable";
import {
  TasksDictionary,
  WorkloadTasksSnapshotType,
  initialState as emptyTasks,
} from "modules/spending/workload/models/workload-day";

const NAME = "WorkloadStore";
const cache = buildCacheStorage(NAME);
const zoomKey = () => nameof((s: WorkloadStoreSnapshotType) => s.zoom) as string;

export const WorkloadStore = types
  .compose(
    Transport,
    Notificator,
    Queryable,
    types.model({
      orders: OrderDictionary,
      tasks: TasksDictionary,
      users: types.array(UserWorkload),
      month: types.number,
      year: types.number,
      onlyMine: types.boolean,
      workDaysCount: types.number,
      monthsWorkHours: types.number,
      zoom: types.number,
      loader: types.number,
      collapser: SectionCollapser,
      cellMode: types.union(types.literal<WorkloadCellMode>("hours"), types.literal<WorkloadCellMode>("projects")),
    })
  )
  .views((self) => ({
    get workHoursCount() {
      return self.workDaysCount * Constants.workDayHours;
    },

    get filteredRows() {
      return self.users.filter((r) => {
        const user = r.user
          ? `${r.user.label.toLowerCase()} ${r.user.department} ${r.user.position}`.toLowerCase()
          : "";
        return user.includes(self.pureQuery);
      });
    },
  }))
  .views((self) => ({
    get exportModel() {
      return {
        year: self.year,
        month: self.month,
        colorByProjects: self.cellMode === "projects",
        employerIds: self.filteredRows.map((e) => e.user.id),
      };
    },
  }))
  .views((self) => ({
    get departmentMap() {
      return groupBy(self.filteredRows, (user) => user.user.department);
    },

    get transform() {
      return +(self.zoom / 100).toFixed(2);
    },

    get isEmpty() {
      return self.users.length === 0;
    },

    get isLoading() {
      return self.loader > 0;
    },
  }))
  .actions((self) => {
    const updateMe = (data: WorkloadSummary) => {
      applySnapshot(self.users, treat(data.users));
      self.workDaysCount = data.monthWorkDayCount;
      self.monthsWorkHours = data.monthsWorkHours;
      self.month = data.month;
      self.year = data.year;
    };

    return {
      setPeriod: (year: number, month: number) => {
        self.year = year;
        self.month = month;
      },

      load: flow(function* (year: number | null = null, month: number | null = null) {
        self.orders.isEmpty && self.orders.load();
        self.tasks.isEmpty && self.tasks.load();
        self.loader++;

        try {
          const data = yield self.transport.get<WorkloadSummary>(apiUrls.workload.list, {
            params: {
              year: year || self.year,
              month: month || self.month,
              onlyMine: self.onlyMine,
            },
          });

          updateMe(data);

          groupBy(data.users, (user) => self.collapser.set(user.user.department, true));

          return true;
        } catch (er) {
          self.notify.error(er);
          return false;
        } finally {
          self.loader--;
        }
      }),

      save: flow(function* (model: any) {
        try {
          const data: UserWorkloadSnapshotType[] = treat(
            yield self.transport.post<any>(apiUrls.workload.update, model)
          );

          const map = groupBy(data, (d) => d.user.id);
          const replacement = self.users.map((u) => {
            if (map[u.user?.id]) {
              return map[u.user?.id][0];
            }

            return getSnapshot(u);
          });

          applySnapshot(self.users, replacement);

          self.notify.success(texts.messages.saved);

          return true;
        } catch (er) {
          self.notify.error(er);
          return false;
        }
      }),
      saveArrayOfCells: flow(function* (model: []) {
        try {
          const days = { days: model };
          const data: any = yield self.transport.post<any>(apiUrls.workload.batch, days);

          data.forEach((item: any) => {
            const user = item.users;
            const map = groupBy(treat(user), (d) => d.user.id);
            const replacement = self.users.map((u) => {
              if (map[u.user?.id]) {
                return map[u.user?.id][0];
              }

              return getSnapshot(u);
            });

            applySnapshot(self.users, replacement);
          });
          self.notify.success(texts.messages.saved);

          return true;
        } catch (er) {
          self.notify.error(er);
          return false;
        }
      }),
      setComment: flow(function* (model: any) {
        try {
          yield self.transport.post<any>(apiUrls.workload.comment, model);

          const replacement = self.users.map((u) => {
            if (u.user?.id === model.employerId) {
              u.days.forEach((day: any) => {
                if (day.comment) {
                  day.isCommented = true;
                } else {
                  day.isCommented = false;
                }
              });
            }

            return getSnapshot(u);
          });

          applySnapshot(self.users, replacement);

          self.notify.success(texts.messages.saved);

          return true;
        } catch (er) {
          self.notify.error(er);
          return false;
        }
      }),

      setCellMode: (mode: WorkloadCellMode) => {
        self.cellMode = mode;
      },

      setZoom: (value: number) => {
        cache.set(zoomKey(), value);
        self.zoom = value;
      },
    };
  })
  .named(NAME);

function treat(data: UserWorkloadSnapshotType[]) {
  const newData = data.map((item) => {
    item.days.forEach((day) => {
      day.isCommented = !!day.comment;
    });
    return item;
  });
  return newData;
}

export type DepartmentBlockMode = "month" | "day";

export type WorkloadStoreType = typeof WorkloadStore.Type;
export type WorkloadStoreSnapshotType = typeof WorkloadStore.SnapshotType;

export const initialState = (onlyMine = false): WorkloadStoreSnapshotType => {
  const now = new Date();
  const zoom = cache.get(zoomKey(), 100);

  return {
    orders: emptyOrders(),
    tasks: emptyTasks(),
    users: [],
    month: now.getMonth() + 1,
    year: now.getFullYear(),
    onlyMine,
    loader: 0,
    workDaysCount: 0,
    monthsWorkHours: 0,
    cellMode: "hours",
    collapser: {
      opened: {},
    },
    zoom,
    pureQuery: "",
    query: "",
  };
};

export function makeWorkloadInputStore(
  day: WorkloadDayType,
  orders: OrderDictionaryItemSnapshotType[],
  tasks: WorkloadTasksSnapshotType[]
) {
  return WorkloadDayStore.create(makeWorkloadInputSnapshot(getSnapshot(day), orders, tasks, false), {
    http: day.transport,
    notificator: day.notify,
  });
}

export function makeWorkloadInputSnapshot(
  day: WorkloadDaySnapshotType,
  orders: OrderDictionaryItemSnapshotType[],
  tasks: WorkloadTasksSnapshotType[],
  forActualTimesheet: boolean
): WorkloadDayStoreSnapshotType {
  const { isDayOff, minimalHours, units, user } = day;
  const nonProduction = user.nonProduction;

  const worked = units.map((unit) => ({
    project: unit.projectInventoryNumber,
    hours: unit.hours,
    minutes: unit.minutes,
    nonProduction,
    type: reduceType(unit.typeDetails),
    isHome: unit.isHome,
    fromHome: unit.type === Constants.workedOutFromHomeHoursType.name || unit.isHome === true,
    forExpertise: unit.type === Constants.workedOutForExpertiseHoursType.name,
    forTrip: unit.type === Constants.workedOutForTripHoursType.name,
    comment: unit.comment,
    guid: unit.task ? unit.task.guid : "",
    workId: unit.task ? unit.task.workId : "",
    workName: unit.task ? unit.task.workName : "",
    workDescription: unit.task ? unit.task.workDescription : "",
  }));

  return {
    isDayOff,
    minimalHours,
    day: day,
    user,
    nonProduction,
    units: worked,
    orders,
    dirty: false,
    tasks,
    forActualTimesheet,
  };
}

function reduceType(initial: WorkloadUnitTypeSnapshotType) {
  // reduce all workable types in one default
  if (
    initial.name === Constants.workedOutFromHomeHoursType.name ||
    initial.name === Constants.workedOutForExpertiseHoursType.name ||
    initial.name === Constants.workedOutForTripHoursType.name ||
    initial.name === Constants.workedVacationHoursType.name ||
    initial.name === Constants.workedIllnesHoursType.name
  ) {
    return getSnapshot(Constants.workedOutHoursType);
  }

  return initial;
}

interface WorkloadSummary {
  users: UserWorkloadSnapshotType[];
  monthWorkDayCount: number;
  monthsWorkHours: number;
  month: number;
  year: number;
}
