import * as yup from 'yup';
import { fields } from 'modules/dictionaries/production-stages/models/production-stage';
import { texts } from 'modules/common/texts';
const { object, string } = yup;

export const productionStageLabels = () => ({
    [fields.name]: texts.name,
    [fields.comment]: texts.comment,
});

const ProductionStageSchema = () => {
    const labels = productionStageLabels();

    return object().shape({

        [fields.name]: string()
            .label(labels[fields.name])
            .required(),

        [fields.comment]: string()
            .label(labels[fields.comment])
            .notRequired(),
    });
}

export default ProductionStageSchema;
