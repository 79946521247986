import { GridOptions, RowClickedEvent } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { observer } from "mobx-react";
import { Search } from "modules/common/components/form/Search";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { MAX_INT } from "modules/common/constants";
import { routes } from "modules/common/routes";
import { DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import { OrderAgentsListStoreType } from "modules/main/models/order-agents-list";
import { OrderContentTaskStore } from "modules/main/models/order-content-task-list";
import { OrderTabs } from "modules/orders-manage/details/OrderDetailsTabsPage";
import { agGridLocale } from "modules/root/services/locale";
import React from "react";
import styles from "./OrderAgentsList.module.scss";
import { columns } from "./columns";

const modules = [...defaultModules];
const TABLE_NAME = OrderContentTaskStore.name;

class OrderAgentsList extends React.Component<OrderAgentsListProps> {
    async componentDidMount() {
        await this.props.store.load();
    }

    private options: GridOptions = DefaultAgGridOptions();
    private config = columns(TABLE_NAME);

    render() {
        const { mainData, loading } = this.props.store;
        return (
            <div className={`${styles.wrapper} `}>
                {loading ? (
                    <div>
                        <LocalLoader active={loading} />
                    </div>
                ) : (
                    <div>
                        <div className={styles.search}>
                            <Search
                                query={this.props.store.query}
                                onSearch={this.props.store.setQuery}
                                style={{ background: "#fff" }}
                            />
                        </div>
                        {mainData.length > 0 ? (
                            <div className={styles.tableContainer}>
                                <GridTheme bordered={true} className={`designed ${styles.table}`}>
                                    <AgGridReact
                                        rowStyle={{
                                            fontFamily: "GothamPro, Arial, sans-serif",
                                            color: "#00273D",
                                        }}
                                        rowHeight={42}
                                        rowSelection="single"
                                        suppressCellSelection={true}
                                        enableCellTextSelection={true}
                                        columnDefs={this.config}
                                        rowData={mainData}
                                        localeText={agGridLocale}
                                        onRowDoubleClicked={this.onRowSelected}
                                        // getRowNodeId={getRowNodeId}
                                        // getContextMenuItems={getContextMenuItems}
                                        // onCellContextMenu={this.onCellContextMenu}
                                        // onSortChanged={store.sorter.resorted}
                                        //onColumnResized={sizeSaver}
                                        gridOptions={this.options}
                                        modules={modules}
                                        rowBuffer={MAX_INT}
                                    />
                                </GridTheme>
                            </div>
                        ) : (
                            <div className={styles.noData}>Нет агентов</div>
                        )}
                    </div>
                )}
            </div>
        );
    }

    onRowSelected = ({ data }: RowClickedEvent) => {
        let path = routes.orders.details(data.orderId, OrderTabs.orderAgents);
        window.open(path, "_blank");
    };
}

export const OrderAgentsListPage = observer(OrderAgentsList);

interface OrderAgentsListProps {
    store: OrderAgentsListStoreType;
}
