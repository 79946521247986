import { types, applySnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { IpdTypeDictionaryItem, IpdTypeDictionaryItemType } from "./ipd-type";
import { Queryable } from "modules/common/models/queryable";

export const IpdTypeDictionary = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            types: types.array(IpdTypeDictionaryItem),
        })
    )
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: IpdTypeDictionaryItemType[] = yield self.transport.get<any>(apiUrls.ipdTypes.dictionary());
                applySnapshot(self.types, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .views((self) => ({
        get isEmpty() {
            return !self.types.length;
        },

        get filtered() {
            return self.types.filter((idp) => {
                const text = `${idp.label}${idp.description}`.toLowerCase();
                return text.includes(self.pureQuery);
            });
        },
    }))
    .named("IpdTypeDictionary");

export const initialState = (): IpdTypeDictionarySnapshotType => ({
    types: [],
    pureQuery: "",
    query: "",
});

export type IpdTypeDictionaryType = typeof IpdTypeDictionary.Type;
export type IpdTypeDictionarySnapshotType = typeof IpdTypeDictionary.SnapshotType;
