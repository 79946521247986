import { types, applySnapshot, isAlive } from "mobx-state-tree";
import { BaseEntity, isNewlyCreated, DictionaryItem } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID, DATE_TIME_FORMAT, WHITE } from "modules/common/constants";
import Schema from "../components/details/validation";
import moment from "moment";
import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";
import { getFieldLabel } from "modules/common/services/form/fields";

export const OrderStatusDictionaryItem = types
    .compose(
        DictionaryItem,
        types.model({
            color: types.string,
            canBeApplied: types.boolean,
            type: types.string,
        })
    )
    .named("OrderStatusDictionaryItem");

const OrderStatusBase = types.compose(
    Transport,
    Notificator,
    BaseEntity,
    types.model({
        type: types.string,
        name: types.string,
        description: types.string,
        color: types.string,
    })
);

export const OrderStatus = OrderStatusBase.actions((self) => ({
    load: flow(function* (id: string) {
        try {
            const snapshot = isNewlyCreated(id)
                ? initialState()
                : yield self.transport.get<OrderStatusSnapshotType>(apiUrls.orderStatuses.details(id));

            applySnapshot(self, snapshot);
        } catch (er) {
            self.notify.error(er);
        }
    }),

    save: flow(function* (model: OrderStatusSnapshotType) {
        try {
            const snapshot = self.isNewlyCreated
                ? yield self.transport.put<OrderStatusSnapshotType>(apiUrls.orderStatuses.create(), model)
                : yield self.transport.post<OrderStatusSnapshotType>(apiUrls.orderStatuses.update(self.id), model);

            isAlive(self) && applySnapshot(self, snapshot);
            self.notify.success(texts.messages.saved);

            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    delete: flow(function* () {
        if (self.isNewlyCreated) {
            return true;
        }

        try {
            yield self.transport.delete<boolean>(apiUrls.orderStatuses.delete(self.id));
            self.notify.success(texts.messages.removed);

            isAlive(self) && applySnapshot(self, initialState());
            return true;
        } catch (er) {
            self.notify.error(er);
            return false;
        }
    }),
})).named("OrderStatus");

export type OrderStatusDictionaryItemType = typeof OrderStatusDictionaryItem.Type;
export type OrderStatusSnapshotType = typeof OrderStatusBase.SnapshotType;
export type OrderStatusType = typeof OrderStatus.Type;

export const initialState = (): OrderStatusSnapshotType => ({
    type: "",
    id: EMPTY_OBJECT_ID,
    created: moment().format(DATE_TIME_FORMAT),
    name: "",
    description: "",
    color: WHITE,
});

export const ACCEPT = "image/svg+xml";

export const fields = {
    name: nameof((a: OrderStatusType) => a.name) as string,
    description: nameof((a: OrderStatusType) => a.description) as string,
    color: nameof((a: OrderStatusType) => a.color) as string,
    type: nameof((a: OrderStatusType) => a.type) as string,
};

export function formatOrderStatus(orderStatus: OrderStatusSnapshotType | null) {
    let result = "";

    if (orderStatus) {
        const schema = Schema();

        result += `${getFieldLabel(fields.name, schema, null)}: ${orderStatus.name}\n`;
        if (orderStatus.type) {
            result += `${getFieldLabel(fields.type, schema, null)}: ${orderStatus.type}\n`;
        }
        result += `${getFieldLabel(fields.description, schema, null)}: ${orderStatus.description}\n`;
        result += `${getFieldLabel(fields.color, schema, null)}: ${orderStatus.color}\n`;
    }

    return result;
}
