import React from "react";
import weeks from "./WeekDays.module.scss";
import user from "./MonthUserRow.module.scss";
import styles from "./MonthHeader.module.scss";
import { Constants } from "modules/root/models/constants";
import { texts } from "modules/common/texts";
import TimesheetMonthSchema from "./validation";
import { getFieldLabel } from "modules/common/services/form/fields";
import { fields } from "../models/user-timesheet";

const schema = TimesheetMonthSchema();

export const MonthHeader: React.FC = () => (
    <div className={styles.header}>
        <div className={`timesheet-user-name ${weeks.part} ${styles.cell}`}></div>
        <div className={`${weeks.baseCell} ${user.workedOut} ${weeks.part} ${styles.cell}`}>Норма</div>
        <div className={`${weeks.baseCell} ${user.workedOut} ${weeks.part} ${styles.cell}`}>Отработано</div>
        <div className={`${weeks.baseCell} ${user.overtime} ${weeks.part} ${styles.cell}`}>В том числе сверхурочно</div>
        <div className={`${weeks.baseCell} ${user.overtimeScale} ${weeks.part} ${styles.cell}`}>
            {getFieldLabel(fields.overtimeScale, schema, null)}
        </div>
        <div className={`${weeks.baseCell} ${user.dayOff} ${weeks.part} ${styles.cell}`}>
            В том числе вых.
            <br /> и праздн.
        </div>
        <div className={`${weeks.baseCell} ${user.fromHome} ${weeks.part} ${styles.cell}`}>
            В том числе <br /> из дома
        </div>
        <div className={`${weeks.baseCell} ${user.workedOut} ${weeks.part} ${styles.cell}`}>Переработка</div>
        <div className={`${weeks.baseCell} ${user.missing} ${weeks.part} ${styles.cell}`}>
            <div>{Constants.withoutPayHoursType.name}</div>
        </div>
        <div className={`${weeks.baseCell} ${user.missing} ${weeks.part} ${styles.cell}`}>
            <div>{Constants.vacationHoursType.name}</div>
        </div>
        <div className={`${weeks.baseCell} ${user.missing} ${weeks.part} ${styles.cell}`}>
            <div>{Constants.illnesHoursType.name}</div>
        </div>
        <div className={`${weeks.baseCell} ${user.missing} ${weeks.part} ${styles.cell}`}>
            <div>{texts.other}</div>
        </div>
    </div>
);
