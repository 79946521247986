import { applySnapshot, getSnapshot, types } from "mobx-state-tree";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Queryable } from "modules/common/models/queryable";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { nameof } from "modules/common/services/typescript";

const OrderChartItem = types
    .model({
        orderId: types.string,
        orderName: types.string,
        inventoryNumber: types.string,
        orderStatus: types.string,
        orderStatusColor: types.string,
        name: types.string,
        categories: types.string,
        materials: types.string,
        price: types.string,
        memberName: types.string,
        phone: types.string,
        rewardPercent: types.string,
        rewardSum: types.string,
        agentId: types.string,
        description: types.string,
        deal: types.string,
    })
    .named("OrderChartItem");

export type OrderChartItemType = typeof OrderChartItem.Type;

export const OrderAgentsListStore = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            data: types.array(OrderChartItem),
            loading: types.boolean,
            // sorter: TableSorter,
        })
    )
    .views((self) => ({
        get mainData() {
            const predicate = (o: OrderChartItemType) => {
                let result = true;
                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.inventoryNumber} ${o.orderName} ${o.orderStatus} ${o.name}  ${o.memberName} ${o.price} 
                        ${o.rewardSum} ${o.rewardPercent} ${o.deal} ${o.description} ${o.categories} ${o.materials}`
                            .toLowerCase()
                            .indexOf(self.pureQuery) >= 0;
                }

                return result;
            };
            return self.data.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                self.loading = true;
                const newData = yield self.transport.get<any>(apiUrls.dashboard.orderAgents());

                applySnapshot(self.data, newData);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.loading = false;
            }
        }),
    }))
    .named("OrderAgentsListStore");

export type OrderAgentsListStoreType = typeof OrderAgentsListStore.Type;
export type OrderAgentsListStoreSnapshotType = typeof OrderAgentsListStore.SnapshotType;

// const sortStorage = getSortOption(OrderContentTaskStore.name);

export const initialState = (): OrderAgentsListStoreSnapshotType => ({
    data: [],
    loading: false,
    pureQuery: "",
    query: "",
});

export const fields = {
    inventoryNumber: nameof((a: OrderChartItemType) => a.inventoryNumber) as string,
    orderName: nameof((a: OrderChartItemType) => a.orderName) as string,
    orderStatus: nameof((a: OrderChartItemType) => a.orderStatus) as string,
    memberName: nameof((a: OrderChartItemType) => a.memberName) as string,
    description: nameof((a: OrderChartItemType) => a.description) as string,
    rewardSum: nameof((a: OrderChartItemType) => a.rewardSum) as string,
    price: nameof((a: OrderChartItemType) => a.price) as string,
    deal: nameof((a: OrderChartItemType) => a.deal) as string,
    phone: nameof((a: OrderChartItemType) => a.phone) as string,
    name: nameof((a: OrderChartItemType) => a.name) as string,
    materials: nameof((a: OrderChartItemType) => a.materials) as string,
    categories: nameof((a: OrderChartItemType) => a.categories) as string,
};
