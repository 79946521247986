import { Classes, Dialog, InputGroup, Switch, TextArea } from "@blueprintjs/core";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { BankDetailsFields } from "modules/agents/components/BankDetailsFields";
import {
  PassportBirthDay,
  PassportDetailsFields,
} from "modules/agents/outsourcers/components/details/PassportDetailsFields";
import { SelfEmployedBankDetailsFields } from "modules/agents/outsourcers/components/details/SelfEmployedBankDetailsFields";
import { OutsourcerType } from "modules/agents/outsourcers/models/outsourcer";
import { maxIssueDate } from "modules/agents/outsourcers/models/passport";
import { SupplierDocuments } from "modules/agents/suppliers/components/details/SupplierDocuments";
import { Buttons, CreditCard } from "modules/common/components/form";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import {
  DefaultSelectedOption,
  SimpleSelect,
  filterItemPredicate,
  renderSingleOption as renderOption,
} from "modules/common/services/form/select";
import { eat } from "modules/common/services/typescript";
import { texts } from "modules/common/texts";
import { AgentsCategoriesDictionaryType } from "modules/dictionaries/agents-categories/models/agents-categories-dictionary";
import React from "react";
import { v4 } from "uuid";
import { SelfOutsourcerType, SessionType } from "../auth/models/session";
import styles from "./FormAgentDetails.module.scss";
import OutsourcerSchema from "./validation";

const BankFields = {
  inn: "inn",
  kpp: "kpp",
  ogrn: "ogrn",
  okpo: "okpo",
  bankName: "bankName",
  corrAccount: "corrAccount",
  address: "address",
  directorName: "directorName",
  directorNameGenitive: "directorNameGenitive",
  guid: "guid",
  account: "account",
  bankIdentificator: "bankIdentificator",
};

const PassportFields = {
  issueDate: "issueDate",
  issuer: "issuer",
  issuerCode: "issuerCode",
  number: "number",
  series: "series",
  regAddress: "regAddress",
};

class FormAgentDetailsBase extends React.PureComponent<FormAgentDetailsProps, FormAgentDetailsState> {
  private formProps: FormikProps<any> | null = null;

  constructor(props: FormAgentDetailsProps) {
    super(props);

    this.state = {
      showClientOrders: false,
      selectedOrder: null,
      materialVal: "",
      showMaterials: false,
      showCategories: false,
      materials: [],
      selectedCategories: [],
      isLegal: true,
      selfEmployed: false,
    };
  }
  private commentArea: HTMLTextAreaElement | null = null;

  private commentAreaRef = (ref: HTMLTextAreaElement | null) => {
    this.commentArea = ref;
  };
  public render() {
    const { outsourcer, canChange, canRemove, baseUrl, categories } = this.props;
    const { onSaved, children, session, regions } = this.props;
    const { materials, selectedCategories, isLegal, selfEmployed } = this.state;
    const initial = getFormValues(outsourcer);
    const schema = OutsourcerSchema(isLegal);
    let regionsItems = regions.map((reg) => ({ id: reg, label: reg }));
    var categoriesItems = categories.types.map((item) => ({ id: item.id, label: item.label }));
    return (
      <div className={`${styles.details} outsourcer-details`}>
        <Formik
          initialValues={initial}
          enableReinitialize={true}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            if (!canChange) {
              return;
            }

            const succsessfull = await session.saveAgent(values as any, isLegal, selfEmployed, selectedCategories);
            setSubmitting(false);

            if (succsessfull) {
              resetForm();
              onSaved();
            }
          }}
        >
          {(formProps) => {
            this.formProps = formProps;

            return (
              <Form className={styles.details} autoComplete="off">
                <div className={`${Classes.DIALOG_BODY} ${styles.dialogBody}`} style={{ minHeight: " 750px" }}>
                  <div className={styles.mainInfo}>
                    <div className={styles.isLegalBtns}>
                      <PlanrButton
                        type={isLegal ? "primary" : "graybtn"}
                        size="small"
                        onClick={() => {
                          this.setState({ isLegal: true, selfEmployed: false });
                        }}
                        style={{
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                          marginLeft: "0px",
                        }}
                      >
                        Юр. лицо
                      </PlanrButton>
                      <PlanrButton
                        type={!isLegal ? "primary" : "graybtn"}
                        size="small"
                        onClick={() => {
                          this.setState({ isLegal: false });
                        }}
                        style={{
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          marginLeft: "0px",
                        }}
                      >
                        Физ. лицо
                      </PlanrButton>
                    </div>

                    {!isLegal && (
                      <StandardFormInput
                        name={"selfEmployed"}
                        schema={schema}
                        small={true}
                        className="planr-form-input"
                        inline={false}
                      >
                        {({ field, form }) => (
                          <Switch
                            checked={field.value}
                            {...field}
                            large={true}
                            disabled={!canChange}
                            onChange={(e) => {
                              const checked = e.currentTarget.checked;

                              this.setState({ selfEmployed: e.currentTarget.checked });

                              form.setFieldValue(field.name, checked);
                              form.setFieldTouched(field.name, true);
                            }}
                          />
                        )}
                      </StandardFormInput>
                    )}
                    <h3 className={styles.headerLabel}>Основные данные</h3>
                    <StandardFormInput
                      name={"name"}
                      schema={schema}
                      label={isLegal ? texts.name : "ФИО"}
                      small={true}
                      inline={false}
                      className="planr-form-input"
                    >
                      {({ field }) => (
                        <InputGroup
                          id={field.name}
                          {...field}
                          className="planr-default-input"
                          autoComplete="off"
                          data-lpignore="true"
                          disabled={!canChange}
                        />
                      )}
                    </StandardFormInput>
                    {isLegal && (
                      <>
                        <StandardFormInput
                          name={"fullName"}
                          schema={schema}
                          small={true}
                          className="planr-form-input"
                          inline={false}
                        >
                          {({ field }) => (
                            <InputGroup
                              id={field.name}
                              {...field}
                              className="planr-default-input"
                              autoComplete="off"
                              disabled={!canChange}
                            />
                          )}
                        </StandardFormInput>

                        <StandardFormInput
                          name={"directorName"}
                          schema={schema}
                          small={true}
                          className="planr-form-input"
                          inline={false}
                        >
                          {({ field }) => (
                            <InputGroup
                              id={field.name}
                              {...field}
                              className="planr-default-input"
                              autoComplete="off"
                              data-lpignore="true"
                              disabled={!canChange}
                            />
                          )}
                        </StandardFormInput>
                      </>
                    )}
                    <StandardFormInput
                      name={"genitiveName"}
                      schema={schema}
                      small={true}
                      className="planr-form-input"
                      inline={false}
                      label={!isLegal ? "ФИО в родительном падеже" : "Директор (Родит. падеж)"}
                    >
                      {({ field }) => (
                        <InputGroup
                          id={field.name}
                          {...field}
                          className="planr-default-input"
                          autoComplete="off"
                          data-lpignore="true"
                          disabled={!canChange}
                        />
                      )}
                    </StandardFormInput>
                    {isLegal && (
                      <StandardFormInput
                        name={"address"}
                        schema={schema}
                        small={true}
                        className="planr-form-input"
                        inline={false}
                      >
                        {({ field }) => (
                          <InputGroup
                            id={field.name}
                            {...field}
                            className="planr-default-input"
                            autoComplete="off"
                            data-lpignore="true"
                            disabled={!canChange}
                          />
                        )}
                      </StandardFormInput>
                    )}
                    {!isLegal && (
                      <PassportBirthDay name={"birthday"} schema={schema} inline={false} readOnly={!canChange} />
                    )}
                    <StandardFormInput
                      name={"phone"}
                      schema={schema}
                      small={true}
                      className="planr-form-input"
                      inline={false}
                    >
                      {({ field }) => (
                        <InputGroup
                          id={field.name}
                          {...field}
                          className="planr-default-input"
                          autoComplete="off"
                          disabled={!canChange}
                        />
                      )}
                    </StandardFormInput>
                    <StandardFormInput
                      name={"phone2"}
                      schema={schema}
                      small={true}
                      className="planr-form-input"
                      inline={false}
                    >
                      {({ field }) => (
                        <InputGroup
                          id={field.name}
                          {...field}
                          className="planr-default-input"
                          autoComplete="off"
                          disabled={!canChange}
                        />
                      )}
                    </StandardFormInput>
                    <StandardFormInput
                      name={"phone3"}
                      schema={schema}
                      small={true}
                      className="planr-form-input"
                      inline={false}
                    >
                      {({ field }) => (
                        <InputGroup
                          id={field.name}
                          {...field}
                          className="planr-default-input"
                          autoComplete="off"
                          disabled={!canChange}
                        />
                      )}
                    </StandardFormInput>
                    <StandardFormInput
                      name={"email"}
                      schema={schema}
                      small={true}
                      className="planr-form-input"
                      inline={false}
                    >
                      {({ field }) => (
                        <InputGroup
                          id={field.name}
                          {...field}
                          className="planr-default-input"
                          autoComplete="off"
                          disabled={!canChange}
                        />
                      )}
                    </StandardFormInput>
                    <StandardFormInput
                      name={"comment"}
                      schema={schema}
                      small={true}
                      className="planr-form-input"
                      inline={false}
                    >
                      {({ field }) => (
                        <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                          <TextArea
                            id={field.name}
                            {...field}
                            growVertically={true}
                            className={Classes.FILL}
                            inputRef={this.commentAreaRef}
                            disabled={!canChange}
                            style={{ maxHeight: "220px" }}
                          />
                        </div>
                      )}
                    </StandardFormInput>
                    {!isLegal && !selfEmployed && (
                      <StandardFormInput
                        name={"cardNumber"}
                        schema={schema}
                        small={true}
                        className="planr-form-input"
                        inline={false}
                      >
                        {({ field }) => (
                          <CreditCard
                            id={field.name}
                            {...field}
                            className="planr-default-input"
                            disabled={!canChange}
                          />
                        )}
                      </StandardFormInput>
                    )}
                    {!isLegal && selfEmployed && (
                      <>
                        <StandardFormInput
                          name={"selfEmployedAccount"}
                          schema={schema}
                          small={true}
                          inline={false}
                          className="planr-form-input"
                        >
                          {({ field }) => (
                            <InputGroup
                              id={field.name}
                              {...field}
                              className="planr-default-input"
                              autoComplete="off"
                              data-lpignore="true"
                              disabled={!canChange}
                            />
                          )}
                        </StandardFormInput>

                        <StandardFormInput
                          name={"selfEmployedAccountDate"}
                          schema={schema}
                          small={true}
                          className="planr-form-input"
                          inline={false}
                        >
                          {({ field, form }) => {
                            return (
                              <div className={`${Classes.INPUT_GROUP} `}>
                                <DatePicker
                                  clasName="planr-default-input"
                                  value={field.value}
                                  onChange={(date) => {
                                    form.setFieldValue(field.name, date);
                                    form.setFieldTouched(field.name, true);
                                  }}
                                  disabled={!canChange}
                                />
                              </div>
                            );
                          }}
                        </StandardFormInput>
                      </>
                    )}
                  </div>

                  <div className={styles.mainInfo}>
                    <div>
                      <label className={styles.headerLabel}>&nbsp;</label>
                    </div>

                    <div style={{ position: "relative" }}>
                      <StandardFormInput
                        name={"сategories"}
                        schema={schema}
                        small={true}
                        className="planr-form-input"
                        inline={false}
                      >
                        {({ field, form }) => (
                          <div style={{ display: "flex" }}>
                            <div className={styles.materialsList}>
                              {field.value &&
                                field.value.map((item: string, index: number) => {
                                  const cat = categoriesItems.find((category) => category.id === item);
                                  if (cat) {
                                    return <div key={index}>{cat.label};</div>;
                                  }
                                  return null;
                                })}
                            </div>
                            <PlanrButton
                              type="secondary"
                              size="small"
                              icon="general-plus-big"
                              style={{
                                position: "absolute",
                                top: "-12px",
                                right: "0px",
                                height: "30px",
                                width: "54px",
                              }}
                              onClick={() => {
                                if (field.value !== undefined && field.value !== null) {
                                  let newResposes: {
                                    id: string;
                                    label: string;
                                  }[] = [];
                                  field.value.forEach((c: string) => {
                                    newResposes.push({
                                      id: c,
                                      label: c,
                                    });
                                  });
                                  this.setState({
                                    selectedCategories: field.value.map((val: any) => val),
                                  });
                                }
                                this.toggleShowCategories();
                              }}
                            />

                            <Dialog
                              title={`Категория`}
                              isOpen={this.state.showCategories}
                              onClose={this.toggleShowCategories}
                              backdropClassName="standard"
                              className={`${styles.smallDialog} ${styles.workDialog} figma-dialog`}
                            >
                              <div className={`${Classes.DIALOG_BODY} `}>
                                {categoriesItems && categoriesItems.length > 0 && (
                                  <div className={styles.workItemContainer}>
                                    {categoriesItems.map((category) => {
                                      const item = selectedCategories.find((u) => u === category.id);
                                      return (
                                        <div
                                          className={styles.workItem}
                                          key={category.id}
                                          onClick={() => {
                                            this.setSelectedCategories(category.id);
                                          }}
                                        >
                                          <PlanrButton
                                            icon={item ? "general-checkbox" : "general-zero-checkbox"}
                                            type="neutral"
                                            size="small"
                                            onClick={eat}
                                          />
                                          <div>{category.label}</div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                              <div className={Classes.DIALOG_FOOTER}>
                                <Buttons
                                  left={
                                    <div style={{ display: "flex" }}>
                                      <PlanrButton
                                        size="small"
                                        type="greenish"
                                        onClick={() => {
                                          const vals = [...this.state.selectedCategories];
                                          form.setFieldValue(field.name, vals);
                                          form.setFieldTouched(field.name, true);
                                          this.toggleShowCategories();
                                        }}
                                      >
                                        Сохранить
                                      </PlanrButton>
                                      <PlanrButton size="small" type="graybtn" onClick={this.toggleShowCategories}>
                                        Отмена
                                      </PlanrButton>
                                    </div>
                                  }
                                />
                              </div>
                            </Dialog>
                          </div>
                        )}
                      </StandardFormInput>
                    </div>

                    <StandardFormInput
                      name={"region"}
                      schema={schema}
                      small={true}
                      inline={false}
                      className="planr-form-input"
                    >
                      {({ field, form }) => {
                        const typeOption = regionsItems.find((r) => r.id === field.value);
                        return (
                          <SimpleSelect
                            className={`full-width-select ${Classes.FILL}`}
                            activeItem={typeOption}
                            items={regionsItems}
                            itemRenderer={renderOption}
                            onItemSelect={(i) => {
                              form.setFieldValue(field.name, i.id);
                              form.setFieldTouched(field.name, true);
                            }}
                            filterable={true}
                            itemPredicate={filterItemPredicate}
                          >
                            <DefaultSelectedOption option={typeOption} />
                          </SimpleSelect>
                        );
                      }}
                    </StandardFormInput>

                    <StandardFormInput
                      name={"memberName"}
                      schema={schema}
                      small={true}
                      className="planr-form-input"
                      inline={false}
                    >
                      {({ field }) => (
                        <InputGroup
                          id={field.name}
                          {...field}
                          className="planr-default-input"
                          autoComplete="off"
                          disabled={!canChange}
                        />
                      )}
                    </StandardFormInput>

                    <div className={styles.materialAddContainer}>
                      <StandardFormInput
                        name={"materials"}
                        schema={schema}
                        small={true}
                        className="planr-form-input"
                        inline={false}
                      >
                        {({ field, form }) => (
                          <div style={{ display: "flex" }}>
                            <div className={styles.materialsList}>
                              {field.value &&
                                field.value.map((item: string, index: number) => (
                                  <div key={`${item} ${index}`}>{`${item}`};</div>
                                ))}
                            </div>
                            <PlanrButton
                              type="secondary"
                              size="small"
                              icon="general-plus-big"
                              className={styles.materialAddBtn}
                              onClick={() => {
                                let newResposes: {
                                  value: string;
                                  guid: string;
                                }[] = [];
                                field.value.forEach((c: string) => {
                                  newResposes.push({
                                    guid: v4(),
                                    value: c,
                                  });
                                });
                                this.setState({ materials: newResposes });
                                this.toggleShowMaterials();
                              }}
                            />
                            <Dialog
                              title={`Поставляемое оборудование`}
                              isOpen={this.state.showMaterials}
                              onClose={this.toggleShowMaterials}
                              backdropClassName="standard"
                              className={`${styles.materialsDialog} figma-dialog`}
                            >
                              <div className={`${Classes.DIALOG_BODY} `}>
                                <div className={styles.materials}>
                                  {materials &&
                                    materials.map((res) => {
                                      return (
                                        <div key={res.guid} className={styles.responsesRow}>
                                          <InputGroup
                                            type="text"
                                            autoComplete="off"
                                            className={`planr-default-input ${styles.materialsRow}`}
                                            data-lpignore="true"
                                            value={res.value}
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                              this.setMaterials(e.currentTarget.value, res.guid);
                                            }}
                                          />
                                          <PlanrButton
                                            type="neutral"
                                            icon="general-trash"
                                            onClick={() => {
                                              this.removeMaterials(res.guid);
                                            }}
                                            size="small"
                                            title={texts.remove}
                                          />
                                        </div>
                                      );
                                    })}
                                  <div className={styles.addRows}>
                                    <PlanrButton
                                      size="small"
                                      type="secondary"
                                      onClick={() => {
                                        this.addMaterials("");
                                      }}
                                    >
                                      Добавить
                                    </PlanrButton>
                                  </div>
                                </div>
                              </div>
                              <div className={Classes.DIALOG_FOOTER}>
                                <Buttons
                                  left={
                                    <div style={{ display: "flex" }}>
                                      <PlanrButton
                                        size="small"
                                        type="greenish"
                                        onClick={() => {
                                          let val = this.state.materials.map((mat) => mat.value);
                                          form.setFieldValue(field.name, val);
                                          form.setFieldTouched(field.name, true);
                                          this.toggleShowMaterials();
                                        }}
                                      >
                                        Сохранить
                                      </PlanrButton>
                                      <PlanrButton
                                        size="small"
                                        type="graybtn"
                                        onClick={() => {
                                          this.toggleShowMaterials();
                                        }}
                                      >
                                        Отмена
                                      </PlanrButton>
                                    </div>
                                  }
                                />
                              </div>
                            </Dialog>
                          </div>
                        )}
                      </StandardFormInput>
                    </div>
                    <StandardFormInput
                      name={"site"}
                      schema={schema}
                      small={true}
                      inline={false}
                      className="planr-form-input"
                    >
                      {({ field }) => (
                        <InputGroup id={field.name} {...field} className="planr-default-input" autoComplete="off" />
                      )}
                    </StandardFormInput>

                    <div className={`${styles.files}`}>
                      <Field name={"materialDocuments"}>
                        {(fieldProps: FieldProps) => {
                          return (
                            <SupplierDocuments
                              session={session}
                              canChange={canChange}
                              supplier={outsourcer}
                              fieldProps={fieldProps}
                              baseUrl={baseUrl}
                              label={"Демонстрационные материалы"}
                            />
                          );
                        }}
                      </Field>
                    </div>
                  </div>

                  <div className={styles.mainInfo}>
                    {isLegal && (
                      <>
                        <h3 className={styles.h3Label}>Реквизиты</h3>
                        <BankDetailsFields schema={schema} fields={BankFields} inline={false} readonly={!canChange} />
                      </>
                    )}
                    {!isLegal && (
                      <PassportDetailsFields
                        schema={schema}
                        fields={PassportFields}
                        inline={false}
                        maxIssueDate={maxIssueDate()}
                        readOnly={!canChange}
                      />
                    )}

                    {selfEmployed && !isLegal && (
                      <SelfEmployedBankDetailsFields
                        schema={schema}
                        fields={BankFields}
                        inline={false}
                        readonly={!canChange}
                      />
                    )}
                  </div>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                  {!formProps.isValid && (
                    <div className={styles.errorLabel}>Заполните необходимые поля перед сохранением!</div>
                  )}
                  {canChange && (
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                      {children}
                      <StandardFormButtons
                        {...formProps}
                        isRemoved={false}
                        what={`аутсорсера ${outsourcer.name}`}
                        isNewlyCreated={!canRemove || outsourcer.isNewlyCreated}
                        onRemove={eat}
                      />
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }

  setMaterials = (value: string, guid: string) => {
    const materials = [...this.state.materials];
    const index = materials.findIndex((e) => e.guid === guid);
    if (index > -1) {
      const item = materials[index];
      materials.splice(index, 1, { ...item, value });
      this.setState({ materials });
    }
  };
  toggleShowMaterials = () => {
    this.setState({ showMaterials: !this.state.showMaterials });
  };

  toggleShowCategories = () => {
    this.setState({ showCategories: !this.state.showCategories });
  };

  setSelectedCategories = (id: string) => {
    let newUnits = [...this.state.selectedCategories];
    const index = newUnits.findIndex((unit) => unit === id);
    let newArr: string[] = newUnits;
    if (index > -1) {
      newArr = [...newUnits.slice(0, index), ...newUnits.slice(index + 1)];
    } else {
      newArr.push(id);
    }
    this.setState({ selectedCategories: newArr });
  };

  saveSelectedCategories = () => {
    this.toggleShowCategories();
  };

  removeMaterials = (guid: string) => {
    const materials = [...this.state.materials];
    const index = materials.findIndex((e) => e.guid === guid);
    if (index > -1) {
      materials.splice(index, 1);
      this.setState({ materials });
    }
  };

  addMaterials = (value: string) => {
    const materials = [...this.state.materials];
    materials.push({ guid: v4(), value });
    this.setState({ materials });
  };
}
export const FormAgentDetails = observer(FormAgentDetailsBase);
interface FormAgentDetailsProps {
  session: SessionType;
  outsourcer: SelfOutsourcerType;
  categories: AgentsCategoriesDictionaryType;
  onSaved: () => void;
  onRemoved: () => void;
  onPrintCredentials: (outsourcer: OutsourcerType) => void;
  canChange: boolean;
  canRemove: boolean;
  canConfirm: boolean;
  canSupplierOrder: boolean;
  baseUrl: string;
  access: string[];
  regions: string[];
  showSensitiveData: boolean;
  onPrintDocCredentials?: (client: OutsourcerType, order: SelectItem | null) => void;
  confirmRequest?: (id: string) => void;
}

interface FormAgentDetailsState {
  showClientOrders: boolean;
  selectedOrder: null | SelectItem;
  materialVal: string;
  showMaterials: boolean;
  showCategories: boolean;
  isLegal: boolean;
  selfEmployed: boolean;
  materials: { value: string; guid: string }[];
  selectedCategories: string[];
}

function getFormValues(outsourcer: SelfOutsourcerType): TStringMap<any> {
  return {
    ...getSnapshot(outsourcer),
  };
}
