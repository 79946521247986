import { types } from "mobx-state-tree";

import { initialState as emptyIndicator } from "./order-indicator";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { Indicator, IndicatorList, initialState as emptyList } from "./indicator-list";

export const IndicatorsStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            list: IndicatorList,
            details: Indicator,
        })
    )
    .named("IndicatorsStore");

export const initialState = (): typeof IndicatorsStore.SnapshotType => ({
    list: emptyList(),
    details: emptyIndicator(),
});

export type IndicatorsStoreType = typeof IndicatorsStore.Type;
