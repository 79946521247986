import React from 'react';
import styles from './Buttons.module.scss';

export const Buttons = ({ left, children, className }: React.PropsWithChildren<ButtonsProps>) => (
    <div className={`buttons ${styles.buttons} ${className || ''}`}>
        {left && <div className={`buttons-left ${styles.left}`}>{left}</div>}
        {children}
    </div>
);

interface ButtonsProps {
    left?: React.ReactNode;
    className?: string;
}
