import React, { useCallback, useRef, useMemo } from "react";
import styles from "./PeriodSliderGray.module.scss";
import moment from "moment";
import { capitalize } from "modules/common/services/strings";

import Picker from "react-month-picker";
import { PlanrButton } from "modules/common/components/planr/button/Button";

const months = ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"];

export const PeriodSliderGray: React.FC<PeriodSliderProps> = (props) => {
    const { month, year, onChange } = props;

    const pickerRef = useRef<any>();

    const gotoNextMonth = useCallback(() => {
        const next = convertToMoment(month, year).add(1, "month");
        onChange(next.year(), next.month() + 1);
    }, [month, year, onChange]);

    const gotoPreviousMonth = useCallback(() => {
        const prev = convertToMoment(month, year).subtract(1, "month");
        if (prev.isValid()) {
            onChange(prev.year(), prev.month() + 1);
        }
    }, [month, year, onChange]);

    const onMonthChange = useCallback(
        (y: number, m: number) => {
            setTimeout(() => {
                onChange(y, m);
            }, 100);
        },
        [onChange]
    );

    const showPicker = useCallback(() => {
        pickerRef.current && pickerRef.current.show();
    }, []);

    const yearsOptions = useMemo(() => buildYears(year), [year]);

    const monthName = useMemo(() => makeMonthName(month), [month]);

    return (
        <div className={`period-slider ${styles.slider}`}>
            <PlanrButton
                type="graybtn"
                icon="general-big-arrow-left"
                onClick={gotoPreviousMonth}
                round={true}
                size="small"
            />

            {/* <Icon icon="chevron-left" onClick={gotoPreviousMonth} className={`${styles.step} no-print`} /> */}
            <Picker ref={pickerRef} years={yearsOptions} value={{ month, year }} lang={months} onChange={onMonthChange}>
                <span className={styles.value} onClick={showPicker} style={{ marginLeft: "16px", marginRight: "16px" }}>
                    &nbsp;{monthName}&nbsp;
                </span>
            </Picker>

            <PlanrButton
                type="graybtn"
                icon="general-big-arrow-right"
                onClick={gotoNextMonth}
                round={true}
                size="small"
            />

            {/* <Icon icon="chevron-right" onClick={gotoNextMonth} className={`${styles.step} no-print`} /> */}
        </div>
    );
};

const convertToMoment = (month: number, year: number) => moment([year, month - 1, 1]);

export const makePeriodName = (month: number, year: number) =>
    capitalize(convertToMoment(month, year).format("MMMM YYYY"));

export const makeMonthName = (month: number) => capitalize(convertToMoment(month, 1900).format("MMMM"));

const buildYears = (year: number) => {
    let start = year + 5;
    let end = Math.max(1, start - 15);

    const years: number[] = [];
    while (start >= end) {
        years.push(end++);
    }

    return years;
};

interface PeriodSliderProps {
    month: number;
    year: number;
    onChange: (year: number, month: number) => void;
}
