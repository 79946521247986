import { types, applySnapshot } from 'mobx-state-tree';
import { TokenList, initialState as emptyList } from './token-list';
import { TokenSnapshotType } from './token';
import { Transport } from 'modules/common/models/transport';
import { Notificator } from 'modules/common/models/notificator';
import { flow } from 'modules/common/models/flow';
import { apiUrls } from 'modules/common/services/communication/urls';

export const AutomaticTableName = 'TokensListAutomatic';
export const OuterTableName = 'TokensListOuter';

export const TokensStore = types.compose(
        Transport, Notificator, types.model({
        automatic: TokenList.named(AutomaticTableName),
        outer: TokenList.named(OuterTableName),
    }))
    .actions((self) => {

        const actions = {
            loadLists: flow(function* (){
                try {
                    const data: TokenSnapshotType[] = yield self.transport.get<TokenSnapshotType[]>(apiUrls.tokens.list);

                    const automatic = data.filter((o) => o.automatic);
                    const outer = data.filter((o) => !o.automatic);

                    applySnapshot(self.automatic.tokens, automatic);
                    applySnapshot(self.outer.tokens, outer);

                    return true;
                } catch (er) {
                    self.notify.error(er);
                    return false;
                }
            }),

            init () {
                actions.loadLists();
            }
        };

        return actions;
    })
    .named('TokensStore');

export const initialState = (): typeof TokensStore.SnapshotType => ({
    automatic: emptyList(AutomaticTableName),
    outer: emptyList(OuterTableName)
});

export type TokensStoreType = typeof TokensStore.Type;

