import { types, applySnapshot } from 'mobx-state-tree';
import { Transport } from 'modules/common/models/transport';
import { Notificator } from 'modules/common/models/notificator';
import { apiUrls } from 'modules/common/services/communication/urls';
import { flow } from 'modules/common/models/flow';
import { ProjectPortfolioDictionaryItem, ProjectPortfolioDictionaryItemType } from './project-portfolio';

export const ProjectPortfolioDictionary = types.compose(
    Transport, Notificator, types.model({
        portfolios: types.array(ProjectPortfolioDictionaryItem),
    }))
    .actions((self) => ({
        load: flow(function* (){
            try {
                const data: ProjectPortfolioDictionaryItemType[] = yield self.transport.get<any>(apiUrls.projectPortfolios.dictionary());
                applySnapshot(self.portfolios, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        })
    }))
    .views((self) => ({
        get isEmpty () {
            return !self.portfolios.length;
        }
    }))
    .named('ProjectPortfolioDictionary');

export const initialState = (): ProjectPortfolioDictionarySnapshotType => ({
    portfolios: []
});

export type ProjectPortfolioDictionaryType = typeof ProjectPortfolioDictionary.Type;
export type ProjectPortfolioDictionarySnapshotType = typeof ProjectPortfolioDictionary.SnapshotType;
