import { types } from "mobx-state-tree";
import moment from "moment";
import { DATE_TIME_FORMAT } from "modules/common/constants";
import { nameof } from "modules/common/services/typescript";
import { OutsourcerPaymentType } from "modules/agents/outsourcers/models/outsourcer-payment";

export interface WithSum {
  sum: number;
}

export const BasePayment = types
  .model({
    date: types.maybeNull(types.string),
    hasDate: types.maybeNull(types.string),
    sum: types.number,
    day: types.maybeNull(types.string),
    created: types.string,
  })
  .views((self) => ({
    get dateAsDate() {
      return self.date ? moment(self.date, DATE_TIME_FORMAT).toDate() : null;
    },
  }))
  .named("BasePayment");

export const OrderPayment = types
  .compose(
    BasePayment,
    types.model({
      name: types.string,
      guid: types.string,
      sortableDate: types.string,
      money: types.string,
      comment: types.string,
      type: types.string,
    })
  )
  .named("OrderPayment");

export const PlanOrderPayment = types
  .compose(
    OrderPayment,
    types.model({
      stageGuid: types.string,
      concreteDate: types.maybeNull(types.string),
      isPayed: types.boolean,
      hasProblem: types.boolean,
      problemDescription: types.string,
    })
  )
  .views((self) => ({
    get concreteDateAsDate() {
      return self.concreteDate ? moment(self.concreteDate, DATE_TIME_FORMAT).toDate() : null;
    },
  }))
  .actions((self) => ({
    setSum(value: number) {
      self.sum = value;
    },

    setName(value: string) {
      self.name = value;
    },
    setProblem() {
      self.hasProblem = !self.hasProblem;
    },
    setDescription(value: string) {
      self.problemDescription = value;
    },
  }))
  .named("PlanOrderPayment");

export const ActualOrderPayment = types
  .compose(
    OrderPayment,
    types.model({
      date: types.string,
      hasPlanMatch: types.boolean,
      planPaymentGuids: types.array(types.string),
      planPaymentsSum: types.array(
        types.model({
          planGuid: types.string,
          sum: types.number,
        })
      ),
    })
  )
  .actions((self) => ({
    addPlanPayment(guid: string) {
      if (!self.planPaymentGuids.includes(guid) && !self.hasPlanMatch) {
        self.planPaymentGuids.push(guid);
        return true;
      }

      return false;
    },

    removePlanPayment(guid: string) {
      return self.planPaymentGuids.remove(guid);
    },
  }))
  .named("ActualOrderPayment");

export type OrderPaymentType = typeof OrderPayment.Type;
export type OrderPaymentSnapshotType = typeof OrderPayment.SnapshotType;
export type PlanOrderPaymentType = typeof PlanOrderPayment.Type;
export type PlanOrderPaymentSnapshotType = typeof PlanOrderPayment.SnapshotType;
export type ActualOrderPaymentType = typeof ActualOrderPayment.Type;
export type ActualOrderPaymentSnapshotType = typeof ActualOrderPayment.SnapshotType;

export const paymentFields = {
  guid: nameof((a: OrderPaymentType) => a.guid) as string,
  name: nameof((a: OrderPaymentType) => a.name) as string,
  date: nameof((a: OrderPaymentType) => a.date) as string,
  sum: nameof((a: OrderPaymentType) => a.sum) as string,
  eventDates: nameof((a: OutsourcerPaymentType) => a.sum) as string,
};
