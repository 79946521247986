import { Classes, Dialog, FormGroup, Intent, Position, TextArea, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { FileLink } from "modules/common/components/files/FileLink";
import { Asterisk } from "modules/common/components/form";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { formatMoney } from "modules/common/components/money/Money";
import { MoneyInput } from "modules/common/components/money/MoneyInput";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { PlanrTag } from "modules/common/components/planr/tag/PlanrTag";
import { preventSubmitKeyDown } from "modules/common/services/form/values";
import { eat } from "modules/common/services/typescript";
import { texts } from "modules/common/texts";
import { ExpertiseFileType } from "modules/orders-manage/models/order";
import {
  CompletionFileType,
  ConsignmentFileType,
  OrderFileSnapshotType,
} from "modules/orders-manage/models/order-file";
import {
  EventedDateValue,
  OrderCompletionRowType,
  OrderConsignmentRowType,
  OrderExpertiseRowType,
  OrderStageRowItem,
  OrderStageRowType,
  OrderStagesStoreType,
  PlanOrderPaymentDetailsRowType,
  PlanOrderPaymentDetailsType,
  fields,
} from "modules/orders-manage/models/order-stages-store";
import { IdFactory } from "modules/orders-manage/types";
import React, { useCallback, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { NumberFormatValues } from "react-number-format";
import { ActualPayment } from "./ActualPayment";
import { CompletionRow } from "./CompletionRow";
import { ConsignmentRow } from "./ConsignmentRow";
import { EventedDate } from "./EventedDate";
import { ExpertiseRow } from "./ExpertiseRow";
import { PaymentRow } from "./PaymentRow";
import { SentExpertiseRow } from "./SentExpertiseRow";
import styles from "./Stages.module.scss";

const Error: Intent = "danger";
const star = <Asterisk isRequired={true} />;

const sortPayments = (a: any, b: any) => {
  const adate = Date.parse(a.created.replace(/(\d+).(\d+).(\d+)/, "$3/$2/$1"));
  const bdate = Date.parse(b.created.replace(/(\d+).(\d+).(\d+)/, "$3/$2/$1"));
  return adate - bdate;
};

export const StageRow = observer(
  ({
    stage,
    index,
    store,
    readonly,
    onSubmit,
    payments,
    completions,
    consignments,
    expertises,
    sentExpertises,
    additions,
    highlight,
  }: StageRowProps) => {
    const { validation, isValid } = stage;
    const [linkCompletions, setLinkCompletions] = useState<boolean>(false);
    const [linkExpertises, setLinkExpertises] = useState<boolean>(false);
    const [linkSentExpertises, setLinkSentExpertises] = useState<boolean>(false);
    const [linkConsignments, setLinkConsignments] = useState<boolean>(false);
    const [paymentType, setPaymentType] = useState<string>("");

    const stopPaymentManage = useCallback(() => setPaymentType(""), []);
    const startPaymentManage = useCallback((type: string) => {
      setPaymentType(type);
      setLinkCompletions(false);
      setLinkExpertises(false);
      setLinkConsignments(false);
      setLinkSentExpertises(false);
    }, []);

    const stopLinkCompletions = useCallback(() => setLinkCompletions(false), []);
    const startLinkCompletions = useCallback(() => {
      setLinkCompletions(true);
      setLinkExpertises(false);
      setLinkConsignments(false);
      setLinkSentExpertises(false);
      setPaymentType("");
    }, []);

    const stopLinkConsignments = useCallback(() => setLinkConsignments(false), []);
    const startLinkConsignments = useCallback(() => {
      setLinkConsignments(true);
      setLinkExpertises(false);
      setLinkCompletions(false);
      setLinkSentExpertises(false);
      setPaymentType("");
    }, []);

    const stopLinkExpertises = useCallback(() => setLinkExpertises(false), []);
    const startLinExpertises = useCallback(() => {
      setLinkExpertises(true);
      setLinkCompletions(false);
      setLinkSentExpertises(false);
      setLinkConsignments(false);
      setPaymentType("");
    }, []);

    const stopLinkSentExpertises = useCallback(() => setLinkSentExpertises(false), []);
    const startLinkSentExpertises = useCallback(() => {
      setLinkSentExpertises(true);
      setLinkExpertises(false);
      setLinkCompletions(false);
      setLinkConsignments(false);
      setPaymentType("");
    }, []);

    const onNameChangeHandler = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        stage.setName(e.target.value);
      },
      [stage]
    );

    const onSumChangeHandler = useCallback(
      (money: NumberFormatValues) => {
        stage.setSum(money.floatValue ?? 0);
      },
      [stage]
    );
    const onWithoutSumChangeHandler = useCallback(
      (val: boolean) => {
        stage.setWithoutSum(val);
      },
      [stage]
    );

    const onStartDateChangeHandler = useCallback(
      (value: EventedDateValue) => {
        stage.setStartPlanDate(value);
      },
      [stage]
    );

    const onStopDateChangeHandler = useCallback(
      (value: EventedDateValue) => {
        stage.setStopPlanDate(value);
      },
      [stage]
    );

    const onActivateHandler = useCallback(() => {
      if (!readonly) {
        store.editStage(stage);
      }
    }, [stage, store, readonly]);

    const onCancelHandler = useCallback(() => {
      store.cancelEditing(stage);
    }, [stage, store]);

    const linkedPayments = store.stagePlanMap[stage.newGuid];

    var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });
    if (linkedPayments) {
      linkedPayments["Расчетный"].sort((a: PlanOrderPaymentDetailsRowType, b: PlanOrderPaymentDetailsRowType) =>
        collator.compare(a.name, b.name)
      );

      linkedPayments["Авансовый"].sort((a: PlanOrderPaymentDetailsRowType, b: PlanOrderPaymentDetailsRowType) =>
        collator.compare(a.name, b.name)
      );
    }
    const linkedCompletons = store.stageCompletionMap[stage.newGuid];
    const linkedConsignments = store.stageConsignmentMap[stage.newGuid];
    const linkedSentExpertises = store.stageSentExpertiseMap[stage.newGuid];
    const linkedExpertises = store.stageExpertiseMap[stage.newGuid];
    const completedSum = linkedCompletons ? linkedCompletons.reduce((sum, item) => sum + item.sum, 0) : 0;
    const completedPlanPayments = linkedPayments
      ? linkedPayments["Авансовый"].reduce(
          (sum: any, item: any) => (item.date && item.remainsSum === 0 ? sum + item.sum : sum + 0),
          0
        )
      : 0;
    const completedActPayments = linkedPayments
      ? linkedPayments["Расчетный"].reduce((sum: any, item: any) => {
          return item.date && item.remainsSum === 0 ? sum + item.sum : sum + 0;
        }, 0)
      : 0;
    linkedPayments && linkedPayments["Расчетный"].forEach((item: any) => {});
    const stageCompleted = completedSum >= stage.sum;
    const warning = getWarnings(stage, linkedPayments);
    const paymentsCompleted = completedPlanPayments + completedActPayments >= stage.sum;

    if (!stage.active) {
      return (
        <div className={styles.stageRow} onDoubleClick={onActivateHandler}>
          <div className={styles.number}>Этап работ №{index + 1}</div>
          <div className={styles.name}>
            {stage.name}
            <div>
              {linkedExpertises && <span className={styles.smallExpertiseText}>Заключ.экспертизы</span>}
              {linkedExpertises?.map((expertise) => {
                return <StageExpertise expertise={expertise} key={expertise.newGuid} />;
              })}
            </div>
            <div>
              {linkedSentExpertises && <span className={styles.smallExpertiseText}>Передача в экспертизу</span>}
              {linkedSentExpertises?.map((expertise) => {
                return <StageExpertise expertise={expertise} key={expertise.newGuid} />;
              })}
            </div>
          </div>
          <div className={styles.completions}>
            {!stage.withoutSum ? (
              <>
                <PlanrTag type={stageCompleted ? "success" : "neutral"}>{formatMoney(completedSum)}</PlanrTag>
                {linkedCompletons?.map((completion) => {
                  return <StageCompletion completion={completion} key={completion.newGuid} />;
                })}
              </>
            ) : (
              <span style={{ fontSize: "12px" }}>Без стоимости</span>
            )}
          </div>
          <div className={styles.completions}>
            {linkedConsignments?.map((consignment) => {
              return <StageConsignment consignment={consignment} key={consignment.newGuid} />;
            })}
          </div>
          <div className={styles.start}>
            <StageDate date={stage.start} description={stage.startDateText} />
          </div>
          <div className={styles.stop}>
            <StageDate date={stage.stop} description={stage.stopDateText} />
          </div>
          <div className={styles.sum}>
            {!stage.withoutSum ? (
              <>
                <PlanrTag type={paymentsCompleted ? "success" : "neutral"}>{formatMoney(stage.sum)}</PlanrTag>
                {"sum" in warning && !readonly && <Warning warning={warning.sum} />}
              </>
            ) : (
              <span style={{ fontSize: "12px" }}>Без стоимости</span>
            )}
          </div>
          <div className={styles.payments}>
            {linkedPayments &&
              linkedPayments["Авансовый"].map((payment: PlanOrderPaymentDetailsType) => {
                return <PaymentInfo key={payment.newGuid} payment={payment} store={store} highlight={highlight} />;
              })}
          </div>
          <div className={styles.payments}>
            {linkedPayments &&
              linkedPayments["Расчетный"].map((payment: PlanOrderPaymentDetailsType) => {
                return <PaymentInfo key={payment.newGuid} payment={payment} store={store} highlight={highlight} />;
              })}
          </div>
        </div>
      );
    }

    const { canLinkExpertises, canLinkSentExpertises, canLinkCompletions, canLinkPayments, canLinkConsignments } =
      store;

    return (
      <div className={`${styles.stageRow} ${styles.active}`}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={styles.number}>{index + 1}.</div>
          <div className={styles.name}>
            <FormGroup
              labelInfo={star}
              helperText={validation[fields.name]}
              intent={validation[fields.name] ? Error : "none"}
              className={Classes.SMALL}
            >
              <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                <TextArea
                  id="name"
                  value={stage.name}
                  growVertically={true}
                  autoComplete="off"
                  onChange={onNameChangeHandler}
                  placeholder="Наименование работ"
                  small={true}
                />
              </div>
            </FormGroup>
            <div>
              {linkedExpertises?.map((expertise) => (
                <ExpertiseRow expertise={expertise} key={expertise.guid} />
              ))}
            </div>
            <div className={styles.buttons}>
              {!canLinkExpertises && (
                <PlanrButton
                  type="secondary"
                  size="small"
                  icon="general-plus-small"
                  onClick={store.addNewExpertise}
                  style={{ paddingLeft: "7px" }}
                >
                  Экспертиза
                </PlanrButton>
              )}
              {canLinkExpertises && (
                <PlanrButton type="secondary" size="small" onClick={startLinExpertises}>
                  Привязать экспертизы
                </PlanrButton>
              )}
            </div>
            <div className={styles.blockContainer}>
              {linkedSentExpertises?.map((expertise) => (
                <SentExpertiseRow expertise={expertise} key={expertise.guid} />
              ))}
            </div>
            <div className={styles.buttons}>
              {!canLinkSentExpertises && (
                <PlanrButton
                  type="secondary"
                  size="small"
                  icon="general-plus-small"
                  onClick={store.addNewSentExpertise}
                  style={{ paddingLeft: "7px" }}
                >
                  Передача в экспертизу
                </PlanrButton>
              )}
              {canLinkSentExpertises && (
                <PlanrButton type="secondary" size="small" onClick={startLinkSentExpertises}>
                  Привязать перед. в эксперт.
                </PlanrButton>
              )}
            </div>
          </div>
          <div className={styles.completions}>
            <div className={styles.blockContainer}>
              {linkedCompletons?.map((completion) => (
                <CompletionRow completion={completion} key={completion.newGuid} />
              ))}
            </div>
            {!stage.withoutSum && (
              <div className={styles.buttons}>
                {!canLinkCompletions && (
                  <PlanrButton
                    type="secondary"
                    size="small"
                    icon="general-plus-small"
                    onClick={store.addNewCompletion}
                    style={{ paddingLeft: "7px" }}
                  >
                    Новый акт
                  </PlanrButton>
                )}
                {canLinkCompletions && (
                  <PlanrButton type="secondary" size="small" onClick={startLinkCompletions}>
                    Привязать акты
                  </PlanrButton>
                )}
              </div>
            )}
          </div>
          <div className={styles.completions}>
            <div className={styles.blockContainer}>
              {linkedConsignments?.map((consignment) => (
                <ConsignmentRow consignment={consignment} key={consignment.newGuid} />
              ))}
              {linkedConsignments && linkedConsignments.length > 0 && (
                <PlanrButton
                  type="secondary"
                  size="small"
                  onClick={() => {
                    if (stage.stageIsConsigned) {
                      stage.setConsignment(false);
                    } else {
                      stage.setConsignment(true);
                    }
                  }}
                >
                  {!stage.stageIsConsigned ? "Закончить загрузку" : "Добавить еще"}
                </PlanrButton>
              )}
            </div>
            <div className={styles.buttons}>
              {!canLinkConsignments && !stage.stageIsConsigned && (
                <PlanrButton
                  type="secondary"
                  size="small"
                  icon="general-plus-small"
                  onClick={store.addNewConsignment}
                  style={{ paddingLeft: "7px" }}
                >
                  Накладная
                </PlanrButton>
              )}
              {canLinkConsignments && (
                <PlanrButton type="secondary" size="small" onClick={startLinkConsignments}>
                  Привязать накладные
                </PlanrButton>
              )}
            </div>
          </div>
          <div className={styles.start}>
            <FormGroup
              labelInfo={star}
              helperText={validation[fields.startDate]}
              intent={validation[fields.startDate] ? Error : "none"}
              className={Classes.SMALL}
            >
              <EventedDate
                onChange={onStartDateChangeHandler}
                date={stage.startPlanDateAsDate}
                days={0}
                workDaysOnly={false}
                events={stage.startEventDates}
                newId={store.generateId}
                payments={store.planPayments}
                excludeBelonging={stage.newGuid}
                stages={store.stageItems}
                exclusiveMode={true}
                additions={additions}
              />
            </FormGroup>
          </div>
          <div className={styles.stop}>
            <FormGroup
              labelInfo={star}
              helperText={validation[fields.stopDate]}
              intent={validation[fields.stopDate] ? Error : "none"}
              className={Classes.SMALL}
            >
              <EventedDate
                withDuration={true}
                onChange={onStopDateChangeHandler}
                date={stage.stopPlanDateAsDate}
                days={stage.duration?.daysCount ?? 0}
                workDaysOnly={stage.duration?.workDays ?? false}
                events={stage.stopEventDates}
                newId={store.generateId}
                payments={store.planPayments}
                excludeBelonging={stage.newGuid}
                stages={store.stageItems}
                exclusiveMode={true}
                additions={additions}
                stageGuid={stage.newGuid}
              />
            </FormGroup>
          </div>
          <div className={styles.sum}>
            {!stage.withoutSum && (
              <>
                <FormGroup
                  labelInfo={star}
                  helperText={validation[fields.sum]}
                  intent={validation.sum ? Error : "none"}
                  className={Classes.SMALL}
                >
                  <MoneyInput
                    className="in-cell planr-default-input"
                    id="sum"
                    small={false}
                    autoComplete="off"
                    data-lpignore="true"
                    value={stage.sum}
                    onMoneyChange={onSumChangeHandler}
                    onKeyDown={preventSubmitKeyDown}
                  />
                </FormGroup>
                {"sum" in warning && <Warning warning={warning.sum} />}
                <PlanrButton
                  type="secondary"
                  onClick={() => {
                    onWithoutSumChangeHandler(true);
                  }}
                  size="small"
                >
                  Без стоимости
                </PlanrButton>
              </>
            )}
            {stage.withoutSum && (
              <PlanrButton
                type="secondary"
                onClick={() => {
                  onWithoutSumChangeHandler(false);
                }}
                size="small"
              >
                Со стоимостью
              </PlanrButton>
            )}
          </div>
          {!stage.withoutSum && (
            <div className={styles.payments}>
              <StagePayments
                type="Авансовый"
                newId={store.generateId}
                payments={linkedPayments?.Авансовый}
                allPayments={store.planPayments}
                onAddNewPayment={store.addNewPayment}
                onStartLink={startPaymentManage}
                allStages={store.stageItems}
                canLink={canLinkPayments}
                additions={additions}
              />
            </div>
          )}
          {!stage.withoutSum && (
            <div className={styles.payments}>
              <StagePayments
                type="Расчетный"
                newId={store.generateId}
                payments={linkedPayments?.Расчетный}
                allPayments={store.planPayments}
                onAddNewPayment={store.addNewPayment}
                onStartLink={startPaymentManage}
                allStages={store.stageItems}
                canLink={canLinkPayments}
                additions={additions}
              />
            </div>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}>
          {stage.guid && (
            <RemoveConfirmation<OrderStageRowType>
              what={() => "Этап"}
              render={({ confirmRemoving }) => (
                <PlanrButton
                  type="secondary"
                  icon="general-trash"
                  onClick={() => confirmRemoving(stage)}
                  size="small"
                  title={texts.remove}
                  className={styles.removeButton}
                >
                  {texts.remove} этап
                </PlanrButton>
              )}
              onConfirmed={store.removeStage}
            />
          )}

          <PlanrButton
            type="greenish"
            disabled={!isValid}
            size="small"
            onClick={onSubmit}
            className={styles.saveButton}
          >
            {texts.save}
          </PlanrButton>

          <PlanrButton type="graybtn" size="small" onClick={onCancelHandler} style={{ marginRight: "10px" }}>
            {texts.cancel}
          </PlanrButton>
        </div>

        <Dialog
          icon="info-sign"
          title={paymentType}
          canEscapeKeyClose={true}
          canOutsideClickClose={false}
          isOpen={!!paymentType}
          onClose={stopPaymentManage}
          style={{ width: `780px` }}
          backdropClassName="standard"
        >
          <div className={`${Classes.DIALOG_BODY} ${styles.linkerDialog}`}>{payments(paymentType)}</div>
        </Dialog>

        <Dialog
          icon="info-sign"
          title="Акты выполненных работ"
          canEscapeKeyClose={true}
          canOutsideClickClose={false}
          isOpen={linkCompletions}
          onClose={stopLinkCompletions}
          style={{ width: `780px` }}
          backdropClassName="standard"
        >
          <div className={`${Classes.DIALOG_BODY} ${styles.linkerDialog}`}>{completions()}</div>
        </Dialog>

        <Dialog
          icon="info-sign"
          title="Переданные накладные"
          canEscapeKeyClose={true}
          canOutsideClickClose={false}
          isOpen={linkConsignments}
          onClose={stopLinkConsignments}
          style={{ width: `780px` }}
          backdropClassName="standard"
        >
          <div className={`${Classes.DIALOG_BODY} ${styles.linkerDialog}`}>{consignments()}</div>
        </Dialog>

        <Dialog
          icon="info-sign"
          title="Заключения экспертиз"
          canEscapeKeyClose={true}
          canOutsideClickClose={false}
          isOpen={linkExpertises}
          onClose={stopLinkExpertises}
          style={{ width: `850px` }}
          backdropClassName="standard"
        >
          <div className={`${Classes.DIALOG_BODY} ${styles.linkerDialog}`}>{expertises()}</div>
        </Dialog>
        <Dialog
          icon="info-sign"
          title="Передача в экспертизу"
          canEscapeKeyClose={true}
          canOutsideClickClose={false}
          isOpen={linkSentExpertises}
          onClose={stopLinkSentExpertises}
          style={{ width: `850px` }}
          backdropClassName="standard"
        >
          <div className={`${Classes.DIALOG_BODY} ${styles.linkerDialog}`}>{sentExpertises()}</div>
        </Dialog>
      </div>
    );
  }
);

const PaymentInfo = observer(
  ({
    payment,
    store,
    highlight,
  }: {
    payment: PlanOrderPaymentDetailsType;
    store: OrderStagesStoreType;
    highlight: string;
  }) => {
    const { actualMap } = store;
    //const knownDate = payment.date && payment.date !== payment.dateDescription ? payment.date : null;

    const date = payment.hasDate ? payment.hasDate : "";
    const knownDate = date && date !== payment.dateDescription ? date : null;

    const type = payment.type === "Авансовый" ? "Аванс-" : "";

    const unlinkPlanPayment = (actualPaymentGuid: string, planPaymentGuid: string) => {
      store.saveDrop(planPaymentGuid, actualPaymentGuid, true);
    };

    // const actPaymentsMatcher = store.paymentsMatcher();
    const paidSum = payment.sum - payment.remainsSum;
    const completellyPaid = payment.isPayed;
    const paidText = !completellyPaid ? formatMoney(paidSum) : "";
    const planSumFormated = formatMoney(payment.sum);
    const leftSum = !completellyPaid ? formatMoney(payment.remainsSum) : "";
    return (
      <div data-object-id={payment.guid}>
        <Droppable droppableId={payment.guid} isDropDisabled={payment.isPayed}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`${styles.dropHere} ${!completellyPaid ? styles.dropNotFullPaid : ""} ${
                snapshot.isDraggingOver ? styles.isDraggingOver : ""
              } ${highlight === payment.guid ? styles.highlighted : ""}`}
            >
              <div className={styles.headerPayment}>
                {payment.hasProblem && (
                  <Tooltip
                    className={Classes.TOOLTIP_INDICATOR}
                    content={
                      payment.problemDescription && payment.problemDescription !== ""
                        ? `Претензия: ${payment.problemDescription}`
                        : "Претензия"
                    }
                  >
                    <span>
                      <GeneralIcon type="general-exclamation" style={{ color: "#EA561E" }} />
                    </span>
                  </Tooltip>
                )}
                {type}платеж №{payment.name}
              </div>
              <div>
                <div className={completellyPaid ? styles.paid : ""}>
                  {!completellyPaid ? (
                    `План: ${planSumFormated}`
                  ) : (
                    <PlanrTag style={{ marginBottom: "5px" }} type={"success"}>
                      {planSumFormated}
                    </PlanrTag>
                  )}
                </div>
                {!completellyPaid ? <div className={styles.unpaid}>{`Факт: ${paidText}`}</div> : null}
                {!completellyPaid ? (
                  <div className={styles.leftPaid}>
                    <PlanrTag type={"danger"}>{leftSum}</PlanrTag>
                  </div>
                ) : null}
              </div>
              <div style={{ marginBottom: "5px" }}>
                {knownDate && (
                  <Tooltip content={knownDate} position={Position.TOP}>
                    <span className={Classes.TOOLTIP_INDICATOR}>{payment.dateDescription}</span>
                  </Tooltip>
                )}
                {!knownDate && payment.dateDescription}
              </div>
              {payment.actualPaymentGuids.map((guid) => {
                const actualPayment = actualMap[guid];
                if (!actualPayment) {
                  return null;
                }

                return (
                  <div className={styles.linkedPayment} key={guid}>
                    <ActualPayment
                      hideRemove={snapshot.isDraggingOver}
                      planGuid={payment.guid}
                      payment={actualPayment}
                      highlight={highlight}
                      onRemove={() => {
                        unlinkPlanPayment(actualPayment.guid, payment.guid);
                      }}
                    />
                  </div>
                );
              })}
              {provided.placeholder}
              {payment.canBeLinked && snapshot.isDraggingOver && (
                <div className={styles.pointer}>Перетащите платеж сюда</div>
              )}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
);

const StageDate = ({ date, description }: { date: string | null; description: string }) => {
  const knownDate = date && date !== description ? date : null;

  if (knownDate) {
    return (
      <Tooltip content={knownDate} position={Position.BOTTOM}>
        <>
          {splitDescription(description).map((line, i) => (
            <div key={i} className={`${styles.line} ${Classes.TOOLTIP_INDICATOR}`}>
              {line}
            </div>
          ))}
        </>
      </Tooltip>
    );
  }

  return (
    <>
      {splitDescription(description).map((line, i) => (
        <div key={i} className={styles.line}>
          {line}
        </div>
      ))}
    </>
  );
};

const StagePayments = ({
  payments,
  allStages,
  allPayments,
  canLink,
  type,
  newId,
  onAddNewPayment,
  onStartLink,
  additions,
}: StagePaymentsProps) => {
  const onAddHandler = useCallback(() => onAddNewPayment(type), [type, onAddNewPayment]);
  const onLinkHandler = useCallback(() => onStartLink(type), [type, onStartLink]);

  return (
    <>
      {payments?.sort(sortPayments).map((payment, index) => {
        return (
          <PaymentRow
            key={payment.guid || index}
            payment={payment}
            newId={newId}
            payments={allPayments}
            stages={allStages}
            additions={additions}
          />
        );
      })}

      <div className={styles.buttons}>
        {!canLink && (
          <PlanrButton
            type="secondary"
            size="small"
            icon="general-plus-small"
            onClick={onAddHandler}
            style={{ paddingLeft: "7px" }}
          >
            Новый платеж
          </PlanrButton>
        )}

        {canLink && (
          <PlanrButton type="secondary" size="small" onClick={onLinkHandler}>
            Привязать платежи
          </PlanrButton>
        )}
      </div>
    </>
  );
};

const StageCompletion = ({ completion }: { completion: OrderCompletionRowType }) => {
  const child = (
    <div key={completion.newGuid}>
      Акт №{completion.name}
      <br />
      {formatMoney(completion.sum)}
    </div>
  );

  return completion.file ? (
    <FileLink<CompletionFileType>
      baseUrl={completion.baseUrl}
      file={completion.file}
      readOnly={true}
      onRemove={eat}
      fileLabel={() => child}
      textWidth={"90%"}
      style={{ width: "175px", minWidth: "175px", maxWidth: "175px" }}
    />
  ) : (
    child
  );
};

const StageConsignment = ({ consignment }: { consignment: OrderConsignmentRowType }) => {
  const child = <div key={consignment.newGuid}>Накладная №{consignment.number}</div>;

  return consignment.file ? (
    <FileLink<ConsignmentFileType>
      baseUrl={consignment.baseUrl}
      file={consignment.file}
      readOnly={true}
      onRemove={eat}
      fileLabel={() => child}
      textWidth={"90%"}
      style={{ width: "175px", minWidth: "175px", maxWidth: "175px" }}
    />
  ) : (
    child
  );
};

const StageExpertise = ({ expertise }: { expertise: OrderExpertiseRowType }) => {
  return expertise.file ? (
    <FileLink<ExpertiseFileType>
      baseUrl={expertise.baseUrl}
      file={expertise.file}
      readOnly={true}
      onRemove={eat}
      style={{ width: "175px", minWidth: "175px", maxWidth: "175px" }}
    />
  ) : null;
};

interface StagePaymentsProps extends IdFactory {
  type: string;
  payments?: PlanOrderPaymentDetailsRowType[];
  allStages: OrderStageRowItem[];
  additions: any;
  allPayments: PlanOrderPaymentDetailsRowType[];
  onAddNewPayment: (type: string) => void;
  onStartLink: (type: string) => void;
  canLink: boolean;
}

interface StageRowProps {
  index: number;
  stage: OrderStageRowType;
  store: OrderStagesStoreType;
  additions: OrderFileSnapshotType[];
  onSubmit: () => void;
  payments: (type: string) => React.ReactNode;
  completions: () => React.ReactNode;
  expertises: () => React.ReactNode;
  sentExpertises: () => React.ReactNode;
  consignments: () => React.ReactNode;
  readonly?: boolean;
  highlight: string;
}

const splitDescription = (description: string) => {
  return description.split(";");
};

const getWarnings = (stage: OrderStageRowType, payments?: TStringMap<PlanOrderPaymentDetailsRowType[]>) => {
  const result: TStringMap<string> = {};
  payments = payments ?? {};

  let collection: PlanOrderPaymentDetailsRowType[] = [];
  for (const key in payments) {
    if (Object.prototype.hasOwnProperty.call(payments, key)) {
      collection.push(...payments[key]);
    }
  }

  const paymentsSum = collection.reduce((sum, payment) => sum + payment.sum, 0).toFixed(2);
  const stageSum = stage.sum.toFixed(2);

  if (paymentsSum !== stageSum) {
    result["sum"] = "Стоимость не совпадает с суммой платежей по этапу";
  }

  return result;
};

const Warning = ({ warning }: { warning: string }) => {
  return (
    <div className={styles.warning}>
      <GeneralIcon type="general-exclamation" style={{ color: "#EA561E" }} size={13} />
      &nbsp;
      {warning}
    </div>
  );
};
