import React, { useCallback, useEffect, useRef } from "react";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Block } from "modules/common/components/page/Block";
import { Route, matchPath, useHistory } from "react-router";
import { History } from "history";
import { routes } from "modules/common/routes";
import { observer } from "mobx-react";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { DeprGroupDetails } from "./details/DeprGroupsDetails";
import { DeprGroupsList } from "./list/DeprGroupsList";
import { can } from "modules/session/auth/access";
import functionalities from "modules/dictionaries/functionalities";
import { useMst } from "modules/root/models/hooks";
import styles from "./DeprGroupsPage.module.scss";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { Search } from "modules/common/components/form/Search";
import { Link } from "react-router-dom";

interface DeprGroupsPageProps {
    access: string[];
}

const openDeprGroup = (history: History, id: string) => {
    const url = id ? routes.dictionaries.inventory.deprGroupsDetails(id) : routes.dictionaries.inventory.pathGroups;
    history.push(url);
};

const getSelectedDeprGroupId = (pathname: string) => {
    const match = matchPath<any>(pathname, routes.dictionaries.inventory.deprGroupsDetails());
    return match && match.params ? match.params.id : "";
};

export const DeprGroupsPage = observer((props: DeprGroupsPageProps) => {
    const history = useHistory();
    const { deprGroups } = useMst();
    const deprGroupId = useRef("");

    useEffect(() => {
        document.title = "Группы амортизации";
        deprGroups.list.load();
        deprGroups.details.load(getSelectedDeprGroupId(history.location.pathname));
        return history.listen((loc) => {
            const currentDeprGroupId = getSelectedDeprGroupId(loc.pathname);

            if (deprGroupId.current !== currentDeprGroupId) {
                deprGroupId.current = currentDeprGroupId;
                deprGroups.details.load(currentDeprGroupId);
            }
        });
    }, [history, deprGroups.list, deprGroups.details]);

    const onDeprGroupSelected = useCallback((id = "") => openDeprGroup(history, id), [history]);

    const onDeprGroupClicked = (id = "") => {};

    const onDeprGroupRemoved = useCallback(() => {
        deprGroups.list.load();
        history.push(routes.dictionaries.inventory.pathGroups);
    }, [history, deprGroups.list]);

    const onDeprGroupSaved = useCallback(() => {
        openDeprGroup(history, "");
        deprGroups.list.load();
    }, [history, deprGroups.list]);

    const onDeprGroupAdd = useCallback(() => openDeprGroup(history, EMPTY_OBJECT_ID), [history]);

    const canChange = can(functionalities.INVENTORY_EDIT, props.access);

    return (
        <div className={styles.container}>
            <Block columned={true} className={styles.page}>
                <Route path={routes.dictionaries.inventory.deprGroupsDetails()}>
                    <DetailsPopup title="Группа амортизации" onCancel={onDeprGroupSelected}>
                        <DeprGroupDetails
                            deprGroup={deprGroups.details}
                            onSaved={onDeprGroupSaved}
                            onRemoved={onDeprGroupRemoved}
                            canChange={canChange}
                        />
                    </DetailsPopup>
                </Route>
                <span className={styles.topSpan}>Группы амортизации</span>
                {canChange && (
                    <div className={styles.nav}>
                        <PlanrButton
                            type="secondary"
                            size="small"
                            icon="general-plus-big"
                            onClick={onDeprGroupAdd}
                            round={true}
                            style={{
                                // width: "220px",
                                marginBottom: "32px",
                                // borderRadius: "120px",
                            }}
                        />

                        <Link to={routes.dictionaries.inventory.path} className="planr-button-link">
                            <PlanrButton
                                type="lightblueish"
                                size="small"
                                style={{
                                    width: "200px",
                                    marginBottom: "32px",
                                    borderRadius: "120px",
                                }}
                            >
                                Материальная база
                            </PlanrButton>
                        </Link>
                    </div>
                )}
                <div className={styles.right}>
                    <Search query={deprGroups.list.query} onSearch={deprGroups.list.setQuery} />
                </div>
                <DeprGroupsList
                    list={deprGroups.list}
                    onSelected={onDeprGroupSelected}
                    onClicked={onDeprGroupClicked}
                    onRemoved={onDeprGroupRemoved}
                />
            </Block>
        </div>
    );
});
