import { types } from "mobx-state-tree";
import { BaseEntity } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { flow } from "modules/common/models/flow";
import { nameof } from "modules/common/services/typescript";
import { apiUrls } from "modules/common/services/communication/urls";
import { EmployerLink } from "modules/spending/employee/models/employer";

const TokenBase = types
    .compose(
        Transport,
        Notificator,
        BaseEntity,
        types.model({
            value: types.string,
            ipAddress: types.string,
            employer: EmployerLink,
            automatic: types.boolean,
            userAgent: types.string,
            location: types.string,
            confirmed: types.maybeNull(types.boolean),
            creationDate: types.string,
            createdSortable: types.string,
        })
    )
    .views((self) => ({
        get login() {
            return self.employer.login;
        },

        get employerName() {
            return self.employer.name;
        },
    }));

export const Token = TokenBase.actions((self) => ({
    toggleConfirmationState: flow(function* (state: boolean) {
        try {
            const data: boolean = yield self.transport.post<boolean>(apiUrls.tokens.toggle(self.id));
            self.confirmed = data;
            return true;
        } catch (er) {
            self.notify.error(er);
            return false;
        }
    }),
})).named("Token");

export const fields = {
    id: nameof((a: TokenType) => a.id) as string,
    value: nameof((a: TokenType) => a.value) as string,
    ipAddress: nameof((a: TokenType) => a.ipAddress) as string,
    created: nameof((a: TokenType) => a.created) as string,
    creationDate: nameof((a: TokenType) => a.creationDate) as string,
    createdSortable: nameof((a: TokenType) => a.createdSortable) as string,
    employer: nameof((a: TokenType) => a.employerName) as string,
    automatic: nameof((a: TokenType) => a.automatic) as string,
    userAgent: nameof((a: TokenType) => a.userAgent) as string,
    login: nameof((a: TokenType) => a.login) as string,
    location: nameof((a: TokenType) => a.location) as string,
    confirmed: nameof((a: TokenType) => a.confirmed) as string,
};

export type TokenSnapshotType = typeof TokenBase.SnapshotType;
export type TokenType = typeof Token.Type;
