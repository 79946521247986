import React from "react";
import { Formik, Form } from "formik";
import styles from "./BankDetailsDetails.module.scss";
import CompanyBankDetailsSchema from "./validation";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { Classes, InputGroup, Switch } from "@blueprintjs/core";
import { fixTextAreaHeight, MODAL_AWAIT_DELAY } from "modules/common/services/form/textarea";
import { CompanyBankDetailsType, fields, CompanyBankDetailsSnapshotType } from "../../models/bank-details";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { texts } from "modules/common/texts";
import { Prompt } from "react-router";
import { BankDetailsForm } from "./BankDetailsForm";
import {
    DirectorField,
    BankDetailsCompanyAddressField,
    BankDetailsEmailField,
} from "modules/agents/components/BankDetailsFields";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";

const schema = CompanyBankDetailsSchema();

class BankDetailsDetailsBase extends React.PureComponent<ProjectPortfolioDetailsProps> {
    private descriptionArea: HTMLTextAreaElement | null = null;
    private alive: boolean = true;

    private descriptionAreaRef = (ref: HTMLTextAreaElement | null) => {
        this.descriptionArea = ref;
    };

    private resize = () => {
        fixTextAreaHeight(this.descriptionArea);
    };

    public componentDidMount() {
        this.alive = true;
        setTimeout(this.resize, MODAL_AWAIT_DELAY);
    }

    public componentDidUpdate() {
        this.resize();
    }

    public componentWillUnmount() {
        this.alive = false;
    }

    render() {
        const { bankDetails, onSave, onSaved, onRemove, canChange } = this.props;

        return (
            <div className={styles.details}>
                <Formik
                    initialValues={getFormValues(bankDetails)}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        if (!canChange) {
                            return;
                        }

                        const succsessfull = await onSave(values);

                        if (this.alive) {
                            setSubmitting(false);

                            if (succsessfull) {
                                resetForm();
                                onSaved();
                            }
                        }
                    }}
                >
                    {(formProps) => {
                        const remove = async () => {
                            if (!canChange) {
                                return;
                            }

                            formProps.setSubmitting(true);
                            await onRemove();
                            if (this.state) {
                                formProps.setSubmitting(false);
                            }
                        };

                        return (
                            <Form autoComplete="off">
                                <Prompt
                                    when={formProps.dirty && canChange}
                                    message={texts.messages.leaveConfiramtion}
                                />
                                <div className={Classes.DIALOG_BODY}>
                                    <StandardFormInput
                                        name={fields.main}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <Switch
                                                checked={field.value}
                                                {...field}
                                                large={true}
                                                disabled={!canChange}
                                            />
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.name}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <InputGroup
                                                id={field.name}
                                                {...field}
                                                className="planr-default-input"
                                                autoComplete="off"
                                                data-lpignore="true"
                                                autoFocus={true}
                                                disabled={!canChange}
                                            />
                                        )}
                                    </StandardFormInput>

                                    <DirectorField schema={schema} fields={fields} readonly={!canChange} />

                                    <BankDetailsCompanyAddressField
                                        schema={schema}
                                        fields={fields}
                                        readonly={!canChange}
                                    />

                                    <BankDetailsEmailField schema={schema} fields={fields} readonly={!canChange} />

                                    <BankDetailsForm
                                        fields={fields}
                                        schema={schema}
                                        commentRef={this.descriptionAreaRef}
                                        readonly={!canChange}
                                    />
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    {canChange && (
                                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                            <StandardFormButtons
                                                {...formProps}
                                                isRemoved={false}
                                                what={`реквизиты ${bankDetails.name}`}
                                                isNewlyCreated={bankDetails.isNewlyCreated}
                                                onRemove={remove}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

export const BankDetailsDetails = observer(BankDetailsDetailsBase);

interface ProjectPortfolioDetailsProps {
    bankDetails: CompanyBankDetailsType;
    onSave: (values: CompanyBankDetailsSnapshotType) => Promise<boolean>;
    onSaved: () => void;
    onRemove: () => Promise<boolean>;
    canChange: boolean;
}

function getFormValues(bankDetails: CompanyBankDetailsType) {
    return getSnapshot(bankDetails);
}
