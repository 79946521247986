import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import {
  ScheduleRow,
  ScheduleRowSnapshotType,
  ScheduleUnit,
  initialState as emptyUnit,
  fields,
  ScheduleUnitType,
  ScheduleUnitSnapshotType,
} from "./schedule-unit";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { treat as treatUser } from "modules/spending/employee/models/employer";
import { groupBy, uniq, differenceWith, isEqual } from "lodash";
import { texts } from "modules/common/texts";
import moment from "moment";
import { eat, isNotNull, nameof } from "modules/common/services/typescript";
import { getFormValues } from "../components/ScheduleUserRow";
import { base64ToBlob } from "modules/common/services/files";
import {
  EmployerDictionaryItem,
  EmployerDictionaryItemType,
} from "modules/spending/employee/models/employee-dictionary";
import { buildCacheStorage } from "modules/common/services/cache";
import { SectionCollapser } from "modules/common/models/section-collapser";
import { Queryable } from "modules/common/models/queryable";
import { Constants } from "modules/root/models/constants";
import { saveAs } from "file-saver";
import { Period } from "modules/common/models/period";
import { DictionaryLink } from "modules/common/models/dictionary-link";

export const title = "Расписание";
const NAME = "ScheduleStore";
const cache = buildCacheStorage(NAME);
const zoomKey = () => nameof((s: ScheduleStoreSnapshotType) => s.zoom) as string;

let continuation: Function | null = null;

const EmployerPercent = types
  .model({
    employer: types.maybeNull(DictionaryLink),
    oldPercent: types.number,
    newPercent: types.number,
  })
  .named("EmployerPercent");

const ScheduleEmployerPercent = types
  .model({
    id: types.string,
    oldYear: types.number,
    newYear: types.number,
    oldMonth: types.number,
    newMonth: types.number,
    employers: types.array(EmployerPercent),
  })
  .named("ScheduleEmployerPercent");

export type ScheduleEmployerPercentType = typeof ScheduleEmployerPercent.Type;
export type ScheduleEmployerPercentSnapshotType = typeof ScheduleEmployerPercent.SnapshotType;

export const ScheduleStore = types
  .compose(
    Transport,
    Notificator,
    Queryable,
    Period,
    types.model({
      workDaysCount: types.number,
      monthsWorkHours: types.number,
      ndflTaxePercent: types.number,
      socialTaxePercent: types.number,
      bonusTaxePercent: types.number,
      rows: types.array(ScheduleRow),
      employeeDictionary: types.array(EmployerDictionaryItem),
      selectedId: types.optional(types.string, ""),
      selectedUnit: types.optional(ScheduleUnit, emptyUnit(title)),
      loader: types.optional(types.number, 0),
      zoom: types.number,
      collapser: SectionCollapser,
      percents: types.array(ScheduleEmployerPercent),
    })
  )
  .views((self) => ({
    get usersLength() {
      return self.rows.length;
    },
  }))
  .actions((self) => ({
    setZoom: (value: number) => {
      cache.set(zoomKey(), value);
      self.zoom = value;
    },
  }))
  .actions((self) => {
    const updateMe = (data: any) =>
      applySnapshot(self, {
        ...data,
        collapser: getSnapshot(self.collapser),
        workDaysCount: data.monthWorkDayCount,
        query: self.query,
        pureQuery: self.pureQuery,
        percents: getSnapshot(self.percents),
        zoom: self.zoom,
      });

    return {
      setPeriod: (year: number, month: number) => {
        self.year = year;
        self.month = month;
      },
      loadPercent: flow(function* (year: number | null = null, month: number | null = null) {
        try {
          const percents: ScheduleEmployerPercentSnapshotType[] = yield self.transport.get<any>(
            apiUrls.schedule.percents,
            {
              params: { year: year || self.year, month: month || self.month },
            }
          );
          if (percents) {
            applySnapshot(self.percents, percents);
          }

          return true;
        } catch (er) {
          self.notify.error(er);
          return false;
        }
      }),
      load: flow(function* (year: number | null = null, month: number | null = null) {
        self.loader++;

        try {
          const data: any = yield self.transport.get<any>(apiUrls.schedule.list, {
            params: { year: year || self.year, month: month || self.month },
          });

          if (data === null) {
            self.loader--;
          } else {
            updateMe({
              ...data,
              rows: treat(data.rows),
              loader: self.loader - 1,
            });
          }

          groupBy(data.rows, (row) => self.collapser.set(row.user?.department.name || "", true));

          return true;
        } catch (er) {
          self.notify.error(er);

          applySnapshot(self, {
            ...initialState(),
            month: month || self.month,
            year: year || self.year,
            loader: self.loader - 1,
            query: "",
            pureQuery: "",
            zoom: self.zoom,
          });

          return false;
        }
      }),

      save: flow(function* (id: string, values: any) {
        try {
          continuation = eat;
          yield self.transport.post<any>(apiUrls.schedule.save(id), {
            ...prepareValues(values),
            year: self.year,
            month: self.month,
          });

          self.notify.success(texts.messages.saved);
          return true;
        } catch (er) {
          continuation = null;
          self.notify.error(er);
          return false;
        }
      }),

      remove: flow(function* (ids: string[]) {
        try {
          const { month, year } = self;

          continuation = eat;
          yield self.transport.delete<any>(apiUrls.schedule.delete, {
            data: { ids, month, year },
          });

          self.notify.success(texts.messages.removed);
          return true;
        } catch (er) {
          continuation = null;
          self.notify.error(er);
          return false;
        }
      }),

      selectUnit: (unit: ScheduleUnitType | null = null) => {
        self.selectedId = unit ? unit.id : "";
        self.selectedUnit = ScheduleUnit.create(unit ? getSnapshot(unit) : emptyUnit(title));
      },

      recalculate: flow(function* () {
        try {
          const { month, year } = self;

          continuation = () => {
            self.notify.success("Перерасчет успешно завершен");
          };

          yield self.transport.post<any>(apiUrls.schedule.recalculate, {
            month,
            year,
          });

          self.notify.success("Запрос на перерасчет отправлен");
          return true;
        } catch (er) {
          continuation = null;
          self.notify.error(er);
          return false;
        }
      }),
    };
  })
  .actions((self) => ({
    onCalculationCompleted: flow(function* ({ type, selectors }: ICalculationCompletedParams) {
      if (!continuation) {
        return;
      }

      if (type === "ScheduleSummaryRowCalculation" && selectors.Period === self.periodName) {
        const copy = continuation;
        continuation = null;

        yield self.load();

        copy();
      }
    }),
  }))
  .actions((self) => {
    const fillInternal = async (month: number, year: number) => {
      try {
        continuation = null;
        const data = await self.transport.post<any>(apiUrls.schedule.fill, {
          target: { month: self.month, year: self.year },
          source: { month, year },
        });
        applySnapshot(self.rows, treat(data.rows));
        self.loadPercent();
        self.notify.success(texts.messages.saved);
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    };

    return {
      fromNext: flow(function* () {
        const date = moment([self.year, self.month - 1, 1]).add(1, "month");
        if (date.isValid()) {
          yield fillInternal(date.month() + 1, date.year());
        }
      }),

      fromPrevious: flow(function* () {
        const date = moment([self.year, self.month - 1, 1]).subtract(1, "month");
        if (date.isValid()) {
          yield fillInternal(date.month() + 1, date.year());
        }
      }),
    };
  })
  .actions((self) => ({
    uploadCalendar: flow(function* (xml: string) {
      try {
        const year: number = yield self.transport.post<any>(apiUrls.schedule.calendar, {
          xml,
        });

        if (year === self.year) {
          self.load();
        }

        self.notify.success(texts.messages.saved);
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
  }))
  .views((self) => ({
    get filteredRows() {
      return self.rows.filter((r) => {
        const user = r.user
          ? `${r.user.name.toLowerCase()} ${r.user.department?.name} ${r.user.employerPosition?.name}`.toLowerCase()
          : "";

        return user.includes(self.pureQuery);
      });
    },

    get transform() {
      return +(self.zoom / 100).toFixed(2);
    },

    get workHoursCount() {
      return self.workDaysCount * Constants.workDayHours;
    },

    get bonusTaxeHidden() {
      return self.bonusTaxePercent === 0;
    },
  }))
  .views((self) => ({
    get departmentMap() {
      return groupBy(self.filteredRows, (row) => {
        return row.user ? row.user.department.name : "";
      });
    },

    get employeeOptions() {
      const used = self.rows
        .map((row) => row.user)
        .filter(isNotNull)
        .map((row) => row.id);

      const predicate = (e: EmployerDictionaryItemType) => !used.includes(e.id);

      return self.employeeDictionary.filter(predicate);
    },

    get isEmpty() {
      return self.rows.length === 0;
    },

    get isLoading() {
      return self.loader > 0;
    },
  }))
  .views((self) => ({
    get departmentTotals() {
      const departments = uniq(self.filteredRows.map((row) => (row.user ? row.user.department.name : "")));
      const result: TStringMap<ScheduleUnitSnapshotType[]> = {};

      departments.forEach((department) => {
        const users = self.departmentMap[department];
        const plan = emptyUnit();
        const actual = emptyUnit();

        users.forEach((user) => sum(plan, user.plan));
        users.forEach((user) => sum(actual, user.actual));

        result[department] = [plan, actual];
      });

      return result;
    },
  }))
  .views((self) => ({
    get totals() {
      const plan = emptyUnit();
      const actual = emptyUnit();
      Object.values(self.departmentTotals).forEach((department) => {
        const [p, a] = department;
        sum(plan, p);
        sum(actual, a);
      });

      return [plan, actual];
    },
  }))
  .actions((self) => ({
    addUnit: flow(function* () {
      const unit = emptyUnit(title);
      const oldIds = self.rows.map((row) => row.plan.id);

      try {
        continuation = function (old: string[]) {
          const newIds = self.rows.map((row) => row.plan.id);
          const diff = differenceWith(newIds, old, isEqual);

          if (diff.length > 0) {
            const src = self.rows.find((row) => row.plan.id === diff[0]);
            if (src) {
              self.selectedId = src.plan.id;
              self.selectedUnit = ScheduleUnit.create(getSnapshot(src.plan));
            }
          }
        }.bind(null, oldIds);

        yield self.transport.post<any>(apiUrls.schedule.save(unit.id), {
          ...prepareValues(getFormValues(unit)),
          year: self.year,
          month: self.month,
        });

        return true;
      } catch (er) {
        continuation = null;
        self.notify.error(er);
        return false;
      }
    }),

    export: flow(function* (print: boolean) {
      self.loader++;

      try {
        const blocks = Object.keys(self.departmentMap).map((departmentName) => ({
          name: departmentName,
          users: self.departmentMap[departmentName].map((user) => user.toExportView()),
          peoples: self.departmentMap[departmentName].length,
        }));

        const convertTo = print ? "pdf" : "xlsx";

        const [plan, actual] = self.totals;

        var file: FileDescription = yield self.transport.post<any>(apiUrls.schedule.print, {
          variables: JSON.stringify({
            blocks,
            employeeCount: self.filteredRows.length,
            daysCount: self.workDaysCount,
            hoursCount: self.workHoursCount,
            totals: { plan, actual },
          }),
          templateId: "ExportScheduleTab",
          convertTo,
        });

        const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
        if (print) {
          const fileURL = URL.createObjectURL(blob);

          const viewer = window.open(fileURL, "_blank", "");
          if (viewer) {
            viewer.onclose = () => URL.revokeObjectURL(fileURL);
          }
        } else {
          file.name = `Штатное расписание ${self.periodName}.${convertTo}`;
          saveAs(blob, file.name);
        }
      } catch (er) {
        self.notify.error(er);
        return false;
      } finally {
        self.loader--;
      }
    }),
  }))
  .named(NAME);

export type ScheduleStoreType = typeof ScheduleStore.Type;
export type ScheduleStoreSnapshotType = typeof ScheduleStore.SnapshotType;

export const initialState = (): ScheduleStoreSnapshotType => {
  const now = new Date();
  const unit = emptyUnit(title);
  const zoom = cache.get(zoomKey(), 100);

  return {
    bonusTaxePercent: 0,
    employeeDictionary: [],
    ndflTaxePercent: 0,
    rows: [],
    socialTaxePercent: 0,
    workDaysCount: 0,
    monthsWorkHours: 0,
    month: now.getMonth() + 1,
    year: now.getFullYear(),
    selectedId: "",
    query: "",
    pureQuery: "",
    collapser: { opened: {} },
    selectedUnit: unit,
    loader: 0,
    zoom,
    percents: [],
  };
};

function treat(data: ScheduleRowSnapshotType[]) {
  return data.map((row) => ({
    ...row,
    user: row.user ? treatUser(row.user) : null,
  }));
}

function prepareValues(values: TStringMap<any>) {
  const notNumber = [fields.employerId, fields.bonusCorrectionComment];
  const result: TStringMap<any> = {};

  Object.keys(values).forEach((key) => {
    result[key] = notNumber.includes(key) ? values[key] : values[key] || 0;
  });

  return result;
}

function sum(target: ScheduleUnitSnapshotType, source: ScheduleUnitSnapshotType) {
  target.bonus += source.bonus;
  target.bonusTaxe += source.bonusTaxe;
  target.bonusGrade += source.bonusGrade;
  target.bonusWorkedOut += source.bonusWorkedOut;
  target.bonusWorkedOutGrade += source.bonusWorkedOutGrade;
  target.socialTaxe += source.socialTaxe;
  target.companySpending += source.companySpending;
  target.companySpendingPerHour += source.companySpendingPerHour;
  target.decrease += source.decrease;
  target.finalSalary += source.finalSalary;
  target.increase += source.increase;
  target.overtime += source.overtime;
  target.profit += source.profit;
  target.perHourRate += source.perHourRate;
  target.salary += source.salary;
  target.totalSalary += source.totalSalary;
  target.illnessBonus += source.illnessBonus;
  target.illness += source.illness;
  target.vacation += source.vacation;
  target.vacationBonus += source.vacationBonus;
  target.vacationSalary += source.vacationSalary;
  target.ndflTaxe += source.ndflTaxe;
}
