import { types } from 'mobx-state-tree';
import { OrderTypeList, OrderType, initialState as emptyList } from './order-type-list';
import { initialState as emptyOrderType } from './order-type';
import { Transport } from 'modules/common/models/transport';
import { Notificator } from 'modules/common/models/notificator';

export const OrderTypesStore = types.compose(
    Transport, Notificator, types.model({
        list: OrderTypeList,
        details: OrderType
    }))
    .named('OrderTypesStore');

export const initialState = (): typeof OrderTypesStore.SnapshotType => ({
    list: emptyList(),
    details: emptyOrderType()
});

export type OrderTypesStoreType = typeof OrderTypesStore.Type;
