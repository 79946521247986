import { types, applySnapshot, isAlive } from "mobx-state-tree";
import { BaseEntity, isNewlyCreated, DictionaryItem } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID, DATE_TIME_FORMAT } from "modules/common/constants";
import Schema from "../components/details/validation";
import moment from "moment";
import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";
import { getFieldLabel } from "modules/common/services/form/fields";

export const IndicatorDictionaryItem = types
    .compose(
        DictionaryItem,
        types.model({
            description: types.string,
        })
    )
    .named("IndicatorDictionaryItem");

const IndicatorBase = types.compose(
    Transport,
    Notificator,
    BaseEntity,
    types.model({
        label: types.string,
    })
);

export const Indicator = IndicatorBase.actions((self) => ({
    load: flow(function* (id: string) {
        try {
            const snapshot = isNewlyCreated(id)
                ? initialState()
                : yield self.transport.get<IndicatorSnapshotType>(apiUrls.indicators.details(id));

            applySnapshot(self, snapshot);
        } catch (er) {
            self.notify.error(er);
        }
    }),

    save: flow(function* (model: IndicatorSnapshotType) {
        try {
            const snapshot = self.isNewlyCreated
                ? yield self.transport.put<IndicatorSnapshotType>(apiUrls.indicators.create(), model)
                : yield self.transport.post<IndicatorSnapshotType>(apiUrls.indicators.update(self.id), model);

            isAlive(self) && applySnapshot(self, snapshot);
            self.notify.success(texts.messages.saved);

            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    delete: flow(function* () {
        if (self.isNewlyCreated) {
            return true;
        }

        try {
            yield self.transport.delete<boolean>(apiUrls.indicators.delete(self.id));
            self.notify.success(texts.messages.removed);

            isAlive(self) && applySnapshot(self, initialState());
            return true;
        } catch (er) {
            self.notify.error(er);
            return false;
        }
    }),
})).named("Indicator");

export type IndicatorSnapshotType = typeof IndicatorBase.SnapshotType;
export type IndicatorType = typeof Indicator.Type;
export type IndicatorDictionaryItemType = typeof IndicatorDictionaryItem.Type;

export const initialState = (): IndicatorSnapshotType => ({
    id: EMPTY_OBJECT_ID,
    label: "",
    created: moment().format(DATE_TIME_FORMAT),
});

export const fields = {
    label: nameof((a: IndicatorType) => a.label) as string,
};

export function formatIndicator(type: IndicatorSnapshotType | null) {
    let result = "";

    if (type) {
        const schema = Schema();

        result += `${getFieldLabel(fields.label, schema, null)}: ${type.label}\n`;
    }

    return result;
}
