import * as yup from "yup";
import { fields } from "../../models/department";
import { texts } from "modules/common/texts";
const { object, string, number } = yup;

export const departmentLabels = () => ({
    [fields.name]: texts.name,
    [fields.description]: texts.comment,
    [fields.order]: "Порядковый №",
});

const DepartmentSchema = () => {
    const labels = departmentLabels();

    return object().shape({
        [fields.name]: string().label(labels[fields.name]).required(),

        [fields.description]: string().label(labels[fields.description]).notRequired(),

        [fields.order]: number().label(labels[fields.order]).notRequired().min(1),
    });
};

export default DepartmentSchema;
