import React from "react";
import styles from "./TimesheetCell.module.scss";
import classnames from "classnames";
import { Classes, Icon, Popover, TextArea } from "@blueprintjs/core";
import { Constants } from "modules/root/models/constants";
import { hex2rgba } from "modules/common/services/colors";
import { observer } from "mobx-react";
import { toString } from "modules/common/services/strings";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { WorkloadDayType } from "modules/spending/workload/models/workload-day";
import { isNotNull } from "modules/common/services/typescript";

const empty = <span>&nbsp;</span>;

export const OvertimeCell = ({ state }: { state: number }) => {
  const className = classnames({
    "print-only": true,
    "timesheet-cell": true,
    [styles.cell]: true,
    [styles.overtime]: true,
  });

  const text = state === 0 ? "" : toString(state);

  return <div className={className}>{text}</div>;
};

export const TimesheetCell = observer(
  class extends React.Component<TimesheetCellProps, TimesheetCellState> {
    otherTypes = Constants.otherHoursTypes.map((type) => type.name);

    constructor(props: TimesheetCellProps) {
      super(props);
      this.state = {
        comment: "",
        open: false,
        isMobile: false,
      };
    }

    componentDidMount() {
      if (this.props.day.comment) {
        this.setState({ comment: this.props.day.comment });
      }
      if (window.innerWidth < 885) {
        this.setState({ isMobile: true });
      } else {
        this.setState({ isMobile: false });
      }
    }

    render() {
      const {
        preToday,
        day,
        selectedCell,
        mode,
        locked,
        canComment,
        sessionId,
        showMinutes,
        selectedCells,
        cells,
        onContext,
        light,
      } = this.props;
      const { isDayOff, isDayWorkable, displayHours, minimalHours, workedOutHours, isToday, missingType, displayType } =
        day;
      const isMe = sessionId === day.user.id;
      const handlePopupClose = () => {
        const { day: cellDay, changeComment } = this.props;
        const model = {
          comment: cellDay.comment,
          day: cellDay.day,
          employerId: cellDay.user.id,
        };

        changeComment(model);

        this.setState({ open: false });
      };

      const handlePopupClear = () => {
        const { day: cellDay, changeComment } = this.props;
        cellDay.setComment("");
        const model = {
          comment: cellDay.comment,
          day: cellDay.day,
          employerId: cellDay.user.id,
        };
        changeComment(model);

        this.setState({ open: false });
      };

      // const isSelected = selectedCell.includes(day.day) && selectedCell.includes(day.user.id);
      let isSelected = selectedCell.includes(day.day) && selectedCell.includes(day.user.id);
      const itemClassName = "mouse-select__selectable";
      selectedCells &&
        selectedCells.forEach((cell) => {
          if (cell.includes(day.day) && cell.includes(day.user.id)) {
            isSelected = true;
          }
        });

      const type = displayType ? displayType.name : "";

      const isSuccess = workedOutHours > 0 ? (isDayOff ? true : workedOutHours >= minimalHours) : false;
      const halfSuccess = workedOutHours > 0 && (!missingType || missingType === Constants.withoutPayHoursType.name);
      const isMissing = workedOutHours <= 0 && type === Constants.withoutPayHoursType.name;
      const isVacation = type === Constants.vacationHoursType.name || type === Constants.workedVacationHoursType.name;
      const isIllnes = type === Constants.illnesHoursType.name || type === Constants.workedIllnesHoursType.name;
      const isOther = !isVacation && !isIllnes && this.otherTypes.includes(type);
      let hoursPlan = 0;

      day.plan.forEach((unit) => (hoursPlan += unit.hours));
      const className = classnames({
        "timesheet-cell": true,
        [styles.cell]: true,
        [`${styles.today} is-today`]: isToday(),
        [styles.preToday]: preToday,
        selected: isSelected,
      });

      const overlay = classnames({
        [styles.overlay]: true,
        [styles.selected]: isSelected,
        [styles.today]: isToday(),
        [styles.hoursMode]: mode === "hours",
        [styles.weekend]: isDayOff,
        [styles.success]: isSuccess,
        [styles.halfSuccess]: halfSuccess,
        [styles.missing]: isMissing,
        [styles.vacation]: isVacation,
        [styles.illness]: isIllnes,
        [styles.other]: isOther,
      });
      let displayHour = "";
      let smallSize = false;
      if (showMinutes) {
        if (displayHours) {
          const dec = displayHours - Math.floor(displayHours);
          let mins = 0;
          if (dec > 0) {
            mins = 60 * dec;
          }

          if (mins > 0) {
            smallSize = true;
            displayHour = `${Math.floor(displayHours)}.${Math.round(mins)}`;
          } else displayHour = `${displayHours}`;
        }
      } else {
        displayHour = `${Math.round(displayHours)}`;
      }

      return (
        <div
          key={`${day.day}_${day.user.id}`}
          data-id={`${day.day}_${day.user.id}`}
          className={classnames(className, itemClassName, { [styles.light]: light && day.day === light })}
          onDoubleClick={this.onChangeStart}
          onClick={this.state.isMobile ? this.onChangeStart : this.onSelect}
          onContextMenu={(e) => {
            e.preventDefault();

            this.onSelect();
            onContext(
              e.clientX + e.currentTarget.getBoundingClientRect().left - 600,
              e.currentTarget.getBoundingClientRect().top - 40,
              day
            );
          }}
          // onMouseDown={this.onSelect}
          id={`${day.day}_${day.user.id}`}
          data-object-id={day.day}
        >
          {isDayWorkable && (
            <>
              <div className={styles.short}>
                {displayType ? (
                  displayType.shortName
                ) : !displayType && hoursPlan ? (
                  <GeneralIcon type="general-info" style={{ margin: "4px 0px 0px 2px" }} size={14} />
                ) : (
                  empty
                )}
              </div>
              <div className={`${styles.short} ${styles.hours} ${smallSize ? styles.small : null}`}>
                {displayHours ? displayHour : hoursPlan && !displayHours ? hoursPlan : empty}
              </div>
              <div className={styles.project}>{this.renderProject(day)}</div>
            </>
          )}
          {!isDayWorkable && <span>&nbsp;</span>}
          <div className={overlay} style={this.overlayStyle()}>
            {locked && <Icon icon="lock" />}
          </div>
          {isSelected &&
            !day.isCommented &&
            canComment &&
            selectedCells &&
            selectedCells?.length <= 1 &&
            cells.length <= 1 && (
              <div className={styles.popoverButton}>
                <Popover
                  isOpen={this.state.open}
                  usePortal={true}
                  position={"top"}
                  onInteraction={(state) => {
                    this.setState({ open: state });
                  }}
                  interactionKind={"click"}
                  content={
                    <div style={{ display: "flex", padding: "10px" }}>
                      <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                        <TextArea
                          growVertically={true}
                          rows={1}
                          autoComplete="off"
                          placeholder="Комментарий"
                          data-lpignore="true"
                          value={day.comment}
                          className={`${Classes.FILL} planr-default-input`}
                          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                            day.setComment(e.target.value);
                          }}
                        />
                      </div>
                      <GeneralIcon
                        type="general-redo"
                        onClick={handlePopupClose}
                        style={{
                          color: "#1DD278",
                          cursor: "pointer",
                          marginLeft: "10px",
                          marginTop: "10px",
                        }}
                      />
                      <GeneralIcon
                        type="general-trash"
                        onClick={handlePopupClear}
                        style={{
                          color: "#E31818",
                          cursor: "pointer",
                          marginLeft: "10px",
                          marginTop: "10px",
                        }}
                      />
                    </div>
                  }
                >
                  <GeneralIcon
                    type="general-plus-small"
                    onClick={() => {
                      this.setState({ open: true });
                    }}
                  />
                </Popover>
              </div>
            )}
          {day.isCommented && canComment && (
            <div className={styles.popoverButtonCommented}>
              <Popover
                isOpen={this.state.open}
                usePortal={true}
                position={"top"}
                onInteraction={(state) => {
                  this.setState({ open: state });
                }}
                interactionKind={"click"}
                content={
                  <div style={{ display: "flex", padding: "10px" }}>
                    <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                      <TextArea
                        growVertically={true}
                        rows={1}
                        autoComplete="off"
                        placeholder="Комментарий"
                        data-lpignore="true"
                        value={day.comment}
                        className={`${Classes.FILL} planr-default-input`}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                          day.setComment(e.target.value);
                        }}
                      />
                    </div>
                    <GeneralIcon
                      type="general-redo"
                      onClick={handlePopupClose}
                      style={{
                        color: "#1DD278",
                        cursor: "pointer",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                    />
                    <GeneralIcon
                      type="general-trash"
                      onClick={handlePopupClear}
                      style={{
                        color: "#E31818",
                        cursor: "pointer",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                    />
                  </div>
                }
              >
                <div
                  className={styles.isCommentedTag}
                  onClick={() => {
                    this.setState({ open: true });
                  }}
                ></div>
              </Popover>
            </div>
          )}
          {isSelected &&
            !day.isCommented &&
            isMe &&
            selectedCells &&
            selectedCells?.length <= 1 &&
            cells.length <= 1 && (
              <div className={styles.popoverButton}>
                <Popover
                  isOpen={this.state.open}
                  usePortal={true}
                  position={"top"}
                  onInteraction={(state) => {
                    this.setState({ open: state });
                  }}
                  interactionKind={"click"}
                  content={
                    <div style={{ display: "flex", padding: "10px" }}>
                      <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                        <TextArea
                          growVertically={true}
                          rows={1}
                          autoComplete="off"
                          placeholder="Комментарий"
                          data-lpignore="true"
                          value={day.comment}
                          className={`${Classes.FILL} planr-default-input`}
                          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                            day.setComment(e.target.value);
                          }}
                        />
                      </div>
                      <GeneralIcon
                        type="general-redo"
                        onClick={handlePopupClose}
                        style={{
                          color: "#1DD278",
                          cursor: "pointer",
                          marginLeft: "10px",
                          marginTop: "10px",
                        }}
                      />
                      <GeneralIcon
                        type="general-trash"
                        onClick={handlePopupClear}
                        style={{
                          color: "#E31818",
                          cursor: "pointer",
                          marginLeft: "10px",
                          marginTop: "10px",
                        }}
                      />
                    </div>
                  }
                >
                  <GeneralIcon
                    type="general-plus-small"
                    onClick={() => {
                      this.setState({ open: true });
                    }}
                  />
                </Popover>
              </div>
            )}
          {day.isCommented && isMe && (
            <div className={styles.popoverButtonCommented}>
              <Popover
                isOpen={this.state.open}
                usePortal={true}
                position={"top"}
                onInteraction={(state) => {
                  this.setState({ open: state });
                }}
                interactionKind={"click"}
                content={
                  <div style={{ display: "flex", padding: "10px" }}>
                    <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                      <TextArea
                        growVertically={true}
                        rows={1}
                        autoComplete="off"
                        placeholder="Комментарий"
                        data-lpignore="true"
                        value={day.comment}
                        className={`${Classes.FILL} planr-default-input`}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                          day.setComment(e.target.value);
                        }}
                      />
                    </div>
                    <GeneralIcon
                      type="general-redo"
                      onClick={handlePopupClose}
                      style={{
                        color: "#1DD278",
                        cursor: "pointer",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                    />
                    <GeneralIcon
                      type="general-trash"
                      onClick={handlePopupClear}
                      style={{
                        color: "#E31818",
                        cursor: "pointer",
                        marginLeft: "10px",
                        marginTop: "10px",
                      }}
                    />
                  </div>
                }
              >
                <div
                  className={styles.isCommentedTag}
                  onClick={() => {
                    this.setState({ open: true });
                  }}
                ></div>
              </Popover>
            </div>
          )}
        </div>
      );
    }

    overlayStyle = (): React.CSSProperties => {
      const { mode, day } = this.props;

      const orders = day.units.map((unit) => unit.order).filter(isNotNull);

      const plan = day.plan.map((unit) => unit.order).filter(isNotNull);

      const alpha = 0.4;
      if (mode === "hours") {
        if (orders.length === 0 && day.units.length === 0) {
          if (plan.length > 1) {
            return {
              backgroundColor: hex2rgba("#E4E8EB", 0.4),
            };
          }
          if (plan.length === 1) {
            return {
              backgroundColor: hex2rgba("#E4E8EB", 0.4),
            };
          }
        }
        return {};
      }

      if (orders.length > 0) {
        if (orders.length > 1) {
          return {
            backgroundColor: hex2rgba("#ffff00", alpha),
          };
        }
        if (orders.length === 1) {
          return {
            backgroundColor: hex2rgba(orders[0].color, alpha),
          };
        }
      } else {
        if (day.units.length === 0) {
          if (plan.length > 1) {
            return {
              backgroundColor: hex2rgba("#E4E8EB", alpha),
            };
          }
          if (plan.length === 1) {
            return {
              backgroundColor: hex2rgba("#E4E8EB", alpha),
            };
          }
        }
      }

      return {};
    };

    renderProject = (day: WorkloadDayType) => {
      const { projects, plan, units } = day;
      if (projects.length) {
        if (projects.length > 1) {
          return (
            <div className="flex-center gray-color">
              <GeneralIcon type="general-shuffle" />
              <span style={{ marginLeft: "3px" }}>{projects.length}</span>
            </div>
          );
        }

        if (projects.length === 1) {
          return <span className="gray-color">{projects[0]}</span>;
        }
      }

      if (units.length === 0) {
        if (plan.length > 1) {
          return (
            <div className="flex-center gray-color">
              <GeneralIcon type="general-shuffle" />
              <span style={{ marginLeft: "3px" }}>{plan.length}</span>
            </div>
          );
        }

        if (plan.length === 1) {
          return <span className="gray-color">{plan[0].order?.fullInventoryNumber}</span>;
        }
      }

      return empty;
    };

    onSelect = () => {
      const { day, onSelectCel } = this.props;
      onSelectCel(`${day.day}_${day.user.id}`);
    };

    onChangeStart = () => {
      this.onSelect();

      const { locked, onEdit } = this.props;
      !locked && onEdit(this.props.day);
    };
  }
);

export interface DaySelector {
  selectedCell: string;
  onSelectCell: (cell: string) => void;
  canComment?: boolean;
  changeComment: (model: any) => void;
  sessionId: string;
}
// day: WorkloadDayType;
// preToday: boolean;
// onEdit: (day: WorkloadDayType) => void;
// mode: WorkloadCellMode;
// locked: boolean;
// selectedCells: string[];
// cells: string[];
// selectedCell: string;
// onSelectCel: (cell: string) => void;
// orders: OrderDictionaryType;
// onContext: (x: number, y: number, day: WorkloadDayType) => void;
// nonProduction: boolean;
// changeComment: (model: any) => void;
export type TimesheetCellMode = "hours" | "projects";

export interface TimesheetCellProps {
  day: WorkloadDayType;
  preToday: boolean;
  onEdit: (day: WorkloadDayType) => void;
  mode: TimesheetCellMode;
  locked: boolean;
  selectedCells: string[] | undefined;
  cells: string[];
  selectedCell: string;
  onSelectCel: (cell: string) => void;
  showMinutes: boolean;
  changeComment: (model: any) => void;
  //onSelectCell: (cell: string) => void;
  onContext: (x: number, y: number, day: WorkloadDayType) => void;
  canComment?: boolean;
  light?: string;
  sessionId: string;
}

interface TimesheetCellState {
  comment: string;
  open: boolean;
  isMobile: boolean;
}
