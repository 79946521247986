import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { ProductionStage, ProductionStageType, ProductionStageSnapshotType, fields } from "./production-stage";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
// import { getIn } from "formik";
// import { orderBy } from "lodash";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { Queryable } from "modules/common/models/queryable";

export const ProductionStageList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            productionStages: types.array(ProductionStage),
            sorter: TableSorter,
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<ProductionStageType> {
            return self.productionStages.reduce((result, productionStage) => {
                result[productionStage.id] = productionStage;
                return result;
            }, {} as TStringMap<ProductionStageType>);
        },

        get data() {
            const predicate = (o: ProductionStageType) => {
                let result = true;
                if (self.pureQuery) {
                    result = result && `${o.name} ${o.comment} `.toLowerCase().indexOf(self.pureQuery) >= 0;
                }
                return result;
            };
            return self.productionStages.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));
            // const order = self.sorter.asc ? 'asc' : 'desc';

            // return orderBy(
            //     getSnapshot(self.productionStages),
            //     [(o) => getIn(o, self.sorter.column)],
            //     [order]
            // );
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: any = yield self.transport.get<ProductionStageSnapshotType[]>(
                    apiUrls.productionStages.list()
                );
                applySnapshot(self.productionStages, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        delete: flow(function* (productionStage: ProductionStageSnapshotType) {
            const model = ProductionStage.create(productionStage, {
                http: self.transport,
                notificator: self.notify,
            });
            return yield model.delete();
        }),
    }))
    .named("ProductionStageList");

export type ProductionStagesListType = typeof ProductionStageList.Type;
export type ProductionStageListSnapshotType = typeof ProductionStageList.SnapshotType;
export { ProductionStage };

const sortStorage = getSortOption(ProductionStageList.name);

export const initialState = (): ProductionStageListSnapshotType => {
    const options = sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        productionStages: [],
        pureQuery: "",
        query: "",
        sorter: {
            id: ProductionStageList.name,
            tableName: ProductionStageList.name,
            column: options.column,
            asc: options.asc,
        },
    };
};
