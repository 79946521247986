import React, { useState, useCallback } from 'react';
import { getColumnSize } from 'modules/common/services/table/size-storage';
import { TokenSnapshotType, fields } from '../../models/token';
import styles from './TokensList.module.scss';
import { Switch } from '@blueprintjs/core';
import { EmployerName } from 'modules/common/components/employer/EmployerName';
import { ColDef, ICellRendererParams } from '@ag-grid-community/core';

function ConfirmedInput ({ context, data }: ICellRendererParams) {
    const [isChecked, setIsChecked] = useState(data[fields.confirmed]);

    const handler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.currentTarget.checked;
        const actions = context as TokenActions;
        const row = data as TokenSnapshotType;

        setIsChecked(checked);
        actions.onToggle(row, checked);
    }, [context, data]);

    return (
        <Switch
            checked={isChecked}
            large={false}
            onChange={handler}
        />
    );
}

export const columns = (tableName: string) => {
    const columnSize = getColumnSize(tableName);

    const result: ColDef[] = [
        {
            headerName: 'Дата и время',
            field: fields.creationDate,
            width: columnSize(fields.creationDate, 140),
            sortable: true,
            resizable: false,
            comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                const dateA = (nodeA.data as TokenSnapshotType).createdSortable;
                const dateB = (nodeB.data as TokenSnapshotType).createdSortable;
                return dateA.localeCompare(dateB);
            }
        },
        {
            headerName: 'Сотрудник',
            field: fields.employer,
            width: columnSize(fields.employer, 200),
            sortable: true,
            resizable: true,
            cellRendererFramework: ({ data }: ICellRendererParams) => {
                const token = data as TokenSnapshotType;
                return (
                    <EmployerName name={token.employer.name} position={token.employer.position} />
                );
            }
        },
        {
            headerName: 'IP',
            field: fields.ipAddress,
            width: columnSize(fields.ipAddress, 270),
            sortable: true,
            resizable: true,
            cellClass: 'ip-address',
            cellRendererFramework: ({ data }: ICellRendererParams) => {
                const token = data as TokenSnapshotType;
                return (
                    <>
                        {token.ipAddress}
                        <div className={styles.location}>{token.location}</div>
                    </>
                );
            }
        },
        {
            headerName: 'Браузер',
            field: fields.userAgent,
            width: columnSize(fields.userAgent, 170),
            sortable: false,
            resizable: true
        },
        {
            headerName: 'Логин',
            field: fields.login,
            width: columnSize(fields.login, 100),
            sortable: true,
            resizable: true
        },
        {
            headerName: 'Разрешить',
            field: fields.confirmed,
            width: columnSize(fields.confirmed, 100),
            sortable: true,
            resizable: false,
            cellClass: 'centered',
            cellRendererFramework: ConfirmedInput
        }
    ];

    return result;
}

export interface TokenActions {
    onToggle: (row: TokenSnapshotType, state: boolean) => void;
}
