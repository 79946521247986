import { types, applySnapshot } from "mobx-state-tree";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { DictionaryItem, DictionaryItemType } from "modules/common/models/entity";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";

export const OverheadTypeDictionary = types
    .compose(
        Notificator,
        Transport,
        types.model({
            types: types.array(DictionaryItem),
        })
    )
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: DictionaryItemType[] = yield self.transport.get<any>(apiUrls.overheadTypes.dictionary());
                applySnapshot(self.types, data);

                return true;
            } catch (er) {
                applySnapshot(self.types, []);
                return false;
            }
        }),
    }))
    .views((self) => ({
        get isEmpty() {
            return !self.types.length;
        },

        get asMap() {
            return self.types.reduce((result, item) => {
                result[item.id] = item;
                return result;
            }, {} as TStringMap<DictionaryItemType>);
        },
    }))
    .named("OverheadTypeDictionary");

export type OverheadTypeDictionaryType = typeof OverheadTypeDictionary.Type;

export const initialState = (): typeof OverheadTypeDictionary.SnapshotType => ({
    types: [],
});
