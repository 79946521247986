import React from "react";
import { fields as bankFields } from "modules/common/models/bank-details";
import { Schema } from "yup";
import { InputGroup } from "@blueprintjs/core";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";

type FieldsType = ReturnType<typeof bankFields>;

export const DirectorField = ({ fields, ...rest }: BankDetailsFieldsProps) => {
  return <BankDetailsTextField {...rest} name={fields.directorName} />;
};

export const DirectorGenitiveField = ({ fields, ...rest }: BankDetailsFieldsProps) => {
  return <BankDetailsTextField {...rest} name={fields.directorNameGenitive} />;
};

export const BankDetailsCompanyAddressField = ({ fields, ...rest }: BankDetailsFieldsProps) => {
  return <BankDetailsTextField {...rest} name={fields.address} label="Юр. адрес" />;
};

export const BankDetailsEmailField = ({ fields, ...rest }: BankDetailsFieldsProps) => {
  return <BankDetailsTextField {...rest} name="email" label="Email бухгалтерии" />;
};

export const AccountField = ({ fields, ...rest }: BankDetailsFieldsProps) => {
  return <BankDetailsTextField {...rest} name={fields.account} />;
};

export const InnField = ({ fields, ...rest }: BankDetailsFieldsProps) => {
  return <BankDetailsTextField {...rest} name={fields.inn} />;
};

export const BankNameField = ({ fields, ...rest }: BankDetailsFieldsProps) => {
  return <BankDetailsTextField {...rest} name={fields.bankName} />;
};

export const BankIdentificatorField = ({ fields, ...rest }: BankDetailsFieldsProps) => {
  return <BankDetailsTextField {...rest} name={fields.bankIdentificator} />;
};

export const CorrAccountField = ({ fields, ...rest }: BankDetailsFieldsProps) => {
  return <BankDetailsTextField {...rest} name={fields.corrAccount} />;
};

export const BankDetailsFields = (props: BankDetailsFieldsProps) => {
  const { schema, fields, inline, readonly } = props;

  return (
    <>
      {!inline && (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {InnField(props)}
            &nbsp;
            <BankDetailsTextField name={fields.kpp} schema={schema} inline={inline} readonly={readonly} />
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <BankDetailsTextField name={fields.ogrn} schema={schema} inline={inline} readonly={readonly} />
            &nbsp;
            <BankDetailsTextField name={fields.okpo} schema={schema} inline={inline} readonly={readonly} />
          </div>
        </>
      )}

      {inline && (
        <>
          {InnField(props)}
          <BankDetailsTextField name={fields.kpp} schema={schema} inline={inline} readonly={readonly} />
          <BankDetailsTextField name={fields.ogrn} schema={schema} inline={inline} readonly={readonly} />
          <BankDetailsTextField name={fields.okpo} schema={schema} inline={inline} readonly={readonly} />
        </>
      )}

      {AccountField(props)}

      {BankNameField(props)}

      {BankIdentificatorField(props)}

      {CorrAccountField(props)}
    </>
  );
};

interface BankDetailsBaseProps {
  schema: Schema<any>;
  inline?: boolean;
  readonly?: boolean;
  style?: React.CSSProperties;
}

interface BankDetailsFieldsProps extends BankDetailsBaseProps {
  fields: FieldsType;
}

const BankDetailsTextField: React.FC<BankDetailsBaseProps & { name: string; label?: string }> = ({
  schema,
  name,
  label,
  inline,
  readonly,
  style,
}) => {
  return (
    <StandardFormInput
      label={label}
      name={name}
      schema={schema}
      small={true}
      className="planr-form-input"
      inline={inline}
      style={style}
    >
      {({ field }) => (
        <InputGroup id={field.name} {...field} className="planr-default-input" autoComplete="off" disabled={readonly} />
      )}
    </StandardFormInput>
  );
};
