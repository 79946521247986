import React from "react";
import { Formik, Form } from "formik";
import styles from "./StartPage.module.scss";
import StartSchema from "./details/validation";
import { observer } from "mobx-react";
import { fields, StartType } from "./models/start";
import { InputGroup, Classes } from "@blueprintjs/core";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { Buttons } from "modules/common/components/form";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { getSnapshot } from "mobx-state-tree";

const schema = StartSchema();

export class StartPageDetails extends React.PureComponent<StartPageDetailsProps> {
    componentDidMount() {
        this.props.start.load();
    }

    public render() {
        const { start, onSaved } = this.props;

        return (
            <Formik
                initialValues={getFormValues(start)}
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    const successfull = await start.save(values);
                    setSubmitting(false);

                    if (successfull) {
                        resetForm();
                        onSaved(successfull);
                    }
                }}
            >
                {() => {
                    return (
                        <Form>
                            <div className={Classes.DIALOG_BODY}>
                                <div className={styles.columns}>
                                    <div className={styles.column}>
                                        <StandardFormInput
                                            name={fields.companyName}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.shortCompanyName}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    autoComplete="off"
                                                    className="planr-default-input"
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.email}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    autoComplete="off"
                                                    className="planr-default-input"
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.phoneNumber}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.inn}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.kpp}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.ogrn}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.okpo}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.okved}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.account}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.bankName}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.bankIdentificator}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.corrAccount}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.address}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.directorName}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                />
                                            )}
                                        </StandardFormInput>
                                        <StandardFormInput
                                            name={fields.directorNameGenitive}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                />
                                            )}
                                        </StandardFormInput>
                                    </div>
                                </div>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <Buttons>
                                    <PlanrButton
                                        type="greenish"
                                        nativeType="submit"
                                        size="small"
                                        style={{ marginLeft: "570px" }}
                                    >
                                        Сохранить
                                    </PlanrButton>
                                </Buttons>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

function getFormValues(start: StartType) {
    return {
        ...getSnapshot(start),
    };
}
export const StartPage = observer(StartPageDetails);

export interface StartPageDetailsProps {
    start: StartType;
    onSaved: (value: boolean) => void;
}
