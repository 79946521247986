import * as yup from "yup";
import { fields } from "../models/user-timesheet";
const { object, number } = yup;

export const monthLabels = () => ({
    [fields.overtimeScale]: "Коэф. оплаты",
});

const TimesheetMonthSchema = () => {
    const labels = monthLabels();

    return object().shape({
        [fields.overtimeScale]: number()
            .label(labels[fields.overtimeScale])
            .required()
            .min(1)
            /* eslint-disable-next-line */
            .typeError("${path} должен быть числом"),
    });
};

export default TimesheetMonthSchema;
