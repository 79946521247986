import React, { useCallback } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import styles from "./DeprGroupDetails.module.scss";
import DeprGroupSchema from "./depr-group-validation";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { InputGroup, Classes } from "@blueprintjs/core";
import { DeprGroupType, fields } from "modules/dictionaries/inventory/models/depr-group";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { texts } from "modules/common/texts";
import { Prompt } from "react-router";
import { onlyDigitsInputKeyPress } from "modules/common/services/form/values";

const digits = onlyDigitsInputKeyPress();
const schema = DeprGroupSchema();

export const DeprGroupDetails: React.FC<DeprGroupDetailsProps> = observer((props) => {
    const { deprGroup, canChange, onSaved, onRemoved } = props;

    const onSubmit = useCallback(
        async (values: FormValues, form: FormikHelpers<FormValues>) => {
            if (!canChange) {
                return;
            }

            const succsessfull = await deprGroup.save(values);
            form.setSubmitting(false);

            if (succsessfull) {
                form.resetForm();
                onSaved();
            }
        },
        [canChange, onSaved, deprGroup]
    );

    return (
        <div className={styles.details}>
            <Formik<FormValues>
                initialValues={getFormValues(deprGroup)}
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={onSubmit}
            >
                {(formProps) => {
                    const onRemove = async () => {
                        formProps.setSubmitting(true);
                        const success = await deprGroup.delete();
                        formProps.setSubmitting(false);
                        success && onRemoved();
                    };

                    return (
                        <Form autoComplete="off">
                            <Prompt when={formProps.dirty && canChange} message={texts.messages.leaveConfiramtion} />
                            <div className={Classes.DIALOG_BODY}>
                                <StandardFormInput
                                    name={fields.name}
                                    schema={schema}
                                    small={true}
                                    className="planr-form-input"
                                >
                                    {({ field }) => (
                                        <InputGroup
                                            id={field.name}
                                            className="planr-default-input"
                                            {...field}
                                            type="text"
                                            autoComplete="off"
                                            data-lpignore="true"
                                            disabled={!canChange}
                                        />
                                    )}
                                </StandardFormInput>

                                <StandardFormInput
                                    name={fields.minYears}
                                    schema={schema}
                                    small={true}
                                    className="planr-form-input"
                                >
                                    {({ field }) => (
                                        <InputGroup
                                            className="planr-default-input"
                                            id={field.name}
                                            {...field}
                                            autoComplete="off"
                                            data-lpignore="true"
                                            onKeyPress={digits}
                                            disabled={!canChange}
                                        />
                                    )}
                                </StandardFormInput>

                                <StandardFormInput
                                    name={fields.maxYears}
                                    schema={schema}
                                    small={true}
                                    className="planr-form-input"
                                >
                                    {({ field }) => (
                                        <InputGroup
                                            className="planr-default-input"
                                            id={field.name}
                                            {...field}
                                            autoComplete="off"
                                            data-lpignore="true"
                                            onKeyPress={digits}
                                            disabled={!canChange}
                                        />
                                    )}
                                </StandardFormInput>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                {canChange && (
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        <StandardFormButtons
                                            {...formProps}
                                            isRemoved={false}
                                            what={`группу ${deprGroup.name}`}
                                            isNewlyCreated={deprGroup.isNewlyCreated}
                                            onRemove={onRemove}
                                        />
                                    </div>
                                )}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
});

interface DeprGroupDetailsProps {
    deprGroup: DeprGroupType;
    canChange: boolean;
    onSaved: () => void;
    onRemoved: () => void;
}

function getFormValues(deprGroup: DeprGroupType) {
    return {
        ...getSnapshot(deprGroup),
    };
}

type FormValues = ReturnType<typeof getFormValues>;
