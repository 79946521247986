import { types } from "mobx-state-tree";
import { saveSortOption } from "modules/common/services/table/sorting-storage";
import { SortChangedEvent } from "@ag-grid-community/core";

export const TableSorter = types
    .model("TableSorter", {
        id: types.identifier,
        column: types.string,
        asc: types.boolean,
        tableName: types.string,
    })
    .views((self) => ({
        get direction() {
            return sorterDirection(self);
        },
    }))
    .views((self) => ({
        get lodashComparator() {
            return lodashComparator(self);
        },
        get agGridSortModel() {
            return [{ colId: self.column, sort: self.direction }];
        },
    }))
    .actions((self) => ({
        sortBy(column: string) {
            const storage = saveSortOption(self.tableName);

            const asc = self.column === column ? !self.asc : true;

            self.asc = asc;
            self.column = column;

            storage(column, asc);
        },

        resorted({ api, columnApi }: SortChangedEvent, refresh = true) {
            const state = columnApi.getColumnState();

            const currentSort = state.find((c) => c.sort);

            if (currentSort) {
                const storage = saveSortOption(self.tableName);

                self.column = currentSort.colId ?? "";
                self.asc = currentSort.sort === "asc";

                storage(self.column, self.asc);
            }

            refresh && api.refreshCells();
        },
    }));

export type TableSorterType = typeof TableSorter.Type;
export type TableSorterSnapshotType = typeof TableSorter.SnapshotType;

function sorterDirection(self: any) {
    return self.asc ? "asc" : "desc";
}

export function lodashComparator(self: any) {
    return [[self.column], [sorterDirection(self)]];
}
