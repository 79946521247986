import React from "react";

import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import { formatMoney } from "modules/common/components/money/Money";
import { formatPhoneNumber } from "modules/common/services/formatting/phone";
import { getColumnSize } from "modules/common/services/table/size-storage";
import { fields } from "modules/main/models/order-agents-list";
import { RenderOrderStatus } from "../table/columns";
export const columns = (tableName: string) => {
    const columnSize = getColumnSize(tableName);

    const result: ColDef[] = [
        // RowNumberColumn(false, ""),
        {
            headerName: "Инв",
            field: fields.inventoryNumber,
            width: columnSize(fields.inventoryNumber, 60),
            sortable: true,
            resizable: false,
        },
        {
            headerName: "Договор",
            field: fields.orderName,
            width: columnSize(fields.orderName, 150),
            sortable: true,
            resizable: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: "Статус",
            field: fields.orderStatus,
            width: columnSize(fields.orderStatus, 100),
            sortable: true,
            resizable: false,

            cellRendererFramework: ({ data }: ICellRendererParams) => {
                // const status = stat.asMap[row.statusId];

                return RenderOrderStatus(data.orderStatus, data.orderStatusColor);
            },
        },
        {
            headerName: "Категория",
            field: fields.categories,
            width: columnSize(fields.categories, 160),
            sortable: true,
            resizable: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: "Наименование",
            field: fields.name,
            width: columnSize(fields.name, 160),
            sortable: true,
            resizable: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: "Оборудование",
            field: fields.materials,
            width: columnSize(fields.materials, 160),
            sortable: true,
            resizable: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: "Агент. вознагр",
            field: fields.rewardSum,
            width: columnSize(fields.price, 140),
            sortable: true,
            resizable: false,
            wrapText: true,
            autoHeight: true,
            valueGetter: (params: any) => {
                return (+params?.data.price * +params?.data.rewardPercent) / 100;
            },

            cellRendererFramework: ({ data }: ICellRendererParams) => {
                const percent = data.rewardPercent + "%";
                const sum = formatMoney((+data.price * +data.rewardPercent) / 100);

                return (
                    <div>
                        <div>{percent}</div>
                        <div style={{ marginTop: "-5px" }}>{sum}</div>
                    </div>
                );
            },
        },
        {
            headerName: "Стоимость",
            field: fields.price,
            width: columnSize(fields.price, 140),
            sortable: true,
            resizable: false,
            valueGetter: (params: any) => {
                return +params.data.price;
            },

            cellRendererFramework: ({ value }: ICellRendererParams) => {
                // const status = stat.asMap[row.statusId];

                return <div>{formatMoney(+value)}</div>;
            },
        },
        {
            headerName: "Представитель",
            field: fields.memberName,
            width: columnSize(fields.memberName, 150),
            sortable: true,
            resizable: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: "Телефон",
            field: fields.phone,
            width: columnSize(fields.phone, 140),
            sortable: true,
            resizable: false,
            cellRendererFramework: ({ value }: ICellRendererParams) => {
                // const status = stat.asMap[row.statusId];

                return <div>{formatPhoneNumber(value)}</div>;
            },
        },
        {
            headerName: "Комментарии",
            field: fields.description,
            width: columnSize(fields.description, 160),
            sortable: true,
            resizable: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: "Сделка",
            field: fields.deal,
            width: columnSize(fields.deal, 140),
            sortable: true,
            resizable: false,
            valueGetter: (params: any) => {
                return +params.data.deal;
            },
            cellRendererFramework: ({ value }: ICellRendererParams) => {
                // const status = stat.asMap[row.statusId];

                return <div>{formatMoney(+value)}</div>;
            },
        },
    ];

    return result;
};
