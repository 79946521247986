import { applySnapshot, types } from "mobx-state-tree";
import { formatMoney } from "modules/common/components/money/Money";
import { DATE_TIME_FORMAT } from "modules/common/constants";
import { formatDate } from "modules/common/services/formatting/date";
import { nameof } from "modules/common/services/typescript";
import { EventDate } from "modules/orders-manage/models/order-events";
import { InvoiceFile } from "modules/orders-manage/models/order-file";
import { EventedDateValue } from "modules/orders-manage/models/order-stages-store";
import { BasePayment } from "modules/orders-manage/models/payment";
import moment from "moment";

export const OutsourcerPayment = types
  .compose(
    BasePayment,
    types.model({
      name: types.string,
      guid: types.string,
      isCash: types.boolean,
      employerId: types.string,
      sortableDate: types.string,
      money: types.string,
      planPaymentGuid: types.string,
      automatic: types.boolean,
      comment: types.string,
      status: types.string,
      invoice: types.maybeNull(InvoiceFile),
      concreteDate: types.maybeNull(types.string),
      hasDate: types.maybeNull(types.string),
      dateDescription: types.maybeNull(types.string),
      eventDates: types.array(EventDate),
      stageGuid: types.maybeNull(types.string),
      type: types.maybeNull(types.string),
    })
  )
  .views((self) => ({
    get concreteDateAsDate() {
      return self.concreteDate ? moment(self.concreteDate, DATE_TIME_FORMAT).toDate() : null;
    },
  }))
  .actions((self) => ({
    setDDate(value: EventedDateValue) {
      self.date = formatDate(value.date);
      // applySnapshot(self.eventDates, value.events);
    },
  }))
  .actions((self) => ({
    setDate(value: EventedDateValue) {
      self.concreteDate = formatDate(value.date);
      applySnapshot(self.eventDates, value.events);
    },
  }))
  .named("OutsourcerPayment");

export type OutsourcerPaymentType = typeof OutsourcerPayment.Type;
export type OutsourcerPaymentSnapshotType = typeof OutsourcerPayment.SnapshotType;

export const ActualPayment = types
  .compose(
    OutsourcerPayment,
    types.model({
      correctionPayments: types.array(OutsourcerPayment),
    })
  )
  .named("ActualPayment");

export type ActualPaymentType = typeof ActualPayment.Type;
export type ActualPaymentSnapshotType = typeof ActualPayment.SnapshotType;

export const outsourcerPaymentFields = {
  guid: nameof((a: OutsourcerPaymentType) => a.guid) as string,
  name: nameof((a: OutsourcerPaymentType) => a.name) as string,
  date: nameof((a: OutsourcerPaymentType) => a.date) as string,
  concreteDate: nameof((a: OutsourcerPaymentType) => a.concreteDate) as string,
  hasDate: nameof((a: OutsourcerPaymentType) => a.hasDate) as string,
  dateDescription: nameof((a: OutsourcerPaymentType) => a.dateDescription) as string,
  eventDates: nameof((a: OutsourcerPaymentType) => a.eventDates) as string,
  sum: nameof((a: OutsourcerPaymentType) => a.sum) as string,
  isCash: nameof((a: OutsourcerPaymentType) => a.isCash) as string,
  automatic: nameof((a: OutsourcerPaymentType) => a.automatic) as string,
  invoice: nameof((a: OutsourcerPaymentType) => a.invoice) as string,
  status: nameof((a: OutsourcerPaymentType) => a.status) as string,
};

export const emptyOutsourcerPayment = (guid: string, index: number): ActualPaymentSnapshotType => ({
  created: formatDate(new Date()),
  date: "",
  day: "",
  guid,
  name: index.toString().padStart(5, "0"),
  sum: 0,
  isCash: false,
  employerId: "",
  sortableDate: "",
  money: formatMoney(0),
  automatic: false,
  comment: "",
  status: "",
  invoice: null,
  planPaymentGuid: "",
  correctionPayments: [],
  concreteDate: null,
  dateDescription: null,
  hasDate: null,
  eventDates: [],
  stageGuid: "",
  type: "",
});
