import React from "react";
import { observer } from "mobx-react";
import styles from "../Dashboard.module.scss";
import local from "./Statuses.module.scss";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { BaseBlockProps } from "../SimpleChart";
import { SimpleChartDatum } from "modules/main/models/simple-chart";
import { OrdersStateDatasetType } from "modules/main/models/orders-state-chart";
import { Link } from "react-router-dom";
import { routes } from "modules/common/routes";
import { tabs } from "../../tabs";
import { STATUS_SECTION } from "../../table/filter-params";
import { OrderStatusDictionaryType } from "modules/dictionaries/order-statuses/models/order-status-dictionary";
import { colorLuminance } from "modules/common/services/colors";

export const ObjectCountCases: TStringMap<string> = {
  "0": "объектов",
  "1": "объект",
  "2": "объекта",
  "3": "объекта",
  "4": "объекта",
  "5": "объектов",
  "6": "объектов",
  "7": "объектов",
  "8": "объектов",
  "9": "объектов",
};

export const StatusesBlock = observer(
  class extends React.PureComponent<StatusesBlockProps> {
    componentDidMount() {
      this.props.store.load();
    }

    render() {
      const { width, store, statuses } = this.props;
      const showLoader = !store.loaded && store.loading;

      return (
        <div
          className={`${styles.widget} ${local.statuses} statuses-widget`}
          style={{ width: `${width}px`, minWidth: `${width}px`, maxHeight: "370px", overflowY: "auto" }}
        >
          <LocalLoader active={showLoader} />

          {/* use legacy table-based layout to correct image convertation */}
          <table>
            <colgroup>
              <col width="50%"></col>
              <col width="50%"></col>
            </colgroup>
            <tbody>
              {store.ids.map((statusId, index) => {
                let color = "#fff";
                const period = store.labels[index];
                statuses?.isAvailable.find((item) => {
                  if (item.id === statusId) {
                    color = item.color;
                    return true;
                  }
                });

                const row = store.amChartData[index];
                const caseType = row.data.toString().slice(-1);
                const caseName = ObjectCountCases[caseType];

                const href = statusId ? `${routes.orders.manage}${tabs.table}?${STATUS_SECTION}=${statusId}` : "";
                const element = href ? (
                  <Link to={href}>
                    <span
                      style={{
                        padding: "5px 7px",
                        fontSize: "12px",
                        borderRadius: "4px",
                        backgroundColor: `${colorLuminance(color, 0.1)}`,
                        color: `${colorLuminance(color, -0.5)}`,
                      }}
                    >
                      {period}
                    </span>
                  </Link>
                ) : (
                  <strong
                    style={{
                      padding: "5px 7px",
                      fontSize: "12px",
                    }}
                  >
                    {period}:
                  </strong>
                );

                return (
                  <tr key={period}>
                    <td>{element}</td>
                    <td className={`${countRowStyle(row, index)} ${local.count}`}>
                      {row.data} {caseName}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
  }
);

interface StatusesBlockProps extends BaseBlockProps {
  store: OrdersStateDatasetType;
  statuses?: OrderStatusDictionaryType;
}

const countRowStyle = (row: SimpleChartDatum, index: number) => {
  const result = row.data === 0 ? local.empty : "";

  return `${result} `;
};
