import React from "react";
import classnames from "classnames";
import { GeneralIcon, GeneralIcons } from "../icon/Generalcon";

export const PlanrLink: React.FC<ButtonProps> = ({ type, size, className, icon, target, round, children, href }) => {
    // TODO: loading

    const styleName = classnames({
        "planr-button": true,
        "is-round": round,
        primary: !type || type === "primary",
        secondary: type === "secondary",
        dashed: type === "dashed",
        neutral: type === "neutral",
        medium: size !== "small",
        small: size === "small",
        [className || ""]: true,
    });

    return (
        <a className={styleName} href={href} target={target}>
            {icon && <GeneralIcon type={icon} />}
            {!round && children && <span>{children}</span>}
        </a>
    );
};

interface ButtonProps {
    size?: "medium" | "small";
    href: string;
    icon?: GeneralIcons;
    loading?: boolean;
    type: "primary" | "secondary" | "dashed" | "neutral";
    className?: string;
    round?: boolean;
    target?: string;
}
