import { types, applySnapshot, isAlive } from "mobx-state-tree";
import { BaseEntity, isNewlyCreated, DictionaryItem } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID, DATE_TIME_FORMAT } from "modules/common/constants";
import Schema from "../components/details/validation";
import moment from "moment";
import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";
import { getFieldLabel } from "modules/common/services/form/fields";

export const WorkTypeDictionaryItem = types
    .compose(
        DictionaryItem,
        types.model({
            workTypeCategory: types.string,
            description: types.string,
        })
    )
    .named("WorkTypeDictionaryItem");

const WorkTypeBase = types.compose(
    Transport,
    Notificator,
    BaseEntity,
    types.model({
        name: types.string,
        description: types.string,
        workTypeCategory: types.string,
    })
);

export const WorkType = WorkTypeBase.actions((self) => ({
    load: flow(function* (id: string) {
        try {
            const snapshot = isNewlyCreated(id)
                ? initialState()
                : yield self.transport.get<WorkTypeSnapshotType>(apiUrls.workTypes.details(id));

            applySnapshot(self, snapshot);
        } catch (er) {
            self.notify.error(er);
        }
    }),

    save: flow(function* (model: WorkTypeSnapshotType) {
        try {
            const snapshot: any = self.isNewlyCreated
                ? yield self.transport.put<WorkTypeSnapshotType>(apiUrls.workTypes.create(), model)
                : yield self.transport.post<WorkTypeSnapshotType>(apiUrls.workTypes.update(self.id), model);

            isAlive(self) && applySnapshot(self, snapshot);
            self.notify.success(texts.messages.saved);

            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    delete: flow(function* () {
        if (self.isNewlyCreated) {
            return true;
        }

        try {
            yield self.transport.delete<boolean>(apiUrls.workTypes.delete(self.id));
            self.notify.success(texts.messages.removed);

            isAlive(self) && applySnapshot(self, initialState());
            return true;
        } catch (er) {
            self.notify.error(er);
            return false;
        }
    }),
}))
    .views((self) => ({
        get asSelectItem() {
            return asSelectItem(self);
        },
    }))
    .named("WorkType");

export type WorkTypeDictionaryItemSnapshotType = typeof WorkTypeDictionaryItem.SnapshotType;
export type WorkTypeDictionaryItemType = typeof WorkTypeDictionaryItem.Type;
export type WorkTypeSnapshotType = typeof WorkTypeBase.SnapshotType;
export type WorkTypeType = typeof WorkType.Type;

export const asSelectItem = (workType: WorkTypeSnapshotType) =>
    workType ? ({ id: workType.id, label: workType.name } as SelectItem) : null;

export const initialState = (): WorkTypeSnapshotType => ({
    id: EMPTY_OBJECT_ID,
    created: moment().format(DATE_TIME_FORMAT),
    name: "",
    description: "",
    workTypeCategory: "",
});

export const fields = {
    name: nameof((a: WorkTypeType) => a.name) as string,
    description: nameof((a: WorkTypeType) => a.description) as string,
    category: nameof((a: WorkTypeType) => a.workTypeCategory) as string,
};

export function formatWorkType(workType: WorkTypeSnapshotType | null) {
    let result = "";

    if (workType) {
        result += `${getFieldLabel(fields.name, Schema, null)}: ${workType.name}\n`;
        result += `${getFieldLabel(fields.description, Schema, null)}: ${workType.description}\n`;
        result += `${getFieldLabel(fields.category, Schema, null)}: ${workType.workTypeCategory}\n`;
    }

    return result;
}
