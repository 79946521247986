import React from "react";

import { PlanrButton } from "modules/common/components/planr/button/Button";

export const CollapseExpandAll = ({ onChange }: CollapseExpandAllProps) => {
    return (
        <div className={`planr-tools-arrows`}>
            <PlanrButton
                type="graybtn"
                icon="general-big-arrow-down"
                onClick={() => onChange(false)}
                round={true}
                title="Развернуть все"
            />

            <PlanrButton
                type="graybtn"
                icon="general-big-arrow-up"
                onClick={() => onChange(true)}
                round={true}
                title="Свернуть все"
                style={{ marginLeft: "0px" }}
            />
            {/* <Icon icon="arrow-down" onClick={() => onChange(false)} htmlTitle="Развернуть все" />
            &nbsp;&nbsp;
            <Icon icon="arrow-up" onClick={() => onChange(true)} htmlTitle="Свернуть все" /> */}
        </div>
    );
};

interface CollapseExpandAllProps {
    onChange: (collapsed: boolean) => void;
}
