import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { AgentsCategories, AgentsCategoriesType, AgentsCategoriesSnapshotType, fields } from "./agents-categories";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
// import { getIn } from "formik";
// import { orderBy } from "lodash";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { Queryable } from "modules/common/models/queryable";

export const AgentsCategoryList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            agentsCategories: types.array(AgentsCategories),
            sorter: TableSorter,
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<AgentsCategoriesType> {
            return self.agentsCategories.reduce((result, ipdType) => {
                result[ipdType.id] = ipdType;
                return result;
            }, {} as TStringMap<AgentsCategoriesType>);
        },

        get data() {
            const predicate = (o: AgentsCategoriesType) => {
                let result = true;
                if (self.pureQuery) {
                    result = result && `${o.name} ${o.description} `.toLowerCase().indexOf(self.pureQuery) >= 0;
                }
                return result;
            };
            return self.agentsCategories.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));
            // const order = self.sorter.asc ? "asc" : "desc";

            // return orderBy(getSnapshot(self.agentsCategories), [(o) => getIn(o, self.sorter.column)], [order]);
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: any = yield self.transport.get<AgentsCategoriesSnapshotType[]>(
                    apiUrls.agentsCategories.list()
                );
                applySnapshot(self.agentsCategories, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
        loadNoRules: flow(function* () {
            try {
                const data: any = yield self.transport.get<AgentsCategoriesSnapshotType[]>(
                    apiUrls.agentsCategories.listNoRules()
                );
                applySnapshot(self.agentsCategories, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        delete: flow(function* (ipdType: AgentsCategoriesSnapshotType) {
            const model = AgentsCategories.create(ipdType, {
                http: self.transport,
                notificator: self.notify,
            });
            return yield model.delete();
        }),
    }))
    .named("AgentsCategoryList");

export type AgentsCategoryListType = typeof AgentsCategoryList.Type;
export type AgentsCategoryListSnapshotType = typeof AgentsCategoryList.SnapshotType;
export { AgentsCategories };

const sortStorage = getSortOption(AgentsCategoryList.name);

export const initialState = (): AgentsCategoryListSnapshotType => {
    const options = sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        agentsCategories: [],
        pureQuery: "",
        query: "",
        sorter: {
            id: AgentsCategoryList.name,
            tableName: AgentsCategoryList.name,
            column: options.column,
            asc: options.asc,
        },
    };
};
