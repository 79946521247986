import React from "react";
import { Dialog, Classes } from "@blueprintjs/core";
import { OrderDictionaryType, OrderDictionaryItemType } from "modules/orders-manage/models/order-dictionary";
import {
    ProjectSelect,
    renderProjectOption,
    ProjectSelectedOption,
    filterProjectItemPredicate,
} from "modules/common/components/form/ProjectSelect";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import styles from "./ProjectAssigner.module.scss";

import { LocalLoader } from "../loader/Loader";
import { PlanrButton } from "../planr/button/Button";

export const ProjectAssigner = observer(
    class extends React.PureComponent<ProjectAssignerProps, ProjectAssignerState> {
        state = { orderId: "" };

        render() {
            const { orderId } = this.state;
            const { onClose, orders, loading } = this.props;
            const item = orders.asMap[orderId] || null;

            return (
                <Dialog
                    isCloseButtonShown={true}
                    isOpen={true}
                    onClose={onClose}
                    title="Выбор проекта"
                    backdropClassName="standard"
                    canOutsideClickClose={false}
                    canEscapeKeyClose={false}
                    className={`${styles.assigner} figma-dialog`}
                >
                    <LocalLoader active={!!loading} />

                    <div className={Classes.DIALOG_BODY}>
                        <ProjectSelect
                            className={`planr-default-input ${Classes.FILL}`}
                            popoverProps={{
                                popoverClassName: styles.popover,
                            }}
                            filterable={true}
                            itemPredicate={filterProjectItemPredicate}
                            activeItem={item}
                            items={this.filteredOptions()}
                            itemRenderer={renderProjectOption}
                            onItemSelect={this.projectSelected}
                            resetOnClose={true}
                            inputProps={{
                                placeholder: "Проект",
                            }}
                        >
                            <ProjectSelectedOption option={item} />
                        </ProjectSelect>
                    </div>

                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <PlanrButton type="graybtn" size="small" onClick={onClose} style={{ marginRight: "10px" }}>
                                {texts.cancel}
                            </PlanrButton>
                            <PlanrButton type="greenish" size="small" onClick={this.onOk}>
                                {texts.ok}
                            </PlanrButton>
                        </div>
                    </div>
                </Dialog>
            );
        }

        filteredOptions = () => {
            const { orders, excludeId } = this.props;

            return excludeId ? orders.orders.filter((o) => o.id !== excludeId) : orders.orders;
        };

        projectSelected = (item: OrderDictionaryItemType) => {
            this.setState({ orderId: item.id });
        };

        onOk = () => {
            const { onAssign } = this.props;
            const { orderId } = this.state;

            orderId && onAssign(orderId);
        };
    }
);

interface ProjectAssignerProps {
    onClose: () => void;
    onAssign: (orderId: string) => void;
    orders: OrderDictionaryType;
    excludeId?: string;
    loading?: boolean;
}

interface ProjectAssignerState {
    orderId: string;
}
