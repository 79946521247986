import { ColDef } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { Collapse } from "@blueprintjs/core";
import { sumBy } from "lodash";
import { observer } from "mobx-react";
import { Caret } from "modules/common/components/collapse/Caret";
import { FileLink } from "modules/common/components/files/FileLink";
import { IconedRadio, IconedRadioGroup } from "modules/common/components/form/IconedRadioGroup";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { Search } from "modules/common/components/form/Search";
import { Loader } from "modules/common/components/loader/Loader";
import { formatMoney, Money } from "modules/common/components/money/Money";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { PlanrTag } from "modules/common/components/planr/tag/PlanrTag";
import { CollapseExpandAll } from "modules/common/components/table/CollapseExpandAll";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { routes } from "modules/common/routes";
import { prevent } from "modules/common/services/form/select";
import { DefaultAgGridOptions, defaultModules, FIGMA_HEADER_HEIGHT } from "modules/common/services/table/helpers";
import { eat } from "modules/common/services/typescript";
import { Constants } from "modules/root/models/constants";
import { agGridLocale } from "modules/root/services/locale";
import React from "react";
import { TabStoreType } from "../models/finance-requests-store";
import {
  FinanceRequestSnapshotType,
  getRowNodeId,
  removeConfiramtionAction,
  removeConfiramtionName,
} from "../models/request";
import { columnsBuilder, RequestActions } from "./columns";
import styles from "./FinanceRequestsPage.module.scss";

export const RequestsTable = observer(
  class extends React.Component<NewRequestsProps, RequestsTableState> implements RequestActions {
    private options = DefaultAgGridOptions(true);
    private columns: ColDef[];

    constructor(props: NewRequestsProps) {
      super(props);

      const { type, data } = this.props.store;

      this.columns = columnsBuilder(type, this.props.canViewOutsourser);
      this.state = {
        collapser: {
          [Object.keys(data)[0]]: true,
        },
        immediate: [],
        showTools: false,
      };
    }

    componentDidUpdate(prev: NewRequestsProps) {
      if (prev.canViewOutsourser !== this.props.canViewOutsourser) {
        const { type } = this.props.store;
        this.columns = columnsBuilder(type, this.props.canViewOutsourser);
      }
    }

    render() {
      const { store, canExport, isMobile, sum } = this.props;
      const { collapser, immediate, showTools } = this.state;
      const { data, grouping, query, isLoading } = store;

      return (
        <div className={styles.table}>
          <Loader active={isLoading} />

          <div className={styles.topTools}>
            <div className={styles.actions}>
              <div className={styles.right}>
                {canExport && (
                  <>
                    <PlanrButton type="graybtn" icon="general-export" onClick={store.export} round={true} />
                    <PlanrButton type="graybtn" icon="general-print" onClick={store.print} round={true} />
                  </>
                )}
                <Search query={query} onSearch={this.onSearch} />
              </div>
              <div className="sorting">
                <CollapseExpandAll onChange={this.collapseAll} />
              </div>
            </div>

            <div className="tools">
              <IconedRadioGroup
                className="grouping"
                onChange={this.onGroupingChange}
                selectedValue={grouping}
                inline={true}
                name="grouping"
              >
                <IconedRadio label="По проектам" value="project">
                  <GeneralIcon type={"general-home"} />
                </IconedRadio>
                <IconedRadio label="По датам" value="period">
                  <GeneralIcon type={"general-calendar"} />
                </IconedRadio>
                <IconedRadio label="По контрагентам" value="outsourcer">
                  <GeneralIcon type={"general-ppl"} />
                </IconedRadio>
              </IconedRadioGroup>
            </div>
          </div>

          <div className={styles.mobileTools}>
            <div className="tools">
              <IconedRadioGroup
                className="grouping"
                onChange={this.onGroupingChange}
                selectedValue={grouping}
                inline={true}
                name="grouping"
              >
                <IconedRadio label="По проектам" value="project">
                  <GeneralIcon type={"general-home"} />
                </IconedRadio>
                <IconedRadio label="По датам" value="period">
                  <GeneralIcon type={"general-calendar"} />
                </IconedRadio>
                <IconedRadio label="По контрагентам" value="outsourcer">
                  <GeneralIcon type={"general-ppl"} />
                </IconedRadio>
              </IconedRadioGroup>
            </div>
            <div>
              <CollapseExpandAll onChange={this.collapseAll} />
            </div>
            <PlanrButton
              type={showTools ? "blueish" : "lightblueish"}
              style={{ marginLeft: "10px" }}
              icon="general-dots-more"
              round
              onClick={this.toggleShowTools}
            />
          </div>
          {showTools && (
            <div className={styles.dialogTools}>
              {canExport && (
                <>
                  <PlanrButton type="graybtn" icon="general-export" onClick={store.export} round={true} />
                  <PlanrButton type="graybtn" icon="general-print" onClick={store.print} round={true} />
                </>
              )}
              <Search query={query} onSearch={this.onSearch} />
            </div>
          )}
          {sum && (
            <div className={styles.mobileSum}>
              <PlanrTag>Всего: {formatMoney(sum)}</PlanrTag>
            </div>
          )}
          {Object.keys(data).map((title) => {
            const collapsed = !collapser[title];
            const rows = data[title];
            const total = sumBy(rows, (r) => r.sum);
            const toggle = () => this.onToggleCollapse(title);

            return (
              <GridTheme bordered={true} key={title} className={`designed collapse-header-finance`}>
                <div className={styles.header}>
                  <h1 className="planr-block-header-wide collapser" onClick={toggle}>
                    {this.renderTitle(rows, title)}
                    <Money amount={total} />
                    <Caret collapsed={collapsed} />
                  </h1>
                </div>
                <div className={styles.mobileHeader}>
                  <h1 className="planr-block-header-wide collapser" onClick={toggle}>
                    <div className={styles.headerText}>
                      <div className={styles.headerTitle}>{title}</div>
                      <div className={styles.headerMoney}>{formatMoney(total)}</div>
                    </div>
                    <Caret collapsed={collapsed} />
                  </h1>
                </div>

                <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                  <span className="collapse-wide">
                    {!isMobile && (
                      <AgGridReact
                        rowStyle={{
                          borderBottom: "1 px solid #EDF0F2",
                          fontFamily: "GothamPro, Arial, sans-serif",
                          fontWeight: "300",
                          color: "#00273D",
                          height: "28px",
                          fontSize: "12px",
                        }}
                        suppressRowClickSelection={true}
                        suppressCellSelection={true}
                        enableCellTextSelection={true}
                        columnDefs={this.columns}
                        rowData={rows}
                        headerHeight={FIGMA_HEADER_HEIGHT}
                        minColWidth={5}
                        getRowNodeId={getRowNodeId}
                        domLayout="autoHeight"
                        localeText={agGridLocale}
                        context={this}
                        gridOptions={this.options}
                        immutableData={true}
                        modules={defaultModules}
                        animateRows={true}
                      />
                    )}
                    {isMobile && (
                      <div className={styles.cardList}>
                        {rows.map((row) => {
                          const rowImmediate = immediate.find((r) => r.id === row.id);
                          const isImmediate = rowImmediate && rowImmediate.value === true;
                          return (
                            <div key={row.id}>
                              <div className={styles.card}>
                                <div className={styles.mid}>
                                  {row.order && (
                                    <>
                                      <div className={styles.label}>Объект:</div>
                                      <div
                                        className={styles.row}
                                      >{`${row.order?.fullInventoryNumber} ${row.order?.name}`}</div>
                                    </>
                                  )}
                                  {row.taskStatus && (
                                    <>
                                      <div className={styles.label}>Задание:</div>
                                      <div className={styles.row}>{row.taskStatus}</div>
                                    </>
                                  )}
                                  {row.workTypeName && (
                                    <>
                                      <div className={styles.label}>Виды работ:</div>
                                      <div className={styles.row}>{row.workTypeName}</div>
                                    </>
                                  )}
                                  {row.date && (
                                    <>
                                      <div className={styles.label}>Дата:</div>
                                      <div className={styles.row}>{row.date}</div>
                                    </>
                                  )}
                                  {row.sum && (
                                    <>
                                      <div className={styles.label}>Сумма:</div>
                                      <div className={styles.row}>{formatMoney(row.sum)}</div>
                                    </>
                                  )}
                                  {row.outsourcer && (
                                    <>
                                      <div className={styles.label}>Контрагент:</div>
                                      <div className={styles.row}>
                                        <a
                                          href={routes.agents.outsourcers.details.outsourcers(row.outsourcer.id)}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {row.outsourcer.label}
                                        </a>
                                      </div>
                                    </>
                                  )}
                                  {row.supplier && (
                                    <>
                                      <div className={styles.label}>Контрагент:</div>
                                      <div className={styles.row}>
                                        {row.supplier.id !== EMPTY_OBJECT_ID ? (
                                          <a
                                            href={routes.agents.outsourcers.details.outsourcers(row.supplier.id)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {row.supplier.label}
                                          </a>
                                        ) : (
                                          row.supplier.label
                                        )}
                                      </div>
                                    </>
                                  )}
                                  {row.invoice && row.invoice.length > 0 && (
                                    <>
                                      <div className={styles.label}>Счет:</div>
                                      <div className={styles.row}>
                                        <div style={{ padding: "5px", width: "270px" }}>
                                          {row.invoice.map((file) => (
                                            <FileLink
                                              key={file.fileId}
                                              baseUrl={this.baseUrl()}
                                              file={{
                                                fileId: file.fileId,
                                                fileName: file.fileName ? file.fileName : "",
                                              }}
                                              style={{ maxWidth: "none", height: "25px" }}
                                              readOnly={true}
                                              onRemove={eat}
                                            />
                                          ))}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {row.orderGipName && (
                                    <>
                                      <div className={styles.label}>ГИП/Сотрудник:</div>
                                      <div className={styles.row}>{row.orderGipName}</div>
                                    </>
                                  )}

                                  {store.type === "new" && (
                                    <>
                                      <div className={styles.label}>Срочно:</div>
                                      <div className={styles.row}>
                                        <PlanrButton
                                          type="neutral"
                                          icon={isImmediate ? "general-checkbox" : "general-zero-checkbox"}
                                          onClick={() => {
                                            let oldImmediate = [...immediate];
                                            const index = immediate.findIndex((r) => r.id === row.id);
                                            if (index >= 0) {
                                              oldImmediate[index] = {
                                                id: oldImmediate[index].id,
                                                value: !oldImmediate[index].value,
                                              };
                                              this.setState({ immediate: [...oldImmediate] });
                                            } else {
                                              oldImmediate.push({ id: row.id, value: !row.immediate });
                                              this.setState({ immediate: [...oldImmediate] });
                                            }

                                            this.onImmediateCange(row, true);
                                          }}
                                          style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                                        />
                                      </div>
                                    </>
                                  )}
                                  {store.type === "accepted" && row.immediate && (
                                    <div className={styles.row}>
                                      <span>Срочно</span>
                                    </div>
                                  )}
                                </div>
                                <div className={styles.edit}>
                                  {row.status === Constants.orderPaymentStatusNew ? (
                                    <PlanrButton
                                      type="greenish"
                                      onClick={(e: React.MouseEvent) => {
                                        e.stopPropagation();
                                        this.accept(row);
                                      }}
                                      style={{
                                        height: "20px",
                                        padding: "10px",
                                      }}
                                    >
                                      Одобрить
                                    </PlanrButton>
                                  ) : null}
                                  <RemoveConfirmation<FinanceRequestSnapshotType>
                                    what={removeConfiramtionName}
                                    actionName={removeConfiramtionAction(row)}
                                    onConfirmed={this.cancel}
                                    render={({ confirmRemoving }) => {
                                      return (
                                        <PlanrButton
                                          type="danger"
                                          onClick={() => confirmRemoving(row)}
                                          style={{
                                            height: "20px",
                                            padding: "10px",
                                          }}
                                        >
                                          Отклонить
                                        </PlanrButton>
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </span>
                </Collapse>
              </GridTheme>
            );
          })}
        </div>
      );
    }
    toggleShowTools = () => this.setState({ showTools: !this.state.showTools });

    renderTitle = (rows: FinanceRequestSnapshotType[], title: string) => {
      const { store, canViewOutsourser } = this.props;
      const outsourcer = rows[0] ? rows[0].outsourcer : null;

      if (outsourcer && store.grouping === "outsourcer" && canViewOutsourser) {
        const url = routes.agents.outsourcers.details.outsourcers;

        return (
          <a href={url(outsourcer.id)} target="_blank" rel="noopener noreferrer" onClick={prevent}>
            {title}
          </a>
        );
      }

      return title;
    };

    onSearch = this.props.store.setQuery;

    collapseAll = (collapsed: boolean) => {
      const { data } = this.props.store;

      const appendo: TStringMap<boolean> = {};
      Object.keys(data).forEach((key) => (appendo[key] = !collapsed));
      const collapser = { ...this.state.collapser, ...appendo };

      this.setState({ collapser });
    };

    onToggleCollapse = (name: string) => {
      const old = !!this.state.collapser[name];
      const collapser = { ...this.state.collapser, [name]: !old };

      this.setState({ collapser });
    };

    accept = (request: FinanceRequestSnapshotType) => {
      this.props.toggleStatus(request.id);
    };

    cancel = (request: FinanceRequestSnapshotType) => {
      const { cancelRequest } = this.props;
      cancelRequest && cancelRequest(request.id);
    };

    onImmediateCange = (request: FinanceRequestSnapshotType, value: boolean) => {
      const { changeImmediate } = this.props;

      changeImmediate && changeImmediate(request.id, value);
    };

    onGroupingChange = (value: string) => {
      const { store } = this.props;
      store.setGrouping(value as any);
    };

    baseUrl = () => this.props.baseUrl;
  }
);

interface RequestsTableState {
  collapser: TStringMap<boolean>;
  immediate: Immediate[];
  showTools: boolean;
}

interface NewRequestsProps {
  toggleStatus: (id: string) => void;
  cancelRequest: (id: string) => void;
  changeImmediate?: (id: string, value: boolean) => void;
  canExport: boolean;
  canViewOutsourser: boolean;
  store: TabStoreType;
  baseUrl: string;
  isMobile?: boolean;
  sum?: number;
}

interface Immediate {
  id: string;
  value: boolean;
}
