import { types } from "mobx-state-tree";
import { flow } from "modules/common/models/flow";
import { PeriodDataset, initialState as emptyPeriodChart } from "./period-chart";

export const ComposedChartDataset = types
    .model({
        charts: types.array(PeriodDataset),
        period: types.union(types.literal("Quarter"), types.literal("Year")),
    })
    .actions((self) => ({
        load: flow(function* () {
            const tasks = self.charts.map((c) => c.load());
            yield Promise.all(tasks);
        }),

        setLoaded(value: boolean) {
            self.charts.forEach((c) => c.setLoaded(value));
        },

        setPeriod(value: "Quarter" | "Year") {
            self.charts.forEach((c) => c.setPeriod(value));
            self.period = value;
        },
    }))
    .views((self) => ({
        get loading() {
            return self.charts.some((c) => c.loading);
        },

        get loaded() {
            return self.charts.every((c) => c.loaded);
        },

        get amChartData() {
            // collect labels
            const map: TStringMap<boolean> = {};
            const periods: string[] = [];
            self.charts.forEach((chart) => {
                const src = chart.data.get(self.period);

                src &&
                    src.labels.forEach((label) => {
                        if (!map[label]) {
                            map[label] = true;
                            periods.push(label);
                        }
                    });
            });

            return periods.map((period) => {
                const datum: TStringMap<any> = { period };
                let max = 0;

                self.charts.forEach((chart, i) => {
                    const src = chart.data.get(self.period);
                    if (src) {
                        const index = src.labels.indexOf(period);
                        const value = index < 0 ? 0 : src.data[index];

                        datum[`data${i}`] = value;
                        if (value > max) {
                            max = value;
                        }
                    }
                });

                datum.max = max;

                return datum;
            });
        },
    }))
    .named("ComposedChartDataset");

export type ComposedChartDatasetType = typeof ComposedChartDataset.Type;
export type ComposedChartDatasetSnapshotType = typeof ComposedChartDataset.SnapshotType;

export const initialState = (charts: { url: string; title: string }[]): ComposedChartDatasetSnapshotType => {
    return {
        charts: charts.map(({ title, url }) => emptyPeriodChart(url, title)),
        period: "Quarter",
    };
};
