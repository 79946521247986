import React from "react";
import { OrderCommunicationRowSnapshotType } from "../../models/order-communication";
import styles from "./OrderCommunicationRow.module.scss";

export const OrderCommunicationHeader = ({ active }: OrderCommunicationHeaderProps) => {
    return (
        <div className={`${styles.row} ${styles.headerRow} flat-mode`}>
            <div className={styles.iconCell}>&nbsp;</div>
            <div className={`${styles.responsesBlock}`}>Зона ответственности</div>

            <div className={`${styles.employer} `}>Участник проекта</div>
            <div className={`${styles.number} `}>Время связи</div>

            <div className={styles.iconCell}>&nbsp;</div>
        </div>
    );
};

interface OrderCommunicationHeaderProps {
    active: OrderCommunicationRowSnapshotType | null;
}
