import { types } from "mobx-state-tree";
import { debounce } from "lodash";

export const Queryable = types
    .model({
        query: types.string,
        pureQuery: types.string,
    })
    .actions((self) => ({
        search(query: string) {
            self.pureQuery = query.toLowerCase();

            const me = self as any;
            if (typeof me.onQueryChanged === "function") {
                me.onQueryChanged(self.pureQuery);
            }
        },
    }))
    .actions((self) => {
        const updateQuery = debounce(self.search, 100);

        return {
            setQuery(value: string) {
                self.query = value || "";
                updateQuery(self.query);
            },
        };
    });

export type QueryableType = typeof Queryable.Type;
