import * as yup from "yup";
import { fields } from "../models/schedule-unit";
import { texts } from "modules/common/texts";
const { object, number, string } = yup;

export const scheduleLabels = () => ({
    [fields.salary]: "Оклад",
    [fields.bonus]: "Премия",
    [fields.bonusCorrection]: "Корр. премии",
    [fields.bonusCorrectionComment]: texts.comment,
    [fields.increase]: "Надбавка",
    [fields.decrease]: "Вычет",
    [fields.employerId]: "Сотрудник",
    [fields.vacationBonus]: "Отпуск (премия)",
    [fields.vacationSalary]: "Отпуск (оклад)",
});

const ScheduleSchema = () => {
    const labels = scheduleLabels();

    return object().shape({
        [fields.salary]: number()
            .label(labels[fields.salary])
            .transform((value) => (isNaN(value) ? undefined : value))
            .notRequired()
            .min(0)
            /* eslint-disable-next-line */
            .typeError("${path} должен быть числом"),

        [fields.bonus]: number()
            .label(labels[fields.bonus])
            .transform((value) => (isNaN(value) ? undefined : value))
            .notRequired()
            .min(0)
            /* eslint-disable-next-line */
            .typeError("${path} должна быть числом"),

        [fields.bonusCorrection]: number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .label(labels[fields.bonusCorrection])
            .notRequired()
            /* eslint-disable-next-line */
            .typeError("${path} должна быть числом"),

        [fields.bonusCorrectionComment]: string()
            .label(labels[fields.bonusCorrectionComment])
            .when(fields.bonusCorrection, {
                is: (correction) => !!correction,
                then: yup.string().required(),
                otherwise: yup.string().notRequired(),
            }),

        [fields.increase]: number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .label(labels[fields.increase])
            .notRequired()
            /* eslint-disable-next-line */
            .typeError("${path} должна быть числом")
            .min(0),

        [fields.decrease]: number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .label(labels[fields.decrease])
            .notRequired()
            /* eslint-disable-next-line */
            .typeError("${path} должен быть числом")
            .min(0),

        [fields.vacationBonus]: number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .label(labels[fields.vacationBonus])
            .notRequired()
            /* eslint-disable-next-line */
            .typeError("${path} должен быть числом")
            .min(0),

        [fields.vacationSalary]: number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .label(labels[fields.vacationSalary])
            .notRequired()
            /* eslint-disable-next-line */
            .typeError("${path} должен быть числом")
            .min(0),

        [fields.employerId]: string().label(labels[fields.employerId]).notRequired(),
    });
};

export default ScheduleSchema;
