import React from "react";
import { PrintAnchorInfoListType } from "modules/common/models/print-anchor";
import { observer } from "mobx-react";
import styles from "./PrintAnchors.module.scss";
import { Search } from "../form/Search";

interface PrintAnchorsProps {
    store: PrintAnchorInfoListType;
    className?: string;
}

export const PrintAnchors = observer(({ store, className }: PrintAnchorsProps) => {
    return (
        <div className={`${styles.anchors} print-anchors ${className || ""}`}>
            <Search query={store.query} onSearch={store.setQuery} style={{ marginLeft: 0, marginBottom: "10px" }} />

            {store.filtered.map((anchor, index) => (
                <div key={index} className={styles.anchor} onClick={anchor.copy}>
                    <span>{anchor.help}</span>
                </div>
            ))}
        </div>
    );
});
