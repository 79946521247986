import React, { useCallback } from "react";
import { NodeType, ACCEPT } from "modules/technical/models/store";
import { FileUploader } from "modules/common/components/files/Uploader";

import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { Prompt } from "modules/common/components/form/Prompt";
import { isNewlyCreated } from "modules/common/models/entity";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import styles from "./FileSystemPage.module.scss";
import { texts } from "modules/common/texts";

export const FolderActions = ({ folder, uploadFile, remove, create, rename, canManage }: FolderActionsProps) => {
    const onFileSelected = useCallback(
        async (file: File) => {
            if (folder) {
                return await uploadFile(file, folder);
            }
        },
        [uploadFile, folder]
    );

    const onCreate = useCallback(
        (name: string) => {
            create(name, folder);
        },
        [folder, create]
    );

    const onRename = useCallback(
        (name: string) => {
            folder && rename(name, folder);
        },
        [folder, rename]
    );

    return (
        <div className={styles.folderBtns}>
            {folder && canManage && <FileUploader accept={ACCEPT} onFileSelected={onFileSelected} />}
            {folder && canManage && !isNewlyCreated(folder.id) && (
                <Prompt
                    what="Новое название папки"
                    onConfirmed={onRename}
                    render={({ ask }) => (
                        <PlanrButton
                            type="graybtn"
                            icon={"general-edit"}
                            onClick={() => ask(folder.label)}
                            round={true}
                            title="Переименовать"
                            style={{ marginLeft: "8px" }}
                        />
                        // <div title="Переименовать" onClick={() => ask(folder.label)}>
                        //     <Icon icon="edit" />
                        // </div>
                    )}
                />
            )}
            {canManage && (
                <Prompt
                    what="Название папки"
                    onConfirmed={onCreate}
                    render={({ ask }) => (
                        <PlanrButton
                            type="graybtn"
                            icon={"general-folder-add"}
                            onClick={() => ask("")}
                            round={true}
                            title="Новая папка"
                        />
                        // <div title="Новая папка" onClick={() => ask("")}>
                        //     <Icon icon="folder-shared" />
                        // </div>
                    )}
                />
            )}
            {folder && canManage && (
                <RemoveConfirmation<NodeType>
                    what={(f) => `директорию ${f.label} и все дочерние элементы`}
                    onConfirmed={remove}
                    render={({ confirmRemoving }) => {
                        return (
                            <PlanrButton
                                type="graybtn"
                                icon={"general-trash"}
                                onClick={() => confirmRemoving(folder)}
                                round={true}
                                title={texts.remove}
                            />
                        );
                    }}
                />
            )}
        </div>
    );
};

interface FolderActionsProps {
    folder: NodeType | null;
    remove: (folder: NodeType) => void;
    create: (name: string, parent: NodeType | null) => void;
    rename: (name: string, folder: NodeType) => void;
    uploadFile: (file: File, folder: NodeType) => Promise<any>;
    canManage: boolean;
}
