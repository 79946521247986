import React from "react";
import { types } from "mobx-state-tree";
import { WorkloadDay } from "./workload-day";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { nameof } from "modules/common/services/typescript";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { texts } from "modules/common/texts";
import { EmployerDictionaryItem } from "modules/spending/employee/models/employee-dictionary";

export const UserWorkload = types
    .compose(
        Transport,
        Notificator,
        types.model({
            month: types.number,
            year: types.number,
            monthName: types.string,
            user: EmployerDictionaryItem,
            days: types.array(WorkloadDay),
            workedOutHours: types.number,
            monthHours: types.number,
            requiredHours: types.number,
            overtimeScale: types.number,
            overheadHours: types.number,
            overtimeHours: types.model({
                totalHours: types.number,
                dayOffHours: types.number,
            }),
            workedOutFromHomeHours: types.number,
            illnessHours: types.number,
            vacationHours: types.number,
            vacationWorkedHours: types.number,
            missingHours: types.number,
            otherMissingHours: types.map(types.number),
        })
    )
    .views((self) => ({
        get totalOtherMissingHours() {
            return Array.from(self.otherMissingHours.values()).reduce((sum, hours) => sum + hours, 0);
        },

        get hoursDiff() {
            return self.workedOutHours - self.requiredHours;
        },
    }))
    .views((self) => ({
        get truncated() {
            return {
                name: self.user.label + "\r\n" + self.user.position.substr(0, 30),
                position: self.user.position,
                requiredHours: formatHours(self.requiredHours, true),
                workedOutHours: formatHours(self.workedOutHours, true),
                overtimeHours: formatHours(self.overtimeHours.totalHours, true),
                dayOffHours: formatHours(self.overtimeHours.dayOffHours, true),
                workedOutFromHomeHours: formatHours(self.workedOutFromHomeHours, true),
                overheadHours: formatHours(self.overheadHours, true),
                missingHours: formatHours(self.missingHours, true),
                vacationHours: formatHours(self.vacationHours, true),
                illnessHours: formatHours(self.illnessHours, true),
                totalOtherMissingHours: formatHours(self.totalOtherMissingHours, true),
                overtimeScale: self.overtimeScale,
            };
        },
    }))
    .actions((self) => ({
        save: flow(function* (values: any) {
            try {
                const overtimeScale = parseInt(values[fields.overtimeScale], 10);

                yield self.transport.post<any>(apiUrls.schedule.overtimeScale, {
                    ...values,
                    month: self.month,
                    year: self.year,
                    employerId: self.user.id,
                });

                self.overtimeScale = overtimeScale;
                self.notify.success(texts.messages.saved);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .named("UserWorkload");

export type UserWorkloadType = typeof UserWorkload.Type;
export type UserWorkloadSnapshotType = typeof UserWorkload.SnapshotType;

export const fields = {
    overtimeScale: nameof((a: UserWorkloadType) => a.overtimeScale) as string,
};

export function formatHours(hours: number, asText = false) {
    return hours > 0 ? `${hours} ч.` : asText ? "-" : <>&ndash;</>;
}
