import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { IpdType, IpdTypeType, IpdTypeSnapshotType, fields } from "./ipd-type";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
// import { getIn } from "formik";
// import { orderBy } from "lodash";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { Queryable } from "modules/common/models/queryable";

export const IpdTypeList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            ipdTypes: types.array(IpdType),
            sorter: TableSorter,
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<IpdTypeType> {
            return self.ipdTypes.reduce((result, ipdType) => {
                result[ipdType.id] = ipdType;
                return result;
            }, {} as TStringMap<IpdTypeType>);
        },

        get data() {
            const predicate = (o: IpdTypeType) => {
                let result = true;
                if (self.pureQuery) {
                    result = result && `${o.name} ${o.description} `.toLowerCase().indexOf(self.pureQuery) >= 0;
                }
                return result;
            };
            return self.ipdTypes.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));
            // const order = self.sorter.asc ? "asc" : "desc";

            // return orderBy(getSnapshot(self.ipdTypes), [(o) => getIn(o, self.sorter.column)], [order]);
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: any = yield self.transport.get<IpdTypeSnapshotType[]>(apiUrls.ipdTypes.list());
                applySnapshot(self.ipdTypes, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        delete: flow(function* (ipdType: IpdTypeSnapshotType) {
            const model = IpdType.create(ipdType, {
                http: self.transport,
                notificator: self.notify,
            });
            return yield model.delete();
        }),
    }))
    .named("IpdTypeList");

export type IpdTypesListType = typeof IpdTypeList.Type;
export type IpdTypeListSnapshotType = typeof IpdTypeList.SnapshotType;
export { IpdType };

const sortStorage = getSortOption(IpdTypeList.name);

export const initialState = (): IpdTypeListSnapshotType => {
    const options = sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        ipdTypes: [],
        pureQuery: "",
        query: "",
        sorter: {
            id: IpdTypeList.name,
            tableName: IpdTypeList.name,
            column: options.column,
            asc: options.asc,
        },
    };
};
