import React from "react";
import { apiUrls } from "modules/common/services/communication/urls";
import { RemoveConfirmation } from "../form/RemoveConfirmation";
import { GeneralIcon } from "../planr/icon/Generalcon";
import { stopPropagation } from "modules/common/services/form/values";
import classnames from "classnames";

interface FileLinkProps<T> {
    baseUrl: string;
    file: T;
    removeLabel?: (file: T) => string;
    fileLabel?: (file: T) => React.ReactNode;
    onRemove: (file: T) => void;
    readOnly?: boolean;
    righted?: boolean;
    style?: React.CSSProperties;
    textWidth?: string;
    title?: string;
    highlight?: boolean;
    littleIcon?: boolean;
    children?: any;
}

export const FileLink = <TFile extends FileEssentials>(props: FileLinkProps<TFile>) => {
    const {
        baseUrl,
        file,
        removeLabel,
        fileLabel,
        onRemove,
        readOnly,
        righted,
        style,
        textWidth,
        highlight,
        littleIcon,
        children,
        title,
    } = props;

    const stringLabel = removeLabel ? removeLabel(file) : file.fileName;
    const src = baseUrl + apiUrls.application.files.content(file.fileId, stringLabel);

    const remover = readOnly ? null : (
        <RemoveConfirmation<TFile>
            what={() => stringLabel}
            render={({ confirmRemoving }) => (
                <>
                    {righted && <span>&nbsp;&nbsp;</span>}
                    <GeneralIcon
                        type="general-trash"
                        onClick={(e) => {
                            stopPropagation(e);
                            confirmRemoving(file);
                        }}
                        style={{ color: "#E31818", cursor: "pointer", position: "absolute", right: "20px" }}
                    />
                    {!righted && <span>&nbsp;&nbsp;</span>}
                </>
            )}
            onConfirmed={onRemove}
        />
    );

    if (children) {
        return (
            <div style={style}>
                {righted && remover}
                <a
                    href={src}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ overflow: "hidden", textDecoration: "none", maxWidth: textWidth }}
                    onClick={stopPropagation}
                >
                    {children}
                </a>
            </div>
        );
    }

    if (littleIcon) {
        return (
            <div style={style}>
                {righted && remover}
                <a
                    href={src}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ overflow: "hidden", maxWidth: textWidth }}
                    onClick={stopPropagation}
                >
                    <GeneralIcon
                        type="general-document"
                        style={{
                            cursor: "pointer",
                            color: "#369CD7",
                        }}
                        title={title}
                    />
                </a>
            </div>
        );
    }

    return (
        <div
            className={classnames({
                "file-link": true,
                highlight: highlight,
            })}
            style={style}
        >
            {righted && remover}

            <GeneralIcon type="general-document" style={{ position: "absolute", left: "20px" }} />
            <a
                href={src}
                target="_blank"
                rel="noopener noreferrer"
                style={{ maxWidth: textWidth, overflow: "hidden" }}
                onClick={stopPropagation}
            >
                {fileLabel ? fileLabel(file) : file.fileName}
            </a>
            {!righted && remover}
        </div>
    );
};
