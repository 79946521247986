import React from 'react';
import { ProtectedRoute } from 'modules/common/components/routing/ProtectedRoute';
import { routes } from 'modules/common/routes';
import { LoggedInProps } from 'modules/common/components/routing/AnonymousRoute';
import { SessionType } from 'modules/session/auth/models/session';
import func from './functionalities';
import { can } from 'modules/session/auth/access';
import { TechnicalTabsPage } from './components/TechnicalTabsPage';
import { KnowledgeBaseStoreType } from './models/store';
import { KnowledgeBasePage } from './components/knowledge-base/KnowledgeBasePage';

export const Routes = ({ loggedIn, session, manuals, standards, templates, organisations, orders }: RoutesProps) => {
    return (
        <ProtectedRoute
            path={routes.technical.path}
            loggedIn={loggedIn}
            render={(props) => (
                <TechnicalTabsPage
                    {...props}

                    manualsPanel={can(func.TECHICAL_MANUALS_READ, session.access) ? (
                        <KnowledgeBasePage store={manuals} session={session} />
                    ) : null}

                    standardPanel={can(func.TECHICAL_QUALITY_STANDARDS_READ, session.access) ? (
                        <KnowledgeBasePage store={standards} session={session} />
                    ) : null}

                    templatesPanel={can(func.TECHICAL_TEMPLATES_READ, session.access) ? (
                        <KnowledgeBasePage store={templates} session={session} />
                    ) : null}

                    organisationsPanel={can(func.TECHICAL_ORGANISATIONS_READ, session.access) ? (
                        <KnowledgeBasePage store={organisations} session={session} />
                    ) : null}

                    ordersPanel={can(func.TECHICAL_ORDERS_READ, session.access) ? (
                        <KnowledgeBasePage store={orders} session={session} />
                    ) : null}
                />
            )}
        />
    );
};

interface RoutesProps extends LoggedInProps {
    manuals: KnowledgeBaseStoreType;
    standards: KnowledgeBaseStoreType;
    templates: KnowledgeBaseStoreType;
    organisations: KnowledgeBaseStoreType;
    orders: KnowledgeBaseStoreType;
    session: SessionType;
}
