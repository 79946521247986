import React from "react";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Block } from "modules/common/components/page/Block";
import { Route, withRouter, RouteComponentProps, matchPath } from "react-router";
import { routes } from "modules/common/routes";
import { UnregisterCallback } from "history";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { OrderIndicatorsStoreType } from "../models/order-indcator-store";
import { OrderIndicatorDetails } from "./details/OrderIndicatorDetails";

import { menuItems } from "modules/root/components/menu/MainMenu";
import func from "modules/dictionaries/functionalities";
import { can } from "modules/session/auth/access";

import styles from "./OrderIndicatorsPage.module.scss";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { Search } from "modules/common/components/form/Search";
import { OrderIndicatorsList } from "./list/OrderIndicatorsList";
import { Link } from "react-router-dom";
import { IndicatorsStoreType } from "../models/indicator-store";

const menu = menuItems();

class OrderIndicatorsPage extends React.Component<
  OrderIndicatorsPageProps & RouteComponentProps,
  OrderIndicatorsPageState
> {
  private unregister: UnregisterCallback | null = null;

  constructor(props: OrderIndicatorsPageProps & RouteComponentProps) {
    super(props);

    this.state = { typeId: getSelectedOrderIndicatorId(props.location.pathname) };
  }

  componentDidMount() {
    document.title = menu.dictionaries.nested.orderIndicators.label;

    const { history, indicators } = this.props;
    const { typeId } = this.state;
    indicators.list.load();
    this.loadList();
    this.loadDetails(typeId);

    this.unregister = history.listen((location) => {
      const typeId = getSelectedOrderIndicatorId(location.pathname);

      if (this.state.typeId !== typeId) {
        this.setState({ typeId });
        this.loadDetails(typeId);
      }
    });
  }

  componentWillUnmount() {
    this.unregister && this.unregister();
  }

  render() {
    const { store, access, indicators } = this.props;
    const canChange = can(func.ORDERINDICATOR_EDIT, access);

    return (
      <Block columned={true} className={styles.page}>
        <Route path={routes.dictionaries.orderIndicator.details()}>
          <DetailsPopup title="ТЭП" onCancel={this.onOrderIndicatorSelected}>
            <OrderIndicatorDetails
              canChange={canChange}
              orderIndicator={store.details}
              onSaved={this.onOrderIndicatorSaved}
              onRemoved={this.onOrderIndicatorRemoved}
              indicators={indicators.list}
            />
          </DetailsPopup>
        </Route>
        <div className={styles.tools}>
          {canChange && (
            <PlanrButton
              type="secondary"
              size="small"
              icon="general-plus-big"
              onClick={this.onOrderIndicatorAdd}
              style={{
                width: "200px",
                marginBottom: "32px",
                borderRadius: "120px",
              }}
            >{`${texts.add} ТЭП`}</PlanrButton>
          )}
          <Link to={routes.dictionaries.orderIndicator.pathIndicators} className="planr-button-link">
            <PlanrButton
              type="lightblueish"
              size="small"
              style={{
                width: "200px",
                marginBottom: "32px",
                borderRadius: "120px",
              }}
            >
              Ед. измерения
            </PlanrButton>
          </Link>
        </div>
        <div className={styles.mobileTools}>
          {canChange && (
            <PlanrButton type="secondary" round icon="general-plus-big" onClick={this.onOrderIndicatorAdd} />
          )}
        </div>
        <div className={styles.right}>
          <Search query={store.list.query} onSearch={store.list.setQuery} />
        </div>
        <div className={styles.mobileTools} style={{ marginTop: "10px" }}>
          <Link to={routes.dictionaries.orderIndicator.pathIndicators} className="planr-button-link">
            <PlanrButton
              type="lightblueish"
              size="small"
              style={{
                width: "200px",

                borderRadius: "120px",
              }}
            >
              Ед. измерения
            </PlanrButton>
          </Link>
        </div>
        <OrderIndicatorsList
          store={store.list}
          onSelected={this.onOrderIndicatorSelected}
          onRemoved={this.onOrderIndicatorRemoved}
        />
      </Block>
    );
  }

  onOrderIndicatorAdd = () => {
    this.onOrderIndicatorSelected(EMPTY_OBJECT_ID);
  };

  onOrderIndicatorSelected = (id: string = "") => {
    const { history } = this.props;

    const url = id ? routes.dictionaries.orderIndicator.details(id) : routes.dictionaries.orderIndicator.path;

    history.push(url);
  };

  onOrderIndicatorSaved = () => {
    this.onOrderIndicatorSelected("");
    this.loadList();
  };

  onOrderIndicatorRemoved = () => {
    const { history } = this.props;

    this.loadList();

    const url = routes.dictionaries.orderIndicator.path;
    history.push(url);
  };

  loadList = () => this.props.store.list.load();

  loadDetails = (id: string) => this.props.store.details.load(id);
}

export default withRouter(observer(OrderIndicatorsPage));

function getSelectedOrderIndicatorId(pathname: string) {
  const match = matchPath<OrderIndicatorsPageState>(pathname, routes.dictionaries.orderIndicator.details());

  return match && match.params ? match.params.typeId : "";
}

interface OrderIndicatorsPageProps {
  store: OrderIndicatorsStoreType;
  indicators: IndicatorsStoreType;
  access: string[];
}

interface OrderIndicatorsPageState {
  typeId: string;
}
