import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { WorkType, WorkTypeType, WorkTypeSnapshotType, fields } from "./work-type";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
import { getSortOption, SortOptions } from "modules/common/services/table/sorting-storage";
// import { toJsonHard } from "modules/common/services/mobx/serialize";
import { Queryable } from "modules/common/models/queryable";

export const WorkTypeList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            workTypes: types.array(WorkType),
            sorter: TableSorter,
            categoryId: types.string,
        })
    )
    .views((self) => {
        const views = {
            get asMap(): TStringMap<WorkTypeType> {
                return self.workTypes.reduce((result, workType) => {
                    result[workType.id] = workType;
                    return result;
                }, {} as TStringMap<WorkTypeType>);
            },

            get data() {
                const predicate = (o: WorkTypeType) => {
                    let result = true;
                    if (self.pureQuery) {
                        result =
                            result &&
                            `${o.name} ${o.description} ${o.workTypeCategory}`.toLowerCase().indexOf(self.pureQuery) >=
                                0;
                    }
                    return result;
                };
                return self.workTypes.filter(predicate).map((order) => ({
                    ...getSnapshot(order),
                }));
                // return self.workTypes.map(toJsonHard);
            },
        };

        return views;
    })
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: any = yield self.transport.get<WorkTypeSnapshotType[]>(apiUrls.workTypes.list());
                applySnapshot(self.workTypes, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        delete: flow(function* (workType: WorkTypeSnapshotType) {
            const model = WorkType.create(workType, {
                http: self.transport,
                notificator: self.notify,
            });
            return yield model.delete();
        }),

        setCategoryId(id: string) {
            self.categoryId = id;
        },
    }))
    .named("WorkTypeList");

export type WorkTypesListType = typeof WorkTypeList.Type;
export type WorkTypeListSnapshotType = typeof WorkTypeList.SnapshotType;
export { WorkType };

const sortStorage = getSortOption(WorkTypeList.name);

export const initialState = (sortOptions: SortOptions | null = null): WorkTypeListSnapshotType => {
    const options = sortOptions || sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        workTypes: [],
        pureQuery: "",
        query: "",
        sorter: {
            id: WorkTypeList.name,
            tableName: WorkTypeList.name,
            column: options.column,
            asc: options.asc,
        },
        categoryId: "",
    };
};
