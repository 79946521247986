import { types } from "mobx-state-tree";
import { DepartmentList, Department, initialState as emptyList } from "./department-list";
import { initialState as emptyDepartment } from "./department";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";

export const DepartmentsStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            list: DepartmentList,
            details: Department,
        })
    )
    .named("DepartmentsStore");

export const initialState = (): typeof DepartmentsStore.SnapshotType => ({
    list: emptyList(),
    details: emptyDepartment(),
});

export type DepartmentsStoreType = typeof DepartmentsStore.Type;
