import { types, applySnapshot, isAlive } from "mobx-state-tree";
import { BaseEntity, isNewlyCreated, DictionaryItem } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID, DATE_TIME_FORMAT } from "modules/common/constants";
import Schema from "../components/details/validation";
import moment from "moment";
import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";
import { getFieldLabel } from "modules/common/services/form/fields";
import { ShortBankDetailsSnapshotType } from "modules/common/models/bank-details";

export const ProjectPortfolioDictionaryItem = DictionaryItem.named("ProjectPortfolioDictionaryItem");

const ProjectPortfolioBase = types.compose(
    Transport,
    Notificator,
    BaseEntity,
    types.model({
        name: types.string,
        description: types.string,
    })
);

export const ProjectPortfolio = ProjectPortfolioBase.actions((self) => ({
    load: flow(function* (id: string) {
        try {
            const snapshot = isNewlyCreated(id)
                ? initialState()
                : yield self.transport.get<ProjectPortfolioSnapshotType>(apiUrls.projectPortfolios.details(id));

            applySnapshot(self, snapshot);
        } catch (er) {
            self.notify.error(er);
        }
    }),

    save: flow(function* (model: ProjectPortfolioSnapshotType) {
        try {
            const snapshot = self.isNewlyCreated
                ? yield self.transport.put<ProjectPortfolioSnapshotType>(apiUrls.projectPortfolios.create(), model)
                : yield self.transport.post<ProjectPortfolioSnapshotType>(
                      apiUrls.projectPortfolios.update(self.id),
                      model
                  );

            isAlive(self) && applySnapshot(self, snapshot);
            self.notify.success(texts.messages.saved);

            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    delete: flow(function* () {
        if (self.isNewlyCreated) {
            return true;
        }

        try {
            yield self.transport.delete<boolean>(apiUrls.projectPortfolios.delete(self.id));
            self.notify.success(texts.messages.removed);

            isAlive(self) && applySnapshot(self, initialState());
            return true;
        } catch (er) {
            self.notify.error(er);
            return false;
        }
    }),

    emptyBankDetails: flow(function* () {
        const guid = yield self.generateGuid();
        const details: ShortBankDetailsSnapshotType = {
            guid,
            account: "",
            bankIdentificator: "",
        };
        return details;
    }),
})).named("ProjectPortfolio");

export type ProjectPortfolioDictionaryItemType = typeof ProjectPortfolioDictionaryItem.Type;
export type ProjectPortfolioSnapshotType = typeof ProjectPortfolioBase.SnapshotType;
export type ProjectPortfolioType = typeof ProjectPortfolio.Type;

export const initialState = (): ProjectPortfolioSnapshotType => ({
    id: EMPTY_OBJECT_ID,
    created: moment().format(DATE_TIME_FORMAT),
    name: "",
    description: "",
});

export const fields = {
    name: nameof((a: ProjectPortfolioType) => a.name) as string,
    description: nameof((a: ProjectPortfolioType) => a.description) as string,
};

export function formatProjectPortfolio(projectPortfolio: ProjectPortfolioSnapshotType | null) {
    let result = "";

    if (projectPortfolio) {
        const schema = Schema();

        result += `${getFieldLabel(fields.name, schema, null)}: ${projectPortfolio.name}\n`;
        result += `${getFieldLabel(fields.description, schema, null)}: ${projectPortfolio.description}\n`;
    }

    return result;
}
