import { Classes, InputGroup, TextArea } from "@blueprintjs/core";
import classnames from "classnames";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { getDragItemStyle } from "modules/common/services/drag";
import { preventSubmitKeyDown } from "modules/common/services/form/values";
import { formatPhoneNumber } from "modules/common/services/formatting/phone";
import { texts } from "modules/common/texts";
import { EmployerDictionaryType } from "modules/spending/employee/models/employee-dictionary";
import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { SettingsStoreType } from "../../../session/settings/models/settings";
import { OrderCommunicationRowSnapshotType } from "../../models/order-communication";
import { MentorSelectRenderer } from "./OrderCommunication";
import styles from "./OrderCommunicationRow.module.scss";

export class OrderCommunicationRow extends React.Component<OrderCommunicationRowProps> {
    render() {
        const {
            row,
            readOnly,
            settings,
            active,
            onInput,
            onRemove,
            isDragging,
            draggable,
            employers,
            employerShow,
            onActiveEmployer,
            responses,
            setResponse,
            removeResponse,
            addResponse,
        } = this.props;

        const currentEmployer = active && active.employer && employers.employee.find((em) => em.id === active.employer);
        const employer = row && row.employer && employers.employee.find((em) => em.id === row.employer);

        let savedMentor = currentEmployer
            ? { id: currentEmployer.id, label: currentEmployer.name }
            : {
                  id: "",
                  label: "",
              };
        const company = employer ? settings.model.shortCompanyName : row ? row.employerCompanyName : "";
        const phone = employer ? employer.phone : row ? row.employerPhone : "";
        const employerName = employer ? employer.name : row.employerName;

        const rowOnEdit = active && active.guid === row.guid;

        return (
            <div
                ref={draggable.innerRef}
                {...draggable.draggableProps}
                {...draggable.dragHandleProps}
                style={getDragItemStyle(isDragging, draggable.draggableProps.style)}
                className={classnames({
                    [styles.row]: true,
                    [styles.rowEdit]: rowOnEdit && active,
                    // [styles.highlighted]: !!ipd.documentFileId,
                    // [styles.inquiried]: !!ipd.requestFileId && !ipd.documentFileId,
                })}
            >
                {!rowOnEdit && !readOnly && (
                    <div className={styles.iconCell}>
                        <PlanrButton
                            type="neutral"
                            icon="general-edit"
                            onClick={this.activate}
                            size="small"
                            style={{ color: "#EA561E" }}
                        />
                    </div>
                )}
                {rowOnEdit && active && (
                    <div className={styles.iconCell}>
                        <PlanrButton
                            type="neutral"
                            icon="general-redo"
                            onClick={this.onSave}
                            size="small"
                            style={{ color: "#1DD278" }}
                        />
                        {/* <SaveIcon className="action-icon" onClick={this.onSave} /> */}
                    </div>
                )}
                <div className={styles.responsesBlock}>
                    {rowOnEdit && active && (
                        <div className={styles.responses}>
                            {responses &&
                                responses
                                    .filter((r) => r.activeGuid === active.guid)
                                    .map((res) => {
                                        return (
                                            <div key={res.guid} className={styles.responsesRow}>
                                                <InputGroup
                                                    type="text"
                                                    autoComplete="off"
                                                    className="planr-default-input"
                                                    data-lpignore="true"
                                                    value={res.value}
                                                    required={true}
                                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                        setResponse(e.currentTarget.value, res.guid);
                                                    }}
                                                />
                                                <PlanrButton
                                                    type="neutral"
                                                    icon="general-trash"
                                                    onClick={() => {
                                                        removeResponse(res.guid);
                                                    }}
                                                    size="small"
                                                    title={texts.remove}
                                                />
                                            </div>
                                        );
                                    })}
                            <div className={styles.addRows}>
                                <PlanrButton
                                    size="small"
                                    type="secondary"
                                    onClick={() => {
                                        addResponse("", active.guid);
                                    }}
                                >
                                    Добавить
                                </PlanrButton>
                            </div>
                        </div>
                    )}
                    {!rowOnEdit &&
                        row &&
                        row.responses &&
                        row.responses.map((res, index) => <div key={index}>{res}</div>)}
                </div>

                <div className={styles.employer}>
                    {rowOnEdit && active && (
                        <div style={{ width: "100%" }}>
                            {employerShow.show === "" ? (
                                <div>
                                    {" "}
                                    <PlanrButton
                                        type="secondary"
                                        style={{ marginBottom: "10px", width: "221px" }}
                                        size="small"
                                        onClick={() => {
                                            onActiveEmployer({ guid: employerShow.guid, show: "dictionary" });
                                        }}
                                    >
                                        Выбрать из справочника
                                    </PlanrButton>
                                    <PlanrButton
                                        type="secondary"
                                        size="small"
                                        style={{ marginLeft: "0px", marginBottom: "10px", width: "221px" }}
                                        onClick={() => {
                                            onActiveEmployer({ guid: employerShow.guid, show: "self" });
                                        }}
                                    >
                                        Добавить вручную
                                    </PlanrButton>
                                </div>
                            ) : employerShow.show === "dictionary" ? (
                                <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                                    <MentorSelectRenderer
                                        item={savedMentor}
                                        items={employers.employee}
                                        style={{ width: "350px" }}
                                        className={styles.mentorSelect}
                                        disabled={false}
                                        onSelect={(item: SelectItem) => {
                                            onInput({ ...active, employer: item.id });
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className={styles.rowsWrapper}>
                                    <div className={styles.rowItem}>
                                        <div className={styles.rowItemLabel}>ФИО</div>
                                        <InputGroup
                                            type="text"
                                            autoComplete="off"
                                            className="planr-default-input"
                                            data-lpignore="true"
                                            style={{ width: "290px" }}
                                            value={active.employerName ? active.employerName : ""}
                                            required={true}
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                onInput({ ...active, employerName: e.currentTarget.value });
                                            }}
                                            onKeyDown={preventSubmitKeyDown}
                                        />
                                    </div>
                                    <div className={styles.rowItem}>
                                        <div className={styles.rowItemLabel}>Телефон</div>
                                        <InputGroup
                                            type="text"
                                            autoComplete="off"
                                            className="planr-default-input"
                                            data-lpignore="true"
                                            style={{ width: "290px" }}
                                            value={active.employerPhone ? active.employerPhone : ""}
                                            required={true}
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                onInput({ ...active, employerPhone: e.currentTarget.value });
                                            }}
                                            onKeyDown={preventSubmitKeyDown}
                                        />
                                    </div>
                                    <div className={styles.rowItem}>
                                        <div className={styles.rowItemLabel}>Эл. почта</div>
                                        <InputGroup
                                            type="text"
                                            autoComplete="off"
                                            className="planr-default-input"
                                            data-lpignore="true"
                                            style={{ width: "290px" }}
                                            value={active.employerEmail ? active.employerEmail : ""}
                                            required={true}
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                onInput({ ...active, employerEmail: e.currentTarget.value });
                                            }}
                                            onKeyDown={preventSubmitKeyDown}
                                        />
                                    </div>
                                    <div className={styles.rowItem}>
                                        <div className={styles.rowItemLabel}>Компания</div>
                                        <InputGroup
                                            type="text"
                                            autoComplete="off"
                                            className="planr-default-input"
                                            data-lpignore="true"
                                            style={{ width: "290px" }}
                                            value={active.employerCompanyName ? active.employerCompanyName : ""}
                                            required={true}
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                onInput({ ...active, employerCompanyName: e.currentTarget.value });
                                            }}
                                            onKeyDown={preventSubmitKeyDown}
                                        />
                                    </div>
                                </div>
                            )}
                            {employerShow.show !== "" && (
                                <PlanrButton
                                    type="graybtn"
                                    size="small"
                                    style={{ marginTop: "10px", marginLeft: "125px", marginBottom: "10px" }}
                                    onClick={() => {
                                        onInput({
                                            ...active,
                                            employerEmail: "",
                                            employerName: "",
                                            employer: "",
                                            employerPhone: "",
                                        });
                                        onActiveEmployer({ guid: employerShow.guid, show: "" });
                                    }}
                                >
                                    Удалить
                                </PlanrButton>
                            )}
                        </div>
                    )}
                    {!rowOnEdit && <div>{employerName}</div>}
                    {!rowOnEdit && <div>{formatPhoneNumber(phone)}</div>}
                    {!rowOnEdit && <div>{company}</div>}
                </div>

                <div className={styles.number}>
                    {rowOnEdit && active && (
                        <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                            <TextArea
                                value={active.timeDescription}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    onInput({ ...active, timeDescription: e.currentTarget.value });
                                }}
                                growVertically={true}
                                className={Classes.FILL}
                                style={{ maxHeight: "220px" }}
                            />
                        </div>
                    )}
                    {!rowOnEdit && row.timeDescription}
                </div>

                {!readOnly && !active && (
                    <div className={styles.iconCell}>
                        <RemoveConfirmation
                            onConfirmed={onRemove}
                            what={(w) => {
                                return "строку коммуникаций";
                            }}
                            render={({ confirmRemoving }) => {
                                return (
                                    <PlanrButton
                                        type="neutral"
                                        icon="general-trash"
                                        onClick={() => confirmRemoving(row)}
                                        size="small"
                                        title={texts.remove}
                                    />
                                );
                            }}
                        />
                    </div>
                )}
                {rowOnEdit && active && (
                    <div className={styles.iconCell}>
                        <PlanrButton type="neutral" icon="general-undo" onClick={this.deactivate} size="small" />
                    </div>
                )}
            </div>
        );
    }

    activate = () => {
        this.props.onActivate(this.props.row);
    };

    onSave = () => {
        const { active } = this.props;

        if (active) {
            this.props.onSave(active);
        }
    };

    deactivate = () => this.props.onActivate(null);
}

interface OrderCommunicationRowProps {
    row: OrderCommunicationRowSnapshotType;
    onSave: (ipd: OrderCommunicationRowSnapshotType) => void;
    onRemove: (ipd: OrderCommunicationRowSnapshotType) => void;
    readOnly: boolean;
    settings: SettingsStoreType;
    active: OrderCommunicationRowSnapshotType | null;
    onActivate: (ipd: OrderCommunicationRowSnapshotType | null) => void;
    onActiveEmployer: (item: { guid: string; show: "dictionary" | "self" | "" }) => void;
    isDragging: boolean;
    draggable: DraggableProvided;
    onInput: (active: OrderCommunicationRowSnapshotType) => void;
    employers: EmployerDictionaryType;
    employerShow: {
        guid: string;
        show: "dictionary" | "self" | "";
    };
    responses: { guid: string; value: string; activeGuid: string }[];
    setResponse: (v: string, g: string) => void;
    removeResponse: (g: string) => void;
    addResponse: (v: string, activeGuid: string) => void;
}
