import { types, applySnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { groupBy } from "lodash";
import {
    WorkTypeDictionaryItem,
    WorkTypeDictionaryItemType,
    WorkTypeDictionaryItemSnapshotType as Pass,
} from "./work-type";
import { Queryable } from "modules/common/models/queryable";

export const WorkTypeDictionary = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            workTypes: types.array(WorkTypeDictionaryItem),
        })
    )
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: WorkTypeDictionaryItemType[] = yield self.transport.get<any>(
                    apiUrls.workTypes.dictionary()
                );
                applySnapshot(self.workTypes, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .views((self) => ({
        get filtered() {
            return filter(self.workTypes, self.pureQuery);
        },
    }))
    .views((self) => ({
        get isEmpty() {
            return !self.workTypes.length;
        },

        get asMap() {
            return groupBy(self.workTypes, (type) => type.workTypeCategory);
        },

        get filteredAsMap() {
            const groups = groupBy(filter(self.workTypes, self.pureQuery), (type) => type.workTypeCategory);
            return groups;
        },
    }))
    .named("WorkTypeDictionary");

function filter(data: WorkTypeDictionaryItemType[], query: string) {
    return data.filter((type) => {
        const text = `${type.label}${type.description}`.toLowerCase();
        return text.includes(query);
    });
}

export type WorkTypeDictionaryType = typeof WorkTypeDictionary.Type;
export type WorkTypeDictionarySnapshotType = typeof WorkTypeDictionary.SnapshotType;
export type WorkTypeDictionaryItemSnapshotType = Pass;

export const initialState = (): WorkTypeDictionarySnapshotType => ({
    workTypes: [],
    pureQuery: "",
    query: "",
});
