import React from "react";
import { LoggedInProps } from "modules/common/components/routing/AnonymousRoute";
import { ProtectedRoute } from "modules/common/components/routing/ProtectedRoute";
import { routes } from "modules/common/routes";
import { FinanceRequestsPage } from "./finance-requests/components/FinanceRequesPage";
import { FinanceRequestsStoreType } from "./finance-requests/models/finance-requests-store";
import { SessionType } from "modules/session/auth/models/session";
import { ImportSpendingsStoreType } from "./import/models/import-store";
import { ExpensesPage } from "./components/ExpensesPage";
import { SpendingsListType } from "./summary/models/spending-list";
import { OverheadSpendingsType } from "./overhead/models/overhead-spendings";

export const Routes = ({
    requestsStore,
    importStore,
    spendingsStore,
    overheadStore,
    session,
    loggedIn,
}: RoutesProps) => {
    return (
        <>
            <ProtectedRoute
                path={routes.expenses.path}
                loggedIn={loggedIn}
                render={(props) => (
                    <ExpensesPage
                        {...props}
                        importStore={importStore}
                        spendingsStore={spendingsStore}
                        overheadStore={overheadStore}
                        session={session}
                    />
                )}
            />
            <ProtectedRoute
                path={routes.financeRequests.path}
                loggedIn={loggedIn}
                render={(props) => <FinanceRequestsPage {...props} store={requestsStore} session={session} />}
            />
        </>
    );
};

interface RoutesProps extends LoggedInProps {
    requestsStore: FinanceRequestsStoreType;
    importStore: ImportSpendingsStoreType;
    spendingsStore: SpendingsListType;
    overheadStore: OverheadSpendingsType;
    session: SessionType;
}
