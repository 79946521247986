import React from "react";
import { MenuItem } from "@blueprintjs/core/lib/esnext/components";
import { texts } from "modules/common/texts";
import { EmptySquare } from "modules/common/components/icons/square";
import { MenuItemDef } from "@ag-grid-community/core";

export const EntityContextMenu = ({ onChange, onCopy, onRemove }: EntityContextMenuProps) => {
    return (
        <>
            {onCopy && <MenuItem text={texts.copy} icon="duplicate" onClick={onCopy} />}
            <MenuItem text={texts.change} icon="edit" onClick={onChange} />
            {onRemove && <MenuItem text={texts.remove} icon={<EmptySquare />} onClick={onRemove} />}
        </>
    );
};
const icon = (type: string) => `<svg
width="16"
height="16"
>
<use href="#${type}" />
</svg>`;
// const icon = (type: string) => `<i class="fa ${type}" style="width: 10px" />`;
// export const ChangeIcon = icon("fa-pencil");
// export const CopyIcon = icon("fa-clone");
// export const UndoIcon = icon("fa fa-undo");
// export const RemoveIcon = icon("fa fa-trash");
export const ChangeIcon = icon("general-edit");
export const CopyIcon = icon("general-copy");
export const UndoIcon = icon("general-undo");
export const RemoveIcon = icon("general-trash");

export const agGridEntityContextMenu = ({ onChange, onCopy, onRemove }: EntityContextMenuProps) => {
    let result: MenuItemDef[] = [];

    if (onChange) {
        result.push({
            name: texts.change,
            icon: ChangeIcon,
            action: onChange,
            cssClasses: ["main-menu-font"],
        });
    }

    if (onRemove) {
        result.push({
            name: texts.remove,
            action: onRemove,
            icon: RemoveIcon,
            cssClasses: ["main-menu-font-red"],
        });
    }

    if (onCopy) {
        result = [
            {
                name: texts.copy,
                icon: CopyIcon,
                action: onCopy,
                cssClasses: ["main-menu-font"],
            },
            ...result,
        ];
    }

    return result;
};

interface EntityContextMenuProps {
    onCopy?: () => void;
    onChange?: () => void;
    onRemove?: () => void;
}
