import React, { useCallback } from "react";
import { PremiumRow, EmployerPremiumPaymentSnapshotType } from "modules/orders-manage/models/premium";
import { observer } from "mobx-react";
import { Popover, Classes } from "@blueprintjs/core";
import styles from "./Premium.module.scss";
import { Money } from "modules/common/components/money/Money";
import { PremiumPayments } from "./PremiumPayments";

interface PremiumMoneyProps {
    row: PremiumRow;
    opened: boolean;
    readOnly: boolean | undefined;
    onSelect: (userId: string | null) => void;
    factory: (sum: number) => EmployerPremiumPaymentSnapshotType;
    onSetPayments: (payments: EmployerPremiumPaymentSnapshotType[]) => void;
}

export const PaidMoney = observer(({ opened, row, readOnly, onSelect, factory, onSetPayments }: PremiumMoneyProps) => {
    const clearSelection = useCallback(() => onSelect(null), [onSelect]);
    const selectUser = useCallback(() => onSelect(row.userId), [row, onSelect]);

    return opened ? (
        <Popover
            popoverClassName={Classes.POPOVER_CONTENT_SIZING}
            portalClassName={styles.payments}
            position="right"
            usePortal={true}
            enforceFocus={false}
            isOpen={true}
            interactionKind="click-target"
            modifiers={{
                arrow: { enabled: true },
                flip: { enabled: true },
                keepTogether: { enabled: true },
                preventOverflow: { enabled: true },
            }}
        >
            <Money amount={row.paidSum} className={Classes.TOOLTIP_INDICATOR} />
            <PremiumPayments
                value={row}
                readOnly={readOnly}
                factory={factory}
                onCancel={clearSelection}
                onSave={(value) => {
                    onSetPayments(value.payments);
                    clearSelection();
                }}
            />
        </Popover>
    ) : (
        <Money amount={row.paidSum} className={Classes.TOOLTIP_INDICATOR} onClick={selectUser} />
    );
});
