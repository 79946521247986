import { types } from "mobx-state-tree";
import { IdEntity } from "modules/common/models/entity";
import { NotificationType } from "modules/common/services/notifications/types";

let ID = 1;

export const Notification = types
    .compose(
        IdEntity,
        types.model({
            type: types.enumeration<NotificationType>(["error", "info", "success", "warning"]),
            message: types.string,
        })
    )
    .named("Notification");

export const NotificationStore = types
    .model("NotificationStore", {
        alerts: types.array(Notification),
    })
    .actions((self) => ({
        closeNotification(id: string) {
            const notification = self.alerts.find((a) => a.id === id);
            if (notification) {
                self.alerts.remove(notification);
            }
        },
        addNotification(type: NotificationType, message: string) {
            // if (!self.alerts.find((a) => a.message === message)) {
            self.alerts.push({
                id: (ID++).toString(),
                type,
                message,
            });
            //   }
        },
        addInfoNotification(type: NotificationType, message: string) {
            if (!self.alerts.find((a) => a.message === message)) {
                self.alerts.push({
                    id: (ID++).toString(),
                    type,
                    message,
                });
            }
        },
    }));

export const initialState = (): typeof NotificationStore.SnapshotType => ({
    alerts: [],
});

export type NotificationStoreType = typeof NotificationStore.Type;
