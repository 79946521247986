import { types, getEnv } from "mobx-state-tree";

export const Notificator = types.model("Notificator", {}).views((self) => ({
    get notify() {
        return getEnv(self).notificator as Notifier;
    },
}));

export interface Notifier {
    success: (message: string) => void;
    error: (message: string | Error | unknown) => void;
    warning: (message: string) => void;
    info: (message: string) => void;
}
