import { types, applySnapshot, isAlive } from "mobx-state-tree";
import { BaseEntity, isNewlyCreated, DictionaryItem } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID, DATE_TIME_FORMAT } from "modules/common/constants";
import Schema from "../components/details/validation";
import moment from "moment";
import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";
import { getFieldLabel } from "modules/common/services/form/fields";

export const OrderTypeDictionaryItem = DictionaryItem.named("OrderTypeDictionaryItem");

const OrderTypeBase = types.compose(
    Transport,
    Notificator,
    BaseEntity,
    types.model({
        name: types.string,
        description: types.string,
        fullName: types.string,
    })
);

export const OrderType = OrderTypeBase.actions((self) => ({
    load: flow(function* (id: string) {
        try {
            const snapshot = isNewlyCreated(id)
                ? initialState()
                : yield self.transport.get<OrderTypeSnapshotType>(apiUrls.orderTypes.details(id));

            applySnapshot(self, snapshot);
            return true;
        } catch (er) {
            self.notify.error(er);
            return false;
        }
    }),

    save: flow(function* (model: OrderTypeSnapshotType) {
        try {
            const snapshot = self.isNewlyCreated
                ? yield self.transport.put<OrderTypeSnapshotType>(apiUrls.orderTypes.create(), model)
                : yield self.transport.post<OrderTypeSnapshotType>(apiUrls.orderTypes.update(self.id), model);

            isAlive(self) && applySnapshot(self, snapshot);
            self.notify.success(texts.messages.saved);

            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    delete: flow(function* () {
        if (self.isNewlyCreated) {
            return true;
        }

        try {
            yield self.transport.delete<boolean>(apiUrls.orderTypes.delete(self.id));
            self.notify.success(texts.messages.removed);

            isAlive(self) && applySnapshot(self, initialState());
            return true;
        } catch (er) {
            self.notify.error(er);
            return false;
        }
    }),
})).named("OrderType");

export type OrderTypeSnapshotType = typeof OrderTypeBase.SnapshotType;
export type OrderTypeType = typeof OrderType.Type;
export type OrderTypeDictionaryItemType = typeof OrderTypeDictionaryItem.Type;

export const initialState = (): OrderTypeSnapshotType => ({
    id: EMPTY_OBJECT_ID,
    created: moment().format(DATE_TIME_FORMAT),
    name: "",
    description: "",
    fullName: "",
});

export const fields = {
    name: nameof((a: OrderTypeType) => a.name) as string,
    description: nameof((a: OrderTypeType) => a.description) as string,
    fullName: nameof((a: OrderTypeType) => a.fullName) as string,
};

export function formatOrderType(orderType: OrderTypeSnapshotType | null) {
    let result = "";

    if (orderType) {
        const schema = Schema();

        result += `${getFieldLabel(fields.name, schema, null)}: ${orderType.name}\n`;
        result += `${getFieldLabel(fields.description, schema, null)}: ${orderType.description}\n`;
        result += `${getFieldLabel(fields.fullName, schema, null)}: ${orderType.fullName}\n`;
    }

    return result;
}
