import React, { useCallback } from "react";

import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { FileMetadataType } from "modules/common/models/file-metadata";
import { texts } from "modules/common/texts";
import { ITreeNode } from "@blueprintjs/core/lib/esnext";
import { Prompt } from "modules/common/components/form/Prompt";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import styles from "./FileSystemPage.module.scss";

export const FileActions = ({ file, remove, canManage, rename }: FileActionsProps) => {
    const download = useCallback(() => {
        if (file && file.nodeData) {
            file.nodeData.meta.save();
        }
    }, [file]);

    const onRename = useCallback(
        (name: string) => {
            file && rename(name, file.id as string);
        },
        [file, rename]
    );

    return (
        <div className={styles.folderBtns}>
            {file && (
                <PlanrButton type="graybtn" icon={"general-download"} onClick={download} round={true} title="Скачать" />
                // <div title={texts.download} onClick={download}>
                //     <Icon icon="download" />
                // </div>
            )}
            {file && canManage && (
                <Prompt
                    what="Новое название файла"
                    onConfirmed={onRename}
                    render={({ ask }) => (
                        <PlanrButton
                            type="graybtn"
                            icon={"general-edit"}
                            onClick={() => ask(file.label as string)}
                            round={true}
                            title="Переименовать"
                        />
                        // <div title="Переименовать" onClick={() => ask(file.label as string)}>
                        //     <Icon icon="edit" />
                        // </div>
                    )}
                />
            )}
            {file && canManage && (
                <RemoveConfirmation<ITreeNode<NodeData>>
                    what={(f) => {
                        const prefix =
                            f.nodeData && f.nodeData.version > 1 ? `${f.nodeData.version} версию файла` : "файл";
                        return `${prefix} ${f.label}`;
                    }}
                    onConfirmed={(f) => remove(f.id as string)}
                    render={({ confirmRemoving }) => {
                        return (
                            <PlanrButton
                                type="graybtn"
                                icon={"general-trash"}
                                onClick={() => confirmRemoving(file)}
                                round={true}
                                title={texts.remove}
                            />
                            // <div title={texts.remove} onClick={() => confirmRemoving(file)}>
                            //     <Icon icon='trash' />
                            // </div>
                        );
                    }}
                />
            )}
        </div>
    );
};

export interface NodeData {
    meta: FileMetadataType;
    version: number;
}

interface FileActionsProps {
    canManage: boolean;
    file: ITreeNode<NodeData> | null;
    remove: (id: string) => void;
    rename: (name: string, id: string) => void;
}
