import React from "react";

interface EmailLinkProps {
    value: string;
    label?: string;
}

export const EmailLink = ({ value, label }: EmailLinkProps) => {
    return (
        <a href={`mailto:${value}`} target="_blank" rel="noopener noreferrer">
            {label || value}
        </a>
    );
};
