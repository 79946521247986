import React from "react";
import styles from "./TotalLineUnit.module.scss";

export const TotalLineUnit: React.FC<TotalLineUnitProps> = ({ sortColumn }) => {
    return (
        <div>
            <div className={`total-line ${styles.lineHeader} `}>
                <div
                    style={{ position: "absolute", left: "35px", width: "300px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("name");
                    }}
                >
                    Сотрудник
                </div>
                <div
                    style={{ position: "absolute", left: "365px", width: "300px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("workedPercentSort");
                    }}
                >
                    Отработано %
                </div>
                <div
                    style={{ position: "absolute", left: "800px", width: "140px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("workedOutHours");
                    }}
                >
                    Часы
                </div>
                <div
                    style={{ position: "absolute", left: "950px", width: "260px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("paidMoney");
                    }}
                >
                    Денежные средства
                </div>
                <div
                    style={{ position: "absolute", left: "1222px", width: "180px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("costPerHour");
                    }}
                >
                    Стоимость раб. врем.
                </div>
                <div
                    style={{ position: "absolute", left: "1410px", width: "120px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("percentDifference");
                    }}
                >
                    Разница в %
                </div>
            </div>
        </div>
    );
};

interface TotalLineUnitProps {
    sortColumn: (id: string) => void;
}
