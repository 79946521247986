import { departmentLabels } from "../details/validation";
import { getColumnSize } from "modules/common/services/table/size-storage";
import { fields } from "../../models/department";
import { ColDef } from "@ag-grid-community/core";
import { RowNumberColumn } from "modules/common/services/table/helpers";
import { MAX_INT } from "modules/common/constants";

const labels = departmentLabels();

export const columns = (tableName: string) => {
    const columnSize = getColumnSize(tableName);

    const result: ColDef[] = [
        RowNumberColumn(false, ""),
        {
            cellClass: "centered",
            headerName: "№",
            field: fields.order,
            width: columnSize(fields.order, 60),
            sortable: true,
            resizable: false,
            comparator: (valueA, valueB) => {
                const numA = valueA === null ? MAX_INT : valueA;
                const numB = valueB === null ? MAX_INT : valueB;

                return numA - numB;
            },
        },
        {
            headerName: labels[fields.name],
            field: fields.name,
            width: columnSize(fields.name, 400),
            sortable: true,
            resizable: true,
        },
        {
            headerName: labels[fields.description],
            field: fields.description,
            flex: 1,
            sortable: false,
            resizable: false,
        },
    ];

    return result;
};
