import { OutsourcerSnapshotType } from "modules/agents/outsourcers/models/outsourcer";
import { ONLY_DIGITS_REGEXP } from "modules/common/constants";
import { phoneValidator } from "modules/common/services/form/values";
import { extractDigits } from "modules/common/services/strings";
import { texts } from "modules/common/texts";
import * as yup from "yup";
// import { fields } from "../auth/models/session";
const { object, string } = yup;

export const LegalNameLabel = texts.name;
export const PhysycalNameLabel = "ФИО";

export const MAX_WORK_TYPES = 10;

export const outsourcerLabels = () => ({
  directorName: "Директор",
  memberName: "ФИО представителя",
  site: "Сайт",
  region: "Регион нахождения",
  isAgent: "Доступен как агент",
  address: "Юр.адрес",
  сategories: "Категория",
  materials: "Поставляемое оборудование",
  materialDocuments: "Демонстрационные материалы",
  fullName: "Полное наименование",
  birthday: "Дата рождения",
  phone: "Телефон 1",
  phone2: "Телефон 2",
  phone3: "Телефон 3",
  email: "E-mail для обратной связи",
  comment: texts.comment,
  login: "Логин",
  newPassword: "Пароль",
  inn: "ИНН",
  kpp: "КПП",
  ogrn: "ОГРН",
  okpo: "ОКПО",
  bankName: "Банк",
  corrAccount: "Корр. счет",
  directorNameGenitive: "directorNameGenitive",
  account: "Расчетный счет",
  bankIdentificator: "БИК",
  issueDate: "Когда выдан",
  issuer: "Кем выдан",
  issuerCode: "Код подразделения",
  number: "Номер",
  series: "Серия",
  regAddress: "Адрес регистрации",
  cardNumber: "№ Карты",
  selfEmployed: "Самозанятый",
  selfEmployedAccount: "Справка о рег. в качестве самозанятого",
  selfEmployedAccountDate: "Дата выдачи справки",
});

const OutsourcerSchema = (isLegalEntity: boolean) => {
  const labels = outsourcerLabels();
  // eslint-disable-next-line no-template-curly-in-string
  const digitsMessage = "${path} должен состоять из цифр";

  return object().shape({
    selfEmployed: yup.boolean().label(labels.selfEmployed),

    selfEmployedAccount: yup.string().label(labels.selfEmployedAccount).notRequired(),
    selfEmployedAccountDate: yup
      .date()
      .label(labels.selfEmployedAccountDate)
      .typeError(texts.messages.incorrectDate())
      .notRequired()
      .nullable(),

    cardNumber: string()
      .label(labels.cardNumber)
      .notRequired()
      .transform(function (val, original) {
        return this.isType(val) && val ? extractDigits(val) : val;
      })
      .matches(ONLY_DIGITS_REGEXP),
    regAddress: yup.string().label(labels.regAddress).notRequired(),
    number: yup.string().label(labels.number).notRequired(),
    series: yup.string().label(labels.series).notRequired(),
    issuer: yup.string().label(labels.issuer).notRequired(),
    issuerCode: yup.string().label(labels.issuerCode).notRequired(),
    issueDate: yup
      .date()
      .label(labels.issueDate)
      .typeError(texts.messages.incorrectDate("дата выдачи"))
      .nullable()
      .notRequired(),

    name: isLegalEntity ? string().required().label(LegalNameLabel) : string().required().label(PhysycalNameLabel),
    fullName: isLegalEntity
      ? string().label(labels.fullName).required()
      : string().label(labels.fullName).notRequired(),
    genitiveName: isLegalEntity
      ? string().required().label("Директор (Родит. падеж)")
      : string().required().label("ФИО в родительном падеже"),
    phone: phoneValidator(
      labels.phone,
      string().test("needPhone", "Необходимо указать телефон", function (value) {
        const agent: OutsourcerSnapshotType = this.parent;
        return !!value || !!agent.email;
      })
    ),
    phone2: phoneValidator(labels.phone2),
    phone3: phoneValidator(labels.phone3),
    email: string().label(labels.email).email().required(),
    inn: yup.lazy((inn) =>
      !!inn
        ? string().label(labels.inn).matches(ONLY_DIGITS_REGEXP, digitsMessage)
        : string().label(labels.inn).notRequired()
    ),
    kpp: yup.lazy((kpp) =>
      !!kpp
        ? string().label(labels.kpp).matches(ONLY_DIGITS_REGEXP, digitsMessage)
        : string().label(labels.kpp).notRequired()
    ),

    ogrn: yup.lazy((ogrn) =>
      !!ogrn
        ? string().label(labels.ogrn).matches(ONLY_DIGITS_REGEXP, digitsMessage)
        : string().label(labels.ogrn).notRequired()
    ),

    okpo: yup.lazy((okpo) =>
      !!okpo
        ? string().label(labels.okpo).matches(ONLY_DIGITS_REGEXP, digitsMessage)
        : string().label(labels.okpo).notRequired()
    ),

    account: yup.lazy((account) =>
      !!account
        ? string().label(labels.account).matches(ONLY_DIGITS_REGEXP, digitsMessage)
        : string().label(labels.account).notRequired()
    ),

    bankName: string().label(labels.bankName),

    bankIdentificator: yup.lazy((account) =>
      !!account
        ? string().label(labels.bankIdentificator).matches(ONLY_DIGITS_REGEXP, digitsMessage)
        : string().label(labels.bankIdentificator).notRequired()
    ),

    corrAccount: yup.lazy((corrAccount) =>
      !!corrAccount
        ? string().label(labels.corrAccount).matches(ONLY_DIGITS_REGEXP, digitsMessage)
        : string().label(labels.corrAccount).notRequired()
    ),
    directorName: string().label(labels.directorName).notRequired(),
    address: string().label(labels.address).notRequired(),
    comment: string().label(labels.comment).required(),
    memberName: string().label(labels.memberName).notRequired(),
    site: string().label(labels.site).notRequired(),
    region: string().label(labels.region).notRequired(),
    isAgent: string().label(labels.isAgent).notRequired(),
    сategories: string().label(labels.сategories).notRequired(),
    materials: string().label(labels.materials).notRequired(),
    materialDocuments: string().label(labels.materialDocuments).notRequired(),
    birthday: yup.date().label(labels.birthday).typeError(texts.messages.incorrectDate()).notRequired().nullable(),
    // "bankDetails": isLegalEntity ? bankDetails.required() : bankDetails.nullable().notRequired(),
    // "passport": passport,
  });
};

export default OutsourcerSchema;
