import React from "react";
import { PhotoshopPicker, ColorResult } from "react-color";
import { ContextMenu } from "@blueprintjs/core";
import styles from "./ColorPicker.module.scss";
import { GeneralIcon } from "../planr/icon/Generalcon";

export class ColorPicker extends React.PureComponent<ColorPickerProps, ColorPickerState> {
    constructor(props: ColorPickerProps) {
        super(props);

        this.state = { opened: false, tempColor: props.color };
    }

    componentDidUpdate(prev: ColorPickerProps) {
        if (prev.color !== this.props.color) {
            this.setState({ tempColor: this.props.color });
        }
    }

    render() {
        const { color, className, small, readOnly } = this.props;

        return (
            <div
                className={`${styles.picker} ${!readOnly ? styles.active : ""} ${small ? styles.small : ""} ${
                    className || ""
                }`}
                style={{ backgroundColor: color, position: "relative" }}
                onClick={this.open}
            >
                <GeneralIcon type="general-palette" style={{ position: "absolute", right: "15px", top: "10px" }} />
            </div>
        );
    }

    menu = () => {
        const { tempColor } = this.state;

        return (
            <PhotoshopPicker
                header="Выбор цвета"
                color={tempColor}
                onChangeComplete={this.colorSelected}
                onAccept={this.colorAccepted}
                onCancel={() => this.close()}
            />
        );
    };

    colorSelected = (color: ColorResult) => {
        this.setState({ tempColor: color.hex });
    };

    colorAccepted = () => {
        const { onSelected } = this.props;
        const { tempColor } = this.state;

        onSelected(tempColor.toUpperCase());
        this.close();
    };

    private open = (e: React.MouseEvent<HTMLDivElement>) => {
        const { readOnly } = this.props;
        if (readOnly) {
            return;
        }

        this.setState({ opened: true });

        const menu = this.menu();

        ContextMenu.show(menu, { left: e.clientX, top: e.clientY }, () => {
            this.close(true);
        });
    };

    private close = (automatic: boolean = false) => {
        if (!automatic && ContextMenu.isOpen()) {
            ContextMenu.hide();
        }

        this.setState({ opened: false });
    };
}

interface ColorPickerProps {
    color: string;
    onSelected: (color: string) => void;
    className?: string;
    small?: boolean;
    readOnly?: boolean;
}

interface ColorPickerState {
    opened: boolean;
    tempColor: string;
}
