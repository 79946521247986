import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { EmployeePosition, EmployeePositionType, EmployeePositionSnapshotType, fields } from "./employee-position";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
import { getSortOption, SortOptions } from "modules/common/services/table/sorting-storage";
import { Queryable } from "modules/common/models/queryable";

export const EmployeePositionList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            employeePositions: types.array(EmployeePosition),
            sorter: TableSorter,
            selectedDepartment: types.string,
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<EmployeePositionType> {
            return self.employeePositions.reduce((result, employeePosition) => {
                result[employeePosition.id] = employeePosition;
                return result;
            }, {} as TStringMap<EmployeePositionType>);
        },
        get positions() {
            const predicate = (o: EmployeePositionType) => {
                let result = true;
                if (self.selectedDepartment) {
                    result = result && o.department?.id === self.selectedDepartment;
                }
                if (self.pureQuery) {
                    result = result && `${o.name} ${o.comment} ${o.order}`.toLowerCase().indexOf(self.pureQuery) >= 0;
                }
                return result;
            };
            return self.employeePositions.filter(predicate).map((pos) => ({
                ...getSnapshot(pos),
                [fields.employeeCount]: pos.employeeCount,
            }));
        },
        get data() {
            const predicate = (o: EmployeePositionType) => {
                let result = true;

                if (self.pureQuery) {
                    result = result && `${o.name} ${o.comment} ${o.order}`.toLowerCase().indexOf(self.pureQuery) >= 0;
                }
                return result;
            };
            return self.employeePositions.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));
        },

        get isEmpty() {
            return !self.employeePositions.length;
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: any = yield self.transport.get<EmployeePositionSnapshotType[]>(
                    apiUrls.employeePositions.list()
                );
                applySnapshot(self.employeePositions, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        setDepartment(id: string) {
            self.selectedDepartment = id;
        },

        delete: flow(function* (employeePosition: EmployeePositionSnapshotType) {
            const model = EmployeePosition.create(employeePosition, {
                http: self.transport,
                notificator: self.notify,
            });
            const result = yield model.delete();
            return result;
        }),
    }))
    .named("EmployeePositionList");

export type EmployeePositionsListType = typeof EmployeePositionList.Type;
export type EmployeePositionListSnapshotType = typeof EmployeePositionList.SnapshotType;
export { EmployeePosition };

const sortStorage = getSortOption(EmployeePositionList.name);

export const initialState = (sortOptions: SortOptions | null = null): EmployeePositionListSnapshotType => {
    const options = sortOptions || sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        employeePositions: [],
        pureQuery: "",
        query: "",
        selectedDepartment: "",
        sorter: {
            id: EmployeePositionList.name,
            tableName: EmployeePositionList.name,
            column: options.column,
            asc: options.asc,
        },
    };
};
