import { Classes, Dialog, InputGroup, Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { getFieldLabel } from "modules/common/services/form/fields";
import { onlyDigitsInputKeyPress } from "modules/common/services/form/values";
import React from "react";
import { Schema } from "yup";
import { BonusSalaryPercentType, fields } from "../models/settings";
import styles from "./SystemSettings.module.scss";

import { DictionaryLinkType } from "modules/common/models/dictionary-link";

const digits = onlyDigitsInputKeyPress();

class SystemBase extends React.PureComponent<SystemSettingsProps, SystemState> {
  constructor(props: any) {
    super(props);

    this.state = { showdialog: false, showDanger: false, bonusSalaryPercent: [], employersExclude: [] };
  }

  render() {
    const { schema, showDialog, toggleShowDialog, sendMailTest, topic, text, emailTo, setMail, setTopic, setMessage } =
      this.props;

    return (
      <div className="columns">
        <div className={styles.columns}>
          <div className={styles.column}>
            <StandardFormInput
              name={fields.administratorPositions}
              schema={schema}
              small={true}
              className="planr-form-input"
              inline={false}
              label={
                <span className="planr-input-label-help">
                  {getFieldLabel(fields.administratorPositions, schema, null)} (или несколько через запятую)&nbsp;&nbsp;
                  <Tooltip
                    content="Используется для рассылки уведомлений системным администраторам"
                    position={Position.RIGHT}
                  >
                    <GeneralIcon type="general-question" />
                  </Tooltip>
                </span>
              }
            >
              {({ field }) => (
                <InputGroup
                  {...field}
                  id={field.name}
                  className="planr-default-input"
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.sendEmail}
              schema={schema}
              inline={false}
              small={true}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.emailPassword}
              schema={schema}
              inline={false}
              label={
                <span className="planr-input-label-help">
                  {getFieldLabel(fields.emailPassword, schema, null)} &nbsp;&nbsp;
                  <Tooltip
                    content={
                      <div>
                        <p>{`Перейдите в настройки почтового аккаунта -> Безопасность.`}</p>
                        <p>Создайте "Пароль для внешних приложений".</p>
                      </div>
                    }
                    position={Position.RIGHT}
                  >
                    <GeneralIcon type="general-question" />
                  </Tooltip>
                </span>
              }
              small={true}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.emailServer}
              schema={schema}
              inline={false}
              label={
                <span className="planr-input-label-help">
                  {getFieldLabel(fields.emailServer, schema, null)} &nbsp;&nbsp;
                  <Tooltip
                    content={
                      <div>
                        <p>{`Укажите имя smtp сервера от почтового сервиса (пример: smtp.yandex.ru).`}</p>
                      </div>
                    }
                    position={Position.RIGHT}
                  >
                    <GeneralIcon type="general-question" />
                  </Tooltip>
                </span>
              }
              small={true}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.emailPort}
              schema={schema}
              inline={false}
              small={true}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  onKeyPress={digits}
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.emailReadServer}
              schema={schema}
              inline={false}
              label={
                <span className="planr-input-label-help">
                  {getFieldLabel(fields.emailReadServer, schema, null)} &nbsp;&nbsp;
                  <Tooltip
                    content={
                      <div>
                        <p>{`Укажите имя imap сервера от почтового сервиса (пример: imap.yandex.ru).`}</p>
                      </div>
                    }
                    position={Position.RIGHT}
                  >
                    <GeneralIcon type="general-question" />
                  </Tooltip>
                </span>
              }
              small={true}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.emailReadPort}
              schema={schema}
              inline={false}
              small={true}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  onKeyPress={digits}
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.isSsl}
              schema={schema}
              small={true}
              className="planr-form-input"
              inline={false}
            >
              {({ field, form }) => (
                <PlanrButton
                  type="neutral"
                  icon={field.value ? "general-checkbox" : "general-zero-checkbox"}
                  onClick={() => {
                    form.setFieldValue(field.name, !field.value);
                    form.setFieldTouched(field.name, true);
                  }}
                  style={{ marginTop: "-5px" }}
                />
              )}
            </StandardFormInput>
            <PlanrButton type="dashed" onClick={toggleShowDialog} size="small" style={{ marginTop: "10px" }}>
              Отправить email
            </PlanrButton>

            <Dialog
              title={"Отправка электронного письма"}
              isOpen={showDialog}
              isCloseButtonShown={true}
              style={{ width: "600px" }}
              onClose={toggleShowDialog}
              backdropClassName="standard"
            >
              <div className={`${Classes.DIALOG_BODY} ${styles.dialogBody}`}>
                <div className={styles.label}>Email получателя</div>
                <InputGroup
                  className="planr-default-input"
                  value={emailTo}
                  onChange={(e: any) => {
                    setMail(e.currentTarget.value);
                  }}
                  autoComplete="off"
                  data-lpignore="true"
                />
                <div className={styles.label}>Тема письма</div>
                <InputGroup
                  className="planr-default-input"
                  value={topic}
                  onChange={(e: any) => {
                    setTopic(e.currentTarget.value);
                  }}
                  autoComplete="off"
                  data-lpignore="true"
                />
                <div className={styles.label}>Текст письма</div>
                <InputGroup
                  className="planr-default-input"
                  value={text}
                  onChange={(e: any) => {
                    setMessage(e.currentTarget.value);
                  }}
                  autoComplete="off"
                  data-lpignore="true"
                />
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <PlanrButton
                    type="greenish"
                    onClick={async () => {
                      const res = await sendMailTest();
                      if (res) {
                        toggleShowDialog();
                      }
                    }}
                  >
                    Отправить
                  </PlanrButton>
                </div>
              </div>
            </Dialog>
          </div>

          <div className={styles.column} style={{ marginTop: "5px" }}>
            <StandardFormInput
              name={fields.showMinutes}
              schema={schema}
              small={true}
              className="planr-form-input"
              inline={false}
            >
              {({ field, form }) => (
                <PlanrButton
                  type="neutral"
                  icon={field.value ? "general-checkbox" : "general-zero-checkbox"}
                  onClick={() => {
                    form.setFieldValue(field.name, !field.value);
                    form.setFieldTouched(field.name, true);
                  }}
                  style={{ marginTop: "-5px" }}
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.prevDaysCount}
              schema={schema}
              small={true}
              className="planr-form-input"
              inline={false}
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="number"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.futureDaysCount}
              schema={schema}
              small={true}
              className="planr-form-input"
              inline={false}
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="number"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
          </div>
        </div>
      </div>
    );
  }
}

export const SystemSettings = observer(SystemBase);
interface SystemState {
  showdialog: boolean;
  showDanger: boolean;
  bonusSalaryPercent: BonusSalaryPercentType[];
  employersExclude: DictionaryLinkType[];
}
interface SystemSettingsProps {
  schema: Schema<any>;
  showDialog: boolean;
  sendMailTest: () => Promise<boolean>;
  topic: string;
  text: string;
  emailTo: string;
  toggleShowDialog: () => void;
  setMail: (val: string) => void;
  setTopic: (val: string) => void;
  setMessage: (val: string) => void;
}
