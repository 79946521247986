import { types } from "mobx-state-tree";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { formatDate } from "modules/common/services/formatting/date";
import { ConsignmentFile } from "./order-file";
import moment from "moment";
import { DATE_TIME_FORMAT } from "modules/common/constants";

export const CONSIGNMENT_ACCEPT = "*";

export const OrderConsignment = types
    .compose(
        Transport,
        Notificator,
        types.model({
            stageGuid: types.string,
            created: types.string,
            signDate: types.string,
            number: types.string,
            comment: types.string,
            file: types.maybeNull(ConsignmentFile),
            guid: types.string,
        })
    )
    .views((self) => ({
        get dateAsDate() {
            return self.signDate ? moment(self.signDate, DATE_TIME_FORMAT).toDate() : null;
        },
    }))
    .actions((self) => ({
        setNumber(value: string) {
            self.number = value;
        },

        setDate(value: Date | null) {
            self.signDate = formatDate(value);
        },

        setComment(value: string) {
            self.comment = value;
        },

        uploadFile: flow(function* (file: File) {
            try {
                const model = new FormData();

                model.append("file", file);
                model.append("accept", CONSIGNMENT_ACCEPT);

                const result: UploadFileResult = yield self.transport.post<any>(
                    apiUrls.application.files.upload,
                    model
                );
                const { id, previewMimeType, mimeType } = result;

                self.file = ConsignmentFile.create({
                    fileId: id,
                    fileName: file.name,
                    previewMimeType,
                    mimeType,
                });

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        clearFile() {
            self.file = null;
        },
    }))
    .named("OrderCompletion");

export type OrderConsignmentType = typeof OrderConsignment.Type;
export type OrderConsignmentSnapshotType = typeof OrderConsignment.SnapshotType;

export const emptyConsignment = (guid: string): OrderConsignmentSnapshotType => ({
    number: "",
    created: formatDate(new Date()),
    signDate: "",
    comment: "",
    stageGuid: "",
    guid,
    file: null,
});
