import * as yup from "yup";
import { fields } from "../../models/material-value";
import { texts } from "modules/common/texts";
const { object, string, number, date } = yup;

export const materialValueLabels = () => ({
    [fields.name]: texts.name,
    [fields.comment]: "Примечания",
    [fields.description]: "Характеристики",
    [fields.sum]: "Начальная стоимость",
    [fields.inventoryNumber]: "№",
    [fields.materialValueCategoryId]: "Категория",
    [fields.depreciationGroupId]: "Группа амортизации",
    [fields.employerId]: "Сотрудник",
    [fields.startDate]: "Дата постановки",
    [fields.lifeTime]: "Cрок использ. (мес)",
    [fields.fullInventoryNumber]: "Инв. номер",
    [fields.customInventoryNumber]: "Инв. номер",
});

const MaterialValueSchema = () => {
    const labels = materialValueLabels();

    return object().shape({
        [fields.name]: string().label(labels[fields.name]).required(),
        [fields.fullInventoryNumber]: string().label(labels[fields.fullInventoryNumber]),
        [fields.customInventoryNumber]: string().label(labels[fields.customInventoryNumber]),
        [fields.sum]: number().label(labels[fields.sum]).required().typeError("Неправильный формат числа"),
        [fields.lifeTime]: number().label(labels[fields.lifeTime]).required(),

        [fields.comment]: string().label(labels[fields.comment]).notRequired(),
        [fields.description]: string().label(labels[fields.description]).notRequired(),

        [fields.inventoryNumber]: number().label(labels[fields.inventoryNumber]),
        [fields.startDate]: date()
            .label(labels[fields.startDate])
            .nullable()
            .typeError(texts.messages.incorrectDate())
            .required(),
        [fields.materialValueCategoryId]: string().label(labels[fields.materialValueCategoryId]).required(),
        [fields.depreciationGroupId]: string().label(labels[fields.depreciationGroupId]).required(),
        [fields.employerId]: string().label(labels[fields.employerId]).notRequired(),
    });
};

export default MaterialValueSchema;
