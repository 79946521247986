import React from "react";
import { Tab, Tabs } from "@blueprintjs/core";
import { routes } from "modules/common/routes";
import { Location } from "history";
import { RouteChildrenProps } from "react-router";
import { Page } from "modules/common/components/page/Page";
import styles from "./TokensTabsPage.module.scss";
import { TokensStoreType } from "../models/tokens-store";
import { TokensList } from "./list/TokensList";
import { TokenType } from "../models/token";

export class TokensTabsPage extends React.PureComponent<TokensTabsPageProps & RouteChildrenProps> {
    componentDidMount() {
        this.props.store.init();
    }

    render() {
        const { location, store } = this.props;
        const tabId = location.pathname.includes(routes.tokens.automatic)
            ? routes.tokens.automatic
            : routes.tokens.outer;

        return (
            <Page>
                <Tabs
                    className={`${styles.tabs} planr-tabs-navigation`}
                    id="TokensTabsPage"
                    onChange={this.handleTabChange}
                    selectedTabId={tabId}
                    renderActiveTabPanelOnly={true}
                >
                    <Tab
                        id={routes.tokens.outer}
                        title="Внешние"
                        panel={
                            <TokensList isAutomatic={false} store={store.outer} onTokenToggle={this.onTokenToggle} />
                        }
                    />
                    <Tab
                        id={routes.tokens.automatic}
                        title="Доверенные"
                        panel={
                            <TokensList isAutomatic={true} store={store.automatic} onTokenToggle={this.onTokenToggle} />
                        }
                    />
                </Tabs>
            </Page>
        );
    }

    handleTabChange = (tabId: string) => this.props.history.push(tabId);

    onTokenToggle = (token: TokenType, state: boolean) => {
        token.toggleConfirmationState(state);
    };
}

interface TokensTabsPageProps {
    location: Location;
    store: TokensStoreType;
}
