import React from "react";
import { HTMLTable } from "@blueprintjs/core";
import { ObjectRow } from "./ObjectRow";
import { FieldProps, Field } from "formik";
import {
    objectFields,
    OrderObjectSnapshotType,
    WORK_CODE_SEPARATOR,
    fullInventoryNumber,
} from "modules/orders-manage/models/order";
import { texts } from "modules/common/texts";
import styles from "../Tables.module.scss";
import { OrderObjectSchema } from "../validation";
import { getFieldLabel } from "modules/common/services/form/fields";
import { findIndex } from "lodash";
import { PlanrButton } from "modules/common/components/planr/button/Button";

const schema = OrderObjectSchema();
type TObject = OrderObjectSnapshotType;

export class OrderObjects extends React.Component<OrderObjectsProps> {
    fieldProps: FieldProps | null = null;

    render() {
        const { name, readOnly } = this.props;

        return (
            <Field name={name}>
                {(fieldProps: FieldProps) => {
                    this.fieldProps = fieldProps;
                    // list only non default objects
                    const value = fieldProps.field.value as TObject[];
                    const objects = value.filter(
                        (obj) => typeof obj.inventoryNumber === "string" || obj.inventoryNumber > 0
                    );
                    const invalid = !!fieldProps.form.touched[name] && !!fieldProps.form.errors[name];

                    return (
                        <div className={`order-objects ${styles.wrapper}`}>
                            <div className={`bp3-form-group ${invalid && "bp3-intent-danger"} ${styles.header}`}>
                                <HTMLTable className={styles.table} condensed={true} style={{ marginTop: "-13px" }}>
                                    {this.colgroup()}
                                    {objects.length > 0 && (
                                        <thead>
                                            <tr>
                                                <th>
                                                    <label className="bp3-label">№ ген.пл</label>
                                                </th>

                                                <th>
                                                    <label className="bp3-label">
                                                        {getFieldLabel(objectFields.name, schema, null)}
                                                    </label>
                                                </th>
                                                {!readOnly && <th></th>}
                                            </tr>
                                        </thead>
                                    )}
                                </HTMLTable>
                            </div>

                            <div className={styles.scroller}>
                                <HTMLTable
                                    className={styles.table}
                                    condensed={true}
                                    striped={false}
                                    interactive={false}
                                    style={{ marginTop: "-3px" }}
                                >
                                    {this.colgroup()}
                                    <tbody>
                                        {objects.map((object) => (
                                            <ObjectRow
                                                key={object.guid}
                                                object={object}
                                                readOnly={readOnly}
                                                onChange={(field, text) => {
                                                    const index = value.indexOf(object);
                                                    const appendix: any = { [field]: text };

                                                    if (field === objectFields.inventoryNumber) {
                                                        const newInventory = fullInventoryNumber(text);
                                                        const oldInventory = fullInventoryNumber(
                                                            object.inventoryNumber
                                                        );

                                                        const pattern = `${WORK_CODE_SEPARATOR}${oldInventory}${WORK_CODE_SEPARATOR}`;
                                                        const replacement = `${WORK_CODE_SEPARATOR}${newInventory}${WORK_CODE_SEPARATOR}`;

                                                        appendix["content"] = object.content.map((c) => ({
                                                            ...c,
                                                            name: c.name.replace(pattern, replacement),
                                                        }));
                                                    }

                                                    if (field === objectFields.name) {
                                                        appendix["content"] = object.content.map((c) => ({
                                                            ...c,
                                                            description: c.description.replace(object.name, text),
                                                        }));
                                                    }

                                                    const newValue = [
                                                        ...value.slice(0, index),
                                                        { ...object, ...appendix },
                                                        ...value.slice(index + 1),
                                                    ];

                                                    fieldProps.form.setFieldValue(fieldProps.field.name, newValue);
                                                    fieldProps.form.setFieldTouched(fieldProps.field.name, true);
                                                }}
                                                onRemove={this.removeObject}
                                            />
                                        ))}
                                    </tbody>
                                </HTMLTable>
                            </div>
                            {!readOnly && (
                                <PlanrButton
                                    type="dashed"
                                    icon="general-plus-big"
                                    onClick={this.addObject}
                                    style={{ width: "330px", marginTop: "10px" }}
                                >
                                    {texts.add}
                                </PlanrButton>
                            )}
                        </div>
                    );
                }}
            </Field>
        );
    }

    colgroup = () => {
        const { readOnly } = this.props;
        return (
            <colgroup>
                <col width="85" />
                <col />
                {!readOnly && <col width="25" />}
            </colgroup>
        );
    };

    addObject = async () => {
        const { fieldProps } = this;

        if (fieldProps) {
            const objects: any[] = fieldProps.field.value;
            const object = await this.props.factory.emptyObject(objects.length, objects.length + 1);
            const newValue = [...objects, object];

            fieldProps.form.setFieldValue(fieldProps.field.name, newValue);
            fieldProps.form.setFieldTouched(fieldProps.field.name, true);
        }
    };

    removeObject = (guid: string) => {
        const { fieldProps } = this;

        if (fieldProps) {
            const objects: any[] = fieldProps.field.value;
            const index = findIndex(objects, (o) => o[objectFields.guid] === guid);

            if (index >= 0) {
                const newValue = [...objects.slice(0, index), ...objects.slice(index + 1)];
                fieldProps.form.setFieldValue(fieldProps.field.name, newValue);
                fieldProps.form.setFieldTouched(fieldProps.field.name, true);
            }
        }
    };
}

export interface ObjectFactory {
    emptyObject: (index: number, sortOrder: number) => Promise<OrderObjectSnapshotType>;
}

interface OrderObjectsProps {
    readOnly?: boolean;
    name: string;
    factory: ObjectFactory;
}
