import { Card, Classes } from "@blueprintjs/core";
import { FieldProps } from "formik";
import { flatten, groupBy, sum } from "lodash";
import { observer } from "mobx-react";
import { FileLink } from "modules/common/components/files/FileLink";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { texts } from "modules/common/texts";
import { Constants } from "modules/root/models/constants";
import React from "react";
import { ACCEPT, EmployerFileBase, EmployerType } from "../../models/employer";
import styles from "./EmployerDocuments.module.scss";

class Docs extends React.PureComponent<EmployerDocumentsProps, EmployerDocumentsState> {
    alive = true;

    constructor(props: EmployerDocumentsProps) {
        super(props);
        this.state = { ...documents(props), printing: false, typesToPrint: {} };
    }

    componentDidUpdate(prev: EmployerDocumentsProps) {
        if (prev.fieldProps.field.value !== this.props.fieldProps.field.value) {
            this.setState({ ...documents(this.props) });
        }
    }

    componentWillUnmount() {
        this.alive = false;
    }

    render() {
        const { employer, fieldProps, canChange, baseUrl } = this.props;
        const { documentsMap, printing, typesToPrint } = this.state;

        const hasDocuments = !fieldProps.form.dirty && fieldProps.field.value.length > 0;
        const types = Array.from(Constants.employerDocumentType.values());
        const selectedCount = sum(flatten(Object.values(typesToPrint)));

        return (
            <Card className={styles.card}>
                <LocalLoader active={printing} />

                {hasDocuments && (
                    <PlanrButton
                        type="secondary"
                        size="small"
                        icon="general-print"
                        onClick={this.printDocuments}
                        style={{ width: "100%", marginBottom: "8px" }}
                    >
                        {texts.print} {!selectedCount ? "всех" : ""} документов{" "}
                        {selectedCount > 0 && <span>({selectedCount})</span>}
                    </PlanrButton>
                )}

                {types.map((type) => {
                    const uploaded = documentsMap[type] || [];
                    const className = `${styles.block} ${!!typesToPrint[type] ? styles.selected : ""}`;

                    return (
                        <div key={type} className={className} data-type={type} onClick={this.onToggleSelect}>
                            <div className={styles.blockHeader}>
                                <strong className={Classes.LABEL}>{type}</strong>

                                {canChange && (
                                    <EmployerFileUploader category={type} employer={employer} fieldProps={fieldProps} />
                                )}
                            </div>
                            <div className={styles.fileList}>
                                {uploaded.map((doc, index) => (
                                    <FileLink
                                        baseUrl={baseUrl}
                                        file={doc}
                                        readOnly={!canChange}
                                        onRemove={this.onRemove}
                                        key={index}
                                    />
                                ))}
                            </div>
                        </div>
                    );
                })}
            </Card>
        );
    }

    onRemove = (file: EmployerFileBase) => {
        const { canChange } = this.props;
        const { field, form } = this.props.fieldProps;
        const { value, name } = field;

        if (!canChange) {
            return;
        }

        const index = value.findIndex((d: EmployerFileBase) => d.fileId === file.fileId);

        if (index >= 0) {
            const newValue = [...value.slice(0, index), ...value.slice(index + 1)];
            form.setFieldValue(name, newValue);
            form.setFieldTouched(name, true);
        }
    };

    onToggleSelect = (e: React.MouseEvent<HTMLDivElement>) => {
        const type = e.currentTarget.dataset.type ?? "";
        const { typesToPrint, documentsMap } = this.state;

        const uploaded = documentsMap[type] || [];
        const oldValue = typesToPrint[type] ?? 0;
        const newValue = oldValue > 0 ? 0 : uploaded.length;

        this.setState({ typesToPrint: { ...typesToPrint, [type]: newValue } });
    };

    printDocuments = async () => {
        this.setState({ printing: true });
        const { typesToPrint } = this.state;

        const types = Object.keys(typesToPrint).filter((type) => typesToPrint[type] > 0);
        await this.props.employer.printDocuments(types);

        if (this.state) {
            this.setState({ printing: false });
        }
    };
}

class EmployerFileUploader extends React.PureComponent<UploaderProps> {
    render() {
        return <PlanRDndUploader onFileSelected={this.onFileSelected} accept={ACCEPT} oneRow={true} />;
    }

    onFileSelected = async (file: File) => {
        const { employer, category, fieldProps } = this.props;
        const { field, form } = fieldProps;

        const success = await employer.uploadFile(file, category);

        success && form.setFieldValue(field.name, [...field.value, success]);
        success && form.setFieldTouched(field.name, true);
    };
}

interface EmployerDocumentsProps {
    baseUrl: string;
    fieldProps: FieldProps;
    employer: EmployerType;
    canChange: boolean;
}

interface EmployerDocumentsState {
    documentsMap: TStringMap<EmployerFileBase[]>;
    printing: boolean;
    typesToPrint: TStringMap<number>;
}

interface UploaderProps {
    category: string;
    fieldProps: FieldProps;
    employer: EmployerType;
}

export const EmployerDocuments = observer(Docs);

function documents(props: EmployerDocumentsProps) {
    const value = props.fieldProps.field.value as EmployerFileBase[];

    return {
        documentsMap: groupBy(value, (doc) => doc.type),
    };
}
