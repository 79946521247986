import { Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { formatMoney, numberFormatter } from "modules/common/components/money/Money";
import { Slice } from "modules/orders-manage/details/summary/FinanceDashboard";
import { formatHours } from "modules/spending/timesheet/models/user-timesheet";
import React from "react";
import {
    EmployerMonthSnapshotType,
    UnitEmployerMonthSnapshotType,
    userActCost,
    userPercentDifference,
    userPlanCost,
    userTotalHours,
} from "../models/unit-store";
import styles from "./UnitUserDashboard.module.scss";

class Row extends React.Component<UnitUserDashboardProps> {
    render() {
        const { user, avgHours, maxHours, showMinutes } = this.props;
        const percentDifference = userPercentDifference(user);
        const planCost = user.finalSalary / user.workHoursCount;
        const actCost = userPlanCost(user);
        userActCost(user);

        return (
            <div className={styles.rowDashboard}>
                <div className={styles.left}>
                    <UnitUserDashboardRow
                        user={user}
                        maxHours={maxHours}
                        avgHours={avgHours}
                        showMinutes={showMinutes}
                    />
                </div>
                <div className={styles.right}>
                    <div className={styles.table}>
                        <div className={styles.tableRow}>
                            <div className={`${styles.hours} `}>
                                <span className={styles.plan}> {user.workHoursCount} ч</span> /{" "}
                                <span className={styles.actual}>
                                    {showMinutes
                                        ? formatHours(user.workedOutHours)
                                        : Math.round(user.workedOutHours) + "ч"}
                                </span>
                            </div>
                            <div className={`${styles.money}`}>
                                <span className={styles.plan}> {formatMoney(user.finalSalary)}</span> /{" "}
                                <span className={styles.actual}>{formatMoney(user.paidMoney)}</span>
                            </div>
                            <div className={`${styles.cost}`}>
                                <span className={styles.plan}>{formatMoney(planCost)}</span> /
                                <span className={styles.actual}>
                                    {user.paidMoney > 0 ? formatMoney(actCost) : formatMoney(0)}
                                </span>
                            </div>
                            <div className={`${styles.percents} `}>
                                <span className={styles.actual}>{`${numberFormatter(percentDifference)}%`}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const UnitUserDashboard = observer(Row);

interface UnitUserDashboardProps {
    user: UnitEmployerMonthSnapshotType;
    avgHours: number;
    maxHours: number;
    showMinutes: boolean;
}
interface UnitUserDashboardRowProps {
    user: UnitEmployerMonthSnapshotType | EmployerMonthSnapshotType;
    avgHours: number;
    maxHours: number;
    showMinutes: boolean;
}

export function UnitUserDashboardRow({ user, avgHours, maxHours, showMinutes }: UnitUserDashboardRowProps) {
    const total = userTotalHours(user);

    return (
        <div className={styles.dashboard}>
            <div className={styles.avgHours} style={{ left: `${(100 * avgHours) / maxHours}%` }}>
                <Tooltip
                    content={`В среднем отработано сотрудниками: ${numberFormatter((100 * avgHours) / maxHours)}% (${
                        showMinutes ? formatHours(avgHours) : Math.round(avgHours) + "ч"
                    })`}
                    className={styles.sliceHelper}
                >
                    <span />
                </Tooltip>
            </div>

            <Slice
                title="Работа"
                className={styles.worked}
                value={(100 * user.workedOutHours) / total}
                context={`(${showMinutes ? formatHours(user.workedOutHours) : Math.round(user.workedOutHours) + "ч"})`}
            />
            <Slice
                title="Больничные"
                className={styles.illness}
                value={(100 * user.illnessHours) / total}
                context={`(${showMinutes ? formatHours(user.illnessHours) : Math.round(user.illnessHours) + "ч"})`}
            />
            <Slice
                title="Отпуски"
                className={styles.vacation}
                value={(100 * user.vacationHours) / total}
                context={`(${showMinutes ? formatHours(user.vacationHours) : Math.round(user.vacationHours) + "ч"})`}
            />
            <Slice
                title="Без содержания"
                className={styles.missing}
                value={(100 * user.missingHours) / total}
                context={`(${showMinutes ? formatHours(user.missingHours) : Math.round(user.missingHours) + "ч"})`}
            />
        </div>
    );
}
