import React, { useCallback } from "react";
import { UserWorkloadType } from "modules/spending/workload/models/user-workload";
import styles from "./DepartmentBlock.module.scss";
import { WorkloadUserRow } from "./WorkloadUserRow";
import { WeekDays } from "./WeekDays";
import { DaySelector, WorkloadCellMode } from "./WorkloadCell";
import { OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";
import { WorkloadSaver } from "./WorkloadInput";
import { TasksDictionaryType } from "modules/spending/workload/models/workload-day";
import { Caret } from "modules/common/components/collapse/Caret";
import { Collapse } from "@blueprintjs/core";
import { DepartmentBlockMode } from "../models/workload-store";
import { Peoples } from "modules/orders-manage/details/Peoples";

export const DepartmentBlock: React.FC<DepartmentBlockProps> = (props) => {
    const { collapsed, toggleCollapse } = props;
    const { department, users, cellMode, mode, selectedCells, removeDay, onSelectCells, tasks, ...selector } = props;

    const onToggleCollapse = useCallback(() => toggleCollapse(department), [toggleCollapse, department]);

    return (
        <div className={`${styles.block} department-block`}>
            <h1 className="planr-block-header-wide_lowscreen collapser" onClick={onToggleCollapse}>
                <div className={styles.departmentName}>
                    <div className={styles.department}>{department}</div>

                    <Peoples count={users.length} />
                </div>
                {!collapsed && (
                    <>
                        <div style={{ marginLeft: "-306px" }}>{users.length && <WeekDays days={users[0].days} />}</div>
                    </>
                )}
                <Caret collapsed={collapsed} style={{ left: "7px" }} />
            </h1>
            <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                <div className="collapse-wide_lowscreen" style={{ paddingTop: "0px" }}>
                    <>
                        {users.map((workload) => (
                            <WorkloadUserRow
                                workload={workload}
                                key={workload.user.id}
                                mode={cellMode}
                                {...selector}
                                selectedCells={selectedCells}
                                tasks={tasks}
                                onSelectCells={onSelectCells}
                                removeDay={removeDay}
                            />
                        ))}
                    </>
                </div>
            </Collapse>
        </div>
    );
};

interface DepartmentBlockProps extends DaySelector, WorkloadSaver {
    department: string;
    users: UserWorkloadType[];
    orders: OrderDictionaryType;
    tasks: TasksDictionaryType;
    mode: DepartmentBlockMode;
    canManage: boolean;
    userLogin: string;
    cellMode: WorkloadCellMode;
    collapsed: boolean;
    selectedCells: string[];
    onSelectCells: (cell: string[]) => void;
    toggleCollapse: (key: string) => void;
    removeDay: () => void;
}
