import React from "react";
import { observer } from "mobx-react";
import styles from "./DepartmentsList.module.scss";
import { columns } from "./columns";
import { saveAgGridColumnSize } from "modules/common/services/table/size-storage";
import { defaultModules, DefaultAgGridOptions } from "modules/common/services/table/helpers";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { agGridEntityContextMenu } from "modules/common/components/table/EntityContextMenu";
import { DepartmentList, DepartmentsListType } from "../../models/department-list";
import { DepartmentSnapshotType, formatDepartment } from "../../models/department";
import { MenuModule } from "@ag-grid-enterprise/menu";
import {
    GetContextMenuItemsParams,
    RowClickedEvent,
    RowSelectedEvent,
    CellContextMenuEvent,
    GridReadyEvent,
} from "@ag-grid-community/core";
import copyToClipboard from "copy-to-clipboard";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import { agGridLocale } from "modules/root/services/locale";
import { MAX_INT } from "modules/common/constants";
// import { useHighlihtRowContextMenu, useGridSoter } from "modules/common/services/table/hooks";

// const gridOptions = DefaultAgGridOptions(true);

interface DepartmentsListProps {
    list: DepartmentsListType;
    onRemoved: () => void;
    onSelected: (id: string) => void;
    onClicked: (id: string) => void;
}

interface DepartmentsListState {
    focused: boolean;
}
export const DepartmentsList = observer(
    class extends React.Component<DepartmentsListProps, DepartmentsListState> {
        private gridOptions = DefaultAgGridOptions(true);
        private modules = [...defaultModules, MenuModule];
        private TABLE_NAME = DepartmentList.name;
        private config = columns(this.TABLE_NAME);
        private sizeSaver = saveAgGridColumnSize(this.TABLE_NAME);
        constructor(props: DepartmentsListProps) {
            super(props);
            this.state = { focused: false };
        }
        toggle() {
            this.setState({ focused: true });
        }

        onRowChanged = () => {
            this.gridOptions.api?.forEachNode((node) => {
                if (node.rowIndex === 0) {
                    node.setSelected(true);
                }
            });
        };
        render() {
            const getRowNodeId = (e: DepartmentSnapshotType) => {
                return e.id;
            };
            const removeLabel = (dep: DepartmentSnapshotType) => `отдел ${dep.name}`;

            const { list, onSelected, onClicked, onRemoved } = this.props;
            // const onCellContextMenu = useHighlihtRowContextMenu();
            const onCellContextMenu = ({ node }: CellContextMenuEvent) => {
                node && node.setSelected(true, true);
            };

            const onGridReady = ({ api }: GridReadyEvent) => {
                setTimeout(() => api.setSortModel(list.sorter.agGridSortModel));
            };

            const onRowSelected = ({ data }: RowSelectedEvent) => {
                onSelected(getRowNodeId(data));
            };

            const onRowClicked = ({ data, node }: RowClickedEvent) => {
                if (!node.isSelected()) return;

                onClicked(getRowNodeId(data));
                this.toggle();
            };

            const removeDepartment = async (dep: DepartmentSnapshotType) => {
                const success = await list.delete(dep);
                success && onRemoved();
            };

            return (
                <RemoveConfirmation<DepartmentSnapshotType>
                    what={removeLabel}
                    onConfirmed={removeDepartment}
                    observation={list.data}
                >
                    {({ confirmRemoving }) => {
                        const getContextMenuItems = ({ node }: GetContextMenuItemsParams) =>
                            agGridEntityContextMenu({
                                onCopy: () => copyToClipboard(formatDepartment(node.data), { format: "text/plain" }),
                                onChange: () => onSelected(getRowNodeId(node.data)),
                                onRemove: () => confirmRemoving(node.data),
                            });

                        return (
                            <GridTheme bordered={true} className={`designed ${styles.grid}`}>
                                <AgGridReact
                                    rowStyle={{
                                        borderBottom: "1 px solid #EDF0F2",
                                        fontFamily: "GothamPro, Arial, sans-serif",
                                        color: "#00273D",
                                        height: "32px",
                                        fontSize: "12px",
                                    }}
                                    rowSelection="single"
                                    suppressCellSelection={true}
                                    enableCellTextSelection={true}
                                    columnDefs={this.config}
                                    rowData={list.data}
                                    localeText={agGridLocale}
                                    onRowDoubleClicked={onRowSelected}
                                    onRowSelected={onRowClicked}
                                    getRowNodeId={getRowNodeId}
                                    getContextMenuItems={getContextMenuItems}
                                    onGridReady={onGridReady}
                                    onCellContextMenu={onCellContextMenu}
                                    onSortChanged={list.sorter.resorted}
                                    onColumnResized={this.sizeSaver}
                                    gridOptions={this.gridOptions}
                                    modules={this.modules}
                                    immutableData={true}
                                    rowBuffer={MAX_INT}
                                    onFirstDataRendered={this.onRowChanged}
                                />
                            </GridTheme>
                        );
                    }}
                </RemoveConfirmation>
            );
        }
    }
);
