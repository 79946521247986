import { types, applySnapshot, isAlive } from "mobx-state-tree";
import { BaseEntity, isNewlyCreated, DictionaryItem } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID, DATE_TIME_FORMAT } from "modules/common/constants";
import Schema from "../components/details/validation";
import moment from "moment";
import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";
import { getFieldLabel } from "modules/common/services/form/fields";

export const DepartmentDictionaryItem = types
    .compose(
        DictionaryItem,
        types.model({
            order: types.maybeNull(types.number),
        })
    )
    .named("DepartmentDictionaryItem");

const DepartmentBase = types.compose(
    Transport,
    Notificator,
    BaseEntity,
    types.model({
        name: types.string,
        description: types.string,
        order: types.maybeNull(types.number),
    })
);

export const Department = DepartmentBase.actions((self) => ({
    load: flow(function* (id: string) {
        try {
            const snapshot = isNewlyCreated(id)
                ? initialState()
                : yield self.transport.get<DepartmentSnapshotType>(apiUrls.departments.details(id));

            applySnapshot(self, snapshot);
        } catch (er) {
            self.notify.error(er);
        }
    }),

    save: flow(function* (model: any) {
        try {
            let order: any = model[fields.order];

            if (order === "") {
                order = null;
            } else if (typeof order === "string") {
                order = parseInt(order, 10);
            }

            const snapshot = self.isNewlyCreated
                ? yield self.transport.put<DepartmentSnapshotType>(apiUrls.departments.create(), { ...model, order })
                : yield self.transport.post<DepartmentSnapshotType>(apiUrls.departments.update(self.id), {
                      ...model,
                      order,
                  });

            isAlive(self) && applySnapshot(self, snapshot);
            self.notify.success(texts.messages.saved);

            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    delete: flow(function* () {
        if (self.isNewlyCreated) {
            return true;
        }

        try {
            yield self.transport.delete<boolean>(apiUrls.departments.delete(self.id));
            self.notify.success(texts.messages.removed);

            isAlive(self) && applySnapshot(self, initialState());
            return true;
        } catch (er) {
            self.notify.error(er);
            return false;
        }
    }),
})).named("Department");

export type DepartmentDictionaryItemType = typeof DepartmentDictionaryItem.Type;
export type DepartmentSnapshotType = typeof DepartmentBase.SnapshotType;
export type DepartmentType = typeof Department.Type;

export const initialState = (): DepartmentSnapshotType => ({
    id: EMPTY_OBJECT_ID,
    created: moment().format(DATE_TIME_FORMAT),
    name: "",
    description: "",
    order: null,
});

export const fields = {
    name: nameof((a: DepartmentType) => a.name) as string,
    description: nameof((a: DepartmentType) => a.description) as string,
    order: nameof((a: DepartmentType) => a.order) as string,
};

export function formatDepartment(stage: DepartmentSnapshotType | null) {
    let result = "";

    if (stage) {
        const schema = Schema();

        result += `${getFieldLabel(fields.name, schema, null)}: ${stage.name}\n`;
        result += `${getFieldLabel(fields.description, schema, null)}: ${stage.description}\n`;
    }

    return result;
}
