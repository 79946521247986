import { types, applySnapshot } from "mobx-state-tree";
import { SupplierList, Supplier, initialState as emptyList } from "./supplier-list";
import { initialState as emptySupplier, SupplierSnapshotType } from "./supplier";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { Queryable } from "modules/common/models/queryable";

export const SuppliersStore = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            list: SupplierList,
            details: Supplier,
        })
    )

    .actions((self) => {
        const actions = {
            load: flow(function* () {
                try {
                    const data: SupplierSnapshotType[] = yield self.transport.get<SupplierSnapshotType[]>(
                        apiUrls.suppliers.list()
                    );
                    applySnapshot(self.list.suppliers, data);

                    return true;
                } catch (er) {
                    self.notify.error(er);
                    return false;
                }
            }),
            loadNoRules: flow(function* () {
                try {
                    const data: SupplierSnapshotType[] = yield self.transport.get<SupplierSnapshotType[]>(
                        apiUrls.suppliers.listNoRules()
                    );
                    applySnapshot(self.list.suppliers, data);

                    return true;
                } catch (er) {
                    self.notify.error(er);
                    return false;
                }
            }),
            loadNoRulesAgents: flow(function* () {
                try {
                    const data: SupplierSnapshotType[] = yield self.transport.get<SupplierSnapshotType[]>(
                        apiUrls.suppliers.listNoRulesAgents()
                    );
                    applySnapshot(self.list.suppliers, data);

                    return true;
                } catch (er) {
                    self.notify.error(er);
                    return false;
                }
            }),

            init(supplierId: string) {
                self.details.load(supplierId);
                actions.load();
            },
        };

        return actions;
    })
    .named("SuppliersStore");

export const initialState = (): typeof SuppliersStore.SnapshotType => ({
    list: emptyList(),
    details: emptySupplier(),
    pureQuery: "",
    query: "",
});

export type SuppliersStoreType = typeof SuppliersStore.Type;
