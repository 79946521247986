import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { Category, CategoryType, CategorySnapshotType, fields, initialState as emptyCategory } from "./category";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
import { getSortOption, SortOptions } from "modules/common/services/table/sorting-storage";
import { Queryable } from "modules/common/models/queryable";

export const CategoryList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            categories: types.array(Category),
            remover: Category,
            sorter: TableSorter,
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<CategoryType> {
            return self.categories.reduce((result, Category) => {
                result[Category.id] = Category;
                return result;
            }, {} as TStringMap<CategoryType>);
        },

        get data() {
            const predicate = (o: CategoryType) => {
                let result = true;
                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.name} ${o.inventoryNumber} ${o.fullInventoryNumber}`
                            .toLowerCase()
                            .indexOf(self.pureQuery) >= 0;
                }
                return result;
            };
            return self.categories.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));
            // return getSnapshot(self.Categorys);
        },

        get isEmpty() {
            return !self.categories.length;
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data = yield self.transport.get<CategorySnapshotType[]>(apiUrls.categories.list());
                applySnapshot(self.categories, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        delete: flow(function* (Category: CategorySnapshotType) {
            applySnapshot(self.remover, Category);
            return yield self.remover.delete();
        }),
    }))
    .named("CategoryList");

export type CategorysListType = typeof CategoryList.Type;
export type CategoryListSnapshotType = typeof CategoryList.SnapshotType;
export { Category };

const sortStorage = getSortOption(CategoryList.name);

export const initialState = (sortOptions: SortOptions | null = null): CategoryListSnapshotType => {
    const options = sortOptions || sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        categories: [],
        remover: emptyCategory(),
        pureQuery: "",
        query: "",
        sorter: {
            id: CategoryList.name,
            tableName: CategoryList.name,
            column: options.column,
            asc: options.asc,
        },
    };
};
