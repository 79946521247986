import React, { useCallback } from "react";
import { OrderMailDocumentSnapshotType } from "modules/orders-manage/models/order-mails";
import styles from "./Documents.module.scss";
import { chunkArray } from "modules/common/services/array";

const COLS = 5;

interface DocumentsProps {
    documents: OrderMailDocumentSnapshotType[];
    columns?: number;
    onSelect: (doc: OrderMailDocumentSnapshotType) => void;
}

export const Documents: React.FC<DocumentsProps> = ({ documents, columns, onSelect }) => {
    const columnCount = columns ?? COLS;
    const batches = chunkArray(documents, columnCount);
    const width = 100 / columnCount;

    const onFileClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            const id = e.currentTarget.dataset.file;
            const file = documents.find((doc) => doc.id === id);
            file && onSelect(file);
        },
        [onSelect, documents]
    );

    return (
        <div className={styles.documents}>
            {batches.map((column, index) => (
                <div className={styles.column} key={index} style={{ width: `${width}%` }}>
                    {column.map((doc) => (
                        <div className={styles.file} key={doc.id} data-file={doc.id} onClick={onFileClick}>
                            {doc.name}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
