import React from "react";
import styles from "./EmployeeTable.module.scss";
import { ContextMenuTarget } from "@blueprintjs/core/lib/esnext";
import { groupBy } from "lodash";
import { RoleAccessSnapshotType } from "../models/access-store";
import { Menu, MenuItem } from "@blueprintjs/core";
import { texts } from "modules/common/texts";
import { EmptySquare } from "modules/common/components/icons/square";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";

export const W = 45;
export const H = 25;

export const calculateWidth = (span: number): React.CSSProperties => ({
    maxWidth: `${W * span}px`,
    minWidth: `${W * span}px`,
    width: `${W * span}px`,
});

export const calculateHeight = (): React.CSSProperties => ({
    maxHeight: `${H}px`,
    minHeight: `${H}px`,
    height: `${H}px`,
});

export const EmployeeTableHeader = ({
    data,
    onCopy,
    buffer,
    onPaste,
    selectedIndex,
    onSelect,
}: EmployeeTableHeaderProps) => {
    const departments = groupBy(data, (e) => e.department);

    return (
        <div>
            <table className={`${styles.table} ${styles.header}`}>
                <thead>
                    <tr>
                        {Object.keys(departments).map((dep) => {
                            const span = departments[dep].length;
                            const style = calculateWidth(span);

                            return (
                                <th key={dep} colSpan={span} style={style} className={styles.department}>
                                    <div>{dep}</div>
                                </th>
                            );
                        })}
                    </tr>

                    <tr>
                        {data.map((employer, index) => (
                            <EmployerName
                                key={employer.id}
                                role={employer}
                                onCopy={onCopy}
                                buffer={buffer}
                                onPaste={onPaste}
                                index={index}
                                selectedIndex={selectedIndex}
                                onClick={() => onSelect(index)}
                            />
                        ))}
                    </tr>
                </thead>
            </table>
        </div>
    );
};

@ContextMenuTarget
class EmployerName extends React.PureComponent<EmployerNameProps> {
    private confirmRemoving: any = null;

    render() {
        const { role: employer, onClick, index, selectedIndex } = this.props;

        const current = selectedIndex === index ? styles.current : "";
        const columnOpener = index === selectedIndex && index === 0 ? styles.columnOpener : "";
        const columnSelection = index === selectedIndex ? styles.columnSelection : "";

        return (
            <th
                key={employer.id}
                className={`${styles.role} ${columnSelection} ${columnOpener} ${current}`}
                onClick={onClick}
            >
                <RemoveConfirmation<RoleAccessSnapshotType>
                    what={(access) => `доступ для ${access.name}`}
                    onConfirmed={(access) => this.clearConfirmed(access)}
                    render={({ confirmRemoving }) => {
                        this.confirmRemoving = confirmRemoving;

                        return <div className={styles.rotated}>{employer.name}</div>;
                    }}
                />
            </th>
        );
    }

    renderContextMenu() {
        const { buffer, role: employer } = this.props;

        return (
            <Menu>
                <MenuItem onClick={this.copy} text={texts.copy} icon="duplicate" />
                {buffer && <MenuItem onClick={this.paste} text={texts.paste} icon="arrow-down" />}
                {employer.access.length > 0 && (
                    <MenuItem onClick={this.clear} text={`${texts.remove} доступ`} icon={<EmptySquare />} />
                )}
            </Menu>
        );
    }

    copy = () => {
        const { role, onCopy } = this.props;
        onCopy([...role.access.map((a) => a.name)]);
    };

    paste = () => {
        const { onPaste, buffer, role } = this.props;
        buffer && onPaste(role.id, buffer);
    };

    clear = () => this.confirmRemoving && this.confirmRemoving(this.props.role);

    clearConfirmed = (role: RoleAccessSnapshotType) => {
        const { onPaste } = this.props;
        onPaste(role.id, []);
    };
}

interface Buffer {
    onCopy: (access: string[]) => void;
    onPaste: (roleId: string, access: string[]) => void;
    buffer: string[] | null;
}

interface EmployerNameProps extends Buffer {
    role: RoleAccessSnapshotType;
    index: number;
    selectedIndex: number;
    onClick: () => void;
}

interface EmployeeTableHeaderProps extends Buffer {
    data: RoleAccessSnapshotType[];
    selectedIndex: number;
    onSelect: (index: number) => void;
}
