import { ApplicationStoreType } from "./store";
import { createContext, useContext } from "react";

const RootStoreContext = createContext<ApplicationStoreType | null>(null);
export const RootStoreProvider = RootStoreContext.Provider;

export function useMst() {
    const store = useContext(RootStoreContext);

    if (store === null) {
        throw new Error("Store cannot be null, please add a context provider");
    }

    return store;
}
