import { types, applySnapshot } from 'mobx-state-tree';
import { Transport } from 'modules/common/models/transport';
import { Notificator } from 'modules/common/models/notificator';
import { apiUrls } from 'modules/common/services/communication/urls';
import { flow } from 'modules/common/models/flow';
import { OrderTypeDictionaryItem, OrderTypeDictionaryItemType } from './order-type';

export const OrderTypeDictionary = types.compose(
    Transport, Notificator, types.model({
        orderTypes: types.array(OrderTypeDictionaryItem),
    }))
    .actions((self) => ({
        load: flow(function* (){
            try {
                const data: OrderTypeDictionaryItemType[] = yield self.transport.get<any>(apiUrls.orderTypes.dictionary());
                applySnapshot(self.orderTypes, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        })
    }))
    .views((self) => ({
        get isEmpty () {
            return !self.orderTypes.length;
        }
    }))
    .named('OrderTypeDictionary');

export const initialState = (): OrderTypeDictionarySnapshotType => ({
    orderTypes: []
});

export type OrderTypeDictionaryType = typeof OrderTypeDictionary.Type;
export type OrderTypeDictionarySnapshotType = typeof OrderTypeDictionary.SnapshotType;
