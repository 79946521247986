import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { BaseEntity, isNewlyCreated } from "modules/common/models/entity";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { formatDate } from "modules/common/services/formatting/date";
import { EMPTY_OBJECT_ID } from "modules/common/constants";

const LIMIT = 10;

export const News = types
    .compose(
        BaseEntity,
        Transport,
        types.model({
            name: types.string,
            text: types.string,
            author: types.string,
            read: types.boolean,
            image: types.maybeNull(types.string),
            day: types.string,
        })
    )
    .actions((self) => ({
        open: flow(function* () {
            if (self.read) {
                return;
            }

            try {
                const result = yield self.transport.post<boolean>(apiUrls.news.open(self.id));
                self.read = result;

                return result;
            } catch (er) {
                return false;
            }
        }),
    }))
    .views((self) => ({
        get title() {
            return self.name.length > 60 ? self.name.substr(0, 60) + "..." : self.name;
        },
    }))
    .named("News");

export const NewsStore = types
    .compose(
        Notificator,
        Transport,
        types.model({
            items: types.array(News),
            hasMore: types.boolean,
            selectedId: types.string,
        })
    )
    .views((self) => ({
        get isEmpty() {
            return self.items.length === 0;
        },
        get asMap(): TStringMap<NewsType> {
            return self.items.reduce((result, n) => {
                result[n.id] = n;
                return result;
            }, {} as TStringMap<NewsType>);
        },
    }))
    .views((self) => ({
        get selected() {
            return self.asMap[self.selectedId];
        },
    }))
    .actions((self) => ({
        loadFirstPage: flow(function* () {
            try {
                const data: any[] = yield self.transport.get<any>(apiUrls.news.list(), {
                    params: { fromId: "", limit: LIMIT },
                });

                self.hasMore = data.length === LIMIT;
                applySnapshot(self.items, data);
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        loadPage: flow(function* () {
            try {
                const data: any[] = yield self.transport.get<any>(apiUrls.news.list(), {
                    params: { fromId: "" },
                });

                self.hasMore = true;
                applySnapshot(self.items, data);
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        newsFactory(): NewsSnapshotType {
            return {
                author: "",
                created: formatDate(new Date()),
                day: formatDate(new Date()),
                id: EMPTY_OBJECT_ID,
                name: "",
                read: true,
                text: "",
                image: null,
            };
        },
    }))
    .actions((self) => {
        return {
            loadNext: flow(function* () {
                try {
                    if (self.isEmpty) {
                        yield self.loadFirstPage();
                    }

                    const fromId = self.items[self.items.length - 1].id;
                    const data: any[] = yield self.transport.get<any>(apiUrls.news.list(), {
                        params: { fromId, limit: LIMIT },
                    });

                    self.hasMore = data.length === LIMIT;
                    applySnapshot(self.items, [...getSnapshot(self.items), ...data]);
                    return true;
                } catch (er) {
                    self.notify.error(er);
                    return false;
                }
            }),
            select(id: string) {
                self.selectedId = id;

                if (self.selected) {
                    self.selected.open();
                }
            },
        };
    })
    .actions((self) => ({
        saveNews: flow(function* (values: { name: string; text: string; id: string }) {
            try {
                const shapshot = isNewlyCreated(values.id)
                    ? yield self.transport.put<NewsSnapshotType>(apiUrls.news.create(), values)
                    : yield self.transport.post<NewsSnapshotType>(apiUrls.news.update(values.id), values);

                if (isNewlyCreated(values.id)) {
                    self.items.unshift(shapshot);
                } else if (self.asMap[values.id]) {
                    self.asMap[values.id].name = shapshot.name;
                    self.asMap[values.id].text = shapshot.text;
                }

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        removeNews: flow(function* (id: string) {
            try {
                yield self.transport.delete<NewsSnapshotType>(apiUrls.news.delete(id));

                if (self.asMap[id]) {
                    self.items.remove(self.asMap[id]);
                }

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .named("NewsStore");

export type NewsType = typeof News.Type;
export type NewsSnapshotType = typeof News.SnapshotType;

export type NewsStoreType = typeof NewsStore.Type;

export const initialState = (): typeof NewsStore.SnapshotType => ({
    items: [],
    hasMore: true,
    selectedId: "",
});
