import React from "react";
import { observer } from "mobx-react";
import styles from "./MaterialValuesList.module.scss";
import { columns, stopColumns, TableContext, columnsAll, stopColumnsAll } from "./columnsMaterials";
import { saveAgGridColumnSize } from "modules/common/services/table/size-storage";
import { defaultModules, DefaultAgGridOptions } from "modules/common/services/table/helpers";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { agGridEntityContextMenu } from "modules/common/components/table/EntityContextMenu";
import { MaterialValueList, MaterialValuesListType } from "../../models/material-value-list";
import { MaterialValueSnapshotType, formatMaterialValue } from "../../models/material-value";
import { MenuModule } from "@ag-grid-enterprise/menu";
import {
    GridOptions,
    GridReadyEvent,
    RowClickedEvent,
    CellContextMenuEvent,
    GetContextMenuItemsParams,
} from "@ag-grid-community/core";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import { agGridLocale } from "modules/root/services/locale";
import { MAX_INT } from "modules/common/constants";
import copyToClipboard from "copy-to-clipboard";

const modules = [...defaultModules, MenuModule];
const TABLE_NAME = MaterialValueList.name;
const sizeSaver = saveAgGridColumnSize(TABLE_NAME);

export const MaterialValuesList = observer(
    class extends React.Component<MaterialValuesListProps> implements TableContext {
        private options: GridOptions = DefaultAgGridOptions(true);

        render() {
            const { store, onSelected, getDataset, ended, all } = this.props;
            const config = ended ? stopColumns(TABLE_NAME) : columns(TABLE_NAME);
            const configAll = ended ? stopColumnsAll(TABLE_NAME) : columnsAll(TABLE_NAME);
            const rowData = getDataset(store);

            return (
                <RemoveConfirmation<MaterialValueSnapshotType>
                    what={this.removeLabel}
                    onConfirmed={this.removeMaterialValue}
                    render={({ confirmRemoving }) => {
                        const getContextMenuItems = ({ node }: GetContextMenuItemsParams) =>
                            agGridEntityContextMenu({
                                onCopy: () => copyToClipboard(formatMaterialValue(node.data), { format: "text/plain" }),
                                onChange: () => onSelected(getRowNodeId(node.data)),
                                onRemove: () => confirmRemoving(node.data),
                            });

                        return (
                            <GridTheme bordered={true} className={`designed ${styles.grid}`}>
                                <AgGridReact
                                    rowStyle={{
                                        fontFamily: "GothamPro, Arial, sans-serif",
                                        color: "#00273D",
                                        fontSize: "12px",
                                    }}
                                    immutableData={true}
                                    rowSelection="single"
                                    suppressCellSelection={true}
                                    enableCellTextSelection={true}
                                    columnDefs={all ? configAll : config}
                                    rowData={rowData}
                                    rowHeight={32}
                                    localeText={agGridLocale}
                                    onRowDoubleClicked={this.onRowSelected}
                                    getRowNodeId={getRowNodeId}
                                    getContextMenuItems={getContextMenuItems}
                                    onGridReady={this.onGridReady}
                                    onCellContextMenu={this.onCellContextMenu}
                                    onSortChanged={store.sorter.resorted}
                                    onColumnResized={sizeSaver}
                                    gridOptions={this.options}
                                    modules={modules}
                                    rowBuffer={MAX_INT}
                                    context={this}
                                    domLayout="autoHeight"
                                    animateRows={true}
                                    applyColumnDefOrder={true}
                                />
                            </GridTheme>
                        );
                    }}
                />
            );
        }

        setRemoved = async (materialValue: MaterialValueSnapshotType) => {
            const { onChange } = this.props;
            await this.props.store.delete(materialValue);
            onChange();
        };

        setUnremoved = async (materialValue: MaterialValueSnapshotType) => {
            const { onChange } = this.props;
            await this.props.store.returnItem(materialValue);
            onChange();
        };

        setStop = async (materialValue: MaterialValueSnapshotType) => {
            const { onChange } = this.props;
            await this.props.store.stopping(materialValue);
            onChange();
        };

        setUnstoped = async (materialValue: MaterialValueSnapshotType) => {
            const { onChange } = this.props;
            await this.props.store.returning(materialValue);
            onChange();
        };

        removeLabel = (materialValue: MaterialValueSnapshotType) => `Ценность ${materialValue.name}`;

        removeMaterialValue = async (materialValue: MaterialValueSnapshotType) => {
            const { store, onRemoved } = this.props;
            const success = await store.delete(materialValue);
            success && onRemoved();
        };

        onCellContextMenu = ({ node }: CellContextMenuEvent) => {
            node && node.setSelected(true, true);
        };

        onRowSelected = ({ data }: RowClickedEvent) => {
            const { onSelected } = this.props;
            onSelected(getRowNodeId(data));
        };

        onGridReady = async ({ api }: GridReadyEvent) => {
            const { sorter } = this.props.store;

            setTimeout(() => api.setSortModel(sorter.agGridSortModel));
        };
    }
);

const getRowNodeId = (e: MaterialValueSnapshotType) => e.id;

interface MaterialValuesListProps {
    onRemoved: () => void;
    onSelected: (id: string) => void;
    onChange: () => void;
    store: MaterialValuesListType;
    getDataset: (store: MaterialValuesListType) => any[];
    ended?: boolean;
    all?: boolean;
}
