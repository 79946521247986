import React, { useCallback, useEffect, useState } from "react";
import { AccessStoreType } from "../../models/access-store";
import styles from "./Roles.module.scss";
import { groupBy } from "lodash";
import { observer } from "mobx-react";
import { Search } from "modules/common/components/form/Search";
import classnames from "classnames";
import { RoleAssigner } from "../role-assigner/RoleAssigner";
import { RoleDetails } from "./RoleDetails";

export const Roles = observer(({ store, setFunctionality, replaceAccess }: RolesProps) => {
    const { filteredRoles, roles } = store;
    const [selectedRole, selectRole] = useState("");
    const [copyFromVisible, setCopyFromVisible] = useState("");

    const onRoleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        const id = e.currentTarget.dataset.role ?? "";
        selectRole(id);
    }, []);

    const closeCopyFrom = useCallback(() => setCopyFromVisible(""), []);

    const onAssignAccess = useCallback(
        async (roleId: string) => {
            const source = roles.find((r) => r.id === roleId);
            if (source) {
                if (await replaceAccess(copyFromVisible, source.functionalities)) {
                    setCopyFromVisible("");
                }
            }
        },
        [roles, replaceAccess, copyFromVisible]
    );

    useEffect(() => {
        if (!selectedRole && filteredRoles.length) {
            selectRole(filteredRoles[0].id);
        }
    }, [filteredRoles, selectedRole]);

    const roleToDisplay = filteredRoles.find((r) => r.id === selectedRole);
    const map = groupBy(filteredRoles, (r) => r.department);

    return (
        <div className={styles.page}>
            {copyFromVisible && (
                <RoleAssigner
                    onClose={closeCopyFrom}
                    roles={roles}
                    onAssign={onAssignAccess}
                    excludeId={roleToDisplay ? roleToDisplay.id : undefined}
                />
            )}

            <div className={styles.tools}>
                <Search query={store.query} onSearch={store.setQuery} />
            </div>

            <div className={styles.positions}>
                {store.departments.map((dep) => {
                    const departmentRoles = map[dep] ?? [];

                    return (
                        <div className={`department-block ${styles.block}`} key={dep}>
                            <h1 className={`planr-block-header ${styles.departmentName}`}>{dep}</h1>
                            <div className={`collapse`}>
                                <div className={styles.roleList}>
                                    {departmentRoles.map((role) => {
                                        const roleClassName = classnames({
                                            [styles.roleName]: true,
                                            [styles.selected]: selectedRole === role.id,
                                        });

                                        return (
                                            <div
                                                key={role.id}
                                                data-role={role.id}
                                                className={roleClassName}
                                                onClick={onRoleClick}
                                            >
                                                {role.name}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            {roleToDisplay && (
                <RoleDetails
                    role={roleToDisplay}
                    columns={store.accessColumns}
                    onToggle={setFunctionality}
                    onReplace={setCopyFromVisible}
                />
            )}
        </div>
    );
});

interface RolesProps {
    store: AccessStoreType;
    setFunctionality: (roleId: string, functionality: string, state: boolean) => void;
    replaceAccess: (roleId: string, access: string[]) => Promise<boolean>;
}
