import React from "react";
import { fields, monthFields } from "../models/unit-store";
import { ColDef } from "@ag-grid-community/core";
import { formatMoney, gridMoneyFormatter, numberFormatter } from "modules/common/components/money/Money";
import styles from "./UnitPage.module.scss";
import { UnitUserDashboardRow } from "./UnitUserDashboard";
import { formatHours } from "modules/spending/timesheet/models/user-timesheet";

export const columns = (showMinutes: boolean) => {
    const result: ColDef[] = [
        {
            headerName: "",
            valueGetter: "node.rowIndex + 1",
            width: 50,
            sortable: true,
            resizable: false,
            lockPosition: true,
            cellClass: "righted row-number",
        },
        {
            headerName: "ФИО",
            field: fields.name,
            width: 280,
            sortable: true,
            lockPosition: true,
        },
        {
            headerName: "ЗП",
            field: fields.salary,
            width: 100,
            sortable: true,
            lockPosition: true,
            valueFormatter: gridMoneyFormatter,
        },
        {
            headerName: "Кзп",
            field: fields.salaryFactor,
            width: 60,
            sortable: true,
            lockPosition: true,
            valueFormatter: gridMoneyFormatter,
        },
        {
            headerName: "Раб. часы",
            field: fields.workedOutHours,
            width: 100,
            sortable: true,
            lockPosition: true,
            cellRendererFramework(value: any) {
                return (
                    <div>
                        {showMinutes
                            ? formatHours(value.data.workedOutHours)
                            : Math.round(value.data.workedOutHours) + "ч"}
                    </div>
                );
            },
        },
        {
            headerName: "Сверх ур.",
            field: fields.overtimeHours,
            width: 100,
            sortable: true,
            lockPosition: true,
            cellRendererFramework(value: any) {
                return (
                    <div>
                        {showMinutes
                            ? formatHours(value.data.overtimeHours)
                            : Math.round(value.data.overtimeHours) + "ч"}
                    </div>
                );
            },
        },
        {
            headerName: "Раб. сверх ур.",
            field: fields.hours,
            width: 130,
            sortable: true,
            lockPosition: true,
            cellRendererFramework(value: any) {
                return <div>{showMinutes ? formatHours(value.data.hours) : Math.round(value.data.hours) + "ч"}</div>;
            },
        },
        {
            headerName: "Куч",
            field: fields.hoursFactor,
            width: 60,
            sortable: true,
            valueFormatter: gridMoneyFormatter,
            lockPosition: true,
        },
        {
            headerName: "Столбец10",
            field: fields.factor,
            width: 110,
            sortable: true,
            valueFormatter: gridMoneyFormatter,
            lockPosition: true,
        },
        {
            headerName: "% от премии",
            field: fields.workPercent,
            width: 120,
            sortable: true,
            valueFormatter: gridMoneyFormatter,
            lockPosition: true,
        },
        {
            headerName: "Сумма",
            field: fields.sum,
            width: 120,
            sortable: true,
            valueFormatter: gridMoneyFormatter,
            lockPosition: true,
        },
        {
            headerName: "Денежные ср-ва",
            field: fields.paidMoney,
            width: 170,
            sortable: true,
            valueFormatter: gridMoneyFormatter,
            lockPosition: true,
        },
    ];

    return result;
};

export const columnsUsers = (avg: number, max: number, showMinutes: boolean) => {
    const result: ColDef[] = [
        {
            headerName: "",
            valueGetter: "node.rowIndex + 1",
            width: 30,
            sortable: true,
            resizable: false,
            lockPosition: true,
            cellClass: "righted row-number",
        },
        {
            headerName: "Сотрудник",
            width: 320,
            sortable: true,
            field: monthFields.name,
            lockPosition: true,
            cellRendererFramework(value: any) {
                return (
                    <div>
                        <div>{value.data.name}</div>
                        <div className={styles.departmentSubtitle}>{value.data.department}</div>
                    </div>
                );
            },
        },
        {
            headerName: "",
            width: 440,
            field: monthFields.workedPercentSort,
            sortable: true,
            lockPosition: true,
            cellRendererFramework(value: any) {
                return (
                    <UnitUserDashboardRow user={value.data} maxHours={max} avgHours={avg} showMinutes={showMinutes} />
                );
            },
        },
        {
            headerName: "Часы",
            width: 150,
            sortable: true,
            field: monthFields.workedOutHours,
            lockPosition: true,
            cellRendererFramework(value: any) {
                return (
                    <div className={`${styles.hours} `}>
                        <span className={styles.plan}> {value.data.workHoursCount} ч</span> /{" "}
                        <span className={styles.actual}>
                            {" "}
                            {showMinutes
                                ? formatHours(value.data.workedOutHours)
                                : Math.round(value.data.workedOutHours) + "ч"}
                        </span>
                    </div>
                );
            },
        },

        {
            headerName: "Денежные средства",
            width: 270,
            sortable: true,
            field: monthFields.paidMoney,
            lockPosition: true,
            cellRendererFramework(value: any) {
                return (
                    <div className={`${styles.money}`}>
                        <span className={styles.plan}> {formatMoney(value.data.finalSalary)}</span> /{" "}
                        <span className={styles.actual}>{formatMoney(value.data.paidMoney)}</span>
                    </div>
                );
            },
        },
        {
            headerName: "Стоимость раб. врем.",
            width: 190,
            sortable: true,
            field: monthFields.costPerHour,
            lockPosition: true,
            cellRendererFramework(value: any) {
                const planCost = value.data.planCost;
                const actCost = value.data.actCost;
                return (
                    <div className={`${styles.cost}`}>
                        <span className={styles.plan}>{formatMoney(planCost)}</span> /{" "}
                        <span className={styles.actual}>
                            {value.data.paidMoney > 0 ? formatMoney(actCost) : formatMoney(0)}
                        </span>
                    </div>
                );
            },
        },
        {
            headerName: "Разница в %",
            width: 130,
            sortable: true,
            field: monthFields.percentDifference,
            lockPosition: true,
            cellRendererFramework(value: any) {
                return (
                    <div className={`${styles.percents} `}>
                        <span className={styles.actual}>{`${numberFormatter(value.value)}%`}</span>{" "}
                    </div>
                );
            },
        },
    ];

    return result;
};
