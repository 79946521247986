import * as yup from "yup";
import { texts } from "modules/common/texts";
import { fields } from "../models/own-finance-requests";

const { object, string, date, boolean, number } = yup;

export const requestLabels = () => ({
    [fields.comment]: "Примечания",
    [fields.date]: "Дата",
    [fields.filesId]: "Счет на оплату",
    [fields.immediate]: "Срочно",
    [fields.sum]: "Сумма",
    [fields.typeId]: "Категория",
    [fields.supplierName]: "Контрагент",
    [fields.supplierId]: "Контрагент",
});

export const RequestSchema = (isName: boolean) => {
    const labels = requestLabels();

    return object().shape({
        [fields.comment]: string().label(labels[fields.comment]).notRequired(),
        [fields.supplierName]: isName
            ? string().label(labels[fields.supplierName]).required()
            : string().label(labels[fields.supplierName]).notRequired(),
        [fields.supplierId]: isName
            ? string().label(labels[fields.supplierId]).notRequired()
            : string().label(labels[fields.supplierId]).required(),
        [fields.date]: date().label(labels[fields.date]).typeError(texts.messages.incorrectDate()).required(),
        [fields.immediate]: boolean().label(labels[fields.immediate]),
        [fields.sum]: number().label(labels[fields.sum]).required().min(1),
        [fields.typeId]: string().label(labels[fields.typeId]).required(),
    });
};
