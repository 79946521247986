import React from "react";
import styles from "./TechIndicatorRow.module.scss";
import { SaveOrderTechIndicator, techIndicatorFields } from "modules/orders-manage/models/order-tech-indicator";
import classnames from "classnames";
import { InputGroup, Classes } from "@blueprintjs/core";
import { preventSubmitKeyDown } from "modules/common/services/form/values";
import { texts } from "modules/common/texts";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { DraggableProvided } from "react-beautiful-dnd";
import { getDragItemStyle } from "modules/common/services/drag";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import {
    DefaultSelectedOption,
    filterItemPredicate,
    renderSingleOption,
    SimpleSelect,
} from "modules/common/services/form/select";
import { OrderIndicatorDictionaryItemType } from "modules/dictionaries/order-indicators/models/order-indicator";

export class TechIndicatorRow extends React.Component<TechIndicatorRowProps> {
    render() {
        const { indicator, readOnly, active, onChange, onRemove, isDragging, draggable, indicators } = this.props;

        const rowOnEdit = active && active.guid === indicator.guid;

        const items =
            indicators && indicators.length > 0
                ? indicators.map((s) => ({ id: s.id, label: `${s.label} (${s.description})` }))
                : [];

        const indicatorName = items.find((i) => i.id === indicator.orderIndicatorId);
        const selectedStage = rowOnEdit && active ? items.find((i) => i.id === active.orderIndicatorId) : indicatorName;

        return (
            <div
                ref={draggable.innerRef}
                {...draggable.draggableProps}
                {...draggable.dragHandleProps}
                style={getDragItemStyle(isDragging, draggable.draggableProps.style)}
                className={classnames({
                    [styles.row]: true,
                    [styles.rowEdit]: rowOnEdit && active,
                    // [styles.highlighted]: !!ipd.documentFileId,
                    // [styles.inquiried]: !!ipd.requestFileId && !ipd.documentFileId,
                })}
            >
                {!rowOnEdit && !readOnly && (
                    <div className={styles.iconCell}>
                        <PlanrButton
                            type="neutral"
                            icon="general-edit"
                            onClick={this.activate}
                            size="small"
                            style={{ color: "#EA561E" }}
                        />
                    </div>
                )}
                {rowOnEdit && active && (
                    <div className={styles.iconCell}>
                        <PlanrButton
                            type="neutral"
                            icon="general-redo"
                            onClick={this.onSave}
                            size="small"
                            style={{ color: "#1DD278" }}
                        />
                        {/* <SaveIcon className="action-icon" onClick={this.onSave} /> */}
                    </div>
                )}
                <div className={styles.number}>
                    {rowOnEdit && active && (
                        <InputGroup
                            type="number"
                            className="planr-default-input"
                            autoComplete="off"
                            data-lpignore="true"
                            value={active.number}
                            // className={Classes.FILL}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onChange([
                                    {
                                        field: techIndicatorFields.number,
                                        value: e.currentTarget.value,
                                    },
                                ]);
                            }}
                            onKeyDown={preventSubmitKeyDown}
                        />
                    )}
                    {!rowOnEdit && indicator.number}
                </div>

                <div className={styles.description}>
                    {rowOnEdit && active && (
                        <div style={{ width: "100%", marginTop: "-8px" }}>
                            <SimpleSelect
                                className={`full-width-select ${Classes.FILL}`}
                                activeItem={selectedStage}
                                items={items}
                                itemRenderer={renderSingleOption}
                                onItemSelect={(i: SelectItem) => {
                                    onChange([
                                        {
                                            field: techIndicatorFields.orderIndicatorId,
                                            value: i.id,
                                        },
                                        {
                                            field: techIndicatorFields.orderIndicatorName,
                                            value: i.label,
                                        },
                                    ]);
                                }}
                                filterable={true}
                                itemPredicate={filterItemPredicate}
                            >
                                <DefaultSelectedOption option={selectedStage} />
                            </SimpleSelect>
                        </div>
                    )}
                    {!rowOnEdit && indicatorName && <span>{indicatorName.label}</span>}
                </div>

                {!readOnly && !active && (
                    <div className={styles.iconCell}>
                        <RemoveConfirmation
                            onConfirmed={onRemove}
                            what={(w) => {
                                const name = items.find((i) => i.id === w.orderIndicatorId);
                                if (name) {
                                    return name.label;
                                }
                                return "";
                            }}
                            render={({ confirmRemoving }) => {
                                return (
                                    <PlanrButton
                                        type="neutral"
                                        icon="general-trash"
                                        onClick={() => confirmRemoving(indicator)}
                                        size="small"
                                        title={texts.remove}
                                    />
                                );
                            }}
                        />
                    </div>
                )}
                {rowOnEdit && active && (
                    <div className={styles.iconCell}>
                        <PlanrButton type="neutral" icon="general-undo" onClick={this.deactivate} size="small" />
                    </div>
                )}
            </div>
        );
    }

    activate = () => this.props.onActivate(this.props.indicator);

    onSave = () => {
        const { active } = this.props;

        if (active) {
            this.props.onSave(active);
        }
    };

    deactivate = () => this.props.onActivate(null);
}

interface TechIndicatorRowProps {
    indicator: SaveOrderTechIndicator;
    onChange: (changes: Array<{ field: string; value: any }>) => void;
    onSave: (ipd: SaveOrderTechIndicator) => void;
    onRemove: (ipd: SaveOrderTechIndicator) => void;
    readOnly: boolean;
    active: SaveOrderTechIndicator | null;
    onActivate: (ipd: SaveOrderTechIndicator | null) => void;
    isDragging: boolean;
    draggable: DraggableProvided;
    indicators: OrderIndicatorDictionaryItemType[];
}
