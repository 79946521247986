import { types, getParent } from "mobx-state-tree";
import { EmployerLink, Employer } from "modules/spending/employee/models/employer";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { nameof } from "modules/common/services/typescript";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { IdEntity } from "modules/common/models/entity";
import { toJsonHard } from "modules/common/services/mobx/serialize";
import { Period } from "modules/common/models/period";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { sortBy } from "lodash";

export const VacationRow = types
  .model({
    days: types.number,
    daysCount: types.number,
    salary: types.number,
    bonus: types.number,
    skip: types.boolean,
    year: types.number,
    month: types.number,
    monthName: types.string,
  })
  .named("VacationRow");

export const VacationDetals = types
  .model({
    rows: types.array(VacationRow),
    daysCount: types.number,
    transferFrom: types.maybeNull(Period),
    salary: types.number,
    bonus: types.number,
  })
  .views((self) => ({
    get sum() {
      return self.salary + self.bonus;
    },

    get sortField() {
      return self.transferFrom ? `${self.transferFrom.year}_${self.transferFrom.month}` : "z";
    },
  }))
  .named("VacationDetals");

export const ScheduleUnit = types
  .compose(
    Transport,
    Notificator,
    IdEntity,
    Period,
    types.model({
      employer: types.maybeNull(EmployerLink),
      salary: types.number,
      totalSalary: types.number,
      bonus: types.number,
      illness: types.number,
      illnessBonus: types.number,
      bonusCorrection: types.number,
      bonusCorrectionComment: types.string,
      increase: types.number,
      overtime: types.number,
      overtimeScale: types.number,
      finalSalary: types.number,
      perHourRate: types.number,
      decrease: types.number,
      ndflTaxe: types.number,
      socialTaxe: types.number,
      bonusTaxe: types.number,
      vacationSalary: types.number,
      vacationBonus: types.number,
      vacation: types.number,
      profit: types.number,
      bonusGrade: types.number,
      bonusWorkedOut: types.number,
      bonusWorkedOutGrade: types.number,
      bonusGradePercent: types.number,
      bonusWorkedOutPercent: types.number,
      companySpending: types.number,
      companySpendingPerHour: types.number,
      department: types.optional(types.string, ""),
      vacations: types.array(VacationDetals),
    })
  )
  .views((self) => ({
    get employerId() {
      return self.employer ? self.employer.id : "";
    },

    get finalBonus() {
      return self.bonus + self.illnessBonus;
    },

    get sortedVacations() {
      return sortBy(self.vacations, (v) => v.sortField);
    },
  }))
  .actions((self) => ({
    toExportView() {
      const result: any = toJsonHard(self);
      result.finalBonus = self.finalBonus;

      return result;
    },
  }))
  .named("ScheduleUnit");

export const ScheduleRow = types
  .compose(
    Transport,
    Notificator,
    types.model({
      actual: ScheduleUnit,
      user: types.maybeNull(Employer),
      plan: ScheduleUnit,
    })
  )
  .actions((self) => ({
    toExportView() {
      return {
        actual: self.actual.toExportView(),
        plan: self.plan.toExportView(),
        name: self.user?.name || "",
        position: (self.user?.employerPosition?.name || "").substring(0, 30),
      };
    },
  }))
  .views((self) => ({
    get employerId() {
      return self.user ? self.user.id : "";
    },
  }))
  .actions((self) => ({
    reloadParent() {
      const store = getParent(getParent(self));
      if (typeof store?.load === "function") {
        return store?.load();
      }
    },
  }))
  .actions((self) => ({
    transfer: flow(function* (month: number, year: number) {
      try {
        if (!self.employerId) {
          return false;
        }

        yield self.transport.post<any>(apiUrls.schedule.vacation.transfer(self.employerId), {
          from: { month: self.plan.month, year: self.plan.year },
          to: { month, year },
        });

        self.reloadParent();
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),

    removeTransfer: flow(function* () {
      try {
        if (!self.employerId) {
          return false;
        }

        yield self.transport.delete<any>(apiUrls.schedule.vacation.transfer(self.employerId), {
          data: {
            month: self.plan.month,
            year: self.plan.year,
          },
        });

        self.reloadParent();
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
  }))
  .named("ScheduleRow");

export type ScheduleUnitType = typeof ScheduleUnit.Type;
export type ScheduleUnitSnapshotType = typeof ScheduleUnit.SnapshotType;
export type ScheduleRowType = typeof ScheduleRow.Type;
export type ScheduleRowSnapshotType = typeof ScheduleRow.SnapshotType;
export type VacationRowType = typeof VacationRow.Type;
export type VacationDetailsType = typeof VacationDetals.Type;

export const initialState = (userName: string = ""): ScheduleUnitSnapshotType => ({
  id: EMPTY_OBJECT_ID,
  month: 0,
  year: 0,
  bonus: 0,
  bonusCorrection: 0,
  bonusCorrectionComment: "",
  bonusTaxe: 0,
  bonusGrade: 0,
  bonusGradePercent: 0,
  bonusWorkedOut: 0,
  bonusWorkedOutGrade: 0,
  bonusWorkedOutPercent: 0,
  companySpending: 0,
  companySpendingPerHour: 0,
  decrease: 0,
  illness: 0,
  illnessBonus: 0,
  department: "",
  employer: null,
  finalSalary: 0,
  increase: 0,
  ndflTaxe: 0,
  overtime: 0,
  overtimeScale: 1,
  perHourRate: 0,
  profit: 0,
  salary: 0,
  totalSalary: 0,
  socialTaxe: 0,
  vacation: 0,
  vacationBonus: 0,
  vacationSalary: 0,
  vacations: [],
});

export const fields = {
  salary: nameof((a: ScheduleUnitType) => a.salary) as string,
  bonus: nameof((a: ScheduleUnitType) => a.bonus) as string,
  bonusCorrection: nameof((a: ScheduleUnitType) => a.bonusCorrection) as string,
  bonusCorrectionComment: nameof((a: ScheduleUnitType) => a.bonusCorrectionComment) as string,
  bonusGradePercent: nameof((a: ScheduleUnitType) => a.bonusGradePercent) as string,
  bonusWorkedOutPercent: nameof((a: ScheduleUnitType) => a.bonusWorkedOutPercent) as string,
  increase: nameof((a: ScheduleUnitType) => a.increase) as string,
  decrease: nameof((a: ScheduleUnitType) => a.decrease) as string,
  vacationSalary: nameof((a: ScheduleUnitType) => a.vacationSalary) as string,
  vacationBonus: nameof((a: ScheduleUnitType) => a.vacationBonus) as string,
  employerId: nameof((a: ScheduleUnitType) => a.employerId) as string,
};
