import { max } from "lodash";
import { formatMoney } from "modules/common/components/money/Money";
import { toString } from "modules/common/services/strings";
import { isNotNull, nameof } from "modules/common/services/typescript";
import { WorkTypeLinkSnapshotType } from "modules/orders-manage/models/order";
import { getDebit, OutsourcedOrderSpendingSnapshotType } from "modules/orders-manage/models/order-spending";
import { Constants } from "modules/root/models/constants";

export const OUTSORCE_BLOCK_NAME = "Силы подрядчика";
export const OTHER_BLOCK_NAME = "Прочие затраты";
export const MAIN_DATA_BLOCK_NAME = "Основные данные";
export const PLAN_PAYMENTS_BLOCK_NAME = "Плановые оплаты";
export const ACTUAL_PAYMENTS_BLOCK_NAME = "Фактические оплаты";

type TSpending = OutsourcedOrderSpendingSnapshotType;

export const SpendingBlockSorting = [
    nameof((a: TSpending) => a.sortOrder) as string,
    nameof((a: TSpending) => a.generatedKey) as string,
];

export function outsourcedSpendingsPrintModel<T extends TSpending>(
    name: string,
    sorted: T[],
    units: WorkTypeLinkSnapshotType[],
    predicate: (s: T, u: WorkTypeLinkSnapshotType) => boolean,
    agents: TStringMap<SelectItem>
) {
    const orderTotal = sorted.reduce((acc, unit) => +unit.actualSum + acc, 0);
    const actualTotal = sorted.reduce((acc, unit) => {
        return (
            acc +
            unit.actualPayments.reduce((t, p) => t + p.sum - p.correctionPayments.reduce((s, c) => s + c.sum, 0), 0)
        );
    }, 0);

    const blocks = units.map((item) => {
        const spendings = sorted.filter((s) => predicate(s, item));
        if (!spendings.length) {
            return null;
        }

        let blockSum: number = 0;
        let blockPaidSum: number = 0;

        const rows = spendings.map((spending, key) => {
            const outsourcerId = spending.outsourcer ? spending.outsourcer.id : "";
            const outsourcer = agents[outsourcerId];

            blockSum += +spending.actualSum;

            let summaryTable = [
                {
                    summaryLabel: "Сумма",
                    summaryVal: formatMoney(+spending.actualSum),
                    planSum: "",
                    planDate: "",
                    planPaid: "",
                    actualSum: "",
                    actualDate: "",
                    actualPaid: "",
                },
                {
                    summaryLabel: "Остаток",
                    summaryVal: formatMoney(getDebit(spending, "actual")),
                    planSum: "",
                    planDate: "",
                    planPaid: "",
                    actualSum: "",
                    actualDate: "",
                    actualPaid: "",
                },
                {
                    summaryLabel: "Срок (дни)",
                    summaryVal: toString(spending.days),
                    planSum: "",
                    planDate: "",
                    planPaid: "",
                    actualSum: "",
                    actualDate: "",
                    actualPaid: "",
                },
                {
                    summaryLabel: "Договор",
                    summaryVal: spending.orderNumber ?? "",
                    planSum: "",
                    planDate: "",
                    planPaid: "",
                    actualSum: "",
                    actualDate: "",
                    actualPaid: "",
                },
                {
                    summaryLabel: "от",
                    summaryVal: spending.startDate ?? "",
                    planSum: "",
                    planDate: "",
                    planPaid: "",
                    actualSum: "",
                    actualDate: "",
                    actualPaid: "",
                },
            ];

            const table = [...summaryTable];
            const maxLength =
                max([summaryTable.length, spending.planPayments.length, spending.actualPayments.length]) ?? 0;
            for (let i = 0; i < maxLength; i++) {
                let summary = table[i];
                const plan = spending.planPayments[i];
                const actual = spending.actualPayments[i];

                if (!summary) {
                    summary = table[i] = {
                        summaryLabel: "",
                        summaryVal: "",
                        planSum: "",
                        planDate: "",
                        planPaid: "",
                        actualSum: "",
                        actualDate: "",
                        actualPaid: "",
                    };
                }

                if (plan && plan.day) {
                    summary.planSum = formatMoney(+plan.sum);
                    summary.planDate = plan.day;
                    summary.planPaid = plan.status === Constants.orderPaymentStatusPaid ? "✔" : "";
                }
                if (actual && actual.day) {
                    summary.actualSum = formatMoney(+actual.sum);
                    summary.actualDate = actual.day;
                    summary.actualPaid = formatMoney(
                        +actual.sum - actual.correctionPayments.reduce((s, c) => s + c.sum, 0)
                    );
                }
            }

            return {
                status: taskStatus(spending),
                outsourcer: outsourcer?.label ?? "",
                plan: spending.planPayments.map((payment) => {
                    return {
                        sum: formatMoney(payment.sum),
                        date: payment.day,
                        paid: payment.status === Constants.orderPaymentStatusPaid,
                    };
                }),
                actual: spending.actualPayments.map((payment) => {
                    const correctionSum = payment.correctionPayments.reduce((s, c) => s + c.sum, 0);
                    blockPaidSum += payment.sum - correctionSum;
                    return {
                        sum: formatMoney(payment.sum - correctionSum),
                        date: payment.day,
                        paid: true,
                    };
                }),
                labels: {
                    status: "Статус",
                    order: MAIN_DATA_BLOCK_NAME,
                    plan: PLAN_PAYMENTS_BLOCK_NAME,
                    actual: ACTUAL_PAYMENTS_BLOCK_NAME,
                    empty: "",
                },
                table,
            };
        });

        return {
            name: item.partNumber + " " + item.name + " " + item.description,
            sum: formatMoney(blockSum),
            paid: formatMoney(blockPaidSum),
            remains: formatMoney(blockSum - blockPaidSum),
            rows,
        };
    });

    return {
        name,
        sum: formatMoney(orderTotal),
        paid: formatMoney(actualTotal),
        remains: formatMoney(orderTotal - actualTotal),
        blocks: blocks.filter(isNotNull),
    };
}

function taskStatus(spending: TSpending) {
    const issued = !!spending.taskIssued;
    const completed = !!spending.taskCompleted;
    const accepted = !!spending.taskAccepted;

    if (accepted) {
        return "Принято";
    }
    if (completed) {
        return "Сдано";
    }
    if (issued) {
        return "Выдано";
    }

    return "";
}
