import React from "react";
import { ImportedBankRowType, ImportedBankRowSnapshotType } from "../models/bank-row";
import { observer } from "mobx-react";
import { ImportSpendingsStoreType } from "../models/import-store";
import { columnsBuilder, ContextActions } from "./columns";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { AgGridReact } from "@ag-grid-community/react";
import {
    FIGMA_ROW_HEIGHT,
    FIGMA_HEADER_HEIGHT,
    defaultModules,
    DefaultAgGridOptions,
} from "modules/common/services/table/helpers";
import { agGridLocale } from "modules/root/services/locale";
import { GridReadyEvent, GridApi, RowNode } from "@ag-grid-community/core";
import styles from "./Table.module.scss";
import { isNotNull } from "modules/common/services/typescript";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { OutsourcedOrderSpendingType } from "modules/orders-manage/models/order-spending";
import { OtherOrderSpendingType } from "../../../orders-manage/models/order-spending";

class Base extends React.Component<TableProps> implements ContextActions {
    private gridApi: GridApi | null = null;
    private columns = columnsBuilder();
    private options = DefaultAgGridOptions(true);

    constructor(props: any) {
        super(props);

        this.state = { wizard: null };
    }

    async componentDidMount() {
        await this.props.store.loadTable(true);

        setTimeout(() => {
            this.gridApi && this.gridApi.redrawRows();
        }, 200);
    }

    render() {
        const { store } = this.props;

        return (
            <GridTheme bordered={true} className={`designed ${styles.table}`}>
                <AgGridReact
                    rowStyle={{
                        fontFamily: "GothamPro, Arial, sans-serif",
                        fontWeight: "400",
                        color: "#00273D",
                        fontSize: "12px",
                    }}
                    suppressRowClickSelection={true}
                    suppressCellSelection={true}
                    enableCellTextSelection={true}
                    columnDefs={this.columns}
                    rowData={store.rowsSnapshot}
                    getRowHeight={this.getRowHeight}
                    headerHeight={FIGMA_HEADER_HEIGHT}
                    minColWidth={5}
                    getRowNodeId={this.getRowNodeId}
                    localeText={agGridLocale}
                    context={this}
                    gridOptions={this.options}
                    immutableData={true}
                    modules={defaultModules}
                    onGridReady={this.onGridReady}
                    onSortChanged={store.sorter.resorted}
                    rowClassRules={this.rowClassRules}
                    animateRows={true}
                    rowBuffer={500}
                />

                {store.hasMore && (
                    <PlanrButton type="blueish" onClick={this.loadMore} size="small">
                        Показать еще
                    </PlanrButton>
                )}
            </GridTheme>
        );
    }

    getRowHeight = ({ node }: { node: RowNode }) => {
        const row = node.data as ImportedBankRowType;

        return Object.keys(row.dictionaries).some((key) => key.indexOf("_Next") > 0)
            ? FIGMA_ROW_HEIGHT * 6.5
            : FIGMA_ROW_HEIGHT * 4;
    };

    loadMore = () => this.props.store.loadTable();

    getRowNodeId = (row: ImportedBankRowType) => row.id;

    onGridReady = async ({ api }: GridReadyEvent) => {
        this.gridApi = api;
        const { sorter } = this.props.store;
        setTimeout(() => api.setSortModel(sorter.agGridSortModel));
    };

    changePayload = (node: RowNode, value: string) => {
        this.props.store.changePayload(node.id ?? "", value);
        this.gridApi?.redrawRows({ rowNodes: [node] });
    };

    changeTempPayload = (node: RowNode, value: string) => {
        this.props.store.changeTempPayload(node.id ?? "", value);
        this.gridApi?.redrawRows({ rowNodes: [node] });
    };

    changeExclude = (node: RowNode, value: boolean) => {
        this.props.store.changeExclude(node.id ?? "", value);
        this.gridApi?.redrawRows({ rowNodes: [node] });
    };

    changeType = (node: RowNode, value: string) => {
        this.props.store.changeType(node.id ?? "", value);
        this.gridApi?.redrawRows({ rowNodes: [node] });
    };

    changeOutsourcedMap = (node: RowNode, value: OutsourcedOrderSpendingType[]) => {
        this.props.store.changeOutsourcedMap(node.id ?? "", value);
        this.gridApi?.redrawRows({ rowNodes: [node] });
    };
    changeOtherMap = (node: RowNode, value: OtherOrderSpendingType[]) => {
        this.props.store.changeOtherMap(node.id ?? "", value);
        this.gridApi?.redrawRows({ rowNodes: [node] });
    };
    changeLoadMap = (node: RowNode, value: boolean) => {
        this.props.store.changeLoadMap(node.id ?? "", value);
        this.gridApi?.redrawRows({ rowNodes: [node] });
    };
    changeSpendingMap = (node: RowNode, value: string) => {
        this.props.store.changeSpendingMap(node.id ?? "", value);
        this.gridApi?.redrawRows({ rowNodes: [node] });
    };

    save = async (id: string) => {
        const { store } = this.props;
        const redraw = await store.save(id);
        const api = this.gridApi;

        if (redraw.length > 0 && api) {
            setTimeout(() => {
                const nodes = redraw.map((i) => api.getRowNode(i)).filter(isNotNull);
                api.redrawRows({ rowNodes: nodes });
            }, 200);
        }
    };

    private rowClassRules = {
        processed: (params: any) => {
            const data: ImportedBankRowSnapshotType = params.data;
            return data.processed;
        },
        excluded: (params: any) => {
            const { store } = this.props;
            return store.isExcluded(params.node.id);
        },
        error: (params: any) => {
            const data: ImportedBankRowSnapshotType = params.data;
            return !!data.error;
        },
        ready: (params: any) => {
            const { store } = this.props;
            return store.isValid(params.node.id);
        },
    };

    get store() {
        return this.props.store;
    }
}

export const ImportTable = observer(Base);

interface TableProps {
    store: ImportSpendingsStoreType;
}
