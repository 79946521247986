import { types, getEnv } from "mobx-state-tree";
import { HttpTransport } from "modules/common/services/communication/http";
import { v4 } from "uuid";
import { apiUrls } from "../services/communication/urls";
import { mongoObjectId, trimEnd } from "../services/strings";

export const Transport = types
  .model("Transport", {})
  .views((self) => ({
    get transport() {
      return getEnv(self).http as HttpTransport;
    },
  }))
  .views((self) => ({
    get baseUrl() {
      return trimEnd("/", self.transport.defaults.baseURL || "");
    },
  }))
  .actions((self) => ({
    async generateGuid() {
      try {
        const response = await self.transport.get<string>(apiUrls.application.generate.guid);
        return response;
      } catch (e) {
        // fallback
        return v4();
      }
    },

    async generateId() {
      try {
        const response = await self.transport.get<string>(apiUrls.application.generate.id);
        return response;
      } catch (e) {
        // fallback
        return mongoObjectId();
      }
    },
  }));
