import React, { useCallback } from "react";

import { texts } from "modules/common/texts";
import { OrderMailSnapshotType } from "modules/orders-manage/models/order-mails";

import styles from "./Actions.module.scss";

import { PlanrButton } from "modules/common/components/planr/button/Button";

export const Actions: React.FC<ActionsProps> = (props) => {
    const { mails, onEdit, onRemove, onAssign, onAdd } = props;

    const editMail = useCallback(() => mails.length === 1 && onEdit(mails[0]), [onEdit, mails]);
    const removeMail = useCallback(() => mails.length && onRemove(mails), [onRemove, mails]);
    const assignMails = useCallback(() => mails.length && onAssign && onAssign(mails), [onAssign, mails]);

    return (
        <div className={styles.actions}>
            {/* <DictioantyAddButton onClick={onAdd} label="" /> */}
            <PlanrButton
                type="secondary"
                icon={"general-plus-big"}
                round={true}
                onClick={onAdd}
                style={{ marginRight: "8px" }}
            />

            {onAssign && (
                <PlanrButton
                    type="graybtn"
                    icon={"general-attach"}
                    round={true}
                    size="small"
                    onClick={assignMails}
                    title="Прикрепить к проекту"
                    disabled={mails.length ? false : true}
                />
            )}

            <PlanrButton
                type="graybtn"
                icon={"general-edit"}
                round={true}
                size="small"
                onClick={editMail}
                title={texts.change}
                disabled={mails.length === 1 ? false : true}
            />
            <PlanrButton
                type="graybtn"
                icon={"general-trash"}
                size="small"
                round={true}
                onClick={removeMail}
                title={texts.remove}
                disabled={mails.length ? false : true}
            />
        </div>
    );
};

interface ActionsProps {
    mails: OrderMailSnapshotType[];
    onEdit: (mail: OrderMailSnapshotType) => void;
    onRemove: (mails: OrderMailSnapshotType[]) => void;
    onAssign?: (mails: OrderMailSnapshotType[]) => void;
    onAdd: () => void;
}
