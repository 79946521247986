import { Card } from "@blueprintjs/core";
import { FieldProps } from "formik";
import { observer } from "mobx-react";
import { FileLink } from "modules/common/components/files/FileLink";
import React from "react";
import { ACCEPT } from "../../models/supplier";
import styles from "./SupplierDocuments.module.scss";
import { OutsourcerType } from "modules/agents/outsourcers/models/outsourcer";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { SessionType } from "modules/session/auth/models/session";

class Docs extends React.PureComponent<SupplierDocumentsProps> {
  render() {
    const { supplier, fieldProps, canChange, baseUrl, label, session } = this.props;
    const documents: FileBase[] = fieldProps.field.value;
    return (
      <Card className={styles.fileList}>
        <div style={{ marginBottom: "5px" }}>{label}</div>
        {canChange && <SupplierFileUploader supplier={supplier} fieldProps={fieldProps} session={session} />}
        <br />
        <div className={styles.uploadedfilesList}>
          {documents &&
            documents.map((doc, index) => {
              return (
                <FileLink baseUrl={baseUrl} file={doc} key={index} readOnly={!canChange} onRemove={this.onRemove} />
              );
            })}
        </div>
      </Card>
    );
  }

  onRemove = (file: FileBase) => {
    const { canChange } = this.props;
    const { field, form } = this.props.fieldProps;
    const { value, name } = field;

    if (!canChange) {
      return;
    }

    const index = value.findIndex((d: FileBase) => d.fileId === file.fileId);
    if (index >= 0) {
      const newValue = [...value.slice(0, index), ...value.slice(index + 1)];
      form.setFieldValue(name, newValue);
      form.setFieldTouched(name, true);
    }
  };
}

export class SupplierFileUploader extends React.PureComponent<UploaderProps> {
  render() {
    return <PlanRDndUploader accept={ACCEPT} multiple={true} withLoader={true} onFileSelected={this.onFileSelected} />;
  }

  onFileSelected = async (file: File) => {
    const { supplier, fieldProps, session } = this.props;
    const { field, form } = fieldProps;
    let success = null;
    if (session) {
      success = await session.uploadFile(file);
    } else {
      success = await supplier.uploadFile(file);
    }

    if (success) {
      form.setFieldValue(field.name, [...field.value, success]);
      form.setFieldTouched(field.name, true);
    }
  };
}

interface SupplierDocumentsProps {
  fieldProps: FieldProps;
  supplier: OutsourcerType;
  canChange: boolean;
  baseUrl: string;
  session?: SessionType;
  label: string;
}

interface UploaderProps {
  fieldProps: FieldProps;
  session?: SessionType;
  supplier: OutsourcerType;
}

export const SupplierDocuments = observer(Docs);
