import React from "react";
import styles from "./OwnMaterialsWidget.module.scss";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import { MaterialValuesListType } from "modules/dictionaries/inventory/models/material-value-list";

class Widget extends React.Component<OwnMaterialsWidgetProps> {
    componentDidMount() {
        const { store, employerId } = this.props;
        store.loadEmployer(employerId);
    }
    render() {
        const { store } = this.props;

        return (
            <div className={`${styles.widget} own-premiums-widget`}>
                <div className={`${styles.scroller} own-premiums-widget-scroller`}>
                    {!store.isEmpty && (
                        <>
                            <div className={styles.row}>
                                <div className={`${styles.inventory} centered ${styles.headerTableTop}`}>
                                    Инв. номер
                                </div>
                                <div className={`${styles.name} centered ${styles.headerTableTop}`}>Материал</div>
                                <div className={`${styles.date} centered ${styles.headerTableTop}`}>Дата с</div>
                            </div>
                        </>
                    )}

                    {store.materialValues.map((material) => {
                        if (material.stopDate === null && material.isRemoved === false) {
                            return (
                                <div className={styles.row} key={material.id}>
                                    <div className={`${styles.inventory} centered`}>{material.fullInventoryNumber}</div>
                                    <div className={`${styles.name} centered`}>{material.name}</div>
                                    <div className={`${styles.date} centered`}>{material.currentEmployer?.since}</div>
                                </div>
                            );
                        } else return null;
                    })}
                    {store.isEmpty && <div className={styles.noData}>{texts.noData}</div>}
                </div>
            </div>
        );
    }
}

export const OwnMaterialsWidget = observer(Widget);

interface OwnMaterialsWidgetProps {
    store: MaterialValuesListType;
    orderAccess?: boolean;
    employerId: string;
}
