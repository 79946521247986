import * as yup from "yup";
import { fields } from "../../models/indicator";
import { texts } from "modules/common/texts";
const { object, string } = yup;

export const indicatorLabels = () => ({
    [fields.label]: texts.name,
});

const DeprGroupSchema = () => {
    const labels = indicatorLabels();

    return object().shape({
        [fields.label]: string().label(labels[fields.label]).required(),
    });
};

export default DeprGroupSchema;
