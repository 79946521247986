import React from "react";
import Schema from "../details/validation";
import { getColumnSize } from "modules/common/services/table/size-storage";
import { getFieldLabel } from "modules/common/services/form/fields";
import { fields } from "../../models/order-status";
import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import { RowNumberColumn } from "modules/common/services/table/helpers";

const schema = Schema();

export const columns = (tableName: string) => {
    const columnSize = getColumnSize(tableName);

    const result: ColDef[] = [
        RowNumberColumn(false, ""),
        {
            headerName: getFieldLabel(fields.name, schema, null),
            field: fields.name,
            width: columnSize(fields.name, 250),
            sortable: true,
            resizable: true,
        },
        {
            headerName: getFieldLabel(fields.description, schema, null),
            field: fields.description,
            width: columnSize(fields.description, 600),
            sortable: false,
            resizable: true,
        },
        {
            headerName: getFieldLabel(fields.color, schema, null),
            field: fields.color,
            width: columnSize(fields.color, 200),
            sortable: false,
            resizable: false,
            cellClass: "stretched flat status-color",
            cellRendererFramework: ({ value }: ICellRendererParams) => {
                return (
                    <div
                        style={{
                            backgroundColor: value,
                            height: "100%",
                            width: "50%",
                            margin: "0 auto",
                            borderRadius: "4px",
                        }}
                    >
                        {" "}
                        <span></span>
                        &nbsp;
                    </div>
                );
            },
        },
    ];

    return result;
};
