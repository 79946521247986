import React from "react";
import { Tab, Tabs } from "@blueprintjs/core";
import { routes } from "modules/common/routes";
import { menuItems } from "modules/root/components/menu/MainMenu";
import { RouteComponentProps } from "react-router";
import { Page } from "modules/common/components/page/Page";
import styles from "./TechnicalTabsPage.module.scss";

const menu = menuItems();

export class TechnicalTabsPage extends React.Component<TechnicalTabsPageProps & RouteComponentProps> {
    componentDidMount() {
        document.title = menu.technical.label;
    }

    render() {
        const { location, manualsPanel, standardPanel, templatesPanel, ordersPanel, organisationsPanel } = this.props;
        const tabId = getTabId(location.pathname);

        return (
            <Page className={styles.orderManage}>
                <Tabs
                    className={`${styles.tabs} planr-tabs-navigation`}
                    id="TechnicalTabsPage"
                    onChange={this.handleTabChange}
                    selectedTabId={tabId}
                    renderActiveTabPanelOnly={true}
                >
                    {manualsPanel && (
                        <Tab
                            className="manuals"
                            id={routes.technical.manuals}
                            title={menu.technical.nested.manuals.label}
                            panel={manualsPanel}
                        />
                    )}

                    {standardPanel && (
                        <Tab
                            className="standards"
                            id={routes.technical.standards}
                            title={menu.technical.nested.standards.label}
                            panel={standardPanel}
                        />
                    )}

                    {templatesPanel && (
                        <Tab
                            className="templates"
                            id={routes.technical.templates}
                            title={menu.technical.nested.templates.label}
                            panel={templatesPanel}
                        />
                    )}

                    {organisationsPanel && (
                        <Tab
                            className="organisations"
                            id={routes.technical.organisations}
                            title={menu.technical.nested.organisations.label}
                            panel={organisationsPanel}
                        />
                    )}

                    {ordersPanel && (
                        <Tab
                            className="orders"
                            id={routes.technical.orders}
                            title={menu.technical.nested.orders.label}
                            panel={ordersPanel}
                        />
                    )}
                </Tabs>
            </Page>
        );
    }

    handleTabChange = (tabId: string) => {
        const { history } = this.props;
        history.push(tabId);
    };
}

function getTabId(pathname: string) {
    if (pathname.includes(routes.technical.manuals)) {
        return routes.technical.manuals;
    }

    if (pathname.includes(routes.technical.standards)) {
        return routes.technical.standards;
    }

    if (pathname.includes(routes.technical.templates)) {
        return routes.technical.templates;
    }

    if (pathname.includes(routes.technical.orders)) {
        return routes.technical.orders;
    }

    if (pathname.includes(routes.technical.organisations)) {
        return routes.technical.organisations;
    }

    return "";
}

interface TechnicalTabsPageProps {
    manualsPanel: JSX.Element | null;
    standardPanel: JSX.Element | null;
    templatesPanel: JSX.Element | null;
    ordersPanel: JSX.Element | null;
    organisationsPanel: JSX.Element | null;
}
