import { InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import React from "react";
import { Schema } from "yup";
import { fields } from "../models/settings";
import styles from "./CommonSettings.module.scss";

class GeneralBase extends React.PureComponent<GeneralProps> {
  render() {
    const { schema } = this.props;
    return (
      <div className="columns">
        <div className={styles.columns}>
          <div className={styles.column}>
            <StandardFormInput
              name={fields.companyName}
              schema={schema}
              small={true}
              className="planr-form-input"
              inline={false}
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.shortCompanyName}
              schema={schema}
              inline={false}
              small={true}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.directorName}
              schema={schema}
              small={true}
              inline={false}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.directorNameGenitive}
              schema={schema}
              small={true}
              inline={false}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup id={field.name} {...field} className="planr-default-input" autoComplete="off" />
              )}
            </StandardFormInput>
          </div>

          <div className={styles.column}>
            <StandardFormInput
              name={fields.okved}
              schema={schema}
              inline={false}
              small={true}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.address}
              schema={schema}
              small={true}
              inline={false}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.phoneNumber}
              schema={schema}
              small={true}
              inline={false}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.email}
              schema={schema}
              inline={false}
              small={true}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
          </div>
        </div>
      </div>
    );
  }
}

export const GeneralSettings = observer(GeneralBase);

interface GeneralProps {
  schema: Schema<any>;
}
