import { fields, initialState as emptyOrder, OrderSummary, OrderSummaryType } from "modules/orders-manage/models/order";
import React from "react";
import ReactDOM from "react-dom";

import { Field, FieldProps, Form, Formik } from "formik";
import { observer } from "mobx-react";
import styles from "./OrderCommunication.module.scss";
// import { OrderCommunicationRow } from "./OrderCommunicationRow";
import { Location } from "history";

import { move } from "modules/common/services/array";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import portal from "../portal";

import { OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";

import { cloneDeep } from "lodash";
import { PlanrButton } from "modules/common/components/planr/button/Button";

import { InputGroup } from "@blueprintjs/core";
import { getEnv } from "mobx-state-tree";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { ProjectAssigner } from "modules/common/components/orders/ProjectAssigner";
import DenyNavigation from "modules/common/components/routing/DenyNavigation";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { routes } from "modules/common/routes";
import {
    DefaultSelectedOption,
    filterItemPredicate,
    renderSingleOption,
    SimpleSelect,
} from "modules/common/services/form/select";
import { buildPatch, preventSubmitKeyDown } from "modules/common/services/form/values";
import { eat } from "modules/common/services/typescript";
import { texts } from "modules/common/texts";
import { OrderCommunicationRowSnapshotType } from "modules/orders-manage/models/order-communication";
import { OrdersStoreType } from "modules/orders-manage/models/orders-store";
import { EmployerDictionaryType } from "modules/spending/employee/models/employee-dictionary";
import { v4 } from "uuid";
import { SettingsStoreType } from "../../../session/settings/models/settings";
import { OrderCommunicationType } from "../../models/order";
import { mapOrderCommunication, OrderCommunicationRowType } from "../../models/order-communication";
import { OrderCommunicationHeader } from "./OrderCommunicationHeader";
import { OrderCommunicationRow } from "./OrderCommunicationRow";
// import { OrderCommunicationRow } from "./OrderCommunicationRow";

// import { OrderCommunicationHeader } from "./OrderCommunicationHeader";

class OrderCommunication extends React.Component<OrderCommunicationProps, OrderCommunicationState> {
    private fieldProps: FieldProps | null = null;
    constructor(props: OrderCommunicationProps) {
        super(props);

        this.state = {
            activeRow: null,
            managerShow: "",
            employerShow: [],
            responses: [],
            visibleOrders: false,
            proxyLoading: false,
        };
    }
    async componentDidMount() {
        const { orderId, store, settings } = this.props;
        if (orderId !== EMPTY_OBJECT_ID) {
            await settings.loadShortSettings();
            if (store.details.orderCommunication.projectManager !== "") this.setState({ managerShow: "dictionary" });
            if (
                store.details.orderCommunication.projectManager === "" &&
                store.details.orderCommunication.projectManagerName !== ""
            )
                this.setState({ managerShow: "self" });
            if (
                store.details.orderCommunication.projectManager === "" &&
                store.details.orderCommunication.projectManagerName === ""
            )
                this.setState({ managerShow: "" });

            if (
                store.details.orderCommunication.orderCommunicationRows &&
                store.details.orderCommunication.orderCommunicationRows.length > 0
            ) {
                const rows: OrderCommunicationRowSnapshotType[] = [
                    ...store.details.orderCommunication.orderCommunicationRows,
                ];

                const arr: {
                    guid: string;
                    show: "dictionary" | "self" | "";
                }[] = rows.map((r) => {
                    if (r.employer !== "") {
                        return {
                            guid: r.guid,
                            show: "dictionary",
                        };
                    }
                    if (r.employerName !== "") {
                        return {
                            guid: r.guid,
                            show: "self",
                        };
                    }
                    return {
                        guid: r.guid,
                        show: "",
                    };
                });
                let newResposes: { value: string; guid: string; activeGuid: string }[] = [];

                rows.forEach((r) => {
                    if (r.responses && r.responses.length > 0) {
                        r.responses.forEach((c) => {
                            newResposes.push({
                                guid: v4(),
                                activeGuid: r.guid,
                                value: c,
                            });
                        });
                    }
                });
                this.setState({ employerShow: arr, responses: newResposes });
            }
        }
    }

    render() {
        const { settings, name, readOnly, store, schema, orderId, employers, onPrint, orderDictionary } = this.props;
        const { activeRow, managerShow, employerShow, responses, visibleOrders, proxyLoading } = this.state;

        const initial = getFormValues(store.details);

        return (
            <Formik
                initialValues={initial}
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    let patch: any;

                    if (store.details.isNewlyCreated) {
                        patch = cloneDeep(values);
                    } else {
                        patch = buildPatch(initial, values);
                    }

                    const orderSnapshot = await store.saveOrderCommunication(patch);

                    setSubmitting(false);

                    if (!!orderSnapshot) {
                        store.details.refreshOrderCommunication(orderSnapshot);

                        resetForm();

                        //  this.onOrderSaved();
                    }

                    if (orderSnapshot === null) {
                        resetForm();
                    }
                }}
                onReset={() => {
                    const { orderId, store } = this.props;
                    if (orderId !== EMPTY_OBJECT_ID) {
                        if (store.details.orderCommunication.projectManager !== "")
                            this.setState({ managerShow: "dictionary" });
                        if (
                            store.details.orderCommunication.projectManager === "" &&
                            store.details.orderCommunication.projectManagerName !== ""
                        )
                            this.setState({ managerShow: "self" });
                        if (
                            store.details.orderCommunication.projectManager === "" &&
                            store.details.orderCommunication.projectManagerName === ""
                        )
                            this.setState({ managerShow: "" });

                        if (
                            store.details.orderCommunication.orderCommunicationRows &&
                            store.details.orderCommunication.orderCommunicationRows.length > 0
                        ) {
                            const rows: OrderCommunicationRowSnapshotType[] = [
                                ...store.details.orderCommunication.orderCommunicationRows,
                            ];

                            const arr: {
                                guid: string;
                                show: "dictionary" | "self" | "";
                            }[] = rows.map((r) => {
                                if (r.employer !== "") {
                                    return {
                                        guid: r.guid,
                                        show: "dictionary",
                                    };
                                }
                                if (r.employerName !== "") {
                                    return {
                                        guid: r.guid,
                                        show: "self",
                                    };
                                }
                                return {
                                    guid: r.guid,
                                    show: "",
                                };
                            });

                            let newResposes: { value: string; guid: string; activeGuid: string }[] = [];
                            rows.forEach((r) => {
                                if (r.responses && r.responses.length > 0) {
                                    r.responses.forEach((c) => {
                                        newResposes.push({
                                            guid: v4(),
                                            activeGuid: r.guid,
                                            value: c,
                                        });
                                    });
                                }
                            });
                            this.setState({ employerShow: arr, responses: newResposes });
                        }
                    }
                }}
            >
                {(formProps) => {
                    if (orderId !== EMPTY_OBJECT_ID) {
                        return (
                            <Form autoComplete="off">
                                <DenyNavigation
                                    when={formProps.dirty}
                                    message="На странице остались несохраненные изменения"
                                    skip={this.navigationPromt}
                                />
                                <div className={styles.ipd}>
                                    <div className="planr-tools">
                                        <PlanrButton
                                            type="graybtn"
                                            icon="general-print"
                                            round={true}
                                            title={texts.print}
                                            onClick={async () => {
                                                await onPrint(orderId);
                                            }}
                                        />
                                    </div>
                                    {
                                        <Field name={name}>
                                            {(fieldProps: FieldProps) => {
                                                this.fieldProps = fieldProps;
                                                const value = fieldProps.field.value as OrderCommunicationType;

                                                const rows =
                                                    value && value.orderCommunicationRows
                                                        ? value.orderCommunicationRows
                                                        : null;
                                                const managerId =
                                                    value && value.projectManager ? value.projectManager : null;

                                                const managerName =
                                                    value && value.projectManagerName ? value.projectManagerName : null;

                                                const managerPhone =
                                                    value && value.projectManagerPhone
                                                        ? value.projectManagerPhone
                                                        : null;

                                                const managerMobilePhone =
                                                    value && value.projectManagerMobilePhone
                                                        ? value.projectManagerMobilePhone
                                                        : null;

                                                const managerEmail =
                                                    value && value.projectManagerEmail
                                                        ? value.projectManagerEmail
                                                        : null;
                                                const managerExists =
                                                    managerId ||
                                                    managerName ||
                                                    managerPhone ||
                                                    managerMobilePhone ||
                                                    managerEmail;
                                                const dataExists = managerExists || (rows && rows.length > 0);

                                                let selectedItem: Select = { id: "", label: "" };
                                                if (managerId) {
                                                    const employee = employers.employee.find((e) => e.id === managerId);
                                                    if (employee) {
                                                        selectedItem = { id: employee.id, label: employee.name };
                                                    }
                                                }
                                                return (
                                                    <div>
                                                        {visibleOrders && (
                                                            <ProjectAssigner
                                                                orders={orderDictionary}
                                                                onClose={this.closeOrders}
                                                                excludeId={store.details.id}
                                                                onAssign={this.copyOrder}
                                                                loading={proxyLoading}
                                                            />
                                                        )}

                                                        {visibleOrders && dataExists && (
                                                            <RemoveConfirmation<string>
                                                                what={() => "Лист коммуникаций"}
                                                                actionName="перезаписать"
                                                                onConfirmed={this.copyOrder}
                                                                render={({ confirmRemoving }) => (
                                                                    <ProjectAssigner
                                                                        orders={orderDictionary}
                                                                        onClose={this.closeOrders}
                                                                        excludeId={store.details.id}
                                                                        onAssign={confirmRemoving}
                                                                        loading={proxyLoading}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                        <div className={styles.manager}>
                                                            <div className={styles.managerTitle}>
                                                                Управляющий проектом
                                                            </div>

                                                            {managerShow === "" && !readOnly ? (
                                                                <div className={styles.managerButtons}>
                                                                    <PlanrButton
                                                                        type="secondary"
                                                                        style={{ marginRight: "10px" }}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                managerShow: "dictionary",
                                                                            });
                                                                        }}
                                                                    >
                                                                        Выбрать из справочника
                                                                    </PlanrButton>

                                                                    <PlanrButton
                                                                        type="secondary"
                                                                        onClick={() => {
                                                                            this.setState({ managerShow: "self" });
                                                                        }}
                                                                    >
                                                                        Добавить вручную
                                                                    </PlanrButton>
                                                                </div>
                                                            ) : managerShow === "dictionary" ? (
                                                                <div className={styles.rowManager}>
                                                                    <MentorSelectRenderer
                                                                        item={selectedItem}
                                                                        items={employers.employee}
                                                                        style={{ width: "480px" }}
                                                                        className={styles.mentorSelect}
                                                                        disabled={readOnly}
                                                                        onSelect={(item: SelectItem) => {
                                                                            this.onSelectManager(item);
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <div className={styles.rowManager}>
                                                                        <div>ФИО</div>
                                                                        <InputGroup
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            className="planr-default-input"
                                                                            data-lpignore="true"
                                                                            style={{ width: "350px" }}
                                                                            value={managerName ? managerName : ""}
                                                                            required={true}
                                                                            readOnly={readOnly}
                                                                            onChange={(
                                                                                e: React.FormEvent<HTMLInputElement>
                                                                            ) => {
                                                                                this.onWriteManager(
                                                                                    "projectManagerName",
                                                                                    e.currentTarget.value
                                                                                );
                                                                            }}
                                                                            onKeyDown={preventSubmitKeyDown}
                                                                        />
                                                                    </div>
                                                                    <div className={styles.rowManager}>
                                                                        <div>Телефон</div>
                                                                        <InputGroup
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            className="planr-default-input"
                                                                            style={{ width: "350px" }}
                                                                            data-lpignore="true"
                                                                            readOnly={readOnly}
                                                                            value={managerPhone ? managerPhone : ""}
                                                                            onChange={(
                                                                                e: React.FormEvent<HTMLInputElement>
                                                                            ) => {
                                                                                this.onWriteManager(
                                                                                    "projectManagerPhone",
                                                                                    e.currentTarget.value
                                                                                );
                                                                            }}
                                                                            onKeyDown={preventSubmitKeyDown}
                                                                        />
                                                                    </div>
                                                                    <div className={styles.rowManager}>
                                                                        <div>Моб. телефон</div>
                                                                        <InputGroup
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            readOnly={readOnly}
                                                                            className="planr-default-input"
                                                                            style={{ width: "350px" }}
                                                                            data-lpignore="true"
                                                                            value={
                                                                                managerMobilePhone
                                                                                    ? managerMobilePhone
                                                                                    : ""
                                                                            }
                                                                            onChange={(
                                                                                e: React.FormEvent<HTMLInputElement>
                                                                            ) => {
                                                                                this.onWriteManager(
                                                                                    "projectManagerMobilePhone",
                                                                                    e.currentTarget.value
                                                                                );
                                                                            }}
                                                                            onKeyDown={preventSubmitKeyDown}
                                                                        />
                                                                    </div>
                                                                    <div className={styles.rowManager}>
                                                                        <div>Эл. почта</div>
                                                                        <InputGroup
                                                                            type="text"
                                                                            readOnly={readOnly}
                                                                            autoComplete="off"
                                                                            className="planr-default-input"
                                                                            style={{ width: "350px" }}
                                                                            data-lpignore="true"
                                                                            value={managerEmail ? managerEmail : ""}
                                                                            onChange={(
                                                                                e: React.FormEvent<HTMLInputElement>
                                                                            ) => {
                                                                                this.onWriteManager(
                                                                                    "projectManagerEmail",
                                                                                    e.currentTarget.value
                                                                                );
                                                                            }}
                                                                            onKeyDown={preventSubmitKeyDown}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {managerShow !== "" && !readOnly && (
                                                                <PlanrButton
                                                                    type="neutral"
                                                                    icon="general-trash"
                                                                    style={{ marginTop: "-8px" }}
                                                                    onClick={() => {
                                                                        this.ClearManager();
                                                                        this.setState({ managerShow: "" });
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                        <div className={styles.contents}>
                                                            {rows && rows.length > 0 && (
                                                                <OrderCommunicationHeader active={activeRow} />
                                                            )}
                                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                                                <Droppable droppableId="ipd">
                                                                    {(provided) => (
                                                                        <div
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                        >
                                                                            {rows &&
                                                                                rows.map((indicator, index) => {
                                                                                    const active = employerShow.find(
                                                                                        (e) =>
                                                                                            activeRow &&
                                                                                            e.guid === activeRow.guid
                                                                                    );
                                                                                    const activeitem: {
                                                                                        guid: string;
                                                                                        show:
                                                                                            | "dictionary"
                                                                                            | "self"
                                                                                            | "";
                                                                                    } = active
                                                                                        ? active
                                                                                        : {
                                                                                              guid: "",
                                                                                              show: "",
                                                                                          };
                                                                                    return (
                                                                                        <Draggable
                                                                                            key={indicator.guid}
                                                                                            draggableId={indicator.guid}
                                                                                            index={index}
                                                                                            isDragDisabled={!!activeRow}
                                                                                        >
                                                                                            {(draggable, snapshot) => {
                                                                                                const child = (
                                                                                                    <OrderCommunicationRow
                                                                                                        key={
                                                                                                            indicator.guid
                                                                                                        }
                                                                                                        settings={
                                                                                                            settings
                                                                                                        }
                                                                                                        onInput={
                                                                                                            this.onInput
                                                                                                        }
                                                                                                        row={indicator}
                                                                                                        active={
                                                                                                            activeRow
                                                                                                        }
                                                                                                        onActivate={
                                                                                                            this
                                                                                                                .onActivateRow
                                                                                                        }
                                                                                                        onRemove={
                                                                                                            this
                                                                                                                .onRemoveRow
                                                                                                        }
                                                                                                        readOnly={
                                                                                                            readOnly
                                                                                                        }
                                                                                                        onSave={
                                                                                                            this
                                                                                                                .onSaveRow
                                                                                                        }
                                                                                                        draggable={
                                                                                                            draggable
                                                                                                        }
                                                                                                        isDragging={
                                                                                                            snapshot.isDragging
                                                                                                        }
                                                                                                        employers={
                                                                                                            employers
                                                                                                        }
                                                                                                        employerShow={
                                                                                                            activeitem
                                                                                                        }
                                                                                                        onActiveEmployer={
                                                                                                            this
                                                                                                                .onActiveEmployer
                                                                                                        }
                                                                                                        responses={
                                                                                                            responses
                                                                                                        }
                                                                                                        setResponse={
                                                                                                            this
                                                                                                                .setResponse
                                                                                                        }
                                                                                                        removeResponse={
                                                                                                            this
                                                                                                                .removeResponse
                                                                                                        }
                                                                                                        addResponse={
                                                                                                            this
                                                                                                                .addResponse
                                                                                                        }
                                                                                                    />
                                                                                                );

                                                                                                return snapshot.isDragging
                                                                                                    ? ReactDOM.createPortal(
                                                                                                          child,
                                                                                                          portal
                                                                                                      )
                                                                                                    : child;
                                                                                            }}
                                                                                        </Draggable>
                                                                                    );
                                                                                })}

                                                                            {provided.placeholder}
                                                                        </div>
                                                                    )}
                                                                </Droppable>
                                                            </DragDropContext>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </Field>
                                    }
                                    {!readOnly && (
                                        <div className="collapse-add" style={{ margin: "16px", marginBottom: "80px" }}>
                                            <PlanrButton
                                                type="secondary"
                                                icon="general-plus-big"
                                                className="collapse-add-rows-small"
                                                onClick={async () => {
                                                    const unit = await store.details.emptyOrderCommunicationRow();
                                                    this.onAddRow(unit);
                                                }}
                                            >
                                                Добавить участника
                                            </PlanrButton>
                                        </div>
                                    )}
                                    {!readOnly && (
                                        <div className="collapse-add" style={{ margin: "16px", marginBottom: "80px" }}>
                                            <PlanrButton
                                                type="dashed"
                                                icon="general-plus-big"
                                                className="collapse-add-rows-small"
                                                onClick={this.showOrders}
                                            >
                                                Добавить из проекта
                                            </PlanrButton>
                                        </div>
                                    )}
                                </div>
                                {!readOnly && (
                                    <div className={styles.buttons}>
                                        <div className={styles.buttonsLeft}>
                                            <StandardFormButtons
                                                {...formProps}
                                                isRemoved={false}
                                                what={""}
                                                isNewlyCreated={true}
                                                submitOnDirty={true}
                                                onRemove={eat}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Form>
                        );
                    } else {
                        return <h2>Сначала необходимо сохранить основные данные договора</h2>;
                    }
                }}
            </Formik>
        );
    }

    onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination || !this.fieldProps) {
            return;
        }
        const fieldProps = this.fieldProps;
        const orderCommunication: OrderCommunicationType = fieldProps.field.value;
        let newRows: OrderCommunicationRowType[] = [];
        if (
            orderCommunication &&
            orderCommunication.orderCommunicationRows &&
            orderCommunication.orderCommunicationRows.length > 0
        ) {
            newRows = [...orderCommunication.orderCommunicationRows];
            const from = result.source.index;
            // new index
            const to = result.destination.index;

            const newArr = move(newRows, from, to);

            const newValue = { ...orderCommunication, orderCommunicationRows: newArr };
            fieldProps.form.setFieldValue(this.props.name, newValue);
            fieldProps.form.setFieldTouched(this.props.name, true);
        }
        // initial index
    };

    onAddRow = (row: OrderCommunicationRowSnapshotType) => {
        if (this.fieldProps) {
            const fieldProps = this.fieldProps;
            const orderCommunication: OrderCommunicationType = fieldProps.field.value;
            let newRows: OrderCommunicationRowSnapshotType[] = [];

            if (
                orderCommunication &&
                orderCommunication.orderCommunicationRows &&
                orderCommunication.orderCommunicationRows.length > 0
            ) {
                newRows = [...orderCommunication.orderCommunicationRows];
                newRows.push(row);
            } else {
                newRows.push(row);
            }
            const employerrows = [...this.state.employerShow];
            employerrows.push({ guid: row.guid, show: "" });
            this.setState({ employerShow: employerrows });
            const newValue = { ...orderCommunication, orderCommunicationRows: newRows };
            fieldProps.form.setFieldValue(this.props.name, newValue);
            fieldProps.form.setFieldTouched(this.props.name, true);
            this.onActivateRow({ ...row });
        }
    };

    onRemoveRow = (row: OrderCommunicationRowSnapshotType) => {
        if (this.fieldProps) {
            const fieldProps = this.fieldProps;
            const orderCommunication: OrderCommunicationType = fieldProps.field.value;
            if (
                orderCommunication &&
                orderCommunication.orderCommunicationRows &&
                orderCommunication.orderCommunicationRows.length > 0
            ) {
                const newValue = orderCommunication.orderCommunicationRows.filter((i) => i.guid !== row.guid);
                fieldProps.form.setFieldValue(this.props.name, {
                    ...orderCommunication,
                    orderCommunicationRows: newValue,
                });

                const employerrows = [...this.state.employerShow];
                const index = employerrows.findIndex((i) => i.guid === row.guid);
                if (index > -1) {
                    employerrows.splice(index, 1);
                    this.setState({ employerShow: employerrows });
                }

                fieldProps.form.setFieldTouched(this.props.name, true);
            }
        }
    };

    onSaveRow = (row: OrderCommunicationRowSnapshotType) => {
        if (this.fieldProps) {
            const fieldProps = this.fieldProps;
            const orderCommunication: OrderCommunicationType = fieldProps.field.value;
            if (
                orderCommunication &&
                orderCommunication.orderCommunicationRows &&
                orderCommunication.orderCommunicationRows.length > 0
            ) {
                const arr = [...orderCommunication.orderCommunicationRows];

                // const allResponses = [...this.state.allResponses];
                const newResponses = [...this.state.responses.filter((i) => i.value !== "")];
                // allResponses.forEach((r) => {
                //     const index = newResponses.findIndex((i) => i.guid === r.guid)
                // });
                const newValue = arr.map((i) => (i.guid === row.guid ? { ...row } : i));
                const newarr: OrderCommunicationRowSnapshotType[] = [];

                newValue.forEach((v) => {
                    const responses = newResponses.filter((r) => r.activeGuid === v.guid);
                    const resArr = responses.map((a) => a.value);

                    newarr.push({ ...v, responses: resArr });
                });

                fieldProps.form.setFieldValue(this.props.name, {
                    ...orderCommunication,
                    orderCommunicationRows: newarr,
                });
                fieldProps.form.setFieldTouched(this.props.name, true);
            }
        }
        this.onActivateRow(null);
    };

    onWriteManager = (field: string, value: string) => {
        if (this.fieldProps) {
            const fieldProps = this.fieldProps;

            const orderCommunication: OrderCommunicationType = fieldProps.field.value;
            const newValue = { ...orderCommunication, [field]: value };

            fieldProps.form.setFieldValue(this.props.name, newValue);
            fieldProps.form.setFieldTouched(this.props.name, true);

            // setTimeout(() => fieldProps.form.validateField(this.props.name));
        }
    };

    ClearManager = () => {
        if (this.fieldProps) {
            const fieldProps = this.fieldProps;
            const orderCommunication: OrderCommunicationType = fieldProps.field.value;
            const newValue = {
                ...orderCommunication,
                projectManager: "",
                projectManagerName: "",
                projectManagerPhone: "",
                projectManagerMobilePhone: "",
                projectManagerEmail: "",
            };

            fieldProps.form.setFieldValue(this.props.name, newValue);
            fieldProps.form.setFieldTouched(this.props.name, true);

            // setTimeout(() => fieldProps.form.validateField(this.props.name));
        }
    };

    onSelectManager = (item: SelectItem) => {
        if (this.fieldProps) {
            const fieldProps = this.fieldProps;

            const orderCommunication: OrderCommunicationType = fieldProps.field.value;
            const newValue = { ...orderCommunication, projectManager: item.id };

            fieldProps.form.setFieldValue(this.props.name, newValue);
            fieldProps.form.setFieldTouched(this.props.name, true);

            // setTimeout(() => fieldProps.form.validateField(this.props.name));
        }
    };
    onInput = (activeRow: OrderCommunicationRowSnapshotType) => this.setState({ activeRow });

    onActivateRow = (row: OrderCommunicationRowSnapshotType | null) => {
        this.setState({ activeRow: row });
    };
    onActiveEmployer = (item: { guid: string; show: "dictionary" | "self" | "" }) => {
        const newItems = [...this.state.employerShow];
        const index = newItems.findIndex((n) => n.guid === item.guid);
        if (index > -1) {
            newItems.splice(index, 1, item);
            this.setState({ employerShow: newItems });
        }
    };

    setResponse = (value: string, guid: string) => {
        const responses = [...this.state.responses];
        const index = responses.findIndex((e) => e.guid === guid);
        if (index > -1) {
            const item = responses[index];
            responses.splice(index, 1, { ...item, value });
            this.setState({ responses });
        }
    };

    removeResponse = (guid: string) => {
        const responses = [...this.state.responses];
        const index = responses.findIndex((e) => e.guid === guid);
        if (index > -1) {
            responses.splice(index, 1);
            this.setState({ responses });
        }
    };

    addResponse = (value: string, activeGuid: string) => {
        const responses = [...this.state.responses];
        responses.push({ guid: v4(), value, activeGuid });
        this.setState({ responses });
    };

    showOrders = () => this.setState({ visibleOrders: true });
    closeOrders = () => this.setState({ visibleOrders: false });

    getOrderId = () => this.props.store.details.id || EMPTY_OBJECT_ID;

    navigationPromt = (location: Location) => {
        // just tabs switching
        return location.pathname.startsWith(routes.orders.communicationTab(this.getOrderId())) ? true : false;
    };

    copyOrder = async (orderId: string) => {
        const { orderDictionary, store } = this.props;
        const source = orderDictionary.asMap[orderId];

        if (source && this.fieldProps) {
            this.setState({ proxyLoading: true });

            try {
                const proxy = OrderSummary.create(emptyOrder(false), getEnv(store.details));
                const loaded = await proxy.load(source.id, true);

                if (loaded) {
                    const newValue = proxy.orderCommunication;

                    this.fieldProps.form.setFieldValue(this.props.name, newValue);
                    this.fieldProps.form.setFieldTouched(this.props.name, true);
                    const value = this.fieldProps.field.value;
                    if (value.projectManager !== "") this.setState({ managerShow: "dictionary" });
                    if (value.projectManager === "" && value.projectManagerName !== "")
                        this.setState({ managerShow: "self" });
                    if (value.projectManager === "" && value.projectManagerName === "")
                        this.setState({ managerShow: "" });

                    if (value.orderCommunicationRows && value.orderCommunicationRows.length > 0) {
                        const rows: OrderCommunicationRowSnapshotType[] = [...value.orderCommunicationRows];

                        const arr: {
                            guid: string;
                            show: "dictionary" | "self" | "";
                        }[] = rows.map((r) => {
                            if (r.employer !== "") {
                                return {
                                    guid: r.guid,
                                    show: "dictionary",
                                };
                            }
                            if (r.employerName !== "") {
                                return {
                                    guid: r.guid,
                                    show: "self",
                                };
                            }
                            return {
                                guid: r.guid,
                                show: "",
                            };
                        });

                        let newResposes: { value: string; guid: string; activeGuid: string }[] = [];
                        rows.forEach((r) => {
                            if (r.responses && r.responses.length > 0) {
                                r.responses.forEach((c) => {
                                    newResposes.push({
                                        guid: v4(),
                                        activeGuid: r.guid,
                                        value: c,
                                    });
                                });
                            }
                        });
                        this.setState({ employerShow: arr, responses: newResposes });
                    }
                }
            } finally {
                this.setState({ proxyLoading: false });
            }
        }

        this.closeOrders();
    };
}

export const OrderCommunicationPage = observer(OrderCommunication);

interface OrderCommunicationProps {
    name: string;
    orderDictionary: OrderDictionaryType;
    readOnly: boolean;
    baseUrl: string;
    employers: EmployerDictionaryType;
    onPrint: (id: string) => Promise<boolean | undefined>;
    settings: SettingsStoreType;
    store: OrdersStoreType;
    schema: any;
    orderId: string;
}
interface Select {
    id: string;
    label: string;
}
interface OrderCommunicationState {
    activeRow: OrderCommunicationRowSnapshotType | null;
    managerShow: "dictionary" | "self" | "";
    employerShow:
        | {
              guid: string;
              show: "dictionary" | "self" | "";
          }[];
    responses: { value: string; guid: string; activeGuid: string }[];
    visibleOrders: boolean;
    proxyLoading: boolean;
}

function getFormValues(order: OrderSummaryType) {
    const result = {
        [fields.orderCommunication]: mapOrderCommunication(order.orderCommunication),
    };

    return result;
}
interface SelectRendererProps {
    item: Select;
    items: any[];
    style: React.CSSProperties;
    onSelect: (item: SelectItem) => void;
    className: string;
    disabled: boolean;
}

export const MentorSelectRenderer = ({ item, items, onSelect, style, className, disabled }: SelectRendererProps) => {
    if (!item || !item.id) {
        style.color = "#ea561e";
    }

    return (
        <SimpleSelect
            className={className}
            activeItem={item}
            filterable={true}
            itemPredicate={filterItemPredicate}
            popoverProps={{
                boundary: "viewport",
                usePortal: true,
                popoverClassName: "order-content_mentor-select-dropdown",
            }}
            inputProps={{
                placeholder: texts.search,
            }}
            items={items}
            itemRenderer={renderSingleOption}
            onItemSelect={onSelect}
            matchTargetWidth={false}
            disabled={disabled}
        >
            <DefaultSelectedOption option={item} style={style} />
        </SimpleSelect>
    );
};
