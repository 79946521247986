import React from "react";
import { NewsStoreType, NewsType, NewsSnapshotType } from "modules/main/models/news-store";
import InfiniteScroll from "react-infinite-scroller";
import styles from "./NewsWidget.module.scss";
import { texts } from "modules/common/texts";
import { observer } from "mobx-react";
import { OpenedNews } from "./OpenedNews";
import { NewsEditor } from "./NewsEditor";
import { SessionType } from "modules/session/auth/models/session";
import { can } from "modules/session/auth/access";
import func from "../../functionalities";
import { getSnapshot } from "mobx-state-tree";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { RouteComponentProps } from "react-router-dom";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { trimStart } from "modules/common/services/strings";
import { parse } from "querystring";

class Widget extends React.Component<NewsWidgetProps & RouteComponentProps, NewsWidgetState> {
  constructor(props: NewsWidgetProps & RouteComponentProps) {
    super(props);

    this.state = { editable: null, collapsed: false };
  }
  newsExternalParams = (locationHash: string) => {
    const hash = trimStart("#", locationHash);

    let newsId = "";
    if (hash && hash !== "#") {
      const metadata = parse(hash);
      newsId = (metadata["newsId"] as string) || "";
      return newsId;
    }
    return newsId;
  };

  onToggleCollapse = () => this.setState({ collapsed: !this.state.collapsed });
  async componentDidMount() {
    const { store, location } = this.props;
    if (store.isEmpty) {
      await store.loadFirstPage();
    }
    const newsId = this.newsExternalParams(location.hash);
    const index = store.items.findIndex((item) => item.id === newsId);
    if (index !== -1) {
      store.select(newsId);
    }
  }

  render() {
    const { store, session, onOpen, isDialog } = this.props;
    const { editable } = this.state;
    const canEditNews = can(func.NEWS_EDIT, session.access);
    let sum = 0;
    function unread() {
      store.items.forEach((item) => {
        if (!item.read) {
          sum++;
        }
      });
      return sum;
    }

    const unreaded = unread();
    return (
      <div className={`${isDialog ? styles.widgetDialog : styles.widget} main-widget`}>
        {store.selected && (
          <DetailsPopup
            title={
              <div>
                <div className={styles.titleDialog}>{store.selected.name}</div>
                <span className={styles.subtitleDialog}>{store.selected.day}</span>
              </div>
            }
            onCancel={this.onNewsSelected}
          >
            <OpenedNews news={store.selected} onClose={this.closeNewsViewer} />
          </DetailsPopup>
        )}

        {editable && (
          <NewsEditor
            news={editable}
            onClose={this.closeNewsEditor}
            onSave={store.saveNews}
            onRemove={store.removeNews}
          />
        )}

        <div className="planr-block-header flat-mode">
          Новости <span className="unreaded">{unreaded >= 100 ? "99+" : unreaded}</span>
          {canEditNews && (
            <PlanrButton
              type="secondary"
              round={true}
              icon="general-plus-big"
              onClick={this.addNews}
              title="Добавить новость"
              size="small"
              // style={{ marginLeft: "355px" }}
              style={{
                position: "absolute",
                right: isDialog ? "12px" : "145px",
                height: "25px",
                width: "25px",
              }}
            />
          )}
          {onOpen && (
            <PlanrButton
              size="small"
              type="lightblueish"
              onClick={onOpen}
              style={{
                position: "absolute",
                right: "0px",
                height: "25px",
                width: "135px",
              }}
            >
              Открыть все
            </PlanrButton>
          )}
        </div>

        <div className={styles.scroller}>
          <InfiniteScroll
            pageStart={1}
            loadMore={store.loadNext}
            hasMore={store.hasMore}
            useWindow={false}
            initialLoad={false}
            threshold={50}
          >
            {store.items.map((message) => this.message(message, canEditNews))}
          </InfiniteScroll>
          {store.isEmpty && <div className={styles.noData}>{texts.noData}</div>}
        </div>
      </div>
    );
  }

  message = (news: NewsType, canEditNews: boolean) => {
    return (
      <div key={news.id} className={`${styles.news} ${news.read ? "" : styles.unread}`}>
        <div className={styles.date}>{news.created}</div>
        <div className={`${styles.title} `} onClick={() => this.openNewsViewer(news.id)} title={texts.open}>
          {news.title}
        </div>
        {canEditNews && (
          <div className={styles.editButton}>
            <PlanrButton
              type="neutral"
              round={true}
              icon="general-edit"
              onClick={() => this.editNews(news)}
              title={texts.change}
              size="small"
            />
          </div>
        )}
      </div>
    );
  };

  openNewsViewer = (id: string) => {
    this.closeNewsEditor();
    this.onNewsSelected(id);
  };

  closeNewsViewer = () => this.props.store.select("");

  onNewsSelected = (id: string = "") => {
    const { store } = this.props;
    if (id) {
      store.select(id);
    } else {
      store.select("");
    }
  };

  addNews = () => {
    this.closeNewsViewer();
    this.setState({ editable: this.props.store.newsFactory() });
  };

  editNews = (news: NewsType) => {
    this.closeNewsViewer();
    this.setState({ editable: getSnapshot(news) });
  };

  closeNewsEditor = () => this.setState({ editable: null });
}

export const NewsWidget = observer(Widget);

interface NewsWidgetProps {
  store: NewsStoreType;
  session: SessionType;
  onOpen?: () => void;
  isDialog?: boolean;
}

interface NewsWidgetState {
  editable: NewsSnapshotType | null;
  collapsed: boolean;
}
