import * as yup from 'yup';
import { fields } from '../../models/supplier';
import { BankDetailsSchema } from 'modules/common/models/bank-details';
import { extractDigits } from 'modules/common/services/strings';
import { texts } from 'modules/common/texts';
const { object, string, bool } = yup;

export const LegalNameLabel = texts.name;

export const supplierLabels = () => ({
    [fields.name]: LegalNameLabel,
    [fields.fullName]: 'Полное наименование',
    [fields.phone]: 'Телефон',
    [fields.email]: 'E-mail',
    [fields.comment]: texts.comment,
    [fields.hasAgentOrder]: 'Агентский договор'
});

const SupplierSchema = () => {
    const labels = supplierLabels();
    const bankDetails = BankDetailsSchema();

    return object().shape({

        [fields.name]: string()
            .label(labels[fields.name])
            .required(),

        [fields.fullName]: string()
            .label(labels[fields.fullName])
            .required(),

        [fields.phone]: string()
            .label(labels[fields.phone])
            .transform(function(val, _) {
                return this.isType(val) && val ? extractDigits(val) : val;
            })
            .notRequired()
            .min(7, 'Минимум 7 цифр')
            .max(15, 'Максимум 15 цифр'),

        [fields.email]: string()
            .label(labels[fields.email])
            .notRequired()
            .email(),

        [fields.comment]: string()
            .label(labels[fields.comment])
            .notRequired(),

        [fields.hasAgentOrder]: bool()
            .label(labels[fields.hasAgentOrder]),

        [fields.bankDetails]: bankDetails.required(),
    });
}

export default SupplierSchema;
