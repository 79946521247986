import * as yup from "yup";
const { object, string } = yup;

export const authLabels = () => ({
  login: "Логин",
  password: "Пароль",
  code: "Код",
});

const AuthSchema = () => {
  const labels = authLabels();

  return object().shape({
    login: string().label(labels.login).required(),

    password: string().label(labels.password).required(),

    code: string().label(labels.code).notRequired(),
  });
};

export default AuthSchema;
