import React from 'react';
import { DatePicker } from 'modules/common/components/form/DatePicker';
import { DATE_TIME_FORMAT, ICON_BUTTON_SIZE } from 'modules/common/constants';
import moment from 'moment';
import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core/lib/esnext';
import { formatDate } from 'modules/common/services/formatting/date';
import { ICellRendererParams } from '@ag-grid-community/core';
import styles from './PaymentList.module.scss';
import { Icon } from '@blueprintjs/core';
import { FlowDatasetOrderPaymentSnapshotType } from 'modules/main/models/orders-chart';

const convert = (value: string) => {
    const temp = moment(value, DATE_TIME_FORMAT);
    return temp.isValid() ? temp.toDate() : value;
};

const format = (value: Date | string) => value instanceof Date ? formatDate(value) : value;

@HotkeysTarget
export class DateCell extends React.PureComponent<ICellRendererParams, { value: any, active: boolean }> {
    constructor (props: ICellRendererParams) {
        super(props);
        this.state = { value: convert(props.value), active: false };
    }

    componentDidUpdate (prevProps: ICellRendererParams) {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: convert(this.props.value) });
        }
    }

    render () {
        const { value, active } = this.state;

        return (
            <div className={styles.date}>
                {!active && (
                    <div className={styles.value}>{format(value)}</div>
                )}
                {active && (
                    <DatePicker
                        boundary="viewport"
                        value={value}
                        onChange={this.onChange}
                    />
                )}
                {active && (
                    <Icon icon='floppy-disk' className={styles.button} onClick={this.save} iconSize={ICON_BUTTON_SIZE} />
                )}
                {!active && (
                    <Icon icon='edit' className={styles.button} onClick={this.activate} iconSize={ICON_BUTTON_SIZE} />
                )}
            </div>
        );
    }

    renderHotkeys () {
        return (
            <Hotkeys>
                <Hotkey
                    global={true}
                    allowInInput={true}
                    label="cancel edit"
                    combo="esc"
                    onKeyUp={this.cancel}
                />
            </Hotkeys>
        );
    }

    activate = () => this.setState({ active: true });

    cancel = () => this.setState({ active: false, value: convert(this.props.value) });

    save = async () => {
        const { value } = this.state;
        const { context, data } = this.props;
        if (!value) {
            return;
        }

        const success = await (context as DateCellContext).setPaymentDate(getRowNodeId(data), value);
        success && this.setState({ active: false });
    }

    onChange = (value: Date | null) => value && this.setState({ value });
}

export const getRowNodeId = (node: FlowDatasetOrderPaymentSnapshotType) => node.guid;

export interface DateCellContext {
    setPaymentDate: (guid: string, date: Date) => Promise<boolean>;
}
