import React from "react";
import styles from "./Block.module.scss";
import { observer } from "mobx-react";
import { UnitsLinkSnapshotType, WorkResultStoreType } from "modules/orders-manage/models/order-work-result";
import { Caret } from "modules/common/components/collapse/Caret";
import { Collapse } from "@blueprintjs/core";
import { RowFile } from "./RowFile";

export type TUnit = UnitsLinkSnapshotType;

class BlockBase extends React.PureComponent<BlockProps> {
    private ref = React.createRef<HTMLDivElement>();

    render() {
        const { units, oldUnits, store, orderId, baseUrl, readOnly, canDownload } = this.props;
        const unitsCollapser = { ...store.unitsCollapser.plain };

        return (
            <div ref={this.ref} className={`${styles.block} `}>
                {units.length > 0 && (
                    <div className={styles.wrapper}>
                        {units.map((work, index) => {
                            const collapsed = !unitsCollapser[work.code];
                            const oldWork = oldUnits[index];
                            const onToggleCollapse = () => {
                                store.unitsCollapser.toggle(work.code);
                            };
                            const files = work.documents.slice().sort((a, b) => +b.version - +a.version);
                            return (
                                <div key={index}>
                                    <div className={styles.row}>
                                        <div className={styles.rowTaskBlock}>
                                            <h1 className="planr-block-header collapser" onClick={onToggleCollapse}>
                                                <div className={styles.orderNumber}>{work.code}</div>
                                                <div className={styles.description}>{work.description}</div>
                                                <Caret collapsed={collapsed} />
                                            </h1>
                                            <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                                                <div className="collapse" style={{ width: "1466px" }}>
                                                    <div className={styles.rowTask}>
                                                        <div className={styles.rowTaskLeft}>
                                                            <div className={styles.rowTaskHeader}>
                                                                <div
                                                                    className={styles.rowTaskHeaderItem}
                                                                    style={{ width: "40%" }}
                                                                >
                                                                    Результат выполнения
                                                                </div>
                                                                <div
                                                                    className={styles.rowTaskHeaderItem}
                                                                    style={{ width: "60%" }}
                                                                >
                                                                    Замечания заказчика
                                                                </div>
                                                            </div>
                                                            <div className={styles.rowTaskLeftItemHeader}>
                                                                <div className={styles.taskFileMiddle}>
                                                                    Файл результата
                                                                </div>
                                                                <div className={styles.version}>Версия</div>
                                                                {canDownload && (
                                                                    <div className={styles.download}>Архив</div>
                                                                )}
                                                                <div
                                                                    className={styles.commentFile}
                                                                    style={{
                                                                        marginLeft: canDownload ? "56px" : "162px",
                                                                    }}
                                                                >
                                                                    Комментарий
                                                                </div>
                                                                <div className={styles.comment}>Файлы замечаний</div>
                                                            </div>
                                                            <div
                                                                className={styles.rowTaskLeftItem}
                                                                style={{ display: "flex", flexDirection: "column" }}
                                                            >
                                                                {files.map((file, i) => {
                                                                    const oldFile = oldWork.documents
                                                                        .slice()
                                                                        .sort((a, b) => +b.version - +a.version)[i];
                                                                    return (
                                                                        <RowFile
                                                                            key={i}
                                                                            downloadFile={(item: string) =>
                                                                                this.downLoadFile(orderId, item)
                                                                            }
                                                                            baseUrl={baseUrl}
                                                                            canDownload={canDownload}
                                                                            file={file}
                                                                            oldFile={oldFile}
                                                                            readOnly={readOnly}
                                                                            actual={i === 0 ? true : false}
                                                                            store={store}
                                                                            guid={work.guid}
                                                                            changeComment={(comment) => {
                                                                                store.setComment(
                                                                                    work.guid,
                                                                                    comment,
                                                                                    file.version
                                                                                );
                                                                            }}
                                                                            saveComment={async () => {
                                                                                await store.saveComment(
                                                                                    work.guid,
                                                                                    file.comment,
                                                                                    +file.version,
                                                                                    orderId
                                                                                );
                                                                            }}
                                                                            saveFileComment={async (
                                                                                commentFile: any,
                                                                                deleted: boolean
                                                                            ) => {
                                                                                await store.saveFileComment(
                                                                                    orderId,
                                                                                    work.guid,
                                                                                    file.version,
                                                                                    commentFile.fileId,
                                                                                    commentFile.fileName,
                                                                                    deleted
                                                                                );
                                                                            }}
                                                                            uploadFile={async (file: File) => {
                                                                                const data = await store.uploadFile(
                                                                                    file
                                                                                );
                                                                                return data;
                                                                            }}
                                                                            downloadVersion={this.downloadVersion}
                                                                        />
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }

    downloadVersion = async (contentGuid: string, version: number) => {
        await this.props.store.downloadVersion(this.props.orderId, contentGuid, version);
    };

    downLoadFile = async (orderId: string, fileId: string) => {
        const result = await this.props.store.downloadFile(orderId, fileId);
        if (result) {
            return result;
        }
    };
}

export const Block = observer(BlockBase);
export type ActivationDelegate = (unit: TUnit) => void;

interface BlockProps {
    canDownload: boolean;
    category: string;
    units: TUnit[];
    oldUnits: TUnit[];
    store: WorkResultStoreType;
    orderId: string;
    baseUrl: string;
    readOnly: boolean;
}
