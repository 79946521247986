export const DATE_FORMAT = "DD.MM.YYYY";

export const DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm:ss";

export const SORTABLE_DATE_FORMAT = "YYYY.MM.DD";

export const SORTABLE_DATE_TIME_FORMAT = "YYYY.MM.DD HH:mm:ss";

export const EMPTY_OBJECT_ID = "000000000000000000000000";

export const EMPTY_INN = "000000000000";

export const DEFAULT_SORTING_ASCENDING_VALUE = true;

export const HTML_COLOR_REGEXP = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

export const WHITE = "#FFFFFF";
export const TRANSPARENT = "transparent";

export const GREY_1 = "#5c7080";
export const GREY_2 = "#738694";
export const GREY_3 = "#8a9ba8";
export const GREY_4 = "#a7b6c2";
export const GREY_5 = "#bfccd6";

export const LIGHT_GREY_1 = "#ced9e0";
export const LIGHT_GREY_2 = "#d8e1e8";
export const LIGHT_GREY_3 = "#e1e8ed";
export const LIGHT_GREY_4 = "#ebf1f5";
export const LIGHT_GREY_5 = "#f5f8fa";

export const STANDARD_GREY = "#A4B1BC";

export const ONLY_DIGITS_REGEXP = /^\d+$/;

export const ROOT = "ROOT";

export const DAY_HOURS = 24;

export const ICON_BUTTON_SIZE = 12;

export const LARGE_ICON_AS_BUTTON_SIZE = 22;

export const MAX_INT = 2147483647;

export const ALL_MAILS = "Все письма";

export const ALL_STATUSES = "Все записи";

export const ALL_PROJECTS = "Все проекты";
