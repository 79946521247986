export default {
    WORKLOAD_EDIT: "WORKLOAD_EDIT",
    WORKOUT_UNIT: "WORKOUT_UNIT",
    TIMESHEET_EDIT: "TIMESHEET_EDIT",
    TIMESHEET_EDIT_DEPARTMENT: "TIMESHEET_EDIT_DEPARTMENT",
    TIMESHEET_EDIT_USER: "TIMESHEET_EDIT_USER",

    TIMESHEET_EXPORT: "TIMESHEET_EXPORT",
    TIMESHEET_COMMENTS: "TIMESHEET_COMMENTS",

    SCHEDULE_EDIT: "SCHEDULE_EDIT",
    SCHEDULE_READ: "SCHEDULE_READ",
    SCHEDULE_EXPORT: "SCHEDULE_EXPORT",

    DEPARTMENT_READ: "DICTIONARY_DEPARTMENT_READ",
    DEPARTMENT_EDIT: "DICTIONARY_DEPARTMENT_EDIT",

    EMPLOYER_READ: "DICTIONARY_EMPLOYER_READ",
    EMPLOYER_EDIT: "DICTIONARY_EMPLOYER_EDIT",

    REPORTS_READ: "REPORTS_READ",
};
