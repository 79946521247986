import { saveAs } from "file-saver";
import { groupBy, sortBy } from "lodash";
import { applySnapshot, getParent, getSnapshot, types } from "mobx-state-tree";
import { ClientDictionary, initialState as emptyClients } from "modules/agents/clients/models/client-dictionary";
import {
  OutsourcerDictionary,
  initialState as emptyAgents,
} from "modules/agents/outsourcers/models/outsourcer-dictionary";
import { OutsourcerPaymentType } from "modules/agents/outsourcers/models/outsourcer-payment";
import { SuppliersStore, initialState as suppliersInite } from "modules/agents/suppliers/models/suppliers-store";
import { DEFAULT_SORTING_ASCENDING_VALUE, EMPTY_OBJECT_ID } from "modules/common/constants";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { PrintAnchorInfoList, initialState as emptyAnchors } from "modules/common/models/print-anchor";
import { Queryable } from "modules/common/models/queryable";
import { SectionCollapser } from "modules/common/models/section-collapser";
import { TableSorter } from "modules/common/models/table-sorter";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { base64ToBlob, printPdf } from "modules/common/services/files";
import { formatDate } from "modules/common/services/formatting/date";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { texts } from "modules/common/texts";
import {
  IpdTypeDictionary,
  initialState as emptyIpds,
} from "modules/dictionaries/ipd-types/models/ipd-type-dictionary";
import {
  OrderStatusDictionary,
  initialState as emptyStatuses,
} from "modules/dictionaries/order-statuses/models/order-status-dictionary";
import {
  OrderTypeDictionary,
  initialState as emptyTypes,
} from "modules/dictionaries/order-types/models/order-type-dictionary";
import {
  ProductionStageDictionary,
  initialState as emptyStages,
} from "modules/dictionaries/production-stages/models/production-stage-dictionary";
import {
  ProjectPortfolioDictionary,
  initialState as emptyPortfolios,
} from "modules/dictionaries/project-portfolios/models/project-portfolio-dictionary";
import {
  WorkTypeDictionary,
  WorkTypeDictionaryItemSnapshotType,
  initialState as emptyWorkTypes,
} from "modules/dictionaries/work-types/models/work-type-dictionary";
import { ExternalMailNumber, OrderMailsStore, initialState as emptyMails } from "modules/order-mails/models/store";
import func from "modules/orders-manage/functionalities";
import { menuItems } from "modules/root/components/menu/MainMenu";
import { Constants } from "modules/root/models/constants";
import { can } from "modules/session/auth/access";
import {
  EmployerDictionary,
  initialState as emptyEmployee,
} from "modules/spending/employee/models/employee-dictionary";
import { OTHER_WORKS } from "../details/constants";
import {
  OTHER_BLOCK_NAME,
  OUTSORCE_BLOCK_NAME,
  SpendingBlockSorting,
  outsourcedSpendingsPrintModel,
} from "../details/spending/outsourced/print-view-model";
import {
  OWN_BLOCK_NAME,
  OwnSpendingBlockSorting,
  ownSpendingsActualPrintModel,
  ownSpendingsPlanPrintModel,
} from "../details/spending/own/print-view-model";
import { PREMIUM_BLOCK_NAME, premiumsPlanPrintModel } from "../details/spending/premium/print-view-model";
import {
  OrderSummary,
  OrderSummarySnapshotType,
  OrderSummaryType,
  WorkTypeLinkSnapshotType,
  initialState as emptyOrder,
  emptyWorkTypeLink,
  fields,
  filterObjectUnits,
  getCategoryProgress,
  getContentUnits,
  getObjectsToDisplay,
  getOrderPrice,
  onlyPremiumsInPatch,
  onlyTasksInPatch,
  sortOrderObjects,
} from "./order";
import { OrderContentTasksDictionary, initialState as emptyContentTasks } from "./order-content-tasks";
import { OrderDictionary, initialState as emptyOrderDictionary } from "./order-dictionary";
import { OrderFinancesStore } from "./order-finances-store";
import { OrderList, initialState as emptyList } from "./order-list";
import { OrderLogsStore } from "./order-logs";
import { OrderMailSnapshotType, searchString } from "./order-mails";
import { OrderStageType, initialState as emptyOrderStages } from "./order-stages-store";
import { OrderTechTasks, initialState as emptyTechTasks } from "./order-tech-tasks";
import { OrderPremiumDetails, OrderPremiumDetailsSnapshotType, emptyPremiumDetails } from "./premium";
import { RenderCache } from "./render-cache";

import { OrderPostStore, OrderPostType, initialPostStoreState } from "modules/agents/outsourcers/models/outsourcer";
import {
  AgentsCategoriesDictionary,
  initialState as categoriesInite,
} from "modules/dictionaries/agents-categories/models/agents-categories-dictionary";
import {
  ExternalConfirmNumber,
  OrderConfirmStore,
  initialState as emptyConfirms,
} from "modules/order-confirms/models/store";

const SpendingCollapser = types
  .model({
    own: types.boolean,
    outsource: types.boolean,
    other: types.boolean,
    premium: types.boolean,
    trip: types.boolean,
  })
  .actions((self) => ({
    setAll(collapsed: boolean) {
      self.own = collapsed;
      self.outsource = collapsed;
      self.other = collapsed;
      self.trip = collapsed;
      self.premium = collapsed;
    },
  }))
  .named("SpendingCollapse");

export const OrdersStore = types
  .compose(
    Transport,
    Notificator,
    Queryable,
    types.model({
      list: OrderList,
      orderDictionary: OrderDictionary,
      ipdTypeDictionary: IpdTypeDictionary,
      details: OrderSummary,
      finances: OrderFinancesStore,
      logs: OrderLogsStore,
      techTasks: OrderTechTasks,
      premiums: OrderPremiumDetails,
      mails: OrderMailsStore,
      orderConfirms: OrderConfirmStore,
      portfolios: ProjectPortfolioDictionary,
      statuses: OrderStatusDictionary,
      clients: ClientDictionary,
      orderTypes: OrderTypeDictionary,
      productionStages: ProductionStageDictionary,
      engineers: EmployerDictionary,
      employee: EmployerDictionary,
      workTypes: WorkTypeDictionary,
      agents: OutsourcerDictionary,
      orderAgents: SuppliersStore,
      spendingCollapse: SpendingCollapser,
      outsourceCollapser: SectionCollapser,
      otherCollapser: SectionCollapser,
      ownPlanCollapser: SectionCollapser,
      ownActualCollapser: SectionCollapser,
      rowTripCollapser: SectionCollapser,
      financeCollapser: SectionCollapser,
      contentCollapser: SectionCollapser,
      objectsCollapser: SectionCollapser,
      mailSorter: TableSorter,
      taskAnchors: PrintAnchorInfoList,
      loading: types.boolean,
      contentTasks: OrderContentTasksDictionary,
      sendEmailsList: OrderPostStore,
      filtersType: types.string,
      filtersStatus: types.string,
      agentsCategories: AgentsCategoriesDictionary,
    })
  )
  .actions((self) => ({
    loadTaskVersions() {
      self.techTasks.setFileId(self.details.id);
      return self.techTasks.loadVersions();
    },

    refreshOrderPrice(stages: OrderStageType[]) {
      self.details.setPrice(getOrderPrice(stages));
    },
    loadSendEmails: flow(function* (orderId?: string) {
      try {
        const data: OrderPostType[] = yield self.transport.post<OrderPostType[]>(
          apiUrls.outsourcers.getRequestEmailSend(),
          { orderId }
        );

        applySnapshot(self.sendEmailsList, data);
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
  }))
  .actions((self) => ({
    updateOrder: (order: OrderSummaryType) => {
      const target = self.list.asMap[order.id];
      if (target) {
        target.fullInventoryNumber = order.fullInventoryNumber;
        target.name = order.name;
      } else {
        const status = self.statuses.asMap[order.orderStatusId];
        self.list.orders.addOrder(getSnapshot(order), status ? status.color : "");
      }
    },

    load() {
      self.taskAnchors.load();
      self.engineers.load({
        departmentId: Constants.gipDepartmentId,
        withSalary: null,
        withFired: false,
        sortByHierarchy: false,
      });
      self.employee.load({
        departmentId: "",
        withSalary: formatDate(new Date()),
        withFired: true,
        sortByHierarchy: false,
      });
      self.orderDictionary.load();
      self.ipdTypeDictionary.load();
      self.portfolios.load();
      self.statuses.load();
      self.clients.load();
      self.orderTypes.load();
      self.productionStages.load();
      self.workTypes.load();
      self.agents.load();
      self.orderAgents.loadNoRulesAgents();
      self.agentsCategories.load();
    },

    resetPremiums(premiums: OrderPremiumDetailsSnapshotType | null) {
      if (premiums) {
        applySnapshot(self.premiums, premiums);
      }
    },

    loadDetails: flow(function* (id: string, loadMails = true, canConfirmRead = true) {
      try {
        self.loading = true;
        if (loadMails) {
          yield self.mails.setOrderId(id);
        }

        if (canConfirmRead) {
          yield self.orderConfirms.setOrderId(id);
        }

        const rawData: any = yield self.details.load(id);

        const snapshot: OrderSummarySnapshotType | null = rawData;

        if (snapshot) {
          const categories = Array.from(Constants.workTypeCategory.values());
          categories.forEach((key) => self.contentCollapser.set(key, false));

          self.details.objects.forEach((object) => {
            object.content.forEach((key) => self.objectsCollapser.set(key.guid, false));
          });

          applySnapshot(self.premiums, rawData.premiumDetails);

          const access: string[] = getParent(self).session.access;
          if (can(func.ORDERS_TECHNICAL_TASK_READ, access)) {
            yield self.loadTaskVersions();
          }
          if (can(func.ORDERS_CONTENT_TASK_READ, access) && id !== EMPTY_OBJECT_ID) {
            yield self.contentTasks.load(id);
          }

          return { snapshot, premiums: rawData.premiumDetails };
        }

        return { snapshot: null, premiums: null };
      } finally {
        self.loading = false;
      }
    }),

    toggleCollapse(type: string) {
      switch (type) {
        case "own": {
          self.spendingCollapse.own = !self.spendingCollapse.own;
          break;
        }
        case "outsource": {
          self.spendingCollapse.outsource = !self.spendingCollapse.outsource;
          break;
        }
        case "other": {
          self.spendingCollapse.other = !self.spendingCollapse.other;
          break;
        }

        case "trip": {
          self.spendingCollapse.trip = !self.spendingCollapse.trip;
          break;
        }
        case "premium": {
          self.spendingCollapse.premium = !self.spendingCollapse.premium;
          break;
        }
      }
    },

    setupCollapseState(expanded: string) {
      if (expanded === "own") {
        self.spendingCollapse.own = false;
      } else {
        self.spendingCollapse.own = true;
      }

      if (expanded === "trip") {
        self.spendingCollapse.trip = false;
      } else {
        self.spendingCollapse.trip = true;
      }

      if (expanded === "outsource") {
        self.spendingCollapse.outsource = false;
      } else {
        self.spendingCollapse.outsource = true;
      }

      if (expanded === "other") {
        self.spendingCollapse.other = false;
      } else {
        self.spendingCollapse.other = true;
      }

      if (expanded === "premium") {
        self.spendingCollapse.premium = false;
      } else {
        self.spendingCollapse.premium = true;
      }
    },

    togglePaymentStatus: flow(function* (id: string) {
      if (!self.details) {
        return false;
      }

      try {
        let payment: OutsourcerPaymentType | undefined;
        self.details.outsourcedOrderSpendins
          .concat(self.details.otherOrderSpendings)
          .concat(self.details.tripOrderSpendings)
          .find((sp) => {
            const found = sp.planPayments.find((p) => p.guid === id);
            if (found) {
              payment = found;
            }
            return !!found;
          });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars

        if (payment) {
          const status: { status: string } = yield self.transport.post<any>(apiUrls.financeRequests.toggle(id), {
            currentStatus: [payment.status],
          });
          payment.status = status.status;
          return true;
        }

        return false;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),

    printOutsourcerOrder: flow(function* (outsourcerId: string, contentGuid: string, comment: string = "") {
      if (self.details.isNewlyCreated) {
        return;
      }

      try {
        const file: FileDescription = yield self.transport.post<any>(
          apiUrls.orders.print.outsourcerOrder(self.details.id),
          {
            outsourcerId,
            objectContentGuid: contentGuid,
            comment,
          }
        );

        const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
        saveAs(blob, file.name);
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
    printStages: flow(function* () {
      if (self.details.isNewlyCreated || self.finances.stages.stages.length < 1) {
        return;
      }

      try {
        const file: FileDescription = yield self.transport.get<any>(apiUrls.orders.print.printStages(self.details.id));

        const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
        saveAs(blob, file.name);
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
  }))
  .actions((self) => ({
    emptyContent: flow(function* (orderNumber: string, sortOrder: number) {
      const result: WorkTypeLinkSnapshotType = yield self.details.emptyContent(orderNumber, sortOrder);

      self.outsourceCollapser.set(result.guid, false);
      self.ownPlanCollapser.set(result.guid, false);

      return result;
    }),

    emptyContentFromWorkType: flow(function* (
      orderNumber: string,
      objectNumber: number,
      objectName: string,
      type: WorkTypeDictionaryItemSnapshotType,
      sortOrder: number
    ) {
      const result: WorkTypeLinkSnapshotType = yield self.details.emptyContentFromWorkType(
        orderNumber,
        objectNumber,
        objectName,
        type,
        sortOrder
      );

      self.outsourceCollapser.set(result.guid, false);
      self.ownPlanCollapser.set(result.guid, false);

      return result;
    }),
  }))
  .actions((self) => ({
    printIpdOrder: flow(function* (guid: string) {
      if (self.details.isNewlyCreated) {
        return;
      }
      try {
        if (guid) {
          const file: any = yield self.transport.post<any>(apiUrls.orders.print.ipdOrder(self.details.id), {
            guid,
          });

          const blob: any = yield base64ToBlob(file.Content || "", file.MimeType);
          saveAs(blob, file.Name);
          return true;
        } else {
          self.notify.error("Необходимо указать раздел!");
          return false;
        }
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
    printCommunication: flow(function* (guid: string) {
      if (self.details.isNewlyCreated) {
        return;
      }
      try {
        const file: FileDescription = yield self.transport.post<any>(
          apiUrls.orders.print.communication(self.details.id),
          { guid }
        );

        const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
        saveAs(blob, file.name);
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),

    printContentDocument: flow(function* (guids: string[]) {
      if (self.details.isNewlyCreated) {
        return;
      }
      try {
        if (guids.length > 0) {
          const file: FileDescription = yield self.transport.post<any>(
            apiUrls.orders.print.contentOrder(self.details.id),
            {
              guids,
            }
          );

          const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
          saveAs(blob, file.name);
          return true;
        } else {
          self.notify.error("Необходимо указать разделы!");
          return false;
        }
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
    printContentInvoice: flow(function* (guids: string[]) {
      if (self.details.isNewlyCreated) {
        return;
      }
      try {
        if (guids.length > 0) {
          const file: FileDescription = yield self.transport.post<any>(
            apiUrls.orders.print.contentInvoice(self.details.id),
            {
              guids,
            }
          );

          const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
          saveAs(blob, file.name);
          return true;
        } else {
          self.notify.error("Необходимо указать разделы!");
          return false;
        }
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
    printContentType: flow(function* () {
      try {
        const objects = sortOrderObjects(self.details.objects);

        // prepare data in template format
        const categories = Array.from(Constants.workTypeCategory.values());

        const blocks = categories.map((category) => {
          const objectsToDisplay = getObjectsToDisplay(category, objects);

          const units: any[] = [];

          objectsToDisplay.forEach((object) => {
            filterObjectUnits(object, category)
              .map((unit) => ({
                ...unit,
                progress: unit.progress.toFixed(0),
                objectName: object.name || "",
              }))
              .forEach((unit) => units.push(unit));
          });

          return {
            name: category || OTHER_WORKS,
            progress: getCategoryProgress(category, objectsToDisplay).toFixed(0),
            units,
          };
        });

        const file: FileDescription = yield self.transport.post<any>(apiUrls.application.print, {
          variables: JSON.stringify({ blocks }),
          templateId: "ExportOrderContentTab",
        });

        const blob: any = yield base64ToBlob(file.content || "", file.mimeType);

        const fileURL = URL.createObjectURL(blob);
        const printer = printPdf(fileURL, true);
        if (printer) {
          printer.onclose = () => URL.revokeObjectURL(fileURL);
        }

        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
    printSpendingsTab: flow(function* (form: any) {
      try {
        const menu = menuItems();
        const units = getContentUnits(form, true);
        const otherOrderSpendings = sortBy(form.otherOrderSpendings, SpendingBlockSorting);
        const model = {
          title: menu.orders.nested.spending.label,
          parts: [
            {
              variables: JSON.stringify(
                outsourcedSpendingsPrintModel(
                  OUTSORCE_BLOCK_NAME,
                  sortBy(form.outsourcedOrderSpendins, SpendingBlockSorting),
                  units,
                  (s, u) => s.contentGuid === u.guid,
                  self.agents.asMap
                )
              ),
              templateId: "ExportOutsourcedSpendings",
            },
            {
              variables: JSON.stringify(
                outsourcedSpendingsPrintModel(
                  OTHER_BLOCK_NAME,
                  otherOrderSpendings,
                  Object.keys(groupBy(otherOrderSpendings, (s) => s.comment)).map((blockName) => ({
                    ...emptyWorkTypeLink(""),
                    name: blockName,
                  })),
                  (s, u) => s.comment === u.name,
                  self.agents.asMap
                )
              ),
              templateId: "ExportOutsourcedSpendings",
            },
            {
              variables: JSON.stringify(
                ownSpendingsPlanPrintModel(
                  `${OWN_BLOCK_NAME}. План.`,
                  sortBy((form as any)[fields.ownOrderSpendings], OwnSpendingBlockSorting),
                  getContentUnits(form, false),
                  self.employee.asMap
                )
              ),
              templateId: "ExportOwnSpendings",
            },
            {
              variables: JSON.stringify(
                ownSpendingsActualPrintModel(`${OWN_BLOCK_NAME}. Факт.`, self.details.timesheetSpendings)
              ),
              templateId: "ExportOwnSpendings",
            },
            {
              variables: JSON.stringify(premiumsPlanPrintModel(PREMIUM_BLOCK_NAME, self.premiums)),
              templateId: "ExportPremiums",
            },
          ],
        };

        const file: FileDescription = yield self.transport.post<any>(apiUrls.application.batchPrint2, model);

        const blob: any = yield base64ToBlob(file.content || "", file.mimeType);

        const fileURL = URL.createObjectURL(blob);
        const printer = printPdf(fileURL, true);
        if (printer) {
          printer.onclose = () => URL.revokeObjectURL(fileURL);
        }

        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
  }))
  .actions((self) => ({
    printMails: flow(function* (files: string[]) {
      if (files.length) {
        try {
          const content: DownloadFileResult = yield self.transport.post<any>(apiUrls.application.files.print, {
            ids: files,
          });

          if (content) {
            const blob: any = yield base64ToBlob(content.content, content.mimeType);
            const fileURL = URL.createObjectURL(blob);
            const printer = printPdf(fileURL, true);
            if (printer) {
              printer.onclose = () => URL.revokeObjectURL(fileURL);
            }

            return true;
          }
        } catch (er) {
          self.notify.error(er);
          return false;
        }
      }

      return false;
    }),
    downloadMails: flow(function* (files: string[]) {
      if (files.length) {
        try {
          const content: DownloadFileResult = yield self.transport.post<any>(apiUrls.application.files.download, {
            ids: files,
          });

          if (content) {
            const blob: any = yield base64ToBlob(content.content, content.mimeType);
            saveAs(blob, content.name);
            return true;
          }
        } catch (er) {
          self.notify.error(er);
          return false;
        }
      }

      return false;
    }),

    setFiltersType(type: string) {
      if (self.filtersType !== type) {
        self.filtersType = type;
      }
    },

    toggleFiltersStatus(status: string) {
      if (!status) {
        self.filtersStatus = "";
      } else {
        self.filtersStatus = self.filtersStatus === status ? "" : status;
      }
    },

    filterMails(value: OrderMailSnapshotType[]) {
      const predicate = (mail: OrderMailSnapshotType): boolean => {
        if (self.filtersType && mail.type !== self.filtersType) {
          return false;
        }

        if (self.filtersStatus === Constants.expiredOrderMailStatus && !mail.expired) {
          return false;
        }

        if (self.filtersStatus === Constants.completedOrderMailStatus && !mail.completed) {
          return false;
        }

        if (self.filtersStatus === Constants.acceptedOrderMailStatus && !mail.accepted) {
          return false;
        }

        if (self.pureQuery && !searchString(mail).includes(self.pureQuery)) {
          return false;
        }

        return true;
      };

      return value.filter(predicate);
    },

    printFile: flow(function* (id: string) {
      try {
        const content: DownloadFileResult = yield self.transport.post<any>(apiUrls.application.files.print, {
          ids: [id],
        });

        if (content) {
          const blob: any = yield base64ToBlob(content.content, content.mimeType);
          const fileURL = URL.createObjectURL(blob);
          const printer = printPdf(fileURL, true);
          if (printer) {
            printer.onclose = () => URL.revokeObjectURL(fileURL);
          }

          return true;
        }

        return false;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),

    downloadFile: flow(function* (id: string) {
      try {
        const content: DownloadFileResult = yield self.transport.post<any>(apiUrls.application.files.download, {
          ids: [id],
        });

        const blob: any = yield base64ToBlob(content.content || "", content.mimeType);
        saveAs(blob, content.name);

        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
  }))
  .actions((self) => ({
    saveOrderDetails: flow(function* (patch: TStringMap<any>, refresh = false) {
      try {
        // 2 commands - save order and save premiums
        let premiums: any = null;
        let order: any = null;
        let contentTasks: any = null;
        if (fields.premiums in patch) {
          premiums = self.premiums.buildSaveCommand();
        }

        if (fields.contentTasks in patch) {
          contentTasks = self.contentTasks;
        }
        if (!onlyPremiumsInPatch(patch)) {
          if (!onlyTasksInPatch(patch)) {
            order = self.details.preparePath(patch);
          }
        }
        const command = { order, premiums };
        let snapshot: any = null;
        if (command.order || command.premiums) {
          snapshot = self.details.isNewlyCreated
            ? yield self.transport.put<any>(apiUrls.orders.summary.create(), command)
            : yield self.transport.post<any>(apiUrls.orders.summary.update(self.details.id), command);
        }

        if (contentTasks) {
          yield contentTasks.updateData(self.details.id);
        }

        if (refresh && snapshot) {
          self.details.refresh(snapshot);
        }
        self.notify.success(texts.messages.saved);
        return snapshot;
      } catch (er) {
        self.notify.error(er);

        return false;
      }
    }),
    saveOrderIpds: flow(function* (patch: TStringMap<any>, refresh = false) {
      if (self.details.isNewlyCreated) {
        self.notify.error("Для начала заполните основные данные договора");
        return;
      }
      try {
        // 2 commands - save order and save premiums

        let snapshot: any = null;

        snapshot = yield self.transport.post<any>(apiUrls.orders.ipds.update(self.details.id), patch);

        if (refresh && snapshot) {
          self.details.refreshIpds(snapshot);
        }
        self.notify.success(texts.messages.saved);
        return snapshot;
      } catch (er) {
        self.notify.error(er);

        return false;
      }
    }),
    saveOrderTechIndicators: flow(function* (patch: TStringMap<any>, refresh = false) {
      if (self.details.isNewlyCreated) {
        self.notify.error("Для начала заполните основные данные договора");
        return;
      }
      if (patch.orderTechIndicators.find((p: any) => p.Name === "" || p.orderIndicatorId === "")) {
        self.notify.error("Необходимо заполнить все поля!");
        return;
      }
      try {
        // 2 commands - save order and save premiums

        let snapshot: any = null;

        snapshot = yield self.transport.post<any>(apiUrls.orders.techIndicators.update(self.details.id), patch);

        if (refresh && snapshot) {
          self.details.refreshTechIndicators(snapshot);
        }
        self.notify.success(texts.messages.saved);
        return snapshot;
      } catch (er) {
        self.notify.error(er);

        return false;
      }
    }),
    saveOrderAgents: flow(function* (patch: TStringMap<any>, refresh = false) {
      if (self.details.isNewlyCreated) {
        self.notify.error("Для начала заполните основные данные договора");
        return;
      }

      try {
        // 2 commands - save order and save premiums
        let snapshot: any = null;
        snapshot = yield self.transport.post<any>(apiUrls.orders.orderAgents.saveOrderAgent(self.details.id), patch);

        if (refresh && snapshot) {
          self.details.refreshOrderAgents(snapshot);
        }
        self.notify.success(texts.messages.saved);
        return snapshot;
      } catch (er) {
        self.notify.error(er);

        return false;
      }
    }),

    saveOrderCommunication: flow(function* (patch: TStringMap<any>, refresh = false) {
      if (self.details.isNewlyCreated) {
        self.notify.error("Для начала заполните основные данные договора");
        return;
      }
      try {
        // 2 commands - save order and save premiums
        let snapshot: any = null;
        snapshot = yield self.transport.post<any>(apiUrls.orders.orderCommunication.update(self.details.id), patch);

        // if (refresh && snapshot) {
        //     self.details.refreshOrderCommunication(snapshot);
        // }
        self.notify.success(texts.messages.saved);
        return snapshot;
      } catch (er) {
        self.notify.error(er);

        return false;
      }
    }),
  }))

  .named("OrderTypesStore");

export const ORDER_MAILS_TABLE_NAME = OrdersStore.name + "_mails";

export const initialState = (meta: AppConfig): typeof OrdersStore.SnapshotType => {
  const sorter = getSortOption(ORDER_MAILS_TABLE_NAME)({
    column: "Date",
    asc: DEFAULT_SORTING_ASCENDING_VALUE,
  });

  return {
    list: emptyList(meta),
    orderDictionary: emptyOrderDictionary(),
    ipdTypeDictionary: emptyIpds(),
    details: emptyOrder(true),
    finances: {
      stages: emptyOrderStages(),
      timeline: [],
      orderId: "",
    },
    logs: { data: [], loading: false, hasMore: true },
    techTasks: emptyTechTasks(),
    premiums: emptyPremiumDetails(),
    mails: emptyMails(ExternalMailNumber()),
    orderConfirms: emptyConfirms(ExternalConfirmNumber()),
    portfolios: emptyPortfolios(),
    statuses: emptyStatuses(),
    clients: emptyClients(),
    orderTypes: emptyTypes(),
    productionStages: emptyStages(),
    engineers: emptyEmployee(),
    employee: emptyEmployee(),
    workTypes: emptyWorkTypes(),
    contentTasks: emptyContentTasks(),
    orderAgents: suppliersInite(),
    agents: emptyAgents(),
    spendingCollapse: {
      other: true,
      outsource: true,
      own: true,
      trip: true,
      premium: true,
    },
    outsourceCollapser: { opened: {} },
    otherCollapser: { opened: {} },
    ownActualCollapser: { opened: {} },
    ownPlanCollapser: { opened: {} },
    rowTripCollapser: { opened: {} },
    financeCollapser: {
      opened: {
        "Оплаты фактические": false,
        "Акты выполненных работ": false,
        "Оплаты плановые": false,
        Сметы: false,
      },
    },
    contentCollapser: { opened: {} },
    objectsCollapser: { opened: {} },
    mailSorter: {
      id: ORDER_MAILS_TABLE_NAME,
      tableName: ORDER_MAILS_TABLE_NAME,
      column: sorter.column,
      asc: sorter.asc,
    },
    taskAnchors: emptyAnchors(apiUrls.orders.summary.tasks.anchors),
    loading: false,
    pureQuery: "",
    query: "",
    filtersStatus: "",
    filtersType: "",
    agentsCategories: categoriesInite(),
    sendEmailsList: initialPostStoreState(),
  };
};

export type OrdersStoreType = typeof OrdersStore.Type;
export type RenderCacheType = typeof RenderCache.Type;
export type SectionCollapserType = typeof SectionCollapser.Type;
