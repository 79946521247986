import React from "react";
import { observer } from "mobx-react";
import styles from "./EmployerList.module.scss";
import { columns } from "./columns";
import { saveAgGridColumnSize } from "modules/common/services/table/size-storage";
import { DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import { EmployeeListType, EmployeeList } from "../../models/employer-list";
import {
    RowClickedEvent,
    GetContextMenuItemsParams,
    GridReadyEvent,
    CellContextMenuEvent,
    GridOptions,
    RowNode,
} from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { agGridLocale } from "modules/root/services/locale";
import { EmployerSnapshotType, fired, formatEmployer } from "../../models/employer";
import { agGridEntityContextMenu } from "modules/common/components/table/EntityContextMenu";
import copyToClipboard from "copy-to-clipboard";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { MAX_INT } from "modules/common/constants";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";

const modules = [...defaultModules, MenuModule];
const TABLE_NAME = EmployeeList.name;
const sizeSaver = saveAgGridColumnSize(TABLE_NAME);
const config = columns(TABLE_NAME);

class Internal extends React.Component<EmployeeListProps> {
    private options: GridOptions;
    private confirmRemoving?: (e: EmployerSnapshotType) => void;

    constructor(props: any) {
        super(props);

        this.options = {
            ...DefaultAgGridOptions(true),
            getRowClass: (params) => (fired(params.data) ? "removed" : ""),
            postSort: this.postSort,
        };
    }

    render() {
        const { store, hidden } = this.props;

        return (
            <RemoveConfirmation<EmployerSnapshotType>
                observation={store.data}
                what={(e) => `сотрудника ${e.name}`}
                onConfirmed={this.onRemove}
                render={({ confirmRemoving }) => {
                    this.confirmRemoving = confirmRemoving;

                    return (
                        <GridTheme bordered={true} className={`designed ${styles.grid} ${hidden ? styles.hidden : ""}`}>
                            <AgGridReact
                                rowStyle={{
                                    borderBottom: "1 px solid #EDF0F2",
                                    fontFamily: "GothamPro, Arial, sans-serif",
                                    color: "#00273D",

                                    fontSize: "12px",
                                }}
                                rowClassRules={this.rowClassRules}
                                rowSelection="single"
                                suppressCellSelection={true}
                                enableCellTextSelection={true}
                                columnDefs={config}
                                rowData={store.data}
                                localeText={agGridLocale}
                                onRowDoubleClicked={this.onRowSelected}
                                getRowNodeId={getRowNodeId}
                                getContextMenuItems={this.getContextMenuItems}
                                onGridReady={this.onGridReady}
                                onCellContextMenu={this.onCellContextMenu}
                                onSortChanged={store.sorter.resorted}
                                onColumnResized={sizeSaver}
                                gridOptions={this.options}
                                modules={modules}
                                rowBuffer={MAX_INT}
                                immutableData={true}
                            />
                        </GridTheme>
                    );
                }}
            />
        );
    }

    private rowClassRules = {
        fired: (params: any) => {
            const data: EmployerSnapshotType = params.data;
            return !!data.fireDate;
        },
    };

    getContextMenuItems = ({ node }: GetContextMenuItemsParams) => {
        const { canChange, onEmployerSelected } = this.props;

        return agGridEntityContextMenu({
            onChange: () => onEmployerSelected(getRowNodeId(node.data)),
            onCopy: canChange ? () => copyToClipboard(formatEmployer(node.data), { format: "text/plain" }) : undefined,
            onRemove: canChange ? () => this.confirmRemoving && this.confirmRemoving(node.data) : undefined,
        });
    };

    onCellContextMenu = ({ node }: CellContextMenuEvent) => {
        node && node.setSelected(true, true);
    };

    onRowSelected = ({ data }: RowClickedEvent) => {
        const { onEmployerSelected } = this.props;
        onEmployerSelected(getRowNodeId(data));
    };

    onGridReady = async ({ api }: GridReadyEvent) => {
        const { sorter } = this.props.store;
        setTimeout(() => api.setSortModel(sorter.agGridSortModel));
    };

    postSort = (rowNodes: RowNode[]) => {
        // передвигаем уволенных сотрудников в конец

        function notFired({ data }: RowNode) {
            return !fired(data);
        }

        function move(toIndex: number, fromIndex: number) {
            rowNodes.splice(toIndex, 0, rowNodes.splice(fromIndex, 1)[0]);
        }

        let nextInsertPos = 0;

        for (let i = 0; i < rowNodes.length; i++) {
            if (notFired(rowNodes[i])) {
                move(nextInsertPos, i);
                nextInsertPos++;
            }
        }
    };

    onRemove = (e: EmployerSnapshotType) => this.props.onEmployerRemove(e.id);
}

export const EmployerList = observer(Internal);

const getRowNodeId = (e: EmployerSnapshotType) => e.id;

interface EmployeeListProps {
    onEmployerSelected: (id: string) => void;
    onEmployerRemove: (id: string) => void;
    store: EmployeeListType;
    hidden?: boolean;
    canChange: boolean;
}
