import React from "react";
import { observer } from "mobx-react";
import { columnsBuilder, TableContext } from "./columns";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { AgGridReact } from "@ag-grid-community/react";
import {
    FIGMA_ROW_HEIGHT,
    FIGMA_HEADER_HEIGHT,
    defaultModules,
    DefaultAgGridOptions,
} from "modules/common/services/table/helpers";
import { agGridLocale } from "modules/root/services/locale";
import { GridReadyEvent, RowClickedEvent, GetContextMenuItemsParams } from "@ag-grid-community/core";
import styles from "./OverheadSpendings.module.scss";
import { OverheadSpendingsType, fields, OverheadSpendingsByYearSnapshotType } from "../models/overhead-spendings";
import { MAX_INT, EMPTY_OBJECT_ID } from "modules/common/constants";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { agGridEntityContextMenu } from "modules/common/components/table/EntityContextMenu";

const modules = [...defaultModules, MenuModule];

class Base extends React.Component<TableProps, { activated: string }> implements TableContext {
    private columns = columnsBuilder();
    private options = DefaultAgGridOptions(true);

    constructor(props: TableProps) {
        super(props);

        this.state = { activated: "" };
    }

    render() {
        const { store } = this.props;

        return (
            <>
                {store.totalRow.length > 0 && (
                    <GridTheme bordered={false} className={`designed ${styles.totalRow}`}>
                        <AgGridReact
                            rowStyle={{
                                fontFamily: "GothamPro, Arial, sans-serif",
                                color: "#00273D",
                            }}
                            suppressRowClickSelection={true}
                            suppressCellSelection={true}
                            enableCellTextSelection={true}
                            columnDefs={this.columns}
                            rowData={store.totalRow}
                            rowHeight={FIGMA_ROW_HEIGHT * 2}
                            headerHeight={FIGMA_HEADER_HEIGHT}
                            minColWidth={5}
                            getRowNodeId={this.getRowNodeId}
                            localeText={agGridLocale}
                            context={this}
                            gridOptions={this.options}
                            immutableData={true}
                            modules={defaultModules}
                            rowBuffer={MAX_INT}
                        />
                    </GridTheme>
                )}
                <GridTheme bordered={true} className={`designed ${styles.table}`}>
                    <LocalLoader active={store.loading} />

                    <RemoveConfirmation<OverheadSpendingsByYearSnapshotType>
                        observation={store.data}
                        what={this.removeLabel}
                        onConfirmed={this.removeStatus}
                        render={({ confirmRemoving }) => {
                            const getContextMenuItems = ({ node }: GetContextMenuItemsParams) =>
                                agGridEntityContextMenu({
                                    onChange: selectable(node.data) ? () => this.selectRow(node.data) : undefined,
                                    onRemove: selectable(node.data) ? () => confirmRemoving(node.data) : undefined,
                                });

                            return (
                                <AgGridReact
                                    rowStyle={{
                                        fontFamily: "GothamPro, Arial, sans-serif",
                                        // color: "#00273D",
                                    }}
                                    suppressRowClickSelection={true}
                                    suppressCellSelection={true}
                                    enableCellTextSelection={true}
                                    columnDefs={this.columns}
                                    rowData={store.data}
                                    rowHeight={FIGMA_ROW_HEIGHT * 1.8}
                                    headerHeight={FIGMA_HEADER_HEIGHT}
                                    minColWidth={5}
                                    getRowNodeId={this.getRowNodeId}
                                    localeText={agGridLocale}
                                    context={this}
                                    gridOptions={this.options}
                                    immutableData={true}
                                    modules={modules}
                                    onGridReady={this.onDataGridReady}
                                    onSortChanged={store.sorter.resorted}
                                    onRowDoubleClicked={this.onRowSelected}
                                    rowBuffer={MAX_INT}
                                    getContextMenuItems={getContextMenuItems}
                                />
                            );
                        }}
                    />
                </GridTheme>
            </>
        );
    }

    removeStatus = async (status: OverheadSpendingsByYearSnapshotType) => {
        const { store, onRemoved } = this.props;
        const success = await store.delete(status);
        success && onRemoved();
    };

    removeLabel = (row: OverheadSpendingsByYearSnapshotType) => `тип накладного расхода ${row.overheadType?.name}`;

    getRowNodeId = (row: any) => row[fields.overheadTypeName];

    onDataGridReady = async ({ api }: GridReadyEvent) => {
        const { sorter } = this.props.store;
        setTimeout(() => api.setSortModel(sorter.agGridSortModel));
    };

    onRowSelected = ({ data }: RowClickedEvent) => this.selectRow(data);

    selectRow = (row: OverheadSpendingsByYearSnapshotType) => {
        const { onTypeSelected } = this.props;
        if (selectable(row)) {
            onTypeSelected(row.overheadType.id);
        }
    };

    setSum = async (id: string, sum: number) => {
        const success = await this.store.setSum(id, sum);
        if (success) {
            this.onActivate("");
        }

        return success;
    };

    copyFromMonth = (from: number, to: number) => this.store.copyFromMonth(from, to);

    copyFromYear = (month: number) => this.store.copyFromYear(month);

    onActivate = (activated: string) => this.setState({ activated });

    getActivated = () => this.state.activated;

    get store() {
        return this.props.store;
    }
}

function selectable(row: OverheadSpendingsByYearSnapshotType) {
    return row.overheadType.id !== EMPTY_OBJECT_ID && row.overheadType.id !== "000000010000000000000000";
}

export const OverheadTable = observer(Base);

interface TableProps {
    store: OverheadSpendingsType;
    onRemoved: () => void;
    onTypeSelected: (id: string) => void;
}
