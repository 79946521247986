import React, { useCallback, useState } from "react";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { Checkbox, Classes, InputGroup } from "@blueprintjs/core";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { onlyDigitsInputKeyPress } from "modules/common/services/form/values";
import { toString } from "modules/common/services/strings";
import {
  EventedDateValue,
  OrderStageRowItem,
  PlanOrderPaymentDetailsType,
} from "modules/orders-manage/models/order-stages-store";
import { EventsFormItem } from "./events/EventsFormItem";
import { EventDateType } from "modules/orders-manage/models/order-events";
import { IdFactory } from "modules/orders-manage/types";

const digits = onlyDigitsInputKeyPress();

type Mode = "date" | "event" | "duration";

const KindOptons = [
  {
    value: "date" as Mode,
    label: "Дата",
  },
  {
    value: "event" as Mode,
    label: "События",
  },
];

const WithDurationKindOptons = [
  ...KindOptons,
  {
    value: "duration" as Mode,
    label: "Дни",
  },
];

export const EventedDate = (props: EventedDateProps) => {
  const {
    onChange,
    date,
    payments,
    stages,
    additions,
    days,
    workDaysOnly,
    withDuration,
    newId,
    events,
    excludeBelonging,
    exclusiveMode,
    stageGuid,
  } = props;

  const [mode, setMode] = useState(intialMode(props));

  const onModeChange = useCallback((val: Mode) => {
    setMode(val);
  }, []);

  const onDateChangeHandler = useCallback(
    (val: Date | null) => {
      onChange({
        date: val,
        days: exclusiveMode ? 0 : days,
        workDaysOnly: exclusiveMode ? false : workDaysOnly,
        events: exclusiveMode ? [] : events,
      });
    },
    [onChange, days, workDaysOnly, events, exclusiveMode]
  );

  const onWorkDaysCheckedHandler = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      onChange({
        date: exclusiveMode ? null : date,
        days: days,
        workDaysOnly: e.currentTarget.checked,
        events: exclusiveMode ? [] : events,
      });
    },
    [onChange, days, events, date, exclusiveMode]
  );

  const onDaysCountChangeHandler = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      onChange({
        date: exclusiveMode ? null : date,
        days: parseInt(e.currentTarget.value || "0", 10),
        workDaysOnly,
        events: exclusiveMode ? [] : events,
      });
    },
    [onChange, workDaysOnly, date, events, exclusiveMode]
  );

  const onAddEventHandler = useCallback(
    (e: any) => {
      onChange({
        date: exclusiveMode ? null : date,
        days: exclusiveMode ? 0 : days,
        workDaysOnly: exclusiveMode ? false : workDaysOnly,
        events: [...events, e],
      });
    },
    [onChange, events, date, days, workDaysOnly, exclusiveMode]
  );

  const onRemoveEventHandler = useCallback(
    (e: EventDateType) => {
      const newEvents = events.filter((ev) => ev.model.newId !== e.model.newId);

      onChange({
        date: exclusiveMode ? null : date,
        days: exclusiveMode ? 0 : days,
        workDaysOnly: exclusiveMode ? false : workDaysOnly,
        events: newEvents,
      });
    },
    [onChange, events, date, days, workDaysOnly, exclusiveMode]
  );
  return (
    <div>
      <ModeToggler value={mode} options={withDuration ? WithDurationKindOptons : KindOptons} onChange={onModeChange} />
      {mode === "date" && (
        <div className={`${Classes.INPUT_GROUP} `}>
          <DatePicker
            boundary="viewport"
            value={date}
            onChange={onDateChangeHandler}
            clasName="planr-default-input"
            small={true}
          />
        </div>
      )}
      {mode === "duration" && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputGroup
            id="offcet"
            value={days === 0 ? "" : toString(days)}
            type="text"
            className="planr-default-input"
            autoComplete="off"
            data-lpignore="true"
            onKeyPress={digits}
            onChange={onDaysCountChangeHandler}
            placeholder="Дни"
            small={true}
          />
          &nbsp;
          <Checkbox large={false} checked={workDaysOnly} onChange={onWorkDaysCheckedHandler} label="Рабочие" />
        </div>
      )}
      {mode === "event" && (
        <EventsFormItem
          newId={newId}
          events={events}
          payments={payments}
          stages={stages}
          additions={additions}
          excludeBelonging={excludeBelonging}
          onAddEvent={onAddEventHandler}
          onRemoveEvent={onRemoveEventHandler}
          stageGuid={stageGuid}
        />
      )}
    </div>
  );
};

const intialMode = ({ date, days, workDaysOnly, events }: EventedDateProps): Mode => {
  if (date) {
    return "date";
  }

  if (days > 0 || workDaysOnly) {
    return "duration";
  }

  if (events.length > 0) {
    return "event";
  }

  return "date";
};

interface EventedDateProps extends EventedDateValue, IdFactory {
  payments: PlanOrderPaymentDetailsType[];
  stages: OrderStageRowItem[];
  additions: any;
  onChange: (value: EventedDateValue) => void;
  withDuration?: boolean;
  excludeBelonging?: string;
  stageGuid?: string;
  exclusiveMode?: boolean;
}

const ModeToggler = ({ value, onChange, options }: ModeTogglerProps) => {
  const width = (100 / options.length).toFixed(1) + "%";

  return (
    <div style={{ display: "flex", width: "100%", marginBottom: "5px" }}>
      {options.map((opt, index) => (
        <PlanrButton
          key={opt.value}
          type={value === opt.value ? "primary" : "graybtn"}
          onClick={() => onChange(opt.value)}
          size="small"
          style={{
            width,
            marginRight: "0px",
            marginLeft: "0px",
            ...togglerRadius(index, options.length),
          }}
        >
          {opt.label}
        </PlanrButton>
      ))}
    </div>
  );
};

const togglerRadius = (index: number, max: number): React.CSSProperties => {
  const base = "4px";

  if (index === 0) {
    return {
      borderBottomRightRadius: "0px",
      borderTopRightRadius: "0px",
      borderBottomLeftRadius: base,
      borderTopLeftRadius: base,
    };
  }

  if (index === max - 1) {
    return {
      borderBottomRightRadius: base,
      borderTopRightRadius: base,
      borderBottomLeftRadius: "0px",
      borderTopLeftRadius: "0px",
    };
  }

  return {
    borderBottomRightRadius: "0px",
    borderTopRightRadius: "0px",
    borderBottomLeftRadius: "0px",
    borderTopLeftRadius: "0px",
  };
};

interface ModeTogglerProps {
  options: Array<{ value: Mode; label: string }>;
  value: Mode;
  onChange: (value: Mode) => void;
}
