import { Classes, Collapse, Dialog, Popover, Spinner } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { Caret } from "modules/common/components/collapse/Caret";
import { FileLink } from "modules/common/components/files/FileLink";
import { Buttons } from "modules/common/components/form";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { DATE_FORMAT } from "modules/common/constants";
import { eat } from "modules/common/services/typescript";
import {
    OrderContentTasksListSnapshotType,
    OrderContentTasksListType,
} from "modules/orders-manage/models/order-content-tasks";
import { Constants } from "modules/root/models/constants";
import moment from "moment";
import React, { useState } from "react";
import { TUnit } from "./Block";
import styles from "./Block.module.scss";
import { titleIndicator } from "./CategoryBlock";

interface RowTaskProps {
    contentTask: OrderContentTasksListType | undefined;
    work: TUnit;
    baseUrl: string;
    highlighted?: string;
    collapsed: boolean;
    showDeleteTask: (guid: string, id: string) => void;
    showAddTask: (guid: string) => void;
    returnTask: (id: string) => void;
    addAccept: (guid: string, value: boolean, id: string) => void;
    addComplete: (guid: string, value: boolean, id: string) => void;
    addConfirm: (guid: string, value: boolean, id: string) => void;
    addCancell: (guid: string, value: boolean, id: string) => void;
    showUploadFiles: (guid: string) => void;
    showDevelopers: (guid: string, task: any) => void;
    showEditTask: (
        descr: string,
        stopDate: string | null,
        id: string,
        file: any,
        guid: string,
        employerId: string | null
    ) => void;
    downloadFiles: (guid: string) => Promise<any>;
    downloadVersion: (guid: string, version: number) => Promise<any>;
    deleteVersion: (guid: string, version: number) => void;
    onToggleCollapse: () => void;
    readOnly: boolean;
}

interface RowTaskState {
    downloading: boolean;
    versionDownloading: boolean;
    copied: boolean;
}

class Row extends React.PureComponent<RowTaskProps, RowTaskState> {
    constructor(props: RowTaskProps) {
        super(props);

        this.state = { downloading: false, versionDownloading: false, copied: false };
    }

    render() {
        const {
            contentTask,
            work,
            baseUrl,
            // showDeleteTask,
            returnTask,
            showAddTask,
            showEditTask,
            addAccept,
            showUploadFiles,
            showDevelopers,
            addComplete,
            addConfirm,
            addCancell,
            collapsed,
            downloadVersion,
            onToggleCollapse,
            readOnly,
            downloadFiles,
            deleteVersion,
            highlighted,
        } = this.props;

        const canEdit = !readOnly;
        const lastVersion = contentTask && contentTask.lastFilesVersion ? contentTask.lastFilesVersion : null;
        const canUploadFiles = contentTask && contentTask.canUploadFiles ? contentTask.canUploadFiles : false;
        const canChangeTask = contentTask && contentTask.canAddTasks ? contentTask.canAddTasks : false; //
        const canDownloadStructure =
            contentTask && contentTask.canDownloadStructure ? contentTask.canDownloadStructure : false;
        const canDeleteVersion = contentTask && contentTask.canDeleteVersion ? contentTask.canDeleteVersion : false;
        const canGenerateSheet = contentTask && contentTask.canGenerateSheet ? contentTask.canGenerateSheet : false;
        const comments =
            contentTask && contentTask.comments.length > 0
                ? contentTask.comments.slice().sort((a, b) => b.version - a.version)
                : null;

        let tasksCount = 0;
        if (contentTask) {
            tasksCount = contentTask.tasks.filter((t) => !t.confirmed).length;
        }
        let nowDate = new Date();
        nowDate.setHours(0, 0, 0, 0);

        return (
            <div className={styles.rowTaskBlock}>
                <h1 className="planr-block-header collapser" onClick={onToggleCollapse}>
                    <div className={styles.indicator}>
                        {tasksCount > 0 && (
                            <span title={titleIndicator} className={styles.countIndicator}>
                                {tasksCount}
                            </span>
                        )}
                    </div>

                    <div className={styles.orderNumber}>{work.partNumber}</div>
                    <div className={styles.workAndInventory}>{work.name}</div>
                    <div className={styles.description}>{work.description}</div>
                    <div className={styles.progress}>
                        {
                            <span
                                title={work.lastModified ? `Обновлено: ${work.lastModified}` : ""}
                                className={
                                    work.progress === 0
                                        ? "percents color-red"
                                        : work.progress < 50
                                        ? "percents color-orange"
                                        : work.progress >= 50 && work.progress < 75
                                        ? "percents color-blue"
                                        : work.progress >= 75 && work.progress < 100
                                        ? "percents color-purple"
                                        : work.progress === 100
                                        ? "percents color-green"
                                        : "progress"
                                }
                            >
                                {work.progress} %
                            </span>
                        }
                    </div>
                    <Caret collapsed={collapsed} />
                </h1>
                <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                    <div className="collapse" style={{ width: "1492.91px" }}>
                        <div>
                            <div className={styles.rowTask}>
                                <div className={styles.leftColumn}>
                                    {contentTask && (
                                        <div className={styles.leftTasks}>
                                            {contentTask.tasks.map((task, index) => {
                                                const removed = task.toRemove;
                                                const accepted = task.accepted;
                                                const completed = task.completed;
                                                const confirmed = task.confirmed;
                                                const cancelled = task.cancelled;
                                                const canChangeAccept = task.canChangeAcceptance;
                                                const canChangeComplete = task.canChangeCompletion;
                                                const canChangeConfirm = task.canChangeConfirmation;
                                                const canChangeCancell = task.canChangeCancellation;
                                                const canEditTask = task.canEditTask;

                                                return (
                                                    <div
                                                        className={`${styles.rowTaskLeft} ${
                                                            highlighted && highlighted === task.id && styles.highlighted
                                                        }`}
                                                        key={index}
                                                        data-object-id={task.id}
                                                        style={{
                                                            backgroundColor: cancelled ? "#f6f6f6" : "",
                                                        }}
                                                    >
                                                        <div>
                                                            <div className={styles.rowTaskHeader}>
                                                                Задание
                                                                <div className={styles.rowTaskHeaderBtns}>
                                                                    {!removed && canEdit && canChangeCancell && (
                                                                        <PlanrButton
                                                                            type={
                                                                                cancelled
                                                                                    ? "lightgreenish"
                                                                                    : "secondary"
                                                                            }
                                                                            icon={
                                                                                cancelled
                                                                                    ? "general-undo"
                                                                                    : "general-cross-small"
                                                                            }
                                                                            size="small"
                                                                            title={
                                                                                cancelled
                                                                                    ? "Восстановить задание"
                                                                                    : "Аннулировать задание"
                                                                            }
                                                                            onClick={
                                                                                canChangeCancell
                                                                                    ? () => {
                                                                                          const cancell = !cancelled;
                                                                                          addCancell(
                                                                                              work.guid,
                                                                                              cancell,
                                                                                              task.id
                                                                                          );
                                                                                      }
                                                                                    : eat
                                                                            }
                                                                        />
                                                                    )}
                                                                    {!cancelled && canEdit && canEditTask && (
                                                                        <PlanrButton
                                                                            type="lightblueish"
                                                                            icon="general-edit"
                                                                            size="small"
                                                                            title="Редактировать задание"
                                                                            onClick={() => {
                                                                                showEditTask(
                                                                                    task.description,
                                                                                    task.stopDate,
                                                                                    task.id,
                                                                                    task.taskFile,
                                                                                    work.guid,
                                                                                    task.implementer
                                                                                        ? task.implementer.id
                                                                                        : null
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}

                                                                    {/* {!removed && canEdit && (
                                                                        <PlanrButton
                                                                            type="secondary"
                                                                            size="small"
                                                                            icon="general-trash"
                                                                            title="Удалить задание"
                                                                            onClick={() => {
                                                                                showDeleteTask(work.guid, task.id);
                                                                            }}
                                                                        />
                                                                    )} */}
                                                                    {removed && canEdit && (
                                                                        <PlanrButton
                                                                            type="secondary"
                                                                            size="small"
                                                                            icon="general-undo"
                                                                            title="Отменить удаление"
                                                                            onClick={() => {
                                                                                returnTask(task.id);
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {/* <GeneralIcon type="general-redo" onClick={this.returnTask} /> */}
                                                                </div>
                                                            </div>
                                                            <div className={styles.commentRow}>{task.description}</div>

                                                            <div className={styles.rowTaskLeftItemHeader}>
                                                                <div className={styles.employer}>Выдал</div>
                                                                <div className={styles.taskFileSmall}>Файл</div>
                                                                <div className={styles.date}>Дата от</div>
                                                                <div className={styles.implementer}>Исполнитель</div>
                                                                <div className={styles.stopDate}>Дата до</div>
                                                                <div className={styles.taskStatus}>Статус</div>
                                                            </div>
                                                            <div className={styles.rowTaskLeftItem}>
                                                                <div className={styles.employer}>{task.author}</div>
                                                                <div className={styles.taskFileSmall}>
                                                                    {task.taskFile &&
                                                                        task.taskFile.length > 0 &&
                                                                        task.taskFile.map((file) => (
                                                                            <div
                                                                                style={{ display: "flex" }}
                                                                                key={file.id}
                                                                            >
                                                                                <FileLink<any>
                                                                                    key={file.id}
                                                                                    baseUrl={baseUrl}
                                                                                    file={{
                                                                                        fileId: file.id,
                                                                                        fileName: file.name,
                                                                                    }}
                                                                                    readOnly={true}
                                                                                    onRemove={eat}
                                                                                    littleIcon
                                                                                    title={file.name}
                                                                                />

                                                                                {file.path && (
                                                                                    <Popover
                                                                                        position="top"
                                                                                        usePortal={true}
                                                                                        interactionKind="hover"
                                                                                        content={
                                                                                            <div
                                                                                                className={
                                                                                                    styles.pathWrapper
                                                                                                }
                                                                                            >
                                                                                                <div>
                                                                                                    <div>
                                                                                                        Путь к файлу
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={
                                                                                                            styles.pathText
                                                                                                        }
                                                                                                    >
                                                                                                        {file.path}
                                                                                                    </div>
                                                                                                </div>
                                                                                                {!this.state.copied ? (
                                                                                                    <PlanrButton
                                                                                                        type="secondary"
                                                                                                        icon="general-copy"
                                                                                                        title="Копировать"
                                                                                                        size="small"
                                                                                                        onClick={async () => {
                                                                                                            try {
                                                                                                                await navigator.clipboard.writeText(
                                                                                                                    file.path
                                                                                                                        ? file.path
                                                                                                                        : ""
                                                                                                                );
                                                                                                            } catch (e) {
                                                                                                            } finally {
                                                                                                                this.setState(
                                                                                                                    {
                                                                                                                        copied: true,
                                                                                                                    }
                                                                                                                );
                                                                                                                setTimeout(
                                                                                                                    () => {
                                                                                                                        this.setState(
                                                                                                                            {
                                                                                                                                copied: false,
                                                                                                                            }
                                                                                                                        );
                                                                                                                    },
                                                                                                                    1000
                                                                                                                );
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                ) : (
                                                                                                    <PlanrButton
                                                                                                        type="greenish"
                                                                                                        icon="general-check"
                                                                                                        size="small"
                                                                                                    />
                                                                                                )}
                                                                                            </div>
                                                                                        }
                                                                                    >
                                                                                        <span
                                                                                            className={
                                                                                                styles.withDataCell
                                                                                            }
                                                                                        >
                                                                                            <GeneralIcon
                                                                                                type="general-attach"
                                                                                                style={{
                                                                                                    cursor: "pointer",
                                                                                                    color: "#1DD278",
                                                                                                    marginLeft: "10px",
                                                                                                }}
                                                                                            />
                                                                                        </span>
                                                                                    </Popover>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                                <div className={styles.date}>
                                                                    {task.date}
                                                                    <br />
                                                                    <span className={styles.time}>{task.time}</span>
                                                                </div>
                                                                <div className={styles.implementer}>
                                                                    {task.implementer ? (
                                                                        task.implementer.name
                                                                    ) : (
                                                                        <span className={styles.noDataCell}>Нет</span>
                                                                    )}
                                                                </div>
                                                                <div className={styles.stopDate}>
                                                                    {task.stopDate ? (
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    moment(
                                                                                        task.stopDate,
                                                                                        DATE_FORMAT
                                                                                    ).toDate() < nowDate
                                                                                        ? "#e31818"
                                                                                        : "",
                                                                            }}
                                                                        >
                                                                            {task.stopDate}
                                                                        </span>
                                                                    ) : (
                                                                        <span className={styles.noDataCell}>Нет</span>
                                                                    )}
                                                                </div>

                                                                <div className={styles.taskStatus}>
                                                                    <div
                                                                        className={`${styles.taskStatusRow} ${
                                                                            !canChangeAccept
                                                                                ? styles.cantChangeState
                                                                                : ""
                                                                        }`}
                                                                        title="Доступ: Ответственный"
                                                                    >
                                                                        <div className={styles.taskStatusRowLabel}>
                                                                            Принял
                                                                        </div>
                                                                        <div className={styles.taskStatusRowBtn}>
                                                                            <GeneralIcon
                                                                                type={
                                                                                    accepted
                                                                                        ? "general-checkbox"
                                                                                        : "general-zero-checkbox"
                                                                                }
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={
                                                                                    canChangeAccept
                                                                                        ? () => {
                                                                                              const accept = !accepted;
                                                                                              addAccept(
                                                                                                  work.guid,
                                                                                                  accept,
                                                                                                  task.id
                                                                                              );
                                                                                          }
                                                                                        : eat
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={`${styles.taskStatusRow} ${
                                                                            !canChangeComplete
                                                                                ? styles.cantChangeState
                                                                                : ""
                                                                        }`}
                                                                        title="Доступ: Ответственный, Исполнитель"
                                                                    >
                                                                        <div className={styles.taskStatusRowLabel}>
                                                                            Выполнил
                                                                        </div>
                                                                        <div className={styles.taskStatusRowBtn}>
                                                                            <GeneralIcon
                                                                                type={
                                                                                    completed
                                                                                        ? "general-checkbox"
                                                                                        : "general-zero-checkbox"
                                                                                }
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={
                                                                                    canChangeComplete
                                                                                        ? () => {
                                                                                              const complete =
                                                                                                  !completed;
                                                                                              addComplete(
                                                                                                  work.guid,
                                                                                                  complete,
                                                                                                  task.id
                                                                                              );
                                                                                          }
                                                                                        : eat
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={`${styles.taskStatusRow} ${
                                                                            !canChangeConfirm
                                                                                ? styles.cantChangeState
                                                                                : ""
                                                                        }`}
                                                                        title="Доступ: Выдал, Ответственный"
                                                                    >
                                                                        <div className={styles.taskStatusRowLabel}>
                                                                            Подтвердил
                                                                        </div>
                                                                        <div className={styles.taskStatusRowBtn}>
                                                                            <GeneralIcon
                                                                                type={
                                                                                    confirmed
                                                                                        ? "general-checkbox"
                                                                                        : "general-zero-checkbox"
                                                                                }
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={
                                                                                    canChangeConfirm
                                                                                        ? () => {
                                                                                              const confirm =
                                                                                                  !confirmed;
                                                                                              addConfirm(
                                                                                                  work.guid,
                                                                                                  confirm,
                                                                                                  task.id
                                                                                              );
                                                                                          }
                                                                                        : eat
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                    {canEdit && canChangeTask && (
                                        <PlanrButton
                                            type="secondary"
                                            style={{ margin: "10px 250px" }}
                                            onClick={() => {
                                                showAddTask(work.guid);
                                            }}
                                        >
                                            Добавить задание
                                        </PlanrButton>
                                    )}
                                </div>

                                <div className={styles.rightColumn}>
                                    {lastVersion && contentTask && (
                                        <div className={styles.rowTaskRight}>
                                            <div className={styles.rowTaskHeader}>
                                                Результат выполнения
                                                <div className={styles.rowTaskHeaderBtns}>
                                                    {canUploadFiles && (
                                                        <PlanrButton
                                                            type="graybtn"
                                                            icon="general-edit"
                                                            size="small"
                                                            title="Загрузить новую версию файлов"
                                                            onClick={() => {
                                                                showUploadFiles(work.guid);
                                                            }}
                                                        />
                                                    )}

                                                    {canEdit && canGenerateSheet && (
                                                        <PlanrButton
                                                            type="secondary"
                                                            size="small"
                                                            title="Сформировать ИУЛ"
                                                            onClick={() => {
                                                                showDevelopers(work.guid, contentTask);
                                                            }}
                                                        >
                                                            ИУЛ
                                                        </PlanrButton>
                                                    )}
                                                    {canDownloadStructure && (
                                                        <DownloadButton
                                                            loading={this.state.downloading}
                                                            onClick={async () => {
                                                                try {
                                                                    this.setState({ downloading: true });
                                                                    await downloadFiles(contentTask.guid);
                                                                } finally {
                                                                    this.setState({ downloading: false });
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className={styles.rowTaskLeftItemHeader}>
                                                <div className={styles.taskFileMiddle}>Исходные файлы</div>
                                                <div className={styles.taskFileMiddle}>Файл результата</div>
                                                <div className={styles.date}>Дата</div>
                                                <div className={styles.version}>Версия</div>
                                                <div className={styles.taskFileMiddle}>Иул</div>
                                            </div>
                                            <div className={styles.rowTaskLeftItem}>
                                                <div className={styles.taskFileMiddle}>
                                                    {lastVersion.files.map((file, index) => {
                                                        if (file.type === Constants.orderFileTypeContentTaskSource) {
                                                            return (
                                                                <FileLink<any>
                                                                    key={index}
                                                                    baseUrl={baseUrl}
                                                                    file={{
                                                                        fileId: file.id,
                                                                        fileName: file.name,
                                                                    }}
                                                                    style={{
                                                                        height: "35px",
                                                                        marginBottom: "5px",
                                                                        minWidth: "170px",
                                                                        width: "170px",
                                                                        border: "3px dashed #edf0f2",
                                                                        fontSize: "12px",
                                                                    }}
                                                                    readOnly={true}
                                                                    onRemove={eat}
                                                                />
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>
                                                <div className={styles.taskFileMiddle}>
                                                    {lastVersion.files.map((file, index) => {
                                                        if (file.type === Constants.orderFileTypeContentTaskResult) {
                                                            return (
                                                                <FileLink<any>
                                                                    key={index}
                                                                    baseUrl={baseUrl}
                                                                    file={{
                                                                        fileId: file.id,
                                                                        fileName: file.name,
                                                                    }}
                                                                    style={{
                                                                        height: "35px",
                                                                        marginBottom: "5px",
                                                                        minWidth: "170px",
                                                                        width: "170px",
                                                                        border: "3px dashed #edf0f2",
                                                                        fontSize: "12px",
                                                                    }}
                                                                    readOnly={true}
                                                                    onRemove={eat}
                                                                />
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>
                                                <div className={styles.date}>
                                                    {lastVersion.date}
                                                    <br /> <span className={styles.time}>{lastVersion.time}</span>
                                                </div>
                                                <div className={styles.version}>
                                                    {
                                                        <Popover
                                                            position="auto"
                                                            enforceFocus={false}
                                                            isOpen={undefined}
                                                        >
                                                            <span className="bp3-tooltip-indicator">{`Версия_${lastVersion.version}`}</span>
                                                            <VersionDetails
                                                                task={contentTask}
                                                                baseUrl={baseUrl}
                                                                removeVersion={(guid: string, version: number) => {
                                                                    deleteVersion(guid, version);
                                                                }}
                                                                downloadVersion={async (
                                                                    guid: string,
                                                                    version: number
                                                                ) => {
                                                                    await downloadVersion(guid, version);
                                                                }}
                                                                canDownload={canDownloadStructure}
                                                                canDelete={canDeleteVersion}
                                                            />
                                                        </Popover>
                                                    }
                                                </div>
                                                <div className={styles.taskFileMiddle}>
                                                    {contentTask.sheetFile && (
                                                        <FileLink<any>
                                                            baseUrl={baseUrl}
                                                            file={{
                                                                fileId: contentTask.sheetFile.id,
                                                                fileName: contentTask.sheetFile.name,
                                                            }}
                                                            style={{
                                                                height: "35px",
                                                                marginBottom: "5px",
                                                                minWidth: "170px",
                                                                width: "170px",
                                                                border: "3px dashed #edf0f2",
                                                                fontSize: "12px",
                                                            }}
                                                            readOnly={true}
                                                            onRemove={eat}
                                                        />
                                                    )}
                                                </div>
                                                <div className={styles.accepted}></div>
                                            </div>
                                        </div>
                                    )}
                                    {!lastVersion && canUploadFiles && (
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                margin: "10px auto",
                                            }}
                                        >
                                            <PlanrButton
                                                type="secondary"
                                                onClick={() => {
                                                    showUploadFiles(work.guid);
                                                }}
                                            >
                                                Загрузить результат работ
                                            </PlanrButton>
                                        </div>
                                    )}

                                    {comments && (
                                        <div className={styles.rowTaskRight}>
                                            <div className={styles.rowTaskHeader}>Замечания заказчика</div>
                                            <div className={styles.rowTaskLeftItemHeader} style={{ marginLeft: "8px" }}>
                                                <div className={styles.version}>Версия</div>
                                                <div className={styles.commentRemark}>Комментарий</div>
                                                <div className={styles.commentRemarkFile}>Файлы замечаний</div>
                                            </div>
                                            <div
                                                className={styles.rowTaskLeftItem}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                {comments.map((comment, i) => {
                                                    return (
                                                        <div key={i} className={styles.commentItem}>
                                                            <div className={styles.version}>
                                                                Версия_{comment.version}
                                                            </div>
                                                            <div
                                                                className={styles.commentRemark}
                                                                style={{ width: "380px", marginRight: "20px" }}
                                                            >
                                                                {comment.comment}
                                                            </div>
                                                            <div className={styles.commentRemarkFile}>
                                                                {comment.commentFiles &&
                                                                    comment.commentFiles.map((commentFile, index) => (
                                                                        <FileLink<any>
                                                                            key={index}
                                                                            baseUrl={baseUrl}
                                                                            file={{
                                                                                fileId: commentFile.fileId,
                                                                                fileName: commentFile.fileName,
                                                                            }}
                                                                            style={{
                                                                                marginBottom: "8px",
                                                                                minWidth: "200px",
                                                                                width: "200px",
                                                                                fontSize: "12px",
                                                                            }}
                                                                            readOnly={true}
                                                                            onRemove={eat}
                                                                        />
                                                                    ))}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }
}

export const RowTask = observer(Row);

interface VersionDetailsProps {
    task: OrderContentTasksListSnapshotType;
    baseUrl: string;
    downloadVersion: (guid: string, version: number) => Promise<any>;
    removeVersion: (guid: string, version: number) => void;
    canDownload: boolean;
    canDelete: boolean;
}

const VersionDetails = ({
    task,
    baseUrl,
    downloadVersion,
    removeVersion,
    canDownload,
    canDelete,
}: VersionDetailsProps) => {
    const [showDelete, setshowDelete] = useState(false);
    const [version, setversion] = useState(1);

    const versions = task.versions.slice().sort((a, b) => b.version - a.version);
    return (
        <div className={styles.popupVersions}>
            <Dialog
                title={`Удаление версии`}
                isOpen={showDelete}
                onClose={() => {
                    setshowDelete(false);
                }}
                backdropClassName="standard"
                className={`${styles.FilesDialog} figma-dialog`}
            >
                <div className={`${Classes.DIALOG_BODY} `}>
                    <div
                        className={styles.showDialogTask}
                    >{`Вы действительно хотите удалить файлы (Версия_${version})?`}</div>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <Buttons
                        left={
                            <div style={{ display: "flex" }}>
                                <PlanrButton
                                    size="small"
                                    type="danger"
                                    onClick={() => {
                                        removeVersion(task.guid, version);
                                        setshowDelete(false);
                                    }}
                                >
                                    Удалить
                                </PlanrButton>
                                <PlanrButton
                                    size="small"
                                    type="graybtn"
                                    onClick={() => {
                                        setshowDelete(false);
                                    }}
                                >
                                    Отмена
                                </PlanrButton>
                            </div>
                        }
                    />
                </div>
            </Dialog>
            <div className={styles.rowTaskRight} style={{ minWidth: "600px" }}>
                <div className={styles.rowTaskLeftItemHeader}>
                    <div className={styles.taskFileWide}>Исходные файлы</div>
                    <div className={styles.taskFileWide}>Файл результата</div>
                    <div className={styles.version}>Версия</div>
                    <div className={styles.date}>Дата</div>
                </div>

                {versions.map((version, index) => (
                    <Version
                        key={index}
                        version={version}
                        baseUrl={baseUrl}
                        setVersion={(v: number) => {
                            setversion(v);
                        }}
                        canDelete={canDelete}
                        canDownload={canDownload}
                        showDelete={() => {
                            setshowDelete(true);
                        }}
                        onDownload={async (v: number) => {
                            await downloadVersion(task.guid, v);
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

interface VersionProps {
    version: any;
    baseUrl: string;
    setVersion: (v: number) => void;
    canDelete: boolean;
    canDownload: boolean;
    showDelete: () => void;
    onDownload: (v: number) => Promise<any>;
}
interface VersionState {
    downloading: boolean;
}
class Version extends React.PureComponent<VersionProps, VersionState> {
    mounted = true;
    constructor(props: VersionProps) {
        super(props);

        this.state = { downloading: false };
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
        this.setState({ downloading: false });
    }

    render() {
        const { version, baseUrl, canDownload, setVersion, canDelete, showDelete, onDownload } = this.props;

        return (
            <div>
                <div className={styles.rowTaskLeftItem}>
                    <div className={styles.taskFileWide}>
                        {version.files.map((file: any, fileIndex: number) => {
                            if (file.type === Constants.orderFileTypeContentTaskSource) {
                                return (
                                    <FileLink<any>
                                        key={fileIndex}
                                        baseUrl={baseUrl}
                                        file={{
                                            fileId: file.id,
                                            fileName: file.name,
                                        }}
                                        style={{
                                            height: "35px",
                                            marginBottom: "5px",
                                            width: "200px",
                                            border: "3px dashed #edf0f2",
                                            fontSize: "12px",
                                        }}
                                        readOnly={true}
                                        onRemove={eat}
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}
                    </div>
                    <div className={styles.taskFileWide}>
                        {version.files.map((file: any, fileIndex: number) => {
                            if (file.type === Constants.orderFileTypeContentTaskResult) {
                                return (
                                    <FileLink<any>
                                        key={fileIndex}
                                        baseUrl={baseUrl}
                                        file={{
                                            fileId: file.id,
                                            fileName: file.name,
                                        }}
                                        style={{
                                            height: "35px",
                                            marginBottom: "5px",
                                            width: "200px",
                                            border: "3px dashed #edf0f2",
                                            fontSize: "12px",
                                        }}
                                        readOnly={true}
                                        onRemove={eat}
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}
                    </div>
                    <div className={styles.version}>{`Версия_${version.version}`}</div>
                    <div className={styles.date}>
                        {version.date} <br />
                        <span className={styles.time}>{version.time}</span>
                    </div>
                    {canDownload && (
                        <div className={styles.date}>
                            <DownloadButton
                                loading={this.state.downloading}
                                onClick={async () => {
                                    try {
                                        this.setState({ downloading: true });
                                        await onDownload(version.version);
                                    } catch {
                                        return;
                                    } finally {
                                        if (this.mounted) {
                                            this.setState({ downloading: false });
                                        }
                                    }
                                }}
                            />
                        </div>
                    )}

                    {canDelete && (
                        <div className={styles.date}>
                            <PlanrButton
                                type="secondary"
                                size="small"
                                icon="general-trash"
                                title="Удалить версию"
                                onClick={() => {
                                    showDelete();
                                    setVersion(version.version);
                                }}
                            />
                        </div>
                    )}
                </div>
                <hr />
            </div>
        );
    }
}

export default RowTask;

export const DownloadButton = (props: { loading: boolean; onClick: () => void; gray?: boolean }) => {
    return (
        <PlanrButton
            type={props.gray ? "graybtn" : "greenish"}
            size={!props.gray ? "small" : "medium"}
            icon={props.loading ? undefined : "general-download"}
            onClick={props.onClick}
            disabled={props.loading}
            round={props.gray ? true : false}
            title="Скачать архив с файлами по заданию"
        >
            {props.loading && <Spinner intent="primary" size={16} />}
        </PlanrButton>
    );
};
