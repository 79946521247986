import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { DictionaryItem } from "modules/common/models/entity";
import { formatDate } from "modules/common/services/formatting/date";
import { IdEntity } from "../../../common/models/entity";

export const EmployerDictionaryLink = types
    .compose(
        IdEntity,
        types.model({
            name: types.string,
            login: types.string,
            position: types.string,
        })
    )
    .named("EmployerDictionaryLink");

export const EmployerDictionaryItem = types
    .compose(
        DictionaryItem,
        types.model({
            name: types.string,
            login: types.string,
            position: types.string,
            phone: types.string,
            department: types.string,
            nonProduction: types.boolean,
            companySpendingPerHour: types.number,
            salaryPerMonth: types.number,
            fired: types.boolean,
        })
    )
    .named("EmployerDictionaryItem");

const EmployerDictionaryFilter = types.model({
    departmentId: types.string,
    withSalary: types.maybeNull(types.string),
    sortByHierarchy: types.boolean,
    withFired: types.optional(types.boolean, false),
});

export const EmployerDictionary = types
    .compose(
        Transport,
        Notificator,
        types.model({
            employee: types.array(EmployerDictionaryItem),
            filter: EmployerDictionaryFilter,
        })
    )
    .actions((self) => ({
        predefineFilter(filter: EmployerDictionaryFilterSnapshotType) {
            applySnapshot(self.filter, filter);
        },
        load: flow(function* (filter?: EmployerDictionaryFilterSnapshotType) {
            try {
                const data: any[] = yield self.transport.get<any>(apiUrls.employee.dictionary(), {
                    params: filter || self.filter || {},
                });
                applySnapshot(self.employee, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .views((self) => ({
        get isEmpty() {
            return !self.employee.length;
        },

        get departmentMap() {
            const result: DepartmentGroup[] = [];

            self.employee.forEach((user) => {
                let target = result.find((gr) => gr.department === user.department);
                if (!target) {
                    target = { department: user.department, employee: [] };
                    result.push(target);
                }

                target.employee.push(user);
            });

            return result;
        },

        get asMap(): TStringMap<EmployerDictionaryItemType> {
            return self.employee.reduce((result, employer) => {
                result[employer.id] = employer;
                return result;
            }, {} as TStringMap<EmployerDictionaryItemType>);
        },

        get data() {
            return self.employee.map((e) => getSnapshot(e));
        },
    }))
    .named("EmployerDictionary");

export type EmployerDictionaryLinkType = typeof EmployerDictionaryLink.Type;
export type EmployerDictionaryItemType = typeof EmployerDictionaryItem.Type;
export type EmployerDictionaryItemSnapshotType = typeof EmployerDictionaryItem.SnapshotType;
export type EmployerDictionaryType = typeof EmployerDictionary.Type;
export type EmployerDictionarySnapshotType = typeof EmployerDictionary.SnapshotType;
export type EmployerDictionaryFilterSnapshotType = typeof EmployerDictionaryFilter.SnapshotType;

export const initialState = (): EmployerDictionarySnapshotType => ({
    employee: [],
    filter: { departmentId: "", withSalary: formatDate(new Date()), sortByHierarchy: false, withFired: false },
});

interface DepartmentGroup {
    department: string;
    employee: EmployerDictionaryItemType[];
}
