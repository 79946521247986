import React from "react";
import styles from "./WorkloadCell.module.scss";
import { WorkloadDayType } from "modules/spending/workload/models/workload-day";
import classnames from "classnames";
import { Icon } from "@blueprintjs/core";
import { Constants } from "modules/root/models/constants";
import { WorkloadUnitOrderLinkType } from "modules/spending/workload/models/workload-unit";
import { hex2rgba } from "modules/common/services/colors";
import { observer } from "mobx-react";
import { toString } from "modules/common/services/strings";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";

const empty = <span>&nbsp;</span>;

export const OvertimeCell = ({ state }: { state: number }) => {
  const className = classnames({
    "print-only": true,
    "timesheet-cell": true,
    [styles.cell]: true,
    [styles.overtime]: true,
  });

  const text = state === 0 ? "" : toString(state);

  return <div className={className}>{text}</div>;
};

export const WorkloadCell = observer(
  class extends React.Component<WorkloadCellProps, WorkloadCellState> {
    constructor(props: WorkloadCellProps) {
      super(props);

      this.state = { comment: "", open: false };
    }
    componentDidMount() {
      if (this.props.day.comment) {
        this.setState({ comment: this.props.day.comment });
      }
    }
    otherTypes = Constants.otherHoursTypes.map((type) => type.name);

    render() {
      const { preToday, day, selectedCell, selectedCells, onContext, mode, locked, nonProduction } = this.props;
      const { isDayOff, displayHours, isToday, displayType } = day;
      // const handlePopupClose = () => {
      //     const { day, changeComment } = this.props;
      //     const model = {
      //         comment: day.comment,
      //         day: day.day,
      //         employerId: day.user.id,
      //     };

      //     changeComment(model);

      //     this.setState({ open: false });
      // };

      // const handlePopupClear = () => {
      //     const { day, changeComment } = this.props;
      //     day.setComment("");
      //     const model = {
      //         comment: day.comment,
      //         day: day.day,
      //         employerId: day.user.id,
      //     };
      //     changeComment(model);

      //     this.setState({ open: false });
      // };
      let isSelected = selectedCell.includes(day.day) && selectedCell.includes(day.user.id);

      selectedCells.forEach((cell) => {
        if (cell.includes(day.day) && cell.includes(day.user.id)) {
          isSelected = true;
        }
      });

      const className = classnames({
        "timesheet-cell": true,
        [styles.cell]: true,
        [`${styles.today} is-today`]: isToday(),
        [styles.preToday]: preToday,
        selected: isSelected,
        nonProduction,
      });

      const overlay = classnames({
        [styles.overlay]: true,
        [styles.selected]: isSelected,
        [styles.nonProduction]: nonProduction,
        [styles.today]: isToday(),
        [styles.hoursMode]: mode === "hours",
        [styles.weekend]: isDayOff,
      });
      const itemClassName = "mouse-select__selectable";

      return (
        <div
          key={`${day.day}_${day.user.id}`}
          data-id={`${day.day}_${day.user.id}`}
          className={classnames(className, itemClassName)}
          onClick={this.onSelect}
          onDoubleClick={!nonProduction && !isDayOff ? this.onChangeStart : () => {}}
          onContextMenu={
            !nonProduction && !isDayOff
              ? (e) => {
                  e.preventDefault();

                  this.onSelect();
                  onContext(
                    e.clientX + e.currentTarget.getBoundingClientRect().left - 600,
                    e.currentTarget.getBoundingClientRect().top - 40,
                    day
                  );
                }
              : (e) => {
                  e.preventDefault();
                  this.onSelect();
                }
          }
          id={`${day.day}_${day.user.id}`}
        >
          {/* {isDayWorkable && ( */}
          <>
            <div className={styles.short}>{displayType ? displayType.shortName : empty}</div>
            <div className={`${styles.short} ${styles.hours}`}>{displayHours || empty}</div>
            <div className={styles.project}>{this.renderProject(day)}</div>
          </>

          <div className={overlay} style={this.overlayStyle()}>
            {locked && <Icon icon="lock" />}
          </div>
          {/* {isSelected && !day.isCommented && (
                        <div className={styles.popoverButton}>
                            <Popover
                                isOpen={this.state.open}
                                usePortal={true}
                                position={"top"}
                                onInteraction={(state) => {
                                    this.setState({ open: state });
                                }}
                                interactionKind={"click"}
                                content={
                                    <div style={{ display: "flex", padding: "10px" }}>
                                        <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                                            <TextArea
                                                growVertically={true}
                                                rows={1}
                                                autoComplete="off"
                                                placeholder="Комментарий"
                                                data-lpignore="true"
                                                value={day.comment}
                                                className={`${Classes.FILL} planr-default-input`}
                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                    day.setComment(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <GeneralIcon
                                            type="general-redo"
                                            onClick={handlePopupClose}
                                            style={{
                                                color: "#1DD278",
                                                cursor: "pointer",
                                                marginLeft: "10px",
                                                marginTop: "10px",
                                            }}
                                        />
                                        <GeneralIcon
                                            type="general-trash"
                                            onClick={handlePopupClear}
                                            style={{
                                                color: "#E31818",
                                                cursor: "pointer",
                                                marginLeft: "10px",
                                                marginTop: "10px",
                                            }}
                                        />
                                    </div>
                                }
                            >
                                <GeneralIcon
                                    type="general-plus-small"
                                    onClick={() => {
                                        this.setState({ open: true });
                                    }}
                                />
                            </Popover>
                        </div>
                    )}
                    {day.isCommented && (
                        <div className={styles.popoverButtonCommented}>
                            <Popover
                                isOpen={this.state.open}
                                usePortal={true}
                                position={"top"}
                                onInteraction={(state) => {
                                    this.setState({ open: state });
                                }}
                                interactionKind={"click"}
                                content={
                                    <div style={{ display: "flex", padding: "10px" }}>
                                        <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                                            <TextArea
                                                growVertically={true}
                                                rows={1}
                                                autoComplete="off"
                                                placeholder="Комментарий"
                                                data-lpignore="true"
                                                value={day.comment}
                                                className={`${Classes.FILL} planr-default-input`}
                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                    day.setComment(e.target.value);
                                                }}
                                            />
                                        </div>
                                        <GeneralIcon
                                            type="general-redo"
                                            onClick={handlePopupClose}
                                            style={{
                                                color: "#1DD278",
                                                cursor: "pointer",
                                                marginLeft: "10px",
                                                marginTop: "10px",
                                            }}
                                        />
                                        <GeneralIcon
                                            type="general-trash"
                                            onClick={handlePopupClear}
                                            style={{
                                                color: "#E31818",
                                                cursor: "pointer",
                                                marginLeft: "10px",
                                                marginTop: "10px",
                                            }}
                                        />
                                    </div>
                                }
                            >
                                <div
                                    className={styles.isCommentedTag}
                                    onClick={() => {
                                        this.setState({ open: true });
                                    }}
                                ></div>
                            </Popover>
                        </div>
                    )} */}
        </div>
      );
    }

    overlayStyle = (): React.CSSProperties => {
      const { mode, day } = this.props;
      const ordersArr = day.units
        .map((unit) => unit.order)
        .filter((order) => order !== null) as WorkloadUnitOrderLinkType[];

      if (mode === "hours" || ordersArr.length === 0) {
        return {};
      }

      const alpha = 0.4;

      if (ordersArr.length > 1) {
        return {
          backgroundColor: hex2rgba("#ffff00", alpha),
        };
      }

      return {
        backgroundColor: hex2rgba(ordersArr[0].color, alpha),
      };
    };

    renderProject = (day: WorkloadDayType) => {
      const { projects } = day;

      if (projects.length > 1) {
        return (
          <div className="flex-center gray-color">
            <GeneralIcon type="general-shuffle" />
            <span style={{ marginLeft: "3px" }}>{projects.length}</span>
          </div>
        );
      }

      if (projects.length === 1) {
        return <span className="gray-color">{projects[0]}</span>;
      }

      return empty;
    };

    onSelect = () => {
      const { day, onSelectCel } = this.props;
      onSelectCel(`${day.day}_${day.user.id}`);
    };

    onChangeStart = () => {
      // if (!selected) {
      //     this.onSelect();
      // }

      const { locked, onEdit } = this.props;
      !locked && onEdit(this.props.day);
      // const { onEdit } = this.props;
      // onEdit(this.props.day);
    };
  }
);

export interface DaySelector {
  selectedCell: string;
  onSelectCell: (cell: string) => void;
  changeComment: (model: any) => void;
  onContext: (x: number, y: number, day: WorkloadDayType) => void;
}

export type WorkloadCellMode = "hours" | "projects";

export interface WorkloadCellProps {
  day: WorkloadDayType;
  preToday: boolean;
  onEdit: (day: WorkloadDayType) => void;
  mode: WorkloadCellMode;
  locked: boolean;
  selectedCells: string[];
  selectedCell: string;
  onSelectCel: (cell: string) => void;
  orders: OrderDictionaryType;
  onContext: (x: number, y: number, day: WorkloadDayType) => void;
  nonProduction: boolean;
  changeComment: (model: any) => void;
}

interface WorkloadCellState {
  open: boolean;
  comment: string;
}
