import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { ProjectPortfolio, ProjectPortfolioType, ProjectPortfolioSnapshotType, fields } from "./project-portfolio";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
// import { getIn } from "formik";
// import { orderBy } from "lodash";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { Queryable } from "modules/common/models/queryable";

export const ProjectPortfolioList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            projectPortfolios: types.array(ProjectPortfolio),
            sorter: TableSorter,
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<ProjectPortfolioType> {
            return self.projectPortfolios.reduce((result, projectPortfolio) => {
                result[projectPortfolio.id] = projectPortfolio;
                return result;
            }, {} as TStringMap<ProjectPortfolioType>);
        },

        get data() {
            const predicate = (o: ProjectPortfolioType) => {
                let result = true;
                if (self.pureQuery) {
                    result = result && `${o.name} ${o.description}`.toLowerCase().indexOf(self.pureQuery) >= 0;
                }
                return result;
            };
            return self.projectPortfolios.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));
            // const order = self.sorter.asc ? 'asc' : 'desc';

            // return orderBy(
            //     getSnapshot(self.projectPortfolios),
            //     [(o) => getIn(o, self.sorter.column)],
            //     [order]
            // );
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: any = yield self.transport.get<ProjectPortfolioSnapshotType[]>(
                    apiUrls.projectPortfolios.list()
                );
                applySnapshot(self.projectPortfolios, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        delete: flow(function* (projectPortfolio: ProjectPortfolioSnapshotType) {
            const model = ProjectPortfolio.create(projectPortfolio, {
                http: self.transport,
                notificator: self.notify,
            });
            return yield model.delete();
        }),
    }))
    .named("ProjectPortfolioList");

export type ProjectPortfoliosListType = typeof ProjectPortfolioList.Type;
export type ProjectPortfoliosListSnapshotType = typeof ProjectPortfolioList.SnapshotType;
export { ProjectPortfolio };

const sortStorage = getSortOption(ProjectPortfolioList.name);

export const initialState = (): ProjectPortfoliosListSnapshotType => {
    const options = sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        projectPortfolios: [],
        pureQuery: "",
        query: "",
        sorter: {
            id: ProjectPortfolioList.name,
            tableName: ProjectPortfolioList.name,
            column: options.column,
            asc: options.asc,
        },
    };
};
