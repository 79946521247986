import React from "react";
import { Schema } from "yup";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { fields } from "../models/settings";
import styles from "./KpiSettings.module.scss";
import { onlyDigitsInputKeyPress } from "modules/common/services/form/values";
import { PercentInput } from "modules/common/components/form/PercentInput";
import { FieldProps } from "formik";

const digits = onlyDigitsInputKeyPress();

class KpiBase extends React.PureComponent<KpiProps> {
    render() {
        const { schema } = this.props;
        return (
            <div className="columns">
                <div className={styles.columns}>
                    <div className={styles.column}>
                        <StandardFormInput name={fields.productionQuality} schema={schema} small={true} inline={false}>
                            {this.fieldRenderer}
                        </StandardFormInput>

                        <div className={styles.row}>
                            <StandardFormInput
                                name={fields.productionBudget}
                                schema={schema}
                                small={true}
                                inline={false}
                                className={styles.full}
                            >
                                {this.fieldRenderer}
                            </StandardFormInput>

                            <StandardFormInput
                                name={fields.productionBudgetDelta}
                                schema={schema}
                                small={true}
                                inline={false}
                                className={styles.semicolumn}
                            >
                                {this.fieldRenderer}
                            </StandardFormInput>
                        </div>
                    </div>

                    <div className={styles.column}>
                        <StandardFormInput name={fields.engineerQuality} schema={schema} small={true} inline={false}>
                            {this.fieldRenderer}
                        </StandardFormInput>

                        <div className={styles.row}>
                            <StandardFormInput
                                name={fields.engineerBudget}
                                schema={schema}
                                small={true}
                                inline={false}
                                className={styles.full}
                            >
                                {this.fieldRenderer}
                            </StandardFormInput>

                            <StandardFormInput
                                name={fields.engineerBudgetDelta}
                                schema={schema}
                                small={true}
                                inline={false}
                                className={styles.semicolumn}
                            >
                                {this.fieldRenderer}
                            </StandardFormInput>
                        </div>

                        <div className={styles.row}>
                            <StandardFormInput
                                name={fields.engineerTime}
                                schema={schema}
                                small={true}
                                inline={false}
                                className={styles.full}
                            >
                                {this.fieldRenderer}
                            </StandardFormInput>

                            <StandardFormInput
                                name={fields.engineerTimeDelta}
                                schema={schema}
                                small={true}
                                inline={false}
                                className={styles.semicolumn}
                                label="Допуск (дней)"
                            >
                                {({ field }) => (
                                    <InputGroup
                                        id={field.name}
                                        {...field}
                                        className="planr-default-input"
                                        autoComplete="off"
                                        data-lpignore="true"
                                        onKeyPress={digits}
                                    />
                                )}
                            </StandardFormInput>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    fieldRenderer = ({ field, form }: FieldProps) => (
        <PercentInput
            onBlur={field.onBlur}
            id={field.name}
            name={field.name}
            value={field.value}
            className="planr-default-input"
            autoComplete="off"
            data-lpignore="true"
            onKeyPress={digits}
            onPercentChange={(p) => {
                form.setFieldValue(field.name, p.floatValue);
                form.setFieldTouched(field.name, true);
            }}
        />
    );
}

export const KpiSettings = observer(KpiBase);

interface KpiProps {
    schema: Schema<any>;
}
