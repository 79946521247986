import Schema from "../details/material-validation";
import { getColumnSize } from "modules/common/services/table/size-storage";
import { getFieldLabel } from "modules/common/services/form/fields";
import { MaterialValueType, fields } from "../../models/material-value";
import { EmployeeRenderer } from "modules/expenses/summary/components/columns";
import { fields as EmployeeFields } from "modules/spending/employee/models/employer";
import { fields as employerHistoryFields } from "modules/common/models/dictionary-link";
import { ROW_HEIGHT, DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import React from "react";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { AgGridReact } from "@ag-grid-community/react";
import { prevent } from "modules/common/services/form/select";
import { Popover } from "@blueprintjs/core";
import styles from "./MaterialValuesList.module.scss";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { gridMoneyFormatter } from "modules/common/components/money/Money";
import { texts } from "modules/common/texts";

const schema = Schema();

export const columns = (tableName: string) => {
    const columnSize = getColumnSize(tableName);

    const result: ColDef[] = [
        // RowNumberColumn(false, ""),
        {
            headerName: "Инв. номер",
            field: fields.fullInventoryNumber,
            width: columnSize(fields.fullInventoryNumber, 130),
            sortable: true,
            resizable: false,
        },
        {
            headerName: getFieldLabel(fields.name, schema, null),
            field: fields.name,
            width: columnSize(fields.name, 220),
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Сотрудник",
            field: fields.currentEmployeeName,
            width: columnSize(fields.currentEmployeeName, 250),
            sortable: true,
            resizable: true,
            cellRendererFramework: RenderEmployee,
        },
        {
            headerName: "Ост. стоим.",

            field: fields.actualSum,
            width: columnSize(fields.actualSum, 120),
            sortable: true,
            resizable: true,
            valueFormatter: gridMoneyFormatter,
        },
        {
            suppressMovable: true,
            headerName: "",
            field: "*",
            width: 80,
            cellClass: "centered flat",
            headerClass: "no-print no-print",
            cellRendererFramework:
                // RenderButton,
                (params: ICellRendererParams) => {
                    const getContext = () => params.context as TableContext;
                    return params.data.stopDate === null && params.data.isRemoved !== true ? (
                        <PlanrButton
                            type="secondary"
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();

                                getContext().setStop(params.data);
                            }}
                            style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                        >
                            Списать
                        </PlanrButton>
                    ) : params.data.stopDate !== null && params.data.isRemoved !== true ? (
                        <PlanrButton
                            type="greenish"
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                                getContext().setUnstoped(params.data);
                            }}
                            style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                        >
                            {texts.revert}
                        </PlanrButton>
                    ) : null;
                },
        },
        {
            suppressMovable: true,
            headerName: "",
            field: "*",
            width: 80,
            cellClass: "centered flat",
            headerClass: "no-print no-print",
            cellRendererFramework: (params: ICellRendererParams) => {
                const getContext = () => params.context as TableContext;
                return params.data.isRemoved !== true ? (
                    <PlanrButton
                        type="graybtn"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();

                            getContext().setRemoved(params.data);
                        }}
                        style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                    >
                        {texts.remove}
                    </PlanrButton>
                ) : (
                    <PlanrButton
                        type="greenish"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            getContext().setUnremoved(params.data);
                        }}
                        style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                    >
                        {texts.revert}
                    </PlanrButton>
                );
            },
        },
    ];

    return result;
};

export const stopColumns = (tableName: string) => {
    const columnSize = getColumnSize(tableName);

    const result: ColDef[] = [
        {
            headerName: "Инв. номер",
            field: fields.fullInventoryNumber,
            width: columnSize(fields.fullInventoryNumber, 125),
            sortable: true,
            resizable: false,
        },
        {
            headerName: getFieldLabel(fields.name, schema, null),
            field: fields.name,
            width: columnSize(fields.name, 210),
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Сотрудник",
            field: fields.currentEmployeeName,
            width: columnSize(fields.currentEmployeeName, 240),
            sortable: true,
            resizable: true,
            cellRendererFramework: RenderEmployee,
        },
        {
            headerName: "Ост. стоим.",
            field: fields.actualSum,
            width: columnSize(fields.actualSum, 120),
            sortable: true,
            resizable: true,
            valueFormatter: gridMoneyFormatter,
        },
        {
            headerName: "Дата списания",

            field: fields.stopDate,
            width: columnSize(fields.stopDate, 140),
            sortable: true,
            resizable: true,
        },
        {
            suppressMovable: true,
            headerName: "",
            field: "*",
            width: 80,
            cellClass: "centered flat",
            headerClass: "no-print no-print",
            cellRendererFramework: (params: ICellRendererParams) => {
                const getContext = () => params.context as TableContext;
                return params.data.stopDate === null && params.data.isRemoved !== true ? (
                    <PlanrButton
                        type="secondary"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();

                            getContext().setStop(params.data);
                        }}
                        style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                    >
                        Списать
                    </PlanrButton>
                ) : params.data.stopDate !== null && params.data.isRemoved !== true ? (
                    <PlanrButton
                        type="greenish"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            getContext().setUnstoped(params.data);
                        }}
                        style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                    >
                        {texts.revert}
                    </PlanrButton>
                ) : null;
            },
        },
        {
            suppressMovable: true,
            headerName: "",
            field: "*",
            width: 80,
            cellClass: "centered flat",
            headerClass: "no-print no-print",
            cellRendererFramework: (params: ICellRendererParams) => {
                const getContext = () => params.context as TableContext;
                return params.data.isRemoved !== true ? (
                    <PlanrButton
                        type="graybtn"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();

                            getContext().setRemoved(params.data);
                        }}
                        style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                    >
                        {texts.remove}
                    </PlanrButton>
                ) : (
                    <PlanrButton
                        type="greenish"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            getContext().setUnremoved(params.data);
                        }}
                        style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                    >
                        {texts.revert}
                    </PlanrButton>
                );
            },
        },
    ];

    return result;
};

export const columnsAll = (tableName: string) => {
    const columnSize = getColumnSize(tableName);

    const result: ColDef[] = [
        {
            headerName: "Инв. номер",
            field: fields.fullInventoryNumber,
            width: columnSize(fields.fullInventoryNumber, 120),
            sortable: true,
            resizable: false,
        },
        {
            headerName: getFieldLabel(fields.materialValueCategoryId, schema, null),
            field: fields.materialValueCategoryId,
            width: columnSize(fields.materialValueCategoryId, 200),
            sortable: true,
            resizable: true,
            cellRendererFramework: RenderCategory,
        },
        {
            headerName: "Группа аморт.",
            field: fields.depreciationGroupId,
            width: columnSize(fields.depreciationGroupId, 185),
            sortable: true,
            resizable: true,
            cellRendererFramework: RenderDepreciation,
        },
        {
            headerName: getFieldLabel(fields.name, schema, null),
            field: fields.name,
            width: columnSize(fields.name, 300),
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Сотрудник",
            field: fields.currentEmployeeName,
            width: columnSize(fields.currentEmployeeName, 290),
            sortable: true,
            resizable: true,
            cellRendererFramework: RenderEmployee,
        },
        {
            headerName: "Ост. стоим.",
            field: fields.actualSum,
            width: columnSize(fields.actualSum, 130),
            sortable: true,
            resizable: true,
            valueFormatter: gridMoneyFormatter,
        },
        {
            suppressMovable: true,
            headerName: "",
            field: "*",
            width: 80,
            cellClass: "centered flat",
            headerClass: "no-print no-print",
            cellRendererFramework: (params: ICellRendererParams) => {
                const getContext = () => params.context as TableContext;
                return params.data.stopDate === null && params.data.isRemoved !== true ? (
                    <PlanrButton
                        type="secondary"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();

                            getContext().setStop(params.data);
                        }}
                        style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                    >
                        Списать
                    </PlanrButton>
                ) : params.data.stopDate !== null && params.data.isRemoved !== true ? (
                    <PlanrButton
                        type="greenish"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            getContext().setUnstoped(params.data);
                        }}
                        style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                    >
                        {texts.revert}
                    </PlanrButton>
                ) : null;
            },
        },
        {
            suppressMovable: true,
            headerName: "",
            field: "*",
            width: 80,
            cellClass: "centered flat",
            headerClass: "no-print no-print",
            cellRendererFramework: (params: ICellRendererParams) => {
                const getContext = () => params.context as TableContext;
                return params.data.isRemoved !== true ? (
                    <PlanrButton
                        type="graybtn"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();

                            getContext().setRemoved(params.data);
                        }}
                        style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                    >
                        {texts.remove}
                    </PlanrButton>
                ) : (
                    <PlanrButton
                        type="greenish"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            getContext().setUnremoved(params.data);
                        }}
                        style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                    >
                        {texts.revert}
                    </PlanrButton>
                );
            },
        },
    ];

    return result;
};

export const stopColumnsAll = (tableName: string) => {
    const columnSize = getColumnSize(tableName);

    const result: ColDef[] = [
        {
            headerName: "Инв. номер",
            field: fields.fullInventoryNumber,
            width: columnSize(fields.fullInventoryNumber, 120),
            sortable: true,
            resizable: false,
        },
        {
            headerName: getFieldLabel(fields.materialValueCategoryId, schema, null),
            field: fields.materialValueCategoryId,
            width: columnSize(fields.materialValueCategoryId, 200),
            sortable: true,
            resizable: true,
            cellRendererFramework: RenderCategory,
        },
        {
            headerName: "Группа аморт.",
            field: fields.depreciationGroupId,
            width: columnSize(fields.depreciationGroupId, 185),
            sortable: true,
            resizable: true,
            cellRendererFramework: RenderDepreciation,
        },
        {
            headerName: getFieldLabel(fields.name, schema, null),
            field: fields.name,
            width: columnSize(fields.name, 300),
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Сотрудник",
            field: fields.currentEmployeeName,
            width: columnSize(fields.currentEmployeeName, 290),
            sortable: true,
            resizable: true,
            cellRendererFramework: RenderEmployee,
        },
        {
            headerName: "Ост. стоим.",
            field: fields.actualSum,
            width: columnSize(fields.actualSum, 130),
            sortable: true,
            resizable: true,
            valueFormatter: gridMoneyFormatter,
        },
        {
            headerName: "Дата спис.",

            field: fields.stopDate,
            width: columnSize(fields.stopDate, 100),
            sortable: true,
            resizable: true,
        },
        {
            suppressMovable: true,
            headerName: "",
            field: "*",
            width: 70,
            cellClass: "centered flat",
            headerClass: "no-print no-print",
            cellRendererFramework: (params: ICellRendererParams) => {
                const getContext = () => params.context as TableContext;
                return params.data.stopDate === null && params.data.isRemoved !== true ? (
                    <PlanrButton
                        type="secondary"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();

                            getContext().setStop(params.data);
                        }}
                        style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                    >
                        Списать
                    </PlanrButton>
                ) : params.data.stopDate !== null && params.data.isRemoved !== true ? (
                    <PlanrButton
                        type="greenish"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            getContext().setUnstoped(params.data);
                        }}
                        style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                    >
                        {texts.revert}
                    </PlanrButton>
                ) : null;
            },
        },
        {
            suppressMovable: true,
            headerName: "",
            field: "*",
            width: 70,
            cellClass: "centered flat",
            headerClass: "no-print no-print",
            cellRendererFramework: (params: ICellRendererParams) => {
                const getContext = () => params.context as TableContext;
                return params.data.isRemoved !== true ? (
                    <PlanrButton
                        type="graybtn"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();

                            getContext().setRemoved(params.data);
                        }}
                        style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                    >
                        {texts.remove}
                    </PlanrButton>
                ) : (
                    <PlanrButton
                        type="greenish"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            getContext().setUnremoved(params.data);
                        }}
                        style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                    >
                        {texts.revert}
                    </PlanrButton>
                );
            },
        },
    ];

    return result;
};

export interface TableContext {
    setRemoved: (materialValue: MaterialValueType) => Promise<void>;
    setUnstoped: (materialValue: MaterialValueType) => Promise<void>;
    setUnremoved: (materialValue: MaterialValueType) => Promise<void>;
    setStop: (materialValue: MaterialValueType) => Promise<void>;
}

class RenderEmployee extends React.PureComponent<ICellRendererParams, { value: any; active: boolean }> {
    render() {
        const data: MaterialValueType = this.props.data;

        const row =
            data.employeeHistory.length > 0 ? (
                <Popover position="auto" enforceFocus={false} isOpen={undefined}>
                    <span className="bp3-tooltip-indicator">
                        {data.currentEmployer?.id ? (
                            data.currentEmployer?.name
                        ) : !data.currentEmployer?.id && data.employeeHistory.length !== 0 ? (
                            <span style={{ color: "red" }}>Нет</span>
                        ) : (
                            "Нет"
                        )}
                    </span>

                    <CellDetails store={data} />
                </Popover>
            ) : (
                <span>
                    {data.currentEmployer?.id ? data.currentEmployer?.name : <span style={{ color: "red" }}>Нет</span>}
                </span>
            );

        return <div className="actual">{row}</div>;
    }
}

class RenderDepreciation extends React.PureComponent<ICellRendererParams, { value: any; active: boolean }> {
    render() {
        const positions: MaterialValueType = this.props.data;

        const data = positions;
        const row = <span>{data.depreciationGroup?.id ? data.depreciationGroup?.name : null}</span>;

        return <div className="actual">{row}</div>;
    }
}

class RenderCategory extends React.PureComponent<ICellRendererParams, { value: any; active: boolean }> {
    render() {
        const positions: MaterialValueType = this.props.data;

        const data = positions;
        const row = <span>{data.materialValueCategory?.id ? data.materialValueCategory?.name : null}</span>;

        return <div className="actual">{row}</div>;
    }
}

export const detailsColumns = (): ColDef[] => {
    return [
        {
            suppressMovable: true,
            headerName: "Сотрудник",
            field: EmployeeFields.name,
            width: 300,
            sortable: true,
            headerClass: "centered",
            cellRendererFramework: EmployeeRenderer,
        },
        {
            suppressMovable: true,
            headerName: "Дата с",
            field: employerHistoryFields.since,
            width: 100,
            sortable: true,
            headerClass: "centered",
        },
        {
            suppressMovable: true,
            headerName: "Дата по",
            field: employerHistoryFields.until,
            width: 100,
            sortable: true,
            headerClass: "centered",
        },
    ];
};
const options = DefaultAgGridOptions();
const columnsEmployee = detailsColumns();
const CellDetails = ({ store }: CellDetailsProps) => {
    return (
        <GridTheme
            className={`no-last-column-break vacation-details ${styles.cellDetails}`}
            onClick={prevent}
            onDoubleClick={prevent}
        >
            <AgGridReact
                rowStyle={{
                    borderBottom: "1 px solid #EDF0F2",
                    fontFamily: "GothamPro, Arial, sans-serif",
                    color: "#00273D",
                    fontSize: "12px",
                }}
                enableCellTextSelection={true}
                columnDefs={columnsEmployee}
                rowData={store.employeeHistory}
                headerHeight={ROW_HEIGHT}
                domLayout="autoHeight"
                gridOptions={options}
                modules={defaultModules}
                immutableData={true}
            />
        </GridTheme>
    );
};

interface CellDetailsProps {
    store: MaterialValueType;
}
