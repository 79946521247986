import { types } from "mobx-state-tree";
import { EmployeePositionList, EmployeePosition, initialState as emptyList } from "./employee-position-list";
import { initialState as emptyEmployeePosition } from "./employee-position";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { DepartmentDictionary } from "./department-dictionary";

export const EmployeePositionsStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            list: EmployeePositionList,
            details: EmployeePosition,
            departments: DepartmentDictionary,
        })
    )
    .actions((self) => ({
        init: function (employerId: string) {
            self.departments.load();
        },
    }))
    .named("EmployeePositionsStore");

export const initialState = (): typeof EmployeePositionsStore.SnapshotType => ({
    departments: { departments: [] },
    list: emptyList(),
    details: emptyEmployeePosition(),
});

export type EmployeePositionsStoreType = typeof EmployeePositionsStore.Type;
