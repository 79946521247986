import React from "react";
import { OrderConfirmStoreType } from "../models/store";
import { Page } from "modules/common/components/page/Page";
import { ConfirmsTab } from "./Tab";
import styles from "./OrderConfirmsPage.module.scss";
import { Tabs, Tab } from "@blueprintjs/core";
import { menuItems } from "modules/root/components/menu/MainMenu";
import { SessionType } from "modules/session/auth/models/session";
import { observer } from "mobx-react";
import func from "modules/orders-manage/functionalities";
import { can } from "modules/session/auth/access";

const menu = menuItems();

export const OrderConfirmsPage = observer(
  class extends React.PureComponent<OrderMailsPageProps> {
    constructor(props: OrderMailsPageProps) {
      super(props);

      props.store.load();
    }

    render() {
      const { store, session } = this.props;

      return (
        <Page className={styles.page}>
          <Tabs
            className={`${styles.tabs} planr-tabs-navigation`}
            id="OrderMailsPage"
            selectedTabId="1"
            renderActiveTabPanelOnly={true}
          >
            <Tab
              id="1"
              title={menu.orderConfirms.label}
              panel={
                <ConfirmsTab store={store} canManage={can(func.ORDERS_CONFIRM_EDIT, session.access)} isActiveTab />
              }
            />
          </Tabs>
        </Page>
      );
    }
  }
);

interface OrderMailsPageProps {
  store: OrderConfirmStoreType;
  session: SessionType;
}
