import { types, getSnapshot } from "mobx-state-tree";
import { flow } from "./flow";
import { Transport } from "./transport";
import { BaseEntity } from "./entity";
import { apiUrls } from "../services/communication/urls";
import { Notificator } from "./notificator";
import { base64ToBlob, isPdf, printPdf } from "../services/files";
import { saveAs } from "file-saver";

export const FileMetadata = types
  .compose(
    Transport,
    Notificator,
    BaseEntity,
    types.model({
      mimeType: types.string,
      name: types.string,
      content: types.maybeNull(types.string),
      previewMimeType: types.optional(types.string, ""),
      type: types.string,
      size: types.maybeNull(
        types.model({
          width: types.number,
          height: types.number,
        })
      ),
    })
  )
  .views((self) => ({
    get canBeViewed() {
      return !!self.size;
    },

    get asFileBase(): FileBase {
      return {
        fileId: self.id,
        fileName: self.name,
        mimeType: self.mimeType,
        previewMimeType: self.previewMimeType,
      };
    },
  }))
  .actions((self) => ({
    update(data: DownloadFileResult) {
      self.mimeType = data.mimeType;
      self.content = data.content;
      self.size = data.size;
    },
  }))
  .actions((self) => ({
    download: flow(function* () {
      try {
        const content: DownloadFileResult = self.content
          ? getSnapshot(self)
          : yield self.transport.post<any>(apiUrls.application.files.download, {
              ids: [self.id],
            });

        self.update(content);

        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
    print: flow(function* () {
      try {
        const content: DownloadFileResult = yield self.transport.post<any>(apiUrls.application.files.print, {
          ids: [self.id],
        });

        if (content) {
          const blob: any = yield base64ToBlob(content.content, content.mimeType);
          const fileURL = URL.createObjectURL(blob);
          const printer = printPdf(fileURL, true);
          if (printer) {
            printer.onclose = () => URL.revokeObjectURL(fileURL);
          }

          return true;
        }

        return false;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
    view: flow(function* () {
      const blob: any = yield base64ToBlob(self.content || "", self.mimeType);
      const fileURL = URL.createObjectURL(blob);

      const viewer = window.open(fileURL, "_blank", "");
      if (viewer) {
        viewer.onclose = () => URL.revokeObjectURL(fileURL);
      }
    }),
  }))
  .actions((self) => ({
    dowloadForPreview: flow(function* () {
      if (isPdf(self.previewMimeType || "")) {
        return true;
      }

      return yield self.download();
    }),

    save: flow(function* () {
      try {
        const success = yield self.download();
        if (!success) {
          throw new Error("Ошибка скачивания файла");
        }

        const blob: any = yield base64ToBlob(self.content || "", self.mimeType);
        saveAs(blob, self.name);

        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
  }))
  .named("FileMetadata");

export type FileMetadataType = typeof FileMetadata.Type;
export type FileMetadataSnapshotType = typeof FileMetadata.SnapshotType;

export const asFileBase = (doc: FileMetadataSnapshotType): FileBase => ({
  fileId: doc.id,
  fileName: doc.name,
  mimeType: doc.mimeType,
  previewMimeType: doc.previewMimeType,
});
