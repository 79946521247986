import { types } from 'mobx-state-tree';
import { CompanyBankDetailsList, CompanyBankDetails, initialState as emptyList } from './bank-details-list';
import { initialState as emptyCompanyBankDetails } from './bank-details';
import { Transport } from 'modules/common/models/transport';
import { Notificator } from 'modules/common/models/notificator';

export const CompanyBankDetailsStore = types.compose(
    Transport, Notificator, types.model({
        list: CompanyBankDetailsList,
        details: CompanyBankDetails
    }))
    .actions((self) => ({
        init: function (detailsId: string) {
            self.details.load(detailsId);
            self.list.load();
        }
    }))
    .named('CompanyBankDetailsStore');

export const initialState = (): typeof CompanyBankDetailsStore.SnapshotType => ({
    list: emptyList(),
    details: emptyCompanyBankDetails()
});

export type CompanyBankDetailsStoreType = typeof CompanyBankDetailsStore.Type;
