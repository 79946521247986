import React from "react";
import { Formik, Form } from "formik";
import styles from "./ProductionStageDetails.module.scss";
import ProductionStageSchema from "./validation";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { InputGroup, TextArea, Classes } from "@blueprintjs/core";
import { ProductionStageType, fields } from "modules/dictionaries/production-stages/models/production-stage";
import { fixTextAreaHeight, MODAL_AWAIT_DELAY } from "modules/common/services/form/textarea";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { Schema } from "yup";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { texts } from "modules/common/texts";
import { Prompt } from "react-router";

class ProductionStageDetailsBase extends React.PureComponent<ProductionStageDetailsProps, ProductionStageState> {
  private schema: Schema<any>;
  private commentArea: HTMLTextAreaElement | null = null;

  constructor(props: ProductionStageDetailsProps) {
    super(props);

    this.schema = ProductionStageSchema();
    this.state = {
      isMobile: false,
    };
  }

  private commentAreaRef = (ref: HTMLTextAreaElement | null) => {
    this.commentArea = ref;
  };

  handleResize = () => {
    if (window.innerWidth < 885) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  private resize = () => {
    fixTextAreaHeight(this.commentArea);
  };

  public componentDidMount() {
    window.addEventListener("resize", this.handleResize);

    setTimeout(this.resize, MODAL_AWAIT_DELAY);
  }

  public componentDidUpdate() {
    this.resize();
  }

  componentWillMount() {
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const { schema, props } = this;
    const { productionStage, onSaved, onRemoved, canChange } = props;
    const isMobile = this.state.isMobile;

    return (
      <div className={styles.details}>
        <Formik
          initialValues={getFormValues(productionStage)}
          enableReinitialize={true}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            if (!canChange) {
              return;
            }

            const succsessfull = await productionStage.save(values);
            setSubmitting(false);

            if (succsessfull) {
              resetForm();
              onSaved();
            }
          }}
        >
          {(formProps) => {
            const onRemove = async () => {
              formProps.setSubmitting(true);
              const success = await productionStage.delete();
              formProps.setSubmitting(false);
              success && onRemoved();
            };

            return (
              <Form autoComplete="off">
                <Prompt when={formProps.dirty && canChange} message={texts.messages.leaveConfiramtion} />
                <div className={Classes.DIALOG_BODY}>
                  <StandardFormInput
                    name={fields.name}
                    schema={schema}
                    small={true}
                    inline={!isMobile}
                    className="planr-form-input"
                  >
                    {({ field }) => (
                      <InputGroup
                        id={field.name}
                        {...field}
                        className="planr-default-input"
                        autoComplete="off"
                        data-lpignore="true"
                        disabled={!canChange}
                      />
                    )}
                  </StandardFormInput>

                  <StandardFormInput
                    name={fields.comment}
                    schema={schema}
                    small={true}
                    inline={!isMobile}
                    className="planr-form-input"
                  >
                    {({ field }) => (
                      <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                        <TextArea
                          id={field.name}
                          {...field}
                          growVertically={true}
                          className={Classes.FILL}
                          inputRef={this.commentAreaRef}
                          disabled={!canChange}
                        />
                      </div>
                    )}
                  </StandardFormInput>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                  {canChange && (
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                      <StandardFormButtons
                        {...formProps}
                        isRemoved={false}
                        what={`стадию строительства ${productionStage.name}`}
                        isNewlyCreated={productionStage.isNewlyCreated}
                        onRemove={onRemove}
                      />
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export const ProductionStageDetails = observer(ProductionStageDetailsBase);

interface ProductionStageDetailsProps {
  productionStage: ProductionStageType;
  onSaved: () => void;
  onRemoved: () => void;
  canChange: boolean;
}

interface ProductionStageState {
  isMobile: boolean;
}

function getFormValues(productionStage: ProductionStageType) {
  return {
    ...getSnapshot(productionStage),
  };
}
