import React from "react";

import { MenuItem } from "@blueprintjs/core/lib/esm/components";
import { texts } from "modules/common/texts";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

export const ContextMenu = ({ xpos, ypos, onCopy, onPaste, onClear }: WeekDaysProps) => {
    return (
        <div
            style={{
                position: "absolute",
                background: "#fff",
                borderRadius: "4px",
                boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
                left: xpos,
                top: ypos,
                zIndex: 1000,
                listStyleType: "none",
                color: "#00273D",
            }}
        >
            <MenuItem text={texts.copy} icon={<GeneralIcon type="general-copy" />} onClick={onCopy} />
            <MenuItem text={"Вставить"} icon={<GeneralIcon type="general-doc-check" />} onClick={onPaste} />
            {onClear && (
                <MenuItem
                    text={"Очистить"}
                    icon={<GeneralIcon type="general-trash" />}
                    onClick={onClear}
                    style={{ color: "#E31818" }}
                />
            )}
        </div>
    );
};

interface WeekDaysProps {
    xpos: string;
    ypos: string;
    onCopy: () => void;
    onPaste: () => void;
    onClear?: () => void;
}
