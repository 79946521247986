import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";

am4core.options.commercialLicense = true;

const SCROLL_DELAY = 300;

function gradient(opacity = 0.4) {
    const fillModifier = new am4core.LinearGradientModifier();
    fillModifier.opacities = [opacity, 0];
    fillModifier.offsets = [0, 1];
    fillModifier.gradient.rotation = 90;
    return fillModifier;
}

function customizeGrip(grip: am4core.ResizeButton) {
    grip.icon.disabled = true;

    grip.background.fill = am4core.color("#c00");
    grip.background.fillOpacity = 0.5;
    grip.background.disabled = true;

    var img = grip.createChild(am4core.RoundedRectangle);
    img.cornerRadiusBottomLeft = img.cornerRadiusBottomRight = img.cornerRadiusTopLeft = img.cornerRadiusTopRight = 4;
    img.width = 8;
    img.height = 16;
    img.fill = am4core.color("#8698A3");
    img.fillOpacity = 1;
    img.align = "center";
    img.valign = "middle";

    var line = grip.createChild(am4core.Rectangle);
    line.height = 36;
    line.width = 2;
    line.fill = am4core.color("#8698A3");
    line.fillOpacity = 1;
    line.align = "center";
    line.valign = "middle";

    var stripe1 = grip.createChild(am4core.Rectangle);
    stripe1.height = 1;
    stripe1.width = 4;
    stripe1.fill = am4core.color("#00273D");
    stripe1.fillOpacity = 0.3;
    stripe1.align = "center";
    stripe1.valign = "middle";

    var stripe2 = grip.createChild(am4core.Rectangle);
    stripe2.height = 1;
    stripe2.width = 4;
    stripe2.fill = am4core.color("#00273D");
    stripe2.fillOpacity = 0.3;
    stripe2.marginTop = 3;
    stripe2.align = "center";

    var stripe3 = grip.createChild(am4core.Rectangle);
    stripe3.height = 1;
    stripe3.width = 4;
    stripe2.fill = am4core.color("#00273D");
    stripe3.fillOpacity = 0.3;
    stripe3.marginTop = -4;
    stripe3.align = "center";
}

function customizeScrollbar(scrollbarX: am4charts.XYChartScrollbar) {
    customizeGrip(scrollbarX.startGrip);
    customizeGrip(scrollbarX.endGrip);

    scrollbarX.background.fill = am4core.color("#F7F9FA");
    scrollbarX.background.fillOpacity = 1;
    scrollbarX.thumb.background.fill = am4core.color("#00273D");
    scrollbarX.thumb.background.fillOpacity = 0.07;
    scrollbarX.unselectedOverlay.fillOpacity = 0;
    scrollbarX.scrollbarChart.plotContainer.filters.clear();
    scrollbarX.minHeight = 36;

    const series = scrollbarX.scrollbarChart.series.getIndex(0);
    if (series) {
        series.filters.clear();
        series.fillOpacity = 0.1;
        series.strokeOpacity = 0.1;
    }

    const scrollAxis = scrollbarX.scrollbarChart.xAxes.getIndex(0);
    if (scrollAxis) {
        scrollAxis.renderer.labels.template.disabled = true;
        scrollAxis.renderer.grid.template.disabled = true;
    }
}

const createScrollSeries = (chart: am4charts.XYChart, field: string, category: string, name: string, color: string) => {
    const paretoSeries = chart.series.push(new am4charts.LineSeries());
    paretoSeries.dataFields.valueY = field;
    paretoSeries.dataFields.categoryX = category;
    paretoSeries.name = name;
    paretoSeries.strokeWidth = 1;
    paretoSeries.stroke = am4core.color(color);
    paretoSeries.strokeOpacity = 0;
    paretoSeries.fill = am4core.color(color);
    paretoSeries.fillOpacity = 0;
    paretoSeries.hiddenInLegend = true;

    return paretoSeries;
};

const blackTooltip = (tooltip: am4core.Tooltip) => {
    tooltip.getFillFromObject = false;
    tooltip.background.fill = am4core.color("#00273D");
    tooltip.label.wrap = false;
    tooltip.background.stroke = am4core.color("#00273D");
};

const tooltipTemplate = (color: string, title = "{period}", value = "{valueY}", width = 110) => {
    return (
        `
    <div class="planr-chart-tooltip" style="width: ${width}px">
        <strong>
            <span class="planr-chart-tooltip_bullet" style="background-color: ${color}"></span>
            ` +
        title +
        `
        </strong>
        <div>` +
        value +
        `</div>
    </div>
`
    );
};

const customizeBulletTooltip = (
    bullet: am4charts.Bullet,
    color: string,
    title = "{period}",
    value = "{valueY}",
    width = 110
) => {
    bullet.tooltipHTML = tooltipTemplate(color, title, value, width);
    if (!bullet.tooltip) {
        bullet.tooltip = new am4core.Tooltip();
    }

    blackTooltip(bullet.tooltip);
};

const customizeSeriesTooltip = (
    series: am4core.ListTemplate<any>,
    color: string,
    title = "{period}",
    value = "{valueY}",
    width = 110
) => {
    series.template.tooltipHTML = tooltipTemplate(color, title, value, width);
    if (!series.template.tooltip) {
        series.template.tooltip = new am4core.Tooltip();
    }

    blackTooltip(series.template.tooltip);
};

export {
    am4lang_ru_RU,
    am4core,
    am4charts,
    gradient,
    customizeScrollbar,
    createScrollSeries,
    customizeBulletTooltip,
    customizeSeriesTooltip,
    blackTooltip,
    SCROLL_DELAY,
};
