import React from "react";
import { Intent } from "@blueprintjs/core";
import { Asterisk } from "modules/common/components/form";
import { EventDateType } from "modules/orders-manage/models/order-events";
import { OrderStageRowItem, PlanOrderPaymentDetailsType } from "modules/orders-manage/models/order-stages-store";
import { formatMoney } from "modules/common/components/money/Money";
import { OutsourcerPaymentType } from "modules/agents/outsourcers/models/outsourcer-payment";

export interface CommonBusinessEventProps {
  readonly: boolean;
  event: EventDateType;
  payments: PlanOrderPaymentDetailsType[];
  spendings: OutsourcerPaymentType[];
  stages: OrderStageRowItem[];
  isNotStateChange?: (field: string, val: any) => void;
}

export const Error: Intent = "danger";
export const star = <Asterisk isRequired={true} />;

export const stageLabel = (stage: OrderStageRowItem) => {
  let result = "Этап № " + (stage.index + 1) + " на сумму " + formatMoney(stage.stage.sum);

  return result;
};
