import func from "../functionalities";
import { fields } from "modules/orders-manage/models/order";
import { can } from "modules/session/auth/access";
import { ClientFunctionalities } from "modules/agents/clients/models/client";

export const AccessValidationMap = {
    [func.ORDERS_SUMMARY_EDIT]: [
        fields.color,
        fields.fullInventoryNumber,
        fields.orderStatusId,
        fields.clientId,
        fields.engineerId,
        fields.fullName,
        fields.name,
        fields.stopDatePlan,
        fields.stopDateActual,
        fields.portfolioId,
        fields.orderTypeId,
        fields.productionStageId,
        fields.objects,
        fields.orderNumber,
        fields.startDate,
        fields.premiumPercents,
        fields.planProductionSpendingsPercents,
        fields.finValuesActualOverheadPercents,
        //   fields.consignments,
        fields.additions,
    ],
    [func.ORDERS_CONTENTS_EDIT]: [fields.objects],
    [(func.ORDERS_IPD_EDIT, ClientFunctionalities.CLIENTS_USER_ACCESS)]: [fields.orderIpds],

    [func.ORDERS_SPENDING_EDIT]: [
        fields.outsourcedOrderSpendins,
        fields.tripOrderSpendings,
        fields.otherOrderSpendings,
        fields.ownOrderSpendings,
        fields.premiumGipPercents,
        fields.premiumNonProductionPercents,
        fields.premiumPercents,
        fields.premiumProductionPercents,
        fields.finValuesPlanOverheadPercents,
    ],
};

export const validateField = (access: string[], field: string) => {
    const functionality = Object.keys(AccessValidationMap).find((func) => {
        return AccessValidationMap[func].includes(field);
    });

    if (functionality) {
        return can(functionality, access);
    }

    return true;
};
