import React from "react";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Block } from "modules/common/components/page/Block";
import { Route, withRouter, RouteComponentProps, matchPath } from "react-router";
import { routes } from "modules/common/routes";
import { UnregisterCallback } from "history";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { ProductionStagesStoreType } from "../models/production-stages-store";
import { ProductionStageDetails } from "./details/ProductionStageDetails";
import { ProductionStagesList } from "./list/ProductionStagesList";
import { menuItems } from "modules/root/components/menu/MainMenu";
import func from "modules/dictionaries/functionalities";
import { can } from "modules/session/auth/access";

import { PlanrButton } from "modules/common/components/planr/button/Button";
import styles from "./ProductionStagesPage.module.scss";
import { Search } from "modules/common/components/form/Search";

const menu = menuItems();

class ProductionStagesPage extends React.Component<
  ProductionStagesPageProps & RouteComponentProps,
  ProductionStagesPageState
> {
  private unregister: UnregisterCallback | null = null;

  constructor(props: ProductionStagesPageProps & RouteComponentProps) {
    super(props);

    this.state = { stageId: getSelectedProductionStageId(props.location.pathname) };
  }

  componentDidMount() {
    document.title = menu.dictionaries.nested.productionStages.label;

    const { history } = this.props;
    const { stageId } = this.state;

    this.loadList();
    this.loadDetails(stageId);

    this.unregister = history.listen((location) => {
      const stageId = getSelectedProductionStageId(location.pathname);

      if (this.state.stageId !== stageId) {
        this.setState({ stageId });
        this.loadDetails(stageId);
      }
    });
  }

  componentWillUnmount() {
    this.unregister && this.unregister();
  }

  render() {
    const { store, access } = this.props;
    const canChange = can(func.PRODUCTIONSTAGE_EDIT, access);

    return (
      <Block columned={true} className={styles.page}>
        <Route path={routes.dictionaries.productionStage.details()}>
          <DetailsPopup title="Стадия строительства" onCancel={this.onProductionStageSelected}>
            <ProductionStageDetails
              canChange={canChange}
              productionStage={store.details}
              onSaved={this.onProductionStageSaved}
              onRemoved={this.onProductionStageRemoved}
            />
          </DetailsPopup>
        </Route>
        <div className={styles.tools}>
          {canChange && (
            <PlanrButton
              type="secondary"
              size="small"
              icon="general-plus-big"
              onClick={this.onProductionStageAdd}
              style={{
                width: "310px",
                marginBottom: "32px",
                borderRadius: "120px",
              }}
            >{`${texts.add} стадию строительства`}</PlanrButton>
          )}
        </div>
        <div className={styles.mobileTools}>
          {canChange && (
            <PlanrButton type="secondary" round icon="general-plus-big" onClick={this.onProductionStageAdd} />
          )}
        </div>
        <div className={styles.right}>
          <Search query={store.list.query} onSearch={store.list.setQuery} />
        </div>
        <ProductionStagesList
          store={store.list}
          onSelected={this.onProductionStageSelected}
          onRemoved={this.onProductionStageRemoved}
        />
      </Block>
    );
  }

  onProductionStageAdd = () => {
    this.onProductionStageSelected(EMPTY_OBJECT_ID);
  };

  onProductionStageSelected = (id: string = "") => {
    const { history } = this.props;

    const url = id ? routes.dictionaries.productionStage.details(id) : routes.dictionaries.productionStage.path;

    history.push(url);
  };

  onProductionStageSaved = () => {
    this.onProductionStageSelected("");
    this.loadList();
  };

  onProductionStageRemoved = () => {
    const { history } = this.props;

    this.loadList();

    const url = routes.dictionaries.productionStage.path;
    history.push(url);
  };

  loadList = () => this.props.store.list.load();

  loadDetails = (id: string) => this.props.store.details.load(id);
}

export default withRouter(observer(ProductionStagesPage));

function getSelectedProductionStageId(pathname: string) {
  const match = matchPath<ProductionStagesPageState>(pathname, routes.dictionaries.productionStage.details());

  return match && match.params ? match.params.stageId : "";
}

interface ProductionStagesPageProps {
  store: ProductionStagesStoreType;
  access: string[];
}

interface ProductionStagesPageState {
  stageId: string;
}
