import { types, applySnapshot } from 'mobx-state-tree';
import { Transport } from 'modules/common/models/transport';
import { Notificator } from 'modules/common/models/notificator';
import { flow } from 'modules/common/models/flow';
import { apiUrls } from 'modules/common/services/communication/urls';
import { IdEntity } from 'modules/common/models/entity';

const MoneyAccount = types.compose(IdEntity, types.model({
    name: types.string,
    sum: types.number,
    lastModifiedDateTime: types.string,
    sortOrder: types.number
}))
.views((self) => ({
    get title () {
        return `Изменено: ${self.lastModifiedDateTime}`;
    }
}))
.named('MoneyAccount');

export const MoneyAccountStore = types.compose(Transport, Notificator, types.model({
    accounts: types.array(MoneyAccount),
    loading: types.boolean
}))
.actions((self) => ({
    load: flow(function* (){
        try {
            const accounts: any[] = yield self.transport.get<any>(apiUrls.dashboard.accounts());

            applySnapshot(self.accounts, accounts);

            return true;
        } catch (er) {
            self.notify.error(er);
            return false;
        }
    }),

    save: flow(function* (account: MoneyAccountType, value: string){
        if (self.loading) {
            return;
        }

        self.loading = true;

        try {
            const data: any = yield self.transport.post<any>(apiUrls.dashboard.accounts(account.id), {
                sum: parseFloat(value)
            });

            applySnapshot(account, data);

            self.loading = false;
            return true;
        } catch (er) {
            self.loading = false;
            self.notify.error(er);
            return false;
        }
    }),
}))
.views((self) => ({
    get isEmpty () {
        return !self.accounts.length;
    }
}))
.named('MoneyAccountStore');

export type MoneyAccountType = typeof MoneyAccount.Type;
export type MoneyAccountStoreType = typeof MoneyAccountStore.Type;
export type MoneyAccountStoreSnapshotType = typeof MoneyAccountStore.SnapshotType;

export const initialState = (): MoneyAccountStoreSnapshotType => ({
    accounts: [],
    loading: false
});
