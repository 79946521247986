import { deprGroupLabels } from "../details/depr-group-validation";
import { getColumnSize } from "modules/common/services/table/size-storage";
import { fields } from "modules/dictionaries/inventory/models/depr-group";
import { ColDef } from "@ag-grid-community/core";

const labels = deprGroupLabels();

export const columns = (tableName: string) => {
    const columnSize = getColumnSize(tableName);

    const result: ColDef[] = [
        {
            headerName: labels[fields.name],
            field: fields.name,
            width: columnSize(fields.name, 350),
            sortable: true,
            resizable: false,
        },
        {
            headerName: labels[fields.minYears],
            field: fields.minYears,
            width: columnSize(fields.minYears, 200),
            sortable: true,
            resizable: false,
        },
        {
            headerName: labels[fields.maxYears],
            field: fields.maxYears,
            width: columnSize(fields.maxYears, 200),
            sortable: true,
            resizable: false,
        },
        {
            headerName: labels[fields.minLifetime],
            field: fields.minLifetime,
            width: columnSize(fields.minLifetime, 200),
            sortable: true,
            resizable: false,
        },
        {
            headerName: labels[fields.maxLifetime],
            field: fields.maxLifetime,
            width: columnSize(fields.maxLifetime, 200),
            sortable: true,
            resizable: false,
        },
    ];

    return result;
};
