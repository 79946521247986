import React from "react";
import styles from "./TotalLine.module.scss";
import { formatMoney } from "modules/common/components/money/Money";
import { formatHours } from "modules/spending/timesheet/models/user-timesheet";

export const TotalLine: React.FC<TotalLineProps> = ({
    children,
    store,
    plan,
    bonusTaxeHidden,
    sortColumn,
    showMinutes,
}) => {
    let workedOutHours = 0;
    let overtimeHours = 0;
    let hours = 0;
    let sum = 0;
    let paidSum = 0;
    store.forEach((data: any) => {
        workedOutHours += data.workedOutHours;
        overtimeHours += data.overtimeHours;
        hours += data.hours;
        sum += data.sum;
        paidSum += data.paidMoney;
    });
    return (
        <div>
            <div className={`total-line ${styles.line} `}>
                <div className={`color-gray`} style={{ position: "absolute", left: "500px", width: "80px" }}>
                    {showMinutes ? formatHours(workedOutHours) : Math.round(workedOutHours) + "ч"}
                </div>

                <div className={`color-gray`} style={{ position: "absolute", left: "600px", width: "80px" }}>
                    {showMinutes ? formatHours(overtimeHours) : Math.round(overtimeHours) + "ч"}
                </div>

                <div className={`color-gray`} style={{ position: "absolute", left: "700px", width: "110px" }}>
                    {showMinutes ? formatHours(hours) : Math.round(hours) + "ч"}
                </div>

                <div className={`color-green`} style={{ position: "absolute", left: "1115px", width: "110px" }}>
                    {formatMoney(sum)}
                </div>
                <div className={`color-green`} style={{ position: "absolute", left: "1240px", width: "110px" }}>
                    {formatMoney(paidSum)}
                </div>
                <div className={`green-cell`}></div>
            </div>
            <div className={`total-line ${styles.lineHeader} `}>
                <div
                    style={{ position: "absolute", left: "62px", width: "200px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("name");
                    }}
                >
                    ФИО
                </div>
                <div
                    style={{ position: "absolute", left: "342px", width: "80px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("salary");
                    }}
                >
                    ЗП
                </div>
                <div
                    style={{ position: "absolute", left: "442px", width: "80px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("salaryFactor");
                    }}
                >
                    Кзп
                </div>
                <div
                    style={{ position: "absolute", left: "502px", width: "80px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("workedOutHours");
                    }}
                >
                    Раб. часы
                </div>
                <div
                    style={{ position: "absolute", left: "602px", width: "80px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("overtimeHours");
                    }}
                >
                    Сверх ур.
                </div>
                <div
                    style={{ position: "absolute", left: "702px", width: "120px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("hours");
                    }}
                >
                    Раб. сверх ур.
                </div>
                <div
                    style={{ position: "absolute", left: "832px", width: "80px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("hoursFactor");
                    }}
                >
                    Куч
                </div>
                <div
                    style={{ position: "absolute", left: "892px", width: "80px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("factor");
                    }}
                >
                    Столбец10
                </div>
                <div
                    style={{ position: "absolute", left: "1002px", width: "100px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("workPercent");
                    }}
                >
                    % от премии
                </div>
                <div
                    style={{ position: "absolute", left: "1122px", width: "80px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("sum");
                    }}
                >
                    Сумма
                </div>
                <div
                    style={{ position: "absolute", left: "1240px", width: "170px", cursor: "pointer" }}
                    onClick={() => {
                        sortColumn("paidMoney");
                    }}
                >
                    Денежные ср-ва
                </div>
            </div>
        </div>
    );
};

interface TotalLineProps {
    bonusTaxeHidden?: boolean;
    store: any;
    plan?: boolean;
    sortColumn: (id: string) => void;
    showMinutes: boolean;
}
