import { types, applySnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { isNewlyCreated, IdEntity } from "modules/common/models/entity";

export const EmployerPremiumDetails = types
    .compose(
        IdEntity,
        types.model({
            fullInventoryNumber: types.string,
            name: types.string,
            sum: types.number,
            orderId: types.string,
            paidSum: types.number,
            remainsSum: types.number,
        })
    )
    .named("EmploerPremiumDetails");

export const EmployerPremiums = types
    .compose(
        Transport,
        Notificator,
        types.model({
            id: types.string,
            rows: types.array(EmployerPremiumDetails),
        })
    )
    .views((self) => ({
        get isEmpty() {
            return self.rows.length === 0;
        },

        get totals() {
            const result = {
                sum: 0,
                paidSum: 0,
                remainsSum: 0,
            };

            self.rows.forEach((row) => {
                result.sum += row.sum;
                result.paidSum += row.paidSum;
                result.remainsSum += row.remainsSum;
            });

            return result;
        },
    }))
    .actions((self) => ({
        load: flow(function* (id: string) {
            try {
                const snapshot = isNewlyCreated(id) ? [] : yield self.transport.get<any>(apiUrls.employee.premiums(id));

                self.id = id;
                applySnapshot(self.rows, snapshot);
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .named("EmployerPremiums");

export type EmployerPremiumDetailsType = typeof EmployerPremiumDetails.Type;
export type EmployerPremiumsType = typeof EmployerPremiums.Type;

export const initialState = (): typeof EmployerPremiums.SnapshotType => ({
    rows: [],
    id: "",
});
