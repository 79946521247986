export const AppVersion = () => {
    const meta: HTMLMetaElement | null = document.querySelector('meta[name="build"]');
    return meta ? meta.content : "";
};

/**
 * Создать HTMLElement из разметки
 */
export function htmlToElement(html: string) {
    var template = document.createElement("template");
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;

    return template.content.firstChild as HTMLElement;
}

/**
 * Получить разметку страницы, пригодную для растеризации элементов
 */
export async function getCleanPageLayout(anchor = "***ELEMENT_ANCHOR***") {
    // embed external styles
    const externalStyles = document.querySelectorAll("link[rel=stylesheet]");
    if (externalStyles.length > 0) {
        let layout = document.documentElement.innerHTML;
        // add special anchor for backend
        let newLayout = layout.replace(document.body.innerHTML, anchor);

        const fake: HTMLHtmlElement = document.createElement("html");
        fake.innerHTML = newLayout;
        fake.lang = document.documentElement.lang;

        const links: NodeListOf<HTMLLinkElement> = fake.querySelectorAll("link[rel=stylesheet]");
        const promises = Array.from(links).map((link) => {
            return fetch(link.href)
                .then((css) => css.text())
                .then((text) => {
                    let style = document.createElement("style");
                    style.innerHTML = text;
                    fake.querySelector("head")?.appendChild(style);

                    link.remove();
                });
        });

        await Promise.all(promises);
        return fake.outerHTML;
    } else {
        let layout = document.documentElement.innerHTML;
        // add special anchor for backend
        return layout.replace(document.body.innerHTML, anchor);
    }
}

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
