import React, { useCallback, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { InvisibleSubmit } from "modules/common/components/form";
import { CollaboraMessage } from "./collabora-api";

let BaseUrl = process.env.REACT_APP_WOPI_URL;

interface CollaboraFrameProps {
    onMessage: (e: CollaboraEvent) => void;
    id: string;
    style?: React.CSSProperties;
    children?: any;
}

export const CollaboraFrame = ({ onMessage, id, style, children }: CollaboraFrameProps) => {
    const frameRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        const messageHandler = (e: any) => {
            if (typeof e.data === "string" && frameRef.current) {
                try {
                    const data: CollaboraMessage = JSON.parse(e.data);
                    onMessage({ ...data, frame: frameRef.current });
                } catch (er) {
                    // nothing
                }
            }
        };

        window.addEventListener("message", messageHandler, false);

        return () => {
            window.removeEventListener("message", messageHandler, false);
        };
    }, [onMessage]);

    return (
        <div style={Object.assign({ position: "relative", width: "100%", height: "100%" }, style)}>
            <iframe
                name={id}
                title="NP"
                allowFullScreen={true}
                style={{ width: "100%", height: "100%", border: "0px" }}
                ref={frameRef}
            />
            {children}
        </div>
    );
};

export interface CollaboraLoaderProps {
    editorUrl: string;
    apiUrl: string;
    wopiToken: string;
    container: HTMLDivElement | null;
    id: string;
}

export const CollaboraLoader = ({ container, wopiToken, editorUrl, id, apiUrl }: CollaboraLoaderProps) => {
    const el = useRef(document.createElement("DIV"));
    const submitRef = useRef<HTMLButtonElement>(null);
    const stop = useCallback((e: any) => e.stopPropagation(), []);

    useEffect(() => {
        const element = el.current;

        if (container) {
            container.appendChild(element);
        }

        if (submitRef.current) {
            submitRef.current.click();
        }

        return () => {
            if (container) {
                container.removeChild(element);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const wopiSrc = (BaseUrl || window.location.origin) + "/api" + apiUrl;
    const url = `${editorUrl}WOPISrc=${wopiSrc}`;

    const form = () => (
        <form name="loleafletform" target={id} id={id} action={url} method="post" onSubmit={stop}>
            <input name="access_token" value={wopiToken} type="hidden" />
            <input name="ui_defaults" value="UIMode=classic;TextSidebar=false,SpreadsheetSidebar=false" type="hidden" />
            <InvisibleSubmit innerRef={submitRef} />
        </form>
    );

    return container ? ReactDOM.createPortal(form(), el.current) : form();
};

export type CollaboraEvent = CollaboraMessage & { frame: HTMLIFrameElement };
