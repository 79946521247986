import moment from "moment";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "modules/common/constants";
import { toString } from "../strings";

export const formatDate = (date?: Date | null, format = DATE_FORMAT) => {
    if (!date) {
        return "";
    }

    if (!(date instanceof Date)) {
        return toString(date);
    }

    return moment(date).format(format);
};

export const formatDateTime = (date?: Date | null) => {
    return date ? moment(date).format(DATE_TIME_FORMAT) : "";
};

export const parseDate = (value: string, format = DATE_FORMAT) => {
    //format - converts to locale
    if (!value) {
        return null;
    }
    const result = moment(value, format, true);
    const date = result.isValid() ? result.toDate() : false;
    return date;
};

export const parseWrapper = (value: string) => {
    return parseDate(value);
};
