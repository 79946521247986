import React from "react";
import ReactDOM from "react-dom";
import {
    WorkTypeLinkSnapshotType,
    fields,
    OrderObjectSnapshotType,
    getContentUnits,
} from "modules/orders-manage/models/order";
import {
    OwnOrderSpendingType,
    OwnOrderSpendingSnapshotType,
    OrderTimesheetSpendingType,
    ownSpendingFields,
} from "modules/orders-manage/models/order-spending";
import { EmployerDictionaryItemType } from "modules/spending/employee/models/employee-dictionary";
import { Field, FieldProps } from "formik";
import { Collapse, InputGroup } from "@blueprintjs/core";
import { findIndex, sortBy } from "lodash";
import { TimesheetSpendings } from "./TimesheetSpendings";
import { Money, formatMoney } from "modules/common/components/money/Money";
import { observer } from "mobx-react";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
    DroppableProvided,
    DraggableProvided,
} from "react-beautiful-dnd";
import { move } from "modules/common/services/array";
import { OwnSpendingObject } from "./OwnSpendingObject";
import { Caret } from "modules/common/components/collapse/Caret";
import { SectionCollapserType } from "modules/orders-manage/models/orders-store";
import styles from "./OwnSpendings.module.scss";
import { getDragItemStyle } from "modules/common/services/drag";
import { onlyDigitsInputKeyPress, preventSubmitKeyDown } from "modules/common/services/form/values";
import { ReadOnlyFormElement } from "modules/common/components/form/StandardFormInput";
import { Asterisk } from "modules/common/components/form";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";

import portal from "../../portal";
import { EmployerSelect } from "modules/common/components/form/EmployerSelect";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { getMoney, OwnSpendingBlockSorting, OWN_BLOCK_NAME } from "./print-view-model";
import { formatHours } from "modules/spending/timesheet/models/user-timesheet";

type TObject = OrderObjectSnapshotType;
type TUnit = WorkTypeLinkSnapshotType;
type TSpending = OwnOrderSpendingType;

const digits = onlyDigitsInputKeyPress();

const hours = (value: number) => (
    <>
        &nbsp;&nbsp;<span>({value}ч)</span>
    </>
);

const OBJECTS_DRAG = "own-objects";

export const OwnSpendings = observer(
    class extends React.PureComponent<OwnSpendingsProps> {
        private fieldProps: FieldProps | null = null;

        render() {
            const {
                name,
                employee,
                collapsed,
                onToggleCollapse,
                spendings,
                readOnly,
                planCollapser,
                actualCollapser,
                disabled,
            } = this.props;
            const { factHours, factMoney } = spendings.spendingsTotal;
            return (
                <Field name={name}>
                    {(fieldProps: FieldProps) => {
                        this.fieldProps = fieldProps;

                        const content = this.getContentUnits();
                        const value: TSpending[] = fieldProps.field.value;

                        const totalMoney = value.reduce((acc, unit) => acc + getMoney(employee, unit), 0);
                        const totalHours = value.reduce((acc, unit) => acc + unit.hours, 0);

                        return (
                            <div className={`own-spendings ${styles.spendings}`}>
                                <h1 className="planr-block-header collapser" onClick={onToggleCollapse}>
                                    {OWN_BLOCK_NAME}
                                    <Caret collapsed={collapsed} />
                                </h1>

                                <div className="order-total" onClick={onToggleCollapse}>
                                    Всего план:&nbsp;
                                    <Money className="spendings-money" amount={totalMoney} />
                                    {hours(spendings.showMinutes ? totalHours : Math.round(totalHours))}
                                </div>

                                <div className={`actual-total`} onClick={onToggleCollapse}>
                                    Всего факт:&nbsp;&nbsp;
                                    <Money className="spendings-money" amount={factMoney} />
                                    {` (${formatHours(spendings.showMinutes ? factHours : Math.round(factHours))})`}
                                </div>

                                <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                                    <div className="collapse">
                                        <div className={"collapse-left-header"}>
                                            <span className={styles.spendingsSpan}>Плановые затраты</span>
                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                                <Droppable
                                                    droppableId={OBJECTS_DRAG}
                                                    type={OBJECTS_DRAG}
                                                    isDropDisabled={readOnly}
                                                >
                                                    {(provided) => (
                                                        <DraggableInternal
                                                            disabled={disabled}
                                                            provided={provided}
                                                            content={content}
                                                            employee={employee}
                                                            factory={this.props.factory}
                                                            fieldProps={fieldProps}
                                                            readOnly={readOnly}
                                                            value={value}
                                                            collapser={planCollapser}
                                                        />
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </div>
                                        <div className={"collapse-right-header"}>
                                            <span className={styles.spendingsSpan}>Фактические затраты</span>
                                            <TimesheetSpendings spendings={spendings} collapser={actualCollapser} />
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        );
                    }}
                </Field>
            );
        }

        getContentUnits = () => {
            if (!this.fieldProps) {
                return [];
            }

            return getContentUnits(this.fieldProps.form.values, false);
        };

        onDragEnd = (result: DropResult) => {
            // dropped outside the list
            if (!result.destination || !this.fieldProps) {
                return;
            }

            const { form, field } = this.fieldProps;

            // initial index
            const from = result.source.index;
            // new index
            const to = result.destination.index;

            // detect what exactly dragged was

            if (result.type === OBJECTS_DRAG) {
                // top level - object content

                const orderObjects = form.values[fields.objects] as TObject[];
                const content = this.getContentUnits();
                const indexes: TStringMap<number> = {};
                move(content, from, to).forEach((obj, index) => {
                    indexes[obj.guid] = index + 1;
                });

                const newValue = orderObjects.map((obj) => {
                    let changed = false;
                    const replacement = obj.content.map((cnt) => {
                        if (indexes[cnt.guid]) {
                            changed = true;
                            return { ...cnt, sortOrder: indexes[cnt.guid] };
                        }

                        return cnt;
                    });
                    if (changed) {
                        return { ...obj, content: replacement };
                    }
                    return obj;
                });

                form.setFieldValue(fields.objects, newValue);
                form.setFieldTouched(fields.objects, true);
            } else {
                // second level - spending inside object

                const value = field.value as TSpending[];
                const contentGuid = result.type;
                const spendings = sortBy(
                    value.filter((v) => v.contentGuid === contentGuid),
                    (s: TSpending) => s.sortOrder
                );

                const indexes: TStringMap<number> = {};
                move(spendings, from, to).forEach((s, index) => {
                    indexes[s.id] = index + 1;
                });

                const newValue = value.map((sp) => (indexes[sp.id] ? { ...sp, sortOrder: indexes[sp.id] } : sp));

                form.setFieldValue(field.name, newValue);
                form.setFieldTouched(field.name, true);
            }
        };
    }
);

export interface OwnSpendingFactory {
    emptyOwnSpending: (unit: TUnit, sortOrder: number) => Promise<OwnOrderSpendingSnapshotType>;
}

interface OwnSpendingsProps {
    factory: OwnSpendingFactory;
    name: string;
    employee: TStringMap<EmployerDictionaryItemType>;
    onToggleCollapse: () => void;
    collapsed: boolean;
    spendings: OrderTimesheetSpendingType;
    readOnly: boolean;
    planCollapser: SectionCollapserType;
    actualCollapser: SectionCollapserType;
    disabled?: boolean | undefined;
}

interface DraggableInternalProps {
    factory: OwnSpendingFactory;
    provided: DroppableProvided;
    content: TUnit[];
    value: TSpending[];
    readOnly: boolean;
    fieldProps: FieldProps;
    employee: TStringMap<EmployerDictionaryItemType>;
    collapser: SectionCollapserType;
    disabled?: boolean | undefined;
}

const DraggableInternal = observer(
    class extends React.Component<DraggableInternalProps> {
        render() {
            const { provided, content, value, readOnly, employee, fieldProps, collapser, disabled } = this.props;
            const sorted = sortBy(value, OwnSpendingBlockSorting);

            return (
                <div {...provided.droppableProps} ref={provided.innerRef} className="objects">
                    {content.map((item, index) => {
                        const spendings = sorted.filter((v) => v.contentGuid === item.guid);

                        return (
                            <OwnSpendingObject
                                employee={employee}
                                spendings={spendings}
                                key={item.guid}
                                index={index}
                                item={item}
                                readOnly={disabled || readOnly}
                                collapsed={!collapser.plain[item.guid]}
                                onToggleCollapse={() => {
                                    collapser.toggle(item.guid);
                                }}
                                addSpending={() => {
                                    this.addSpending(item, spendings.length + 1);
                                    collapser.set(item.guid, true);
                                }}
                            >
                                <UserTable
                                    content={content}
                                    employee={employee}
                                    fieldProps={fieldProps}
                                    readOnly={disabled || readOnly}
                                    spendings={spendings}
                                    value={value}
                                />
                            </OwnSpendingObject>
                        );
                    })}
                    {provided.placeholder}
                </div>
            );
        }

        addSpending = async (unit: TUnit, sortOrder: number) => {
            const { fieldProps, factory } = this.props;

            if (fieldProps) {
                const value: TSpending[] = fieldProps.field.value;
                const estimate = await factory.emptyOwnSpending(unit, sortOrder);
                const newValue = [...value, estimate];

                fieldProps.form.setFieldValue(fieldProps.field.name, newValue);
                fieldProps.form.setFieldTouched(fieldProps.field.name, true);
            }
        };
    }
);

interface UserTableProps {
    employee: TStringMap<EmployerDictionaryItemType>;
    spendings: TSpending[];
    value: TSpending[];
    content: TUnit[];
    readOnly: boolean;
    fieldProps: FieldProps;
}

const UserTable = observer(
    class extends React.Component<UserTableProps> {
        renderSpending = (index: number, sp: TSpending, usedEmployee: string[]) => {
            const { employee, readOnly, value, fieldProps } = this.props;
            const employerId = sp.employerId;
            const allowed = Object.values(employee).filter(
                (e) => (!e.fired && !usedEmployee.includes(e.id)) || e.id === employerId
            );
            const savedEmployee = sp.employer
                ? {
                      id: sp.employer.id,
                      label: sp.employer.name,
                  }
                : null;
            const employer = employee[employerId] || savedEmployee;

            const onChange = (field: string, text: any) => {
                const position = value.indexOf(sp);
                const newValue = [...value.slice(0, position), { ...sp, [field]: text }, ...value.slice(position + 1)];
                fieldProps.form.setFieldValue(fieldProps.field.name, newValue);
                fieldProps.form.setFieldTouched(fieldProps.field.name, true);
            };

            return (
                <Draggable key={sp.id} draggableId={sp.id} index={index} isDragDisabled={readOnly}>
                    {(provided, snapshot) => {
                        const spending: any = sp;
                        const row = (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={styles.row}
                                style={this.getItemStyle(snapshot.isDragging, provided)}
                            >
                                <div className={styles.employer}>
                                    {!readOnly && (
                                        <EmployerSelect
                                            employee={allowed}
                                            field={fieldProps}
                                            saved={savedEmployee}
                                            value={employerId}
                                            onItemSelect={(item) => {
                                                onChange(ownSpendingFields.employerId, item.id);
                                            }}
                                            matchTargetWidth={false}
                                            popoverClassName="order_own_spending_employer_select"
                                        />
                                    )}
                                    {readOnly && <span>{employer ? employer.label : ""}</span>}
                                </div>
                                <div className={styles.daysCount}>
                                    {!readOnly && (
                                        <InputGroup
                                            value={spending[ownSpendingFields.hours]}
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                const val = parseInt(e.currentTarget.value, 10);
                                                onChange(ownSpendingFields.hours, isNaN(val) ? 0 : val);
                                            }}
                                            className="planr-default-input"
                                            autoComplete="off"
                                            data-lpignore="true"
                                            onKeyDown={preventSubmitKeyDown}
                                            onKeyPress={digits}
                                        />
                                    )}
                                    {readOnly && <span>{spending[ownSpendingFields.hours]}</span>}
                                </div>
                                <div className={styles.money}>
                                    <ReadOnlyFormElement
                                        label=""
                                        value={formatMoney(getMoney(employee, spending), { noFraction: true })}
                                        inline={false}
                                    />
                                </div>
                                <div>
                                    {!readOnly && (
                                        <RemoveConfirmation
                                            onConfirmed={() => this.removeSpending(sp.id)}
                                            what={() => (employer ? employer.label : "сотрудника")}
                                            render={({ confirmRemoving }) => {
                                                return (
                                                    <PlanrButton
                                                        icon="general-trash"
                                                        type={"neutral"}
                                                        onClick={confirmRemoving}
                                                        size="small"
                                                    />
                                                );
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        );

                        return snapshot.isDragging ? ReactDOM.createPortal(row, portal) : row;
                    }}
                </Draggable>
            );
        };

        render() {
            const { spendings } = this.props;
            const usedEmployee = spendings.map((s) => s.employerId).filter((id) => !!id);

            return spendings.length > 0 ? (
                <div className={styles.planTable}>
                    <div className={styles.row}>
                        <div className={styles.employer}>
                            Сотрудник
                            <Asterisk isRequired={true} />
                        </div>
                        <div className={styles.daysCount}>
                            Кол-во часов
                            <Asterisk isRequired={true} />
                        </div>
                        <div className={styles.money}>Затраты</div>
                        <div>&nbsp;</div>
                    </div>

                    {spendings.map((sp, index) => this.renderSpending(index, sp, usedEmployee))}
                </div>
            ) : null;
        }

        removeSpending = (id: string) => {
            const { fieldProps } = this.props;

            if (fieldProps) {
                const value: TSpending[] = fieldProps.field.value;
                const index = findIndex(value, (o) => o.id === id);

                if (index >= 0) {
                    const newValue = [...value.slice(0, index), ...value.slice(index + 1)];

                    fieldProps.form.setFieldValue(fieldProps.field.name, newValue);
                    fieldProps.form.setFieldTouched(fieldProps.field.name, true);
                }
            }
        };

        getItemStyle = (isDragging: boolean, provided: DraggableProvided): React.CSSProperties => {
            return getDragItemStyle(isDragging, provided.draggableProps.style);
        };
    }
);
