import React from "react";
import { OrderConsignmentRowType } from "modules/orders-manage/models/order-stages-store";
import { DraggableProvided } from "react-beautiful-dnd";
import { observer } from "mobx-react";

import styles from "./Consignment.module.scss";

import { FileLink } from "modules/common/components/files/FileLink";
import { ConsignmentFileType } from "modules/orders-manage/models/order-file";
import { eat } from "modules/common/services/typescript";

type ConsignmentProps = {
    draggable?: DraggableProvided;
    consignment: OrderConsignmentRowType;
};

export const Consignment: React.FC<ConsignmentProps> = observer(({ consignment, draggable, children }) => {
    const draggablePrps = draggable
        ? {
              ref: draggable.innerRef,
              ...draggable.draggableProps,
              ...draggable.dragHandleProps,
          }
        : {};

    return (
        <div className={`${styles.planPayment} ${styles.consignment}`} {...draggablePrps}>
            <div style={{ display: "flex" }}>
                <div className={styles.paymentNumber}>№{consignment.number}</div>
                <div className={styles.paymentNumber}>{consignment.signDate}</div>
                {children}
            </div>
            {consignment.file && (
                <FileLink<ConsignmentFileType>
                    baseUrl={consignment.baseUrl}
                    file={consignment.file}
                    onRemove={eat}
                    readOnly={true}
                />
            )}
        </div>
    );
});
