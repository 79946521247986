import { types, applySnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";

const StartBase = types.compose(
    Transport,
    Notificator,
    types.model({
        phoneNumber: types.string,
        email: types.string,
        companyName: types.string,
        shortCompanyName: types.string,
        guid: types.string,
        account: types.string,
        bankIdentificator: types.string,
        inn: types.string,
        kpp: types.string,
        ogrn: types.string,
        okpo: types.string,
        okved: types.string,
        bankName: types.string,
        corrAccount: types.string,
        address: types.string,
        directorName: types.string,
        directorNameGenitive: types.string,
    })
);

export const Start = StartBase.actions((self) => ({
    save: flow(function* (model: StartSnapshotType) {
        try {
            yield self.transport.post<any>(apiUrls.application.startSettings, model);
            applySnapshot(self, model);

            self.notify.success(texts.messages.saved);
            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),
    load: flow(function* () {
        try {
            const snapshot = yield self.transport.get<any>(apiUrls.application.startSettings);
            applySnapshot(self, snapshot);

            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),
})).named("Start");

export type StartSnapshotType = typeof StartBase.SnapshotType;
export type StartType = typeof Start.Type;

export const fields = {
    phoneNumber: nameof((a: StartType) => a.phoneNumber) as string,
    email: nameof((a: StartType) => a.email) as string,
    companyName: nameof((a: StartType) => a.companyName) as string,
    shortCompanyName: nameof((a: StartType) => a.shortCompanyName) as string,
    guid: nameof((a: StartType) => a.guid) as string,
    account: nameof((a: StartType) => a.account) as string,
    bankIdentificator: nameof((a: StartType) => a.bankIdentificator) as string,
    inn: nameof((a: StartType) => a.inn) as string,
    kpp: nameof((a: StartType) => a.kpp) as string,
    ogrn: nameof((a: StartType) => a.ogrn) as string,
    okpo: nameof((a: StartType) => a.okpo) as string,
    okved: nameof((a: StartType) => a.okved) as string,
    bankName: nameof((a: StartType) => a.bankName) as string,
    corrAccount: nameof((a: StartType) => a.corrAccount) as string,
    address: nameof((a: StartType) => a.address) as string,
    directorName: nameof((a: StartType) => a.directorName) as string,
    directorNameGenitive: nameof((a: StartType) => a.directorNameGenitive) as string,
};

export const initialState = (): StartSnapshotType => ({
    phoneNumber: "",
    email: "",
    companyName: "",
    shortCompanyName: "",
    guid: "",
    account: "",
    bankIdentificator: "",
    address: "",
    bankName: "",
    corrAccount: "",
    directorName: "",
    inn: "",
    kpp: "",
    ogrn: "",
    okpo: "",
    okved: "",
    directorNameGenitive: "",
});
