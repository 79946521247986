import { types } from 'mobx-state-tree';
import { ProductionStageList, ProductionStage, initialState as emptyList } from './production-stage-list';
import { initialState as emptyProductionStage } from './production-stage';
import { Transport } from 'modules/common/models/transport';
import { Notificator } from 'modules/common/models/notificator';

export const ProductionStagesStore = types.compose(
    Transport, Notificator, types.model({
        list: ProductionStageList,
        details: ProductionStage
    }))
    .named('ProductionStagesStore');

export const initialState = (): typeof ProductionStagesStore.SnapshotType => ({
    list: emptyList(),
    details: emptyProductionStage()
});

export type ProductionStagesStoreType = typeof ProductionStagesStore.Type;
