import React from "react";
import { UnitStoreType } from "../models/unit-store";
import { observer } from "mobx-react";
import styles from "./UnitPage.module.scss";
import { DepartmentBlock } from "./DepartmentBlock";
import { NoData } from "modules/common/components/table/NoData";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { menuItems } from "modules/root/components/menu/MainMenu";
import { CollapseExpandAll } from "modules/common/components/table/CollapseExpandAll";
import { Search } from "modules/common/components/form/Search";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { AgGridReact } from "@ag-grid-community/react";
import { MAX_INT } from "modules/common/constants";
import { agGridLocale } from "modules/root/services/locale";
import { GridOptions } from "@ag-grid-community/core";
import { DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import { columns, columnsUsers } from "./columns";
import { TotalLine } from "./TotalLine";
import { Popover } from "@blueprintjs/core";
import { TotalLineUnit } from "./TotalLineUnit";
import { texts } from "modules/common/texts";

const menu = menuItems();
const modules = [...defaultModules];

class Page extends React.Component<UnitPageProps, UnitPageState> {
    private options: GridOptions = DefaultAgGridOptions(true);

    private sortFields: any = {
        name: "desc",
        sum: "desc",
        percent: "desc",
        factor: "desc",
        hoursFactor: "desc",
        overtimeHours: "desc",
        workedOutHours: "desc",
        workPercent: "desc",
        salaryFactor: "desc",
        salary: "desc",
        hours: "desc",
        paidMoney: "desc",
        costPerHour: "desc",
        percentDifference: "desc",
        workedPercentSort: "desc",
    };

    constructor(props: any) {
        super(props);

        this.state = {
            selectedPeriod: "I квартал",
            showWorkOut: false,
            showDepartments: false,
        };
    }

    componentDidMount() {
        document.title = menu.spending.nested.unit.label;

        const { store } = this.props;

        return store.load();
    }

    render() {
        const { store, setUserFilter } = this.props;
        const { isEmpty, isLoading, years } = store;
        const { showWorkOut, selectedPeriod, showDepartments } = this.state;

        const workedOut = showWorkOut ? store.workedOutPeriod(selectedPeriod) : [];
        const query = store.pureQuery;

        const periods = Object.keys(store.periodGroups);
        const userData = store.periodGroups[selectedPeriod];

        const userList = userData.users.filter((row) => store.isRowMatch(row.name, row.department, row.position));
        const avgQuarter = userData ? userData.medianHours : 0;
        const maxHoursQuarter = userData ? userData.maxHours : 0;
        const departmentsMap = userData ? userData.departmentMap : {};
        const config = columns(store.showMinutes);
        const configUsers = columnsUsers(avgQuarter, maxHoursQuarter, store.showMinutes);
        return (
            <div className={styles.page}>
                <LocalLoader active={isLoading} />

                <div
                    className={styles.filters}
                    style={
                        showWorkOut
                            ? { marginBottom: "125px" }
                            : !showWorkOut && showDepartments
                            ? { marginBottom: "115px" }
                            : !showWorkOut && !showDepartments
                            ? { marginBottom: "145px" }
                            : {}
                    }
                >
                    <div className={styles.right}>
                        <PlanrButton
                            type="graybtn"
                            icon="general-print"
                            onClick={this.print}
                            title={texts.print}
                            round={true}
                        />
                        <div style={{ marginRight: "24px" }}>
                            <Search query={query} onSearch={setUserFilter} />
                        </div>
                        {!showWorkOut && showDepartments && <CollapseExpandAll onChange={store.collapser.setAll} />}
                    </div>

                    <div className={styles.tools}>
                        <PlanrButton
                            type={"greenish"}
                            onClick={() => {
                                this.setState({ showWorkOut: !this.state.showWorkOut });
                            }}
                            style={{ marginTop: "5px", width: "145px", marginRight: "120px" }}
                            size="small"
                            className={`action-icon add-button `}
                        >
                            {!showWorkOut ? "Выработка" : "Unit"}
                        </PlanrButton>

                        {periods.map((periodName) => (
                            <PlanrButton
                                key={periodName}
                                type={selectedPeriod === periodName ? "primary" : "secondary"}
                                onClick={() => {
                                    this.setState({ selectedPeriod: periodName });
                                }}
                                style={{ marginTop: "5px", width: "145px", marginRight: "5px" }}
                                size="small"
                                className="action-icon add-button"
                            >
                                <span className={styles.tabsTitle}>
                                    <span>{periodName}</span> <span className={styles.lowTitle}>({store.year})</span>
                                </span>
                            </PlanrButton>
                        ))}
                        <Popover
                            usePortal={true}
                            position={"bottom"}
                            // onInteraction={(state) => {
                            //     this.setState({ open: state });
                            // }}
                            interactionKind={"click"}
                            content={
                                <div style={{ padding: "10px" }}>
                                    {years.map((year, index) => (
                                        <div
                                            className={store.year === year.year ? styles.yearActive : styles.yearItem}
                                            key={index}
                                            onClick={() => {
                                                this.changeYear(year.year);
                                            }}
                                        >
                                            {year.year} год
                                        </div>
                                    ))}
                                </div>
                            }
                        >
                            <PlanrButton
                                type="secondary"
                                round={true}
                                size="small"
                                icon="general-dots-more"
                                style={{ marginTop: "5px", marginLeft: "7px" }}
                            ></PlanrButton>
                        </Popover>
                    </div>
                    {!showWorkOut && (
                        <div className={`print-area ${styles.rowControl}`}>
                            <div className={styles.filterBtns}>
                                <PlanrButton
                                    type={showDepartments ? "blueish" : "graybtn"}
                                    size="small"
                                    onClick={this.filterDepartment}
                                    style={{
                                        borderTopRightRadius: "0px",
                                        borderBottomRightRadius: "0px",
                                        width: "150px",
                                    }}
                                >
                                    По отделам
                                </PlanrButton>
                                <PlanrButton
                                    type={!showDepartments ? "blueish" : "graybtn"}
                                    size="small"
                                    onClick={this.filterDepartment}
                                    style={{
                                        borderTopLeftRadius: "0px",
                                        borderBottomLeftRadius: "0px",
                                        marginLeft: "0px",
                                        width: "150px",
                                    }}
                                >
                                    Весь список
                                </PlanrButton>
                            </div>
                        </div>
                    )}
                    <div className={styles.totalLine}>
                        {showWorkOut && (
                            <div className={`print-area ${styles.rowControl}`}>
                                <TotalLine
                                    sortColumn={this.toggleSort}
                                    store={workedOut}
                                    showMinutes={store.showMinutes}
                                ></TotalLine>
                            </div>
                        )}
                        {!showWorkOut && !showDepartments && (
                            <div className={`print-area ${styles.rowControl}`}>
                                <TotalLineUnit sortColumn={this.toggleSort}></TotalLineUnit>{" "}
                            </div>
                        )}
                    </div>
                </div>
                {showWorkOut && (
                    <div className={`print-area ${styles.rowControl} `}>
                        <GridTheme bordered={true} className={`designed ${styles.grid}`}>
                            <AgGridReact
                                rowStyle={{
                                    fontFamily: "GothamPro, Arial, sans-serif",
                                    color: "#00273D",
                                    fontSize: "12px",
                                }}
                                rowSelection="single"
                                suppressCellSelection={true}
                                enableCellTextSelection={true}
                                columnDefs={config}
                                rowData={workedOut}
                                rowHeight={32}
                                localeText={agGridLocale}
                                gridOptions={this.options}
                                rowBuffer={MAX_INT}
                                context={this}
                                domLayout="autoHeight"
                                animateRows={true}
                                modules={modules}
                                applyColumnDefOrder={true}
                            />
                        </GridTheme>
                    </div>
                )}
                {!showWorkOut && showDepartments && (
                    <div className={`print-area ${styles.rowControl} `}>
                        <div style={{ display: "block" }}>
                            {Object.keys(departmentsMap).map((department) => {
                                const departmentUsers = departmentsMap[department].filter((row) =>
                                    store.isRowMatch(row.employer.name, row.employer.department, row.employer.position)
                                );

                                return departmentUsers.length > 0 ? (
                                    <DepartmentBlock
                                        users={departmentUsers}
                                        department={department}
                                        store={store}
                                        key={department}
                                        collapsed={!store.collapser.opened.get(department)}
                                        toggleCollapse={store.collapser.toggle}
                                        avgHours={avgQuarter}
                                        maxHours={maxHoursQuarter}
                                    />
                                ) : null;
                            })}
                        </div>
                    </div>
                )}
                {!showWorkOut && !showDepartments && (
                    <div className={`print-area ${styles.rowControl} `}>
                        <GridTheme bordered={true} className={`designed ${styles.grid}`}>
                            <AgGridReact
                                rowStyle={{
                                    fontFamily: "GothamPro, Arial, sans-serif",
                                    color: "#00273D",
                                    fontSize: "14px",
                                }}
                                rowSelection="single"
                                suppressCellSelection={true}
                                enableCellTextSelection={true}
                                columnDefs={configUsers}
                                rowData={userList}
                                rowHeight={45}
                                localeText={agGridLocale}
                                gridOptions={this.options}
                                rowBuffer={MAX_INT}
                                context={this}
                                domLayout="autoHeight"
                                animateRows={true}
                                modules={modules}
                                applyColumnDefOrder={true}
                            />
                        </GridTheme>
                    </div>
                )}
                {isEmpty && !isLoading && <NoData className={styles.nodata} />}
            </div>
        );
    }

    filterDepartment = () => {
        this.setState({ showDepartments: !this.state.showDepartments });
    };

    changeYear = (year: number) => {
        return this.props.store.load(year);
    };

    toggleSort = (colId: any) => {
        for (let key in this.sortFields) {
            if (key.toString() === colId) {
                const sorted = this.sortFields[key];
                this.options.columnApi?.applyColumnState({
                    state: [
                        {
                            colId,
                            sort: sorted === "desc" ? "asc" : "desc",
                        },
                    ],
                    defaultState: { sort: null },
                });
                this.sortFields[key] = sorted === "desc" ? "asc" : "desc";
            }
        }
    };

    print = () => {
        const { store } = this.props;
        const { showDepartments, showWorkOut, selectedPeriod } = this.state;

        if (showDepartments) {
            return store.printDepartments(selectedPeriod);
        }

        const columnState = this.options.columnApi?.getColumnState() ?? [];
        const column = columnState.find((c) => !!c.sort);

        if (!showDepartments && !showWorkOut) {
            return store.printDirect(selectedPeriod, column);
        }

        if (!showDepartments && showWorkOut) {
            return store.printWorkingOut(selectedPeriod, column);
        }
    };
}

export const UnitPage = observer(Page);

interface UnitPageProps {
    store: UnitStoreType;
    setUserFilter: (value: string) => void;
}

interface UnitPageState {
    selectedPeriod: string;
    showWorkOut: boolean;
    showDepartments: boolean;
}
