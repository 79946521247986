import moment from 'moment';
import { types, applySnapshot, getEnv } from 'mobx-state-tree';
import { Transport } from 'modules/common/models/transport';
import { Notificator } from 'modules/common/models/notificator';
import { flow } from 'modules/common/models/flow';
import { apiUrls } from 'modules/common/services/communication/urls';
import { DEFAULT_SORTING_ASCENDING_VALUE, DATE_TIME_FORMAT, EMPTY_OBJECT_ID } from 'modules/common/constants';
import { TableSorter } from 'modules/common/models/table-sorter';
import { getSortOption } from 'modules/common/services/table/sorting-storage';
import { nameof } from 'modules/common/services/typescript';
import { groupBy } from 'lodash';
import { FileMetadata } from 'modules/common/models/file-metadata';
import func from '../functionalities';
import { MimeTypes } from 'modules/common/services/files';
import { IdEntity } from 'modules/common/models/entity';

const KnowledgeBaseDocument = FileMetadata.named('KnowledgeBaseDocument');

export const ACCEPT = [MimeTypes.pdf, MimeTypes.doc, MimeTypes.docx, MimeTypes.xls, MimeTypes.xlsx].join(',');

const Node = types.compose(IdEntity, types.model({
    guid: types.string,
    label: types.string,
    created: types.string,
    isFolder: types.boolean,
    parent: types.string,
    version: types.number,
    file: types.maybeNull(KnowledgeBaseDocument)
})).views((self) => ({
    get createdAsDate () {
        return moment(self.created, DATE_TIME_FORMAT)
    },
}));

export type NodeType = typeof Node.Type;
export type NodeSnapshotType = typeof Node.SnapshotType;

const KnowledgeBase = types.model({
    nodes: types.array(Node),
    sorter: TableSorter
});

// unable to move this types into Constants store
export const KnowledgeBaseTypes = {
    manuals: 'Должностные инструкции',
    standards: 'СМК',
    templates: 'Шаблоны',
    orders: 'Приказы',
    organisations: 'СРО'
};

export const KnowledgeBaseStore = types.compose(Transport, Notificator, KnowledgeBase, types.model({
    type: types.string
}))
.views((self) => ({
    get asMap () {
        return self.nodes.reduce((result, node) => {
            result[node.id] = node;
            return result;
        }, {} as TStringMap<NodeType>);
    },

    get documentsMap () {
        return groupBy(self.nodes.filter((f) => !f.isFolder), (s) => s.guid);
    },

    get functionality () {
        switch (self.type) {
            case KnowledgeBaseTypes.manuals:
                return func.TECHICAL_MANUALS_EDIT;
            case KnowledgeBaseTypes.standards:
                return func.TECHICAL_QUALITY_STANDARDS_EDIT;
            case KnowledgeBaseTypes.templates:
                return func.TECHICAL_TEMPLATES_EDIT;
            case KnowledgeBaseTypes.orders:
                return func.TECHICAL_ORDERS_EDIT;
            case KnowledgeBaseTypes.organisations:
                return func.TECHICAL_ORGANISATIONS_EDIT;
            default:
                return 'unknown';
        }
    }
}))
.actions((self) => {
    function mapNode (item: any, isFolder: boolean): NodeSnapshotType {
        return {
            created: isFolder ? item.changed : item.created,
            id: item.id,
            isFolder,
            label: item.name,
            parent: item.folderId,
            guid: isFolder ? '' : item.guid,
            version: isFolder ? 0 : item.version,
            file: isFolder ? null : KnowledgeBaseDocument.create(item, getEnv(self))
        };
    }

    return {
        load: flow(function*(){
            try {
                const list = yield self.transport.get<any>(apiUrls.knowledgeBase.list, {
                    params: { type: self.type }
                });

                const nodes = list.documents.map((d: any) => mapNode(d, false))
                    .concat(list.folders.map((f: any) => mapNode(f, true)));
                applySnapshot(self.nodes, nodes);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        uploadFile: flow(function* (file: File, folder: NodeType, guid = '') {
            try {
                const model = new FormData();
                model.append('file', file);
                model.append('accept', ACCEPT);

                const meta: UploadFileResult = yield self.transport.post<any>(apiUrls.application.files.upload, model);
                const document = yield self.transport.post<any>(apiUrls.knowledgeBase.file.upload, {
                    id: meta.id, folderId: folder.id, guid
                });

                self.nodes.push(mapNode(document, false));

                self.notify.success('Файл успешно загружен');
                return true;
            } catch (er) {
                self.notify.error(er);
                return null;
            }
        }),

        removeFile: flow(function* (id: string) {
            try {
                yield self.transport.delete<any>(apiUrls.knowledgeBase.file.delete(id));

                const child = self.nodes.find((f) => f.id === id);
                child && self.nodes.remove(child);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        removeFolder: flow(function* (folder: NodeType) {
            try {
                yield self.transport.delete<any>(apiUrls.knowledgeBase.folder.delete(folder.id));

                const child = self.nodes.filter((f) => f.parent === folder.id);

                self.nodes.remove(folder);
                child.forEach((c) => self.nodes.remove(c));

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        createFolder: flow(function* (name: string, parent: NodeType | null) {
            try {
                const folder = yield self.transport.post<any>(apiUrls.knowledgeBase.folder.create, {
                    name, folderId: parent ? parent.id : EMPTY_OBJECT_ID, type: self.type
                });

                self.nodes.push(mapNode(folder, true));

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        renameFolder: flow(function* (name: string, folder: NodeType) {
            try {
                const data = yield self.transport.put<any>(apiUrls.knowledgeBase.folder.rename(folder.id), {
                    name
                });

                const target = self.nodes.find((f) => f.id === folder.id);
                if (target) {
                    applySnapshot(target, mapNode(data, true));
                }

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        renameFile: flow(function* (name: string, id: string) {
            try {
                const item = yield self.transport.put<any>(apiUrls.knowledgeBase.file.rename(id), {
                    name
                });

                const targets = self.nodes.filter((f) => f.guid === item.guid);
                targets.forEach((target) => {
                    target.label = item.name;
                    if (target.file) {
                        target.file.name = item.name;
                    }
                });

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    };
})
.named('KnowledgeBaseStore');

export const fields = {
    name: nameof((s: NodeSnapshotType) => s.label) as string,
    created: nameof((s: NodeSnapshotType) => s.created) as string,
};

const tableName = KnowledgeBaseStore.name;
const sortStorage = getSortOption(tableName);
export const initialState = (type: string): KnowledgeBaseStoreSnapshotType => {
    const options = sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        type,
        nodes: [],
        sorter: {
            id: tableName + '_' + type,
            tableName,
            column: options.column,
            asc: options.asc,
        }
    };
};

export type KnowledgeBaseStoreType = typeof KnowledgeBaseStore.Type;
export type KnowledgeBaseStoreSnapshotType = typeof KnowledgeBaseStore.SnapshotType;
