import { types } from "mobx-state-tree";
import moment from "moment";
import { DATE_TIME_FORMAT } from "modules/common/constants";
import { nameof } from "modules/common/services/typescript";
import * as yup from "yup";
import { texts } from "modules/common/texts";

export const Passport = types
    .model({
        series: types.string,
        number: types.string,
        issuer: types.string,
        issuerCode: types.string,
        issueDate: types.maybeNull(types.string),
        regAddress: types.string,
    })
    .views((self) => ({
        get issueDateAsDate() {
            return self.issueDate ? moment(self.issueDate, DATE_TIME_FORMAT).toDate() : null;
        },
    }))
    .named("Passport");

export type PassportType = typeof Passport.Type;
export type PassportSnapshotType = typeof Passport.SnapshotType;

export const initialState = (): PassportSnapshotType => ({
    issueDate: null,
    issuer: "",
    issuerCode: "",
    number: "",
    series: "",
    regAddress: "",
});

export const fields = (prefix = "") => ({
    series: prefix + nameof((a: PassportType) => a.series),
    number: prefix + nameof((a: PassportType) => a.number),
    issueDate: prefix + nameof((a: PassportType) => a.issueDate),
    issuer: prefix + nameof((a: PassportType) => a.issuer),
    issuerCode: prefix + nameof((a: PassportType) => a.issuerCode),
    regAddress: prefix + nameof((a: PassportType) => a.regAddress),
});

export const passportLabels = () => {
    const ownFields = fields();

    return {
        [ownFields.issueDate]: "Когда выдан",
        [ownFields.issuer]: "Кем выдан",
        [ownFields.issuerCode]: "Код подразделения",
        [ownFields.number]: "Номер",
        [ownFields.series]: "Серия",
        [ownFields.regAddress]: "Адрес регистрации",
    };
};

export const PassportSchema = () => {
    const ownFields = fields();
    const labels = passportLabels();

    const result = {
        [ownFields.regAddress]: yup.string().label(labels[ownFields.regAddress]).notRequired(),
        [ownFields.number]: yup.string().label(labels[ownFields.number]).notRequired(),
        [ownFields.series]: yup.string().label(labels[ownFields.series]).notRequired(),
        [ownFields.issuer]: yup.string().label(labels[ownFields.issuer]).notRequired(),
        [ownFields.issuerCode]: yup.string().label(labels[ownFields.issuerCode]).notRequired(),
        [ownFields.issueDate]: yup
            .date()
            .label(labels[ownFields.issueDate])
            .typeError(texts.messages.incorrectDate("дата выдачи"))
            .nullable()
            .notRequired(),
    };

    return yup.object().shape(result);
};

export const maxIssueDate = () => new Date();
