import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { DeprGroupDictionaryItem, DeprGroupDictionaryItemType } from "./depr-group";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { orderBy } from "lodash";
import { MAX_INT } from "modules/common/constants";

export const DeprGroupDictionary = types
    .compose(
        Transport,
        Notificator,
        types.model({
            deprGroups: types.array(DeprGroupDictionaryItem),
        })
    )
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data = yield self.transport.get<DeprGroupDictionaryItemType[]>(apiUrls.deprGroups.list());
                applySnapshot(self.deprGroups, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .views((self) => ({
        get isEmpty() {
            return !self.deprGroups.length;
        },

        get sorted() {
            return orderBy(
                self.deprGroups,
                [(d) => (d.order !== null ? d.order : MAX_INT), (d) => d.name],
                ["asc", "asc"]
            );
        },

        get asMap(): TStringMap<DeprGroupDictionaryItemType> {
            return self.deprGroups.reduce((result, gr) => {
                result[gr.id] = gr;
                return result;
            }, {} as TStringMap<DeprGroupDictionaryItemType>);
        },

        get data() {
            return self.deprGroups.map((e) => ({
                ...getSnapshot(e),
                label: e.label,
            }));
        },
    }))
    .named("DeprGroupDictionary");

export type DeprGroupDictionaryType = typeof DeprGroupDictionary.Type;
export type DeprGroupDictionarySnapshotType = typeof DeprGroupDictionary.SnapshotType;

export const initialState = (): DeprGroupDictionarySnapshotType => ({
    deprGroups: [],
});
