import React from "react";
import styles from "./OrderAgentsRow.module.scss";
import { SaveAgent } from "modules/orders-manage/models/order-agents";
import { formatMoney } from "modules/common/components/money/Money";

export const OrderAgentsTotalRow = ({ values }: OrderAgentsTotalRowProps) => {
  return (
    <div className={`${styles.rowTotal} ${styles.headerRow} flat-mode`}>
      <div className={styles.iconCell}>&nbsp;</div>
      <div className={styles.category}>&nbsp;</div>
      <div className={`${styles.name}`}>&nbsp;</div>

      <div className={`${styles.materials} `}>&nbsp;</div>

      <div className={styles.reward}>
        <div className={styles.totalValue}>
          {formatMoney(values.reduce((partialSum, a) => partialSum + (+a.price * +a.newReward) / 100, 0))}
        </div>
      </div>

      <div className={styles.price}>
        <div className={styles.totalValue}>
          {formatMoney(values.reduce((partialSum, a) => partialSum + +a.price, 0))}
        </div>
      </div>

      <div className={styles.fio}>&nbsp;</div>

      <div className={styles.phone}>&nbsp;</div>

      <div className={styles.comment}>&nbsp;</div>

      <div className={styles.deal}>
        <div className={styles.totalValue}>
          {formatMoney(values.reduce((partialSum, a) => partialSum + +a.deals.reduce((s, b) => s + +b.sum, 0), 0))}
        </div>
      </div>
      <div className={styles.iconCell}>&nbsp;</div>
    </div>
  );
};

interface OrderAgentsTotalRowProps {
  values: SaveAgent[];
}
