import React from "react";
import { observer } from "mobx-react";
import { OverheadSpendingsType } from "../models/overhead-spendings";
import { SessionType } from "modules/session/auth/models/session";
import func from "modules/dictionaries/functionalities";
import { can } from "modules/session/auth/access";
import { Page } from "modules/common/components/page/Page";
import styles from "./OverheadSpendings.module.scss";
import { YearCard } from "./YearCard";
import { OverheadTable } from "./Table";
import { texts } from "modules/common/texts";
import { Route, withRouter, RouteComponentProps, matchPath } from "react-router-dom";
import { routes } from "modules/common/routes";
import { UnregisterCallback } from "history";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { OverheadTypeDetails } from "modules/dictionaries/overhead-types/components/details/OverheadTypeDetails";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { eat } from "modules/common/services/typescript";
import { trimStart } from "modules/common/services/strings";
import { parse } from "query-string";
import { PlanrButton } from "modules/common/components/planr/button/Button";

export const YEAR_SECTION = "year";

export function OverheadSpendingsExternalParams(locationHash: string) {
  const hash = trimStart("#", locationHash);

  if (hash) {
    const metadata = parse(hash);
    const year = (metadata[YEAR_SECTION] as string) || "";

    if (!isNaN(+year)) {
      const result = { year };
      return result;
    }
  }

  return null;
}

export const OverheadSpendings = withRouter(
  observer(
    class extends React.Component<OverheadSpendingsProps & RouteComponentProps, OverheadSpendingsState> {
      private unregister: UnregisterCallback | null = null;

      constructor(props: OverheadSpendingsProps & RouteComponentProps) {
        super(props);

        this.state = { typeId: getSelectedOverheadTypeId(props.location.pathname) };
      }

      componentDidMount() {
        document.title = "Накладные расходы";

        const { history, store, location } = this.props;
        const { typeId } = this.state;
        const externals = OverheadSpendingsExternalParams(location.hash);

        store.loadSumary();
        if (externals) {
          store.setYear(externals.year, true);
        } else {
          this.loadList();
        }
        this.loadDetails(typeId);

        this.unregister = history.listen((location) => {
          const typeId = getSelectedOverheadTypeId(location.pathname);

          if (this.state.typeId !== typeId) {
            this.setState({ typeId });
            this.loadDetails(typeId);
          }
        });
      }

      componentWillUnmount() {
        this.unregister && this.unregister();
      }

      render() {
        const { store, session } = this.props;
        const canChange = can(func.OVERHEADTPYE_EDIT, session.access);

        return (
          <Page className={styles.page}>
            <Route path={routes.expenses.overhead.details()}>
              <DetailsPopup title="Тип накладного расхода" onCancel={this.onOverheadTypeSelected}>
                <OverheadTypeDetails
                  canChange={canChange}
                  overheadType={store.details}
                  onSaved={this.onOverheadTypeSaved}
                  onRemoved={eat}
                  hideRemove={true}
                />
              </DetailsPopup>
            </Route>

            <div className={styles.years}>
              <span className={styles.results}>Итоги</span>
              {canChange && (
                <div className={styles.add}>
                  <PlanrButton
                    type="secondary"
                    icon={"general-plus-big"}
                    onClick={this.onOverheadTypeAdd}
                    round={true}
                    title={texts.add}
                  />
                  {/* <Icon
                                className='action-icon'
                                title={texts.add}
                                onClick={this.onOverheadTypeAdd}
                            /> */}
                </div>
              )}

              <div className={styles.scroller}>
                {store.summary.map((year) => (
                  <YearCard
                    key={year.period}
                    store={year}
                    active={year.period === store.year}
                    onSelect={store.setYear}
                  />
                ))}
              </div>
            </div>
            <div className={styles.actions}>
              <div className={styles.right}>
                {/* <PlanrButton
                                    type="graybtn"
                                    icon="general-big-arrow-left"
                                    title={texts.export}
                                    // onClick={this.export}
                                    round={true}
                                /> */}
                <PlanrButton
                  type="graybtn"
                  icon="general-export"
                  onClick={store.export}
                  title={texts.export}
                  round={true}
                />
                <PlanrButton
                  type="graybtn"
                  icon="general-print"
                  onClick={store.print}
                  title={texts.export}
                  round={true}
                  style={{ marginLeft: "16px" }}
                />
              </div>
            </div>

            <OverheadTable
              store={store}
              onTypeSelected={this.onOverheadTypeSelected}
              onRemoved={this.onOverheadTypeRemoved}
            />
          </Page>
        );
      }

      loadList = () => this.props.store.loadDetailed();

      loadDetails = (id: string) => this.props.store.details.load(id);

      onOverheadTypeSelected = (id: string = "") => {
        const { history } = this.props;

        const url = id ? routes.expenses.overhead.details(id) : routes.expenses.overhead.path;

        history.push(url);
      };

      onOverheadTypeRemoved = () => {
        this.loadList();
      };

      onOverheadTypeAdd = () => {
        this.onOverheadTypeSelected(EMPTY_OBJECT_ID);
      };

      onOverheadTypeSaved = () => {
        this.onOverheadTypeSelected("");
        this.loadList();
      };
    }
  )
);

interface OverheadSpendingsProps {
  store: OverheadSpendingsType;
  session: SessionType;
}

interface OverheadSpendingsState {
  typeId: string;
}

function getSelectedOverheadTypeId(pathname: string) {
  const match = matchPath<OverheadSpendingsState>(pathname, routes.expenses.overhead.details());

  return match && match.params ? match.params.typeId : "";
}
