import React, { useCallback } from 'react';
import { FileMetadataType } from 'modules/common/models/file-metadata';
import styles from './Toolbox.module.scss';
import { texts } from 'modules/common/texts';

export const Toolbox = ({ document, onPrint }: ToolboxProps) => {
    const download = useCallback(() => document.save(), [ document ]);
    const print = useCallback(() => onPrint && onPrint(document), [document, onPrint]);

    return (
        <div className={`${styles.toolbox} file-toolbox`}>
            <span className={styles.name}>{document.name}</span>
            <div className={styles.buttons}>
                <div className={`${styles.button} ${styles.download}`} title={texts.download} onClick={download} />
                {onPrint && (
                    <div className={`${styles.button} ${styles.print}`} title={texts.print} onClick={print} />
                )}
            </div>
        </div>
    );
};

interface ToolboxProps {
    document: FileMetadataType;
    onPrint?: (document: FileMetadataType) => void;
}
