import { types } from "mobx-state-tree";
import moment from "moment";
import { capitalize } from "modules/common/services/strings";

export const Period = types
    .model("Period", {
        month: types.number,
        year: types.number,
    })
    .views((self) => ({
        get periodName() {
            return makePeriodName(self.month, self.year);
        },
    }));

export type PeriodType = typeof Period.Type;
export type PeriodSnapshotType = typeof Period.SnapshotType;

export const convertToMoment = (month: number, year: number) => moment([year, month - 1, 1]);

export const makePeriodName = (month: number, year: number, format = "MMMM YYYY") =>
    capitalize(convertToMoment(month, year).format(format));

export const MakeMonthName = (month: number) => capitalize(convertToMoment(month, 1900).format("MMMM"));

export const MonthNames = ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"];

export const BuildYears = (year: number) => {
    let start = year + 5;
    let end = Math.max(1, start - 15);

    const years: number[] = [];
    while (start >= end) {
        years.push(end++);
    }

    return years;
};
