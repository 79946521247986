import React from "react";
import styles from "./EmployerName.module.scss";

export const EmployerName = ({ name, position, showTitle, style }: EmployerNameProps) => (
    <div className="employer-name" title={showTitle ? name : ""} style={style}>
        <span className="employer-name-label">{name}</span>
        <div className={`employer-position ${styles.small}`}>{position}</div>
    </div>
);

interface EmployerNameProps {
    name: string;
    position: string;
    showTitle?: boolean;
    style?: React.CSSProperties;
}
