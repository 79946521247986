import React, { useCallback } from "react";

import styles from "./Rating.module.scss";
import { GeneralIcon, GeneralIcons } from "modules/common/components/planr/icon/Generalcon";

const STARS = 5;
const stars = Array(STARS).fill(0);

export const Rating = ({ rating, onChange, readOnly }: RatingProps) => {
    const onClick = useCallback(
        (e: React.MouseEvent<SVGSVGElement>) => {
            if (onChange) {
                const value = parseInt(e.currentTarget.dataset.index ?? "", 10);

                if (!isNaN(value)) {
                    onChange(value);
                }
            }
        },
        [onChange]
    );

    rating = typeof rating === "string" ? parseInt(rating, 10) : rating;
    const count = Math.min(STARS, Math.max(0, rating));

    return (
        <span className={`${styles.rating} rating-stars`}>
            {stars.map((_, index) => {
                const icon: GeneralIcons = index < count ? "general-star-fill" : "general-star-not-fill";
                return (
                    <i key={index} style={{ marginRight: "3px" }}>
                        <GeneralIcon
                            type={icon}
                            onClick={readOnly ? undefined : onClick}
                            size={12}
                            data-index={index + 1}
                        />
                    </i>
                );
            })}
        </span>
    );
};

interface RatingProps {
    id?: string;
    rating: number | string;
    onChange?: (rating: number) => void;
    readOnly?: boolean;
}
