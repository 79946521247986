import React from "react";
import styles from "./FunctionalityTree.module.scss";
import { TreeRow } from "../models/access-store";
import classNames from "classnames";

export const FunctionalityTree = ({ onRowSelect, currentRow, rows }: FunctionalityTreeProps) => {
    return (
        <div className={`functionality-tree ${styles.tree}`}>
            <table>
                <tbody>{rendered(currentRow, rows, onRowSelect)}</tbody>
            </table>
        </div>
    );
};

export const renderRows =
    (addModuleName: boolean, positionTable?: boolean, level4?: (row: TreeRow, key: number) => JSX.Element) =>
    (currentRow: number, iteratee: TreeRow[], onRowSelect?: (index: number) => void) => {
        const used: TStringMap<boolean> = {};
        const selected = iteratee[currentRow];

        let increment = 1;
        const rows = iteratee.map((row, rowIndex) => {
            const cells: JSX.Element[] = [];

            if (row.level1 && !used[row.level1] && addModuleName) {
                const span = iteratee.filter((r) => r.level1 === row.level1).length;
                let className = classNames({
                    [styles.current]: selected && selected.level1 === row.level1,
                });

                cells.push(
                    <td rowSpan={span} className={`${styles.vertical} ${className}`} key={increment++}>
                        <div className={styles.text}>{row.level1}</div>
                    </td>
                );

                used[row.level1] = true;
            }

            if (row.level2 && !used[row.level1 + row.level2]) {
                const isVertical = !row.level1;
                const span = iteratee.filter((r) => r.level1 === row.level1 && r.level2 === row.level2).length;
                let className = classNames({
                    [styles.current]: selected && selected.level1 === row.level1 && selected.level2 === row.level2,
                    [styles.vertical]: isVertical,
                });

                if (!isVertical || addModuleName) {
                    cells.push(
                        <td rowSpan={span} key={increment++} className={`centered ${className}`}>
                            <div className={!row.level1 ? styles.text : ""}>{row.level2}</div>
                        </td>
                    );

                    used[row.level1 + row.level2] = true;
                }
            }

            // always
            {
                const colSpan = row.level1 && row.level2 ? 1 : !row.level1 && !row.level2 ? 3 : 2;
                let className = classNames({
                    [styles.current]: selected && selected.functionality === row.functionality,
                    [styles.small]: colSpan === 1,
                });

                cells.push(
                    <td
                        colSpan={colSpan}
                        key={increment++}
                        className={`centered ${className} ${positionTable ? styles.level3Pos : styles.level3}`}
                        onClick={onRowSelect ? () => onRowSelect(rowIndex) : undefined}
                    >
                        {row.level3}
                    </td>
                );

                level4 && cells.push(level4(row, ++increment));
            }

            return <tr key={increment++}>{cells}</tr>;
        });

        return rows;
    };

const rendered = renderRows(true);

interface FunctionalityTreeProps {
    currentRow: number;
    onRowSelect: (index: number) => void;
    rows: TreeRow[];
}
