import React from "react";
import { ProductionEmployerPremiumType, OrderPremiumDetailsType } from "modules/orders-manage/models/premium";
import { observer } from "mobx-react";
import { Checkbox, Classes, Popover } from "@blueprintjs/core";
import { Money } from "modules/common/components/money/Money";
import styles from "./Premium.module.scss";
import { PaidMoney } from "./helpers";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { PlanrTag } from "modules/common/components/planr/tag/PlanrTag";
import { prettyRound } from "modules/common/services/formatting/numbers";
import { formatHours } from "modules/spending/timesheet/models/user-timesheet";

class Premium extends React.Component<ProductionPremiumProps, ProductionPremiumState> {
    constructor(props: ProductionPremiumProps) {
        super(props);

        this.state = { userId: null };
    }

    render() {
        return <div>{this.blocks()}</div>;
    }

    blocks = () => {
        const { store } = this.props;

        return Object.values(store.premiumProductionAsMap).map((premiums) => {
            return this.block(premiums);
        });
    };

    block = (premiums: ProductionEmployerPremiumType[]) => {
        const department = premiums[0].department;
        const { store, readOnly, onTouch, canAssing } = this.props;

        let remainsSum = 0;
        let assignedSum = 0;
        let paidSum = 0;
        let sum = 0;
        let percents = 0;
        let calculatedSum = 0;
        const showMinutes = store.showMinutes;
        premiums.forEach((p) => {
            const rowMetadata = store.premiumRowsAsMap[p.employer.id] || null;

            remainsSum += rowMetadata.remainsSum;
            assignedSum += rowMetadata.assignedSum;
            paidSum += rowMetadata.paidSum;
            calculatedSum += p.calculatedSum;
            sum += p.sum;
            percents += p.premiumPercent;
        });

        return (
            <div key={department.id}>
                <div className={`${styles.row} ${styles.summary}`}>
                    <div className={styles.user}>
                        <h3 className={styles.departmentName}>{department.name}</h3>
                    </div>
                    <div className={styles.salary}></div>
                    <div className={styles.salaryFactor}></div>
                    <div className={styles.hours}></div>
                    <div className={styles.factor}></div>
                    <div className={styles.percent}>
                        <PlanrTag type="success">{prettyRound(percents)}%</PlanrTag>
                    </div>
                    <div className={styles.sum}>
                        <PlanrTag type="success">
                            <Money amount={calculatedSum} />
                        </PlanrTag>
                    </div>
                    <div className={styles.quality}></div>
                    <div className={styles.sum}>
                        <PlanrTag>
                            <Money amount={sum} />
                        </PlanrTag>
                    </div>
                    {canAssing && (
                        <div className={styles.sum}>
                            <PlanrTag>
                                <Money amount={assignedSum} />
                            </PlanrTag>
                        </div>
                    )}
                    <div className={styles.sum}>
                        <PlanrTag>
                            <Money amount={paidSum} />
                        </PlanrTag>
                    </div>
                    <div className={styles.sum}>
                        <PlanrTag>
                            <Money amount={remainsSum} />
                        </PlanrTag>
                    </div>
                </div>

                {this.tableHeader(canAssing)}

                {premiums.map((premium) => {
                    const details = store.premiumRowsAsMap[premium.employer.id];

                    return (
                        <div key={premium.employer.id} className={`${styles.row} ${premium.fired ? styles.fired : ""}`}>
                            <div className={styles.user}>{premium.employer.name}</div>
                            <div className={styles.salary}>
                                <Money amount={premium.salary} noFraction={true} />
                            </div>
                            <div className={styles.salaryFactor}> {premium.salaryFactor.toFixed(2)}</div>
                            <div className={styles.hours}>
                                {formatHours(showMinutes ? premium.hours : Math.round(premium.hours))}
                            </div>
                            <div className={styles.factor}>{premium.hoursFactor.toFixed(5)}</div>
                            <div className={styles.percent}>{premium.premiumPercent.toFixed(2)}%</div>
                            <div className={styles.sum}>
                                <Money amount={premium.calculatedSum} />
                            </div>
                            <div className={styles.quality}>
                                <Checkbox
                                    checked={premium.hasQualityMark}
                                    data-user={premium.employer.id}
                                    onChange={premium.fired ? undefined : this.onFeedbackToggle}
                                    disabled={readOnly || premium.fired}
                                />
                            </div>

                            <div className={styles.sum}>
                                <Popover
                                    position="auto"
                                    enforceFocus={false}
                                    isOpen={undefined}
                                    popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                                    usePortal={true}
                                >
                                    <Money amount={premium.sum} className="bp3-tooltip-indicator" />
                                    <div className={styles.engineerDetails}>
                                        <div>
                                            Бюджет: <Money amount={premium.budget} />
                                        </div>
                                        <div>
                                            Качество: <Money amount={premium.quality} />
                                        </div>
                                    </div>
                                </Popover>
                            </div>

                            {canAssing && (
                                <>
                                    <div className={styles.assing}>
                                        {details.assigned && (
                                            <>
                                                <Money amount={details.assignedSum} />
                                                {!premium.fired && (
                                                    <GeneralIcon
                                                        type="general-trash"
                                                        onClick={() => {
                                                            store.removeAssingnation(premium);
                                                            onTouch();
                                                        }}
                                                        style={{ color: "#E31818", cursor: "pointer" }}
                                                    />
                                                )}
                                            </>
                                        )}
                                        {!details.assigned && !premium.fired && details.userId && (
                                            <PlanrButton
                                                type="secondary"
                                                onClick={() => {
                                                    store.assingPremium(premium);
                                                    onTouch();
                                                }}
                                                size="small"
                                                className={`${styles.addButton} `}
                                                disabled={readOnly || premium.sum === 0}
                                                style={{
                                                    height: "20px",
                                                    fontSize: "10px",
                                                    padding: "0px 7px",
                                                }}
                                            >
                                                Назначить
                                            </PlanrButton>
                                        )}
                                    </div>
                                </>
                            )}

                            <div className={styles.sum}>
                                {!premium.fired && (
                                    <PaidMoney
                                        factory={premium.paymentFactory}
                                        readOnly={readOnly}
                                        row={details}
                                        onSelect={this.selectPremium}
                                        opened={this.state.userId === details.userId}
                                        onSetPayments={(data) => {
                                            store.setPayments(details.userId, data);
                                            onTouch();
                                        }}
                                    />
                                )}
                                {premium.fired && <Money amount={details.paidSum} />}
                            </div>
                            <div className={styles.sum}>
                                <Money amount={details.remainsSum} />
                            </div>
                            <div className={styles.sum}>
                                {premium.fired && <PlanrTag type="danger">Увольнение</PlanrTag>}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    tableHeader = (canAssing: boolean) => (
        <div className={`${styles.row} ${styles.header}`}>
            <div className={styles.user}>ФИО</div>
            <div className={styles.salary}>ЗП</div>
            <div className={styles.salaryFactor}>Кзп</div>
            <div className={styles.hours}>Часы</div>
            <div className={styles.factor}>Куч</div>
            <div className={styles.percent}>% от фонда</div>
            <div className={styles.sum}>Сумма</div>
            <div className={styles.quality}>Отм. кач</div>
            <div className={styles.sum}>Расчетная</div>
            {canAssing && <div className={styles.sum}>Назначено</div>}
            <div className={styles.sum}>Оплаты</div>
            <div className={styles.sum}>Остаток</div>
            <div className={styles.sum}></div>
        </div>
    );

    onFeedbackToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.readOnly) {
            return;
        }

        const checked = e.target.checked;
        const employerId = e.target.dataset.user || "";

        this.props.store.setProductionEmployerFeedback(employerId, checked);
        this.props.onFeedbackToggle(employerId, checked);
    };

    selectPremium = (userId: string | null) => this.setState({ userId });
}

export const ProductionPremiums = observer(Premium);

interface ProductionPremiumProps {
    store: OrderPremiumDetailsType;
    readOnly: boolean | undefined;
    onFeedbackToggle: (employerId: string, checked: boolean) => void;
    onTouch: () => void;
    canAssing: boolean;
}

interface ProductionPremiumState {
    userId: string | null;
}
