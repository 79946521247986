import * as React from 'react';
import { Redirect, Route, RouteProps as BaseProps } from 'react-router-dom';
import { routes } from 'modules/common/routes';

const defaultState = { from: routes.main };

/**
 * Route that allowed only for unauthorized users.
 * Redirects logged users to location.state.from.pathname
 * @param props Component props
 */
export const AnonymousRoute = (props: RouteProps) => {
    const { loggedIn, render, component, children, ...rest } = props;
    const Component = component;

    return (
        <Route
            {...rest}
            render={(route) =>{
                const state: RedirectState = route.location ? route.location.state as any || defaultState : defaultState;

                return loggedIn
                    ? <Redirect to={state.from}/>
                    : (Component ? <Component {...route} /> : (
                        render ? render(route) : children
                    ));
            }}
        />
    );
};

export interface RedirectState {
    from: string;
}

export interface LoggedInProps {
    loggedIn: boolean;
}

export interface RouteProps extends BaseProps, LoggedInProps {
}
