import React from "react";
import { ColDef, ICellRendererParams, ValueFormatterParams } from "@ag-grid-community/core";
import { isNotNull } from "modules/common/services/typescript";
import { fields, SpendingsListRowSnapshotType, removeConfiramtionName } from "../models/spending-list-row";
import { gridMoneyFormatterWithCurrency } from "modules/common/components/money/Money";
import { routes } from "modules/common/routes";
import { OrderTabs } from "modules/orders-manage/details/OrderDetailsTabsPage";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { texts } from "modules/common/texts";
import { PARAM_SECTION, PARAM_RECORD } from "modules/orders-manage/models/order";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { EmployerSnapshotType } from "modules/spending/employee/models/employer";
import { apiUrls } from "modules/common/services/communication/urls";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

export const columnsBuilder = (baseUrl: string): ColDef[] => {
    const result: (ColDef | null)[] = [
        {
            headerName: "",
            field: fields.isProduction,
            width: 5,
            minWidth: 5,
            sortable: true,
            cellClass: "flat",
            headerClass: "flat centered",
            cellRendererFramework: (params: ICellRendererParams) => {
                if (params.value) {
                    return (
                        <div
                            style={{ backgroundColor: "#E31818", minWidth: "5px", height: "26px" }}
                            className="order-color"
                        />
                    );
                } else {
                    return (
                        <div
                            style={{ backgroundColor: "#1DD278", minWidth: "5px", height: "26px" }}
                            className="order-color"
                        />
                    );
                }
            },
        },
        {
            suppressMovable: true,
            headerName: "Дата",
            field: fields.sortableDate,
            width: 85,
            sortable: true,
            valueFormatter: ({ data }: ValueFormatterParams) => data[fields.date],
        },
        {
            suppressMovable: true,
            headerName: "Доход",
            field: fields.incomingSum,
            width: 120,
            sortable: true,
            valueFormatter: gridMoneyFormatterWithCurrency,
        },
        {
            suppressMovable: true,
            headerName: "Расход",
            field: fields.outcomingSum,
            width: 120,
            sortable: true,
            valueFormatter: gridMoneyFormatterWithCurrency,
        },
        {
            suppressMovable: true,
            headerName: "Объект",
            field: fields.orderName,
            width: 200,
            sortable: true,

            keyCreator: function (params) {
                return params.data.fullInventoryNumber;
            },
            cellRendererFramework: ({ data, value }: ICellRendererParams) => {
                const row = data as SpendingsListRowSnapshotType;
                const { order } = row;

                if (!order) {
                    return value;
                }

                let path = "";

                if (row.isIncoming) {
                    // доход - ссылка на вкладку финансирования
                    path = routes.orders.details(order.id, OrderTabs.finance);
                } else {
                    // расход - ссылка на вкладку затрат
                    path = routes.orders.details(order.id, OrderTabs.spending);

                    // указание на конкрентный объект во вкладке затрат
                    if (row.orderSpending) {
                        path += `&${PARAM_SECTION}=${row.orderSpending.label}`;
                    }
                }

                path += `&${PARAM_RECORD}=${row.id}`;

                return (
                    <a href={path} target="_blank" rel="noopener noreferrer">
                        {value}
                    </a>
                );
            },
        },
        {
            suppressMovable: true,
            headerName: "Виды работ",
            field: fields.workTypeName,
            width: 280,
            sortable: true,
        },
        {
            suppressMovable: true,
            headerName: "Контрагент",
            field: fields.agentName,
            width: 200,
            sortable: true,

            cellRendererFramework: AgentRenderer,
        },
        {
            suppressMovable: true,
            headerName: "Файл",
            field: fields.document,
            width: 65,
            sortable: false,

            cellRendererFramework: (params: ICellRendererParams) => {
                const { document } = params.data as SpendingsListRowSnapshotType;

                const src = document
                    ? baseUrl + apiUrls.application.files.content(document.fileId, document.fileName)
                    : "";

                return src ? (
                    <a href={src} target="_blank" rel="noopener noreferrer" style={{ border: "none" }}>
                        <GeneralIcon type={"general-import"} />
                    </a>
                ) : null;
            },
        },
        {
            suppressMovable: true,
            headerName: texts.comment,
            field: fields.comment,
            flex: 1,
            sortable: false,
        },
        {
            suppressMovable: true,
            headerName: "",
            field: "**",
            width: 80,
            cellClass: "flat no-print",
            headerClass: "no-print",
            cellRendererFramework: ({ context, data }: any) => {
                const actions: ContextActions = context;
                const row: SpendingsListRowSnapshotType = data;
                const canRevert = !!row.bankRowId;

                return (
                    <RemoveConfirmation<SpendingsListRowSnapshotType>
                        what={removeConfiramtionName}
                        onConfirmed={actions.removeRow}
                        render={({ confirmRemoving }) => {
                            return (
                                <PlanrButton
                                    type="primary"
                                    onClick={() => confirmRemoving(row)}
                                    style={{
                                        height: "20px",
                                        fontSize: "10px",
                                        backgroundColor: "red",
                                        padding: "0px 7px",
                                    }}
                                >
                                    {texts.remove}
                                </PlanrButton>
                            );
                        }}
                        removeOptions={canRevert ? [{ id: "revert", label: "Сбросить назначение" }] : undefined}
                    />
                );
            },
        },
    ];

    return result.filter(isNotNull);
};

export interface ContextActions {
    removeRow: (row: SpendingsListRowSnapshotType, option?: string) => void;
}

export const AgentRenderer = ({ data, value }: ICellRendererParams) => {
    const row: SpendingsListRowSnapshotType = data;
    if (!row.agent) {
        return row.comment || "";
    }

    let path = "";

    if (row.agent.type === "Аутсорсер") {
        path = routes.agents.outsourcers.details.outsourcers(row.agent.id);
    } else if (row.agent.type === "Сотрудник") {
        path = routes.spending.employee.details(row.agent.id);
    } else if (row.agent.type === "Агент") {
        path = routes.agents.outsourcers.details.outsourcers(row.agent.id);
    } else if (row.agent.type === "Клиент") {
        path = routes.agents.outsourcers.details.outsourcers(row.agent.id);
    }

    return path ? (
        <a href={path} target="_blank" rel="noopener noreferrer">
            {value}
        </a>
    ) : (
        value
    );
};

export const EmployeeRenderer = ({ data, value }: ICellRendererParams) => {
    const row: EmployerSnapshotType = data;
    let path = routes.spending.employee.details(row.id);

    return path ? (
        <a href={path} target="_blank" rel="noopener noreferrer">
            {row.name}
        </a>
    ) : (
        row.name
    );
};
