import React, { useCallback } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import styles from "./CategoryDetails.module.scss";
import CategorySchema from "./category-validation";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { InputGroup, Classes } from "@blueprintjs/core";
import { CategoryType, fields } from "modules/dictionaries/inventory/models/category";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { texts } from "modules/common/texts";
import { Prompt } from "react-router";
import { onlyDigitsInputKeyPress } from "modules/common/services/form/values";

const digits = onlyDigitsInputKeyPress();
const schema = CategorySchema();

export const CategoryDetails: React.FC<CategoryDetailsProps> = observer((props) => {
    const { category, canChange, onSaved, onRemoved } = props;

    const onSubmit = useCallback(
        async (values: FormValues, form: FormikHelpers<FormValues>) => {
            if (!canChange) {
                return;
            }

            const succsessfull = await category.save(values);
            form.setSubmitting(false);

            if (succsessfull) {
                form.resetForm();
                onSaved();
            }
        },
        [canChange, onSaved, category]
    );

    return (
        <div className={styles.details}>
            <Formik<FormValues>
                initialValues={getFormValues(category)}
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={onSubmit}
            >
                {(formProps) => {
                    const onRemove = async () => {
                        if (!canChange) {
                            return;
                        }

                        formProps.setSubmitting(true);
                        const success = await category.delete();
                        formProps.setSubmitting(false);
                        success && onRemoved();
                    };

                    return (
                        <Form autoComplete="off">
                            <Prompt when={formProps.dirty && canChange} message={texts.messages.leaveConfiramtion} />
                            <div className={Classes.DIALOG_BODY}>
                                <StandardFormInput
                                    name={fields.inventoryNumber}
                                    schema={schema}
                                    small={true}
                                    className="planr-form-input"
                                >
                                    {({ field }) => (
                                        <InputGroup
                                            id={field.name}
                                            className="planr-default-input"
                                            {...field}
                                            type="text"
                                            autoComplete="off"
                                            data-lpignore="true"
                                            onKeyPress={digits}
                                            disabled={!canChange}
                                        />
                                    )}
                                </StandardFormInput>

                                <StandardFormInput
                                    name={fields.name}
                                    schema={schema}
                                    small={true}
                                    className="planr-form-input"
                                >
                                    {({ field }) => (
                                        <InputGroup
                                            className="planr-default-input"
                                            id={field.name}
                                            {...field}
                                            autoComplete="off"
                                            data-lpignore="true"
                                            disabled={!canChange}
                                        />
                                    )}
                                </StandardFormInput>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                {canChange && (
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        <StandardFormButtons
                                            {...formProps}
                                            isRemoved={false}
                                            what={`отдел ${category.name}`}
                                            isNewlyCreated={category.isNewlyCreated}
                                            onRemove={onRemove}
                                        />
                                    </div>
                                )}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
});

interface CategoryDetailsProps {
    category: CategoryType;
    canChange: boolean;
    onSaved: () => void;
    onRemoved: () => void;
}

function getFormValues(category: CategoryType) {
    return {
        ...getSnapshot(category),
    };
}

type FormValues = ReturnType<typeof getFormValues>;
