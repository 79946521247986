import { types } from 'mobx-state-tree';
import { WorkTypeList, WorkType, initialState as emptyList } from './work-type-list';
import { initialState as emptyWorkType } from './work-type';
import { Transport } from 'modules/common/models/transport';
import { Notificator } from 'modules/common/models/notificator';

export const WorkTypesStore = types.compose(
    Transport, Notificator, types.model({
        list: WorkTypeList,
        details: WorkType
    }))
    .named('WorkTypesStore');

export const initialState = (): typeof WorkTypesStore.SnapshotType => ({
    list: emptyList(),
    details: emptyWorkType()
});

export type WorkTypesStoreType = typeof WorkTypesStore.Type;
