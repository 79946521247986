import React, { useCallback, useEffect, useRef } from "react";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Block } from "modules/common/components/page/Block";
import { Route, matchPath, useHistory } from "react-router";
import { History } from "history";
import { routes } from "modules/common/routes";
import { observer } from "mobx-react";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { IndicatorDetails } from "./details/IndicatorsDetails";
import { IndicatorsList } from "./list/IndicatorsList";
import { can } from "modules/session/auth/access";
import functionalities from "modules/dictionaries/functionalities";
import { useMst } from "modules/root/models/hooks";
import styles from "./IndicatorsPage.module.scss";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { Search } from "modules/common/components/form/Search";
import { Link } from "react-router-dom";

interface IndicatorsPageProps {
  access: string[];
}

const openIndicator = (history: History, id: string) => {
  const url = id
    ? routes.dictionaries.orderIndicator.indicatorsDetails(id)
    : routes.dictionaries.orderIndicator.pathIndicators;
  history.push(url);
};

const getSelectedIndicatorId = (pathname: string) => {
  const match = matchPath<any>(pathname, routes.dictionaries.orderIndicator.indicatorsDetails());
  return match && match.params ? match.params.id : "";
};

export const IndicatorsPage = observer((props: IndicatorsPageProps) => {
  const history = useHistory();
  const { indicators } = useMst();
  const deprGroupId = useRef("");

  useEffect(() => {
    document.title = "Ед измерения";
    indicators.list.load();
    indicators.details.load(getSelectedIndicatorId(history.location.pathname));
    return history.listen((loc) => {
      const currentIndicatorId = getSelectedIndicatorId(loc.pathname);

      if (deprGroupId.current !== currentIndicatorId) {
        deprGroupId.current = currentIndicatorId;
        indicators.details.load(currentIndicatorId);
      }
    });
  }, [history, indicators.list, indicators.details]);

  const onIndicatorSelected = useCallback((id = "") => openIndicator(history, id), [history]);

  const onIndicatorClicked = (id = "") => {};

  const onIndicatorRemoved = useCallback(() => {
    indicators.list.load();
    history.push(routes.dictionaries.orderIndicator.pathIndicators);
  }, [history, indicators.list]);

  const onIndicatorSaved = useCallback(() => {
    openIndicator(history, "");
    indicators.list.load();
  }, [history, indicators.list]);

  const onIndicatorAdd = useCallback(() => openIndicator(history, EMPTY_OBJECT_ID), [history]);

  const canChange = can(functionalities.ORDERINDICATOR_EDIT, props.access);

  return (
    <div className={styles.container}>
      <Block columned={true} className={styles.page}>
        <Route path={routes.dictionaries.orderIndicator.indicatorsDetails()}>
          <DetailsPopup title="Ед. измерения" onCancel={onIndicatorSelected}>
            <IndicatorDetails
              indicator={indicators.details}
              onSaved={onIndicatorSaved}
              onRemoved={onIndicatorRemoved}
              canChange={canChange}
            />
          </DetailsPopup>
        </Route>
        <span className={styles.topSpan}>Ед. измерения</span>

        <div className={styles.tools}>
          {canChange && (
            <PlanrButton
              type="secondary"
              size="small"
              icon="general-plus-big"
              onClick={onIndicatorAdd}
              style={{
                // width: "220px",
                marginBottom: "32px",
                borderRadius: "120px",
              }}
            >
              Добавить ед измерения
            </PlanrButton>
          )}
          <Link to={routes.dictionaries.orderIndicator.path} className="planr-button-link">
            <PlanrButton
              type="lightblueish"
              size="small"
              style={{
                width: "200px",
                marginBottom: "32px",
                borderRadius: "120px",
              }}
            >
              ТЭП
            </PlanrButton>
          </Link>
        </div>
        <div className={styles.mobileTools}>
          {canChange && <PlanrButton type="secondary" round icon="general-plus-big" onClick={onIndicatorAdd} />}
        </div>
        <div className={styles.right}>
          <Search query={indicators.list.query} onSearch={indicators.list.setQuery} />
        </div>
        <div className={styles.mobileTools} style={{ marginTop: "10px" }}>
          <Link to={routes.dictionaries.orderIndicator.path} className="planr-button-link">
            <PlanrButton
              type="lightblueish"
              size="small"
              style={{
                width: "200px",
                borderRadius: "120px",
              }}
            >
              ТЭП
            </PlanrButton>
          </Link>
        </div>
        <IndicatorsList
          list={indicators.list}
          onSelected={onIndicatorSelected}
          onClicked={onIndicatorClicked}
          onRemoved={onIndicatorRemoved}
        />
      </Block>
    </div>
  );
});
