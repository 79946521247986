import { types } from "mobx-state-tree";
import { DeprGroupList, DeprGroup, initialState as emptyList } from "./depr-group-list";
import { initialState as emptyDeprGroup } from "./depr-group";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";

export const DeprGroupsStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            list: DeprGroupList,
            details: DeprGroup,
        })
    )
    .named("DeprGroupsStore");

export const initialState = (): typeof DeprGroupsStore.SnapshotType => ({
    list: emptyList(),
    details: emptyDeprGroup(),
});

export type DeprGroupsStoreType = typeof DeprGroupsStore.Type;
