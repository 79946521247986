import { types } from "mobx-state-tree";
import { MaterialValueList, MaterialValue, initialState as emptyList } from "./material-value-list";
import { initialState as emptyMaterialValue, MaterialValueType } from "./material-value";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { CategoryDictionary } from "./category-dictionary";
import { DeprGroupDictionary } from "./depr-group-dictionary";
import { convertTextToImage, printImage } from "modules/common/services/files";
import {
    EmployerDictionary,
    initialState as emptyEmployee,
} from "modules/spending/employee/models/employee-dictionary";
import { CategoryList, initialState as categoryState } from "./category-list";

export const MaterialValuesStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            list: MaterialValueList,
            details: MaterialValue,
            categories: CategoryDictionary,
            deprGroups: DeprGroupDictionary,
            employers: EmployerDictionary,
            categoriesList: CategoryList,
        })
    )
    .actions((self) => ({
        init: function (employerId: string) {
            self.categories.load();
        },
        update: function () {
            self.categoriesList.load();
        },
        printCredentials: function (materialValue: MaterialValueType) {
            const credentials = materialValue.fullInventoryNumber;

            if (credentials) {
                const dataURL = convertTextToImage([`Инвентарный номер : ${credentials}`]);
                printImage(dataURL);
            }
        },
    }))
    .named("MaterialValuesStore");

export const initialState = (): typeof MaterialValuesStore.SnapshotType => ({
    categories: { categories: [] },
    deprGroups: { deprGroups: [] },
    employers: emptyEmployee(),
    list: emptyList(),
    categoriesList: categoryState(),
    details: emptyMaterialValue(),
});

export type MaterialValuesStoreType = typeof MaterialValuesStore.Type;
