import { observer } from "mobx-react";
import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import styles from "./Linker.module.scss";
import { PlanPayment } from "./PlanPayment";
import portal from "../portal";
import { OrderStagesStoreType, OrderStageRowType } from "modules/orders-manage/models/order-stages-store";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { PlanOrderPaymentDetailsRowType } from "../../models/order-stages-store";

export const LinkPlanPayments = observer(({ stage, type, store }: LinkPlanPaymentsProps) => {
    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (result.destination) {
                const guid = result.destination.droppableId === "unlink" ? "" : stage.newGuid;
                const paymentGuid = result.draggableId;
                const payment = store.planMap[paymentGuid];

                payment.linkToStage(stage.newGuid, guid, type);
            }
        },
        [stage, store, type]
    );

    const unlinkPayment = (payment: PlanOrderPaymentDetailsRowType) => {
        payment.linkToStage(stage.newGuid, "", type);
    };

    const { used, available } = splitByStage(store, stage.newGuid, type);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className={styles.paymentLinker}>
                <div>
                    <h2>{stage.name}</h2>
                    <Droppable droppableId={stage.newGuid}>
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={`${styles.dropHere} ${snapshot.isDraggingOver ? styles.isDraggingOver : ""}`}
                            >
                                {used.map((payment, index) => (
                                    <Draggable key={payment.newGuid} draggableId={payment.newGuid} index={index}>
                                        {(draggable, sn) => {
                                            const child = (
                                                <PlanPayment
                                                    payment={payment}
                                                    key={payment.newGuid}
                                                    draggable={draggable}
                                                >
                                                    <div className={styles.unlink}>
                                                        <GeneralIcon
                                                            type="general-cross-small"
                                                            onClick={() => unlinkPayment(payment)}
                                                            title="Отвязать платеж"
                                                        />
                                                    </div>
                                                </PlanPayment>
                                            );
                                            return sn.isDragging ? ReactDOM.createPortal(child, portal) : child;
                                        }}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                <div className={styles.pointer}>Перетащите нужный платеж сюда</div>
                            </div>
                        )}
                    </Droppable>
                </div>

                <div>
                    <h2>Все плановые платежи</h2>
                    <Droppable droppableId="unlink">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={`${styles.dropHere} ${snapshot.isDraggingOver ? styles.isDraggingOver : ""}`}
                            >
                                {available.map((payment, index) => (
                                    <Draggable key={payment.newGuid} draggableId={payment.newGuid} index={index}>
                                        {(draggable, sn) => {
                                            const child = (
                                                <PlanPayment
                                                    payment={payment}
                                                    key={payment.newGuid}
                                                    draggable={draggable}
                                                />
                                            );
                                            return sn.isDragging ? ReactDOM.createPortal(child, portal) : child;
                                        }}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </div>
        </DragDropContext>
    );
});

interface LinkPlanPaymentsProps {
    type: string;
    stage: OrderStageRowType;
    store: OrderStagesStoreType;
}

function splitByStage(self: OrderStagesStoreType, stage: string, type: string) {
    const used: PlanOrderPaymentDetailsRowType[] = [];
    const available: PlanOrderPaymentDetailsRowType[] = [];

    self.planPayments.forEach((payment: PlanOrderPaymentDetailsRowType) => {
        if (payment.removed) {
            return;
        }

        if (payment.stageGuid === stage && payment.type === type) {
            used.push(payment);
        } else {
            available.push(payment);
        }
    });

    return { used, available };
}
