import * as yup from "yup";
import { fields, OutsourcerSnapshotType } from "modules/agents/outsourcers/models/outsourcer";
import { BankDetailsSchema } from "modules/common/models/bank-details";
import { ONLY_DIGITS_REGEXP } from "modules/common/constants";
import { extractDigits } from "modules/common/services/strings";
import { phoneValidator } from "modules/common/services/form/values";
import { texts } from "modules/common/texts";
import { PassportSchema } from "../../models/passport";
const { object, string, bool } = yup;

export const LegalNameLabel = texts.name;
export const PhysycalNameLabel = "ФИО";

export const MAX_WORK_TYPES = 10;

export const outsourcerLabels = () => ({
  [fields.memberName]: "ФИО представителя",
  [fields.site]: "Сайт",
  [fields.region]: "Регион нахождения",
  [fields.isAgent]: "Доступен как агент",
  [fields.isAgentReward]: "Агентское вознаграждение",
  [fields.agentReward]: "Значение в %",
  [fields.сategories]: "Категория",
  [fields.materials]: "Поставляемое оборудование или материалы",
  [fields.materialDocuments]: "Демонстрационные материалы",
  //
  [fields.fullName]: "Полное наименование",
  [fields.birthday]: "Дата рождения",
  [fields.phone]: "Телефон 1",
  [fields.phone2]: "Телефон 2",
  [fields.phone3]: "Телефон 3",
  [fields.email]: "E-mail",
  [fields.cardNumber]: "# Карты",
  [fields.comment]: texts.comment,
  [fields.priceRating]: "Рейтинг цены",
  [fields.qualityRating]: "Рейтинг качества",
  [fields.timeRating]: "Рейтинг сроков",
  [fields.workTypeIds]: "",
  [fields.isConfirmed]: "Одобрен",
  [fields.isBim]: "BIM",
  [fields.selfEmployed]: "Самозанятый",
  [fields.selfEmployedAccount]: "Справка о рег. в качестве самозанятого",
  [fields.selfEmployedAccountDate]: "Дата выдачи справки",
  [fields.login]: "Логин",
  [fields.isLegal]: "Физ. лицо",

  [fields.newPassword]: "Пароль",
  [fields.hasAgentOrder]: "Агентское соглашение",
  [fields.hasSupplierOrder]: "Агентский договор",
});

const OutsourcerSchema = (isLegalEntity: boolean) => {
  const labels = outsourcerLabels();
  const bankDetails = BankDetailsSchema();
  const passport = PassportSchema();
  return object().shape({
    [fields.name]: isLegalEntity
      ? string().required().label(LegalNameLabel)
      : string().required().label(PhysycalNameLabel),

    [fields.fullName]: isLegalEntity
      ? string().label(labels[fields.fullName]).required()
      : string().label(labels[fields.fullName]).notRequired(),

    [fields.genitiveName]: isLegalEntity
      ? string().required().label("Директор (Родит. падеж)")
      : string().required().label("ФИО в родительном падеже"),

    [fields.phone]: phoneValidator(
      labels[fields.phone],
      string().test("needPhone", "Необходимо указать телефон", function (value) {
        const agent: OutsourcerSnapshotType = this.parent;

        return !!value || !!agent.email;
      })
    ),

    [fields.phone2]: phoneValidator(labels[fields.phone2]),

    [fields.phone3]: phoneValidator(labels[fields.phone3]),

    [fields.email]: string()
      .label(labels[fields.email])
      .test("needEmail", "Необходимо указать email", function (value) {
        const agent: OutsourcerSnapshotType = this.parent;

        return !!value || !!agent.phone;
      })
      .email(),

    [fields.cardNumber]: string()
      .label(labels[fields.cardNumber])
      .notRequired()
      .transform(function (val, original) {
        return this.isType(val) && val ? extractDigits(val) : val;
      })
      .matches(ONLY_DIGITS_REGEXP),

    [fields.comment]: string().label(labels[fields.comment]).notRequired(),

    [fields.memberName]: string().label(labels[fields.memberName]).notRequired(),
    [fields.site]: string().label(labels[fields.site]).notRequired(),
    [fields.region]: string().label(labels[fields.region]).notRequired(),
    [fields.isAgent]: string().label(labels[fields.isAgent]).notRequired(),
    [fields.isAgentReward]: string().label(labels[fields.isAgentReward]).notRequired(),
    [fields.agentReward]: yup
      .number()
      .label(labels[fields.agentReward])
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(0),
    [fields.сategories]: string().label(labels[fields.сategories]).notRequired(),
    [fields.materials]: string().label(labels[fields.materials]).notRequired(),
    [fields.materialDocuments]: string().label(labels[fields.materialDocuments]).notRequired(),

    [fields.priceRating]: yup.number().label(labels[fields.priceRating]).min(1).max(5),

    [fields.qualityRating]: yup.number().label(labels[fields.qualityRating]).min(1).max(5),

    [fields.timeRating]: yup.number().label(labels[fields.timeRating]).min(1).max(5),

    [fields.isConfirmed]: yup.boolean().label(labels[fields.isConfirmed]),

    [fields.isBim]: yup.boolean().label(labels[fields.isBim]),

    [fields.selfEmployed]: yup.boolean().label(labels[fields.selfEmployed]),
    [fields.isLegal]: yup.boolean().label(labels[fields.isLegal]),

    [fields.selfEmployedAccount]: yup.string().label(labels[fields.selfEmployedAccount]).notRequired(),

    [fields.birthday]: yup
      .date()
      .label(labels[fields.birthday])
      .typeError(texts.messages.incorrectDate())
      .notRequired()
      .nullable(),

    [fields.selfEmployedAccountDate]: yup
      .date()
      .label(labels[fields.selfEmployedAccountDate])
      .typeError(texts.messages.incorrectDate())
      .notRequired()
      .nullable(),

    [fields.workTypeIds]: yup
      .array()
      .of(yup.string().label("Вид работ").required())
      .label("")
      .ensure()
      .max(MAX_WORK_TYPES),

    [fields.bankDetails]: isLegalEntity ? bankDetails.required() : bankDetails.nullable().notRequired(),

    [fields.passport]: passport,

    [fields.login]: string()
      .label(labels[fields.login])
      .when(fields.newPassword, {
        is: (pass) => !!pass,
        then: string().required().min(5),
        otherwise: string().notRequired(),
      }),
    [fields.hasAgentOrder]: bool().label(labels[fields.hasAgentOrder]),
    [fields.hasSupplierOrder]: bool().label(labels[fields.hasSupplierOrder]),
    [fields.newPassword]: string().label(labels[fields.newPassword]),
  });
};

export default OutsourcerSchema;
