import * as yup from "yup";
import { fields, childFields } from "modules/spending/employee/models/employer";
import { texts } from "modules/common/texts";
import { extractDigits } from "modules/common/services/strings";
const { object, string, date, boolean } = yup;

export const employerLabels = () => ({
    [fields.name]: "ФИО",
    [fields.phone]: "Телефон",
    [fields.email]: "E-mail",
    [fields.login]: "Логин",
    [fields.newPassword]: "Пароль",
    [fields.poohBah]: "Совместитель",
    [fields.birthday]: "День рождения",
    [fields.positionId]: "Должность",
    [fields.position]: "Должность",
    [fields.fireDate]: "Дата увольнения",
    [fields.hireDate]: "Дата приема",
    [fields.contractNumber]: "№ трудового договора",
    [fields.account]: "Данные для перечисления з/п",
    [fields.address]: "Адрес",
    [fields.workSince]: "Стаж с",
    [fields.fired]: "Уволен(а)",
    [fields.isBim]: "BIM",
    [fields.canBeEngineer]: "Доступен как ГИП",
    [fields.salary]: "Оклад",
    [fields.bonus]: "Премия",
    [fields.changeSalary]: "Новый оклад",
    [fields.changeBonus]: "Новая премия",
    [fields.changePositionId]: "Новая должность",
    [fields.changePositionDate]: "С даты",
    [fields.canBeReturn]: "Бумеранг",
    [fields.hobby]: "Хобби",
});

const EmployerSchema = () => {
    const labels = employerLabels();

    return object().shape({
        [fields.name]: string().label(labels[fields.name]).required(),

        [fields.email]: string().label(labels[fields.email]).notRequired().email(),

        [fields.phone]: string()
            .label(labels[fields.phone])
            .transform(function (val, _) {
                return this.isType(val) && val ? extractDigits(val) : val;
            })
            .required()
            .min(7, "Минимум 7 цифр")
            .max(15, "Максимум 15 цифр"),

        [fields.positionId]: string().label(labels[fields.positionId]).required(),

        [fields.birthday]: date()
            .label(labels[fields.birthday])
            .typeError(texts.messages.incorrectDate())
            .notRequired()
            .nullable(),

        [fields.fired]: boolean().label(labels[fields.fired]),

        [fields.fireDate]: date()
            .label(labels[fields.fireDate])
            .when(fields.fired, {
                is: true,
                then: date().typeError(texts.messages.incorrectDate()).required(),
                otherwise: date().typeError(texts.messages.incorrectDate()).nullable().notRequired(),
            }),

        [fields.address]: string().label(labels[fields.address]),
        [fields.hobby]: string().label(labels[fields.hobby]).nullable().notRequired(),

        [fields.contractNumber]: string().label(labels[fields.contractNumber]),

        [fields.account]: string().label(labels[fields.account]),

        [fields.login]: string()
            .label(labels[fields.login])
            .when(fields.newPassword, {
                is: (pass) => !!pass,
                then: string().required().min(5),
                otherwise: string().notRequired(),
            }),

        [fields.newPassword]: string().label(labels[fields.newPassword]),

        [fields.hireDate]: date()
            .label(labels[fields.hireDate])
            .typeError(texts.messages.incorrectDate())
            .nullable()
            .notRequired(),

        [fields.birthday]: date()
            .label(labels[fields.birthday])
            .typeError(texts.messages.incorrectDate())
            .nullable()
            .notRequired(),

        [fields.workSince]: date()
            .label(labels[fields.workSince])
            .typeError(texts.messages.incorrectDate())
            .nullable()
            .notRequired(),

        [fields.poohBah]: boolean().label(labels[fields.poohBah]),

        [fields.isBim]: boolean().label(labels[fields.isBim]),
        [fields.canBeReturn]: boolean().label(labels[fields.canBeReturn]),

        [fields.canBeEngineer]: boolean().label(labels[fields.canBeEngineer]),

        [fields.salary]: yup
            .number()
            .label(labels[fields.salary])
            .transform((value) => (isNaN(value) ? undefined : value))
            .required()
            // eslint-disable-next-line no-template-curly-in-string
            .typeError("${path} должен быть числом")
            .min(0),

        [fields.bonus]: yup
            .number()
            .label(labels[fields.bonus])
            .transform((value) => (isNaN(value) ? undefined : value))
            .required()
            // eslint-disable-next-line no-template-curly-in-string
            .typeError("${path} должна быть числом")
            .min(0),

        [fields.changePositionId]: string().label(labels[fields.changePositionId]).notRequired(),
        [fields.changeSalary]: yup
            .number()
            .label(labels[fields.changeSalary])
            .transform((value) => (isNaN(value) ? undefined : value))
            .notRequired()
            // eslint-disable-next-line no-template-curly-in-string
            .typeError("${path} должен быть числом")
            .min(0),

        [fields.changeBonus]: yup
            .number()
            .label(labels[fields.changeBonus])
            .transform((value) => (isNaN(value) ? undefined : value))
            .notRequired()
            // eslint-disable-next-line no-template-curly-in-string
            .typeError("${path} должна быть числом")
            .min(0),

        [fields.changePositionDate]: date()
            .label(labels[fields.changePositionDate])
            .typeError(texts.messages.incorrectDate())
            .nullable()
            .notRequired(),
    });
};

export default EmployerSchema;

export const childLabels = () => ({
    [childFields.name]: "ФИО",
    [childFields.birthday]: "День рождения",
});

export const ChildSchema = () => {
    const labels = employerLabels();

    return object().shape({
        [fields.name]: string().label(labels[fields.name]).required(),

        [fields.birthday]: date()
            .label(labels[fields.birthday])
            .typeError(texts.messages.incorrectDate())
            .notRequired()
            .nullable(),
    });
};
