import React from "react";
import { observer } from "mobx-react";
import { KnowledgeBaseStoreType, fields, NodeType } from "modules/technical/models/store";
import {
    Tree,
    IBreadcrumbProps,
    Breadcrumbs,
    Breadcrumb,
    Popover,
    Position,
    PopoverInteractionKind,
    Menu,
    MenuItem,
} from "@blueprintjs/core";
import styles from "./FileSystemPage.module.scss";
import { TableSorterType, lodashComparator } from "modules/common/models/table-sorter";
import { onSnapshot, IDisposer } from "mobx-state-tree";
import { ITreeNode } from "@blueprintjs/core/lib/esnext";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { orderBy, groupBy, sortBy } from "lodash";
import { toString } from "modules/common/services/strings";
import { FolderActions } from "./FolderActions";
import { FileUploader } from "modules/common/components/files/Uploader";
import { texts } from "modules/common/texts";
import { FileActions, NodeData } from "./FileActions";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

export class FileSystemPage extends React.PureComponent<FileSystemPageProps, FileSystemPageState> {
    map: TStringMap<ITreeNode<NodeData>> = {};
    disposer1: IDisposer;
    disposer2: IDisposer;

    constructor(props: FileSystemPageProps) {
        super(props);

        this.state = { tree: [], path: [], selection: null };

        this.disposer1 = onSnapshot(props.store.nodes, this.updateState);
        this.disposer2 = onSnapshot(props.store.sorter, this.updateState);
    }

    updateState = () => {
        const { previewFile, store } = this.props;
        const { nodes, sorter } = store;

        const data: any = orderBy(nodes, ...lodashComparator(sorter));
        const { files, folders } = extractChildren(data, EMPTY_OBJECT_ID);
        const current = this.state.selection;
        let selection: ITreeNode<NodeData> | null = null;

        const accumulation: TStringMap<ITreeNode<NodeData>> = {};
        const accumulate: Accumulate = (doc) => {
            accumulation[doc.id] = doc;

            if (current && current.id === doc.id) {
                selection = doc;
                selection.isSelected = current.isSelected;
            }

            const prev = this.map[doc.id];
            if (prev) {
                doc.isExpanded = prev.isExpanded;
                doc.icon = prev.icon;
            }

            return doc;
        };

        const tree = folders
            .map((f) => this.makeTree(accumulate, data, f))
            .concat(files.map((f) => accumulate(this.mapDocument(f))));

        this.map = accumulation;
        this.setState({ tree, selection });
        previewFile && previewFile(null as any);
    };

    componentDidMount() {
        this.props.store.load();
    }

    componentWillUnmount() {
        this.disposer1();
        this.disposer2();
    }

    render() {
        const { store } = this.props;

        return (
            <div className={`${styles.page} file-system`}>
                <div className={styles.panel}>
                    <div className={styles.navigtor}>
                        <Breadcrumbs
                            breadcrumbRenderer={this.breadcrumbRenderer}
                            currentBreadcrumbRenderer={this.currentBreadcrumbRenderer}
                            items={this.state.path}
                            minVisibleItems={1}
                        />
                        <div className={styles.actions}>{this.actionsRenderer()}</div>
                    </div>
                    <div className={styles.sorter}>
                        <Sorter
                            field={fields.name}
                            label="Название папки/файла"
                            className={styles.name}
                            sorter={store.sorter}
                        />
                        <Sorter
                            field={fields.created}
                            label="Изменения"
                            className={styles.date}
                            sorter={store.sorter}
                        />
                    </div>
                </div>
                <Tree
                    contents={this.state.tree}
                    onNodeCollapse={this.collapseFolder}
                    onNodeExpand={this.expandFolder}
                    onNodeClick={this.onClick}
                />
            </div>
        );
    }

    breadcrumbRenderer = ({ text, target, current }: IBreadcrumbProps) => {
        const id = target || "";
        const node = this.map[id];

        return (
            <Breadcrumb onClick={() => this.selectNode(node, true)} className={current ? "last" : ""}>
                {text}
            </Breadcrumb>
        );
    };

    currentBreadcrumbRenderer = (props: IBreadcrumbProps) => {
        return this.breadcrumbRenderer({ ...props, current: true });
    };

    actionsRenderer = () => {
        const { selection } = this.state;

        if (!selection || isFolder(selection)) {
            const folder = selection ? this.props.store.asMap[selection.id] : null;

            return (
                <FolderActions
                    folder={folder}
                    canManage={this.props.canManage}
                    uploadFile={this.props.store.uploadFile}
                    remove={this.props.store.removeFolder}
                    create={this.props.store.createFolder}
                    rename={this.props.store.renameFolder}
                />
            );
        }

        if (selection && !isFolder(selection)) {
            return (
                <FileActions
                    canManage={this.props.canManage}
                    file={selection}
                    remove={this.props.store.removeFile}
                    rename={this.props.store.renameFile}
                />
            );
        }

        return null;
    };

    collapseFolder = (node: ITreeNode) => {
        node.isExpanded = false;
        node.icon = "folder-close";
        const tree = [...this.state.tree];
        this.setState({ tree });
    };

    expandFolder = (node: ITreeNode) => {
        node.isExpanded = true;
        node.icon = "folder-open";
        const tree = [...this.state.tree];
        this.setState({ tree });
    };

    selectNode = async (node: ITreeNode<NodeData>, keepCurrent = false) => {
        let path: IBreadcrumbProps[] = [];
        let selection = this.state.selection;

        const select = () => {
            selection = node;
            selection.isSelected = true;
            path = this.makeCrumbs(node.id as string);
        };

        if (selection) {
            if (selection.id === node.id) {
                if (keepCurrent) {
                    return;
                }

                selection.isSelected = false;
                selection = null;
            } else {
                selection.isSelected = false;
                select();
            }
        } else {
            select();
        }

        const tree = [...this.state.tree];
        this.setState({ path, selection, tree });

        const { previewFile } = this.props;
        if (!isFolder(node) && node.nodeData) {
            const document = node.nodeData.meta;
            if (previewFile) {
                const success = await document.dowloadForPreview();
                success && previewFile(node);
            }
        } else {
            previewFile && previewFile(null as any);
        }
    };

    onClick = (node: ITreeNode<NodeData>) => this.selectNode(node);

    makeCrumbs = (id: string) => {
        const path: IBreadcrumbProps[] = [];

        const { store } = this.props;
        let node = store.asMap[id];

        while (node) {
            path.unshift({ text: node.label, target: node.id });
            node = store.asMap[node.parent];
        }

        return path;
    };

    makeTree = (accumulate: Accumulate, nodes: NodeType[], root: NodeType): ITreeNode<NodeData> => {
        const { files, folders } = extractChildren(nodes, root.id);

        const documents = files.map((f) => {
            const doc = this.mapDocument(f);
            return accumulate(doc);
        });
        const directories = folders.map((f) => this.makeTree(accumulate, nodes, f));

        const notEmpty = files.length > 0 || folders.length > 0;

        const result: ITreeNode<NodeData> = {
            id: root.id,
            label: root.label,
            // icon: notEmpty ? "folder-open" : "folder-close",
            icon: notEmpty ? "folder-open" : "folder-close",
            isExpanded: notEmpty,
            secondaryLabel: root.created,
            hasCaret: notEmpty,
            childNodes: directories.concat(documents),
        };

        return accumulate(result);
    };

    mapDocument = (root: NodeType): ITreeNode<NodeData> => {
        const folder = this.props.store.asMap[root.parent];

        return {
            id: root.id,
            label: root.label,
            icon: "document",
            secondaryLabel: this.secondaryLabel(root, folder),
            hasCaret: false,
            isExpanded: false,
            nodeData: {
                meta: root.file as any,
                version: root.version,
            },
        };
    };

    secondaryLabel = ({ guid, id, file, version, label, created }: NodeType, folder: NodeType) => {
        if (file === null) {
            return null;
        }

        const map = this.props.store.documentsMap;
        const versions = sortBy(
            map[guid].filter((f) => f.id !== id),
            (f) => -f.version
        );
        const menu = versions.length ? (
            <Menu>
                {versions.map((v) => (
                    <MenuItem
                        key={v.id}
                        text={`v${v.version} от ${v.created}`}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            file.save();
                        }}
                        title={texts.download}
                    />
                ))}
            </Menu>
        ) : undefined;

        const versionNode = <span>v{version}</span>;

        const versionLabel = menu ? (
            <Popover
                content={menu}
                position={Position.BOTTOM}
                interactionKind={PopoverInteractionKind.HOVER}
                boundary="viewport"
            >
                {versionNode}
            </Popover>
        ) : (
            versionNode
        );

        return (
            <div className={styles.fileLabel}>
                <div className={styles.version}>
                    {versionLabel}
                    {this.props.canManage && (
                        <FileUploader
                            accept={file.mimeType}
                            onFileSelected={(f) => this.props.store.uploadFile(f, folder, guid)}
                            className={styles.fileUploader}
                            icon="plus"
                            title="Загрузить новую версию"
                        />
                    )}
                </div>
                <div className={styles.date}>{created}</div>
            </div>
        );
    };
}

interface FileSystemPageProps {
    previewFile: (doc: ITreeNode<NodeData>) => void;
    store: KnowledgeBaseStoreType;
    canManage: boolean;
}

interface FileSystemPageState {
    tree: ITreeNode<NodeData>[];
    selection: ITreeNode<NodeData> | null;
    path: IBreadcrumbProps[];
}

const Sorter = observer(({ label, field, className, sorter }: SorterProps) => {
    const icon =
        sorter.column === field ? (
            <div>
                <GeneralIcon type={sorter.asc ? "general-icon-sort-by" : "general-icon-sort-by"} />
            </div>
        ) : undefined;

    return (
        <div className={className} onClick={() => sorter.sortBy(field)}>
            <div>{label}</div> <div>{icon}</div>
        </div>
    );
});

interface SorterProps {
    label: string;
    field: string;
    className?: string;
    sorter: TableSorterType;
}

function isFolder(node: ITreeNode) {
    const icon = toString(node.icon);
    return icon.includes("folder");
}

function extractChildren(nodes: NodeType[], root: string) {
    let files: NodeType[] = [];
    const folders: NodeType[] = [];

    nodes.forEach((f) => {
        if (f.parent === root) {
            if (f.isFolder) {
                folders.push(f);
            } else {
                files.push(f);
            }
        }
    });

    const temp = groupBy(files, (f) => f.guid);
    files = Object.values(temp).map((array) => {
        const sorted = array.length > 0 ? sortBy(array, (f) => f.version) : array;
        return sorted[sorted.length - 1];
    });

    return { files, folders };
}

type Accumulate = (node: ITreeNode<NodeData>) => ITreeNode<NodeData>;
