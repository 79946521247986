import React from "react";
import { IInputGroupProps, HTMLInputProps, InputGroup } from "@blueprintjs/core";
import NumberFormat, { NumberFormatValues } from "react-number-format";

export class PercentInput extends React.Component<PercentInputProps & HTMLInputProps> {
    render() {
        const { type, value, hideSuffix, onPercentChange, allowNegative, ...rest } = this.props;

        return (
            <NumberFormat
                {...rest}
                value={value === null || value === undefined ? "" : value}
                thousandSeparator={false}
                allowNegative={typeof allowNegative === "boolean" ? allowNegative : false}
                suffix={hideSuffix ? undefined : " %"}
                customInput={InputGroup}
                allowedDecimalSeparators={[".", ","]}
                onValueChange={onPercentChange}
            />
        );
    }
}

interface PercentInputProps extends IInputGroupProps {
    allowNegative?: boolean;
    onPercentChange: (value: NumberFormatValues) => void;
    hideSuffix?: boolean;
}
