import React from "react";
import { fields } from "modules/orders-manage/models/order";
import { observer } from "mobx-react";
import { Schema } from "yup";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import styles from "./Premium.module.scss";
import { Money } from "modules/common/components/money/Money";
import { onlyDigitsInputKeyPress } from "modules/common/services/form/values";
import { OrderPremiumBankType } from "modules/orders-manage/models/premium";
import { PercentInput } from "modules/common/components/form/PercentInput";

const digits = onlyDigitsInputKeyPress(true, false);

export const PremiumDestribution = observer((props: PremiumDestributionProps) => {
    return (
        <div>
            <h3>Распределение фонда</h3>
            <div className={styles.inputs}>
                {input(fields.premiumGipPercents, props)}

                {input(fields.premiumProductionPercents, props)}

                {input(fields.premiumNonProductionPercents, props)}
            </div>
        </div>
    );
});

const input = (name: string, { schema, disabled, bank }: PremiumDestributionProps) => (
    <StandardFormInput name={name} schema={schema} small={true} inline={false}>
        {({ field, form }) => {
            const percents = +field.value;

            return (
                <>
                    <PercentInput
                        id={field.name}
                        name={field.name}
                        onBlur={field.onBlur}
                        value={field.value}
                        onPercentChange={(e) => {
                            form.setFieldValue(field.name, e.floatValue);
                            form.setFieldTouched(field.name, true);
                        }}
                        className="planr-default-input"
                        autoComplete="off"
                        data-lpignore="true"
                        disabled={disabled}
                        onKeyPress={digits}
                        style={{ marginBottom: "8px" }}
                    />
                    <div className={styles.sum}>
                        <Money amount={(bank.calculatedSumm / 100) * percents} className="money-gray" />
                        <div className={styles.final}>
                            Корр:&nbsp;
                            <Money amount={(bank.finalSum / 100) * percents} className="money-gray" />
                        </div>
                    </div>
                </>
            );
        }}
    </StandardFormInput>
);

interface PremiumDestributionProps {
    bank: OrderPremiumBankType;
    schema: Schema<any>;
    disabled: boolean;
}
