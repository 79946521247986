import React from 'react';
import styles from './NoData.module.scss';
import { NonIdealState } from '@blueprintjs/core';

const title = 'Нет данных';

export const NoData = ({ className, compact }: NoDataProps) => compact ? (
    <div className={`${className || ''} ${styles.nodata}`}>
        {title}
    </div>
) : (
    <div className={`${className || ''} ${styles.nodata}`}>
        <NonIdealState
            icon="search"
            title={title}
        />
    </div>
);

interface NoDataProps {
    compact?: boolean;
    className?: string;
}
