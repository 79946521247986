import { LoggedInProps } from "modules/common/components/routing/AnonymousRoute";
import { ProtectedRoute } from "modules/common/components/routing/ProtectedRoute";
import { routes } from "modules/common/routes";
import { can } from "modules/session/auth/access";
import { SessionType } from "modules/session/auth/models/session";
import { DepartmentsStoreType } from "modules/spending/departments/models/departments-store";
import func from "modules/reports/functionalities";
import React from "react";
import { OrderDictionaryType } from "../orders-manage/models/order-dictionary";
import ReportTabsPage from "./components/ReportTabsPage";
import { ReportsPage } from "./userSpending/components/ReportsPage";
import { ReportsStoreType } from "./userSpending/models/reports-store";
import { ProjectPortfolioDictionaryType } from "modules/dictionaries/project-portfolios/models/project-portfolio-dictionary";
import { OrderYearReportPage } from "./userSpending copy/components/OrderYearReportPage";

export const Routes = ({ loggedIn, session, reports, orders, departments, portfolios }: RoutesProps) => {
    return (
        <ProtectedRoute
            path={routes.reports.path}
            loggedIn={loggedIn}
            render={(props) => (
                <ReportTabsPage
                    {...props}
                    reportsPanel={
                        can(func.EMPLOYER_SPENDING_REPORTS_READ, session.access) ? (
                            <ReportsPage store={reports} session={session} orders={orders} departments={departments} />
                        ) : null
                    }
                    orderReportsPanel={
                        can(func.ORDER_YEAR_REPORTS_READ, session.access) ? (
                            <OrderYearReportPage store={reports} session={session} portfolios={portfolios} />
                        ) : null
                    }
                />
            )}
        />
    );
};

interface RoutesProps extends LoggedInProps {
    session: SessionType;
    reports: ReportsStoreType;
    orders: OrderDictionaryType;
    departments: DepartmentsStoreType;
    portfolios: ProjectPortfolioDictionaryType;
}
