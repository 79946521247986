import React, { useCallback } from "react";
import { InputGroup } from "@blueprintjs/core";

import { InputRightIcon } from "./Common";

export const Search = ({ disabled, query, onSearch, placeholder, style }: SearchProps) => {
    const onChange = useCallback(
        (e: any) => {
            onSearch(e.target.value);
        },
        [onSearch]
    );

    const clear = useCallback(() => onSearch(""), [onSearch]);

    const right = InputRightIcon({
        hasValue: !!query,
        disabled: disabled,
        onClear: clear,
        icon: "search",
    });

    return (
        <InputGroup
            className={`planr-round-input`}
            disabled={disabled}
            onChange={onChange}
            placeholder={placeholder || "Поиск"}
            rightElement={right}
            value={query}
            style={Object.assign({}, { marginLeft: "16px" }, style)}
        />
    );
};

interface SearchProps {
    query: string;
    onSearch: (query: string) => void;
    disabled?: boolean;
    placeholder?: string;
    style?: React.CSSProperties;
}
