import { types, getSnapshot, applySnapshot } from "mobx-state-tree";

export const SectionCollapser = types
    .model({
        opened: types.map(types.boolean),
    })
    .actions((self) => ({
        toggle(key: string) {
            const value = self.opened.get(key) || false;
            self.opened.set(key, !value);
        },

        set(key: string, opened: boolean) {
            self.opened.set(key, opened);
        },

        reset() {
            applySnapshot(self.opened, {});
        },

        setAll(collapsed: boolean) {
            const iteratee = self.opened.keys();
            for (const key of iteratee) {
                self.opened.set(key, !collapsed);
            }
        },
    }))
    .views((self) => ({
        get plain() {
            return getSnapshot(self.opened);
        },
    }))
    .named("SectionCollapser");
