import { sortBy } from "lodash";

export const range = (count: number) => {
    const result = Array.apply(null, Array(count)).map((_, i) => i);
    return result;
};

export function arraybufferEqual(buf1: ArrayBuffer, buf2: ArrayBuffer) {
    if (buf1 === buf2) {
        return true;
    }

    if (buf1.byteLength !== buf2.byteLength) {
        return false;
    }

    const view1 = new DataView(buf1);
    const view2 = new DataView(buf2);

    let i = buf1.byteLength;
    while (i--) {
        if (view1.getUint8(i) !== view2.getUint8(i)) {
            return false;
        }
    }

    return true;
}

/**
 * Move element in array from one position to another (no mutation)
 * @param arr Array
 * @param oldIndex Initial element index
 * @param newIndex New element index
 */
export function move<T>(arr: T[], oldIndex: number, newIndex: number): T[] {
    const copy: any[] = [...arr];
    while (oldIndex < 0) {
        oldIndex += arr.length;
    }
    while (newIndex < 0) {
        newIndex += arr.length;
    }
    if (newIndex >= arr.length) {
        let k = newIndex - arr.length;
        while (k-- + 1) {
            copy.push(undefined);
        }
    }
    copy.splice(newIndex, 0, copy.splice(oldIndex, 1)[0]);
    return copy;
}

export const chunkArray = <T>(array: T[], columns: number) => {
    if (!array.length) {
        return [array];
    }

    const clone = [...array];
    const size = Math.ceil(array.length / columns);
    const arrays: T[][] = [];

    while (clone.length > 0) {
        arrays.push(clone.splice(0, size));
    }

    return arrays;
};

export const getMedian = <T>(arr: T[], getter: (e: T) => number): number => {
    const sorted = sortBy(arr, getter);

    if (sorted.length % 2 === 0) {
        const index1 = sorted.length / 2;
        const index2 = sorted.length / 2 - 1;

        const item1 = sorted[index1];
        const item2 = sorted[index2];

        return (getter(item1) + getter(item2)) / 2;
    } else {
        return getter(sorted[Math.floor(sorted.length / 2)]);
    }
};
