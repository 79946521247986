import React, { useCallback, useState } from "react";
import { OrderTechTaskVersionType } from "modules/orders-manage/models/order-tech-tasks";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { texts } from "modules/common/texts";
import { InputGroup, TextArea, Classes, Tooltip } from "@blueprintjs/core";
import { Asterisk, Buttons } from "modules/common/components/form";
import { observer } from "mobx-react";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { EmployerName } from "modules/common/components/employer/EmployerName";

export interface TechTaskVerisonsProps {
    versions: OrderTechTaskVersionType[];
    select: (v: OrderTechTaskVersionType) => void;
    onSave: (name: string, descrition: string) => Promise<boolean>;
    readonly?: boolean;
    style?: React.CSSProperties;
}

export const TechTaskVerisons = observer(({ versions, select, readonly, style, onSave }: TechTaskVerisonsProps) => {
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState("");
    const [nameTouched, setNameTouched] = useState(false);
    const [description, setDescription] = useState("");
    const [descriptionTouched, setDescriptionTouched] = useState(false);

    const onVersionClick = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            const index = parseInt(e.currentTarget.dataset.version ?? "", 10);
            select(versions[index]);
        },
        [select, versions]
    );

    const onTextInput = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = e.currentTarget.dataset.field ?? "";
        if (target === "name") {
            setName(e.currentTarget.value);
            setNameTouched(true);
        }
        if (target === "description") {
            setDescription(e.currentTarget.value);
            setDescriptionTouched(true);
        }
    }, []);

    const onAdd = useCallback((e: any) => {
        setEditMode(true);
    }, []);

    const onCancelEdit = useCallback(() => {
        setEditMode(false);
        setName("");
        setDescription("");
        setNameTouched(false);
        setDescriptionTouched(false);
    }, []);

    const onSaveVersion = useCallback(async () => {
        setNameTouched(true);
        setDescriptionTouched(true);

        if (!name || !description) {
            return;
        }

        setLoading(true);
        const ok = await onSave(name, description);
        setLoading(false);

        if (ok) {
            onCancelEdit();
        }
    }, [name, description, onSave, onCancelEdit]);

    return (
        <div className="file-versions" style={style}>
            <h3>Версии файла:</h3>

            <LocalLoader active={loading} />

            {!readonly && !editMode && (
                <PlanrButton
                    type="secondary"
                    size="small"
                    icon="general-plus-big"
                    onClick={onAdd}
                    style={{
                        width: "100%",
                        marginBottom: "32px",
                        borderRadius: "120px",
                    }}
                >
                    {texts.add}
                </PlanrButton>
            )}

            {editMode && (
                <div>
                    <div
                        className={`bp3-form-group bp3-small planr-form-input ${
                            !name && nameTouched && "bp3-intent-danger"
                        }`}
                    >
                        <label className="bp3-label">
                            Наименование
                            <span className="bp3-text-muted">
                                <Asterisk isRequired={true} />
                            </span>
                        </label>

                        <div className="bp3-form-content">
                            <InputGroup
                                className="planr-default-input"
                                autoComplete="off"
                                value={name}
                                data-field="name"
                                onChange={onTextInput}
                            />
                            {!name && nameTouched && <div className="bp3-form-helper-text">Требуется значение</div>}
                        </div>
                    </div>

                    <div
                        className={`bp3-form-group bp3-small planr-form-input ${
                            !description && descriptionTouched && "bp3-intent-danger"
                        }`}
                    >
                        <label className="bp3-label">
                            Примечания
                            <span className="bp3-text-muted">
                                <Asterisk isRequired={true} />
                            </span>
                        </label>

                        <div className="bp3-form-content">
                            <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                                <TextArea
                                    growVertically={true}
                                    className={Classes.FILL}
                                    value={description}
                                    data-field="description"
                                    onChange={onTextInput}
                                />
                            </div>
                            {!description && descriptionTouched && (
                                <div className="bp3-form-helper-text">Требуется значение</div>
                            )}
                        </div>
                    </div>

                    <Buttons>
                        <div style={{ display: "flex" }}>
                            <PlanrButton
                                type="greenish"
                                size="small"
                                onClick={onSaveVersion}
                                icon="general-redo"
                                title={texts.save}
                            />

                            <PlanrButton
                                type="graybtn"
                                onClick={onCancelEdit}
                                size="small"
                                style={{ marginLeft: "5px" }}
                                title={texts.cancel}
                                icon="general-undo"
                            />
                        </div>
                    </Buttons>
                </div>
            )}

            {!editMode && (
                <div className="file-versions-scroller">
                    {versions.map((version, index) => (
                        <div key={index} className="version">
                            <strong data-version={index} onClick={onVersionClick} style={{ cursor: "pointer" }}>
                                <Tooltip content={version.description}>
                                    <EmployerName name={version.name} position={"от 2021.07.20"} />
                                </Tooltip>
                            </strong>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});
