import React from "react";
import styles from "./SpendingsListPage.module.scss";
import { observer } from "mobx-react";
import { SpendingsExternalParams, SummaryTable } from "./Table";
import { Page } from "modules/common/components/page/Page";
import { BatchFilterChange, cacheFilters, SpendingsListType } from "../models/spending-list";
import { Filters } from "./Filters";
import { Loader } from "modules/common/components/loader/Loader";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { SpendingForm } from "./SpendingForm";
import { Model, model } from "./validation";
import { isNotNull } from "modules/common/services/typescript";
import { formatDate, parseDate } from "modules/common/services/formatting/date";
import { SpendingsListRowSnapshotType } from "../models/spending-list-row";
import { toString, trimStart } from "modules/common/services/strings";
import { SessionType } from "modules/session/auth/models/session";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { Money } from "modules/common/components/money/Money";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { routes } from "modules/common/routes";
import { SPENDING_SECTION } from "./filter-params";
import { parse } from "query-string";
import { can } from "modules/session/auth/access";
import func from "../../functionalities";
import { InputGroup } from "@blueprintjs/core";

function isSpendingIdInHash(locationHash: string) {
    const hash = trimStart("#", locationHash);

    if (hash) {
        const metadata = parse(hash);
        const spending = (metadata[SPENDING_SECTION] as string) || "";
        if (spending && spending !== "") {
            return true;
        }
    }

    return false;
}

class Base extends React.Component<SpendingsListPageProps & RouteComponentProps, SpendingsListPageState> {
    constructor(props: any) {
        super(props);

        this.state = { editable: null, selected: null, isSpendingId: false, searchInput: "" };
    }

    async componentDidMount() {
        this.props.store.init();
        const externals = SpendingsExternalParams(this.props.location.hash);

        if (externals) {
            this.props.store.setupFilter(externals);
        }
        const isId = isSpendingIdInHash(this.props.location.hash);
        this.setState({ isSpendingId: isId });
        if (!isId) {
            this.resetFilters();
        }
        await this.props.store.loadTable();
    }

    render() {
        const { store, session } = this.props;
        const { editable, selected } = this.state;

        return (
            <Page className={styles.page}>
                {isNotNull(editable) && (
                    <DetailsPopup title={"Операция"} onCancel={this.onCreationCancel} width={700}>
                        <SpendingForm
                            baseUrl={store.baseUrl}
                            orders={store.orders}
                            types={store.overheadTypes}
                            requests={store.requests}
                            model={editable}
                            row={selected}
                            store={store}
                            onSubmit={this.onSubmit}
                            onSaved={this.onCreationCancel}
                        />
                    </DetailsPopup>
                )}

                <Loader active={store.isLoading} />

                {!this.state.isSpendingId && (
                    <>
                        <div className="header-block">
                            <Filters store={store} session={session} />
                        </div>
                        <PlanrButton
                            type="secondary"
                            icon="general-plus-big"
                            round={true}
                            onClick={this.createRow}
                            className={styles.addButton}
                        />
                    </>
                )}
                <div className={`${styles.tools} `}>
                    {can(func.SPENDINGS_EXPORT, session.access) && (
                        <PlanrButton
                            type="graybtn"
                            icon="general-export"
                            round={true}
                            onClick={this.props.store.export}
                        />
                    )}
                    <PlanrButton type="graybtn" icon="general-print" round={true} onClick={this.props.store.print} />

                    <InputGroup
                        className={`planr-round-input`}
                        onChange={this.onChange}
                        placeholder={"Поиск"}
                        value={this.state.searchInput}
                        style={Object.assign({}, { marginLeft: "16px" })}
                    />
                    <PlanrButton
                        type="secondary"
                        icon="general-search"
                        size="small"
                        style={{ marginLeft: "24px" }}
                        onClick={() => {
                            this.onSearch(this.state.searchInput);
                        }}
                    />
                </div>

                {this.state.isSpendingId && (
                    <div className={styles.resetPageBtn}>
                        <PlanrButton
                            type="blueish"
                            onClick={async () => {
                                this.resetFilters();
                                this.setState({ isSpendingId: false });
                                await this.props.store.loadTable();
                            }}
                            size={"small"}
                        >
                            Показать все
                        </PlanrButton>
                    </div>
                )}

                <div className={styles.SumValue}>
                    <div className={`color-green`} style={{ position: "absolute", left: "100px", width: "105px" }}>
                        <Money className="money " amount={store.incomingSum} />
                    </div>
                    <div className={`color-red`} style={{ position: "absolute", left: "220px", width: "105px" }}>
                        <Money className="money " amount={store.spendingSum} />
                    </div>
                </div>

                <SummaryTable store={store} onSelect={this.editRow} />
            </Page>
        );
    }

    onSubmit = (body: Model) => {
        return this.props.store.saveRow({
            ...body,
            date: formatDate(body.date),
        });
    };
    onChange = (e: any) => {
        this.setState({ searchInput: e.currentTarget.value });
    };
    onSearch = (value: string) => {
        this.props.store.setQuery(value);
        this.props.store.queryChanged(value);
    };

    createRow = () => {
        this.setState({ editable: model(), selected: null });
    };

    resetFilters = () => {
        this.props.history.push(routes.expenses.path);
        const filters = cacheFilters;
        if (filters) {
            const start = filters.start ? filters.start.toString() : "";
            const end = filters.end ? filters.end.toString() : "";
            const result: BatchFilterChange = {
                isIncoming: filters.isIncoming ? filters.isIncoming.toString() : "",
                isProduction: filters.isProduction ? filters.isProduction.toString() : "",
                query: filters.queryString ? filters.queryString.toString() : "",
                spending: filters.spendingId ? filters.spendingId.toString() : "",
                period: [parseDate(start) || null, parseDate(end) || null],
            };
            this.props.store.setupFilter(result);
        } else {
            const start = "";
            const end = "";
            const result: BatchFilterChange = {
                isIncoming: "",
                isProduction: "",
                query: "",
                spending: "",
                period: [parseDate(start) || null, parseDate(end) || null],
            };
            this.props.store.setupFilter(result);
        }
    };

    editRow = (row: SpendingsListRowSnapshotType) => {
        this.setState({
            editable: {
                id: row.id,
                date: parseDate(row.date) || null,
                incoming: toString(row.isIncoming),
                name: row.name,
                payloadId: row.payload || row.dictionaryId,
                tempPayloadId: row.dictionaryId,
                sum: row.sum,
                type: row.type,
                isCash: row.isCash,
                comment: row.comment,
                document: row.document,
                spending: "",
            },
            selected: row,
        });
    };

    onCreationCancel = () => this.setState({ editable: null, selected: null });
}

export const SpendingsListPage = withRouter(observer(Base));

interface SpendingsListPageProps {
    store: SpendingsListType;
    session: SessionType;
}

interface SpendingsListPageState {
    editable: Model | null;
    selected: SpendingsListRowSnapshotType | null;
    isSpendingId: boolean;
    searchInput: string;
}
