import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { Notificator } from "modules/common/models/notificator";
import { Queryable } from "modules/common/models/queryable";
import { TablePager } from "modules/common/models/table-pager";
import { Transport } from "modules/common/models/transport";
import { FinanceRequest, FinanceRequestSnapshotType } from "./request";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { nameof } from "modules/common/services/typescript";
import {
    OverheadTypeDictionary,
    initialState as emptyTypes,
} from "modules/dictionaries/overhead-types/models/overhead-type-dictionary";
import { formatDate } from "modules/common/services/formatting/date";

export const OwnFinanceRequestsStore = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        TablePager,
        types.model({
            rows: types.array(FinanceRequest),
            types: OverheadTypeDictionary,
        })
    )
    .views((self) => ({
        get queryParams() {
            return {
                page: self.page,
                itemsPerPage: self.pageSize,
                complexSearch: self.pureQuery,
            };
        },

        get data() {
            return getSnapshot(self.rows);
        },
    }))
    .actions((self) => ({
        init() {
            return self.types.load();
        },

        load: flow(function* (page = 1) {
            try {
                self.page = page;
                let requests: PagedList<FinanceRequestSnapshotType> = yield self.transport.get<any>(
                    apiUrls.spendings.overhead.requests(),
                    {
                        params: self.queryParams,
                    }
                );
                self.totalCount = requests.totalCount;
                applySnapshot(self.rows, requests.items);
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .actions((self) => ({
        onQueryChanged() {
            return self.load();
        },

        save: flow(function* (model: FinanceRequestModel) {
            try {
                const body: any = { ...model };

                if (typeof body[fields.sum] === "string") {
                    body[fields.sum] = parseFloat(body[fields.sum]);
                }
                if (body[fields.date] instanceof Date) {
                    body[fields.date] = formatDate(body[fields.date]);
                }
                if (model.filesId) {
                    body[fields.filesId] = model.filesId.map((file) => file.fileId);
                }
                yield self.transport.post<any>(apiUrls.spendings.overhead.requests(), body);

                return yield self.load();
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        uploadFile: flow(function* (file: File) {
            try {
                const model = new FormData();

                model.append("file", file);
                model.append("accept", "*");

                const result: UploadFileResult = yield self.transport.post<any>(
                    apiUrls.application.files.upload,
                    model
                );
                const { id, previewMimeType, mimeType } = result;

                const fileBase: FileBase = { fileId: id, fileName: file.name, previewMimeType, mimeType };
                return fileBase;
            } catch (er) {
                self.notify.error(er);
                return null;
            }
        }),
    }))
    .named("OwnFinanceRequestsStore");

export type OwnFinanceRequestsStoreType = typeof OwnFinanceRequestsStore.Type;
export type OwnFinanceRequestsStoreSnapshotType = typeof OwnFinanceRequestsStore.SnapshotType;

export const initialState = (): OwnFinanceRequestsStoreSnapshotType => ({
    page: 1,
    pageSize: 10,
    pureQuery: "",
    query: "",
    rows: [],
    totalCount: 0,
    types: emptyTypes(),
});

export interface FinanceRequestModel {
    sum: number;
    typeId: string;
    comment: string;
    filesId: FileBase[] | null;
    immediate: boolean;
    date: Date | null;
    supplierId: string;
    supplierName: string;
}

export const fields = {
    sum: nameof((r: FinanceRequestModel) => r.sum) as string,
    typeId: nameof((r: FinanceRequestModel) => r.typeId) as string,
    comment: nameof((r: FinanceRequestModel) => r.comment) as string,
    filesId: nameof((r: FinanceRequestModel) => r.filesId) as string,
    immediate: nameof((r: FinanceRequestModel) => r.immediate) as string,
    date: nameof((r: FinanceRequestModel) => r.date) as string,
    supplierName: nameof((r: FinanceRequestModel) => r.supplierName) as string,
    supplierId: nameof((r: FinanceRequestModel) => r.supplierId) as string,
};
