import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { Client, ClientType, ClientSnapshotType, fields as baseFields } from "./client";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { nameof } from "modules/common/services/typescript";
import { Queryable } from "modules/common/models/queryable";

export const ClientDetails = types
    .compose(
        Client,
        types.model({
            ordersSum: types.number,
            actSum: types.number,
            remainSum: types.number,
        })
    )
    .named("ClientDetails");

export const ClientList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            clients: types.array(ClientDetails),
            sorter: TableSorter,
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<ClientType> {
            return self.clients.reduce((result, client) => {
                result[client.id] = client;
                return result;
            }, {} as TStringMap<ClientType>);
        },

        get data() {
            const predicate = (o: ClientType) => {
                let result = true;
                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.name} ${o.phone} ${o.email} ${o.comment} ${o.bankDetails.inn}`
                            .toLowerCase()
                            .indexOf(self.pureQuery) >= 0;
                }

                return result;
            };
            return self.clients.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));

            // return self.clients.map(toJsonHard);
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data = yield self.transport.get<ClientDetailsSnapshotType[]>(apiUrls.clients.list());
                applySnapshot(self.clients, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        delete: flow(function* (client: ClientSnapshotType) {
            const model = Client.create(client, {
                http: self.transport,
                notificator: self.notify,
            });
            return yield model.delete();
        }),
    }))
    .named("ClientList");

export type ClientDetailsType = typeof ClientDetails.Type;
export type ClientDetailsSnapshotType = typeof ClientDetails.SnapshotType;
export type ClientListType = typeof ClientList.Type;
export type ClientListSnapshotType = typeof ClientList.SnapshotType;
export { Client };

export const fields = {
    ...baseFields,
    ordersSum: nameof((c: ClientDetailsType) => c.ordersSum) as string,
    actSum: nameof((c: ClientDetailsType) => c.actSum) as string,
    remainSum: nameof((c: ClientDetailsType) => c.remainSum) as string,
};

const sortStorage = getSortOption(ClientList.name);
export const initialState = (): ClientListSnapshotType => {
    const options = sortStorage({ column: baseFields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        clients: [],
        pureQuery: "",
        query: "",
        sorter: {
            id: ClientList.name,
            tableName: ClientList.name,
            column: options.column,
            asc: options.asc,
        },
    };
};
