import React from "react";

export const ScrollToElement = (ref: React.RefObject<HTMLDivElement>, id: string, time = 3000) => {
  setTimeout(() => {
    const container = ref.current;
    if (!container || !id) {
      return;
    }

    const element: HTMLElement | null = container.querySelector(`[data-object-id="${id}"]`);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, time);
};
