import React from "react";
import styles from "./TechIndicatorRow.module.scss";
import { SaveOrderTechIndicator } from "modules/orders-manage/models/order-tech-indicator";

export const TechIndicatorHeader = ({ active }: TechIndicatorHeaderProps) => {
    return (
        <div className={`${styles.row} ${styles.headerRow} flat-mode`}>
            <div className={styles.iconCell}>&nbsp;</div>
            <div className={`${styles.number} ${styles.headerMg}`}>Значение</div>

            <div className={`${styles.description} `}>Показатель</div>

            <div className={styles.iconCell}>&nbsp;</div>
        </div>
    );
};

interface TechIndicatorHeaderProps {
    active: SaveOrderTechIndicator | null;
}
