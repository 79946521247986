import { Classes } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import {
    DefaultSelectedOption,
    SimpleSelect,
    filterItemPredicate,
    renderSingleOption,
} from "modules/common/services/form/select";
import { SessionType } from "modules/session/auth/models/session";
import React from "react";
import { ReportsStoreType } from "../../userSpending/models/reports-store";
import styles from "./OrderYearReportPage.module.scss";
import { ProjectPortfolioDictionaryType } from "modules/dictionaries/project-portfolios/models/project-portfolio-dictionary";

class Page extends React.Component<ReportsPageProps, ReportsPageState> {
    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            selectedPortfolio: null,
            selectedYear: null,
        };
    }

    async componentDidMount() {
        await this.props.portfolios.load();
    }

    render() {
        const { store, portfolios } = this.props;
        const { loading, selectedPortfolio, selectedYear } = this.state;
        const orderItems = portfolios.portfolios.map((o) => {
            return { id: o.id, label: o.label };
        });

        function generateYearsBetween(startYear: number = 2000) {
            const endDate = new Date().getFullYear();
            let years = [];

            for (var i = startYear; i <= endDate; i++) {
                years.push(startYear);
                startYear++;
            }
            return years;
        }
        const years = generateYearsBetween(2018).map((y) => ({ id: y.toString(), label: y.toString() }));
        return (
            <div className={styles.page}>
                <LocalLoader active={loading} />
                <div className={styles.reports}>
                    <div className={styles.reportUnitSpending}>
                        <div className={styles.reportLabel}>Проекты за год</div>
                        <div className={styles.orders}>
                            <div>Год</div>
                            <SimpleSelect
                                className={`full-width-select ${Classes.FILL}`}
                                activeItem={selectedYear}
                                items={years}
                                itemRenderer={renderSingleOption}
                                onItemSelect={(item: SelectItem) => {
                                    this.setState({ selectedYear: item });
                                }}
                                popoverProps={{
                                    boundary: "viewport",
                                    popoverClassName:
                                        "import-spendings-table__select import-spendings-table__select_action",
                                }}
                                inputProps={{
                                    placeholder: "Проект",
                                }}
                                filterable
                                itemPredicate={filterItemPredicate}
                            >
                                <DefaultSelectedOption option={selectedYear} />
                            </SimpleSelect>
                        </div>

                        <div className={styles.orders}>
                            <div>Портфель проектов</div>
                            <SimpleSelect
                                className={`full-width-select ${Classes.FILL}`}
                                activeItem={selectedPortfolio}
                                items={orderItems}
                                itemRenderer={renderSingleOption}
                                onItemSelect={(item: SelectItem) => {
                                    this.setState({ selectedPortfolio: item });
                                }}
                                popoverProps={{
                                    boundary: "viewport",
                                    popoverClassName:
                                        "import-spendings-table__select import-spendings-table__select_action",
                                }}
                                inputProps={{
                                    placeholder: "Проект",
                                }}
                                filterable
                                itemPredicate={filterItemPredicate}
                            >
                                <DefaultSelectedOption option={selectedPortfolio} />
                            </SimpleSelect>
                        </div>

                        {selectedYear && (
                            <div>
                                <PlanrButton
                                    type="greenish"
                                    size="small"
                                    icon="general-export"
                                    onClick={async () => {
                                        this.setState({ loading: true });
                                        await store.reportOrderYear(
                                            selectedYear.label,
                                            selectedPortfolio ? selectedPortfolio.id : ""
                                        );
                                        this.setState({ loading: false });
                                    }}
                                >
                                    Сформировать
                                </PlanrButton>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export const OrderYearReportPage = observer(Page);

interface ReportsPageProps {
    store: ReportsStoreType;
    session: SessionType;
    portfolios: ProjectPortfolioDictionaryType;
}

interface ReportsPageState {
    loading: boolean;
    selectedPortfolio: SelectItem | null;
    selectedYear: SelectItem | null;
}
