import React from "react";
import { WorkTypeLinkSnapshotType } from "modules/orders-manage/models/order";
import styles from "./Block.module.scss";
import {
    OrderContentTasksDictionaryType,
    OrderContentTasksListSnapshotType,
} from "modules/orders-manage/models/order-content-tasks";
import { RowTask } from "./RowTask";
import { observer } from "mobx-react";
import { SectionCollapserType } from "modules/orders-manage/models/orders-store";
import { TTaskFilePath } from "./CategoryBlock";

export type TUnit = WorkTypeLinkSnapshotType;
type TTask = OrderContentTasksListSnapshotType;
class BlockBase extends React.PureComponent<BlockProps> {
    private ref = React.createRef<HTMLDivElement>();

    render() {
        const { units, collapserContent, store, baseUrl, readOnly, highlightRow } = this.props;
        const collapsed = { ...collapserContent.plain };

        return (
            <div ref={this.ref} className={`${styles.block} `}>
                {units.length > 0 && (
                    <div className={styles.wrapper}>
                        {units.map((work) => {
                            const collapsedItem = !collapsed[work.unit.guid];
                            const onToggleCollapse = () => {
                                collapserContent.toggle(work.unit.guid);
                            };

                            const contentTask = store.contentTasksList.filter(
                                (item) => item.guid === work.unit.guid
                            )[0];

                            return (
                                <div key={work.unit.guid}>
                                    <div className={styles.row}>
                                        <RowTask
                                            deleteVersion={this.deleteVersion}
                                            contentTask={contentTask}
                                            work={work.unit}
                                            showUploadFiles={this.showUploadFilesDialog}
                                            baseUrl={baseUrl}
                                            showDeleteTask={this.showDeleteTask}
                                            returnTask={this.returnTask}
                                            addAccept={this.addAccept}
                                            addComplete={this.addComplete}
                                            addConfirm={this.addConfirm}
                                            addCancell={this.addCancell}
                                            showAddTask={this.showAddTask}
                                            showEditTask={this.showEditTask}
                                            showDevelopers={this.showDevelopersDialog}
                                            downloadFiles={this.downloadFiles}
                                            downloadVersion={this.downloadVersion}
                                            collapsed={collapsedItem}
                                            onToggleCollapse={onToggleCollapse}
                                            readOnly={readOnly}
                                            highlighted={highlightRow}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }

    workGuidActive = (guid: string) => {
        this.props.setGuid(guid);
    };
    taskActive = (id: string) => {
        this.props.setTaskId(id);
    };
    showAddTask = (guid: string) => {
        this.workGuidActive(guid);
        this.props.setTaskId("");
        this.props.showAddTask(guid);
    };

    showEditTask = (
        description: string,
        stopDate: string | null,
        id: string,
        taskFile: TTaskFilePath[],
        guid: string,
        employerId: string | null
    ) => {
        this.props.showEditTask(description, stopDate, taskFile, guid, employerId);
        this.workGuidActive(guid);
        this.props.setTaskId(id);
    };

    //working with removing tasks
    showDeleteTask = (guid: string, id: string) => {
        this.props.toggleDeleteShow();
        this.props.setTaskId(id);
        this.workGuidActive(guid);
    };

    returnTask = (id: string) => {
        this.props.store.returnTaskTemp(id);
    };

    //accepting task to work with
    addAccept = async (guid: string, value: boolean, id: string) => {
        await this.props.store.saveAccepted(this.props.orderId, guid, value, id);
    };

    //completing task
    addComplete = async (guid: string, value: boolean, id: string) => {
        await this.props.store.saveCompleted(this.props.orderId, guid, value, id);
    };

    //confirming task
    addConfirm = async (guid: string, value: boolean, id: string) => {
        await this.props.store.saveConfirmed(this.props.orderId, guid, value, id);
    };

    //cancellTask
    addCancell = async (guid: string, value: boolean, id: string) => {
        await this.props.store.saveCancelled(this.props.orderId, guid, value, id);
    };

    //working with loading new files with results
    toggleShowUploadFiles = () => {
        this.props.toggleShowUploadFiles();
    };

    showUploadFilesDialog = (guid: string) => {
        this.workGuidActive(guid);

        this.props.toggleShowUploadFiles();
    };

    showDevelopersDialog = (guid: string, task: TTask) => {
        this.workGuidActive(guid);
        this.props.showDevelopersDialog(task.developers);
    };

    //downloadingFiles
    downloadFiles = async (contentGuid: string) => {
        await this.props.store.downloadFiles(this.props.orderId, [{ contentGuid }]);
    };

    //downloadVersion
    downloadVersion = async (contentGuid: string, version: number) => {
        await this.props.store.downloadVersion(this.props.orderId, contentGuid, version);
    };

    //removeVersion
    deleteVersion = (contentGuid: string, version: number) => {
        this.props.store.removeVersion(this.props.orderId, contentGuid, version);
    };

    /////////////////////////////////////////////////////////////////////
}

export const Block = observer(BlockBase);
export type ActivationDelegate = (unit: TUnit) => void;

interface BlockProps {
    category: string;
    units: { index: number; unit: TUnit }[];
    onChange: (tasks: TTask[]) => void;
    onSort: (from: number, to: number) => void;
    touched: boolean;
    readOnly: boolean;
    store: OrderContentTasksDictionaryType;
    baseUrl: string;
    orderId: string;
    user: string;
    setGuid: (guid: string) => void;
    setTaskId: (id: string) => void;
    collapserContent: SectionCollapserType;
    toggleDeleteShow: () => void;
    toggleShowUploadFiles: () => void;
    showAddTask: (guid: string) => void;
    showDevelopersDialog: (developers: any[]) => void;
    showEditTask: (
        description: string,
        stopDate: string | null,
        file: TTaskFilePath[],
        guid: string,
        employerId: string | null
    ) => void;
    highlightRow?: string;
}
