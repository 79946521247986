import { chunkString, extractDigits } from '../strings';

export const cleanPhoneNumber = extractDigits;

export const formatPhoneNumber = (text: string) => {
    let clean = cleanPhoneNumber(text);
    if (!clean) {
        return '';
    }

    const chunk = chunkString(2);
    let prefix = '';

    // city format
    if (clean.length < 8) {
        if ((clean.length % 2) > 0 && clean.length > 3) {
            prefix = clean.substring(0, 3) + '-';
            clean = clean.substring(3);
        }

        return prefix + chunk(clean).join('-');
    }

    // federal format
    if (clean.startsWith('7') && clean.length === 11) {
        prefix = '+7';
        clean = clean.substring(1);
    }

    // federal format
    if (clean.startsWith('8') && clean.length === 11) {
        prefix = '+7';
        clean = clean.substring(1);
    }

    // federal format
    if (clean.startsWith('9') && clean.length === 10) {
        prefix = '+7';
    }

    // city code
    prefix += `${prefix ? ' ': ''}(${clean.substring(0, 3)}) `;
    clean = clean.substring(3);

    let parts: string[] = [];
    // для нечетного кол-ва цифр отделяем первые 3 в отдельную группу
    if ((clean.length % 2) > 0) {
        parts.push(clean.substring(0, 3));
        clean = clean.substring(3);
    }

    parts = [...parts, ...chunk(clean)];

    return prefix + parts.join('-');
}
