import React from "react";
import { OrderListType } from "../models/order-list";
import func, { canReadOrder } from "../functionalities";
import { can } from "modules/session/auth/access";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { routes } from "modules/common/routes";
import { Link } from "react-router-dom";
import { PlanrButton } from "modules/common/components/planr/button/Button";
export const MenuButton = (store: OrderListType) => (access: string[]) =>
    canReadOrder(access) ? (
        <>
            {can(func.ORDERS_SUMMARY_EDIT, access) && (
                <Link
                    to={routes.orders.details(EMPTY_OBJECT_ID)}
                    className="add-order-button"
                    title=""
                    onClick={() => {
                        store.setLastestProjectId(EMPTY_OBJECT_ID);
                    }}
                >
                    <PlanrButton
                        type="secondary"
                        icon="general-plus"
                        className="menu-neworder-btn"
                        style={{ marginLeft: "10px" }}
                    >
                        Новый проект
                    </PlanrButton>
                </Link>
            )}
        </>
    ) : undefined;
