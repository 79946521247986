import React from "react";
import { MoneyAccountStoreType, MoneyAccountType } from "modules/main/models/money-accounts";
import { observer } from "mobx-react";
import { Money } from "modules/common/components/money/Money";
import { LocalLoader } from "modules/common/components/loader/Loader";
import styles from "../Dashboard.module.scss";
import { ICON_BUTTON_SIZE } from "modules/common/constants";
import { MoneyInput } from "modules/common/components/money/MoneyInput";
import { NumberFormatValues } from "react-number-format";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

export const AccountsBlock = observer(
    class extends React.PureComponent<AccountsProps, AccountsState> {
        constructor(props: AccountsProps) {
            super(props);
            this.state = { editable: null, value: "" };
        }

        componentDidMount() {
            this.props.store.load();
        }

        render() {
            const { width, store, sum, accountsSum, receivablesSum } = this.props;
            const { editable, value } = this.state;
            const showLoader = store.isEmpty && store.loading;

            return (
                <div
                    className={`${styles.widget} ${styles.block} accounts-value`}
                    style={{ width: "auto", minWidth: `${width}px`, maxWidth: `${width}px`, margin: "6px 0px" }}
                >
                    <LocalLoader active={showLoader} />

                    {!showLoader && (
                        <div style={{ width: "100%" }}>
                            <div className={styles.creditTags}>
                                <div className={styles.creditTagsLabel}>Оборотные средства:</div>
                                <div className={styles.creditTagsValue}>
                                    <Money amount={sum} className={styles.credit} />
                                </div>
                            </div>

                            <div className={styles.creditTags}>
                                <div className={styles.creditTagsLabel}>Кредит. задолженность:</div>
                                <div className={styles.creditTagsValue}>
                                    <Money amount={accountsSum} className={styles.credit} />
                                </div>
                            </div>

                            <div className={styles.creditTags}>
                                <div className={styles.creditTagsLabel}>Дебит. задолженность:</div>
                                <div className={styles.creditTagsValue}>
                                    <Money amount={receivablesSum} className={styles.credit} />
                                </div>
                            </div>

                            <div className={styles.columnName}>
                                {store.accounts.map((account) => (
                                    <div key={account.id} title={account.title} className={styles.columnRow}>
                                        <strong>{account.name}</strong>
                                    </div>
                                ))}
                            </div>
                            <div className={`${styles.columnValue}`}>
                                {store.accounts.map((account) => (
                                    <div key={account.id} title={account.title} className={styles.columnRow}>
                                        {(!editable || editable.id !== account.id) && <Money amount={account.sum} />}
                                        {editable && editable.id === account.id && (
                                            <>
                                                <div className={styles.columnInput}>
                                                    <MoneyInput
                                                        value={value}
                                                        onMoneyChange={this.onMoneyChange}
                                                        className="planr-tiny-input"
                                                    />
                                                </div>
                                                <div className={styles.columnBtns}>
                                                    <GeneralIcon
                                                        type="general-undo"
                                                        size={14}
                                                        onClick={this.deactivate}
                                                        style={{ marginLeft: "5px" }}
                                                    />
                                                    <GeneralIcon
                                                        type="general-redo"
                                                        size={14}
                                                        onClick={this.save}
                                                        style={{ marginLeft: "5px" }}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        {!editable && (
                                            <GeneralIcon
                                                type="general-edit"
                                                size={ICON_BUTTON_SIZE}
                                                onClick={() => this.activate(account)}
                                                style={{ marginLeft: "5px" }}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            );
        }

        onMoneyChange = (money: NumberFormatValues) => {
            this.setState({ value: money.value });
        };

        activate = (editable: MoneyAccountType) => {
            const value = editable.sum.toString();
            this.setState({ editable, value });
        };

        deactivate = () => {
            this.setState({ editable: null, value: "" });
        };

        save = async () => {
            const { editable, value } = this.state;
            if (editable && value) {
                if (await this.props.store.save(editable, value)) {
                    this.deactivate();
                }
            }
        };
    }
);

interface AccountsProps {
    store: MoneyAccountStoreType;
    width: number;
    receivablesSum: number;
    accountsSum: number;
    sum: number;
}

interface AccountsState {
    editable: MoneyAccountType | null;
    value: string;
}
