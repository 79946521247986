import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import { RouteProps, RedirectState } from "./AnonymousRoute";
import { routes } from "modules/common/routes";

/**
 * Route that allowed only for authorized users.
 * Redirects anonymous users to "/login" page.
 * @param props Component props
 */
export const ProtectedRoute = (props: RouteProps) => {
    const { loggedIn, component, render, children, ...rest } = props;
    const Component = component;

    return (
        <Route
            {...rest}
            render={(route) => {
                const state: RedirectState = {
                    from: route.location ? route.location.pathname : routes.main.path,
                };

                return loggedIn ? (
                    Component ? (
                        <Component {...route} />
                    ) : render ? (
                        render(route)
                    ) : (
                        children
                    )
                ) : (
                    <Redirect to={{ pathname: routes.login, state }} />
                );
            }}
        />
    );
};
