import React, { useCallback } from "react";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { stopPropagation } from "modules/common/services/form/values";

interface ChartPeriodSwitcherProps {
    period: "Quarter" | "Year";
    onChange: (period: "Quarter" | "Year") => void;
}

export const ChartPeriodSwitcher: React.FC<ChartPeriodSwitcherProps> = ({ onChange, period }) => {
    const setYear = useCallback(
        (e) => {
            stopPropagation(e);
            onChange("Year");
        },
        [onChange]
    );

    const setQuarter = useCallback(
        (e) => {
            stopPropagation(e);
            onChange("Quarter");
        },
        [onChange]
    );

    return (
        <div className="chart-period-switcher" style={{ display: "flex" }}>
            <PlanrButton
                type={period === "Year" ? "primary" : "graybtn"}
                size="small"
                onClick={setYear}
                style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px", height: "30px" }}
            >
                Год
            </PlanrButton>

            <PlanrButton
                type={period === "Quarter" ? "primary" : "graybtn"}
                size="small"
                onClick={setQuarter}
                style={{
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    marginLeft: "0px",
                    height: "30px",
                }}
            >
                Квартал
            </PlanrButton>
        </div>
    );
};
