import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { DeprGroup, DeprGroupType, DeprGroupSnapshotType, fields, initialState as emptyDeprGroup } from "./depr-group";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
import { getSortOption, SortOptions } from "modules/common/services/table/sorting-storage";
import { Queryable } from "modules/common/models/queryable";

export const DeprGroupList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            deprGroups: types.array(DeprGroup),
            remover: DeprGroup,
            sorter: TableSorter,
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<DeprGroupType> {
            return self.deprGroups.reduce((result, DeprGroup) => {
                result[DeprGroup.id] = DeprGroup;
                return result;
            }, {} as TStringMap<DeprGroupType>);
        },

        get data() {
            const predicate = (o: DeprGroupType) => {
                let result = true;
                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.name} ${o.minYears} ${o.maxYears} ${o.maxLifetime} ${o.minLifetime} `
                            .toLowerCase()
                            .indexOf(self.pureQuery) >= 0;
                }
                return result;
            };
            return self.deprGroups.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));
            // return getSnapshot(self.DeprGroups);
        },

        get isEmpty() {
            return !self.deprGroups.length;
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data = yield self.transport.get<DeprGroupSnapshotType[]>(apiUrls.deprGroups.list());
                applySnapshot(self.deprGroups, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        delete: flow(function* (DeprGroup: DeprGroupSnapshotType) {
            applySnapshot(self.remover, DeprGroup);
            return yield self.remover.delete();
        }),
    }))
    .named("DeprGroupList");

export type DeprGroupsListType = typeof DeprGroupList.Type;
export type DeprGroupListSnapshotType = typeof DeprGroupList.SnapshotType;
export { DeprGroup };

const sortStorage = getSortOption(DeprGroupList.name);

export const initialState = (sortOptions: SortOptions | null = null): DeprGroupListSnapshotType => {
    const options = sortOptions || sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        deprGroups: [],
        remover: emptyDeprGroup(),
        pureQuery: "",
        query: "",
        sorter: {
            id: DeprGroupList.name,
            tableName: DeprGroupList.name,
            column: options.column,
            asc: options.asc,
        },
    };
};
