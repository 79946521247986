/**
 * Get standard drag style
 * @param isDragging Is element dragging now
 * @param draggableStyle predefined styles
 */
export const getDragItemStyle = (isDragging: boolean, draggableStyle: any): React.CSSProperties => ({
    userSelect: 'none',
    boxShadow: isDragging
        ? '0 0 0 1px rgba(16, 22, 26, .1), 0 2px 4px rgba(16, 22, 26, .2), 0 8px 24px rgba(16, 22, 26, .2)'
        : undefined,
    // styles we need to apply on draggables
    ...draggableStyle
});
