import React, { useCallback } from "react";
import { UploaderFatory } from "modules/orders-manage/types";
import { ClientFeedbackFileSnapshotType } from "modules/orders-manage/models/order-file";
import { FileLink } from "modules/common/components/files/FileLink";

import styles from "./FeedbackFile.module.scss";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";

export const FeedbackFile: React.FC<FeedbackFileProps> = (props) => {
    const { file, onChange, upload, baseUrl, readOnly } = props;

    const uploadFile = useCallback(
        async (f: File) => {
            const meta = await upload(f);

            if (meta) {
                onChange({
                    fileId: meta.fileId,
                    fileName: meta.fileName,
                });
            }
        },
        [onChange, upload]
    );

    const onFileRemove = useCallback(() => onChange(null), [onChange]);

    return (
        <div className={`${styles.feedback} bp3-form-group`}>
            {file && (
                <FileLink<ClientFeedbackFileSnapshotType>
                    baseUrl={baseUrl}
                    file={file}
                    onRemove={onFileRemove}
                    readOnly={readOnly}
                    style={{ height: "61px", marginBottom: "0px" }}
                />
            )}

            <div className="bp3-form-content">
                {!readOnly && !file && (
                    <PlanRDndUploader accept="" multiple={false} onFileSelected={uploadFile} withLoader={true} />
                )}
            </div>
        </div>
    );
};

interface FeedbackFileProps extends UploaderFatory {
    readOnly?: boolean;
    baseUrl: string;
    file: ClientFeedbackFileSnapshotType | null;
    onChange: (file: ClientFeedbackFileSnapshotType | null) => void;
}
