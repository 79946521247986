import React from "react";
import { observer } from "mobx-react";
import styles from "./EmployeePositionsList.module.scss";
import { columns } from "./columnsPosititions";
import { saveAgGridColumnSize } from "modules/common/services/table/size-storage";
import { defaultModules, DefaultAgGridOptions } from "modules/common/services/table/helpers";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { agGridEntityContextMenu } from "modules/common/components/table/EntityContextMenu";
import { EmployeePositionList, EmployeePositionsListType } from "../../models/employee-position-list";
import { EmployeePositionSnapshotType, formatEmployeePosition } from "../../models/employee-position";
import { MenuModule } from "@ag-grid-enterprise/menu";
import {
    GridOptions,
    GridReadyEvent,
    RowClickedEvent,
    CellContextMenuEvent,
    GetContextMenuItemsParams,
} from "@ag-grid-community/core";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import { agGridLocale } from "modules/root/services/locale";
import { MAX_INT } from "modules/common/constants";
import copyToClipboard from "copy-to-clipboard";

const modules = [...defaultModules, MenuModule];
const TABLE_NAME = EmployeePositionList.name;
const sizeSaver = saveAgGridColumnSize(TABLE_NAME);

export const EmployeePositionsList = observer(
    class extends React.Component<EmployeePositionsListProps> {
        private options: GridOptions = DefaultAgGridOptions(true);

        render() {
            const { store, onSelected } = this.props;
            const config = columns(TABLE_NAME, this.props.baseUrl);
            return (
                <RemoveConfirmation<EmployeePositionSnapshotType>
                    observation={store.positions}
                    what={this.removeLabel}
                    onConfirmed={this.removeEmployeePosition}
                    render={({ confirmRemoving }) => {
                        const getContextMenuItems = ({ node }: GetContextMenuItemsParams) =>
                            agGridEntityContextMenu({
                                onCopy: () =>
                                    copyToClipboard(formatEmployeePosition(node.data), { format: "text/plain" }),
                                onChange: () => onSelected(getRowNodeId(node.data)),
                                onRemove: () => confirmRemoving(node.data),
                            });

                        return (
                            <GridTheme bordered={true} className={`designed ${styles.grid}`}>
                                <AgGridReact
                                    rowStyle={{
                                        borderBottom: "1 px solid #EDF0F2",
                                        fontFamily: "GothamPro, Arial, sans-serif",
                                        color: "#00273D",
                                        height: "32px",
                                        fontSize: "12px",
                                    }}
                                    immutableData={true}
                                    rowSelection="single"
                                    suppressCellSelection={true}
                                    enableCellTextSelection={true}
                                    columnDefs={config}
                                    rowData={store.positions}
                                    localeText={agGridLocale}
                                    onRowDoubleClicked={this.onRowSelected}
                                    getRowNodeId={getRowNodeId}
                                    getContextMenuItems={getContextMenuItems}
                                    onGridReady={this.onGridReady}
                                    onCellContextMenu={this.onCellContextMenu}
                                    onSortChanged={store.sorter.resorted}
                                    onColumnResized={sizeSaver}
                                    gridOptions={this.options}
                                    modules={modules}
                                    rowBuffer={MAX_INT}
                                    applyColumnDefOrder={true}
                                />
                            </GridTheme>
                        );
                    }}
                />
            );
        }

        removeLabel = (employeePosition: EmployeePositionSnapshotType) => `должность ${employeePosition.name}`;

        removeEmployeePosition = async (employeePosition: EmployeePositionSnapshotType) => {
            const { store, onRemoved } = this.props;
            const success = await store.delete(employeePosition);
            success && onRemoved();
        };

        onCellContextMenu = ({ node }: CellContextMenuEvent) => {
            node && node.setSelected(true, true);
        };

        onRowSelected = ({ data }: RowClickedEvent) => {
            const { onSelected } = this.props;
            onSelected(getRowNodeId(data));
        };

        onGridReady = async ({ api }: GridReadyEvent) => {
            const { sorter } = this.props.store;
            setTimeout(() => api.setSortModel(sorter.agGridSortModel));
        };
    }
);

const getRowNodeId = (e: EmployeePositionSnapshotType) => e.id;

interface EmployeePositionsListProps {
    onRemoved: () => void;
    onSelected: (id: string) => void;
    store: EmployeePositionsListType;
    baseUrl: string;
}
