import { indicatorLabels } from "../details/indicator-validation";
import { getColumnSize } from "modules/common/services/table/size-storage";
import { fields } from "modules/dictionaries/order-indicators/models/indicator";
import { ColDef } from "@ag-grid-community/core";

const labels = indicatorLabels();

export const columns = (tableName: string) => {
    const columnSize = getColumnSize(tableName);

    const result: ColDef[] = [
        {
            headerName: labels[fields.label],
            field: fields.label,
            width: columnSize(fields.label, 350),
            sortable: true,
            resizable: false,
        },
    ];

    return result;
};
