import React from "react";
import { fields as bankFields } from "modules/common/models/bank-details";
import { Schema } from "yup";
import {
  AccountField,
  InnField,
  BankNameField,
  BankIdentificatorField,
  CorrAccountField,
} from "modules/agents/components/BankDetailsFields";
import styles from "./OutsourcerDetails.module.scss";

type FieldsType = ReturnType<typeof bankFields>;

interface SelfEmployedBankDetailsFieldsProps {
  schema: Schema<any>;
  fields: FieldsType;
  inline?: boolean;
  readonly?: boolean;
}

export const SelfEmployedBankDetailsFields = ({
  schema,
  fields,
  inline,
  readonly,
}: SelfEmployedBankDetailsFieldsProps) => {
  return (
    <>
      <h3 className={styles.h3Label}>Реквизиты</h3>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <InnField fields={fields} schema={schema} inline={inline} readonly={readonly} />
        &nbsp;
        <BankIdentificatorField fields={fields} schema={schema} inline={inline} readonly={readonly} />
      </div>

      <AccountField fields={fields} schema={schema} inline={inline} readonly={readonly} />

      <BankNameField fields={fields} schema={schema} inline={inline} readonly={readonly} />

      <CorrAccountField fields={fields} schema={schema} inline={inline} readonly={readonly} />
    </>
  );
};
