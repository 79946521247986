import React from "react";
import { Formik, Form } from "formik";
import SurveySchema, { MAX_VARIANTS } from "./validation";
import styles from "./SurveyEditor.module.scss";
import { Classes, InputGroup, Dialog } from "@blueprintjs/core";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { eat } from "modules/common/services/typescript";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { tomorrow, year } from "modules/common/services/dates";

const schema = SurveySchema();

export class SurveyEditor extends React.PureComponent<SurveyEditorProps> {
    minDate = new Date();
    defaultValue = tomorrow();
    maxDate = year();

    render() {
        const { width, onSave, onClose } = this.props;

        return (
            <Dialog
                title="Опрос"
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                isOpen={true}
                onClose={onClose}
                style={{ width: `${width || 500}px` }}
                backdropClassName="standard"
                className={`figma-dialog`}
            >
                <Formik
                    initialValues={getFormValues()}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        const succsessfull = await onSave(values);
                        setSubmitting(false);

                        if (succsessfull) {
                            resetForm();
                            onClose();
                        }
                    }}
                >
                    {(formProps) => {
                        return (
                            <Form autoComplete="off">
                                <div className={`${Classes.DIALOG_BODY} ${styles.dialog}`}>
                                    <StandardFormInput name="name" schema={schema} small={true} inline={false}>
                                        {({ field }) => (
                                            <InputGroup id="name" {...field} small={true} autoComplete="off" />
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name="variants"
                                        schema={schema}
                                        small={true}
                                        inline={false}
                                        required={true}
                                        className={styles.variants}
                                    >
                                        {({ field, form }) => {
                                            const strings: string[] = field.value;
                                            return (
                                                <div>
                                                    {strings.map((value, index) => {
                                                        const onChange = (e: React.FormEvent<HTMLInputElement>) => {
                                                            const text = e.currentTarget.value;
                                                            const newValue = [
                                                                ...strings.slice(0, index),
                                                                text,
                                                                ...strings.slice(index + 1),
                                                            ];
                                                            form.setFieldValue(field.name, newValue);
                                                            form.setFieldTouched(field.name, true);
                                                        };

                                                        return (
                                                            <div className={styles.variant} key={index}>
                                                                <span>{index + 1}.&nbsp;</span>
                                                                <InputGroup
                                                                    onBlur={field.onBlur}
                                                                    value={value}
                                                                    small={true}
                                                                    onChange={onChange}
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        }}
                                    </StandardFormInput>

                                    <StandardFormInput name="until" schema={schema} small={true} inline={false}>
                                        {({ field, form }) => {
                                            return (
                                                <div className={`${Classes.INPUT_GROUP} ${Classes.SMALL}`}>
                                                    <DatePicker
                                                        value={field.value}
                                                        onChange={(date) => {
                                                            form.setFieldValue(field.name, date);
                                                            form.setFieldTouched(field.name, true);
                                                        }}
                                                        minDate={this.minDate}
                                                        maxDate={this.maxDate}
                                                        defaultValue={this.defaultValue}
                                                    />
                                                </div>
                                            );
                                        }}
                                    </StandardFormInput>
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        <StandardFormButtons
                                            {...formProps}
                                            isRemoved={false}
                                            what="новость"
                                            isNewlyCreated={true}
                                            onRemove={eat}
                                        />
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Dialog>
        );
    }
}

interface SurveyEditorProps {
    width?: number;
    onClose: () => void;
    onSave: (news: FormValues) => Promise<boolean>;
}

interface FormValues {
    name: string;
    variants: string[];
    until: Date | null;
}

const getFormValues = (): FormValues => ({
    name: "",
    variants: Array(MAX_VARIANTS).fill(""),
    until: null,
});
