import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import {
  OrderIndicator,
  OrderIndicatorSnapshotType,
  fields,
  OrderIndicatorMarkupType,
  OrderIndicatorMarkup,
} from "./order-indicator";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
// import { getIn } from "formik";
// import { orderBy } from "lodash";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { Queryable } from "modules/common/models/queryable";

export const OrderIndicatorList = types
  .compose(
    Transport,
    Notificator,
    Queryable,
    types.model({
      orderIndicators: types.array(OrderIndicatorMarkup),
      sorter: TableSorter,
    })
  )
  .views((self) => ({
    get asMap(): TStringMap<OrderIndicatorMarkupType> {
      return self.orderIndicators.reduce((result, ipdType) => {
        result[ipdType.id] = ipdType;
        return result;
      }, {} as TStringMap<OrderIndicatorMarkupType>);
    },

    get data() {
      const predicate = (o: OrderIndicatorMarkupType) => {
        let result = true;
        if (self.pureQuery) {
          result = result && `${o.label} ${o.description} `.toLowerCase().indexOf(self.pureQuery) >= 0;
        }
        return result;
      };
      return self.orderIndicators.filter(predicate).map((order) => ({
        ...getSnapshot(order),
      }));
      // const order = self.sorter.asc ? "asc" : "desc";

      // return orderBy(getSnapshot(self.orderIndicators), [(o) => getIn(o, self.sorter.column)], [order]);
    },
  }))
  .actions((self) => ({
    load: flow(function* () {
      try {
        const data: any = yield self.transport.get<OrderIndicatorSnapshotType[]>(apiUrls.orderIndicators.list());
        applySnapshot(self.orderIndicators, data);

        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),

    delete: flow(function* (ipdType: OrderIndicatorSnapshotType) {
      const model = OrderIndicator.create(ipdType, {
        http: self.transport,
        notificator: self.notify,
      });
      return yield model.delete();
    }),
  }))
  .named("OrderIndicatorList");

export type OrderIndicatorsListType = typeof OrderIndicatorList.Type;
export type OrderIndicatorListSnapshotType = typeof OrderIndicatorList.SnapshotType;
export { OrderIndicator };

const sortStorage = getSortOption(OrderIndicatorList.name);

export const initialState = (): OrderIndicatorListSnapshotType => {
  const options = sortStorage({ column: fields.label, asc: DEFAULT_SORTING_ASCENDING_VALUE });

  return {
    orderIndicators: [],
    pureQuery: "",
    query: "",
    sorter: {
      id: OrderIndicatorList.name,
      tableName: OrderIndicatorList.name,
      column: options.column,
      asc: options.asc,
    },
  };
};
