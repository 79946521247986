import { types } from "mobx-state-tree";
import { nameof } from "modules/common/services/typescript";
import { OrderTechIndicatorType } from "./order";

export const OrderTechIndicator = types
    .model({
        orderIndicatorId: types.string,
        orderIndicatorName: types.maybeNull(types.string),
        number: types.string,
        guid: types.string,
    })
    .named("OrderTechIndicator");

export const mapTechIndicator = (ipd: OrderTechIndicatorType): SaveOrderTechIndicator => ({
    orderIndicatorId: ipd.orderIndicatorId,
    orderIndicatorName: ipd.orderIndicatorName,
    number: ipd.number,
    guid: ipd.guid,
});

export interface SaveOrderTechIndicator {
    orderIndicatorId: string;
    orderIndicatorName: string | null;
    number: string;
    guid: string;
}

export const techIndicatorFields = {
    number: nameof((a: SaveOrderTechIndicator) => a.number) as string,
    orderIndicatorId: nameof((a: SaveOrderTechIndicator) => a.orderIndicatorId) as string,
    orderIndicatorName: nameof((a: SaveOrderTechIndicator) => a.orderIndicatorName) as string,
};
