import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { CompanyBankDetails, CompanyBankDetailsType, CompanyBankDetailsSnapshotType, fields } from "./bank-details";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
import { getSortOption } from "modules/common/services/table/sorting-storage";
// import { toJsonHard } from 'modules/common/services/mobx/serialize';
import { Queryable } from "modules/common/models/queryable";

export const CompanyBankDetailsList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            bankDetails: types.array(CompanyBankDetails),
            sorter: TableSorter,
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<CompanyBankDetailsType> {
            return self.bankDetails.reduce((result, details) => {
                result[details.id] = details;
                return result;
            }, {} as TStringMap<CompanyBankDetailsType>);
        },

        get data() {
            const predicate = (o: CompanyBankDetailsType) => {
                let result = true;
                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.name} ${o.bankName} ${o.comment} ${o.account}`.toLowerCase().indexOf(self.pureQuery) >= 0;
                }
                return result;
            };
            return self.bankDetails.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));
            // return self.bankDetails.map(toJsonHard);
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: any = yield self.transport.get<CompanyBankDetailsSnapshotType[]>(
                    apiUrls.bankDetails.list()
                );
                applySnapshot(self.bankDetails, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        delete: flow(function* (details: CompanyBankDetailsSnapshotType) {
            const model = CompanyBankDetails.create(details, {
                http: self.transport,
                notificator: self.notify,
            });
            return yield model.delete();
        }),
    }))
    .named("CompanyBankDetailsList");

export type CompanyBankDetailsListType = typeof CompanyBankDetailsList.Type;
export type CompanyBankDetailsListSnapshotType = typeof CompanyBankDetailsList.SnapshotType;
export { CompanyBankDetails };

const sortStorage = getSortOption(CompanyBankDetailsList.name);

export const initialState = (): CompanyBankDetailsListSnapshotType => {
    const options = sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        bankDetails: [],
        pureQuery: "",
        query: "",
        sorter: {
            id: CompanyBankDetailsList.name,
            tableName: CompanyBankDetailsList.name,
            column: options.column,
            asc: options.asc,
        },
    };
};
