import { DictionaryLink } from "modules/common/models/dictionary-link";
import { types, applySnapshot, isAlive } from "mobx-state-tree";
import { BaseEntity, isNewlyCreated, DictionaryItem } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID, DATE_TIME_FORMAT } from "modules/common/constants";
import {
    BankDetails,
    fields as bankDetailsFields,
    initialState as bankDetailsInitialState,
    formatBankDetails,
} from "modules/common/models/bank-details";
import Schema from "../components/details/validation";
import moment from "moment";
import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";
import { getFieldLabel } from "modules/common/services/form/fields";
import { formatPhoneNumber } from "modules/common/services/formatting/phone";

export const ClientDictionaryItem = DictionaryItem.named("ClientDictionaryItem");

const ClientOrder = types
    .compose(
        DictionaryLink,
        types.model({
            actSum: types.number,
            planSum: types.number,
            remainSum: types.number,
        })
    )
    .named("ClientOrder");

const ClientBase = types.compose(
    Transport,
    Notificator,
    BaseEntity,
    types.model({
        name: types.string,
        fullName: types.string,
        email: types.string,
        phone: types.string,
        rating: types.number,
        comment: types.string,
        bankDetails: BankDetails,
        login: types.string,
        newPassword: types.optional(types.string, ""),
        orders: types.maybeNull(types.array(ClientOrder)),
    })
);

export const Client = ClientBase.views((self) => ({
    get isLegalEntity() {
        return true;
    },
}))
    .actions((self) => ({
        load: flow(function* (id: string) {
            try {
                const snapshot = isNewlyCreated(id)
                    ? initialState()
                    : yield self.transport.get<ClientSnapshotType>(apiUrls.clients.details(id));

                applySnapshot(self, snapshot);
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        save: flow(function* (model: ClientSnapshotType) {
            try {
                // convert input string
                if (typeof model.rating === "string") {
                    model.rating = parseInt(model.rating, 10);
                }

                const snapshot = self.isNewlyCreated
                    ? yield self.transport.put<ClientSnapshotType>(apiUrls.clients.create(), model)
                    : yield self.transport.post<ClientSnapshotType>(apiUrls.clients.update(self.id), model);

                isAlive(self) && applySnapshot(self, snapshot);
                self.notify.success(texts.messages.saved);

                return true;
            } catch (er) {
                self.notify.error(er);

                return false;
            }
        }),

        delete: flow(function* () {
            if (self.isNewlyCreated) {
                return;
            }

            try {
                yield self.transport.delete<boolean>(apiUrls.clients.delete(self.id));
                self.notify.success(texts.messages.removed);

                isAlive(self) && applySnapshot(self, initialState());

                return true;
            } catch (er) {
                self.notify.error(er);

                return false;
            }
        }),

        format() {
            return formatClient(self);
        },

        getCredentials: flow(function* () {
            try {
                const result: ClientCredentials = yield self.transport.get<any>(apiUrls.clients.credentials(self.id));
                return result;
            } catch (er) {
                self.notify.error(er);
                return null;
            }
        }),
    }))
    .views((self) => ({
        get formattedPhone() {
            return formatPhoneNumber(self.phone);
        },
    }))
    .named("Client");

export type ClientDictionaryItemType = typeof ClientDictionaryItem.Type;
export type ClientSnapshotType = typeof ClientBase.SnapshotType;
export type ClientType = typeof Client.Type;

export const initialState = (): ClientSnapshotType => ({
    id: EMPTY_OBJECT_ID,
    created: moment().format(DATE_TIME_FORMAT),
    name: "",
    fullName: "",
    email: "",
    phone: "",
    comment: "",
    rating: 3,
    bankDetails: bankDetailsInitialState(),
    login: "",
    newPassword: "",
    orders: null,
});

export const fields = {
    name: nameof((a: ClientType) => a.name) as string,
    fullName: nameof((a: ClientType) => a.fullName) as string,
    email: nameof((a: ClientType) => a.email) as string,
    phone: nameof((a: ClientType) => a.phone) as string,
    rating: nameof((a: ClientType) => a.rating) as string,
    comment: nameof((a: ClientType) => a.comment) as string,
    isLegalEntity: nameof((a: ClientType) => a.isLegalEntity) as string,
    bankDetails: nameof((a: ClientType) => a.bankDetails) as string,
    login: nameof((a: ClientType) => a.login) as string,
    newPassword: nameof((a: ClientType) => a.newPassword) as string,
    ...bankDetailsFields(`${nameof((a: ClientType) => a.bankDetails) as string}.`),
};

export function formatClient(client: ClientSnapshotType | null) {
    let result = "";

    if (client) {
        const schema = Schema();

        result += `${getFieldLabel(fields.name, schema, null)}: ${client.name}\n`;
        result += `${getFieldLabel(fields.fullName, schema, null)}: ${client.fullName}\n`;
        result += `${getFieldLabel(fields.phone, schema, null)}: ${formatPhoneNumber(client.phone)}\n`;
        result += `${getFieldLabel(fields.email, schema, null)}: ${client.email}\n`;
        result += `${getFieldLabel(fields.comment, schema, null)}: ${client.comment}\n`;
        result += formatBankDetails(client.bankDetails);
    }

    return result;
}

export interface ClientCredentials {
    login: string;
    password: string;
}

export const ClientFunctionalities = {
    CLIENTS_USER_ACCESS: "CLIENTS_USER_ACCESS",
};
