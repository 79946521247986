import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import React, { useState } from "react";
// import Lightbox from "react-image-lightbox";
import Lightbox from "lightbox-react";
import styles from "./Block.module.scss";
import { DocumentsLinkSnapshotType, WorkResultStoreType } from "modules/orders-manage/models/order-work-result";
import { Classes, TextArea } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { FileLink } from "modules/common/components/files/FileLink";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { DownloadButton } from "../content-task/RowTask";

function Row(props: RowFileProps) {
    const [downloading, setDownloading] = useState(false);
    const [isOpen, setisOpen] = useState(false);
    const [images, setimages] = useState<string[]>([]);
    const [imageIndex, setimageIndex] = useState(0);

    const {
        downloadFile,
        file,
        actual,
        changeComment,
        saveComment,
        saveFileComment,
        readOnly,
        oldFile,
        guid,
        baseUrl,
        uploadFile,
        canDownload,
        downloadVersion,
    } = props;

    const setImages = (file: string) => {
        const newImages: string[] = images;
        newImages.push(file);
        setimages(newImages);
    };

    const getNextImages = async (imagesCount: number) => {
        const count = images.length + imagesCount;
        const start = images.length;
        for (let i = start; i < file.previewPages.length; i++) {
            if (i < count) {
                const newFile = await downloadFile(file.previewPages[i]);
                if (newFile) {
                    setImages(newFile);
                }
            } else {
                break;
            }
        }
    };

    return (
        <div className={styles.rowFile}>
            <div className={styles.taskFileLeft}>
                <div
                    className={`file-link ${styles.fileBlock}`}
                    onClick={async () => {
                        if (images.length === 0) {
                            await getNextImages(3);
                        }
                        setisOpen(true);
                    }}
                >
                    <GeneralIcon
                        type="general-document"
                        style={{
                            position: "absolute",
                            left: "20px",
                        }}
                    />
                    <span className={styles.fileName}>{file.name}</span>
                </div>
                {!actual && <div style={{ marginLeft: "24px" }}>Версия_{file.version}</div>}
                {actual && (
                    <div style={{ marginLeft: "24px", /*color: "#48C273",*/ fontWeight: "bold" }}>
                        Версия_{file.version}
                    </div>
                )}
                <div style={{ marginLeft: "28px" }}>
                    {canDownload && (
                        <DownloadButton
                            loading={downloading}
                            onClick={async () => {
                                try {
                                    setDownloading(true);
                                    await downloadVersion(guid, +file.version);
                                } finally {
                                    setDownloading(false);
                                }
                            }}
                        />
                    )}
                </div>
            </div>
            <div className={styles.taskFileRight}>
                <div className={styles.taskFileRightItem} style={{ display: "flex", width: "60%" }}>
                    <div className={`${Classes.INPUT_GROUP}  planr-default-input`} style={{ width: "88%" }}>
                        {!readOnly && (
                            <TextArea
                                style={{
                                    height: "65px",
                                    maxHeight: "65px",
                                }}
                                growVertically={false}
                                rows={1}
                                autoComplete="off"
                                data-lpignore="true"
                                value={file.comment}
                                className={`${Classes.FILL} planr-default-input`}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    changeComment(e.target.value);
                                }}
                            />
                        )}
                        {readOnly && <div className={styles.commentSupplier}>{file.comment}</div>}
                    </div>
                    {!readOnly && file.comment !== oldFile.comment && (
                        <div
                            style={{
                                marginLeft: "10px",
                            }}
                        >
                            <div>
                                <GeneralIcon
                                    style={{ color: "#1DD278", cursor: "pointer", marginTop: "6px" }}
                                    type="general-redo"
                                    onClick={async () => {
                                        await saveComment();
                                    }}
                                    size={20}
                                    title="Сохранить"
                                />
                            </div>
                            <div>
                                <GeneralIcon
                                    type="general-undo"
                                    style={{ color: "#EA561E", cursor: "pointer", marginTop: "8px" }}
                                    size={20}
                                    onClick={() => {
                                        changeComment(oldFile.comment);
                                    }}
                                    title="Отменить"
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.taskFileRightItem}>
                    {file.commentFiles?.map((commentFile, index) => (
                        <FileLink<any>
                            key={index}
                            baseUrl={baseUrl}
                            file={{
                                fileId: commentFile.fileId,
                                fileName: commentFile.fileName,
                            }}
                            style={{
                                marginBottom: "8px",
                                minWidth: "320px",
                                width: "320px",
                                fontSize: "12px",
                            }}
                            readOnly={readOnly}
                            onRemove={async () => {
                                await saveFileComment(commentFile, true);
                            }}
                        />
                    ))}
                    {!readOnly && (
                        <PlanRDndUploader
                            onFileSelected={async (file: File) => {
                                const meta = await uploadFile(file);
                                if (meta) {
                                    await saveFileComment(meta, false);
                                }
                            }}
                            accept={"*"}
                            oneRow={true}
                            width={320}
                            noType={true}
                        />
                    )}
                </div>
            </div>
            <div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[imageIndex]}
                        nextSrc={images[(imageIndex + 1) % images.length]}
                        prevSrc={images[(imageIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => {
                            setisOpen(false);
                        }}
                        onMovePrevRequest={() => {
                            if (imageIndex === 0) {
                                return;
                            }
                            setimageIndex((imageIndex + images.length - 1) % images.length);
                        }}
                        onMoveNextRequest={async () => {
                            if (imageIndex === images.length - 1) {
                                await getNextImages(3);
                            }
                            setimageIndex((imageIndex + 1) % images.length);
                        }}
                    />
                )}
            </div>
        </div>
    );
}

interface RowFileProps {
    canDownload: boolean;
    downloadFile: (item: string) => Promise<any>;
    file: DocumentsLinkSnapshotType;
    oldFile: DocumentsLinkSnapshotType;
    actual?: boolean;
    store: WorkResultStoreType;
    guid: string;
    changeComment: (comment: string) => void;
    downloadVersion: (guid: string, version: number) => Promise<any>;
    readOnly: boolean;
    saveComment: () => Promise<void>;
    saveFileComment: (commentFile: any, deleted: boolean) => Promise<void>;
    uploadFile: (uploadFile: File) => Promise<any>;

    baseUrl: string;
}

export const RowFile = observer(Row);
