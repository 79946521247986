import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { Classes, Dialog, InputGroup, MenuItem, Switch } from "@blueprintjs/core";
import { ItemRenderer } from "@blueprintjs/select";
import { Field, FieldProps, Form, Formik } from "formik";
import { observer } from "mobx-react";
import { SuppliersStoreType } from "modules/agents/suppliers/models/suppliers-store";
import { FileLink } from "modules/common/components/files/FileLink";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Search } from "modules/common/components/form/Search";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { gridMoneyFormatterWithCurrency } from "modules/common/components/money/Money";
import { MoneyInput } from "modules/common/components/money/MoneyInput";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { PlanrTag, PlanrTagType } from "modules/common/components/planr/tag/PlanrTag";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { Pager } from "modules/common/components/table/Pager";
import { MimeTypes } from "modules/common/services/files";
import {
  DefaultSelectedOption,
  filterItemPredicate,
  renderSingleOption,
  SimpleSelect,
} from "modules/common/services/form/select";
import { DefaultAgGridOptions, defaultModules, FIGMA_HEADER_HEIGHT } from "modules/common/services/table/helpers";
import { eat } from "modules/common/services/typescript";
import { texts } from "modules/common/texts";
import { OverheadTypeDictionaryType } from "modules/dictionaries/overhead-types/models/overhead-type-dictionary";
import { Constants } from "modules/root/models/constants";
import { agGridLocale } from "modules/root/services/locale";
import React, { useState } from "react";
import { FinanceRequestModel, OwnFinanceRequestsStoreType, fields } from "../models/own-finance-requests";
import { getRowNodeId, fields as tableFields } from "../models/request";
import { InvoiceActions, RenderInvoice } from "./columns";
import styles from "./OwnFinanceRequests.module.scss";
import { RequestSchema, requestLabels } from "./validaion";

export const OwnFinanceRequests = observer(
  class extends React.Component<OwnFinanceRequestsProps, OwnFinanceRequestsState> implements InvoiceActions {
    private options = DefaultAgGridOptions(true);
    private columns = columnsBuilder();

    constructor(props: OwnFinanceRequestsProps) {
      super(props);

      this.state = { opened: false, formVisible: false };
    }

    baseUrl = () => this.props.store.baseUrl;

    componentDidMount() {
      this.props.store.init();
      this.props.suppliers.loadNoRules();
      this.props.store.load();
    }

    render() {
      const { opened, formVisible } = this.state;
      const { store, suppliers, isMobile } = this.props;

      return (
        <>
          <PlanrButton type="secondary" size="small" onClick={this.open} style={isMobile ? { marginLeft: "0px" } : {}}>
            Мои фин запросы
          </PlanrButton>

          <Dialog
            icon="info-sign"
            title="Мои финансовые запросы"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            isOpen={opened}
            onClose={this.close}
            style={{ width: `1100px` }}
            backdropClassName="standard"
          >
            <div className={`${Classes.DIALOG_BODY} ${styles.requests}`}>
              <div className={styles.search}>
                <Search query={store.query} onSearch={store.setQuery} />
              </div>

              <GridTheme bordered={false} className="designed">
                <AgGridReact
                  rowStyle={{
                    borderBottom: "1 px solid #EDF0F2",
                    fontFamily: "GothamPro, Arial, sans-serif",
                    fontWeight: "300",
                    color: "#00273D",
                    fontSize: "12px",
                  }}
                  suppressRowClickSelection={true}
                  suppressCellSelection={true}
                  enableCellTextSelection={true}
                  columnDefs={this.columns}
                  rowData={store.data}
                  headerHeight={FIGMA_HEADER_HEIGHT}
                  rowHeight={35}
                  minColWidth={5}
                  getRowNodeId={getRowNodeId}
                  domLayout="autoHeight"
                  localeText={agGridLocale}
                  context={this}
                  gridOptions={this.options}
                  immutableData={true}
                  modules={defaultModules}
                  animateRows={false}
                />
              </GridTheme>

              <div className={styles.pager}>
                <Pager
                  itemsPerPage={store.pageSize}
                  page={store.page}
                  totalItems={store.totalCount}
                  pageSelected={store.load}
                  small={true}
                  hideIfEmpty={true}
                />
              </div>
            </div>

            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <PlanrButton
                  type="secondary"
                  icon="general-plus-big"
                  onClick={this.openForm}
                  size="small"
                  title="Новый запрос"
                  style={{ width: "100%" }}
                >
                  Новый запрос
                </PlanrButton>
              </div>
            </div>
          </Dialog>

          {formVisible && opened && (
            <DetailsPopup
              title="Новый финансовый запрос"
              onCancel={this.closeForm}
              style={isMobile ? { width: "100%", zIndex: 1000 } : undefined}
              width={640}
            >
              <RequestDetails
                onSaved={this.closeForm}
                save={store.save}
                upload={store.uploadFile}
                types={store.types}
                baseUrl={store.baseUrl}
                suppliers={suppliers}
              />
            </DetailsPopup>
          )}
        </>
      );
    }

    open = () => {
      this.setState({ opened: true });
      this.props.store.load();
    };

    close = () => this.setState({ opened: false, formVisible: false });

    openForm = () => {
      this.setState({ formVisible: true });
    };

    closeForm = () => this.setState({ formVisible: false });
  }
);

const RequestDetails = ({ save, onSaved, upload, baseUrl, types, suppliers }: RequestDetailsProps) => {
  const [supplierFieldNotVisible, setSupplierFieldNotVisible] = useState(false);

  const labels = requestLabels();
  const schema = RequestSchema(supplierFieldNotVisible);

  const suppliersList = suppliers.list.data.map((item) => {
    return { id: item.id, label: item.name, comment: item.comment };
  });

  return (
    <div className={styles.details}>
      <Formik
        initialValues={getFormValues()}
        enableReinitialize={true}
        validationSchema={schema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const succsessfull = await save(values);
          setSubmitting(false);

          if (succsessfull) {
            resetForm();
            onSaved();
          }
        }}
      >
        {(formProps) => {
          return (
            <Form autoComplete="off">
              <div className={Classes.DIALOG_BODY}>
                <StandardFormInput name={fields.typeId} schema={schema} label="Категория" small={true}>
                  {({ field, form }) => {
                    const typeOption = types.asMap[field.value];

                    return (
                      <SimpleSelect
                        className={`full-width-select ${Classes.FILL}`}
                        activeItem={typeOption}
                        items={types.types}
                        itemRenderer={renderSingleOption}
                        onItemSelect={(i) => {
                          form.setFieldValue(field.name, i.id, true);
                        }}
                        popoverProps={{
                          boundary: "viewport",
                          popoverClassName: "import-spendings-table__select",
                        }}
                        inputProps={{
                          placeholder: "Расход",
                        }}
                        filterable={true}
                        itemPredicate={filterItemPredicate}
                      >
                        <DefaultSelectedOption option={typeOption} />
                      </SimpleSelect>
                    );
                  }}
                </StandardFormInput>

                <StandardFormInput
                  name={fields.date}
                  schema={schema}
                  small={true}
                  className="planr-form-input"
                  inline={true}
                >
                  {({ field, form }) => {
                    return (
                      <div className={`${Classes.INPUT_GROUP} `}>
                        <DatePicker
                          clasName="planr-default-input"
                          value={field.value}
                          onChange={(date) => {
                            form.setFieldValue(field.name, date, true);
                          }}
                        />
                      </div>
                    );
                  }}
                </StandardFormInput>

                <StandardFormInput name={fields.sum} schema={schema} small={true} className="planr-form-input">
                  {({ field, form }) => (
                    <MoneyInput
                      className="in-cell planr-default-input"
                      id={field.name}
                      small={false}
                      autoComplete="off"
                      data-lpignore="true"
                      value={field.value}
                      onMoneyChange={(money) => {
                        form.setFieldValue(field.name, money.value, true);
                      }}
                    />
                  )}
                </StandardFormInput>

                <StandardFormInput name={fields.comment} schema={schema} small={true} className="planr-form-input">
                  {({ field }) => (
                    <InputGroup id={field.name} {...field} className="planr-default-input" autoComplete="off" />
                  )}
                </StandardFormInput>

                <StandardFormInput
                  name={fields.immediate}
                  schema={schema}
                  small={true}
                  className="planr-form-input"
                  inline={true}
                >
                  {({ field }) => <Switch checked={field.value} {...field} large={true} />}
                </StandardFormInput>

                <div className={styles.supplierBlock}>
                  <div className={styles.supplierField}>
                    {supplierFieldNotVisible && (
                      <StandardFormInput
                        name={fields.supplierName}
                        schema={schema}
                        small={true}
                        className="planr-form-input"
                        inline={false}
                      >
                        {({ field, form }) => {
                          return (
                            <InputGroup
                              id={field.name}
                              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                form.setFieldValue(field.name, e.currentTarget.value);
                                form.setFieldValue(fields.supplierId, "");
                                form.setFieldTouched(field.name, true);
                              }}
                              value={field.value}
                              className="planr-default-input"
                              autoComplete="off"
                              //   style={{ width: "350px" }}
                            />
                          );
                        }}
                      </StandardFormInput>
                    )}

                    {!supplierFieldNotVisible && (
                      <StandardFormInput
                        name={fields.supplierId}
                        schema={schema}
                        small={true}
                        className="planr-form-input"
                        inline={false}
                      >
                        {({ field, form }) => {
                          const item = suppliersList.find((i) => i.id === field.value);
                          const option = item ? item : { id: "", label: "" };
                          return (
                            <SimpleSelect
                              activeItem={option}
                              filterable={true}
                              itemPredicate={filterItemPredicate}
                              inputProps={{
                                placeholder: texts.search,
                              }}
                              items={suppliersList}
                              itemRenderer={renderOwnRequestOptionFactory()}
                              onItemSelect={(item: SelectItem) => {
                                form.setFieldValue(field.name, item.id, true);
                                form.setFieldValue(fields.supplierName, "", true);
                              }}
                              matchTargetWidth={true}
                            >
                              <DefaultSelectedOption
                                option={item}
                                onClear={() => {
                                  form.setFieldValue(field.name, "", true);
                                  form.setFieldValue(fields.supplierName, "", true);
                                }}
                              />
                            </SimpleSelect>
                          );
                        }}
                      </StandardFormInput>
                    )}
                  </div>
                  <div className={styles.supplierSwitch}>
                    <div className={styles.supplierSwitchLabel}>Нет в списке</div>
                    <Switch
                      checked={supplierFieldNotVisible}
                      onChange={() => {
                        setSupplierFieldNotVisible(!supplierFieldNotVisible);
                      }}
                      large={true}
                    />
                  </div>
                </div>

                <Field name={fields.filesId}>
                  {({ field, form }: FieldProps) => {
                    const onRemoveFile = (file: FileBase) => {
                      const fileIndex = field.value.findIndex((v: any) => v.fileId === file.fileId);
                      if (fileIndex > -1) {
                        let newFiles = [...field.value];
                        newFiles.splice(fileIndex, 1);
                        form.setFieldValue(field.name, newFiles, true);
                      }
                    };

                    const onFileSelected = async (file: File) => {
                      const fileResult = await upload(file);
                      let newFiles = null;
                      if (fileResult) {
                        if (field.value) {
                          newFiles = [...field.value];
                          newFiles.push(fileResult);
                        } else {
                          newFiles = [fileResult];
                        }
                        form.setFieldValue(field.name, newFiles, true);
                        form.setFieldTouched(field.name);
                      }
                    };

                    return (
                      <div className={styles.fileList}>
                        <label className="bp3-label">{labels[fields.filesId]}</label>

                        {field.value &&
                          field.value.map((file: FileBase) => (
                            <FileLink
                              key={file.fileId}
                              baseUrl={baseUrl}
                              file={file}
                              onRemove={onRemoveFile}
                              style={{ maxWidth: "none", height: "60px" }}
                            />
                          ))}

                        <PlanRDndUploader
                          accept={`${MimeTypes.pdf},${MimeTypes.png},${MimeTypes.jpeg},${MimeTypes.jpg}`}
                          onFileSelected={onFileSelected}
                          withLoader={true}
                          multiple={false}
                          style={{ maxWidth: "none" }}
                        />
                      </div>
                    );
                  }}
                </Field>
              </div>

              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <StandardFormButtons
                    {...formProps}
                    isRemoved={false}
                    what={`запрос`}
                    isNewlyCreated={true}
                    onRemove={eat}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const getFormValues = (): FinanceRequestModel => ({
  comment: "",
  date: null,
  filesId: null,
  immediate: false,
  sum: 0,
  typeId: "",
  supplierId: "",
  supplierName: "",
});

interface RequestDetailsProps {
  types: OverheadTypeDictionaryType;
  suppliers: SuppliersStoreType;
  save: (values: FinanceRequestModel) => Promise<boolean>;
  onSaved: () => void;
  baseUrl: string;
  upload: (file: File) => Promise<FileBase | null>;
}

interface OwnFinanceRequestsProps {
  store: OwnFinanceRequestsStoreType;
  suppliers: SuppliersStoreType;
  isMobile?: boolean;
}

interface OwnFinanceRequestsState {
  opened: boolean;
  formVisible: boolean;
}

function columnsBuilder() {
  const result: ColDef[] = [
    {
      suppressMovable: true,
      headerName: "Вид расхода",
      field: tableFields.workTypeName,
      width: 170,
      sortable: false,
    },
    {
      headerName: "Дата",
      field: tableFields.date,
      width: 100,
      sortable: false,
    },
    {
      suppressMovable: true,
      headerName: "Сумма",
      field: tableFields.sum,
      width: 120,
      sortable: false,
      valueFormatter: gridMoneyFormatterWithCurrency,
    },
    {
      suppressMovable: true,
      headerName: "Примечания",
      field: tableFields.comment,
      flex: 1,
      sortable: false,
    },
    {
      suppressMovable: true,
      headerName: "Контрагент",
      field: tableFields.supplier,
      width: 250,
      sortable: false,
      cellRendererFramework: (params: ICellRendererParams) => {
        const { data } = params;
        const { supplier } = data;
        if (supplier && supplier.label) {
          return <span>{supplier.label}</span>;
        } else return null;
      },
    },
    {
      suppressMovable: true,
      headerName: "Счет",
      field: tableFields.invoice,
      width: 60,
      sortable: true,
      headerClass: "centered",
      cellClass: "centered",
      cellRendererFramework: RenderInvoice,
    },
    {
      suppressMovable: true,
      headerName: "Срч",
      field: tableFields.immediate,
      width: 70,
      cellClass: "centered",
      headerClass: "centered",
      cellRendererFramework: (params: any) => {
        const immediate: boolean = params.value;

        return immediate ? (
          <span title="Срочно">
            <GeneralIcon type={"general-exclamation"} style={{ color: "#EA561E" }} />
          </span>
        ) : null;
      },
    },
    {
      suppressMovable: true,
      headerName: "Статус",
      field: tableFields.status,
      width: 100,
      cellClass: "centered",
      headerClass: "centered",
      cellRendererFramework: (params: any) => {
        const status: string = params.value;

        let type: PlanrTagType = "neutral";
        if (status === Constants.financeRequestPaidStatus) {
          type = "success";
        } else if (status === Constants.financeRequestCancelledStatus) {
          type = "danger";
        } else if (status === Constants.financeRequestAcceptedStatus) {
          type = "primary";
        }

        return <PlanrTag type={type}>{status}</PlanrTag>;
      },
    },
  ];

  return result;
}

export const renderOwnRequestOptionFactory = (): ItemRenderer<any> => {
  return (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    const text = (
      <div className={styles.requestItem}>
        <div className={styles.requestRow}>
          <div className={styles.project}>{item.label}</div>
        </div>
        <div className={styles.requestRow}>
          <div className={styles.outsourcerName}>{item.comment}</div>
        </div>
      </div>
    );

    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={item.id}
        onClick={handleClick}
        text={text}
      />
    );
  };
};
