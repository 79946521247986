import * as yup from "yup";
import { texts } from "modules/common/texts";
const { object, string } = yup;

export const newsLabels = () => ({
    name: texts.name,
    text: "Текст",
});

const NewsSchema = () => {
    const labels = newsLabels();

    return object().shape({
        name: string().label(labels.name).required(),

        text: string().label(labels.text).required(),
    });
};

export default NewsSchema;
