import React from "react";
import { TableIcon, FIGMA_TABLE_ICON_SIZE } from "./helpers";
import { GridApi, ColumnApi, Column } from "@ag-grid-community/core";

export type TSorting = "asc" | "desc" | "none";

export const SortIcon = (direction: TSorting) => {
    let sortIcon: any = null;

    if (direction === "asc" || direction === "desc") {
        sortIcon = (
            <span className={`ag-header-icon ag-header-label-icon ag-sort-${direction}ending-icon`} aria-hidden="true">
                {TableIcon(direction === "asc" ? "fa-caret-up" : "fa-caret-down", FIGMA_TABLE_ICON_SIZE, false)}
            </span>
        );
    }

    return sortIcon;
};

export const ExpandIcon = ({ expanded, onClick }: { expanded: boolean; onClick: () => void }) => {
    const name = expanded ? "expanded" : "collapsed";

    return (
        <span
            className={`ag-header-icon ag-header-expand-icon ag-header-expand-icon-${name}`}
            aria-hidden="true"
            onClick={onClick}
        >
            {TableIcon(expanded ? "fa-caret-right" : "fa-caret-left", FIGMA_TABLE_ICON_SIZE, false)}
        </span>
    );
};

export const CustomHeaderCell: React.FC<{ label: any } & React.HTMLAttributes<HTMLSpanElement>> = ({
    label,
    children,
    ...rest
}) => {
    return (
        <div className="ag-cell-label-container">
            <div className="ag-header-cell-label" role="presentation" unselectable="on">
                <span className="ag-header-cell-text" role="columnheader" unselectable="on" {...rest}>
                    {label}
                </span>
                {children}
            </div>
        </div>
    );
};

interface CustomSortableHeaderProps extends IHeaderComponentFrameworkParams {
    renderLabel: (params: IHeaderComponentFrameworkParams) => any;
}

export class CustomSortableHeader extends React.PureComponent<CustomSortableHeaderProps, { sorting: TSorting }> {
    constructor(props: any) {
        super(props);

        props.column.addEventListener("sortChanged", this.onSortChanged);
        this.state = { sorting: this.getSorting() };
    }

    componentWillUnmount() {
        this.props.column.removeEventListener("sortChanged", this.onSortChanged);
    }

    render() {
        const { renderLabel, ...rest } = this.props;
        const sortIcon = rest.enableSorting ? SortIcon(this.state.sorting) : null;

        return <CustomHeaderCell onClick={this.setSort} label={renderLabel(rest)} children={sortIcon} />;
    }

    setSort = () => {
        const toggle: TStringMap<string> = {
            asc: "desc",
            desc: "asc",
            none: "asc",
        };

        const direction = toggle[this.state.sorting];
        const sortModel = [{ colId: this.props.column.getId(), sort: direction }];
        this.props.api.setSortModel(sortModel);
    };

    getSorting = () => {
        if (this.props.column.isSortAscending()) {
            return "asc";
        }

        if (this.props.column.isSortDescending()) {
            return "desc";
        }

        return "none";
    };

    onSortChanged = () => {
        this.setState({ sorting: this.getSorting() });
    };
}

export interface IHeaderComponentFrameworkParams<T = undefined> {
    context: T;
    api: GridApi;
    columnApi: ColumnApi;
    column: Column;
    enableSorting: boolean;
    displayName: string;
}
