import { types } from "mobx-state-tree";

export const Changelog = types
    .model({
        name: types.string,
        description: types.string,
    })
    .named("Changelog");

export type ChangelogType = typeof Changelog.Type;
