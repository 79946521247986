import { Button, Classes, FormGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { EventTypes, fields } from "modules/orders-manage/models/order-events";
import React, { useCallback } from "react";
import { DefaultSelectedOption, renderSingleOption, SimpleSelect } from "modules/common/services/form/select";
import { CommonBusinessEventProps, Error, stageLabel, star } from "./common";

const Base = ({ event, readonly, stages, additions, isNotStateChange }: AdditionBusinessEventProps) => {
  const { value, validate } = event.model;

  const stage = stages.find((o) => o.stage.newGuid === value);

  let addition = null;
  if (value) {
    addition = additions.find((o: any) => o.orderNumber === value);
  } else {
    addition = additions.find((o: any) => o.orderNumber === event.model.additionNumber);
  }

  const item: SelectItem | null = addition ? { id: addition.orderNumber, label: addition.fileName } : null;
  const options = stages.map((o) => ({ id: o.stage.newGuid, label: stageLabel(o) }));
  const optionsAdditions = additions.map((a: any) => ({ id: a.orderNumber, label: a.fileName }));

  const onChangeHandler = useCallback(
    (i: SelectItem) => {
      event.model.setValue(fields.additionNumber, i.id);
    },
    [event]
  );

  const valid = !item ? "Необходимо выбрать доп соглашение" : null;

  const onChange = (i: SelectItem) => {
    isNotStateChange && isNotStateChange("additionNumber", i.id);
  };

  if (!isNotStateChange)
    return (
      <div>
        <FormGroup
          labelInfo={star}
          helperText={validate[fields.additionNumber]}
          intent={validate[fields.additionNumber] ? Error : "none"}
          className={Classes.SMALL}
        >
          <div className={`${Classes.INPUT_GROUP}`}>
            <SimpleSelect
              className={`full-width-select ${Classes.FILL}`}
              filterable={false}
              activeItem={item}
              items={optionsAdditions}
              itemRenderer={renderSingleOption}
              onItemSelect={onChangeHandler}
              disabled={readonly}
              popoverProps={{
                usePortal: true,
                boundary: "viewport",
                popoverClassName: "business-event__typeselect-dropdown",
              }}
            >
              {readonly && (
                <Button fill={true} className="selected-option">
                  {stage && stageLabel(stage)}
                </Button>
              )}
              {!readonly && <DefaultSelectedOption option={item} empty={!options.length} />}
            </SimpleSelect>
          </div>
        </FormGroup>
      </div>
    );

  return (
    <div>
      <div className={`${Classes.INPUT_GROUP}`}>
        <div style={{ color: "red", fontSize: "12px" }}>{valid ? valid : null}</div>
        <SimpleSelect
          className={`full-width-select ${Classes.FILL}`}
          filterable={false}
          activeItem={item}
          items={optionsAdditions}
          itemRenderer={renderSingleOption}
          onItemSelect={onChange}
          disabled={readonly}
          popoverProps={{
            usePortal: true,
            boundary: "viewport",
            popoverClassName: "business-event__typeselect-dropdown",
          }}
        >
          {readonly && (
            <Button fill={true} className="selected-option">
              {stage && stageLabel(stage)}
            </Button>
          )}
          {!readonly && <DefaultSelectedOption option={item} empty={!options.length} />}
        </SimpleSelect>
      </div>
    </div>
  );
};

Base.TYPE = EventTypes.AdditionBusinessEvent;

interface AdditionBusinessEventProps extends CommonBusinessEventProps {
  additions: any;
}

export const OrderAdditionBuisnessEvent = observer(Base);
