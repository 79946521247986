import { ColDef, ColGroupDef, ColumnApi, ColumnState } from "@ag-grid-community/core";
import { types, getSnapshot } from "mobx-state-tree";
import { saveColumns } from "modules/common/services/table/columns-storage";

import { TableSorterType } from "./table-sorter";

export const MOVE_STORAGE_PREFIX = "moved_";
export const SIZE_STORAGE_PREFIX = "resized_";

export const TableColumner = types
    .model("TableColumner", {
        tableName: types.string,
        hiddenColumns: types.array(types.string),
        movedColumns: types.array(types.string),
        sizedColumns: types.array(types.string),
    })
    .actions((self) => ({
        isColumnSelected(col: ColumnConfig) {
            return isColumnSelected(col, self.hiddenColumns);
        },

        buildColumnsState(colsDef: any[], sorter: TableSorterType) {
            const newState: ColumnState[] = [];

            colsDef.forEach((def) => {
                if (def.groupId) {
                    const column: ColGroupDef = def;
                    column.children.forEach((child: any) => {
                        newState.push({
                            aggFunc: null,
                            colId: child.field,
                            flex: null,
                            hide: false,
                            pinned: null,
                            pivot: false,
                            pivotIndex: null,
                            rowGroup: false,
                            rowGroupIndex: null,
                            sort: sorter.column === child.field ? sorter.direction : undefined,
                            sortIndex: null,
                            width: child.width,
                        });
                    });
                } else {
                    const column: ColDef = def;
                    newState.push({
                        aggFunc: null,
                        colId: column.field,
                        flex: column.flex,
                        hide: false,
                        pinned: null,
                        pivot: false,
                        pivotIndex: null,
                        rowGroup: false,
                        rowGroupIndex: null,
                        sort: sorter.column === column.field ? sorter.direction : undefined,
                        sortIndex: null,
                        width: column.width,
                    });
                }
            });

            return newState;
        },
    }))
    .actions((self) => ({
        filterColumns(all: ColumnConfig[]) {
            return filterColumns(all, self.hiddenColumns);
        },

        columnDeselected(column: string) {
            const storage = saveColumns(self.tableName);

            self.hiddenColumns.push(column);

            storage(getSnapshot(self.hiddenColumns));
        },

        columnSelected(column: string) {
            const storage = saveColumns(self.tableName);

            self.hiddenColumns.remove(column);

            storage(getSnapshot(self.hiddenColumns));
        },

        saveColumnsOrder(all: ColumnState[]) {
            const storage = saveColumns(MOVE_STORAGE_PREFIX + self.tableName);
            self.movedColumns.clear();

            all.forEach((item, index: number) => {
                self.movedColumns[index] = item.colId ?? "";
            });

            storage(getSnapshot(self.movedColumns));
        },

        saveColumnsSize(all: ColumnState[]) {
            const storage = saveColumns(SIZE_STORAGE_PREFIX + self.tableName);
            self.sizedColumns.clear();

            all.forEach((item, index: number) => {
                if (item.colId && item.width)
                    self.sizedColumns.push(`{"colId": "${item.colId}", "width": ${item.width}}`);
            });

            storage(getSnapshot(self.sizedColumns));
        },

        setColumnVisibility(api: ColumnApi, columns: any[], id: string, visibility: boolean) {
            const group: ColGroupDef = columns.find((col) => col.groupId === id);
            if (group) {
                group.children.forEach((child: any) => {
                    api.setColumnVisible(child.field, visibility);
                });
            }

            api.setColumnVisible(id, visibility);
        },

        reset() {
            const storage = saveColumns(MOVE_STORAGE_PREFIX + self.tableName);
            const storageHide = saveColumns(self.tableName);
            self.movedColumns.clear();
            self.hiddenColumns.clear();
            storage(getSnapshot(self.movedColumns));

            storageHide(getSnapshot(self.hiddenColumns));
        },
    }))
    .actions((self) => ({
        applyVisibility(api: ColumnApi, columns: any[]) {
            self.hiddenColumns.forEach((column) => {
                self.setColumnVisibility(api, columns, column, false);
            });
        },
        sizeColumns(colsDef: ColDef[]) {
            self.sizedColumns.forEach((column) => {
                const col = JSON.parse(column);

                colsDef.forEach((def: any) => {
                    if (def.groupId) {
                        const column: any = def;
                        column.children.forEach((child: any) => {
                            if (col.colId === child.field) {
                                child.width = col.width;
                            }
                        });
                    } else {
                        const column: ColDef = def;
                        if (col.colId === column.field) {
                            column.width = col.width;
                        }
                    }
                });
            });

            return colsDef;
        },
        hideColumns(colsDef: any[]) {
            self.hiddenColumns.forEach((item) => {
                colsDef.forEach((def: any) => {
                    if (def.groupId) {
                        const column: ColGroupDef = def;
                        if (column.groupId === item) {
                            column.children.forEach((child: ColDef) => {
                                child.hide = true;
                            });
                        }
                    } else {
                        const column: ColDef = def;

                        if (column.field === item) {
                            column.hide = true;
                        }
                    }
                });
            });
        },

        moveColumns(colsDef: ColDef[], sorter: TableSorterType | null) {
            let i = 0;
            let v = 0;
            let childs: TStringMap<ParentIndex[]> = {};

            // Получаем объекст с группами и позициями children в исходном массиве
            colsDef.forEach((def: any) => {
                if (def.groupId) {
                    const column: ColGroupDef = def;
                    if (v === 0) {
                        i++;
                    }
                    v = i;
                    if (column.children.length > 0) {
                        if (typeof column.groupId !== "undefined") {
                            childs[column.groupId ?? ""] = column.children.map((item) => {
                                const col: ColDef = item;
                                i++;
                                return { name: col.field ?? "", index: i - 2 };
                            });
                        }
                    }
                } else {
                    i++;
                }
            });

            const children: TStringMap<ParentIndex[]> = {};
            if (self.movedColumns.length) {
                // Получаем позиции со смещненными children в группах
                for (let key in childs) {
                    const indexOfParents: ParentIndex[] = [];
                    self.movedColumns.forEach((item, index: number) => {
                        for (let i = 0; i < childs[key].length; i++) {
                            if (item === childs[key][i].name) {
                                indexOfParents.push({ name: item, index: index });
                            }
                        }
                    });
                    children[`${key}Moved`] = indexOfParents;
                }

                //Сортируем исходный массив без групп
                let arr: any[] = [];
                self.movedColumns.forEach((item) => {
                    colsDef.forEach((def: any) => {
                        if (def.groupId) {
                            const column: any = def;
                            if (item === column.children[0].field) {
                                arr.push(column);
                            }
                        } else {
                            const column: ColDef = def;
                            if (item === column.field) {
                                arr.push(column);
                            }
                        }
                    });
                });

                let parentIndex: any = [];
                //Получаем позиции всех родительских элементов в группах
                for (let key in children) {
                    for (let i = 0; i < children[key].length; i++) {
                        arr.forEach((def: any) => {
                            if (def.groupId) {
                                const column: any = def;
                                if (children[key][i].name === column.children[0].field) {
                                    parentIndex.push(children[key][i].index);
                                }
                            }
                        });
                    }
                }

                let movedFromGroup: any = [];
                let arrMoved: any = [];

                for (let i = 0; i < parentIndex.length; i++) {
                    for (let key in children) {
                        let parentId = parentIndex[i];
                        for (let i = 0; i < children[key].length; i++) {
                            if (children[key][i].index < parentId) {
                                movedFromGroup.push(children[key][i]);
                            }
                        }
                        i++;
                    }
                }

                movedFromGroup.forEach((item: any) => {
                    arr.forEach((def: any) => {
                        if (def.groupId) {
                            const column: any = def;

                            column.children.forEach((child: any, index: number) => {
                                if (child.field === item.name) {
                                    arrMoved.push(child);
                                    column.children.splice(index, 1);
                                }
                            });
                        }
                    });
                });

                movedFromGroup.forEach((item: any, index: any) => {
                    arr.splice(item.index, 0, arrMoved[index]);
                });

                if (sorter) {
                    arr.forEach((def: ColDef) => {
                        def.sort = sorter.column === def.field ? sorter.direction : undefined;
                    });
                }

                return arr;
            } else {
                if (sorter) {
                    colsDef.forEach((def: ColDef) => {
                        def.sort = sorter.column === def.field ? sorter.direction : undefined;
                    });
                }

                return colsDef;
            }
        },
    }));

export type TableColumnerType = typeof TableColumner.Type;
export type TableColumnerSnapshotType = typeof TableColumner.SnapshotType;

export const isColumnSelected = (col: ColumnConfig, hiddenColumns: string[]) => {
    if (!col.headerName) {
        return true;
    }

    const id = col.field ?? col.groupId;

    return hiddenColumns.length > 0 ? !hiddenColumns.includes(id ?? "") : true;
};

export const filterColumns = (all: ColumnConfig[], hiddenColumns: string[]) => {
    return all.filter((col) => isColumnSelected(col, hiddenColumns));
};

export interface ColumnConfig {
    headerName: string;
    /** The field of the row to get the cells data from */
    field?: string;
    /** Group ID */
    groupId?: string;
}

interface ParentIndex {
    name: string;
    index: number;
}
