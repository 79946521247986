import { types } from 'mobx-state-tree';
import { OrderStatusList, OrderStatus, initialState as emptyList } from './order-status-list';
import { initialState as emptyOrderStatus } from './order-status';
import { Transport } from 'modules/common/models/transport';
import { Notificator } from 'modules/common/models/notificator';

export const OrderStatusStore = types.compose(
    Transport, Notificator, types.model({
        list: OrderStatusList,
        details: OrderStatus
    }))
    .named('OrderStatusStore');

export const initialState = (): typeof OrderStatusStore.SnapshotType => ({
    list: emptyList(),
    details: emptyOrderStatus()
});

export type OrderStatusStoreType = typeof OrderStatusStore.Type;
