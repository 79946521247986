import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { MimeTypes } from "modules/common/services/files";
import { formatDate } from "modules/common/services/formatting/date";
import { SentExpertiseFile } from "./order-file";
import moment from "moment";
import { DATE_FORMAT } from "modules/common/constants";

export const EXPERTISE_ACCEPT = MimeTypes.pdf;

export const OrderSentExpertise = types
    .compose(
        Transport,
        Notificator,
        types.model({
            guid: types.string,
            created: types.string,
            date: types.string,
            stageGuid: types.string,
            file: types.maybeNull(SentExpertiseFile),
            number: types.string,
        })
    )
    .views((self) => ({
        get dateAsDate() {
            return self.date ? moment(self.date, DATE_FORMAT).toDate() : null;
        },
    }))
    .actions((self) => ({
        setDate(value: Date | null) {
            self.date = formatDate(value);
        },

        setNumber(value: string) {
            self.number = value;
        },

        uploadFile: flow(function* (file: File) {
            try {
                const model = new FormData();

                model.append("file", file);
                model.append("accept", EXPERTISE_ACCEPT);

                const result: UploadFileResult = yield self.transport.post<any>(
                    apiUrls.application.files.upload,
                    model
                );
                const { id, previewMimeType, mimeType } = result;

                self.file = SentExpertiseFile.create({
                    fileId: id,
                    fileName: file.name,
                    previewMimeType,
                    mimeType,
                });

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        clearFile() {
            self.file = null;
        },
    }))
    .named("OrderSentExpertise");

export type OrderSentExpertiseType = Instance<typeof OrderSentExpertise>;
export type OrderSentExpertiseSnapshotType = SnapshotOut<typeof OrderSentExpertise>;

export const emptyOrderSentExpertise = (guid: string): OrderSentExpertiseSnapshotType => ({
    created: formatDate(new Date()),
    date: "",
    guid,
    stageGuid: "",
    file: null,
    number: "",
});
