import { RootCache } from "modules/common/services/cache";

const prefix = (tableName: string) => `columns_${tableName}`;

const valueSerializer = (columns: string[]) => {
    return JSON.stringify(columns);
};

const valueDeserializer = (json: string): string[] => {
    return json ? JSON.parse(json) : [];
};

export const saveColumns = (tableName: string) => {
    const key = prefix(tableName);

    return (columns: string[]) => {
        RootCache.set(key, valueSerializer(columns));
    };
};

export const getSavedColumns = (tableName: string) => {
    const key = prefix(tableName);

    return (defaultColumns: string[]) => {
        const value = RootCache.get(key, valueSerializer(defaultColumns));
        return valueDeserializer(value);
    };
};
