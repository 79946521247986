import { Button, Classes, Dialog, InputGroup, MenuItem, Popover, TextArea } from "@blueprintjs/core";
import { ItemRenderer } from "@blueprintjs/select";
import classnames from "classnames";
import { groupBy } from "lodash";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { Buttons } from "modules/common/components/form";
import {
  filterProjectItemPredicate,
  optionLabel,
  ProjectSelect,
  renderProjectOption,
} from "modules/common/components/form/ProjectSelect";
import { ReactComponent as HomeIcon } from "modules/common/components/icons/home.icon.svg";
import { ReactComponent as IllnessIcon } from "modules/common/components/icons/illness.icon.svg";
import { ReactComponent as VacationIcon } from "modules/common/components/icons/vacation.icon.svg";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import {
  DefaultSelectedOption,
  renderSingleOption,
  SelectFactory,
  SimpleSelect,
} from "modules/common/services/form/select";
import { MODAL_AWAIT_DELAY } from "modules/common/services/form/textarea";
import { toString } from "modules/common/services/strings";
import { texts } from "modules/common/texts";
import { OrderDictionaryItemSnapshotType } from "modules/orders-manage/models/order-dictionary";
import { Constants } from "modules/root/models/constants";
import { getRowIcon, RenderUnit, TaskSelect, UnitLabel } from "modules/spending/workload/components/WorkloadInput";
import {
  DayWorkRowSnapshotType,
  DayWorkRowType,
  MAX_RECORD_COUNT,
  WorkloadDayStoreType,
  WorkloadDayType,
} from "modules/spending/workload/models/workload-day";
import { WorkloadUnitTypeType } from "modules/spending/workload/models/workload-unit";
import React from "react";
import styles from "./TimesheetInput.module.scss";
import { ReactComponent as WithoutPayIcon } from "./without-work.svg";

const MissingSelect = SelectFactory<WorkloadUnitTypeType>();

type Event = React.ChangeEvent<HTMLInputElement>;

interface TimesheetInputState {
  comment: string;
  isMobile: boolean;
}

const minutesValues = [
  {
    id: "0",
    label: "0",
  },
  {
    id: "5",
    label: "5",
  },
  {
    id: "10",
    label: "10",
  },
  {
    id: "15",
    label: "15",
  },
  {
    id: "20",
    label: "20",
  },
  {
    id: "25",
    label: "25",
  },
  {
    id: "30",
    label: "30",
  },
  {
    id: "35",
    label: "35",
  },
  {
    id: "40",
    label: "40",
  },
  {
    id: "45",
    label: "45",
  },
  {
    id: "50",
    label: "50",
  },
  {
    id: "55",
    label: "55",
  },
];

class Input extends React.Component<TimesheetInputProps, TimesheetInputState> {
  constructor(props: TimesheetInputProps) {
    super(props);
    this.state = {
      comment: "",
      isMobile: false,
    };
    this.initialValues = getSnapshot(props.store.units);
    this.otherTypes = Constants.otherHoursTypes.map((type) => type.name);
  }

  handleResize = () => {
    if (window.innerWidth < 885) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentWillMount() {
    this.handleResize();
  }
  componentDidMount() {
    document.title = "Главная";
    window.addEventListener("resize", this.handleResize);
  }

  otherTypes: string[];
  initialValues: DayWorkRowSnapshotType[];

  dialogBody = React.createRef<HTMLDivElement>();
  confirmation = React.createRef<HTMLDivElement>();

  nonTaskedUnits = (unitList: RenderUnit[]) => {
    const { readOnly, showMinutes } = this.props;

    return unitList.map(({ unit, index, label }) => {
      const selectItem = { id: String(unit.minutes), label: String(unit.minutes) };
      unit.setTask(unit.guid);
      return (
        <div className={styles.projectRows} key={label.id}>
          <div className={styles.projectRow} data-unit-index={index}>
            <div className={styles.projectName}>{label.node}</div>
            <div className={styles.iconsRow}>
              <InputGroup
                className={`${styles.hoursInput} planr-default-input hoursInputer`}
                type="number"
                value={hoursToString(unit.hours)}
                onChange={(e: Event) => {
                  this.setHours(unit, e.target.value);
                }}
                disabled={unit.isDisabled}
                readOnly={readOnly}
                maxLength={2}
              />
              ч.
              {showMinutes && (
                <>
                  <SimpleSelect
                    className={`${styles.minutesInput} full-width-select `}
                    activeItem={selectItem}
                    items={minutesValues}
                    itemRenderer={renderSingleOption}
                    onItemSelect={(i: SelectItem) => {
                      this.setMinutes(unit, i.label);
                    }}
                    popoverProps={{
                      boundary: "viewport",
                      popoverClassName: "import-spendings-table__select",
                    }}
                    inputProps={{
                      placeholder: "мин",
                    }}
                    filterable={false}
                  >
                    <DefaultSelectedOption option={selectItem} />
                  </SimpleSelect>
                  мин.
                </>
              )}
              {!readOnly && (
                <>
                  <GeneralIcon
                    type={"general-trash"}
                    onClick={this.removeUnint}
                    className="red-icon action-icon"
                    title={texts.remove}
                    data-index={index}
                    style={{ color: "#E31818", margin: "0px 20px" }}
                  />
                  <HomeIcon
                    className={classnames({
                      [styles.home]: true,
                      [styles.selectedIcon]: unit.fromHome,
                      [styles.invisible]: unit.type.name === Constants.idleHoursType.name,
                    })}
                    onClick={unit.toggleFromHome}
                    title={Constants.workedOutFromHomeHoursType.name}
                  />
                </>
              )}
            </div>
          </div>
          <div className={styles.projectRow}>
            <InputGroup
              className={`${styles.commentInput} ${Classes.FILL} planr-default-input`}
              type="string"
              value={unit.comment}
              placeholder="Примечания"
              onChange={(e: Event) => {
                this.setComment(unit, e.target.value);
              }}
              readOnly={readOnly}
            />
          </div>
        </div>
      );
    });
  };

  taskedUnits = (unitList: RenderUnit[]) => {
    const { store, readOnly, showMinutes } = this.props;
    const groupLabel = unitList[0].label;

    const options = store.projectTasks(groupLabel.project ?? null);
    return (
      <div className={styles.projectRows} key={groupLabel.id}>
        <div className={styles.projectRow}>
          <div className={styles.projectName}>{groupLabel.node}</div>
        </div>

        {unitList.map(({ unit, index }) => {
          const selectItem = { id: String(unit.minutes), label: String(unit.minutes) };
          unit.setTask(unit.guid);
          return (
            <div key={index}>
              <div className={styles.projectRow} data-unit-index={index}>
                <div className={styles.projectSelectRow}>
                  <div
                    style={{
                      margin: "10px 0px",
                      position: "relative",
                      width: "100%",
                      maxWidth: showMinutes ? "592px" : "712px",
                    }}
                  >
                    <TaskSelect
                      store={store}
                      currentOptions={options}
                      value={unit.guid}
                      onChange={(item) => {
                        unit.setTask(item.id);
                      }}
                      showMinutes={showMinutes}
                      isMobile={this.state.isMobile}
                    />

                    {Object.entries(options).length === 0 && (
                      <span className={styles.noWorkInfo}>Нет незавершенных задач для СС</span>
                    )}
                  </div>
                </div>
                <div className={showMinutes ? styles.iconsRowCol : styles.iconsRow}>
                  <div className={styles.hoursRow}>
                    <InputGroup
                      className={`${styles.hoursInput} planr-default-input hoursInputer`}
                      type="number"
                      style={{ marginTop: "-1px" }}
                      value={hoursToString(unit.hours)}
                      onChange={(e: Event) => {
                        this.setHours(unit, e.target.value);
                      }}
                      disabled={unit.isDisabled}
                      readOnly={readOnly}
                      maxLength={2}
                    />
                    ч.
                    {showMinutes && (
                      <>
                        <SimpleSelect
                          className={`${styles.minutesInput} full-width-select `}
                          activeItem={selectItem}
                          items={minutesValues}
                          itemRenderer={renderSingleOption}
                          onItemSelect={(i: SelectItem) => {
                            this.setMinutes(unit, i.label);
                          }}
                          popoverProps={{
                            boundary: "viewport",
                            popoverClassName: "import-spendings-table__select",
                          }}
                          inputProps={{
                            placeholder: "мин",
                          }}
                          filterable={false}
                        >
                          <DefaultSelectedOption option={selectItem} />
                        </SimpleSelect>
                        мин.
                      </>
                    )}
                  </div>
                  <div className={styles.btnsRow}>
                    {!readOnly && (
                      <>
                        <GeneralIcon
                          type={"general-trash"}
                          onClick={this.removeUnint}
                          className="red-icon action-icon"
                          title={texts.remove}
                          data-index={index}
                          style={{ color: "#E31818", margin: "0px 20px" }}
                        />
                        <PlanrButton
                          type={unit.forExpertise ? "greenish" : "neutral"}
                          icon="general-doc-check"
                          onClick={unit.toggleForExpertise}
                          title={Constants.workedOutForExpertiseHoursType.name}
                          round
                        />
                        <PlanrButton
                          type={unit.forTrip ? "primary" : "neutral"}
                          icon="general-case"
                          onClick={unit.toggleForTrip}
                          title={Constants.workedOutForTripHoursType.name}
                          round
                        />
                        <PlanrButton
                          type={unit.fromHome ? "blueish" : "neutral"}
                          icon="general-folder"
                          onClick={unit.toggleFromHome}
                          title={Constants.workedOutFromHomeHoursType.name}
                          round
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.projectRow}>
                <InputGroup
                  className={`${styles.commentInput} ${Classes.FILL} planr-default-input`}
                  type="string"
                  value={unit.comment}
                  placeholder="Примечания"
                  onChange={(e: Event) => {
                    this.setComment(unit, e.target.value);
                  }}
                  readOnly={readOnly}
                />
              </div>
            </div>
          );
        })}

        <PlanrButton
          type="dashed"
          icon="general-plus-big"
          size="small"
          style={{ width: "100%", marginTop: "10px" }}
          data-project={groupLabel.project}
          onClick={this.addUnit}
        >
          Добавить задачу для {groupLabel.node}
        </PlanrButton>
      </div>
    );
  };

  render() {
    const { isOpened, store, onClose, readOnly, canComment, sessionId, selectedCells } = this.props;
    const { nonProduction, missingType, day, user, units, usedProjects, dirty, isDayOff } = store;
    const plan = day.plan;
    const projectOptions = store.orders.filter((o) => !usedProjects.includes(o.inventoryNumber));
    const isMe = sessionId === day.user.id;
    const letComment = canComment || isMe;

    const renderList: RenderUnit[] = units.map((unit, index) => ({
      label: this.getRowLabel(unit),
      unit,
      index,
    }));
    const unitsGroup = groupBy(renderList, (u) => u.label.id);
    const otherHoursTypes = Constants.otherHoursTypes.filter((type) => type.availableForDay(isDayOff));
    const selectCells = () => {
      const days = selectedCells && selectedCells.length > 1 && selectedCells.map((cell) => cell.slice(0, 10));
      let day = "";
      if (days) {
        day = days[0] + " - " + days[days.length - 1];
        return day;
      }
      return null;
    };
    const days = selectCells() ? selectCells() : day.day;
    return (
      <>
        <Dialog
          title={`${days} (${user.label})`}
          isCloseButtonShown={true}
          isOpen={isOpened}
          onClose={onClose}
          onOpened={this.focusFirstInput}
          canEscapeKeyClose={true}
          canOutsideClickClose={false}
          backdropClassName="standard"
          className={`${plan.length ? styles.dialogWidth : styles.dialog} figma-dialog `}
        >
          {isOpened && (
            <form onSubmit={this.onSubmit}>
              <div className={`${Classes.DIALOG_BODY} `} ref={this.dialogBody} style={{ display: "flex" }}>
                <div className={styles.dialogLeft}>
                  {letComment && (
                    <div className={styles.commentDay}>
                      <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                        <TextArea
                          growVertically={true}
                          rows={1}
                          autoComplete="off"
                          placeholder="Комментарий"
                          data-lpignore="true"
                          value={day.comment}
                          className={`${Classes.FILL} planr-default-input`}
                          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                            this.setCommentDay(day, e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {!readOnly && (
                    <div className={styles.dayType}>
                      {Constants.withoutPayHoursType.availableForDay(isDayOff) && (
                        <WithoutPayIcon
                          className={`figma-icon ${
                            missingType === Constants.withoutPayHoursType.name ? styles.activeIcon : ""
                          }`}
                          title={Constants.withoutPayHoursType.name}
                          onClick={this.selectedByIcon}
                          data-type={Constants.withoutPayHoursType.name}
                        />
                      )}

                      {Constants.illnesHoursType.availableForDay(isDayOff) && (
                        <IllnessIcon
                          className={`figma-icon ${
                            missingType === Constants.illnesHoursType.name ? styles.activeIcon : ""
                          }`}
                          title={Constants.illnesHoursType.name}
                          onClick={this.selectedByIcon}
                          data-type={Constants.illnesHoursType.name}
                        />
                      )}

                      {Constants.vacationHoursType.availableForDay(isDayOff) && (
                        <VacationIcon
                          className={`figma-icon ${
                            missingType === Constants.vacationHoursType.name ? styles.activeIcon : ""
                          }`}
                          title={Constants.vacationHoursType.name}
                          onClick={this.selectedByIcon}
                          data-type={Constants.vacationHoursType.name}
                        />
                      )}

                      <MissingSelect
                        className={"full-width-select"}
                        filterable={false}
                        activeItem={null}
                        items={otherHoursTypes}
                        itemRenderer={renderTimesheetTypeOption}
                        onItemSelect={this.missingTypeSelected}
                      >
                        <Button
                          className="selected-option"
                          active={this.otherTypes.includes(missingType)}
                          fill={true}
                          rightIcon={<GeneralIcon type="general-chevron-down" />}
                          text="Другое"
                        />
                      </MissingSelect>
                    </div>
                  )}
                  {nonProduction && !readOnly && (
                    <div className={styles.projectSelection}>
                      <PlanrButton
                        type={"graybtn"}
                        className={styles.justWork}
                        onClick={this.justWorkSelected}
                        disabled={store.workButtonActive}
                        size="small"
                      >
                        Работа
                      </PlanrButton>
                    </div>
                  )}
                  {!nonProduction && !readOnly && (
                    <div className={styles.projectSelection}>
                      <ProjectSelect
                        className={`full-width-select ${styles.projectSelect}`}
                        filterable={true}
                        itemPredicate={filterProjectItemPredicate}
                        activeItem={null}
                        items={projectOptions}
                        itemRenderer={renderProjectOption}
                        onItemSelect={this.projectSelected}
                        disabled={units.length >= MAX_RECORD_COUNT}
                        resetOnClose={true}
                        inputProps={projectSelectInputProps}
                      >
                        <Button
                          className="selected-option"
                          active={store.workButtonActive}
                          fill={true}
                          rightIcon={<GeneralIcon type="general-chevron-down" />}
                          text="Проект"
                          disabled={units.length >= MAX_RECORD_COUNT}
                        />
                      </ProjectSelect>
                    </div>
                  )}

                  <div className={styles.divider}></div>

                  <div className={styles.dayResult}>
                    {Object.values(unitsGroup).map((renderUnits) => {
                      const label = renderUnits[0].label;
                      return label.project ? this.taskedUnits(renderUnits) : this.nonTaskedUnits(renderUnits);
                    })}
                  </div>
                </div>
                {plan.length !== 0 && (
                  <div className={styles.dialogRight}>
                    <div className={styles.planTitle}>План работ</div>
                    <div className={styles.rowHead}>
                      <div className={`${styles.inventory} ${styles.bold}`}>Инв</div>
                      <div className={`${styles.order} ${styles.bold}`}>Проект</div>
                      <div className={`${styles.name} ${styles.bold}`}>Вид работ</div>
                      <div className={`${styles.hours} ${styles.bold}`}>Часы</div>
                    </div>
                    {plan.map((unit) => (
                      <div className={styles.row} key={unit.workId}>
                        <div className={`${styles.inventory} `}>{unit.order?.inventoryNumber}</div>
                        <div className={`${styles.order} `}>{unit.order?.name}</div>

                        {!!unit.workName && (
                          <Popover
                            usePortal={true}
                            interactionKind={"hover"}
                            content={
                              <div className={`${styles.name} `} style={{ display: "flex", padding: "10px" }}>
                                {unit.workName} {unit.workDescription}
                              </div>
                            }
                          >
                            <div className={`${styles.name} bp3-tooltip-indicator`}>
                              {unit.workName} {unit.workDescription}
                            </div>
                          </Popover>
                        )}

                        {!unit.workName && <div className={styles.name}></div>}

                        <div className={`${styles.hours} `}>{unit.hours}ч.</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <Buttons
                  left={
                    <div style={{ display: "flex" }}>
                      <PlanrButton type="greenish" size="small" nativeType="submit">
                        {texts.save}
                      </PlanrButton>
                      {dirty && (
                        <PlanrButton
                          type="graybtn"
                          onClick={this.revertChanges}
                          size="small"
                          style={{ marginLeft: "10px" }}
                        >
                          Отмена
                        </PlanrButton>
                      )}
                    </div>
                  }
                />
              </div>
            </form>
          )}
        </Dialog>
      </>
    );
  }

  revertChanges = () => {
    const { store } = this.props;
    store.revertChanges(this.initialValues);
  };

  removeUnint = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    const { store } = this.props;
    const index = parseInt(e.currentTarget.dataset.index || "", 10);
    store.removeUnit(index);
  };

  selectedByIcon = (e: React.MouseEvent<SVGSVGElement>) => {
    const typeName = e.currentTarget.dataset.type;
    const selectedType = Constants.allHoursTypes.find((t) => t.name === typeName);
    selectedType && this.missingTypeSelected(selectedType);
  };

  getRowLabel = (unit: DayWorkRowType): UnitLabel => {
    const { store } = this.props;
    const order = unit.project !== null ? store.ordersMap.get(unit.project) : undefined;

    if (order) {
      return { id: optionLabel(order), node: optionLabel(order), project: order.inventoryNumber };
    }

    if (unit.type.name === Constants.workedOutHoursType.name) {
      return { id: "Работа", node: "Работа" };
    }

    return {
      id: unit.type.name,
      node: getRowIcon(unit),
    };
  };

  justWorkSelected = () => this.projectSelected(null);

  projectSelected = (project: OrderDictionaryItemSnapshotType | null = null) => {
    this.addUnitToProject(project ? project.inventoryNumber : null);
  };

  addUnitToProject = (inventory: number | null) => {
    const { store } = this.props;
    store.addProject(inventory);
    this.focusLastInput();
  };

  addUnit = (e: React.MouseEvent<HTMLButtonElement>) => {
    const inventory = parseInt(e.currentTarget.dataset["project"] ?? "");
    this.addUnitToProject(isNaN(inventory) ? null : inventory);
  };

  missingTypeSelected = (type: WorkloadUnitTypeType) => {
    const { store } = this.props;
    store.setMissingType(type);
    this.focusLastInput();
  };

  focusLastInput = () => {
    const { store } = this.props;
    const index = store.units.length - 1;
    this.focusInput(index);
  };

  focusFirstInput = () => this.focusInput(0);

  focusInput = (index = 0) => {
    index >= 0 &&
      setTimeout(() => {
        if (this.dialogBody.current) {
          const input: HTMLInputElement | null = this.dialogBody.current.querySelector(
            `[data-unit-index='${index}'] .hoursInputer input`
          );

          input && input.focus();
        }
      }, MODAL_AWAIT_DELAY);
  };

  focusConfirmation = () => {
    setTimeout(() => {
      if (this.confirmation.current) {
        const button: HTMLButtonElement | null = this.confirmation.current.querySelector(".bp3-intent-primary");
        button && button.focus();
      }
    }, MODAL_AWAIT_DELAY);
  };

  setHours = (unit: DayWorkRowType, hours: string) => {
    const value = parseInt(hours, 10);

    if (!isNaN(value) && value >= 0) {
      unit.setHours(value);
    } else {
      unit.setHours(0);
    }

    this.props.store.recalculateWithoutPayHours();
  };

  setMinutes = (unit: DayWorkRowType, hours: string) => {
    const value = parseInt(hours, 10);

    if (!isNaN(value) && value >= 0) {
      unit.setMinutes(value);
    } else {
      unit.setMinutes(0);
    }
  };

  setComment = (unit: DayWorkRowType, str: string) => {
    unit.setComment(str);
  };

  setCommentDay = (day: WorkloadDayType, str: string) => {
    day.setComment(str);
  };

  onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await this.save();
  };

  save = async () => {
    const { onSave, store, onClose } = this.props;
    const model = store.getSubmitModel();

    if (model != null) {
      const success = await onSave(model);
      success && onClose();
    }
  };
}

export const TimesheetInput = observer(Input);

export interface TimesheetSaver {
  onSave: (model: any) => Promise<boolean>;
  readOnly?: boolean;
}

interface TimesheetInputProps extends TimesheetSaver {
  store: WorkloadDayStoreType;
  isOpened: boolean;
  onClose: () => void;
  readonly?: boolean;
  day: WorkloadDayType;
  showMinutes: boolean;
  canComment?: boolean;
  sessionId: string;
  selectedCells?: string[];
}

function hoursToString(hours: number) {
  return hours > 0 ? toString(hours) : "";
}

const renderTimesheetTypeOption: ItemRenderer<WorkloadUnitTypeType> = (item, { handleClick, modifiers }) => {
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={item.name}
      onClick={handleClick}
      text={item.name}
    />
  );
};

const projectSelectInputProps = {
  placeholder: "Проект",
};
