import { types, applySnapshot, isAlive, getSnapshot } from "mobx-state-tree";
import { BaseEntity } from "modules/common/models/entity";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TablePager } from "modules/common/models/table-pager";
import { Constants } from "modules/root/models/constants";
import { formatDate } from "modules/common/services/formatting/date";

const LIMIT = 2;

export const Survey = types
    .compose(
        Notificator,
        Transport,
        BaseEntity,
        types.model({
            name: types.string,
            status: types.string,
            author: types.string,
            variants: types.array(types.string),
            unitl: types.maybeNull(types.string),
            votes: types.map(types.string),
            results: types.map(types.number),
            voted: types.boolean,
        })
    )
    .views((self) => ({
        get active() {
            return !Constants.surveyInactiveStatuses.includes(self.status);
        },
    }))
    .actions((self) => ({
        vote: flow(function* (variant: string) {
            try {
                const result = yield self.transport.post<any>(apiUrls.surveys.vote(self.id), {
                    variant,
                });

                isAlive(self) && applySnapshot(self, result);
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        cancel: flow(function* () {
            try {
                const result = yield self.transport.delete<string>(apiUrls.surveys.delete(self.id));
                if (isAlive(self)) {
                    self.status = result;
                }

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .named("Survey");

export const SurveyStore = types
    .compose(
        Notificator,
        Transport,
        TablePager,
        types.model({
            items: types.array(Survey),
        })
    )
    .views((self) => ({
        get isEmpty() {
            return self.items.length === 0;
        },

        get asMap(): TStringMap<SurveyType> {
            return self.items.reduce((result, n) => {
                result[n.id] = n;
                return result;
            }, {} as TStringMap<SurveyType>);
        },

        get queryParams() {
            return {
                page: self.page,
                itemsPerPage: self.pageSize,
            };
        },

        get hasNextPage() {
            const knownCount = (self.page - 1) * self.pageSize + self.items.length;
            return knownCount < self.totalCount;
        },

        get hasPrevPage() {
            return self.page > 1;
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                let surveys: PagedList<SurveySnapshotType> = yield self.transport.get<any>(apiUrls.surveys.list(), {
                    params: self.queryParams,
                });

                self.totalCount = surveys.totalCount;
                applySnapshot(self.items, surveys.items);
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        saveSurvey: flow(function* (values: { name: string; variants: string[]; until: Date | null }) {
            try {
                const model = { ...values, until: values.until ? formatDate(values.until) : null };

                const shapshot = yield self.transport.put<SurveySnapshotType>(apiUrls.surveys.create(), model);

                if (self.items.length === self.pageSize) {
                    applySnapshot(self.items, [shapshot, getSnapshot(self.items[0])]);
                } else {
                    self.items.unshift(shapshot);
                }

                self.totalCount++;
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .actions((self) => ({
        setPage(page: number) {
            self.page = page;
            return self.load();
        },
    }))
    .named("SurveyStore");

export type SurveyType = typeof Survey.Type;
export type SurveySnapshotType = typeof Survey.SnapshotType;
export type SurveyStoreType = typeof SurveyStore.Type;

export const initialState = (): typeof SurveyStore.SnapshotType => ({
    items: [],
    page: 1,
    pageSize: LIMIT,
    totalCount: -1,
});
