import { types, applySnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { flow } from "modules/common/models/flow";
import { isNewlyCreated } from "modules/common/models/entity";
import { apiUrls } from "modules/common/services/communication/urls";
import { texts } from "modules/common/texts";
import { MimeTypes, base64ToBlob, printPdf } from "modules/common/services/files";

export const ACCEPT = MimeTypes.odt;

export const OrderTechTaskVersion = types
    .model({
        name: types.string,
        description: types.string,
    })
    .named("OrderTechTaskVersion");

export const OrderTechTasks = types
    .compose(
        Transport,
        Notificator,
        types.model({
            id: types.string,
            versions: types.array(OrderTechTaskVersion),
            diff: types.string,
        })
    )
    .views((self) => ({
        get previuos() {
            return self.versions.filter((v) => !!v);
        },
    }))
    .actions((self) => ({
        resetDiff() {
            self.diff = "";
        },

        loadVersions: flow(function* () {
            if (isNewlyCreated(self.id)) {
                return;
            }

            try {
                const versions: any[] = yield self.transport.get<any>(apiUrls.orders.summary.tasks.versions(self.id));
                applySnapshot(self.versions, versions);

                return true;
            } catch (er) {
                applySnapshot(self.versions, []);
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .actions((self) => ({
        setFileId(id: string) {
            self.id = id;
        },

        saveVersion: flow(function* (name: string, description: string) {
            if (isNewlyCreated(self.id)) {
                return false;
            }

            try {
                const ok = yield self.transport.post<boolean>(apiUrls.orders.summary.tasks.versions(self.id), {
                    name,
                    description,
                });

                if (ok) {
                    self.loadVersions();
                }

                return ok;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        uploadTaskFile: flow(function* (fileId: string) {
            try {
                yield self.transport.put<any>(apiUrls.orders.summary.tasks.contents(self.id), {
                    fileId,
                });

                self.notify.success(texts.messages.saved);

                return true;
            } catch (er) {
                self.notify.error(er);

                return false;
            }
        }),

        copyFile: flow(function* (id: string, fileId: string, version: string) {
            try {
                yield self.transport.post<any>(apiUrls.orders.summary.tasks.copy(id), {
                    version,
                    fileId,
                });
                self.notify.success(texts.messages.saved);

                return true;
            } catch (er) {
                self.notify.error(er);

                return false;
            }
        }),

        uploadFile: flow(function* (file: File) {
            try {
                const model = new FormData();

                model.append("file", file);
                model.append("accept", ACCEPT);

                const result: UploadFileResult = yield self.transport.post<any>(
                    apiUrls.application.files.upload,
                    model
                );
                const { id, previewMimeType, mimeType } = result;

                const fileBase: FileBase = { fileId: id, fileName: file.name, previewMimeType, mimeType };
                return fileBase;
            } catch (er) {
                self.notify.error(er);
                return null;
            }
        }),

        compare: flow(function* (v1: string, v2: string) {
            try {
                if (isNewlyCreated(self.id)) {
                    self.resetDiff();
                    return false;
                }

                const diff: string = yield self.transport.get<any>(apiUrls.orders.summary.tasks.diff(self.id), {
                    params: {
                        v1,
                        v2,
                    },
                });

                self.diff = diff;

                return true;
            } catch (er) {
                self.resetDiff();
                self.notify.error(er);
                return false;
            }
        }),

        print: flow(function* () {
            try {
                if (isNewlyCreated(self.id)) {
                    return false;
                }

                const file: FileDescription = yield self.transport.post<any>(
                    apiUrls.orders.print.technicalTask(self.id)
                );

                const blob: any = yield base64ToBlob(file.content || "", file.mimeType);

                const fileURL = URL.createObjectURL(blob);
                const printer = printPdf(fileURL, true);
                if (printer) {
                    printer.onclose = () => URL.revokeObjectURL(fileURL);
                }

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .named("OrderTechTasks");

export type OrderTechTasksSnapshotType = typeof OrderTechTasks.SnapshotType;
export type OrderTechTasksType = typeof OrderTechTasks.Type;
export type OrderTechTaskVersionType = typeof OrderTechTaskVersion.Type;

export const initialState = (): OrderTechTasksSnapshotType => ({
    diff: "",
    id: "",
    versions: [],
});
