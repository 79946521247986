import * as yup from "yup";
import { texts } from "modules/common/texts";
import { fields } from "../../models/calendar-event-store";

const { object, string, array, date, number } = yup;

export const MAX_VARIANTS = 5;

export const eventLabels = () => ({
    [fields.name]: "Наименование",
    [fields.users]: "Другие участники",
    [fields.start]: "Начало",
    [fields.stop]: "Конец",
    [fields.color]: "Цвет",
    [fields.notificationMinutes]: "Уведомить за",
});

const EventSchema = () => {
    const labels = eventLabels();

    return object().shape({
        [fields.name]: string().label(labels[fields.name]).required(),

        [fields.notificationMinutes]: number().label(labels[fields.notificationMinutes]).nullable().notRequired(),

        [fields.color]: string().label(labels[fields.color]).required(),

        [fields.users]: array().of(string().label("Сотрудник")).label(labels[fields.users]),

        [fields.start]: date().label(labels[fields.start]).typeError(texts.messages.incorrectDate()).required(),

        [fields.stop]: date().label(labels[fields.stop]).typeError(texts.messages.incorrectDate()).required(),
    });
};

export default EventSchema;
