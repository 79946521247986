import * as yup from "yup";
import { fields } from "../../models/employee-position";
import { texts } from "modules/common/texts";
const { object, string, boolean, number } = yup;

export const employeePositionLabels = () => ({
    [fields.name]: texts.name,
    [fields.comment]: texts.comment,
    [fields.nonProduction]: "Непроизводств. затраты",
    [fields.order]: "№",
    [fields.departmentId]: "Отдел",
    [fields.manualFile]: "Долж. инструкция",
});

const EmployeePositionSchema = () => {
    const labels = employeePositionLabels();

    return object().shape({
        [fields.name]: string().label(labels[fields.name]).required(),

        [fields.comment]: string().label(labels[fields.comment]).notRequired(),

        [fields.nonProduction]: boolean().label(labels[fields.nonProduction]),

        [fields.order]: number().label(labels[fields.order]).notRequired().min(1),
        [fields.departmentId]: string().label(labels[fields.departmentId]).required(),
    });
};

export default EmployeePositionSchema;
