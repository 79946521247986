export const MimeTypes = {
    jpeg: "image/jpeg",
    jpg: "image/jpg",
    png: "image/png",
    pdf: "application/pdf",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    odt: "application/vnd.oasis.opendocument.text",
};

export const MimeTypesEntries = Object.keys(MimeTypes).reduce((acc, key) => {
    const from = MimeTypes as any;
    return { ...acc, [from[key]]: key };
}, {} as TStringMap<string>);

export const getFileExtension = (fileName: string) => fileName.split(".").pop();

export const isPdf = (mime: string) => mime.includes(MimeTypes.pdf);

export const isImage = (mime: string) =>
    mime.includes(MimeTypes.jpeg) || mime.includes(MimeTypes.jpg) || mime.includes(MimeTypes.png);

/**
 * Convert base64 string into Blob
 * @param b64Data base64 string
 * @param contentType Content type of string data
 */
export function base64ToBlob(b64Data: string, contentType: string): Promise<Blob> {
    let url = b64Data;
    if (!b64Data.startsWith("data:")) {
        url = "data:" + contentType + ";base64," + b64Data;
    }

    return fetch(url).then((res) => res.blob());
}

/**
 * Build image preview
 * @param file File from Form
 * @param size Max expected size
 */
export function buildPreview(file: File, size = 100): Promise<string> {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        if (file.type !== "image/jpg" && file.type !== "image/jpeg" && file.type !== "image/png") {
            reject();
        }

        reader.onload = (event: ProgressEvent<FileReader>) => {
            if (event.target && typeof event.target.result === "string") {
                const img = new Image();
                img.src = event.target.result;

                img.onload = () => {
                    const elem = document.createElement("canvas");

                    const ratioX = size / img.width;
                    const ratioY = size / img.height;
                    const ratio = Math.min(ratioX, ratioY);

                    const newWidth = Math.floor(img.width * ratio);
                    const newHeight = Math.floor(img.height * ratio);

                    elem.width = newWidth;
                    elem.height = newHeight;

                    const ctx = elem.getContext("2d");
                    ctx && ctx.drawImage(img, 0, 0, newWidth, newHeight);

                    const data = ctx ? ctx.canvas.toDataURL() : "";

                    resolve(data);
                };

                img.onerror = reject;
            } else {
                reject();
            }
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
    });
}

/**
 * Fix base64 string with mime type
 * @param image base64 string
 * @param mimeType file mime type
 */
export const treatBase64 = (image: string | null | undefined, mimeType: string) =>
    image && !image.startsWith("data") ? `data:${normalizeMimeType(mimeType)};base64,` + image : image || "";

/**
 * Generate image (dataUrl) with printed rows
 * @param text Rows to be printed
 */
export const convertTextToImage = (text: string[]) => {
    const font = 20;
    const step = font * 2;
    const start = font / 2;

    const canvas = document.createElement("canvas");
    canvas.width = 1000;
    canvas.height = text.length * step + start;

    const ctx = canvas.getContext("2d");
    ctx && (ctx.font = `${20}px "DejaVu Sans Mono"`);

    text.forEach((row, index) => {
        ctx && ctx.fillText(row, 10, step * (index + 1) - start);
    });

    return canvas.toDataURL();
};

/**
 * Open image int new tab and call print dialog
 * @param dataUrl Image as dataUrl
 */
export const printImage = (dataUrl: string, fullscreen = false) => {
    const win = window.open("");
    const html = `
        <style>
            img {
                display: none;
            }

            @media print {
                @page { margin: 0; } /* remove headers automatically */
                body { margin: 1cm; }
                img {
                    display: block;
                    ${fullscreen ? "max-width: 100%;" : ""}
                    ${fullscreen ? "max-height: 100%;" : ""}
                }
            }
        </style>
        <img src="${dataUrl}" onload="window.print(); window.close()" />
    `;

    win && win.document.write(html);
    win && win.focus();
};

/**
 * Open pdf in the new tab and call print dialog
 * @param dataUrl Image as dataUrl
 */
export const printPdf = (dataUrl: string, fullscreen = false) => {
    const win = window.open("");
    const html = `
        <style>
            iframe {
                visibility: hidden;
                ${fullscreen ? "idth: 100%;" : ""}
                ${fullscreen ? "height: 100%;" : ""}
            }

            @media print {
                @page { margin: 0; } /* remove headers automatically */
                body { margin: 1cm; }
                iframe {
                    visibility: visible;
                    border: 0;
                    ${fullscreen ? "max-width: 100%;" : ""}
                    ${fullscreen ? "max-height: 100%;" : ""}
                }
            }
        </style>
        <iframe id="pdf" name="pdf" src="${dataUrl}" frameborder="0"></iframe>
        <script>
            var e = document.getElementById('pdf');
            e.addEventListener('load', function() {
                window.frames['pdf'].focus()
                window.frames['pdf'].print()
            });
        </script>
    `;

    win && win.document.write(html);
    setTimeout(() => {
        win && win.focus();
    });

    return win;
};

function normalizeMimeType(mime: string) {
    return mime.includes("pdf") ? "application/pdf" : mime;
}
