import React from "react";
import {
    am4core,
    am4charts,
    SCROLL_DELAY,
    gradient,
    createScrollSeries,
    customizeScrollbar,
    am4lang_ru_RU,
    customizeBulletTooltip,
} from "../am";
import { IDisposer } from "mobx-state-tree";
import { ComposedChartDatasetType } from "modules/main/models/composed-chart";
import { reaction } from "mobx";
import styles from "../Dashboard.module.scss";
import { STANDARD_GREY } from "modules/common/constants";
import { CircleBullet, ValueAxis } from "@amcharts/amcharts4/charts";
import { max } from "lodash";

const fillGradient = gradient();

export class ComposedChart extends React.PureComponent<ComposedChartProps> {
    protected container = React.createRef<HTMLDivElement>();
    protected chart: any;
    protected loadClear: IDisposer | null = null;
    protected periodClear: IDisposer | null = null;

    componentDidMount() {
        const { store } = this.props;
        this.setupChart(store.amChartData);
        store.load();

        this.loadClear = reaction(
            () => store.loaded,
            (loaded) => {
                if (loaded) {
                    this.setupChart(store.amChartData);
                    store.setLoaded(false);
                }
            }
        );

        this.periodClear = reaction(
            () => store.period,
            () => {
                this.setupChart(store.amChartData);
            }
        );
    }

    componentWillUnmount() {
        this.loadClear && this.loadClear();
        this.periodClear && this.periodClear();
        this.chart && this.chart.dispose();
    }

    render() {
        const { width, height } = this.props;

        return (
            <div
                className={styles.widget}
                style={{ width: `${width}px`, minWidth: `${width}px`, height: `${height}px` }}
            >
                <div ref={this.container} style={{ height: "100%" }}></div>
            </div>
        );
    }

    setupChart = (data: TStringMap<any>[]) => {
        if (!this.container.current) {
            return;
        }

        const { formatter, maxData, colors, graduator, navigator, bulleter } = this.props;

        if (!this.chart && data.length) {
            const chart = am4core.create(this.container.current, am4charts.XYChart);
            chart.language.locale = am4lang_ru_RU;

            chart.numberFormatter.numberFormat = formatter || "##.";

            const topContainer = chart.chartContainer.createChild(am4core.Container);
            topContainer.layout = "absolute";
            topContainer.toBack();
            topContainer.paddingBottom = 15;
            topContainer.width = am4core.percent(100);

            const title = topContainer.createChild(am4core.Label);
            title.text = this.props.title;
            title.fontWeight = "600";
            title.align = "left";

            chart.zoomOutButton.disabled = true;

            const periodAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            periodAxis.dataFields.category = "period";
            periodAxis.title.text = "";
            periodAxis.renderer.minGridDistance = 20;
            periodAxis.renderer.labels.template.fill = am4core.color(STANDARD_GREY);
            periodAxis.renderer.labels.template.horizontalCenter = "middle";
            periodAxis.renderer.labels.template.verticalCenter = "middle";
            periodAxis.renderer.labels.template.rotation = 270;
            periodAxis.renderer.labels.template.fontSize = "11px";
            periodAxis.renderer.labels.template.wrap = true;
            periodAxis.renderer.labels.template.maxWidth = 100;
            periodAxis.renderer.labels.template.textAlign = "end";
            periodAxis.renderer.grid.template.location = 0;
            periodAxis.renderer.grid.template.stroke = am4core.color("#FFF", 0);
            periodAxis.renderer.line.stroke = am4core.color("#000");
            periodAxis.renderer.line.strokeOpacity = 1;
            periodAxis.renderer.line.strokeWidth = 1;
            periodAxis.cursorTooltipEnabled = false;

            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.min = 0;
            valueAxis.title.text = "";
            valueAxis.renderer.labels.template.fill = am4core.color(STANDARD_GREY);
            valueAxis.renderer.labels.template.fontSize = "11px";
            valueAxis.cursorTooltipEnabled = false;
            valueAxis.renderer.line.stroke = am4core.color("#000");
            valueAxis.renderer.line.strokeOpacity = 1;
            valueAxis.renderer.line.strokeWidth = 1;

            const createLineSeries = (field: string, name: string, color: string) => {
                const paretoSeries = chart.series.push(new am4charts.LineSeries());
                paretoSeries.dataFields.valueY = field;
                paretoSeries.dataFields.categoryX = "period";
                paretoSeries.tensionX = 0.77;
                paretoSeries.name = name;
                paretoSeries.strokeWidth = 2;
                paretoSeries.fill = am4core.color(color);
                paretoSeries.stroke = am4core.color(color);
                paretoSeries.fillOpacity = 1;
                paretoSeries.segments.template.fillModifier = fillGradient;

                const circleBullet = paretoSeries.bullets.push(new am4charts.CircleBullet());

                circleBullet.circle.fill = am4core.color(color);
                circleBullet.circle.strokeWidth = navigator ? 20 : 1;
                circleBullet.circle.radius = 1;
                circleBullet.circle.stroke = navigator ? am4core.color("#FFF", 0) : am4core.color(color);
                if (navigator) {
                    circleBullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                }

                if (bulleter) {
                    bulleter(circleBullet);
                }

                if (navigator) {
                    circleBullet.events.on("hit", (e) => {
                        navigator(name, e.target.dataItem?.categories["categoryX"] || "", null);
                    });
                }

                customizeBulletTooltip(circleBullet, color);
            };

            this.props.store.charts.forEach((chart, i) => {
                createLineSeries(`data${i}`, chart.title, colors[i]);
            });

            chart.legend = new am4charts.Legend();
            chart.legend.useDefaultMarker = true;
            chart.legend.labels.template.fontSize = "12px";
            chart.legend.position = "bottom";
            chart.legend.contentAlign = "left";
            chart.legend.itemContainers.template.marginLeft = 1;

            const markerTemplate = chart.legend.markers.template;
            markerTemplate.width = 12;
            markerTemplate.height = 12;

            const marker: any = markerTemplate.children.getIndex(0);
            if (marker) {
                marker.cornerRadius(6, 6, 6, 6);
            }

            const scrollbar = new am4charts.XYChartScrollbar();
            chart.scrollbarX = scrollbar;
            scrollbar.series.push(createScrollSeries(chart, `data${1}`, "period", "Навигатор", "#5E7784"));
            scrollbar.parent = chart.chartAndLegendContainer;
            customizeScrollbar(scrollbar);

            chart.events.on("datavalidated", () => {
                if (!graduator) {
                    valueAxis.min = valueAxis.minZoomed;
                    valueAxis.max = valueAxis.maxZoomed;
                }

                const values = chart.data;
                const max = maxData;

                if (max && values.length > max) {
                    const start = values[values.length - max].period;
                    const end = values[values.length - 1].period;

                    setTimeout(() => {
                        periodAxis.zoomToCategories(start, end);
                    }, SCROLL_DELAY);
                }
            });

            this.chart = chart;
        }

        if (this.chart) {
            if (graduator) {
                this.chart.yAxes.values.forEach((valueAxis: ValueAxis) => {
                    valueAxis.renderer.grid.template.disabled = true;
                    valueAxis.renderer.labels.template.disabled = true;
                    graduator(valueAxis, max(data.map((row) => row.max)) || 0);
                });
            }

            this.chart.data = data;
        }
    };
}

interface ComposedChartProps {
    maxData?: number;
    formatter?: string;
    width: number;
    height: number;
    title: string;
    store: ComposedChartDatasetType;
    colors: string[];
    graduator?: (axis: ValueAxis, maxValue: number) => void;
    navigator?: (title: string, period: string, value: any) => void;
    bulleter?: (bullet: CircleBullet) => void;
}
