import { types, getEnv } from "mobx-state-tree";
import { FileMetadata, FileMetadataSnapshotType } from "modules/common/models/file-metadata";
import { isImage } from "modules/common/services/files";

export const RenderCache = types
    .model({
        id: types.string,
        cache: types.map(FileMetadata),
    })
    .actions((self) => ({
        preview(doc: FileMetadataSnapshotType | null) {
            if (!doc) {
                self.id = "";
                return;
            }

            if (!self.cache.has(doc.id)) {
                const cached = FileMetadata.create(doc, getEnv(self));
                self.cache.set(doc.id, cached);

                if (isImage(doc.mimeType)) {
                    cached.download();
                }
            }

            self.id = doc.id;
        },
    }))
    .views((self) => ({
        get viewer() {
            return self.cache.get(self.id) || null;
        },
    }))
    .named("RenderCache");
