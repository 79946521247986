import React, { useCallback, useRef, useMemo } from "react";
import styles from "./PeriodSlider.module.scss";
import Picker from "react-month-picker";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { MakeMonthName, convertToMoment, MonthNames, BuildYears } from "modules/common/models/period";

export const PeriodSlider: React.FC<PeriodSliderProps> = (props) => {
    const { month, year, onChange } = props;

    const pickerRef = useRef<any>();

    const gotoNextMonth = useCallback(() => {
        const next = convertToMoment(month, year).add(1, "month");
        onChange(next.year(), next.month() + 1);
    }, [month, year, onChange]);

    const gotoPreviousMonth = useCallback(() => {
        const prev = convertToMoment(month, year).subtract(1, "month");
        if (prev.isValid()) {
            onChange(prev.year(), prev.month() + 1);
        }
    }, [month, year, onChange]);

    const onMonthChange = useCallback(
        (y: number, m: number) => {
            setTimeout(() => {
                onChange(y, m);
            }, 100);
        },
        [onChange]
    );

    const showPicker = useCallback(() => {
        pickerRef.current && pickerRef.current.show();
    }, []);

    const yearsOptions = useMemo(() => BuildYears(year), [year]);

    const monthName = useMemo(() => MakeMonthName(month), [month]);

    return (
        <div className={`period-slider ${styles.slider}`}>
            <PlanrButton type="neutral" icon="general-big-arrow-left" onClick={gotoPreviousMonth} size="small" />

            <Picker
                ref={pickerRef}
                years={yearsOptions}
                value={{ month, year }}
                lang={MonthNames}
                onChange={onMonthChange}
            >
                <span className={styles.value} onClick={showPicker}>
                    &nbsp;{monthName}&nbsp;
                </span>
            </Picker>

            <PlanrButton type="neutral" icon="general-big-arrow-right" onClick={gotoNextMonth} size="small" />
        </div>
    );
};

interface PeriodSliderProps {
    month: number;
    year: number;
    onChange: (year: number, month: number) => void;
}
