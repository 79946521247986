import React from "react";

import { Dialog, Classes, Callout } from "@blueprintjs/core";
import { texts } from "modules/common/texts";
import { inflate } from "pako";
import { DndUploader } from "modules/common/components/files/DndUploader";
import styles from "./WorkCalendar.module.scss";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { Buttons } from "modules/common/components/form";
import { PlanrButton } from "modules/common/components/planr/button/Button";

const ACCEPT = "application/gzip,application/x-gzip";

export class WorkCalendar extends React.Component<WorkCalendarProps, WorkCalendarState> {
    constructor(props: any) {
        super(props);

        this.state = { file: null, opened: false, loading: false, xml: "" };
    }

    render() {
        const { opened, loading } = this.state;

        return (
            <>
                <PlanrButton
                    type="graybtn"
                    icon="general-calendar"
                    title="Загрузка производственного календаря"
                    onClick={this.open}
                    round={true}
                />
                {/* <CalendarIcon
                    className="figma-icon"
                    onClick={this.open}
                    style={{ width: "20px", height: "20px" }}
                    title="Загрузка производственного календаря"
                /> */}

                <Dialog
                    isCloseButtonShown={true}
                    isOpen={opened}
                    onClose={this.close}
                    title="Загрузка производственного календаря"
                    backdropClassName="standard"
                    canOutsideClickClose={false}
                    canEscapeKeyClose={false}
                    className={`figma-dialog ${styles.workCalendarDialog}`}
                >
                    <LocalLoader active={loading} />

                    <div className={Classes.DIALOG_BODY}>
                        <Callout intent={"warning"}>
                            Загрузите производственный календарь с сайта&nbsp;
                            <a target="_blank" rel="noopener noreferrer" href="http://xmlcalendar.ru/">
                                http://xmlcalendar.ru
                            </a>
                            . Выбирайте формат <strong>xml.gz</strong>
                        </Callout>

                        {}

                        <DndUploader accept={ACCEPT} onFileSelected={this.fileSelected}>
                            {this.state.file && this.state.file.name}
                        </DndUploader>
                    </div>

                    <div className={Classes.DIALOG_FOOTER}>
                        <Buttons
                            left={
                                <div style={{ display: "flex" }}>
                                    <PlanrButton type="greenish" size="small" onClick={this.uplaod}>
                                        {texts.upload}
                                    </PlanrButton>

                                    <PlanrButton
                                        type="graybtn"
                                        onClick={this.close}
                                        size="small"
                                        style={{ marginLeft: "10px" }}
                                    >
                                        {texts.cancel}
                                    </PlanrButton>
                                </div>
                                // <>
                                //     <Button onClick={this.uplaod} intent="success">
                                //         {texts.upload}
                                //     </Button>
                                //     <Button onClick={this.close}>{texts.cancel}</Button>
                                // </>
                            }
                        />
                    </div>
                </Dialog>
            </>
        );
    }

    open = () => this.setState({ file: null, opened: true, xml: "", loading: false });

    close = () => this.setState({ file: null, opened: false, xml: "", loading: false });

    fileSelected = async (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const compressed: ArrayBuffer = event.target?.result as any;

                try {
                    let xml = inflate(new Uint8Array(compressed), { to: "string" }) || "";
                    xml = xml.trim();

                    if (xml.startsWith("<?xml")) {
                        this.setState({ file, xml });
                        return resolve();
                    }

                    reject(new Error("Ожидается .xml файл в архиве .gz"));
                } catch (err) {
                    reject(err);
                }
            };
            reader.onerror = () => reject(reader.error || new Error("Ошибка чтения файла"));
            reader.readAsArrayBuffer(file);
        });
    };

    uplaod = async () => {
        const { xml } = this.state;
        const { onUpload } = this.props;

        if (!xml) {
            return;
        }

        this.setState({ loading: true });

        const success = await onUpload(xml);

        this.setState({ loading: false });

        if (success) {
            this.close();
        }
    };
}

interface WorkCalendarProps {
    onUpload: (xml: string) => Promise<boolean>;
}

interface WorkCalendarState {
    file: File | null;
    xml: string;
    opened: boolean;
    loading: boolean;
}
