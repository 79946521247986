import { types, applySnapshot, isAlive } from "mobx-state-tree";
import { BaseEntity, isNewlyCreated } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID, DATE_TIME_FORMAT } from "modules/common/constants";
import Schema from "../components/details/validation";
import moment from "moment";
import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";
import { getFieldLabel } from "modules/common/services/form/fields";

const OverheadTypeBase = types.compose(
    Transport,
    Notificator,
    BaseEntity,
    types.model({
        name: types.string,
        comment: types.string,
    })
);

export const OverheadType = OverheadTypeBase.actions((self) => ({
    load: flow(function* (id: string) {
        try {
            const snapshot = isNewlyCreated(id)
                ? initialState()
                : yield self.transport.get<OverheadTypeSnapshotType>(apiUrls.overheadTypes.details(id));

            applySnapshot(self, snapshot);
        } catch (er) {
            self.notify.error(er);
        }
    }),

    save: flow(function* (model: OverheadTypeSnapshotType) {
        try {
            const snapshot = self.isNewlyCreated
                ? yield self.transport.put<OverheadTypeSnapshotType>(apiUrls.overheadTypes.create(), model)
                : yield self.transport.post<OverheadTypeSnapshotType>(apiUrls.overheadTypes.update(self.id), model);

            isAlive(self) && applySnapshot(self, snapshot);
            self.notify.success(texts.messages.saved);

            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    delete: flow(function* () {
        if (self.isNewlyCreated) {
            return true;
        }

        try {
            yield self.transport.delete<boolean>(apiUrls.overheadTypes.delete(self.id));
            self.notify.success(texts.messages.removed);

            isAlive(self) && applySnapshot(self, initialState());
            return true;
        } catch (er) {
            self.notify.error(er);
            return false;
        }
    }),
})).named("OverheadType");

export type OverheadTypeSnapshotType = typeof OverheadTypeBase.SnapshotType;
export type OverheadTypeType = typeof OverheadType.Type;

export const initialState = (): OverheadTypeSnapshotType => ({
    id: EMPTY_OBJECT_ID,
    created: moment().format(DATE_TIME_FORMAT),
    name: "",
    comment: "",
});

export const fields = {
    name: nameof((a: OverheadTypeType) => a.name) as string,
    comment: nameof((a: OverheadTypeType) => a.comment) as string,
};

export function formatOverheadType(stage: OverheadTypeSnapshotType | null) {
    let result = "";

    if (stage) {
        const schema = Schema();

        result += `${getFieldLabel(fields.name, schema, null)}: ${stage.name}\n`;
        result += `${getFieldLabel(fields.comment, schema, null)}: ${stage.comment}\n`;
    }

    return result;
}
