import { types, applySnapshot, getSnapshot, getParent } from "mobx-state-tree";
import { BaseEntity } from "modules/common/models/entity";
import moment from "moment";
import { DATE_TIME_FORMAT } from "modules/common/constants";
import { Transport } from "modules/common/models/transport";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { Notificator } from "modules/common/models/notificator";
import { CalendarEventStoreType } from "./calendar-event-store";

export const Message = types
    .compose(
        BaseEntity,
        Transport,
        Notificator,
        types.model({
            fullText: types.string,
            type: types.string,
            created: types.string,
            readDate: types.maybeNull(types.string),
            data: types.map(types.string),
        })
    )
    .views((self) => ({
        get dateAsDate() {
            return moment(self.created, DATE_TIME_FORMAT);
        },
    }))
    .actions((self) => ({
        markAsRead: flow(function* () {
            try {
                yield self.transport.post<any>(apiUrls.messages.unread.open(self.id), {});
                self.readDate = moment().format(DATE_TIME_FORMAT);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .named("Message");

export const MessageStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            items: types.array(Message),
            hasMore: types.boolean,
            unreadCount: types.number,
        })
    )
    .views((self) => ({
        get isEmpty() {
            return self.items.length === 0;
        },
    }))
    .actions((self) => ({
        setData(data: MessageSnapshotType[], append = true) {
            if (data.length) {
                if (append) {
                    applySnapshot(self.items, [...getSnapshot(self.items), ...data]);
                } else {
                    applySnapshot(self.items, [...data, ...getSnapshot(self.items)]);
                }

                const parent = getParent(self);
                if (!append && parent && parent.eventsWidget) {
                    const calendar: CalendarEventStoreType = parent.eventsWidget;

                    const updateCalendar = data.find((e) => {
                        const month = parseInt(e.data["StartMonth"], 10);
                        const year = parseInt(e.data["StartYear"], 10);

                        return (
                            e.type === "CalendarEventMessage" &&
                            !e.readDate &&
                            month === calendar.month &&
                            year === calendar.year
                        );
                    });

                    if (updateCalendar) {
                        calendar.load();
                    }
                }
            }
        },
    }))
    .actions((self) => ({
        loadCount: flow(function* () {
            try {
                const data: any = yield self.transport.get<any>(apiUrls.messages.unread.count);
                self.unreadCount = data;

                return true;
            } catch (er) {
                self.unreadCount = 0;
                return false;
            }
        }),

        loadNext: flow(function* () {
            try {
                const fromId = self.items.length ? self.items[self.items.length - 1].id : "";

                const data: any[] = yield self.transport.get<any>(apiUrls.messages.list, {
                    params: { fromId, limit: LIMIT },
                });

                self.hasMore = data.length === LIMIT;
                self.setData(data);
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .actions((self) => ({
        refresh: flow(function* () {
            self.loadCount();

            if (self.isEmpty) {
                self.loadNext();
                return;
            }

            const fromId = self.items[0].id;
            const data: any[] = yield self.transport.get<any>(apiUrls.messages.list, {
                params: { fromId, limit: -1 },
            });

            self.setData(data, false);
        }),

        markAsRead: flow(function* (message: MessageType) {
            const ok = yield message.markAsRead();
            if (ok) {
                self.unreadCount--;
            }
        }),

        remove: flow(function* (ids: string[]) {
            try {
                const removed = yield self.transport.delete<string[]>(apiUrls.messages.delete, {
                    data: { ids },
                });

                const candidates = self.items.filter((m) => removed.includes(m.id));
                if (candidates.length) {
                    candidates.forEach((c) => {
                        if (!c.readDate) {
                            self.unreadCount--;
                        }

                        self.items.remove(c);
                    });
                }

                return true;
            } catch (er) {
                self.unreadCount = 0;
                return false;
            }
        }),
        setRead: flow(function* (ids: string[]) {
            try {
                yield self.transport.post<string[]>(apiUrls.messages.unread.openList, {
                    ids,
                });
                ids.forEach((id) => {
                    self.items.forEach((item) => {
                        if (item.id === id && !item.readDate) {
                            item.readDate = moment().format(DATE_TIME_FORMAT);
                            self.unreadCount--;
                        }
                    });
                });

                return true;
            } catch (er) {
                self.notify.error(er);

                return false;
            }
        }),
    }))
    .named("MessageStore");

const LIMIT = 10;

export const initialState = (): typeof MessageStore.SnapshotType => ({
    hasMore: true,
    items: [],
    unreadCount: 0,
});

export type MessageStoreType = typeof MessageStore.Type;
export type MessageType = typeof Message.Type;
export type MessageSnapshotType = typeof Message.SnapshotType;
