import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { Employer, EmployerType, EmployerSnapshotType, fields, treat } from "./employer";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { Queryable } from "modules/common/models/queryable";
import { OrderDictionaryItem, OrderDictionaryItemType } from "modules/orders-manage/models/order-dictionary";

export const EmployeeList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            removed: types.boolean,
            employers: types.array(Employer),
            sorter: TableSorter,
            orders: types.array(OrderDictionaryItem),
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<EmployerType> {
            return self.employers.reduce((result, employer) => {
                result[employer.id] = employer;
                return result;
            }, {} as TStringMap<EmployerType>);
        },

        get data() {
            const predicate = (o: EmployerType) => {
                let result = true;
                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.name} ${o.login} ${o.department.id} ${o.employerPosition.name} ${o.fireDate} ${o.phone} ${o.email}`
                            .toLowerCase()
                            .indexOf(self.pureQuery) >= 0;
                }

                return result;
            };

            return self.employers.filter(predicate).map((order) => ({
                ...getSnapshot(order),
            }));
        },

        get active() {
            return self.employers.filter((e) => !e.fired);
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                const url = !self.removed ? apiUrls.employee.list() : apiUrls.employee.removed;
                const data: any = yield self.transport.get<EmployerSnapshotType[]>(url);
                applySnapshot(
                    self.employers,
                    data.map((sn: EmployerSnapshotType) => treat(sn))
                );

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
        loadProjects: flow(function* (id: string) {
            try {
                const data: any = yield self.transport.get<OrderDictionaryItemType[]>(apiUrls.employee.engineer(id));
                applySnapshot(self.orders, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .named("EmployeeList");

export type EmployeeListType = typeof EmployeeList.Type;
export type EmployeeListSnapshotType = typeof EmployeeList.SnapshotType;
export { Employer };

const sortStorage = getSortOption(EmployeeList.name);

export const initialState = (removed = false): EmployeeListSnapshotType => {
    const options = sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        removed,
        employers: [],
        orders: [],
        pureQuery: "",
        query: "",
        sorter: {
            id: EmployeeList.name,
            tableName: EmployeeList.name,
            column: options.column,
            asc: options.asc,
        },
    };
};
