import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { CategoryDictionaryItem, CategoryDictionaryItemType } from "./category";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";

export const CategoryDictionary = types
    .compose(
        Transport,
        Notificator,
        types.model({
            categories: types.array(CategoryDictionaryItem),
        })
    )
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data = yield self.transport.get<CategoryDictionaryItemType[]>(apiUrls.categories.list());
                applySnapshot(self.categories, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .views((self) => ({
        get isEmpty() {
            return !self.categories.length;
        },

        get data() {
            return self.categories.map((c) => ({
                ...getSnapshot(c),
                label: c.label,
            }));
        },
    }))

    .named("CategoryDictionary");

export type CategoryDictionaryType = typeof CategoryDictionary.Type;
export type CategoryDictionarySnapshotType = typeof CategoryDictionary.SnapshotType;

export const initialState = (): CategoryDictionarySnapshotType => ({
    categories: [],
});
