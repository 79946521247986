import React, { useEffect, useState } from "react";
import styles from "./Finance.module.scss";
import { Stages } from "./Stages";
import { OrderFinancesStoreType } from "modules/orders-manage/models/order-finances-store";
import { OrderTimeline } from "../common/OrderTimeline";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { OrderExternalParams, OrderSummaryType } from "modules/orders-manage/models/order";
import { ScrollToElement } from "../spending/helpers";
import { RouteComponentProps, withRouter } from "react-router-dom";

const Finance = ({
  store,
  orderId,
  readonly,
  isActiveTab,
  printStages,
  storeDetails,
  location,
}: FinanceTabProps & RouteComponentProps) => {
  const me = React.useRef<HTMLDivElement>(null);
  const [pointer, setPointer] = useState("");
  useEffect(() => {
    if (isActiveTab && store.orderId !== orderId) {
      store.load(orderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab, orderId]);
  useEffect(() => {
    const external = OrderExternalParams(location.hash);
    setPointer(external.rowId);
    ScrollToElement(me, external.rowId || "", 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!isActiveTab) {
    return null;
  }

  return (
    <div className={styles.finance}>
      <div className="planr-tools">
        <PlanrButton
          type="graybtn"
          icon="general-print"
          onClick={async () => {
            await store.printStagesModel();
          }}
          round={true}
          title="Печать"
        />
        <PlanrButton
          type="graybtn"
          icon="general-document"
          onClick={() => {
            printStages();
          }}
          round={true}
          title="Печать"
        />
      </div>
      {orderId !== EMPTY_OBJECT_ID ? (
        <div ref={me}>
          <div className="order-timeline-wrapper">
            <OrderTimeline events={store.timeline} isCollapsed={true} />
          </div>
          <Stages
            store={store.stages}
            readonly={readonly}
            financeStore={store}
            additions={storeDetails.additions}
            details={storeDetails}
            highlight={pointer}
          />
        </div>
      ) : (
        <h2>Сначала необходимо сохранить основные данные договора</h2>
      )}
    </div>
  );
};

export const FinanceTab = withRouter(Finance);

interface FinanceTabProps {
  orderId: string;
  store: OrderFinancesStoreType;
  isActiveTab: boolean;
  printStages: () => void;
  readonly?: boolean;
  storeDetails: OrderSummaryType;
}
