import { types } from "mobx-state-tree";
import { routes } from "modules/common/routes";
import { MenuItems, menuItems } from "../components/menu/types";

export const MainMenuStore = types
    .model({
        items: types.map(types.boolean),
    })
    .actions((self) => ({
        toggle(item: string, state: boolean) {
            self.items.set(item, state);
        },
    }))
    .named("MainMenuStore");

export type MainMenuStoreType = typeof MainMenuStore.Type;
export type MainMenuStoreSnapshotType = typeof MainMenuStore.SnapshotType;

export const initialState = (): MainMenuStoreSnapshotType => {
    const items = menuItems([]);
    const expanded = getExpandedTabFromRoute(window.location.pathname, items);

    return {
        items: {
            [expanded]: true,
        },
    };
};

const getExpandedTabFromRoute = (pathname: string, items: MenuItems) => {
    if (pathname.includes(routes.agents.path)) {
        return items.agents.id;
    }

    if (pathname.includes(routes.dictionaries.path)) {
        return items.dictionaries.id;
    }

    if (pathname.includes(routes.orders.path)) {
        return items.orders.id;
    }

    if (pathname.includes(routes.spending.path)) {
        return items.spending.id;
    }

    return "";
};
