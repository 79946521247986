import React from "react";
import { types } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { nameof } from "modules/common/services/typescript";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { texts } from "modules/common/texts";
import { EmployerDictionaryItem } from "modules/spending/employee/models/employee-dictionary";
import { WorkloadDay } from "modules/spending/workload/models/workload-day";

export const UserTimesheet = types
    .compose(
        Transport,
        Notificator,
        types.model({
            month: types.number,
            year: types.number,
            monthName: types.string,
            user: EmployerDictionaryItem,
            days: types.array(WorkloadDay),
            workedOutHours: types.number,
            monthHours: types.number,
            requiredHours: types.number,
            overtimeScale: types.number,
            overheadHours: types.number,
            overtimeHours: types.model({
                totalHours: types.number,
                dayOffHours: types.number,
            }),
            workedOutFromHomeHours: types.number,
            illnessHours: types.number,
            vacationHours: types.number,
            vacationWorkedHours: types.number,
            missingHours: types.number,
            otherMissingHours: types.map(types.number),
        })
    )
    .views((self) => ({
        get totalOtherMissingHours() {
            return Array.from(self.otherMissingHours.values()).reduce((sum, hours) => sum + hours, 0);
        },

        get hoursDiff() {
            return self.workedOutHours - self.requiredHours;
        },
    }))

    .actions((self) => ({
        save: flow(function* (values: any) {
            try {
                const overtimeScale = parseInt(values[fields.overtimeScale], 10);

                yield self.transport.post<any>(apiUrls.schedule.overtimeScale, {
                    ...values,
                    month: self.month,
                    year: self.year,
                    employerId: self.user.id,
                });

                self.overtimeScale = overtimeScale;
                self.notify.success(texts.messages.saved);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
        truncated(minutes: boolean = false) {
            const workedOutHours = minutes ? self.workedOutHours : Math.round(self.workedOutHours);
            const overtimeHours = minutes ? self.overtimeHours.totalHours : Math.round(self.overtimeHours.totalHours);
            const overheadHours = minutes ? self.overheadHours : Math.round(self.overheadHours);
            const workedOutFromHomeHours = minutes
                ? self.workedOutFromHomeHours
                : Math.round(self.workedOutFromHomeHours);
            const dayOffHours = minutes ? self.overtimeHours.dayOffHours : Math.round(self.overtimeHours.dayOffHours);
            const missingHours = minutes ? self.missingHours : Math.round(self.missingHours);
            const totalOtherMissingHours = minutes
                ? self.totalOtherMissingHours
                : Math.round(self.totalOtherMissingHours);
            const illnessHours = minutes ? self.illnessHours : Math.round(self.illnessHours);
            const vacationHours = minutes ? self.vacationHours : Math.round(self.vacationHours);

            return {
                name: self.user.label + "\r\n" + self.user.position.substr(0, 30),
                position: self.user.position,
                requiredHours: formatHours(self.requiredHours, true),
                workedOutHours: formatHours(workedOutHours, true),
                overtimeHours: formatHours(overtimeHours, true),
                dayOffHours: formatHours(dayOffHours, true),
                workedOutFromHomeHours: formatHours(workedOutFromHomeHours, true),
                overheadHours: formatHours(overheadHours, true),
                missingHours: formatHours(missingHours, true),
                vacationHours: formatHours(vacationHours, true),
                illnessHours: formatHours(illnessHours, true),
                totalOtherMissingHours: formatHours(totalOtherMissingHours, true),
                overtimeScale: self.overtimeScale,
            };
        },
    }))
    .named("UserTimesheet");

export type UserTimesheetType = typeof UserTimesheet.Type;
export type UserTimesheetSnapshotType = typeof UserTimesheet.SnapshotType;

export const fields = {
    overtimeScale: nameof((a: UserTimesheetType) => a.overtimeScale) as string,
};

export function formatHours(hours: number, asText = false) {
    let dec = hours - Math.floor(hours);
    if (hours === 0) {
        return `0ч`;
    }
    let mins = 0;
    if (dec > 0) {
        mins = 60 * dec;
    }
    if (mins > 0) {
        return hours > 0 ? `${Math.floor(hours)}ч ${Math.round(mins)}м` : asText ? "-" : <>&ndash;</>;
    } else {
        return hours > 0 ? `${hours}ч` : asText ? "-" : <>&ndash;</>;
    }
}
