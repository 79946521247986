import React from "react";
import { OrderListType } from "../models/order-list";
import { observer } from "mobx-react";
import styles from "./OrderList.module.scss";
import { withRouter, RouteComponentProps } from "react-router";
import { InputGroup, Classes } from "@blueprintjs/core";
import { WHITE } from "modules/common/constants";
import classnames from "classnames";
import { colorLuminance } from "modules/common/services/colors";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

type Props = OrderListProps & RouteComponentProps;

class OrderList extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    props.store.load();
  }

  render() {
    const { store, style, selectedOrder, canRead } = this.props;

    return (
      <div className={`order-list ${styles.list} ${Classes.ELEVATION_3}`} style={style}>
        <div className={styles.mobileClose}>
          <GeneralIcon
            size={28}
            type="general-cross-big"
            onClick={() => {
              store.toggleVisibility(false);
            }}
          />
        </div>
        <div className={`order-list-title ${styles.title}`}>Лента проектов</div>

        <InputGroup
          onChange={this.handleFilterChange}
          placeholder="Поиск проекта"
          value={store.lastFilter}
          className={`${styles.filter} order-list-filter planr-default-input`}
        />

        <div className={`${styles.scroller} `}>
          {!store.isEmpty && (
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>№</th>
                  <th>Имя</th>
                  <th>Статус</th>
                </tr>
              </thead>
              <tbody>
                {store.data.map((order, index) => {
                  const statusStyle: React.CSSProperties = {
                    padding: "5px 7px",
                    fontSize: "10px",
                    borderRadius: "4px",
                    backgroundColor: `${colorLuminance(order.statusColor, 0.1)}`,
                    color: `${colorLuminance(order.statusColor, -0.5)}`,
                  };

                  return (
                    <tr
                      className={classnames({
                        [styles.even]: index % 2 === 0,
                        [styles.selected]: selectedOrder === order.id,
                        [styles.available]: canRead,
                      })}
                      key={order.id}
                      onClick={() => this.gotoOrder(order.id)}
                    >
                      <td className={styles.number}>
                        <div className={styles.color} style={{ backgroundColor: order.color || WHITE }}></div>
                        {order.fullInventoryNumber}
                      </td>
                      <td className={styles.name}>{order.name}</td>
                      <td className={styles.status}>
                        <span style={statusStyle}>{order.status}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }

  gotoOrder = (id: string) => {
    if (!this.props.canRead) {
      return;
    }

    const { history, onOrderSelected, orderRoute } = this.props;

    const url = orderRoute(id);
    history.push(url);

    onOrderSelected(id);
  };

  handleFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const filter = e.currentTarget.value;
    this.props.store.filter(filter);
  };
}

export default withRouter(observer(OrderList));

export interface OrderListProps {
  canRead: boolean;
  store: OrderListType;
  selectedOrder: string;
  onOrderSelected: (id: string) => void;
  orderRoute: (id: string) => string;
  style?: React.CSSProperties;
}
