import React from "react";
import { Icon, Tag } from "@blueprintjs/core";
import { Money } from "modules/common/components/money/Money";
import styles from "./Premium.module.scss";
import { MoneyInput } from "modules/common/components/money/MoneyInput";
import {
    EmployerPremiumPaymentSnapshotType,
    paidSum,
    paymentsError,
    PremiumRow,
} from "modules/orders-manage/models/premium";
import { Buttons } from "modules/common/components/form";
import { texts } from "modules/common/texts";
import { toJsonHard } from "modules/common/services/mobx/serialize";
import moment from "moment";
import { SORTABLE_DATE_FORMAT } from "modules/common/constants";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { NoData } from "modules/common/components/table/NoData";
import { makePeriodName } from "modules/common/models/period";
import { Constants } from "modules/root/models/constants";

export class PremiumPayments extends React.PureComponent<PremiumPaymentsProps, PremiumPaymentsState> {
    constructor(props: PremiumPaymentsProps) {
        super(props);

        this.state = { value: toJsonHard(props.value) };
    }

    render() {
        const { value } = this.state;
        const { readOnly, onCancel } = this.props;
        const remains = this.remains();
        const error = paymentsError(value);
        const payments = value.payments.filter((p) => !p.removed);

        return (
            <div>
                <h3 className={styles.header}>
                    Оплаты <Icon icon="plus" className="action-icon" onClick={this.addPayment} />
                    <Tag minimal={true} className={styles.remains} intent={remains < 0 ? "warning" : "none"}>
                        Осталось: <Money amount={remains} />
                    </Tag>
                </h3>
                {payments.map((payment, index) => {
                    return (
                        <div className={styles.row} key={index}>
                            <MoneyInput
                                value={payment.sum}
                                onMoneyChange={(money) => {
                                    const newValue = [
                                        ...value.payments.slice(0, index),
                                        { ...payment, sum: money.floatValue || 0, dirty: true },
                                        ...value.payments.slice(index + 1),
                                    ];

                                    this.setState({
                                        value: {
                                            ...value,
                                            payments: newValue,
                                        },
                                    });
                                }}
                                small={true}
                                autoComplete="off"
                                data-lpignore="true"
                                disabled={readOnly}
                                className="planr-tiny-input"
                            />

                            <div className={styles.period}>
                                {payment.month && <span>{payment.periodName}</span>}
                                {!payment.month && !readOnly && (
                                    <PlanrButton
                                        type="secondary"
                                        onClick={this.setPeriod}
                                        size="small"
                                        className={`${styles.addButton} `}
                                        disabled={readOnly || !payment.sum}
                                        data-index={index}
                                        style={{
                                            height: "20px",
                                            fontSize: "10px",
                                            padding: "0px 7px",
                                        }}
                                    >
                                        В надбавку
                                    </PlanrButton>
                                )}
                            </div>

                            <div className={styles.action}>
                                <GeneralIcon
                                    type="general-trash"
                                    data-index={index}
                                    onClick={this.removePayment}
                                    style={{ color: "#E31818", cursor: "pointer" }}
                                />
                            </div>
                        </div>
                    );
                })}

                {!payments.length && <NoData compact={true} />}

                <div className="bp3-form-group bp3-intent-danger">
                    <div className="bp3-form-helper-text">{error}</div>
                </div>

                <Buttons>
                    <PlanrButton type="graybtn" size="small" onClick={onCancel}>
                        {texts.cancel}
                    </PlanrButton>
                    <PlanrButton type="greenish" size="small" onClick={this.onOk}>
                        {texts.ok}
                    </PlanrButton>
                </Buttons>
            </div>
        );
    }

    setPeriod = (e: React.MouseEvent<HTMLElement>) => {
        const index = +(e.currentTarget.dataset.index ?? "");
        const value = this.state.value;
        const payment = value.payments[index];
        if (payment.sum === 0) {
            return;
        }

        const today = new Date();
        // если премия назначена до этого дня (включительно) - распределять на текущий месяц, после - на след. месяц
        const day = today.getDate() > Constants.premiumTransferDay ? moment(today).add(1, "month").toDate() : today;
        const month = day.getMonth() + 1;
        const year = day.getFullYear();

        const periodName = makePeriodName(month, year);
        const period = makePeriodName(month, year, SORTABLE_DATE_FORMAT);

        const newValue = [
            ...value.payments.slice(0, index),
            { ...payment, month: { month, year }, period, periodName, dirty: true },
            ...value.payments.slice(index + 1),
        ];

        this.setState({
            value: {
                ...value,
                payments: newValue,
            },
        });
    };

    removePayment = (e: React.MouseEvent<SVGSVGElement>) => {
        const index = +(e.currentTarget.dataset.index ?? "");
        const value = this.state.value;
        const payment = value.payments[index];
        if (payment.sum === 0) {
            return;
        }

        const newValue = [
            ...value.payments.slice(0, index),
            { ...payment, removed: true },
            ...value.payments.slice(index + 1),
        ];

        this.setState({
            value: {
                ...value,
                payments: newValue,
            },
        });
    };

    addPayment = () => {
        const remains = Math.max(0, this.remains());
        const payment = this.props.factory(remains);
        const value = this.state.value;
        this.setState({ value: { ...value, payments: [...value.payments, payment] } });
    };

    remains = () => {
        const value = this.state.value;
        const sum = value.assigned ? value.assigned.sum : 0;
        return Math.max(0, sum - paidSum(value.payments));
    };

    onOk = () => {
        const value = this.state.value;
        if (paymentsError(value)) {
            return;
        }

        this.props.onSave(value);
    };
}

interface PremiumPaymentsProps {
    factory: (sum: number) => EmployerPremiumPaymentSnapshotType;
    value: PremiumRow;
    readOnly: boolean | undefined;
    onSave: (value: PremiumRow) => void;
    onCancel: () => void;
}

interface PremiumPaymentsState {
    value: PremiumRow;
}
