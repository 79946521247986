import { types, applySnapshot } from "mobx-state-tree";
import { DepartmentDictionaryItem, DepartmentDictionaryItemType } from "./department";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { orderBy } from "lodash";
import { MAX_INT } from "modules/common/constants";

export const DepartmentDictionary = types
    .compose(
        Transport,
        Notificator,
        types.model({
            departments: types.array(DepartmentDictionaryItem),
        })
    )
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data = yield self.transport.get<DepartmentDictionaryItemType[]>(apiUrls.departments.dictionary());
                applySnapshot(self.departments, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .views((self) => ({
        get isEmpty() {
            return !self.departments.length;
        },

        get sorted() {
            return orderBy(
                self.departments,
                [(d) => (d.order !== null ? d.order : MAX_INT), (d) => d.label],
                ["asc", "asc"]
            );
        },
    }))
    .named("DepartmentDictionary");

export type DepartmentDictionaryType = typeof DepartmentDictionary.Type;
export type DepartmentDictionarySnapshotType = typeof DepartmentDictionary.SnapshotType;

export const initialState = (): DepartmentDictionarySnapshotType => ({
    departments: [],
});
