import { types } from "mobx-state-tree";
import { FileMetadata, asFileBase } from "modules/common/models/file-metadata";
import { BaseEntity } from "modules/common/models/entity";
import { Notificator } from "modules/common/models/notificator";
import { nameof } from "modules/common/services/typescript";
import { DATE_TIME_FORMAT, DATE_FORMAT } from "modules/common/constants";
import moment from "moment";
import { EmployerLink } from "modules/spending/employee/models/employer";
import { StringifiedOrderLink } from "./order-link";
import { formatDate, parseDate } from "modules/common/services/formatting/date";
import { Constants } from "modules/root/models/constants";

export interface SaveOrderMailValue {
    type: string;
    id: string;
    number: string;
    name: string;
    comment: string;
    orderId: string;
    assigneeId: string;
    assigneeName: string;
    mentorId: string;
    mentorName: string;
    date: Date | null;
    deadline: Date | null;
    documents: FileBase[];
    counterpart: string;
    accepted: boolean;
    completed: boolean;
}

export const OrderMailDocument = FileMetadata;

export type OrderMailDocumentSnapshotType = typeof OrderMailDocument.SnapshotType;
export type OrderMailDocumentType = typeof OrderMailDocument.Type;

export const OrderMail = types
    .compose(
        BaseEntity,
        Notificator,
        types.model({
            type: types.string,
            name: types.string,
            number: types.string,
            comment: types.string,
            day: types.string,
            date: types.string,
            dateSortable: types.string,
            dateTime: types.string,
            deadline: types.maybeNull(types.string),
            employerId: types.string,
            order: types.maybeNull(StringifiedOrderLink),
            assignee: types.maybeNull(EmployerLink),
            mentor: types.maybeNull(EmployerLink),
            counterpart: types.string,
            documents: types.array(OrderMailDocument),
            accepted: types.boolean,
            completed: types.boolean,
            expired: types.boolean,
            status: types.maybeNull(types.string),
        })
    )
    .views((self) => ({
        get dateAsDate() {
            return moment(self.date, DATE_TIME_FORMAT);
        },

        get documentsAsMap() {
            return self.documents.reduce((result, doc) => {
                result[doc.id] = doc;
                return result;
            }, {} as TStringMap<OrderMailDocumentType>);
        },
    }))
    .actions((self) => ({
        toFieldValue() {
            return convert(self);
        },
    }))
    .named("OrderMail");

export const initialState = (): OrderMailSnapshotType => ({
    comment: "",
    created: formatDate(new Date(), DATE_FORMAT),
    day: "",
    date: "",
    dateTime: "",
    dateSortable: "",
    deadline: "",
    documents: [],
    id: "",
    name: "",
    number: "",
    type: Constants.incomingOrderMailType,
    counterpart: "",
    accepted: false,
    completed: false,
    assignee: null,
    mentor: null,
    order: null,
    employerId: "",
    status: "",
    expired: false,
});

export const searchString = (mail: OrderMailSnapshotType) => {
    const orderName = mail.order ? mail.order.name : "";
    const orderInventoryNumber = mail.order ? mail.order.inventoryNumber : "";
    const assignee = mail.assignee ? mail.assignee.name : "";
    const mentor = mail.mentor ? mail.mentor.name : "";

    return `${mail.name} ${mail.number} ${mail.counterpart} ${orderName} ${orderInventoryNumber} ${assignee} ${mentor}`.toLowerCase();
};

export const fields = {
    name: nameof((a: SaveOrderMailValue) => a.name) as string,
    number: nameof((a: SaveOrderMailValue) => a.number) as string,
    comment: nameof((a: SaveOrderMailValue) => a.comment) as string,
    documents: nameof((a: SaveOrderMailValue) => a.documents) as string,
    date: nameof((a: SaveOrderMailValue) => a.date) as string,
    type: nameof((a: SaveOrderMailValue) => a.type) as string,
    orderId: nameof((a: SaveOrderMailValue) => a.orderId) as string,
    counterpart: nameof((a: SaveOrderMailValue) => a.counterpart) as string,
    assigneeId: nameof((a: SaveOrderMailValue) => a.assigneeId) as string,
    mentorId: nameof((a: SaveOrderMailValue) => a.mentorId) as string,
    deadline: nameof((a: SaveOrderMailValue) => a.deadline) as string,
    accepted: nameof((a: SaveOrderMailValue) => a.accepted) as string,
    completed: nameof((a: SaveOrderMailValue) => a.completed) as string,
    day: nameof((a: OrderMailType) => a.day) as string,
    status: nameof((a: OrderMailType) => a.status) as string,
    order: nameof((a: OrderMailType) => a.order) as string,
    assignee: nameof((a: OrderMailType) => a.assignee) as string,
};

export type OrderMailType = typeof OrderMail.Type;
export type OrderMailSnapshotType = typeof OrderMail.SnapshotType;

export const convert = (mail: OrderMailSnapshotType): SaveOrderMailValue => ({
    accepted: mail.accepted,
    assigneeId: mail.assignee ? mail.assignee.id : "",
    assigneeName: mail.assignee ? mail.assignee.name : "",
    comment: mail.comment,
    completed: mail.completed,
    counterpart: mail.counterpart,
    documents: mail.documents.map(asFileBase),
    id: mail.id,
    mentorId: mail.mentor ? mail.mentor.id : "",
    mentorName: mail.mentor ? mail.mentor.name : "",
    name: mail.name,
    number: mail.number,
    orderId: mail.order ? mail.order.id : "",
    type: mail.type,
    date: parseDate(mail.date) || null,
    deadline: parseDate(mail.deadline || "") || null,
});
