import React from 'react';
import classNames from 'classnames';
import { toString } from 'modules/common/services/strings';

export const GridTheme: React.FC<GridThemeProps> = ({ children, className, observable, bordered, ...rest }) => {
    const style = classNames({
        'ag-grid-wrapper': true,
        'ag-theme-balham': true,
        'observable': !!observable,
        'bordered': !!bordered,

        [toString(className)]: !!className
    });

    return (
        <div className={style} {...rest}>{children}</div>
    );
};

interface GridThemeProps extends React.HTMLAttributes<HTMLDivElement> {
    bordered?: boolean;
    observable?: any;
}
