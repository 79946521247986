import React from 'react';
import { AuthFormProps, AuthForm } from './AuthForm';
import { Dialog, Icon } from '@blueprintjs/core';
import styles from './AuthPopup.module.scss';

export const AuthPopup = ({ store } : AuthPopupProps) => {
    return (
        <Dialog
            className={styles.popup}
            icon={<Icon icon="issue" intent="danger" />}
            title="Ваша сессия истекла"
            isCloseButtonShown={false}
            isOpen={true}
        >
            <AuthForm store={store} />
        </Dialog>
    );
}

interface AuthPopupProps extends AuthFormProps {}
