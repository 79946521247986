import { types } from "mobx-state-tree";
import moment from "moment";
import { DATE_TIME_FORMAT, EMPTY_OBJECT_ID } from "modules/common/constants";

export const IdEntity = types.model("IdEntity", {
    id: types.string,
});

export const DictionaryItem = types
    .compose(
        IdEntity,
        types.model({
            label: types.string,
        })
    )
    .named("DictionaryItem");

export const CategoryDictionaryItem = types.compose(IdEntity, types.model({})).named("DictionaryItem");

export const BaseEntity = types
    .compose(
        IdEntity,
        types.model({
            created: types.string,
        })
    )
    .views((self) => ({
        get createdAsDate() {
            return moment(self.created, DATE_TIME_FORMAT);
        },
        get isNewlyCreated() {
            return isNewlyCreated(self.id);
        },
    }))
    .named("BaseEntity");

export function isNewlyCreated(id: string) {
    return !id || id === EMPTY_OBJECT_ID;
}

export type DictionaryItemType = typeof DictionaryItem.Type;
export type CategoryDictionaryItemType = typeof CategoryDictionaryItem.Type;
export type DictionaryItemSnapshotType = typeof DictionaryItem.SnapshotType;
