import React from "react";
import { FormikProps } from "formik";
import { Buttons } from "./Buttons";
import { Button } from "@blueprintjs/core/lib/esnext/components";
import { texts } from "modules/common/texts";
import { RemoveButton } from "./RemoveButton";
import styles from "./StandardFormButtons.module.scss";

export const StandardFormButtons = (props: StandardFormButtonsProps) => {
    const { submitOnDirty, submitLabel } = props;
    const { dirty, resetForm, isSubmitting, isNewlyCreated, isRemoved, what, onRemove, compact } = props;
    const submitVisible = submitOnDirty ? dirty : true;

    return (
        <Buttons
            className={`${styles.buttons} ${compact ? styles.compact : ""}`}
            left={
                <>
                    {submitVisible && (
                        <Button
                            text={compact ? undefined : submitLabel || texts.save}
                            icon={compact ? "floppy-disk" : undefined}
                            intent="success"
                            type="submit"
                            className={submitOnDirty ? styles.pulse : ""}
                            disabled={isSubmitting}
                            style={{
                                color: "#fff",
                                backgroundColor: "#1DD278",
                                fontFamily: "GothamPro, Arial, sans-serif",
                                borderRadius: "4px",
                                border: "none",
                                fontSize: "14px",
                                padding: "6px 18px",
                                transition: "0.2s ease-in",
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = "#1dc470";
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = "#1DD278";
                            }}
                        />
                    )}
                    {dirty ? (
                        <Button
                            text={compact ? undefined : texts.cancel}
                            onClick={() => resetForm()}
                            disabled={isSubmitting}
                            style={{
                                color: "rgba(0, 39, 61, 0.4)",
                                backgroundColor: "#EDF0F2",
                                fontFamily: "GothamPro, Arial, sans-serif",
                                borderRadius: "4px",
                                border: "none",
                                fontSize: "14px",
                                padding: "6px 18px",
                                transition: "0.2s ease-in",
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = "#e3e6e7";
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = "#EDF0F2";
                            }}
                        />
                    ) : (
                        !isNewlyCreated && (
                            <RemoveButton
                                actionName={isRemoved ? texts.restore.toLowerCase() : undefined}
                                text={isRemoved ? texts.restore : undefined}
                                onClick={() => onRemove()}
                                disabled={isSubmitting}
                                what={what}
                                compact={compact ? "button" : "button-no-icon"}
                                style={{
                                    color: "#fff",
                                    backgroundColor: "#E31818",
                                    fontFamily: "GothamPro, Arial, sans-serif",
                                    borderRadius: "4px",
                                    border: "none",
                                    fontSize: "14px",
                                    padding: "6px 18px",
                                    transition: "0.2s ease-in",
                                }}
                                onMouseOver={(e) => {
                                    e.currentTarget.style.backgroundColor = "#d30e0e";
                                }}
                                onMouseOut={(e) => {
                                    e.currentTarget.style.backgroundColor = "#E31818";
                                }}
                            />
                        )
                    )}
                </>
            }
        ></Buttons>
    );
};

interface StandardFormButtonsProps extends FormikProps<any> {
    onRemove: () => void;
    isRemoved: boolean;
    isNewlyCreated: boolean;
    what: string;
    compact?: boolean;
    submitOnDirty?: boolean;
    submitLabel?: string;
}
