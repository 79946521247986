import React from "react";
import styles from "./OwnSpendingsWidget.module.scss";
import { OwnSpendingsWidgetStoreType } from "modules/main/models/own-spendings-store";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import { colorLuminance } from "modules/common/services/colors";
import { routes } from "modules/common/routes";
import { Link } from "react-router-dom";
import { Money } from "modules/common/components/money/Money";
import { EmployeeListType } from "modules/spending/employee/models/employer-list";

class Widget extends React.Component<OwnSpendingsWidgetProps, OwnSpendingsWidgetState> {
    constructor(props: OwnSpendingsWidgetProps) {
        super(props);

        this.state = { collapsed: false };
    }
    canCollapse = () => (typeof this.props.collapsable === "boolean" ? this.props.collapsable : true);

    render() {
        const { store, orderAccess, withSpendings, employeeList } = this.props;

        return (
            <div className={`${styles.scroller} own-spendings-widget-scroller`}>
                {store.items.map((project) => {
                    const href = orderAccess ? routes.orders.details(project.order.id) : "";

                    return (
                        <div className={styles.row} key={project.order.id}>
                            <div className={`${styles.inventory} `}>
                                {href ? (
                                    <Link to={href}>{project.order.fullInventoryNumber}</Link>
                                ) : (
                                    project.order.fullInventoryNumber
                                )}
                            </div>
                            <div className={styles.name}>
                                {href ? <Link to={href}>{project.order.name}</Link> : project.order.name}
                            </div>
                            {withSpendings && (
                                <div className={`${styles.money} `}>
                                    <Money amount={project.money} />
                                </div>
                            )}
                            <div className={`${styles.hours} `}>
                                <span>{project.formattedHours}</span> ч
                            </div>
                        </div>
                    );
                })}
                {employeeList?.orders &&
                    employeeList?.orders.map((item) => {
                        const href = routes.orders.details(item.id);
                        const statusStyle: React.CSSProperties = {
                            padding: "5px 7px",
                            fontSize: "10px",
                            borderRadius: "4px",
                            backgroundColor: `${colorLuminance(item.statusColor, 0.1)}`,
                            color: `${colorLuminance(item.statusColor, -0.5)}`,
                        };
                        return (
                            <div className={styles.row} key={item.id}>
                                <div className={`${styles.inventory} `}>
                                    {href ? (
                                        <Link to={href}>{item.fullInventoryNumber}</Link>
                                    ) : (
                                        item.fullInventoryNumber
                                    )}
                                </div>
                                <div className={styles.name}>
                                    {href ? <Link to={href}>{item.name}</Link> : item.name}
                                </div>

                                <div className={`${styles.hours} righted`}>
                                    <span style={statusStyle}>{item.status}</span>
                                </div>
                            </div>
                        );
                    })}
                {store.isEmpty && <div className={styles.noData}>{texts.noData}</div>}
            </div>
        );
    }

    onToggleCollapse = () => {
        const value = this.canCollapse() ? !this.state.collapsed : false;
        this.setState({ collapsed: value });
    };
}

export const OwnSpendingsWidget = observer(Widget);

interface OwnSpendingsWidgetProps {
    store: OwnSpendingsWidgetStoreType;
    withSpendings: boolean;
    collapsable?: boolean;
    orderAccess?: boolean;
    employeeList?: EmployeeListType;
    employerId?: string;
}

interface OwnSpendingsWidgetState {
    collapsed: boolean;
}
