import { types, applySnapshot, getSnapshot, getParent } from "mobx-state-tree";
import { MaterialValue, MaterialValueType, MaterialValueSnapshotType, fields } from "./material-value";
import { CategoryList, initialState as initialist } from "./category-list";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { TableSorter } from "modules/common/models/table-sorter";
import { DEFAULT_SORTING_ASCENDING_VALUE, DATE_TIME_FORMAT } from "modules/common/constants";
import { getSortOption, SortOptions } from "modules/common/services/table/sorting-storage";
import { Queryable } from "modules/common/models/queryable";
import moment from "moment";

export const MaterialValueList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            materialValues: types.array(MaterialValue),
            sorter: TableSorter,
            selectedEmployer: types.string,
            selectedCategory: types.string,
            selectedDepreciationGroup: types.string,
            categories: CategoryList,
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<MaterialValueType> {
            return self.materialValues.reduce((result, materialValue) => {
                result[materialValue.id] = materialValue;
                return result;
            }, {} as TStringMap<MaterialValueType>);
        },

        get materialsAll() {
            const predicate = (o: MaterialValueType) => {
                let result = true;

                result = result && o.isRemoved !== true && o.stopDate === null;

                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.name} ${o.comment} ${o.currentEmployer?.name} ${o.actualSum} ${o.depreciationGroup?.name} ${o.materialValueCategory?.name}`
                            .toLowerCase()
                            .indexOf(self.pureQuery) >= 0;
                }
                return result;
            };

            return self.materialValues.filter(predicate).map((pos) => ({
                ...getSnapshot(pos),
                currentEmployeeName: pos.currentEmployeeName,
                materialValueCategoryId: pos.materialValueCategoryId,
                depreciationGroupId: pos.depreciationGroupId,
            }));
        },

        get freeMaterials() {
            const predicate = (o: MaterialValueType) => {
                let result = true;
                if (self.selectedCategory) {
                    result =
                        result &&
                        o.materialValueCategory?.id === self.selectedCategory &&
                        o.currentEmployer?.id === undefined &&
                        o.isRemoved !== true &&
                        o.stopDate === null;
                }
                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.name} ${o.comment} ${o.currentEmployer?.name} ${o.actualSum}`
                            .toLowerCase()
                            .indexOf(self.pureQuery) >= 0;
                }
                return result;
            };

            return self.materialValues.filter(predicate).map((pos) => ({
                ...getSnapshot(pos),
            }));
        },
        get fixedMaterials() {
            const predicate = (o: MaterialValueType) => {
                let result = true;
                if (self.selectedCategory) {
                    result =
                        result &&
                        o.materialValueCategory?.id === self.selectedCategory &&
                        o.currentEmployer?.id !== undefined &&
                        o.isRemoved !== true &&
                        o.stopDate === null;
                }
                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.name} ${o.comment} ${o.currentEmployer?.name}`.toLowerCase().indexOf(self.pureQuery) >= 0;
                }
                return result;
            };

            return self.materialValues.filter(predicate).map((pos) => ({
                ...getSnapshot(pos),
                currentEmployeeName: pos.currentEmployeeName,
            }));
        },

        get deletedMaterials() {
            const predicate = (o: MaterialValueType) => {
                let result = true;
                if (self.selectedCategory) {
                    result = result && o.materialValueCategory?.id === self.selectedCategory && o.isRemoved === true;
                }
                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.name} ${o.comment} ${o.currentEmployer?.name}`.toLowerCase().indexOf(self.pureQuery) >= 0;
                }
                return result;
            };

            return self.materialValues.filter(predicate).map((pos) => ({
                ...getSnapshot(pos),
                currentEmployeeName: pos.currentEmployeeName,
            }));
        },

        get deletedMaterialsAll() {
            const predicate = (o: MaterialValueType) => {
                let result = true;

                result = result && o.isRemoved === true;

                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.name} ${o.comment} ${o.currentEmployer?.name} ${o.depreciationGroup?.name} ${o.materialValueCategory?.name}`
                            .toLowerCase()
                            .indexOf(self.pureQuery) >= 0;
                }
                return result;
            };

            return self.materialValues.filter(predicate).map((pos) => ({
                ...getSnapshot(pos),
                currentEmployeeName: pos.currentEmployeeName,
                materialValueCategoryId: pos.materialValueCategoryId,
                depreciationGroupId: pos.depreciationGroupId,
            }));
        },

        get endedMaterials() {
            const predicate = (o: MaterialValueType) => {
                let result = true;
                if (self.selectedCategory) {
                    result =
                        result &&
                        o.materialValueCategory?.id === self.selectedCategory &&
                        o.isRemoved !== true &&
                        o.stopDate !== null;
                }
                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.name} ${o.comment} ${o.currentEmployer?.name}`.toLowerCase().indexOf(self.pureQuery) >= 0;
                }
                return result;
            };

            return self.materialValues.filter(predicate).map((pos) => ({
                ...getSnapshot(pos),
                currentEmployeeName: pos.currentEmployeeName,
            }));
        },

        get endedMaterialsAll() {
            const predicate = (o: MaterialValueType) => {
                let result = true;

                result = result && o.isRemoved !== true && o.stopDate !== null;

                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.name} ${o.comment} ${o.currentEmployer?.name} ${o.depreciationGroup?.name} ${o.materialValueCategory?.name}`
                            .toLowerCase()
                            .indexOf(self.pureQuery) >= 0;
                }
                return result;
            };

            return self.materialValues.filter(predicate).map((pos) => ({
                ...getSnapshot(pos),
                currentEmployeeName: pos.currentEmployeeName,
                materialValueCategoryId: pos.materialValueCategoryId,
                depreciationGroupId: pos.depreciationGroupId,
            }));
        },

        get isEmpty() {
            return !self.materialValues.length;
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: any = yield self.transport.get<MaterialValueSnapshotType[]>(apiUrls.materialValues.list());

                applySnapshot(self.materialValues, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .actions((self) => ({
        loadEmployer: flow(function* (id: string) {
            try {
                const data: any = yield self.transport.get<MaterialValueSnapshotType[]>(
                    apiUrls.materialValues.employer(id)
                );
                applySnapshot(self.materialValues, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        setEmployer(id: string) {
            self.selectedEmployer = id;
        },

        setCategory(id: string) {
            self.selectedCategory = id;
        },

        setDepreciationGroup(id: string) {
            self.selectedDepreciationGroup = id;
        },

        delete: flow(function* (materialValue: MaterialValueSnapshotType) {
            const target = self.materialValues.find((v) => v.id === materialValue.id);
            if (target) {
                yield target.delete();
                yield self.load();
            }
            const node = getParent(self);
            if (node.update) {
                node.update();
            }

            return true;
        }),

        stopping: flow(function* (materialValue: MaterialValueSnapshotType) {
            try {
                const target = self.materialValues.find((v) => v.id === materialValue.id);
                if (target) {
                    yield target.stopp(moment().format(DATE_TIME_FORMAT));
                    yield self.load();
                }

                const node = getParent(self);
                if (node.update) {
                    node.update();
                }

                return true;
            } catch (e) {
                return false;
            }
        }),

        returning: flow(function* (materialValue: MaterialValueSnapshotType) {
            try {
                const target = self.materialValues.find((v) => v.id === materialValue.id);
                if (target) {
                    yield target.stopp("");
                    yield self.load();
                }
                const node = getParent(self);
                if (node.update) {
                    node.update();
                }
                return true;
            } catch (e) {
                return false;
            }
        }),

        returnItem: flow(function* (materialValue: MaterialValueSnapshotType) {
            try {
                const target = self.materialValues.find((v) => v.id === materialValue.id);
                if (target) {
                    yield target.restore();
                    yield self.load();
                }
                const node = getParent(self);
                if (node.update) {
                    node.update();
                }
                return true;
            } catch (e) {
                return false;
            }
        }),
    }))
    .named("MaterialValueList");

export type MaterialValuesListType = typeof MaterialValueList.Type;
export type MaterialValueListSnapshotType = typeof MaterialValueList.SnapshotType;
export { MaterialValue };

const sortStorage = getSortOption(MaterialValueList.name);

export const initialState = (sortOptions: SortOptions | null = null): MaterialValueListSnapshotType => {
    const options = sortOptions || sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        materialValues: [],
        pureQuery: "",
        query: "",
        categories: initialist(),
        selectedEmployer: "",
        selectedDepreciationGroup: "",
        selectedCategory: "",
        sorter: {
            id: MaterialValueList.name,
            tableName: MaterialValueList.name,
            column: options.column,
            asc: options.asc,
        },
    };
};
