import React, { useCallback } from "react";
import { orderBy } from "lodash";
import { FileLink } from "modules/common/components/files/FileLink";

interface GuidableFile extends FileEssentials {
    guid: string;
}

export const OrderFiles = <TFile extends GuidableFile>(props: OrderFilesProps<TFile>) => {
    const { files, onRemove, baseUrl, removeLabel, fileLabel, readOnly, sorter } = props;

    const onFileRemove = useCallback(
        (file: TFile) => {
            onRemove(file.guid);
        },
        [onRemove]
    );

    return (
        <div className="order-files">
            {orderBy(files, sorter).map((file) => {
                return (
                    <FileLink<TFile>
                        key={file.guid}
                        baseUrl={baseUrl}
                        file={file}
                        onRemove={onFileRemove}
                        readOnly={readOnly}
                        removeLabel={removeLabel}
                        fileLabel={fileLabel}
                        style={{ height: "61px" }}
                        highlight={true}
                    />
                );
            })}
        </div>
    );
};

interface OrderFilesProps<TFile> {
    readOnly?: boolean;
    removeLabel: (file: TFile) => string;
    fileLabel: (file: TFile) => React.ReactNode;
    baseUrl: string;
    files: TFile[];
    onRemove: (guid: string) => void;
    sorter: (f: TFile) => number;
}
