import { ClientFunctionalities } from "modules/agents/clients/models/client";
import { canAny } from "modules/session/auth/access";

const func = {
  ORDERS_ALL_EDIT: "ORDERS_ALL_EDIT",

  ORDERS_SUMMARY_READ: "ORDERS_SUMMARY_READ",
  ORDERS_SUMMARY_EDIT: "ORDERS_SUMMARY_EDIT",
  ORDERS_SPENDING_READ: "ORDERS_SPENDING_READ",
  ORDERS_SPENDING_EDIT: "ORDERS_SPENDING_EDIT",
  ORDERS_CONTENTS_READ: "ORDERS_CONTENTS_READ",
  ORDERS_CONTENTS_EDIT: "ORDERS_CONTENTS_EDIT",
  ORDERS_CONTENT_TASK_READ: "ORDERS_CONTENT_TASK_READ",
  ORDERS_CONTENT_TASK_EDIT: "ORDERS_CONTENT_TASK_EDIT",
  ORDERS_IPD_READ: "ORDERS_IPD_READ",
  ORDERS_IPD_EDIT: "ORDERS_IPD_EDIT",

  ORDERS_AGENTS_READ: "ORDERS_AGENTS_READ",
  ORDERS_AGENTS_EDIT: "ORDERS_AGENTS_EDIT",

  ORDERS_CONFIRM_READ: "ORDERS_CONFIRM_READ",
  ORDERS_CONFIRM_EDIT: "ORDERS_CONFIRM_EDIT",
  ORDERS_FINANCE_READ: "ORDERS_FINANCE_READ",
  ORDERS_FINANCE_EDIT: "ORDERS_FINANCE_EDIT",
  ORDERS_MAILS_READ: "ORDERS_MAILS_READ",
  ORDERS_MAILS_EDIT: "ORDERS_MAILS_EDIT",
  ORDERS_TECHNICAL_TASK_READ: "ORDERS_TECHNICAL_TASK_READ",
  ORDERS_TECHNICAL_TASK_EDIT: "ORDERS_TECHNICAL_TASK_EDIT",
  ORDERS_SENSITIVE_DATA: "ORDERS_SENSITIVE_DATA",
  FLOW_ORDERS_CHART: "FLOW_ORDERS_CHART",
  FLOW_ORDERS_CHART_REFRESH: "FLOW_ORDERS_CHART_REFRESH",
  FLOW_ORDERS_TABLE: "FLOW_ORDERS_TABLE",
  TIMESHEET_COMMENTS: "TIMESHEET_COMMENTS",
  ORDERS_TECHINDICATORS_READ: "ORDERS_TECHINDICATORS_READ",
  ORDERS_TECHINDICATORS_EDIT: "ORDERS_TECHINDICATORS_EDIT",
  ORDERS_COMMUNICATION_READ: "ORDERS_COMMUNICATION_READ",
  ORDERS_COMMUNICATION_EDIT: "ORDERS_COMMUNICATION_EDIT",
};

export const canReadOrder = (access: string[]) => {
  const canSummaryRead = canAny([func.ORDERS_SUMMARY_EDIT, func.ORDERS_SUMMARY_READ], access);
  const canFinanceRead = canAny([func.ORDERS_FINANCE_EDIT, func.ORDERS_FINANCE_READ], access);
  const canContentRead = canAny([func.ORDERS_CONTENTS_EDIT, func.ORDERS_CONTENTS_READ], access);
  const canIpdRead = canAny([func.ORDERS_IPD_EDIT, func.ORDERS_IPD_READ], access);
  const canSpendingRead = canAny([func.ORDERS_SPENDING_EDIT, func.ORDERS_SPENDING_READ], access);
  const canMailsRead = canAny([func.ORDERS_MAILS_EDIT, func.ORDERS_MAILS_READ], access);
  const canTechTaskRead = canAny([func.ORDERS_TECHNICAL_TASK_EDIT, func.ORDERS_MAILS_READ], access);
  const canContentTask = canAny([func.ORDERS_CONTENT_TASK_EDIT, func.ORDERS_CONTENT_TASK_READ], access);
  const canWorkResult = canAny([func.ORDERS_CONTENT_TASK_READ, ClientFunctionalities.CLIENTS_USER_ACCESS], access);

  return (
    canSummaryRead ||
    canFinanceRead ||
    canContentRead ||
    canIpdRead ||
    canSpendingRead ||
    canMailsRead ||
    canTechTaskRead ||
    canContentTask ||
    canWorkResult
  );
};

export default func;
