import * as yup from 'yup';
import { fields } from 'modules/dictionaries/order-types/models/order-type';
import { texts } from 'modules/common/texts';
const { object, string } = yup;

export const orderTypeLabels = () => ({
    [fields.name]: 'Сокращенно',
    [fields.description]: texts.comment,
    [fields.fullName]: 'Полное наименование',
});

const OrderTypeSchema = () => {
    const labels = orderTypeLabels();

    return object().shape({

        [fields.name]: string()
            .label(labels[fields.name])
            .required(),

        [fields.description]: string()
            .label(labels[fields.description])
            .notRequired(),

        [fields.fullName]: string()
            .label(labels[fields.fullName])
            .required(),
    });
}

export default OrderTypeSchema;
