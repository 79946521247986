import { types, getSnapshot } from "mobx-state-tree";
import { nameof } from "modules/common/services/typescript";
import { OrderIpdType } from "./order";
import { IpdFileGuid, IpdFileGuidSnapshotType } from "./order-file";

export const OrderIpd = types
    .model({
        orderIpdTypeId: types.string,
        name: types.string,
        number: types.string,
        description: types.string,
        guid: types.string,
        stageGuid: types.maybeNull(types.string),
        status: types.string,
        requestFile: types.maybeNull(types.array(IpdFileGuid)),
        documentFile: types.maybeNull(types.array(IpdFileGuid)),
        clientIsMentor: types.boolean,
    })
    .named("OrderIpd");

export const mapIpd = (ipd: OrderIpdType): SaveOrderIpd => ({
    orderIpdTypeId: ipd.orderIpdTypeId,
    name: ipd.name,
    number: ipd.number,
    description: ipd.description,
    guid: ipd.guid,
    clientIsMentor: ipd.clientIsMentor,
    requestFile: ipd.requestFile ? getSnapshot(ipd.requestFile) : null,
    documentFile: ipd.documentFile ? getSnapshot(ipd.documentFile) : null,
    status: ipd.status,
    stageGuid: ipd.stageGuid,
});

export interface SaveOrderIpd {
    orderIpdTypeId: string;
    name: string;
    number: string;
    description: string;
    guid: string;
    stageGuid: string | null;
    clientIsMentor: boolean;
    requestFile: IpdFileGuidSnapshotType[] | null;
    documentFile: IpdFileGuidSnapshotType[] | null;
    status: string;
}

export const ipdFields = {
    name: nameof((a: SaveOrderIpd) => a.name) as string,
    number: nameof((a: SaveOrderIpd) => a.number) as string,
    description: nameof((a: SaveOrderIpd) => a.description) as string,
    clientIsMentor: nameof((a: SaveOrderIpd) => a.clientIsMentor) as string,
    requestFile: nameof((a: SaveOrderIpd) => a.requestFile) as string,
    documentFile: nameof((a: SaveOrderIpd) => a.documentFile) as string,
    stageGuid: nameof((a: SaveOrderIpd) => a.stageGuid) as string,
};
