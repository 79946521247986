import { types } from 'mobx-state-tree';

export const TablePager = types
    .model('TableSorter', {
        page: types.number,
        pageSize: types.number,
        totalCount: types.number
    });

export type TablePagerType = typeof TablePager.Type;
export type TablePagerSnapshotType = typeof TablePager.SnapshotType;
