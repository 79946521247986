export const texts = {
    warning: "Предупреждение",
    all: "Все",
    add: "Добавить",
    cancel: "Отмена",
    change: "Изменить",
    clear: "Очистить",
    close: "Закрыть",
    compare: "Сравнить",
    comment: "Примечания",
    confirmation: "Подтвеждение",
    copy: "Копировать",
    download: "Скачать",
    description: "Описание",
    more: "Подробнее",
    name: "Наименование",
    no: "Нет",
    ok: "ОК",
    paste: "Вставить",
    remove: "Удалить",
    reset: "Сбросить",
    restore: "Восстановить",
    revert: "Вернуть",
    rub: "₽",
    save: "Сохранить",
    search: "Найти",
    undefined: "Не определено",
    upload: "Загрузить файл",
    yes: "Да",
    other: "Другое",
    noData: "Нет данных",
    print: "Печать",
    export: "Экспорт",
    loading: "Загрузка...",
    ready: "Готово",
    total: "Итого",
    open: "Открыть",
    error: "Ошибка",
    plan: "План",
    actual: "Факт",
    messages: {
        saved: "Данные успешно сохранены",
        removed: "Данные успешно удалены",
        incorrectDate: (what = "") => `Указана некорректная дата ${what}`.trim(),
        leaveConfiramtion: "Все несохраненные данные будут потеряны. Продолжить?",
    },
};
