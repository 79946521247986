import React from "react";
import { SpendingsListType } from "../models/spending-list";
import styles from "./Filters.module.scss";
import {
    ProjectSelect,
    ProjectSelectedOption,
    filterProjectItemPredicate,
    renderProjectOption,
} from "modules/common/components/form/ProjectSelect";
import { Classes, InputGroup } from "@blueprintjs/core";
import { DateRange, DateRangeInput } from "@blueprintjs/datetime";
import { LocaleUtils } from "react-day-picker";
import { observer } from "mobx-react";
import { DictionaryItemType, DictionaryItemSnapshotType } from "modules/common/models/entity";
import {
    SimpleSelect,
    filterItemPredicate as simpleFilterItemPredicate,
    renderSingleOption,
    DefaultSelectedOption,
    renderSingleOptionFactory,
} from "modules/common/services/form/select";
import { toString } from "modules/common/services/strings";
import { formatDate, parseDate } from "modules/common/services/formatting/date";
import { onlyDigitsInputKeyPress } from "modules/common/services/form/values";
import { DayPickerProps, DefaultShortcuts } from "modules/common/components/form/DatePicker";
import { InputRightIcon } from "modules/common/components/form/Common";
import { SessionType } from "modules/session/auth/models/session";

import { PlanrButton } from "modules/common/components/planr/button/Button";

const digits = onlyDigitsInputKeyPress(true, false);

const IncomingOptions: DictionaryItemSnapshotType[] = [
    { id: "true", label: "Доход" },
    { id: "false", label: "Расход" },
];

const ProductionOptions: DictionaryItemSnapshotType[] = [
    { id: "true", label: "Производственный" },
    { id: "false", label: "Накладной" },
];

const renderProductionSingleOption = renderSingleOptionFactory((item) => (
    <div className={`${styles.marker} ${item.id === "true" ? styles.production : ""}`} />
));

class Base extends React.Component<FiltersProps, { dateFocused: boolean; sumFocused: boolean; dotsOpened: boolean }> {
    state = { dateFocused: false, sumFocused: false, dotsOpened: false };
    private shortcuts = DefaultShortcuts();

    render() {
        const { orders, filters, agents } = this.props.store;

        const selectedOrder = filters.orderId ? orders.asMap[filters.orderId] : null;
        const selectedAgent = filters.agentId ? agents.asMap[filters.agentId] : null;

        const selectedIncoming =
            filters.isIncoming !== null ? IncomingOptions.find((o) => o.id === toString(filters.isIncoming)) : null;
        const selectedProduction =
            filters.isProduction !== null
                ? ProductionOptions.find((o) => o.id === toString(filters.isProduction))
                : null;

        return (
            <div className={styles.filters}>
                <div className={styles.filtersRow}>
                    <ProjectSelect
                        className={`full-width-select borderless ${Classes.FILL} ${styles.filter}`}
                        filterable={true}
                        itemPredicate={filterProjectItemPredicate}
                        activeItem={selectedOrder}
                        items={orders.orders}
                        itemRenderer={renderProjectOption}
                        onItemSelect={this.projectSelected}
                        resetOnClose={true}
                        inputProps={{
                            placeholder: "Объект",
                        }}
                    >
                        <ProjectSelectedOption option={selectedOrder} onClear={this.clearProject} noData="Объект" />
                    </ProjectSelect>

                    <SimpleSelect
                        className={`full-width-select borderless ${Classes.FILL} ${styles.filter}`}
                        filterable={true}
                        itemPredicate={simpleFilterItemPredicate}
                        activeItem={selectedAgent}
                        items={agents.agents}
                        itemRenderer={renderSingleOption}
                        onItemSelect={this.agentSelected}
                        resetOnClose={true}
                        inputProps={{
                            placeholder: "Контрагент",
                        }}
                    >
                        <DefaultSelectedOption option={selectedAgent} onClear={this.clearAgent} what="Контрагент" />
                    </SimpleSelect>

                    <SimpleSelect
                        className={`full-width-select borderless ${Classes.FILL} ${styles.filter}`}
                        filterable={true}
                        itemPredicate={simpleFilterItemPredicate}
                        activeItem={selectedIncoming}
                        items={IncomingOptions}
                        itemRenderer={renderSingleOption}
                        onItemSelect={this.isIncomingSelected}
                        resetOnClose={true}
                        inputProps={{
                            placeholder: "Тип операций",
                        }}
                    >
                        <DefaultSelectedOption
                            option={selectedIncoming}
                            onClear={this.clearIsIncoming}
                            what="Тип операций"
                        />
                    </SimpleSelect>

                    <SimpleSelect
                        className={`full-width-select borderless ${Classes.FILL} ${styles.filter}`}
                        filterable={true}
                        itemPredicate={simpleFilterItemPredicate}
                        activeItem={selectedProduction}
                        items={ProductionOptions}
                        itemRenderer={renderProductionSingleOption}
                        onItemSelect={this.isProductionSelected}
                        resetOnClose={true}
                        inputProps={{
                            placeholder: "Вид расхода",
                        }}
                    >
                        <DefaultSelectedOption
                            option={selectedProduction}
                            onClear={this.clearIsProduction}
                            what="Вид расхода"
                        />
                    </SimpleSelect>
                    <PlanrButton
                        type={this.state.dotsOpened ? "primary" : "graybtn"}
                        icon="general-dots-more"
                        round={true}
                        onClick={(e) => {
                            this.setState(({ dotsOpened }) => {
                                return { dotsOpened: !dotsOpened };
                            });
                        }}
                    />
                </div>

                <div
                    className={`${styles.filtersRow}`}
                    style={this.state.dotsOpened ? { visibility: "visible" } : { visibility: "hidden" }}
                >
                    {this.props.children}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className={`${styles.rowLabels}`}>
                            <label>Укажите период:</label>
                            <label>Укажите сумму:</label>
                        </div>
                        <div className={`${styles.rowLabels}`}>
                            <label>
                                <DateRangeInput
                                    className={`planr-default-input ${styles.filter} ${
                                        this.state.dateFocused ? "focused" : ""
                                    }`}
                                    closeOnSelection={true}
                                    shortcuts={this.shortcuts}
                                    allowSingleDayRange={true}
                                    popoverProps={{
                                        boundary: "viewport",
                                    }}
                                    locale="ru"
                                    localeUtils={LocaleUtils}
                                    onChange={this.datesChanged}
                                    formatDate={this.formatDate}
                                    parseDate={parseDate}
                                    dayPickerProps={DayPickerProps}
                                    startInputProps={{
                                        placeholder: "дд.мм.гггг",
                                        onFocus: this.focusDate,
                                        onBlur: this.blurDate,
                                    }}
                                    endInputProps={{
                                        placeholder: "дд.мм.гггг",
                                        onFocus: this.focusDate,
                                        onBlur: this.blurDate,
                                        rightElement: InputRightIcon({
                                            hasValue: !filters.dateRangeIsEmpty,
                                            onClear: this.clearDates,
                                            icon: "calendar",
                                        }),
                                    }}
                                    value={filters.range}
                                />
                            </label>
                            <label style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ display: "flex", justifyContent: "space-around" }}>
                                    <InputGroup
                                        className="planr-default-input"
                                        placeholder="0"
                                        onFocus={this.focusSum}
                                        onBlur={this.blurSum}
                                        onKeyPress={digits}
                                        onChange={this.fromChanged}
                                        value={toString(filters.from)}
                                        style={{ marginRight: "8px" }}
                                    />
                                    <InputGroup
                                        className="planr-default-input"
                                        placeholder="0"
                                        onFocus={this.focusSum}
                                        onBlur={this.blurSum}
                                        onKeyPress={digits}
                                        onChange={this.toChanged}
                                        value={toString(filters.to)}
                                        style={{ marginRight: "8px" }}
                                        rightElement={InputRightIcon({
                                            hasValue: filters.sumRangeNotEmpty,
                                            onClear: this.clearSum,
                                            icon: <span className={styles.currency}>₽</span>,
                                        })}
                                    />
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    focusDate = () => this.setState({ dateFocused: true });

    blurDate = () => this.setState({ dateFocused: false });

    focusSum = () => this.setState({ sumFocused: true });

    blurSum = () => {
        this.setState({ sumFocused: false });
        this.props.store.loadTable();
    };

    fromChanged = (e: React.FormEvent<HTMLInputElement>) => {
        this.props.store.fromChanged(e.currentTarget.value);
    };

    toChanged = (e: React.FormEvent<HTMLInputElement>) => {
        this.props.store.toChanged(e.currentTarget.value);
    };

    clearSum = () => {
        this.props.store.fromChanged("");
        this.props.store.toChanged("");
        this.props.store.loadTable();
    };

    datesChanged = (range: DateRange) => {
        if (!range || (range[0] && range[1]) || (!range[0] && !range[1])) {
            this.props.store.datesChanged(range);
        }
    };

    clearDates = () => {
        this.props.store.datesChanged(null);
    };

    isProductionSelected = (item: DictionaryItemType) => {
        this.props.store.isProductionSelected(item.id);
    };

    clearIsProduction = () => {
        this.props.store.isProductionSelected(null);
    };

    isIncomingSelected = (item: DictionaryItemType) => {
        this.props.store.isIncomingSelected(item.id);
    };

    clearIsIncoming = () => {
        this.props.store.isIncomingSelected(null);
    };

    agentSelected = (item: DictionaryItemType) => {
        this.props.store.agentSelected(item.id);
    };

    clearAgent = () => {
        this.props.store.agentSelected(null);
    };

    projectSelected = (item: DictionaryItemType) => {
        this.props.store.projectSelected(item.id);
    };

    clearProject = () => {
        this.props.store.projectSelected(null);
    };

    formatDate = (date: any) => {
        return formatDate(date);
    };
}

export const Filters = observer(Base);

interface FiltersProps {
    store: SpendingsListType;
    session: SessionType;
}
