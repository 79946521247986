import { observer } from "mobx-react";
import { formatMoney } from "modules/common/components/money/Money";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { PlanrTag } from "modules/common/components/planr/tag/PlanrTag";
import { texts } from "modules/common/texts";
import { OrderSummaryType } from "modules/orders-manage/models/order";
import { OrderFileSnapshotType } from "modules/orders-manage/models/order-file";
import { OrderFinancesStoreType } from "modules/orders-manage/models/order-finances-store";
import {
  OrderStageRowType,
  OrderStagesStoreType,
  PlanOrderPaymentDetailsRowType,
} from "modules/orders-manage/models/order-stages-store";
import React from "react";
import { LinkCompletions } from "./LinkCompletions";
import { LinkConsignments } from "./LinkConsignments";
import { LinkExpertises } from "./LinkExpertises";
import { LinkPlanPayments } from "./LinkPlanPayments";
import { LinkSentExpertises } from "./LinkSentExpertises";
import { StageRow } from "./StageRow";
import styles from "./Stages.module.scss";

export const StagesList = observer(
  ({ store, readonly, financeStore, additions, details, highlight }: StagesListProps) => {
    const { stages, completionsSum, stagesSum } = store;
    const onSubmitHandler = async (stage: OrderStageRowType) => {
      if (!readonly) {
        const result = await store.saveStage(stage.newGuid);
        await financeStore.loadTimeline(stage.orderId);
        if (result) {
          details.updateStages(store.stages);
        }
      }
    };
    let planSum = 0;
    let actSum = 0;
    // let planPaid = true;
    // let actPaid = true;

    store.stages.forEach((stage) => {
      const linkedPayments = store.stagePlanMap[stage.newGuid];
      linkedPayments &&
        linkedPayments["Авансовый"].forEach((payment: PlanOrderPaymentDetailsRowType) => {
          planSum += payment.sum;
          // const paidSum = payment.sum - payment.remainsSum;
          // if (payment.sum !== paidSum) {
          //     planPaid = false;
          // }
        });
      linkedPayments &&
        linkedPayments["Расчетный"].forEach((payment: PlanOrderPaymentDetailsRowType) => {
          actSum += payment.sum;
          // const paidSum = payment.sum - payment.remainsSum;
          // if (payment.sum !== paidSum) {
          //     actPaid = false;
          // }
        });
    });

    return (
      <div className={styles.stageListContainer}>
        <div className={styles.stageList}>
          <div className={`${styles.stageRow} ${styles.header}`}>
            <div className={styles.number}>№</div>
            <div className={styles.name}>Наименование работ, состав документации</div>
            <div className={styles.completions}>
              Акты выполненных работ <br />
              <PlanrTag type="neutral">{formatMoney(completionsSum)}</PlanrTag>
            </div>
            <div className={styles.completions}>
              Накладные <br />
            </div>
            <div className={styles.start}>Начало</div>
            <div className={styles.stop}>Окончание</div>
            <div className={styles.sum}>
              Стоимость
              <br />
              <PlanrTag type="neutral">{formatMoney(stagesSum)}</PlanrTag>
            </div>
            <div className={styles.payments}>
              Аванс
              <br />
              <PlanrTag type={"neutral"}>{formatMoney(planSum)}</PlanrTag>
            </div>
            <div className={styles.payments}>
              Расчет
              <br />
              <PlanrTag type="neutral">{formatMoney(actSum)}</PlanrTag>
            </div>
          </div>

          {stages.map((stage, index) => (
            <StageRow
              key={stage.newGuid}
              stage={stage}
              store={store}
              index={index}
              additions={additions}
              onSubmit={() => onSubmitHandler(stage)}
              readonly={readonly}
              payments={(type) => <LinkPlanPayments stage={stage} store={store} type={type} />}
              completions={() => <LinkCompletions stage={stage} store={store} />}
              consignments={() => <LinkConsignments stage={stage} store={store} />}
              expertises={() => <LinkExpertises stage={stage} store={store} />}
              sentExpertises={() => <LinkSentExpertises stage={stage} store={store} />}
              highlight={highlight}
            />
          ))}
        </div>

        {!readonly && (
          <PlanrButton
            type="secondary"
            icon="general-plus-big"
            onClick={store.addNewStage}
            size="small"
            title={store.activeRow ? "Сохраните или отмените изменения по открытому этапу" : texts.add}
            className={`${styles.addButton} `}
            disabled={!!store.activeRow}
          >
            Добавить этап
          </PlanrButton>
        )}
      </div>
    );
  }
);

interface StagesListProps {
  store: OrderStagesStoreType;
  financeStore: OrderFinancesStoreType;
  readonly?: boolean;
  additions: OrderFileSnapshotType[];
  details: OrderSummaryType;
  highlight: string;
}
