import { FileLink } from "modules/common/components/files/FileLink";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { eat } from "modules/common/services/typescript";
import { fields } from "modules/orders-manage/models/order";
import { OrderExpertiseType } from "modules/orders-manage/models/order-expertise";
import { IdFactory, UploaderFatory } from "modules/orders-manage/types";
import React from "react";
import { Schema } from "yup";
import { OrderSummaryLabels } from "../validation";
import styles from "./Summary.module.scss";

const labels = OrderSummaryLabels();

/** Доп соглашения */
export const Expertises = ({ baseUrl, readOnly, expertises, schema, upload, newId }: ExpertisesProps) => {
    return expertises && expertises.length > 0 ? (
        <div>
            {/* Экспертиза */}
            <div className={styles.columnHeader} style={{ marginTop: "15px" }}>
                {labels[fields.expertises]}
                {
                    <GeneralIcon
                        type="general-exclamation"
                        title="Редактирование доступно на вкладке Финансирование"
                        style={{
                            marginTop: "5px",
                            marginLeft: "5px",
                            color: "rgb(234, 86, 30)",
                            cursor: "pointer",
                        }}
                    />
                }
            </div>
            <div>
                {expertises.map((expertise, index) =>
                    expertise.file ? (
                        <FileLink
                            baseUrl={baseUrl}
                            file={expertise.file}
                            key={index}
                            readOnly={true}
                            onRemove={eat}
                            style={{ height: "61px" }}
                            highlight={true}
                        />
                    ) : null
                )}
            </div>
        </div>
    ) : null;
};

interface ExpertisesProps extends UploaderFatory, IdFactory {
    schema: Schema<any>;
    baseUrl: string;
    readOnly?: boolean;
    expertises: OrderExpertiseType[] | null;
}

export interface AdditionFactory {}
