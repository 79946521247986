import React from "react";
import { UploaderProps, FileUploaderHOC } from "modules/common/components/files/Uploader";
import { intent, icon as notificationIcon, timeout } from "modules/root/components/app/Notifications";
import { Notificator } from "modules/root/components/app/toaster";
import { take } from "lodash";
import { MimeTypesEntries } from "modules/common/services/files";
import classnames from "classnames";
import { GeneralIcon } from "../icon/Generalcon";
import { Spinner } from "@blueprintjs/core";

const MAX = 20;

export class PlanRDndUploader extends React.Component<DndUploaderProps, DndUploaderState> {
    private dropRef = React.createRef<HTMLDivElement>();
    private dragCounter = 0;
    private alive = true;

    constructor(props: DndUploaderProps) {
        super(props);

        this.state = {
            dragging: false,
            loading: false,
        };
    }

    componentDidMount() {
        this.alive = true;
        const div = this.dropRef.current;
        if (div) {
            div.addEventListener("dragenter", this.handleDragIn);
            div.addEventListener("dragleave", this.handleDragOut);
            div.addEventListener("dragover", this.handleDrag);
            div.addEventListener("drop", this.handleDrop);
        }
    }

    componentWillUnmount() {
        this.alive = false;
        const div = this.dropRef.current;
        if (div) {
            div.removeEventListener("dragenter", this.handleDragIn);
            div.removeEventListener("dragleave", this.handleDragOut);
            div.removeEventListener("dragover", this.handleDrag);
            div.removeEventListener("drop", this.handleDrop);
        }
    }
    getStyles = () => {
        const { oneRow, width, style } = this.props;
        let styles: React.CSSProperties = {};

        if (oneRow) {
            styles.height = "38px";
        }
        if (width) {
            styles.width = `${width}px`;
            styles.minWidth = `${width}px`;
            styles.maxWidth = `${width}px`;
        }
        if (style) {
            styles = { ...styles, ...style };
        }
        return styles;
    };

    render() {
        const { accept, children, oneRow, what, noType, multiple } = this.props;
        const help =
            accept && accept !== "*"
                ? accept
                      .split(",")
                      .map((mime) => MimeTypesEntries[mime])
                      .join(", ")
                : "";
        const styles = this.getStyles();
        const styleName = classnames({
            "planr-dnduploader": true,
        });
        if (!this.state.loading) {
            return (
                <div className={styleName} ref={this.dropRef} style={styles}>
                    <div className="uploader-icon">
                        <GeneralIcon type={"general-import"} size={!oneRow ? 25 : 16} />
                    </div>
                    <div className={"uploader-text"}>
                        <FileUploaderHOC
                            accept={accept}
                            onFileSelected={this.handleSelect}
                            render={({ onClick }) => (
                                <span className={"text-item uploadlink "} onClick={onClick}>
                                    Выберите {what || "файл"}
                                </span>
                            )}
                            maximum={multiple ? MAX : undefined}
                        />
                        {!oneRow && <span className={"text-item"}>или перетащите в поле загрузки</span>}
                    </div>

                    {!noType && help && <div className={"text-item"}>{help}</div>}

                    <div className={"text-item"}>{children}</div>
                </div>
            );
        } else {
            return (
                <div className={styleName} ref={this.dropRef} style={styles}>
                    <div className="uploader-icon">{<Spinner intent="primary" size={!oneRow ? 25 : 16} />}</div>
                    <div className={"uploader-text"}>
                        <span className={"text-item"}>Идет загрузка...</span>
                    </div>

                    {!noType && help && <div className={"text-item"}>{help}</div>}

                    <div className={"text-item"}>{children}</div>
                </div>
            );
        }
    }

    handleDrag = (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
    };

    handleDragIn = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        this.dragCounter++;

        if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ dragging: true });
        }
    };

    handleDragOut = (e: Event) => {
        e.preventDefault();
        e.stopPropagation();

        this.dragCounter--;

        if (this.dragCounter === 0) {
            this.setState({ dragging: false });
        }
    };

    handleSelect = (file: File) => {
        return this.filesSelected([file] as any);
    };

    handleDrop = async (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ dragging: false });

        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            await this.filesSelected(e.dataTransfer.files);

            e.dataTransfer.clearData();
            this.dragCounter = 0;
        }
    };

    filesSelected = async (files: FileList) => {
        const { multiple, accept } = this.props;

        const source = take(
            (multiple ? Array.from(files) : [files[0]]).filter((file) => {
                const fileType = file.type || "_unknown_";

                if (accept && accept !== "*" && !accept.includes(fileType)) {
                    return false;
                }

                return true;
            }),
            MAX
        );

        if (!source.length) {
            return;
        }

        this.setState({ loading: true });

        try {
            for (let file of source) {
                await this.props.onFileSelected(file);
            }
        } catch (er) {
            const message = typeof er === "string" ? er : er.message;

            Notificator.show({
                message,
                icon: notificationIcon("error"),
                intent: intent("error"),
                timeout: timeout("error"),
            });
        } finally {
            if (this.alive) {
                this.setState({ loading: false });
            }
        }
    };
}

interface DndUploaderState {
    dragging: boolean;
    loading: boolean | undefined;
}

interface DndUploaderProps extends UploaderProps {
    withLoader?: boolean | undefined;
    multiple?: boolean;
    width?: number;
    oneRow?: boolean;
    noType?: boolean;
    what?: string;
    style?: React.CSSProperties;
}
