import React from "react";
import { SurveyType } from "modules/main/models/surveys-store";
import { observer } from "mobx-react";
import styles from "./Survey.module.scss";
import { ProgressBar, Checkbox, Icon } from "@blueprintjs/core";
import { Constants } from "modules/root/models/constants";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";

export const Survey = observer(({ survey, children, button }: SurveyProps) => {
  const statusClassName = Constants.surveyInactiveStatuses.includes(survey.status) ? styles.inactive : styles.active;
  const canVote = !survey.voted && survey.active;

  return (
    <div className={survey.status === "Активен" ? styles.survey : styles.surveyEnded}>
      <div className={`planr-block-header flat-mode ${styles.header}`}>
        {survey.created}
        <span className={`${styles.status} ${statusClassName}`}>{survey.status}</span>
      </div>
      <div className={styles.surveyTop}>
        {survey.active && (
          <RemoveConfirmation<SurveyType>
            what={() => "опрос"}
            actionName="завершить"
            render={({ confirmRemoving }) => (
              <>
                <Icon
                  icon="cross"
                  className={styles.surveyEndIcon}
                  htmlTitle="Завершить"
                  onClick={() => confirmRemoving(survey)}
                />
                &nbsp;&nbsp;
              </>
            )}
            onConfirmed={() => survey.cancel()}
          />
        )}
        <span className={styles.surveyName}>{survey.name}</span>
      </div>
      <div className={survey.status === "Активен" ? styles.body : styles.bodyEnded}>
        {!canVote && (
          <div className={styles.result}>
            {Array.from(survey.results.entries()).map(([variant, percents]) => {
              return (
                <div key={variant} className={styles.stripe}>
                  {variant} - {percents}%
                  <div className={styles.progress}>
                    <ProgressBar intent="primary" value={percents / 100} animate={false} stripes={false} />
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {canVote && (
          <div className={styles.form}>
            {survey.variants.map((variant) => {
              const handler = () => survey.vote(variant);

              return (
                <div key={variant} className={styles.variant}>
                  <Checkbox large={false} checked={false} onChange={handler} label={variant} />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className={styles.footer}>
        {button}
        Проголосовало
        <span className={styles.peopleCount}>{survey.votes.size} человек</span>
        {children}
      </div>
    </div>
  );
});

interface SurveyProps {
  survey: SurveyType;
  children?: any;
  button?: any;
}
