import { types } from "mobx-state-tree";
import { OrderIndicatorList, OrderIndicator, initialState as emptyList } from "./order-indicator-list";
import { initialState as emptyOrderIndicator } from "./order-indicator";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";

export const OrderIndicatorsStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            list: OrderIndicatorList,
            details: OrderIndicator,
        })
    )
    .named("OrderIndicatorsStore");

export const initialState = (): typeof OrderIndicatorsStore.SnapshotType => ({
    list: emptyList(),
    details: emptyOrderIndicator(),
});

export type OrderIndicatorsStoreType = typeof OrderIndicatorsStore.Type;
