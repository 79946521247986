import React from "react";
import { DateInput, IDateRangeShortcut, TimePrecision } from "@blueprintjs/datetime";
import { LocaleUtils } from "react-day-picker";
import { formatDate as baseFormat, parseWrapper } from "modules/common/services/formatting/date";
import { Position, PopperBoundary, InputGroup } from "@blueprintjs/core";
import moment from "moment";
import { InputRightIcon } from "./Common";

const format = (date: any) => baseFormat(date);
const max = moment().add(1000, "year").toDate();
const min = new Date(1, 1, 1);

export const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
];

export const weekdaysShort = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
export const weekdaysLong = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];

export const DayPickerProps = {
    locale: "ru",
    localeUtils: LocaleUtils,
    months,
    weekdaysLong,
    weekdaysShort,
    firstDayOfWeek: 1,
    labels: {
        nextMonth: "следующий месяц",
        previousMonth: "предыдущий месяц",
    },
};

export const DefaultShortcuts = (): IDateRangeShortcut[] => {
    const today = moment().startOf("day");
    const yesterday = moment(today).subtract(1, "day");
    const week = moment(today).subtract(7, "day");
    const month = moment(today).subtract(1, "month");
    const halfYear = moment(today).subtract(6, "month");
    const year = moment(today).subtract(1, "year");

    return [
        { label: "Сегодня", includeTime: false, dateRange: [today.toDate(), today.toDate()] },
        { label: "Вчера", includeTime: false, dateRange: [yesterday.toDate(), yesterday.toDate()] },
        { label: "Неделя", includeTime: false, dateRange: [week.toDate(), today.toDate()] },
        { label: "Месяц", includeTime: false, dateRange: [month.toDate(), today.toDate()] },
        { label: "Полгода", includeTime: false, dateRange: [halfYear.toDate(), today.toDate()] },
        { label: "Год", includeTime: false, dateRange: [year.toDate(), today.toDate()] },
    ];
};

export const DatePicker = ({
    onChange,
    value,
    name,
    clasName,
    maxDate,
    minDate,
    disabled,
    readOnly,
    boundary,
    defaultValue,
    timePrecision,
    small,
    formatDate,
    onKeyDown,
}: DatePickerProps) => {
    return readOnly ? (
        <InputGroup
            className="planr-default-input"
            value={(formatDate || format)(value)}
            placeholder="дд.мм.гггг"
            readOnly={true}
            small={small}
        />
    ) : (
        <DateInput
            locale="ru"
            localeUtils={LocaleUtils}
            formatDate={formatDate || format}
            onChange={readOnly ? undefined : onChange}
            parseDate={parseWrapper}
            value={value}
            popoverProps={{
                position: Position.BOTTOM,
                boundary,
            }}
            highlightCurrentDay={true}
            todayButtonText="Сегодня"
            dayPickerProps={DayPickerProps}
            className={`${clasName || ""} date-picker`}
            maxDate={maxDate || max}
            minDate={minDate || min}
            invalidDateMessage=""
            inputProps={{
                rightElement: disabled
                    ? undefined
                    : InputRightIcon({
                          hasValue: !!value,
                          disabled: disabled,
                          onClear: () => onChange(null),
                          icon: "calendar",
                      }),
                name,
                onKeyDown,
                small,
                onKeyPress: (e: any) => {
                    let input = e.target;
                    if (e.charCode < 48 || e.charCode > 57) {
                        e.preventDefault();
                    }
                    var len = input.value.length;

                    if (len !== 1 || len !== 3) {
                        if (e.charCode === 46) {
                            e.preventDefault();
                        }
                    }

                    if (len === 2) {
                        input.value += ".";
                    }

                    if (len === 5) {
                        input.value += ".";
                    }
                },
            }}
            placeholder="дд.мм.гггг"
            disabled={disabled}
            defaultValue={defaultValue}
            timePrecision={timePrecision}
            timePickerProps={
                timePrecision
                    ? {
                          showArrowButtons: true,
                      }
                    : undefined
            }
        />
    );
};

interface DatePickerProps {
    onChange: (value: Date | null) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    name?: string;
    value: Date | null;
    defaultValue?: Date;
    clasName?: string;
    maxDate?: Date;
    minDate?: Date;
    disabled?: boolean;
    readOnly?: boolean;
    boundary?: PopperBoundary;
    timePrecision?: TimePrecision;
    formatDate?: (date: Date | null, locale?: string) => string;
    small?: boolean;
}
