import React from "react";
import ReactDOM from "react-dom";
import { DraggableProvided, Droppable, Draggable } from "react-beautiful-dnd";
import { FieldProps } from "formik";
import styles from "./OtherSpendings.module.scss";
import { getDragItemStyle } from "modules/common/services/drag";
import { Caret } from "modules/common/components/collapse/Caret";
import { InputGroup, Collapse } from "@blueprintjs/core";
import { preventSubmitKeyDown, stopPropagation } from "modules/common/services/form/values";
import portal from "../../portal";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { OtherOrderSpendingType } from "modules/orders-manage/models/order-spending";
import { Money } from "modules/common/components/money/Money";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

export const EMPTY_GROUP = "EMPTY";
export function MakeGroupKey(comment: string) {
    return comment || EMPTY_GROUP;
}
type TSpending = OtherOrderSpendingType;

export class OtherSpendingsObject extends React.PureComponent<OtherSpendingsObjectProps, OtherSpendingsObjectState> {
    constructor(props: OtherSpendingsObjectProps) {
        super(props);

        this.state = { comment: props.description || "", onEdit: !props.description };
    }

    renderInternal = (isDragging: boolean, provided: DraggableProvided) => {
        const { collapsed, description, readOnly, onToggleCollapse, children, spendings, warning } = this.props;
        const onEditMode = !readOnly && this.state.onEdit;
        let summ: number = 0;
        let actual: number = 0;
        spendings.forEach((items) => {
            summ += +items.actualSum;
            items.actualPayments.forEach((payment) => {
                actual += +payment.sum - payment.correctionPayments.reduce((s, c) => s + c.sum, 0);
            });
        });

        return (
            <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                className={styles.object}
                style={getDragItemStyle(isDragging, provided.draggableProps.style)}
            >
                <div className={styles.label} onClick={onToggleCollapse} style={readOnly ? { padding: "9px 8px" } : {}}>
                    <Caret collapsed={collapsed} style={{ top: "10px" }} />

                    {onEditMode && (
                        <div className="flex">
                            <InputGroup
                                value={this.state.comment}
                                onChange={this.onDescriptionChange}
                                className="planr-default-input"
                                autoComplete="off"
                                small={true}
                                data-lpignore="true"
                                onKeyDown={preventSubmitKeyDown}
                                onClick={stopPropagation}
                            />

                            <PlanrButton
                                type="neutral"
                                icon={"general-redo"}
                                onClick={this.setDescription}
                                size="small"
                            />
                        </div>
                    )}

                    {!onEditMode && (
                        <div className="flex">
                            <span style={{ minWidth: "400px" }}>{description}</span>
                            {!readOnly && (
                                <PlanrButton
                                    type="neutral"
                                    icon={"general-edit"}
                                    onClick={this.gotoEditMode}
                                    size="small"
                                />
                            )}
                        </div>
                    )}

                    {warning && (
                        <GeneralIcon
                            type={"general-exclamation"}
                            style={{
                                color: "red",
                                marginTop: "3px",
                                marginLeft: "10px",
                                display: "flex",
                                cursor: "pointer",
                            }}
                            title={warning}
                        />
                    )}
                </div>

                <Collapse isOpen={!collapsed}>
                    {summ !== 0 && (
                        <div className={styles.sumNumbersObj}>
                            <div className="order-total" style={{ top: "8px" }}>
                                Всего по договорам:&nbsp;
                                <Money className="spendings-money" amount={summ} />
                            </div>

                            <div
                                className={`actual-total ${actual < summ ? "red-total" : "green-total"}`}
                                style={{ top: "8px" }}
                            >
                                Всего по оплатам:&nbsp;
                                <Money className="spendings-money" amount={actual} />
                            </div>
                            <div
                                className={`spendings-total ${summ - actual !== 0 ? "red-total" : "green-total"}`}
                                style={{ top: "8px" }}
                            >
                                Остаток по оплатам:&nbsp;
                                <Money className="spendings-money" amount={summ - actual} />
                            </div>
                        </div>
                    )}
                    <Droppable
                        droppableId={MakeGroupKey(description)}
                        type={MakeGroupKey(description)}
                        isDropDisabled={readOnly}
                    >
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={`spendings ${styles.table} ${styles.outsourcers}`}
                            >
                                {children}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    {!readOnly && (
                        <div>
                            <PlanrButton
                                type="secondary"
                                icon={"general-add-user"}
                                onClick={this.addSpending}
                                className={"spendings-add-button"}
                                style={{ marginBottom: "10px" }}
                            >
                                Добавить исполнителя
                            </PlanrButton>
                        </div>
                    )}
                </Collapse>
            </div>
        );
    };

    render() {
        const { index, description, readOnly } = this.props;

        return (
            <Draggable
                key={MakeGroupKey(description)}
                draggableId={MakeGroupKey(description)}
                index={index}
                isDragDisabled={readOnly}
            >
                {(provided, snapshot) => {
                    const child = this.renderInternal(snapshot.isDragging, provided);

                    return snapshot.isDragging ? ReactDOM.createPortal(child, portal) : child;
                }}
            </Draggable>
        );
    }

    addSpending = (e: React.MouseEvent) => {
        e.stopPropagation();
        this.props.addSpending();
    };

    gotoEditMode = (e: React.BaseSyntheticEvent) => {
        stopPropagation(e);
        this.setState({ onEdit: true });
    };

    exitEditMode = () => this.setState({ onEdit: false });

    setDescription = (e: React.BaseSyntheticEvent) => {
        stopPropagation(e);

        if (this.state.comment) {
            this.props.onDescriptionChange(this.state.comment);
            this.exitEditMode();
        }
    };

    onDescriptionChange = (e: React.FormEvent<HTMLInputElement>) => {
        stopPropagation(e);
        this.setState({ comment: e.currentTarget.value });
    };
}

interface OtherSpendingsObjectProps {
    description: string;
    addSpending: () => void;
    fieldProps: FieldProps;
    index: number;
    onToggleCollapse: () => void;
    collapsed: boolean;
    readOnly?: boolean;
    onDescriptionChange: (value: string) => void;
    spendings: TSpending[];
    warning: string;
}

interface OtherSpendingsObjectState {
    comment: string;
    onEdit: boolean;
}
