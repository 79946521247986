import React, { useState, useCallback } from "react";
import { Dialog, Classes, InputGroup } from "@blueprintjs/core";
import { texts } from "modules/common/texts";
import { PlanrButton } from "../planr/button/Button";

export function Prompt(props: React.PropsWithChildren<PromptProps>) {
    const { onConfirmed, render, what } = props;
    const [input, setInput] = useState<HTMLInputElement | null>(null);
    const [opened, setOpened] = useState(false as any);
    const [value, setValue] = useState("");

    const yes = useCallback(
        (e?: React.MouseEvent) => {
            e && e.stopPropagation();
            if (value) {
                setOpened(false);
                onConfirmed(value);
                setValue("");
            }
        },
        [onConfirmed, value]
    );

    const no = useCallback((e?: React.MouseEvent) => {
        e && e.stopPropagation();
        setOpened(false);
        setValue("");
    }, []);

    const onChange = useCallback((e: React.FormEvent<HTMLInputElement>) => setValue(e.currentTarget.value), []);
    const onSubmit = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.keyCode === 13) {
                yes();
            }
        },
        [yes]
    );

    const onOpen = useCallback(() => {
        input && input.focus();
    }, [input]);

    return (
        <>
            {render({
                ask: (initial = "") => {
                    setValue(initial);
                    setOpened(true);
                },
            })}
            <Dialog
                icon="help"
                canOutsideClickClose={false}
                onClose={() => no()}
                title={what}
                isOpen={opened}
                onOpened={onOpen}
                backdropClassName="standard"
            >
                <div className={Classes.DIALOG_BODY}>
                    <InputGroup
                        type="text"
                        className="planr-default-input"
                        autoComplete="off"
                        data-lpignore="true"
                        placeholder={what}
                        value={value}
                        onChange={onChange}
                        onKeyDown={onSubmit}
                        inputRef={setInput}
                    />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <PlanrButton type="graybtn" size="small" onClick={no} style={{ marginRight: "10px" }}>
                            {texts.cancel}
                        </PlanrButton>
                        {/* <Button onClick={no}>{texts.cancel}</Button> */}
                        {/* <Button onClick={yes} intent="primary">
                            {texts.ok}
                        </Button> */}
                        <PlanrButton type="greenish" size="small" onClick={yes}>
                            {texts.ok}
                        </PlanrButton>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

interface PromptProps {
    what: string;
    render: (props: PromptRenderProps) => JSX.Element;
    onConfirmed: (value: string) => void;
}

export interface PromptRenderProps {
    ask: (initial: string) => void;
}
