import React from 'react';
import { Button, IconName, Intent, } from '@blueprintjs/core';
import { texts } from 'modules/common/texts';
import styles from './RemoveButton.module.scss';
import { Icon } from '@blueprintjs/core/lib/esnext/components';
import { RemoveConfirmation } from './RemoveConfirmation';
import { ICON_BUTTON_SIZE } from 'modules/common/constants';

export const RemoveButton = ({ onClick, text, what, icon, compact, actionName, disabled, ...rest }: RemoveButtonProps) => {
    return (
        <RemoveConfirmation
            onConfirmed={onClick}
            what={() => what}
            actionName={actionName}
            render={({ confirmRemoving }) => {
                const onClick = () => !disabled && confirmRemoving(true);

                return compact === 'icon'
                    ? (
                        <Icon
                            icon={icon || 'trash'}
                            iconSize={ICON_BUTTON_SIZE}
                            style={{cursor: 'pointer'}}
                            onClick={onClick}
                            htmlTitle={!text ? texts.remove : ''}
                        />
                    ) : compact === 'button' ? (
                        <Button
                            {...rest}
                            icon={<Icon icon={icon || 'trash'}/>}
                            onClick={onClick}
                            className={`${rest.className || ''}`}
                            title={!text ? texts.remove : ''}
                            disabled={disabled}
                        />
                    ) : compact === 'button-no-icon' ? (
                        <Button
                            {...rest}
                            text={text || texts.remove}
                            onClick={onClick}
                            className={`${rest.className || ''}`}
                            title={!text ? texts.remove : ''}
                            disabled={disabled}
                        />
                    ) : (
                        <Button
                            {...rest}
                            icon={<Icon icon={icon || 'trash'} color={compact ? '#f55656' : undefined} />}
                            text={text || texts.remove}
                            onClick={onClick}
                            className={`${rest.className || ''} ${compact ? styles.compact : ''}`}
                            title={!text ? texts.remove : ''}
                            disabled={disabled}
                        />
                    );
            }}
        />
    );
}

interface RemoveButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    intent?: Intent;
    compact?: 'icon' | 'button' | 'button-no-icon';
    icon?: IconName;
    text?: string;
    what: string;
    actionName?: string;
    onClick: () => void;
    small?: boolean;
}
