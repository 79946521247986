import React from "react";
import { observer } from "mobx-react";
import styles from "./WorkResult.module.scss";
import { CategoryBlock } from "./CategoryBlock";
import { CollapseExpandAll } from "modules/common/components/table/CollapseExpandAll";
import { UnregisterCallback } from "history";
import { WorkResultStoreType } from "modules/orders-manage/models/order-work-result";
import { OrderFinancesStoreType } from "modules/orders-manage/models/order-finances-store";
import { OrderTimeline } from "../common/OrderTimeline";

class WorkResult extends React.Component<WorkResultProps> {
    private unregister: UnregisterCallback | null = null;

    componentDidMount() {
        document.title = "Результаты работ";
        this.props.financeStore.loadTimeline(this.props.orderId);
    }

    render() {
        const { store, baseUrl, readOnly, orderId, financeStore } = this.props;
        const categories = store.data.categories;
        const oldCategories = store.oldData.categories;
        const categoryCollapser = { ...store.categoryCollapser.plain };
        const collapseAll = (state: boolean) => {
            store.categoryCollapser.setAll(state);
            store.unitsCollapser.setAll(state);
        };

        return (
            <div className={styles.content}>
                <div className="planr-tools">
                    <CollapseExpandAll onChange={collapseAll} />
                </div>

                <div className={styles.page}>
                    <div className="order-timeline-wrapper">
                        <OrderTimeline events={financeStore.timeline} isCollapsed={false} />
                    </div>
                    {!store.data.loading && (
                        <div className={styles.contents}>
                            {categories.map((category, index) => {
                                const objects = category.objects;
                                const oldObjects = oldCategories[index].objects;
                                return (
                                    <CategoryBlock
                                        baseUrl={baseUrl}
                                        key={index}
                                        canDownload={store.data.canClientDownloadTasks}
                                        category={category.name}
                                        store={store}
                                        collapsed={!categoryCollapser[category.name]}
                                        onToggleCollapse={() => {
                                            store.categoryCollapser.toggle(category.name);
                                        }}
                                        objects={objects}
                                        oldObjects={oldObjects}
                                        orderId={orderId}
                                        readOnly={readOnly}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {store.data.loading && <h3>Подготовка данных. Ожидайте...</h3>}
                </div>
            </div>
        );
    }
}
// export function getSelectedOrderId(pathname: string) {
//     const match = matchPath<WorkResultState>(pathname, routes.orders.workResult(undefined));
//     return match && match.params ? match.params.orderId : "";
// }
export const WorkResultPage = observer(WorkResult);

interface WorkResultProps {
    store: WorkResultStoreType;
    baseUrl: string;
    readOnly: boolean;
    orderId: string;
    financeStore: OrderFinancesStoreType;
}

interface WorkResultState {
    orderId: string;
}
