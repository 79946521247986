import React from "react";
import { fields as passportFields } from "../../models/passport";
import { InputGroup, Classes } from "@blueprintjs/core";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import styles from "./OutsourcerDetails.module.scss";
import { Schema } from "yup";

interface PassportDetailsProps extends PassportDetailsBaseProps {
  fields: FieldsType;
  maxIssueDate?: Date;
}

interface PassportDetailsBaseProps {
  schema: Schema<any>;
  inline?: boolean;
  readOnly?: boolean;
}

interface PassportDetailsFieldProps extends PassportDetailsBaseProps {
  name: string;
}

type FieldsType = ReturnType<typeof passportFields>;

export const PassportBirthDay = ({ name, schema, readOnly, inline }: PassportDetailsFieldProps) => (
  <StandardFormInput name={name} schema={schema} small={true} className="planr-form-input" inline={inline}>
    {({ field, form }) => {
      return (
        <div className={`${Classes.INPUT_GROUP} `}>
          <DatePicker
            clasName="planr-default-input"
            value={field.value}
            onChange={(date) => {
              form.setFieldValue(field.name, date);
              form.setFieldTouched(field.name, true);
            }}
            disabled={readOnly}
          />
        </div>
      );
    }}
  </StandardFormInput>
);

export const PassportDetailsFields = ({ schema, fields, maxIssueDate, readOnly, inline }: PassportDetailsProps) => {
  return (
    <>
      <h3 className={styles.h3Label}>Паспортные данные</h3>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <StandardFormInput
          name={fields.series}
          schema={schema}
          small={true}
          className="planr-form-input"
          inline={inline}
        >
          {({ field }) => (
            <InputGroup
              id={field.name}
              {...field}
              className="planr-default-input"
              autoComplete="off"
              disabled={readOnly}
            />
          )}
        </StandardFormInput>
        &nbsp;
        <StandardFormInput
          name={fields.number}
          schema={schema}
          small={true}
          className="planr-form-input"
          inline={inline}
        >
          {({ field }) => (
            <InputGroup
              id={field.name}
              {...field}
              className="planr-default-input"
              autoComplete="off"
              disabled={readOnly}
            />
          )}
        </StandardFormInput>
      </div>

      <StandardFormInput name={fields.issuer} schema={schema} small={true} className="planr-form-input" inline={inline}>
        {({ field }) => (
          <InputGroup
            id={field.name}
            {...field}
            className="planr-default-input"
            autoComplete="off"
            disabled={readOnly}
          />
        )}
      </StandardFormInput>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <StandardFormInput
          name={fields.issueDate}
          schema={schema}
          small={true}
          className="planr-form-input"
          inline={false}
        >
          {({ field, form }) => {
            return (
              <div className={`${Classes.INPUT_GROUP} `}>
                <DatePicker
                  clasName="planr-default-input"
                  value={field.value}
                  onChange={(date) => {
                    form.setFieldValue(field.name, date);
                    form.setFieldTouched(field.name, true);
                  }}
                  maxDate={maxIssueDate}
                  disabled={readOnly}
                />
              </div>
            );
          }}
        </StandardFormInput>
        &nbsp;
        <StandardFormInput
          name={fields.issuerCode}
          schema={schema}
          small={true}
          className="planr-form-input"
          inline={inline}
        >
          {({ field }) => (
            <InputGroup
              id={field.name}
              {...field}
              className="planr-default-input"
              autoComplete="off"
              disabled={readOnly}
            />
          )}
        </StandardFormInput>
      </div>

      <StandardFormInput
        name={fields.regAddress}
        schema={schema}
        small={true}
        className="planr-form-input"
        inline={inline}
      >
        {({ field }) => (
          <InputGroup
            id={field.name}
            {...field}
            className="planr-default-input"
            autoComplete="off"
            disabled={readOnly}
          />
        )}
      </StandardFormInput>
    </>
  );
};
