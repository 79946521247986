import React from "react";
import { CalendarEventDayType, EventDayType } from "modules/main/models/calendar-event-store";
import { observer } from "mobx-react";
import styles from "./CalendarDay.module.scss";
import classnames from "classnames";

import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { texts } from "modules/common/texts";

const HEIGHT = 18;

class Day extends React.Component<CalendarDayProps> {
  render() {
    const { day, selected, events, index, daysCount } = this.props;

    const style = classnames({
      Calendar_Day: true,
      [styles.day]: true,
      [styles.isDayOff]: day.isDayOff,
      [styles.isOtherMonth]: day.isOtherMonth,
      [styles.isToday]: day.isToday,
      [styles.topLeft]: index === 0,
      [styles.topRight]: index === 6,
      [styles.bottomLeft]: index === daysCount - 7,
      [styles.bottomRight]: index === daysCount - 1,
    });

    return (
      <div className={style} onClick={this.onSelect} data-day={day.day}>
        <div className={`${styles.number} ${day.isOtherMonth ? styles.other : ""}`}>{day.dayOfMonth}</div>
        {selected && <div className={styles.selection} />}
        {day.isToday && <div className={styles.todaySelection} />}
        {events
          .filter((e) => e.event.offcet < 3)
          .map((e) => {
            const startedToday = e.days[0].day === day.day;
            const endedToday = e.days[e.days.length - 1].day === day.day;
            const eventStyle = classnames({
              [styles.event]: true,
              [styles.start]: startedToday,
              [styles.end]: endedToday,
            });
            const top = (e.event.offcet + 1) * (HEIGHT + 1) + 10;
            return (
              <React.Fragment key={e.event.id}>
                <div
                  className={eventStyle}
                  style={{
                    top: `${top}px`,
                    backgroundColor: e.event.color,
                    height: `${HEIGHT}px`,
                    color: "#00273d",
                    fontSize: "14px",
                  }}
                >
                  <div className={styles.stripe} style={{ backgroundColor: e.event.color }} />
                </div>

                {startedToday && (
                  <div
                    onClick={this.onNameClick}
                    className={styles.name}
                    style={{
                      width: `${150}px`,
                      top: `${top}px`,
                      height: `${HEIGHT}px`,
                    }}
                  >
                    <span className={styles.title}>{e.event.name}</span>

                    {!e.event.readonly && (
                      <RemoveConfirmation<CalendarEventDayType>
                        what={() => (e.event.iamAnAuthor ? `событие ${e.event.name}` : `от события ${e.event.name}`)}
                        onConfirmed={this.onRemove}
                        actionName={e.event.iamAnAuthor ? "удалить" : "отказаться"}
                        render={({ confirmRemoving }) => {
                          return (
                            <GeneralIcon
                              type="general-cross-big"
                              className="red-icon"
                              onClick={(m) => {
                                m.stopPropagation();
                                confirmRemoving(e);
                              }}
                              size={12}
                            />
                          );
                        }}
                        removeOptions={
                          e.event.iamAnAuthor
                            ? [
                                {
                                  id: "for-all",
                                  label: `${texts.remove} для всех участников`,
                                },
                              ]
                            : undefined
                        }
                      />
                    )}
                  </div>
                )}
              </React.Fragment>
            );
          })}
      </div>
    );
  }

  onNameClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const elements = document.elementsFromPoint(e.clientX, e.clientY);

    const day: HTMLDivElement | undefined = elements.find((el) =>
      typeof el.className === "string" ? el.className.includes("Calendar_Day") : false
    ) as any;
    if (day) {
      this.props.onClick(day.dataset.day || "");
    }
  };

  onSelect = () => this.props.onClick(this.props.day.day);

  onRemove = (e: CalendarEventDayType, option?: string) => {
    return this.props.onRemove(e, option === "for-all");
  };
}

export const CalendarDay = observer(Day);

interface CalendarDayProps {
  onRemove: (e: CalendarEventDayType, forAll: boolean) => void;
  events: CalendarEventDayType[];
  day: EventDayType;
  daysCount: number;
  onClick: (day: string) => void;
  selected: boolean;
  index: number;
}
