import React from "react";
import { Page } from "modules/common/components/page/Page";
import styles from "./AuthPage.module.scss";
import { AuthFormProps, AuthForm } from "./AuthForm";
import { AppLogo } from "modules/root/components/logo/AppLogo";

export const AuthPage = ({ store }: AuthPageProps) => {
    return (
        <Page className={styles.page}>
            <div className={styles.wrapper}>
                <AppLogo />

                <AuthForm store={store} />
            </div>
        </Page>
    );
};

interface AuthPageProps extends AuthFormProps {}
