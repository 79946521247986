import React from "react";
import { SessionType } from "modules/session/auth/models/session";
import { Icon, Menu, Classes, ContextMenu } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { withRouter, RouteComponentProps } from "react-router";
import { routes } from "modules/common/routes";
import { EmptySquare } from "modules/common/components/icons/square";
import { History } from "history";
import func from "modules/session/functionalities";
import internalFunc from "modules/main/functionalities";
import { can, canAny } from "modules/session/auth/access";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import styles from "./User.module.scss";

class Me extends React.Component<UserProps & RouteComponentProps> {
  render() {
    const { store } = this.props;

    return (
      <div className={`app-user centered planr-user`}>
        {store.tgBotLink && (
          <a href={store.tgBotLink} title="Регистрация в чат-боте">
            <i
              className={`fa fa-telegram`}
              style={{
                fontSize: "40px",
                marginRight: "20px",
                color: "#279FDA",
              }}
              aria-hidden="true"
            />
          </a>
        )}

        <div className="user-details">
          <div style={{ color: "#00273d" }}>{store.userName} </div>
          <div className="user-details-post">{store.userLogin}</div>
        </div>
        {store.userAvatar ? (
          <img src={store.userAvatar} alt={"avatar"} className={styles.avatarImage} />
        ) : (
          <Icon icon="user" iconSize={44} />
        )}

        <GeneralIcon
          type="general-settings-dots"
          className={`app-user centered planr-settingsicon`}
          onClick={this.showMenu}
          size={50}
        />
      </div>
    );
  }

  showMenu = (e: React.MouseEvent<SVGSVGElement>) => {
    const menu = this.renderContextMenu();
    ContextMenu.show(menu, { left: e.clientX, top: e.clientY + 12 });
  };

  public renderContextMenu() {
    return <UserMenu {...this.props} />;
  }
}

export const ACCESS_MENU_LABEL = "Настройки доступа";

const UserMenu = observer(
  class extends React.Component<UserMenuProps> {
    render() {
      const { access } = this.props.store;

      return (
        <Menu className={Classes.ELEVATION_1} style={{ fontFamily: "GothamPro, Arial, sans-serif" }}>
          {can(func.SETTINGS_EDIT, access) && (
            <Menu.Item icon={<Icon icon="cog" />} onClick={this.gotoSettings} text="Настройки приложения" />
          )}

          {canAny([internalFunc.INTERNAL_USER_ACCESS, func.ACCESS_EDIT], access) && (
            <Menu.Item
              icon="lock"
              onClick={this.gotoAccess}
              text={can(func.ACCESS_EDIT, access) ? ACCESS_MENU_LABEL : "Таблица разрешений"}
            />
          )}

          {can(func.TOKENS_CONFIRMATION, access) && (
            <Menu.Item icon="desktop" onClick={this.gotoTokens} text="Подтверждение устройств" />
          )}

          <Menu.Item icon={<EmptySquare />} onClick={this.logOut} text="Выход" />
        </Menu>
      );
    }

    gotoAccess = () => {
      this.props.history.push(routes.access);
    };

    gotoSettings = () => {
      this.props.history.push(routes.settings);
    };

    gotoTokens = () => {
      this.props.history.push(routes.tokens.outer);
    };

    logOut = async () => {
      if (await this.props.store.logOut()) {
        this.props.onLogout();
      }
    };
  }
);

interface UserMenuProps {
  store: SessionType;
  history: History;
  onLogout: () => void;
}

interface UserProps {
  store: SessionType;
  onLogout: () => void;
}

export const User = withRouter(observer(Me));
