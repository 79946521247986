import React from "react";
import { Formik, Form } from "formik";
import styles from "./MaterialValueDetails.module.scss";
import MaterialValueSchema from "./material-validation";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { InputGroup, TextArea, Classes } from "@blueprintjs/core";
import { MaterialValueType, fields } from "../../models/material-value";
import { fixTextAreaHeight, MODAL_AWAIT_DELAY } from "modules/common/services/form/textarea";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { Schema } from "yup";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { Prompt } from "react-router";
import { texts } from "modules/common/texts";
import { onlyDigitsInputKeyPress } from "modules/common/services/form/values";
import {
    DefaultSelectedOption,
    SimpleSelect,
    renderSingleOption,
    buildOptions,
} from "modules/common/services/form/select";
import { CategoryDictionaryType } from "../../models/category-dictionary";
import { DeprGroupDictionaryType } from "../../models/depr-group-dictionary";
import { EmployerSelect } from "modules/common/components/form/EmployerSelect";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { EmployerDictionaryType } from "modules/spending/employee/models/employee-dictionary";
import { DatePicker } from "modules/common/components/form/DatePicker";
import moment from "moment";
import { DATE_TIME_FORMAT } from "modules/common/constants";

const sum = onlyDigitsInputKeyPress(true, false);
const digits = onlyDigitsInputKeyPress();

class MaterialValueDetailsBase extends React.PureComponent<MaterialValueDetailsProps> {
    private schema: Schema<any>;
    private commentArea: HTMLTextAreaElement | null = null;
    private descriptionArea: HTMLTextAreaElement | null = null;

    constructor(props: MaterialValueDetailsProps) {
        super(props);

        this.schema = MaterialValueSchema();
    }

    private commentAreaRef = (ref: HTMLTextAreaElement | null) => {
        this.commentArea = ref;
    };

    private descriptionAreaRef = (ref: HTMLTextAreaElement | null) => {
        this.descriptionArea = ref;
    };

    private resize = () => {
        fixTextAreaHeight(this.commentArea);
        fixTextAreaHeight(this.descriptionArea);
    };

    public componentDidMount() {
        const { categories, deprGroups, employers } = this.props;
        setTimeout(this.resize, MODAL_AWAIT_DELAY);
        categories.load();
        deprGroups.load();
        employers.load();
    }

    public componentDidUpdate() {
        this.resize();
    }

    render() {
        const { schema, props } = this;
        const { materialValue, onSaved, canChange, categories, onPrintCredentials } = props;
        const { category, deprGroups, employers } = props;
        const positionCategories = buildOptions(null, categories.data);

        return (
            <div className={styles.details}>
                <Formik
                    initialValues={getFormValues(materialValue, category)}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        if (!canChange) {
                            return;
                        }

                        const succsessfull = await materialValue.save(values);
                        setSubmitting(false);

                        if (succsessfull) {
                            resetForm();
                            onSaved(category);
                        }
                    }}
                >
                    {(formProps) => {
                        const onRemove = async () => {
                            formProps.setSubmitting(true);
                            const success = await materialValue.delete();
                            formProps.setSubmitting(false);
                            if (success) {
                                formProps.resetForm();
                                onSaved(category);
                            }
                        };
                        const savedEmployer = formProps.values.currentEmployer
                            ? {
                                  id: formProps.values.currentEmployer.id,
                                  label: formProps.values.currentEmployer.name,
                              }
                            : null;

                        const savedDeprGroup = formProps.values.depreciationGroup
                            ? {
                                  id: formProps.values.depreciationGroup.id,
                                  label: formProps.values.depreciationGroup.name,
                              }
                            : null;

                        const positionDeprGroups = buildOptions(savedDeprGroup, deprGroups.data);

                        return (
                            <Form autoComplete="off">
                                <Prompt
                                    when={formProps.dirty && canChange}
                                    message={texts.messages.leaveConfiramtion}
                                />
                                <div className={Classes.DIALOG_BODY}>
                                    <StandardFormInput
                                        name={fields.materialValueCategoryId}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field, form }) => {
                                            let option = positionCategories[field.value];
                                            return (
                                                <div className={`${Classes.INPUT_GROUP}`}>
                                                    <SimpleSelect
                                                        className={`${Classes.FILL} planr-default-input`}
                                                        filterable={false}
                                                        activeItem={option}
                                                        inputProps={field}
                                                        items={Object.values(positionCategories)}
                                                        itemRenderer={renderSingleOption}
                                                        onItemSelect={(item) => {
                                                            form.setFieldValue(field.name, item.id);
                                                            form.setFieldTouched(field.name, true);
                                                        }}
                                                        disabled={!canChange || categories.isEmpty}
                                                    >
                                                        <DefaultSelectedOption
                                                            option={option}
                                                            empty={categories.isEmpty}
                                                            disabled={!canChange}
                                                        />
                                                    </SimpleSelect>
                                                </div>
                                            );
                                        }}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.customInventoryNumber}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <InputGroup
                                                id={field.name}
                                                {...field}
                                                type="text"
                                                className="planr-default-input"
                                                autoComplete="off"
                                                data-lpignore="true"
                                                placeholder="Оставьте пустым для автоматического назначения"
                                                disabled={!canChange}
                                            />
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.name}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <InputGroup
                                                id={field.name}
                                                {...field}
                                                type="text"
                                                className="planr-default-input"
                                                autoComplete="off"
                                                data-lpignore="true"
                                                disabled={!canChange}
                                            />
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.sum}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <InputGroup
                                                id={field.name}
                                                {...field}
                                                className="planr-default-input"
                                                autoComplete="off"
                                                data-lpignore="true"
                                                onKeyPress={sum}
                                                disabled={!canChange}
                                            />
                                        )}
                                    </StandardFormInput>
                                    <StandardFormInput
                                        name={fields.startDate}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field, form }) => {
                                            return (
                                                <div className={`${Classes.INPUT_GROUP} `}>
                                                    <DatePicker
                                                        clasName="planr-default-input"
                                                        value={field.value}
                                                        onChange={(date) => {
                                                            form.setFieldValue(field.name, date ?? null, true);
                                                        }}
                                                        name={fields.startDate}
                                                        disabled={!canChange}
                                                    />
                                                </div>
                                            );
                                        }}
                                    </StandardFormInput>
                                    <StandardFormInput
                                        name={fields.depreciationGroupId}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field, form }) => {
                                            let option = positionDeprGroups[field.value];

                                            return (
                                                <div className={`${Classes.INPUT_GROUP}`}>
                                                    <SimpleSelect
                                                        className={`${Classes.FILL} planr-default-input`}
                                                        filterable={false}
                                                        activeItem={option}
                                                        inputProps={field}
                                                        items={Object.values(positionDeprGroups)}
                                                        itemRenderer={renderSingleOption}
                                                        onItemSelect={(item) => {
                                                            form.setFieldValue(field.name, item.id);
                                                            form.setFieldTouched(field.name, true);
                                                            form.setFieldValue(
                                                                fields.lifeTime,
                                                                findDeprGroupLifetime(item.id, deprGroups)
                                                            );
                                                            form.setFieldTouched(fields.lifeTime, true);
                                                        }}
                                                        disabled={!canChange || deprGroups.isEmpty}
                                                    >
                                                        <DefaultSelectedOption
                                                            option={option}
                                                            empty={deprGroups.isEmpty}
                                                            disabled={!canChange}
                                                        />
                                                    </SimpleSelect>
                                                </div>
                                            );
                                        }}
                                    </StandardFormInput>
                                    <StandardFormInput
                                        name={fields.lifeTime}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <InputGroup
                                                id={field.name}
                                                {...field}
                                                type="text"
                                                className="planr-default-input"
                                                autoComplete="off"
                                                data-lpignore="true"
                                                onKeyPress={digits}
                                                disabled={!canChange}
                                            />
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.employerId}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {(fieldProps) => {
                                            const clear = () => {
                                                fieldProps.form.setFieldValue(fieldProps.field.name, "");
                                                fieldProps.form.setFieldTouched(fieldProps.field.name, true);
                                            };

                                            return (
                                                <div className={`${Classes.INPUT_GROUP}`}>
                                                    <EmployerSelect
                                                        saved={savedEmployer}
                                                        employee={employers.data}
                                                        field={fieldProps}
                                                        disabled={!canChange || employers.isEmpty}
                                                        onClear={clear}
                                                    />
                                                </div>
                                            );
                                        }}
                                    </StandardFormInput>
                                    <StandardFormInput
                                        name={fields.description}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                                                <TextArea
                                                    id={field.name}
                                                    {...field}
                                                    growVertically={true}
                                                    className={Classes.FILL}
                                                    inputRef={this.descriptionAreaRef}
                                                    disabled={!canChange}
                                                />
                                            </div>
                                        )}
                                    </StandardFormInput>
                                    <StandardFormInput
                                        name={fields.comment}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                                                <TextArea
                                                    id={field.name}
                                                    {...field}
                                                    growVertically={true}
                                                    className={Classes.FILL}
                                                    inputRef={this.commentAreaRef}
                                                    disabled={!canChange}
                                                />
                                            </div>
                                        )}
                                    </StandardFormInput>
                                    {!!materialValue.fullInventoryNumber && (
                                        <div className={styles.printCredentials}>
                                            <PlanrButton
                                                type="blueish"
                                                style={{ marginLeft: "180px", width: "430px" }}
                                                size="small"
                                                onClick={() => onPrintCredentials(materialValue)}
                                            >
                                                Распечатать инвентарный номер
                                            </PlanrButton>
                                        </div>
                                    )}
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    {canChange && (
                                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                            <StandardFormButtons
                                                {...formProps}
                                                isRemoved={false}
                                                what={`должность ${materialValue.name}`}
                                                isNewlyCreated={materialValue.isNewlyCreated}
                                                onRemove={onRemove}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

export const MaterialValueDetails = observer(MaterialValueDetailsBase);

interface MaterialValueDetailsProps {
    materialValue: MaterialValueType;
    onSaved: (id: string) => void;

    canChange: boolean;
    categories: CategoryDictionaryType;
    deprGroups: DeprGroupDictionaryType;
    employers: EmployerDictionaryType;
    category: string;
    onPrintCredentials: (materialValue: MaterialValueType) => void;
}

function findDeprGroupLifetime(id: string, deprGroups: DeprGroupDictionaryType) {
    const group = deprGroups.asMap[id];
    return group ? group.maxLifetime : 0;
}

function getFormValues(material: MaterialValueType, category: string) {
    return {
        ...getSnapshot(material),
        [fields.materialValueCategoryId]: material.materialValueCategoryId || category,
        [fields.depreciationGroupId]: material.depreciationGroupId,
        [fields.employerId]: material.employerId,
        [fields.startDate]: material.startDate ? moment(material.startDate, DATE_TIME_FORMAT).toDate() : null,
    };
}
