import { types, applySnapshot, cast } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { DictionaryLink, initialState as emptyLink } from "modules/common/models/dictionary-link";
import { treatBase64, base64ToBlob } from "modules/common/services/files";
import { SectionCollapser } from "modules/common/models/section-collapser";
import { texts } from "modules/common/texts";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { saveAs } from "file-saver";

const FileLink = types
    .model({
        fileId: types.string,
        fileName: types.string,
    })
    .named("FileLink");

export const DocumentsLink = types
    .model({
        name: types.string,
        version: types.string,
        previewPages: types.array(types.string),
        comment: types.string,
        commentFiles: types.maybeNull(types.array(FileLink)),
    })
    .named("DocumentsLink");

export const UnitsLink = types
    .model({
        code: types.string,
        description: types.string,
        guid: types.string,
        documents: types.array(DocumentsLink),
    })
    .named("UnitsLink");

export const ObjectsLink = types
    .model({
        category: types.string,
        name: types.string,
        units: types.array(UnitsLink),
    })
    .named("ObjectsLink");

export const CategoriesLink = types
    .model({
        name: types.string,
        objects: types.array(ObjectsLink),
    })
    .named("CategoriesLink");

export const WorkResultLink = types
    .model({
        name: types.string,
        inventoryNumber: types.number,
        orderStatus: types.maybeNull(DictionaryLink),
        orderStatusColor: types.string,
        loading: types.boolean,
        categories: types.array(CategoriesLink),
        canClientDownloadTasks: types.boolean,
    })

    .named("WorkResultLink");

export const WorkResultStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            data: WorkResultLink,
            oldData: WorkResultLink,
            categoryCollapser: SectionCollapser,
            unitsCollapser: SectionCollapser,
        })
    )
    .actions((self) => ({
        load: flow(function* (id: string) {
            self.data.loading = true;

            try {
                if (id === EMPTY_OBJECT_ID) {
                    applySnapshot(self.data, { ...emptyWorkResultDictionary(), loading: true });
                    applySnapshot(self.oldData, { ...emptyWorkResultDictionary(), loading: true });
                    return;
                }

                const data: WorkResultLinkType = yield self.transport.get<any>(apiUrls.orders.workResult(id));
                applySnapshot(self.data, { ...data, loading: true });
                applySnapshot(self.oldData, { ...data, loading: true });
                if (data) {
                    self.data.categories.forEach((key) => {
                        self.categoryCollapser.set(key.name, false);
                        key.objects.forEach((object) => {
                            object.units.forEach((unit) => {
                                self.unitsCollapser.set(unit.code, false);
                            });
                        });
                    });
                }
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.data.loading = false;
            }
        }),
        downloadFile: flow(function* (id: string, fileId: string) {
            try {
                const result = yield self.transport.get<any>(apiUrls.orders.workResultFile(id, fileId), {
                    responseType: "arraybuffer",
                });
                if (result) {
                    const buff = Buffer.from(result, "binary").toString("base64");
                    const fimageSrc = treatBase64(buff, "image/jpeg");
                    return fimageSrc;
                }
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        downloadVersion: flow(function* (id: string, guid: string, version: number) {
            try {
                const result = yield self.transport.get<any>(
                    apiUrls.orders.contentTasks.downloadFilesVersion(id, guid, version)
                );
                if (result) {
                    const blob: any = yield base64ToBlob(result.content || "", result.mimeType);
                    saveAs(blob, result.name);
                }
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
        setComment: (guid: string, comment: string, version: string) => {
            self.data.categories.forEach((item) => {
                item.objects.forEach((object) => {
                    object.units.forEach((unit) => {
                        if (unit.guid === guid) {
                            unit.documents.forEach((doc) => {
                                if (doc.version === version) {
                                    doc.comment = comment;
                                }
                            });
                        }
                    });
                });
            });
        },
        saveComment: flow(function* (workGuid: string, comment: string, version: number, orderId: string) {
            try {
                const result = yield self.transport.post<any>(apiUrls.orders.workResultComment(orderId), {
                    comment,
                    workGuid,
                    version,
                });
                if (result) {
                    self.oldData.categories.forEach((item) => {
                        item.objects.forEach((object) => {
                            object.units.forEach((unit) => {
                                if (unit.guid === workGuid) {
                                    unit.documents.forEach((doc) => {
                                        if (+doc.version === version) {
                                            doc.comment = comment;
                                        }
                                    });
                                }
                            });
                        });
                    });
                    self.notify.success(texts.messages.saved);
                }
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
        uploadFile: flow(function* (file: File) {
            try {
                const model = new FormData();

                model.append("file", file);
                model.append("accept", "*");

                const result: any = yield self.transport.post<any>(apiUrls.application.files.upload, model);
                if (result) {
                    // self.notify.success("Файл загружен");
                    const { id, previewMimeType, mimeType } = result;

                    const fileBase: FileBase = { fileId: id, fileName: file.name, previewMimeType, mimeType };
                    return fileBase;
                }
            } catch (er) {
                self.notify.error(er);
                return null;
            }
        }),
        saveFileComment: flow(function* (
            orderId: string,
            workGuid: string,
            version: string,
            fileId: string,
            fileName: string,
            deleted: boolean
        ) {
            try {
                const result = yield self.transport.post<any>(apiUrls.orders.workResultCommentFile(orderId), {
                    fileId,
                    fileName,
                    workGuid,
                    version: +version,
                    deleted,
                });

                if (result) {
                    self.data.categories.forEach((item) => {
                        item.objects.forEach((object) => {
                            object.units.forEach((unit) => {
                                if (unit.guid === workGuid) {
                                    unit.documents.forEach((doc) => {
                                        if (doc.version === version) {
                                            if (!deleted) {
                                                if (doc.commentFiles !== null) {
                                                    doc.commentFiles.push({
                                                        fileId,
                                                        fileName,
                                                    });
                                                } else {
                                                    const file: FileLinkType = {
                                                        fileId,
                                                        fileName,
                                                    };
                                                    let arr: FileLinkType[] = [];
                                                    arr.push(file);
                                                    doc.commentFiles = cast(arr);
                                                }
                                            } else {
                                                if (doc.commentFiles !== null) {
                                                    const index = doc.commentFiles.findIndex(
                                                        (item) => item.fileId === fileId
                                                    );
                                                    doc.commentFiles.splice(index, 1);
                                                }
                                            }
                                        }
                                    });
                                }
                            });
                        });
                    });

                    self.notify.success(texts.messages.saved);
                }
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .named("WorkResultStore");

export const emptyWorkResultDictionary = (): WorkResultLinkSnapshotType => ({
    name: "",
    inventoryNumber: 0,
    orderStatus: emptyLink(),
    orderStatusColor: "",
    categories: [],
    loading: true,
    canClientDownloadTasks: false,
});

export const emptyWorkResultStore = (): WorkResultStoreSnapshotType => ({
    data: emptyWorkResultDictionary(),
    oldData: emptyWorkResultDictionary(),
    categoryCollapser: { opened: {} },
    unitsCollapser: { opened: {} },
});

export type FileLinkType = typeof FileLink.Type;
export type FileLinkSnapshotType = typeof FileLink.SnapshotType;

export type WorkResultStoreType = typeof WorkResultStore.Type;
export type WorkResultStoreSnapshotType = typeof WorkResultStore.SnapshotType;
export type WorkResultLinkType = typeof WorkResultLink.Type;
export type WorkResultLinkSnapshotType = typeof WorkResultLink.SnapshotType;
export type CategoriesLinkType = typeof CategoriesLink.Type;
export type CategoriesLinkSnapshotType = typeof CategoriesLink.SnapshotType;
export type ObjectsLinkType = typeof ObjectsLink.Type;
export type ObjectsLinkSnapshotType = typeof ObjectsLink.SnapshotType;
export type UnitsLinkType = typeof UnitsLink.Type;
export type UnitsLinkSnapshotType = typeof UnitsLink.SnapshotType;
export type DocumentsLinkType = typeof DocumentsLink.Type;
export type DocumentsLinkSnapshotType = typeof DocumentsLink.SnapshotType;

interface fileProps {
    fileId: string;
    fileName: string;
}
