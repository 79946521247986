import React from "react";
import { columns, requestAgentColumns } from "./columns";
import { saveAgGridColumnSize, AgGridSizeSaver } from "modules/common/services/table/size-storage";
import { DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import { OutsourcerSnapshotType, formatOutsourcer } from "modules/agents/outsourcers/models/outsourcer";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import styles from "./OutsourcersList.module.scss";
import { OutsourcerListType } from "../../models/outsourcer-list";
import { observer } from "mobx-react";
import { OutsourcerTableName } from "../../models/outsourcers-store";
import { agGridEntityContextMenu } from "modules/common/components/table/EntityContextMenu";
import copyToClipboard from "copy-to-clipboard";
import { AgGridReact } from "@ag-grid-community/react";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { agGridLocale } from "modules/root/services/locale";
import {
  RowClickedEvent,
  GetContextMenuItemsParams,
  GridReadyEvent,
  CellContextMenuEvent,
} from "@ag-grid-community/core";
import { GridTheme } from "modules/common/components/table/GridTheme";

const modules = [...defaultModules, MenuModule];

export const OutsourcersList = observer(
  class extends React.Component<OutsourcersListProps> {
    private sizeSaver: AgGridSizeSaver;
    private options = DefaultAgGridOptions();

    constructor(props: OutsourcersListProps) {
      super(props);
      const tableName = OutsourcerTableName;
      this.sizeSaver = saveAgGridColumnSize(tableName);
    }

    render() {
      const { onOutsourcerSelect, store, isRequestAgent } = this.props;
      const tableName = OutsourcerTableName;
      const config = isRequestAgent ? requestAgentColumns(tableName) : columns(tableName);

      return (
        <RemoveConfirmation<OutsourcerSnapshotType>
          observation={store.data}
          what={this.removeLabel}
          onConfirmed={this.removeOutsourcer}
          render={({ confirmRemoving }) => {
            const getContextMenuItems = ({ node }: GetContextMenuItemsParams) =>
              agGridEntityContextMenu({
                onCopy: () => copyToClipboard(formatOutsourcer(node.data), { format: "text/plain" }),
                onChange: () => onOutsourcerSelect(getRowNodeId(node.data)),
                onRemove: () => confirmRemoving(node.data),
              });

            return (
              <GridTheme className={styles.grid} style={isRequestAgent ? { marginTop: "0px" } : {}}>
                <AgGridReact
                  rowStyle={{
                    borderBottom: "1 px solid #EDF0F2",
                    fontFamily: "GothamPro, Arial, sans-serif",
                    fontWeight: "300",
                    color: "#00273D",
                    fontSize: "12px",
                  }}
                  rowSelection="single"
                  suppressCellSelection={true}
                  enableCellTextSelection={true}
                  columnDefs={config}
                  rowData={store.data}
                  localeText={agGridLocale}
                  onRowDoubleClicked={this.onRowSelected}
                  getRowNodeId={getRowNodeId}
                  getContextMenuItems={getContextMenuItems}
                  onGridReady={this.onGridReady}
                  onCellContextMenu={this.onCellContextMenu}
                  onSortChanged={store.sorter.resorted}
                  onColumnResized={this.sizeSaver}
                  gridOptions={this.options}
                  modules={modules}
                  immutableData={true}
                />
              </GridTheme>
            );
          }}
        />
      );
    }

    removeLabel = (outsourcer: OutsourcerSnapshotType) => `аутсорсера ${outsourcer.name}`;

    onRowSelected = ({ data }: RowClickedEvent) => {
      const { onOutsourcerSelect } = this.props;
      onOutsourcerSelect(getRowNodeId(data));
    };

    removeOutsourcer = async (outsourcer: OutsourcerSnapshotType) => {
      const { store, onOutsourcerRemoved } = this.props;
      const success = await store.delete(outsourcer);
      success && onOutsourcerRemoved();
    };

    onCellContextMenu = ({ node }: CellContextMenuEvent) => {
      node && node.setSelected(true, true);
    };

    onGridReady = async ({ api }: GridReadyEvent) => {
      const { sorter } = this.props.store;
      setTimeout(() => api.setSortModel(sorter.agGridSortModel));
      api.resetRowHeights();
    };
  }
);

const getRowNodeId = (node: OutsourcerSnapshotType) => node.id;

interface OutsourcersListProps {
  onOutsourcerRemoved: () => void;
  onOutsourcerSelect: (id: string) => void;
  store: OutsourcerListType;
  isLegalEntities?: boolean;
  isRequestAgent?: boolean;
}
