import { types, applySnapshot, isAlive } from "mobx-state-tree";
import { isNewlyCreated, CategoryDictionaryItem as CategoryDItem } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import Schema from "../components/details/category-validation";

import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";
import { getFieldLabel } from "modules/common/services/form/fields";

export const CategoryDictionaryItem = types
    .compose(
        CategoryDItem,
        types.model({
            order: types.maybeNull(types.number),
            name: types.string,
        })
    )
    .views((self) => ({
        get label() {
            return self.name;
        },
    }))
    .named("CategoryDictionaryItem");

const CategoryBase = types.compose(
    Transport,
    Notificator,
    types
        .model({
            id: types.string,
            inventoryNumber: types.number,
            fullInventoryNumber: types.string,
            name: types.string,
            sum: types.number,
            usedItemsCount: types.number,
            freeItemsCount: types.number,
            totalItemsCount: types.number,
        })
        .views((self) => ({
            get isNewlyCreated() {
                return isNewlyCreated(self.id);
            },
        }))
);

export const Category = CategoryBase.actions((self) => ({
    load: flow(function* (id: string) {
        try {
            const snapshot = isNewlyCreated(id)
                ? initialState()
                : yield self.transport.get<CategorySnapshotType>(apiUrls.categories.details(id));

            applySnapshot(self, snapshot);
        } catch (er) {
            self.notify.error(er);
        }
    }),

    save: flow(function* (model: any) {
        try {
            const snapshot = self.isNewlyCreated
                ? yield self.transport.put<CategorySnapshotType>(apiUrls.categories.create(), { ...model })
                : yield self.transport.post<CategorySnapshotType>(apiUrls.categories.update(self.id), {
                      ...model,
                  });

            isAlive(self) && applySnapshot(self, snapshot);
            self.notify.success(texts.messages.saved);

            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    delete: flow(function* () {
        if (self.isNewlyCreated) {
            return true;
        }

        try {
            yield self.transport.delete<boolean>(apiUrls.categories.delete(self.id));
            self.notify.success(texts.messages.removed);

            isAlive(self) && applySnapshot(self, initialState());
            return true;
        } catch (er) {
            self.notify.error(er);
            return false;
        }
    }),
})).named("Category");

export type CategoryDictionaryItemType = typeof CategoryDictionaryItem.Type;
export type CategorySnapshotType = typeof CategoryBase.SnapshotType;
export type CategoryType = typeof Category.Type;

export const initialState = (): CategorySnapshotType => ({
    id: EMPTY_OBJECT_ID,
    inventoryNumber: 0,
    fullInventoryNumber: "",
    name: "",
    sum: 0,
    usedItemsCount: 0,
    freeItemsCount: 0,
    totalItemsCount: 0,
});

export const fields = {
    inventoryNumber: nameof((a: CategoryType) => a.inventoryNumber) as string,
    fullInventoryNumber: nameof((a: CategoryType) => a.fullInventoryNumber) as string,
    name: nameof((a: CategoryType) => a.name) as string,
    sum: nameof((a: CategoryType) => a.sum) as string,
    usedItemsCount: nameof((a: CategoryType) => a.usedItemsCount) as string,
    freeItemsCount: nameof((a: CategoryType) => a.freeItemsCount) as string,
    totalItemsCount: nameof((a: CategoryType) => a.totalItemsCount) as string,
};

export function formatCategory(stage: CategorySnapshotType | null) {
    let result = "";

    if (stage) {
        const schema = Schema();

        result += `${getFieldLabel(fields.name, schema, null)}: ${stage.name}\n`;
    }

    return result;
}
