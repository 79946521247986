import { types } from "mobx-state-tree";
import { nameof } from "modules/common/services/typescript";

export const FinanceValues = types
    .model({
        overhead: types.number,
        production: types.number,
        totalProduction: types.number,
        premiums: types.number,
        profitability: types.number,
        percents: types.map(types.number),
        price: types.number,
    })
    .named("FinanceValues");

export const OrderFinances = types
    .model({
        actual: types.compose(
            FinanceValues,
            types.model({
                waitingQuarter: types.string,
                fixedProduction: types.number,
                fixedPremiums: types.number,
            })
        ),
        plan: FinanceValues,
        today: FinanceValues,
    })
    .named("OrderFinances");

export interface SaveFinanceValues {
    premiumPercents: number;
    planProductionSpendingsPercents: number;
}

export const FinanceValuesFields = {
    premiumPercents: nameof((a: SaveFinanceValues) => a.premiumPercents) as string,
    planProductionSpendingsPercents: nameof((a: SaveFinanceValues) => a.planProductionSpendingsPercents) as string,
};

export const initialFinances = (): typeof OrderFinances.SnapshotType => ({
    plan: initialValues(),
    today: initialValues(),
    actual: {
        ...initialValues(),
        waitingQuarter: "",
        fixedProduction: 0,
        fixedPremiums: 0,
    },
});

export const initialValues = (): typeof FinanceValues.SnapshotType => ({
    overhead: 0,
    premiums: 0,
    price: 0,
    production: 0,
    totalProduction: 0,
    profitability: 0,
    percents: {
        overhead: 0,
        premiums: 0,
        price: 0,
        production: 0,
        profitability: 0,
    },
});
