import React from "react";
import { FinanceValuesSnapshotType } from "modules/orders-manage/models/order";
import { Popover, Classes, HTMLTable } from "@blueprintjs/core";
import { PlanrTag } from "modules/common/components/planr/tag/PlanrTag";
import { Money } from "modules/common/components/money/Money";
import { prettyRound } from "modules/common/services/formatting/numbers";
import styles from "./FinanceValuesDetails.module.scss";

const financeMoneyStyles: React.CSSProperties = {
    minWidth: "100px",
};

export function FinanceValuesDetails(financeValues: FinanceValuesSnapshotType, target: any) {
    return (
        <Popover
            position="left"
            usePortal={true}
            enforceFocus={true}
            interactionKind="hover-target"
            content={
                <div
                    style={{ backgroundColor: "white", padding: "1rem" }}
                    className={`${Classes.ELEVATION_1} ${styles.popover}`}
                >
                    <p>
                        <PlanrTag type="success">
                            Сумма: <Money amount={financeValues.price} />
                        </PlanrTag>
                    </p>
                    <HTMLTable condensed={true}>
                        <tbody>
                            <tr>
                                <td>Накл. расходы:</td>
                                <td className="righted">
                                    <strong>{prettyRound(financeValues.percents.overhead)}%</strong>
                                </td>
                                <td style={financeMoneyStyles} className="righted">
                                    <Money amount={financeValues.overhead} />
                                </td>
                            </tr>
                            <tr>
                                <td>Произв. затраты:&nbsp;</td>
                                <td className="righted">
                                    <strong>{prettyRound(financeValues.percents.totalProduction)}%</strong>
                                </td>
                                <td style={financeMoneyStyles} className="righted">
                                    <Money amount={financeValues.production} />
                                </td>
                            </tr>
                            <tr>
                                <td>Рентабельность:</td>
                                <td className="righted">
                                    <strong>{prettyRound(financeValues.percents.profitability)}%</strong>
                                </td>
                                <td style={financeMoneyStyles} className="righted">
                                    <Money amount={financeValues.profitability} />
                                </td>
                            </tr>
                        </tbody>
                    </HTMLTable>
                </div>
            }
        >
            {target}
        </Popover>
    );
}
