import { types } from "mobx-state-tree";
import moment from "moment";
import { DATE_TIME_FORMAT } from "modules/common/constants";
import { IdEntity } from "modules/common/models/entity";
import { nameof } from "modules/common/services/typescript";
import { Constants } from "modules/root/models/constants";
import { formatMoney } from "modules/common/components/money/Money";
import { orderTitle } from "modules/orders-manage/models/order";
import { ExtendedOrderLink } from "modules/orders-manage/models/order-link";
import { InvoiceFile } from "modules/orders-manage/models/order-file";
import { DictionaryLink } from "modules/common/models/dictionary-link";
import { OutsourcerDictionaryItem } from "modules/agents/outsourcers/models/outsourcer-dictionary";
import { SupplierDictionaryItem } from "modules/agents/suppliers/models/supplier";

export const FinanceRequest = types
    .compose(
        IdEntity,
        types.model({
            sum: types.number,
            date: types.string,
            sortableDate: types.string,
            workType: types.maybeNull(DictionaryLink),
            order: types.maybeNull(ExtendedOrderLink),
            outsourcer: types.maybeNull(OutsourcerDictionaryItem),
            supplier: types.maybeNull(SupplierDictionaryItem),
            orderGipName: types.maybeNull(types.string),
            status: types.string,
            taskStatus: types.string,
            isCash: types.boolean,
            immediate: types.boolean,
            workTypeName: types.string,
            invoice: types.maybeNull(types.array(InvoiceFile)),
            isProduction: types.boolean,
            comment: types.string,
        })
    )
    .views((self) => ({
        get dateAsDate() {
            return moment(self.date, DATE_TIME_FORMAT);
        },

        get projectName() {
            return self.order ? orderTitle(self.order) : "";
        },

        get outsourcerName() {
            return self.outsourcer?.label || "";
        },

        get money() {
            return self.sum.toString();
        },
    }))
    .actions((self) => ({
        found(query: string) {
            return (
                self.projectName.toLowerCase().includes(query) ||
                self.workTypeName.toLowerCase().includes(query) ||
                self.outsourcerName.toLowerCase().includes(query) ||
                (self.orderGipName || "").toLowerCase().includes(query) ||
                self.money.includes(query)
            );
        },
    }))
    .named("FinanceRequest");

export const getRowNodeId = (row: FinanceRequestSnapshotType) => row.id;

export function removeConfiramtionName(row: FinanceRequestSnapshotType) {
    const money = formatMoney(row.sum, { noFraction: true });

    if (row.status === Constants.orderPaymentStatusPaid) {
        return `платеж на ${money}`;
    }

    return `финансовый запрос на ${money}`;
}

export function removeConfiramtionAction(row: FinanceRequestSnapshotType) {
    if (row.status === Constants.orderPaymentStatusPaid) {
        return undefined;
    }

    return "отменить";
}

export type FinanceRequestType = typeof FinanceRequest.Type;
export type FinanceRequestSnapshotType = typeof FinanceRequest.SnapshotType;

export const fields = {
    sum: nameof((s: FinanceRequestType) => s.sum) as string,
    date: nameof((s: FinanceRequestType) => s.date) as string,
    workTypeName: nameof((s: FinanceRequestType) => s.workTypeName) as string,
    taskStatus: nameof((s: FinanceRequestType) => s.taskStatus) as string,
    outsourcer: nameof((s: FinanceRequestType) => s.outsourcer) as string,
    supplier: nameof((s: FinanceRequestType) => s.supplier) as string,
    invoice: nameof((s: FinanceRequestType) => s.invoice) as string,
    order: nameof((s: FinanceRequestType) => s.order) as string,
    orderGipName: nameof((s: FinanceRequestType) => s.orderGipName) as string,
    isCash: nameof((s: FinanceRequestType) => s.isCash) as string,
    immediate: nameof((s: FinanceRequestType) => s.immediate) as string,
    id: nameof((s: FinanceRequestType) => s.id) as string,
    comment: nameof((s: FinanceRequestType) => s.comment) as string,
    status: nameof((s: FinanceRequestType) => s.status) as string,
};
