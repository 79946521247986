import React from "react";
import { WorkloadStoreType, DepartmentBlockMode } from "modules/spending/workload/models/workload-store";
import { observer } from "mobx-react";
import { DepartmentBlock } from "./DepartmentBlock";
import styles from "./WorkloadPage.module.scss";
import { PeriodSlider } from "modules/common/components/period/PeriodSlider";
import { SessionType } from "modules/session/auth/models/session";

import { Zoomer } from "modules/common/components/zoomer/Zoomer";
import { can } from "modules/session/auth/access";
import func from "modules/spending/functionalities";
import { NoData } from "modules/common/components/table/NoData";
import { ScheduleStoreType } from "modules/spending/schedule/models/schedule-store";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { menuItems } from "modules/root/components/menu/MainMenu";
import { WorkDays } from "modules/spending/components/WorkDays";
import { Search } from "modules/common/components/form/Search";
import { CollapseExpandAll } from "modules/common/components/table/CollapseExpandAll";
import { ContextMenu } from "./ContextMenu";
import { WorkloadDayType } from "../models/workload-day";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { Popover } from "@blueprintjs/core";

const menu = menuItems();

class Page extends React.Component<WorkloadPlanPageProps, WorkloadPlanPageState> {
    printable = React.createRef<any>();
    private day: any | null = null;

    constructor(props: any) {
        super(props);

        this.state = {
            mode: "day",
            selectedCell: "",
            copyItem: {},
            monthModeIsEditing: false,
            selectedCells: [],
            showContext: false,
            x: 0,
            y: 0,
        };
    }

    componentDidMount() {
        document.title = menu.spending.nested.workload.label;

        const { store } = this.props;
        store.load();
    }

    render() {
        const { session, store, setUserFilter } = this.props;
        const { year, month, workDaysCount, monthsWorkHours, orders, tasks, departmentMap, isLoading } = store;
        const { selectedCell, selectedCells, mode, monthModeIsEditing } = this.state;

        const canManage = can(func.WORKLOAD_EDIT, session.access);
        // const canExport = can(func.WORKLOAD_EDIT, session.access);
        const showContext = (x: number, y: number, day: WorkloadDayType) => {
            this.day = day;
            this.setState({ x, y, showContext: true });
        };

        return (
            <div
                className={styles.page}
                ref={this.printable}
                onClick={(e) => {
                    this.setState({ showContext: false });
                }}
            >
                <LocalLoader active={isLoading} />
                {this.state.showContext && (
                    <ContextMenu
                        xpos={`${this.state.x / 2}px`}
                        ypos={`${this.state.y}px`}
                        onCopy={() => {
                            const model = {
                                day: this.day.day,
                                employerId: this.day.user.id,
                                comment: this.day.comment,
                                units: this.day.units.map((unit: any) => {
                                    return {
                                        comment: unit.comment,
                                        hours: unit.hours,
                                        inventoryNumber: unit.order ? unit.order.inventoryNumber : null,
                                        workId: unit.task ? unit.task.workId : null,
                                        type: unit.type,
                                        fromHome: unit.type === "Работа из дома" ? true : false,
                                        isWorkedOut: unit.isWorkedOut,
                                    };
                                }),
                            };

                            this.setState({ copyItem: model, showContext: false });
                        }}
                        onPaste={() => {
                            this.setState({ showContext: false });
                            let item = this.state.copyItem;

                            if (item.units) {
                                item.day = this.day.day;
                                item.employerId = this.day.user.id;
                                this.saveDay(item);
                            }
                        }}
                        onClear={this.removeDay}
                    />
                )}
                <div className={styles.filters}>
                    <div className={styles.periodSlider}>
                        <div>
                            <PeriodSlider year={year} month={month} onChange={this.gotoMonth} />
                            <div className={styles.center}>
                                <WorkDays days={workDaysCount} hours={monthsWorkHours} users={store.users.length} />
                            </div>
                        </div>
                        <div className={styles.actions}>
                            <div className={styles.right}>
                                {!monthModeIsEditing && (
                                    <>
                                        {/* {canExport && (
                            <>
                                <PlanrButton
                                    type="graybtn"
                                    icon="general-export"
                                    onClick={this.export}
                                    round={true}
                                    title={texts.export}
                                />
                                <PlanrButton
                                    type="graybtn"
                                    icon="general-print"
                                    onClick={this.print}
                                    round={true}
                                    title={texts.print}
                                />
                            </>
                        )} */}

                                        <Search query={store.query} onSearch={setUserFilter} />
                                    </>
                                )}
                            </div>
                            <Zoomer zoom={store.zoom} min={50} max={100} step={5} onChange={store.setZoom} />
                            <CollapseExpandAll onChange={store.collapser.setAll} />
                        </div>
                    </div>

                    <div className={styles.tools}>
                        <div className={styles.left}>
                            <div className={styles.infoButton}>
                                <Popover
                                    usePortal={true}
                                    interactionKind={"click"}
                                    content={
                                        <div className={styles.infoPopup}>
                                            <p>
                                                Зажатием ЛКМ можно выделять ячейки в строке как слева направо, так и
                                                справа налево.
                                            </p>
                                            <hr />
                                            <p>
                                                Чтобы скопировать данные из ячейки, щелкните по ней ПКМ и выберите
                                                команду Копировать.
                                            </p>
                                            <hr />
                                            <p>
                                                Чтобы вставить данные в выбранные ячейки, щелкните по ним ПКМ и выберите
                                                команду Вставить.
                                            </p>
                                            <hr />
                                            <p>
                                                Чтобы изменить данные в выбранных ячейках вручную, дважды щелкните по
                                                одной из них ЛКМ. Если ячейка
                                                <br /> не была пуста, вы можете применить ее данные к остальным ячейкам,
                                                нажатием кнопки Сохранить.
                                            </p>
                                        </div>
                                    }
                                >
                                    <GeneralIcon
                                        type="general-exclamation"
                                        style={{ color: "#EA561E", cursor: "pointer" }}
                                        size={22}
                                    />
                                </Popover>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.zoomArea} style={{ transform: `scale(${store.transform})` }}>
                    {Object.keys(departmentMap).map((department) => {
                        const users = departmentMap[department];

                        return (
                            <DepartmentBlock
                                mode={mode}
                                cellMode={"projects"}
                                department={department}
                                users={users}
                                orders={orders}
                                tasks={tasks}
                                selectedCell={selectedCell}
                                onSelectCell={this.selectCell}
                                onSelectCells={this.selectCells}
                                key={department}
                                selectedCells={selectedCells}
                                onSave={this.saveDay}
                                canManage={canManage}
                                userLogin={session.userLogin}
                                collapsed={!store.collapser.opened.get(department)}
                                toggleCollapse={store.collapser.toggle}
                                onContext={showContext}
                                removeDay={this.removeDay}
                                changeComment={(model: any) => {
                                    store.setComment(model);
                                }}
                            />
                        );
                    })}
                </div>

                {store.isEmpty && !isLoading && <NoData className={styles.nodata} />}
            </div>
        );
    }

    gotoMonth = (year: number, month: number) => {
        this.props.store.load(year, month);
        this.props.schedule.setPeriod(year, month);
    };

    selectCell = (selectedCell: string) => {
        this.setState({ selectedCell: selectedCell });
    };

    selectCells = (selectedCells: string[]) => {
        this.setState({ selectedCells });
    };
    removeDay = () => {
        this.setState({ showContext: false });

        let item = this.state.copyItem;

        item.day = this.state.selectedCell.slice(0, 10);
        item.employerId = this.state.selectedCell.slice(11, this.state.selectedCell.length);

        item.units = [];

        this.saveDay(item);
    };
    saveDay = (model: any) => {
        const index = this.state.selectedCells.findIndex((cell) => cell.slice(0, 10) === model.day);
        let success: any = false;

        let arr: any = [];
        if (index !== -1) {
            this.state.selectedCells.forEach((cell) => {
                const item = {
                    day: cell.slice(0, 10),
                    employerId: model.employerId,
                    units: model.units,
                    comment: model.comment,
                };
                arr.push(item);
            });

            success = this.props.store.saveArrayOfCells(arr);
        } else {
            success = this.props.store.save(model);
        }
        return success;
    };

    // print = () => this.props.store.print(this.state.mode);

    // export = () => this.props.store.export(this.state.mode);

    setMonthModeEditingState = (state: boolean) => this.setState({ monthModeIsEditing: state });
}

export const WorkloadPage = observer(Page);

interface WorkloadPlanPageProps {
    store: WorkloadStoreType;
    schedule: ScheduleStoreType;
    session: SessionType;
    setUserFilter: (value: string) => void;
}

interface WorkloadPlanPageState {
    selectedCell: string;
    mode: DepartmentBlockMode;
    monthModeIsEditing: boolean;
    selectedCells: string[];
    copyItem: any;
    showContext: boolean;
    x: number;
    y: number;
}
