import { Icon } from "@blueprintjs/core";
import React from "react";
import { RoleAccessRecordSnapshotType, TreeRow } from "../../models/access-store";
import { renderRows } from "../FunctionalityTree";
import tree from "../FunctionalityTree.module.scss";
import styles from "./AccessModule.module.scss";

export const AccessModule = ({ name, functionalities, access, onToggle }: AccessModuleProps) => {
    const renderer = renderRows(false, true, (row, key) => {
        const value = access.find((a) => a.name === row.functionality);
        const can = !!value && value.value;

        return (
            <td
                className={value && value.overwritten ? "access-value overwritten" : "access-value"}
                style={{ width: "30px", minWidth: "30px", textAlign: "center" }}
                key={key}
                onClick={() => onToggle(row.functionality, !can)}
            >
                {can && <Icon icon="tick" />}
            </td>
        );
    });

    return (
        <div className={`${tree.tree} ${styles.module}`}>
            <h3 className={`planr-block-header ${styles.header}`}>{name}</h3>
            <div className="collapse">
                <table className={styles.table}>
                    <tbody>{renderer(-1, functionalities)}</tbody>
                </table>
            </div>
        </div>
    );
};

interface AccessModuleProps {
    functionalities: TreeRow[];
    name: string;
    access: RoleAccessRecordSnapshotType[];
    startLevel?: number;
    onToggle: (functionality: string, state: boolean) => void;
}
