import { types, applySnapshot } from "mobx-state-tree";
import { BaseEntity, isNewlyCreated } from "modules/common/models/entity";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import moment from "moment";
import { SORTABLE_DATE_FORMAT, WHITE, EMPTY_OBJECT_ID, DATE_TIME_FORMAT } from "modules/common/constants";
import { sortBy } from "lodash";
import { toJsonHard } from "modules/common/services/mobx/serialize";
import {
  EmployerDictionary,
  initialState as emptyEmployee,
} from "modules/spending/employee/models/employee-dictionary";
import { formatDate } from "modules/common/services/formatting/date";
import { TimesheetStore, initialState as emptyTimesheet } from "modules/spending/timesheet/models/timesheet-store";

const EventDay = types
  .model({
    day: types.string,
    isDayOff: types.boolean,
    isOtherMonth: types.boolean,
  })
  .views((self) => ({
    get asMoment() {
      return moment(self.day, SORTABLE_DATE_FORMAT);
    },
  }))
  .views((self) => ({
    get dayOfMonth() {
      return self.asMoment.format("DD");
    },

    get isToday() {
      return self.asMoment.isSame(moment(), "day");
    },
  }));

export const CalendarEvent = types
  .compose(
    BaseEntity,
    Transport,
    Notificator,
    types.model({
      eventGuid: types.string,
      name: types.string,
      fullName: types.maybeNull(types.string),
      startDay: types.string,
      startTime: types.string,
      endTime: types.string,
      endDay: types.string,
      startDateSortable: types.string,
      color: types.string,
      iamAnAuthor: types.boolean,
      notificationMinutes: types.maybeNull(types.number),
      users: types.array(types.string),
      offcet: types.optional(types.number, 0),
    })
  )
  .views((self) => ({
    get startAsDate() {
      return moment(self.startDay, SORTABLE_DATE_FORMAT).toDate();
    },

    get endAsDate() {
      return moment(self.endDay, SORTABLE_DATE_FORMAT).toDate();
    },
  }))
  .views((self) => ({
    get readonly() {
      return !self.eventGuid;
    },
  }))
  .actions((self) => ({
    setOffcet(value: number) {
      self.offcet = value;
    },
  }))
  .named("CalendarEvent");

export const CalendarEventDay = types
  .model({
    event: CalendarEvent,
    days: types.array(EventDay),
  })
  .views((self) => ({
    get dayDuration() {
      return self.days.length;
    },

    eventTime(day: string) {
      const start = day === self.event.startDay ? self.event.startTime : "00:00";
      const end = day === self.event.endDay ? self.event.endTime : "00:00";

      return formatTime(start, end);
    },
  }))
  .named("CalendarEventDay");

export const CalendarEventsMonth = types.model({
  days: types.array(EventDay),
  events: types.array(CalendarEventDay),
  eventsAsMap: types.map(types.array(CalendarEventDay)),
});

export const CalendarEventStore = types
  .compose(
    Transport,
    Notificator,
    types.model({
      timesheet: TimesheetStore,
      employee: EmployerDictionary,
      month: types.number,
      year: types.number,
      loader: types.number,
      days: types.array(EventDay),
      eventsAsMap: types.map(types.array(CalendarEventDay)),
    })
  )
  .views((self) => ({
    get loading() {
      return self.loader > 0;
    },

    get daysAsMap() {
      return self.days.reduce((result, d) => {
        result[d.day] = d;
        return result;
      }, {} as TStringMap<EventDayType>);
    },
  }))
  .actions((self) => ({
    fillMap(data: CalendarEventDaySnapshotType[]) {
      let events = sortBy(
        data,
        (e) => {
          return -e.days.length;
        },
        (e) => {
          return e.event.startDay;
        }
      );

      const offcets: TStringMap<number> = {};
      const map: TStringMap<CalendarEventDaySnapshotType[]> = {};

      self.days.forEach((day) => {
        const candidates = events.filter((e) => e.days.find((d) => d.day === day.day));
        if (candidates.length) {
          const offcet = offcets[candidates[0].event.id] || 0;

          candidates.forEach((e, i) => {
            if (e.event.startDay === day.day) {
              offcets[e.event.id] = i + offcet;
            }
          });
        }

        map[day.day] = candidates;
      });

      Object.keys(map).forEach((day) => {
        for (let i = 0; i < map[day].length; i++) {
          const event = map[day][i];
          if (typeof offcets[event.event.id] === "number") {
            map[day][i] = {
              ...event,
              event: {
                ...event.event,
                offcet: offcets[event.event.id],
              },
            };
          }
        }
      });

      applySnapshot(self.eventsAsMap, map);
    },
  }))
  .actions((self) => ({
    load: flow(function* (year: number | null = null, month: number | null = null) {
      self.loader++;
      try {
        const data = toJsonHard(
          yield self.transport.get<any>(apiUrls.events.list(), {
            params: { year: year || self.year, month: month || self.month },
          })
        );

        applySnapshot(self.days, data.days);
        self.fillMap(data.events);

        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      } finally {
        self.loader--;
      }
    }),
  }))
  .actions((self) => ({
    setPeriod: (year: number, month: number) => {
      self.year = year;
      self.month = month;

      self.timesheet.setPeriod(year, month);
    },

    save: flow(function* (value: any) {
      const model = {
        ...value,
        [fields.start]: formatDate(value[fields.start], DATE_TIME_FORMAT),
        [fields.stop]: formatDate(value[fields.stop], DATE_TIME_FORMAT),
      };

      try {
        const id = model.id;
        if (isNewlyCreated(id)) {
          yield self.transport.put<any>(apiUrls.events.create(), model);
        } else {
          yield self.transport.post<any>(apiUrls.events.update(id), model);
        }

        self.load();
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),

    remove: flow(function* (eventId: string, deleteForAll: boolean) {
      try {
        yield self.transport.delete<any>(apiUrls.events.delete(eventId), {
          data: { deleteForAll },
        });
        self.load();

        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),

    factory(startDay: string) {
      const result: CalendarEventSnapshotType = {
        color: WHITE,
        created: "",
        endDay: startDay,
        endTime: "23:59",
        eventGuid: "",
        iamAnAuthor: true,
        id: EMPTY_OBJECT_ID,
        name: "",
        fullName: null,
        notificationMinutes: 15,
        offcet: 0,
        startDateSortable: startDay,
        startDay: startDay,
        startTime: "00:00",
        users: [],
      };

      return result;
    },
  }))
  .named("CalendarEventStore");

function formatTime(start: string, end: string) {
  if (start === "00:00" && end !== "00:00" && end !== "23:59") {
    return `до ${end}`;
  }

  if (start !== "00:00" && (end === "00:00" || end === "23:59")) {
    return `С ${start}`;
  }

  if (start !== "00:00" && end !== "00:00" && end !== "23:59") {
    return `С ${start} до ${end}`;
  }

  return "Весь день";
}

export const initialState = (): typeof CalendarEventStore.SnapshotType => ({
  timesheet: emptyTimesheet(true),
  employee: emptyEmployee(),
  days: [],
  eventsAsMap: {},
  loader: 0,
  month: new Date().getMonth() + 1,
  year: new Date().getFullYear(),
});

export type EventDayType = typeof EventDay.Type;
export type CalendarEventType = typeof CalendarEvent.Type;
export type CalendarEventSnapshotType = typeof CalendarEvent.SnapshotType;
export type CalendarEventDayType = typeof CalendarEventDay.Type;
export type CalendarEventDaySnapshotType = typeof CalendarEventDay.SnapshotType;
export type CalendarEventStoreType = typeof CalendarEventStore.Type;

export const fields = {
  name: "name",
  start: "start",
  stop: "stop",
  users: "users",
  notificationMinutes: "notificationMinutes",
  color: "color",
};
