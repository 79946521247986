import React, { useState, useCallback } from "react";
import { TableColumnerType, ColumnConfig, isColumnSelected } from "modules/common/models/table-columner";
import { observer } from "mobx-react";
import { PlanrButton, ButtonProps } from "../planr/button/Button";
import { Popover, Classes, Checkbox } from "@blueprintjs/core";
import styles from "./ColumnsSelector.module.scss";

export const ColumnsSelector = observer((props: ColumnsSelectorProps) => {
  const { store, columns, resetColumns, onVisibleChanged, isColumnsMoved, ...button } = props;

  const [opened, setOpened] = useState(false);
  const onOpen = useCallback(() => setOpened(true), [setOpened]);
  const onChecked = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const columnName = e.currentTarget.dataset.column ?? "";
      const selected = e.currentTarget.checked;

      if (selected && columnName) {
        store.columnSelected(columnName);
        onVisibleChanged(columnName, true);
      }

      if (!selected && columnName) {
        store.columnDeselected(columnName);
        onVisibleChanged(columnName, false);
      }
    },
    [onVisibleChanged, store]
  );

  const buttonState = "graybtn";

  return (
    <Popover
      interactionKind="click"
      position="bottom"
      isOpen={opened}
      onInteraction={setOpened}
      className="planr-column-selector"
      content={
        <div className={`${Classes.ELEVATION_1} ${styles.columns}`}>
          <PlanrButton type="secondary" size="small" onClick={resetColumns} style={{ marginBottom: "10px" }}>
            Сбросить по умолчанию
          </PlanrButton>
          {store.movedColumns.length > 0 && <p className={styles.infoMoved}>Порядок колонок изменен</p>}
          {props.columns
            .filter((column) => !!column.headerName)
            .map((column) => {
              return (
                <div key={column.field || column.groupId}>
                  <Checkbox
                    large={false}
                    checked={isColumnSelected(column, store.hiddenColumns)}
                    onChange={onChecked}
                    label={column.headerName}
                    data-column={column.field || column.groupId}
                  ></Checkbox>
                </div>
              );
            })}
        </div>
      }
    >
      <PlanrButton
        {...button}
        title="Выбрать колонки таблицы"
        type={opened ? "primary" : buttonState}
        icon="general-dots-more"
        onClick={onOpen}
      />
    </Popover>
  );
});

interface ColumnsSelectorProps extends Omit<ButtonProps, "type" | "icon" | "onClick"> {
  columns: ColumnConfig[];
  store: TableColumnerType;
  resetColumns: () => void;
  onVisibleChanged: (id: string, visibility: boolean) => void;
  isColumnsMoved: boolean;
}
