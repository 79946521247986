import React from "react";
import ReactDOM from "react-dom";
import { WorkTypeLinkSnapshotType } from "modules/orders-manage/models/order";
import { Collapse } from "@blueprintjs/core";
import { Droppable, Draggable, DraggableProvided } from "react-beautiful-dnd";
import { getDragItemStyle } from "modules/common/services/drag";
import styles from "./OwnSpendingObject.module.scss";
import { Caret } from "modules/common/components/collapse/Caret";
import portal from "../../portal";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { OwnOrderSpendingType } from "modules/orders-manage/models/order-spending";
import { EmployerDictionaryItemType } from "modules/spending/employee/models/employee-dictionary";
import { Money } from "modules/common/components/money/Money";
import { prettyRound } from "modules/common/services/formatting/numbers";

type TUnit = WorkTypeLinkSnapshotType;
type TSpending = OwnOrderSpendingType;

export class OwnSpendingObject extends React.PureComponent<OwnSpendingObjectProps> {
    renderInternal = (isDragging: boolean, provided: DraggableProvided) => {
        const { item, readOnly, children, collapsed, onToggleCollapse, spendings, employee } = this.props;
        let summ: number = 0;
        let hours: number = 0;
        spendings.forEach((items) => {
            for (let key in employee) {
                if (employee[key].id === items.employerId) {
                    summ += +items.hours * employee[key].companySpendingPerHour;
                }
            }
            hours += +items.hours;
        });

        return (
            <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                className={styles.block}
                style={getDragItemStyle(isDragging, provided.draggableProps.style)}
            >
                <div className={styles.label} onClick={onToggleCollapse}>
                    <span className={styles.span}>
                        {item.name}. {item.description}
                    </span>
                    {hours !== 0 && (
                        <div className={styles.sumNumbersObj}>
                            <div
                                className="order-total"
                                style={{
                                    left: "385px",
                                    top: "-18px",
                                    float: "right",
                                }}
                            >
                                Всего часов:&nbsp;
                                {hours}
                            </div>

                            <div className={`actual-total`} style={{ left: "525px", top: "-18px" }}>
                                Всего затрат:&nbsp;
                                <Money className="spendings-money" amount={prettyRound(summ)} />
                            </div>
                        </div>
                    )}
                    <Caret collapsed={collapsed} style={{ top: "3px" }} />
                </div>

                <Collapse isOpen={!collapsed}>
                    <Droppable droppableId={item.guid} type={item.guid} isDropDisabled={readOnly}>
                        {(pr) => (
                            <div {...pr.droppableProps} ref={pr.innerRef} className="spendings">
                                {children}
                                {pr.placeholder}
                            </div>
                        )}
                    </Droppable>
                    {!readOnly && (
                        <PlanrButton
                            type="dashed"
                            icon={"general-plus-big"}
                            onClick={this.onAdd}
                            className="own-sprendings-addbtn"
                        >
                            Добавить сотрудника
                        </PlanrButton>
                    )}
                </Collapse>
            </div>
        );
    };

    render() {
        const { item, index, readOnly } = this.props;

        return (
            <Draggable key={item.guid} draggableId={item.guid} index={index} isDragDisabled={readOnly}>
                {(provided, snapshot) => {
                    const child = this.renderInternal(snapshot.isDragging, provided);

                    return snapshot.isDragging ? ReactDOM.createPortal(child, portal) : child;
                }}
            </Draggable>
        );
    }

    onAdd = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.addSpending();
    };
}

interface OwnSpendingObjectProps {
    index: number;
    item: TUnit;
    readOnly: boolean;
    addSpending: () => void;
    onToggleCollapse: () => void;
    collapsed: boolean;
    spendings: TSpending[];
    employee: TStringMap<EmployerDictionaryItemType>;
}
