import React from "react";
import classnames from "classnames";

export const GeneralIcon: React.FC<GeneralIconProps> = ({
  type,
  size,
  className,
  style,
  title,
  onClick,
  onOver,
  ...rest
}) => {
  const styleName = classnames({
    "planr-icon": true,
    [className || ""]: true,
  });

  return (
    <svg
      {...rest}
      className={styleName}
      width={size || 16}
      height={size || 16}
      onClick={onClick}
      style={style}
      onMouseOver={onOver}
    >
      <title>{title}</title>
      <use href={`#${type || "general-transparent"}`} />
    </svg>
  );
};

interface GeneralIconProps {
  type: GeneralIcons | string;
  size?: number;
  className?: string;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void;
  onOver?: (e: React.MouseEvent<SVGSVGElement>) => void;
  title?: string;
}

export type GeneralIcons =
  | "general-expences"
  | "general-doc-clean"
  | "general-add-user"
  | "general-palette"
  | "general-uploading"
  | "general-eye"
  | "general-dot"
  | "general-chevron-down-active"
  | "general-icon-sort-by"
  | "general-active-list"
  | "general-icon-sort-by-desc"
  | "general-folder-add"
  | "general-folders"
  | "general-attach"
  | "general-big-arrow-down"
  | "general-big-arrow-left"
  | "general-big-arrow-right"
  | "general-big-arrow-up"
  | "general-calendar"
  | "general-calendar-day"
  | "general-calend-next"
  | "general-calend-prev"
  | "general-case"
  | "general-check"
  | "general-chevron-down"
  | "general-chevron-left"
  | "general-chevron-right"
  | "general-chevron-up"
  | "general-clock"
  | "general-compare"
  | "general-contact"
  | "general-copy"
  | "general-credit-card"
  | "general-cross-big"
  | "general-cross-small"
  | "general-dislike"
  | "general-doc-arrow-right"
  | "general-doc-check"
  | "general-document"
  | "general-dollar"
  | "general-dots-more"
  | "general-download"
  | "general-edit"
  | "general-exclamation"
  | "general-export"
  | "general-folder"
  | "general-home"
  | "general-import"
  | "general-info"
  | "general-like"
  | "general-list"
  | "general-loader"
  | "general-mail"
  | "general-minus"
  | "general-plus"
  | "general-plus-big"
  | "general-plus-small"
  | "general-print"
  | "general-question"
  | "general-redo"
  | "general-save"
  | "general-search"
  | "general-shuffle"
  | "general-sign-in"
  | "general-sign-out"
  | "general-time"
  | "general-trash"
  | "general-un-attach"
  | "general-undo"
  | "general-upload"
  | "general-settings-dots"
  | "general-contractor"
  | "general-corpdoc"
  | "general-dashboard"
  | "general-doc"
  | "general-expenses"
  | "general-msg"
  | "general-users"
  | "general-wallet"
  | "general-transparent"
  | "general-cb-rounded-active"
  | "general-cb-rounded"
  | "general-checkbox"
  | "general-zero-checkbox"
  | "general-mail-in"
  | "general-mail-out"
  | "general-portfolio"
  | "general-star-fill"
  | "general-star-not-fill"
  | "general-star-semi-fill"
  | "general-board"
  | "general-boomerang"
  | "general-ppl";
