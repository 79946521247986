import { types, applySnapshot } from 'mobx-state-tree';
import { DictionaryItem, DictionaryItemType } from './entity';
import { flow } from './flow';
import { Notificator } from './notificator';
import { Transport } from './transport';
import { apiUrls } from '../services/communication/urls';

export const AgentsDictionary = types.compose(Notificator, Transport, types.model({
    agents: types.array(DictionaryItem)
})).actions((self) => ({
    load: flow(function* (){
        try {
            const data: DictionaryItemType[] = yield self.transport.get<any>(apiUrls.application.dictionary.agents);
            applySnapshot(self.agents, data);

            return true;
        } catch (er) {
            applySnapshot(self.agents, []);
            return false;
        }
    })
})).views((self) => ({
    get isEmpty () {
        return !self.agents.length;
    },

    get asMap () {
        return self.agents.reduce((result, agent) => {
            result[agent.id] = agent;
            return result;
        }, {} as TStringMap<DictionaryItemType>);
    },
})).named('AgentsDictionary');

export type AgentsDictionaryType = typeof AgentsDictionary.Type;

export const initialState = (): typeof AgentsDictionary.SnapshotType => ({
    agents: []
});
