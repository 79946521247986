import { types } from "mobx-state-tree";
import { SimpleChartStore, initialState as simpleChartDatasetState } from "./simple-chart";
import { OrdersChartDataset, initialState as ordersChartDatasetState } from "./orders-chart";
import { ComposedChartDataset, initialState as composedChartState } from "./composed-chart";
import { apiUrls } from "modules/common/services/communication/urls";
import { SectionCollapser } from "modules/common/models/section-collapser";
import { EngineersLoadingDataset, initialState as engineersChartState } from "./engineers-loading-chart";
import { OrdersStateDataset, initialState as ordersStateChartState } from "./orders-state-chart";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { flow } from "modules/common/models/flow";
import { PeriodDataset, initialState as periodChartState } from "./period-chart";
import { base64ToBlob, printPdf } from "modules/common/services/files";
import { OrderAgentsListStore, initialState as orderAgentsInite } from "./order-agents-list";

export const DashboardStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            ordersChart: OrdersChartDataset,
            employeeChart: SimpleChartStore,
            companyAgeChart: SimpleChartStore,
            salaryChart: SimpleChartStore,
            composedMoneyChart: ComposedChartDataset,
            composedWorkingOutChart: ComposedChartDataset,
            overheadSpendingsRelativeChart: PeriodDataset,
            profitabilityChart: PeriodDataset,
            engineersChart: EngineersLoadingDataset,
            ordersStateChart: OrdersStateDataset,
            orderAgents: OrderAgentsListStore,
            collapser: SectionCollapser,
            charts: types.array(types.string),
        })
    )
    .actions((self) => ({
        changeCollapse(collapsed: boolean) {
            self.collapser.set(Charts.employee, !collapsed);
            self.collapser.set(Charts.companyAge, !collapsed);
            self.collapser.set(Charts.salary, !collapsed);
            self.collapser.set(Charts.overheadSpendings, !collapsed);
            self.collapser.set(Charts.engineers, !collapsed);
            self.collapser.set(Charts.orders, !collapsed);
            self.collapser.set(Charts.accounts, !collapsed);
            self.collapser.set(Charts.composed, !collapsed);
            self.collapser.set(Charts.workingOut, !collapsed);
        },
        loadcharts: flow(function* () {
            try {
                const data = yield self.transport.post<any>(apiUrls.dashboard.refresh());
                if (data) {
                    self.notify.success("Запрос на обновление отправлен!");
                }

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        reloadCharts(charts: string[]) {
            charts.forEach((chart) => {
                if (chart === "overheadSpendingsRelativeChart") {
                    self.overheadSpendingsRelativeChart.load();
                } else if (chart === "engineersChart") {
                    self.engineersChart.load();
                } else if (chart === "employeeChart") {
                    self.employeeChart.load();
                } else if (chart === "salaryChart") {
                    self.salaryChart.load();
                } else if (chart === "companyAgeChart") {
                    self.companyAgeChart.load();
                } else if (chart === "composedMoneyChart") {
                    self.composedMoneyChart.load();
                } else if (chart === "ordersChart") {
                    self.ordersChart.load();
                } else if (chart === "workingOut") {
                    self.composedWorkingOutChart.load();
                } else {
                    self.notify.error("Не удалось обновить график!");
                }
            });
            self.notify.success("Графики обновлены!");
        },
        print: flow(function* (charts: any[]) {
            try {
                const report: FileDescription = yield self.transport.post(apiUrls.dashboard.print(), {
                    charts,
                });

                const blob: any = yield base64ToBlob(report.content || "", report.mimeType);

                const fileURL = URL.createObjectURL(blob);
                const printer = printPdf(fileURL, true);
                if (printer) {
                    printer.onclose = () => URL.revokeObjectURL(fileURL);
                }

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))

    .named("DashboardStore");

export type DashboardStoreType = typeof DashboardStore.Type;
export type DashboardStoreSnapshotType = typeof DashboardStore.SnapshotType;

export const Charts = {
    employee: "employeeChart",
    salary: "salaryChart",
    companyAge: "companyAgeChart",
    overheadSpendings: "overheadSpendings",
    engineers: "engineers",
    orders: "orders",
    accounts: "accounts",
    composed: "composed",
    workingOut: "workingOut",
    profitability: "profitability",
};

export const ComposedMoney = {
    overhead: { color: "#369CD7", title: "Накладные" },
    income: { color: "#EB5757", title: "Выручка" },
    dividends: { color: "#1DD278", title: "Прибыль" },
    production: { color: "#C767DC", title: "Производственные" },
};

export const ComposedWorkingOut = {
    workingOut: { color: "#369CD7", title: "Выработка" },
    income: { color: "#1DD278", title: "Доход на сотрудника" },
};

export const initialState = (): DashboardStoreSnapshotType => ({
    orderAgents: orderAgentsInite(),
    ordersChart: ordersChartDatasetState(),
    employeeChart: simpleChartDatasetState(apiUrls.dashboard.employee()),
    salaryChart: simpleChartDatasetState(apiUrls.dashboard.salary()),
    companyAgeChart: simpleChartDatasetState(apiUrls.dashboard.companyAge()),
    profitabilityChart: periodChartState(apiUrls.dashboard.profitability(), "Рентабельность"),
    composedMoneyChart: composedChartState([
        { url: apiUrls.dashboard.overhead.absolute(), title: ComposedMoney.overhead.title },
        { url: apiUrls.dashboard.income(), title: ComposedMoney.income.title },
        { url: apiUrls.dashboard.dividends(), title: ComposedMoney.dividends.title },
        { url: apiUrls.dashboard.production(), title: ComposedMoney.production.title },
    ]),
    composedWorkingOutChart: composedChartState([
        { url: apiUrls.dashboard.workingOut(), title: ComposedWorkingOut.workingOut.title },
        { url: apiUrls.dashboard.incomeToEmployee(), title: ComposedWorkingOut.income.title },
    ]),
    overheadSpendingsRelativeChart: periodChartState(apiUrls.dashboard.overhead.relative(), "Накладные расходы"),
    ordersStateChart: ordersStateChartState(apiUrls.dashboard.orders.status()),
    engineersChart: engineersChartState(apiUrls.dashboard.engineers()),
    collapser: {
        opened: defaultCollapse,
    },
    charts: [],
});

export const defaultCollapse = {
    [Charts.employee]: false,
    [Charts.salary]: false,
    [Charts.companyAge]: false,
    [Charts.overheadSpendings]: false,
    [Charts.engineers]: true,
    [Charts.orders]: true,
    [Charts.accounts]: false,
    [Charts.composed]: true,
    [Charts.profitability]: false,
    [Charts.workingOut]: false,
};
