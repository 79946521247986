import React from "react";
import { Dialog, Classes, MenuItem, Button } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import styles from "./RoleAssigner.module.scss";
import { RoleAccessType } from "../../models/access-store";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { DefaultSelectedOption, RendererProps, SelectFactory } from "modules/common/services/form/select";
import { ItemPredicate, ItemRenderer } from "@blueprintjs/select";
import { EmployerName } from "modules/common/components/employer/EmployerName";

const PositionSelect = SelectFactory<RoleAccessType>();

export const RoleAssigner = observer(
    class extends React.PureComponent<RoleAssignerProps, RoleAssignerState> {
        state = { roleId: "" };

        render() {
            const { roleId } = this.state;
            const { onClose, roles, loading } = this.props;
            const item = roles.find((r) => r.id === roleId) ?? null;

            return (
                <Dialog
                    isCloseButtonShown={true}
                    isOpen={true}
                    onClose={onClose}
                    title="Выбор роли"
                    backdropClassName="standard"
                    canOutsideClickClose={false}
                    canEscapeKeyClose={false}
                    className={`${styles.assigner} figma-dialog`}
                >
                    <LocalLoader active={!!loading} />

                    <div className={Classes.DIALOG_BODY}>
                        <PositionSelect
                            className={`planr-default-input ${Classes.FILL}`}
                            filterable={true}
                            itemPredicate={filterPositionPredicate}
                            activeItem={item}
                            items={this.filteredOptions()}
                            itemRenderer={PositionOptionRenderer}
                            onItemSelect={this.roleSelected}
                            disabled={!roles.length}
                            inputProps={{
                                placeholder: "Роль",
                            }}
                        >
                            <DefaultSelectedOption
                                option={item}
                                empty={!roles.length}
                                render={SelectedPositionRenderer}
                            />
                        </PositionSelect>
                    </div>

                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <PlanrButton type="graybtn" size="small" onClick={onClose} style={{ marginRight: "10px" }}>
                                {texts.cancel}
                            </PlanrButton>
                            <PlanrButton type="greenish" size="small" onClick={this.onOk}>
                                {texts.ok}
                            </PlanrButton>
                        </div>
                    </div>
                </Dialog>
            );
        }

        filteredOptions = () => {
            const { roles, excludeId } = this.props;

            return excludeId ? roles.filter((o) => o.id !== excludeId) : roles;
        };

        roleSelected = (item: RoleAccessType) => {
            this.setState({ roleId: item.id });
        };

        onOk = () => {
            const { onAssign } = this.props;
            const { roleId } = this.state;

            roleId && onAssign(roleId);
        };
    }
);

const PositionOptionRenderer: ItemRenderer<RoleAccessType> = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }

    const text = <EmployerName name={item.name} position={item.department} />;

    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={item.id}
            onClick={handleClick}
            text={text}
        />
    );
};

const SelectedPositionRenderer = ({ disabled, icon, text, option }: RendererProps) => {
    const item: RoleAccessType | null | undefined = option as any;

    return (
        <Button
            disabled={disabled}
            style={{ height: "50px" }}
            fill={true}
            rightIcon={icon}
            className="selected-option"
            text={item ? <EmployerName name={text} position={item.department} /> : text}
        />
    );
};

const filterPositionPredicate: ItemPredicate<RoleAccessType> = (query, item, _index, exactMatch) => {
    const normalizedQuery = query.toLowerCase().trim();

    if (exactMatch) {
        return item.name.toLowerCase() === normalizedQuery;
    } else {
        const normalizedText = `${item.name} ${item.department}`.toLowerCase();
        return normalizedText.indexOf(normalizedQuery) >= 0;
    }
};

interface RoleAssignerProps {
    onClose: () => void;
    onAssign: (roleId: string) => void;
    roles: RoleAccessType[];
    excludeId?: string;
    loading?: boolean;
}

interface RoleAssignerState {
    roleId: string;
}
