import { types, applySnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { DictionaryItem } from "modules/common/models/entity";
import { ShortBankDetails } from "modules/common/models/bank-details";

export const OutsourcerDictionaryItem = types
    .compose(
        DictionaryItem,
        types.model({
            isConfirmed: types.boolean,
            bankDetails: types.maybeNull(ShortBankDetails),
            isLegalEntity: types.boolean,
            selfEmployed: types.boolean,
            type: types.string,
        })
    )
    .named("OutsourcerDictionaryItem");

export const OutsourcerDictionary = types
    .compose(
        Transport,
        Notificator,
        types.model({
            outsourcers: types.array(OutsourcerDictionaryItem),
        })
    )
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: any[] = yield self.transport.get<any>(apiUrls.outsourcers.dictionary());
                applySnapshot(self.outsourcers, data);
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        confirmedOnly(include: string | null) {
            return self.outsourcers.filter((o) => o.isConfirmed || o.id === include);
        },
    }))
    .views((self) => ({
        get isEmpty() {
            return !self.outsourcers.length;
        },

        get asMap(): TStringMap<OutsourcerDictionaryItemType> {
            return self.outsourcers.reduce((result, outsourcer) => {
                result[outsourcer.id] = outsourcer;
                return result;
            }, {} as TStringMap<OutsourcerDictionaryItemType>);
        },
    }))
    .named("OutsourcerDictionary");

export type OutsourcerDictionaryItemType = typeof OutsourcerDictionaryItem.Type;
export type OutsourcerDictionaryItemSnapshotType = typeof OutsourcerDictionaryItem.SnapshotType;
export type OutsourcerDictionaryType = typeof OutsourcerDictionary.Type;
export type OutsourcerDictionarySnapshotType = typeof OutsourcerDictionary.SnapshotType;

export const initialState = (): OutsourcerDictionarySnapshotType => ({
    outsourcers: [],
});
