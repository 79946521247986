import { OrderTechTaskVersionType } from "modules/orders-manage/models/order-tech-tasks";
import { AppLoadingStatus, hostReady, hideButton } from "./collabora-api";
import React, { useCallback } from "react";
import { apiUrls } from "modules/common/services/communication/urls";
import { Dialog, Classes } from "@blueprintjs/core";
import { CollaboraLoader, CollaboraFrame } from "./collabora";

const portal = document.getElementById("fullscreen-backdrop");

interface VersionViewerProps {
    version: OrderTechTaskVersionType;
    fileId: string;
    editorUrl: string;
    wopiToken: string;
    onClose: () => void;
}

export const VersionViewer = ({ onClose, version, fileId, ...rest }: VersionViewerProps) => {
    const onMessage = useCallback((e) => {
        if (e.MessageId === "App_LoadingStatus") {
            const event: AppLoadingStatus = e;

            if (event.Values.Status === "Document_Loaded") {
                hostReady(e.frame);
                hideButton(e.frame, "fold");
                hideButton(e.frame, "sidebar");
            }

            return true;
        }
    }, []);

    const editorFrameId = "order-tech-task-version-frame";

    const apiUrl = apiUrls.orders.summary.tasks.fileInfo(fileId, version.name);

    return (
        <Dialog
            isOpen={true}
            portalContainer={portal || undefined}
            onClose={onClose}
            style={{ width: "60%", minWidth: "1170px" }}
            title={version.name}
            canEscapeKeyClose={true}
            canOutsideClickClose={false}
            backdropClassName="standard"
        >
            <div className={Classes.DIALOG_BODY}>
                <CollaboraLoader id={editorFrameId} container={null} {...rest} apiUrl={apiUrl} />
                <CollaboraFrame onMessage={onMessage} id={editorFrameId} />
            </div>
        </Dialog>
    );
};
