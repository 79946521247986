import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { IdEntity } from "modules/common/models/entity";
import { groupBy, orderBy, uniq } from "lodash";
import { Constants } from "modules/root/models/constants";
import { Queryable } from "modules/common/models/queryable";
import { texts } from "modules/common/texts";

export const RoleAccessRecord = types
    .model({
        name: types.string,
        value: types.boolean,
        overwritten: types.boolean,
    })
    .named("RoleAccessRecord");

export const RoleAccess = types
    .compose(
        Transport,
        Notificator,
        IdEntity,
        types.model({
            name: types.string,
            access: types.array(RoleAccessRecord),
            department: types.string,
        })
    )
    .views((self) => ({
        get label() {
            return self.name;
        },

        get sublabel() {
            return self.department;
        },

        get functionalities() {
            return self.access.filter((a) => a.value).map((a) => a.name);
        },
    }))
    .actions((self) => ({
        setWholeAccess: flow(function* (access: string[]) {
            try {
                const data: any = yield self.transport.post<any>(apiUrls.employeePositions.wholeAccess(self.id), {
                    access,
                });

                applySnapshot(self, data);

                self.notify.success(texts.messages.saved);
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        setFunctionalityAccess: flow(function* (functionality: string, state: boolean) {
            try {
                const data: any = yield self.transport.post<any>(apiUrls.employeePositions.singleAccess(self.id), {
                    functionality,
                    state,
                });

                applySnapshot(self, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .named("RoleAccess");

export const AccessStore = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            roles: types.array(RoleAccess),
            authorization: types.map(types.string),
        })
    )
    .views((self) => ({
        get filteredRoles() {
            const data = self.roles.map((role) => ({
                ...getSnapshot(role),
                label: role.label,
                sublabel: role.sublabel,
            }));

            return self.pureQuery
                ? data.filter((role) => {
                      const text = `${role.name} ${role.department}`.toLowerCase();
                      return text.indexOf(self.pureQuery) >= 0;
                  })
                : data;
        },
    }))
    .views((self) => ({
        get departments() {
            return uniq(self.filteredRoles.map((r) => r.department));
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            applySnapshot(self.authorization, getSnapshot(Constants.authorization));

            try {
                const data = yield self.transport.get<RoleAccessSnapshotType[]>(apiUrls.employee.access);

                applySnapshot(self.roles, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .views((self) => ({
        get accessTree() {
            return buildAccessTree(self.authorization.entries());
        },
    }))
    .views((self) => ({
        get accessColumns() {
            return makeColumns(self.accessTree, 4);
        },
    }))
    .named("AccessStore");

export type RoleAccessRecordSnapshotType = typeof RoleAccessRecord.SnapshotType;
export type RoleAccessSnapshotType = typeof RoleAccess.SnapshotType;
export type RoleAccessType = typeof RoleAccess.Type;
export type AccessStoreSnapshotType = typeof AccessStore.SnapshotType;
export type AccessStoreType = typeof AccessStore.Type;

export const initialState = (): AccessStoreSnapshotType => ({
    roles: [],
    authorization: {},
    pureQuery: "",
    query: "",
});

export const buildRows = (data: [string, string][]) => {
    let level1: string[] = [];
    const result: TreeRow[] = data.map((functionality) => {
        const [name, label] = functionality;
        const parts = makeLabels(label);

        if (parts[0].trim()) {
            level1.push(parts[0]);
        }

        return {
            functionality: name,
            level1: parts[0],
            level2: parts[1],
            level3: parts[2],
        };
    });

    // normalize columns
    level1 = uniq(level1);
    result.forEach((row) => {
        if (!row.level1 && level1.includes(row.level2) && row.level3) {
            row.level1 = row.level2;
            row.level2 = row.level3;
            row.level3 = "";
        }
    });

    return result;
};

const makeLabels = (label: string) => {
    const parts = label.split(".").filter((part) => !!part);
    const result = ["", "", ""];

    if (parts.length === 2) {
        result[2] = parts[1];
        result[1] = parts[0];
    } else if (parts.length === 3) {
        result[2] = parts[2];
        result[1] = parts[1];
        result[0] = parts[0];
    } else if (parts.length === 4) {
        result[2] = parts[2] + " " + parts[3];
        result[1] = parts[1];
        result[0] = parts[0];
    } else {
        result[2] = label;
    }

    return result;
};

export interface TreeRow {
    level1: string;
    level2: string;
    level3: string;
    functionality: string;
}

export interface AccessRolesModule extends TCounted {
    module: string;
    tree: TreeRow[];
}

export interface RoleData {
    id: string;
    label: string;
    sublabel: string;
    access: RoleAccessRecordSnapshotType[];
}

interface TCounted {
    length: () => number;
}

/**
 * Разбить набор коллекций на колонки ~ равной величины
 * @param array Набор коллекций
 * @param columnCount Кол-во колонок
 */
export function makeColumns<T extends TCounted>(array: T[], columnCount: number) {
    const chunkLength = Math.ceil(array.reduce((r, part) => r + part.length(), 0) / Math.max(1, columnCount));
    const portions: T[][] = [];

    let portion: T[] = [];
    while (array.length) {
        if (!portion.length) {
            portion.push(array.shift() as T);
            continue;
        }

        if (portion.reduce((r, part) => r + part.length(), 0) >= chunkLength) {
            portions.push(portion);
            portion = [];
            continue;
        }

        portion.push(array.pop() as T);
    }

    if (portion.length) {
        portions.push(portion);
    }

    return portions;
}

/**
 * Собрать древовидное представление из списка функциональностей
 * @param authorization Полный список функциональностей приложения [ключ, название]
 */
export function buildAccessTree(authorization: IterableIterator<[string, string]>) {
    const modules = groupBy(Array.from(authorization), (e) => e[1].split(".")[0]);

    return orderBy(
        Object.entries(modules).map(
            (module) =>
                ({
                    module: module[0],
                    tree: buildRows(module[1]),
                    length: () => module[1].length,
                } as AccessRolesModule)
        ),
        (m) => -m.tree.length
    );
}
