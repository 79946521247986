import React from "react";
import { observer } from "mobx-react";
import styles from "./CategoriesList.module.scss";
import { columns } from "./columnsDeprGroups";
import { saveAgGridColumnSize } from "modules/common/services/table/size-storage";
import { defaultModules, DefaultAgGridOptions } from "modules/common/services/table/helpers";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { agGridEntityContextMenu } from "modules/common/components/table/EntityContextMenu";
import { DeprGroupList, DeprGroupsListType } from "../../models/depr-group-list";
import { DeprGroupSnapshotType, formatDeprGroup } from "../../models/depr-group";
import { MenuModule } from "@ag-grid-enterprise/menu";
import {
    GetContextMenuItemsParams,
    RowClickedEvent,
    RowSelectedEvent,
    CellContextMenuEvent,
    GridReadyEvent,
} from "@ag-grid-community/core";
import copyToClipboard from "copy-to-clipboard";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import { agGridLocale } from "modules/root/services/locale";
import { MAX_INT } from "modules/common/constants";

interface DeprGroupsListProps {
    list: DeprGroupsListType;
    onRemoved: () => void;
    onSelected: (id: string) => void;
    onClicked: (id: string) => void;
}

interface DeprGroupsListState {
    focused: boolean;
}
export const DeprGroupsList = observer(
    class extends React.Component<DeprGroupsListProps, DeprGroupsListState> {
        private gridOptions = DefaultAgGridOptions(true);
        private modules = [...defaultModules, MenuModule];
        private TABLE_NAME = DeprGroupList.name;
        private config = columns(this.TABLE_NAME);
        private sizeSaver = saveAgGridColumnSize(this.TABLE_NAME);

        constructor(props: DeprGroupsListProps) {
            super(props);
            this.state = { focused: false };
        }

        componentDidUpdate() {
            const { focused } = this.state;
            if (!focused)
                this.gridOptions.api?.forEachNode((node) => {
                    if (node.rowIndex === 0) {
                        node.setSelected(true);
                    }
                });
        }

        render() {
            const { list, onSelected } = this.props;

            return (
                <RemoveConfirmation<DeprGroupSnapshotType>
                    what={removeLabel}
                    onConfirmed={this.removeDeprGroup}
                    observation={list.data}
                >
                    {({ confirmRemoving }) => {
                        const getContextMenuItems = ({ node }: GetContextMenuItemsParams) =>
                            agGridEntityContextMenu({
                                onCopy: () => copyToClipboard(formatDeprGroup(node.data), { format: "text/plain" }),
                                onChange: () => onSelected(getRowNodeId(node.data)),
                                onRemove: () => confirmRemoving(node.data),
                            });

                        return (
                            <GridTheme bordered={true} className={`designed ${styles.grid}`}>
                                <AgGridReact
                                    rowStyle={{
                                        borderBottom: "1 px solid #EDF0F2",
                                        fontFamily: "GothamPro, Arial, sans-serif",
                                        color: "#00273D",
                                        height: "32px",
                                        fontSize: "12px",
                                    }}
                                    rowSelection="single"
                                    suppressCellSelection={true}
                                    enableCellTextSelection={true}
                                    columnDefs={this.config}
                                    rowData={list.data}
                                    localeText={agGridLocale}
                                    onRowDoubleClicked={this.onRowSelected}
                                    onRowSelected={this.onRowClicked}
                                    getRowNodeId={getRowNodeId}
                                    getContextMenuItems={getContextMenuItems}
                                    onGridReady={this.onGridReady}
                                    onCellContextMenu={onCellContextMenu}
                                    onSortChanged={list.sorter.resorted}
                                    onColumnResized={this.sizeSaver}
                                    gridOptions={this.gridOptions}
                                    modules={this.modules}
                                    immutableData={true}
                                    rowBuffer={MAX_INT}
                                />
                            </GridTheme>
                        );
                    }}
                </RemoveConfirmation>
            );
        }

        toggle = () => this.setState({ focused: true });

        onGridReady = async ({ api }: GridReadyEvent) => {
            const { list } = this.props;

            setTimeout(() => api.setSortModel(list.sorter.agGridSortModel));
        };

        onRowSelected = ({ data }: RowSelectedEvent) => {
            const { onSelected } = this.props;

            onSelected(getRowNodeId(data));
        };

        onRowClicked = ({ data, node }: RowClickedEvent) => {
            const { onClicked } = this.props;

            if (!node.isSelected()) {
                return;
            }

            onClicked(getRowNodeId(data));
            this.toggle();
        };

        removeDeprGroup = async (dep: DeprGroupSnapshotType) => {
            const { list, onRemoved } = this.props;

            const success = await list.delete(dep);
            success && onRemoved();
        };
    }
);

const onCellContextMenu = ({ node }: CellContextMenuEvent) => {
    node && node.setSelected(true, true);
};

const getRowNodeId = (e: DeprGroupSnapshotType) => {
    return e.id;
};

const removeLabel = (dep: DeprGroupSnapshotType) => `категорию ${dep.name}`;
