import React from "react";
import { OutsourcedOrderSpendingType, OwnOrderSpendingType } from "modules/orders-manage/models/order-spending";
import {
  OrderObjectSnapshotType,
  WorkTypeLinkSnapshotType,
  fields,
  filterObjectUnits,
  getObjectsToDisplay,
  getCategoryProgress,
} from "modules/orders-manage/models/order";
import { FieldProps } from "formik";
import { OTHER_WORKS } from "../constants";
import { Caret } from "modules/common/components/collapse/Caret";
import { Collapse } from "@blueprintjs/core";
import { findIndex } from "lodash";
import { ContentFactory, ContentDetais } from "./ContentDetails";
import { Block, ActivationDelegate } from "./Block";
import { move } from "modules/common/services/array";
import { WorkTypeDictionaryType } from "modules/dictionaries/work-types/models/work-type-dictionary";
import styles from "./CategoryBlock.module.scss";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { OutsourcerDictionaryType } from "modules/agents/outsourcers/models/outsourcer-dictionary";
import { EmployerDictionaryType } from "modules/spending/employee/models/employee-dictionary";
import { IpdFileSnapshotType } from "modules/orders-manage/models/order-file";

type TOutsourced = OutsourcedOrderSpendingType;
type TOwn = OwnOrderSpendingType;
type TObject = OrderObjectSnapshotType;
type TUnit = WorkTypeLinkSnapshotType;

export class CategoryBlock extends React.Component<CategoryBlockProps> {
  render() {
    const { category, onToggleCollapse, collapsed, objects, readOnly, fieldProps, employers, agents, onDownload } =
      this.props;
    const { orderNumber, selected, contentFactory, workTypes, onUpload, baseUrl } = this.props;

    const objectsToDisplay = getObjectsToDisplay(category, objects);

    const progress = getCategoryProgress(category, objectsToDisplay);

    const touched = !!fieldProps.form.touched[fieldProps.field.name];

    return (
      <div className={styles.categoryBlock}>
        <h1 className="planr-block-header collapser" onClick={onToggleCollapse}>
          {category || OTHER_WORKS}
          <span
            style={{ marginLeft: "1110px" }}
            className={
              progress === 0
                ? "collapse-percents color-red"
                : progress < 50
                ? "collapse-percents color-orange"
                : progress >= 50 && progress < 75
                ? "collapse-percents color-blue"
                : progress >= 75 && progress < 100
                ? "collapse-percents color-purple"
                : progress === 100
                ? "collapse-percents color-green"
                : "collapse-percents collapse-progress"
            }
          >
            {progress.toFixed(0)}%
          </span>
          <Caret collapsed={collapsed} />
        </h1>

        <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
          <div className="collapse">
            <div className="columns-vertical">
              {objectsToDisplay.map((object, i) => {
                // checked work types sorted by user order
                const filteredUnits = filterObjectUnits(object, category)
                  // original indexes for dnd sorting
                  .map((unit) => ({ unit, index: object.content.indexOf(unit) }));

                const canAdd = !readOnly;

                let activation: ActivationDelegate | null = null;

                return (
                  <div key={object.guid} className={styles.content}>
                    {!!selected && selected.category === category && selected.object === object.guid && (
                      <ContentDetais
                        orderNumber={orderNumber}
                        units={selected.units}
                        workTypes={workTypes}
                        objectName={object.name}
                        objectNumber={object.inventoryNumber}
                        workTypeCategory={category}
                        onClose={(units) => this.objectConfigured(object, units)}
                        factory={contentFactory}
                      />
                    )}

                    <div>
                      <Block
                        activation={({ onActivate }) => (activation = onActivate)}
                        category={category}
                        title={category ? object.name || "Общее" : ""}
                        touched={touched}
                        units={filteredUnits}
                        onOpen={() => this.openCategory(object)}
                        onRemove={(unit) => this.removeUnit(object, unit)}
                        onSort={(from, to) => this.resortUnits(object, from, to)}
                        onChange={(oldUnit, newUnit) => this.changeUnit(object, oldUnit, newUnit)}
                        readOnly={readOnly}
                        employers={employers}
                        agents={agents}
                        onUpload={onUpload}
                        baseUrl={baseUrl}
                        onDownload={onDownload}
                      />

                      {canAdd && (
                        <div className="collapse-add">
                          <PlanrButton
                            type="dashed"
                            icon="general-plus-big"
                            className="collapse-add-rows"
                            onClick={async () => {
                              const unit = await this.addCustomRow(object);
                              activation && activation(unit);
                            }}
                          >
                            Добавить раздел
                          </PlanrButton>
                          {category && (
                            <PlanrButton
                              type="dashed"
                              icon="general-plus-big"
                              className="collapse-add-rows"
                              onClick={() => {
                                this.openCategory(object);
                              }}
                            >
                              Добавить из справочника
                            </PlanrButton>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Collapse>
      </div>
    );
  }

  setFieldValue = (newObjects: TObject[]) => {
    this.props.fieldProps.form.setFieldValue(this.props.fieldProps.field.name, newObjects);
    this.props.fieldProps.form.setFieldTouched(this.props.fieldProps.field.name, true);
  };

  openCategory = (object: TObject) => {
    const { category } = this.props;

    if (category) {
      const filteredUnits: TUnit[] = filterObjectUnits(object, category);
      this.props.onSelect({ category, units: [...filteredUnits], object: object.guid });
    }
  };

  addCustomRow = async (object: TObject) => {
    const { contentFactory, orderNumber, objects, category } = this.props;
    const position = findIndex(objects, (o) => o.guid === object.guid);

    const newRow = await contentFactory.emptyContent(orderNumber, object.content.length + 1);
    newRow.workTypeCategory = category;

    const content: TUnit[] = [...object.content, newRow];

    const newObjects = [
      ...objects.slice(0, position),
      {
        ...object,
        content,
      },
      ...objects.slice(position + 1),
    ];

    this.setFieldValue(newObjects);

    return newRow;
  };

  removeUnit = (object: TObject, unit: TUnit) => {
    const { objects } = this.props;
    const position = findIndex(objects, (o) => o.guid === object.guid);

    const content = object.content.filter((u) => u.guid !== unit.guid);
    const newObjects = [
      ...objects.slice(0, position),
      {
        ...object,
        content,
      },
      ...objects.slice(position + 1),
    ];

    this.setFieldValue(newObjects);
  };

  resortUnits = (object: TObject, from: number, to: number) => {
    if (from !== to) {
      const { objects } = this.props;
      const position = findIndex(objects, (o) => o.guid === object.guid);

      const content = move(object.content, from, to);
      const newObjects = [
        ...objects.slice(0, position),
        {
          ...object,
          content,
        },
        ...objects.slice(position + 1),
      ];

      this.setFieldValue(newObjects);
    }
  };

  changeUnit = (object: TObject, oldUnut: TUnit, newUnit: TUnit) => {
    const { objects, fieldProps } = this.props;
    const position = findIndex(objects, (o) => o.guid === object.guid);

    let sortOrder = newUnit.sortOrder;

    if (oldUnut.outsourced !== newUnit.outsourced) {
      // move row at the begining
      sortOrder = 0;
    }

    const newContent = object.content.map((u) =>
      newUnit.guid === u.guid
        ? {
            ...newUnit,
            sortOrder,
          }
        : u
    );

    const newObjects = [
      ...objects.slice(0, position),
      {
        ...object,
        content: newContent,
      },
      ...objects.slice(position + 1),
    ];

    this.setFieldValue(newObjects);

    if (oldUnut.outsourced !== newUnit.outsourced) {
      // reinitialize outsourced spendings
      const content = newObjects.reduce((acc, obj) => [...acc, ...obj.content], [] as TUnit[]);

      const outsourced = fieldProps.form.values[fields.outsourcedOrderSpendins] as TOutsourced[];
      const newOutsourced = content
        .filter((u) => u.outsourced)
        .reduce((acc, u) => {
          return [...acc, ...outsourced.filter((sp) => sp.contentGuid === u.guid)];
        }, [] as TOutsourced[]);

      fieldProps.form.setFieldValue(fields.outsourcedOrderSpendins, newOutsourced);
      fieldProps.form.setFieldTouched(fields.outsourcedOrderSpendins, true);

      // reinitialize own spendings
      const own = fieldProps.form.values[fields.ownOrderSpendings] as TOwn[];
      const newOwn = content
        .filter((u) => !u.outsourced)
        .reduce((acc, u) => {
          return [...acc, ...own.filter((sp) => sp.contentGuid === u.guid)];
        }, [] as TOwn[]);

      fieldProps.form.setFieldValue(fields.ownOrderSpendings, newOwn);
      fieldProps.form.setFieldTouched(fields.ownOrderSpendings, true);
    }
  };

  objectConfigured = (object: TObject, units: TUnit[]) => {
    const { category, objects, onSelect } = this.props;

    if (units.length) {
      const position = findIndex(objects, (o) => o.guid === object.guid);

      const other: TUnit[] = object.content.filter((unit) => unit.workTypeCategory !== category);

      const newObjects = [
        ...objects.slice(0, position),
        {
          ...object,
          content: [...other, ...units],
        },
        ...objects.slice(position + 1),
      ];

      this.setFieldValue(newObjects);
    }

    onSelect(null);
  };
}

interface CategoryBlockProps {
  fieldProps: FieldProps;
  category: string;
  readOnly?: boolean;
  objects: TObject[];
  collapsed: boolean;
  onToggleCollapse: () => void;
  onSelect: (selected: Selection | null) => void;
  workTypes: WorkTypeDictionaryType;
  contentFactory: ContentFactory;
  orderNumber: string;
  selected: Selection | null;
  employers: EmployerDictionaryType;
  agents: OutsourcerDictionaryType;
  onUpload: (file: File) => Promise<IpdFileSnapshotType | null>;
  onDownload: (file: string) => Promise<boolean | undefined>;
  baseUrl: string;
}

export interface Selection {
  category: string;
  object: string;
  units: TUnit[];
}
