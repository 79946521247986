import { Classes, Dialog, InputGroup } from "@blueprintjs/core";
import { UnregisterCallback } from "history";
import { observer } from "mobx-react";
import { FileLink } from "modules/common/components/files/FileLink";
import { Buttons } from "modules/common/components/form";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import {
  DefaultSelectedOption,
  SimpleSelect,
  filterItemPredicate,
  renderSingleOptionFactory,
} from "modules/common/services/form/select";
import { eat } from "modules/common/services/typescript";
import { AgentsCategoriesDictionaryType } from "modules/dictionaries/agents-categories/models/agents-categories-dictionary";
import { OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";
import React from "react";
import ReactQuill from "react-quill";
import { ACCEPT, OrderPostStoreType, OrderPostType } from "../../models/outsourcer";
import styles from "./RequestEmail.module.scss";

export const renderSingleOption = renderSingleOptionFactory(() => null);

class RequestEmail extends React.Component<RequestEmailProps, RequestEmailState> {
  private unregister: UnregisterCallback | null = null;

  constructor(props: RequestEmailProps) {
    super(props);

    this.state = {
      selectedCategory: null,
      load: false,
      loadMails: false,
      changeDate: false,
      isReply: false,
      selectedAgents: [],
      selectedOrder: null,
      selectedMoveOrder: null,
      uploadedFiles: [],
      subject: "",
      message: "",
      openOrderMove: false,
      selectedMoveMessage: null,
    };
  }
  async componentDidMount() {
    this.setState({ loadMails: true });
    await this.props.categories.load();
    await this.props.orderDictionary.load();
    if (this.props.orderId) {
      const order = this.props.orderDictionary.orders.find((o) => o.id === this.props.orderId);
      if (order) {
        const selOrder = { id: order.id, label: order.label };
        this.setState({ selectedOrder: selOrder });
        await this.props.store.loadSendEmails(this.props.orderId);
      }
    } else {
      await this.props.store.loadSendEmails();
    }
    this.setState({ loadMails: false });
  }
  componentWillUnmount() {
    this.unregister && this.unregister();
  }

  render() {
    const { categories, baseUrl, store, orderDictionary, agentsList, orderId } = this.props;
    const {
      selectedCategory,
      selectedAgents,
      selectedOrder,
      uploadedFiles,
      subject,
      message,
      load,
      selectedMoveOrder,
      openOrderMove,
      selectedMoveMessage,
      loadMails,
      changeDate,
    } = this.state;
    const agents = selectedCategory
      ? agentsList.filter(
          (agent) =>
            agent.categories.includes(selectedCategory.id) &&
            agent.isAgent &&
            agent.email !== "" &&
            agent.email !== null
        )
      : agentsList.filter((agent) => agent.isAgent && agent.email !== "" && agent.email !== null);
    const emptyItem = { id: "", label: "" };
    const agentItems = agents.map((ag) => ({ id: ag.id, label: `${ag.name} <${ag.email}>` }));

    const orders = orderDictionary.orders.map((order) => ({
      id: order.id,
      label: `${order.fullInventoryNumber} ${order.label}`,
    }));

    return (
      <>
        <div className={`${Classes.DIALOG_BODY} `}>
          <div className={styles.page}>
            <Dialog
              isOpen={openOrderMove}
              backdropClassName="standard"
              title="Выберите договор"
              onClose={this.closeOrderMove}
            >
              <div className={`${Classes.DIALOG_BODY} `}>
                <div className={styles.row}>
                  <div className={styles.label}>Договор</div>
                  <SimpleSelect
                    className={`full-width-select ${Classes.FILL}`}
                    activeItem={selectedMoveOrder}
                    items={orders}
                    itemRenderer={renderSingleOption}
                    onItemSelect={(i: SelectItem) => {
                      this.setState({ selectedMoveOrder: i });
                    }}
                    popoverProps={{
                      boundary: "viewport",
                      popoverClassName: "import-spendings-table__select",
                    }}
                    inputProps={{
                      placeholder: "Поиск",
                    }}
                    filterable={true}
                    itemPredicate={filterItemPredicate}
                  >
                    <DefaultSelectedOption option={selectedMoveOrder} />
                  </SimpleSelect>
                </div>
                <div className={styles.row}>
                  <div className={styles.label}>Изменить дату и время письма на текущие</div>
                  <PlanrButton
                    type="neutral"
                    icon={changeDate ? "general-checkbox" : "general-zero-checkbox"}
                    onClick={() => {
                      this.setState({ changeDate: !this.state.changeDate });
                    }}
                    style={{ marginTop: "-5px" }}
                  />
                </div>
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <Buttons
                  left={
                    <div style={{ display: "flex" }}>
                      <PlanrButton
                        size="small"
                        type="greenish"
                        onClick={async () => {
                          this.setState({ loadMails: true });
                          const res = await store.saveSendEmailOrder(
                            selectedMoveMessage,
                            selectedMoveOrder,
                            changeDate
                          );
                          if (res) {
                            this.closeOrderMove();
                          }
                          this.setState({ loadMails: false });
                        }}
                        style={{ minWidth: "120px" }}
                        // disabled={isUploading}
                      >
                        {/* {isUploading && <Spinner intent="primary" size={16} />} */}
                        {"Сохранить"}
                      </PlanrButton>
                      <PlanrButton size="small" type="graybtn" onClick={this.closeOrderMove}>
                        Отмена
                      </PlanrButton>
                    </div>
                  }
                />
              </div>
            </Dialog>
            <div className={styles.columnMessages}>
              <PlanrButton
                type="lightblueish"
                size="small"
                style={{ position: "absolute", top: "-65px", left: "150px" }}
                onClick={async () => {
                  this.setState({ loadMails: true });
                  await store.readMailPost();
                  await store.loadSendEmails(selectedOrder?.id);
                  this.setState({ loadMails: false });
                }}
              >
                Синхронизировать
              </PlanrButton>
              <div className={styles.messagesWrapper}>
                {loadMails && <LocalLoader active text="Обновляем данные..." />}
                <div className={styles.messagesList}>
                  {store.list.map((mail) => (
                    <div
                      key={mail.id}
                      className={styles.messageItem}
                      style={{ justifyContent: mail.isRequest ? "start" : "end" }}
                    >
                      <MessageItem
                        message={mail}
                        baseUrl={baseUrl}
                        onOrverMove={this.onOrderMove}
                        onMessageAnswer={this.onMessageAnswer}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.column}>
              {load && <LocalLoader active text="Рассылаем электронные письма..." />}
              <div className={styles.row}>
                <div className={styles.label}>Договор</div>
                {!orderId && (
                  <SimpleSelect
                    className={`full-width-select ${Classes.FILL}`}
                    activeItem={selectedOrder}
                    items={orders}
                    itemRenderer={renderSingleOption}
                    onItemSelect={async (i: SelectItem) => {
                      this.setState({ loadMails: true });
                      this.setState({ selectedOrder: i });

                      await store.loadSendEmails(i.id);
                      this.setState({ loadMails: false });
                    }}
                    popoverProps={{
                      boundary: "viewport",
                      popoverClassName: "import-spendings-table__select",
                    }}
                    inputProps={{
                      placeholder: "Поиск",
                    }}
                    filterable={true}
                    itemPredicate={filterItemPredicate}
                  >
                    <DefaultSelectedOption
                      option={selectedOrder}
                      onClear={async () => {
                        this.setState({ selectedOrder: null });
                        this.setState({ loadMails: true });
                        await store.loadSendEmails();
                        this.setState({ loadMails: false });
                      }}
                    />
                  </SimpleSelect>
                )}
                {orderId && (
                  <InputGroup
                    className="planr-default-input"
                    autoComplete="off"
                    value={selectedOrder?.label}
                    readOnly
                  />
                )}
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Категории агентов</div>
                <SimpleSelect
                  className={`full-width-select ${Classes.FILL}`}
                  activeItem={selectedCategory}
                  items={categories.types}
                  itemRenderer={renderSingleOption}
                  onItemSelect={(i: SelectItem) => {
                    this.setState({ selectedCategory: i });
                  }}
                  popoverProps={{
                    boundary: "viewport",
                    popoverClassName: "import-spendings-table__select",
                  }}
                  inputProps={{
                    placeholder: "Поиск",
                  }}
                  filterable={true}
                  itemPredicate={filterItemPredicate}
                >
                  <DefaultSelectedOption
                    option={selectedCategory}
                    onClear={() => {
                      this.setState({ selectedCategory: null });
                    }}
                  />
                </SimpleSelect>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Агенты</div>
                <SimpleSelect
                  className={`full-width-select ${Classes.FILL}`}
                  activeItem={emptyItem}
                  items={agentItems}
                  itemRenderer={renderSingleOption}
                  onItemSelect={(i: SelectItem) => {
                    let oldAgents = [...selectedAgents];
                    const index = oldAgents.findIndex((a) => a.id === i.id);
                    if (index === -1) {
                      oldAgents.push(i);
                      this.setState({ selectedAgents: oldAgents });
                    }
                  }}
                  popoverProps={{
                    boundary: "viewport",
                    popoverClassName: "import-spendings-table__select",
                  }}
                  inputProps={{
                    placeholder: "Поиск",
                  }}
                  filterable={true}
                  itemPredicate={filterItemPredicate}
                >
                  <DefaultSelectedOption option={emptyItem} />
                </SimpleSelect>
              </div>
              {selectedAgents.length > 0 && (
                <div className={styles.selectedAgentsWrapper}>
                  <div className={styles.label}>Выбранные агенты</div>
                  <div className={styles.selectedAgentsList}>
                    {selectedAgents.map((agent) => (
                      <div key={agent.id} className={styles.selectedRow}>
                        <div className={styles.name}>{agent.label}</div>

                        <PlanrButton
                          type={"lightdanger"}
                          icon="general-trash"
                          size="small"
                          style={{ height: "20px" }}
                          onClick={() => {
                            let oldAgents = [...selectedAgents];
                            const index = oldAgents.findIndex((a) => a.id === agent.id);
                            if (index > -1) {
                              oldAgents.splice(index, 1);
                              this.setState({ selectedAgents: oldAgents });
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* {isReply && (
                <div className={styles.row}>
                  <PlanrButton
                    type="secondary"
                    size="small"
                    onClick={() => {
                      this.setState({ isReply: false, subject: "" });
                    }}
                  >
                    Отменить ответ
                  </PlanrButton>
                </div>
              )} */}
              <div className={styles.row}>
                <div className={styles.label}>Тема</div>
                <InputGroup
                  className="planr-default-input"
                  autoComplete="off"
                  value={subject}
                  onChange={(e: any) => {
                    this.setState({ subject: e.currentTarget.value });
                  }}
                />
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Сообщение</div>
                <div className={`${Classes.INPUT_GROUP} planr-default-input`} style={{ height: "280px" }}>
                  <ReactQuill
                    value={message}
                    onChange={(editor) => {
                      this.setState({ message: editor });
                    }}
                    theme="snow"
                    className="planr-default-input"
                  />
                  {/* <TextArea
                    growVertically={true}
                    className={Classes.FILL}
                    style={{ maxHeight: "220px" }}
                    value={message}
                    onChange={(e: any) => {
                      this.setState({ message: e.currentTarget.value });
                    }}
                  /> */}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Файлы</div>
                <PlanRDndUploader
                  accept={ACCEPT}
                  multiple={true}
                  withLoader={true}
                  onFileSelected={this.uploadFile}
                  style={{ marginBottom: "5px" }}
                />
                <div className={styles.uploadedfilesList}>
                  {uploadedFiles &&
                    uploadedFiles.length > 0 &&
                    uploadedFiles.map((doc, index) => {
                      return <FileLink key={doc.fileId} baseUrl={baseUrl} file={doc} onRemove={this.onRemove} />;
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <Buttons
            left={
              <div style={{ display: "flex" }}>
                <PlanrButton
                  size="small"
                  type="greenish"
                  onClick={async () => {
                    this.setState({ load: true });

                    const res = await store.saveSendEmail(
                      selectedAgents,
                      selectedOrder,
                      uploadedFiles,
                      agentsList,
                      subject,
                      message
                    );
                    if (res) {
                      this.setState({
                        selectedAgents: [],
                        selectedCategory: null,
                        uploadedFiles: [],
                        subject: "",
                        message: "",
                        isReply: false,
                        selectedMoveMessage: null,
                      });
                    }

                    this.setState({ load: false });
                  }}
                  style={{ minWidth: "120px" }}
                  // disabled={isUploading}
                >
                  {/* {isUploading && <Spinner intent="primary" size={16} />} */}
                  {"Отправить"}
                </PlanrButton>
                <PlanrButton
                  size="small"
                  type="graybtn"
                  onClick={() => {
                    this.setState({
                      selectedAgents: [],
                      selectedCategory: null,
                      selectedMoveMessage: null,
                      uploadedFiles: [],
                      subject: "",
                      message: "",
                      isReply: false,
                    });
                  }}
                >
                  Отмена
                </PlanrButton>
              </div>
            }
          />
        </div>
      </>
    );
  }
  uploadFile = async (f: any) => {
    const file = await this.props.store.uploadFile(f);
    if (file) {
      let oldFiles = [...this.state.uploadedFiles];
      oldFiles.push(file);
      this.setState({ uploadedFiles: oldFiles });
    }
  };

  onOrderMove = (id: string) => {
    this.setState({ selectedMoveMessage: id });
    this.toggleOpenOrderMove();
  };
  onMessageAnswer = (message: OrderPostType) => {
    let order = null;
    if (message.order) {
      const ord = this.props.orderDictionary.orders.find((r) => r.id === message.order?.id);
      order = { id: message.order.id, label: ord ? ord.fullInventoryNumber + " " + ord.label : "" };
    }

    let selectedAgents = message.recipients.map((r) => ({ id: r.id, label: `${r.name} <${r.email}>` }));
    this.setState({
      subject: "Re: " + message.subject,
      selectedOrder: order,
      selectedAgents,
    });
  };

  closeOrderMove = () => {
    this.setState({ selectedMoveMessage: null, selectedMoveOrder: null });
    this.toggleOpenOrderMove();
  };

  toggleOpenOrderMove = () => {
    this.setState({ openOrderMove: !this.state.openOrderMove });
  };

  onRemove = (f: any) => {
    let oldFiles = [...this.state.uploadedFiles];
    const index = oldFiles.findIndex((file) => file.fileId === f.fileId);
    if (index > -1) {
      oldFiles.splice(index, 1);
      this.setState({ uploadedFiles: oldFiles });
    }
  };
}

export default observer(RequestEmail);

interface RequestEmailProps {
  categories: AgentsCategoriesDictionaryType;
  store: OrderPostStoreType;
  toggleClose: () => void;
  baseUrl: string;
  orderId?: string;
  agentsList: any[];
  orderDictionary: OrderDictionaryType;
}

interface RequestEmailState {
  load: boolean;
  openOrderMove: boolean;
  loadMails: boolean;
  changeDate: boolean;
  isReply: boolean;
  selectedCategory: null | SelectItem;
  selectedMoveOrder: null | SelectItem;
  selectedMoveMessage: null | string;
  selectedOrder: null | SelectItem;
  selectedAgents: SelectItem[];
  uploadedFiles: FileBase[];
  subject: string;
  message: string;
}

const MessageItem = observer(
  ({
    message,
    baseUrl,
    onOrverMove,
    onMessageAnswer,
  }: {
    message: OrderPostType;
    baseUrl: string;
    onOrverMove: (id: string) => void;
    onMessageAnswer: (message: OrderPostType) => void;
  }) => {
    return (
      <div className={message.isRequest ? styles.messageRequest : styles.messageAnswer}>
        <div className={styles.answerBtns}>
          {!message.order && (
            <PlanrButton
              type={message.isRequest ? "lightblueish" : "lightgreenish"}
              size="small"
              icon="general-attach"
              title="Прикрепить к договору"
              className={styles.answerBtn}
              onClick={() => onOrverMove(message.id)}
            />
          )}
          {/* {!message.isRequest && (
            <PlanrButton
              type="lightgreenish"
              size="small"
              icon="general-save"
              title="Ответить"
              className={styles.answerBtn}
              onClick={() => onMessageAnswer(message)}
            />
          )} */}
        </div>
        <div className={styles.messageRow}>
          <div className={styles.shortInfo}>{message.isRequest ? "Запрос" : "Ответ"}</div>
          <div className={styles.messageText} style={{ color: "#ea561e" }}>{`${message.date} ${message.time}`}</div>
        </div>

        {message.isRequest && (
          <div className={styles.messageRow}>
            <div className={styles.shortInfo}>Отправитель:</div>
            <div className={styles.messageText}>{message.employer?.name}</div>
          </div>
        )}

        <div className={styles.messageRow}>
          <div className={styles.shortInfo}>{message.isRequest ? "Получатель" : "Отправитель"}:</div>
          <div style={{ color: "#7c36d7" }}>
            {message.recipients.map((rec) => (
              <div key={rec.id}>{`${rec.name} <${rec.email}>; `}</div>
            ))}
          </div>
        </div>

        <div className={styles.messageRow}>
          <div className={styles.shortInfo}>Тема:</div>
          <div className={styles.messageText}>{message.subject}</div>
        </div>

        <div className={styles.messageRow} style={{ marginTop: "8px" }}>
          <div className={styles.shortInfo}>Сообщение:</div>
          <div dangerouslySetInnerHTML={{ __html: message.message }} />
          {/* <div className={styles.messageText}>{message.message}</div> */}
        </div>

        {message.attachments.length > 0 && (
          <div className={styles.messageRow}>
            <div className={styles.shortInfo}>Файлы:</div>
            <div className={styles.messageText}>
              {message.attachments.map((att) => {
                const file = { fileId: att.id, fileName: att.name };
                return (
                  <FileLink
                    key={att.id}
                    baseUrl={baseUrl}
                    file={file}
                    readOnly
                    onRemove={eat}
                    style={{ borderColor: message.isRequest ? "#36acd7" : "#1dd278", borderRadius: "4px" }}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
);
