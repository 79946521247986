import React from "react";
import { OTHER_WORKS } from "../constants";
import { Caret } from "modules/common/components/collapse/Caret";
import { Collapse } from "@blueprintjs/core";
import { Block } from "./Block";
import styles from "./CategoryBlock.module.scss";
import { observer } from "mobx-react";
import { ObjectsLinkSnapshotType, WorkResultStoreType } from "modules/orders-manage/models/order-work-result";

type TObject = ObjectsLinkSnapshotType;

class CategoryBlockBase extends React.Component<CategoryBlockProps> {
    render() {
        const {
            category,
            onToggleCollapse,
            collapsed,
            objects,
            store,
            orderId,
            baseUrl,
            readOnly,
            oldObjects,
            canDownload,
        } = this.props;

        return (
            <div className={styles.categoryBlock}>
                <h1 className="planr-block-header collapser" onClick={onToggleCollapse}>
                    {category || OTHER_WORKS}

                    <Caret collapsed={collapsed} />
                </h1>

                <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                    <div className="collapse">
                        <div className="columns-vertical">
                            {objects.map((object, i) => {
                                const units = object.units;
                                const oldUnits = oldObjects[i].units;
                                return (
                                    <div key={i} className={styles.content}>
                                        <div>
                                            {category && (
                                                <div className={styles.objectTitle}>{object.name || "Общее"}</div>
                                            )}
                                            <Block
                                                readOnly={readOnly}
                                                category={category}
                                                units={units}
                                                oldUnits={oldUnits}
                                                store={store}
                                                canDownload={canDownload}
                                                orderId={orderId}
                                                baseUrl={baseUrl}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }
}

export const CategoryBlock = observer(CategoryBlockBase);

interface CategoryBlockProps {
    baseUrl: string;
    category: string;
    objects: TObject[];
    oldObjects: TObject[];
    collapsed: boolean;
    canDownload: boolean;
    onToggleCollapse: () => void;
    store: WorkResultStoreType;
    orderId: string;
    readOnly: boolean;
}
