import React from "react";
import { EmployerDictionaryItemType } from "modules/spending/employee/models/employee-dictionary";
import { DictionaryItemType } from "modules/common/models/entity";
import {
    buildOptions,
    SimpleSelect,
    renderSingleOption,
    DefaultSelectedOption,
    filterItemPredicate,
} from "modules/common/services/form/select";
import { Classes } from "@blueprintjs/core";
import { texts } from "modules/common/texts";
import { FieldProps } from "formik";

export const EmployerSelect: React.FC<EmployerSelectProps> = ({
    value,
    field,
    saved,
    employee,
    disabled,
    onClear,
    onItemSelect,
    matchTargetWidth,
    popoverClassName,
}) => {
    const val = onItemSelect ? value : field.field.value;
    const currentOptions = buildOptions(saved, employee, true);
    const option = currentOptions[val] || saved;
    const isEmpty = !employee.length;
    const handler = (item: SelectItem) => {
        field.form.setFieldValue(field.field.name, item.id);
        field.form.setFieldTouched(field.field.name, true);
    };

    return (
        <SimpleSelect
            className={`full-width-select ${Classes.FILL}`}
            filterable={true}
            activeItem={option}
            items={Object.values(currentOptions)}
            itemRenderer={renderSingleOption}
            inputProps={{
                ...field.field,
                placeholder: texts.search,
            }}
            onItemSelect={onItemSelect || handler}
            disabled={isEmpty || disabled}
            itemPredicate={filterItemPredicate}
            matchTargetWidth={matchTargetWidth}
            popoverProps={{
                popoverClassName,
            }}
        >
            <DefaultSelectedOption option={option} empty={isEmpty} onClear={disabled ? undefined : onClear} />
        </SimpleSelect>
    );
};

interface EmployerSelectProps {
    saved: DictionaryItemType | null;
    employee: EmployerDictionaryItemType[];
    field: FieldProps;
    disabled?: boolean;
    onClear?: () => void;
    value?: string;
    onItemSelect?: (item: SelectItem) => void;
    matchTargetWidth?: boolean;
    popoverClassName?: string;
}
