import React from "react";
import { observer } from "mobx-react";
import styles from "./IpdTypesList.module.scss";
import { columns } from "./columns";
import { saveAgGridColumnSize } from "modules/common/services/table/size-storage";
import { DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { agGridEntityContextMenu } from "modules/common/components/table/EntityContextMenu";
import { IpdTypeList, IpdTypesListType } from "../../models/ipd-type-list";
import { IpdTypeSnapshotType, formatIpdType } from "../../models/ipd-type";
import copyToClipboard from "copy-to-clipboard";
import {
  GridReadyEvent,
  RowClickedEvent,
  GridOptions,
  CellContextMenuEvent,
  GetContextMenuItemsParams,
} from "@ag-grid-community/core";
import { agGridLocale } from "modules/root/services/locale";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { MAX_INT } from "modules/common/constants";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { PlanrButton } from "modules/common/components/planr/button/Button";

const modules = [...defaultModules, MenuModule];
const TABLE_NAME = IpdTypeList.name;
const sizeSaver = saveAgGridColumnSize(TABLE_NAME);
const config = columns(TABLE_NAME);

export const IpdTypesList = observer(
  class extends React.Component<IpdTypesListProps> {
    private options: GridOptions = DefaultAgGridOptions();

    render() {
      const { store, onSelected } = this.props;

      return (
        <RemoveConfirmation<IpdTypeSnapshotType>
          observation={store.data}
          what={this.removeLabel}
          onConfirmed={this.removeStage}
          render={({ confirmRemoving }) => {
            const getContextMenuItems = ({ node }: GetContextMenuItemsParams) =>
              agGridEntityContextMenu({
                onCopy: () => copyToClipboard(formatIpdType(node.data), { format: "text/plain" }),
                onChange: () => onSelected(getRowNodeId(node.data)),
                onRemove: () => confirmRemoving(node.data),
              });

            return (
              <>
                <GridTheme bordered={true} className={`designed ${styles.grid}`}>
                  <AgGridReact
                    rowStyle={{
                      borderBottom: "1 px solid #EDF0F2",
                      fontFamily: "GothamPro, Arial, sans-serif",
                      color: "#00273D",
                      height: "32px",
                      fontSize: "12px",
                    }}
                    rowSelection="single"
                    suppressCellSelection={true}
                    enableCellTextSelection={true}
                    columnDefs={config}
                    rowData={store.data}
                    localeText={agGridLocale}
                    onRowDoubleClicked={this.onRowSelected}
                    getRowNodeId={getRowNodeId}
                    getContextMenuItems={getContextMenuItems}
                    onGridReady={this.onGridReady}
                    onCellContextMenu={this.onCellContextMenu}
                    onSortChanged={store.sorter.resorted}
                    onColumnResized={sizeSaver}
                    gridOptions={this.options}
                    modules={modules}
                    rowBuffer={MAX_INT}
                  />
                </GridTheme>
                <div className={styles.cardList}>
                  {store.data.map((row) => {
                    return (
                      <div key={row.id}>
                        <div className={styles.card}>
                          <div className={styles.mid}>
                            <div className={styles.label}>Наименование:</div>
                            <div className={styles.row}>{row.name}</div>

                            {row.description && (
                              <>
                                <div className={styles.label}>Описание:</div>
                                <div className={styles.row}>{row.description}</div>
                              </>
                            )}
                          </div>
                          <div className={styles.edit}>
                            <PlanrButton
                              type="lightblueish"
                              icon="general-edit"
                              size="small"
                              onClick={() => {
                                this.props.onSelected(row.id);
                              }}
                            />
                            <PlanrButton
                              type="neutral"
                              icon={"general-trash"}
                              size="small"
                              style={{ marginLeft: "0px", marginTop: "5px", color: "#e31818" }}
                              onClick={() => {
                                confirmRemoving(row);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            );
          }}
        />
      );
    }

    removeLabel = (type: IpdTypeSnapshotType) => `ИРД ${type.name}`;

    removeStage = async (type: IpdTypeSnapshotType) => {
      const { store, onRemoved } = this.props;
      const success = await store.delete(type);
      success && onRemoved();
    };

    onCellContextMenu = ({ node }: CellContextMenuEvent) => {
      node && node.setSelected(true, true);
    };

    onRowSelected = ({ data }: RowClickedEvent) => {
      const { onSelected } = this.props;
      onSelected(getRowNodeId(data));
    };

    onGridReady = async ({ api }: GridReadyEvent) => {
      const { sorter } = this.props.store;
      setTimeout(() => api.setSortModel(sorter.agGridSortModel));
    };
  }
);

const getRowNodeId = (e: IpdTypeSnapshotType) => e.id;

interface IpdTypesListProps {
  onRemoved: () => void;
  onSelected: (id: string) => void;
  store: IpdTypesListType;
}
