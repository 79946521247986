import React from "react";
import { MessageType } from "modules/main/models/mesages-store";
import { observer } from "mobx-react";
import { Divider } from "@blueprintjs/core";

import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

export const SimpleMessage = observer(
    class extends React.Component<SimpleMessageProps> {
        render() {
            const { message, children } = this.props;
            return (
                <>
                    <div className="message">
                        <div className="date">{message.created}</div>

                        <div
                            className={`content ${message.readDate ? "read" : ""}`}
                            title="Пометить как прочитанное"
                            onClick={this.mark}
                        >
                            <span>{message.fullText}</span>
                            {children ? <>&nbsp;</> : null}
                            {children}
                        </div>
                        <div className="checker">
                            <GeneralIcon
                                type={!this.props.selected ? "general-zero-checkbox" : "general-checkbox"}
                                onClick={this.onCheck}
                            />
                        </div>
                    </div>
                    <Divider />
                </>
            );
        }

        mark = () => this.props.markAsRead(this.props.message);

        onCheck = () => this.props.onSelect(!this.props.selected);
    }
);

export interface SimpleMessageProps {
    message: MessageType;
    markAsRead: (message: MessageType) => void;
    onSelect: (selected: boolean) => void;
    selected: boolean;
}
