import { types } from "mobx-state-tree";
import { ClientList, Client, initialState as emptyList } from "./client-list";
import { ClientType, initialState as emptyClient } from "./client";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { Queryable } from "modules/common/models/queryable";
import { flow } from "modules/common/models/flow";
import { base64ToBlob, convertTextToImage, printImage } from "modules/common/services/files";
import { getSiteAddress } from "modules/common/services/communication/http";
import { apiUrls } from "modules/common/services/communication/urls";
import saveAs from "file-saver";

export const ClientsStore = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            list: ClientList,
            details: Client,
        })
    )
    .actions((self) => ({
        printCredentials: flow(function* (user: ClientType) {
            const credentials = yield user.getCredentials();

            if (credentials) {
                const dataURL = convertTextToImage([
                    `Логин : ${credentials.login}`,
                    `Пароль: ${credentials.password}`,
                    `Ссылка: ${getSiteAddress()}`,
                ]);

                printImage(dataURL);
            }
        }),
        printDocCredentials: flow(function* (user: ClientType, order: SelectItem | null) {
            if (self.details.isNewlyCreated) {
                return;
            }
            try {
                if (user) {
                    if (order) {
                        const file: FileDescription = yield self.transport.post<any>(apiUrls.clients.print(user.id), {
                            orderId: order.id,
                        });

                        const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
                        saveAs(blob, file.name);
                        return true;
                    } else {
                        self.notify.error("Необходимо указать договор с заказчиком!");
                        return false;
                    }
                } else {
                    self.notify.error("Необходимо указать раздел!");
                    return false;
                }
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .named("ClientsStore");

export const initialState = (): typeof ClientsStore.SnapshotType => ({
    list: emptyList(),
    details: emptyClient(),
    pureQuery: "",
    query: "",
});

export type ClientsStoreType = typeof ClientsStore.Type;
