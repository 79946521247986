import moment from "moment";

export const tomorrow = () => {
    return moment().add(1, "day").toDate();
};

export const afterTomorrow = () => {
    return moment().add(2, "day").toDate();
};

export const year = () => {
    return moment().add(1, "year").toDate();
};

export const asTimestamp = (date: Date) => Math.floor(date.getTime() / 1000);
