import React from "react";
import { isPdf } from "modules/common/services/files";
import { FileMetadataType } from "modules/common/models/file-metadata";
import { apiUrls } from "modules/common/services/communication/urls";
import { observer } from "mobx-react";

export const ScrollPdfViewer = observer(
  class extends React.PureComponent<ScrollPdfViewerProps> {
    getUrl = (document: FileMetadataType, toolbox?: boolean) => {
      const source = isPdf(document.previewMimeType || "") ? "preview" : "content";
      const baseUrl = document.baseUrl;

      const src =
        baseUrl +
        apiUrls.application.files.content(document.id, "preview.pdf", source) +
        `#toolbar=${toolbox ? "1" : "0"}&view=fit`;

      return src;
    };

    render() {
      const src = this.getUrl(this.props.document, this.props.toolbox);

      return (
        <embed className="rost scroll-pdf-viewer" object-fit="contain" src={src} key={this.props.document.id}></embed>
      );
    }
  }
);

interface Base64PdfViewerProps {
  document: string;
  toolbox?: boolean;
}

interface ScrollPdfViewerProps {
  document: FileMetadataType;
  toolbox?: boolean;
}
