import { types, applySnapshot, isAlive } from "mobx-state-tree";
import { isNewlyCreated, CategoryDictionaryItem } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import Schema from "../components/details/category-validation";

import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";
import { getFieldLabel } from "modules/common/services/form/fields";

export const DeprGroupDictionaryItem = types
    .compose(
        CategoryDictionaryItem,
        types.model({
            order: types.maybeNull(types.number),
            name: types.string,
            maxLifetime: types.number,
        })
    )
    .views((self) => ({
        get label() {
            return self.name;
        },
    }))
    .named("DeprGroupDictionaryItem");

const DeprGroupBase = types.compose(
    Transport,
    Notificator,
    types
        .model({
            id: types.string,
            minYears: types.number,
            maxYears: types.number,
            minLifetime: types.number,
            maxLifetime: types.number,
            name: types.string,
        })
        .views((self) => ({
            get isNewlyCreated() {
                return isNewlyCreated(self.id);
            },
        }))
);

export const DeprGroup = DeprGroupBase.actions((self) => ({
    load: flow(function* (id: string) {
        try {
            const snapshot = isNewlyCreated(id)
                ? initialState()
                : yield self.transport.get<DeprGroupSnapshotType>(apiUrls.deprGroups.details(id));

            applySnapshot(self, snapshot);
        } catch (er) {
            self.notify.error(er);
        }
    }),

    save: flow(function* (model: any) {
        try {
            const snapshot = self.isNewlyCreated
                ? yield self.transport.put<DeprGroupSnapshotType>(apiUrls.deprGroups.create(), { ...model })
                : yield self.transport.post<DeprGroupSnapshotType>(apiUrls.deprGroups.update(self.id), {
                      ...model,
                  });

            isAlive(self) && applySnapshot(self, snapshot);
            self.notify.success(texts.messages.saved);

            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    delete: flow(function* () {
        if (self.isNewlyCreated) {
            return true;
        }

        try {
            yield self.transport.delete<boolean>(apiUrls.deprGroups.delete(self.id));
            self.notify.success(texts.messages.removed);

            isAlive(self) && applySnapshot(self, initialState());
            return true;
        } catch (er) {
            self.notify.error(er);
            return false;
        }
    }),
})).named("DeprGroup");

export type DeprGroupDictionaryItemType = typeof DeprGroupDictionaryItem.Type;
export type DeprGroupSnapshotType = typeof DeprGroupBase.SnapshotType;
export type DeprGroupType = typeof DeprGroup.Type;

export const initialState = (): DeprGroupSnapshotType => ({
    id: EMPTY_OBJECT_ID,
    minYears: 0,
    maxYears: 0,
    minLifetime: 0,
    maxLifetime: 0,
    name: "",
});

export const fields = {
    minYears: nameof((a: DeprGroupType) => a.minYears) as string,
    maxYears: nameof((a: DeprGroupType) => a.maxYears) as string,
    minLifetime: nameof((a: DeprGroupType) => a.minLifetime) as string,
    maxLifetime: nameof((a: DeprGroupType) => a.maxLifetime) as string,
    name: nameof((a: DeprGroupType) => a.name) as string,
};

export function formatDeprGroup(stage: DeprGroupSnapshotType | null) {
    let result = "";

    if (stage) {
        const schema = Schema();

        result += `${getFieldLabel(fields.name, schema, null)}: ${stage.name}\n`;
    }

    return result;
}
