import { types, applySnapshot } from 'mobx-state-tree';
import { Transport } from 'modules/common/models/transport';
import { Notificator } from 'modules/common/models/notificator';
import { apiUrls } from 'modules/common/services/communication/urls';
import { flow } from 'modules/common/models/flow';
import { ProductionStageDictionaryItem, ProductionStageDictionaryItemType } from './production-stage';

export const ProductionStageDictionary = types.compose(
    Transport, Notificator, types.model({
        stages: types.array(ProductionStageDictionaryItem),
    }))
    .actions((self) => ({
        load: flow(function* (){
            try {
                const data: ProductionStageDictionaryItemType[] = yield self.transport.get<any>(apiUrls.productionStages.dictionary());
                applySnapshot(self.stages, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        })
    }))
    .views((self) => ({
        get isEmpty () {
            return !self.stages.length;
        }
    }))
    .named('ProductionStageDictionary');

export const initialState = (): ProductionStageDictionarySnapshotType => ({
    stages: []
});

export type ProductionStageDictionaryType = typeof ProductionStageDictionary.Type;
export type ProductionStageDictionarySnapshotType = typeof ProductionStageDictionary.SnapshotType;
