import axios, { AxiosResponse, AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { texts } from "modules/common/texts";
import { apiUrls } from "./urls";

type RecoveryPromise = Promise<any> & { pending: boolean };

export class HttpError extends Error {
  constructor(public code: number, message?: string) {
    super(message);

    // restore prototype chain
    const actualProto = new.target.prototype;

    Object.setPrototypeOf(this, actualProto);
  }
}

export const buildHttpTransport = (apiConfig: ApiConfig, onError: ErrorHandler): HttpTransport => {
  let authRecovered: RecoveryPromise;
  let authorized: Function;

  const baseUrl = apiConfig.url;
  const instance = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
  });

  const recovering = () => authRecovered;
  const startRecovering = (done = false) => {
    if (recovering()?.pending) {
      return;
    }

    const result: any = new Promise((resolve) => {
      authorized = () => {
        result.pending = false;
        resolve(true);
      };
    });

    result.pending = !done;
    done && authorized();

    authRecovered = result;
  };

  const successHandler = () => (resp: AxiosResponse) => {
    authorized();
    return resp.data;
  };

  const errorHandler = () => async (err: AxiosError) => {
    const { response } = err;

    const status = response ? response.status : -1;
    const message = response && response.data ? response.data + "" : texts.error;

    if (status === 401 && err.config && err.config.url !== apiUrls.auth.login) {
      startRecovering();
      onError(status, message);

      return instance.request(err.config);
    }

    return Promise.reject(new HttpError(status, message));
  };

  const success = successHandler();
  const failure = errorHandler();

  instance.interceptors.response.use(success, failure);
  instance.interceptors.request.use(async (config) => {
    if (config.url !== apiUrls.auth.login) {
      await recovering();
    }

    // Добавляем версию в запросы
    config.headers = config.headers || {};
    config.headers.AppVersion = apiConfig.version;
    return config;
  });

  startRecovering(true);
  return instance;
};

export const getSiteAddress = () => {
  const protocol = window.location.protocol;
  const host = window.location.host;

  return `${protocol}//${host}`;
};

export const openInNewTab = (path: string, base = "") => {
  const url = base + path;
  window.open(url, "_blank", "");
};

type ErrorHandler = (status: number, message: string) => void;

export interface HttpTransport extends AxiosInstance {
  // override methods definitions beacuse of custom successHandler
  request<R = any>(config: AxiosRequestConfig): Promise<R>;
  get<R = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
  delete<R = any>(url: string, config?: AxiosRequestConfig, data?: any): Promise<R>;
  head<R = any>(url: string, config?: AxiosRequestConfig): Promise<R>;
  post<R = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
  put<R = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
  patch<R = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
}

export const NotAuthorized = 401;
export const ConfirmationWaiting = 428;
export const Locked = 423;
