import lscache from "lscache";
import { ROOT } from "../constants";

const keyBuilder = (prefix: string) => {
    return (key: string) => `${prefix}_${key}`;
};

export const buildCacheStorage = (prefix: string) => {
    const kk = keyBuilder(prefix);

    return {
        get<TType>(key: string, deflt: TType): TType {
            const value = lscache.get(kk(key));
            return value === null ? deflt : value;
        },

        set<TType>(key: string, value: TType) {
            lscache.set(kk(key), value);
        },
    };
};

export const RootCache = buildCacheStorage(ROOT);
