import { types } from "mobx-state-tree";
import { OrderFile } from "./order-file";

export const OrderBase = types
    .model({
        startDate: types.maybeNull(types.string),
        orderNumber: types.string,
        additions: types.array(OrderFile),
    })
    .named("OrderBase");
