export default {
    INVENTORY_READ: "DICTIONARY_MATERIALVALUES_READ",
    INVENTORY_EDIT: "DICTIONARY_MATERIALVALUES_EDIT",

    PRODUCTIONSTAGE_READ: "DICTIONARY_PRODUCTIONSTAGE_READ",
    PRODUCTIONSTAGE_EDIT: "DICTIONARY_PRODUCTIONSTAGE_EDIT",

    IPDTYPE_READ: "DICTIONARY_IPDTYPE_READ",
    IPDTYPE_EDIT: "DICTIONARY_IPDTYPE_EDIT",

    AGENTSCATEGORIES_READ: "DICTIONARY_AGENTSCATEGORIES_READ",
    AGENTSCATEGORIES_EDIT: "DICTIONARY_AGENTSCATEGORIES_EDIT",

    ORDERINDICATOR_READ: "DICTIONARY_ORDERINDICATOR_READ",
    ORDERINDICATOR_EDIT: "DICTIONARY_ORDERINDICATOR_EDIT",

    ORDERSTATUS_READ: "DICTIONARY_ORDERSTATUS_READ",
    ORDERSTATUS_EDIT: "DICTIONARY_ORDERSTATUS_EDIT",

    ORDERTYPE_READ: "DICTIONARY_ORDERTYPE_READ",
    ORDERTYPE_EDIT: "DICTIONARY_ORDERTYPE_EDIT",

    WORKTYPE_READ: "DICTIONARY_WORKTYPE_READ",
    WORKTYPE_EDIT: "DICTIONARY_WORKTYPE_EDIT",

    OVERHEADTYPE_READ: "DICTIONARY_OVERHEADTYPE_READ",
    OVERHEADTPYE_EDIT: "DICTIONARY_OVERHEADTPYE_EDIT",

    PROJECTPORTFOLIO_READ: "DICTIONARY_PROJECTPORTFOLIO_READ",
    PROJECTPORTFOLIO_EDIT: "DICTIONARY_PROJECTPORTFOLIO_EDIT",

    BANKDETAILS_READ: "DICTIONARY_BANKDETAILS_READ",
    BANKDETAILS_EDIT: "DICTIONARY_BANKDETAILS_EDIT",
};
