import { PlanrButton } from "modules/common/components/planr/button/Button";
import { DATE_FORMAT } from "modules/common/constants";
import { texts } from "modules/common/texts";
import { OrderFileLabel, OrderFileSnapshotType, OrderFileSorter } from "modules/orders-manage/models/order-file";
import { IdFactory, UploaderFatory } from "modules/orders-manage/types";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { Schema } from "yup";
import { OrderBase } from "./OrderBase";
import { OrderFiles } from "./OrderFiles";
import styles from "./Summary.module.scss";

const SummaryOrderLabel = OrderFileLabel("Договор");

/** Доп соглашения */
export const OrderBaseWithName = ({
  baseUrl,
  readOnly,
  files,
  onRemove,
  onAdd,
  factory,
  schema,
  upload,
  newId,
}: OrderBaseWithNameProps) => {
  const [order, setOrderBaseWithName] = useState<OrderFileSnapshotType>();

  const addOrderBaseWithName = useCallback(async () => {
    setOrderBaseWithName(await factory.emptyOrderBaseWithName());
  }, [factory]);

  const saveOrderBaseWithName = useCallback(
    (file: OrderFileSnapshotType) => {
      if (order) {
        onAdd({
          ...order,
          startDate: moment(order.startDate).format(DATE_FORMAT),
          fileId: file.fileId,
        });
        setOrderBaseWithName(undefined);
      }
    },
    [onAdd, order]
  );

  return (
    <>
      {/* Доп соглашение */}
      <div className={styles.columnHeader} style={{ marginTop: "15px", marginBottom: "0px" }}>
        {"Дополнительные договоры"}
      </div>

      {/* Список файлов доп соглашений */}
      <div className={styles.orderFilesBlock}>
        <OrderFiles<OrderFileSnapshotType>
          readOnly={readOnly}
          removeLabel={SummaryOrderLabel}
          fileLabel={SummaryOrderLabel}
          files={files}
          baseUrl={baseUrl}
          sorter={OrderFileSorter}
          onRemove={onRemove}
        />
      </div>
      {order && (
        <OrderBase
          inline={false}
          what="Договор"
          schema={schema}
          number={order.orderNumber}
          date={order.startDate as any}
          onChange={(prop, value) => {
            const state: any = { ...order, [prop]: value };
            setOrderBaseWithName(state);
          }}
          addOrderFile={saveOrderBaseWithName}
          readOnly={readOnly}
          upload={upload}
          newId={newId}
        />
      )}

      {!order && !readOnly && (
        <PlanrButton
          type="dashed"
          icon="general-plus-big"
          onClick={addOrderBaseWithName}
          style={{ width: "358px", marginTop: "-20px", marginBottom: "10px" }}
        >
          {texts.add}
        </PlanrButton>
      )}
    </>
  );
};

interface OrderBaseWithNameProps extends UploaderFatory, IdFactory {
  schema: Schema<any>;
  baseUrl: string;
  readOnly?: boolean;
  files: OrderFileSnapshotType[];
  onRemove: (guid: string) => void;
  onAdd: (file: OrderFileSnapshotType) => void;
  factory: OrderBaseWithNameFactory;
}

export interface OrderBaseWithNameFactory {
  emptyOrderBaseWithName: () => Promise<OrderFileSnapshotType>;
}
