import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import moment from "moment";
import { DATE_TIME_FORMAT, MAX_INT } from "modules/common/constants";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { nameof } from "modules/common/services/typescript";
import { Constants } from "modules/root/models/constants";
import { sortBy, groupBy, sumBy } from "lodash";
import { capitalize } from "modules/common/services/strings";
import { toJsonHard } from "modules/common/services/mobx/serialize";
import { texts } from "modules/common/texts";
import { printPdf, base64ToBlob } from "modules/common/services/files";
import { orderTitle } from "modules/orders-manage/models/order";
import { Queryable } from "modules/common/models/queryable";
import { saveAs } from "file-saver";
import { FinanceRequest, FinanceRequestSnapshotType, FinanceRequestType } from "./request";

export type GroupingType = "project" | "period" | "outsourcer";
export type TabType = "new" | "accepted" | "paid";

const TabStore = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            query: types.string,
            pureQuery: types.string,
            rows: types.array(FinanceRequest),
            grouping: types.enumeration(["project", "period", "outsourcer"]),
            type: types.enumeration(["new", "accepted", "paid"]),
            loader: types.number,
        })
    )
    .views((self) => ({
        get searched() {
            if (self.pureQuery.length < 1) {
                return self.rows;
            }

            return self.rows.filter((r) => r.found(self.pureQuery));
        },

        get isLoading() {
            return self.loader > 0;
        },
    }))
    .views((self) => ({
        get rowsSnapshot() {
            return self.searched.map((row) => ({
                ...toJsonHard(row),
                workTypeName: row.workTypeName,
                sortableDate: row.sortableDate,
                timestamp: +row.dateAsDate,
            }));
        },
    }))
    .views((self) => ({
        get totalSum() {
            return sumBy(self.searched, (r) => r.sum);
        },

        get projectGrouping() {
            return groupData(
                self.rowsSnapshot,
                (r) => (r.order ? -r.order.inventoryNumber : -MAX_INT),
                (r) => (r.order ? orderTitle(r.order) : "Накладные расходы")
            );
        },

        get periodGrouping() {
            return groupData(
                self.rowsSnapshot,
                (r) => -r.timestamp,
                (r) => {
                    return capitalize(moment(r.date, DATE_TIME_FORMAT).format("MMMM YYYY"));
                }
            );
        },

        get outsourcerGrouping() {
            return groupData(
                self.rowsSnapshot,
                (r) => r.outsourcer?.label || "",
                (r) => {
                    return r.order ? r.outsourcer?.label || "" : "Накладные расходы";
                }
            );
        },
    }))
    .views((self) => ({
        get data() {
            switch (self.grouping) {
                case "outsourcer":
                    return self.outsourcerGrouping;
                case "period":
                    return self.periodGrouping;
                default:
                    return self.projectGrouping;
            }
        },

        get status() {
            switch (self.type) {
                case "new":
                    return Constants.orderPaymentStatusNew;
                case "paid":
                    return Constants.orderPaymentStatusPaid;
                default:
                    return Constants.orderPaymentStatusAccepted;
            }
        },
    }))
    .actions((self) => ({
        print: flow(function* () {
            self.loader++;

            try {
                const file: FileDescription = yield self.transport.post<any>(apiUrls.financeRequests.export, {
                    type: self.status,
                    grouping: self.grouping,
                    asPdf: true,
                });

                const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
                if (blob) {
                    const fileURL = URL.createObjectURL(blob);
                    const printer = printPdf(fileURL, true);
                    if (printer) {
                        printer.onclose = () => URL.revokeObjectURL(fileURL);
                    }
                }
            } finally {
                self.loader--;
            }
        }),

        export: flow(function* () {
            self.loader++;

            try {
                const file: FileDescription = yield self.transport.post<any>(apiUrls.financeRequests.export, {
                    type: self.status,
                    grouping: self.grouping,
                });

                const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
                saveAs(blob, file.name);
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.loader--;
            }
        }),
    }))
    .actions((self) => {
        return {
            setGrouping(grouping: GroupingType) {
                self.grouping = grouping;
            },

            setRows(rows: FinanceRequestSnapshotType[]) {
                applySnapshot(self.rows, rows);
            },

            removeRow(row: FinanceRequestType) {
                if (row.status !== self.status) {
                    const mine = self.rows.indexOf(row);
                    if (mine >= 0) {
                        applySnapshot(
                            self.rows,
                            self.rows.filter((r) => r.id !== row.id)
                        );
                    }
                }
            },

            addRow(row: FinanceRequestType) {
                if (row.status === self.status) {
                    const mine = self.rows.indexOf(row);
                    if (mine < 0) {
                        self.rows.push(getSnapshot(row));
                    }
                }
            },
        };
    })
    .named("FinanceRequestsTab");

export const FinanceRequestsStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            immediateMap: types.map(types.boolean),
            inProccess: types.boolean,

            new: TabStore,
            accepted: TabStore,
        })
    )
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: FinanceRequestSnapshotType[] = yield self.transport.get<any>(apiUrls.financeRequests.list);

                const newRows = data.filter((r) => r.status === self.new.status);
                const acceptedRows = data.filter((r) => r.status === self.accepted.status);

                self.new.setRows(newRows);
                self.accepted.setRows(acceptedRows);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .views((self) => ({
        get rows() {
            return [...self.new.rows, ...self.accepted.rows];
        },
    }))
    .actions((self) => ({
        togglePaymentStatus: flow(function* (id: string) {
            if (self.inProccess) {
                return;
            }

            try {
                self.inProccess = true;

                const payment = self.rows.find((r) => r.id === id);
                if (!payment) {
                    return;
                }

                const immediate = self.immediateMap.get(id);
                const url = payment.isProduction
                    ? apiUrls.financeRequests.toggle(id)
                    : apiUrls.spendings.overhead.toggle(id);

                yield self.transport.post<any>(url, {
                    immediate: immediate === undefined ? false : immediate,
                    currentStatus: [payment.status],
                    withoutControl: true,
                });

                self.notify.success(texts.messages.saved);

                yield self.load();
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.inProccess = false;
            }
        }),

        cancelPaymentRequest: flow(function* (id: string) {
            if (self.inProccess) {
                return;
            }

            try {
                self.inProccess = true;

                const payment = self.rows.find((r) => r.id === id);
                if (!payment) {
                    return;
                }

                const url = payment.isProduction
                    ? apiUrls.financeRequests.cancel(id)
                    : apiUrls.spendings.overhead.cancel(id);
                yield self.transport.post<any>(url);
                self.notify.success(texts.messages.saved);

                yield self.load();
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.inProccess = false;
            }
        }),

        changeImmediate: function (id: string, value: boolean) {
            self.immediateMap.set(id, value);
        },
    }))
    .named("FinanceRequests");

export type FinanceRequestsStoreType = typeof FinanceRequestsStore.Type;
export type FinanceRequestsStoreSnapshotType = typeof FinanceRequestsStore.SnapshotType;
export type TabStoreType = typeof TabStore.Type;

export const initialState = (): FinanceRequestsStoreSnapshotType => ({
    immediateMap: {},
    inProccess: false,
    accepted: {
        grouping: "project",
        query: "",
        pureQuery: "",
        rows: [],
        type: "accepted",
        loader: 0,
    },
    new: {
        grouping: "project",
        query: "",
        pureQuery: "",
        rows: [],
        type: "new",
        loader: 0,
    },
});

export const FinanceRequestsTabs = {
    new: nameof((s: FinanceRequestsStoreType) => s.new) as string,
    accepted: nameof((s: FinanceRequestsStoreType) => s.accepted) as string,
};

type SRow = FinanceRequestSnapshotType & { timestamp: number };
function groupData(data: SRow[], sorter: (r: SRow) => any, grouper: (r: SRow) => any) {
    const rows: SRow[] = sortBy(data, sorter);
    return groupBy(rows, grouper);
}
