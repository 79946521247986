import React from "react";
import { FileMetadataSnapshotType } from "modules/common/models/file-metadata";
import { OrderConfirmStoreType } from "../models/store";
import styles from "./Tab.module.scss";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { columns } from "./columns";
import { OrderConfirmSnapshotType } from "modules/orders-manage/models/order-confirm";
import { ConfirmsList } from "./List";
import { SidePanel } from "./SidePanel";

class Confirms extends React.Component<TabProps, TabState> {
  mounted = true;
  colDefs: any;

  constructor(props: TabProps) {
    super(props);
    this.state = { selection: null, sideLoading: false };
    this.colDefs = columns(!!props.hideProject);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { store, canManage, hideProject, canClientEdit, isActiveTab } = this.props;
    const { selection, sideLoading } = this.state;
    return (
      <div className={styles.tab}>
        <ConfirmsList
          sorter={store.sorter}
          canManage={canManage}
          onConfirmSelected={this.confirmSelected}
          current={selection}
          data={store.data}
          baseUrl={store.baseUrl}
          isActiveTab={isActiveTab}
          downloadMails={async (confirms) => {
            return await store.downloadConfirms(confirms, canClientEdit);
          }}
          filtersStatus={store.filtersStatus}
          filtersType={store.filtersType}
          printMails={store.printConfirms}
          query={store.query}
          remove={store.remove}
          resorted={store.resorted}
          save={async (mess) => {
            return await store.save(mess);
          }}
          setFiltersType={store.setFiltersType}
          setPage={store.setPage}
          setQuery={store.setQuery}
          toggleFiltersStatus={store.toggleFiltersStatus}
          setOrderId={store.setOrderId}
          upload={store.uploadFile}
          orders={store.orders}
          pager={{
            page: store.page,
            pageSize: store.pageSize,
            totalCount: store.totalCount,
          }}
          rowBuffer={store.pageSize}
          columns={this.colDefs}
          hideProject={!!hideProject}
          mailFactory={store.emptyConfirm}
        />

        <SidePanel
          selection={selection}
          onPreview={this.previewFile}
          renderer={store.renderer}
          goBack={this.goBack}
          goForward={this.goForward}
          onPrint={this.printFile}
          onDownload={this.downloadFile}
          loading={sideLoading}
          canClientEdit={canClientEdit}
        />
      </div>
    );
  }

  confirmSelected = (selection: OrderConfirmSnapshotType) => {
    if (!this.mounted) {
      return;
    }

    this.setState({ selection });
    if (selection) {
      const docs =
        selection &&
        selection.requestFiles
          .map((file) => ({ ...file, name: `Запрос: ${file.name}` }))
          .concat(selection.sourceFiles.map((file) => ({ ...file, name: `Исходник: ${file.name}` })))
          .concat(selection.resultFiles.map((file) => ({ ...file, name: `Результат: ${file.name}` })));
      if (docs.length > 0) {
        this.previewFile(docs[docs.length - 1]);
      } else {
        this.previewFile(null);
      }
    }
  };

  previewFile = (document: FileMetadataSnapshotType | null) => {
    const { renderer } = this.props.store;
    this.mounted && renderer.preview(document);
  };

  goForward = async (from: OrderConfirmSnapshotType) => {
    const seletion = await this.props.store.moveSelection(from, 1);
    if (seletion) {
      this.confirmSelected(getSnapshot(seletion));
    }
  };

  goBack = async (from: OrderConfirmSnapshotType) => {
    const seletion = await this.props.store.moveSelection(from, -1);
    if (seletion) {
      this.confirmSelected(getSnapshot(seletion));
    }
  };

  printFile = async (fileId: string) => {
    this.setState({ sideLoading: true });
    await this.props.store.printFile(fileId);
    this.setState({ sideLoading: false });
  };

  downloadFile = async (fileId: string) => {
    this.setState({ sideLoading: true });
    await this.props.store.downloadFile(fileId);
    this.setState({ sideLoading: false });
  };
}

export const ConfirmsTab = observer(Confirms);

interface TabProps {
  canManage: boolean;
  store: OrderConfirmStoreType;
  hideProject?: boolean;
  canClientEdit?: boolean;
  orderId?: string;
  isActiveTab: boolean;
}

interface TabState {
  selection: OrderConfirmSnapshotType | null;
  sideLoading: boolean;
}
