import { OrderFileLabel, OrderFileSnapshotType, OrderFileSorter } from "modules/orders-manage/models/order-file";
import { fields } from "modules/orders-manage/models/order";
import React, { useCallback, useState } from "react";
import { Schema } from "yup";
import { OrderSummaryLabels } from "../validation";
import { OrderFiles } from "./OrderFiles";
import styles from "./Summary.module.scss";
import { IdFactory, UploaderFatory } from "modules/orders-manage/types";
import { OrderBase } from "./OrderBase";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { texts } from "modules/common/texts";

const SummaryOrderLabel = OrderFileLabel("Доп. соглашение");
const labels = OrderSummaryLabels();

/** Доп соглашения */
export const Additions = ({
    baseUrl,
    readOnly,
    files,
    onRemove,
    onAdd,
    factory,
    schema,
    upload,
    newId,
}: AdditionsProps) => {
    const [addition, setAddition] = useState<OrderFileSnapshotType>();

    const addAddition = useCallback(async () => {
        setAddition(await factory.emptyAddition());
    }, [factory]);

    const saveAddition = useCallback(
        (file: OrderFileSnapshotType) => {
            if (addition) {
                onAdd({
                    ...addition,
                    fileId: file.fileId,
                });
                setAddition(undefined);
            }
        },
        [onAdd, addition]
    );

    return (
        <>
            {/* Доп соглашение */}
            <div className={styles.columnHeader} style={{ marginTop: "15px", marginBottom: "0px" }}>
                {labels[fields.additions]}
            </div>

            {/* Список файлов доп соглашений */}
            <div className={styles.orderFilesBlock}>
                <OrderFiles<OrderFileSnapshotType>
                    readOnly={readOnly}
                    removeLabel={SummaryOrderLabel}
                    fileLabel={SummaryOrderLabel}
                    files={files}
                    baseUrl={baseUrl}
                    sorter={OrderFileSorter}
                    onRemove={onRemove}
                />
            </div>
            {addition && (
                <OrderBase
                    inline={true}
                    what="Соглашение"
                    schema={schema}
                    number={addition.orderNumber}
                    date={addition.startDate as any}
                    onChange={(prop, value) => {
                        const state: any = { ...addition, [prop]: value };
                        setAddition(state);
                    }}
                    addOrderFile={saveAddition}
                    readOnly={readOnly}
                    upload={upload}
                    newId={newId}
                />
            )}

            {!addition && !readOnly && (
                <PlanrButton
                    type="dashed"
                    icon="general-plus-big"
                    onClick={addAddition}
                    style={{ width: "358px", marginTop: "-20px" }}
                >
                    {texts.add}
                </PlanrButton>
            )}
        </>
    );
};

interface AdditionsProps extends UploaderFatory, IdFactory {
    schema: Schema<any>;
    baseUrl: string;
    readOnly?: boolean;
    files: OrderFileSnapshotType[];
    onRemove: (guid: string) => void;
    onAdd: (file: OrderFileSnapshotType) => void;
    factory: AdditionFactory;
}

export interface AdditionFactory {
    emptyAddition: () => Promise<OrderFileSnapshotType>;
}
