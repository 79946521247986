import React from "react";
import { AccessStoreType, buildRows } from "../models/access-store";
import { observer } from "mobx-react";
import styles from "./AccessPage.module.scss";
import { Page } from "modules/common/components/page/Page";
import { getSnapshot } from "mobx-state-tree";
import { FunctionalityTree } from "./FunctionalityTree";
import { EmployeeTable } from "./EmployeeTable";
import { EmployeeTableHeader } from "./EmployeeTableHeader";
import { Tabs, Tab } from "@blueprintjs/core";
import { ACCESS_MENU_LABEL } from "modules/root/components/app/User";
import { can } from "modules/session/auth/access";
import Functionalities from "../../functionalities";
import { SessionType } from "modules/session/auth/models/session";
import { Roles } from "./roles/Roles";

const NestedTabs = {
    roles: "roles",
    matrix: "matrix",
};

class AccessPage extends React.Component<AccessPageProps, AccessPageState> {
    state = { currentRow: -1, currentColumn: -1, copied: null, selectedTab: NestedTabs.roles };

    componentDidMount() {
        this.props.store.load();
    }

    render() {
        const { store, session } = this.props;
        const readonly = !can(Functionalities.ACCESS_EDIT, session.access);
        const nested = this.renderInnerTabs(readonly);

        return (
            <Page className={styles.page}>
                {store.roles.length > 0 && this.state.selectedTab === NestedTabs.matrix && (
                    <div className={styles.shlepka} />
                )}

                <Tabs
                    className={`${styles.tabs} planr-tabs-navigation`}
                    id="AccessTabsPage"
                    defaultSelectedTabId="1"
                    renderActiveTabPanelOnly={true}
                >
                    <Tab id="1" title={!readonly ? ACCESS_MENU_LABEL : "Таблица разрешений"} panel={nested} />
                </Tabs>
            </Page>
        );
    }

    renderInnerTabs = (readonly: boolean) => {
        const { store, session } = this.props;
        const { currentRow, currentColumn, copied, selectedTab } = this.state;
        const data = getSnapshot(store.roles);
        const matrix = (
            <>
                <div className={styles.header}>
                    <EmployeeTableHeader
                        data={data}
                        buffer={copied}
                        onCopy={this.onCopyAccess}
                        onPaste={this.replaceAccess}
                        onSelect={this.selectColumn}
                        selectedIndex={currentColumn}
                    />
                </div>
                <div className={styles.body}>
                    <FunctionalityTree
                        currentRow={currentRow}
                        onRowSelect={this.selectRow}
                        rows={buildRows(Array.from(store.authorization))}
                    />
                    <EmployeeTable
                        data={data}
                        selectedRow={currentRow}
                        onToggle={this.setFunctionality}
                        selectedColumn={currentColumn}
                        canChange={can(Functionalities.ACCESS_EDIT, session.access)}
                    />
                </div>
            </>
        );

        return readonly ? (
            matrix
        ) : (
            <Tabs
                className={`planr-content-tabs-navigation ${styles.nestedTabs} `}
                id="AccessViewTabs"
                selectedTabId={selectedTab}
                renderActiveTabPanelOnly={true}
                onChange={this.tabSelected}
            >
                <Tab
                    id={NestedTabs.roles}
                    title={"Должности"}
                    panel={
                        <Roles
                            store={store}
                            setFunctionality={this.setFunctionality}
                            replaceAccess={this.replaceAccess}
                        />
                    }
                    className="refresh-tabbtn"
                />

                <Tab id={NestedTabs.matrix} title={"Матрица ролей"} panel={matrix} className="refresh-tabbtn" />
            </Tabs>
        );
    };

    onCopyAccess = (access: string[]) => this.setState({ copied: access });

    selectRow = (currentRow: number) => {
        const newValue = this.state.currentRow === currentRow ? -1 : currentRow;

        this.setState({ currentRow: newValue });
    };

    selectColumn = (currentColumn: number) => {
        const newValue = this.state.currentColumn === currentColumn ? -1 : currentColumn;

        this.setState({ currentColumn: newValue });
    };

    setFunctionality = async (roleId: string, functionality: string, state: boolean) => {
        const { roles } = this.props.store;
        const role = roles.find((e) => e.id === roleId);

        if (role) {
            return await role.setFunctionalityAccess(functionality, state);
        }

        return false;
    };

    replaceAccess = async (roleId: string, access: string[]) => {
        const { roles } = this.props.store;
        const role = roles.find((e) => e.id === roleId);

        if (role) {
            return await role.setWholeAccess(access);
        }

        return false;
    };

    tabSelected = (selectedTab: string) => this.setState({ selectedTab });
}

export default observer(AccessPage);

interface AccessPageProps {
    store: AccessStoreType;
    session: SessionType;
}

interface AccessPageState {
    currentColumn: number;
    currentRow: number;
    copied: string[] | null;
    selectedTab: string;
}
