import React, { useCallback, useEffect, useRef, useState } from "react";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Block } from "modules/common/components/page/Block";
import { Route, matchPath, useHistory } from "react-router";
import { History } from "history";
import { ProtectedRoute } from "modules/common/components/routing/ProtectedRoute";
import { routes } from "modules/common/routes";
import { observer } from "mobx-react";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { CategoryDetails } from "./details/CategoryDetails";
import { CategorysList } from "./list/CategoriesList";
import { menuItems } from "modules/root/components/menu/MainMenu";
import { can } from "modules/session/auth/access";
import functionalities from "modules/dictionaries/functionalities";
import { Link } from "react-router-dom";
import styles from "./CategoriesPage.module.scss";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { Search } from "modules/common/components/form/Search";
import { MaterialValuesStoreType } from "../models/material-value-store";
import { ItemsTabsPage } from "./ItemsTabsPage";
import ItemsFreePage from "./MaterialValuesFreePage";
import { MaterialValueDetails } from "./details/MaterialValueDetails";
import { CategoriesStoreType } from "../models/categories-store";
import { MaterialValuesListType } from "../models/material-value-list";

const menu = menuItems();

interface CategoriesPageProps {
    access: string[];
    store: MaterialValuesStoreType;
    categories: CategoriesStoreType;
}

const getDeletedMaterials = (store: MaterialValuesListType) => store.deletedMaterials;
const getDeletedMaterialsAll = (store: MaterialValuesListType) => store.deletedMaterialsAll;

const getEndedMaterials = (store: MaterialValuesListType) => store.endedMaterials;
const getEndedMaterialsAll = (store: MaterialValuesListType) => store.endedMaterialsAll;

const getFixedMaterials = (store: MaterialValuesListType) => store.fixedMaterials;

const getFreeMaterials = (store: MaterialValuesListType) => store.freeMaterials;
const getMaterialsAll = (store: MaterialValuesListType) => store.materialsAll;

const openCategory = (history: History, id: string) => {
    const url = id ? routes.dictionaries.inventory.categoriesDetails(id) : routes.dictionaries.inventory.path;
    history.push(url);
};

const getSelectedCategoryId = (pathname: string) => {
    const match = matchPath<any>(pathname, routes.dictionaries.inventory.categoriesDetails());
    return match && match.params ? match.params.id : "";
};

export const CategoriesPage = observer((props: CategoriesPageProps) => {
    const { store, access } = props;
    const history = useHistory();
    const categoryId = useRef("");
    const [category, setcategory] = useState("");
    const [filterCategories, setFilterCategories] = useState(true);
    const categories = props.categories;

    useEffect(() => {
        document.title = menu.dictionaries.nested.inventory.label;

        categories.list.load();

        store.employers.load();
        store.update();
        categories.details.load(getSelectedCategoryId(history.location.pathname));
        return history.listen((loc) => {
            const currentCategoryId = getSelectedCategoryId(loc.pathname);

            if (categoryId.current !== currentCategoryId) {
                categoryId.current = currentCategoryId;
                categories.details.load(currentCategoryId);
            }
        });
    }, [history, categories, store]);

    const onCategorySelected = useCallback((id = "") => openCategory(history, id), [history]);

    const onCategoryClicked = (id = "") => {
        props.store.list.setCategory(id);
        setcategory(id);
    };

    const onCategoryRemoved = useCallback(() => {
        categories.list.load();
        history.push(routes.dictionaries.inventory.path);
    }, [history, categories.list]);

    const onCategoryReload = useCallback(() => {
        categories.list.load();
    }, [categories.list]);

    const onCategorySaved = useCallback(() => {
        openCategory(history, "");
        categories.list.load();
    }, [history, categories.list]);

    const onCategoryAdd = useCallback(() => openCategory(history, EMPTY_OBJECT_ID), [history]);

    const canChange = can(functionalities.INVENTORY_EDIT, props.access);
    const onMaterialSelected = (id: string = "") => {
        const url = id ? routes.dictionaries.inventory.itemsDetils(id) : routes.dictionaries.inventory.path;

        history.push(url);
    };
    const onMaterialValueAdd = () => {
        onMaterialSelected(EMPTY_OBJECT_ID);
    };
    const onMaterialSaved = (id: string) => {
        onMaterialSelected("");
        store.list.load();
        categories.updateCategory(id);
    };

    const filterAll = () => {
        setFilterCategories(false);
    };

    const filterCategory = () => {
        setFilterCategories(true);
    };

    return (
        <div className={styles.container}>
            <div className={filterCategories ? styles.leftBlock : styles.leftBlockHidden}>
                <Block columned={true} className={styles.page}>
                    <Route path={routes.dictionaries.inventory.categoriesDetails()}>
                        <DetailsPopup title="Категория" onCancel={onCategorySelected}>
                            <CategoryDetails
                                category={categories.details}
                                onSaved={onCategorySaved}
                                onRemoved={onCategoryRemoved}
                                canChange={canChange}
                            />
                        </DetailsPopup>
                    </Route>
                    <span className={styles.topSpan}>Категории</span>
                    <div className={styles.nav}>
                        {canChange && (
                            <PlanrButton
                                type="secondary"
                                size="small"
                                icon="general-plus-big"
                                onClick={onCategoryAdd}
                                round={true}
                                style={{
                                    marginBottom: "32px",
                                }}
                            />
                        )}

                        <Link to={routes.dictionaries.inventory.pathGroups} className="planr-button-link">
                            <PlanrButton
                                type="lightblueish"
                                size="small"
                                style={{
                                    width: "200px",
                                    marginBottom: "32px",
                                    borderRadius: "120px",
                                }}
                            >
                                Группы амортизации
                            </PlanrButton>
                        </Link>
                    </div>
                    <CategorysList
                        list={categories.list}
                        onSelected={onCategorySelected}
                        onClicked={onCategoryClicked}
                        onRemoved={onCategoryRemoved}
                    />
                </Block>
            </div>
            <div className={filterCategories ? styles.rightBlock : styles.rightBlockFull}>
                <span className={styles.topSpan}>Ценности</span>
                <div className={styles.filterBtns}>
                    <PlanrButton
                        type={filterCategories ? "primary" : "graybtn"}
                        size="small"
                        onClick={filterCategory}
                        style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px", width: "151px" }}
                    >
                        По категориям
                    </PlanrButton>
                    <PlanrButton
                        type={!filterCategories ? "primary" : "graybtn"}
                        size="small"
                        onClick={filterAll}
                        style={{
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                            marginLeft: "0px",
                            width: "151px",
                        }}
                    >
                        Весь список
                    </PlanrButton>
                </div>
                <Route path={routes.dictionaries.inventory.itemsDetils()}>
                    <DetailsPopup title="Ценность" onCancel={onMaterialSelected} width={650}>
                        <MaterialValueDetails
                            onPrintCredentials={store.printCredentials}
                            canChange={canChange}
                            materialValue={store.details}
                            onSaved={onMaterialSaved}
                            categories={store.categories}
                            deprGroups={store.deprGroups}
                            employers={store.employers}
                            category={category}
                        />
                    </DetailsPopup>
                </Route>
                {canChange && (
                    <PlanrButton
                        type="secondary"
                        size="small"
                        icon="general-plus-big"
                        onClick={onMaterialValueAdd}
                        round={true}
                        style={{
                            position: "absolute",
                            top: "70px",
                            left: "24px",
                            zIndex: 5,
                        }}
                    />
                )}
                <div
                    style={{
                        position: "absolute",
                        top: "70px",
                        right: "42px",
                    }}
                >
                    <Search query={store.list.query} onSearch={store.list.setQuery} />
                </div>

                {filterCategories ? (
                    <ProtectedRoute
                        path={routes.dictionaries.inventory.path}
                        loggedIn={true}
                        render={(routeProps) => (
                            <ItemsTabsPage
                                {...routeProps}
                                itemsFreePanel={
                                    can(functionalities.INVENTORY_READ, access) ? (
                                        <ItemsFreePage
                                            onChange={onCategoryReload}
                                            store={store}
                                            access={access}
                                            category={category}
                                            getDataset={getFreeMaterials}
                                        />
                                    ) : null
                                }
                                itemsFixedPanel={
                                    can(functionalities.INVENTORY_READ, access) ? (
                                        <ItemsFreePage
                                            onChange={onCategoryReload}
                                            store={store}
                                            access={access}
                                            category={category}
                                            getDataset={getFixedMaterials}
                                        />
                                    ) : null
                                }
                                itemsEndedPanel={
                                    can(functionalities.INVENTORY_READ, access) ? (
                                        <ItemsFreePage
                                            onChange={onCategoryReload}
                                            store={store}
                                            access={access}
                                            category={category}
                                            getDataset={getEndedMaterials}
                                            ended={true}
                                        />
                                    ) : null
                                }
                                itemsDeletedPanel={
                                    can(functionalities.INVENTORY_READ, access) ? (
                                        <ItemsFreePage
                                            onChange={onCategoryReload}
                                            store={store}
                                            access={access}
                                            category={category}
                                            getDataset={getDeletedMaterials}
                                        />
                                    ) : null
                                }
                            />
                        )}
                    />
                ) : (
                    <ProtectedRoute
                        path={routes.dictionaries.inventory.path}
                        loggedIn={true}
                        render={(routeProps) => (
                            <ItemsTabsPage
                                {...routeProps}
                                itemsAllPanel={
                                    can(functionalities.INVENTORY_READ, access) ? (
                                        <ItemsFreePage
                                            onChange={onCategoryReload}
                                            store={store}
                                            access={access}
                                            getDataset={getMaterialsAll}
                                            all={true}
                                        />
                                    ) : null
                                }
                                itemsEndedPanel={
                                    can(functionalities.INVENTORY_READ, access) ? (
                                        <ItemsFreePage
                                            onChange={onCategoryReload}
                                            store={store}
                                            access={access}
                                            category={category}
                                            getDataset={getEndedMaterialsAll}
                                            ended={true}
                                            all={true}
                                        />
                                    ) : null
                                }
                                itemsDeletedPanel={
                                    can(functionalities.INVENTORY_READ, access) ? (
                                        <ItemsFreePage
                                            onChange={onCategoryReload}
                                            store={store}
                                            access={access}
                                            category={category}
                                            getDataset={getDeletedMaterialsAll}
                                            all={true}
                                        />
                                    ) : null
                                }
                            />
                        )}
                    />
                )}
            </div>
        </div>
    );
});
