import { types } from "mobx-state-tree";
import { CategoryList, Category, initialState as emptyList } from "./category-list";
import { initialState as emptyCategory } from "./category";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";

export const CategoriesStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            list: CategoryList,
            details: Category,
        })
    )
    .actions((self) => ({
        updateCategory(id: string) {
            const cat = self.list.categories.find((c) => c.id === id);
            if (cat) cat.load(id);
        },
    }))

    .named("CategorysStore");

export const initialState = (): typeof CategoriesStore.SnapshotType => ({
    list: emptyList(),
    details: emptyCategory(),
});

export type CategoriesStoreType = typeof CategoriesStore.Type;
