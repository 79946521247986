import React from "react";
import { Classes } from "@blueprintjs/core";
import { NewsType } from "modules/main/models/news-store";
import { observer } from "mobx-react";

export const OpenedNews = observer(({ news }: OpenedNewsProps) => {
    return (
        <>
            <div className={Classes.DIALOG_BODY}>
                <div dangerouslySetInnerHTML={{ __html: news.text }} />
                <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "10px" }}>
                    {news.image && (
                        <img
                            src={news.image}
                            alt="newsimage"
                            style={{
                                width: "200px",
                                height: "200px",
                                objectFit: "cover",
                                borderRadius: "4px",
                            }}
                        />
                    )}
                </div>
            </div>
            <div className={Classes.DIALOG_FOOTER}></div>
        </>
    );
});

interface OpenedNewsProps {
    news: NewsType;
    width?: number;
    onClose: () => void;
}
