import React from "react";
import { LoggedInProps } from "modules/common/components/routing/AnonymousRoute";
import { ProtectedRoute } from "modules/common/components/routing/ProtectedRoute";
import { routes } from "modules/common/routes";
import { SessionType } from "modules/session/auth/models/session";
import { OrderConfirmStoreType } from "./models/store";
import { OrderConfirmsPage } from "./components/OrderConfirmsPage";

const path = routes.orders.orderConfirmsTab;

export const Routes = ({ store, session, loggedIn }: RoutesProps) => {
  return (
    <>
      <ProtectedRoute
        path={path}
        loggedIn={loggedIn}
        render={(props) => <OrderConfirmsPage {...props} store={store} session={session} />}
      />
    </>
  );
};

interface RoutesProps extends LoggedInProps {
  store: OrderConfirmStoreType;
  session: SessionType;
}
