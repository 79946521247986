import { types, getSnapshot } from 'mobx-state-tree';
import { Token, fields, TokenType } from './token';
import { Transport } from 'modules/common/models/transport';
import { Notificator } from 'modules/common/models/notificator';
import { TableSorter } from 'modules/common/models/table-sorter';
import { getSortOption } from 'modules/common/services/table/sorting-storage';

export const TokenList = types.compose(
    Transport, Notificator, types.model({
        tokens: types.array(Token),
        sorter: TableSorter,
    }))
    .views((self) => ({
        get asMap (): TStringMap<TokenType> {
            return self.tokens.reduce((result, token) => {
                result[token.id] = token;
                return result;
            }, {} as TStringMap<TokenType>);
        },

        get data () {
            return self.tokens.map((t) => ({
                ...getSnapshot(t),
                login: t.login,
                employerName: t.employerName
            }));
        },

        get isEmpty () {
            return !self.tokens.length;
        }
    }))
    .named('TokenList');

export type TokensListType = typeof TokenList.Type;
export type TokenListSnapshotType = typeof TokenList.SnapshotType;
export { Token };


export const initialState = (tableName: string): TokenListSnapshotType => {
    const sortStorage = getSortOption(tableName);
    const options = sortStorage({ column: fields.creationDate, asc: false });

    return ({
        tokens: [],
        sorter: {
            id: tableName,
            tableName,
            column: options.column,
            asc: options.asc,
        }
    });
};
