import * as yup from "yup";
import { fields, StartSnapshotType } from "../models/start";
import { phoneValidator } from "modules/common/services/form/values";
const { object, string, number } = yup;

export const startLabels = () => ({
    [fields.companyName]: "Организация (полное наименование)",
    [fields.shortCompanyName]: "Краткое наименование",
    [fields.phoneNumber]: "Телефон",
    [fields.email]: "E-mail",
    [fields.account]: "Расчетный счет",
    [fields.bankIdentificator]: "БИК",
    [fields.inn]: "ИНН",
    [fields.kpp]: "КПП",
    [fields.ogrn]: "ОГРН",
    [fields.okpo]: "ОКПО",
    [fields.okved]: "ОКВЕД",
    [fields.bankName]: "Банк",
    [fields.corrAccount]: "Корр. счет",
    [fields.address]: "Юр. адрес",
    [fields.directorName]: "Гендиректор ФИО",
    [fields.directorNameGenitive]: "Гендиректор ФИО (Родит. падеж)",
});
const messageNumber = "Поле должно содержать только числа";
const StartSchema = () => {
    const labels = startLabels();

    return object().shape({
        [fields.companyName]: string().label(labels[fields.companyName]).required(),
        [fields.shortCompanyName]: string().label(labels[fields.shortCompanyName]).required(),

        [fields.phoneNumber]: phoneValidator(
            labels[fields.phoneNumber],
            string()
                .test("needPhone", "Необходимо указать телефон", function (value) {
                    const agent: StartSnapshotType = this.parent;

                    return !!value || !!agent.email;
                })
                .required()
        ),

        [fields.email]: string()
            .label(labels[fields.email])
            .test("needEmail", "Необходимо указать email", function (value) {
                const agent: StartSnapshotType = this.parent;

                return !!value || !!agent.phoneNumber;
            })
            .email()
            .required(),
        [fields.inn]: number().label(labels[fields.inn]).required().typeError(messageNumber),

        [fields.kpp]: number().label(labels[fields.kpp]).required().typeError(messageNumber),

        [fields.ogrn]: number().label(labels[fields.ogrn]).required().typeError(messageNumber),

        [fields.okpo]: number().label(labels[fields.okpo]).required().typeError(messageNumber),
        [fields.okved]: string().label(labels[fields.okved]).required(),

        [fields.account]: number().label(labels[fields.account]).required().typeError(messageNumber),

        [fields.bankName]: string().label(labels[fields.bankName]).required().typeError(messageNumber),

        [fields.bankIdentificator]: number()
            .label(labels[fields.bankIdentificator])
            .required()
            .typeError(messageNumber),

        [fields.corrAccount]: number().label(labels[fields.corrAccount]).required().typeError(messageNumber),

        [fields.address]: string().label(labels[fields.address]).required(),

        [fields.directorName]: string().label(labels[fields.directorName]).required(),
        [fields.directorNameGenitive]: string().label(labels[fields.directorNameGenitive]).required(),
    });
};

export default StartSchema;
