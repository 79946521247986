import React, { useCallback, useState, useRef, useMemo } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { VacationRowType, VacationDetailsType } from "../models/schedule-unit";
import { observer } from "mobx-react";
import { ColDef, ValueFormatterParams } from "@ag-grid-community/core";
import { nameof } from "modules/common/services/typescript";
import styles from "./VacationDetails.module.scss";
import { defaultFormatter, formatMoney } from "modules/common/components/money/Money";
import { ROW_HEIGHT, DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { prevent } from "modules/common/services/form/select";
import Picker from "react-month-picker";
import moment from "moment";
import { MonthNames, BuildYears, makePeriodName } from "modules/common/models/period";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { Classes } from "@blueprintjs/core";
import { PlanrTag } from "modules/common/components/planr/tag/PlanrTag";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { texts } from "modules/common/texts";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";

const options = DefaultAgGridOptions();

const valueFormatter = (params: ValueFormatterParams) => {
    const data: VacationRowType = params.data;
    const value: number = params.value;

    return data.skip ? "" : defaultFormatter.format(value);
};

const columnsBuilder = (type: "salary" | "bonus"): ColDef[] => [
    {
        headerName: "Месяц",
        field: nameof((a: VacationRowType) => a.monthName) as string,
        width: 80,
    },
    {
        headerName: "Год",
        field: nameof((a: VacationRowType) => a.year) as string,
        width: 55,
    },
    {
        headerName: "",
        field: nameof((a: VacationRowType) => a.daysCount) as string,
        cellClass: "righted",
        headerComponentFramework: () => <div className="days ag-header-cell-text">Дней</div>,
        valueFormatter: (params) => {
            const data: VacationRowType = params.data;
            const value: number = params.value;

            return data.skip ? "" : value.toString();
        },
        width: 45,
    },
    {
        headerName: "",
        field: nameof((a: VacationRowType) => a.days) as string,
        cellClass: "righted",
        width: 60,
        valueFormatter,
    },
    {
        headerName: "Заработок",
        field:
            type === "salary"
                ? (nameof((a: VacationRowType) => a.salary) as string)
                : (nameof((a: VacationRowType) => a.bonus) as string),
        width: 100,
        cellClass: "righted",
        valueFormatter,
    },
];

const salary = columnsBuilder("salary");
const bouns = columnsBuilder("bonus");

const removeLabel = () => "перенос оплаты отпуска";

export const VacationDetails = observer(
    ({ vacation, type, readonly, addTransfer, removeTransfer }: VacationDetailsProps) => {
        const next = moment().add(1, "month").toDate();

        const pickerRef = useRef<any>();

        const [year, setYear] = useState(next.getFullYear());

        const [month, setMonth] = useState(next.getMonth() + 1);

        const yearsOptions = useMemo(() => BuildYears(year), [year]);

        const monthName = useMemo(() => makePeriodName(month, year), [month, year]);

        const transferPayment = useCallback(() => {
            addTransfer(month, year);
        }, [month, year, addTransfer]);

        const onMonthChange = useCallback((y: number, m: number) => {
            setMonth(m);
            setYear(y);
        }, []);

        const showPicker = useCallback(() => {
            pickerRef.current && pickerRef.current.show();
        }, []);

        const columns = type === "bonus" ? bouns : salary;

        let totalDays = 0,
            totalMoney = 0;

        vacation.rows.forEach((row) => {
            if (!row.skip) {
                totalDays += row.days;
                totalMoney += type === "bonus" ? row.bonus : row.salary;
            }
        });

        const perDay = totalDays > 0 ? totalMoney / totalDays : 0;

        return (
            <div className={`vacation-details ${styles.details}`}>
                <GridTheme className="no-last-column-break" onClick={prevent} onDoubleClick={prevent}>
                    {vacation.transferFrom && (
                        <div className={styles.transfer}>
                            <PlanrTag type="neutral" className={styles.transferWarning}>
                                Оплата перенесена с {vacation.transferFrom.periodName}
                                {!readonly && (
                                    <RemoveConfirmation<any>
                                        what={removeLabel}
                                        onConfirmed={removeTransfer}
                                        render={({ confirmRemoving }) => {
                                            return (
                                                <>
                                                    &nbsp;
                                                    <GeneralIcon
                                                        type="general-cross-small"
                                                        onClick={confirmRemoving}
                                                        style={{ color: "#E31818", cursor: "pointer" }}
                                                        title={texts.remove}
                                                    />
                                                </>
                                            );
                                        }}
                                    />
                                )}
                            </PlanrTag>
                        </div>
                    )}

                    {!vacation.transferFrom && !readonly && (
                        <div className={styles.transfer}>
                            <PlanrButton
                                type="greenish"
                                onClick={transferPayment}
                                style={{ height: "20px", fontSize: "10px", padding: "0px 7px" }}
                            >
                                Перенести оплату на
                            </PlanrButton>
                            &nbsp;
                            <Picker
                                ref={pickerRef}
                                years={yearsOptions}
                                value={{ month, year }}
                                lang={MonthNames}
                                onChange={onMonthChange}
                            >
                                <span
                                    className={`${styles.transferMonth} ${Classes.TOOLTIP_INDICATOR}`}
                                    onClick={showPicker}
                                >
                                    {monthName}
                                </span>
                            </Picker>
                        </div>
                    )}

                    <AgGridReact
                        enableCellTextSelection={true}
                        columnDefs={columns}
                        rowData={vacation.rows}
                        rowHeight={ROW_HEIGHT}
                        headerHeight={ROW_HEIGHT}
                        domLayout="autoHeight"
                        gridOptions={options}
                        modules={defaultModules}
                        immutableData={true}
                    />
                </GridTheme>
                <div className={styles.summary}>
                    Средний заработок: {formatMoney(totalMoney)} / {defaultFormatter.format(totalDays)}
                    &nbsp;=&nbsp;<strong>{formatMoney(perDay)}</strong>
                </div>
                <div className={styles.summary}>
                    Сумма: {vacation.daysCount} * {formatMoney(perDay)}
                    &nbsp;=&nbsp;
                    <strong>{formatMoney(type === "bonus" ? vacation.bonus : vacation.salary)}</strong>
                </div>
            </div>
        );
    }
);

interface VacationDetailsProps {
    vacation: VacationDetailsType;
    type: "salary" | "bonus";
    addTransfer: (month: number, year: number) => void;
    removeTransfer: () => void;
    readonly: boolean;
}
