import React, { useCallback } from "react";
import { AccessRolesModule, RoleData } from "../../models/access-store";
import styles from "./RoleDetails.module.scss";
import { EmployerName } from "modules/common/components/employer/EmployerName";
import { AccessModule } from "./AccessModule";
import { PlanrButton } from "modules/common/components/planr/button/Button";

export const RoleDetails = ({ role, columns, onToggle, onReplace }: RoleProps) => {
    const onAccessToggle = useCallback(
        (functionality: string, state: boolean) => {
            onToggle(role.id, functionality, state);
        },
        [onToggle, role]
    );
    const onClickReplace = useCallback(() => onReplace && onReplace(role.id), [onReplace, role]);

    return (
        <div className={`${styles.roleDetails} role-details`}>
            {onReplace && (
                <PlanrButton
                    type="graybtn"
                    icon="general-copy"
                    onClick={onClickReplace}
                    round={true}
                    title="Скопировать из другой роли"
                    className={styles.replaceButton}
                />
            )}

            <EmployerName name={role.label} position={role.sublabel} />

            <div className={styles.access}>
                {columns.map((column, index) => {
                    return (
                        <div className={styles.column} key={index}>
                            {column.map((module) => (
                                <AccessModule
                                    access={role.access}
                                    functionalities={module.tree}
                                    name={module.module}
                                    key={module.module}
                                    onToggle={onAccessToggle}
                                />
                            ))}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

interface RoleProps {
    role: RoleData;
    columns: AccessRolesModule[][];
    onToggle: (roleId: string, functionality: string, state: boolean) => void;
    onReplace?: (roleId: string) => void;
}
