import { formatMoney } from "modules/common/components/money/Money";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { PlanrTag } from "modules/common/components/planr/tag/PlanrTag";
import { eat } from "modules/common/services/typescript";
import { ActualOrderPaymentDetailsType } from "modules/orders-manage/models/order-stages-store";
import React, { useState } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import styles from "./ActualPayment.module.scss";

export const ActualPayment: React.FC<ActualPaymentProps> = ({
  payment,
  draggable,
  isDragable,
  planGuid,
  highlight,
  hideRemove: showRemove,
  showLeftSum,
  onRemove,
}) => {
  const draggablePrps = draggable
    ? {
        ref: draggable.innerRef,
        ...draggable.draggableProps,
        ...draggable.dragHandleProps,
      }
    : {};

  // let title = "";
  // if (invalid) {
  //     title = "Этот платеж используется слишком много раз";
  // }

  const [isHover, setIsHover] = useState(false);

  const payedSum = payment.planPaymentsSum.find((p) => p.planGuid === planGuid);
  const leftSum = showLeftSum
    ? payment.planPaymentsSum.reduce((a, b) => {
        return a + b.sum;
      }, 0)
    : 0;
  return (
    <div
      data-object-id={payment.guid}
      className={`${isDragable ? styles.dragablePayment : styles.actualPayment} ${
        highlight && highlight === payment.guid ? styles.highlighted : ""
      }`}
      {...draggablePrps}
      onMouseOver={
        onRemove
          ? (e) => {
              setIsHover(true);
            }
          : eat
      }
      onMouseLeave={
        onRemove
          ? () => {
              setIsHover(false);
            }
          : eat
      }
    >
      <div style={{ maxWidth: "100%", minWidth: "100%" }} className={isHover ? styles.isHover : styles.infoDiv}>
        {!showRemove && (
          <PlanrButton
            type="lightdanger"
            icon="general-trash"
            className={isHover && !showRemove ? styles.removeBtn : styles.hideBtn}
            title="Отвязать фактический платеж"
            style={isHover ? { height: "100%" } : { height: "0%" }}
            onClick={onRemove}
          >
            Отвязать
          </PlanrButton>
        )}
        <div className={styles.name}>
          <div className={styles.paymentDate}>{payment.date}</div>
          <div className={styles.paymentSum} style={{ display: "flex", alignItems: "center" }}>
            {/* {invalid && (
                            <GeneralIcon
                                type="general-exclamation"
                                style={{ color: "#EA561E", marginRight: "5px" }}
                                title={title}
                            />
                        )} */}
            <div>
              <PlanrTag
                type={"neutral"}
                // type={invalid ? "danger" : "neutral"}
              >
                {showLeftSum ? "Сумма: " : null}
                {formatMoney(payment.sum)}
              </PlanrTag>
            </div>
          </div>
        </div>
        {showLeftSum && leftSum > 0 && (
          <div className={styles.name} style={{ marginTop: "8px" }}>
            <div className={styles.paymentDate}>Остаток: </div>
            <div className={styles.paymentSum} style={{ display: "flex", alignItems: "center" }}>
              <div>
                <PlanrTag type={"danger"}>{formatMoney(payment.sum - leftSum)}</PlanrTag>
              </div>
            </div>
          </div>
        )}
        {!showLeftSum && payedSum && (
          <div className={styles.paymentName}>
            <div className={styles.paymentDate} style={{ marginRight: "30px" }}>
              Взято:{" "}
            </div>
            <div className={styles.paymentSum} style={{ display: "flex", alignItems: "center" }}>
              <div>{formatMoney(payedSum.sum)}</div>
            </div>
          </div>
        )}

        <div title={payment.comment} className={`${styles.paymentComment} payment-comment`}>
          {payment.comment}
        </div>
      </div>
    </div>
  );
};

interface ActualPaymentProps {
  draggable?: DraggableProvided;
  payment: ActualOrderPaymentDetailsType;
  isDragable?: boolean;
  showLeftSum?: boolean;
  hideRemove?: boolean;
  planGuid?: string;
  onRemove?: () => void;
  highlight?: string;
}
