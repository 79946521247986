import React from "react";
import { NewsSnapshotType } from "modules/main/models/news-store";
import { Formik, Form } from "formik";
import NewsSchema from "./validation";
import styles from "./NewsEditor.module.scss";
import { Classes, InputGroup, Dialog } from "@blueprintjs/core";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { isNewlyCreated } from "modules/common/models/entity";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const schema = NewsSchema();

export class NewsEditor extends React.PureComponent<NewsEditorProps, NewsEditorState> {
    editorRef = React.createRef<any>();

    render() {
        const { news, width, onSave, onClose, onRemove } = this.props;

        return (
            <Dialog
                title="Новость"
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                isOpen={true}
                onClose={onClose}
                style={{ width: `${width || 1000}px` }}
                backdropClassName="standard"
                className={`figma-dialog`}
            >
                <Formik
                    initialValues={getFormValues(news)}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        const succsessfull = await onSave({ ...values, id: news.id });
                        setSubmitting(false);

                        if (succsessfull) {
                            resetForm();
                            onClose();
                        }
                    }}
                >
                    {(formProps) => {
                        const removeNews = async () => {
                            formProps.setSubmitting(true);
                            const success = await onRemove(news.id);
                            formProps.setSubmitting(false);
                            success && onClose();
                        };

                        return (
                            <Form autoComplete="off">
                                <div className={`${Classes.DIALOG_BODY} ${styles.dialog}`}>
                                    <StandardFormInput
                                        name="name"
                                        schema={schema}
                                        small={true}
                                        inline={false}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <InputGroup
                                                id="name"
                                                {...field}
                                                small={true}
                                                autoComplete="off"
                                                className="planr-default-input"
                                            />
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name="text"
                                        schema={schema}
                                        small={true}
                                        inline={false}
                                        className="planr-form-input"
                                    >
                                        {({ field, form }) => {
                                            const onChange = (content: string) => {
                                                form.setFieldValue(field.name, content);
                                                form.setFieldTouched(field.name, true);
                                            };

                                            return (
                                                <div className={`${Classes.INPUT_GROUP} ${Classes.SMALL}`}>
                                                    <ReactQuill
                                                        value={field.value}
                                                        onChange={onChange}
                                                        className="planr-default-input"
                                                    />
                                                </div>
                                            );
                                        }}
                                    </StandardFormInput>
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        <StandardFormButtons
                                            {...formProps}
                                            isRemoved={false}
                                            what="новость"
                                            isNewlyCreated={isNewlyCreated(news.id)}
                                            onRemove={removeNews}
                                        />
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Dialog>
        );
    }
}

interface FormValues {
    name: string;
    text: string;
}

const getFormValues = (news: NewsSnapshotType): FormValues => ({
    name: news.name,
    text: news.text,
});

interface NewsEditorProps {
    width?: number;
    news: NewsSnapshotType;
    onClose: () => void;
    onSave: (news: FormValues & { id: string }) => Promise<boolean>;
    onRemove: (id: string) => Promise<boolean>;
}

interface NewsEditorState {
    //editor: EditorState;
}
