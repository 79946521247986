import React, { useCallback } from 'react';
import styles from './ImageViewer.module.scss';
import { FileMetadataType } from 'modules/common/models/file-metadata';
import { Toolbox } from './Toolbox';
import { printImage, treatBase64 } from 'modules/common/services/files';
import { observer } from 'mobx-react';

export const ImageViewer = observer(({ document, toolbox }: ImageViewerProps) => {
    const print = useCallback(
        (doc: FileMetadataType) => printImage(treatBase64(doc.content, doc.mimeType), true),
        []
    );

    const style: React.CSSProperties = {
        backgroundImage: `url('${treatBase64(document.content, document.mimeType)}')`,
    };

    return (
        <div className={`${styles.imageViewer} image-viewer`}>
            {toolbox && (
                <Toolbox document={document} onPrint={print} />
            )}
            <div className={styles.image} style={style}></div>
        </div>
    );
});

interface ImageViewerProps {
    document: FileMetadataType;
    toolbox?: boolean;
}
