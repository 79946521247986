import React, { PropsWithChildren, useCallback } from "react";
import { Classes } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { routes } from "modules/common/routes";
import { canAny } from "modules/session/auth/access";
import { observer } from "mobx-react";
import { MenuItem } from "./types";
import { prevent } from "modules/common/services/form/select";
import { GeneralIcon, GeneralIcons } from "modules/common/components/planr/icon/Generalcon";

export const MainMenuItem = (props: MainMenuItemProps) => {
  const { item, onClick, ...rest } = props;

  const { nested } = item;

  return nested && Object.keys(nested).length ? (
    <TreeMenuItem {...props} />
  ) : (
    <FlatMenuItem item={item} {...rest} isSubtree={false} onClick={onClick} />
  );
};

const FlatMenuItem = (props: PropsWithChildren<FlatMenuItemProps>) => {
  const { item, children, onClick, isActive, isSubtree, className, access, icon, isMobile, sethHideMenu } = props;
  const { path, labelLeft } = item;

  const clickHandler = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (onClick) {
        onClick();
      } else {
        if (isMobile && sethHideMenu) sethHideMenu();
      }
    },
    [onClick, sethHideMenu, isMobile]
  );

  const functionalities = item.functionality ? item.functionality.split(",") : [];
  const available = functionalities.length ? canAny(functionalities, access) : true;
  if (!available && !children) {
    return null;
  }

  const style = classNames({
    [Classes.MENU_ITEM]: true,
    [`${Classes.ACTIVE} ${Classes.INTENT_PRIMARY}`]: isActive && !isSubtree,
    [`${Classes.ACTIVE} ${Classes.INTENT_DANGER}`]: isActive && isSubtree,
    "top-level": !isSubtree,
    [className || ""]: !!className,
  });

  const label = (
    <div className={Classes.FILL}>
      {item.label}
      <div>
        {item.pin1 && (
          <div className={"pin1"} title="Заявки от агентов">
            {item.pin1}
          </div>
        )}
      </div>
    </div>
  );

  const element =
    path && available ? (
      <Link className={style} to={path}>
        {label}
      </Link>
    ) : (
      <span className={style}>{label}</span>
    );

  return (
    <li onClick={clickHandler} className="main-menu-item">
      {item.icon && (
        <GeneralIcon
          type={item.icon}
          className={
            item.icon !== "general-dot" && !item.iconSmall
              ? "main-menu-leftitem_icon"
              : item.iconSmall
              ? "main-menu-leftitem_icon-small"
              : "main-menu-left-childitem_icon"
          }
        />
      )}

      {element}
      {icon && <GeneralIcon type={icon} className={"main-menu-item_icon"} />}
      {labelLeft && (
        <span className="main-menu-item_left" onClick={prevent}>
          {labelLeft(access)}
        </span>
      )}
      {children}
    </li>
  );
};

const TreeMenuItem = observer((props: MainMenuItemProps) => {
  const { item, currentItem, expanded, onClick, access, isMobile, setHideMenu } = props;

  const empty = item.nested
    ? Object.values(item.nested)
        .map((i) => i.functionality || "")
        .filter((f) => !f)
    : [];

  const nestedFunctionalities = item.nested
    ? Object.values(item.nested)
        .reduce((acc, i) => {
          const f = i.functionality ? i.functionality.split(",") : [];
          return [...acc, ...f];
        }, [] as string[])
        .filter((f) => !!f)
    : [];

  // if no nested item allowed, do not show whole subtree
  if (!empty.length && nestedFunctionalities.length > 0 && !canAny(nestedFunctionalities, access)) {
    return null;
  }

  const nested = item.nested;
  const firstActive = nested
    ? Object.keys(nested).findIndex((childId) => {
        return isItemSelected(nested[childId].path, currentItem);
      })
    : -1;

  const childNodes =
    nested && expanded
      ? Object.keys(nested).map((childId, index) => {
          const className = index > firstActive ? "skip-line" : "";
          return (
            <FlatMenuItem
              key={childId}
              item={nested[childId]}
              isActive={firstActive === index}
              isSubtree={true}
              className={className}
              access={access}
              isMobile={isMobile}
              sethHideMenu={setHideMenu}
            />
          );
        })
      : null;

  const directFunctionalities = item.functionality ? item.functionality.split(",") : [];
  const functionalities = nestedFunctionalities.concat(directFunctionalities);
  const visible = functionalities.length ? canAny(functionalities, access) : true;

  return visible ? (
    <FlatMenuItem
      {...props}
      onClick={onClick}
      isSubtree={false}
      isActive={firstActive >= 0}
      icon={expanded ? "general-chevron-down-active" : "general-chevron-right"}
    >
      <ul className={`${Classes.MENU}`}>{childNodes}</ul>
    </FlatMenuItem>
  ) : null;
});

interface BaseMenuItemProps {
  item: MenuItem;
  className?: string;
}

interface FlatMenuItemProps extends BaseMenuItemProps {
  icon?: GeneralIcons;
  isActive?: boolean;
  onClick?: () => void;
  isSubtree: boolean;
  access: string[];
  isMobile?: boolean;
  sethHideMenu?: () => void;
}

export interface MainMenuItemProps extends BaseMenuItemProps {
  currentItem: string;
  expanded: boolean;
  onClick: () => void;
  access: string[];
  isMobile: boolean;
  setHideMenu: () => void;
}

//const idRoute = /(\/[a-z0-9]{24,24}\/)/gi;
function isItemSelected(itemPath: string, currentItem: string) {
  //const match = idRoute.exec(itemPath);
  //const route = match != null ? itemPath.replace(match[0], '/:id/') : itemPath;

  return itemPath === routes.main.path
    ? currentItem === itemPath
    : !!(
        (
          currentItem &&
          itemPath &&
          (itemPath.includes(currentItem) || currentItem.includes(itemPath))
        ) /* || matchPath(currentItem, route)*/
      );
}
