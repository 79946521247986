import React from "react";
import { IDisposer } from "mobx-state-tree";
import { reaction } from "mobx";
import { am4core, am4charts, SCROLL_DELAY, gradient, am4lang_ru_RU, blackTooltip } from "./am";
import styles from "./Dashboard.module.scss";
import { STANDARD_GREY } from "modules/common/constants";
import { SimpleChartDatum } from "modules/main/models/simple-chart";
import { ValueAxis, CircleBullet } from "@amcharts/amcharts4/charts";
import { max } from "lodash";
import { observer } from "mobx-react";
import { SectionCollapserType } from "modules/orders-manage/models/orders-store";

import { Caret } from "modules/common/components/collapse/Caret";
import moment from "moment";
import { PlanrTag } from "modules/common/components/planr/tag/PlanrTag";

export const HEIGHT = 220;
const fillGradient = gradient();

export class SimpleChart<P extends SimpleChartProps, S = {}> extends React.PureComponent<P, S> {
    protected container = React.createRef<HTMLDivElement>();
    protected chart: any;
    protected stop1: IDisposer | null = null;
    protected stop2: IDisposer | null = null;

    componentDidMount() {
        const { store } = this.props;
        this.setupChart(store.amChartData);
        store.load();

        this.stop1 = reaction(
            () => store.loaded,
            (loaded) => {
                if (loaded) {
                    this.setupChart(store.amChartData);
                    store.setLoaded(false);
                }
            }
        );

        this.stop2 = reaction(
            () => store.observable,
            () => {
                this.setupChart(store.amChartData);
            }
        );
    }

    render() {
        const width = this.getWidth();
        const height = this.getHeight();

        return (
            <div
                data-url={this.props.store.url}
                className={styles.widget}
                style={{ width: `${width}px`, minWidth: `${width}px`, height: `${height}px` }}
            >
                {this.renderChilds()}
                {this.renderContainer()}
            </div>
        );
    }

    renderContainer = (child?: any) => {
        const width = this.getWidth();
        const height = this.getHeight();

        return (
            <div ref={this.container} style={{ width: `${width}px`, minWidth: `${width}px`, height: `${height}px` }}>
                {child}
            </div>
        );
    };

    renderChilds = (): JSX.Element | null => null;

    setupChart = (data: SimpleChartDatum[]) => {
        const width = this.getWidth();
        const height = this.getHeight();

        const { formatter, maxData, graduator, bulleter, legendRotation } = this.props as SimpleChartProps;

        if (!this.container.current) {
            return;
        }

        if (!this.chart && data.length) {
            const chart = am4core.create(this.container.current, am4charts.XYChart);
            chart.width = width;
            chart.height = height;
            chart.language.locale = am4lang_ru_RU;

            chart.numberFormatter.numberFormat = formatter || "##.";
            chart.cursorOverStyle = am4core.MouseCursorStyle.grab;

            const topContainer = chart.chartContainer.createChild(am4core.Container);
            topContainer.layout = "none";
            topContainer.toBack();
            topContainer.paddingBottom = 15;
            topContainer.width = am4core.percent(100);

            const title = topContainer.createChild(am4core.Label);
            if (!this.props.title) {
                title.fill = am4core.color("#FFF", 0);
            }
            title.text = this.props.title || "...";
            title.fontWeight = "600";
            title.align = "left";

            chart.zoomOutButton.disabled = true;

            const periodAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            periodAxis.dataFields.category = "period";
            periodAxis.title.text = "";
            periodAxis.renderer.minGridDistance = 20;
            periodAxis.renderer.labels.template.fill = am4core.color(STANDARD_GREY);
            periodAxis.renderer.labels.template.horizontalCenter = "middle";
            periodAxis.renderer.labels.template.verticalCenter = "middle";
            periodAxis.renderer.labels.template.rotation = legendRotation ?? 315;
            periodAxis.renderer.labels.template.fontSize = "11px";
            periodAxis.renderer.labels.template.wrap = true;
            periodAxis.renderer.labels.template.maxWidth = 100;
            periodAxis.renderer.labels.template.textAlign = "end";
            periodAxis.renderer.grid.template.location = 0;
            periodAxis.renderer.grid.template.stroke = am4core.color("#FFF", 0);
            periodAxis.renderer.line.stroke = am4core.color("#000");
            periodAxis.renderer.line.strokeOpacity = 1;
            periodAxis.renderer.line.strokeWidth = 1;
            periodAxis.cursorTooltipEnabled = false;

            const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.min = 0;
            valueAxis.title.text = "";
            valueAxis.renderer.labels.template.fill = am4core.color(STANDARD_GREY);
            valueAxis.renderer.labels.template.fontSize = "11px";
            valueAxis.cursorTooltipEnabled = false;
            valueAxis.renderer.line.stroke = am4core.color("#000");
            valueAxis.renderer.line.strokeOpacity = 1;
            valueAxis.renderer.line.strokeWidth = 1;

            const createLineSeries = (field: string, name: string, color: string) => {
                const paretoSeries = chart.series.push(new am4charts.LineSeries());
                paretoSeries.dataFields.valueY = field;
                paretoSeries.dataFields.categoryX = "period";
                paretoSeries.tensionX = 0.77;
                paretoSeries.name = name;
                paretoSeries.strokeWidth = 2;
                paretoSeries.fill = am4core.color(color);
                paretoSeries.stroke = am4core.color(color);
                paretoSeries.fillOpacity = 1;
                paretoSeries.segments.template.fillModifier = fillGradient;

                const circleBullet = paretoSeries.bullets.push(new am4charts.CircleBullet());
                circleBullet.tooltipText = "{valueY}" + (this.props.postfix || "");
                circleBullet.circle.fill = am4core.color("#FFF", 0);
                circleBullet.circle.strokeWidth = 0;
                circleBullet.circle.radius = 1;

                if (bulleter) {
                    circleBullet.circle.fill = am4core.color(color);
                    bulleter(circleBullet);
                }

                if (!circleBullet.tooltip) {
                    circleBullet.tooltip = new am4core.Tooltip();
                }

                blackTooltip(circleBullet.tooltip);
            };

            createLineSeries("data", this.props.title, this.props.color);

            chart.cursor = new am4charts.XYCursor();
            chart.cursor.behavior = "panX";
            chart.cursor.lineX.disabled = true;
            chart.cursor.lineY.disabled = true;

            chart.events.on("datavalidated", () => {
                if (!graduator) {
                    valueAxis.min = valueAxis.minZoomed;
                    valueAxis.max = valueAxis.maxZoomed;
                }

                const values = chart.data;

                if (maxData && values.length > maxData) {
                    const start = values[values.length - maxData].period;
                    const end = values[values.length - 1].period;
                    chart.cursorOverStyle = am4core.MouseCursorStyle.grab;

                    setTimeout(() => {
                        periodAxis.zoomToCategories(start, end);
                    }, SCROLL_DELAY);
                } else {
                    chart.cursorOverStyle = am4core.MouseCursorStyle.default;
                }
            });

            this.chart = chart;
        }

        if (this.chart) {
            if (graduator) {
                const valueAxis = this.chart.yAxes.getIndex(0);
                valueAxis.renderer.grid.template.disabled = true;
                valueAxis.renderer.labels.template.disabled = true;
                graduator(valueAxis, max(data.map((row) => row.data)) || 0);
            }

            this.chart.data = data;
        }
    };

    componentWillUnmount() {
        this.stop1 && this.stop1();
        this.stop2 && this.stop2();
        this.chart && this.chart.dispose();
    }

    getWidth = () => (this.props as SimpleChartProps).width;

    getHeight = () => (this.props as SimpleChartProps).height;
}

export interface ISimpleChartStore {
    loaded: boolean;
    observable: any;
    url: string;
    amChartData: SimpleChartDatum[];
    load: () => Promise<any>;
    setLoaded: (state: boolean) => void;
}

export interface BaseBlockProps {
    store: ISimpleChartStore;
    width: number;
}

export interface SimpleChartProps extends BaseBlockProps {
    title: string;
    color: string;
    height: number;
    formatter?: string;
    postfix?: string;
    maxData?: number;
    legendRotation?: number;
    graduator?: (axis: ValueAxis, maxValue: number) => void;
    bulleter?: (bullet: CircleBullet) => void;
}

export const CollapsedChart = observer(
    class extends React.PureComponent<CollapserChartProps> {
        month = moment().format("MM.yyyy");
        year = moment().format("yyyy");

        render() {
            const { renderChild, formatter, width, name, collapser, title, store, summary, collapseToggle, ...rest } =
                this.props;
            //const widthChart = width + 25;
            const child = renderChild({
                ...rest,
                width,
                store,
                formatter,
                title: "",
            });

            return (
                <>
                    <h1 className="planr-block-header collapser" onClick={collapseToggle} style={{ marginTop: "0px" }}>
                        <Caret collapsed={!collapser.opened.get(name)} style={{ zIndex: 1 }} />
                        {title}
                        {summary && <PlanrTag>{summary}</PlanrTag>}
                    </h1>
                    <div className={!collapser.opened.get(name) ? "fakeCollapse" : ""}>
                        <div className="collapse">{child}</div>
                    </div>
                </>
            );
        }

        //   toggle = () => this.props.collapser.toggle(this.props.name);
    }
);

interface CollapserChartProps extends SimpleChartProps {
    summary?: React.ReactNode;
    renderChild: (props: SimpleChartProps) => any;
    collapser: SectionCollapserType;
    name: string;
    collapseToggle: () => void;
}
