import { types } from "mobx-state-tree";
import { BaseEntity } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { DictionaryLink } from "modules/common/models/dictionary-link";
import { RootCache } from "modules/common/services/cache";
import { nameof } from "modules/common/services/typescript";
import { OrderDictionary, initialState as emptyOrders } from "./order-dictionary";

export const OrderListItem = types
    .compose(
        BaseEntity,
        types.model({
            name: types.string,
            inventoryNumber: types.number,
            fullInventoryNumber: types.string,
            color: types.string,
            orderStatus: types.maybeNull(DictionaryLink),
            statusColor: types.optional(types.string, ""),
        })
    )
    .views((self) => ({
        get statusName() {
            return self.orderStatus ? self.orderStatus.name : "";
        },
    }))
    .named("OrderListItem");

export const OrderList = types
    .compose(
        Transport,
        Notificator,
        types.model({
            orders: OrderDictionary,
            visible: types.boolean,
            lastFilter: types.string,
            latestOrderId: types.string,
        })
    )
    .views((self) => ({
        get asMap() {
            return self.orders.asMap;
        },

        get data() {
            const filter = self.lastFilter.toLowerCase();

            return filter
                ? self.orders.orders.filter((order) => {
                      return (
                          order.name.toLowerCase().includes(filter) ||
                          order.fullInventoryNumber.includes(filter) ||
                          order.status.toLowerCase().includes(filter)
                      );
                  })
                : self.orders.orders;
        },
    }))
    .actions((self) => ({
        load() {
            return self.orders.load();
        },

        filter(filter: string) {
            self.lastFilter = filter || "";
        },

        toggleVisibility(state?: boolean) {
            const newState = typeof state === "boolean" ? state : !self.visible;
            self.visible = newState;
        },

        setLastestProjectId: (id: string) => {
            RootCache.set(
                nameof((a: AppConfig) => a.latestOrderId),
                id
            );
            self.latestOrderId = id;
        },
    }))
    .views((self) => ({
        get isEmpty() {
            return self.orders.isEmpty;
        },
    }))
    .named("OrderList");

export const initialState = (meta: AppConfig): typeof OrderList.SnapshotType => ({
    orders: emptyOrders(),
    lastFilter: "",
    visible: false,
    latestOrderId: meta.latestOrderId ?? "",
});

export type OrderListItemType = typeof OrderListItem.Type;
export type OrderListItemSnapshotType = typeof OrderListItem.SnapshotType;
export type OrderListType = typeof OrderList.Type;
export type OrderListSnapshotType = typeof OrderList.SnapshotType;
