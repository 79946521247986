import { formatMoney } from "modules/common/components/money/Money";
import { OrderPremiumDetailsType } from "modules/orders-manage/models/premium";

export const PREMIUM_BLOCK_NAME = "Премии";

interface OwnSpendingsPrintModel {
    name: string;
    bank: string;
    paid: string;
    remains: string;
    gip: {
        user: string;
        bank: string;
        quality: string;
        sum: string;
        assigned: string;
        paid: string;
        remains: string;
    };
    production: {
        name: string;
        percents: string;
        calculated: string;
        sum: string;
        assigned: string;
        paid: string;
        remains: string;
        rows: {
            user: string;
            salary: string;
            salaryF: string;
            hours: string;
            hoursF: string;
            percents: string;
            calculated: string;
            quality: string;
            sum: string;
            assigned: string;
            paid: string;
            remains: string;
        }[];
        labels: TStringMap<string>;
    }[];
    nonproduct: {
        percents: string;
        sum: string;
        assigned: string;
        paid: string;
        remains: string;
        rows: {
            user: string;
            salary: string;
            salaryF: string;
            hours: string;
            hoursF: string;
            percents: string;
            sum: string;
            assigned: string;
            paid: string;
            remains: string;
        }[];
    };
}

type ProductionType = OwnSpendingsPrintModel["production"][number];
type ProductionRowType = ProductionType["rows"][number];

export function premiumsPlanPrintModel(name: string, store: OrderPremiumDetailsType): OwnSpendingsPrintModel {
    const premium = store.engineerPremium;
    const details = store.premiumRowsAsMap[premium.userId];
    const { assigned } = details;

    const result: OwnSpendingsPrintModel = {
        name,
        bank: "",
        paid: "",
        remains: "",
        gip: {
            user: premium.employer?.name ?? "",
            bank: formatMoney(premium.bank),
            quality: premium.hasQualityMark ? "✔" : "",
            sum: formatMoney(premium.sum),
            assigned: formatMoney(assigned?.sum ?? 0),
            paid: formatMoney(details.paidSum),
            remains: formatMoney(details.remainsSum),
        },
        production: [],
        nonproduct: {
            assigned: "",
            paid: "",
            remains: "",
            sum: "",
            percents: "",
            rows: [],
        },
    };

    // production
    Object.values(store.premiumProductionAsMap).forEach((block) => {
        let remainsSum = 0;
        let assignedSum = 0;
        let paidSum = 0;
        let sum = 0;
        let percents = 0;
        let calculatedSum = 0;

        const rows: ProductionRowType[] = [];

        block.forEach((userRow) => {
            const rowMetadata = store.premiumRowsAsMap[userRow.employer.id] || null;

            remainsSum += rowMetadata.remainsSum;
            assignedSum += rowMetadata.assignedSum;
            paidSum += rowMetadata.paidSum;
            calculatedSum += userRow.calculatedSum;
            sum += userRow.sum;
            percents += userRow.premiumPercent;

            rows.push({
                user: userRow.employer.name,
                salary: formatMoney(userRow.salary),
                salaryF: userRow.salaryFactor.toFixed(2),
                hours: `${userRow.hours}`,
                hoursF: userRow.hoursFactor.toFixed(5),
                percents: `${userRow.premiumPercent.toFixed(2)}%`,
                calculated: formatMoney(userRow.calculatedSum),
                quality: userRow.hasQualityMark ? "✔" : "",
                sum: formatMoney(userRow.sum),
                assigned: formatMoney(rowMetadata?.assignedSum ?? 0),
                paid: formatMoney(rowMetadata?.paidSum ?? 0),
                remains: formatMoney(rowMetadata?.remainsSum ?? 0),
            });
        });

        result.production.push({
            name: block[0].department.name,
            percents: `${percents.toFixed(2)}%`,
            calculated: formatMoney(calculatedSum),
            sum: formatMoney(sum),
            assigned: formatMoney(assignedSum),
            paid: formatMoney(paidSum),
            remains: formatMoney(remainsSum),
            rows,
            labels: {
                empty: "",
            },
        });
    });

    // non-production
    {
        let remainsSum = 0;
        let assignedSum = 0;
        let paidSum = 0;
        let sum = 0;
        let percents = 0;

        store.nonProductionEmployerPremiums.forEach((userRow) => {
            const rowMetadata = store.premiumRowsAsMap[userRow.employer.id] || null;
            remainsSum += rowMetadata.remainsSum;
            assignedSum += rowMetadata.assignedSum;
            paidSum += rowMetadata.paidSum;
            percents += userRow.premiumPercent;
            sum += userRow.sum;

            result.nonproduct.rows.push({
                user: userRow.employer.name,
                salary: formatMoney(userRow.salary),
                salaryF: userRow.salaryFactor.toFixed(2),
                hours: `${userRow.hours}`,
                hoursF: userRow.hoursFactor.toFixed(5),
                percents: `${userRow.premiumPercent.toFixed(2)}%`,
                sum: formatMoney(userRow.sum),
                assigned: formatMoney(rowMetadata?.assignedSum ?? 0),
                paid: formatMoney(rowMetadata?.paidSum ?? 0),
                remains: formatMoney(rowMetadata?.remainsSum ?? 0),
            });
        });

        result.nonproduct.sum = formatMoney(sum);
        result.nonproduct.assigned = formatMoney(assignedSum);
        result.nonproduct.paid = formatMoney(paidSum);
        result.nonproduct.remains = formatMoney(remainsSum);
        result.nonproduct.percents = `${percents.toFixed(2)}%`;
    }

    return result;
}
