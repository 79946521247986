import React, { useRef, useState } from "react";
import { UserTimesheetType } from "modules/spending/timesheet/models/user-timesheet";
import styles from "./TimesheetUserRow.module.scss";
import { EmployerName } from "modules/common/components/employer/EmployerName";
import { observer } from "mobx-react";
import { TimesheetCell, DaySelector, TimesheetCellMode, OvertimeCell } from "./TimesheetCell";
import { OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";
import { TimesheetInput, TimesheetSaver } from "./TimesheetInput";
import { getSnapshot } from "mobx-state-tree";
import { makeTimesheetInputStore } from "modules/spending/timesheet/models/timesheet-store";
import {
  TasksDictionaryType,
  WorkloadDayStoreType,
  WorkloadDayType,
} from "modules/spending/workload/models/workload-day";
import { ReactMouseSelect } from "react-mouse-select";

export const TimesheetUserRow = observer((props: TimesheetUserRowProps) => {
  const {
    timesheet,
    onSave,
    selectedCells,
    onSelectCells,
    onSelectCell,
    tasks,
    selectedCell,
    multiSelectCells,
    onContext,
    isMainPage,
    light,
    ...selector
  } = props;

  const [formStore, setformStore] = useState<WorkloadDayStoreType | null>(null);

  const [cells, setcells] = useState<string[]>([]);

  const selectCel = (str: string) => {
    const index = selectedCells
      ? selectedCells.findIndex((cell) => {
          return cell === str;
        })
      : 0;
    if (index === -1) {
      onSelectCells && onSelectCells([]);
      onSelectCell(str);
    } else {
      onSelectCell(str);
    }
  };
  const onDayEditStart = (day: WorkloadDayType) => {
    const { orders, tasks } = props;

    day.isDayWorkable &&
      setformStore(makeTimesheetInputStore(day, getSnapshot(orders.orders), getSnapshot(tasks.tasks)));
  };

  const onDayEditStop = () => setformStore(null);

  const isDayEditable = (day: WorkloadDayType) => {
    const { canManage, userLogin } = props;

    return day.isDayEditable(canManage, userLogin);
  };

  let timesheetDay: any;
  const selectorRef = useRef<HTMLDivElement>(null);
  const itemClassName = "mouse-select__selectable";
  return (
    <div className={`timesheet-user-row ${styles.row}`} style={isMainPage ? { marginBottom: "5px" } : {}}>
      <div className="timesheet-user-name">
        <EmployerName name={timesheet.user.name} position={timesheet.user.position} />
      </div>
      <ReactMouseSelect
        containerRef={selectorRef}
        itemClassName={itemClassName}
        startSelectionCallback={(e: MouseEvent) => {
          onSelectCells && onSelectCells([]);
          setcells([]);
          onSelectCell("");
        }}
        finishSelectionCallback={(items: Element[], e: MouseEvent) => {
          const selectedItems = items.map((item) => item.getAttribute("data-id") || "");
          if (selectedItems.length > 0) {
            onSelectCells && onSelectCells(selectedItems);
            setcells(selectedItems);
            onSelectCell("");
          }
        }}
      />
      <div ref={selectorRef} className="timesheet-user-days" style={{ display: "flex" }}>
        {timesheet.days.map((day, index) => {
          const next = index + 1 < timesheet.days.length ? timesheet.days[index + 1] : undefined;
          const preToday = !!next && next.isToday();
          timesheetDay = day;
          return (
            <TimesheetCell
              key={day.day}
              day={day}
              preToday={preToday}
              onEdit={onDayEditStart}
              locked={!isDayEditable(day)}
              selectedCells={selectedCells}
              cells={cells}
              onSelectCel={selectCel}
              selectedCell={selectedCell}
              onContext={onContext}
              light={light}
              {...selector}
            />
          );
        })}
      </div>

      <OvertimeCell state={timesheet.hoursDiff} />

      {formStore && (
        <TimesheetInput
          store={formStore}
          isOpened={true}
          onClose={onDayEditStop}
          onSave={onSave}
          day={timesheetDay}
          selectedCells={selectedCells}
          readOnly={!isDayEditable(timesheetDay)}
          {...selector}
        />
      )}
    </div>
  );
});

interface TimesheetUserRowProps extends DaySelector, TimesheetSaver {
  timesheet: UserTimesheetType;
  orders: OrderDictionaryType;
  canManage: boolean;
  mode: TimesheetCellMode;
  userLogin: string;
  tasks: TasksDictionaryType;
  showMinutes: boolean;
  selectedCells?: string[];
  onSelectCells?: (cell: string[]) => void;
  onContext: (x: number, y: number, day: WorkloadDayType) => void;
  multiSelectCells?: boolean;
  isMainPage?: boolean;
  light?: string;
}

interface TimesheetUserRowState {
  formStore: WorkloadDayStoreType | null;
  selectedItems: string[];
  mouseDown: boolean;
}
