import * as yup from 'yup';
import { fields } from 'modules/dictionaries/project-portfolios/models/project-portfolio';
import { texts } from 'modules/common/texts';
const { object, string } = yup;

export const projectPortfolioLabels = () => ({
    [fields.name]: texts.name,
    [fields.description]: 'Описание'
});

const ProjectPortfolioSchema = () => {
    const labels = projectPortfolioLabels();

    return object().shape({

        [fields.name]: string()
            .label(labels[fields.name])
            .required(),

        [fields.description]: string()
            .label(labels[fields.description])
            .notRequired(),
    });
}

export default ProjectPortfolioSchema;
