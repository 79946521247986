import React from "react";
import { observer } from "mobx-react";
import styles from "./DepartmentBlock.module.scss";
import { UnitUserRow } from "./UnitUserRow";
import { UnitStoreType, UnitPeriodRow } from "../models/unit-store";
import { Caret } from "modules/common/components/collapse/Caret";
import { Collapse } from "@blueprintjs/core";
import { Peoples } from "modules/orders-manage/details/Peoples";
import { UnitUserDashboard } from "./UnitUserDashboard";

class Block extends React.Component<DepartmentBlockProps> {
    render() {
        const { department, users, store, collapsed, toggleCollapse, avgHours, maxHours, ...rest } = this.props;

        return (
            <div className={`department-block ${styles.block}`}>
                <h1 className="planr-block-header-wide_lowscreen collapser " onClick={this.onToggleCollapse}>
                    <div className={styles.departmentName}>
                        <div className={styles.department}>{department}</div>
                        <Peoples count={users.length} />
                    </div>
                    {!collapsed && (
                        <div className={`${styles.tableHeader}`}>
                            <div className={`${styles.hours} `}>Часы</div>
                            <div className={`${styles.money} `}>Денежные средства</div>
                            <div className={`${styles.cost} `}>Стоимость раб. врем.</div>
                            <div className={`${styles.percents} `}>Разница в %</div>
                        </div>
                    )}
                    <Caret collapsed={collapsed} style={{ left: "7px" }} />
                </h1>

                <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                    <div className="collapse-wide_lowscreen">
                        {users.map((user) => (
                            <div key={user.employer.id} className={styles.userRow}>
                                <UnitUserRow user={user.employer} {...rest} />
                                <UnitUserDashboard
                                    user={user.data}
                                    avgHours={avgHours}
                                    maxHours={maxHours}
                                    showMinutes={store.showMinutes}
                                />
                            </div>
                        ))}
                    </div>
                </Collapse>
            </div>
        );
    }

    onToggleCollapse = () => this.props.toggleCollapse(this.props.department);
}

export const DepartmentBlock = observer(Block);

interface DepartmentBlockProps {
    department: string;
    store: UnitStoreType;
    maxHours: number;
    collapsed: boolean;
    toggleCollapse: (key: string) => void;
    users: UnitPeriodRow[];

    avgHours: number;
}
