import { types } from "mobx-state-tree";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { EmployeeList, initialState as emptyEmployee } from "modules/spending/employee/models/employer-list";

export const EmployeeWidgetStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            list: EmployeeList,
        })
    )
    .named("EmployeeWidgetStore");

export type EmployeeWidgetStoreType = typeof EmployeeWidgetStore.Type;

export const initialState = (): typeof EmployeeWidgetStore.SnapshotType => ({
    list: emptyEmployee(),
});
