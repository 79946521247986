import React from "react";
import { StartPage } from "./StartPage";
import { Classes, Dialog, Icon } from "@blueprintjs/core";
import styles from "./StartPopup.module.scss";
import func from "modules/session/functionalities";
import { can } from "modules/session/auth/access";
import { StartType } from "./models/start";
import { SessionType } from "modules/session/auth/models/session";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { observer } from "mobx-react";

const StartPopup = ({ store, onLogout, access, session, onSaved }: StartPopupProps) => {
    const logOut = async () => {
        if (session.logOut()) {
            onLogout();
        }
    };
    return (
        <Dialog
            className={styles.popup}
            icon={<Icon icon="issue" intent="danger" />}
            title="Начальные настройки"
            isCloseButtonShown={false}
            isOpen={true}
        >
            {!can(func.SETTINGS_EDIT, access) ? (
                <div className={Classes.DIALOG_BODY}>
                    <div className={styles.textInfo}>
                        <div>
                            Начальные настройки не заполнены! Войдите пользователем с достаточными правами доступа для
                            продолжения.
                        </div>

                        <PlanrButton
                            type="blueish"
                            nativeType="submit"
                            size="small"
                            icon="general-sign-out"
                            onClick={logOut}
                            style={{ marginLeft: "570px", marginTop: "20px", marginBottom: "-15px" }}
                        >
                            Выйти
                        </PlanrButton>
                    </div>
                </div>
            ) : (
                <StartPage start={store} onSaved={onSaved} />
            )}
        </Dialog>
    );
};

export default observer(StartPopup);

interface StartPopupProps {
    store: StartType;
    access: string[];
    session: SessionType;
    onLogout: () => void;
    onSaved: (successfull: boolean) => void;
}
